export enum EEmailPaymentCancelledKeys {
    ORDER_NUMBER = '{{ORDER_NUMBER}}',
    REFUND_DETAILS = '{{REFUND_DETAILS}}',
    USERNAME = '{{USERNAME}}'
}

export const emailPaymentCancelledAndRefunded = `
<table class="es-content" cellspacing="0" cellpadding="0" align="center"
    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
    <tr>
        <td align="center" style="padding:0;Margin:0">
            <table class="es-content-body" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:600px">
                <tr>
                    <td align="left" style="padding:0;Margin:0;background-color:#fff">
                        <table width="100%" cellspacing="0" cellpadding="0"
                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                                <td valign="top" align="center"
                                    style="padding:32px 0 0;Margin:0;width:600px">
                                    <table width="100%" cellspacing="0" cellpadding="0"
                                        role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Bonjour {{USERNAME}},
                                                    </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Suite à votre demande, nous vous confirmons l&rsquo;annulation de votre commande {{ORDER_NUMBER}}.</p>
                                            </td>
                                        </tr>
                                        {{REFUND_DETAILS}}
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Merci pour votre confiance et à bientôt sur vos chantiers !</p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
`;

export const emailRefundDetails = ({ amount, isLateCancelation }: { amount: string; isLateCancelation: boolean }) => `
<tr>
    <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
        style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
        <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
            Le remboursement, d&rsquo;un montant de ${amount}€ a été effectué sur le moyen de paiement utilisé lors du règlement.
        </p>
        ${
            isLateCancelation
                ? `
            <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                Le montant remboursé est différent du montant de la prestation commandée car il s'agit d'une annulation tardive, engendrant des frais conformément aux CGV et CGU en vigueur.
            </p>
            `
                : ''
        }
    </td>
</tr>
`;
