import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../../hooks/Auth';
import Loader from '../Loader';

export function AuthenticatedRoutes() {
    const { loading, isAuthenticated } = useAuth();

    if (loading) {
        return <Loader />;
    }

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}
