import { Dropdown } from 'primereact/dropdown';
import { Tag, TagSeverityType } from 'primereact/tag';
import React from 'react';
import styled from 'styled-components';

import { formatE164ToInternational } from '@bbng/util/misc';
import { CollectorRo, ECollectorContract, ECollectorSkill } from '@bbng/util/types';

import { mapCollectorSkill } from '../../common/enumMapper';
import { COLLECTOR_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';

type TagAvailabilityProps = { severity: TagSeverityType; icon?: `pi ${string}`; value: string };

const availabilityOptions = [
    { name: 'Disponible', value: true },
    { name: 'Indisponible', value: false }
];

const contratOptions = [
    { name: 'Interne', value: ECollectorContract.INTERNAL },
    { name: 'Externe', value: ECollectorContract.EXTERNAL },
    { name: 'Prestataire', value: ECollectorContract.PROVIDER }
];

const skillsOptions = Object.values(ECollectorSkill).map((skill) => ({ name: mapCollectorSkill(skill), value: skill }));

const StyledDropdown = styled(Dropdown)`
    width: 300px;
    height: 44px;
`;

const Anchor = styled.a`
    word-break: break-all;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #343699;
`;

const TagList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const FilterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const StyledTag = styled(Tag)<{ color?: string }>`
    ${({ color }) => color && `background-color: ${color};`}
`;

type QueryParams = { is_available?: boolean; contract?: ECollectorContract; skill?: ECollectorSkill };

export const CollectorsListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [isAvailable, setIsAvailable] = React.useState<boolean | undefined>(query.is_available);
    const [contract, setContract] = React.useState<ECollectorContract | undefined>(query.contract);
    const [skill, setSkill] = React.useState<ECollectorSkill | undefined>(query.skill);

    return (
        <Listing<any, CollectorRo>
            url={COLLECTOR_ORCHESTRATION_BASE_URL}
            endpoint="collectors"
            addButtonUrl={urlBuilder.collectorCreate()}
            queryParams={{
                is_available : isAvailable,
                contract     : contract,
                skill        : skill
            }}
            leftHandedComponent={
                <FilterContainer>
                    <StyledDropdown
                        value={isAvailable}
                        onChange={(e) => setIsAvailable(e.value)}
                        placeholder="Filtrer par disponibilité"
                        optionValue="value"
                        optionLabel="name"
                        showClear
                        options={availabilityOptions}
                    />
                    <StyledDropdown
                        value={contract}
                        onChange={(e) => setContract(e.value)}
                        placeholder="Filtrer par contrat"
                        optionValue="value"
                        optionLabel="name"
                        showClear
                        options={contratOptions}
                    />
                    <StyledDropdown
                        value={skill}
                        onChange={(e) => setSkill(e.value)}
                        placeholder="Filtrer par compétence"
                        optionValue="value"
                        optionLabel="name"
                        showClear
                        options={skillsOptions}
                    />
                </FilterContainer>
            }
            name="Conducteur collecteur"
            pluralName="Conducteur collecteurs"
            headers={[
                {
                    name      : 'Nom',
                    field     : 'firstname',
                    component : (data: CollectorRo) => (
                        <Anchor href={urlBuilder.collectorView(data.id)}>
                            {data.firstname} {data.lastname}
                        </Anchor>
                    )
                },
                {
                    name      : 'Email',
                    field     : 'email',
                    component : (data: CollectorRo) => (
                        <Anchor href={`mailto:${data.email}`}>
                            <i className="pi pi-envelope" />
                            {data.email}
                        </Anchor>
                    )
                },
                {
                    name      : 'Téléphone',
                    field     : 'phone_number',
                    width     : 190,
                    component : (data: CollectorRo) => (
                        <Anchor
                            href={data.phone_number ? 'tel:' + formatE164ToInternational(data.phone_number) : undefined}
                        >
                            <i className="pi pi-phone" />
                            {data.phone_number ? formatE164ToInternational(data.phone_number) : ''}
                        </Anchor>
                    )
                },
                {
                    name      : 'Efficacité',
                    field     : 'efficiency',
                    width     : 90,
                    component : (data: CollectorRo) => <span>{data.efficiency}%</span>
                },
                {
                    name      : 'Disponibilité',
                    field     : 'is_available',
                    width     : 130,
                    component : (data: CollectorRo) => {
                        const props: TagAvailabilityProps = data.is_available
                            ? { severity: 'success', icon: 'pi pi-check', value: 'Disponible' }
                            : { severity: 'danger', icon: 'pi pi-times', value: 'Indisponible' };

                        return (
                            <div>
                                <Tag className="mr-2" {...props} />
                            </div>
                        );
                    }
                },
                {
                    name      : 'Contrat',
                    field     : 'contract',
                    width     : 100,
                    component : (data: CollectorRo) => {
                        let props: TagAvailabilityProps;
                        switch (data.contract) {
                            case ECollectorContract.INTERNAL:
                                props = { severity: 'success', value: 'Interne' };
                                break;
                            case ECollectorContract.EXTERNAL:
                                props = { severity: 'info', value: 'Externe' };
                                break;
                            case ECollectorContract.PROVIDER:
                                props = { severity: 'primary', value: 'Prestataire' };
                                break;
                            default:
                                props = { severity: 'danger', value: 'Non renseigné' };
                                break;
                        }
                        return (
                            <div>
                                <Tag className="mr-2" {...props} />
                            </div>
                        );
                    }
                },
                {
                    name      : 'Compétences',
                    field     : 'skills',
                    component : (data: CollectorRo) => {
                        return (
                            <TagList>
                                {data.skills.map((skill) => (
                                    <StyledTag key={data.id + skill} value={mapCollectorSkill(skill)} />
                                ))}
                            </TagList>
                        );
                    }
                }
            ]}
        />
    );
};
