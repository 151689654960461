import { CollectConfigCancelDto, MAX_TIME_LIMIT_FOR_ORDER_CANCELLATION } from '@bbng/util/types';
import moment from 'moment';

import {
    PatchTrackerErrorState,
    PatchTrackerState,
    initialErrorState as patchTrackerInitialErrorState,
    initialState as patchTrackerInitialState
} from '../collect-config/PatchTracker';

export type CollectConfigCancelModulesStates = PatchTrackerState;

export type CollectConfigCancelModulesErrorStates = PatchTrackerErrorState;

export type CollectConfigCancelFormState = {
    patchTracker: PatchTrackerState;
    triggerInvoice: boolean;
};

export type CollectConfigCancelFormErrorState = {
    patchTracker: PatchTrackerErrorState;
};

export const initialState: CollectConfigCancelFormState = {
    patchTracker   : patchTrackerInitialState,
    triggerInvoice : false
};

export const initialErrorState: CollectConfigCancelFormErrorState = {
    patchTracker: patchTrackerInitialErrorState
};

export const mapStateToApiCancelData = (state: CollectConfigCancelFormState): CollectConfigCancelDto => {
    return {
        patch_origin   : state.patchTracker.patch_origin,
        patch_reason   : state.patchTracker.patch_reason,
        do_not_invoice : !state.triggerInvoice
    };
};

export const maxHourLimitText = moment().set(MAX_TIME_LIMIT_FOR_ORDER_CANCELLATION).format('HH:mm');
