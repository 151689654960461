import React from 'react';
import styled from 'styled-components';

import { CC_FAMILY, CardErrors } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import { useFormModule } from '../../hooks/FormModule';

export type OrderDumpsterServiceProps = {
    readOnly?: boolean;
    value?: OrderDumpsterServiceState;
    id: string;
    result: (
        value: OrderDumpsterServiceState,
        errors: null | string[] | CardErrors<OrderDumpsterServiceState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export enum EOrderDumpsterServiceForm {
    DEPOSIT = 'DEPOSIT',
    RETRIEVAL = 'RETRIEVAL',
    ROTATION = 'ROTATION',
    LOAD_WAIT = 'LOAD_WAIT'
    // ROUND_TRIP = 'ROUND_TRIP'
}

const serviceMapper: Record<EOrderDumpsterServiceForm, string> = {
    [EOrderDumpsterServiceForm.DEPOSIT]   : 'Dépôt',
    [EOrderDumpsterServiceForm.RETRIEVAL] : 'Enlèvement',
    [EOrderDumpsterServiceForm.ROTATION]  : 'Rotation',
    [EOrderDumpsterServiceForm.LOAD_WAIT] : 'Attente chargement'
    // [EOrderDumpsterServiceForm.ROUND_TRIP] : 'Aller-retour'
};

export type OrderDumpsterServiceState = {
    service?: EOrderDumpsterServiceForm;
};
export type OrderDumpsterServiceErrorState = CardErrors<OrderDumpsterServiceState>;

export const initialState: OrderDumpsterServiceState = {
    service: undefined
};
export const initialErrorState: OrderDumpsterServiceErrorState = generateInitialErrorState(initialState);

export const OrderDumpsterService: React.FC<OrderDumpsterServiceProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: OrderDumpsterServiceProps) => {
    const { val, setVal, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    return (
        <StyledCard title="Type de service">
            <FullLineInput>
                <Button.Select
                    required={true}
                    id="service"
                    options={Object.values(EOrderDumpsterServiceForm)}
                    readOnly={readOnly}
                    labelMapper={(value) => serviceMapper[value as EOrderDumpsterServiceForm]}
                    result={handleChange}
                    value={val.service}
                    errors={err.service}
                    displayError={displayError}
                />
            </FullLineInput>
        </StyledCard>
    );
};

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)``;

export const mapCollectConfigFamilyToService = (family?: CC_FAMILY): EOrderDumpsterServiceForm => {
    if (family?.includes(EOrderDumpsterServiceForm.DEPOSIT)) return EOrderDumpsterServiceForm.DEPOSIT;
    if (family?.includes(EOrderDumpsterServiceForm.RETRIEVAL)) return EOrderDumpsterServiceForm.RETRIEVAL;
    if (family?.includes(EOrderDumpsterServiceForm.ROTATION)) return EOrderDumpsterServiceForm.ROTATION;
    if (family?.includes(EOrderDumpsterServiceForm.LOAD_WAIT)) return EOrderDumpsterServiceForm.LOAD_WAIT;
    return 'Service inconnu' as EOrderDumpsterServiceForm;
};
