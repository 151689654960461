import React from 'react';
import styled from 'styled-components';

import { CardErrors, ECustomerCategory } from '@bbng/util/types';

import { mapCustomerCategory } from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';

export type CustomerTypeProps = {
    readOnly?: boolean;
    edit?: boolean;
    value?: CustomerTypeState;
    id: string;
    result: (value: CustomerTypeState, errors: null | string[] | CardErrors<CustomerTypeState>, id: string) => void;
    displayError?: boolean;
};

export type CustomerTypeState = {
    category: ECustomerCategory;
};

export type CustomerTypeErrorState = CardErrors<CustomerTypeState>;

export const initialState: CustomerTypeState = {
    category: ECustomerCategory.PRO
};

export const initialErrorState: CustomerTypeErrorState = generateInitialErrorState(initialState);

export const CustomerType = ({
    readOnly = false,
    edit = false,
    value = initialState,
    id,
    result,
    displayError = false
}: CustomerTypeProps) => {
    const [val, setVal] = React.useState<CustomerTypeState>(value);
    const [err, setErr] = React.useState<CustomerTypeErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <StyledCard title="Type de client" create={!edit && !readOnly}>
            <Button.Select
                required
                forceSelection
                id="category"
                readOnly={readOnly}
                result={handleChange}
                options={
                    edit || readOnly
                        ? Object.values(ECustomerCategory)
                        : Object.values(ECustomerCategory).filter((c) => c !== ECustomerCategory.PRO_TO_VALIDATE)
                }
                labelMapper={(value) => mapCustomerCategory(value as ECustomerCategory)}
                value={val.category}
                errors={err.category}
                displayError={displayError}
            />
        </StyledCard>
    );
};

const StyledCard = styled(Card)<{ create?: boolean }>`
    .p-buttonset {
        width: 100%;
    }
    .p-button {
        width: ${({ create }) => (create ? '50%' : '33%')};
    }
`;
