import React, { useCallback, useEffect, useState } from 'react';

import { Content, PageContainer, PageContent, StyledCard, Text } from './style';
import { useParams } from 'react-router-dom';
import { OrderConfirmationData } from '@bbng/util/types';
import { Button } from 'primereact/button';
import { useRequest } from '../../hooks/StatelessRequest';
import { urlApiBuilder } from '../../common/urlBuilder';
import { ProgressSpinner } from 'primereact/progressspinner';

const AcceptOrderConfirmation: React.FC = (): JSX.Element => {
    const { token } = useParams();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [submitLoading, setSubmitLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [invalidToken, setInvalidToken] = useState(false);

    const [orderConfirmationData, setOrderConfirmationData] = useState<OrderConfirmationData>();

    const fetchInvitation = async (token: string) => {
        setFetchLoading(true);
        const res = await bbngRequest<OrderConfirmationData>({
            url    : urlApiBuilder.orderGetConfirmation(token),
            method : 'GET'
        });
        if (res.success && res.response?.data.ro) {
            setOrderConfirmationData(res.response.data.ro);
        } else {
            setInvalidToken(true);
        }
        setFetchLoading(false);
    };

    useEffect(() => {
        if (token) {
            fetchInvitation(token);
        }
    }, [token]);

    const handleConfirm = async () => {
        setSubmitLoading(true);

        console.log('🧐 ~ handleConfirm ~ token:', token);

        if (token) {
            const res = await bbngRequest({
                method : 'POST',
                url    : urlApiBuilder.orderConfirm(token)
            });
            if (res.success) setSuccess(true);
        } else {
            setInvalidToken(true);
        }

        setSubmitLoading(false);
    };

    const renderContent = useCallback(() => {
        if (fetchLoading) {
            return <ProgressSpinner />;
        } else if (success) {
            return <span>La commande a été confirmée avec succès !</span>;
        } else if (orderConfirmationData) {
            return (
                <>
                    <Text>
                        <span>Confirmez votre commande {orderConfirmationData.order_number} !</span>
                        <span>Pour confirmer votre commande, il vous suffit de cliquer sur le bouton ci-dessous.</span>
                    </Text>
                    <Button
                        disabled={!token}
                        loading={submitLoading}
                        icon="pi pi-check"
                        label="Confirmer"
                        onClick={handleConfirm}
                    />
                </>
            );
        } else if (invalidToken) {
            return (
                <Content>
                    <span>Oups ! Cette demande est expirée, invalide ou la commande a déjà été validée.</span>
                </Content>
            );
        }
        return (
            <Content>
                <span>Une erreur est survenue, essayez de recharger la page...</span>
            </Content>
        );
    }, [fetchLoading, success, orderConfirmationData, submitLoading, invalidToken]);

    return (
        <PageContainer>
            <PageContent>
                <StyledCard>
                    <h1>🔐 Confirmation de commande</h1>
                    {renderContent()}
                </StyledCard>
            </PageContent>
        </PageContainer>
    );
};

export default AcceptOrderConfirmation;
