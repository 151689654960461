import { formatE164ToInternational, getMainEmailPhoneFromCustomerContacts } from '@bbng/util/misc';
import {
    CCServiceRo,
    ConstructionSiteRo,
    CustomerRo,
    DumpsterOnSiteRo,
    ECustomerCategory,
    FrontRo,
    isDumpsterOnSiteRo,
    PRODUCT_FAMILY
} from '@bbng/util/types';
import moment from 'moment';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import { mapCollectConfigStatus, mapDumpsterTypeText, mapTrashTypetext } from '../../common/enumMapper';
import { urlBuilder } from '../../common/urlBuilder';
import { DataTableProps } from '../../components/DataTable';
import { displayAddress } from '../Collect/helpers';
import { mapStatusToIcon, mapStatusToSeverity } from '../CollectConfigs';
import { Anchor, DetailContainer, RetrievalContainer, RetrievalLine } from './style';

export const dumpsterOnSiteListHeaders: DataTableProps<DumpsterOnSiteRo>['headers'] = [
    {
        name      : 'Détails benne',
        field     : 'product',
        component : (data: DumpsterOnSiteRo | CCServiceRo) => {
            const product = isDumpsterOnSiteRo(data)
                ? data.product
                : data.products?.find((pr) => pr.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT);
            const comment = data.comment?.trim() !== '' ? data.comment : undefined;
            const trashTypeText = mapTrashTypetext(product?.trash_type);

            const dumpsterType = product?.dumpster_type ?? [];

            let url: string;
            if (isDumpsterOnSiteRo(data)) {
                url = urlBuilder.dumpsterOnSiteView(data.id);
            } else {
                url = urlBuilder.collectConfigView(data.id);
            }

            return (
                <DetailContainer>
                    <div>
                        <a href={url} target="_blank" rel="noreferrer">
                            {trashTypeText} - {product?.volume_m3}m³
                        </a>
                        {dumpsterType.length > 0 && (
                            <div>({dumpsterType.map((type) => mapDumpsterTypeText(type)).join(', ')})</div>
                        )}
                    </div>

                    {comment && (
                        <>
                            <Tooltip
                                position="top"
                                target={`.dumpster-${data.id.replace(':', '')}`}
                                content={data.comment ?? 'Pas de commentaire'}
                            />
                            <i className={`pi pi-comments dumpster-${data.id.replace(':', '')}`} />
                        </>
                    )}
                </DetailContainer>
            );
        }
    },
    {
        name      : 'Client',
        field     : 'customer_id',
        component : (data: FrontRo<DumpsterOnSiteRo | CCServiceRo>) => {
            const customer = data.customer_id[0] as CustomerRo;
            const isIndividual = customer?.category === ECustomerCategory.INDIVIDUAL;
            const individualIcon = <i className="pi pi-user" />;
            return (
                <>
                    <Tooltip
                        position="top"
                        target={`.customer-${data.id.replace(':', '')}`}
                        content={isIndividual ? 'Particulier' : undefined}
                    />
                    <a
                        href={urlBuilder.customerView(customer.id)}
                        target="_blank"
                        rel="noreferrer"
                        className={`customer-${data.id.replace(':', '')}`}
                    >
                        {customer?.name} {isIndividual && individualIcon}
                    </a>
                </>
            );
        }
    },
    {
        name      : 'Email principal',
        field     : 'customer_id',
        component : (data: FrontRo<DumpsterOnSiteRo | CCServiceRo>) => {
            const customer = data.customer_id[0] as CustomerRo;
            const { email } = getMainEmailPhoneFromCustomerContacts(customer.contact);
            if (email) {
                return (
                    <Anchor href={`mailto:${email}`}>
                        <i className="pi pi-envelope" />
                        {email}
                    </Anchor>
                );
            } else {
                return <span>Aucun e-mail</span>;
            }
        }
    },
    {
        name      : 'Téléphone principal',
        field     : 'customer_id',
        component : (data: FrontRo<DumpsterOnSiteRo | CCServiceRo>) => {
            const customer = data.customer_id[0] as CustomerRo;
            const { phone } = getMainEmailPhoneFromCustomerContacts(customer.contact);
            if (!phone) return <span>Aucun téléphone</span>;
            return (
                <Anchor href={phone ? 'tel:' + formatE164ToInternational(phone) : undefined}>
                    <i className="pi pi-phone" />
                    {phone ? formatE164ToInternational(phone) : ''}
                </Anchor>
            );
        }
    },
    {
        name      : 'Adresse',
        field     : 'construction_site_id',
        component : (data: FrontRo<DumpsterOnSiteRo | CCServiceRo>) => {
            const consSite = data.construction_site_id[0] as ConstructionSiteRo;
            return (
                <a href={urlBuilder.constructionSiteView(consSite.id)} target="_blank" rel="noreferrer">
                    {displayAddress(consSite.address)}
                </a>
            );
        }
    },
    {
        name      : 'Déposée le',
        field     : 'deposited_at',
        component : (data: DumpsterOnSiteRo | CCServiceRo) => {
            if (isDumpsterOnSiteRo(data)) {
                return <span>{moment(data.deposited_at).format('DD/MM/YYYY')}</span>;
            } else {
                return <span>Dépôt prévu le {moment(data.from_date).format('DD/MM/YYYY')}</span>;
            }
        },
        sortable: true
    },
    {
        name      : 'Enlevée le',
        field     : 'retrieved_at',
        component : (data: DumpsterOnSiteRo | CCServiceRo) => {
            if (isDumpsterOnSiteRo(data)) {
                if (data.retrieved_at) {
                    return <span>{moment(data.retrieved_at).format('DD/MM/YYYY')}</span>;
                } else {
                    return <span>Toujours sur chantier</span>;
                }
            } else {
                return <span>Pas encore déposée</span>;
            }
        }
    },
    {
        name      : 'Jours depuis le dépôt',
        field     : 'deposited_at',
        component : (data: DumpsterOnSiteRo | CCServiceRo) => {
            if (isDumpsterOnSiteRo(data)) {
                let days = moment().diff(moment(data.deposited_at), 'days');
                if (days < 0) days = 0;
                return (
                    <span>
                        {days} jour{days > 1 ? 's' : ''}
                    </span>
                );
            } else {
                return <span>N/A</span>;
            }
        },
        sortable: true
    },
    {
        name      : "Commande d'enlèvement demandée",
        field     : 'retrieval_collect_config_id',
        component : (data: FrontRo<DumpsterOnSiteRo | CCServiceRo>) => {
            let retrievalCCs: CCServiceRo[] = [];
            retrievalCCs = data.retrieval_collect_config_id as CCServiceRo[];
            if (retrievalCCs?.length && retrievalCCs.length > 0) {
                return (
                    <RetrievalContainer>
                        {retrievalCCs.map((cc) => (
                            <RetrievalLine key={cc.id}>
                                <a
                                    key={cc.id}
                                    href={urlBuilder.collectConfigView(cc.id)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {cc.number} ({cc.order_number})
                                </a>
                                <Tag
                                    className="mr-2"
                                    value={mapCollectConfigStatus(cc.status)}
                                    severity={mapStatusToSeverity(cc.status)}
                                    icon={mapStatusToIcon(cc.status)}
                                />
                            </RetrievalLine>
                        ))}
                    </RetrievalContainer>
                );
            } else {
                return <span>Aucun</span>;
            }
        }
    }
];
