import { Button } from 'primereact/button';
import React from 'react';
import styled from 'styled-components';

import { CardErrors, DocumentRo, EDocumentType } from '@bbng/util/types';

import { mapDocumentType } from '../../common/enumMapper';
import { TDocument } from '../../common/form';
import { urlApiBuilder } from '../../common/urlBuilder';
import { Card } from '../../components/Card';
import { Dropzone } from '../../components/Dropzone';
import Input from '../../components/Inputs';
import { useRequest } from '../../hooks/StatelessRequest';
import InputList from '../InputList';

export type CustomerDocumentsProps = {
    readOnly?: boolean;
    value?: CustomerDocumentsState;
    id: string;
    result: (
        value: CustomerDocumentsState,
        errors: null | string[] | CardErrors<CustomerDocumentsState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type CustomerDocumentsState = {
    documents: TDocument[];
};
export type CustomerDocumentsErrorState = CardErrors<CustomerDocumentsState>;

export const initialState: CustomerDocumentsState = {
    documents: []
};
export const initialErrorState: CustomerDocumentsErrorState = Object.fromEntries(
    Object.keys(initialState).map((k) => [k, null])
) as CustomerDocumentsErrorState;

export const CustomerDocuments: React.FC<CustomerDocumentsProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: CustomerDocumentsProps) => {
    const bbngRequest = useRequest();
    const [val, setVal] = React.useState<CustomerDocumentsState>(value);
    const [err, setErr] = React.useState<CustomerDocumentsErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: TDocument[], errors: string[] | null, childId: string) => {
        setVal(() => ({ documents: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    const getFileData = async (e: TDocument): Promise<{ url: string; name: string } | undefined> => {
        if (e.type === 'local' && e.local && e.local.document) {
            const doc: File = e.local.document;
            const url = URL.createObjectURL(doc);
            const name: string = doc.name;

            return { url, name };
        } else if (e.type === 'online' && e.online) {
            const doc: DocumentRo = e.online;

            const response = await bbngRequest<string>(
                {
                    method : 'GET',
                    url    : urlApiBuilder.documentGetSignedUrl(doc.id)
                },
                true
            );
            if (!response.response?.data.ro) return undefined;

            const url: string = response.response.data.ro;
            const name: string = doc.name;

            return { url, name };
        }
        return undefined;
    };

    const downloadColumnBody = (mode: 'preview' | 'download') => (e: TDocument) => {
        const icon = mode === 'preview' ? 'pi pi-eye' : 'pi pi-download';
        return (
            <Button
                type="button"
                icon={icon}
                className="p-button-rounded p-button-text"
                onClick={() => {
                    getFileData(e).then((info) => {
                        if (info === undefined) return;

                        const { url, name } = info;

                        const anchor = document.createElement('a');

                        anchor.id = 'dummy-download-anchor';
                        if (mode === 'download') anchor.download = name;
                        anchor.href = url;
                        anchor.target = '_blank';

                        anchor.click();
                        anchor.remove();
                    });
                }}
            />
        );
    };

    const formatSize = (size?: number): string => (size ? ((size ?? 0) / 1_000_000).toFixed(2) + ' Mo' : '');

    return (
        <StyledCard title="Documents administratifs">
            <FullLineInput>
                <InputList.Base<TDocument>
                    readOnly={readOnly}
                    title="Documents"
                    onChange={handleChange}
                    value={val.documents}
                    setErrors={() => void 0}
                    columns={[
                        {
                            header : 'Type',
                            body   : (e: TDocument) => mapDocumentType(e.type === 'local' ? e.local?.type : e.online?.type)
                        },
                        {
                            header : 'Nom',
                            body   : (e: TDocument) => (e.type === 'local' ? e.local?.document?.name : e.online?.name)
                        },
                        {
                            header : 'Taille',
                            body   : (e: TDocument) =>
                                formatSize(e.type === 'local' ? e.local?.document?.size : e.online?.size)
                        },
                        {
                            body        : downloadColumnBody('preview'),
                            headerStyle : { maxWidth: '3em' },
                            bodyStyle   : { maxWidth: '3em' }
                        },
                        {
                            body        : downloadColumnBody('download'),
                            headerStyle : { maxWidth: '3em' },
                            bodyStyle   : { maxWidth: '3em' }
                        }
                    ]}
                    disableEditButton
                    disableModalButton={(modalInputs) => {
                        return Boolean(modalInputs?.local?.type && modalInputs?.local?.document) === false;
                    }}
                    modalTemplate={DocumentModal}
                />
            </FullLineInput>
        </StyledCard>
    );
};

const DocumentModal = (
    state: TDocument | undefined,
    setter: React.Dispatch<React.SetStateAction<TDocument | undefined>>
) => {
    return (
        <ModalContainer>
            <Input.Dropdown
                id="type"
                options={Object.values(EDocumentType)}
                required
                result={(value, err, id) => {
                    setter((prev) => ({
                        ...prev,
                        local : { ...prev?.local, type: value as EDocumentType },
                        type  : 'local'
                    }));
                }}
                itemTemplate={(value) => <>{mapDocumentType(value)}</>}
                valueTemplate={(value) => <>{mapDocumentType(value)}</>}
                label="Type de document"
                errors={[]}
                value={state?.local?.type ?? ''}
            />

            {state?.local?.document ? (
                <UploadedItem>
                    <div>{state.local.document.name}</div>
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-text"
                        onClick={() => {
                            setter((prev) => ({
                                ...prev,
                                local : { ...prev?.local, document: undefined },
                                type  : 'local'
                            }));
                        }}
                    />
                </UploadedItem>
            ) : (
                <Dropzone
                    id="test"
                    hidePreview
                    readOnly={!state?.local?.type}
                    required={false}
                    result={(value, errors, id) => {
                        setter((prev) => ({
                            ...prev,
                            local : { ...prev?.local, document: value as File },
                            type  : 'local'
                        }));
                    }}
                    type={EDocumentType.DRIVING_LICENSE}
                    value={state?.local?.document ?? null}
                />
            )}
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
    & > span,
    .p-dropdown {
        width: 100%;
    }
`;

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)`
    flex: 2 !important;
    .subtitle {
        margin-top: 0;
        margin-bottom: 5px;
    }
`;

const UploadedItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;
