import moment from 'moment';
import { Button as PRButton } from 'primereact/button';
import { Tag } from 'primereact/tag';
import React from 'react';
import styled from 'styled-components';

import { getMiddleOfTheDay } from '@bbng/util/misc';

import { axiosClient } from '../../common/axios';
import { defaultErrorToast } from '../../common/syncRequest';
import { urlApiBuilder } from '../../common/urlBuilder';
import Button from '../../components/Button';
import { Dialog } from '../../components/Dialog';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { EPlanningType, PRODUCT_DUMPSTER_TYPE } from '@bbng/util/types';

export const ExportPlanningButton: React.FC = () => {
    const { plannings, planningPageState, collectorsByPlanning, trucksByPlanning, dumpsterTypes } =
        usePlanningV2Store();
    const [showDialog, setShowDialog] = React.useState(false);

    const availableExport = React.useMemo(() => {
        return Object.values(plannings)
            .filter((p) => {
                let planningIsDisplayed = false;
                if (planningPageState.type === EPlanningType.BIG_BAG) planningIsDisplayed = true;
                else {
                    const truck = trucksByPlanning[p.id];
                    planningIsDisplayed = truck.type.some((tpe) =>
                        dumpsterTypes.includes(tpe as PRODUCT_DUMPSTER_TYPE)
                    );
                }
                return p.shift && p.collector_id.length > 0 && p.truck_id.length > 0 && planningIsDisplayed;
            })
            .map((e) => {
                const truck = trucksByPlanning[e.id];
                const collector = collectorsByPlanning[e.id];
                return {
                    id    : e.id,
                    label : `${collector?.fullname} - ${truck?.name} - ${moment(e.shift?.start_date).format(
                        'HH:mm'
                    )} - ${moment(e.shift?.end_date).format('HH:mm')}`
                };
            });
    }, [plannings, planningPageState.type, dumpsterTypes]);

    const [selectedPlannings, setSelectedPlannings] = React.useState<Record<string, boolean>>(
        Object.fromEntries(availableExport.map((e) => [e.id, false]))
    );

    const [isLoading, setIsLoading] = React.useState(false);

    const exportPlanning = async (plannings: string[]) => {
        setIsLoading(true);

        try {
            const response = await axiosClient.post(
                urlApiBuilder.planningExport(),
                {
                    ids: plannings
                },
                {
                    params: {
                        day       : getMiddleOfTheDay(planningPageState.day),
                        type      : planningPageState.type,
                        region    : planningPageState.region,
                        utcOffset : moment().utcOffset()
                    },
                    responseType: 'blob'
                }
            );

            const formatedDay = moment.utc(planningPageState.day as string).format('YYYY-MM-DD');
            const name = `${planningPageState.region}-${formatedDay}-${planningPageState.type}`;

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.data);
            link.download = `Planning-${name}.xlsx`;
            link.click();
            link.remove();
        } catch (e) {
            defaultErrorToast("Une erreur est survenue lors de l'export du planning");
            console.error(e);
        }
        setIsLoading(false);
    };

    return (
        <>
            <PRButton
                type="button"
                disabled={availableExport.length === 0 || isLoading}
                className="p-button-sm"
                icon={`pi ${isLoading ? 'pi-spinner pi-spin' : 'pi-download '}`}
                label="Exporter le planning"
                onClick={() => setShowDialog(true)}
            />
            <Dialog
                visible={showDialog}
                onHide={() => setShowDialog(false)}
                header="Tournées à exporter"
                draggable={false}
                blockScroll
                contentStyle={{ overflow: 'scroll' }}
            >
                <ModalBody>
                    <Tag
                        value="Seules les tournées ayant un chauffeur sont affichées ici."
                        icon="pi pi-info-circle"
                        severity="warning"
                    />
                    <HeaderContainer>
                        <PRButton
                            className="p-button-text"
                            label="Tout sélectionner"
                            onClick={() => {
                                setSelectedPlannings((prev) =>
                                    Object.fromEntries(Object.keys(prev).map((e) => [e, true]))
                                );
                            }}
                        />
                        <PRButton
                            className="p-button-text"
                            label="Tout désélectionner"
                            onClick={() => {
                                setSelectedPlannings((prev) =>
                                    Object.fromEntries(Object.keys(prev).map((e) => [e, false]))
                                );
                            }}
                        />
                    </HeaderContainer>

                    {availableExport.map((e) => (
                        <Button.Checkbox
                            key={e.id}
                            label={e.label}
                            id={e.id}
                            value={selectedPlannings[e.id]}
                            result={(value) => {
                                setSelectedPlannings((prev) => ({
                                    ...prev,
                                    [e.id]: value
                                }));
                            }}
                            labelPosition="right"
                        />
                    ))}

                    <PRButton
                        label="Valider"
                        onClick={() => {
                            exportPlanning(
                                Object.entries(selectedPlannings)
                                    .filter((e) => e[1])
                                    .map((e) => e[0])
                            );
                            setShowDialog(false);
                        }}
                    />
                </ModalBody>
            </Dialog>
        </>
    );
};

const HeaderContainer = styled.div`
    /* padding-top: 8px; */
    display: flex;
    justify-content: space-between;
`;

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
