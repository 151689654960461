import { Item, Item_Authz, Item_History } from '@prisma/client/item';

import { RelationsDto } from '../dto';
import { ISODate } from '../misc';

export type ItemCreateDto = {
    test_name?: string;
    name: string;
    pack_barcode?: string;
    barcode: string;
    status: EItemStatus;
    customer_id?: string[];
    user_id?: string[];
    pickable_date?: ISODate;
    collected_date?: ISODate;
};
export type ItemCreateActionDto = ItemCreateDto & {
    appears_on_list?: boolean;
    id?: string;
};

export type ItemEditDto = RelationsDto<ItemRo> & {
    test_name?: string;
    name?: string;
    pack_barcode?: string;
    barcode?: string;
    status?: EItemStatus;
    pickable_date?: ISODate;
    collected_date?: ISODate;
};

export type ItemEditActionDto = Omit<ItemEditDto, 'connect' | 'disconnect'> & {
    customer_id?: string[];
    user_id?: string[];
    archived?: boolean;
};

/**
 * WARNING: If you add a field in ItemUploadDto, add it to Joi schema
 */
export interface ItemUploadDto {
    name: string;
    barcode: string;
    pack_barcode?: string;
}

export interface ItemRo {
    id: string;
    test_name?: string;
    name: string;
    pack_barcode?: string;
    barcode: string;
    status: EItemStatus;
    customer_id: string[];
    user_id: string[];
    pickable_date?: ISODate;
    collected_date?: ISODate;

    archived: boolean;
    created_by: string;
    created_at: ISODate;
    updated_at: ISODate;
}

export type ItemPrisma = Item & { authorization: Item_Authz; history: Item_History };

export enum EItemStatus {
    INACTIVE = 'INACTIVE',
    PICKABLE = 'PICKABLE',
    COLLECTED = 'COLLECTED'
}
