import moment from 'moment';

import { deepCopy, formatPhoneE164ToPhoneValue, formatPhoneNumberToE164 } from '@bbng/util/misc';
import { ILandfillInventory, LandfillCreateDto, LandfillEditDto, LandfillRo } from '@bbng/util/types';

import {
    getRelationsDto,
    mapBackContactToFrontContact,
    mapFrontContactToBackContact,
    optimiseEditDto
} from '../../common/form';
import {
    AddressErrorState,
    AddressState,
    initialErrorState as addressInitialErrorState,
    initialState as addressInitialState
} from '../../modules/common/Address';
import {
    LandfillCenterContactsErrorState as LandfillContactsErrorState,
    LandfillCenterContactsState as LandfillContactsState,
    initialErrorState as landfillContactsInitialErrorState,
    initialState as landfillContactsInitialState
} from '../../modules/landfill-center/ContactList';
import {
    LandfillCenterExceptionalClosuresErrorState as LandfillExceptionalClosuresErrorState,
    LandfillCenterExceptionalClosuresState as LandfillExceptionalClosuresState,
    initialErrorState as landfillExceptionalClosuresInitialErrorState,
    initialState as landfillExceptionalClosuresInitialState
} from '../../modules/landfill-center/ExceptionalClosureList';
import {
    LandfillCenterGeneralErrorState as LandfillGeneralErrorState,
    LandfillCenterGeneralState as LandfillGeneralState,
    initialErrorState as landfillGeneralInitialErrorState,
    initialState as landfillGeneralInitialState
} from '../../modules/landfill-center/General';
import {
    LandfillCenterOpeningTimeErrorState as LandfillOpeningTimeErrorState,
    LandfillCenterOpeningTimeState as LandfillOpeningTimeState,
    initialErrorState as landfillOpeningTimesInitialErrorState,
    initialState as landfillOpeningTimesInitialState
} from '../../modules/landfill-center/OpeningTimes';
import {
    LandfillCenterPricesErrorState as LandfillPricesErrorState,
    LandfillCenterPricesState as LandfillPricesState,
    initialErrorState as landfillPricesInitialErrorState,
    initialState as landfillPricesInitialState
} from '../../modules/landfill-center/PriceList';
import {
    LandfillCenterInventoryErrorState as LandfillInventoryErrorState,
    LandfillCenterInventoryState as LandfillInventoryState,
    initialErrorState as landfillInventoryInitialErrorState,
    initialState as landfillInventoryInitialState
} from '../../modules/landfill-center/Inventory';

export type LandfillModulesStates =
    | LandfillGeneralState
    | LandfillContactsState
    | LandfillPricesState
    | LandfillExceptionalClosuresState
    | LandfillOpeningTimeState
    | LandfillInventoryState;

export type LandfillModulesErrorStates =
    | LandfillGeneralErrorState
    | LandfillContactsErrorState
    | LandfillPricesErrorState
    | LandfillExceptionalClosuresErrorState
    | LandfillOpeningTimeErrorState
    | AddressErrorState
    | LandfillInventoryErrorState;

export type LandfillFormState = {
    general: LandfillGeneralState;
    contacts: LandfillContactsState;
    prices: LandfillPricesState;
    exceptionalClosures: LandfillExceptionalClosuresState;
    openingTimes: LandfillOpeningTimeState;
    address: AddressState;
    inventory: LandfillInventoryState;
};

export type LandfillFormErrorState = {
    general: LandfillGeneralErrorState;
    contacts: LandfillContactsErrorState;
    prices: LandfillPricesErrorState;
    exceptionalClosures: LandfillExceptionalClosuresErrorState;
    openingTimes: LandfillOpeningTimeErrorState;
    address: AddressErrorState;
    inventory: LandfillInventoryErrorState;
};

export const initialState: LandfillFormState = {
    general             : landfillGeneralInitialState,
    contacts            : landfillContactsInitialState,
    prices              : landfillPricesInitialState,
    exceptionalClosures : landfillExceptionalClosuresInitialState,
    openingTimes        : landfillOpeningTimesInitialState,
    address             : addressInitialState,
    inventory           : landfillInventoryInitialState
};

export const initialErrorState: LandfillFormErrorState = {
    general             : landfillGeneralInitialErrorState,
    contacts            : landfillContactsInitialErrorState,
    prices              : landfillPricesInitialErrorState,
    exceptionalClosures : landfillExceptionalClosuresInitialErrorState,
    openingTimes        : landfillOpeningTimesInitialErrorState,
    address             : addressInitialErrorState,
    inventory           : landfillInventoryInitialErrorState
};

export const mapApiDataToState = (landfill: LandfillRo): LandfillFormState => {
    const calendar = deepCopy(landfill.opening_time);
    const days = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];
    for (const key in calendar) {
        const typedKey = key as unknown as keyof typeof calendar;
        if (calendar[typedKey]) {
            calendar[typedKey].day = days[typedKey];
        }
    }

    return {
        general: {
            name                    : landfill.name,
            code_name               : landfill.code_name,
            siren                   : landfill.siren ?? '',
            siret                   : landfill.siret ?? '',
            complement              : landfill.additional_info ?? '',
            fax                     : landfill.fax_number ? formatPhoneE164ToPhoneValue(landfill.fax_number) : null,
            phone                   : formatPhoneE164ToPhoneValue(landfill.phone_number),
            average_waiting_minutes : landfill.average_waiting_minutes,
            maximum_daily_volume_m3 : landfill.maximum_daily_volume_m3 ?? 0,
            accept_bigbag           : landfill.accept_bigbag,
            accept_dumpster         : landfill.accept_dumpster,
            owner_is_endless        : landfill.owner_is_endless
        },
        address: {
            address_google        : undefined,
            address_street_number : landfill.address.components['street_number'],
            address_street_name   : landfill.address.components['route'],
            address_city          : landfill.address.components['locality'],
            address_zip_code      : landfill.address.components['postal_code'],
            address_country       : landfill.address.components['country'],
            address_complement    : landfill.address.components['complement'] ?? '',
            address_lat           : landfill.address.coordinates.latitude,
            address_lng           : landfill.address.coordinates.longitude
        },
        prices: {
            priceList: landfill.trash_details.map((trashD) => ({
                price_value : trashD.price_value.net_amount_cents / 100,
                price_unit  : trashD.price_unit,
                type        : trashD.type
            }))
        },
        contacts: {
            contactList: landfill.contact.map((c) => mapBackContactToFrontContact(c))
        },
        exceptionalClosures: {
            calendarRangeList: landfill.exceptional_closure.map((e) => [
                moment.utc(e.from).toISOString(),
                moment.utc(e.to).toISOString()
            ])
        },
        openingTimes: {
            calendar
        },
        inventory: landfill.inventory ?? landfillInventoryInitialState
    };
};

export const mapStateToApiCreateData = (state: LandfillFormState): LandfillCreateDto => {
    const opening_time = deepCopy(state.openingTimes.calendar)!;
    for (const key in opening_time) {
        const typedKey = key as unknown as keyof typeof opening_time;
        if (opening_time[typedKey]) {
            delete opening_time[typedKey].day;
            delete opening_time[typedKey].disabled;
        }
    }

    return {
        name                    : state.general.name,
        code_name               : state.general.code_name,
        siren                   : state.general.siren,
        siret                   : state.general.siret,
        additional_info         : state.general.complement,
        fax_number              : state.general.fax ? formatPhoneNumberToE164(state.general.fax) : undefined,
        phone_number            : formatPhoneNumberToE164(state.general.phone!),
        average_waiting_minutes : state.general.average_waiting_minutes!,
        accept_bigbag           : state.general.accept_bigbag,
        accept_dumpster         : state.general.accept_dumpster,
        trash_details           : state.prices.priceList.map((pr) => ({
            price_unit  : pr.price_unit,
            price_value : {
                net_amount_cents : Math.round((pr?.price_value ?? 0) * 100),
                currency         : 'EUR'
            },
            type: pr.type
        })),
        contact             : state.contacts.contactList.map(mapFrontContactToBackContact),
        exceptional_closure : state.exceptionalClosures.calendarRangeList.map(([from, to]) => ({
            from : moment.utc(from).toISOString(),
            to   : moment.utc(to).toISOString()
        })),

        opening_time: opening_time,

        address: {
            coordinates: {
                latitude  : state.address.address_lat!,
                longitude : state.address.address_lng!
            },
            name           : (state.address.address_street_name || state.address.address_google?.name) ?? '',
            formatted_name :
                (state.address.address_google?.formatted_address ||
                    state.address.address_street_name ||
                    state.address.address_google?.name) ??
                '',
            components: {
                street_number : state.address.address_street_number,
                route         : state.address.address_street_name,
                locality      : state.address.address_city,
                postal_code   : state.address.address_zip_code,
                country       : state.address.address_country,
                complement    : state.address.address_complement
            }
        },
        owner_is_endless        : state.general.owner_is_endless,
        maximum_daily_volume_m3 : state.general.owner_is_endless ? state.general.maximum_daily_volume_m3 : undefined,
        inventory               : state.general.owner_is_endless ? (state.inventory as ILandfillInventory) : undefined
    };
};

export const mapStateToApiEditData = (
    state: LandfillFormState,
    apiState: LandfillFormState,
    discount: LandfillRo
): LandfillEditDto =>
    optimiseEditDto(state, apiState, discount, mapStateToApiCreateData, getRelationsDto<LandfillRo>());
