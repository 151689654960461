import styled from 'styled-components';

export const Header = styled.div`
    margin: 0 10%;
    display: flex;
    justify-content: space-evenly;
`;

export const Page = styled.div`
    margin: 16px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    & > * {
        flex: 1;
    }
    gap: 16px;
    & h1 {
        margin: 0;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    gap: 16px;
`;
