import create from 'zustand';
import { compact, filter, flatMap, pipe, toArray } from '@fxts/core';

import {
    CCAdministrativeQuery,
    CCAdministrativeRo,
    CCServiceRo,
    CC_FAMILY,
    CC_STATUS,
    CollectConfigQuery,
    CollectEmptying,
    CollectRo,
    CollectorRo,
    CustomerRo,
    LandfillRo,
    PlanningGetAllQuery,
    PlanningRo,
    PlanningShiftStepCategory,
    PlanningShiftStepService,
    ProductRo,
    TruckRo
} from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import { useRequest } from '../../hooks/StatelessRequest';

export type PStore = {
    plannings: Record<string, PlanningRo>;
    trucks: Record<string, TruckRo>;
    collectors: Record<string, CollectorRo>;
    customers: Record<string, CustomerRo>;
    landfills: Record<string, LandfillRo>;
    ccsService: Record<string, CCServiceRo>;
    ccsAdministrative: Record<string, CCAdministrativeRo>;
    collects: Record<string, CollectRo>;
    products: Record<string, ProductRo>;

    getPlannings: (
        data: PlanningGetAllQuery,
        req: ReturnType<typeof useRequest>,
        fromCalculate?: boolean
    ) => Promise<void>;
    getCCService: (data: CollectConfigQuery, req: ReturnType<typeof useRequest>) => Promise<void>;
    getCCAdministrative: (data: CCAdministrativeQuery, req: ReturnType<typeof useRequest>) => Promise<void>;
    getTrucks: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getCollectors: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getCustomers: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getLandfills: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getCollects: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getProducts: (req: ReturnType<typeof useRequest>) => Promise<void>;

    fetchAll: (data: PlanningGetAllQuery, req: ReturnType<typeof useRequest>) => Promise<void>;

    unnassignedCCService: CCServiceRo[];
    unnassignedCCAdministrative: CCAdministrativeRo[];
    getUnnassignedCCService: () => void;
};

export const usePStore = create<PStore>((set, get) => ({
    plannings                   : {},
    trucks                      : {},
    collectors                  : {},
    customers                   : {},
    constructionSites           : {},
    landfills                   : {},
    ccsService                  : {},
    ccsAdministrative           : {},
    collects                    : {},
    unnassignedCCService        : [],
    unnassignedCCAdministrative : [],
    products                    : {},
    getPlannings                : async (data, req, fromCalculate = false) => {
        const plannings = await req<PlanningRo[]>({
            sync    : false,
            method  : 'GET',
            url     : urlApiBuilder.planningGetAll(),
            payload : {
                queryParams: {
                    region            : data.region,
                    type              : data.type,
                    day               : data.day,
                    redis_version_key : data.redis_version_key,
                    no_limit          : true
                } as PlanningGetAllQuery
            },
            options      : { toastifySuccess: false },
            retryPolling : 1000
        });
        if (plannings.success && plannings.response && plannings.response.data && plannings.response.data.ro) {
            const formattedPlanning = plannings.response.data.ro.reduce((acc, planning) => {
                acc[planning.id] = planning;
                return acc;
            }, {} as Record<string, PlanningRo>);
            set({ plannings: formattedPlanning });
            if (fromCalculate) {
                get().getUnnassignedCCService();
            }
        }
    },
    getTrucks: async (req) => {
        const plannings = get().plannings;
        const truckId = Object.values(plannings).flatMap((p) => p.truck_id);

        const trucks = await req<TruckRo[]>({
            sync    : true,
            method  : 'POST',
            url     : urlApiBuilder.truckGetMany(),
            payload : {
                body: {
                    ids: [...new Set<string>(truckId)]
                }
            }
        });
        if (trucks.success && trucks.response && trucks.response.data && trucks.response.data.ro) {
            const formattedTrucks = trucks.response.data.ro.reduce((acc, truck) => {
                acc[truck.id] = truck;
                return acc;
            }, {} as Record<string, TruckRo>);
            set({ trucks: formattedTrucks });
        }
    },
    getCollectors: async (req) => {
        const plannings = get().plannings;
        const collectorId = Object.values(plannings).flatMap((p) => p.collector_id);

        const collectors = await req<CollectorRo[]>({
            sync    : true,
            method  : 'POST',
            url     : urlApiBuilder.collectorGetMany(),
            payload : {
                body: {
                    ids: [...new Set<string>(collectorId)]
                }
            }
        });
        if (collectors.success && collectors.response && collectors.response.data && collectors.response.data.ro) {
            const formattedCollectors = collectors.response.data.ro.reduce((acc, collector) => {
                acc[collector.id] = collector;
                return acc;
            }, {} as Record<string, CollectorRo>);
            set({ collectors: formattedCollectors });
        }
    },
    getCCService: async (data, req) => {
        const ccsService = await req<CCServiceRo[]>({
            sync    : true,
            method  : 'GET',
            url     : urlApiBuilder.collectConfigReadAll(),
            payload : {
                queryParams: {
                    region      : data.region,
                    type        : data.type,
                    day         : data.day,
                    no_delivery : true,
                    uncancelled : true,
                    no_archived : true,
                    no_limit    : true
                } as CollectConfigQuery
            }
        });

        if (ccsService.success && ccsService.response && ccsService.response.data && ccsService.response.data.ro) {
            const formattedCCsService = ccsService.response.data.ro.reduce((acc, cc) => {
                acc[cc.id] = cc;
                return acc;
            }, {} as Record<string, CCServiceRo>);
            set({ ccsService: formattedCCsService });
            get().getUnnassignedCCService();
        }
    },
    getCCAdministrative: async (data, req) => {
        const ccsAdministrative = await req<CCAdministrativeRo[]>({
            sync    : true,
            method  : 'GET',
            url     : urlApiBuilder.ccAdministrativeReadAll(),
            payload : {
                queryParams: {
                    region      : data.region,
                    type        : data.type,
                    day         : data.day,
                    no_archived : true,
                    no_limit    : true
                } as CCAdministrativeQuery
            },
            options: { toastifySuccess: false }
        });
        if (
            ccsAdministrative.success &&
            ccsAdministrative.response &&
            ccsAdministrative.response.data &&
            ccsAdministrative.response.data.ro
        ) {
            const formattedCCsAdministrative = ccsAdministrative.response.data.ro.reduce((acc, cc) => {
                acc[cc.id] = cc;
                return acc;
            }, {} as Record<string, CCAdministrativeRo>);
            set({ ccsAdministrative: formattedCCsAdministrative });
            const unnassignedCCAdministrative = ccsAdministrative.response.data.ro.filter(
                (cc) => cc.status === CC_STATUS.TO_PLAN
            );
            set({ unnassignedCCAdministrative });
        }
    },
    getCustomers: async (req) => {
        const ccs = get().ccsService;
        const customerId = Object.values(ccs).flatMap((cc) => cc.customer_id);

        const customers = await req<CustomerRo[]>({
            sync    : true,
            method  : 'POST',
            url     : urlApiBuilder.customerGetMany(),
            payload : {
                body: {
                    ids: [...new Set<string>(customerId)]
                }
            }
        });
        if (customers.success && customers.response && customers.response.data && customers.response.data.ro) {
            const formattedCustomers = customers.response.data.ro.reduce((acc, customer) => {
                acc[customer.id] = customer;
                return acc;
            }, {} as Record<string, CustomerRo>);
            set({ customers: formattedCustomers });
        }
    },
    getLandfills: async (req) => {
        const collectsEmptying = Object.values(get().collects).filter(
            (c) => c.category === PlanningShiftStepCategory.EMPTYING
        ) as CollectEmptying[];
        const landfillsId = collectsEmptying.flatMap((c) => c.informations.landfill_id);

        const plannings = get().plannings;
        const landfillsIdFromPlannings = Object.values(plannings).flatMap((p) => {
            return p.shift.steps_emptying.flatMap((s) => s.landfill_id);
        });
        landfillsId.push(...landfillsIdFromPlannings);

        const landfills = await req<LandfillRo[]>({
            sync    : true,
            method  : 'POST',
            url     : urlApiBuilder.landfillGetMany(),
            payload : {
                body: {
                    ids: [...new Set<string>(landfillsId)]
                }
            }
        });

        if (landfills.success && landfills.response && landfills.response.data && landfills.response.data.ro) {
            const formattedLandfills = landfills.response.data.ro.reduce((acc, landfill) => {
                acc[landfill.id] = landfill;
                return acc;
            }, {} as Record<string, LandfillRo>);
            set({ landfills: formattedLandfills });
        }
    },
    getCollects: async (req) => {
        const plannings = get().plannings;
        const collects = Object.values(plannings).flatMap((p) => {
            const allSteps = [
                ...p.shift.steps_emptying,
                ...p.shift.steps_service,
                ...p.shift.steps_administrative,
                ...p.shift.steps_driver
            ]
                .map((s) => s.collect_id)
                .filter((c): c is string => !!c);
            return allSteps;
        });

        const collectsRo = await req<CollectRo[]>({
            sync    : true,
            method  : 'POST',
            url     : urlApiBuilder.collectGetMany(),
            payload : {
                body: {
                    ids: [...new Set<string>(collects)]
                }
            }
        });

        if (collectsRo.success && collectsRo.response && collectsRo.response.data && collectsRo.response.data.ro) {
            const formattedCollects = collectsRo.response.data.ro.reduce((acc, collect) => {
                acc[collect.id] = collect;
                return acc;
            }, {} as Record<string, CollectRo>);
            set({ collects: formattedCollects });
        }
    },
    fetchAll: async (data, req) => {
        const { redis_version_key, ...baseType } = data;
        await Promise.all([
            get().getPlannings(data, req),
            get().getCCService(baseType, req),
            get().getCCAdministrative(baseType, req),
            get().getProducts(req)
        ]);
        if (Object.keys(get().plannings).length > 0) {
            await Promise.all([
                get().getCollectors(req),
                get().getTrucks(req),
                get().getCustomers(req),
                get().getCollects(req)
            ]);
            await Promise.all([get().getLandfills(req)]);
        }

        console.log({
            plannings                   : get().plannings,
            collectors                  : get().collectors,
            trucks                      : get().trucks,
            ccsService                  : get().ccsService,
            ccsAdministrative           : get().ccsAdministrative,
            customers                   : get().customers,
            landfills                   : get().landfills,
            collects                    : get().collects,
            unnassignedCCService        : get().unnassignedCCService,
            unnassignedCCAdministrative : get().unnassignedCCAdministrative,
            products                    : get().products
        });
    },
    getUnnassignedCCService: () => {
        const ccs = Object.values(get().ccsService);
        const plannings = Object.values(get().plannings);

        if (!ccs) return [];

        const { STEP_SERVICES, STEP_SPLITTED_SERVICES } = plannings.reduce(
            (acc, cur) => {
                const assigned_service_ccs = cur.shift?.steps_service || [];
                assigned_service_ccs.forEach((step) => {
                    if (step.is_splitted) {
                        acc.STEP_SPLITTED_SERVICES.push(step);
                    } else {
                        acc.STEP_SERVICES.push(step);
                    }
                });
                return acc;
            },
            {
                STEP_SERVICES          : [] as PlanningShiftStepService[],
                STEP_SPLITTED_SERVICES : [] as PlanningShiftStepService[]
            }
        );

        const forbiddenStatus = [
            CC_STATUS.CANCELED,
            CC_STATUS.FINISHED,
            CC_STATUS.HAZARD,
            CC_STATUS.ORDER_TO_PAY,
            CC_STATUS.WAITING_FOR_APPROVAL,
            CC_STATUS.PLANNED
        ];

        const TO_PLAN_SERVICES = pipe(
            ccs,
            filter((cc): cc is CCServiceRo => cc.family !== CC_FAMILY.ADMINISTRATIVE),
            filter((cc) => !forbiddenStatus.includes(cc.status as any)),
            filter((cc) => cc.status !== CC_STATUS.SPLITTED),
            filter((cc) => {
                const found = STEP_SERVICES.find((step) => step.collect_config_id === cc.id);
                return !found;
            }),
            toArray
        );

        const TO_PLAN_SPLITTED_SERVICE = pipe(
            ccs,
            filter((cc): cc is CCServiceRo => cc.family !== CC_FAMILY.ADMINISTRATIVE),
            filter((cc) => !forbiddenStatus.includes(cc.status as any)),
            filter((cc) => cc.status === CC_STATUS.SPLITTED),
            flatMap((cc) => {
                return cc.splitted_informations.map((splitted_info) => {
                    return {
                        ...cc,
                        products     : splitted_info.products,
                        status       : splitted_info.status,
                        splitted_idx : splitted_info.idx,
                        is_splitted  : true
                    };
                });
            }),
            flatMap((cc) => {
                const foundInAssigned = STEP_SPLITTED_SERVICES.find(
                    (step) =>
                        step.collect_config_id === cc.id &&
                        step.is_splitted === true &&
                        step.splitted_idx === cc.splitted_idx
                );
                if (!foundInAssigned) {
                    return [cc];
                }
                return null;
            }),
            compact,
            toArray
        );

        set({ unnassignedCCService: [...TO_PLAN_SERVICES, ...TO_PLAN_SPLITTED_SERVICE] });
        return;
    },

    getProducts: async (req) => {
        const products = await req<ProductRo[]>({
            sync    : true,
            method  : 'GET',
            url     : urlApiBuilder.productGetAll(''),
            payload : {
                queryParams: {
                    no_limit    : true,
                    no_archived : true
                }
            }
        });

        if (products.success && products.response && products.response.data && products.response.data.ro) {
            const formattedProducts = products.response.data.ro.reduce((acc, product) => {
                acc[product.id] = product;
                return acc;
            }, {} as Record<string, ProductRo>);
            set({ products: formattedProducts });
        }
    }
}));
