import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { MenuItemCommandParams } from 'primereact/menuitem';
import { Tag, TagProps, TagSeverityType } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import { IconType } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dinero from 'dinero.js';

import { displayVolumeFromProducts, mapify } from '@bbng/util/misc';
import {
    CCRoFront,
    CCServiceRoFront,
    CC_FAMILY,
    CC_STATUS,
    CollectConfigQuery,
    CustomerRo,
    ECustomerCategory,
    EPlanningRegion,
    EPlanningType,
    ISODate,
    PrestaPrice,
    PrestaRo,
    ProductInCCOrCO
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { mapCollectConfigFamilyText, mapPlanningConfigRegion, mapTrashTypetext } from '../../common/enumMapper';
import { COLLECT_CONFIG_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import Button from '../../components/Button';
import Calendar from '../../components/Calendar';
import { collectConfigDescription } from '../../components/StepOverview';
import { Dialog } from '../../components/Dialog';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';
import CollectConfigCancelForm from '../../modules/collect-config-cancel-form';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { ColumnContainer, FiltersContainer } from './style';

const regionOptions = Object.values(EPlanningRegion).map((region) => ({
    name  : mapPlanningConfigRegion(region),
    value : region
}));

const TooltipEntry = styled.div<{ margin?: string }>`
    margin: ${(props) => props.margin || 0};
`;

const TypeOptions = [
    {
        type  : 'Big Bag',
        value : EPlanningType.BIG_BAG
    },
    {
        type  : 'Benne',
        value : EPlanningType.DUMPSTER
    }
];

const familyOptions = Object.values(CC_FAMILY)
    .filter((val) => val !== CC_FAMILY.DELIVERY_BIG_BAG)
    .map((family) => ({
        name  : mapCollectConfigFamilyText(family, true),
        value : family
    }));

type QueryParams = {
    type?: EPlanningType;
    region?: EPlanningRegion;
    presta_id?: string;
    customer_id?: string;
    family?: CC_FAMILY;
    date?: ISODate;
};

const GROUP_ROW_KEY = 'id';

export const PrestaCollectConfigsListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [region, setRegion] = React.useState<EPlanningRegion | undefined>(query.region);
    const [customer_id, setCustomerId] = React.useState<string | undefined>(query.customer_id);
    const [presta_id, setPrestaId] = React.useState<string | undefined>(query.presta_id);
    const [family, setFamily] = React.useState<CC_FAMILY | undefined>(query.family);
    const [date, setDate] = React.useState<ISODate | undefined>(query.date);
    const [type, setType] = React.useState<EPlanningType | undefined>(query.type);
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = React.useState(false);
    const [collectConfigToCancel, setCollectConfigToCancel] = React.useState<CCRoFront>();
    const [specificDay, setSpecificDay] = React.useState<boolean>(false);

    const [triggerPageRefresh, setTriggerPageRefresh] = useState<boolean>(false);
    useEffect(() => {
        setTriggerPageRefresh(false);
    }, [triggerPageRefresh]);

    const handleActionRowCancel = (e: MenuItemCommandParams) => {
        const index = e.item['rowInfo']['rowIndex'];
        const collectConfig = e.item['rowInfo']['props']['value'][index];
        setCollectConfigToCancel(collectConfig);
        setCancelDialogIsOpen(true);
    };

    return (
        <>
            <Listing<CollectConfigQuery, CCRoFront>
                title={`Collectes de prestataire en cours`}
                subtitle={`Toutes les collectes de prestataire en cours`}
                url={COLLECT_CONFIG_ORCHESTRATION_BASE_URL}
                endpoint="collect-configs"
                hideCreateButton
                hideBulkMenu
                pageUrl={urlBuilder.collectConfigBase()}
                pageUrlSuffix={(data) => (data.family === CC_FAMILY.ADMINISTRATIVE ? '?isInternal=true' : '')}
                displaySelectColumn={false}
                searchPlaceholder={`Rechercher par numéro de commande ou collecte`}
                disableArchiveAction
                triggerPageRefresh={triggerPageRefresh}
                actionColumnField={GROUP_ROW_KEY}
                groupRowsBy={GROUP_ROW_KEY}
                rowGroupMode="rowspan"
                scrollable={false}
                actionRows={[
                    {
                        label   : 'Annuler',
                        icon    : 'pi pi-exclamation-triangle',
                        command : (e) => handleActionRowCancel(e)
                    }
                ]}
                enrichData={async (data) => {
                    data.data.ro = await fetchDataRelation(data.data.ro ?? [], { customer_id: true, presta_id: true });
                    /**
                     * For splitted cc, create n fake cc (n = split number) to show each of them in the listing
                     */
                    data.data.ro =
                        data.data.ro?.reduce((acc, collectConfig) => {
                            if (collectConfig.status === CC_STATUS.SPLITTED) {
                                collectConfig.splitted_informations?.forEach((splittedCollectConfig) => {
                                    acc.push({
                                        ...collectConfig,
                                        products     : splittedCollectConfig.products,
                                        status       : splittedCollectConfig.status,
                                        splitted_idx : splittedCollectConfig.idx,
                                        is_splitted  : true
                                    });
                                });
                            } else {
                                acc.push(collectConfig);
                            }
                            return acc;
                        }, [] as CCRoFront[]) || [];
                    return data;
                }}
                queryParams={{
                    type,
                    region,
                    presta_id,
                    customer_id,
                    family,
                    no_delivery           : true,
                    not_finished          : true,
                    orderByCollectDateAsc : true,
                    min_date              : specificDay && date ? moment.utc(date).startOf('day').toISOString() : undefined,
                    max_date              : specificDay && date ? moment.utc(date).endOf('day').toISOString() : undefined,
                    presta                : 'only'
                }}
                leftHandedComponent={
                    <ColumnContainer>
                        <FiltersContainer>
                            <Dropdown
                                value={type}
                                onChange={(e) => setType(e.value)}
                                placeholder="Filtrer par type"
                                optionValue="value"
                                optionLabel="type"
                                showClear
                                options={TypeOptions}
                            />
                            <Dropdown
                                value={region}
                                onChange={(e) => setRegion(e.value)}
                                placeholder="Filtrer par région"
                                optionValue="value"
                                optionLabel="name"
                                showClear
                                options={regionOptions}
                            />
                            <Dropdown
                                value={family}
                                onChange={(e) => setFamily(e.value)}
                                placeholder="Filtrer par famille"
                                optionValue="value"
                                optionLabel="name"
                                showClear
                                options={familyOptions}
                            />
                            <RelationAutocomplete.Presta
                                placeholder="Filtrer par prestataire"
                                onSelect={(presta) => setPrestaId(presta?.id)}
                                onUnselect={() => setPrestaId(undefined)}
                            />
                            <RelationAutocomplete.Customer
                                placeholder="Filtrer par client"
                                onSelect={(customer) => setCustomerId(customer?.id)}
                                onUnselect={() => setCustomerId(undefined)}
                            />
                            <Button.Switch
                                id="specificDay"
                                value={specificDay}
                                result={(value) => {
                                    setSpecificDay(value);
                                    setDate(moment.utc().startOf('day').toISOString());
                                }}
                                label="Jour spécifique"
                                labelPosition="left"
                                readOnly={false}
                            />
                            <Calendar.DayMonthYear
                                id=""
                                value={date}
                                readOnly={specificDay === false}
                                required={false}
                                result={(date) => setDate(date)}
                            />
                        </FiltersContainer>
                    </ColumnContainer>
                }
                name="Collecte"
                pluralName="Collectes"
                headers={[
                    {
                        name      : 'Numéro collecte',
                        field     : GROUP_ROW_KEY as keyof CCRoFront,
                        component : (data: CCRoFront) => {
                            if (data.family === CC_FAMILY.ADMINISTRATIVE) {
                                return <span>N/A</span>;
                            }
                            return (
                                <CollectNumber>
                                    <div>
                                        <a href={urlBuilder.collectConfigView(data.id, false)}>{data.number}</a>(
                                        {data.order_number})
                                    </div>
                                    {data.is_splitted && <Tag value="Scindée" severity="warning" />}
                                </CollectNumber>
                            );
                        }
                    },
                    {
                        name      : 'Client',
                        field     : GROUP_ROW_KEY as keyof CCRoFront,
                        component : (data: CCServiceRoFront) => {
                            if (data.family === CC_FAMILY.ADMINISTRATIVE) return <span>N/A</span>;
                            const customer = data.customer_id[0] as CustomerRo;
                            const isIndividual = customer.category === ECustomerCategory.INDIVIDUAL;
                            const individualIcon = <i className="pi pi-user" />;
                            return (
                                <>
                                    <Tooltip
                                        position="top"
                                        target={`.customer-${data.id.replace(':', '')}`}
                                        content={isIndividual ? 'Particulier' : undefined}
                                    />
                                    <span className={`customer-${data.id.replace(':', '')}`}>
                                        {customer.name} {isIndividual && individualIcon}
                                    </span>
                                </>
                            );
                        }
                    },
                    {
                        name      : 'Prestataire',
                        field     : 'presta_id' as keyof CCRoFront,
                        component : (data: CCRoFront) => {
                            if (data.family === CC_FAMILY.ADMINISTRATIVE) return <span>N/A</span>;
                            if (data.presta_id?.length > 0) {
                                const presta = data.presta_id[0] as PrestaRo;

                                return (
                                    <a href={urlBuilder.prestaView(presta.id)} target="_blank" rel="noreferrer">
                                        {presta.name}
                                    </a>
                                );
                            } else {
                                return 'Endless';
                            }
                        }
                    },
                    {
                        name      : 'Status',
                        field     : 'status',
                        component : (data: CCRoFront) => <span>{mapStatus(data.status)}</span>
                    },
                    {
                        name      : 'Prestation',
                        field     : GROUP_ROW_KEY as keyof CCRoFront,
                        component : (data: CCRoFront) => <span>{mapCollectConfigFamilyText(data.family)}</span>
                    },
                    {
                        name      : `Volume`,
                        field     : 'products' as keyof CCRoFront,
                        component : (data: CCServiceRoFront) => {
                            if (data.family === CC_FAMILY.ADMINISTRATIVE) return <span>N/A</span>;
                            const volumeToCollect = displayVolumeFromProducts(data.products, data.family);
                            return (
                                <>
                                    <Tooltip position="top" target={`.volume-${data.id}`}>
                                        {data.products.map((p) => (
                                            <TooltipEntry margin="4px 0 0" key={p.id}>
                                                {p.quantity} x {p.name} - {p.volume_m3}m3
                                            </TooltipEntry>
                                        ))}
                                    </Tooltip>
                                    <StyledSpan className={`volume-${data.id}`}>
                                        {volumeToCollect ?? 'N/A'}
                                        <i className="pi pi-eye" />
                                    </StyledSpan>
                                </>
                            );
                        }
                    },
                    {
                        name      : 'Typologie',
                        field     : 'products' as keyof CCRoFront,
                        component : (data: CCRoFront) => {
                            if (data.family === CC_FAMILY.ADMINISTRATIVE) return <span>N/A</span>;
                            const trashType = Array.from(new Set(data.products.map((e) => e.trash_type)));

                            return <StyledSpan>{trashType.map((e) => mapTrashTypetext(e)).join(', ')}</StyledSpan>;
                        }
                    },
                    {
                        name      : 'Adresse du chantier',
                        field     : 'address',
                        component : (data: CCRoFront) => {
                            return <span>{data.address.formatted_name}</span>;
                        }
                    },
                    {
                        name      : 'Créneau',
                        field     : GROUP_ROW_KEY as keyof CCRoFront,
                        component : (data: CCRoFront) => (
                            <span>
                                {moment(data.from_date).format('DD/MM/YYYY')} {moment(data.from_date).format('HH:mm')} -{' '}
                                {moment(data.to_date).format('HH:mm')}
                            </span>
                        )
                    }
                ]}
            />
            {collectConfigToCancel && (
                <Dialog
                    visible={cancelDialogIsOpen}
                    onHide={() => setCancelDialogIsOpen(false)}
                    header={`Annulation - ${collectConfigToCancel && collectConfigDescription(collectConfigToCancel)}`}
                    draggable={false}
                    resizable={false}
                    blockScroll
                    contentStyle={{ overflow: 'scroll' }}
                >
                    <CollectConfigCancelForm
                        submitCallback={() => setTriggerPageRefresh(true)}
                        setModalIsOpen={setCancelDialogIsOpen}
                        dataId={collectConfigToCancel.id}
                        isAdministrative={collectConfigToCancel.family === CC_FAMILY.ADMINISTRATIVE}
                    />
                </Dialog>
            )}
        </>
    );
};

export const mapStatusToIcon = (status?: CC_STATUS): IconType<TagProps> => {
    switch (status) {
        case CC_STATUS.WAITING_FOR_APPROVAL:
        case CC_STATUS.ORDER_TO_PAY:
            return 'pi pi-cog';
        case CC_STATUS.FINISHED:
            return 'pi pi-check';
        case CC_STATUS.CANCELED:
            return 'pi pi-times';
        case CC_STATUS.PLANNED:
            return 'pi pi-check';
        case CC_STATUS.TO_PLAN:
            return 'pi pi-cog';
        case CC_STATUS.HAZARD:
            return 'pi pi-exclamation-circle';
        case CC_STATUS.TO_PREPARE:
            return 'pi pi-box';
        case CC_STATUS.SPLITTED:
            return 'pi pi-th-large';
        default:
            return '';
    }
};

export const mapStatusToSeverity = (status?: CC_STATUS): TagSeverityType => {
    switch (status) {
        case CC_STATUS.WAITING_FOR_APPROVAL:
        case CC_STATUS.ORDER_TO_PAY:
        case CC_STATUS.HAZARD:
            return 'warning';
        case CC_STATUS.FINISHED:
            return 'success';
        case CC_STATUS.CANCELED:
            return 'danger';
        case CC_STATUS.TO_PLAN:
        case CC_STATUS.TO_PREPARE:
            return 'info';
        case CC_STATUS.PLANNED:
            return 'primary';
        default:
            return 'info';
    }
};

export const mapRegionToSeverity = (status: EPlanningRegion): TagSeverityType => {
    switch (status) {
        case EPlanningRegion.PARIS:
            return 'primary';
        case EPlanningRegion.LYON:
            return 'success';
        case EPlanningRegion.MARSEILLE:
            return 'warning';
        default:
            return 'info';
    }
};

const mapStatus = (status: CC_STATUS): string => {
    switch (status) {
        case CC_STATUS.WAITING_FOR_APPROVAL:
            return 'En attente de validation';
        case CC_STATUS.ORDER_TO_PAY:
            return 'En attente de paiement';
        case CC_STATUS.FINISHED:
            return 'Terminée';
        case CC_STATUS.CANCELED:
            return 'Annulée';
        case CC_STATUS.HAZARD:
            return 'En alea';
        case CC_STATUS.PLANNED:
        case CC_STATUS.TO_PLAN:
            return 'En cours';
        case CC_STATUS.TO_PREPARE:
            return 'À préparer';
        case CC_STATUS.SPLITTED:
            return 'Scindée';
        default:
            return '';
    }
};

const StyledSpan = styled.span`
    display: flex;
    gap: 4px;
    align-items: center;
`;

const CollectNumber = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
`;
