import { Accordion } from 'primereact/accordion';
import { PickList } from 'primereact/picklist';
import { Tag } from 'primereact/tag';
import styled from 'styled-components';

import { Card } from '../../../../components/Card';
import { Dialog } from '../../../../components/Dialog';

export const ManualShiftContainer = styled.div`
    display: flex;
    justify-content: center;
    .p-button {
        width: 100%;
    }
`;

export const StyledDialog = styled(Dialog)`
    height: 95vh;
    width: 80vw;
    .p-dialog-content {
        padding-bottom: 8px;
    }
`;

export const DialogContainer = styled.div`
    display: flex;
    height: 100%;
    gap: 4px;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledPickList = styled(PickList)`
    height: 90%;
    .p-picklist-list-wrapper {
        height: 100%;
        overflow: scroll;
    }
    .p-picklist-header {
        padding: 8px 16px;
    }
    .p-picklist-list {
        max-height: 85%;
    }
`;

export const ButtonContainer = styled.div`
    height: auto;
    display: flex;
    justify-content: space-between;
`;

export const SourceHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const TargetHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const FiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
`;

export const TimeWithLabel = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const FilterHeader = styled.span`
    padding: 4px;
    width: 100%;
    font-size: 1em;
    display: flex;
    align-items: center;
`;

export const StyledAccordion = styled(Accordion)`
    & .p-accordion-header-link {
        padding: 4px 1.25em !important;
    }
`;

export const EmptyingModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 16px 0;
`;

export const EmptyingCardContainer = styled(Card)<{ color: string; borderColor?: string }>`
    background: ${({ color }) => color};
    display: flex;
    justify-content: center;
    font-size: 0.7em;
    color: black;
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : 'none')};
    .p-card-content {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
    }
    .p-card-body {
        padding: 8px;
        display: flex;
        align-items: center;
    }
`;

export const FlexRow = styled.div`
    display: flex;
    gap: 8px;
`;

export const DumpsterToTakeTitle = styled.span`
    font-size: 1.2em;
    text-align: center;
    margin: auto;
    width: 100%;
`;

export const ManualShiftButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledTruckTypeTag = styled(Tag)<{ backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const Header = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;
