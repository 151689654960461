import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'primeicons/primeicons.css';
import { addLocale } from 'primereact/api';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { StrictMode, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import 'react-phone-input-2/lib/material.css';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import App from './app/app';
import { config } from './app/common/env';
import './index.css';

addLocale('fr', {
    firstDayOfWeek : 1,
    dayNames       : ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    dayNamesShort  : ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
    dayNamesMin    : ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames     : [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
    ],
    monthNamesShort : ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
    today           : "Aujourd'hui",
    clear           : 'Effacer'
});

Sentry.init({
    dsn          : config.sentryDsn,
    integrations : [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            )
        })
    ],
    environment : config.nodeEnv,
    debug       : config.nodeEnv === 'dev',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.33
});

ReactDOM.render(
    <StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </StrictMode>,
    document.getElementById('root')
);
