import { Button } from 'primereact/button';
import styled from 'styled-components';

import { formatBytes } from '../../common/enumMapper';

const StyledPreviewContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledPreview = styled.div`
    width: 250px;
    height: 250px;
    margin-right: 10px;
`;
const StyledImgContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
const StyledDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`;

export const ImagePreview = ({ image, handleClear }: { image: File; handleClear: () => void }) => {
    const preview = URL.createObjectURL(image);
    return (
        <StyledPreviewContainer>
            <StyledPreview>
                <StyledImgContainer>
                    {image.type === 'application/pdf' ? (
                        <embed src={preview} width="100%" height="100%" />
                    ) : (
                        <img src={preview} alt={image.name} width="100%" height="100%" />
                    )}
                </StyledImgContainer>
            </StyledPreview>
            <StyledDetails>
                <div>{image.name}</div>
                <div>{formatBytes(image.size)}</div>
                <div>
                    <Button type="button" icon="pi pi-trash" onClick={handleClear} className="p-button-danger" />
                </div>
            </StyledDetails>
        </StyledPreviewContainer>
    );
};
