export const isObject = (value: unknown): boolean => {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

export const removeFrontRoFromObject = <T>(obj: Partial<Record<keyof T, any>>): Record<string, any> => {
    (<Array<keyof T>>Object.keys(obj)).forEach((key) => {
        // If key is an array
        if (Array.isArray(obj[key])) {
            // If array containing ids
            if (typeof key === 'string' && key.endsWith('_id')) {
                obj[key] = (obj[key] as { id: string }[])
                    .map((elem) => {
                        if (typeof elem === 'string') return elem;
                        return elem.id;
                    })
                    .filter(Boolean);
            } else {
                obj[key] = (obj[key] as Array<unknown>).map((elem) => {
                    return isObject(elem) ? removeFrontRoFromObject(elem as Record<string, unknown>) : elem;
                });
            }
        } else if (obj[key]?.id !== undefined && typeof key === 'string' && key.endsWith('_id')) {
            obj[key] = obj[key].id;
        }
        // If key is an object
        if (isObject(obj[key])) {
            // apply recursive on object and retrieve bucket state post computing
            obj[key] = removeFrontRoFromObject(obj[key] as Record<string, unknown>);
        }
    });

    return obj;
};
