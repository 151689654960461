import moment from 'moment';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import ReactJsonView from 'react-json-view';
import styled from 'styled-components';

import { cleanUndefinedKeys } from '@bbng/util/misc';
import { EditAction, HistoryRo } from '@bbng/util/types';

import { useRequest } from '../../hooks/StatelessRequest';

export const HistoryButton = ({ url }: { url: string }) => {
    const request = useRequest();
    const [isLoading, setIsLoading] = useState(true);
    const [history, setHistory] = React.useState<HistoryRo | undefined>();
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        request<HistoryRo>({
            method : 'GET',
            url    : url
        })
            .then((response) => {
                const hist: HistoryRo | undefined = response.response?.data.ro;

                if (hist !== undefined) {
                    hist.updated_by = hist.updated_by
                        .map<EditAction | undefined>((elem) => {
                            const payload: Record<string, any> = (elem.payload as any) ?? {};
                            Object.keys(payload ?? {}).forEach((key) => {
                                if (key.endsWith('_id')) {
                                    delete payload[key];
                                }
                            });
                            const cleanPayload = cleanUndefinedKeys(payload);

                            return Object.keys(cleanPayload).length > 0
                                ? {
                                      ...elem,
                                      payload: cleanPayload
                                  }
                                : undefined;
                        })
                        .filter((elem) => elem !== undefined) as Array<EditAction>;

                    hist.updated_by = hist.updated_by.reverse();
                }

                setHistory(hist);
            })
            .catch((err) => console.error(err))
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return (
            <Button
                className="p-button-rounded p-button-secondary"
                tooltip="Historique"
                tooltipOptions={{ position: 'left' }}
                icon="pi pi-spin pi-spinner"
                type="button"
                disabled
            />
        );
    }

    return (
        <>
            <Button
                className="p-button-rounded p-button-secondary"
                tooltip="Historique"
                tooltipOptions={{ position: 'left' }}
                icon="pi pi-history"
                type="button"
                onClick={() => {
                    setVisible(true);
                }}
            />
            {history && (
                <StyledDialog
                    resizable={false}
                    draggable={false}
                    visible={visible}
                    onHide={() => setVisible(false)}
                    blockScroll
                >
                    {history.created_by && (
                        <div className="container create-container">
                            <h4>Créé par</h4>
                            <p>
                                <strong>{history.created_by.split('|').slice(1).join('')}</strong>
                            </p>
                            <p>
                                à <strong>{moment(history.created_at).format('HH:mm')}</strong> Le{' '}
                                <strong>{moment(history.created_at).format('DD/MM/YYYY')}</strong>
                            </p>
                        </div>
                    )}
                    {history.updated_by && history.updated_by.length > 0 && (
                        <div className="container update-container">
                            <h4>Modifié par</h4>
                            <ul>
                                {history.updated_by.reverse().map((item: EditAction, index: number) => (
                                    <li key={item.by + index + item.at.toString()}>
                                        <p>
                                            <strong>{item.by}</strong> à{' '}
                                            <strong>{moment(item.at).format('HH:mm')}</strong> Le{' '}
                                            <strong>{moment(item.at).format('DD/MM/YYYY')}</strong>
                                        </p>
                                        <div>
                                            <p>Valeurs modifiée:</p>
                                            <div>
                                                <ReactJsonView src={item.payload as object} name={false} />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </StyledDialog>
            )}
        </>
    );
};

const StyledDialog = styled(Dialog)`
    margin-left: 60px;
    padding: 0 10px 0 20px;
    max-width: calc(100vw - 60px);
    .p-dialog {
        border-radius: 8px;
    }
    .p-dialog-content {
        border-radius: 0 0 8px 8px;
        padding: 0 50px;
        min-width: 60vw;
        max-height: 100%;
        overflow-y: auto;
    }
    .create-container h4 {
        margin-top: 0;
    }
`;
