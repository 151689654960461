import { useEffect, useState } from 'react';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import styled from 'styled-components';
import { Card } from '../../components/Card';
import { CustomerRo, CustomerExportRevalorisationDto } from '@bbng/util/types';
import { CalendarDayMonthYear } from '../../components/Calendar/Day&Month&Year';
import { Button } from 'primereact/button';
import { urlApiBuilder } from '../../common/urlBuilder';
import { axiosClient } from '../../common/axios';
import moment from 'moment';
import { defaultErrorToast } from '../../common/syncRequest';
import { ConstructionSiteRo } from '@bbng/util/types';
import { useRequest } from '../../hooks/StatelessRequest';
import { MultiSelect } from 'primereact/multiselect';
import { GifLoader } from '../../components/GifLoader';
import { Skeleton } from 'primereact/skeleton';
import { displayAddress } from '../Collect/helpers';

export const RevalorisationPage = () => {
    const [customer, setCustomer] = useState<CustomerRo>();
    const [loadingConstructionSite, setLoadingConstructionSite] = useState<boolean>(false);
    const [constructionSiteIds, setConstructionSiteIds] = useState<string[]>([]);
    const [constructionSites, setConstructionSites] = useState<ConstructionSiteRo[]>([]);
    const [selectedConstructionSites, setSelectedConstructionSites] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<string>('');
    const [toDate, setToDate] = useState<string>('');
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const fetchConstructionSites = async (customerId: string) => {
        setLoadingConstructionSite(true);

        const response = await bbngRequest<ConstructionSiteRo[]>({
            sync    : true,
            url     : urlApiBuilder.constructionSiteGetAll(),
            method  : 'GET',
            payload : {
                queryParams: {
                    customer_id : customerId,
                    no_limit    : true
                }
            }
        });

        if (response.response?.data.ro && response.response?.data.ro !== undefined) {
            setConstructionSites(
                response.response?.data.ro.map((cs) => ({
                    ...cs,
                    addressText: displayAddress(cs.address) // Add addressText to use for filtering
                }))
            );
        }
        setLoadingConstructionSite(false);
    };

    useEffect(() => {
        /**
         * Fetch construction sites when customer is selected
         */
        if (customer && customer.id) {
            fetchConstructionSites(customer.id);
        }
        /**
         * Reset construction sites when customer changes
         */
        setConstructionSites([]);
        setConstructionSiteIds([]);
        setSelectedConstructionSites([]);
    }, [customer]);

    const exportRevalorisation = async () => {
        try {
            const dto: CustomerExportRevalorisationDto = {
                customer_id           : customer?.id ? customer.id : '',
                construction_site_ids : Array.from(new Set(constructionSiteIds)),
                from_date             : fromDate ? new Date(fromDate).toISOString() : undefined,
                to_date               : toDate ? new Date(toDate).toISOString() : undefined
            };

            const response = await axiosClient.post(
                urlApiBuilder.customerRevalorisationExport(),
                {
                    ...dto
                },
                {
                    responseType : 'blob',
                    timeout      : 1000 * 60 * 15
                }
            );
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.data);
            link.download = `revalorisation-${moment().format('YYYY-MM-DD')}-${customer?.name}.xlsx`;
            link.click();
            link.remove();
        } catch (err) {
            defaultErrorToast('Erreur lors de la génération du rapport.', 400);
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <GifLoader />;
    } else {
        return (
            <>
                <StyledCard title="Revalorisation">
                    <CardLine>
                        <RelationAutocomplete.Customer
                            label="Client"
                            placeholder="Sélectionner un client"
                            onSelect={(val) => setCustomer(val)}
                            onUnselect={() => {
                                // This is used because rendering is triggering onUnselect
                                return;
                            }}
                        />
                        <div className="card flex justify-content-center" style={{ maxWidth: '70vw' }}>
                            {loadingConstructionSite ? (
                                <Skeleton height="40px" />
                            ) : (
                                <MultiSelect
                                    value={selectedConstructionSites}
                                    onChange={(e) => {
                                        setConstructionSiteIds(e.value.map((cs: CustomerRo) => cs.id));
                                        setSelectedConstructionSites(e.value);
                                    }}
                                    disabled={!customer || loadingConstructionSite}
                                    options={constructionSites}
                                    optionLabel="label"
                                    itemTemplate={(item: ConstructionSiteRo) => (
                                        <span>
                                            {item.label} - {displayAddress(item.address)}
                                        </span>
                                    )}
                                    display="chip"
                                    placeholder="Sélectionner un ou plusieurs chantiers"
                                    className="w-full md:w-20rem"
                                    filter
                                    emptyFilterMessage="Aucun chantier trouvé"
                                    filterBy="label,addressText"
                                />
                            )}
                        </div>
                    </CardLine>
                    <CardLine>
                        <CalendarDayMonthYear
                            id="fromDate"
                            label="Date de début"
                            value={String(fromDate)}
                            required={false}
                            readOnly={false}
                            errors={[]}
                            result={(val) => setFromDate(val)}
                        />
                        <CalendarDayMonthYear
                            id="toDate"
                            label="Date de fin"
                            value={toDate}
                            required={false}
                            readOnly={false}
                            errors={[]}
                            result={(val) => setToDate(val)}
                        />
                    </CardLine>
                    <CardLine>
                        {customer && customer.id && (
                            <Button
                                id="revalorisation"
                                type="button"
                                disabled={!customer || !customer.id || constructionSiteIds.length === 0}
                                onClick={() => {
                                    setLoading(true);
                                    exportRevalorisation();
                                }}
                                label="Télécharger le rapport"
                            />
                        )}
                    </CardLine>
                </StyledCard>
            </>
        );
    }
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
