import { AdminRo } from '@bbng/util/types';

import { ADMIN_ORCHESTRATION_BASE_URL } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';

export const AdminsListing: React.FC = (): JSX.Element => {
    return (
        <Listing<any, AdminRo>
            url={ADMIN_ORCHESTRATION_BASE_URL}
            hideCreateButton
            displaySelectColumn={false}
            displayActionColumn={false}
            showSearch={false}
            hideBulkMenu
            endpoint="admins"
            name="Administrateur"
            pluralName="Administrateurs"
            headers={[
                {
                    name      : 'Nom complet',
                    field     : 'fullname',
                    component : (data: AdminRo) => <p>{data.fullname}</p>
                },
                {
                    name      : 'Email',
                    field     : 'email',
                    component : (data: AdminRo) => <p>{data.email ?? 'Non renseigné'}</p>
                }
            ]}
        />
    );
};
