import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {
    CCRo,
    CCServiceRoFront,
    CC_FAMILY,
    CollectorRo,
    ECollectCharacteristic,
    EPlanningType,
    PRODUCT_DUMPSTER_TYPE
} from '@bbng/util/types';

import { CardType, GlobalConfigCCRo, useGlobalConfigContext } from './provider';
import { Card } from './card';
import {
    mapBgColorFromVolume,
    mapCollectConfigFamilyText,
    mapDumpsterTypeToHexa,
    mapTruckType
} from '../../../common/enumMapper';
import { displayIconIfRotation } from '../../planning/shifts/helpers';
import { StyledTruckTypeTag } from './style';
import { useFetchCollector } from '../../../hooks/FetchCollector';
import { Skeleton } from 'primereact/skeleton';

export type CardCCDragItem = {
    bucketIndex: number;
    cc: CCRo;
    planningId: string;
};
type CardCcProps = {
    item: GlobalConfigCCRo;
    columnId: string;
    columnIndex: number;
    disableOverAnimation?: boolean;
    disableDropOver?: boolean;
};
export const CardCC: React.FC<CardCcProps> = ({
    item,
    columnId,
    columnIndex,
    disableOverAnimation,
    disableDropOver
}) => {
    const { cc } = item;
    const { customers, type, plannings, collectors, dumpsterTypes } = useGlobalConfigContext();

    const ItemToEvacuate = React.useMemo(() => {
        if (!cc) return null;

        if (cc.family === CC_FAMILY.ADMINISTRATIVE) return null;

        if (!cc.is_splitted) {
            return (
                <Items>
                    {cc.products.map((line) => {
                        return (
                            <span key={line.id} style={{ fontSize: '1rem', margin: 0, textAlign: 'center' }}>
                                {line.volume_m3} x {line.quantity} {displayIconIfRotation(line, cc as CCRo)}
                            </span>
                        );
                    })}
                </Items>
            );
        } else if (cc?.is_splitted === true && cc.splitted_idx !== undefined) {
            return cc.splitted_informations[cc.splitted_idx].products.map((line) => {
                return (
                    <p key={line.id} style={{ fontSize: '1rem', margin: 0, textAlign: 'center' }}>
                        {line.volume_m3} x {line.quantity} {displayIconIfRotation(line, cc as CCRo)}
                    </p>
                );
            });
        }
        return null;
    }, [cc]);

    const bgColors = React.useMemo((): { firstColor: string; secondColor?: string } => {
        if (!cc) return { firstColor: '#ffffff' };

        switch (cc.family) {
            case CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT:
            case CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT:
            case CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL:
                return {
                    firstColor: mapBgColorFromVolume(
                        cc.products.find((product) => product.family === cc.family)?.volume_m3 || 0
                    )
                };
            case CC_FAMILY.COLLECT_DUMPSTER_ROTATION: {
                const depositVolume =
                    cc.products.find((product) => product.family === CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT)?.volume_m3 ||
                    0;
                const retrievalVolume =
                    cc.products.find((product) => product.family === CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL)?.volume_m3 ||
                    0;
                return {
                    firstColor  : mapBgColorFromVolume(depositVolume),
                    secondColor : mapBgColorFromVolume(retrievalVolume)
                };
            }
            default:
                return {
                    firstColor: '#ffffff'
                };
        }
    }, [cc]);

    const { fetchCollector, isLoading: collectorIsLoading } = useFetchCollector();
    const [collector, setCollector] = useState<CollectorRo | undefined>();
    const fetchAndSetCollector = useCallback(async (collectorId) => {
        const fetchedCollector = await fetchCollector(collectorId);
        setCollector(fetchedCollector);
    }, []);
    useEffect(() => {
        if (!collector) {
            const existingCollector = collectors[cc.collector_id[0] as string];
            if (existingCollector) setCollector(existingCollector);
            if (cc.collector_id[0]) fetchAndSetCollector(cc.collector_id[0]);
        }
    }, [plannings]);

    const customer = React.useMemo(() => {
        if (cc.family === CC_FAMILY.ADMINISTRATIVE) return;
        return customers[cc.customer_id[0] as string];
    }, [customers, cc]);

    const typesToDisplay: ECollectCharacteristic[] = React.useMemo(() => {
        if (cc.family === CC_FAMILY.ADMINISTRATIVE) return [];
        if (type === EPlanningType.DUMPSTER) {
            return cc.characteristics.filter((tp) =>
                [
                    ECollectCharacteristic.DUMPSTER_AMPLIROLL,
                    ECollectCharacteristic.DUMPSTER_CHAIN,
                    ECollectCharacteristic.NARROW_STREET
                ].includes(tp)
            );
        } else {
            return cc.characteristics.filter((tp) => tp === ECollectCharacteristic.NARROW_STREET);
        }
    }, [type, cc]);

    const isDisplayable = useMemo(() => {
        if (type === EPlanningType.BIG_BAG) return true;
        if (cc.family === CC_FAMILY.ADMINISTRATIVE) return true;
        return cc.characteristics.some((type) => dumpsterTypes.includes(type as PRODUCT_DUMPSTER_TYPE));
    }, [type, dumpsterTypes]);

    if (!cc || !isDisplayable) return null;

    return (
        <Card
            type={CardType.CC}
            item={{ columnId, columnIndex, type: item.category, extra: item, id: item.gc_id }}
            disableDropOver={disableDropOver}
            disabledOverEffect={disableOverAnimation}
        >
            <StyledCard {...bgColors}>
                <Header>
                    <HeaderLeft className="card-heading">
                        <i>
                            {cc.family === 'ADMINISTRATIVE'
                                ? `${moment().startOf('day').add(cc.execution_time_minutes, 'minutes').format('HH:mm')}`
                                : `${cc.number}`}{' '}
                            - {mapCollectConfigFamilyText(cc.family)}
                        </i>
                        {(cc as CCServiceRoFront)?.is_splitted && (
                            <>
                                {' '}
                                - <i className="pi pi-fw pi-clone" />
                            </>
                        )}
                        <div>
                            <b>
                                {moment(cc.from_date).format('HH:mm')} - {moment(cc.to_date).format('HH:mm')}
                            </b>
                        </div>
                    </HeaderLeft>
                    <HeaderRight>
                        {typesToDisplay.map((type) => (
                            <StyledTruckTypeTag
                                key={type}
                                value={mapTruckType(type as any, true)}
                                backgroundColor={
                                    type === ECollectCharacteristic.NARROW_STREET
                                        ? '#FFA500'
                                        : mapDumpsterTypeToHexa(type as PRODUCT_DUMPSTER_TYPE)
                                }
                            />
                        ))}
                    </HeaderRight>
                </Header>
                <div className="card-collect-info">
                    <p>{cc.address.formatted_name}</p>
                </div>
                <Footer>
                    {ItemToEvacuate}
                    {collector ? (
                        <p>{collector.fullname ?? ''}</p>
                    ) : (
                        collectorIsLoading && <Skeleton height="10px" width="40px" />
                    )}
                    {customer && <CustomerInfo> {customer?.name ?? ''}</CustomerInfo>}
                </Footer>
            </StyledCard>
        </Card>
    );
};

const StyledCard = styled.div<{ firstColor?: string; secondColor?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2px;

    padding: 6px;
    height: 110px;
    width: 200px;
    background-color: ${({ firstColor }) => firstColor ?? '#ffffff'};
    background-image: ${({ firstColor, secondColor }) =>
        firstColor && secondColor ? `-webkit-linear-gradient(-67deg, ${firstColor} 50%, ${secondColor} 50%)` : 'none'};
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    text-align: center;
    font-size: 0.7rem;
    p {
        margin: 0;
    }
    .card-heading,
    .card-customer-info {
        font-weight: 300;
        color: #606060;
    }
    .card-collect-info {
        display: flex;
        flex-direction: column;
    }
`;

const Items = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    & span {
        display: flex;
        align-items: center;
        gap: 4px;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    align-items: center;
`;

const HeaderLeft = styled.div`
    max-width: 60%;
    flex-grow: 1;
`;

const HeaderRight = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 40%;
    gap: 2px;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
`;

const CustomerInfo = styled.div`
    font-weight: 300;
    color: #606060;
`;
