import axios, { Method } from 'axios';
import { StatusCodes } from 'http-status-codes';

import { IBbngResponse } from '@bbng/util/types';

import { toast } from '../components/Toast';
import { axiosClient } from './axios';

type SyncRequestProps<Payload> = {
    method: Method;
    url: string;
    payload?: Payload;
    headers?: Record<string, string>;
    params?: Record<string, string | number | boolean>;
    onError?: (message: string, status: number) => void;
    onSuccess?: (data: any) => void;
};

type RequestResponse<Response> = {
    success: boolean;
    response?: IBbngResponse<Response>;
    error?: {
        message?: string;
        status: number;
        [k: string]: any;
    };
};

export async function syncRequest<Response = any, Payload = any>(
    props: SyncRequestProps<Payload>
): Promise<RequestResponse<Response>> {
    props.onError ??= () => void 0;
    props.onSuccess ??= () => void 0;

    try {
        const response = await axiosClient.request<IBbngResponse<Response>>({
            method  : props.method,
            url     : props.url,
            data    : props.payload,
            headers : {
                ...(props.headers ?? {})
            },
            params: {
                sync: true,
                ...(props.params ?? {})
            },
            validateStatus: (status: number) =>
                status !== 401 && ((status >= 200 && status < 300) || (status >= 400 && status <= 500))
        });

        if (response.data?.success) {
            props.onSuccess(response.data);
        } else {
            props.onError(response.data?.data?.message ?? 'Error', response.data?.data?.status_code);
        }

        return {
            success  : response.data.success,
            response : response.data
        };
    } catch (err) {
        console.error(err);

        if (axios.isAxiosError(err)) {
            const message = err.response?.data.message ?? 'Une erreur est survenue';
            const status = err.response?.status ?? 500;

            props.onError(message, status);
        }
    }

    const message = 'Une erreur est survenue';
    const status = 500;

    props.onError(message, status);

    return {
        success : false,
        error   : {
            status,
            message
        }
    };
}

export const defaultSuccessToast = () => {
    toast({
        life     : 10_000,
        severity : 'success',
        summary  : 'Succès de la requête',
        detail   : 'Requête effectuée avec succès.'
    });
};

export const defaultErrorToast = (message?: string, code?: StatusCodes) => {
    toast({
        life     : 10_000,
        severity : 'error',
        summary  : 'Échec de la requête',
        detail   :
            message ?? code ? `Une Erreur code ${code} est survenue.` : 'Une erreur est survenue lors de la requête.'
    });
};
