import styled from 'styled-components';

export const PageContainer = styled.div`
    background-image: url('../../assets/images/ndlss-1.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
    height: 100vh;
    display: flex;
`;

export const LoginContainer = styled.div`
    flex: 1;
    display: grid;
    place-items: center;
`;

export const LoginCard = styled.div`
    width: fit-content;
    max-width: 100%;
    padding: 25px 50px;
    margin: 20px;
    backdrop-filter: blur(5px) saturate(200%);
    -webkit-backdrop-filter: blur(5px) saturate(200%);
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`;

export const BlockContainer = styled.div`
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 30px;
    #email-error {
        color: red;
    }
`;

export const BlankContainer = styled.div`
    @media (max-width: 1100px) {
        display: none;
    }
    flex: 1;
`;

export const Logo = styled.img`
    width: 200px;
    margin-bottom: 50px;
`;
