import { Button } from 'primereact/button';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { CustomerRo, FrontRo, OrderEditDto, OrderRo } from '@bbng/util/types';

import { TDocument, mapFrontDocumentToDocumentDto, uploadFiles } from '../../common/form';
import { urlApiBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import { toast } from '../../components/Toast';
import { useRequest } from '../../hooks/StatelessRequest';
import Order from '../../modules/order';
import { OrderDocumentsState } from './Documents';
import { Tag } from 'primereact/tag';

export type OrderSheetEditProps = {
    customer?: CustomerRo;
    documentState?: OrderDocumentsState;
    orderId?: string;
    setModalState: (state: boolean) => void;
    refetch: (id: string) => void;
    hasInvoice?: boolean;
};

export const OrderSheetEdit: React.FC<OrderSheetEditProps> = ({
    customer,
    documentState,
    orderId,
    setModalState,
    refetch,
    hasInvoice
}: OrderSheetEditProps) => {
    const [val, setVal] = useState<OrderDocumentsState>(
        documentState || {
            documents          : [],
            order_sheet_number : ''
        }
    );
    const [loading, setLoading] = useState(false);
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const handleSubmit = useCallback(async () => {
        if ((documentState?.documents || []).length > 0 && val.documents.length === 0) {
            toast({
                severity : 'error',
                summary  : 'Formulaire invalide',
                detail   : 'Aucun bon de commande renseigné.'
            });
            return;
        }

        const docsToUpload: TDocument[] = [];
        /**
         * Docs to upload are only the local ones
         */
        if (val.documents.length > 0) {
            val.documents.forEach((doc) => {
                if (doc.type === 'local') {
                    docsToUpload.push(doc);
                }
            });
        }
        const dto = {
            order_sheet_number : val.order_sheet_number,
            documents          :
                docsToUpload.length > 0
                    ? (await uploadFiles(docsToUpload, 'order'))?.map((doc) => mapFrontDocumentToDocumentDto(doc))
                    : undefined
        } as OrderEditDto;

        setLoading(true);
        await bbngRequest<FrontRo<OrderRo>>({
            method  : 'PATCH',
            url     : urlApiBuilder.orderPatch(orderId as string),
            options : { toastifySuccess: false },
            payload : {
                body: dto
            }
        });
        setLoading(false);
        setModalState(false);
        refetch(orderId as string);
    }, [customer, documentState, val, orderId]);

    if (loading) {
        return <PageLoader loading actionType="create" />;
    }

    return (
        <Container>
            <Order.Documents
                readOnly={false}
                value={documentState}
                id="documents"
                result={(value) => {
                    setVal(value);
                }}
                displayError={true}
                customer={customer}
                withContainer={false}
            />
            {hasInvoice && (
                <Tag severity="warning" icon="pi pi-exclamation-triangle">
                    Attention, la facture ayant déjà été générée, le numéro de BDC ne sera pas mis à jour sur celle-ci.
                </Tag>
            )}
            <Button label="Sauvegarder" onClick={handleSubmit} />
        </Container>
    );
};

const Container = styled.div`
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
