import styled from 'styled-components';

const StyledPlaceholder = styled.div`
    width: 250px;
    height: 250px;
    background-color: #fafafa;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Placeholder = () => {
    return <StyledPlaceholder>Aucun fichier à afficher</StyledPlaceholder>;
};
