import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import { PrestaRoFront } from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { ActionsFormContainer } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import SnackBar from '../../components/SnackBar';
import { useRequest } from '../../hooks/StatelessRequest';
import Presta from '../../modules/presta';
import { Address } from '../../modules/common/Address';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    PrestaFormErrorState,
    PrestaFormState,
    PrestaModulesErrorStates,
    PrestaModulesStates,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiCreateData,
    mapStateToApiEditData
} from './helpers';
import { PageForm, PageFormLine } from './style';
import equal from 'fast-deep-equal/react';

type PrestaFormProps = {
    edit?: boolean;
    readOnly?: boolean;
};

const PrestaForm: React.FC<PrestaFormProps> = ({ edit = false, readOnly = false }: PrestaFormProps): JSX.Element => {
    const { id: paramsId } = useParams();
    const navigate = useNavigate();
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [apiState, setApiState] = React.useState<{ form: PrestaFormState; db: PrestaRoFront }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>(
        edit || readOnly ? 'read' : undefined
    );
    const [loading, setLoading] = useState<boolean>(edit || readOnly);

    const [err, setErr] = useState<PrestaFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<PrestaFormState>['onSubmit']>(
        async (data: PrestaFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            const method = edit ? 'PATCH' : 'POST';
            const url = edit ? urlApiBuilder.prestaEdit(dataId) : urlApiBuilder.prestaCreate();
            const body = edit
                ? mapStateToApiEditData(data, apiState!.form, apiState!.db)
                : mapStateToApiCreateData(data);
            setLoading(true);
            setOnRequestType('create');
            const response = await bbngRequest<PrestaRoFront>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);
            setOnRequestType(undefined);
            if (response.success) {
                navigate(urlBuilder.prestaList());
            }
        },
        [err, apiState, bbngRequest]
    );

    const formik = useFormik<PrestaFormState>({
        initialValues : initialState,
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (value: PrestaModulesStates, errors: PrestaModulesErrorStates | string[] | null, id: string): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response = await bbngRequest<PrestaRoFront>({
            method  : 'GET',
            url     : urlApiBuilder.prestaGet(dataId),
            options : { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            const ro: PrestaRoFront = await fetchDataRelation(response.response?.data.ro, { product_id: true });
            const formikState: PrestaFormState = mapApiDataToState(ro);

            setApiState({ form: formikState, db: ro });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (edit || readOnly) {
            fetchData();
        }
    }, []);

    const handleArchive = React.useCallback(() => {
        setLoading(true);
        setOnRequestType(apiState?.db.archived ? 'unarchive' : 'delete');

        bbngRequest({
            method  : 'PATCH',
            url     : urlApiBuilder.prestaArchive(dataId),
            options : { toastifySuccess: false },
            payload : {
                body: {
                    archived: !apiState?.db.archived
                }
            }
        })
            .then((response) => {
                if (response.success) {
                    navigate(urlBuilder.prestaList());
                }
            })
            .finally(() => {
                setLoading(false);
                setOnRequestType(undefined);
            });
    }, [bbngRequest, setLoading, setOnRequestType, apiState]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (errorCode) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.prestaList()} />
        ) : (
            <Error500 />
        );
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <h1>Prestataire{edit || readOnly ? `: ${apiState?.form?.general.name}` : ''}</h1>
            <ActionsFormContainer
                archived={apiState?.db.archived || false}
                edit={edit}
                readOnly={readOnly}
                // disableSaveOnEdit={JSON.stringify(apiState?.form) === JSON.stringify(formik.values)}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                handleArchiveOnView={handleArchive}
                editPageUrl={urlBuilder.prestaEdit(dataId)}
                historyUrl={urlApiBuilder.prestaHistory(dataId)}
            />
            <PageFormLine>
                <Presta.GeneralInfo
                    id="general"
                    result={handleChange}
                    value={formik.values.general}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            <PageFormLine>
                <Address
                    title="Siège social"
                    displayError={formik.submitCount > 0}
                    readOnly={readOnly}
                    id="headquarter"
                    result={handleChange}
                    value={formik.values.headquarter}
                />
            </PageFormLine>
            <PageFormLine>
                <Presta.PriceList
                    id="priceList"
                    result={handleChange}
                    value={formik.values.priceList}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            {readOnly === false && (
                <Button
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        // (JSON.stringify(apiState?.form) === JSON.stringify(formik.values) && edit)
                        (equal(apiState?.form, formik.values) && edit)
                    }
                />
            )}
            {edit === true && (
                <SnackBar
                    // show={JSON.stringify(apiState?.form) !== JSON.stringify(formik.values)}
                    show={!equal(apiState?.form, formik.values)}
                    content="Vous avez des modifications non enregistrées"
                />
            )}
        </PageForm>
    );
};

export default PrestaForm;
