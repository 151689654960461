import { AddressRo, EPlanningRegion } from '@bbng/util/types';

const PARIS_COORD = { lat: 48.856614, lng: 2.3522219 };
const LYON_COORD = { lat: 45.759724, lng: 4.842223 };
const MARSEILLE_COORD = { lat: 43.296482, lng: 5.36978 };

/**
 * Get region based on the address.
 * @param {AddressRo} address

* @returns {EPlanningRegion}}
 */
export const getRegionFromAddress = (address: AddressRo): EPlanningRegion => {
    const { latitude, longitude } = address.coordinates;
    const P = _distance(PARIS_COORD.lat, PARIS_COORD.lng, latitude, longitude);
    const L = _distance(LYON_COORD.lat, LYON_COORD.lng, latitude, longitude);
    const M = _distance(MARSEILLE_COORD.lat, MARSEILLE_COORD.lng, latitude, longitude);

    const min = Math.min(P, L, M);
    if (min === P) return EPlanningRegion.PARIS;
    if (min === L) return EPlanningRegion.LYON;
    if (min === M) return EPlanningRegion.MARSEILLE;
    return EPlanningRegion.PARIS;
};

const _distance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const p = 0.017453292519943295; // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km earth radius
};
