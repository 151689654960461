import React from 'react';
import styled from 'styled-components';

import { CardErrors } from '@bbng/util/types';

import { Card } from '../../components/Card';
import InputList from '../InputList';
import { CalendarRange } from '../InputList/calendar-range';

export type LandfillCenterExceptionalClosuresProps = {
    readOnly?: boolean;
    value?: LandfillCenterExceptionalClosuresState;
    id: string;
    result: (
        value: LandfillCenterExceptionalClosuresState,
        errors: null | string[] | CardErrors<LandfillCenterExceptionalClosuresState>,
        id: string
    ) => void;
    displayError?: boolean;
};

const FullLineInput = styled.div`
    width: 100%;
`;

export type LandfillCenterExceptionalClosuresState = {
    calendarRangeList: Array<CalendarRange>;
};
export type LandfillCenterExceptionalClosuresErrorState = CardErrors<LandfillCenterExceptionalClosuresState>;

export const initialState: LandfillCenterExceptionalClosuresState = {
    calendarRangeList: []
};
export const initialErrorState: LandfillCenterExceptionalClosuresErrorState = Object.fromEntries(
    Object.keys(initialState).map((k) => [k, null])
) as LandfillCenterExceptionalClosuresErrorState;

export const LandfillCenterExceptionalClosures: React.FC<LandfillCenterExceptionalClosuresProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: LandfillCenterExceptionalClosuresProps) => {
    const [val, setVal] = React.useState<LandfillCenterExceptionalClosuresState>(value);
    const [err, setErr] = React.useState<LandfillCenterExceptionalClosuresErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <Card title="Fermetures exceptionnelles">
            <FullLineInput>
                <InputList.CalendarRange
                    title="Période"
                    onChange={(value) => handleChange(value, null, 'calendarRangeList')}
                    value={val.calendarRangeList}
                    readOnly={readOnly}
                />
            </FullLineInput>
        </Card>
    );
};
