/* eslint-disable no-script-url */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Card } from 'primereact/card';
import React from 'react';
import styled from 'styled-components';

import Draw500 from '../../../assets/images/error-500.jpg';

export type Error500Props = {
    // empty
};

const Error500: React.FC<Error500Props> = (props: Error500Props): JSX.Element => {
    return (
        <CenteredPage>
            <StyledCard>
                <div>
                    <img src={Draw500} width={500} alt="Error 500" />
                </div>
                <h2>Aie, une erreur est survenue.</h2>
                <h3>
                    Essayez de <a href={window.location.href}>réactualiser</a> la page.
                </h3>
                <h4>Si le probleme persiste, allez voir l'équipe technique 😁</h4>
            </StyledCard>
        </CenteredPage>
    );
};

const CenteredPage = styled.div`
    display: grid;
    place-items: center;
    width: 100%;
    height: 100vh;
`;

const StyledCard = styled(Card)`
    & .p-card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export default Error500;
