import { Button } from 'primereact/button';
import styled from 'styled-components';

export const PageForm = styled.form`
    margin: 16px;
    display: flex;
    flex-direction: column;
    & > * {
        flex: 1;
    }
    gap: 16px;
    & h1 {
        margin: 0;
    }
`;

export const PageFormLine = styled.div`
    & > * {
        flex: 1;
    }
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    @media only screen and (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const SubmitButton = styled(Button)`
    margin-bottom: 48px;
`;
