import Joi from 'joi';

import { DiscountRo, EDiscountType, EDiscountUnit } from '.';
import { ETrashType } from '../misc';
import { generateRelationsSchema } from '../dto';

export const DiscountCreateDtoSchema = Joi.object({
    value : Joi.number().required(),
    unit  : Joi.string()
        .valid(...Object.values(EDiscountUnit))
        .required(),
    type: Joi.string()
        .valid(...Object.values(EDiscountType))
        .required(),
    trash_type: Joi.when('type', {
        is   : Joi.string().valid(EDiscountType.DUMPSTER, EDiscountType.BIG_BAG),
        then : Joi.array()
            .items(Joi.string().valid(...Object.values(ETrashType)))
            .min(1)
            .required(),
        otherwise: Joi.forbidden()
    }),
    min_volume: Joi.number()
        .when('type', {
            is        : EDiscountType.DELIVERY,
            then      : Joi.number().min(0.25).required(),
            otherwise : Joi.number().min(0).required()
        })
        .required(),
    max_volume           : Joi.number().positive().required(),
    customer_id          : Joi.array().items(Joi.string()).length(1).required(),
    construction_site_id : Joi.array().items(Joi.string()).max(1),
    zone_id              : Joi.array().items(Joi.string()),

    start_date : Joi.date().iso().required(),
    end_date   : Joi.date()
        .iso()
        .when('start_date', {
            is        : Joi.exist(),
            then      : Joi.date().iso().min(Joi.ref('start_date')),
            otherwise : Joi.forbidden()
        })
});

export const DiscountEditDtoSchema = Joi.object({
    value      : Joi.number(),
    unit       : Joi.string().valid(...Object.values(EDiscountUnit)),
    type       : Joi.string().valid(...Object.values(EDiscountType)),
    trash_type : Joi.array().items(...Object.values(ETrashType)),

    min_volume : Joi.number().min(0),
    max_volume : Joi.number().min(1),

    start_date : Joi.date().iso(),
    end_date   : Joi.date()
        .iso()
        .when('start_date', {
            is   : Joi.exist(),
            then : Joi.date().iso().min(Joi.ref('start_date'))
        })
}).concat(generateRelationsSchema<DiscountRo>('construction_site_id', 'zone_id'));
