import { Button } from 'primereact/button';
import { Column, ColumnBodyOptions, ColumnBodyType } from 'primereact/column';
import {
    DataTable as DataTablePR,
    DataTableSelectionChangeParams,
    DataTableProps as PRDataTableProps
} from 'primereact/datatable';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { MenuItem, MenuItemCommandParams } from 'primereact/menuitem';
import { PaginatorTemplate } from 'primereact/paginator';
import { Paginator } from 'primereact/paginator';
import { Skeleton } from 'primereact/skeleton';
import { Tag } from 'primereact/tag';
import React from 'react';

import { ObjectKeys } from '@bbng/util/types';

import { SmallButton } from './style';
import { useWindowSize } from '../../hooks/WindowSize';

export interface DataTableProps<T> extends PRDataTableProps {
    isLoading: boolean;
    data: T[];
    headers: { name: string; field: ObjectKeys<T>; component?: ColumnBodyType; width?: number; sortable?: boolean }[];
    actionRows: MenuItem[];
    selectedRows: (e: DataTableSelectionChangeParams) => void;
    rows: number;
    onClickPrevPage?: () => void;
    onClickNextPage?: () => void;
    onClickPerPageDropdown: (e: DropdownChangeParams) => void;
    totalRecords: number;
    page: number;
    actionRowArchive: (e: MenuItemCommandParams) => void;
    actionRowEdit: (e: MenuItemCommandParams) => void;
    displayActionColumn?: boolean;
    displaySelectColumn?: boolean;
    displayArchiveColumn?: boolean;
    displayPaginator?: boolean;
    disableArchiveAction?: boolean;
    disableEditAction?: boolean;
    actionColumnField?: string;
    breakpoint?: string;
}

function DataTable<T>({
    isLoading,
    data,
    headers,
    actionRows,
    selectedRows,
    rows,
    onClickPrevPage,
    onClickNextPage,
    onClickPerPageDropdown,
    totalRecords,
    page,
    actionRowArchive,
    actionRowEdit,
    displayActionColumn = true,
    displaySelectColumn = true,
    displayArchiveColumn = true,
    displayPaginator: showPages = true,
    disableArchiveAction = false,
    disableEditAction = false,
    actionColumnField,
    breakpoint = '960px',
    ...props
}: DataTableProps<T>) {
    const menu = React.useRef<Menu[]>([]);
    const [selected, setSelected] = React.useState(null);

    const actionRow = (i: ColumnBodyOptions): JSX.Element => {
        const item = data[i.rowIndex] as { archived: boolean };
        const actionRowsMenu: MenuItem[] = [
            { label: 'Modifier', icon: 'pi pi-pencil', command: (e) => actionRowEdit(e), disabled: disableEditAction },
            {
                label    : item?.archived ? 'Réactiver' : 'Archiver',
                icon     : 'pi pi-folder',
                command  : (e) => actionRowArchive(e),
                disabled : disableArchiveAction
            }
        ];
        if (actionRows.length > 0) {
            actionRowsMenu.unshift(...actionRows);
        }

        return (
            <>
                <SmallButton
                    className="p-button-text"
                    type="button"
                    icon="pi pi-cog"
                    onClick={(e) => menu.current[i.rowIndex].toggle(e)}
                />
                <Menu
                    model={actionRowsMenu.map((el) => {
                        return {
                            ...el,
                            rowInfo: i
                        };
                    })}
                    ref={(el) => {
                        if (el !== null) menu.current[i.rowIndex] = el;
                    }}
                    popup
                />
            </>
        );
    };

    const windowSize = useWindowSize();

    const breakpointValue = React.useMemo(() => {
        return parseInt(breakpoint.replace('px', ''));
    }, [breakpoint]);

    React.useEffect(() => {
        setSelected(null);
    }, [data]);

    const handleCheckboxSelection = (e: DataTableSelectionChangeParams): void => {
        setSelected(e.value);
        selectedRows(e);
    };

    const from = (): number => {
        return (page - 1) * rows + 1;
    };
    const to = (): number => {
        if ((page - 1) * rows + rows > totalRecords) {
            return totalRecords;
        }
        return (page - 1) * rows + rows;
    };
    const disablePrevPage = (): boolean => {
        if (isLoading || onClickPrevPage === undefined) return true;
        return false;
    };
    const disableNextPage = (): boolean => {
        if (isLoading || onClickNextPage === undefined) return true;
        return false;
    };

    const paginationTemplate: PaginatorTemplate = {
        layout        : 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        FirstPageLink : () => {
            return <></>;
        },
        PrevPageLink: () => {
            return (
                <Button
                    className="p-button-text p-button-sm"
                    onClick={onClickPrevPage}
                    disabled={disablePrevPage()}
                    icon="pi pi-caret-left"
                />
            );
        },
        PageLinks: () => {
            return <></>;
        },
        NextPageLink: (option) => {
            return (
                <Button
                    className="p-button-text p-button-sm"
                    onClick={onClickNextPage}
                    disabled={disableNextPage()}
                    icon="pi pi-caret-right"
                />
            );
        },
        LastPageLink: () => {
            return <></>;
        },
        RowsPerPageDropdown: () => {
            const dropdownOptions = [
                { label: 25, value: 25 },
                { label: 50, value: 50 },
                { label: 100, value: 100 }
            ];

            return (
                <>
                    <span
                        className="mx-1"
                        style={{
                            userSelect : 'none',
                            fontSize   : '14px'
                        }}
                    >
                        Élément par page:
                    </span>
                    <Dropdown value={rows} options={dropdownOptions} onChange={(e) => onClickPerPageDropdown(e)} />
                </>
            );
        },
        CurrentPageReport: (option) => {
            return (
                <>
                    {isLoading && (
                        <span
                            style={{
                                width      : '120px',
                                height     : '30px',
                                marginLeft : '10px'
                            }}
                        >
                            <Skeleton height="100%" />
                        </span>
                    )}
                    {!isLoading && (
                        <span
                            style={{
                                userSelect : 'none',
                                width      : '120px',
                                textAlign  : 'center',
                                fontSize   : '14px'
                            }}
                        >
                            {from()} - {to()} sur {option.totalRecords}
                        </span>
                    )}
                </>
            );
        },
        JumpToPageInput: () => {
            return <></>;
        }
    };

    return (
        <div style={{ maxHeight: '100%', overflow: 'scroll' }}>
            <DataTablePR
                {...props}
                value={isLoading ? Array.from({ length: 10 }) : data}
                emptyMessage="No record found"
                className={isLoading ? 'p-data-striped' : ''}
                showGridlines
                size="small"
                rowHover
                selection={selected}
                onSelectionChange={(e) => handleCheckboxSelection(e)}
                responsiveLayout="stack"
                breakpoint={breakpoint}
                scrollHeight="flex"
            >
                {displaySelectColumn && (
                    <Column
                        selectionMode="multiple"
                        headerStyle={{ maxWidth: '3em' }}
                        bodyStyle={{ maxWidth: '3em' }}
                    />
                )}
                {headers.map((el) => (
                    <Column
                        header={el.name}
                        field={el.field}
                        body={isLoading ? <Skeleton /> : el.component ? el.component : null}
                        key={el.name}
                        headerStyle={{
                            ...(el.width && windowSize.width > breakpointValue ? { maxWidth: `${el.width}px` } : {})
                        }}
                        bodyStyle={{
                            ...(el.width && windowSize.width > breakpointValue ? { maxWidth: `${el.width}px` } : {})
                        }}
                        sortable={el.sortable}
                    />
                ))}
                {displayArchiveColumn && (
                    <Column
                        header="État"
                        field="archived"
                        body={(data) =>
                            isLoading ? (
                                <Skeleton />
                            ) : (
                                <Tag
                                    severity={data.archived ? 'danger' : 'success'}
                                    value={data.archived ? 'Archivé' : 'Actif'}
                                />
                            )
                        }
                        headerStyle={{ maxWidth: windowSize.width > breakpointValue ? '5em' : 'auto' }}
                        bodyStyle={{ maxWidth: windowSize.width > breakpointValue ? '5em' : 'auto' }}
                    />
                )}
                {displayActionColumn && (
                    <Column
                        headerStyle={{ maxWidth: 'auto', textAlign: 'center' }}
                        bodyStyle={{ textAlign: 'center', overflow: 'visible', maxWidth: '4rem' }}
                        body={(_, i) => (isLoading ? <Skeleton /> : actionRow(i))}
                        field={actionColumnField}
                    />
                )}
            </DataTablePR>
            <div
                style={{
                    zIndex   : 1000,
                    position : 'fixed',
                    right    : '80px',
                    bottom   : '10px'
                }}
            >
                {showPages && <Paginator template={paginationTemplate} totalRecords={totalRecords} rows={rows} />}
            </div>
        </div>
    );
}

export default DataTable;
