import React, { useState } from 'react';
import styled from 'styled-components';

import { formatE164ToInternational } from '@bbng/util/misc';
import { ConstructionSiteCharacteristic, ConstructionSiteRo, CREATED_FROM, IContact } from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { Dropdown } from 'primereact/dropdown';
import { createdFromOptions, mapCreatedFrom } from '../../common/enumMapper';

export const FiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

type QueryParams = {
    customer_id?: string;
    created_from?: CREATED_FROM;
};

export const ConstructionSiteListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [customer_id, setCustomerId] = React.useState<string | undefined>(query.customer_id);
    const [created_from, setCreatedFrom] = useState<CREATED_FROM | undefined>(query.created_from);

    return (
        <Listing<any, ConstructionSiteRo>
            url={CONSTRUCTION_SITE_ORCHESTRATION_BASE_URL}
            queryParams={{
                customer_id,
                created_from
            }}
            addButtonUrl={urlBuilder.constructionSiteCreate()}
            endpoint="construction-sites"
            name="Chantier"
            pluralName="Chantiers"
            leftHandedComponent={
                <FiltersContainer>
                    <RelationAutocomplete.Customer
                        placeholder="Filtrer par client"
                        onSelect={(customer) => setCustomerId(customer?.id)}
                        onUnselect={() => setCustomerId(undefined)}
                    />
                    <Dropdown
                        value={created_from}
                        onChange={(e) => setCreatedFrom(e.value)}
                        placeholder="Filtrer par origine"
                        optionValue="value"
                        optionLabel="name"
                        showClear
                        options={createdFromOptions}
                    />
                </FiltersContainer>
            }
            enrichData={async (data) => {
                data.data.ro = await fetchDataRelation(data.data.ro ?? [], { customer_id: true });
                return data;
            }}
            headers={[
                {
                    name      : 'Client',
                    field     : 'customer_id',
                    component : (data: ConstructionSiteRo) => (
                        <>
                            {data.customer_id[0]
                                ? (data.customer_id[0] as unknown as { name: string }).name
                                : 'Non renseigné'}
                        </>
                    )
                },
                {
                    name      : 'Nom',
                    field     : 'label',
                    component : (data: ConstructionSiteRo) => (
                        <a href={urlBuilder.constructionSiteView(data.id)}>
                            {data.label && data.label !== '' ? data.label : data.address.formatted_name}
                        </a>
                    )
                },
                {
                    name      : 'Adresse',
                    field     : 'address',
                    component : (data: ConstructionSiteRo) => <>{data.address.formatted_name}</>
                },
                {
                    name      : 'Contact chantier',
                    field     : 'construction_site_contact',
                    component : (data: ConstructionSiteRo) => {
                        if (data.construction_site_contact && data.construction_site_contact?.length > 0) {
                            return formatContact(data.construction_site_contact[0]);
                        } else {
                            return <>Aucun contact</>;
                        }
                    }
                },
                {
                    name      : 'Rue etroite',
                    field     : 'characteristics',
                    width     : 75,
                    component : (data: ConstructionSiteRo) => {
                        if (data.characteristics && data.characteristics?.length > 0) {
                            let isNarrowStreet = false;
                            for (const characteristic of data.characteristics) {
                                if (characteristic === ConstructionSiteCharacteristic.NARROW_STREET) {
                                    isNarrowStreet = true;
                                }
                            }
                            return isNarrowStreet ? <>Oui</> : <>Non</>;
                        }
                        return <>Non</>;
                    }
                },
                {
                    name      : 'Contact logistique',
                    field     : 'log_contact',
                    component : (data: ConstructionSiteRo) => {
                        if (data.log_contact && data.log_contact?.length > 0) {
                            return formatContact(data.log_contact[0]);
                        } else {
                            return <>Aucun contact</>;
                        }
                    }
                },
                {
                    name      : 'Origine',
                    field     : 'created_from',
                    component : (data: ConstructionSiteRo) => <span>{mapCreatedFrom(data.created_from)}</span>
                }
            ]}
        />
    );
};

const formatContact = (contact: IContact): JSX.Element => {
    return (
        <ContactContainer>
            <span>
                {contact.firstname} {contact.lastname}
            </span>
            <br />
            <Anchor href={contact.phone_number ? 'tel:' + formatE164ToInternational(contact.phone_number) : undefined}>
                <i className="pi pi-phone" />
                {contact.phone_number ? formatE164ToInternational(contact.phone_number) : ''}
            </Anchor>
        </ContactContainer>
    );
};

const ContactContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Anchor = styled.a`
    word-break: break-all;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #343699;
`;
