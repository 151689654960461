import { LandfillCenterContacts, LandfillCenterContactsProps } from './ContactList';
import { LandfillCenterExceptionalClosures, LandfillCenterExceptionalClosuresProps } from './ExceptionalClosureList';
import { LandfillCenterGeneral, LandfillCenterGeneralProps } from './General';
import { LandfillCenterInventory, LandfillCenterInventoryProps } from './Inventory';
import { LandfillCenterOpeningTime, LandfillCenterOpeningTimeProps } from './OpeningTimes';
import { LandfillCenterPrices, LandfillCenterPricesProps } from './PriceList';
import { LandfillRevalorisation, LandfillCenterRevalorisationProps } from './RevalorisationCharateristics';

const Modules = {
    GeneralInfo: (props: LandfillCenterGeneralProps) => {
        return <LandfillCenterGeneral {...props} />;
    },
    ContactList: (props: LandfillCenterContactsProps) => {
        return <LandfillCenterContacts {...props} />;
    },
    PriceList: (props: LandfillCenterPricesProps) => {
        return <LandfillCenterPrices {...props} />;
    },
    ExceptionalClosureList: (props: LandfillCenterExceptionalClosuresProps) => {
        return <LandfillCenterExceptionalClosures {...props} />;
    },
    OpeningTimes: (props: LandfillCenterOpeningTimeProps) => {
        return <LandfillCenterOpeningTime {...props} />;
    },
    Inventory: (props: LandfillCenterInventoryProps) => {
        return <LandfillCenterInventory {...props} />;
    },
    RevalorisationCharateristics: (props: LandfillCenterRevalorisationProps) => {
        return <LandfillRevalorisation {...props} />;
    }
};

export default Modules;
