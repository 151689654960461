import equal from 'fast-deep-equal';
import moment from 'moment';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { PLANNING_CALCULATE_KEY_PUBLISHED, PlanningGetAllQuery, PlanningVersionDescriptionRo } from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import { useRequest } from '../../hooks/StatelessRequest';
import Input from '../Inputs';
import { DropdownInputProps } from '../Inputs/Dropdown';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';

export type PlanningVersionsDropdownProps = Omit<DropdownInputProps, 'options' | 'value' | 'result'> & {
    result: (value: PlanningVersionDescriptionRo | null, err: string[] | null, id: string) => void;
    value: PlanningVersionDescriptionRo;
};

export function PlanningVersionsDropdown({ result, value, ...props }: PlanningVersionsDropdownProps) {
    const [versions, setVersions] = React.useState<PlanningVersionDescriptionRo[]>([value]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const bbngRequest = useRequest();

    const { planningPageState, admins } = usePlanningV2Store();

    const handleChange = (newVal: string | null, err: string[] | null, id: string) => {
        const version = versions.find((v) => v.key === newVal);
        if (!version) return;
        if (equal(version, value)) return;
        result(version as PlanningVersionDescriptionRo, null, id);
    };

    useEffect(() => {
        if (value) {
            const alreadyInVersions = versions.find((v) => v.key === value.key);
            if (!alreadyInVersions) {
                setVersions((prev) => [...prev, value]);
            }
        }
    }, [value]);

    const fetchVersions = React.useCallback(async () => {
        setLoading(true);
        const res = await bbngRequest<PlanningVersionDescriptionRo[]>({
            method  : 'GET',
            url     : urlApiBuilder.planningGetVersions(),
            payload : {
                queryParams: {
                    region : planningPageState.region,
                    day    : planningPageState.day,
                    type   : planningPageState.type
                } as PlanningGetAllQuery
            },
            sync: true
        });

        if (res.success && res.response?.data.ro) {
            const fetchedRes = res.response?.data.ro;

            const sortedRes = fetchedRes.sort((a, b) => {
                if (a.key === PLANNING_CALCULATE_KEY_PUBLISHED) return -1;
                if (b.key === PLANNING_CALCULATE_KEY_PUBLISHED) return 1;
                return (b.timestamp || 0) - (a.timestamp || 0);
            });
            setVersions(sortedRes);
        }
        setLoading(false);
    }, [setVersions, planningPageState]);

    const template = React.useCallback(
        (key: string) => {
            if (!key) return 'Aucune version';
            if (loading) return <Skeleton width="48px" />;
            if (key === PLANNING_CALCULATE_KEY_PUBLISHED) return <span>Sauvegardée</span>;
            const version = versions.find((v) => v.key === key);
            if (!version) return <span>Version inconnue</span>;
            const admin = admins[version.admin_id as string]?.fullname ?? 'Utilisateur inconnu';
            return (
                <span>
                    Brouillon - Le {moment.unix(version.timestamp || 0).format('DD/MM/YYYY à HH:mm:ss')} - {admin}
                </span>
            );
        },
        [loading, versions]
    );

    return (
        <StyledDropdown
            {...props}
            errors={null}
            result={handleChange}
            value={value.key}
            options={versions.map((v) => v.key)}
            itemTemplate={template}
            valueTemplate={template}
            onShow={fetchVersions}
            validationSchema={yup.mixed()}
        />
    );
}

const StyledDropdown = styled(Input.Dropdown)`
    & .p-dropdown-panel {
        min-width: 220px !important;
    }
`;
