import moment from 'moment';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { getVolumeFromProductsForHistoric } from '@bbng/util/misc';
import { CCServiceRo, CollectConfigQuery, ConstructionSiteRo, CustomerRo, EPlanningType } from '@bbng/util/types';

import { mapCollectConfigFamilyText, mapCollectConfigStatus } from '../../common/enumMapper';
import { urlApiBuilder } from '../../common/urlBuilder';
import { Card } from '../../components/Card';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import { EOrderTypeForm } from '../../modules/order/Type';
import { displayAddress } from '../Collect/helpers';
import { fetchDataRelation } from '../../common/dataRelation';

export type LastCollectConfigsProps = {
    type?: EOrderTypeForm;
    customer?: CustomerRo;
    constructionSite?: ConstructionSiteRo;
    fetchOrderData: (id: string, duplicate?: boolean) => Promise<void>;
    setDuplicateInitialized: (value: boolean) => void;
    setIsDuplicate: (value: boolean) => void;
    isLoading?: boolean;
    lastCollectConfigs: CCServiceRo[];
};

export function LastCollectConfigs({
    type,
    customer,
    constructionSite,
    fetchOrderData,
    setDuplicateInitialized,
    setIsDuplicate,
    isLoading = false,
    lastCollectConfigs
}: LastCollectConfigsProps) {
    // const [lastCollectConfigs, setLastCollectConfigs] = useState<CCServiceRo[]>([]);
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tabIsOpen, setTabIsOpen] = useState<boolean>(false);
    const bbngRequest = useRequest({ toastifyError: true });

    // const fetchData = useCallback(async () => {
    //     setIsLoading(true);
    //     const res = await fetchDataRelation<StatelessResponse<CCServiceRo[]>>(
    //         await bbngRequest<CCServiceRo[]>({
    //             url     : urlApiBuilder.collectConfigReadAll(),
    //             method  : 'GET',
    //             payload : {
    //                 queryParams: {
    //                     customer_id          : customer?.id,
    //                     construction_site_id : constructionSite?.id,
    //                     type                 : type === EOrderTypeForm.COLLECT_DUMPSTER ? EPlanningType.DUMPSTER : EPlanningType.BIG_BAG,
    //                     limit                : 10,
    //                     no_administrative    : true
    //                 } as CollectConfigQuery
    //             }
    //         }),
    //         { customer_id: true }
    //     );
    //     if (res && res.success && res.response?.data.ro) {
    //         setLastCollectConfigs(res.response?.data.ro.sort((a, b) => moment(b.from_date).diff(moment(a.from_date))));
    //     }
    //     setIsLoading(false);
    // }, [type, customer, constructionSite]);

    const handleTabOpen = () => {
        setTabIsOpen(true);
        // fetchData();
    };
    const handleTabClose = () => {
        setTabIsOpen(false);
    };

    useEffect(() => {
        // if (tabIsOpen) fetchData();
    }, [tabIsOpen, customer, constructionSite, type]);

    return (
        <Card>
            <StyledAccordion onTabOpen={handleTabOpen} onTabClose={handleTabClose}>
                <AccordionTab
                    header={`Historique des commandes ${customer ? `- ${customer.name}` : ''} ${
                        constructionSite ? `- ${displayAddress(constructionSite.address)}` : ''
                    }`}
                >
                    <DataTable
                        value={lastCollectConfigs}
                        emptyMessage={isLoading ? <StyledSkeleton /> : 'Aucune commande'}
                    >
                        <Column
                            field="number"
                            header="Numéro collecte"
                            body={(val) => (isLoading ? <StyledSkeleton /> : val.number)}
                        />
                        <Column
                            field="order_number"
                            header="Numéro commande"
                            body={(val) => (isLoading ? <StyledSkeleton /> : val.order_number)}
                        />
                        {!customer && (
                            <Column
                                field="customer_id"
                                header="Client"
                                body={(val) => (isLoading ? <StyledSkeleton /> : val.customer_id[0].name)}
                            />
                        )}
                        {!constructionSite && (
                            <Column
                                field="address"
                                header="Adresse"
                                body={(val) => (isLoading ? <StyledSkeleton /> : displayAddress(val.address))}
                            />
                        )}
                        <Column
                            field="family"
                            header="Famille"
                            body={(val) =>
                                isLoading ? <StyledSkeleton /> : mapCollectConfigFamilyText(val.family, true)
                            }
                        />
                        <Column
                            field="from_date"
                            header="Date de collecte"
                            body={(val) =>
                                isLoading ? <StyledSkeleton /> : moment(val.from_date).format('DD/MM/YYYY')
                            }
                        />
                        <Column
                            field="from_date"
                            header="Créneau"
                            body={(val) =>
                                isLoading ? (
                                    <StyledSkeleton />
                                ) : (
                                    `${moment(val.from_date).format('HH:mm')} - ${moment(val.to_date).format('HH:mm')}`
                                )
                            }
                        />
                        <Column
                            field="products"
                            header="Volume"
                            body={(val) => {
                                if (isLoading) return <StyledSkeleton />;
                                const volume = getVolumeFromProductsForHistoric(val.products, val.family);
                                return volume ? `${volume}m³` : 'N/A';
                            }}
                        />
                        <Column
                            field="status"
                            header="Statut"
                            body={(val) => (isLoading ? <StyledSkeleton /> : mapCollectConfigStatus(val.status))}
                        />
                        <Column
                            header="Dupliquer"
                            body={(val) => {
                                return (
                                    <Button
                                        onClick={() => {
                                            setIsDuplicate(true);
                                            setDuplicateInitialized(false);
                                            fetchOrderData(val.order_id[0], true);
                                        }}
                                        icon="pi pi-copy"
                                        className="p-button-rounded p-button-text"
                                    />
                                );
                            }}
                        />
                    </DataTable>
                </AccordionTab>
            </StyledAccordion>
        </Card>
    );
}

const StyledAccordion = styled(Accordion)`
    width: 100%;
`;

const StyledSkeleton = styled(Skeleton)`
    margin: 0;
`;
