import equal from 'fast-deep-equal/react';
import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import { DiscountRo, EDiscountType } from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { ActionsFormContainer } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import SnackBar from '../../components/SnackBar';
import { toast } from '../../components/Toast';
import { useRequest } from '../../hooks/StatelessRequest';
import { AddressState } from '../../modules/common/Address';
import Discount from '../../modules/discount';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    DiscountFormErrorState,
    DiscountFormState,
    DiscountModulesErrorStates,
    DiscountModulesStates,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiCreateData,
    mapStateToApiEditData
} from './helpers';
import { PageForm, SubmitButton } from './style';

type DiscountFormProps = {
    edit?: boolean;
    readOnly?: boolean;
    duplicate?: boolean;
};

const DiscountForm: React.FC<DiscountFormProps> = ({
    edit = false,
    readOnly = false,
    duplicate = false
}: DiscountFormProps): JSX.Element => {
    const { id: paramsId } = useParams();
    const navigate = useNavigate();
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [apiState, setApiState] = React.useState<{ form: DiscountFormState; db: DiscountRo }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(edit || readOnly || duplicate);
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>(
        edit || readOnly ? 'read' : undefined
    );

    const [err, setErr] = useState<DiscountFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<DiscountFormState>['onSubmit']>(
        async (data: DiscountFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            if (data.details.type === EDiscountType.DELIVERY && data.details.min_volume === 0) {
                toast({
                    severity : 'error',
                    summary  : 'Formulaire invalide',
                    detail   : "Aucun type de sac n'a été renseigné."
                });
                return;
            }

            const method = edit ? 'PATCH' : 'POST';
            const url = edit ? urlApiBuilder.discountEdit(dataId) : urlApiBuilder.discountCreate();
            const body = edit
                ? mapStateToApiEditData(data, apiState!.form, apiState!.db)
                : mapStateToApiCreateData(data);
            setLoading(true);
            setOnRequestType('create');

            const response = await bbngRequest<DiscountRo>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);
            setOnRequestType(undefined);
            if (response.success) {
                navigate(urlBuilder.discountList());
            }
        },
        [err, apiState, bbngRequest]
    );

    const formik = useFormik<DiscountFormState>({
        initialValues : initialState,
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (
            value: DiscountModulesStates | AddressState,
            errors: DiscountModulesErrorStates | string[] | null,
            id: string
        ): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response = await bbngRequest<DiscountRo>({
            method  : 'GET',
            url     : urlApiBuilder.discountGet(dataId),
            options : { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            const ro: DiscountRo = await fetchDataRelation(response.response?.data.ro, {
                customer_id          : true,
                construction_site_id : true,
                zone_id              : true
            });
            const formikState: DiscountFormState = mapApiDataToState(ro);
            /**
             * Duplicate mode will fill all input except the customer
             */
            if (duplicate) {
                formikState.customer = initialState.customer;
            }

            setApiState({ form: formikState, db: ro });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, [dataId, formik, duplicate]);

    React.useEffect(() => {
        if (edit || readOnly || duplicate) {
            fetchData();
        }
    }, []);

    const handleArchive = React.useCallback(() => {
        setLoading(true);
        setOnRequestType(apiState?.db.archived ? 'unarchive' : 'delete');

        bbngRequest({
            method  : 'PATCH',
            url     : urlApiBuilder.discountArchive(dataId),
            options : { toastifySuccess: false },
            payload : {
                body: {
                    archived: !apiState?.db.archived
                }
            }
        })
            .then((response) => {
                if (response.success) {
                    navigate(urlBuilder.discountList());
                }
            })
            .finally(() => {
                setLoading(false);
                setOnRequestType(undefined);
            });
    }, [bbngRequest, setLoading, setOnRequestType, apiState]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (errorCode) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.discountList()} />
        ) : (
            <Error500 />
        );
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <h1>Remise</h1>
            <ActionsFormContainer
                archived={apiState?.db.archived || false}
                edit={edit}
                readOnly={readOnly}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                handleArchiveOnView={handleArchive}
                editPageUrl={urlBuilder.discountEdit(dataId)}
                historyUrl={urlApiBuilder.discountHistory(dataId)}
            />
            <Discount.GeneralInfo
                id="general"
                result={handleChange}
                value={formik.values.general}
                readOnly={readOnly}
                displayError={formik.submitCount > 0}
            />
            <Discount.Customer
                edit={edit}
                id="customer"
                result={handleChange}
                value={formik.values.customer}
                readOnly={readOnly}
                displayError={formik.submitCount > 0}
            />
            <Discount.Details
                id="details"
                result={handleChange}
                value={formik.values.details}
                readOnly={readOnly}
                displayError={formik.submitCount > 0}
            />
            {readOnly === false && (
                <SubmitButton
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        (equal(apiState?.form, formik.values) && edit)
                    }
                />
            )}
            {edit === true && (
                <SnackBar
                    show={!equal(apiState?.form, formik.values)}
                    content="Vous avez des modifications non enregistrées"
                />
            )}
        </PageForm>
    );
};

export default DiscountForm;
