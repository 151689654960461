import { EDataType, ErrorContext, ErrorResponsability } from '@bbng/util/types';

import { ErrorCodeFR } from './error-translate';
import { NdlssError } from './ndlss-error';

type BusinessErrorConstructor = {
    httpCode?: number;
    code: keyof ErrorCodeFR[ErrorContext.Business];
    params?: Record<string, any>;
    responsability?: ErrorResponsability;
    dataType?: EDataType;
};

/**
 * @description
 * Error thrown when the operation does not comply with the business rules and logics
 *
 * Wrapper around the {@link NdlssError} class to preset the context `ErrorContext.Business`
 *
 */
export class BusinessError extends NdlssError {
    override name = 'NdlssBusinessError';

    constructor({ httpCode, code, responsability, dataType, params = {} }: BusinessErrorConstructor) {
        super({
            httpCode,
            code,
            context: ErrorContext.Business,
            responsability,
            dataType,
            params
        });
    }
}
