import {
    VRPMetadataAdmin,
    VRPMetadataDecheterie,
    VRPMetadataDone,
    VRPMetadataDriver,
    VRPMetadataOrder,
    VRPMetadataTruck
} from './vrp.instance';

export type VRPNode = {
    node_type: EVRPNodeType;
    debug_node_id?: string; //Not on Break
    node_name: string; //Not on Break
    order_volume_in_m3?: string; //Not on Break

    starting_time?: string; //only on Break
    ending_time?: string; //only on Break

    driving_duration: string; //HH:mm
    waiting_duration: string; //HH:mm

    arrival_time: string; //HH:mm Not on Break
    departure_time: string; //HH:mm Not on Break
    service_duration: string; //HH:mm Not on Break
    remaining_capacity_in_m3?: string; //only BB Not on Break
    nb_8m3_containers_to_take?: string; //only Dumpter Not on Break
    nb_15m3_containers_to_take?: string; //only Dumpter Not on Break
    nb_20m3_containers_to_take?: string; //only Dumpter Not on Break
    nb_30m3_containers_to_take?: string; //only Dumpter Not on Break
    metadata:
        | VRPMetadataTruck
        | VRPMetadataOrder
        | VRPMetadataDriver
        | VRPMetadataDone
        | VRPMetadataDecheterie
        | VRPMetadataAdmin;
};

export type VRPRoute = {
    debug_driver_id: string;
    driver_name: string;
    vehicle_id: string;
    travel_distance: string;
    travel_duration: string; //HH:mm
    starting_work_time: string; //HH:mm
    ending_work_time: string; //HH:mm
    breaking_time: string; //HH:mm
    nodes: VRPNode[];
};

export type VRPResponse = {
    debug_solver_name: string;
    debug_solver_version: string;
    debug_solver_build_type: string;
    debug_olver_build_date: string;
    debug_day: string;
    objective_value_master_z: string;
    sub_objective_value_total_distance: string;
    sub_objective_value_total_duration: string;
    sub_objective_value_nb_served_orders: string;
    sub_objective_value_nb_assigned_routes: string;

    sub_objective_value_cost_routings: string;
    sub_objective_value_cost_breaks_waitings_processings_routings: string;
    sub_objective_value_cost_drivers_working_day_tardiness: string;
    sub_objective_value_gains_from_working_load_balancer: string;
    routes: VRPRoute[];
};

export type VRPDumpsterResponse = VRPResponse & {
    debug_solution_id: string;
    sub_objective_value_containers_gains_coming_from_deposit: string;
    sub_objective_value_containers_gains_coming_from_removal: string;
    sub_objective_value_containers_gains_coming_from_dummy: string;
    sub_objective_value_containers_gains_coming_from_administrative: string;
    sub_objective_value_containers_losses_not_collected_from_orders: string;
    sub_objective_value_containers_losses_not_beautiful_orders: string;
};

export type VRPBBResponse = VRPResponse & {
    sub_objective_value_big_bags_gains_coming_from_deposit: string;
    sub_objective_value_big_bags_gains_coming_from_removal: string;
    sub_objective_value_big_bags_gains_coming_from_administrative: string;
    sub_objective_value_big_bags_losses_not_collected_from_orders: string;
};

export enum EVRPNodeType {
    DRIVER_HOUSE_LOCATION_START = 'driver_house_location_start',
    DRIVER_HOUSE_LOCATION_END = 'driver_house_location_end',
    LANDFILL_LOCATION = 'dumpster_location',
    ORDER_LOCATION = 'order_location',
    ADMINISTRATIVE_LOCATION = 'administrative_mission_location',
    BREAK = 'breaking_time'
}
