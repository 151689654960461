export enum ECustomerInvitationLinkTemplateKeys {
    INVITATION_LINK = '{{INVITATION_LINK}}',
    USER_NAME = '{{USER_NAME}}',
    USER_EMAIL = '{{USER_EMAIL}}',
    CUSTOMER_NAME = '{{CUSTOMER_NAME}}',
    EXPIRATION_DATE = '{{EXPIRATION_DATE}}'
}

export const emailCustomerInvitationLinkTemplate = `
<table class="es-content" cellspacing="0" cellpadding="0" align="center"
    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
    <tr>
        <td align="center" style="padding:0;Margin:0">
            <table class="es-content-body" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:600px">
                <tr>
                    <td align="left" style="padding:0;Margin:0;background-color:#fff">
                        <table width="100%" cellspacing="0" cellpadding="0"
                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                                <td valign="top" align="center"
                                    style="padding:32px 0 0;Margin:0;width:600px">
                                    <table width="100%" cellspacing="0" cellpadding="0"
                                        role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Bonjour,</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Un nouvel utilisateur, {{USER_NAME}} ({{USER_EMAIL}}), souhaite rejoindre {{CUSTOMER_NAME}} !</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    En acceptant sa demande, vous lui permettez d'accéder aux outils digitaux MY ENDLESS pour le compte de {{CUSTOMER_NAME}}. Il pourra passer des commandes, les consulter, retrouver ses factures et BSD et accéder aux données de valorisation.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:15px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Pour accepter sa demande, veuillez cliquer sur le lien ci-dessous.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center" style="padding:0;Margin:0">
                                                <!--[if mso]><a href="{{INVITATION_LINK}}" target="_blank" hidden> <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" esdevVmlButton href="{{INVITATION_LINK}}" style="height:48px; v-text-anchor:middle; width:271px" arcsize="10%" stroke="f" fillcolor="#5465fc"> <w:anchorlock></w:anchorlock> <center style='color:#ffffff; font-family:arial, "helvetica neue", helvetica, sans-serif; font-size:18px; font-weight:700; line-height:18px; mso-text-raise:1px'>Accepter la demande</center> </v:roundrect></a><![endif]--><!--[if !mso]><!-- --><span
                                                    class="msohide es-button-border"
                                                    style="border-style:solid;border-color:#2cb543;background:#5465fc;border-width:0px;display:inline-block;border-radius:6px;width:auto;mso-hide:all"><a
                                                        href="{{INVITATION_LINK}}"
                                                        class="es-button es-button-1"
                                                        target="_blank"
                                                        style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:14px;border-style:solid;border-color:#5465fc;border-width:20px 45px;display:inline-block;background:#5465fc;border-radius:6px;font-size:16px;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-weight:bold;line-height:22px;width:auto;text-align:center">Accepter la demande</a></span><!--<![endif]--></td>
                                        </tr>
                                        <tr>
                                            <td align="center"
                                                class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:20px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:20px;color:#df0e07;font-size:13px">
                                                    <strong><em>Attention: Le lien expirera le {{EXPIRATION_DATE}}.</em></strong></p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
`;
