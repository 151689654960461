import Joi from 'joi';

import { EUserRole, UserRo } from '.';
import { PhoneE164Schema } from '../misc.schema';
import { generateRelationsSchema } from '../dto';
import { CREATED_FROM } from '../misc';

export const UserCreateDtoSchema = Joi.object({
    firstname             : Joi.string().required(),
    lastname              : Joi.string().required(),
    email                 : Joi.string().email({ tlds: false }).required(),
    phone_number          : PhoneE164Schema.required(),
    profile_not_completed : Joi.boolean(),
    customer_id           : Joi.array().items(Joi.string()).length(1).required(),
    roles                 : Joi.array()
        .items(Joi.string().valid(...Object.values(EUserRole)))
        .min(1)
        .required(),
    notify_user  : Joi.boolean().optional(),
    created_from : Joi.string()
        .valid(...Object.values(CREATED_FROM))
        .optional()
});

export const UserEditDtoSchema = Joi.object({
    firstname             : Joi.string(),
    lastname              : Joi.string(),
    email                 : Joi.string().email({ tlds: false }),
    phone_number          : PhoneE164Schema,
    profile_not_completed : Joi.boolean(),
    notify_user           : Joi.boolean().optional()
}).concat(generateRelationsSchema<UserRo>('customer_id'));

/**
 * LEGACY SCHEMAS
 */
export const UserAssignSpecificRoleDtoSchema = Joi.object({
    roles: Joi.array()
        .items(Joi.string().valid(...Object.values(EUserRole)))
        .required()
});

export const UserAssignCustomRoleDtoSchema = Joi.object({
    roles       : Joi.array().items(Joi.string().equal(EUserRole.BBNG_USER_CUSTOM)).required(),
    permissions : Joi.array().items(Joi.string()).min(1).required()
});

export const UserEditFromZohoEmailDtoSchema = Joi.object({
    firstname    : Joi.string(),
    lastname     : Joi.string(),
    email        : Joi.string().email({ tlds: false }),
    phone_number : PhoneE164Schema
});
