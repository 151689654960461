import { Button } from 'primereact/button';
import styled from 'styled-components';
import equal from 'fast-deep-equal';

import { getMiddleOfTheDay, getNextBusinessDay } from '@bbng/util/misc';
import {
    EPlanningRegion,
    EPlanningType,
    PLANNING_CALCULATE_KEY_PUBLISHED,
    PlanningVersionDescriptionRo,
    PRODUCT_DUMPSTER_TYPE
} from '@bbng/util/types';

import { mapPlanningConfigRegion, mapPlanningConfigType } from '../../common/enumMapper';
import Calendar from '../../components/Calendar';
import Input from '../../components/Inputs';
import { PlanningVersionsDropdown } from '../../components/PlanningVersionsDropdown';
import { PlanningPageState } from '../../pages/Planning/helpers';
import { DumpsterSelector } from '../../components/DumpsterSelector';

type PlanningSelectorProps = {
    value: PlanningPageState;
    setValue: React.Dispatch<React.SetStateAction<PlanningPageState>>;
    setValueManuallySet: (value: boolean) => void;
    setDumpsterTypes: (value: PRODUCT_DUMPSTER_TYPE[]) => void;
    dumpsterTypes: PRODUCT_DUMPSTER_TYPE[];
};

export const PlanningSelector: React.FC<PlanningSelectorProps> = ({
    value,
    setValue,
    setValueManuallySet,
    dumpsterTypes,
    setDumpsterTypes
}: PlanningSelectorProps): JSX.Element => {
    const handleChange = (
        newVal: string | string[] | PlanningVersionDescriptionRo | null | undefined,
        err: string[] | null,
        id: string
    ) => {
        if (!equal(newVal, value[id as keyof PlanningPageState])) {
            if (id !== 'redis_version_description') {
                setValue((curr) => ({
                    ...curr,
                    [id]                      : newVal,
                    redis_version_description : {
                        day    : value.day,
                        region : value.region,
                        type   : value.type,
                        /**
                         * each time day/region/type is updated, we need to reset the version to published
                         */
                        key    : PLANNING_CALCULATE_KEY_PUBLISHED
                    }
                }));
            } else {
                setValue((curr) => ({
                    ...curr,
                    redis_version_description: newVal as PlanningVersionDescriptionRo
                }));
            }
            setValueManuallySet(true);
        }
    };

    return (
        <InputsContainer>
            <Calendar.DayMonthYear
                value={value.day}
                id="day"
                readOnly={false}
                required={false}
                result={handleChange}
                displayError={false}
                label="Jour du planning"
            />
            <Button
                className="p-button-outlined p-button-secondary p-button-sm"
                label="Aujourd'hui"
                onClick={() => handleChange(getMiddleOfTheDay(), null, 'day')}
            />
            <Button
                className="p-button-outlined p-button-secondary p-button-sm"
                label="Lendemain"
                onClick={() => handleChange(getNextBusinessDay(), null, 'day')}
            />
            <Input.Dropdown
                value={value.region}
                id="region"
                readOnly={false}
                required={false}
                result={handleChange}
                displayError={false}
                label="Région"
                options={Object.values(EPlanningRegion)}
                errors={[]}
                valueTemplate={(region) => mapPlanningConfigRegion(region)}
                itemTemplate={(region) => mapPlanningConfigRegion(region)}
            />
            <Input.Dropdown
                value={value.type}
                id="type"
                readOnly={false}
                required={false}
                result={handleChange}
                displayError={false}
                label="Prestation"
                options={Object.values(EPlanningType)}
                errors={[]}
                valueTemplate={(type) => mapPlanningConfigType(type)}
                itemTemplate={(type) => mapPlanningConfigType(type)}
            />
            <PlanningVersionsDropdown
                value={value.redis_version_description}
                id="redis_version_description"
                readOnly={false}
                required={false}
                result={handleChange}
                displayError={false}
                label="Version"
                errors={[]}
            />
            {value.type === EPlanningType.DUMPSTER && (
                <DumpsterSelector dumpsterTypes={dumpsterTypes} setDumpsterTypes={setDumpsterTypes} />
            )}
        </InputsContainer>
    );
};

export const InputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
`;
