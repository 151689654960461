import { AddressRo } from '@bbng/util/types';

import { AddressMapButton } from '../AddressDisplayButton';
import { AddressOverviewContainer, VerticalContainer } from './style';

export type AddressOverviewProps = { address: AddressRo; showButton?: boolean; flex?: boolean; url?: string };

export const AddressOverview: React.FC<AddressOverviewProps> = ({
    address,
    showButton = false,
    flex = true,
    url
}: AddressOverviewProps): JSX.Element => {
    return (
        <VerticalContainer gap="8px">
            <AddressOverviewContainer flex={flex}>
                {showButton && (
                    <AddressMapButton lat={address.coordinates.latitude} lng={address.coordinates.longitude} />
                )}
                <LinkWrapper url={url}>
                    <VerticalContainer gap="2px">
                        <div>
                            {address.components['street_number']} {address.components['route']}
                        </div>
                        <div>
                            {address.components['postal_code']} {address.components['locality']}
                        </div>
                    </VerticalContainer>
                </LinkWrapper>
            </AddressOverviewContainer>
        </VerticalContainer>
    );
};

const LinkWrapper: React.FC<{ url?: string }> = ({ children, url }) => {
    if (url) {
        return (
            <a href={url} target="_blank" rel="noreferrer">
                {children}
            </a>
        );
    }
    return <>{children}</>;
};
