import moment from 'moment';

import {
    EPlanningRegion,
    EPlanningType,
    ISODate,
    PLANNING_CALCULATE_KEY_PUBLISHED,
    PlanningVersionDescriptionRo
} from '@bbng/util/types';

import { DataRelationKeys, urlRegistry } from '../../common/dataRelation';

export type PlanningPageState = {
    day: ISODate;
    region: EPlanningRegion;
    type: EPlanningType;
    redis_version_description: PlanningVersionDescriptionRo;
};

export const initialState: PlanningPageState = {
    day                       : moment().hour(12).minute(0).second(0).millisecond(0).toISOString(),
    region                    : EPlanningRegion.PARIS,
    type                      : EPlanningType.BIG_BAG,
    redis_version_description : {
        key    : PLANNING_CALCULATE_KEY_PUBLISHED,
        day    : moment().hour(12).minute(0).second(0).millisecond(0).toISOString(),
        region : EPlanningRegion.PARIS,
        type   : EPlanningType.BIG_BAG
    }
};

export const planningRelationKeys: DataRelationKeys<typeof urlRegistry> = {
    customer_id       : true,
    collect_config_id : true,
    truck_id          : true,
    collector_id      : true,
    collect_id        : true,
    landfill_id       : true,
    admin_id          : true
};
