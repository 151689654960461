import { Card as PRCard, CardTemplateTypes } from 'primereact/card';
import styled from 'styled-components';

export type CardProps = React.PropsWithChildren<{
    title?: CardTemplateTypes;
}>;

export function Card({ title, ...props }: CardProps) {
    return <StyledCardContent title={title} {...props} />;
}

const StyledCardContent = styled(PRCard)`
    border-radius: 8px;
    background-color: ##f7f9fc;
    box-shadow: rgba(0, 0, 0, 0.22) 0px 3px 6px -4px;
    .p-card {
    }
    .p-card-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }
`;
