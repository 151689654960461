import { useEffect } from 'react';
import RelationAutocomplete from '../../common/RelationAutocomplete';
import { FilterSectionTitle } from './style';
import { customerStepClassName } from '../helpers';
import { CustomerRo } from '@bbng/util/types';
import { PlanningFilterClassNames } from './helpers';

export type CustomerFilterProps = {
    reset: boolean;
    setFilterClassNames: React.Dispatch<React.SetStateAction<PlanningFilterClassNames>>;
    selectedCustomer: CustomerRo | undefined;
    setSelectedCustomer: React.Dispatch<React.SetStateAction<CustomerRo | undefined>>;
};

export const CustomerFilter: React.FC<CustomerFilterProps> = ({
    reset,
    setFilterClassNames,
    selectedCustomer,
    setSelectedCustomer
}: CustomerFilterProps): JSX.Element => {
    useEffect(() => {
        if (reset) {
            setSelectedCustomer(undefined);
            setFilterClassNames((current) => ({ ...current, customer: [] }));
        }
    }, [reset]);

    return (
        <>
            <FilterSectionTitle>Client</FilterSectionTitle>
            <RelationAutocomplete.Customer
                baseValue={selectedCustomer}
                placeholder="Client spécifique"
                onSelect={(customer) => {
                    if (customer) {
                        setFilterClassNames((current) => ({
                            ...current,
                            customer: [`.${customerStepClassName(customer.id)}`]
                        }));
                        setSelectedCustomer(customer);
                    }
                }}
                onUnselect={() => {
                    setFilterClassNames((current) => ({ ...current, customer: [] }));
                    setSelectedCustomer(undefined);
                }}
            />
        </>
    );
};
