import React from 'react';
import styled from 'styled-components';

import { CardErrors, PhoneValue } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import Input, { textInputProps } from '../../components/Inputs';

export type LandfillCenterInventoryProps = {
    readOnly?: boolean;
    value?: LandfillCenterInventoryState;
    id: string;
    result: (
        value: LandfillCenterInventoryState,
        errors: null | string[] | CardErrors<LandfillCenterInventoryState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type LandfillCenterInventoryState = {
    nb_8m3_dumpster: number | null;
    nb_15m3_dumpster: number | null;
    nb_20m3_dumpster: number | null;
    nb_30m3_dumpster: number | null;
};
export type LandfillCenterInventoryErrorState = CardErrors<LandfillCenterInventoryState>;

export const initialState: LandfillCenterInventoryState = {
    nb_8m3_dumpster  : 0,
    nb_15m3_dumpster : 0,
    nb_20m3_dumpster : 0,
    nb_30m3_dumpster : 0
};

export const initialErrorState: LandfillCenterInventoryErrorState = generateInitialErrorState(initialState);

export const initialRef = Array.from(Array(Object.keys(initialState).length)).map(() =>
    React.createRef<textInputProps>()
);

export const LandfillCenterInventory = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: LandfillCenterInventoryProps) => {
    const [val, setVal] = React.useState<LandfillCenterInventoryState>(value);
    const [err, setErr] = React.useState<LandfillCenterInventoryErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <StyledCard title="Inventaire de bennes">
            <CardLine>
                <Input.Number
                    required
                    label="Bennes 8m3"
                    id="nb_8m3_dumpster"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.nb_8m3_dumpster}
                    errors={err.nb_8m3_dumpster}
                    displayError={displayError}
                />
                <Input.Number
                    required
                    label="Bennes 15m3"
                    id="nb_15m3_dumpster"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.nb_15m3_dumpster}
                    errors={err.nb_15m3_dumpster}
                    displayError={displayError}
                />
            </CardLine>
            <CardLine>
                <Input.Number
                    required
                    label="Bennes 20m3"
                    id="nb_20m3_dumpster"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.nb_20m3_dumpster}
                    errors={err.nb_20m3_dumpster}
                    displayError={displayError}
                />
                <Input.Number
                    required
                    label="Bennes 30m3"
                    id="nb_30m3_dumpster"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.nb_30m3_dumpster}
                    errors={err.nb_30m3_dumpster}
                    displayError={displayError}
                />
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
