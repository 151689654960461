import { EDataType, ErrorContext, ErrorResponsability } from '@bbng/util/types';

import { ErrorCodeFR } from './error-translate';
import { NdlssError } from './ndlss-error';

type DatabaseErrorConstructor = {
    httpCode?: number;
    code: keyof ErrorCodeFR[ErrorContext.Database];
    params?: Record<string, any>;
    responsability?: ErrorResponsability;
    dataType?: EDataType;
};

/**
 * @description
 * Error thrown when a database operation fails
 *
 * Wrapper around the {@link NdlssError} class to preset the context `ErrorContext.Database`
 *
 */
export class DatabaseError extends NdlssError {
    override name = 'NdlssDatabaseError';

    constructor({ httpCode, code, responsability, dataType, params = {} }: DatabaseErrorConstructor) {
        super({
            httpCode,
            code,
            context: ErrorContext.Database,
            responsability,
            dataType,
            params
        });
    }
}
