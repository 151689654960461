import * as React from 'react';

const EndlessLogo: React.FC<React.SVGProps<SVGSVGElement> & { size?: number }> = (props) => (
    <svg xmlSpace="preserve" height={props.size ?? 20} viewBox="0 0 1440 216.983" {...props}>
        <defs>
            <clipPath clipPathUnits="userSpaceOnUse" id="a">
                <path d="M0 162.737h1080V0H0Z" />
            </clipPath>
        </defs>
        <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 216.983)">
            <path
                d="M0 0c23.419-.75 48.715-.562 69.139-.188.188 1.312.188 2.624.188 4.31 0 13.678-8.997 19.862-31.48 19.862C12.739 23.984 2.433 18.737 0 0m105.676-29.043c.939-26.419-12.177-41.035-67.829-41.035-48.529 0-75.699 18.924-75.699 60.896 0 41.785 28.294 59.772 76.261 59.772 50.03 0 67.453-17.987 67.453-48.904 0-9.556-.371-15.365-.748-21.173-13.115.374-36.537.374-58.647.562-17.799 0-35.228-.188-46.467-.188 2.433-17.613 13.863-23.42 38.223-23.42 22.484 0 30.539 4.87 30.916 13.49 5.434-.562 11.43-.75 19.111-.75 7.308 0 12.742.188 17.426.75"
                transform="translate(46.8 76.85)"
            />
            <path
                d="M0 0c.559 13.491.748 33.915.748 56.586 0 20.424-7.494 27.732-29.23 27.732-20.424 0-35.976-9.182-35.976-32.041 0-30.541.189-42.346.374-52.277h-41.971c.752 15.74.752 28.856.752 55.462 0 24.733-.188 42.159-.752 58.086h42.159c-.188-8.619-.373-14.615-.747-22.859l.936-.188c8.432 18.176 26.043 25.669 52.651 25.669 33.351 0 52.839-14.052 52.839-50.028 0-13.303-.376-20.611-.376-29.604 0-13.116.188-24.733.753-36.538z"
                transform="translate(287.74 10.519)"
            />
            <path
                d="M0 0c-9.556 5.059-21.736 7.495-37.288 7.495-25.107 0-36.725-8.245-36.725-29.418 0-20.986 12.368-29.042 34.289-28.856C-17.049-50.779 0-41.596 0-21.736zm40.474 66.704c-.753-24.171-.565-48.529-.565-87.69 0-24.545 0-41.784.377-57.523H-.938c0 4.309.188 8.244.188 13.116 0 3.185.188 6.183.376 9.181l-1.5.375c-8.244-14.615-26.232-24.359-52.089-24.359-37.288 0-59.021 18.363-59.021 58.085 0 39.911 24.919 59.586 61.083 59.586 26.605 0 42.719-7.87 50.963-18.738l.376.188C-.75 26.232-.75 32.789-.75 40.284c0 9.369.188 17.426-.188 26.42z"
                transform="translate(467.035 88.841)"
            />
            <path
                d="M0 0c.747 22.298.562 45.156.562 71.201 0 28.294 0 49.093-.562 73.825h41.036c-.75-23.608-.565-43.845-.565-75.511 0-28.105-.185-46.843.565-69.515Z"
                transform="translate(540.463 10.519)"
            />
            <path
                d="M0 0c23.419-.75 48.715-.562 69.139-.188.188 1.312.188 2.624.188 4.31 0 13.678-8.994 19.862-31.48 19.862C12.739 23.984 2.436 18.737 0 0m105.679-29.043c.936-26.419-12.18-41.035-67.832-41.035-48.527 0-75.697 18.924-75.697 60.896 0 41.785 28.294 59.772 76.262 59.772 50.027 0 67.452-17.987 67.452-48.904 0-9.556-.373-15.365-.75-21.173-13.115.374-36.535.374-58.647.562-17.799 0-35.225-.188-46.467-.188 2.436-17.613 13.863-23.42 38.223-23.42 22.484 0 30.542 4.87 30.916 13.49 5.436-.562 11.432-.75 19.111-.75 7.308 0 12.745.188 17.429.75"
                transform="translate(645.195 76.85)"
            />
            <path
                d="M0 0c0-8.057 7.308-11.992 36.164-11.992 25.107 0 30.915 2.624 30.915 8.432 0 5.434-3.936 9.369-39.724 10.493-48.529 2.061-62.207 15.365-62.207 36.726 0 23.795 17.052 35.975 67.641 35.975 44.97 0 68.203-8.994 68.203-36.539 0-1.31-.185-2.809-.185-4.495-4.125.374-11.244.562-17.802.562-6.935 0-13.678-.188-17.426-.375v1.686c0 7.494-7.12 11.054-33.54 11.054-25.484 0-30.353-2.809-30.353-8.243 0-4.684 3.372-8.807 41.409-10.493 48.904-2.436 59.96-13.491 59.96-35.976 0-23.797-16.676-37.288-68.203-37.288-54.716 0-70.268 13.68-70.268 37.101 0 1.498.188 3.185.188 4.684 4.31-.562 10.68-.75 17.614-.75 6.558 0 12.93 0 17.614.562z"
                transform="translate(809.689 47.618)"
            />
            <path
                d="M0 0c0-8.057 7.308-11.992 36.164-11.992 25.107 0 30.915 2.624 30.915 8.432 0 5.434-3.933 9.369-39.724 10.493-48.526 2.061-62.207 15.365-62.207 36.726 0 23.795 17.052 35.975 67.644 35.975 44.967 0 68.203-8.994 68.203-36.539 0-1.31-.188-2.809-.188-4.495-4.122.374-11.244.562-17.8.562-6.931 0-13.68-.188-17.428-.375v1.686c0 7.494-7.12 11.054-33.54 11.054-25.481 0-30.351-2.809-30.351-8.243 0-4.684 3.372-8.807 41.407-10.493 48.904-2.436 59.96-13.491 59.96-35.976 0-23.797-16.676-37.288-68.203-37.288-54.711 0-70.266 13.68-70.266 37.101 0 1.498.189 3.185.189 4.684 4.31-.562 10.679-.75 17.614-.75 6.555 0 12.927 0 17.611.562z"
                transform="translate(967.998 47.618)"
            />
        </g>
    </svg>
);

export default EndlessLogo;
