import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import { useCallback, useState } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Button as PRButton } from 'primereact/button';

import {
    AdminRo,
    CREATED_FROM,
    CustomerRo,
    CustomerRoFront,
    EBillingShipment,
    ECustomerCategory,
    EMembershipType,
    GetAllCustomerQuery,
    ISODate
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { createdFromOptions, mapBillingShipment, mapCreatedFrom, mapCustomerCategory } from '../../common/enumMapper';
import { CUSTOMER_ORCHESTRATION_BASE_URL, urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { FlexContainer, OrderLink } from './style';
import moment from 'moment';
import { formatE164ToInternational, getMainEmailPhoneFromCustomerContacts, getMiddleOfTheDay } from '@bbng/util/misc';
import { defaultErrorToast } from '../../common/syncRequest';
import { axiosClient } from '../../common/axios';
import Calendar from '../../components/Calendar';
import { toast } from '../../components/Toast';

const StyledDropdown = styled(Dropdown)`
    max-width: 300px;
    margin: 4px;
`;
const StyledFilter = styled.span`
    max-width: 300px;
    margin: 4px;
`;

const categoryOptions = [
    { name: mapCustomerCategory(ECustomerCategory.INDIVIDUAL), value: ECustomerCategory.INDIVIDUAL },
    { name: mapCustomerCategory(ECustomerCategory.PRO), value: ECustomerCategory.PRO },
    { name: mapCustomerCategory(ECustomerCategory.PRO_TO_VALIDATE), value: ECustomerCategory.PRO_TO_VALIDATE }
];

const blockedOptions = [
    { name: 'Autorisé', value: false },
    { name: 'Bloqué', value: true }
];

const statusOptions = [
    { name: EMembershipType.GOLD, value: EMembershipType.GOLD },
    { name: EMembershipType.SILVER, value: EMembershipType.SILVER },
    { name: EMembershipType.IVORY, value: EMembershipType.IVORY }
];

const billingOptions = [
    { name: mapBillingShipment(EBillingShipment.EMAIL), value: EBillingShipment.EMAIL },
    { name: mapBillingShipment(EBillingShipment.LETTER), value: EBillingShipment.LETTER }
];

type QueryParams = {
    category?: ECustomerCategory;
    blocked?: boolean;
    status?: EMembershipType;
    admin_id?: string;
    billing_shipment?: EBillingShipment;
    created_from?: CREATED_FROM;
    min_date?: ISODate;
    max_date?: ISODate;
};

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const StyleSpan = styled.span`
    padding: 12px;
    width: 100%;
    font-size: 1em;
    display: flex;
    align-items: center;
`;

const StyledAccordion = styled(Accordion)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    & .p-accordion {
        width: 100%;
    }
    & .p-accordion-header-link {
        padding: 0 1.25em !important;
    }
    & .p-accordion-content {
        background: transparent;
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        padding: 8px;
        align-items: center;
    }
    & .p-accordion-tab {
        width: 100%;
        background: transparent;
        margin: 0;
    }
`;

export const CustomerListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [category, setCategory] = useState<ECustomerCategory | undefined>(query.category);
    const [blocked, setBlocked] = useState<boolean | undefined>(query.blocked);
    const [status, setStatus] = useState<EMembershipType | undefined>(query.status);
    const [admin_id, setAdminId] = useState<string | undefined>(query.admin_id);
    const [billing_shipment, setBillingShipment] = useState<EBillingShipment | undefined>(query.billing_shipment);
    const [created_from, setCreatedFrom] = useState<CREATED_FROM | undefined>(query.created_from);
    const [minDate, setMinDate] = React.useState<ISODate | undefined>(query.min_date);
    const [maxDate, setMaxDate] = React.useState<ISODate | undefined>(query.max_date);

    const [isExportLoading, setIsExportLoading] = React.useState(false);

    const exportData = useCallback(async () => {
        setIsExportLoading(true);

        try {
            const response = await axiosClient.get(urlApiBuilder.customerExport(), {
                params: {
                    ...query
                } as GetAllCustomerQuery,
                responseType: 'blob'
            });

            const formatedDay = moment().format('YYYY-MM-DD_HH:mm:ss');
            const name = `export-clients-${formatedDay}`;

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.data);

            link.download = `${name}.xlsx`;
            link.click();
            link.remove();
        } catch (e) {
            defaultErrorToast("Une erreur est survenue lors de l'export des clients");
            console.error(e);
        }
        setIsExportLoading(false);
    }, [JSON.stringify(query)]);

    return (
        <Listing<any, CustomerRoFront>
            url={CUSTOMER_ORCHESTRATION_BASE_URL}
            addButtonUrl="/dashboard/customers/create"
            endpoint="customers"
            searchPlaceholder="Rechercher par nom, SIREN ou SIRET"
            hideBulkMenu
            enrichData={async (data) => {
                data.data.ro = await fetchDataRelation(data.data.ro ?? [], {
                    admin_id: true
                });
                return data;
            }}
            displaySelectColumn={false}
            queryParams={
                {
                    category         : category,
                    blocked          : blocked,
                    status           : status,
                    admin_id         : admin_id,
                    billing_shipment : billing_shipment,
                    withOrderCount   : true,
                    created_from,
                    max_date         : maxDate ? moment(maxDate).add(1, 'day').startOf('day').toISOString() : undefined, // max date is exclusive so include the next day for better UX
                    min_date         : minDate ? moment(minDate).startOf('day').toISOString() : undefined
                } as GetAllCustomerQuery
            }
            displayArchiveColumn={false}
            leftHandedComponent={
                <StyledAccordion>
                    <AccordionTab
                        header={
                            <TitleContainer>
                                <StyleSpan>Filtres et exports</StyleSpan>
                            </TitleContainer>
                        }
                    >
                        <Calendar.Range
                            id=""
                            label="Date de création"
                            placeholder=""
                            value={minDate && maxDate ? [minDate, maxDate] : undefined}
                            required={false}
                            readOnly={false}
                            result={(date) => {
                                if (Array.isArray(date)) {
                                    /**
                                     * Needed to get the middle of the day to avoid timezone issues.
                                     */
                                    const date0 = moment(date[0]).toLocaleString();
                                    const middleStartDay = getMiddleOfTheDay(date0);
                                    const date1 = moment(date[1]).toLocaleString();
                                    const middleEndDay = getMiddleOfTheDay(date1);
                                    if (date?.length === 2) {
                                        /**
                                         * Case when only the start date is set.
                                         * Toast the user to select an end date.
                                         * We cannot set the min and max date to the same value as we won't be able to select two dates then.
                                         */
                                        if (date[1] == null) {
                                            toast({
                                                severity : 'info',
                                                summary  : 'Sélectionnez une date de fin.',
                                                detail   : 'Vous devez sélectionner une date de fin pour filtrer par période (pour sélectionner un seul jour, cliquez deux fois sur la même date).'
                                            });
                                            return;
                                        }
                                        setMinDate(middleStartDay);
                                        setMaxDate(middleEndDay);
                                    } else if (date?.length === 1) {
                                        setMinDate(middleStartDay);
                                        setMaxDate(middleStartDay);
                                    }
                                } else {
                                    setMinDate(undefined);
                                    setMaxDate(undefined);
                                }
                            }}
                            showTime={false}
                            displayError={false}
                        />
                        <StyledDropdown
                            value={category}
                            onChange={(e) => setCategory(e.value)}
                            placeholder="Filtrer par type"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={categoryOptions}
                        />
                        <StyledDropdown
                            value={blocked}
                            onChange={(e) => setBlocked(e.value)}
                            placeholder="Filtrer par autorisation"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={blockedOptions}
                        />
                        <StyledDropdown
                            value={status}
                            onChange={(e) => setStatus(e.value)}
                            placeholder="Filtrer par statut"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={statusOptions}
                        />
                        <StyledDropdown
                            value={billing_shipment}
                            onChange={(e) => setBillingShipment(e.value)}
                            placeholder="Filtrer par mode d'envoi"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={billingOptions}
                        />
                        <StyledFilter>
                            <RelationAutocomplete.Admin
                                placeholder="Filtrer par responsable"
                                onSelect={(admin) => setAdminId(admin?.id)}
                                onUnselect={() => setAdminId(undefined)}
                            />
                        </StyledFilter>
                        <Dropdown
                            value={created_from}
                            onChange={(e) => setCreatedFrom(e.value)}
                            placeholder="Filtrer par origine"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={createdFromOptions}
                        />
                        <PRButton
                            type="button"
                            loading={isExportLoading}
                            loadingIcon="pi pi-spin pi-spinner"
                            label="Exporter les clients"
                            icon="pi pi-file-excel"
                            tooltipOptions={{ position: 'left' }}
                            onClick={exportData}
                        />
                    </AccordionTab>
                </StyledAccordion>
            }
            name="Client"
            pluralName="Clients"
            headers={[
                {
                    name      : 'Nom',
                    field     : 'name',
                    component : (data: CustomerRoFront) => (
                        <FlexContainer>
                            <a href={'/dashboard/customers/view/' + data.id}>
                                {data.name}{' '}
                                {data.internal_name && data.internal_name !== '' && `(${data.internal_name})`}
                            </a>
                            <Tooltip
                                target={`.customer-name-${data.id.replace(':', '')}`}
                                content={data.comment ?? 'Aucun commentaire'}
                            />
                            <i className={`customer-name-${data.id.replace(':', '')} pi pi-info-circle`} />
                        </FlexContainer>
                    )
                },
                {
                    name  : 'Siret',
                    field : 'siret',
                    width : 150
                },
                {
                    name  : 'Siren',
                    field : 'siren',
                    width : 120
                },
                {
                    name      : 'Contact',
                    field     : 'contact',
                    component : (data: CustomerRo) => {
                        const { email, phone } = getMainEmailPhoneFromCustomerContacts(data.contact);

                        if (!email && !phone) {
                            return <span>Aucun contact</span>;
                        }

                        return (
                            <span>
                                {email ?? 'Aucun e-mail'} |{' '}
                                {phone ? formatE164ToInternational(phone) : 'Aucun téléphone'}
                            </span>
                        );
                    }
                },
                {
                    name      : 'Statut',
                    field     : 'membership',
                    width     : 70,
                    component : (data: CustomerRoFront) => (
                        <Tag
                            value={data.membership}
                            style={{
                                backgroundColor:
                                    data.membership === EMembershipType.GOLD
                                        ? '#FFD700'
                                        : data.membership === EMembershipType.SILVER
                                        ? '#C0C0C0'
                                        : '#E4D5AD'
                            }}
                        />
                    )
                },
                {
                    name      : 'Type',
                    field     : 'category',
                    width     : 125,
                    component : (data: CustomerRoFront) => mapCustomerCategory(data.category)
                },
                {
                    name      : 'Responsable',
                    field     : 'admin_id',
                    width     : 200,
                    component : (data: CustomerRoFront) => {
                        const admin = data.admin_id[0] as AdminRo;
                        return <Tag key={admin.id} value={admin.fullname} />;
                    }
                },
                {
                    name      : 'Mode envoi',
                    field     : 'billing_shipment',
                    width     : 55,
                    component : (data: CustomerRoFront) => (
                        <>
                            <Tooltip
                                position="top"
                                target={`.customer-shipment-${data.id.replace(':', '')}`}
                                content={mapBillingShipment(data.billing_shipment)}
                            />
                            <i
                                title={mapBillingShipment(data.billing_shipment)}
                                className={`customer-shipment-${data.id.replace(':', '')} pi pi-${
                                    data.billing_shipment === EBillingShipment.EMAIL ? 'at' : 'envelope'
                                }`}
                            />
                        </>
                    )
                },
                {
                    name      : 'Autorisation',
                    field     : 'blocked',
                    width     : 100,
                    component : (data: CustomerRoFront) =>
                        data.blocked ? (
                            <Tag className="mr-2" severity="danger" value="Bloqué" />
                        ) : (
                            <Tag className="mr-2" severity="success" value="Autorisé" />
                        )
                },
                {
                    name      : 'Nombre de commandes',
                    field     : 'orderCount',
                    component : (data: CustomerRoFront) => {
                        if (!data.orderCount) {
                            return <span>Aucune commande</span>;
                        }
                        return (
                            <OrderLink
                                href={`${urlBuilder.orderList()}?customer_id=${data.id}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {data.orderCount}
                                <i className="pi pi-external-link" />
                            </OrderLink>
                        );
                    }
                },
                {
                    name      : 'Origine',
                    field     : 'created_from',
                    component : (data: CustomerRo) => <span>{mapCreatedFrom(data.created_from)}</span>
                },
                {
                    name      : 'Date de création',
                    field     : 'created_at',
                    component : (data: CustomerRo) => <span>{moment(data.created_at).format('DD/MM/YYYY')}</span>
                }
            ]}
        />
    );
};
