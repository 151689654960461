import Joi from 'joi';

import { ETruckType } from '.';
import { AddressCreateSchema } from '../address';
import { ETruckFuelType } from './truck';

export const TruckCharacteristicSchema = Joi.object({
    fuel_type: Joi.string()
        .valid(...Object.values(ETruckFuelType))
        .required(),
    max_autonomy                        : Joi.number().required(),
    remaining_autonomy                  : Joi.number(),
    fuel_refill_duration                : Joi.number().required(),
    // usable_volume_m3                    : Joi.number().required(),
    usable_load_kg                      : Joi.number().required(),
    setup_duration                      : Joi.number().required(),
    setdown_duration                    : Joi.number().required(),
    profitability_bag_number_limit      : Joi.number().required(),
    profitability_dumpster_number_limit : Joi.number().required(),
    dumpster_capacity                   : Joi.number().min(0).max(2),
    bigbag_capacity                     : Joi.number().min(0),
    bigbag_stock                        : Joi.number().min(0)
});

export const TruckCreateDtoSchema = Joi.object({
    test_name       : Joi.string(),
    name            : Joi.string().required(),
    garage_address  : AddressCreateSchema.required(),
    weight_ton      : Joi.number().required(),
    characteristics : TruckCharacteristicSchema.unknown(true),
    type            : Joi.array().items(
        Joi.string()
            .valid(...Object.values(ETruckType))
            .required()
    ),
    license_plate : Joi.string().invalid('_').required(),
    is_available  : Joi.boolean()
});

export const TruckEditDtoSchema = Joi.object({
    test_name       : Joi.string(),
    name            : Joi.string(),
    garage_address  : AddressCreateSchema,
    weight_ton      : Joi.number(),
    characteristics : TruckCharacteristicSchema.unknown(true),
    type            : Joi.array().items(Joi.string().valid(...Object.values(ETruckType))),
    license_plate   : Joi.string(),
    is_available    : Joi.boolean()
});
