import React, { useEffect } from 'react';
import { SMS_ORCHESTRATION_BASE_URL } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import { FlexContainer, ScrollableContainer, StyleSpan } from './style';
import { SMSRoFront } from '@bbng/util/types';
import moment from 'moment';
import useQuery from '../../hooks/Query';

type QueryParams = {
    recipient?: string;
};

// create a mapper that map twilio status message to french status

const TwilioStatusMessageMapper = (status: string): string => {
    switch (status) {
        case 'queued':
            return 'En attente';
        case 'sending':
            return 'En cours';
        case 'sent':
            return 'Envoyé';
        case 'failed':
            return 'Echoué';
        case 'delivered':
            return 'Reçu';
        case 'undelivered':
            return 'Non reçu';
        case 'receiving':
            return 'En cours de réception';
        case 'received':
            return 'Reçu';
        case 'read':
            return 'Lu';
        case 'accepted':
            return 'Accepté';
        case 'scheduled':
            return 'Planifié';
        case 'partially_delivered':
            return 'Partiellement livré';
        case 'canceled':
            return 'Annulé';
        default:
            return status;
    }
};

export const SMSListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [recipient, setRecipient] = React.useState<string | undefined>(query.recipient);

    return (
        <Listing<any, SMSRoFront>
            url={SMS_ORCHESTRATION_BASE_URL}
            endpoint="sms"
            hideBulkMenu
            displaySelectColumn={false}
            name="Liste des sms"
            hideCreateButton
            pluralName="SMS"
            searchPlaceholder="Rechercher destinataire numéro de télephone complet(+33)"
            headers={[
                {
                    name      : 'Message envoyé',
                    field     : 'message',
                    component : (data: SMSRoFront) => (
                        <FlexContainer>
                            <StyleSpan>{data.message}</StyleSpan>
                        </FlexContainer>
                    )
                },
                {
                    name      : 'Statut',
                    field     : 'status',
                    component : (data: SMSRoFront) => (
                        <FlexContainer>
                            <StyleSpan>{TwilioStatusMessageMapper(data.status)}</StyleSpan>
                        </FlexContainer>
                    )
                },
                {
                    name      : "Date d'envoi",
                    field     : 'send_date',
                    component : (data: SMSRoFront) => (
                        <FlexContainer>
                            <StyleSpan>{moment(data.send_date).format('DD-MM-YYYY')}</StyleSpan>
                        </FlexContainer>
                    )
                },
                {
                    name      : 'Destinataires',
                    field     : 'recipients',
                    component : (data: SMSRoFront) => (
                        <ScrollableContainer>
                            {data.recipients.split(',').map((recipient, index) => {
                                return (
                                    <StyleSpan key={index}>
                                        <span>{recipient}</span>
                                    </StyleSpan>
                                );
                            })}
                        </ScrollableContainer>
                    )
                }
            ]}
            showSearch={true}
        />
    );
};
