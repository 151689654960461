/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
/// <reference types="@types/google.maps" />
import React from 'react';

import { config } from '../../common/env';

// @ts-ignore
export type GoogleAPIType = typeof google;

// @ts-ignore
export type PlaceResult = google.maps.places.PlaceResult;

type GoogleMapsContext = {
    api?: GoogleAPIType;
};

export const GoogleMaps = React.createContext<GoogleMapsContext>({});

type GoogleMapsProviderProps = React.PropsWithChildren<{
    //
}>;

export const GoogleMapsProvider: React.FC<GoogleMapsProviderProps> = ({ children }) => {
    const [api, setGoogleAPI] = React.useState<GoogleAPIType>();

    React.useEffect(() => {
        loadGoogleMaps(config.googleApiKey, () => {
            // @ts-ignore
            setGoogleAPI(google);
            console.log('Google Maps API loaded');
        });

        return removeGoogleMaps;
    }, []);

    return <GoogleMaps.Provider value={{ api }}>{children}</GoogleMaps.Provider>;
};

export type GoogleMapsContextValue =
    | {
          isReady: true;
          api: GoogleAPIType;
      }
    | {
          isReady: false;
          api?: GoogleAPIType;
      };

export const useGoogleMapsContext = (): GoogleMapsContextValue => {
    const context = React.useContext(GoogleMaps);
    if (context === undefined) {
        throw new Error('useGoogleMapsContext must be used inside a GoogleMapsProvider');
    }

    if (context.api) {
        return { isReady: true, api: context.api };
    } else {
        return { isReady: false, api: context.api };
    }
};

export const loadGoogleMaps = (apiKey: string, callback: () => void) => {
    /**
     * Fetch our possibly already existing Google Maps API script.
     */
    const existingScript: HTMLElement | null = document.getElementById('googleMaps');

    /**
     * If the script is already loaded, we can just call the callback.
     */
    if (!existingScript) {
        const script: HTMLScriptElement = document.createElement('script');
        script.src = `https://maps.google.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.id = 'googleMaps';
        /**
         * Defer => script is not blocking and will be loaded aside of the other ressources
         */
        script.defer = true;
        /**
         * Async => script will be execute as soon as it is loaded.
         */
        script.async = true;
        document.body.appendChild(script);

        /**
         * Once the script is loaded, we can call the callback.
         */
        script.onload = () => {
            if (callback) callback();
        };
    } else {
        if (callback) callback();
    }
};

export const removeGoogleMaps = () => {
    const mapScript: HTMLElement | null = document.getElementById('googleMaps');

    if (mapScript) {
        /**
         * Detach our loaded script from the DOM.
         */
        if (mapScript.parentNode) mapScript.parentNode.removeChild(mapScript);

        /**
         * We have to find and remove from the head the google script imported by the library.
         */
        const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
        const scripts: HTMLCollectionOf<HTMLScriptElement> = head.getElementsByTagName('script');
        for (const index in scripts) {
            const script: HTMLScriptElement = scripts[index];
            const src: string = script.src;
            if (src?.startsWith('https://maps.google.com/maps')) {
                head.removeChild(script);
            }
        }
    }
};
