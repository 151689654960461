import styled from 'styled-components';
import React from 'react';
import { Tag } from 'primereact/tag';

import {
    CollectorListType,
    CollectorRoFront,
    ConstructionSiteQuery,
    ConstructionSiteRo,
    ConstructionSiteWithDocuments
} from '@bbng/util/types';

import { Card } from '../../components/Card';
import { useRequest } from '../../hooks/StatelessRequest';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { InputListBase } from '../InputList/base';
import { fetchDataRelation } from '../../common/dataRelation';

export type CollectorAccessListProps = {
    collector: CollectorRoFront;
};

export const CollectorAccessList = ({ collector }: CollectorAccessListProps) => {
    const request = useRequest();
    const [access, setAccess] = React.useState<ConstructionSiteWithDocuments[]>();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);

    const loadData = async () => {
        const list = await request<ConstructionSiteRo[]>({
            method  : 'GET',
            url     : urlApiBuilder.constructionSiteGetAll(),
            payload : {
                queryParams: { collectorIdOnList: collector.id } as ConstructionSiteQuery
            },
            sync: true
        });

        if (list.success) {
            const data = await fetchDataRelation<ConstructionSiteWithDocuments[]>(list.response?.data.ro, {
                customer_id: true
            });
            setAccess(data);
        } else {
            setError(true);
        }
    };

    React.useEffect(() => {
        setLoading(true);
        loadData().finally(() => setLoading(false));
    }, []);

    return (
        <StyledCard title="Liste d'accès">
            <CardLine>
                {loading ? (
                    <div style={{ width: '100%', textAlign: 'center' }}>Chargement des données...</div>
                ) : error ? (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        Une erreur est survenue lors du chargement des données.
                    </div>
                ) : (
                    <InputListBase<ConstructionSiteWithDocuments>
                        setErrors={() => void 0}
                        columns={[
                            {
                                header : 'Chantier',
                                body   : (e: ConstructionSiteWithDocuments) => {
                                    return (
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={urlBuilder.constructionSiteView(e.id)}
                                        >
                                            {e.label}
                                        </a>
                                    );
                                }
                            },
                            {
                                header : 'Client',
                                body   : (e: ConstructionSiteWithDocuments) => {
                                    return (
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={urlBuilder.customerView(e.customer_id[0]?.id)}
                                        >
                                            {e.customer_id[0]?.name}
                                        </a>
                                    );
                                }
                            },
                            {
                                header : 'Autorisation',
                                body   : (e: ConstructionSiteWithDocuments) => {
                                    return (
                                        <Tag
                                            value={
                                                e.list_collector_type === CollectorListType.BLACK_LIST
                                                    ? 'Non autorisé'
                                                    : 'Autorisé'
                                            }
                                            severity={
                                                e.list_collector_type === CollectorListType.BLACK_LIST
                                                    ? 'danger'
                                                    : 'success'
                                            }
                                        />
                                    );
                                }
                            }
                        ]}
                        disableAddButton
                        disableEditButton
                        disableModalButton
                        disableDeleteButton
                        value={access}
                    />
                )}
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
