import React, { useRef } from 'react';

import { LandfillRo } from '@bbng/util/types';
import styled from 'styled-components';
import moment from 'moment';
import { CardType, GlobalConfigCCEmptying, MonitorItem, useGlobalConfigContext } from './provider';
import { Card } from './card';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Dialog } from '../../../components/Dialog';
import RelationAutocomplete from '../../common/RelationAutocomplete';
import { InputNumber } from 'primereact/inputnumber';

export type CardCCDragItem = {
    bucketIndex: number;
    landfill: LandfillRo;
    planningId: string;
};
type CardEmptyingProps = {
    item: GlobalConfigCCEmptying;
    columnId: string;
    columnIndex: number;
};
export const CardEmptying: React.FC<CardEmptyingProps> = ({ item, columnId, columnIndex }) => {
    const { landfill, waitingTimeMinutes } = item;

    const monitorItem = React.useMemo<MonitorItem<GlobalConfigCCEmptying>>(
        () => ({ columnId, columnIndex, type: item.category, extra: item, id: item.gc_id }),
        [item, columnIndex, columnId]
    );

    const refMenu = useRef<Menu>(null);
    const [selectedLandill, setSelectedLandill] = React.useState<LandfillRo | undefined>(landfill);
    const [waitingTimeMinutesState, setWaitingTimeMinutesState] = React.useState<number>(waitingTimeMinutes);
    const [action, setAction] = React.useState<'MODIFY' | 'DELETE' | null>(null);
    const { updateLandfillCard, deleteLandfillCard } = useGlobalConfigContext();

    const resetModalStates = React.useCallback(() => {
        setSelectedLandill(landfill);
        setWaitingTimeMinutesState(waitingTimeMinutes);
    }, [landfill, waitingTimeMinutes]);

    const deleteCard = React.useCallback(() => {
        deleteLandfillCard(monitorItem);
    }, [deleteLandfillCard]);

    const updateCard = React.useCallback(() => {
        if (!selectedLandill) return;
        updateLandfillCard(monitorItem, selectedLandill, waitingTimeMinutesState);
        setAction(null);
    }, [updateLandfillCard, selectedLandill, waitingTimeMinutesState]);

    const timeFormat = React.useMemo(() => {
        return moment().startOf('day').add(waitingTimeMinutes, 'minutes').format('[Durée:] HH[h]mm');
    }, [waitingTimeMinutes]);

    const options: MenuItem[] = React.useMemo<MenuItem[]>(
        () => [
            {
                label   : 'Modifier',
                icon    : 'pi pi-fw pi-pencil',
                command : () => {
                    setAction('MODIFY');
                }
            },
            {
                label   : 'Supprimer',
                icon    : 'pi pi-fw pi-trash',
                command : () => {
                    setAction('DELETE');
                }
            }
        ],
        []
    );

    return (
        <Card type={CardType.EMPTYING} item={monitorItem}>
            <StyledCard>
                <div className="card-header">
                    <div className="card-heading">
                        <i>Passage en déchèterie</i>
                        <b>{timeFormat}</b>
                    </div>
                    <Button
                        icon="pi pi-ellipsis-v"
                        className="button-more p-button-text"
                        onClick={(event) => {
                            resetModalStates();
                            refMenu.current?.toggle(event);
                        }}
                    />
                </div>
                <div className="card-collect-info">
                    <p>{landfill.address.formatted_name}</p>
                </div>
                <div className="card-customer-info">
                    <p>{landfill.name}</p>
                </div>
                <Menu ref={refMenu} model={options} popup />
                <Dialog
                    onHide={() => {
                        setAction(null);
                    }}
                    visible={action === 'MODIFY'}
                    header={<h3 style={{ margin: 0, marginRight: '16px' }}>Modifier le passage en déchèterie</h3>}
                >
                    <h3 style={{ marginTop: 0 }}>Déchèterie</h3>
                    <RelationAutocomplete.Landfill
                        baseValue={selectedLandill}
                        onSelect={(e) => e && setSelectedLandill(e)}
                        onUnselect={() => setSelectedLandill(undefined)}
                    />
                    <h3>Temps de vidage (minutes)</h3>
                    <InputNumber
                        size={6}
                        value={waitingTimeMinutesState}
                        onChange={(e) => e.value !== null && setWaitingTimeMinutesState(e.value)}
                    />

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        <Button
                            label="Annuler"
                            className="p-button-text"
                            onClick={() => {
                                setAction(null);
                            }}
                        />
                        <Button
                            label="Valider"
                            className="p-button-text"
                            disabled={!selectedLandill}
                            onClick={() => {
                                setAction(null);
                                updateCard();
                            }}
                        />
                    </div>
                </Dialog>
                <Dialog
                    onHide={() => {
                        setAction(null);
                    }}
                    visible={action === 'DELETE'}
                    header={<h3 style={{ margin: 0, marginRight: '16px' }}>Supprimer le passage en déchèterie</h3>}
                >
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        <Button
                            label="Annuler"
                            className="p-button-text"
                            onClick={() => {
                                setAction(null);
                            }}
                        />
                        <Button
                            label="Valider"
                            className="p-button-text"
                            onClick={() => {
                                setAction(null);
                                deleteCard();
                            }}
                        />
                    </div>
                </Dialog>
            </StyledCard>
        </Card>
    );
};

const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 6px;
    height: 85px;
    width: 200px;
    background-color: #c0b6dd;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    text-align: center;
    font-size: 0.7rem;
    p {
        margin: 0;
    }
    .card-header {
        position: relative;
    }
    .button-more {
        padding: 0;
        margin: 0;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        color: black;
    }
    .card-heading,
    .card-customer-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        font-weight: 300;
        color: #616161;
    }
    .card-collect-info {
        display: flex;
        flex-direction: column;
    }
`;
