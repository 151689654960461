import { Avatar } from 'primereact/avatar';
import { Tooltip } from 'primereact/tooltip';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { urlBuilder } from '../../common/urlBuilder';
import { useAuth } from '../../hooks/Auth';
import { SpeedDialContainer, StyledSpeedDial } from './style';

const fabItems = (navigate: NavigateFunction, logout: (redirect: true) => void) => [
    {
        label   : 'Déconnexion',
        icon    : 'pi pi-power-off',
        command : () => logout(true)
    },
    {
        label   : 'Nouveau client',
        icon    : 'pi pi-user-plus',
        command : () => navigate(urlBuilder.customerCreate())
    },
    {
        label   : 'Nouvelle commande',
        icon    : 'pi pi-shopping-bag',
        command : () => navigate(urlBuilder.orderCreate())
    }
];

const Fab: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    const { user, logout } = useAuth({ withUser: true });

    return (
        <SpeedDialContainer>
            <Tooltip target=".fab .p-speeddial-action" position="bottom" />
            <StyledSpeedDial
                className="fab"
                model={fabItems(navigate, logout)}
                direction="left"
                type="linear"
                buttonTemplate={(item) => {
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={item.onClick}>
                            <Avatar
                                size="large"
                                shape="circle"
                                // image={user?.picture}
                                imageAlt="avatar"
                                label={(user?.firstname?.charAt(0) ?? user?.lastname?.charAt(0) ?? '').toUpperCase()}
                            />
                        </div>
                    );
                }}
            />
        </SpeedDialContainer>
    );
};

export default Fab;
