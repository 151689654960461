import styled from 'styled-components';

export const SnackBarContainer = styled.div`
    position: fixed;
    min-height: 50px;
    bottom: 0;
    left: 60px;
    width: calc(100% - 60px);
    color: black;
    display: grid;
    place-items: center;
    background-color: var(--yellow-300);
    z-index: 100;
`;
