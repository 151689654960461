import React from 'react';

import { SnackBarContainer } from './style';

export type SnackBarProps = {
    show?: boolean;
    content: string | React.ReactNode;
};

const SnackBar: React.FC<SnackBarProps> = ({ show = false, content }: SnackBarProps) => {
    if (!show) return <></>;
    return <SnackBarContainer>{content}</SnackBarContainer>;
};

export default SnackBar;
