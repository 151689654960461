import { SelectButton, SelectButtonChangeParams, SelectButtonProps } from 'primereact/selectbutton';
import React, { useState } from 'react';
import * as yup from 'yup';

import { match } from '@bbng/util/misc';

export type GroupButtonProps = {
    readOnly?: boolean;
    forceSelection?: boolean;
    required?: boolean;
    result: (value: string | string[], error: null | string[], id: string) => void;
    id: string;
    validationSchema?: yup.AnySchema;
    options: string[];
    multiple?: boolean;
    value?: string | string[];
    errors?: string[] | null;
    labelMapper?: (value: string) => string;
    displayError?: boolean;
    optionDisabled?: SelectButtonProps['optionDisabled'];
};

export function GroupButton({
    forceSelection = false,
    readOnly = false,
    required = false,
    result,
    id,
    validationSchema,
    options,
    optionDisabled,
    multiple = false,
    value = [],
    errors,
    labelMapper = (label: string) => label,
    displayError
}: GroupButtonProps) {
    const [val, setVal] = useState<string | string[]>(value);
    const [err, setErr] = useState<null | string[]>(errors ?? null);
    const [displayErr, setDisplayErr] = React.useState<boolean>(displayError ?? false);

    const baseValidationSchema = yup.mixed().when({
        is        : Array.isArray,
        then      : yup.array().of(yup.string()),
        otherwise : yup.string()
    });

    const schema = validationSchema
        ? validationSchema
        : required
        ? baseValidationSchema.required(`${id} requis`)
        : baseValidationSchema;

    const _checkValidationSchema = (val: string | string[]) => {
        try {
            schema.validateSync(val, { abortEarly: false });
        } catch (err) {
            setErr(err.errors);
            result(val, err.errors, id);
        }
    };

    const _checkDescendingValue = React.useCallback(
        async (stateValue: string | string[], propsValue: string | string[]): Promise<void> => {
            if (stateValue !== propsValue) {
                setVal(propsValue);
                if (Array.isArray(propsValue)) {
                    setErr(null);
                    result(propsValue, null, id);
                    if (propsValue.length > 0) {
                        _checkValidationSchema(propsValue);
                        setDisplayErr(true);
                    }
                } else {
                    setErr(null);
                    result(propsValue, null, id);
                    if (propsValue) {
                        _checkValidationSchema(propsValue);
                        setDisplayErr(true);
                    }
                }
            }
        },
        [setVal, setErr, result]
    );

    const _checkDescendingErrors = React.useCallback(
        async (stateError: null | string[], propsError: null | string[]): Promise<void> => {
            if (match(stateError, propsError) === false) {
                setErr(propsError);
                result(val, propsError, id);
                setDisplayErr(true);
            }
        },
        [setVal, setErr, result]
    );

    React.useEffect(() => {
        _checkDescendingValue(val, value);
        if (errors !== undefined) {
            _checkDescendingErrors(err, errors);
        }
    }, [value, errors]);

    React.useEffect(() => {
        setDisplayErr(displayError ?? false);
    }, [displayError]);

    React.useEffect(() => {
        if (required) {
            _checkValidationSchema(val);
        }
    }, [required]);

    const handleOnChange = React.useCallback(
        async (e: SelectButtonChangeParams): Promise<void> => {
            if (e.value === null && forceSelection) return;

            const newVal = e.value || value;
            setVal(newVal);

            try {
                await schema.validate(newVal, { abortEarly: false });
                setErr(null);
                result(newVal, null, id);
            } catch (err) {
                setErr(err.errors);
                result(newVal, err.errors, id);
            }
        },
        [setVal, setErr, result, id]
    );

    return (
        <SelectButton
            disabled={readOnly}
            options={options.map((option: string) => ({ label: labelMapper(option), value: option }))}
            onChange={handleOnChange}
            optionLabel="label"
            optionValue="value"
            multiple={multiple}
            optionDisabled={optionDisabled}
            value={val}
            className={`p-button-sm ${displayErr && err && err.length > 0 ? 'p-invalid' : ''}`}
            tooltip={displayErr && err && err.length > 0 ? err.join('\n') : ''}
        />
    );
}
