import { UserConstructionSites, UserConstructionSitesProps } from './ConstructionSites';
import { UserCustomer, UserCustomerProps } from './Customer';
import { UserGeneral, UserGeneralProps } from './General';

const Modules = {
    GeneralInfo: (props: UserGeneralProps) => {
        return <UserGeneral {...props} />;
    },
    Customer: (props: UserCustomerProps) => {
        return <UserCustomer {...props} />;
    },
    ConstructionSites: (props: UserConstructionSitesProps) => {
        return <UserConstructionSites {...props} />;
    }
};

export default Modules;
