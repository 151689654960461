import {
    AddressRo,
    CCServiceRo,
    CC_STATUS,
    CollectEmptyingFront,
    CollectLateCancelationFront,
    CollectRoFront,
    CollectServiceFront,
    PlanningShiftStepCategory
} from '@bbng/util/types';

import { mapCollectCategoryText } from '../../common/enumMapper';

export const isService = (data?: CollectRoFront): data is CollectServiceFront =>
    data?.informations.step_category === PlanningShiftStepCategory.SERVICE;
export const isEmptying = (data?: CollectRoFront): data is CollectEmptyingFront =>
    data?.informations.step_category === PlanningShiftStepCategory.EMPTYING;
export const isServiceInformation = (
    info?: CollectRoFront['informations']
): info is CollectServiceFront['informations'] => info?.step_category === PlanningShiftStepCategory.SERVICE;
export const isEmptyingInformation = (
    info?: CollectRoFront['informations']
): info is CollectEmptyingFront['informations'] => info?.step_category === PlanningShiftStepCategory.EMPTYING;
export const isLateCancelation = (data?: CollectRoFront): data is CollectLateCancelationFront =>
    data?.informations.step_category === PlanningShiftStepCategory.LATE_CANCELATION;
export const displayAccurateTitle = (data?: CollectRoFront): string => {
    if (!data) return 'Collecte';
    if ([PlanningShiftStepCategory.ADMINISTRATIVE, PlanningShiftStepCategory.SERVICE].includes(data.category)) {
        const serviceCollect = isService(data) ? data : undefined;
        const informationService = isServiceInformation(serviceCollect?.informations)
            ? serviceCollect?.informations
            : undefined;

        return `Collecte ${
            (informationService?.collect_config_id as CCServiceRo).status === CC_STATUS.SPLITTED ? 'scindée' : ''
        } ${(informationService?.collect_config_id as CCServiceRo).number} - ${mapCollectCategoryText(
            data.category
        ).toLowerCase()} `;
    }
    return `Collecte - ${mapCollectCategoryText(data.category).toLowerCase()}`;
};

export const displayAddress = (address: AddressRo): string => {
    return `${address?.components['street_number']} ${address?.components['route']}, ${address?.components['postal_code']} ${address?.components['locality']}`;
};
