import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import {
    CardErrors,
    CCServiceRo,
    CC_FAMILY,
    CC_STATUS,
    CollectConfigQuery,
    DumpsterOnSiteQuery,
    DumpsterOnSiteRo,
    isDumpsterOnSiteRo,
    PRODUCT_FAMILY
} from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import { textInputProps } from '../../components/Inputs';
import { OrderProductsState } from '../order/Products';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { dumpsterOnSiteListHeaders } from '../../pages/DumpstersOnSite/headers';
import { Skeleton } from 'primereact/skeleton';
import { fetchDataRelation } from '../../common/dataRelation';
import { Button } from 'primereact/button';
import { toast } from '../../components/Toast';

export type DumpsterOnSiteSelectProps = {
    readOnly?: boolean;
    value?: DumpsterOnSiteSelectState;
    id: string;
    result: (
        value: DumpsterOnSiteSelectState,
        errors: null | string[] | CardErrors<DumpsterOnSiteSelectState>,
        id: string
    ) => void;
    displayError?: boolean;
    customer_id?: string;
    construction_site_id?: string;
    products: OrderProductsState;
    currentCcId?: string;
};

export type DumpsterOnSiteSelectState = {
    dumpster_on_site?: DumpsterOnSiteRo;
    collect_config?: CCServiceRo;
};

export type DumpsterOnSiteSelectErrorState = CardErrors<DumpsterOnSiteSelectState>;

export const initialState: DumpsterOnSiteSelectState = {
    dumpster_on_site : undefined,
    collect_config   : undefined
};

export const initialErrorState: DumpsterOnSiteSelectErrorState = generateInitialErrorState(initialState);

export const initialRef = Array.from(Array(Object.keys(initialState).length)).map(() =>
    React.createRef<textInputProps>()
);

export const DumpsterOnSiteSelect = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false,
    customer_id,
    construction_site_id,
    products,
    currentCcId
}: DumpsterOnSiteSelectProps) => {
    const [val, setVal] = React.useState<DumpsterOnSiteSelectState>(value);
    const [err, setErr] = React.useState<DumpsterOnSiteSelectErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    const [dumpstersOnSite, setDumpstersOnSite] = React.useState<DumpsterOnSiteRo[]>([]);
    const [depositCCs, setDepositCCs] = React.useState<CCServiceRo[]>([]);
    const bbngRequest = useRequest();
    const [isDumpsterOnSiteFetchLoading, setIsDumpsterOnSiteFetchLoading] = React.useState(false);
    const [isDepositCCLoading, setIsDepositCCLoading] = React.useState(false);

    const dumpsterOnSiteListHeadersLight = useMemo(
        () =>
            dumpsterOnSiteListHeaders.filter((header) => {
                /**
                 * If read only, hide customer, construction site and linked retrieval cc columns.
                 */
                if (readOnly)
                    return (
                        header.field !== 'customer_id' &&
                        header.field !== 'construction_site_id' &&
                        header.field !== 'retrieval_collect_config_id'
                    );
                /**
                 * If customer and construction site are already selected, hide customer and construction site columns.
                 */
                if (customer_id && construction_site_id)
                    return header.field !== 'customer_id' && header.field !== 'construction_site_id';
                return true;
            }),
        [customer_id, construction_site_id, readOnly]
    );

    const retrievalProduct = useMemo(() => {
        if (!products) return undefined;
        return Object.values(products).find(
            (product) =>
                product.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL &&
                !product.operational &&
                product.quantity > 0
        );
    }, [products]);

    const fetchDumpsterOnSite = useCallback(async () => {
        if ((customer_id || construction_site_id) && retrievalProduct) {
            setIsDumpsterOnSiteFetchLoading(true);
            let data: DumpsterOnSiteRo[] = [];
            if (readOnly) {
                /**
                 * If read only, use initial value (no need to fetch all dumpsters on site)
                 */
                data = val.dumpster_on_site ? [val.dumpster_on_site] : [];
            } else {
                const res = await fetchDataRelation<StatelessResponse<DumpsterOnSiteRo[]>>(
                    await bbngRequest<DumpsterOnSiteRo[]>({
                        url     : urlApiBuilder.dumpsterOnSiteGetAll(),
                        method  : 'GET',
                        payload : {
                            queryParams: {
                                customer_id,
                                construction_site_id,
                                archived          : false,
                                no_limit          : true,
                                exclude_retrieved : true
                            } as DumpsterOnSiteQuery
                        }
                    }),
                    {
                        construction_site_id        : true,
                        customer_id                 : true,
                        retrieval_collect_config_id : true
                    }
                );
                if (res.success) {
                    data = res.response?.data.ro || [];
                }
            }
            /**
             * Do not display dumpsters on site with planned retrieval cc unless it's current cc.
             */
            data = data.filter((dumpsterOnSite) => {
                const ccs = dumpsterOnSite.retrieval_collect_config_id as unknown as CCServiceRo[];
                const isPlanned = ccs.some((cc) => [CC_STATUS.PLANNED, CC_STATUS.TO_PLAN].includes(cc.status as any));
                const isCurrent = ccs.some((cc) => cc.id === currentCcId);
                return !isPlanned || isCurrent;
            });
            setDumpstersOnSite(data);
            setIsDumpsterOnSiteFetchLoading(false);
        }
    }, [construction_site_id, customer_id, setDumpstersOnSite, retrievalProduct, currentCcId]);

    const fetchDepositCCs = useCallback(async () => {
        if ((customer_id || construction_site_id) && retrievalProduct) {
            setIsDepositCCLoading(true);
            let data: CCServiceRo[] = [];
            if (readOnly) {
                /**
                 * If read only, use initial value (no need to fetch all dumpsters on site)
                 */
                data = val.collect_config ? [val.collect_config] : [];
            } else {
                const res = await fetchDataRelation<StatelessResponse<CCServiceRo[]>>(
                    await bbngRequest<CCServiceRo[]>({
                        url     : urlApiBuilder.collectConfigReadAll(),
                        method  : 'GET',
                        payload : {
                            queryParams: {
                                customer_id,
                                construction_site_id,
                                families : [CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT, CC_FAMILY.COLLECT_DUMPSTER_ROTATION],
                                statuses : [
                                    CC_STATUS.PLANNED,
                                    CC_STATUS.TO_PLAN,
                                    CC_STATUS.ORDER_TO_PAY,
                                    CC_STATUS.WAITING_FOR_APPROVAL
                                ],
                                archived : false,
                                no_limit : true
                            } as CollectConfigQuery
                        }
                    }),
                    {
                        construction_site_id        : true,
                        customer_id                 : true,
                        retrieval_collect_config_id : true
                    }
                );
                if (res.success) {
                    data = res.response?.data.ro || [];
                }
            }
            /**
             * Do not display dumpsters on site with planned retrieval cc or with same id than current cc.
             */
            data = data.filter((dumpsterOnSite) => {
                const ccs = dumpsterOnSite.retrieval_collect_config_id as unknown as CCServiceRo[];
                const isPlanned = ccs.some((cc) => [CC_STATUS.PLANNED, CC_STATUS.TO_PLAN].includes(cc.status as any));
                const isCurrent = dumpsterOnSite.id === currentCcId;
                if (isCurrent) return false;
                return !isPlanned;
            });
            setDepositCCs(data);
            setIsDepositCCLoading(false);
        }
    }, [construction_site_id, customer_id, setDepositCCs, retrievalProduct, currentCcId]);

    const fetchData = useCallback(async () => {
        fetchDumpsterOnSite();
        fetchDepositCCs();
    }, [construction_site_id, customer_id, setDepositCCs, retrievalProduct, currentCcId]);

    useEffect(() => {
        fetchData();
    }, [customer_id, construction_site_id, retrievalProduct, currentCcId]);

    useEffect(() => {
        if (retrievalProduct && !val.dumpster_on_site) {
            setErr((prev) => ({ ...prev, dumpster_on_site: ['Veuillez sélectionner une benne sur chantier'] }));
        }
        if (!retrievalProduct) {
            setErr((prev) => ({ ...prev, dumpster_on_site: null }));
        }
    }, [retrievalProduct]);

    const listValues: Array<CCServiceRo | DumpsterOnSiteRo> = useMemo(() => {
        if (isDumpsterOnSiteFetchLoading || isDepositCCLoading) {
            return Array.from({ length: 3 });
        }
        if (readOnly) {
            if (val.dumpster_on_site) {
                return [val.dumpster_on_site];
            } else if (val.collect_config) {
                return [val.collect_config];
            } else {
                return [];
            }
        } else {
            return [...dumpstersOnSite, ...depositCCs];
        }
    }, [readOnly, val.dumpster_on_site, dumpstersOnSite, depositCCs, isDumpsterOnSiteFetchLoading, isDepositCCLoading]);

    if (!retrievalProduct) return null;

    return (
        <StyledCard
            title={
                <>
                    <Title>
                        <span>Benne sur chantier</span>
                        {!readOnly && (
                            <>
                                <Button
                                    className="p-button-text"
                                    icon="pi pi-plus"
                                    label="Créer une benne sur chantier"
                                    onClick={() =>
                                        window.open(`${urlBuilder.dumpsterOnSiteCreate()}?&on_success=close`, '_blank')
                                    }
                                    type="button"
                                />
                                <Button
                                    className="p-button-text p-button-secondary"
                                    icon="pi pi-refresh"
                                    label="Rafraîchir"
                                    onClick={fetchData}
                                    type="button"
                                />
                            </>
                        )}
                    </Title>
                    {!readOnly && <Helper>Toutes les bennes déposées ainsi que les dépôts prévus.</Helper>}
                </>
            }
        >
            <CardLine>
                <DataTable
                    emptyMessage={readOnly ? 'Aucune benne associée' : 'Aucune benne compatible trouvée'}
                    value={listValues}
                    dataKey="id"
                    selectionMode={readOnly ? undefined : 'single'}
                    selection={val.dumpster_on_site ?? val.collect_config}
                    onSelectionChange={(e) => {
                        /**
                         * Block selection for deposit collects with linked retrieval.
                         */
                        if (
                            !isDumpsterOnSiteRo(e.value) &&
                            (e.value as CCServiceRo)?.retrieval_collect_config_id?.length > 0
                        ) {
                            toast({
                                severity : 'error',
                                summary  : 'Erreur',
                                life     : 10_000,
                                detail   : "Impossible de sélectionner un dépôt prévu avec un enlèvement déjà associé (veuillez modifier l'enlèvement en question si besoin)."
                            });
                            return;
                        }
                        if (isDumpsterOnSiteRo(e.value)) {
                            handleChange(e.value, null, 'dumpster_on_site');
                            handleChange(undefined, null, 'collect_config');
                        } else {
                            handleChange(e.value, null, 'collect_config');
                            handleChange(undefined, null, 'dumpster_on_site');
                        }
                    }}
                >
                    {!readOnly && <Column selectionMode="single" />}
                    {dumpsterOnSiteListHeadersLight.map((header) => (
                        <Column
                            key={header.name}
                            field={header.field}
                            header={header.name}
                            body={isDumpsterOnSiteFetchLoading || isDepositCCLoading ? <Skeleton /> : header.component}
                        />
                    ))}
                </DataTable>
            </CardLine>
            {displayError && err.dumpster_on_site && (
                <div style={{ color: 'red' }}>{err.dumpster_on_site.join(', ')}</div>
            )}
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const Helper = styled.div`
    font-size: 0.8rem;
    font-weight: 300;
    color: #666;
    margin-top: 8px;
`;
