import { ToggleButton, ToggleButtonChangeParams } from 'primereact/togglebutton';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

export const InputToggleContainer = styled.div`
    display: flex;
    justify-content: left;
    flex-direction: 'row';
    align-items: center;
    gap: 16px;
`;

export type ToggleButtonProps = {
    label: { on: string; off: string };
    value: boolean;
    id: string;
    result: (value: boolean, errors: null | string[], id: string) => void;
    readOnly?: boolean;
};

export const ToggleBtn: React.FC<ToggleButtonProps> = ({
    label,
    value,
    id,
    result,
    readOnly
}: ToggleButtonProps): JSX.Element => {
    const [val, setVal] = useState<boolean>(value);
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const handleOnChange = useCallback(
        (e: ToggleButtonChangeParams): void => {
            if (!readOnly) {
                setVal(e.target.value);
                result(e.target.value, null, id);
            }
        },
        [setVal, result, id]
    );

    return (
        <InputToggleContainer
            style={{
                opacity: readOnly ? '60%' : '100%'
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <ToggleButton
                onChange={handleOnChange}
                checked={val}
                onLabel={label.on}
                offLabel={label.off}
                style={{ cursor: readOnly && isHover ? 'not-allowed' : 'pointer' }}
            />
        </InputToggleContainer>
    );
};
