import {
    CollectService,
    ConstructionSiteRo,
    CustomerRo,
    DumpsterOnSiteCreateDto,
    DumpsterOnSiteEditDto,
    DumpsterOnSiteRo,
    FrontRo,
    ProductRo
} from '@bbng/util/types';
import moment from 'moment';

import { getRelationsDto, optimiseEditDto } from '../../common/form';
import {
    DumpsterOnSiteGeneralErrorState,
    DumpsterOnSiteGeneralState,
    initialErrorState as dumpsterOnSiteGeneralInitialErrorState,
    initialState as dumpsterOnSiteGeneralInitialState
} from '../../modules/dumpster-on-site/General';
import {
    OrderProductsState,
    OrderProductsErrorState,
    initialErrorState as productInfoInitialErrorState,
    initialState as productInfoInitialState,
    ProductRoWithQuantity
} from '../../modules/order/Products';
import {
    OrderTrashTypeErrorState,
    OrderTrashTypeState,
    initialErrorState as orderTrashTypeInitialErrorState,
    initialState as orderTrashTypeInitialState
} from '../../modules/order/TrashType';

export type DumpsterOnSiteModulesStates = DumpsterOnSiteGeneralState | OrderTrashTypeState | OrderProductsState;

export type DumpsterOnSiteModulesErrorStates =
    | DumpsterOnSiteGeneralErrorState
    | OrderTrashTypeErrorState
    | OrderProductsErrorState;

export type DumpsterOnSiteFormState = {
    general: DumpsterOnSiteGeneralState;
    product: OrderProductsState;
    trashType: OrderTrashTypeState;
};

export type DumpsterOnSiteFormErrorState = {
    general: DumpsterOnSiteGeneralErrorState;
    product: OrderProductsErrorState;
    trashType: OrderTrashTypeErrorState;
};

export const initialState: DumpsterOnSiteFormState = {
    general   : dumpsterOnSiteGeneralInitialState,
    product   : productInfoInitialState,
    trashType : orderTrashTypeInitialState
};

export const initialErrorState: DumpsterOnSiteFormErrorState = {
    general   : dumpsterOnSiteGeneralInitialErrorState,
    product   : productInfoInitialErrorState,
    trashType : orderTrashTypeInitialErrorState
};

export const mapApiDataToState = (dumpsterOnSite: FrontRo<DumpsterOnSiteRo>): DumpsterOnSiteFormState => {
    let construction_site = undefined;
    if (dumpsterOnSite.construction_site_id && dumpsterOnSite.construction_site_id.length > 0) {
        construction_site = dumpsterOnSite.construction_site_id[0] as ConstructionSiteRo;
    }

    let customer = undefined;
    if (dumpsterOnSite.customer_id && dumpsterOnSite.customer_id.length > 0) {
        customer = dumpsterOnSite.customer_id[0] as CustomerRo;
    }

    let deposit_collect = undefined;
    if (dumpsterOnSite.deposit_collect_id && dumpsterOnSite.deposit_collect_id.length > 0) {
        deposit_collect = dumpsterOnSite.deposit_collect_id[0] as CollectService;
    }

    let retrieval_collect = undefined;
    if (dumpsterOnSite.retrieval_collect_id && dumpsterOnSite.retrieval_collect_id.length > 0) {
        retrieval_collect = dumpsterOnSite.retrieval_collect_id[0] as CollectService;
    }

    let product: ProductRoWithQuantity | undefined = undefined;
    if (dumpsterOnSite.product) {
        product = {
            ...(dumpsterOnSite.product as unknown as ProductRo),
            quantity: 1
        };
    }

    let productState: OrderProductsState = {};
    if (product) {
        productState = {
            [product.id]: product
        };
    }

    return {
        general: {
            customer,
            construction_site,
            deposit_collect,
            retrieval_collect,
            deposited_at      : dumpsterOnSite.deposited_at,
            retrieved_at      : dumpsterOnSite.retrieved_at ?? moment().toISOString(),
            already_retrieved : dumpsterOnSite.retrieved_at !== null,
            comment           : dumpsterOnSite.comment ?? ''
        },
        product   : productState,
        trashType : {
            trashType: product?.trash_type
        }
    };
};

export const mapStateToApiCreateData = (state: DumpsterOnSiteFormState): DumpsterOnSiteCreateDto => {
    const selectedProduct = Object.values(state.product).filter((product) => product.quantity > 0)[0];
    return {
        construction_site_id : state.general.construction_site?.id,
        deposited_at         : state.general.deposited_at,
        retrieved_at         : state.general.already_retrieved ? state.general.retrieved_at : null,
        product_id           : selectedProduct.id,
        comment              : state.general.comment,
        deposit_collect_id   : state.general.deposit_collect?.id,
        retrieval_collect_id : state.general.retrieval_collect?.id
    } as DumpsterOnSiteCreateDto;
};

export const mapStateToApiEditData = (
    state: DumpsterOnSiteFormState,
    apiState: DumpsterOnSiteFormState,
    dumpsterOnSite: FrontRo<DumpsterOnSiteRo>
): DumpsterOnSiteEditDto =>
    optimiseEditDto(
        state,
        apiState,
        dumpsterOnSite,
        mapStateToApiCreateData,
        getRelationsDto<DumpsterOnSiteRo>(),
        true
    );
