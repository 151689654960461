import axios from 'axios';
import moment from 'moment';

import { AuthTokenInfo, IBbngResponse, AccessRo, RefreshTokenDto } from '@bbng/util/types';

import { urlApiBuilder } from './urlBuilder';

export enum LocalStorageAuthKey {
    ACCESS_TOKEN = 'bbng_access_token',
    REFRESH_TOKEN = 'bbng_refresh_token',
    EXPIRE_AT = 'bbng_expire_at,'
}

export type FrontAuthTokenInfo = {
    accessToken: string;
    refreshToken: string;
    expireAt: string;
};
export class CredentialsManager {
    private isRefreshing = false;

    get tokenInfo(): FrontAuthTokenInfo {
        return {
            accessToken  : window.localStorage.getItem(LocalStorageAuthKey.ACCESS_TOKEN) ?? '',
            refreshToken : window.localStorage.getItem(LocalStorageAuthKey.REFRESH_TOKEN) ?? '',
            expireAt     : window.localStorage.getItem(LocalStorageAuthKey.EXPIRE_AT) ?? moment().toISOString()
        };
    }

    private awaitRefresh = async (): Promise<FrontAuthTokenInfo> => {
        await new Promise((resolve) => {
            const interval = setInterval(() => {
                if (!this.isRefreshing) {
                    clearInterval(interval);
                    resolve('');
                }
            }, 100);
        });

        return this.tokenInfo;
    };

    public getAccessToken = async (): Promise<string> => {
        if (this.isRefreshing) {
            console.log('getAccessToken: waiting for refresh...');
            const info = await this.awaitRefresh();
            return info.accessToken;
        }
        const { accessToken, expireAt } = this.tokenInfo;

        const now = moment();
        const expire = moment(expireAt);
        const diff = expire.diff(now, 'minutes');

        if (diff < 5) {
            console.log('Token will expire in less than 5 minutes, refreshing...');
            const refreshRes = await this.refreshCredentials();
            return refreshRes?.accessToken ?? '';
        }
        return accessToken ?? '';
    };

    public updateCredentials = (info: AuthTokenInfo): FrontAuthTokenInfo => {
        const { access_token, expires_in, refresh_token } = info;
        const expireAt: string = moment().add(expires_in, 'seconds').toISOString();

        window.localStorage.setItem(LocalStorageAuthKey.ACCESS_TOKEN, access_token);
        window.localStorage.setItem(LocalStorageAuthKey.REFRESH_TOKEN, refresh_token);
        window.localStorage.setItem(LocalStorageAuthKey.EXPIRE_AT, expireAt);

        return {
            accessToken  : access_token,
            refreshToken : refresh_token,
            expireAt
        };
    };

    public refreshCredentials = async (): Promise<FrontAuthTokenInfo | null> => {
        if (this.isRefreshing) {
            console.log('refreshCredentials: waiting for refresh...');
            return this.awaitRefresh();
        }
        console.log('refreshCredentials: refreshing...');
        this.isRefreshing = true;
        const refreshToken: string | null = window.localStorage.getItem(LocalStorageAuthKey.REFRESH_TOKEN);
        try {
            const { data: bbngResponse } = await axios.post<IBbngResponse<AccessRo>>(
                urlApiBuilder.adminAuthRefreshToken(),
                {
                    refresh_token: refreshToken ?? ''
                } as RefreshTokenDto
            );

            if (bbngResponse.success && bbngResponse.data.ro) {
                const creds = this.updateCredentials(bbngResponse.data.ro);
                this.isRefreshing = false;
                return creds;
            }
        } catch (err) {
            console.error(err);
        }
        this.isRefreshing = false;
        return null;
    };
}
