import { EPlanningType, ProductInCCOrCO, PRODUCT_FAMILY } from '@bbng/util/types';

export type TEmailOrderConfirmationPrestationParams = {
    buttonText: string;
    expirationTime?: string;
    collectAddress: string;
    collectDay: string;
    collectNetAmount: string;
    collectTotalAmount: string;
    collectZone: string;
    description: string;
    paymentLink: string;
    orderNumber: string;
    prestations: string;
    conditions: string;
};

export const emailOrderConfirmation = ({
    buttonText,
    expirationTime,
    collectAddress,
    collectDay,
    collectNetAmount,
    collectTotalAmount,
    collectZone,
    description,
    paymentLink,
    orderNumber,
    prestations,
    conditions
}: TEmailOrderConfirmationPrestationParams) => `
<table class="es-content" cellspacing="0" cellpadding="0" align="center"
    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
    <tr>
        <td align="center" style="padding:0;Margin:0">
            <table class="es-content-body" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:600px">
                <tr>
                    <td align="left" style="padding:0;Margin:0;background-color:#fff">
                        <table width="100%" cellspacing="0" cellpadding="0"
                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                                <td valign="top" align="center"
                                    style="padding:32px 0 0;Margin:0;width:600px">
                                    <table width="100%" cellspacing="0" cellpadding="0"
                                        role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Bonjour,
                                                    </p>
                                            </td>
                                        </tr>
                                        ${description}
                                        <tr>
                                            <td align="center" style="padding-top:12px;margin:0">
                                                <!--[if mso]><a href="${paymentLink}" target="_blank" hidden> <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" esdevVmlButton href="${paymentLink}" style="height:48px; v-text-anchor:middle; width:271px" arcsize="10%" stroke="f" fillcolor="#5465fc"> <w:anchorlock></w:anchorlock> <center style='color:#ffffff; font-family:arial, "helvetica neue", helvetica, sans-serif; font-size:18px; font-weight:700; line-height:18px; mso-text-raise:1px'>${buttonText}</center> </v:roundrect></a><![endif]--><!--[if !mso]><!-- --><span
                                                    class="msohide es-button-border"
                                                    style="border-style:solid;border-color:#2cb543;background:#5465fc;border-width:0px;display:inline-block;border-radius:6px;width:auto;mso-hide:all"><a
                                                        href="${paymentLink}"
                                                        class="es-button es-button-1"
                                                        target="_blank"
                                                        style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:16px;border-style:solid;border-color:#5465fc;border-width:20px 45px;display:inline-block;background:#5465fc;border-radius:6px;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-weight:bold;line-height:22px;width:auto;text-align:center">${buttonText}</a></span><!--<![endif]--></td>
                                        </tr>
                                        ${
                                            expirationTime
                                                ? `
                                        <tr>
                                            <td align="center"
                                                class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:20px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:20px;color:#df0e07;font-size:13px">
                                                    <em>Attention: Le lien expirera ${expirationTime}</em></p>
                                            </td>
                                        </tr>
                                        `
                                                : ''
                                        }
                                        ${conditions}
                                        <tr>
                                            <td align="center"
                                                style="padding:30px 20px 10px;Margin:0;font-size:0">
                                                <table border="0" width="100%" height="100%"
                                                    cellpadding="0" cellspacing="0"
                                                    role="presentation"
                                                    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                    <tr>
                                                        <td
                                                            style="padding:0;Margin:0;border-bottom:2px solid #F2F2F2;background:unset;height:1px;width:100%;margin:0px">
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
<table class="es-content" cellspacing="0" cellpadding="0" align="center"
    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
    <tr>
        <td align="center" style="padding:0;Margin:0">
            <table class="es-content-body" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:600px">
                <tr>
                    <td align="left" style="padding:0;Margin:0;background-color:#fff">
                        <table width="100%" cellspacing="0" cellpadding="0"
                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                                <td valign="top" align="center"
                                    style="padding:0;Margin:0;width:600px">
                                    <table width="100%" cellspacing="0" cellpadding="0"
                                        role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tr>
                                            <td align="center"
                                                class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding:20px 50px 5px;">
                                                <h1
                                                    style="Margin:0;line-height:50px;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-size:34px;font-style:normal;font-weight:normal;color:#000000">
                                                    <em><strong>Récapitulatif de votre
                                                            commande</strong></em></h1>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:0px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    <strong>Numéro de commande</strong></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:2px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    ${orderNumber}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:0px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    <strong>Date de collecte</strong></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:2px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    ${collectDay}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:0px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    <strong>Adresse</strong></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:2px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    ${collectAddress}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:0px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    <strong>Zone</strong></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:2px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    ${collectZone}</p>
                                            </td>
                                        </tr>
                                        ${prestations}
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
<table class="es-content" cellspacing="0" cellpadding="0" align="center"
    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
    <tr>
        <td align="center" style="padding:0;Margin:0">
            <table class="es-content-body" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:600px">
                <tr>
                    <td align="left"
                        style="Margin:0;padding-top:30px;padding-bottom:10px;padding-left:20px;padding-right:20px;background-color:#fff">
                        <table width="100%" cellspacing="0" cellpadding="0"
                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                                <td valign="top" align="center"
                                    style="padding:0;Margin:0;width:560px">
                                    <table width="100%" cellspacing="0" cellpadding="0"
                                        role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tr>
                                            <td align="center"
                                                class="es-m-txt-c es-m-p10r es-m-p10l"
                                                bgcolor="#EDEFFF"
                                                style="Margin:0;padding-top:20px;padding-bottom:20px;padding-left:25px;padding-right:25px;border-radius:8px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:30px;color:#555555;font-size:18px">
                                                    <strong>Montant total
                                                            :&nbsp;</strong><span style="font-weight:500">${collectNetAmount}€ HT</span> | <span style="font-weight:300">${collectTotalAmount}€</span>
                                                    TTC</p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
`;

export enum EEmailStripeTemplatePrestationKeys {
    SLOT_START_TIME = '{{SLOT_START_TIME}}',
    SLOT_END_TIME = '{{SLOT_END_TIME}}',
    COLLECT_DETAILS = '{{COLLECT_DETAILS}}'
}

export const emailStripeTemplatePrestation = `
<tr>
    <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
        style="Margin:0;padding-top:5px;padding-bottom:0px;padding-left:25px;padding-right:25px">
        <p
            style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
            <strong>Prestation souhaitée</strong></p>
    </td>
</tr>
<tr>
    <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
        style="Margin:0;padding-top:2px;padding-bottom:5px;padding-left:25px;padding-right:25px">
        <p
            style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
            {{COLLECT_DETAILS}}
        </p>
    </td>
</tr>
<tr>
    <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
        style="Margin:0;padding-top:5px;padding-bottom:0px;padding-left:25px;padding-right:25px">
        <p
            style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
            <strong>Créneau souhaité</strong></p>
    </td>
</tr>
<tr>
    <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
        style="Margin:0;padding-top:2px;padding-bottom:5px;padding-left:25px;padding-right:25px">
        <p
            style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
            {{SLOT_START_TIME}} - {{SLOT_END_TIME}}</p>
    </td>
</tr>
`;

export enum EEmailExpirationWarningKeys {
    EXPIRATION_TIME = '{{EXPIRATION_TIME}}'
}

export const emailExpirationWarning = `
<tr>
    <td align="center"
        class="es-m-txt-c es-m-p10r es-m-p10l"
        style="Margin:0;padding-top:5px;padding-bottom:20px;padding-left:25px;padding-right:25px">
        <p
            style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:20px;color:#df0e07;font-size:13px">
            <em>Attention: Le lien expirera {{EXPIRATION_TIME}}</em></p>
    </td>
</tr>`;

export type TEmailOrderConfirmationDescriptionParams = {
    lines: string[];
};

export const emailOrderConfirmationDescription = ({ lines }: TEmailOrderConfirmationDescriptionParams) =>
    lines
        .map((line) => {
            return `
        <tr>
            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                <p
                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                    ${line}
                </p>
            </td>
        </tr>
        `;
        })
        .join('');

export const emailOrderConfirmationPrestation = (products: ProductInCCOrCO[]) => {
    const hasDeposit = products.some((product) => product.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT);
    const hasRetrieval = products.some((product) => product.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL);
    const isRotation = hasDeposit && hasRetrieval;

    const productsList = products.map((p) => `${p.quantity} x ${p.name}`).join('<br>');

    if (isRotation) {
        return `
        <tr>
            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
            style="Margin:0;padding-top:5px;padding-left:25px;padding-right:25px">
                <p
                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                    Rotation de bennes
                </p>
            </td>
        </tr>
        <tr>
            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
            style="Margin:0;padding-bottom:5px;padding-left:40px;padding-right:25px">
                <p
                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                    ${productsList}
                </p>
            </td>
        </tr>
        `;
    } else {
        return `
        <tr>
            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
            style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                <p
                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                    ${productsList}
                </p>
            </td>
        </tr>
        `;
    }
};

export type EmailOrderConfirmationConditionsParams = {
    types: EPlanningType[];
};
export const emailOrderConfirmationConditions = ({ types }: EmailOrderConfirmationConditionsParams): string => `
<table class="es-content" cellspacing="0" cellpadding="0" align="center"
style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
    <tr>
        <td align="center" style="padding:0;Margin:0">
            <table class="es-content-body" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:600px">
                <tr>
                    <td align="left" style="padding:0;Margin:0;background-color:#fff">
                        <table width="100%" cellspacing="0" cellpadding="0"
                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                                <td valign="top" align="center"
                                    style="padding:32px 0 0;Margin:0;width:600px">
                                    <table width="100%" cellspacing="0" cellpadding="0"
                                        role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#000000;font-size:14px;font-weight:bold">
                                                    Rappel de nos conditions de prestation :
                                                    </p>
                                            </td>
                                        </tr>
                                        ${
                                            types.includes(EPlanningType.BIG_BAG)
                                                ? emailOrderConfirmationBBConditions
                                                : ''
                                        }
                                        ${
                                            types.includes(EPlanningType.DUMPSTER)
                                                ? emailOrderConfirmationBNConditions
                                                : ''
                                        }
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
`;

export const emailOrderConfirmationBBConditions = `
<tr>
    <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
        style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
        <p
            style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#555555;font-size:14px">
            <span style="font-style:italic; font-weight:bold;">Politique d'annulation</span> :
            Annulation possible gratuitement jusqu'à la veille (ouvrée) de l'opération avant 18h00. Au delà, il s'agit d'une annulation tardive qui vous sera facturée à hauteur de 39€HT par big bag commandé.
            </p>
    </td>
</tr>
<tr>
    <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
        style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
        <p
            style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#555555;font-size:14px">
            <span style="font-style:italic; font-weight:bold;">Passage à vide</span> :
            Si les big bags ne sont pas prêts à être collectés comme prévu, un passage à vide vous sera facturé à hauteur de 39€HT par big bag commandé non collecté.
            </p>
    </td>
</tr>
`;

export const emailOrderConfirmationBNConditions = `
<tr>
    <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
        style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
        <p
            style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#555555;font-size:14px">
            <span style="font-style:italic; font-weight:bold;">Politique d'annulation</span> :
            Vous pouvez annuler sans frais jusqu'à 17h la veille ouvrée de l'intervention. Au-delà vous serez facturé 199 €HT par benne commandée.
            </p>
    </td>
</tr>
<tr>
    <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
        style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
        <p
            style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#555555;font-size:14px">
            <span style="font-style:italic; font-weight:bold;">Passage à vide</span> :
            Si la benne ne peut être collectée comme prévu (pas accessible, pas disponible, pas prête à être évacuée, etc.), des frais de passage à vide vous seront facturés à hauteur de 199 €HT par benne commandée initialement et non collectée.
            </p>
    </td>
</tr>
<tr>
    <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
        style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
        <p
            style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#555555;font-size:14px">
            <span style="font-style:italic; font-weight:bold;">Surcharge</span> :
            Chaque mètre cube excédant le volume utile de la benne mise à disposition sera facturé au prix de de 39 €HT par m3 supplémentaire. Si le collecteur estime que pour des raisons de surcharges (volume ou
            poids) menaçant la sécurité et/ou l'intégrité du matériel, la benne ne peut être évacuée en l'état, vous serez redevable d'une pénalité de 199 €HT et il vous appartiendra de mettre la benne en conformité pour permettre son évacuation.
            </p>
    </td>
</tr>
`;
