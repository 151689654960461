import { Toast as PRToast, ToastMessage } from 'primereact/toast';
import React from 'react';
import styled from 'styled-components';

export const Toast = () => {
    const ref = React.useRef<PRToast>(null);

    console.log('Toast: Loading function');
    toast = (...items: Array<ToastMessage>) => {
        const toastItems: Array<ToastMessage> = items.map((item) => ({
            ...item,
            severity: item.severity || 'info'
        }));
        const currentRef = ref.current;
        if (currentRef) {
            currentRef?.show(toastItems);
        } else {
            console.warn('Toast not mounted yet');
        }
    };
    console.log('Toast: Function Loaded');

    return <StyledToast ref={ref} position={'bottom-right'} baseZIndex={10000} />;
};

export let toast = (...items: Array<ToastMessage>) => {
    console.warn('Functions not loaded yet');
};

const StyledToast = styled(PRToast)`
    min-width: 25rem;
    max-width: 50rem;
    width: fit-content;
`;
