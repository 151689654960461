import React from 'react';
import styled from 'styled-components';

import { CardErrors, CREATED_FROM } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import Input from '../../components/Inputs';
import { useFormModule } from '../../hooks/FormModule';
import { mapCreatedFrom } from '../../common/enumMapper';

export type OrderMiscProps = {
    readOnly?: boolean;
    value?: OrderMiscState;
    id: string;
    result: (value: OrderMiscState, errors: null | string[] | CardErrors<OrderMiscState>, id: string) => void;
    displayError?: boolean;
};

export type OrderMiscState = {
    comment: string;
    prepaid: boolean;
    taken_from?: CREATED_FROM;
};
export type OrderMiscErrorState = CardErrors<OrderMiscState>;

export const orderCreatedFromOptions = [CREATED_FROM.EMAIL, CREATED_FROM.INBOUND_CALL, CREATED_FROM.OUTBOUND_CALL];

export const initialState: OrderMiscState = {
    comment    : '',
    prepaid    : false,
    taken_from : CREATED_FROM.INBOUND_CALL
};
export const initialErrorState: OrderMiscErrorState = generateInitialErrorState(initialState);

export const OrderMisc: React.FC<OrderMiscProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: OrderMiscProps) => {
    const { val, setVal, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    return (
        <StyledCard title="Informations diverses">
            <FullLineInput>
                <Label>Origine de la commande</Label>
                <Button.Select
                    required={true}
                    id="taken_from"
                    options={orderCreatedFromOptions}
                    readOnly={readOnly}
                    labelMapper={(value) => mapCreatedFrom(value as any)}
                    result={handleChange}
                    value={val.taken_from}
                    errors={err.taken_from}
                    displayError={displayError}
                />
            </FullLineInput>
            <FullLineInput>
                <Input.Textarea
                    label="Commentaires"
                    required={false}
                    id="comment"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.comment}
                    errors={err.comment}
                    displayError={displayError}
                    width="100%"
                />
            </FullLineInput>
            <Button.Checkbox
                id="prepaid"
                readOnly={readOnly}
                result={handleChange}
                value={val.prepaid}
                label="Commande prépayée"
                labelPosition="right"
            />
        </StyledCard>
    );
};

const FullLineInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

const StyledCard = styled(Card)``;

const Label = styled.span`
    font-weight: bold;
`;
