import { FormikConfig, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { deepCheckEmptyObject } from '@bbng/util/misc';
import {
    MAX_DAY_LIMIT_FOR_ORDER_CANCELLATION,
    CCRo,
    ProductGetCancelationAmountRo,
} from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import CollectConfig from '../collect-config';
import { AddressState } from '../common/Address';
import {
    CollectConfigCancelFormErrorState,
    CollectConfigCancelFormState,
    CollectConfigCancelModulesErrorStates,
    CollectConfigCancelModulesStates,
    initialErrorState,
    initialState,
    mapStateToApiCancelData,
    maxHourLimitText
} from './helpers';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import { BoldText, ItalicText, PageForm, StyledText, SubmitButton } from './style';
import { ConfirmDialog } from './confirm-dialog';

type CollectConfigCancelFormProps = {
    setModalIsOpen: (isOpen: boolean) => void;
    dataId: string;
    submitCallback?: (response: StatelessResponse<CCRo>) => void | Promise<void>;
    isAdministrative: boolean;
};

const CollectConfigCancelForm: React.FC<CollectConfigCancelFormProps> = ({
    setModalIsOpen,
    dataId,
    submitCallback,
    isAdministrative
}: CollectConfigCancelFormProps): JSX.Element => {
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [loading, setLoading] = useState<boolean>(false);

    const [err, setErr] = useState<CollectConfigCancelFormErrorState>(initialErrorState);

    const [cancelationAmountRo, setCancelationAmountRo] = useState<ProductGetCancelationAmountRo | null>(null);
    const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState<boolean>(false);

    const handlePreSubmit = React.useCallback<FormikConfig<CollectConfigCancelFormState>['onSubmit']>(
        async (data: CollectConfigCancelFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            if (cancelationAmountRo?.isLate === true) {
                setConfirmDialogIsOpen(true);
            } else {
                handleSubmit(data, helper);
            }
        },
        [err, bbngRequest, cancelationAmountRo, setConfirmDialogIsOpen]
    );

    const handleSubmit = React.useCallback<FormikConfig<CollectConfigCancelFormState>['onSubmit']>(
        async (data: CollectConfigCancelFormState, helper) => {
            setLoading(true);

            /**
             * If the cancelation is not late, we don't want to trigger the invoice.
             */
            if (cancelationAmountRo?.isLate === false) {
                data.triggerInvoice = false;
            }

            const method = 'PATCH';
            const url = isAdministrative
                ? urlApiBuilder.ccAdministrativeCancel(dataId as string)
                : urlApiBuilder.collectConfigCancel(dataId as string);
            const body = mapStateToApiCancelData(data);

            const res = await bbngRequest<CCRo>({
                method,
                url,
                payload: {
                    body
                }
            });

            setModalIsOpen(false);
            setLoading(false);
            if (submitCallback) {
                await submitCallback(res);
            }
        },
        [err, bbngRequest, cancelationAmountRo]
    );

    const formik = useFormik<CollectConfigCancelFormState>({
        initialValues : initialState,
        onSubmit      : handlePreSubmit
    });

    const fetchCancelationprice = async () => {
        setLoading(true);
        const response = await bbngRequest<ProductGetCancelationAmountRo>({
            method : 'GET',
            url    : urlApiBuilder.productCancelationPrice(dataId as string)
        });

        if (response.success && response.response?.data?.ro) {
            const ro = response.response.data.ro;
            setCancelationAmountRo(ro);
        }
        setLoading(false);
    };

    /**
     * On mount, fetch cancelation price to know whether it's late or not
     */
    useEffect(() => {
        fetchCancelationprice();
    }, []);

    const handleChange = React.useCallback(
        (
            value: CollectConfigCancelModulesStates | AddressState,
            errors: CollectConfigCancelModulesErrorStates | string[] | null,
            id: string
        ): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    if (loading) return <PageLoader loading />;

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <CollectConfig.PatchTracker
                displayError={formik.submitCount > 0}
                id="patchTracker"
                result={handleChange}
                value={formik.values.patchTracker}
            />
            <StyledText>
                {cancelationAmountRo?.isLate === true && (
                    <BoldText>
                        L'annulation est tardive. À l'étape suivante, vous pourrez choisir de facturer ou non le client.
                    </BoldText>
                )}
                <ItalicText>
                    <i className="pi pi-info-circle" />
                    L'annulation est gratuite jusqu'à {maxHourLimitText}, {MAX_DAY_LIMIT_FOR_ORDER_CANCELLATION} jour(s)
                    ouvré(s) avant la date de prestation.
                </ItalicText>
            </StyledText>
            <SubmitButton
                type="submit"
                label="Valider"
                disabled={deepCheckEmptyObject(err) === false && formik.submitCount > 0}
            />
            <ConfirmDialog
                visible={confirmDialogIsOpen}
                onHide={() => setConfirmDialogIsOpen(false)}
                onConfirm={(triggerInvoice) => {
                    handleSubmit(
                        {
                            ...formik.values,
                            triggerInvoice
                        },
                        formik
                    );
                    setConfirmDialogIsOpen(false);
                }}
            />
        </PageForm>
    );
};

export default CollectConfigCancelForm;
