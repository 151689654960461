import { CollectConfigQuery } from '@bbng/util/types';
import moment from 'moment';
import { Moment } from 'moment';
import { Button } from 'primereact/button';
import { useCallback, useState } from 'react';
import { axiosClient } from '../../common/axios';
import { mapPlanningConfigType } from '../../common/enumMapper';
import { defaultErrorToast } from '../../common/syncRequest';
import { urlApiBuilder } from '../../common/urlBuilder';
import { Dialog } from '../../components/Dialog';
import { MonthSelector } from '../../modules/month-selector';
import { ExportModalButton, ExportModalContainer } from './style';

type MonthSelectorType = {
    month: number;
    year: number;
};

export type ExportHandlerProps = {
    query: CollectConfigQuery;
    customerName?: string;
};

export const ExportHandler: React.FC<ExportHandlerProps> = ({ query, customerName }): JSX.Element => {
    const [exportIsLoading, setExportIsLoading] = useState<boolean>(false);
    const [exportModalIsOpen, setExportModalIsOpen] = useState<boolean>(false);
    const [currentMonth] = useState<Moment>(moment.utc());
    const [monthSelected, setMonthSelected] = useState<MonthSelectorType>({
        month : currentMonth.month(),
        year  : currentMonth.year()
    });
    const exportData = useCallback(async () => {
        setExportIsLoading(true);

        try {
            const url = urlApiBuilder.exportCollectConfig();
            const response = await axiosClient.get(url, {
                params: {
                    ...query,
                    min_date : moment([monthSelected.year, monthSelected.month]).startOf('month').format(),
                    max_date : moment([monthSelected.year, monthSelected.month]).endOf('month').format()
                } as CollectConfigQuery,
                responseType: 'blob'
            });

            const formattedMonth = moment([monthSelected.year, monthSelected.month]).format('MM-YYYY');
            const name = `export-commandes-${customerName ?? 'tous-clients'}-${
                query.type ?? 'tous-types'
            }-${formattedMonth}`;

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.data);

            link.download = `${name}.xlsx`;
            link.click();
            link.remove();
        } catch (e) {
            defaultErrorToast("Une erreur est survenue lors de l'export des commandes");
            console.error(e);
        }
        setExportIsLoading(false);
    }, [JSON.stringify(query), monthSelected]);
    return (
        <>
            <Button
                className="p-button-info"
                type="button"
                loading={exportIsLoading}
                loadingIcon="pi pi-spin pi-spinner"
                label="Exporter les commandes"
                icon="pi pi-file-excel"
                tooltipOptions={{ position: 'left' }}
                onClick={() => setExportModalIsOpen(true)}
            />
            <Dialog header="Export de commandes" visible={exportModalIsOpen} onHide={() => setExportModalIsOpen(false)}>
                <ExportModalContainer>
                    <MonthSelector setValue={setMonthSelected} value={monthSelected} label="Période" />
                    <div>Client: {customerName ?? 'Tous'}</div>
                    <div>Type: {query.type ? mapPlanningConfigType(query.type) : 'Tous'}</div>
                </ExportModalContainer>
                <ExportModalButton
                    label="Valider"
                    onClick={() => {
                        setExportModalIsOpen(false);
                        exportData();
                    }}
                />
            </Dialog>
        </>
    );
};
