import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AdminRo, IBbngResponse } from '@bbng/util/types';

import { axiosClient } from '../../common/axios';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';

export function useAuth<UserType = AdminRo>(config: UseAuthConfig = {}) {
    const nav = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [user, setUser] = React.useState<UserType>();
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);

    const logout = React.useCallback(() => {
        window.localStorage.clear();
        nav(urlBuilder.login());
    }, []);

    React.useEffect(() => {
        if (config.withUser) {
            axiosClient
                .get<IBbngResponse<UserType>>(urlApiBuilder.adminAuthUser())
                .then((response) => {
                    setUser(response.data.data.ro);
                    setIsAuthenticated(true);
                })
                .catch((err) => {
                    console.error(err);
                    logout();
                })
                .finally(() => setLoading(false));
        } else {
            axiosClient
                .get<IBbngResponse<boolean>>(urlApiBuilder.adminAuthVerifyToken())
                .then((response) => {
                    setIsAuthenticated(response.data.data.ro ?? false);
                })
                .catch((err) => {
                    console.error(err);
                    logout();
                })
                .finally(() => setLoading(false));
        }
    }, []);

    return {
        user,
        loading,
        isAuthenticated,
        logout
    };
}

export type UseAuthConfig = {
    withUser?: boolean;
};

export type UseAuthValues<UserType = AdminRo> = {
    user?: UserType;
    loading: boolean;
    isAuthenticated: boolean;
    logout: (redirect?: boolean) => void;
};
