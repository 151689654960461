import moment from 'moment';
import React from 'react';

import { CC_FAMILY, PlanningShiftStepAdministrative, PlanningShiftStepService, TruckRo } from '@bbng/util/types';

import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';

import { OverviewLineWrapper, StyledTable } from './style';
import { isStepService } from '../../modules/planning/shifts/helpers';

export const PlanningDuplicate: React.FC = (): JSX.Element => {
    const { ccsAdministrative, ccsService, plannings, trucksByPlanning } = usePlanningV2Store();

    const duplicates = React.useMemo(() => {
        const tmp: Record<
            string,
            {
                truck: TruckRo;
                step: PlanningShiftStepAdministrative | PlanningShiftStepService;
                id_label: string;
            }[]
        > = {};

        Object.values(plannings).forEach((planning) => {
            const shift = planning.shift;
            [...shift.steps_administrative, ...shift.steps_service].forEach((step) => {
                let id = ccsAdministrative[step.collect_config_id]
                    ? ccsAdministrative[step.collect_config_id]?.id
                    : ccsService[step.collect_config_id]?.id;

                if (isStepService(step) && step.is_splitted) {
                    id = `${id}@${step.splitted_idx ?? 0}`;
                }

                if (tmp[id]) {
                    tmp[id].push({ truck: trucksByPlanning[planning.id], step: step, id_label: id });
                } else {
                    tmp[id] = [{ truck: trucksByPlanning[planning.id], step: step, id_label: id }];
                }
            });
        });

        return Object.values(tmp).filter((e) => e.length > 1);
    }, [plannings]);

    return (
        <OverviewLineWrapper>
            <StyledTable>
                <thead>
                    <tr>
                        <th>Identifiant</th>
                        <th>Categorie</th>
                        <th>Camion assigné</th>
                        <th>Numéro de commande</th>
                        <th>Numéro de collecte</th>
                        <th>Mis à</th>
                    </tr>
                </thead>
                <tbody>
                    {duplicates.flatMap((duplicate, index) =>
                        duplicate.map((d, i) => (
                            <>
                                <tr>
                                    <td>{d.id_label}</td>
                                    <td>{d.step.category}</td>
                                    <td>{d.truck.name}</td>
                                    <td>
                                        {d.step.category === CC_FAMILY.ADMINISTRATIVE
                                            ? '.'
                                            : ccsService[d.step.collect_config_id as string].order_number}
                                    </td>
                                    <td>
                                        {d.step.category === CC_FAMILY.ADMINISTRATIVE
                                            ? '.'
                                            : ccsService[d.step.collect_config_id as string].number}
                                    </td>
                                    <td>{moment(d.step.scheduled_at).format('HH:mm')}</td>
                                </tr>
                            </>
                        ))
                    )}
                </tbody>
            </StyledTable>
        </OverviewLineWrapper>
    );
};
