import Joi from 'joi';
import { AddressCreateSchema } from '../address';

import { PhoneE164Schema, SiretSchema } from '../misc.schema';

const PrestaPrice = Joi.object({
    product_id       : Joi.string().required(),
    price_in_centime : Joi.number().required()
});

export const PrestaCreateDtoSchema = Joi.object({
    name         : Joi.string().required(),
    email        : Joi.string().email({ tlds: false }).required(),
    phone_number : PhoneE164Schema.required(),
    siret        : SiretSchema.required(),
    headquarter  : AddressCreateSchema.required(),
    price_list   : Joi.array().items(PrestaPrice).required()
});

export const PrestaEditDtoSchema = Joi.object({
    name         : Joi.string(),
    email        : Joi.string().email({ tlds: false }),
    phone_number : PhoneE164Schema,
    siret        : SiretSchema,
    headquarter  : AddressCreateSchema,
    price_list   : Joi.array().items(PrestaPrice)
});
