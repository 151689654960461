import { Collector, Collector_Authz, Collector_History } from '@prisma/client/collector';

import { RelationsDto } from '../dto';
import { IKafkaQuery } from '../kafka';
import { ISODate } from '../misc';
import { EPlanningType } from '../planning';
import { ProductPrice } from '../product';

export interface CollectorCreateDto {
    email: string;
    is_available?: boolean;
    firstname?: string;
    lastname?: string;
    efficiency?: number;
    skills?: ECollectorSkill[];
    contract?: ECollectorContract;
    landfill_id?: string[];
    phone_number: string;
    avatar?: string;
    hourly_rate?: ProductPrice | null;
}
export type CollectorCreateActionDto = CollectorCreateDto & { id?: string };

export interface CollectorEditDto extends RelationsDto<CollectorRo> {
    email?: string;
    phone_number?: string;
    firstname?: string;
    lastname?: string;
    is_available?: boolean;
    efficiency?: number;
    skills?: ECollectorSkill[];
    contract?: ECollectorContract;
    hourly_rate?: ProductPrice | null;
}
export type CollectorEditActionDto = Omit<CollectorEditDto, 'connect' | 'disconnect'> & {
    landfill_id?: string[];
    archived?: boolean;
};

export interface CollectorRo {
    id: string;

    firstname?: string;
    lastname?: string;
    fullname?: string;

    is_available: boolean;

    efficiency?: number;
    skills: ECollectorSkill[];
    contract: ECollectorContract;

    phone_number: string;
    hourly_rate: ProductPrice | null;

    email: string;
    roles: string[];

    landfill_id: string[];

    archived: boolean;
    created_by: string;
    created_at: ISODate;
    updated_at: ISODate;
}

export enum ECollectorRole {
    BBNG_COLLECTOR = 'BBNG_COLLECTOR'
}

export enum ECollectorContract {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
    PROVIDER = 'PROVIDER'
}

export enum ECollectorSkill {
    BIG_BAG = 'BIG_BAG',
    DUMPSTER_AMPLIROLL = 'DUMPSTER_AMPLIROLL',
    DUMPSTER_CHAIN = 'DUMPSTER_CHAIN',
    ADR = 'ADR',
    DELIVERY = 'DELIVERY',
    SMS = 'SMS', // enable collector to send SMS
    OTHER = 'OTHER'
}

export interface CollectorQuery extends IKafkaQuery {
    q?: string;
    has_landfill?: boolean;
    skill?: ECollectorSkill | EPlanningType;
    skills?: ECollectorSkill[];
}

export type CollectorPrisma = Collector & { authorization: Collector_Authz; history: Collector_History };
