import moment from 'moment';
import { CalendarChangeParams, Calendar as PRCalendar } from 'primereact/calendar';
import React from 'react';
import * as yup from 'yup';

import { convertHHMMToDate, match } from '@bbng/util/misc';

export type CalendarHoursMinProps = {
    label?: string;
    readOnly: boolean;
    required: boolean;
    id: string;
    result: (value: Date, errors: null | string[], id: string) => void;
    validationSchema?: yup.AnySchema;
    value?: string | Date;
    errors?: null | string[];
    displayError?: boolean;
    stepMinute?: number;
    defaultTime?: Date;
};

export const CalendarHoursMin = ({
    label,
    readOnly,
    required,
    id,
    result,
    validationSchema,
    value,
    errors,
    displayError,
    stepMinute,
    defaultTime
}: CalendarHoursMinProps) => {
    const [displayErr, setDisplayErr] = React.useState<boolean>(displayError ?? false);
    const DEFAULT_TIME = defaultTime ?? moment().toDate();

    const displayTime = () => {
        if (value) {
            return moment(value).toDate();
        }
        return DEFAULT_TIME;
    };

    const [val, setVal] = React.useState<Date>(value ? convertHHMMToDate(value) : displayTime());
    const [err, setErr] = React.useState<null | string[]>(errors ? errors : null);

    const baseValidationSchema = yup.date();
    const schema = validationSchema
        ? validationSchema
        : required
        ? baseValidationSchema.required('Date requise')
        : baseValidationSchema;

    const _checkValidationSchema = (val: Date | Date[], errVal: Date) => {
        try {
            schema.validateSync(val, { abortEarly: false });
        } catch (err) {
            setErr(err.errors);
            result(errVal, err.errors, id);
        }
    };

    const _checkDescendingValue = React.useCallback(
        async (stateValue: Date | Date[], propsValue: Date | Date[]): Promise<void> => {
            if (match(stateValue, propsValue) === false) {
                setVal(Array.isArray(propsValue) ? propsValue[0] : propsValue);
                setErr(null);
                result(Array.isArray(propsValue) ? propsValue[0] : propsValue, null, id);
                if (propsValue) {
                    _checkValidationSchema(propsValue, Array.isArray(propsValue) ? propsValue[0] : propsValue);
                    setDisplayErr(true);
                }
            }
        },
        [setVal, setErr, result]
    );

    const _checkDescendingErrors = React.useCallback(
        async (stateError: null | string[], propsError: null | string[]): Promise<void> => {
            if (match(stateError, propsError) === false) {
                setErr(propsError);
                result(val, propsError, id);
                setDisplayErr(true);
            }
        },
        [setVal, setErr, result]
    );

    React.useEffect(() => {
        if (value) {
            const data = convertHHMMToDate(value);
            _checkDescendingValue(val, data);
        }
        if (errors !== undefined) {
            _checkDescendingErrors(err, errors);
        }
    }, [value, errors]);

    React.useEffect(() => {
        setDisplayErr(displayError ?? false);
    }, [displayError]);

    React.useEffect(() => {
        if (required) {
            _checkValidationSchema(val, val);
        }
    }, []);

    const handleOnChange = React.useCallback(
        (e: CalendarChangeParams): void => {
            if (e.originalEvent.type === 'blur') return;
            setVal(e.value ? (Array.isArray(e.value) ? e.value[0] : e.value) : DEFAULT_TIME);
            setErr(null);
            result(e.value ? (Array.isArray(e.value) ? e.value[0] : e.value) : DEFAULT_TIME, null, id);
            setDisplayErr(false);
        },
        [setVal, setErr, result, id]
    );

    const handleBlur = React.useCallback(async (): Promise<void> => {
        try {
            await schema.validate(val, { abortEarly: false });
        } catch (err) {
            setErr(err.errors);
            result(val, err.errors, id);
        }
        setDisplayErr(true);
    }, [schema, val, setErr, result, id]);

    return (
        <span className="p-float-label">
            <PRCalendar
                id={id}
                disabled={readOnly}
                onChange={handleOnChange}
                onBlur={handleBlur}
                timeOnly={true}
                hourFormat="24"
                dateFormat="hh:mm"
                value={val}
                tooltip={displayErr && err && err.length > 0 ? err.join('\n') : ''}
                style={{ width: '100px' }}
                className={`${displayErr && err && err.length > 0 ? 'p-invalid' : ''}`}
                stepMinute={stepMinute}
            />
            {label && <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>}
        </span>
    );
};
