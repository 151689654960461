// import sha256 from 'crypto-js/sha256';
// import { v4 as uuidv4 } from 'uuid';

/**
 * Generate a string of random numbers.
 * @param {number} size size of the string to generate
 * @returns {string} random numbers string
 */
export const randomNumbers = (size: number): string => {
    const result = [];
    for (let index = 0; index < size; index++) {
        result.push(Math.floor(Math.random() * 10));
    }
    return result.join('');
};

/**
 * Generate a string of random characters.
 * @param {number} size size of the string to generate
 * @returns {string} random characters string
 */
export const randomString = (size: number) => {
    const index2hex = (index: number) => ('0' + index.toString(16)).slice(-2);
    const r = (a: string, index: number): string => a + index2hex(index);
    const bytes = crypto.getRandomValues(new Uint8Array(size));
    return [...bytes].reduce((accumulator, element) => r(accumulator, element), '');

    // /**
    //  * Based on https://stackoverflow.com/a/19964557
    //  */
    // return Array(size + 1)
    //     .join((Math.random().toString(36) + '00000000000000000').slice(2, 18))
    //     .slice(0, size);
};

/**
 * Hash input string.
 * @param {string} input
 * @returns {string} hashed input
 */
export const createHash = async (input: string): Promise<string> => {
    const data = new TextEncoder().encode(input);
    const hash = await crypto.subtle.digest('SHA-256', data);
    return [...new Uint8Array(hash)]
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('')
        .toString();
    // const hash = await sha256(input);
    // return hash.toString();
};

export const createUUID = (): string => {
    // return uuidv4();
    return crypto.randomUUID();
};
