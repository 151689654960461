import { CCServiceRo } from '../collect-config';
import { CustomerRo } from '../customer';
import { ISODate, MaybeArray } from '../misc';
import { OrderRo } from '../order';
import { UserRo } from '../user';

export const DEFAULT_CONTACT_EMAIL = 'contact@endless.fr';
export const INVOICE_CONTACT_EMAIL = 'compta@endless.fr';
export const DEFAULT_CONTACT_PHONE = '+33180181953';

export type EmailCreateDto<T> = {
    recipients: string | string[];
    type: EmailType;
    data: T;
};

export type EmailOrderConfirmationData = {
    order: OrderRo;
    collect_configs: MaybeArray<
        Pick<
            CCServiceRo,
            'id' | 'order_number' | 'from_date' | 'to_date' | 'products' | 'price' | 'address' | 'zone' | 'type'
        >
    >;
    link: string;
    recipients: string[];
};

export type EmailGoogleDriveLinkData = {
    month: number;
    year: number;
    url: string;
};

export type EmailNewPaymentLinkData = {
    order_number: string;
    link: string;
    user: UserRo;
    expires_at: number; // epoch
};

export type EmailPaymentCancelledData = {
    orderNumber: string;
    user: UserRo;
};

export type EmailPaymentCancelledAndRefundedData = EmailPaymentCancelledData & {
    amountToRefund: number;
    isLateCancelation: boolean;
};

export type EmailCustomerInvitationLinkData = {
    emails: string[];
    expires_at: ISODate;
    customer: CustomerRo;
    user: UserRo;
    url: string;
};

export type EmailCustomerInvitationConfirmationData = {
    customer: CustomerRo;
    user: UserRo;
};

export type EmailUserSigningData = {
    token: string;
};

export const EMAIL_TYPE = {
    ORDER_CONFIRMATION               : 'order_confirmation',
    PAYMENT_NEW_LINK                 : 'payment_new_link',
    PAYMENT_CANCELLED                : 'payment_cancelled',
    PAYMENT_CANCELLED_AND_REFUNDED   : 'payment_cancelled_and_refunded',
    GOOGLE_DRIVE_LINK                : 'google_drive_link',
    CUSTOMER_INVITATION_LINK         : 'customer_invitation_link',
    CUSTOMER_INVITATION_CONFIRMATION : 'customer_invitation_confirmation',
    USER_SIGNING                     : 'USER_SIGNING'
} as const;
export type EmailType = typeof EMAIL_TYPE[keyof typeof EMAIL_TYPE];
