import moment from 'moment';
import { Tag } from 'primereact/tag';
import { useState } from 'react';
import styled from 'styled-components';

import {
    ConstructionSiteRo,
    CustomerRo,
    DiscountRo,
    EDiscountType,
    EDiscountUnit,
    ETrashType,
    ZoneRo
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { mapDiscountType, mapTrashTypetext, mapVolumeToBag } from '../../common/enumMapper';
import { DISCOUNT_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import Button from '../../components/Button';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { FiltersContainer } from '../CollectConfigs/style';
import { useNavigate } from 'react-router-dom';
import { MenuItemCommandParams } from 'primereact/menuitem';

type QueryParams = {
    customer_id?: string;
    include_expired?: boolean;
};

const ValidityContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const TrashContainer = styled.div`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
`;

export const DiscountsListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [customer_id, setCustomerId] = useState<string | undefined>(query.customer_id);
    const [includeExpired, setIncludeExpired] = useState<boolean | undefined>(query.include_expired);
    const navigate = useNavigate();

    const handleActionRowDuplicate = async (e: MenuItemCommandParams) => {
        const index = e.item['rowInfo']['rowIndex'];
        const discount = e.item['rowInfo']['props']['value'][index] as DiscountRo;

        navigate(urlBuilder.discountDuplicate(discount.id));
    };

    return (
        <Listing<any, DiscountRo>
            url={DISCOUNT_ORCHESTRATION_BASE_URL}
            addButtonUrl={urlBuilder.discountCreate()}
            endpoint="discounts"
            name="Remise"
            pluralName="Remises"
            showSearch={false}
            displaySelectColumn={false}
            enrichData={async (data) => {
                data.data.ro = await fetchDataRelation(data.data.ro ?? [], {
                    construction_site_id : true,
                    customer_id          : true,
                    zone_id              : true
                });
                return data;
            }}
            queryParams={{
                customer_id,
                include_expired: includeExpired
            }}
            actionRows={[
                {
                    label   : 'Dupliquer',
                    icon    : 'pi pi-clone',
                    command : (e) => handleActionRowDuplicate(e)
                }
            ]}
            leftHandedComponent={
                <FiltersContainer>
                    <RelationAutocomplete.Customer
                        placeholder="Filtrer par client"
                        onSelect={(customer) => setCustomerId(customer?.id)}
                        onUnselect={() => setCustomerId(undefined)}
                    />
                    <Button.Switch
                        id="includeExpired"
                        value={includeExpired || false}
                        result={(value) => setIncludeExpired(value)}
                        label="Inclure les remises expirées/futures"
                        labelPosition="left"
                        readOnly={false}
                    />
                </FiltersContainer>
            }
            headers={[
                {
                    name      : 'Client',
                    field     : 'customer_id',
                    component : (data: DiscountRo) => (data.customer_id as unknown as CustomerRo[])[0]?.name
                },
                {
                    name      : 'Chantiers',
                    field     : 'construction_site_id',
                    component : (data: DiscountRo) => {
                        if (data.construction_site_id?.length === 0) return 'Tous les chantiers';
                        if (data.construction_site_id && data.construction_site_id?.length > 1)
                            return `Sur ${data.construction_site_id?.length} chantiers`;
                        return (data.construction_site_id as unknown as ConstructionSiteRo[])[0]?.label;
                    }
                },
                {
                    name      : 'Zones',
                    field     : 'zone_id',
                    component : (data: DiscountRo) => {
                        if (data.zone_id?.length === 0) return 'Toutes les zones';
                        if (data.zone_id && data.zone_id?.length > 1) return `Sur ${data.zone_id?.length} zones`;
                        const zone = (data.zone_id as unknown as ZoneRo[])[0];
                        return `${zone?.name} (${zone?.metropole})`;
                    }
                },
                {
                    name      : 'Type',
                    field     : 'type',
                    component : (data: DiscountRo) => (
                        <div>
                            <Tag
                                className="mr-2"
                                severity={
                                    data.type === EDiscountType.BIG_BAG
                                        ? 'success'
                                        : data.type === EDiscountType.DUMPSTER
                                        ? 'info'
                                        : 'primary'
                                }
                                value={mapDiscountType(data.type)}
                            />
                        </div>
                    )
                },
                {
                    name      : 'Montant',
                    field     : 'value',
                    component : (data: DiscountRo) => {
                        let condition = '';
                        let unit = '';
                        const m3value =
                            data.type === EDiscountType.BIG_BAG || data.unit === EDiscountUnit.PERCENT
                                ? data.value
                                : data.value * data.min_volume;
                        switch (data.type) {
                            case EDiscountType.BIG_BAG: {
                                unit = '/m³';
                                if (data.min_volume === data.max_volume) condition = `pour ${data.min_volume}m³`;
                                if (data.min_volume === 0) condition = `jusqu'à ${data.max_volume}m³`;
                                else condition = `de ${data.min_volume} à ${data.max_volume}m³`;
                                break;
                            }
                            case EDiscountType.DUMPSTER: {
                                unit = '/benne';
                                condition = `Pour ${data.min_volume} m³`;
                                break;
                            }
                            case EDiscountType.DELIVERY: {
                                unit = '/sac';
                                condition = `Pour les ${mapVolumeToBag(data.min_volume)}`;
                            }
                        }
                        return (
                            <span>
                                {data.value < 0 && 'Pénalité de '}
                                {Math.abs(m3value).toFixed(2)}
                                {data.unit === EDiscountUnit.AMOUNT ? '€' : '%'}
                                {data.unit === EDiscountUnit.AMOUNT && unit} ({condition})
                            </span>
                        );
                    }
                },
                {
                    name      : 'Période de validité',
                    field     : 'start_date',
                    component : (data: DiscountRo) => {
                        let text = `Dès le ${moment(data.start_date).format('DD/MM/YYYY')}`;
                        if (data.end_date)
                            text = `Du ${moment(data.start_date).format('DD/MM/YYYY')} au ${moment(
                                data.end_date
                            ).format('DD/MM/YYYY')}`;

                        let tagProps = {
                            severity : 'success',
                            icon     : 'pi pi-check',
                            value    : 'En cours de validité'
                        };

                        if (
                            moment(data.start_date).isAfter(moment()) ||
                            (data.end_date && moment().isAfter(data.end_date))
                        )
                            tagProps = {
                                severity : 'danger',
                                icon     : 'pi pi-times',
                                value    : 'Non valide'
                            };

                        return (
                            <ValidityContainer>
                                <span>{text}</span>
                                <Tag {...tagProps} />
                            </ValidityContainer>
                        );
                    }
                },
                {
                    name      : 'Types de déchets',
                    field     : 'trash_type',
                    component : (data: DiscountRo) => {
                        if (data.type === EDiscountType.DELIVERY) return 'N/A';
                        if (Object.values(ETrashType).length === data.trash_type.length) return <Tag value="Tous" />;
                        return (
                            <TrashContainer>
                                {data.trash_type?.map((trashType) => (
                                    <Tag key={trashType} value={mapTrashTypetext(trashType)} />
                                ))}
                            </TrashContainer>
                        );
                    }
                }
            ]}
        />
    );
};
