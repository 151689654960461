import React, { useMemo } from 'react';
import styled from 'styled-components';

import { CardErrors, ETruckFuelType, ETruckType } from '@bbng/util/types';

import { mapFuelType } from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import Input from '../../components/Inputs';

export type TruckCharacteristicsProps = {
    readOnly: boolean;
    value?: TruckCharacteristicsState;
    id: string;
    result: (
        value: TruckCharacteristicsState,
        errors: null | string[] | CardErrors<TruckCharacteristicsState>,
        id: string
    ) => void;
    displayError?: boolean;
    tags: ETruckType[];
};

export type TruckCharacteristicsState = {
    license_plate: string;
    fuel_type: string; // TODO: use EFuelType
    weight_ton: number | null;
    max_autonomy: number | null;
    remaining_autonomy: number | null;
    fuel_refill_duration: number | null;
    // usable_volume_m3: number | null;
    usable_load_kg: number | null;
    setup_duration: number | null;
    setdown_duration: number | null;
    profitability_bag_number_limit: number | null;
    profitability_dumpster_number_limit: number | null;
    bigbag_capacity: number | null;
    dumpster_capacity: number | null;
    bigbag_stock: number | null;
    bigbag_current_load_m3: number | null;
};

export type TruckCharacteristicsErrorState = CardErrors<TruckCharacteristicsState>;

export const initialState: TruckCharacteristicsState = {
    license_plate                       : '',
    fuel_type                           : '',
    weight_ton                          : null,
    max_autonomy                        : null,
    remaining_autonomy                  : null,
    fuel_refill_duration                : null,
    // usable_volume_m3                    : null,
    usable_load_kg                      : null,
    setup_duration                      : null,
    setdown_duration                    : null,
    profitability_bag_number_limit      : null,
    profitability_dumpster_number_limit : null,
    bigbag_capacity                     : null,
    dumpster_capacity                   : null,
    bigbag_stock                        : null,
    bigbag_current_load_m3              : null
};

export const initialErrorState: TruckCharacteristicsErrorState = generateInitialErrorState(initialState);

export const TruckCharacteristics: React.FC<TruckCharacteristicsProps> = ({
    readOnly,
    value = initialState,
    id,
    result,
    displayError,
    tags
}: TruckCharacteristicsProps) => {
    const [val, setVal] = React.useState<TruckCharacteristicsState>(value);
    const [err, setErr] = React.useState<TruckCharacteristicsErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    const isDumpster = useMemo(() => {
        return tags.some((tag) => [ETruckType.DUMPSTER_AMPLIROLL, ETruckType.DUMPSTER_CHAIN].includes(tag));
    }, [tags]);

    return (
        <StyledCard title="Caractéristiques du camion">
            <CardLine>
                <Input.LicensePlate
                    id="license_plate"
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    value={val.license_plate}
                    errors={err.license_plate}
                    displayError={displayError}
                />
                <Input.Dropdown
                    id="fuel_type"
                    label="Type de carburant"
                    options={Object.values(ETruckFuelType)}
                    itemTemplate={(item) => mapFuelType(item)}
                    valueTemplate={(item) => mapFuelType(item)}
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    value={val.fuel_type}
                    errors={err.fuel_type}
                    displayError={displayError}
                />
                <Input.Number
                    label="Poids (tonnes)"
                    id="weight_ton"
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    value={val.weight_ton}
                    errors={err.weight_ton}
                    displayError={displayError}
                />
                <Input.Number
                    label="Charge utile (kg)"
                    id="usable_load_kg"
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    value={val.usable_load_kg}
                    errors={err.usable_load_kg}
                    displayError={displayError}
                    step={500}
                />
            </CardLine>
            <CardLine>
                {/* <Input.Number
                    label="Volume utile (m3)"
                    id="usable_volume_m3"
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    value={val.usable_volume_m3}
                    errors={err.usable_volume_m3}
                    displayError={displayError}
                /> */}
                <Input.Number
                    label="Autonomie max (km)"
                    id="max_autonomy"
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    value={val.max_autonomy}
                    errors={err.max_autonomy}
                    displayError={displayError}
                    step={50}
                />
                <Input.Number
                    label="Autonomie restante (km)"
                    id="remaining_autonomy"
                    readOnly={readOnly}
                    required={false}
                    result={handleChange}
                    value={val.remaining_autonomy}
                    errors={err.remaining_autonomy}
                    displayError={displayError}
                />
                <Input.Number
                    label="Stock de sacs"
                    id="bigbag_stock"
                    readOnly={readOnly}
                    required={false}
                    result={handleChange}
                    value={val.bigbag_stock}
                    errors={err.bigbag_stock}
                    displayError={displayError}
                />
                <Input.Number
                    label="Volume actuel dans la benne (m3)"
                    id="bigbag_current_load_m3"
                    readOnly={readOnly || !tags.includes(ETruckType.BIG_BAG)}
                    required={false}
                    result={handleChange}
                    value={val.bigbag_current_load_m3}
                    errors={err.bigbag_current_load_m3}
                    displayError={displayError}
                />
            </CardLine>
            <CardLine>
                <Input.Number
                    label="Durée de rechargement (min)"
                    id="fuel_refill_duration"
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    value={val.fuel_refill_duration}
                    errors={err.fuel_refill_duration}
                    displayError={displayError}
                />
                <Input.Number
                    label="Durée de d'installation (min)"
                    id="setup_duration"
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    value={val.setup_duration}
                    errors={err.setup_duration}
                    displayError={displayError}
                />
                <Input.Number
                    label="Durée de démontage (min)"
                    id="setdown_duration"
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    value={val.setdown_duration}
                    errors={err.setdown_duration}
                    displayError={displayError}
                />
            </CardLine>
            <CardLine>
                <Input.Number
                    label="Nombre de bennes de profit min"
                    id="profitability_dumpster_number_limit"
                    readOnly={readOnly || !isDumpster}
                    required={isDumpster}
                    result={handleChange}
                    value={val.profitability_dumpster_number_limit}
                    errors={err.profitability_dumpster_number_limit}
                    displayError={displayError}
                />
                <Input.Number
                    label="Capacité de bennes"
                    id="dumpster_capacity"
                    readOnly={readOnly || !isDumpster}
                    required={isDumpster}
                    result={handleChange}
                    value={val.dumpster_capacity}
                    errors={err.dumpster_capacity}
                    displayError={displayError}
                />
                <Input.Number
                    label="Capacité de sacs (1m³)"
                    id="bigbag_capacity"
                    readOnly={readOnly || !tags.includes(ETruckType.BIG_BAG)}
                    required={tags.includes(ETruckType.BIG_BAG)}
                    result={handleChange}
                    value={val.bigbag_capacity}
                    errors={err.bigbag_capacity}
                    displayError={displayError}
                />
                <Input.Number
                    label="Nombre de sacs de profit min"
                    id="profitability_bag_number_limit"
                    readOnly={readOnly || !tags.includes(ETruckType.BIG_BAG)}
                    required={tags.includes(ETruckType.BIG_BAG)}
                    result={handleChange}
                    value={val.profitability_bag_number_limit}
                    errors={err.profitability_bag_number_limit}
                    displayError={displayError}
                />
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
