import { PhoneNumberUtil } from 'google-libphonenumber';
import React from 'react';
import Phone, { PhoneInputProps, CountryData as RPICountryData } from 'react-phone-input-2';
import styled from 'styled-components';
import * as yup from 'yup';

import { PhoneValue } from '@bbng/util/types';

import { useComponentInput } from '../../hooks/ComponentInput';

export type InputPhoneProps = Omit<PhoneInputProps, 'value'> & {
    value: PhoneValue | null;
    required?: boolean;
    readOnly?: boolean;
    id: string;
    result: (value: PhoneValue | null, error: null | string[], id: string) => void;
    errors: null | string[];
    triggerValidation?: boolean;
    displayError?: boolean;
};

const StyledPhone = styled(Phone)`
    width: 250px;
    .form-control {
        padding: 0;
        padding-left: 58px;
        height: 39.5px;
        width: 250px;
        :hover,
        :focus {
            border: 1px solid var(--primary-color);
        }
        :focus {
            box-shadow: 0 0 0 0.2rem #c7d2fe;
        }
    }
`;

const InputPhone: React.FC<InputPhoneProps> = ({
    value = null,
    required = false,
    readOnly = false,
    id,
    result,
    errors,
    specialLabel = 'Téléphone',
    displayError,
    ...props
}: InputPhoneProps) => {
    const baseValidationSchema = yup
        .object({
            countryCode  : yup.string().required(),
            dialCode     : yup.string().required(),
            name         : yup.string().required(),
            phone_number : yup
                .string()
                .required()
                .test('Number', 'Vous devez rentrer un numéro valide', function (phone?: string) {
                    const countryCode = this.parent.countryCode;
                    const dialCode = this.parent.dialCode;
                    if (!countryCode || !dialCode || !phone) return false;

                    const instance: PhoneNumberUtil = PhoneNumberUtil.getInstance();
                    const number = instance.parse(dialCode + phone, countryCode);
                    return instance.isValidNumberForRegion(number, countryCode);
                })
        })
        .nullable();
    const schema = required ? baseValidationSchema.required('Téléphone requis') : baseValidationSchema;

    const { handleBlur, val, err, displayErr, setErr, setVal } = useComponentInput({
        id,
        value,
        errors,
        displayError        : displayError ?? false,
        result,
        required,
        schema,
        didRevalidateSchema : (e) => !!e && Object.keys(e).length > 0,
        mapValue            : (e) => e.target.value
    });

    /**
     * Handle change is defined here (not in custom hook) as it has custom parameters
     */
    const handleOnChange = React.useCallback(
        (phone: string, data: RPICountryData) => {
            if (!data.countryCode) return;

            const phoneValue = {
                countryCode  : data.countryCode.toUpperCase() as PhoneValue['countryCode'],
                dialCode     : data.dialCode,
                name         : data.name as PhoneValue['name'],
                phone_number : phone.replace(data.dialCode, '')
            };
            setVal(phoneValue);
            setErr(null);
            result(phoneValue, null, id);
        },
        [setVal, setErr, result]
    );

    return (
        <StyledPhone
            country="fr"
            inputClass={`phoneNumber p-inputtext p-intputtext-sm p-component custom-input-text ${
                displayErr && err && err.length > 0 ? 'p-invalid' : ''
            }`}
            enableSearch
            countryCodeEditable={false}
            onChange={handleOnChange}
            onBlur={handleBlur}
            isValid={() => (displayErr && err && err.length > 0 ? err[0] : true)}
            disabled={readOnly}
            specialLabel={specialLabel + (required ? ' *' : '')}
            {...(val ? { value: val.dialCode + val.phone_number } : { value: '33' })}
            {...props}
        />
    );
};

export default InputPhone;
