import React, { useCallback, useEffect, useState } from 'react';

import { Content, Footer, PageContainer, PageContent, StyledCard, Text } from './style';
import { useParams } from 'react-router-dom';
import { CustomerInvitationData } from '@bbng/util/types';
import { Button } from 'primereact/button';
import { useRequest } from '../../hooks/StatelessRequest';
import { urlApiBuilder } from '../../common/urlBuilder';
import { ProgressSpinner } from 'primereact/progressspinner';

const AcceptCustomerInvitation: React.FC = (): JSX.Element => {
    const { token } = useParams();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [submitLoading, setSubmitLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [invalidToken, setInvalidToken] = useState(false);

    const [invitationData, setInvitationData] = useState<CustomerInvitationData>();

    const fetchInvitation = async (token: string) => {
        setFetchLoading(true);
        const res = await bbngRequest<CustomerInvitationData>({
            url    : urlApiBuilder.customerGetInvitation(token),
            method : 'GET'
        });
        if (res.success && res.response?.data.ro) {
            setInvitationData(res.response.data.ro);
        } else {
            setInvalidToken(true);
        }
        setFetchLoading(false);
    };

    useEffect(() => {
        if (token) {
            fetchInvitation(token);
        }
    }, [token]);

    const handleAcceptInvitation = async (accept: boolean) => {
        setSubmitLoading(true);

        if (token) {
            const url = accept
                ? urlApiBuilder.customerAcceptInvitation(token)
                : urlApiBuilder.customerRejectInvitation(token);
            const res = await bbngRequest({
                method: 'POST',
                url
            });
            if (res.success) {
                setSuccess(true);
                setAccepted(accept);
            }
        } else {
            setInvalidToken(true);
        }

        setSubmitLoading(false);
    };

    const renderContent = useCallback(() => {
        if (fetchLoading || submitLoading) {
            return <ProgressSpinner />;
        } else if (success) {
            return <span>La demande d'accès a été {accepted ? 'acceptée' : 'refusée'} avec succès !</span>;
        } else if (invitationData) {
            return (
                <>
                    <Text>
                        <span>
                            {invitationData.user_name} ({invitationData.user_email}) souhaite rejoindre{' '}
                            {invitationData.customer_name} !
                        </span>
                        <span>
                            En acceptant, vous lui permettez de passer des commandes et les consulter directement
                            depuis&nbsp;
                            <a href="https://endless.fr/my-endless" target="_blank" rel="noreferrer">
                                notre application mobile&nbsp;
                            </a>
                            ou sur l'espace client&nbsp;
                            <a href="https://my.endless.fr" target="_blank" rel="noreferrer">
                                MY ENDLESS
                            </a>
                            , pour le compte de {invitationData.customer_name}.
                        </span>
                    </Text>
                    <Footer>
                        <Button
                            className="p-button-danger"
                            disabled={!token}
                            icon="pi pi-times"
                            label="Refuser"
                            onClick={() => handleAcceptInvitation(false)}
                        />
                        <Button
                            className="p-button-success"
                            disabled={!token}
                            icon="pi pi-check"
                            label="Accepter"
                            onClick={() => handleAcceptInvitation(true)}
                        />
                    </Footer>
                </>
            );
        } else if (invalidToken) {
            return (
                <Content>
                    <span>Oups ! Cette demande d'accès est expirée, invalide ou a déjà été traitée.</span>
                </Content>
            );
        }
        return (
            <Content>
                <span>Une erreur est survenue, essayez de recharger la page...</span>
            </Content>
        );
    }, [fetchLoading, success, invitationData, submitLoading, invalidToken, accepted]);

    return (
        <PageContainer>
            <PageContent>
                <StyledCard>
                    <h1>🔐 Demande d'accès</h1>
                    {renderContent()}
                </StyledCard>
            </PageContent>
        </PageContainer>
    );
};

export default AcceptCustomerInvitation;
