import styled from 'styled-components';

export const PageContainer = styled.div`
    background-image: url('../../assets/images/ndlss-1.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
    height: 100vh;
    display: flex;
`;

export const PageContent = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
`;
