import { Currency } from 'dinero.js';

import { CountryData } from './country';
import { DiscountRo } from './discount';
import { RelationKeys } from './dto';
import { ProductPrice } from './product';

export interface IGenericObject {
    [key: string]: unknown;
}

export type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};

export type MaybeArray<T> = Array<T> | T;

export type ObjectKeys<T> = Extract<keyof T, string>;

export type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

export type PrismaJsonValue = string | number | boolean | null | PrismaJsonObject | PrismaJsonArray;

export function toPrismaJsonValue<T>(data: T) {
    return data as unknown as PrismaJsonValue;
}

class JsonNull {
    private JsonNull!: never;
}
export const PrismaJsonNull: JsonNull = new JsonNull();

// eslint-disable-next-line
export interface PrismaJsonObject extends Record<string, PrismaJsonValue> {}
// eslint-disable-next-line
export interface PrismaJsonArray extends Array<PrismaJsonValue> {}

export interface EditAction {
    at: ISODate;
    by: string;
    payload: PrismaJsonValue;
}

export type HistoryRo = {
    created_by: string;
    created_at: ISODate;
    updated_at: ISODate;
    updated_by: EditAction[];
};

export type PhoneValue = {
    name: CountryData['name'];
    countryCode: CountryData['iso2'];
    dialCode: string;
    phone_number: string;
};

export interface IContact {
    firstname: string;
    lastname: string;
    email: string;
    phone_number: string;
}

export interface ICloseTime {
    from: ISODate;
    to: ISODate;
}

export type IOpeningDay = {
    day?: string;
    morning_open: ISODate;
    morning_close: ISODate;
    afternoon_open: ISODate;
    afternoon_close: ISODate;
    is_closed: boolean;
    disabled?: boolean;
};
export type CardErrors<T> = {
    [K in ObjectKeys<T>]: null | string[];
};

export interface IOpeningTime<T = IOpeningDay> {
    0: T; // Monday
    1: T; // Tuesday
    2: T; // Wednesday
    3: T; // Thursday
    4: T; // Friday
    5: T; // Saturday
    6: T; // Sunday
}

export const BillingBranch = {
    ENDLESS_1 : 'ENDLESS_1',
    ENDLESS_2 : 'ENDLESS_2',
    ENDLESS_3 : 'ENDLESS_3'
} as const;
export type BillingBranch = typeof BillingBranch[keyof typeof BillingBranch];

export enum ETrashType {
    PAPER_CARDBOARD = 'PAPER_CARDBOARD',
    METAL = 'METAL',
    PLASTIC = 'PLASTIC',
    GLASS = 'GLASS',
    WOOD = 'WOOD',
    MINERAL_FRACTION = 'MINERAL_FRACTION',
    PLASTER = 'PLASTER',
    ASBESTOS = 'ASBESTOS',
    LEAD = 'LEAD',
    MIXED = 'MIXED',
    RUBBLE = 'RUBBLE',
    OIW = 'OIW', // DIB
    GREEN_TRASH = 'GREEN_TRASH',
    ULTIMATE_TRASH = 'ULTIMATE_TRASH'
}

export enum EPriceUnit {
    M3 = 'M3',
    BAG = 'BAG',
    T = 'T',
    KG = 'KG'
}

export interface ITrashDetails {
    price_value: ProductPrice;
    price_unit: EPriceUnit;
    type: ETrashType;
}
export interface IDropdownEntry {
    label: string;
    value: string;
}

export type ISODate = string;

export enum EDataType {
    LANDFILL = 'LANDFILL',
    CUSTOMER = 'CUSTOMER',
    CUSTOMER_MANDATE = 'CUSTOMER_MANDATE',
    PRESTA = 'PRESTA',
    ADMIN = 'ADMIN',
    ITEM = 'ITEM',
    AUTH0 = 'AUTH0',
    DISCOUNT = 'DISCOUNT',
    DOCUMENT = 'DOCUMENT',
    CONSTRUCTION_SITE = 'CONSTRUCTION_SITE',
    TRUCK = 'TRUCK',
    INTERNAL_PRODUCT = 'INTERNAL_PRODUCT',
    COLLECTOR = 'COLLECTOR',
    ORDER = 'ORDER',
    COLLECT = 'COLLECT',
    DUMPSTER_ON_SITE = 'DUMPSTER_ON_SITE',
    DUMPSTER = 'DUMPSTER',
    USER = 'USER',
    EMAIL = 'EMAIL',
    COLLECT_CONFIG = 'COLLECT_CONFIG',
    PLANNING = 'PLANNING',
    INVOICE = 'INVOICE',
    BSD = 'BSD',
    CONTACT = 'CONTACT',
    GOCARDLESS = 'GOCARDLESS',
    PERMISSION = 'PERMISSION',
    STRIPE = 'STRIPE',
    VRP_ALGORITHM = 'VRP_ALGORITHM',
    WAITING_LIST = 'WAITING_LIST',
    ZOHO = 'ZOHO',
    PRODUCT = 'PRODUCT',
    ZONE = 'ZONE',
    GOOGLE_DRIVE = 'GOOGLE_DRIVE',
    RECYCLING_CHARACTERISTICS = 'RECYCLING_CHARACTERISTICS',
    CUSTOMER_APP_VERSION = 'CUSTOMER_APP_VERSION',
    AUTHENTICATION = 'AUTHENTICATION',
    CC_REPLAN_ACTIVITY = 'CC_REPLAN_ACTIVITY',
    CC_PUBLIC_INFORMATIONS = 'CC_PUBLIC_INFORMATIONS',
    SMS = 'SMS',
    HERE = 'HERE'
}

export type DataType =
    | 'landfill'
    | 'customer'
    | 'admin'
    | 'item'
    | 'auth0'
    | 'discount'
    | 'document'
    | 'construction-site'
    | 'truck'
    | 'internal_product'
    | 'collector'
    | 'presta'
    | 'order'
    | 'collect'
    | 'dumpster'
    | 'user'
    | 'email'
    | 'collect-config'
    | 'planning'
    | 'invoice'
    | 'bsd'
    | 'product'
    | 'zone'
    | 'google-drive'
    | 'customer-app-version'
    | 'dumpster-on-site'
    | 'cc-replan-activity'
    | 'authentication'
    | 'cc-public-informations'
    | 'sms';

export type DataAction = 'create' | 'read' | 'update' | 'admin';

export type AccessPermission =
    | `${DataAction}:${DataType}`
    | 'public:product'
    | 'public:discount'
    | 'upload:item'
    | 'roles:user'
    | 'calculate:planning'
    | 'internal:planning:recalculate'
    | 'internal:invoice:create'
    | 'read:kpi'
    | 'instant-delivery:order'
    | 'read:mandates';

export type GenericArchiveDto = {
    archived: boolean;
};

export type PriceObject = {
    amount: number;
    currency: Currency;
};

export type NdlssPrice = {
    base_net: PriceObject;
    base_vat: PriceObject;
    base_total: PriceObject;
    discounted_net: PriceObject;
    discounted_vat: PriceObject;
    discounted_total: PriceObject;
    applied_discounts: DiscountRo[];
    price_per_product: {
        product_id: string;
        base_net: PriceObject;
        base_vat: PriceObject;
        base_total: PriceObject;
        discounted_net: PriceObject;
        discounted_vat: PriceObject;
        discounted_total: PriceObject;
        applied_discount: DiscountRo | null;
    }[];
};

export const isFetched = <DataType, RelationType extends Record<string, any>>(
    data: DataType,
    key: RelationKeys<DataType>
): data is DataType & RelationType => {
    if (Array.isArray(data[key])) {
        return (data[key] as any[]).every((val) => typeof val !== 'string');
    }
    return data[key] !== 'string';
};

export type InternalReq = {
    method: string;
    baseUrl: string;
    path: string;
    query: Record<string, any>;
    headers: { authorization: `Bearer ${string}` };
};

export const DEFAULT_PAGE_LIMIT = 10;
export const DEFAULT_PAGE_IDX = 0;

export const ALLOW_GOD_MODE_KEY = 'GOD_MODE';

/**
 * Type used for invoice flows
 */
export type IdWithInvoiceId = {
    id: string;
    invoice_id: string[];
};

export type EmptyObject = Record<any, never>;

export const CREATED_FROM = {
    BACK_OFFICE   : 'BO', // legacy, should not be used anymore (use email/inbound_call/outbound_call instead to have more precision on the source)
    APPLICATION   : 'APPLICATION',
    ECOMMERCE     : 'ECOMMERCE',
    EMAIL         : 'EMAIL',
    INBOUND_CALL  : 'INBOUND_CALL',
    OUTBOUND_CALL : 'OUTBOUND_CALL'
} as const;
export type CREATED_FROM = typeof CREATED_FROM[keyof typeof CREATED_FROM];
