export interface ZohoPaymentInvoice {
    invoice_id: string;
    amount_applied: number;
}

export interface ZohoPaymentCreateDto {
    customer_id: string;
    payment_mode: ZOHO_PAYMENT_MODE;
    amount: number;
    date: string; // YYYY-MM-DD
    reference_number: string; // stripe payment id
    invoices: ZohoPaymentInvoice[];
    description?: string;
}

export const ZOHO_PAYMENT_MODE = {
    CHECK            : 'check',
    CASH             : 'cash',
    CREDIT_CARD      : 'creditcard',
    BANK_TRANSFER    : 'banktransfer',
    BANK_REMITTANCE  : 'bankremittance',
    AUTO_TRANSACTION : 'autotransaction',
    OTHERS           : 'others'
} as const;
export type ZOHO_PAYMENT_MODE = typeof ZOHO_PAYMENT_MODE[keyof typeof ZOHO_PAYMENT_MODE];

export interface ZohoPaymentInvoiceRo {
    invoice_number: string;
    invoice_payment_id: string;
    invoice_id: string;
    amount_applied: number;
    tax_amount_withheld: number;
    discount_amount: number;
    total: number;
    balance: number;
    date: string; // YYYY-MM-DD
    due_date: string; // YYYY-MM-DD
    price_precision: number;
    apply_date: string;
    allow_partial_payments: string;
}

export interface ZohoPaymentRo {
    payment_id: string;
    payment_number: string;
    payment_link_id: string;
    created_time: Date;
    updated_time: Date;
    payment_number_prefix: string;
    payment_number_suffix: string;
    documents: any[];
    customer_id: string;
    customer_name: string;
    payment_mode: ZOHO_PAYMENT_MODE;
    card_type: string;
    date: string; // YYYY-MM-DD
    offline_created_date_with_time: string;
    account_id: string;
    account_name: string;
    account_type: string;
    customer_advance_account_id: string;
    customer_advance_account_name: string;
    currency_id: string;
    currency_symbol: string;
    currency_code: string;
    exchange_rate: number;
    amount: number;
    unused_amount: number;
    bank_charges: number;
    tax_account_id: string;
    is_client_review_settings_enabled: boolean;
    tax_account_name: string;
    tax_amount_withheld: number;
    discount_amount: number;
    description: string;
    reference_number: string;
    online_transaction_id: string;
    payment_gateway: string;
    settlement_status: string;
    invoices: ZohoPaymentInvoiceRo[];
    payment_refunds: any[];
    last_four_digits: string;
    template_id: string;
    template_name: string;
    page_width: string;
    page_height: string;
    orientation: string;
    template_type: string;
    attachment_name: string;
    can_send_in_mail: boolean;
    can_send_payment_sms: boolean;
    is_payment_details_required: boolean;
    custom_fields: any[];
    imported_transactions: any[];
}
