import moment from 'moment';

import { convertHHMMToDate, getMinutesFromDate, mergeDates, minTwoDigits } from '@bbng/util/misc';
import {
    CCAdministrativeRoFront,
    CCAdministrativeUpdateDto,
    CCRo,
    CCServiceRo,
    CCServiceRoFront,
    CCServiceUpdateDto,
    CC_FAMILY,
    CC_PATCH_ORIGIN,
    CollectorRo,
    DumpsterOnSiteRo,
    ECollectCharacteristic,
    isCCServiceFront,
    PrestaRo
} from '@bbng/util/types';

import {
    getRelationsDto,
    mapBackContactToFrontContact,
    mapFrontContactToBackContact,
    optimiseEditDto
} from '../../common/form';
import {
    OrderCollectInfoErrorState,
    OrderCollectInfoState,
    initialErrorState as orderCollectInfoInitialErrorState,
    initialState as orderCollectInfoInitialState
} from '../../modules/order/CollectInfo';
import {
    OrderProductsErrorState,
    OrderProductsState,
    ProductRoWithQuantity,
    initialErrorState as orderProductsInitialErrorState,
    initialState as orderProductsInitialState
} from '../../modules/order/Products';
import {
    CollectConfigCollectorTruckErrorState,
    CollectConfigCollectorTruckState,
    initialErrorState as collectorTruckInitialErrorState,
    initialState as collectorTruckInitialState
} from '../../modules/collect-config/Collector&Truck';
import {
    CollectConfigMiscErrorState,
    CollectConfigMiscState,
    initialErrorState as miscInitialErrorState,
    initialState as miscInitialState
} from '../../modules/collect-config/Misc';
import {
    PatchTrackerErrorState,
    PatchTrackerState,
    initialErrorState as patchTrackerInitialErrorState,
    initialState as patchTrackerInitialState
} from '../../modules/collect-config/PatchTracker';
import {
    AddressErrorState,
    AddressState,
    initialErrorState as addressInitialErrorState,
    initialState as addressInitialState
} from '../../modules/common/Address';
import {
    ContactsErrorState,
    ContactsState,
    initialErrorState as contactsInitialErrorState,
    initialState as contactsInitialState
} from '../../modules/common/Contacts';
import {
    DumpsterOnSiteSelectErrorState,
    DumpsterOnSiteSelectState,
    initialErrorState as dumpsterOnSiteSelectInitialErrorState,
    initialState as dumpsterOnSiteSelectInitialState
} from '../../modules/collect-config/DumpsterOnSiteSelect';

export type CollectConfigModulesStates =
    | CollectConfigMiscState
    | AddressState
    | CollectConfigCollectorTruckState
    | OrderProductsState
    | OrderCollectInfoState
    | PatchTrackerState
    | DumpsterOnSiteSelectState
    | ContactsState;

export type CollectConfigModulesErrorStates =
    | CollectConfigMiscErrorState
    | AddressErrorState
    | CollectConfigCollectorTruckErrorState
    | OrderProductsErrorState
    | OrderCollectInfoErrorState
    | PatchTrackerErrorState
    | DumpsterOnSiteSelectErrorState
    | ContactsErrorState;

export type CollectConfigFormState = {
    collectInfo?: OrderCollectInfoState;
    misc: CollectConfigMiscState;
    contacts: ContactsState;
    address: AddressState;
    collectorTruck: CollectConfigCollectorTruckState;
    products: OrderProductsState;
    patchTracker: PatchTrackerState;
    dumpsterOnSiteSelect: DumpsterOnSiteSelectState;
};

export type CollectConfigFormErrorState = {
    collectInfo?: OrderCollectInfoErrorState;
    misc: CollectConfigMiscErrorState;
    contacts: ContactsErrorState;
    address: AddressErrorState;
    collectorTruck: CollectConfigCollectorTruckErrorState;
    products: OrderProductsErrorState;
    patchTracker: PatchTrackerErrorState;
    dumpsterOnSiteSelect: DumpsterOnSiteSelectErrorState;
};

export const initialState: CollectConfigFormState = {
    collectInfo          : orderCollectInfoInitialState,
    misc                 : miscInitialState,
    contacts             : contactsInitialState,
    address              : addressInitialState,
    collectorTruck       : collectorTruckInitialState,
    products             : orderProductsInitialState,
    patchTracker         : patchTrackerInitialState,
    dumpsterOnSiteSelect : dumpsterOnSiteSelectInitialState
};

export const initialErrorState: CollectConfigFormErrorState = {
    collectInfo          : orderCollectInfoInitialErrorState,
    misc                 : miscInitialErrorState,
    contacts             : contactsInitialErrorState,
    address              : addressInitialErrorState,
    collectorTruck       : collectorTruckInitialErrorState,
    products             : orderProductsInitialErrorState,
    patchTracker         : patchTrackerInitialErrorState,
    dumpsterOnSiteSelect : dumpsterOnSiteSelectInitialErrorState
};

export const mapApiDataToState = (
    collect_config: CCServiceRoFront | CCAdministrativeRoFront
): CollectConfigFormState => {
    const ccService = isCCServiceFront(collect_config) ? collect_config : undefined;

    const collector = () => {
        if (collect_config.collector_id.length > 0) {
            return ccService
                ? (ccService.collector_id[0] as CollectorRo)
                : (collect_config.collector_id[0] as CollectorRo);
        }
        return undefined;
    };

    const dumpsterOnSite = () => {
        if (ccService?.dumpster_on_site_id && ccService?.dumpster_on_site_id?.length > 0) {
            return ccService.dumpster_on_site_id[0] as DumpsterOnSiteRo;
        }
        return undefined;
    };

    const depositCC = () => {
        if (ccService?.deposit_collect_config_id && ccService?.deposit_collect_config_id?.length > 0) {
            return ccService.deposit_collect_config_id[0] as CCServiceRo;
        }
        return undefined;
    };

    return {
        misc: {
            title                  : collect_config?.title as string,
            comment                : collect_config.comment as string,
            execution_time_minutes : moment(0)
                .set('hours', Math.floor(collect_config.execution_time_minutes / 60))
                .set('minutes', Math.floor(collect_config.execution_time_minutes % 60))
                .toDate()
        },
        collectInfo: {
            collect_day : moment(collect_config.from_date).toISOString(),
            from_date   : convertHHMMToDate(
                `${minTwoDigits(moment(collect_config.from_date).hours())}:${minTwoDigits(
                    moment(collect_config.from_date).minutes()
                )}`
            ),
            to_date: convertHHMMToDate(
                `${minTwoDigits(moment(collect_config.to_date).hours())}:${minTwoDigits(
                    moment(collect_config.to_date).minutes()
                )}`
            ),
            already_available           : !!ccService?.already_available_date,
            already_available_from_date : ccService?.already_available_date?.from_date
                ? moment.utc(ccService?.already_available_date.from_date).toISOString()
                : moment().toDate(),
            already_available_to_date: ccService?.already_available_date?.to_date
                ? moment.utc(ccService?.already_available_date.to_date).toISOString()
                : moment().add(1, 'hour').toDate(),
            characteristics      : ccService?.characteristics as ECollectCharacteristic[],
            waiting_time_minutes : ccService?.waiting_time_minutes
                ? moment(0)
                      .set('hours', Math.floor(ccService?.waiting_time_minutes / 60))
                      .set('minutes', Math.floor(ccService?.waiting_time_minutes % 60))
                      .toDate()
                : undefined,
            by_presta : ccService?.presta_id[0] !== undefined,
            presta    : ccService?.presta_id[0] as PrestaRo
        },
        address: {
            address_google        : undefined,
            address_street_number : collect_config.address.components['street_number'],
            address_street_name   : collect_config.address.components['route'],
            address_city          : collect_config.address.components['locality'],
            address_zip_code      : collect_config.address.components['postal_code'],
            address_country       : collect_config.address.components['country'],
            address_complement    : collect_config.address.components['complement'] ?? '',
            address_lat           : collect_config.address.coordinates.latitude,
            address_lng           : collect_config.address.coordinates.longitude
        },
        contacts: {
            construction_site : ccService?.construction_site_contact.map((c) => mapBackContactToFrontContact(c)) || [],
            logistic          : ccService?.log_contact.map((c) => mapBackContactToFrontContact(c)) || []
        },
        collectorTruck: {
            collector: collector()
        },
        products:
            ccService?.products.reduce((acc, product) => {
                acc[product.id] = product as unknown as ProductRoWithQuantity;
                return acc;
            }, {} as OrderProductsState) || {},
        patchTracker: {
            patch_origin : CC_PATCH_ORIGIN.CLIENT_REQUEST,
            patch_reason : ''
        },
        dumpsterOnSiteSelect: {
            dumpster_on_site : dumpsterOnSite(),
            collect_config   : depositCC()
        }
    };
};

export const mapStateToApiEditData = (
    state: CollectConfigFormState,
    fetchData: CCRo,
    apiState: CollectConfigFormState,
    isAdministrative?: boolean
): CCAdministrativeUpdateDto | CCServiceUpdateDto => {
    const loadWait = fetchData.family === CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT;

    const mapper = (st: CollectConfigFormState) => ({
        title                  : st.misc.title || undefined,
        comment                : st.misc.comment,
        execution_time_minutes : getMinutesFromDate(st.misc.execution_time_minutes),
        from_date              : mergeDates(st.collectInfo?.collect_day, st.collectInfo?.from_date as string),
        to_date                : mergeDates(st.collectInfo?.collect_day, st.collectInfo?.to_date as string),
        already_available_date : st.collectInfo?.already_available
            ? {
                  from_date: mergeDates(
                      st.collectInfo?.collect_day,
                      st.collectInfo?.already_available_from_date as string
                  ),
                  to_date: mergeDates(st.collectInfo?.collect_day, st.collectInfo?.already_available_to_date as string)
              }
            : undefined,
        ...(isAdministrative && {
            address: {
                coordinates: {
                    latitude  : st.address.address_lat as number,
                    longitude : st.address.address_lng as number
                },
                name           : (st.address.address_street_name || st.address.address_google?.name) ?? '',
                formatted_name :
                    (st.address.address_google?.formatted_address ||
                        st.address.address_street_name ||
                        st.address.address_google?.name) ??
                    '',
                components: {
                    street_number : st.address.address_street_number,
                    route         : st.address.address_street_name,
                    locality      : st.address.address_city,
                    postal_code   : st.address.address_zip_code,
                    country       : st.address.address_country,
                    complement    : st.address.address_complement
                }
            }
        }),
        ...(!isAdministrative && {
            waiting_time_minutes      : loadWait ? getMinutesFromDate(st.collectInfo?.waiting_time_minutes) : undefined,
            characteristics           : st.collectInfo?.characteristics,
            construction_site_contact : st.contacts.construction_site.map((c) => mapFrontContactToBackContact(c)),
            log_contact               : st.contacts.logistic.map((c) => mapFrontContactToBackContact(c)),
            lines                     : Object.values(st.products)
                .filter((product) => product.quantity > 0)
                .map((product) => ({
                    id       : product.id,
                    quantity : product.quantity
                }))
        })
    });

    const body = optimiseEditDto(state, apiState, fetchData, mapper, getRelationsDto<CCRo>());

    const relationStateAdministrative = {
        collector_id: state.collectorTruck.collector ? [state.collectorTruck.collector.id] : []
    };

    const relationStateService = {
        presta_id: state.collectInfo?.by_presta && state.collectInfo?.presta?.id ? [state.collectInfo.presta.id] : []
    };

    const adminUpdateDto: CCAdministrativeUpdateDto = {
        ...body,
        ...relationStateAdministrative
    };

    const serviceUpdateDto: CCServiceUpdateDto = {
        ...body,
        ...relationStateService,
        patch_origin        : state.patchTracker.patch_origin,
        patch_reason        : state.patchTracker.patch_reason,
        // put it manually as it's a _id field and it will be removed by optimiseEditDto
        dumpster_on_site_id : state.dumpsterOnSiteSelect.dumpster_on_site
            ? [state.dumpsterOnSiteSelect.dumpster_on_site.id]
            : [],
        deposit_collect_config_id: state.dumpsterOnSiteSelect.collect_config
            ? [state.dumpsterOnSiteSelect.collect_config.id]
            : []
    };

    return isAdministrative ? adminUpdateDto : serviceUpdateDto;
};
