import Joi from 'joi';
import moment from 'moment';

import { EPlanningType } from '../planning';

moment.locale('fr');

export const VRPConstantCommonEditDtoSchema = Joi.object({
    type: Joi.string()
        .valid(...Object.keys(EPlanningType))
        .required(),

    CTG_LONG_POLLING_AVEC_REDIS                                       : Joi.boolean(),
    CTG_SOLVER_DUREE_OPTIMISATION_EN_SECONDES                         : Joi.number().positive(),
    CTG_SOLVER_VERBOSE_DISPLAY_MODE                                   : Joi.number().positive(),
    CTG_OSM_ZOOM_LEVEL                                                : Joi.number().positive(),
    CTG_OSM_DISTANCE_CORRECTION                                       : Joi.number().positive(),
    CTG_NB_UNITE_PAR_HEURE                                            : Joi.number().positive(),
    CTG_UNITE_TEMPORELLE_MINUTE                                       : Joi.number().positive(),
    CTG_CHAUFFEUR_MAX_HEURE_DE_TRAVAIL                                : Joi.string(),
    CTG_CHAUFFEUR_MAX_NB_COMMANDE                                     : Joi.number().positive(),
    CTG_CHAUFFEUR_MAX_STEPS                                           : Joi.number().positive(),
    CTG_CHAUFFEUR_UPPER_BOUND_NB_MAX_COMMANDES_PAR_JOUR               : Joi.number().positive(),
    CTG_CHAUFFEUR_UPPER_BOUND_NB_MAX_DISTANCES_COMMANDES_CONSECUTIVES : Joi.number().positive(),
    CTG_CHAUFFEUR_MAX_HEURE_GLISSANTE_REGLEMENTATION                  : Joi.string(),
    CTG_CHAUFFEUR_PAUSE                                               : Joi.number().positive(),
    CTG_CHAUFFEUR_ATTENTE                                             : Joi.number().positive(),
    CTG_CHAUFFEUR_TRAITEMENT                                          : Joi.number().positive(),
    CTG_CHAUFFEUR_ROUTES                                              : Joi.number().positive(),
    CTG_JOURS_DE_COLLECTE                                             : Joi.array()
        .items(...moment.weekdays(true))
        .min(1)
});

export const VRPConstantDumpsterEditDtoSchema = Joi.object({
    CTG_COMMANDE_CAPACITE_FLOTTE_TOURS         : Joi.number().positive(),
    CTG_CAMION_DUREE_LEVAGE_BENNE_VIDE         : Joi.number().positive(),
    CTG_CAMION_DUREE_DEPOT_BENNE_VIDE          : Joi.number().positive(),
    CTG_CAMION_DUREE_LEVAGE_BENNE_PLEINE       : Joi.number().positive(),
    CTG_CAMION_DUREE_DEPOT_BENNE_PLEINE        : Joi.number().positive(),
    CTG_CAMION_DUREE_DECHARGEMENT_BENNE_PLEINE : Joi.number().positive(),
    CTG_CAMION_DUREE_TRAITEMENT_BENNE          : Joi.number().positive()
}).concat(VRPConstantCommonEditDtoSchema);

const _VRPBigBagTypeSchema = Joi.object({
    volume_big_bag_m3        : Joi.number().positive(),
    charge_utile_big_bag_kg  : Joi.number().positive(),
    duree_traitement_big_bag : Joi.string()
});

export const VRPConstantBigbagEditDtoSchema = Joi.object({
    CTG_DDXXSBIGM                  : Joi.number().positive(),
    CTG_DXXSBIGM                   : Joi.number().positive(),
    CTG_XXSBIGM                    : Joi.number().positive(),
    CTG_XSBIGM                     : Joi.number().positive(),
    CTG_SBIGM                      : Joi.number().positive(),
    CTG_MBIGM                      : Joi.number().positive(),
    CTG_LBIGM                      : Joi.number().positive(),
    CTG_XBIGM                      : Joi.number().positive(),
    CTG_CAMION_DUREE_SET_UP        : Joi.string(),
    CTG_CAMION_DUREE_SET_DOWN      : Joi.string(),
    CTG_CAMION_DUREE_TRAITEMENT_BB : Joi.string(),
    CTG_CAMION_MIN_VOLUME_UTILE_M3 : Joi.number().positive(),
    CTG_CAMION_MIN_VOLUME_UTILE_KG : Joi.number().positive(),
    CTG_CAMION_MAX_VOLUME_UTILE_M3 : Joi.number().positive(),
    CTG_CAMION_MAX_VOLUME_UTILE_KG : Joi.number().positive(),
    CTG_BIG_BAG_GAIN_B0            : Joi.number().positive(),
    CTG_BIG_BAG_DEMI_GAIN_B0       : Joi.number().positive(),
    CTG_BIG_BAG_GAIN_B1            : Joi.number().positive(),
    CTG_BIG_BAG_DEMI_GAIN_B1       : Joi.number().positive(),
    CTG_BIG_BAG_GAIN_B2            : Joi.number().positive(),
    CTG_BIG_BAG_DEMI_GAIN_B2       : Joi.number().positive(),
    CTG_BIG_BAG_TYPE               : {
        B250L : _VRPBigBagTypeSchema,
        B1M3  : _VRPBigBagTypeSchema,
        B2M3  : _VRPBigBagTypeSchema
    }
}).concat(VRPConstantCommonEditDtoSchema);
