import { useState, useEffect, useCallback } from 'react';

import { EPlanningRegion, CC_STATUS, CC_FAMILY } from '@bbng/util/types';
import { mapCollectConfigStatus, mapPlanningConfigRegion } from '../../common/enumMapper';
import { Dropdown } from 'primereact/dropdown';
import { fetchDataRelation } from '../../common/dataRelation';
import { PageLoader } from '../../components/PageLoader';
import { useRequest } from '../../hooks/StatelessRequest';
import { CCServiceRo, CCServiceRoFront, AdminRo, UserRo, CollectorRo, CustomerRo, FrontRo } from '@bbng/util/types';
import { COLLECT_CONFIG_ORCHESTRATION_BASE_URL, urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import moment from 'moment';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Link } from '../../components/Header/style';

export const ListingMobile = () => {
    const [regionSelected, setRegionSelected] = useState(EPlanningRegion.PARIS);
    const [statusSelected, setStatusSelected] = useState(CC_STATUS.TO_PREPARE);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [ccData, setCCData] = useState<CCServiceRoFront[]>([]);

    const request = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const finishCollectConfig = useCallback(async (id: string) => {
        setLoading(true);
        await request<FrontRo<CCServiceRo>>({
            method  : 'PATCH',
            url     : urlApiBuilder.collectConfigFinish(id),
            options : { toastifySuccess: false }
        });
        setLoading(false);
        window.location.reload();
    }, []);

    const regionOptions = Object.values(EPlanningRegion).map((region) => ({
        name  : mapPlanningConfigRegion(region),
        value : region
    }));

    const statusOptions = [CC_STATUS.TO_PREPARE, CC_STATUS.CANCELED, CC_STATUS.FINISHED].map((status) => ({
        name  : mapCollectConfigStatus(status),
        value : status
    }));

    useEffect(() => {
        const fetchData = async () => {
            const data = await request<CCServiceRo[]>({
                url     : `${COLLECT_CONFIG_ORCHESTRATION_BASE_URL}/v1/collect-configs`,
                method  : 'GET',
                payload : {
                    queryParams: {
                        limit             : 50,
                        page              : page,
                        family            : CC_FAMILY.DELIVERY_BIG_BAG,
                        no_administrative : true,
                        status            : statusSelected
                    }
                },
                options: { toastifySuccess: false }
            });
            const ro: (CCServiceRo & {
                tmp: { admin_id: string[]; user_id: string[]; collector_id: string[] };
            })[] = (data.response?.data.ro ?? []).map((cc) => ({
                ...cc,
                // Place created_by id in tmp key to be fetch on fetchDataRelation
                tmp: {
                    admin_id     : [cc.created_by],
                    user_id      : [cc.created_by],
                    collector_id : [cc.created_by]
                }
            }));

            const roFetch: (CCServiceRo & {
                tmp: { admin_id: AdminRo[]; user_id: UserRo[]; collector_id: CollectorRo[] };
            })[] = await fetchDataRelation(ro, {
                customer_id  : true,
                admin_id     : true,
                user_id      : true,
                collector_id : true
            });

            const newRo: CCServiceRo[] = roFetch.map((cc): CCServiceRo => {
                /**
                 * Take created_by email from tmp key once fetched and remove the tmp key
                 */
                if (cc.tmp.admin_id.length > 0 && cc.tmp.admin_id[0]?.id === cc.created_by)
                    cc.created_by = cc.tmp.admin_id[0]?.email;
                else if (cc.tmp.user_id.length > 0 && cc.tmp.user_id[0]?.id === cc.created_by)
                    cc.created_by = cc.tmp.user_id[0]?.email;
                else if (cc.tmp.collector_id.length > 0 && cc.tmp.collector_id[0]?.id === cc.created_by)
                    cc.created_by = cc.tmp.collector_id[0]?.email;
                const item: CCServiceRo & {
                    tmp?: { admin_id?: AdminRo[]; user_id?: UserRo[]; collector_id?: CollectorRo[] };
                } = cc;
                delete item.tmp;

                return item;
            });
            setCCData(newRo);
        };
        fetchData();
        setLoading(false);
    }, [regionSelected, statusSelected, page]);

    if (loading && ccData.length > 0) {
        return <PageLoader />;
    }
    return (
        <div
            style={{
                marginLeft  : '3%',
                marginRight : '3%'
            }}
        >
            <div
                style={{
                    display       : 'flex',
                    flexDirection : 'row'
                }}
            >
                <h1>Livraison</h1>
                <div
                    style={{
                        marginTop  : '1%',
                        marginLeft : '1%'
                    }}
                >
                    {regionSelected === EPlanningRegion.PARIS && (
                        <img
                            style={{
                                height : 50,
                                width  : 50
                            }}
                            alt="logo_PSG"
                            src="https://upload.wikimedia.org/wikipedia/fr/thumb/8/86/Paris_Saint-Germain_Logo.svg/480px-Paris_Saint-Germain_Logo.svg.png"
                        />
                    )}
                    {regionSelected === EPlanningRegion.MARSEILLE && (
                        <img
                            style={{
                                height : 50,
                                width  : 50
                            }}
                            alt="logo_OM"
                            src="https://upload.wikimedia.org/wikipedia/fr/archive/4/43/20200407190016%21Logo_Olympique_de_Marseille.svg"
                        />
                    )}
                    {regionSelected === EPlanningRegion.LYON && (
                        <img
                            style={{
                                height : 50,
                                width  : 50
                            }}
                            alt="logo_OL"
                            src="https://upload.wikimedia.org/wikipedia/fr/thumb/a/a5/Logo_Olympique_Lyonnais_-_2022.svg/1200px-Logo_Olympique_Lyonnais_-_2022.svg.png"
                        />
                    )}
                </div>
                <div
                    style={{
                        backgroundColor : 'green',
                        borderRadius    : 32,
                        height          : 16,
                        width           : 16,
                        marginTop       : 24,
                        marginLeft      : 16
                    }}
                ></div>
                <h3 style={{ marginLeft: 4, marginTop: 22 }}>Fini</h3>
                <div
                    style={{
                        backgroundColor : 'red',
                        borderRadius    : 32,
                        height          : 16,
                        width           : 16,
                        marginTop       : 24,
                        marginLeft      : 16
                    }}
                ></div>
                <h3 style={{ marginLeft: 4, marginTop: 22 }}>Annulé</h3>
                <div
                    style={{
                        backgroundColor : 'orange',
                        borderRadius    : 32,
                        height          : 16,
                        width           : 16,
                        marginTop       : 24,
                        marginLeft      : 16
                    }}
                ></div>
                <h3 style={{ marginLeft: 4, marginTop: 22 }}>A Faire</h3>
            </div>
            <div
                style={{
                    display       : 'flex',
                    flexDirection : 'row'
                }}
            >
                <Dropdown
                    value={regionSelected}
                    onChange={(e) => setRegionSelected(e.value)}
                    placeholder="Filtrer par région"
                    optionValue="value"
                    optionLabel="name"
                    showClear
                    options={regionOptions}
                />
                <Dropdown
                    value={statusSelected}
                    onChange={(e) => setStatusSelected(e.value)}
                    placeholder="Filtrer par statut"
                    optionValue="value"
                    optionLabel="name"
                    showClear
                    options={statusOptions}
                />
            </div>
            <div
                style={{
                    display             : 'grid',
                    gridTemplateColumns : 'repeat(3, 1fr)',
                    gap                 : 10,
                    gridAutoRows        : 'minmax(100px, auto)',
                    alignItems          : 'center',
                    justifyItems        : 'center',
                    marginTop           : '3%'
                }}
            >
                {ccData.map((item) => {
                    return (
                        <div
                            style={{
                                textAlign   : 'center',
                                borderWidth : 2,
                                borderColor :
                                    item.status === CC_STATUS.FINISHED
                                        ? 'green'
                                        : item.status === CC_STATUS.CANCELED
                                        ? 'red'
                                        : 'orange',
                                borderRadius : 18,
                                borderStyle  : 'solid',
                                height       : 500,
                                width        : '100%'
                            }}
                        >
                            <h3>{item.address.formatted_name}</h3>
                            <h4>Liste de produits</h4>
                            <div
                                style={{
                                    overflowX    : 'hidden',
                                    overflowY    : 'auto',
                                    height       : 100,
                                    margin       : 5,
                                    padding      : 5,
                                    borderWidth  : 1,
                                    borderColor  : 'black',
                                    borderRadius : 18,
                                    borderStyle  : 'solid'
                                }}
                            >
                                <div>
                                    {item.products.map((product) => {
                                        return (
                                            <>
                                                <p style={{ textAlign: 'center' }}>
                                                    {product.quantity} X {product.name}
                                                </p>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                            <div>
                                {(item.customer_id as CustomerRo[]).map((customer) => {
                                    // We prefer to display the internal_name than the name from paper's api
                                    return (
                                        <>
                                            <p>{customer.internal_name ? customer.internal_name : customer.name}</p>
                                            <div
                                                style={{
                                                    maxHeight    : 100,
                                                    overflowX    : 'hidden',
                                                    overflowY    : 'auto',
                                                    padding      : 5,
                                                    borderWidth  : 1,
                                                    borderColor  : 'black',
                                                    borderRadius : 18,
                                                    borderStyle  : 'solid'
                                                }}
                                            >
                                                {item.construction_site_contact.map((contact) => {
                                                    if (!contact.phone_number) return;
                                                    return (
                                                        <div
                                                            style={{
                                                                height       : 50,
                                                                margin       : 5,
                                                                padding      : 5,
                                                                borderWidth  : 1,
                                                                borderColor  : 'black',
                                                                borderRadius : 18,
                                                                borderStyle  : 'solid'
                                                            }}
                                                        >
                                                            <p>
                                                                {contact.phone_number} - {contact.firstname} -{' '}
                                                                {contact.lastname}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                            <h4>
                                {moment(item.from_date).format('DD/MM/YYYY')} - {moment(item.from_date).format('HH:mm')}
                                h - {moment(item.to_date).format('HH:mm')}h
                            </h4>
                            <p>Créer par : {item.created_by}</p>
                            {item.status === CC_STATUS.TO_PREPARE && (
                                <Button
                                    label="Finir la commande"
                                    onClick={() => {
                                        confirmDialog({
                                            header      : 'Confirmer la validation.',
                                            message     : 'Etes-vous sur de vouloir valider cette livraison ?',
                                            icon        : 'pi pi-info-circle',
                                            acceptLabel : 'Confirmer',
                                            rejectLabel : 'Annuler',
                                            accept      : () => {
                                                finishCollectConfig(item.id);
                                            }
                                        });
                                    }}
                                />
                            )}
                            {item.status !== CC_STATUS.TO_PREPARE && (
                                <Button>
                                    <Link style={{ color: 'white' }} href={`${urlBuilder.collectConfigView(item.id)}`}>
                                        Voir les details
                                    </Link>
                                </Button>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
