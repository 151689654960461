import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { cleanUndefinedKeys, match } from '@bbng/util/misc';

interface ReturnType<Query extends Record<string, any> = Record<string, any>> {
    query: Partial<Query>;
    setQuery: (data: Partial<Query>) => void;
    updateQuery: (data: Partial<Query>) => void;
}

function useQuery<Query extends Record<string, any> = Record<string, any>>(): ReturnType<Query> {
    const { search } = useLocation();
    const navigate = useNavigate();

    const [query, setQuery] = React.useState<Record<string, any>>(decodeQuery(search));

    React.useEffect(() => {
        const newQuery = decodeQuery(search);
        if (match(query, newQuery)) return;

        setQuery(newQuery);
    }, [search]);

    const updateQueryUrl = React.useCallback((data: Record<string, any>): void => {
        const queryParams: string = encodeQuery(data);
        const queryParamUrlComponent: string = (queryParams.length > 0 ? '?' : '') + queryParams;
        const url = window.location.pathname + queryParamUrlComponent;

        /**
         * Use react navigate instead of window.history.pushState for React to keep track of the navigation.
         * See: https://stackoverflow.com/a/73803088
         */
        navigate(url, { replace: true });
    }, []);

    const writeQuery = React.useCallback(
        (data: Record<string, any>): void => {
            updateQueryUrl(data);
            setQuery(data);
        },
        [updateQueryUrl]
    );

    const updateQuery = React.useCallback(
        (data: Record<string, any>): void => {
            setQuery((prev) => {
                const newQuery = { ...prev, ...data };
                updateQueryUrl(newQuery);
                return newQuery;
            });
        },
        [query]
    );

    return { query: query as Query, setQuery: writeQuery, updateQuery };
}

export function decodeQuery<T = Record<string, any>>(query: string): T {
    const url = new URLSearchParams(query);
    const entries: Array<[string, any]> = Array.from(url.entries()).map<[string, any]>(([key, value]) => {
        try {
            if (key === 'page' || key === 'limit') {
                value = !isNaN(Number(value)) ? value : '10';
            }
            return [key, JSON.parse(value)];
        } catch (err) {
            console.log('Failed parse on', key, value);
            return [key, value];
        }
    });
    return Object.fromEntries(entries) as T;
}

export const encodeQuery = (query: Record<string, any>): string => {
    const queryParams: Array<string> = Object.entries(cleanUndefinedKeys(query)).map(
        ([key, value]) => `${key}=${encodeURIComponent(`${JSON.stringify(value)}`)}`
    );
    return queryParams.join('&');
};

export default useQuery;
