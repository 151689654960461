import moment from 'moment';
import { CalendarChangeParams, Calendar as PRCalendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import * as yup from 'yup';

import { match } from '@bbng/util/misc';
import { ISODate } from '@bbng/util/types';

export type CalendarDayMonthYearProps = {
    label?: string;
    readOnly: boolean;
    required: boolean;
    id: string;
    result: (value: ISODate, errors: null | string[], id: string) => void;
    validationSchema?: yup.AnySchema;
    yearRange?: string;
    maxDate?: ISODate;
    minDate?: ISODate;
    value?: ISODate;
    errors?: string[] | null;
    displayError?: boolean;
    showTime?: boolean;
    showMonths?: boolean;
    dateFormat?: string;
    locale?: string;
};

const TODAY = moment.utc().toISOString();
const YEAR = moment.utc().format('YYYY');

export const CalendarDayMonthYear = ({
    label,
    readOnly,
    required,
    id,
    result,
    validationSchema,
    yearRange,
    maxDate,
    minDate,
    value = TODAY,
    errors,
    displayError,
    showTime,
    showMonths,
    dateFormat = 'dd/mm/yy',
    locale = 'fr'
}: CalendarDayMonthYearProps) => {
    const [val, setVal] = React.useState<ISODate>(value ? value : TODAY);
    const [err, setErr] = React.useState<null | string[]>(errors ?? null);
    const [displayErr, setDisplayErr] = React.useState<boolean>(displayError ?? false);

    const baseValidationSchema = yup.date();
    if (maxDate) baseValidationSchema.max(maxDate);
    if (minDate) baseValidationSchema.min(minDate);
    const schema = validationSchema
        ? validationSchema
        : required
        ? baseValidationSchema.required('Date requise')
        : baseValidationSchema;

    const _checkValidationSchema = (val: ISODate, errVal: ISODate) => {
        try {
            schema.validateSync(val, { abortEarly: false });
        } catch (err) {
            setErr(err.errors);
            result(errVal, err.errors, id);
        }
    };

    const _checkDescendingValue = React.useCallback(
        async (stateValue: ISODate, propsValue: ISODate): Promise<void> => {
            if (stateValue !== propsValue) {
                setVal(propsValue);
                setErr(null);
                result(propsValue, null, id);
                if (propsValue) {
                    _checkValidationSchema(propsValue, propsValue);
                    setDisplayErr(true);
                }
            }
        },
        [setVal, setErr, result]
    );

    const _checkDescendingErrors = React.useCallback(
        async (stateError: null | string[], propsError: null | string[]): Promise<void> => {
            if (match(stateError, propsError) === false) {
                setErr(propsError);
                result(val, propsError, id);
                setDisplayErr(true);
            }
        },
        [setVal, setErr, result]
    );

    React.useEffect(() => {
        _checkDescendingValue(val, value);
        if (errors !== undefined) {
            _checkDescendingErrors(err, errors);
        }
    }, [value, errors]);

    React.useEffect(() => {
        setDisplayErr(displayError ?? false);
    }, [displayError]);

    React.useEffect(() => {
        if (required) {
            _checkValidationSchema(val, val);
        }
    }, []);

    const handleOnChange = React.useCallback(
        (e: CalendarChangeParams): void => {
            let isoDate: string;
            if (showTime) isoDate = moment.utc(e.value as Date).toISOString();
            else
                isoDate = moment
                    .utc(moment(e.value as Date).hours(12))
                    .hours(12)
                    .toISOString(); // this is needed to make sure the time is 12:00 of selected day in UTC
            if (e.originalEvent.type === 'blur') return;
            setVal(isoDate ? isoDate : TODAY);
            setErr(null);
            result(isoDate ? isoDate : TODAY, null, id);
            setDisplayErr(false);
        },
        [setVal, setErr, result, id]
    );

    const handleBlur = React.useCallback(async (): Promise<void> => {
        if (val) {
            try {
                await schema.validate(val, { abortEarly: false });
            } catch (err) {
                setErr(err.errors);
                result(TODAY, err.errors, id);
            }
        }
        setDisplayErr(true);
    }, [schema, val, setErr, result, id]);

    return (
        <span className="p-float-label">
            <PRCalendar
                locale={locale}
                id={id}
                disabled={readOnly}
                onChange={handleOnChange}
                onBlur={handleBlur}
                dateFormat={dateFormat}
                monthNavigator
                yearNavigator
                yearRange={yearRange ? yearRange : `1850:${parseInt(YEAR) + 1}`}
                maxDate={maxDate ? moment(maxDate).toDate() : undefined}
                minDate={minDate ? moment(minDate).toDate() : undefined}
                showTime={showTime}
                monthNavigatorTemplate={monthNavigatorTemplate}
                yearNavigatorTemplate={yearNavigatorTemplate}
                value={moment(val).toDate()}
                view={showMonths ? 'month' : undefined}
                tooltip={displayErr && err && err.length > 0 ? err.join('\n') : ''}
                className={`${displayErr && err && err.length > 0 ? 'p-invalid' : ''}`}
            />
            {label && <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>}
        </span>
    );
};

const monthNavigatorTemplate = (e: any) => {
    return (
        <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            style={{ lineHeight: 1 }}
        />
    );
};

const yearNavigatorTemplate = (e: any) => {
    return (
        <Dropdown
            value={e.value}
            options={e.options.reverse()}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            className="ml-2"
            style={{ lineHeight: 1 }}
        />
    );
};
