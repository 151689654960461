import {
    AddressRo,
    CustomerRo,
    ECustomerCategory,
    ICustomerContact,
    ZohoAddressRo,
    ZohoContactCreateDto,
    ZohoContactPerson,
    ZohoContactRo
} from '@bbng/util/types';

export const mapAddressToZohoAddress = (address: AddressRo): ZohoAddressRo => {
    return {
        address : `${address.components['street_number'] ?? ''} ${address.components['route'] ?? ''}`,
        city    : address.components['locality'],
        zip     : address.components['postal_code'],
        country : address.components['country']
    };
};

export const mapBbngContactToZohoContactPerson = (
    contact: ICustomerContact,
    isRoot: boolean,
    zohoContact?: ZohoContactRo
): ZohoContactPerson => {
    const existingContactPersonId = zohoContact?.contact_persons?.find((contactPerson) =>
        contactPerson.email ? contactPerson.email?.trim().toLowerCase() === contact.email?.trim().toLowerCase() : false
    )?.contact_person_id;
    return {
        contact_person_id:
            contact.id_zoho_contact_person ??
            (contact as any).id_zoho_contact ?? // it doesn't exist anymore in code, but some existing data still have this field in DB (manual scripting of 26/01/2023)
            existingContactPersonId,
        first_name : contact.firstname,
        last_name  : contact.lastname,
        email      : contact.email,
        phone      : contact.phone_number,
        ...(isRoot ? { is_primary_contact: isRoot } : {})
    };
};

/**
 * Lighten zoho contact person to only keep the fields used by mapBbngContactToZohoContactPerson
 * @param {ZohoContactPerson} contact
 *
 * @returns {Partial<ZohoContactPerson>}
 */
export const lightenZohoContactPerson = (contact: ZohoContactPerson): Partial<ZohoContactPerson> => {
    return {
        contact_person_id  : contact.contact_person_id,
        first_name         : contact.first_name,
        last_name          : contact.last_name,
        email              : contact.email,
        phone              : contact.phone,
        is_primary_contact : contact.is_primary_contact
    };
};

export const mapZohoContactPersonToBbngContact = (
    zohoContact: ZohoContactPerson,
    internalContacts?: ICustomerContact[]
): ICustomerContact => {
    /**
     * Try to match the zoho contact person with an internal contact to retrieve the is_invoice field (missing in zoho).
     * If no match is found, the is_invoice field will be set to false.
     * For most cases, id_zoho_contact_person will exist, but if it does not exist (moment when contact is created), matching must be done on other fields.
     */
    const matchingInternalContact = internalContacts?.find((contact) => {
        if (contact.id_zoho_contact_person) return contact.id_zoho_contact_person === zohoContact.contact_person_id;
        if (contact.email) return contact.email?.toLowerCase().trim() === zohoContact.email?.toLowerCase().trim();
        if (contact.phone_number) return contact.phone_number === zohoContact.phone;
        if (contact.firstname && contact.lastname) {
            return (
                contact.firstname?.toLowerCase().trim() === zohoContact.first_name?.toLowerCase().trim() &&
                contact.lastname?.toLowerCase().trim() === zohoContact.last_name?.toLowerCase().trim()
            );
        }
        return false;
    });

    return {
        firstname              : zohoContact.first_name,
        lastname               : zohoContact.last_name,
        email                  : zohoContact.email,
        phone_number           : zohoContact.phone,
        is_primary             : zohoContact.is_primary_contact ?? false,
        is_invoice             : matchingInternalContact?.is_invoice ?? false,
        id_zoho_contact_person : zohoContact.contact_person_id
    };
};

export const mapZohoClientCreate = (
    customer: CustomerRo,
    contacts: ICustomerContact[],
    hasStripe = false
): ZohoContactCreateDto => {
    return {
        contact_name      : customer.name,
        company_name      : customer.name,
        customer_sub_type : customer.category === ECustomerCategory.INDIVIDUAL ? 'individual' : 'business',
        billing_address   : mapAddressToZohoAddress(customer.invoice_addresses[0]),
        shipping_address  : mapAddressToZohoAddress(customer.shipping_addresses[0]),
        contact_persons   : contacts.map((contact, idx) => {
            const primary = (): true | undefined => {
                if (hasStripe && idx === 0) {
                    return true;
                }
                if (contact.is_primary) return true;
                return undefined;
            };
            const contactPerson: ZohoContactPerson = {
                first_name         : contact.firstname?.toLowerCase().trim(),
                last_name          : contact.lastname?.toLowerCase().trim(),
                email              : contact.email?.toLowerCase().trim(),
                phone              : contact.phone_number,
                is_primary_contact : primary()
            };
            return contactPerson;
        }),
        payment_terms: customer.payment_time
    };
};

// export const buildZohoContactDto = (
//     customer: CustomerRo,
//     customerContact: ICustomerContact[] = [],
//     zohoContact?: ZohoContactRo
// ): ZohoContactEditDto => {
//     return {
//         contact_name      : customer.name,
//         company_name      : customer.name,
//         customer_sub_type : customer.category === ECustomerCategory.INDIVIDUAL ? 'individual' : 'business',
//         billing_address   : mapAddressToZohoAddress(customer.invoice_addresses[0]),
//         shipping_address  : mapAddressToZohoAddress(customer.shipping_addresses[0]),
//         contact_persons   : customerContact.map((contact) =>
//             mapBbngContactToZohoContactPerson(contact, contact.is_primary, zohoContact)
//         ),
//         payment_terms: customer.payment_time
//     };
// };
