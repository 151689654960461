import axios, { AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { Button, ButtonProps } from 'primereact/button';
import styled from 'styled-components';

import {
    IDifferenceResult,
    cleanEmptyKeys,
    customFileId,
    formatPhoneE164ToPhoneValue,
    formatPhoneNumberToE164,
    getDifference,
    match
} from '@bbng/util/misc';
import {
    CardErrors,
    DocumentCreateDto,
    DocumentRo,
    EDocumentType,
    GenerateUploadUrlDto,
    IContact,
    RelationKeys,
    RelationsDto
} from '@bbng/util/types';

import { HistoryButton } from '../components/History';
import { FrontContact } from '../modules/InputList/contact';
import { syncRequest } from './syncRequest';
import { urlApiBuilder } from './urlBuilder';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { confirmDialog } from 'primereact/confirmdialog';

export function generateInitialErrorState<
    State extends Record<string, any> = Record<string, any>,
    ErrorState = CardErrors<State>
>(state: State): ErrorState {
    const stateEntries = Object.entries(state);
    const errorEntries = stateEntries.map(([key, value]) => [key, null]);
    const errorState: ErrorState = Object.fromEntries(errorEntries);

    return errorState;
}

/**
 *
 * It will return a new DTO based on the the differences between the old state and the new state.
 * @param state The new state
 * @param apiState The old state
 * @param apiRo The return object given by the api on fetching data
 * @param mapper A function that will map states into DTOs
 * @param relationMapper A function that will map the connect/disconnect part of an Edit DTO
 * @returns The new DTO with changes only
 */
export const optimiseEditDto = <EditRo extends Record<string, any>, ApiState extends Record<string, any>>(
    state: ApiState,
    apiState: ApiState,
    apiRo: Record<string, any>,
    mapper: (state: ApiState) => Record<string, any>,
    relationMapper: (dto: any, apiRo: any) => RelationsDto<Record<string, any>>,
    ignoreRelations?: boolean
): EditRo => {
    const dto = mapper(state);
    const oldDto = mapper(apiState);

    if (ignoreRelations) return dto as EditRo;

    const relationDto = relationMapper(dto, apiRo);

    Object.entries(dto).forEach(([key, value]) => {
        if (key.endsWith('_id')) {
            delete dto[key];
        }

        if (match(value, oldDto[key])) {
            delete dto[key];
        }
    });

    return { ...dto, ...relationDto } as EditRo;
};

/**
 * Curry function that will return a mapper for relation part of Edit Dto
 * @param keys The keys to check up for relations
 * @returns A mapper for relation part of Edit Dto
 */
export const getRelationsDto =
    <Ro extends Record<string, any> = any>(...keys: RelationKeys<Ro>[]) =>
    (dto: any, ro: any): RelationsDto<Ro> => {
        if (keys.length === 0) return {};

        const relationsDiffEntries = keys.map<[string, IDifferenceResult]>((key: string) => {
            const diff = getDifference(
                (ro[key] as Array<any>).map<string>((e: any) => e.id),
                dto[key] ?? []
            );
            return [key, diff];
        });
        const relationsDiff: Record<string, IDifferenceResult> = Object.fromEntries(relationsDiffEntries);

        const isConnect = relationsDiffEntries.reduce((acc, [, diff]) => acc + diff.toAdd.length, 0) > 0;
        const isDisconnect = relationsDiffEntries.reduce((acc, [, diff]) => acc + diff.toRemove.length, 0) > 0;

        const connectDto: RelationsDto<Ro>['connect'] = {};
        keys.forEach((key) => {
            if (relationsDiff[key].toAdd.length > 0) {
                connectDto[key] = relationsDiff[key].toAdd;
            }
        });

        const disconnectDto: RelationsDto<Ro>['disconnect'] = {};
        keys.forEach((key) => {
            if (relationsDiff[key].toRemove.length > 0) {
                disconnectDto[key] = relationsDiff[key].toRemove;
            }
        });

        return cleanEmptyKeys<RelationsDto<Ro>>({
            connect    : isConnect ? cleanEmptyKeys<RelationsDto<Ro>['connect']>(connectDto) : undefined,
            disconnect : isDisconnect ? cleanEmptyKeys<RelationsDto<Ro>['disconnect']>(disconnectDto) : undefined
        });
    };

export type CustomAction = Pick<
    ButtonProps,
    'tooltip' | 'tooltipOptions' | 'icon' | 'className' | 'onClick' | 'disabled'
> & {
    hide?: boolean;
};

export type ActionsFormContainerProps = {
    edit?: boolean;
    readOnly?: boolean;
    editPageUrl?: string;
    historyUrl?: string;
    disableSaveOnEdit?: boolean;
    disableDeleteOnView?: boolean;
    disableEditOnView?: boolean;
    disableAddOnCreate?: boolean;
    handleArchiveOnView?: () => void;
    archived: boolean;
    hideEditButton?: boolean;
    hideArchiveButton?: boolean;
    customActions?: Array<CustomAction>;
};

export const ActionsFormContainer: React.FC<ActionsFormContainerProps> = (
    props: ActionsFormContainerProps
): JSX.Element => {
    const {
        edit = false,
        readOnly = false,
        editPageUrl = '#',
        historyUrl,
        disableAddOnCreate = false,
        disableDeleteOnView = false,
        disableEditOnView = false,
        disableSaveOnEdit = false,
        handleArchiveOnView = () => void 0
    } = props;

    const navigate = useNavigate();

    const CustomActions = React.useMemo(
        () => () => {
            const actions = props.customActions ?? [];

            return (
                <>
                    {actions
                        .filter((f) => f.hide !== true)
                        .map((action, index) => (
                            <Button
                                key={index}
                                tooltip={action.tooltip ?? ''}
                                tooltipOptions={action.tooltipOptions ?? { position: 'left' }}
                                icon={action.icon}
                                className={action.className ?? 'p-button-rounded p-button-primary'}
                                type="button"
                                onClick={action.onClick}
                                disabled={action.disabled}
                            />
                        ))}
                </>
            );
        },
        [props.customActions]
    );

    const handleConfirmArchive = () => {
        if (props.handleArchiveOnView) {
            confirmDialog({
                message         : props.archived ? 'Voulez-vous réactiver cet élément?' : 'Voulez-vous archiver cet élément?',
                header          : 'Confirmation',
                icon            : 'pi pi-exclamation-triangle',
                acceptLabel     : 'Confirmer',
                acceptClassName : 'p-button-danger',
                rejectLabel     : 'Retour',
                accept          : () => handleArchiveOnView(),
                reject          : () => void 0
            });
        }
    };

    if (edit) {
        return (
            <ActionsForm className="actions-form">
                <Button
                    tooltip="Sauvegarder"
                    tooltipOptions={{ position: 'left' }}
                    icon="pi pi-save"
                    className="p-button-rounded p-button-primary"
                    type="submit"
                    disabled={disableSaveOnEdit}
                />
                <CustomActions />
            </ActionsForm>
        );
    } else if (readOnly) {
        return (
            <ActionsForm className="actions-form">
                {!props.hideArchiveButton && (
                    <Button
                        tooltip={props.archived ? 'Réactiver' : 'Archiver'}
                        tooltipOptions={{ position: 'left' }}
                        icon="pi pi-folder"
                        className="p-button-rounded p-button-danger"
                        type="button"
                        onClick={handleConfirmArchive}
                        disabled={disableDeleteOnView}
                    />
                )}
                {!props.hideEditButton && (
                    <Button
                        disabled={disableEditOnView}
                        type="button"
                        tooltip="Modifier"
                        tooltipOptions={{ position: 'left' }}
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-primary"
                        onClick={() => navigate(editPageUrl)}
                    />
                )}
                {historyUrl && <HistoryButton url={historyUrl} />}
                <CustomActions />
            </ActionsForm>
        );
    } else {
        return (
            <ActionsForm className="actions-form">
                <Button
                    tooltip="Ajouter"
                    tooltipOptions={{ position: 'left' }}
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-primary"
                    disabled={disableAddOnCreate}
                    type="submit"
                />
                <CustomActions />
            </ActionsForm>
        );
    }
};

export const ActionsForm = styled.div`
    position: fixed;
    align-items: flex-end;
    right: 25px;
    top: 100px;
    z-index: 1000;
    width: fit-content !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const mapFrontContactToBackContact = (contact: FrontContact): IContact => ({
    ...contact,
    phone_number: contact.phone_number ? formatPhoneNumberToE164(contact.phone_number) : ''
});

export const mapBackContactToFrontContact = (contact: IContact): FrontContact => ({
    ...contact,
    phone_number: contact.phone_number ? formatPhoneE164ToPhoneValue(contact.phone_number) : null
});

export type TDocument = {
    type: 'local' | 'online';
    online?: DocumentRo;
    local?: TDocumentInput;
};

export type TDocumentInput = {
    type?: EDocumentType;
    document?: File;
    id?: string;
};

export const uploadFiles = async (documents: TDocument[], customKey: string): Promise<TDocument[] | undefined> => {
    const uploadedDocuments: TDocument[] = [];
    for (const doc of documents) {
        if (doc.type === 'online') {
            uploadedDocuments.push(doc);
            continue;
        }
        const mimeType = doc.local?.document?.type as string;
        const fileId = customFileId(doc.local?.type as EDocumentType, mimeType, customKey);
        const { response } = await syncRequest<string>({
            url     : urlApiBuilder.documentCreateSignedUrl(),
            method  : 'POST',
            payload : {
                fileId,
                mimeType
            } as GenerateUploadUrlDto
        });

        const res: AxiosResponse = await axios.request({
            method : 'PUT',
            url    : response?.data.ro,
            data   : doc.local?.document
        });

        if (res.status !== StatusCodes.OK) return;

        uploadedDocuments.push({
            ...doc,
            local: {
                ...doc.local,
                id: fileId
            }
        });
    }
    return uploadedDocuments;
};

export const mapFrontDocumentToDocumentDto = (document: TDocument): DocumentCreateDto => {
    return {
        type      : (document.type === 'online' ? document.online?.type : document.local?.type) as EDocumentType,
        id        : (document.type === 'online' ? document.online?.id : document.local?.id) as string,
        mime_type : (document.type === 'online' ? document.online?.mime_type : document.local?.document?.type) as string,
        name      : (document.type === 'online' ? document.online?.name : document.local?.document?.name) as string,
        size      : (document.type === 'online' ? document.online?.size : document.local?.document?.size) as number
    };
};
