import { ProductRo } from '../product';

export const DEFAULT_SMS_SENDER = 'Endless';
export const DEFAULT_SMS_SENDER_ID = 'Endless';

export const SMS_TEMPLATE = {};

export enum SMS_TYPE {
    BEGIN_COLLECT = 'BEGIN_COLLECT',
    END_COLLECT = 'END_COLLECT',
    END_COLLECT_WITH_HAZARD = 'END_COLLECT_WITH_HAZARD',
    BEGIN_COLLECT_WITHOUT_LINK = 'BEGIN_COLLECT_WITHOUT_LINK'
}

export type SendSmsDto = {
    recipients: string[]; // This is an array so you can send to multiple recipients
    content: string;
};

export type SendSMSSdkDto = {
    recipient: string;
    body: string;
};

export type SMSProductLines = {
    product: ProductRo;
    quantity: number;
};

export type SendEndOfCollectSmsDto = SendSmsDto & {
    products: SMSProductLines[];
    construction_site_address: string;
    bdc: string;
};

export type SendEndOfCollectHazardSmsDto = SendSmsDto & {
    construction_site_address: string;
    bdc: string;
};

export type SendBeginOfCollectSmsDto = {
    recipients: string[];
    ccId: string;
    driver_longitude: number;
    driver_latitude: number;
};

export type SendBeginOfCollectSMSActionDto = {
    construction_site_address: string;
    bdc: string;
    estimated_time_arrival: string;
    recipients: string[];
    message_type: SMS_TYPE;
    driver_longitude: number;
    driver_latitude: number;
    collect_public_information_id: string;
};

export type SendEndOfCollectSMSEventDto = SendEndOfCollectSmsDto;

export type SendSmsOutput = {
    message: string;
    status: string;
};

export type SMSRoFront = {
    id: string;
    message: string;
    send_date: string;
    status: string;
    recipients: string;
};

export type getAllSMSQueryParams = {
    q?: string;
    recipient?: string;
    page?: number;
    limit?: number;
};

export type SendSMSEventData = {
    data: SendSmsDto;
};

export type SendSMSEventMetadata = {
    jwt: string;
};
