import { DialogProps, Dialog as PRDialog } from 'primereact/dialog';
import styled from 'styled-components';

export const Dialog: React.FC<DialogProps> = (props): JSX.Element => {
    return <StyledDialog {...props} />;
};

const StyledDialog = styled(PRDialog)`
    max-width: calc(100vw - 60px);
    margin: 16px;
    margin-left: calc(60px + 16px);
    z-index: 2000;
`;
