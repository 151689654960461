import { PhoneNumberUtil } from 'google-libphonenumber';
import * as IBAN from 'ibantools';
import Joi from 'joi';

import { countries } from './country';
import { EPriceUnit, ETrashType, PhoneValue } from './misc';

export const PhoneE164Schema = Joi.string().regex(/^\+?[1-9]\d{1,14}$/);

export const PhoneDtoSchema = Joi.object({
    name: Joi.string()
        .valid(...countries.map((e) => e.name))
        .required(),
    countryCode: Joi.string()
        .valid(...countries.map((e) => e.iso2))
        .required(),
    dialCode     : Joi.string().required(),
    phone_number : Joi.string().required()
}).custom((value: PhoneValue) => {
    if (!value.countryCode || !value.dialCode || !value.phone_number) throw new Error('Invalid phone number');

    const instance: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    const number = instance.parse(value.dialCode + value.phone_number, value.countryCode);
    const valid = instance.isValidNumberForRegion(number, value.countryCode);
    if (valid === false) throw new Error('Invalid phone number');
});

export const IbanSchema = Joi.string().custom((value: string) => {
    const iban = IBAN.electronicFormatIBAN(value);

    if (!iban || !IBAN.isValidIBAN(iban)) {
        throw Error('IBAN is invalid');
    }
});

export const SirenSchema = Joi.string().custom((siren: string | null) => {
    if (!siren) {
        return;
    }
    if (!verifySocietyId(siren?.replace(/\s/g, ''), 9)) {
        throw Error('Siren is not valid');
    }
});

export const SiretSchema = Joi.string().custom((siret: string | null) => {
    if (!siret) {
        return;
    }
    if (!verifySocietyId(siret?.replace(/\s/g, ''), 14)) {
        throw Error('Siret is not valid');
    }
});

export const VatSchema = Joi.string().custom((vat: string | null) => {
    if (!vat) {
        return;
    }
    if (!isVAT(vat)) {
        throw Error('Vat is not valid');
    }
});

export const NafSchema = Joi.string().custom((naf: string | null) => {
    if (!naf) {
        return;
    }
    if (!isNAF(naf)) {
        throw Error('Naf is not valid');
    }
});

// TODO: This is a function duplicata, the original is on lib/feature, find a workaround for circular deps
/**
 * This function verifies the validity of a society id.
 * @param {any} number - The number to be verified.
 * @param {any} size - the length of the number
 * @returns A boolean value.
 */
export const verifySocietyId = (number: any, size: any) => {
    if (isNaN(number) || number.length !== size) return false;
    let bal = 0;
    let total = 0;

    for (let i = size - 1; i >= 0; i--) {
        const step = (number.charCodeAt(i) - 48) * (bal + 1);

        total += step > 9 ? step - 9 : step;
        bal = 1 - bal;
    }
    return total % 10 === 0 ? true : false;
};

// TODO: This is a function duplicata, the original is on lib/feature, find a workaround for circular deps
/**
 * Verify if the given vat is valid.
 * @param {string} vat
 * @param {string} countryCode

* @returns {boolean}
 */
export function isVAT(vat: string, countryCode = 'FR'): boolean {
    const numero = removeSpaceFromString(vat);
    if (numero.substring(0, 2) !== countryCode) return false;
    const keyFound = numero.substring(2, 4);
    const siren = numero.substring(4, 13);
    if (!verifySocietyId(siren, 9)) return false;
    if (getTvaKeyFromSiren(siren) !== keyFound) return false;
    else return true;
}

function removeSpaceFromString(input: string): string {
    return input.replace(/\s/g, '');
}

function getTvaKeyFromSiren(siren: string) {
    const k = (12 + 3 * (parseInt(siren) % 97)) % 97;
    const key = k < 10 ? '0' + k : '' + k;
    return key;
}

// TODO: This is a function duplicata, the original is on lib/feature, find a workaround for circular deps
/**
 * Verify if the given naf is valid (00.00).
 * @param {string} vat

* @returns {boolean}
 */
export function isNAF(naf: string): boolean {
    if (naf.length !== 5 && naf.length !== 6) return false;
    if (naf.substring(0, 2).match(/^[0-9]+$/) === null) return false;
    if (naf.substring(2, 3) !== '.') return false;
    if (naf.substring(3, 5).match(/^[0-9]+$/) === null) return false;
    return true;
}

export const ProductPriceSchema = Joi.object({
    net_amount_cents : Joi.number().min(0).required(),
    currency         : Joi.string().required()
});

export const TrashDetailsDtoSchema = Joi.object({
    price_value : ProductPriceSchema.required(),
    price_unit  : Joi.string()
        .valid(...Object.values(EPriceUnit))
        .required(),
    type: Joi.string()
        .valid(...Object.values(ETrashType))
        .required()
});

// TODO: set required on is_closed and disabled
export const OpeningDayDtoSchema = Joi.object({
    is_closed       : Joi.boolean(),
    // disabled        : Joi.boolean(),
    morning_open    : Joi.string().required(),
    morning_close   : Joi.string().required(),
    afternoon_open  : Joi.string().required(),
    afternoon_close : Joi.string().required()
});

export const CloseDayDtoSchema = Joi.object({
    from : Joi.date().iso().required(),
    to   : Joi.date().iso().required()
});

export const OpeningTimeDtoSchema = Joi.object({
    '0' : OpeningDayDtoSchema.required(),
    '1' : OpeningDayDtoSchema.required(),
    '2' : OpeningDayDtoSchema.required(),
    '3' : OpeningDayDtoSchema.required(),
    '4' : OpeningDayDtoSchema.required(),
    '5' : OpeningDayDtoSchema.required(),
    '6' : OpeningDayDtoSchema.required()
});

export const RetrieveDtoSchema = Joi.object({
    ids: Joi.array().items(Joi.string().required()).required()
});

export const GenericArchiveDtoSchema = Joi.object({
    archived: Joi.boolean().required()
});

export const KafkaParamsQuerySchema = Joi.object({
    page          : Joi.number().integer().min(0).default(0), // 0 as handlePaginationMiddleware is called before (and decrements page value)
    limit         : Joi.number().integer().min(1).default(10),
    no_archived   : Joi.boolean().default(false),
    archived_only : Joi.boolean().default(false),
    sync          : Joi.boolean().default(false),
    no_limit      : Joi.boolean().default(false),
    q             : Joi.string().allow(null, '')
});
