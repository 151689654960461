/**
 * @description
 * Error Enum to be used in the error message to specify the responsability
 */
export enum ErrorResponsability {
    /**
     * @description
     * Error is caused by the server
     */
    Server = 'Server',

    /**
     * @description
     * Error is caused by the input given by the client
     */
    Client = 'Client'
}

/**
 * @description
 * Error Enum to be used in the error message to specify the context
 */
export enum ErrorContext {
    /**
     * @description
     * Error is thrown by the auth verification
     */
    Auth = 'Auth',

    /**
     * @description
     * Error is thrown by the database
     */
    Database = 'Database',

    /**
     * @description
     * Error is thrown during the validation of the DTO
     */
    DtoValidation = 'DtoValidation',

    /**
     * @description
     * Error is thrown to protect bussiness rules and logics
     */
    Business = 'Business',

    Other = 'Other'
}
