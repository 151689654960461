import moment from 'moment';

import {
    ConstructionSiteRo,
    CustomerRo,
    DiscountCreateDto,
    DiscountEditDto,
    DiscountRo,
    EDiscountType,
    EDiscountUnit,
    ETrashType,
    ZoneRo
} from '@bbng/util/types';

import { getRelationsDto, optimiseEditDto } from '../../common/form';
import {
    DiscountCustomerErrorState,
    DiscountCustomerState,
    ESpecificOption,
    initialErrorState as discountCustomerInitialErrorState,
    initialState as discountCustomerInitialState
} from '../../modules/discount/Customer';
import {
    DiscountDetailsErrorState,
    DiscountDetailsState,
    initialErrorState as discountDetailsInitialErrorState,
    initialState as discountDetailsInitialState
} from '../../modules/discount/Details';
import {
    DiscountGeneralErrorState,
    DiscountGeneralState,
    initialErrorState as discountGeneralInitialErrorState,
    initialState as discountGeneralInitialState
} from '../../modules/discount/General';

export type DiscountModulesStates = DiscountGeneralState | DiscountCustomerState | DiscountDetailsState;

export type DiscountModulesErrorStates =
    | DiscountGeneralErrorState
    | DiscountCustomerErrorState
    | DiscountDetailsErrorState;

export type DiscountFormState = {
    general: DiscountGeneralState;
    customer: DiscountCustomerState;
    details: DiscountDetailsState;
};

export type DiscountFormErrorState = {
    general: DiscountGeneralErrorState;
    customer: DiscountCustomerErrorState;
    details: DiscountDetailsErrorState;
};

export const initialState: DiscountFormState = {
    general  : discountGeneralInitialState,
    customer : discountCustomerInitialState,
    details  : discountDetailsInitialState
};

export const initialErrorState: DiscountFormErrorState = {
    general  : discountGeneralInitialErrorState,
    customer : discountCustomerInitialErrorState,
    details  : discountDetailsInitialErrorState
};

export const mapApiDataToState = (discount: DiscountRo): DiscountFormState => {
    const specificOption =
        discount.construction_site_id && discount.construction_site_id?.length > 0
            ? ESpecificOption.CONS_SITE
            : discount.zone_id && discount.zone_id.length > 0
            ? ESpecificOption.ZONE
            : ESpecificOption.GLOBAL;
    return {
        customer: {
            customer         : discount.customer_id[0] as unknown as CustomerRo | undefined,
            constructionSite : discount.construction_site_id
                ? (discount.construction_site_id[0] as unknown as ConstructionSiteRo)
                : undefined,
            specific : specificOption,
            zones    : (discount.zone_id as unknown as ZoneRo[]) ?? []
        },
        general: {
            start_date   : moment.utc(discount.start_date).toDate(),
            end_date     : moment.utc(discount.end_date).toDate(),
            has_end_date : discount.end_date !== undefined ? ['has_end_date'] : []
        },
        details: {
            unit  : discount.unit,
            value : Number(
                discount.type === EDiscountType.BIG_BAG || discount.unit === EDiscountUnit.PERCENT
                    ? discount.value
                    : (discount.value * discount.min_volume).toFixed(2)
            ),
            type         : discount.type,
            max_volume   : discount.max_volume,
            min_volume   : discount.min_volume,
            trash_type   : discount.trash_type,
            allTrashType :
                discount.type === EDiscountType.DUMPSTER &&
                discount.trash_type.length === Object.keys(ETrashType).length
        }
    };
};

export const mapStateToApiCreateData = (state: DiscountFormState): DiscountCreateDto => {
    let value: number;
    /**
     * For easier reading, value is displayed as /dumpster for dumpsters or /bag for deliveries, but actual db value should always be /m3 (for amount, for percentage it is 'unit-less')
     */
    if (state.details.type === EDiscountType.BIG_BAG) {
        value = state.details.value;
    } else {
        if (state.details.unit === EDiscountUnit.AMOUNT) {
            value = Math.ceil((state.details.value / state.details.min_volume) * 100) / 100;
        } else {
            value = state.details.value;
        }
    }

    return {
        start_date : moment(state.general.start_date)?.toISOString(),
        end_date   : state.general.has_end_date.includes('has_end_date')
            ? moment(state.general.end_date).toISOString()
            : undefined,
        /**
         * For easier reading, value is displayed as /dumpster for dumpsters or /bag for deliveries, but actual db value should always be /m3
         */
        value,
        unit                 : state.details.unit,
        type                 : state.details.type,
        min_volume           : state.details.min_volume,
        max_volume           : state.details.type === EDiscountType.BIG_BAG ? state.details.max_volume : state.details.min_volume,
        customer_id          : [state.customer.customer!.id],
        construction_site_id :
            state.customer.specific === ESpecificOption.CONS_SITE ? [state.customer.constructionSite!.id] : [],
        zone_id    : state.customer.specific === ESpecificOption.ZONE ? state.customer.zones!.map((e) => e.id) : [],
        trash_type :
            state.details.type === EDiscountType.DUMPSTER || state.details.type === EDiscountType.BIG_BAG
                ? state.details.trash_type
                : undefined
    } as DiscountCreateDto;
};

export const mapStateToApiEditData = (
    state: DiscountFormState,
    apiState: DiscountFormState,
    discount: DiscountRo
): DiscountEditDto =>
    optimiseEditDto(
        state,
        apiState,
        discount,
        mapStateToApiCreateData,
        getRelationsDto<DiscountRo>('construction_site_id', 'zone_id')
    );
