import { InputText } from 'primereact/inputtext';
import React, { ChangeEvent } from 'react';
import * as yup from 'yup';

import { genericTextInputProps } from '.';
import { useComponentInput } from '../../hooks/ComponentInput';

const mapValue = (e: any) => e.target.value;

export function EmailInput({
    label,
    readOnly,
    required,
    result,
    id,
    validationSchema,
    value = '',
    errors,
    displayError
}: genericTextInputProps) {
    const baseValidationSchema = yup.string().email();
    const schema = validationSchema
        ? validationSchema
        : required
        ? baseValidationSchema.required(`${label} requis`)
        : baseValidationSchema;

    const { handleBlur, val, err, setVal, setErr, setDisplayErr, displayErr } = useComponentInput({
        id,
        value,
        errors,
        displayError        : displayError ?? false,
        result,
        required,
        schema,
        didRevalidateSchema : (e) => e.length > 0,
        mapValue
    });

    const handleOnChange = React.useCallback(
        (e: ChangeEvent): void => {
            const value = mapValue(e);
            const valueWithoutDiacritics = value
                .normalize('NFD')
                .replace(/\p{Diacritic}/gu, '')
                .toLowerCase();
            setVal(valueWithoutDiacritics);
            setErr(null);
            setDisplayErr(false);
            result(valueWithoutDiacritics, null, id);
        },
        [setVal, setErr, result, id]
    );

    return (
        <span className="p-float-label">
            <InputText
                disabled={readOnly}
                type="email"
                name={id}
                className={`p-inputtext-sm ${displayErr && err && err.length > 0 ? 'p-invalid' : ''}`}
                id={id}
                onChange={handleOnChange}
                onBlur={handleBlur}
                value={val}
                tooltip={displayErr && err && err.length > 0 ? err.join('\n') : ''}
                size={35}
            />
            <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>
        </span>
    );
}
