import moment from 'moment';
import { useMemo } from 'react';

import { CCServiceRoFront, ECollectCharacteristic, EPlanningType, PRODUCT_DUMPSTER_TYPE } from '@bbng/util/types';

import { CollectConfigCard } from './CollectConfigCard';
import { CollectConfigsContainer } from './style';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { Accordion, AccordionTab } from 'primereact/accordion';
import styled from 'styled-components';

export const CollectConfigsUnassignedOfTheDay: React.FC = (): JSX.Element => {
    const { unnassignedCCAdministrative, unnassignedCCService, planningPageState, dumpsterTypes } =
        usePlanningV2Store();

    const sortedCCsAdmin = useMemo(() => {
        return unnassignedCCAdministrative.sort((a, b) => (moment(a.from_date).isBefore(moment(b.from_date)) ? -1 : 1));
    }, [unnassignedCCAdministrative]);

    const sortedCCsService = useMemo(() => {
        return unnassignedCCService.sort((a, b) => (moment(a.from_date).isBefore(moment(b.from_date)) ? -1 : 1));
    }, [unnassignedCCAdministrative, unnassignedCCService]);

    const sortedCCChain = useMemo(() => {
        return sortedCCsService.filter((cc) => cc.characteristics.includes(ECollectCharacteristic.DUMPSTER_CHAIN));
    }, [sortedCCsService]);

    const sortedCCAmpliroll = useMemo(() => {
        return sortedCCsService.filter((cc) => cc.characteristics.includes(ECollectCharacteristic.DUMPSTER_AMPLIROLL));
    }, [sortedCCsService]);

    const displayAllCCServices = useMemo(() => {
        return planningPageState.type === EPlanningType.BIG_BAG;
    }, [planningPageState.type]);

    const displayAmpliroll = useMemo(() => {
        return (
            planningPageState.type === EPlanningType.DUMPSTER &&
            dumpsterTypes.includes(PRODUCT_DUMPSTER_TYPE.DUMPSTER_AMPLIROLL)
        );
    }, [planningPageState.type, dumpsterTypes]);

    const displayChain = useMemo(() => {
        return (
            planningPageState.type === EPlanningType.DUMPSTER &&
            dumpsterTypes.includes(PRODUCT_DUMPSTER_TYPE.DUMPSTER_CHAIN)
        );
    }, [planningPageState.type, dumpsterTypes]);

    return (
        <CollectConfigsContainer>
            <StyledAccordion multiple activeIndex={[0]}>
                {displayAllCCServices && (
                    <AccordionTab
                        header={<TitleContainer>Étapes de service ({sortedCCsService.length})</TitleContainer>}
                    >
                        <CollectConfigsContainer>
                            {sortedCCsService.map((collect_config) => (
                                <CollectConfigCard
                                    showAgeBar
                                    key={
                                        collect_config.id + (collect_config as CCServiceRoFront)['splitted_idx'] ?? '0'
                                    }
                                    collect_config={collect_config}
                                    readOnly={false}
                                    splittedIdx={(collect_config as CCServiceRoFront)['splitted_idx']}
                                />
                            ))}
                        </CollectConfigsContainer>
                    </AccordionTab>
                )}
                {displayAmpliroll && (
                    <AccordionTab
                        header={<TitleContainer>Étapes de benne ampliroll ({sortedCCAmpliroll.length})</TitleContainer>}
                    >
                        <CollectConfigsContainer>
                            {sortedCCAmpliroll.map((collect_config) => (
                                <CollectConfigCard
                                    showAgeBar
                                    key={
                                        collect_config.id + (collect_config as CCServiceRoFront)['splitted_idx'] ?? '0'
                                    }
                                    collect_config={collect_config}
                                    readOnly={false}
                                    splittedIdx={(collect_config as CCServiceRoFront)['splitted_idx']}
                                />
                            ))}
                        </CollectConfigsContainer>
                    </AccordionTab>
                )}
                {displayChain && (
                    <AccordionTab
                        header={<TitleContainer>Étapes de benne à chaîne ({sortedCCChain.length})</TitleContainer>}
                    >
                        <CollectConfigsContainer>
                            {sortedCCChain.map((collect_config) => (
                                <CollectConfigCard
                                    showAgeBar
                                    key={
                                        collect_config.id + (collect_config as CCServiceRoFront)['splitted_idx'] ?? '0'
                                    }
                                    collect_config={collect_config}
                                    readOnly={false}
                                    splittedIdx={(collect_config as CCServiceRoFront)['splitted_idx']}
                                />
                            ))}
                        </CollectConfigsContainer>
                    </AccordionTab>
                )}
                <AccordionTab
                    header={<TitleContainer>Étapes administratives ({sortedCCsAdmin.length})</TitleContainer>}
                >
                    <CollectConfigsContainer>
                        {sortedCCsAdmin.map((collect_config) => (
                            <CollectConfigCard
                                showAgeBar
                                key={collect_config.id}
                                collect_config={collect_config}
                                readOnly={false}
                            />
                        ))}
                    </CollectConfigsContainer>
                </AccordionTab>
            </StyledAccordion>
        </CollectConfigsContainer>
    );
};

const StyledAccordion = styled(Accordion)`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    & .p-accordion-header-link {
        padding: 0 1.25em !important;
    }
    & .p-accordion-content {
        background: transparent;
    }
    & .p-accordion-tab {
        width: 100%;
        background: transparent;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    padding: 4px 8px;
    align-items: center;
`;
