import React from 'react';
import styled from 'styled-components';
import equal from 'fast-deep-equal/react';

import {
    CardErrors,
    ECollectorContract,
    ECollectorSkill,
    LandfillRo,
    PhoneValue,
    ProductPrice
} from '@bbng/util/types';

import { mapCollectorSkill } from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import Input from '../../components/Inputs';
import RelationAutocomplete from '../common/RelationAutocomplete';

export type CollectorGeneralProps = {
    readOnly?: boolean;
    value?: CollectorGeneralState;
    id: string;
    result: (
        value: CollectorGeneralState,
        errors: null | string[] | CardErrors<CollectorGeneralState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type CollectorGeneralState = {
    firstname: string;
    lastname: string;
    email: string;
    phone: PhoneValue | null;
    landfill?: LandfillRo;
    efficiency: number | null;
    contract: string;
    is_available: string;
    skills: ECollectorSkill[];
    hourly_rate: ProductPrice;
};

export type CollectorGeneralErrorState = CardErrors<CollectorGeneralState>;

export const initialState: CollectorGeneralState = {
    firstname    : '',
    lastname     : '',
    email        : '',
    phone        : null,
    landfill     : undefined,
    efficiency   : null,
    contract     : ECollectorContract.INTERNAL,
    is_available : 'true',
    skills       : [],
    hourly_rate  : {
        net_amount_cents : 0,
        currency         : 'EUR'
    }
};

export const initialErrorState: CollectorGeneralErrorState = generateInitialErrorState(initialState);

const contractMapper: Record<ECollectorContract, string> = {
    [ECollectorContract.INTERNAL] : 'Interne',
    [ECollectorContract.EXTERNAL] : 'Externe',
    [ECollectorContract.PROVIDER] : 'Prestataire'
};

export const CollectorGeneral = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: CollectorGeneralProps) => {
    const [val, setVal] = React.useState<CollectorGeneralState>(value);
    const [err, setErr] = React.useState<CollectorGeneralErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value2: any, errors: string[] | null, childId: string) => {
        if (equal(value2, val[childId as keyof CollectorGeneralErrorState])) return;
        if (childId === 'hourly_rate') {
            const hourly_rate: ProductPrice = {
                net_amount_cents : value2 ? value2 : 0,
                currency         : 'EUR'
            };
            setVal((prev) => ({ ...prev, hourly_rate }));
            setErr((prev) => ({ ...prev, hourly_rate: errors }));
            return;
        }
        setVal((prev) => ({ ...prev, [childId]: value2 }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <StyledCard title="Informations générales">
            <CardLine>
                <div>
                    <Button.Select
                        required
                        id="contract"
                        options={Object.values(ECollectorContract)}
                        readOnly={readOnly}
                        labelMapper={(value) => contractMapper[value as ECollectorContract]}
                        result={handleChange}
                        value={val.contract}
                        errors={err.contract}
                        displayError={displayError}
                    />
                </div>
                <div>
                    <Button.Select
                        required
                        id="is_available"
                        options={['true', 'false']}
                        readOnly={readOnly}
                        labelMapper={(value) => (value === 'true' ? 'Disponible' : 'Indisponible')}
                        result={handleChange}
                        value={val.is_available}
                        errors={err.is_available}
                        displayError={displayError}
                    />
                </div>
                <div>
                    <Button.Group
                        required
                        id="skills"
                        options={Object.values(ECollectorSkill)}
                        readOnly={readOnly}
                        labelMapper={(value) => mapCollectorSkill(value as ECollectorSkill)}
                        result={handleChange}
                        value={val.skills}
                        errors={err.skills}
                        displayError={displayError}
                    />
                </div>
                <div />
            </CardLine>
            <CardLine>
                <Input.Text
                    required
                    label="Prénom"
                    id="firstname"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.firstname}
                    errors={err.firstname}
                    displayError={displayError}
                />
                <Input.Text
                    required
                    label="Nom"
                    id="lastname"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.lastname}
                    errors={err.lastname}
                    displayError={displayError}
                />
                <Input.Email
                    required
                    label="Email"
                    id="email"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.email}
                    errors={err.email}
                    displayError={displayError}
                />
            </CardLine>
            <CardLine>
                <Input.Percentage
                    required
                    label="Efficacité"
                    id="efficiency"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.efficiency}
                    errors={err.efficiency}
                    displayError={displayError}
                />
                <Input.Phone
                    required
                    id="phone"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.phone}
                    errors={err.phone}
                    displayError={displayError}
                />
                <Input.Number
                    required
                    label="Cout horaire (€/h)"
                    id="hourly_rate"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.hourly_rate.net_amount_cents}
                    errors={err.hourly_rate}
                    displayError={displayError}
                    decimal={true}
                    min={0}
                />
                <RelationAutocomplete.Landfill
                    owner_is_endless={true}
                    readOnly={readOnly}
                    baseValue={val.landfill}
                    id="landfill"
                    label="Exutoire associé"
                    onSelect={(value) => {
                        handleChange(value, null, 'landfill');
                    }}
                    onUnselect={() => {
                        handleChange(undefined, null, 'landfill');
                    }}
                />
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
