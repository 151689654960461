import { PrestaGeneral, PrestaGeneralProps } from './General';
import { PrestaPriceList } from './PriceList';

const Modules = {
    GeneralInfo: (props: PrestaGeneralProps) => {
        return <PrestaGeneral {...props} />;
    },
    PriceList: (props: any) => {
        // Todo: this should be PrestaPriceListProps but provokes a type error, to investigate why
        return <PrestaPriceList {...props} />;
    }
};

export default Modules;
