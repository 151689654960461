import { CC_FAMILY } from '@bbng/util/types';
import {
    commentStepClassName,
    outOfScheduleStepClassName,
    narrowStepClassName,
    familyStepClassName,
    mapVolumeToStepClassName
} from '../helpers';

export type PlanningFilterClassNames = {
    customer: string[];
    general: string[];
    family: string[];
    volume: string[];
};

export type FilterChecked = {
    className: string;
    checked: boolean;
};

/**
 * Takes a list of arrays and returns all possible combinations of the items in the arrays.
 *
 * @param {Array<string[]>} param0
 * @returns {Array<string[]>}
 *
 * @example
 * classNamesCombinations([['a', 'b'], ['1', '2'], ['+', 'x']]) // [['a1+', 'a1x', 'a2+', 'a2x', 'b1+', 'b1x', 'b2+', 'b2x']]
 */
export const classNamesCombinations = ([firstArray, ...[secondArray, ...rest]]: Array<string[]>): Array<string[]> => {
    if (!secondArray) {
        return firstArray.map((item) => [item]);
    }

    let combined = [];
    if (firstArray.length === 0) {
        combined = secondArray;
    } else if (secondArray.length === 0) {
        combined = firstArray;
    } else {
        combined = firstArray.flatMap((item) => secondArray.flatMap((secondItem) => `${item}${secondItem}`));
    }

    return classNamesCombinations([combined, ...rest]);
};

export enum EGeneralFilter {
    WITH_COMMENT = 'WITH_COMMENT',
    OUT_OF_SCHEDULE = 'OUT_OF_SCHEDULE',
    NARROW_STREET = 'NARROW_STREET'
}

export const mapGeneralFilterText = (filter?: EGeneralFilter): string => {
    switch (filter) {
        case EGeneralFilter.WITH_COMMENT:
            return 'Avec commentaires';
        case EGeneralFilter.OUT_OF_SCHEDULE:
            return 'Hors créneau';
        case EGeneralFilter.NARROW_STREET:
            return 'Rue étroite';
        default:
            return '';
    }
};

export const initialGeneralFiltersChecked: Record<EGeneralFilter, FilterChecked> = {
    [EGeneralFilter.WITH_COMMENT]: {
        className : commentStepClassName,
        checked   : false
    },
    [EGeneralFilter.OUT_OF_SCHEDULE]: {
        className : outOfScheduleStepClassName,
        checked   : false
    },
    [EGeneralFilter.NARROW_STREET]: {
        className : narrowStepClassName,
        checked   : false
    }
};

export const initialFamilyFiltersChecked: Record<string, FilterChecked> = {
    [CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT]: {
        className : familyStepClassName(CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT),
        checked   : false
    },
    [CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT]: {
        className : familyStepClassName(CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT),
        checked   : false
    },
    [CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL]: {
        className : familyStepClassName(CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL),
        checked   : false
    },
    [CC_FAMILY.COLLECT_DUMPSTER_ROTATION]: {
        className : familyStepClassName(CC_FAMILY.COLLECT_DUMPSTER_ROTATION),
        checked   : false
    }
};

export const initialBNVolumeFiltersChecked: Record<string, FilterChecked> = {
    8: {
        className : mapVolumeToStepClassName(8),
        checked   : false
    },
    15: {
        className : mapVolumeToStepClassName(15),
        checked   : false
    },
    20: {
        className : mapVolumeToStepClassName(20),
        checked   : false
    },
    30: {
        className : mapVolumeToStepClassName(30),
        checked   : false
    },
    other: {
        className : mapVolumeToStepClassName(),
        checked   : false
    }
};

export const initialBBVolumeFiltersChecked: Record<string, FilterChecked> = {
    0.25: {
        className : mapVolumeToStepClassName(0.25),
        checked   : false
    },
    1: {
        className : mapVolumeToStepClassName(1),
        checked   : false
    },
    2: {
        className : mapVolumeToStepClassName(2),
        checked   : false
    }
};
