import React from 'react';

import EndlessLogo from '../EndlessLogo';
import { Dotted, GifContainer } from './style';

type GifLoaderProps = {
    title?: string;
    subTitle?: string;
};

export const GifLoader: React.FC<GifLoaderProps> = ({ title, subTitle, ...props }): JSX.Element => {
    return (
        <GifContainer {...props}>
            <Dotted>
                <EndlessLogo size={30} />
                <span>.</span>
                <span>.</span>
                <span>.</span>
            </Dotted>
            {subTitle && <div className="sub-title">{subTitle}</div>}
        </GifContainer>
    );
};
