import { setAutoFreeze } from 'immer';
import moment from 'moment';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ContextMenu } from 'primereact/contextmenu';
import { MenuItem, MenuItemCommandParams } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { displayVolumeFromProducts } from '@bbng/util/misc';
import {
    CCAdministrativeRoFront,
    CCServiceRo,
    CCServiceRoFront,
    CC_FAMILY,
    CC_STATUS,
    ECollectCharacteristic,
    EPlanningType,
    PlanningShiftStepCategory,
    isCCServiceFront,
    CCAdministrativeRo,
    isCCService,
    CollectorRo
} from '@bbng/util/types';

import { mapCollectConfigFamilyText } from '../../common/enumMapper';
import { urlApiBuilder } from '../../common/urlBuilder';
import { AddressOverview } from '../../components/AddressOverview';
import { StepOverview, collectConfigDescription } from '../../components/StepOverview';
import { Dialog } from '../../components/Dialog';
import { toast } from '../../components/Toast';
import { useRequest } from '../../hooks/StatelessRequest';
import CollectConfigCancelForm from '../collect-config-cancel-form';
import CollectConfigForm from '../planning-collect-config-form';
import CollectConfigSplitForm from '../collect-config-split-form';
import CollectCreateForm from '../collect-create-form';
import { CardLine, ClientNameContainer, MainLine, StyledCard } from './style';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { useFetchCollector } from '../../hooks/FetchCollector';
import { Skeleton } from 'primereact/skeleton';

setAutoFreeze(false);

type CollectConfigCardProps = {
    collect_config: CCServiceRo | CCAdministrativeRo;
    readOnly: boolean;
    showDay?: boolean;
    showEdit?: boolean;
    showSplit?: boolean;
    showMerge?: boolean;
    showComplete?: boolean;
    showCancel?: boolean;
    showAgeBar?: boolean;
    alreadyAvailable?: boolean;
    borderColor?: string;
    splittedIdx?: number;
};

export const CollectConfigCard: React.FC<CollectConfigCardProps> = ({
    collect_config,
    readOnly,
    showDay = false,
    showEdit = true,
    showSplit = true,
    showMerge = true,
    showComplete = true,
    showCancel = true,
    showAgeBar = false,
    alreadyAvailable = false,
    borderColor,
    splittedIdx
}: CollectConfigCardProps): JSX.Element => {
    const { getPlannings, getCCAdministrative, getCCService, customers, collectors } = usePlanningV2Store();

    const ccService = isCCService(collect_config) ? collect_config : undefined;
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });
    const [detailDialogIsOpen, setDetailDialogIsOpen] = useState(false);
    const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
    const [splitDialogIsOpen, setSplitDialogIsOpen] = useState(false);
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
    const [completeDialogIsOpen, setCompleteDialogIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const cardRef = useRef<ContextMenu>(null);

    const status =
        splittedIdx !== undefined && ccService
            ? ccService.splitted_informations[splittedIdx].status
            : collect_config.status;
    const products =
        splittedIdx !== undefined && ccService
            ? ccService.splitted_informations[splittedIdx].products
            : ccService?.products;

    const canceled = status === CC_STATUS.CANCELED;
    const narrow = ccService?.characteristics.includes(ECollectCharacteristic.NARROW_STREET);
    const customer = ccService?.customer_id[0] ? customers[ccService?.customer_id[0]] : undefined;
    const replanCount = ccService?.replan_count ?? 0;

    const { fetchCollector, isLoading: collectorIsLoading } = useFetchCollector();
    const [collector, setCollector] = useState<CollectorRo | undefined>();
    const fetchAndSetCollector = useCallback(async (collectorId) => {
        const fetchedCollector = await fetchCollector(collectorId);
        setCollector(fetchedCollector);
    }, []);
    useEffect(() => {
        if (!isCCService(collect_config) && collect_config?.collector_id?.[0]) {
            const existingCollector = collectors[collect_config.collector_id[0] as string];
            if (existingCollector) setCollector(existingCollector);
            if (collect_config.collector_id[0]) fetchAndSetCollector(collect_config.collector_id[0]);
        }
    }, [collect_config?.collector_id]);

    let duration = '';
    if (collect_config.execution_time_minutes) {
        duration = moment.utc().startOf('day').add(collect_config.execution_time_minutes, 'minutes').format('HH:mm');
    } else {
        duration = moment.utc().startOf('day').add(ccService?.waiting_time_minutes, 'minutes').format('HH:mm');
    }

    const mergeCollectConfig = React.useCallback(async () => {
        if ((ccService?.splitted_informations ?? []).length === 0) {
            toast({
                severity : 'warning',
                summary  : 'Collecte non fusionnable',
                detail   : "Cette collecte ne peut pas être fusionnée car elle n'a pas été splitée."
            });
        }

        setLoading(true);
        const response = await bbngRequest<CCServiceRo>({
            method  : 'PATCH',
            url     : urlApiBuilder.collectConfigMerge(collect_config.id),
            options : { toastifySuccess: false }
        });
        if (response.success && response?.response?.data?.ro) {
            await Promise.all([getPlannings(bbngRequest), getCCService(bbngRequest)]);
        }
        setLoading(false);
    }, [collect_config]);

    const confirmMerge = () => {
        confirmDialog({
            message : 'Voulez-vous vraiment refusionner cette collecte ?',
            header  : 'Fusionner une collecte',
            icon    : 'pi pi-replay',
            accept  : () => {
                mergeCollectConfig();
            },
            acceptLabel : 'Confirmer',
            rejectLabel : 'Annuler'
        });
    };

    const durationColor = React.useMemo(() => {
        const now = moment();

        const duration = Math.abs(moment(collect_config.created_at).diff(now, 'hours'));

        if (duration < 2) return '#BFBFBF';
        else if (duration < 6) return '#77DD77';
        else if (duration < 12) return '#ffcc67';
        else if (duration < 24) return '#f19b21';
        else return '#ff6961';
    }, [collect_config]);

    if (loading) {
        return (
            <StyledCard>
                <ProgressSpinner />
            </StyledCard>
        );
    }

    return (
        <>
            <span
                style={{ cursor: 'pointer' }}
                onClick={() => setDetailDialogIsOpen(true)}
                onContextMenu={(e) => !canceled && cardRef?.current?.show(e)}
            >
                <StyledCard cancel={canceled} borderColor={borderColor}>
                    {showAgeBar && (
                        <div
                            style={{
                                height          : '3px',
                                width           : '100%',
                                backgroundColor : durationColor,
                                borderRadius    : '4px'
                            }}
                        />
                    )}
                    <CardLine weight={200}>
                        {ccService?.number} - {mapCollectConfigFamilyText(collect_config.family)} -{' '}
                        {displayVolumeFromProducts(products || [], collect_config.family)}
                        {(ccService?.splitted_informations ?? []).length > 0 && (
                            <>
                                {' '}
                                - <i className="pi pi-fw pi-clone" />
                            </>
                        )}
                        {replanCount > 0 && (
                            <>
                                {' '}
                                - <i className="pi pi-refresh" style={{ fontSize: '0.9em' }} />
                                {ccService?.replan_count}
                            </>
                        )}
                    </CardLine>
                    <MainLine>
                        <CardLine weight={200}>
                            {showDay && moment(collect_config.from_date).format('DD/MM/YYYY')}
                        </CardLine>
                        {canceled ? (
                            <CardLine weight={700}>
                                <i className="pi pi-cimes" />
                                COLLECTE ANNULEE
                            </CardLine>
                        ) : (
                            <CardLine weight={600}>
                                {moment(
                                    alreadyAvailable && isCCServiceFront(collect_config)
                                        ? collect_config.already_available_date?.from_date
                                        : collect_config.from_date
                                ).format('HH:mm')}{' '}
                                -{' '}
                                {moment(
                                    alreadyAvailable && isCCServiceFront(collect_config)
                                        ? collect_config.already_available_date?.to_date
                                        : collect_config.to_date
                                ).format('HH:mm')}
                            </CardLine>
                        )}
                        <CardLine>
                            <AddressOverview address={collect_config.address} />
                            {narrow && <em>Rue étroite</em>}
                        </CardLine>
                    </MainLine>
                    {customer && <ClientNameContainer title={customer.name}>{customer.name}</ClientNameContainer>}
                    {collector ? (
                        <ClientNameContainer title={collector.fullname}>{collector.fullname}</ClientNameContainer>
                    ) : (
                        collectorIsLoading && <Skeleton height="10px" width="40px" />
                    )}
                </StyledCard>
            </span>
            <ContextMenu
                model={contextMenuItems({
                    cc                : ccService ?? collect_config,
                    setEditDialog     : setEditDialogIsOpen,
                    setDetailDialog   : setDetailDialogIsOpen,
                    setSplitDialog    : setSplitDialogIsOpen,
                    setCancelDialog   : setCancelDialogIsOpen,
                    setCompleteDialog : setCompleteDialogIsOpen,
                    mergeCallback     : confirmMerge,
                    planningType      : collect_config?.type,
                    isSplitted        : (ccService?.splitted_informations ?? []).length > 0,
                    readOnly,
                    showMerge,
                    showSplit,
                    showComplete,
                    showCancel
                })}
                ref={cardRef}
            />
            <Dialog
                visible={detailDialogIsOpen}
                onHide={() => setDetailDialogIsOpen(false)}
                header={collectConfigDescription(collect_config)}
                draggable={false}
                resizable={false}
                blockScroll
                contentStyle={{ overflow: 'scroll' }}
            >
                <StepOverview
                    collectorId={collect_config.collector_id[0] as unknown as string}
                    customerId={
                        collect_config.family !== CC_FAMILY.ADMINISTRATIVE ? collect_config.customer_id[0] : undefined
                    }
                    family={collect_config.family}
                    planned_start_date={collect_config.from_date}
                    planned_end_date={collect_config.to_date}
                    planned_duration={duration}
                    planned_products={products}
                    status={status}
                    address={collect_config.address}
                    title={collect_config.title ?? undefined}
                    comment={collect_config.comment ?? undefined}
                    characteristics={ccService?.characteristics}
                    isSplitted={status === CC_STATUS.SPLITTED || splittedIdx !== undefined}
                    number={ccService?.number}
                    order_number={ccService?.number}
                    cons_site_contacts={ccService?.construction_site_contact}
                    replanCount={ccService?.replan_count}
                    construction_site_id={ccService?.construction_site_id[0]}
                />
            </Dialog>
            {showEdit && (
                <Dialog
                    visible={editDialogIsOpen}
                    onHide={() => setEditDialogIsOpen(false)}
                    header={collectConfigDescription(collect_config)}
                    draggable={false}
                    resizable={false}
                    blockScroll
                    contentStyle={{ overflow: 'scroll' }}
                >
                    <CollectConfigForm
                        edit
                        setModalIsOpen={setEditDialogIsOpen}
                        dataId={collect_config.id}
                        isInternal={collect_config.family === CC_FAMILY.ADMINISTRATIVE}
                        isAdministrative={collect_config.family === CC_FAMILY.ADMINISTRATIVE}
                    />
                </Dialog>
            )}
            <ConfirmDialog />
            {showSplit && (
                <Dialog
                    visible={splitDialogIsOpen}
                    onHide={() => setSplitDialogIsOpen(false)}
                    header={collectConfigDescription(collect_config)}
                    draggable={false}
                    resizable={false}
                    blockScroll
                    contentStyle={{ overflow: 'scroll' }}
                >
                    <CollectConfigSplitForm setModalIsOpen={setSplitDialogIsOpen} dataId={collect_config?.id} />
                </Dialog>
            )}
            {showCancel && (
                <Dialog
                    visible={cancelDialogIsOpen}
                    onHide={() => setCancelDialogIsOpen(false)}
                    header={`Annulation - ${collectConfigDescription(collect_config)}`}
                    draggable={false}
                    resizable={false}
                    blockScroll
                    contentStyle={{ overflow: 'scroll' }}
                >
                    <CollectConfigCancelForm
                        submitCallback={async () => {
                            /**
                             * Once the cancel is done, we need to refresh the planning and ccs
                             */
                            await Promise.all([
                                getPlannings(bbngRequest),
                                getCCAdministrative(bbngRequest),
                                getCCService(bbngRequest)
                            ]);
                        }}
                        setModalIsOpen={setCancelDialogIsOpen}
                        dataId={collect_config.id}
                        isAdministrative={collect_config.family === CC_FAMILY.ADMINISTRATIVE}
                    />
                </Dialog>
            )}
            {showComplete && (
                <Dialog
                    visible={completeDialogIsOpen}
                    onHide={() => setCompleteDialogIsOpen(false)}
                    header={collectConfigDescription(collect_config)}
                    draggable={false}
                    resizable={false}
                    blockScroll
                    contentStyle={{ overflow: 'scroll' }}
                >
                    <CollectCreateForm
                        setModalIsOpen={setCompleteDialogIsOpen}
                        category={PlanningShiftStepCategory.SERVICE}
                        wasPlanned={false}
                        showLandfill={true}
                        hideCalculateModal={true}
                        cc_id={ccService?.id}
                        showLandfillNumber={false}
                    />
                </Dialog>
            )}
        </>
    );
};

type ContextMenuItemsProps = {
    cc: CCServiceRoFront | CCAdministrativeRoFront;
    setEditDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setDetailDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setSplitDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setCancelDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setCompleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
    mergeCallback: () => void;
    readOnly: boolean;
    isSplitted: boolean;
    planningType?: EPlanningType;
    showMerge?: boolean;
    showSplit?: boolean;
    showEdit?: boolean;
    showComplete?: boolean;
    showCancel?: boolean;
};

const contextMenuItems = ({
    cc,
    setEditDialog,
    setDetailDialog,
    setSplitDialog,
    setCancelDialog,
    setCompleteDialog,
    mergeCallback,
    readOnly,
    isSplitted,
    planningType,
    showMerge,
    showSplit,
    showEdit,
    showComplete,
    showCancel
}: ContextMenuItemsProps): MenuItem[] => {
    return [
        {
            label   : 'Voir',
            icon    : 'pi pi-eye',
            command : (e: MenuItemCommandParams) => {
                setDetailDialog(true);
            }
        },
        ...(showComplete
            ? [
                  {
                      label   : 'Terminer',
                      icon    : 'pi pi-check',
                      command : (e: MenuItemCommandParams) => {
                          setCompleteDialog(true);
                      },
                      disabled: cc.family === CC_FAMILY.ADMINISTRATIVE
                  }
              ]
            : []),
        ...(showEdit
            ? [
                  {
                      label   : 'Editer',
                      icon    : 'pi pi-cog',
                      command : (e: MenuItemCommandParams) => {
                          setEditDialog(true);
                      },
                      disabled: readOnly
                  }
              ]
            : []),
        ...(showSplit
            ? [
                  {
                      label   : 'Scinder',
                      icon    : 'pi pi-clone',
                      command : (e: MenuItemCommandParams) => {
                          setSplitDialog(true);
                      },
                      disabled:
                          isSplitted || planningType !== EPlanningType.BIG_BAG || cc.family === CC_FAMILY.ADMINISTRATIVE
                  }
              ]
            : []),
        ...(showMerge
            ? [
                  {
                      label   : 'Fusionner',
                      icon    : 'pi pi-replay',
                      command : (e: MenuItemCommandParams) => {
                          mergeCallback();
                      },
                      disabled: !isSplitted || planningType !== EPlanningType.BIG_BAG
                  }
              ]
            : []),
        ...(showCancel
            ? [
                  {
                      label   : 'Annuler',
                      icon    : 'pi pi-exclamation-triangle',
                      command : (e: MenuItemCommandParams) => {
                          setCancelDialog(true);
                      },
                      disabled: readOnly
                  }
              ]
            : [])
    ];
};
