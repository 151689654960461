import { useCallback, useEffect, useState } from 'react';

export type ClickableButtonProps = {
    value: boolean;
    id: string;
    result: (value: boolean, errors: null | string[], id: string) => void;
    readOnly?: boolean;
    children: React.ReactNode;
};

export const ClickableButton: React.FC<ClickableButtonProps> = ({
    value,
    id,
    result,
    readOnly,
    ...props
}: ClickableButtonProps): JSX.Element => {
    const [val, setVal] = useState<boolean>(value);

    const _checkDescendingValue = useCallback(
        async (stateValue: boolean, propsValue: boolean): Promise<void> => {
            if (stateValue !== propsValue) {
                setVal(propsValue);
                result(propsValue, null, id);
            }
        },
        [setVal, result]
    );

    const handleOnChange = useCallback(
        (e: any): void => {
            if (!readOnly) {
                let newVal;
                setVal((old) => {
                    newVal = !old;
                    result(newVal, null, id);
                    return newVal;
                });
            }
        },
        [setVal, result, id]
    );

    useEffect(() => {
        _checkDescendingValue(val, value);
    }, [value]);

    return <div onClick={handleOnChange}>{props.children}</div>;
};
