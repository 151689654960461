import Joi from 'joi';

export const AddressCreateSchema = Joi.object({
    formatted_name : Joi.string().required(),
    name           : Joi.string(),
    components     : Joi.object().pattern(Joi.string().required(), Joi.string().allow('').required()).required(),
    coordinates    : Joi.object({
        latitude  : Joi.number().required(),
        longitude : Joi.number().required()
    }).required()
});

export const AddressEditSchema = Joi.object({
    formatted_name : Joi.string(),
    name           : Joi.string(),
    components     : Joi.object().pattern(Joi.string(), Joi.string().allow('')),
    coordinates    : Joi.object({
        latitude  : Joi.number(),
        longitude : Joi.number()
    })
});
