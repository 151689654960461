import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { CCPublicInformationPreviewRo, SMS_TYPE } from '@bbng/util/types';
import { useRequest } from '../../../hooks/StatelessRequest';
import { urlApiBuilder } from '../../../common/urlBuilder';
import { TextInput } from '../../../components/Inputs/Text';
import { toast } from '../../../components/Toast';
import PhoneInput from 'react-phone-input-2';
import { NumberInput } from '../../../components/Inputs/Number';
import { phoneYupValidation } from '../../../components/InputPhone';
import { formatPhoneE164ToPhoneValue } from '@bbng/util/misc';

interface SmsModalProps {
    ccId: string;
    onClose: () => void;
    contactList: string[];
}

export const SmsModal = ({ ccId, onClose, contactList }: SmsModalProps) => {
    const bbngRequest = useRequest({
        toastifyError   : false,
        toastifySuccess : false
    });
    const [buttonAlreadyClicked, setButtonAlreadyClicked] = useState<boolean>(false);
    const [latitude, setLatitude] = useState<number>(0);
    const [longitude, setLongitude] = useState<number>(0);
    const [confirmInput, setConfirmInput] = useState<string>('');
    const [smsPreviewData, setSmsPreviewData] = useState<CCPublicInformationPreviewRo>();
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [peopleToNotify, setPeopleToNotify] = useState<string>(contactList[0] ? contactList[0].replace('+', '') : '');
    const [displayInputError, setDisplayInputError] = useState<boolean>(false);

    const captcha = 'Envoie';
    const [error, setError] = useState<string>('');

    const validateLatitude = (value: number): boolean => {
        if (value < -90 || value > 90 || value === 0) return false;
        return true;
    };

    const validateLongitude = (value: number): boolean => {
        if (value < -180 || value > 180 || value === 0) return false;
        return true;
    };

    const fetchSMSPreview = async () => {
        try {
            setIsLoading(true);
            const response = await bbngRequest<CCPublicInformationPreviewRo>({
                url    : urlApiBuilder.collectConfigSMSPreview(ccId),
                method : 'GET',
                sync   : true
            });
            setIsLoading(false);
            const apiResponse = response.response;

            // This means that we don't have any collect_public_informations for this ccId
            if (apiResponse?.success === false && apiResponse?.data.status_code === 404) {
                setError(
                    "⚠️ Cette collecte n'a pas suffisamment d'informations pour permettre l'envoi d'un sms. Veuillez contacter le support pour plus d'informations. ⚠️"
                );
                return;
            }
            // If the sms has already been sent, we cannot send it again.
            if (response.response?.data.ro?.sms_sent === true) {
                setError('⚠️ Le sms a déjà été envoyé pour cette collecte. ⚠️');
                return;
            }
            setSmsPreviewData(response.response?.data.ro);
        } catch (error) {
            console.log('error', error);
        }
    };

    const sendSMS = async () => {
        try {
            setIsButtonDisabled(true);
            await bbngRequest({
                url     : urlApiBuilder.collectConfigSendSMS(),
                method  : 'POST',
                sync    : true,
                payload : {
                    body: {
                        ccId,
                        recipients       : [`+${peopleToNotify}`],
                        driver_longitude : longitude === 0 ? undefined : longitude,
                        driver_latitude  : latitude === 0 ? undefined : latitude
                    }
                }
            });
            toast({
                severity : 'success',
                summary  : 'Succès',
                detail   : 'Le sms a bien été envoyé'
            });
            onClose();
        } catch (error) {
            toast({
                severity : 'error',
                summary  : 'Erreur',
                detail   : "Une erreur est survenue lors de l'envoi du sms"
            });
            console.error('error', error);
        }
    };

    useEffect(() => {
        if (buttonAlreadyClicked) {
            fetchSMSPreview();
        }
    }, [buttonAlreadyClicked]);

    useEffect(() => {
        try {
            if (
                (buttonAlreadyClicked && confirmInput !== captcha) ||
                (buttonAlreadyClicked && !phoneYupValidation.validateSync(formatPhoneE164ToPhoneValue(peopleToNotify)))
            ) {
                setIsButtonDisabled(true);
            } else {
                if (latitude !== 0 || longitude !== 0) {
                    if (!validateLatitude(latitude) || !validateLongitude(longitude)) {
                        setIsButtonDisabled(true);
                        return;
                    }
                    setIsButtonDisabled(false);
                    return;
                } else {
                    setIsButtonDisabled(false);
                }
            }
        } catch (error) {
            setIsButtonDisabled(true);
        }
    }, [confirmInput, buttonAlreadyClicked, peopleToNotify, latitude, longitude]);

    useEffect(() => {
        if (confirmInput === captcha) {
            setDisplayInputError(false);
        } else {
            setDisplayInputError(true);
        }
    }, [confirmInput]);

    const manageSendSMS = () => {
        if (!buttonAlreadyClicked) {
            setButtonAlreadyClicked(true);
        } else if (buttonAlreadyClicked && confirmInput === captcha) {
            sendSMS();
        }
    };

    return (
        <Container>
            {!error && !buttonAlreadyClicked && (
                <h3>
                    Avant d'envoyer un sms au client pour le notifier de l'arrivée du chauffeur souhaitez-vous un aperçu
                    du message ?{' '}
                </h3>
            )}
            {buttonAlreadyClicked && (
                <>
                    {isLoading && <p>Chargement...</p>}
                    {!isLoading && !error && (
                        <CenteredContainer>
                            <h3>Voici un aperçu du message que le client recevra :</h3>
                            <div
                                dangerouslySetInnerHTML={{ __html: smsPreviewData?.sms_content_preview as string }}
                            ></div>
                            <h3>Le sms sera envoyé au numéro suivant : </h3>
                            <div>
                                <PhoneInput
                                    country={'fr'}
                                    value={peopleToNotify}
                                    onChange={(value) => setPeopleToNotify(value)}
                                    disableDropdown={true}
                                    placeholder="Numéro de téléphone du client"
                                    countryCodeEditable={false}
                                    disableSearchIcon={true}
                                    enableAreaCodeStretch={false}
                                />
                            </div>
                            <h3>Merci de rentrer la position actuelle du chauffeur </h3>
                            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                ⚠️ Si vous ne rentrez pas les coordonnes ⚠️
                                <br /> Le client recevra un sms sans lien de suivi et sans estimations de temps{' '}
                            </p>
                            <HorizontalContainer>
                                <NumberInput
                                    id="latitude"
                                    label="Latitude"
                                    value={latitude}
                                    result={(value) => {
                                        if (value !== null) {
                                            setLatitude(value);
                                        }
                                    }}
                                    required
                                    errors={['La latitude doit être comprise entre -90 et 90']}
                                    decimal={true}
                                    size={20}
                                    maxNumberOfDigit={20}
                                />
                                <NumberInput
                                    id="longitude"
                                    label="Longitude"
                                    value={longitude}
                                    result={(value) => {
                                        if (value !== null) {
                                            setLongitude(value);
                                        }
                                    }}
                                    required
                                    errors={['La longitude doit être comprise entre -180 et 180']}
                                    decimal={true}
                                    size={20}
                                    maxNumberOfDigit={20}
                                />
                            </HorizontalContainer>

                            <h5>
                                Veuillez écrire
                                <Captcha>Envoie</Captcha>
                                dans le champs ci-dessous
                            </h5>
                            <TextInput
                                width="100%"
                                label=""
                                value={confirmInput}
                                result={(value) => setConfirmInput(value)}
                                id="confirmInput"
                                required
                                displayError={displayInputError}
                                errors={displayInputError ? ['Veuillez écrire "Envoie"'] : []}
                            />
                        </CenteredContainer>
                    )}
                    {!isLoading && error && (
                        <CenteredContainer>
                            <h3>{error}</h3>
                        </CenteredContainer>
                    )}
                </>
            )}
            <HorizontalContainer>
                <Button label="Annuler" className="p-button-secondary" onClick={onClose} />
                <Button
                    label={buttonAlreadyClicked ? 'Envoyer un sms' : 'Avoir un aperçu du sms'}
                    className="p-button-primary"
                    disabled={isButtonDisabled}
                    onClick={() => manageSendSMS()}
                />
            </HorizontalContainer>
        </Container>
    );
};

const Container = styled.div`
    max-width: 500px;
`;

const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const HorizontalContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-top: 2em;
`;

const Captcha = styled.span`
    padding: 5px;
    margin: 0 5px;
    background-color: #f0f0f0;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    font-family: monospace;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`;
