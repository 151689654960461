import equal from 'fast-deep-equal/react';
import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import {
    CustomerRo,
    EBillingBundle,
    EBillingMode,
    EBillingShipment,
    ECustomerCategory,
    ECustomerOrigin,
    EDocumentType
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { mapCustomerOrigin } from '../../common/enumMapper';
import { ActionsFormContainer, uploadFiles } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import SnackBar from '../../components/SnackBar';
import { toast } from '../../components/Toast';
import useQuery from '../../hooks/Query';
import { useRequest } from '../../hooks/StatelessRequest';
import { Address } from '../../modules/common/Address';
import Customer from '../../modules/customer';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    CustomerFormErrorState,
    CustomerFormState,
    CustomerModulesErrorStates,
    CustomerModulesStates,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiCreateData,
    mapStateToApiEditData
} from './helpers';
import { PageForm, PageFormLine, StyledH1, SubmitButton } from './style';

type CustomerFormProps = {
    edit?: boolean;
    readOnly?: boolean;
};

const CustomerForm: React.FC<CustomerFormProps> = ({
    edit = false,
    readOnly = false
}: CustomerFormProps): JSX.Element => {
    const { query } = useQuery<{ on_success?: 'close' }>();
    const { id: dataId = 'undefined' } = useParams();
    const navigate = useNavigate();
    const [apiState, setApiState] = React.useState<{ form: CustomerFormState; db: CustomerRo }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(edit || readOnly);
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>(
        edit || readOnly ? 'read' : undefined
    );

    const [err, setErr] = useState<CustomerFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<CustomerFormState>['onSubmit']>(
        async (data: CustomerFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            if (
                data.contacts.user.length > 0 &&
                (data.contacts.user.every((u) => !u.is_primary) || data.contacts.user.every((u) => !u.is_invoice))
            ) {
                toast({
                    severity : 'error',
                    summary  : 'Formulaire invalide',
                    detail   : 'Parmi les contacts, il faut au minimum un contact de facturation et un principal.'
                });
                return;
            }

            if (
                [EBillingMode.INSTANT].includes(data.billing.billing_mode) &&
                data.billing.billing_shipment !== EBillingShipment.EMAIL
            ) {
                toast({
                    severity : 'error',
                    summary  : 'Formulaire invalide',
                    detail   : "Le mode d'émission de facture n'est pas compatible avec le mode d'envoi choisi."
                });
                return;
            }

            if (
                (!data.contacts.user.length || data.contacts.user.length === 0) &&
                data.billing.billing_shipment === EBillingShipment.EMAIL
            ) {
                toast({
                    severity : 'error',
                    summary  : 'Formulaire invalide',
                    detail   : 'Un contact est nécessaire pour un mode de facturation par email.'
                });
                return;
            }

            const documents = await uploadFiles(data.documents.documents, 'customer');
            if (!documents) {
                toast({
                    severity : 'error',
                    summary  : 'Téléversement des documents échoué',
                    detail   : 'Le téléversement des documents a échoué, veuillez réessayer.'
                });
                return;
            }
            const dataWithUploadedDocuments: CustomerFormState = {
                ...data,
                documents: {
                    documents
                }
            };

            const method = edit ? 'PATCH' : 'POST';
            const url = edit ? urlApiBuilder.customerEdit(dataId) : urlApiBuilder.customerCreate();
            const body = edit
                ? mapStateToApiEditData(dataWithUploadedDocuments, apiState!.form, apiState!.db)
                : mapStateToApiCreateData(dataWithUploadedDocuments);

            setLoading(true);
            setOnRequestType('create');

            const response = await bbngRequest<CustomerRo>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);
            setOnRequestType(undefined);
            if (query?.on_success === 'close') {
                window.close();
            }
            if (response.success) {
                navigate(urlBuilder.customerList());
            }
        },
        [err, apiState, bbngRequest]
    );

    const handlePreSubmit = React.useCallback<FormikConfig<CustomerFormState>['onSubmit']>(
        async (data: CustomerFormState, helper) => {
            if (
                !edit &&
                data.customerType.category === ECustomerCategory.PRO &&
                data.billing.billing_mode !== EBillingMode.INSTANT &&
                (data.documents.documents.length === 0 ||
                    !data.documents.documents.some(
                        (d) => d.local?.type === EDocumentType.RIB || d.online?.type === EDocumentType.RIB
                    ))
            ) {
                confirmDialog({
                    message         : "Aucun RIB n'a été ajouté dans les documents. Voulez-vous continuer ?",
                    header          : "Confirmation d'omission volontaire du RIB",
                    icon            : 'pi pi-exclamation-triangle',
                    acceptLabel     : 'Continuer',
                    rejectLabel     : 'Annuler',
                    acceptClassName : 'p-button-danger',
                    accept          : () => {
                        handleSubmit(data, helper);
                    },
                    reject: () => {
                        return;
                    }
                });
            } else {
                handleSubmit(data, helper);
            }
        },
        [edit, apiState, bbngRequest]
    );

    const formik = useFormik<CustomerFormState>({
        initialValues : initialState,
        onSubmit      : handlePreSubmit
    });

    const handleChange = React.useCallback(
        (value: CustomerModulesStates, errors: CustomerModulesErrorStates | string[] | null, id: string): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response = await bbngRequest<CustomerRo>({
            method  : 'GET',
            url     : urlApiBuilder.customerGet(dataId),
            options : { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            const ro: CustomerRo = await fetchDataRelation(response.response?.data.ro, {
                document_id : true,
                admin_id    : true
            });
            const formikState: CustomerFormState = mapApiDataToState(ro) as CustomerFormState;

            setApiState({ form: deepCopy(formikState), db: ro });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (edit || readOnly) {
            fetchData();
        }
    }, []);

    React.useEffect(() => {
        if (formik.values.customerType.category === ECustomerCategory.INDIVIDUAL) {
            err.headquarters = initialErrorState.headquarters;
            formik.setFieldValue('headquarters', initialState.headquarters);
        }
    }, [formik.values, err]);

    React.useEffect(() => {
        /**
         * On customer edition, for customer with billing mode end of month,
         * if the billing mode is changed to another one (instant or after_collect), a warning is displayed
         */
        if (
            edit &&
            [EBillingMode.INSTANT, EBillingMode.AFTER_COLLECT].includes(formik.values.billing.billing_mode) &&
            apiState?.db.billing_mode === EBillingMode.END_OF_MONTH
        ) {
            toast({
                severity : 'warn',
                summary  : 'Avertissement',
                detail   : "Le changement d'un mode de facturation en fin de mois à un autre entraîne la génération des factures de toutes les collectes terminées (1 facture par collecte).",
                life     : 15_000
            });
        }
    }, [formik.values.billing.billing_mode]);

    const handleArchive = React.useCallback(() => {
        setLoading(true);
        setOnRequestType(apiState?.db.archived ? 'unarchive' : 'delete');

        bbngRequest({
            method  : 'PATCH',
            url     : urlApiBuilder.customerArchive(dataId),
            options : { toastifySuccess: false },
            payload : {
                body: {
                    archived: !apiState?.db.archived
                }
            }
        })
            .then((response) => {
                if (response.success) {
                    navigate(urlBuilder.customerList());
                }
            })
            .finally(() => {
                setLoading(false);
                setOnRequestType(undefined);
            });
    }, [bbngRequest, setLoading, setOnRequestType, apiState]);

    React.useEffect(() => {
        formik.setValues((prev) => ({
            ...prev,
            billing: {
                ...prev.billing,
                ...([EBillingMode.INSTANT, EBillingMode.AFTER_COLLECT].includes(prev.billing.billing_mode) && {
                    billing_bundle: EBillingBundle.GLOBAL
                }),
                ...(prev.billing.billing_mode === EBillingMode.INSTANT && {
                    billing_shipment : EBillingShipment.EMAIL,
                    payment_delay    : '0'
                })
            }
        }));
    }, [formik.values.billing.billing_mode]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (errorCode) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.customerList()} />
        ) : (
            <Error500 />
        );
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <StyledH1>
                Client
                {edit || readOnly ? `: ${apiState?.form?.general.name}` : ''}
                {(edit || readOnly) && <Tag value={mapCustomerOrigin(apiState?.db?.origin as ECustomerOrigin)} />}
                {(edit || readOnly) && apiState?.db.archived && <Tag value={'CLIENT ARCHIVÉ'} severity="danger" />}
            </StyledH1>
            <ActionsFormContainer
                archived={apiState?.db.archived || false}
                edit={edit}
                readOnly={readOnly}
                // disableSaveOnEdit={JSON.stringify(apiState?.form) === JSON.stringify(formik.values)}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                handleArchiveOnView={handleArchive}
                editPageUrl={urlBuilder.customerEdit(dataId)}
                historyUrl={urlApiBuilder.customerHistory(dataId)}
            />
            <PageFormLine>
                <Customer.CustomerType
                    edit={edit}
                    id="customerType"
                    result={handleChange}
                    value={formik.values.customerType}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
                {formik.values.customerType.category !== ECustomerCategory.INDIVIDUAL && readOnly === false && (
                    <Customer.Papers readOnly={readOnly} globalSetter={formik.setValues} />
                )}
            </PageFormLine>
            <PageFormLine>
                <Customer.GeneralInfo
                    id="general"
                    result={handleChange}
                    value={formik.values.general}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                    isIndividual={formik.values.customerType.category === ECustomerCategory.INDIVIDUAL}
                    edit={edit}
                />
                {formik.values.customerType.category !== ECustomerCategory.INDIVIDUAL && (
                    <Address
                        title="Siège social"
                        id="headquarters"
                        result={handleChange}
                        value={formik.values.headquarters}
                        readOnly={readOnly}
                        displayError={formik.submitCount > 0}
                    />
                )}
            </PageFormLine>

            <PageFormLine>
                <Customer.Billing
                    id="billing"
                    result={handleChange}
                    value={formik.values.billing}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                    category={formik.values.customerType.category}
                    edit={edit}
                />
            </PageFormLine>
            <PageFormLine>
                <Address
                    title="Adresse de facturation"
                    id="invoice_address"
                    result={handleChange}
                    value={formik.values.invoice_address}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
                <Address
                    title="Adresse de livraison"
                    id="shipping_address"
                    result={handleChange}
                    value={formik.values.shipping_address}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            <Customer.Contacts
                id="contacts"
                result={handleChange}
                value={formik.values.contacts}
                readOnly={readOnly}
                displayError={formik.submitCount > 0}
                emailRequired={formik.values.billing.billing_shipment === EBillingShipment.EMAIL}
            />
            <PageFormLine>
                <Customer.Admin
                    id="admin"
                    result={handleChange}
                    value={formik.values.admin}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
                <Customer.Documents
                    id="documents"
                    result={handleChange}
                    value={formik.values.documents}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            {readOnly === false && (
                <SubmitButton
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        // (JSON.stringify(apiState?.form) === JSON.stringify(formik.values) && edit)
                        (equal(apiState?.form, formik.values) && edit)
                    }
                />
            )}
            {edit === true && (
                <SnackBar
                    // show={JSON.stringify(apiState?.form) !== JSON.stringify(formik.values)}
                    show={!equal(apiState?.form, formik.values)}
                    content="Vous avez des modifications non enregistrées"
                />
            )}
            <ConfirmDialog />
        </PageForm>
    );
};

export default CustomerForm;
