import { InputText } from 'primereact/inputtext';
import * as yup from 'yup';

import { genericTextInputProps } from '.';
import { useComponentInput } from '../../hooks/ComponentInput';

export function TextInput({
    label,
    readOnly,
    required,
    result,
    id,
    validationSchema,
    value = '',
    errors,
    displayError
}: genericTextInputProps) {
    const baseValidationSchema = yup.string();
    const schema = validationSchema
        ? validationSchema
        : required
        ? baseValidationSchema.required(`${label} requis`)
        : baseValidationSchema;

    const { handleBlur, handleOnChange, val, err, displayErr } = useComponentInput({
        id,
        value,
        errors,
        displayError        : displayError ?? false,
        result,
        required,
        schema,
        didRevalidateSchema : (e) => e?.length > 0,
        mapValue            : (e) => e.target.value
    });

    return (
        <span className="p-float-label">
            <InputText
                disabled={readOnly}
                type="text"
                name={id}
                className={`p-inputtext-sm ${err && err.length > 0 && displayErr ? 'p-invalid' : ''}`}
                id={id}
                onChange={handleOnChange}
                onBlur={handleBlur}
                value={val}
                tooltip={displayErr && err && err.length > 0 ? err.join('\n') : ''}
            />
            <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>
        </span>
    );
}
