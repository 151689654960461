import React from 'react';
import { Image } from 'primereact/image';
import { Dialog } from '../../../components/Dialog';

export type ImageInfo = {
    src: string;
    name: string;
};

export type ImageDialogProps = {
    dialogIsOpen: boolean;
    setDialogIsOpen: (isOpen: boolean) => void;
    imageInfo: ImageInfo;
};

export const ImageDialog: React.FC<ImageDialogProps> = ({
    dialogIsOpen,
    setDialogIsOpen,
    imageInfo
}: ImageDialogProps) => {
    return (
        <Dialog
            visible={dialogIsOpen}
            onHide={() => setDialogIsOpen(false)}
            header={`Aperçu de ${imageInfo.name}`}
            draggable={false}
            resizable={false}
            blockScroll
        >
            <Image
                src={imageInfo.src}
                alt={imageInfo.name}
                downloadable
                preview
                imageStyle={{ maxWidth: '50vw' } as unknown as string}
            />
        </Dialog>
    );
};
