import React from 'react';
import styled from 'styled-components';

import { CardErrors, ETruckType, PhoneValue } from '@bbng/util/types';

import { mapTruckType } from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import Input from '../../components/Inputs';

export type TruckGeneralProps = {
    readOnly?: boolean;
    value?: TruckGeneralState;
    id: string;
    result: (value: TruckGeneralState, errors: null | string[] | CardErrors<TruckGeneralState>, id: string) => void;
    displayError?: boolean;
};

export type TruckGeneralState = {
    label: string;
    tag: Array<ETruckType>;
    available: boolean;
};

export type TruckGeneralErrorState = CardErrors<TruckGeneralState>;

export const initialState: TruckGeneralState = {
    label     : '',
    tag       : [],
    available : true
};

export const initialErrorState: TruckGeneralErrorState = generateInitialErrorState(initialState);

export const TruckGeneral = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: TruckGeneralProps) => {
    const [val, setVal] = React.useState<TruckGeneralState>(value);
    const [err, setErr] = React.useState<TruckGeneralErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (
        value: boolean | string | string[] | null | number | undefined | PhoneValue,
        errors: string[] | null,
        childId: string
    ) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <StyledCard title="Informations générales">
            <CardLine>
                <Input.Text
                    required={true}
                    label="Nom"
                    id="label"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.label}
                    errors={err.label}
                    displayError={displayError}
                />
                <div>
                    <Button.Group
                        required
                        id="tag"
                        readOnly={readOnly}
                        result={handleChange}
                        options={Object.values(ETruckType)}
                        labelMapper={(value) => mapTruckType(value as ETruckType)}
                        value={val.tag}
                        errors={err.tag}
                        displayError={displayError}
                    />
                </div>
                <div style={{ flex: '0' }}>
                    <Button.Switch
                        label="Disponible"
                        id="available"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.available}
                    />
                </div>
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
