import React from 'react';
import styled from 'styled-components';

import { CardErrors } from '@bbng/util/types';

import { Card } from '../../../components/Card';
import { useFormModule } from '../../../hooks/FormModule';
import InputList from '../../InputList';
import { FrontContact } from '../../InputList/contact';

export type ContactsProps = {
    readOnly?: boolean;
    value?: ContactsState;
    id: string;
    result: (value: ContactsState, errors: null | string[] | CardErrors<ContactsState>, id: string) => void;
    displayError?: boolean;
};

export type ContactsState = {
    logistic: Array<FrontContact>;
    construction_site: Array<FrontContact>;
};
export type ContactsErrorState = CardErrors<ContactsState>;

export const initialState: ContactsState = {
    logistic          : [],
    construction_site : []
};
export const initialErrorState: ContactsErrorState = Object.fromEntries(
    Object.keys(initialState).map((k) => [k, null])
) as ContactsErrorState;

export const Contacts: React.FC<ContactsProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: ContactsProps) => {
    const { val, setVal, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    return (
        <StyledCard title="Liste de contacts">
            <HalfLineInput>
                <h3 className="subtitle">Logistique: </h3>
                <InputList.Contacts
                    title="Contact"
                    onChange={(value) => handleChange(value, null, 'logistic')}
                    value={val.logistic}
                    readOnly={readOnly}
                    requiredEmail={false}
                    displayError={displayError}
                />
            </HalfLineInput>
            <HalfLineInput>
                <h3 className="subtitle">Chantier: </h3>
                <InputList.Contacts
                    title="Contact"
                    onChange={(value) => handleChange(value, null, 'construction_site')}
                    value={val.construction_site}
                    readOnly={readOnly}
                    requiredEmail={false}
                    displayError={displayError}
                />
            </HalfLineInput>
        </StyledCard>
    );
};

const StyledCard = styled(Card)`
    display: flex;
    .p-card-body {
        width: 100%;
    }
    .subtitle {
        margin-top: 0;
        margin-bottom: 5px;
    }
`;
const HalfLineInput = styled.div`
    width: 100%;
    flex: 1 0 0;
`;
