import { Zone, Zone_Authz, Zone_History } from '@prisma/client/zone';

import { IKafkaQuery } from '../kafka';
import { IOpeningTime, ISODate } from '../misc';
import { EPlanningRegion } from '../planning';
import { ESlot } from '../order';

export type ZoneRo = {
    id: string;
    name: string;
    display_name: string;
    postal_code: string[];
    metropole: EPlanningRegion;
    min_weight: number; // in m3 (called weight but it's a volume)
    required_slot: ESlot | undefined;
    allowed_days: AllowedDays;
    forbidden_days: Record<string /* YYYY-MM-DD */, true>;
    archived: boolean;
    created_by: string;
    created_at: ISODate;
    updated_at: ISODate;
};

export type ZoneGetAllQuery = {
    metropole?: EPlanningRegion;
    postal_code?: string;
    postal_codes?: string[];
    q?: string;
} & IKafkaQuery;

export type ZoneCreateDto = {
    name: string;
    display_name?: string;
    postal_code: string[];
    min_weight: number;
    required_slot?: ESlot | null;
    metropole: EPlanningRegion;
    allowed_days: AllowedDays;
    forbidden_days: Record<string /* YYYY-MM-DD */, true>;
};

export type ZoneCreateActionDto = ZoneCreateDto & { id?: string };

export type ZoneUpdateDto = Partial<ZoneCreateDto>;
export type ZoneUpdateActionDto = ZoneUpdateDto & { archived?: boolean };

export type ZonePrisma = Zone & { authorization: Zone_Authz; history: Zone_History };

export type AllowedDays = IOpeningTime<boolean>;

export const FORBIDDEN_DAY_FORMAT = 'YYYY-MM-DD';
