import React from 'react';
import styled from 'styled-components';

import { CardErrors, PRODUCT_DUMPSTER_TYPE } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import { useFormModule } from '../../hooks/FormModule';
import { mapDumpsterTypeText } from '../../common/enumMapper';
import { Divider } from 'primereact/divider';

export type OrderTypeProps = {
    readOnly?: boolean;
    value?: OrderTypeState;
    id: string;
    result: (value: OrderTypeState, errors: null | string[] | CardErrors<OrderTypeState>, id: string) => void;
    displayError?: boolean;
};

export enum EOrderTypeForm {
    COLLECT_DUMPSTER = 'COLLECT_DUMPSTER',
    COLLECT_BIG_BAG = 'COLLECT_BIG_BAG',
    DELIVERY = 'DELIVERY'
}

const typeMapper: Record<EOrderTypeForm, string> = {
    [EOrderTypeForm.COLLECT_DUMPSTER] : 'Collecte benne',
    [EOrderTypeForm.COLLECT_BIG_BAG]  : 'Collecte big bag',
    [EOrderTypeForm.DELIVERY]         : 'Livraison'
};

export type OrderTypeState = {
    type?: EOrderTypeForm;
    dumpsterType?: PRODUCT_DUMPSTER_TYPE;
};
export type OrderTypeErrorState = CardErrors<OrderTypeState>;

export const initialState: OrderTypeState = {
    type         : EOrderTypeForm.COLLECT_BIG_BAG,
    dumpsterType : undefined
};
export const initialErrorState: OrderTypeErrorState = generateInitialErrorState(initialState);

export const OrderType: React.FC<OrderTypeProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: OrderTypeProps) => {
    const { val, setVal, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    return (
        <StyledCard title="Type de commande">
            <FullLineInput>
                <Button.Select
                    required={true}
                    id="type"
                    options={Object.values(EOrderTypeForm)}
                    readOnly={readOnly}
                    labelMapper={(value) => typeMapper[value as EOrderTypeForm]}
                    result={handleChange}
                    value={val.type}
                    errors={err.type}
                    displayError={displayError}
                />
                <Divider />
                {val.type === EOrderTypeForm.COLLECT_DUMPSTER && (
                    <Button.Select
                        required={true}
                        id="dumpsterType"
                        options={Object.values(PRODUCT_DUMPSTER_TYPE)}
                        readOnly={readOnly}
                        labelMapper={(value) => mapDumpsterTypeText(value as PRODUCT_DUMPSTER_TYPE)}
                        result={handleChange}
                        value={val.dumpsterType ?? []}
                        errors={err.dumpsterType}
                        displayError={displayError}
                    />
                )}
            </FullLineInput>
        </StyledCard>
    );
};

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)``;
