import { formatPhoneE164ToPhoneValue, formatPhoneNumberToE164 } from '@bbng/util/misc';
import dinero from 'dinero.js';
import {
    CollectorCreateDto,
    CollectorEditDto,
    CollectorRoFront,
    ECollectorContract,
    LandfillRo
} from '@bbng/util/types';

import { getRelationsDto, optimiseEditDto } from '../../common/form';
import {
    CollectorGeneralErrorState,
    CollectorGeneralState,
    initialErrorState as collectorGeneralInitialErrorState,
    initialState as collectorGeneralInitialState
} from '../../modules/collector/General';

export type CollectorModulesStates = CollectorGeneralState;

export type CollectorModulesErrorStates = CollectorGeneralErrorState;

export type CollectorFormState = {
    general: CollectorGeneralState;
};

export type CollectorFormErrorState = {
    general: CollectorGeneralErrorState;
};

export const initialState: CollectorFormState = {
    general: collectorGeneralInitialState
};

export const initialErrorState: CollectorFormErrorState = {
    general: collectorGeneralInitialErrorState
};

export const mapApiDataToState = (collector: CollectorRoFront): CollectorFormState => {
    const dineroHourlyRate = dinero({
        amount    : collector.hourly_rate?.net_amount_cents ?? 0,
        currency  : collector.hourly_rate?.currency ?? 'EUR',
        precision : 2
    });
    return {
        general: {
            efficiency   : collector.efficiency ?? null,
            firstname    : collector.firstname ?? '',
            lastname     : collector.lastname ?? '',
            email        : collector.email ?? '',
            phone        : collector.phone_number ? formatPhoneE164ToPhoneValue(collector.phone_number) : null,
            contract     : collector.contract ?? ECollectorContract.INTERNAL,
            is_available : collector.is_available ? 'true' : 'false',
            landfill     : collector.landfill_id[0] as LandfillRo,
            skills       : collector.skills ?? [],
            hourly_rate  : {
                net_amount_cents : dineroHourlyRate.getAmount() / 100,
                currency         : collector.hourly_rate?.currency ?? 'EUR'
            }
        }
    };
};

export const mapStateToApiCreateData = (state: CollectorFormState): CollectorCreateDto => {
    return {
        email        : state.general.email,
        firstname    : state.general.firstname,
        lastname     : state.general.lastname,
        phone_number : formatPhoneNumberToE164(state.general.phone!),
        contract     : state.general.contract as ECollectorContract,
        is_available : state.general.is_available === 'true',
        efficiency   : state.general.efficiency ?? 0,
        skills       : state.general.skills ?? [],
        landfill_id  : state.general.landfill ? [state.general.landfill.id] : undefined,
        hourly_rate  : {
            net_amount_cents : Math.ceil(state.general.hourly_rate.net_amount_cents * 100),
            currency         : state.general.hourly_rate.currency
        }
    };
};

export const mapStateToApiEditData = (
    state: CollectorFormState,
    apiState: CollectorFormState,
    collector: CollectorRoFront
): CollectorEditDto => {
    const body = optimiseEditDto(
        state,
        apiState,
        collector,
        mapStateToApiCreateData,
        getRelationsDto<CollectorRoFront>()
    );

    const relationState = {
        landfill_id: state.general.landfill ? [state.general.landfill.id] : []
    };

    const relations = getRelationsDto<CollectorRoFront>('landfill_id')(relationState, collector);

    return {
        ...body,
        ...relations
    };
};
