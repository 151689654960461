import { ProgressBar as PRProgressBar } from 'primereact/progressbar';
import React from 'react';
import styled from 'styled-components';

export type ProgressBarProps = {
    loadingText?: string;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ loadingText = 'Chargement...' }: ProgressBarProps): JSX.Element => {
    return (
        <CenteredInput>
            <div>
                <div style={{ marginBottom: '6px' }}>{loadingText}</div>
            </div>
            <PRProgressBar mode="indeterminate" style={{ height: '10px', width: '75%', maxWidth: '600px' }} />
        </CenteredInput>
    );
};

const CenteredInput = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export default ProgressBar;
