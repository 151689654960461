import React from 'react';
import styled from 'styled-components';

import { GifLoader } from '../GifLoader';

const LoaderContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 300px;
    }
`;

export const Loader: React.FC = (): JSX.Element => {
    return (
        <LoaderContainer>
            <GifLoader title="chargement" subTitle="Verification des droits d'accès" />
        </LoaderContainer>
    );
};

export default Loader;
