import { AxiosResponse, Method } from 'axios';
import { StatusCodes } from 'http-status-codes';
import React from 'react';

import { BbngPayload } from '@bbng/feature/bbng-requester';
import { NdlssError } from '@bbng/util/error';
import { objectToFormData, sanitizeBody } from '@bbng/util/misc';
import { IBbngResponse } from '@bbng/util/types';

import { axiosClient } from '../../common/axios';
import { syncRequest } from '../../common/syncRequest';
import { WEBSOCKET_URL } from '../../common/urlBuilder';
import { toast } from '../../components/Toast';

export type RequestProps<T = any> = {
    method: Method;
    url: string;
    timeout?: number;
    retryPolling?: number;
    payload?: BbngPayload<T>;
    options?: {
        toastifySuccess?: boolean;
        toastifyError?: boolean;
    };
    sync?: boolean;
};

export type StatelessResponse<Response = any> = {
    success: boolean;
    response?: IBbngResponse<Response>;
    error?: {
        message?: string;
        status: number;
        [k: string]: any;
    };
};

export type ToastifyOptions = {
    toastifySuccess?: boolean;
    toastifyError?: boolean;
};

export type StatelessRequestOptions = {
    onResponse?: (response: StatelessResponse['response'], request: RequestProps) => void;
    onError?: (error: StatelessResponse['error'], request: RequestProps) => void;
} & ToastifyOptions;

export function useRequest({ onResponse, onError, toastifySuccess, toastifyError }: StatelessRequestOptions = {}) {
    const toastify = (type: 'success' | 'error', message?: string, code?: StatusCodes) => {
        toast({
            life     : 10_000,
            severity : type,
            summary  : type === 'success' ? 'Succès de la requête' : 'Échec de la requête',
            detail   :
                (message?.startsWith('NdlssError') ? NdlssError.humanize(message).message : message) ??
                (type === 'success'
                    ? 'Requête effectuée avec succès.'
                    : code
                    ? `Une Erreur code ${code} est survenue.`
                    : 'Une erreur est survenue lors de la requête.')
        });
    };

    const handleRequest = React.useCallback(
        async function <Response, Payload = any>(
            {
                url,
                payload,
                method,
                timeout,
                retryPolling = 200 /* 200 ms by default */,
                options = {},
                sync = true
            }: RequestProps<Payload>,
            httpDirectRequest = false
        ): Promise<StatelessResponse<Response>> {
            options.toastifySuccess ??= toastifySuccess;
            options.toastifyError ??= toastifyError;

            // await wsCommunication.waitUntilReady();

            if (!url || !method) {
                console.warn("Can't handle request, url or method is missing");
                return {
                    success : false,
                    error   : {
                        message : 'url or method is missing',
                        status  : -1
                    }
                };
            }

            const formDataPayload = payload?.headers && payload.headers['Content-Type'] === 'multipart/form-data';

            //Ask for TRX ID
            try {
                if (sync) {
                    return await syncRequest<Response>({
                        method,
                        url,
                        params  : payload?.queryParams,
                        headers : payload?.headers,
                        payload : formDataPayload ? objectToFormData(payload?.body) : sanitizeBody(payload?.body ?? {}),
                        onError : (msg, code) => {
                            if (options.toastifyError) {
                                toastify('error', msg, code);
                            }
                            onError?.(
                                { message: msg, status: code },
                                { url, payload, method, timeout, retryPolling, options, sync }
                            );
                        },
                        onSuccess: (response) => {
                            if (options.toastifySuccess) {
                                toastify('success');
                            }
                            onResponse?.(response, { url, payload, method, timeout, retryPolling, options, sync });
                        }
                    });
                }
                const res: AxiosResponse<IBbngResponse<Response>> = await axiosClient.request({
                    method,
                    url,
                    params  : payload?.queryParams,
                    data    : formDataPayload ? objectToFormData(payload?.body) : sanitizeBody(payload?.body || {}),
                    headers : {
                        ...payload?.headers
                    },
                    timeout: timeout ? timeout : 120_000 /* 2 minutes */
                });

                const trxId = res.headers['x-bbng-trx-id'];

                if (httpDirectRequest) {
                    const { data } = res;

                    if (onResponse) onResponse(data, { method, url, payload, timeout });
                    const type = data.success ? 'success' : 'error';
                    if (
                        (type === 'success' && options.toastifySuccess) ||
                        (type === 'error' && options.toastifyError)
                    ) {
                        toastify(type, data.data.message, data.data.status_code);
                    }

                    return {
                        success  : true,
                        response : data
                    };
                }

                const { success, data } = res.data;

                if (success && trxId && res.status < 300) {
                    try {
                        const resolved: { response: IBbngResponse<Response>; success: boolean } = await new Promise<{
                            response: IBbngResponse<Response>;
                            success: boolean;
                        }>((resolve) => {
                            const inter = setInterval(async () => {
                                const response: AxiosResponse<IBbngResponse<Response>> = await axiosClient({
                                    method : 'GET',
                                    params : {
                                        trxId
                                    },
                                    url: WEBSOCKET_URL + '/v1/websocket/polling'
                                });

                                if (response.status === StatusCodes.OK) {
                                    const { data } = response;
                                    if (onResponse) onResponse(data, { method, url, payload, timeout });
                                    const type = data.success ? 'success' : 'error';
                                    if (
                                        (type === 'success' && options.toastifySuccess) ||
                                        (type === 'error' && options.toastifyError)
                                    ) {
                                        toastify(type, data.data.message, data.data.status_code);
                                    }
                                    clearInterval(inter);
                                    resolve({ response: data, success: data.success });
                                }
                            }, retryPolling);
                        });
                        return resolved;
                    } catch (err) {
                        console.error(url, err);
                        return {
                            success : false,
                            error   : {
                                message : err.message,
                                status  : err.status_code
                            }
                        };
                    }
                } else {
                    const error: StatelessResponse['error'] = {
                        message : data.message,
                        status  : data.status_code
                    };

                    if (onError) onError(error, { method, url, payload, timeout });
                    if (options.toastifyError) toastify('error', error?.message, error?.status);

                    return {
                        success: false,
                        error
                    };
                }
            } catch (err) {
                if (err?.response) {
                    const response = err?.response as AxiosResponse<IBbngResponse<Response>>;
                    toastify('error', response?.data?.data?.message, response?.data?.data?.status_code);
                }

                return {
                    success : false,
                    error   : {
                        message : 'An error has occured',
                        status  : StatusCodes.INTERNAL_SERVER_ERROR
                    }
                };
            }
            return {
                success : false,
                error   : {
                    message : 'An error has occured',
                    status  : StatusCodes.INTERNAL_SERVER_ERROR
                }
            };
        },
        [onResponse, onError, toastifySuccess, toastifyError]
    );

    return handleRequest;
}
