import * as yup from 'yup';

import { isSIREN } from '@bbng/util/misc';

import { textInputProps } from '.';
import { MaskInput } from './Mask';

export function SirenInput({
    readOnly,
    required,
    result,
    id,
    validationSchema,
    value = '',
    errors,
    displayError
}: textInputProps) {
    const baseValidationSchema = yup.string().test({
        name    : 'siren',
        message : "Ce Siren n'est pas valide.",
        test    : (value) => (value ? isSIREN(value) : false)
    });
    const schema = validationSchema ? validationSchema : baseValidationSchema;

    return (
        <MaskInput
            label="Siren"
            readOnly={readOnly}
            mask="999 999 999"
            id={id}
            value={value}
            size={12}
            required={required}
            errors={errors}
            result={result}
            displayError={displayError}
            validationSchema={schema}
        />
    );
}
