import { InputMask, InputMaskChangeParams } from 'primereact/inputmask';
import React from 'react';
import * as yup from 'yup';

import { genericMaskInputProps } from '.';
import { useComponentInput } from '../../hooks/ComponentInput';

export function MaskInput({
    label,
    readOnly,
    required,
    result,
    id,
    validationSchema,
    value = '',
    errors,
    displayError,
    mask,
    maxLength,
    size
}: genericMaskInputProps) {
    const baseValidationSchema = yup.string();
    const schema = validationSchema
        ? validationSchema
        : required
        ? baseValidationSchema.required(`${label} requis`)
        : baseValidationSchema;

    const { handleBlur, val, err, displayErr, setVal, setErr } = useComponentInput({
        id,
        value,
        errors,
        displayError        : displayError ?? false,
        result,
        required,
        schema,
        didRevalidateSchema : (e) => e.length > 0,
        mapValue            : (e) => e.value
    });

    /**
     * Custom handleOnChange to prevent blur event from being triggered
     */
    const handleOnChange = React.useCallback(
        (e: InputMaskChangeParams): void => {
            if (e.originalEvent.type === 'blur') return;
            setVal(e.value);
            setErr(null);
            result(e.value, null, id);
        },
        [setVal, setErr, result, id]
    );

    return (
        <span className="p-float-label">
            <InputMask
                maxLength={maxLength}
                disabled={readOnly}
                type="text"
                mask={mask}
                name={id}
                className={`p-inputtext-sm ${displayErr && err && err.length > 0 ? 'p-invalid' : ''}`}
                id={id}
                onChange={handleOnChange}
                onBlur={handleBlur}
                value={val}
                tooltip={displayErr && err && err.length > 0 ? err.join('\n') : ''}
                size={size}
            />
            <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>
        </span>
    );
}
