import Dinero from 'dinero.js';
import React from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import styled from 'styled-components';
import { Dropdown } from 'primereact/dropdown';

import {
    BillingBranch,
    ETrashType,
    PRODUCT_FAMILY,
    ProductQuery,
    ProductRo,
    ZoneRo,
    EProductBillingMode,
    EPlanningType,
    PRODUCT_DUMPSTER_TYPE
} from '@bbng/util/types';

import {
    mapDumpsterTypeText,
    mapDumpsterTypeToHexa,
    mapProductBillingMode,
    mapProductFamilyToString,
    mapTrashTypetext
} from '../../common/enumMapper';
import { PRODUCT_ORCHESTRATION_BASE_URL, urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import Input from '../../components/Inputs';
import { Listing } from '../../components/Layout';
import { useRequest } from '../../hooks/StatelessRequest';
import { Tag } from 'primereact/tag';
import { MenuItemCommandParams } from 'primereact/menuitem';
import { PageLoader } from '../../components/PageLoader';
import useQuery from '../../hooks/Query';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Button from '../../components/Button';

const BillingBranchOptions = [
    { name: 'Endless 1', value: BillingBranch.ENDLESS_1 },
    { name: 'Endless 2', value: BillingBranch.ENDLESS_2 },
    { name: 'Endless 3', value: BillingBranch.ENDLESS_3 }
];

const BillingModeOptions = Object.values(EProductBillingMode).map((billingMode) => ({
    name  : mapProductBillingMode(billingMode),
    value : billingMode
}));

const TrashtrashTypeOptions = Object.values(ETrashType).map((trashType) => ({
    name  : mapTrashTypetext(trashType),
    value : trashType
}));

const FamilyOptions = Object.values(PRODUCT_FAMILY).map((family) => ({
    name  : mapProductFamilyToString(family),
    value : family
}));

const DumpsterTypeOptions = Object.values(PRODUCT_DUMPSTER_TYPE).map((type) => ({
    name  : mapDumpsterTypeText(type),
    value : type
}));

const StyledDropdown = styled(Dropdown)`
    width: 300px;
    height: 44px;
`;

const FilterColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const FilterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

type QueryParams = {
    billing_branch?: BillingBranch;
    zone_id?: string;
    trashType?: ETrashType;
    family?: PRODUCT_FAMILY;
    billing_mode?: EProductBillingMode;
    publicOnly?: boolean;
    internalOnly?: boolean;
    operationalOnly?: boolean;
    dumpster_type?: PRODUCT_DUMPSTER_TYPE;
};

export const ProductListing: React.FC = (): JSX.Element => {
    const bbngRequest = useRequest({
        toastifyError: true
    });
    const { query } = useQuery<QueryParams>();
    const [billingBranch, setBillingBranch] = React.useState<BillingBranch | undefined>(query.billing_branch);
    const [billingMode, setBillingMode] = React.useState<EProductBillingMode | undefined>(query.billing_mode);
    const [trashType, setTrashType] = React.useState<ETrashType | undefined>(query.trashType);
    const [family, setFamily] = React.useState<PRODUCT_FAMILY | undefined>(query.family);
    const [zone_id, setZoneId] = React.useState<string | undefined>(query.zone_id);
    const [publicOnly, setPublicOnly] = React.useState<boolean>(query.publicOnly ?? false);
    const [internalOnly, setInternalOnly] = React.useState<boolean>(query.internalOnly ?? false);
    const [operationalOnly, setOperationalOnly] = React.useState<boolean>(query.operationalOnly ?? false);
    const [dumpsterType, setDumpsterType] = React.useState<PRODUCT_DUMPSTER_TYPE | undefined>(query.dumpster_type);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [triggerPageRefresh, setTriggerPageRefresh] = React.useState<boolean>(false);
    React.useEffect(() => {
        setTriggerPageRefresh(false);
    }, [triggerPageRefresh]);

    const ProductImage = ({ id }: { id: string }) => {
        const [url, setUrl] = React.useState<string>('');
        const [loading, setLoading] = React.useState<boolean>(true);

        React.useEffect(() => {
            fetchProductImage();
            setLoading(false);
        }, []);

        const fetchProductImage = async () => {
            const result = await bbngRequest<{ url: string }>({
                method : 'GET',
                url    : urlApiBuilder.documentGetSignedUrl(id)
            });
            if (!result.response?.data.ro) {
                setUrl('https://via.placeholder.com/150');
            } else {
                setUrl(String(result.response.data.ro));
            }
        };

        return loading ? <p>Chargement...</p> : <img src={url} style={{ height: 100, width: 100 }} alt="product" />;
    };

    const handleActionPublish = async (e: MenuItemCommandParams, visibility: boolean) => {
        const index = e.item['rowInfo']['rowIndex'];
        const product = e.item['rowInfo']['props']['value'][index] as ProductRo;

        confirmDialog({
            header  : visibility ? 'Publier le produit' : 'Dépublier le produit',
            message : `Êtes-vous sûr de vouloir ${visibility ? 'publier' : 'dépublier'} le produit ${product.name} ?`,
            accept  : async () => {
                setLoading(true);
                await bbngRequest<ProductRo>({
                    method  : 'PATCH',
                    url     : urlApiBuilder.productSetVisibility(product?.id),
                    payload : {
                        body: {
                            visibility
                        }
                    }
                });
                setLoading(false);

                setTriggerPageRefresh(true);
            },
            acceptLabel : 'Confirmer',
            rejectLabel : 'Annuler'
        });
    };

    if (loading) {
        return <PageLoader loading actionType="create" />;
    }

    return (
        <>
            <Listing<any, ProductRo>
                url={PRODUCT_ORCHESTRATION_BASE_URL}
                endpoint="products"
                name="Produit"
                addButtonUrl={urlBuilder.productCreate()}
                pluralName="Produits"
                searchPlaceholder="Rechercher par nom, volume"
                hideBulkMenu
                displaySelectColumn={false}
                triggerPageRefresh={triggerPageRefresh}
                queryParams={
                    {
                        billing_branch : billingBranch,
                        billing_modes  : billingMode ? [billingMode] : undefined,
                        trash_type     : trashType,
                        zone_id,
                        family,
                        internal       : publicOnly ? false : internalOnly ? true : undefined,
                        operational    : operationalOnly ? true : undefined,
                        dumpster_type  : dumpsterType
                    } as ProductQuery
                }
                leftHandedComponent={
                    <StyledAccordion>
                        <AccordionTab
                            header={
                                <TitleContainer>
                                    <StyleSpan>Filtres</StyleSpan>
                                </TitleContainer>
                            }
                        >
                            <FilterColumnContainer>
                                <FilterContainer>
                                    <StyledDropdown
                                        value={billingBranch}
                                        onChange={(e) => setBillingBranch(e.value)}
                                        placeholder="Filtrer par entité de facturation"
                                        optionValue="value"
                                        optionLabel="name"
                                        showClear
                                        options={BillingBranchOptions}
                                    />
                                    <StyledDropdown
                                        value={billingMode}
                                        onChange={(e) => setBillingMode(e.value)}
                                        placeholder="Filtrer par mode de facturation éligible"
                                        optionValue="value"
                                        optionLabel="name"
                                        showClear
                                        options={BillingModeOptions}
                                    />
                                    <StyledDropdown
                                        value={trashType}
                                        onChange={(e) => setTrashType(e.value)}
                                        placeholder="Filtrer par déchet"
                                        optionValue="value"
                                        optionLabel="name"
                                        showClear
                                        options={TrashtrashTypeOptions}
                                    />
                                    <StyledDropdown
                                        value={family}
                                        onChange={(e) => setFamily(e.value)}
                                        placeholder="Filtrer par famille"
                                        optionValue="value"
                                        optionLabel="name"
                                        showClear
                                        options={FamilyOptions}
                                    />
                                    <StyledDropdown
                                        value={dumpsterType}
                                        onChange={(e) => setDumpsterType(e.value)}
                                        placeholder="Filtrer par type de benne"
                                        optionValue="value"
                                        optionLabel="name"
                                        showClear
                                        options={DumpsterTypeOptions}
                                    />
                                    <RelationAutocomplete.Zone
                                        placeholder="Filtrer par zone"
                                        onSelect={(zone) => setZoneId(zone?.id)}
                                        onUnselect={() => setZoneId(undefined)}
                                    />
                                </FilterContainer>
                                <FilterContainer>
                                    <Button.Checkbox
                                        id="publicOnly"
                                        value={publicOnly}
                                        result={(value) => setPublicOnly(value)}
                                        label="Produits publics uniquement"
                                        labelPosition="right"
                                        readOnly={false}
                                    />
                                    <Button.Checkbox
                                        id="internalOnly"
                                        value={internalOnly}
                                        result={(value) => setInternalOnly(value)}
                                        label="Produits internes uniquement"
                                        labelPosition="right"
                                        readOnly={false}
                                    />
                                    <Button.Checkbox
                                        id="operationalOnly"
                                        value={operationalOnly}
                                        result={(value) => setOperationalOnly(value)}
                                        label="Produits opérationnels uniquement"
                                        labelPosition="right"
                                        readOnly={false}
                                    />
                                </FilterContainer>
                            </FilterColumnContainer>
                        </AccordionTab>
                    </StyledAccordion>
                }
                actionRows={[
                    {
                        label   : 'Publier le produit',
                        icon    : 'pi pi-send',
                        command : (e) => handleActionPublish(e, true)
                    },
                    {
                        label   : 'Dépublier le produit',
                        icon    : 'pi pi-times',
                        command : (e) => handleActionPublish(e, false)
                    }
                ]}
                enrichData={async (data) => {
                    if (!data.data.ro) return data;

                    const zone_ids = data.data.ro.reduce((acc, product) => {
                        product.zone_id.forEach((zone_id: string) => {
                            acc.add(zone_id);
                        });
                        return acc;
                    }, new Set<string>());

                    const result = await bbngRequest<ZoneRo[]>({
                        method  : 'POST',
                        url     : urlApiBuilder.zoneGetMany(),
                        payload : {
                            body: {
                                ids: Array.from(zone_ids)
                            }
                        }
                    });

                    if (!result.response?.data.ro) return data;
                    data.data.ro?.map((product: ProductRo) => {
                        if (!product.zone_id) return (product.zone_id = []);
                        if (!result.response?.data.ro) return (product.zone_id = []);
                        const zones: ZoneRo[] = result.response.data.ro;

                        return (product.zone_id = zones
                            .filter((zone: ZoneRo) => product.zone_id.includes(zone.id))
                            .map((zone: ZoneRo) => zone.name));
                    });
                    return data;
                }}
                displayBulkArchiveAction={false}
                headers={[
                    {
                        name      : 'Photo',
                        field     : 'main_photo',
                        component : (data: ProductRo) =>
                            data.main_photo ? <ProductImage id={data.main_photo} /> : <p>❌</p>
                    },
                    {
                        name      : 'Nom (famille)',
                        field     : 'id',
                        component : (data: ProductRo) => {
                            return (
                                <NameContainer>
                                    <a href={urlBuilder.productView(data.id)}>{data.name}</a>
                                    <Tag
                                        className="mr-2"
                                        severity={data.family?.includes(EPlanningType.BIG_BAG) ? 'success' : 'info'}
                                        value={mapProductFamilyToString(data.family)}
                                    />
                                    {data.dumpster_type?.length > 0 &&
                                        data.dumpster_type.map((dumpsterType) => (
                                            <Tag style={{ backgroundColor: mapDumpsterTypeToHexa(dumpsterType) }}>
                                                {mapDumpsterTypeText(dumpsterType)}
                                            </Tag>
                                        ))}
                                </NameContainer>
                            );
                        }
                    },
                    {
                        name      : 'volume m3',
                        field     : 'volume_m3',
                        width     : 100,
                        component : (data: ProductRo) => <p>{data.volume_m3}</p>
                    },
                    {
                        name      : 'Type de déchet',
                        field     : 'trash_type',
                        component : (data: ProductRo) => <p>{mapTrashTypetext(data.trash_type)}</p>
                    },
                    {
                        name      : 'Entité de facturation',
                        field     : 'billing_branch',
                        component : (data: ProductRo) => <p>{data.billing_branch?.replace('_', ' ')}</p>
                    },
                    {
                        name      : 'Mode de facturation éligibles',
                        field     : 'billing_modes',
                        component : (data: ProductRo) => {
                            return (
                                <>
                                    {data.billing_modes.map((billingMode) => (
                                        <Tag style={{ marginBottom: '4px' }}>{mapProductBillingMode(billingMode)}</Tag>
                                    ))}
                                </>
                            );
                        }
                    },
                    {
                        name      : 'Zone(s)',
                        field     : 'zone_id',
                        component : (data: ProductRo) => (
                            <Input.Chips
                                id="zone_id"
                                required={true}
                                readOnly={true}
                                result={(val, err, displayError) => {
                                    return;
                                }}
                                value={data.zone_id.length > 0 ? data.zone_id : ['Pas de Zone']}
                                errors={data.zone_id}
                            />
                        )
                    },
                    {
                        name      : 'Prix',
                        field     : 'price',
                        width     : 100,
                        component : (data: ProductRo) => (
                            <p>
                                {Dinero({
                                    amount   : data.price.net_amount_cents,
                                    currency : data.price.currency
                                }).toFormat('$0,0.00')}
                            </p>
                        )
                    },
                    {
                        name      : 'Vente',
                        field     : 'internal',
                        component : (data: ProductRo) =>
                            data.internal ? <Tag style={{ backgroundColor: 'orange' }}>Interne</Tag> : <Tag>Public</Tag>
                    },
                    {
                        name      : 'Ops',
                        field     : 'operational',
                        component : (data: ProductRo) =>
                            data.operational ? (
                                <Tag icon="pi pi-cog" style={{ backgroundColor: 'orange' }}>
                                    Pour l'opérationnel {data.is_default_empty_run ? '(PAV par défaut)' : undefined}
                                </Tag>
                            ) : (
                                <Tag>Non</Tag>
                            )
                    }
                ]}
            />
            <ConfirmDialog />
        </>
    );
};

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const StyleSpan = styled.span`
    padding: 12px;
    width: 100%;
    font-size: 1em;
    display: flex;
    align-items: center;
`;

const StyledAccordion = styled(Accordion)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    & .p-accordion {
        width: 100%;
    }
    & .p-accordion-header-link {
        padding: 0 1.25em !important;
    }
    & .p-accordion-content {
        background: transparent;
        display: flex;
        flex-wrap: wrap;
        padding: 8px;
    }
    & .p-accordion-tab {
        width: 100%;
        background: transparent;
        margin: 0;
    }
`;

const NameContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    flex-direction: column;
`;
