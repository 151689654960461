import { TagSeverityType } from 'primereact/tag';
import styled from 'styled-components';

import { EZohoInvoiceStatus } from '@bbng/util/types';

export const Header = styled.div`
    margin: 0 10%;
    display: flex;
    justify-content: space-evenly;
`;

export const Page = styled.div`
    margin: 16px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    & > * {
        flex: 1;
    }
    gap: 16px;
    & h1 {
        margin: 0;
    }
`;

export const InvoicePlaceholder = styled.div`
    margin: auto;
    display: flex;
    gap: 32px;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
`;

export const ButtonWithDetail = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    al
`;

export const CenterSpan = styled.span`
    display: flex;
    gap: 4px;
    align-items: center;
`;

export const StyledTable = styled.table`
    border-spacing: 8px;
    text-align: center;
`;

export const CustomerWithoutMailModal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    .p-confirm-dialog-reject {
        display: none;
    }
`;

export const mapStatusToIcon = (status?: EZohoInvoiceStatus): string => {
    switch (status) {
        case EZohoInvoiceStatus.PAID:
            return 'pi pi-check';
        case EZohoInvoiceStatus.DRAFT:
            return 'pi pi-pencil';
        case EZohoInvoiceStatus.OVERDUE:
        case EZohoInvoiceStatus.PARTIALLY_PAID:
            return 'pi pi-exclamation-triangle';
        case EZohoInvoiceStatus.UNPAID:
            return 'pi pi-clock';
        case EZohoInvoiceStatus.SENDING:
            return 'pi pi-send';
        case EZohoInvoiceStatus.SENT:
            return 'pi pi-envelope';
        case EZohoInvoiceStatus.VIEWED:
            return 'pi pi-eye';
        case EZohoInvoiceStatus.VOID:
            return 'pi pi-times';
        default:
            return '';
    }
};

export const mapStatusToSeverity = (status: EZohoInvoiceStatus): TagSeverityType => {
    switch (status) {
        case EZohoInvoiceStatus.PAID:
            return 'success';
        case EZohoInvoiceStatus.DRAFT:
            return 'info';
        case EZohoInvoiceStatus.OVERDUE:
        case EZohoInvoiceStatus.PARTIALLY_PAID:
        case EZohoInvoiceStatus.UNPAID:
        case EZohoInvoiceStatus.VOID:
            return 'danger';
        case EZohoInvoiceStatus.SENT:
        case EZohoInvoiceStatus.SENDING:
            return 'primary';
        case EZohoInvoiceStatus.VIEWED:
            return 'warning';
        default:
            return 'info';
    }
};
