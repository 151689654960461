import Dinero from 'dinero.js';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import { Tag } from 'primereact/tag';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { calculatePrice, formatPhoneNumber } from '@bbng/util/misc';
import {
    AdminRo,
    CCRo,
    CCServiceRoFront,
    CC_FAMILY,
    CC_STATUS,
    DiscountQuery,
    DiscountRo,
    ECollectHazardReason,
    ECustomerOrigin,
    HistoryRo,
    NdlssPrice,
    OrderRo,
    OrderRoFront,
    PriceObject,
    ProductInCCOrCO,
    UserRo
} from '@bbng/util/types';

import {
    mapBillingMode,
    mapCollectConfigFamilyText,
    mapCollectConfigStatus,
    mapCollectHazardReason,
    mapCustomerOrigin,
    mapTrashTypetext
} from '../../common/enumMapper';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { AddressMapButton } from '../../components/AddressDisplayButton';
import { Card } from '../../components/Card';
import { useRequest } from '../../hooks/StatelessRequest';
import { mapStatusToSeverity } from '../../pages/CollectConfigs';
import { OrderFormState } from '../../pages/OrderForm/helpers';
import { AddressState } from '../common/Address';
import { OrderDocuments } from './Documents';
import { OrderSheetEdit } from './OrderSheetEdit';
import { ProductRoWithQuantity } from './Products';
import { EOrderTypeForm } from './Type';
import { isCC } from '../planning/shifts/manual-shift/helpers';
import CollectConfigCancelForm from '../collect-config-cancel-form';

export type OrderOverviewProps = {
    values?: OrderFormState;
    dbValues?: OrderRoFront;
    admin?: AdminRo;
    refetch: (id: string) => void;
    isDelivery?: boolean;
};

export const OrderOverview: React.FC<OrderOverviewProps> = ({
    values,
    dbValues,
    admin,
    refetch,
    isDelivery
}: OrderOverviewProps) => {
    const [discountsDb, setDiscountsDb] = React.useState<DiscountRo[]>([]);
    const [priceLoading, setPriceLoading] = React.useState(false);
    const [frontPrice, setFrontPrice] = React.useState<NdlssPrice | null>(null);
    const [editDocumentModalIsOpen, setEditDocumentModalIsOpen] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const orderContainsCollects = values?.type.type !== EOrderTypeForm.DELIVERY;

    const hasInvoice = useMemo(() => {
        return dbValues?.collect_config_id.some((cc) => (cc as CCServiceRoFront)?.invoice_id?.length > 0);
    }, [dbValues]);

    const user = useMemo(() => {
        if (dbValues?.user_id && dbValues.user_id.length > 0) {
            return dbValues?.user_id[0] as UserRo | undefined;
        } else {
            return values?.customer?.user as UserRo | undefined;
        }
    }, [dbValues, values]);

    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    /**
     * Handle canceled order related information.
     */
    const [canceledDetails, setCanceledDetails] = React.useState<string>();
    const [canceledByLoading, setCanceledByLoading] = React.useState<boolean>(false);
    const cc = useMemo(() => {
        if (dbValues?.collect_config_id?.length) {
            if (isCC(dbValues?.collect_config_id[0] as any)) {
                return dbValues.collect_config_id[0] as CCRo;
            }
        }
        return undefined;
    }, [dbValues]);
    const isCanceled = useMemo(() => {
        if (cc) {
            return cc.status === CC_STATUS.CANCELED;
        }
        return false;
    }, [cc]);
    const isDone = useMemo(() => {
        if (cc) {
            return [CC_STATUS.FINISHED, CC_STATUS.HAZARD].includes(cc.status as any);
        }
        return false;
    }, [cc]);

    /**
     * To know when and who canceled the order, we need to fetch the history of the associated collect config.
     * Once done, we look for the update containing canceled status and extract the details.
     */
    const fetchAndSetCanceledBy = useCallback(() => {
        if (cc && isCanceled) {
            if (cc?.status === CC_STATUS.CANCELED) {
                setCanceledByLoading(true);
                bbngRequest<HistoryRo>({
                    method : 'GET',
                    url    : urlApiBuilder.collectConfigHistory(cc.id)
                })
                    .then((res) => {
                        if (res.success) {
                            const ro = res.response?.data?.ro;
                            if (ro) {
                                const canceledData = ro.updated_by.find((elem) =>
                                    JSON.stringify(elem).includes(CC_STATUS.CANCELED)
                                );
                                if (canceledData) {
                                    const details = `Le ${moment(canceledData?.at).format('DD/MM/YYYY à HH:mm')} par ${
                                        canceledData?.by
                                    }`;
                                    setCanceledDetails(details);
                                }
                            }
                        }
                    })
                    .finally(() => setCanceledByLoading(false));
            }
        }
    }, [cc]);
    useEffect(() => {
        if (dbValues) {
            fetchAndSetCanceledBy();
        }
    }, [dbValues]);

    const fetchDiscounts = useCallback(async () => {
        setPriceLoading(true);
        const response = await bbngRequest<DiscountRo[]>({
            method  : 'GET',
            url     : urlApiBuilder.discountGetAll(),
            payload : {
                queryParams: {
                    customer_id     : values?.customer?.customer?.id,
                    include_expired : true,
                    no_limit        : true,
                    no_archived     : true
                } as DiscountQuery
            },
            options: {
                toastifySuccess: false
            }
        });
        if (response.success && response.response?.data.ro) {
            setDiscountsDb(response.response.data.ro);
        }
        setPriceLoading(false);
    }, [values, setDiscountsDb, setPriceLoading]);

    useEffect(() => {
        if (!dbValues && values?.customer?.customer) {
            fetchDiscounts();
        }
    }, [values?.customer.customer]);

    const calculateDiscountedPrice = useCallback(() => {
        if (!values) return;
        setPriceLoading(true);
        if (
            values.customer?.customer &&
            (values.constructionSite?.constructionSite || values.deliveryAddress) &&
            Object.keys(values.products).length > 0 &&
            values.collectInfo?.collect_day &&
            !dbValues &&
            values.zone?.zone
        ) {
            const price = calculatePrice({
                customerId         : values.customer?.customer.id,
                date               : values.collectInfo.collect_day,
                products           : Object.values(values.products) as unknown as ProductInCCOrCO[],
                zoneId             : values.zone.zone.id,
                constructionSiteId : values.constructionSite?.constructionSite?.id,
                discounts          : discountsDb
            });
            setFrontPrice(price);
        }
        setPriceLoading(false);
    }, [values, discountsDb, setFrontPrice, setPriceLoading]);

    useEffect(() => {
        if (!dbValues) {
            calculateDiscountedPrice();
        }
    }, [dbValues, discountsDb, values?.constructionSite, values?.products, values?.collectInfo?.collect_day]);

    return (
        <StyledCard title="Récapitulatif">
            <GroupContainer>
                <div>
                    <h3>Client</h3>
                    <ColumnData>
                        <LargeText>
                            {values?.customer.customer?.name}{' '}
                            <Chip label={values?.customer.customer?.membership as string} />{' '}
                            {values?.customer.customer?.origin && (
                                <Chip label={mapCustomerOrigin(values?.customer.customer?.origin as ECustomerOrigin)} />
                            )}
                        </LargeText>
                        {values?.customer.customer?.created_at && (
                            <LightText>
                                Client depuis: {moment(values?.customer.customer?.created_at).format('DD/MM/YYYY')}
                            </LightText>
                        )}
                        {admin && <LightText>Responsable associé: {admin.fullname}</LightText>}
                        {<LightText>Paiement: {mapBillingMode(values?.customer?.customer?.billing_mode)}</LightText>}
                        {user && (
                            <LightText>
                                Utilisateur associé: {user.fullname} ({user.email})
                            </LightText>
                        )}
                        {cc &&
                            dbValues &&
                            (isCanceled ? (
                                <LightText>
                                    <CanceledByContainer>
                                        Annulée:
                                        {canceledByLoading ? (
                                            <Skeleton height="12px" width="64px" />
                                        ) : (
                                            <span>{canceledDetails}</span>
                                        )}
                                    </CanceledByContainer>
                                </LightText>
                            ) : (
                                !isDone && <CancelDialog cc={cc} order={dbValues as OrderRo} />
                            ))}
                    </ColumnData>
                </div>
                {orderContainsCollects && (
                    <>
                        <Divider layout="vertical" />
                        <div>
                            <h3>Chantier</h3>
                            <ColumnData>
                                <AddressContainer>
                                    <div>
                                        <AddressMapButton
                                            lat={
                                                values?.constructionSite?.constructionSite?.address?.coordinates
                                                    .latitude || 0
                                            }
                                            lng={
                                                values?.constructionSite?.constructionSite?.address?.coordinates
                                                    .longitude || 0
                                            }
                                        />
                                    </div>
                                    <ColumnData>
                                        <LightText>{values?.constructionSite?.constructionSite?.label}</LightText>
                                        <MediumText>
                                            {values?.constructionSite?.constructionSite?.address?.formatted_name}
                                        </MediumText>
                                        <MediumText>
                                            Zone:{' '}
                                            {values?.zone?.zone
                                                ? `${values?.zone?.zone?.metropole} - ${values?.zone?.zone?.name}`
                                                : 'non renseignée'}
                                        </MediumText>
                                    </ColumnData>
                                </AddressContainer>
                            </ColumnData>
                        </div>
                    </>
                )}
            </GroupContainer>
            <Divider />
            <GroupContainer>
                <LargeText>Commentaire</LargeText>
                <MediumText>{values?.misc?.comment !== '' ? values?.misc?.comment : 'Non renseigné'}</MediumText>
            </GroupContainer>
            <Divider />
            {dbValues && (
                <>
                    <OrderDocuments value={values?.documents} readOnly={true} id="documents" result={() => void 0} />
                    <Button
                        label="Modifier les documents administratifs"
                        disabled={false} // order sheet can always be edited
                        onClick={() => setEditDocumentModalIsOpen(true)}
                    />
                    <Dialog
                        header="Modifier les documents administratifs"
                        visible={editDocumentModalIsOpen}
                        onHide={() => setEditDocumentModalIsOpen(false)}
                    >
                        <OrderSheetEdit
                            customer={values?.customer.customer}
                            documentState={values?.documents}
                            orderId={dbValues.id}
                            setModalState={setEditDocumentModalIsOpen}
                            refetch={refetch}
                            hasInvoice={hasInvoice}
                        />
                    </Dialog>
                </>
            )}
            {orderContainsCollects && (
                <div>
                    <h3>Contacts chantier et logistique/bureau</h3>
                    <DataTable
                        value={[...(values?.contacts.construction_site || []), ...(values?.contacts.logistic || [])]}
                        emptyMessage="Aucun contact"
                    >
                        <Column field="firstname" header="Prénom" />
                        <Column field="lastname" header="Nom" />
                        <Column
                            header="Télephone"
                            field="phone_number"
                            body={(e) => <div>{formatPhoneNumber(e.phone_number)}</div>}
                        />
                    </DataTable>
                </div>
            )}
            {!orderContainsCollects && values.deliveryAddress && (
                <div>
                    <h3>Adresse de livraison</h3>
                    <ColumnData>
                        <AddressContainer>
                            <div>
                                <AddressMapButton
                                    lat={values.deliveryAddress.address_lat || 0}
                                    lng={values.deliveryAddress.address_lng || 0}
                                />
                            </div>
                            <ColumnData>
                                <MediumText>
                                    {values.deliveryAddress
                                        ? displayAddress(values.deliveryAddress)
                                        : 'Aucune adresse de livraison'}
                                </MediumText>
                                <MediumText>
                                    Zone:{' '}
                                    {values?.zone?.zone
                                        ? `${values?.zone?.zone?.metropole} - ${values?.zone?.zone?.name}`
                                        : 'non renseignée'}
                                </MediumText>
                            </ColumnData>
                        </AddressContainer>
                    </ColumnData>
                </div>
            )}
            <div>
                <h3>Produits</h3>
                {dbValues ? (
                    dbValues.collect_config_id.map((collectConfig, idx) => {
                        const castedCollectConfig = collectConfig as CCServiceRoFront;
                        const collect = castedCollectConfig.collect;
                        const emptyingId = collect?.informations?.emptied_at_landfill_id as string;
                        return (
                            <React.Fragment key={castedCollectConfig.id}>
                                <StyledH4>
                                    <Tag
                                        value={mapCollectConfigStatus(castedCollectConfig.status)}
                                        severity={mapStatusToSeverity(castedCollectConfig.status)}
                                    />
                                    Collecte {castedCollectConfig.number}
                                    <Button
                                        label="Editer"
                                        icon="pi pi-pencil"
                                        className="p-button-sm p-button-text"
                                        onClick={() => navigate(urlBuilder.collectConfigEdit(castedCollectConfig.id))}
                                        disabled={!!collect}
                                    />
                                </StyledH4>
                                <CollectContainer>
                                    <CollectDiv>
                                        <StyledH4>Demandée</StyledH4>
                                        <StyledDescription>
                                            {castedCollectConfig.status === CC_STATUS.WAITING_FOR_APPROVAL ? (
                                                <Chip label="En attente de validation" />
                                            ) : (
                                                <i>
                                                    Pour le{' '}
                                                    {moment(castedCollectConfig?.from_date).format('dddd DD MMMM YYYY')}
                                                    , entre {moment(castedCollectConfig?.from_date).format('HH:mm')} et{' '}
                                                    {moment(castedCollectConfig?.to_date).format('HH:mm')}
                                                </i>
                                            )}
                                            {castedCollectConfig.already_available_date && (
                                                <i>
                                                    (Déjà disponible le{' '}
                                                    {moment(
                                                        castedCollectConfig?.already_available_date.from_date
                                                    ).format('dddd DD MMMM YYYY')}
                                                    , entre{' '}
                                                    {moment(
                                                        castedCollectConfig?.already_available_date.from_date
                                                    ).format('HH:mm')}{' '}
                                                    et{' '}
                                                    {moment(castedCollectConfig?.already_available_date.to_date).format(
                                                        'HH:mm'
                                                    )}
                                                    )
                                                </i>
                                            )}
                                        </StyledDescription>
                                        {castedCollectConfig.family === CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT &&
                                            castedCollectConfig.retrieval_date && (
                                                <StyledDescription>
                                                    <i>
                                                        <b>Retrait estimé </b> le{' '}
                                                        {moment(castedCollectConfig?.retrieval_date).format(
                                                            'dddd DD MMMM YYYY'
                                                        )}
                                                    </i>
                                                </StyledDescription>
                                            )}
                                        <DataTable value={castedCollectConfig.products} emptyMessage="Aucun produit">
                                            <Column field="quantity" header="Quantité" />
                                            <Column header="Résumé" body={ProductOverviewTemplate} />
                                            <Column header="Prix de base (HT)" body={TotalPriceColumnTemplate} />
                                        </DataTable>
                                        {PriceContainer(castedCollectConfig.price)}
                                    </CollectDiv>
                                    <CollectDiv>
                                        {collect ? (
                                            <>
                                                <div>
                                                    <StyledH4>
                                                        Réalisée
                                                        <Button
                                                            label="Voir en détail"
                                                            icon="pi pi-eye"
                                                            className="p-button-sm p-button-text"
                                                            onClick={() => navigate(urlBuilder.collectView(collect.id))}
                                                            disabled={!collect}
                                                        />
                                                    </StyledH4>
                                                </div>
                                                <StyledDescription>
                                                    <i>
                                                        Le {moment(collect?.arrived_at).format('dddd DD MMMM YYYY')},
                                                        entre {moment(collect?.arrived_at).format('HH:mm')} et{' '}
                                                        {moment(collect?.completed_at).format('HH:mm')}
                                                    </i>
                                                </StyledDescription>
                                                {collect.status === CC_STATUS.FINISHED ? (
                                                    <DoneContainer>
                                                        <DataTable
                                                            value={collect.informations?.collected_items}
                                                            emptyMessage="Aucun produit"
                                                        >
                                                            <Column field="quantity" header="Quantité" />
                                                            <Column header="Résumé" body={ProductOverviewTemplate} />
                                                            <Column
                                                                header="Prix de base (HT)"
                                                                body={TotalPriceColumnTemplate}
                                                            />
                                                        </DataTable>
                                                        {collect.informations?.price &&
                                                            PriceContainer(collect.informations.price)}
                                                        {emptyingId ? (
                                                            <a
                                                                href={urlBuilder.landfillView(emptyingId)}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                Evacuée ici (cliquer pour accéder au CDT)
                                                            </a>
                                                        ) : (
                                                            <span>Non évacuée pour le moment</span>
                                                        )}
                                                    </DoneContainer>
                                                ) : (
                                                    <DoneContainer>
                                                        <div>
                                                            <LargeText>
                                                                {mapCollectHazardReason(
                                                                    collect.hazard_reason as ECollectHazardReason
                                                                )}
                                                            </LargeText>
                                                        </div>
                                                        <div>
                                                            <MediumText>{collect.hazard_comment}</MediumText>
                                                        </div>
                                                    </DoneContainer>
                                                )}
                                            </>
                                        ) : (
                                            <StyledH4>Non terminée pour le moment.</StyledH4>
                                        )}
                                    </CollectDiv>
                                </CollectContainer>
                                {idx < dbValues.collect_config_id.length - 1 && <Divider />}
                            </React.Fragment>
                        );
                    })
                ) : (
                    <>
                        <DataTable
                            value={
                                values?.products
                                    ? Object.values(values?.products).filter((product) => product.quantity > 0)
                                    : []
                            }
                            emptyMessage="Aucun produit ajouté"
                        >
                            <Column field="quantity" header="Quantité" />
                            <Column header="Résumé" body={ProductOverviewTemplate} />
                            <Column header="Prix de base (HT)" body={TotalPriceColumnTemplate} />
                        </DataTable>
                        <OrderCreatePriceWrapper>
                            {priceLoading || frontPrice ? (
                                PriceContainer(frontPrice, priceLoading)
                            ) : (
                                <span>
                                    Complétez les informations de la collecte pour obtenir le prix incluant les remises.
                                </span>
                            )}
                        </OrderCreatePriceWrapper>
                    </>
                )}
            </div>
        </StyledCard>
    );
};

const displayAddress = (address: AddressState) => {
    return `${address.address_street_number} ${address.address_street_name}, ${address.address_zip_code} ${address.address_city}`;
};

export const ProductOverviewTemplate = (rowData: ProductRoWithQuantity) => {
    return (
        <>
            {rowData.name} {rowData.trash_type && `- ${mapTrashTypetext(rowData.trash_type)}`}{' '}
            {rowData.family && `- ${mapCollectConfigFamilyText(rowData.family, true)}`}
        </>
    );
};

export const TotalPriceColumnTemplate = (rowData: ProductRoWithQuantity) => {
    const displayPrice = Dinero({
        amount   : rowData.price.net_amount_cents,
        currency : rowData.price.currency
    })
        .multiply(rowData.quantity)
        .toFormat('$0.00'); //We can't put the $ at the end.

    return <span>{displayPrice} (HT)</span>;
};

export const PriceContainer = (price: NdlssPrice | null, loading?: boolean) => {
    const Line = (
        label: string,
        discounted: PriceObject | undefined,
        base: PriceObject | undefined,
        loading?: boolean
    ) => (
        <LineWrapper>
            <PriceLabel>{label}:</PriceLabel>
            {loading ? (
                <Skeleton height="12px" width="64px" />
            ) : (
                <>
                    <Price>
                        {Dinero({ amount: discounted?.amount ?? 0, currency: discounted?.currency ?? 'EUR' }).toFormat(
                            '$0.00'
                        )}
                    </Price>
                    {discounted?.amount !== base?.amount && (
                        <PriceDiscount>
                            ({' '}
                            {Dinero({ amount: base?.amount ?? 0, currency: base?.currency ?? 'EUR' })
                                .subtract(
                                    Dinero({ amount: discounted?.amount ?? 0, currency: discounted?.currency ?? 'EUR' })
                                )
                                .toFormat('$0.00')}{' '}
                            de réduction)
                        </PriceDiscount>
                    )}
                </>
            )}
        </LineWrapper>
    );
    return (
        <PriceWrapper>
            <tbody>
                {Line('Prix total (HT)', price?.discounted_net, price?.base_net, loading)}
                {Line('TVA', price?.discounted_vat, price?.base_vat, loading)}
                {Line('Prix total (TTC)', price?.discounted_total, price?.base_total, loading)}
            </tbody>
        </PriceWrapper>
    );
};

const CancelDialog = ({ cc, order }: { cc: CCRo; order: OrderRo }) => {
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = React.useState(false);
    return (
        <>
            <Button
                label="Annuler la commande"
                className="p-button-text p-button-danger"
                onClick={() => setCancelDialogIsOpen(true)}
            />
            <Dialog
                visible={cancelDialogIsOpen}
                onHide={() => setCancelDialogIsOpen(false)}
                header={`Annulation - ${order.number}`}
                draggable={false}
                resizable={false}
                blockScroll
                contentStyle={{ overflow: 'scroll' }}
            >
                <CollectConfigCancelForm
                    submitCallback={(res) => {
                        /**
                         * Reload the page if the cancelation was successful.
                         * If not successful, no need to reload as nothing happened.
                         */
                        if (res.success) window.location.reload();
                    }}
                    setModalIsOpen={setCancelDialogIsOpen}
                    dataId={cc.id}
                    isAdministrative={cc.family === CC_FAMILY.ADMINISTRATIVE}
                />
            </Dialog>
        </>
    );
};

const PriceWrapper = styled.table`
    margin-top: 8px;
    border-spacing: 4px;
`;
const LineWrapper = styled.tr`
    display: flex;
    align-items: center;
    gap: 16px;
`;
const Price = styled.td`
    font-weight: bold;
    text-align: center;
`;
const PriceLabel = styled.td`
    font-weight: 200;
    text-align: right;
    margin-left: auto;
`;
const PriceDiscount = styled.td`
    font-weight: 400;
    text-align: left;
`;

const StyledCard = styled(Card)`
    & .p-card-content {
        display: flex;
        flex-direction: column;
        align-items: initial;
        flex-wrap: wrap;
    }
`;

const GroupContainer = styled.div`
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
`;

export const ColumnData = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const LargeText = styled.span`
    font-weight: 600;
    font-size: 1.2em;
    text-transform: uppercase;
`;

export const MediumText = styled.span`
    font-weight: 400;
    font-size: 1em;
`;

export const LightText = styled.span`
    font-weight: 200;
    font-size: 1em;
`;

export const TotalData = styled.div`
    margin: 16px 8px 0;
    display: flex;
    justify-content: space-between;
`;

export const StyledH4 = styled.h4`
    margin: 2px 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
`;

export const AddressContainer = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;

export const CollectContainer = styled.div`
    display: flex;
    gap: 16px;
`;

export const StyledDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-bottom: 16px;
`;

export const CollectDiv = styled.div`
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
`;

export const DoneContainer = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
`;

export const OrderCreatePriceWrapper = styled.div`
    padding: 8px;
    display: flex;
    justify-content: right;
`;

export const CanceledByContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
`;
