import { ESagaName } from './kafka';

/**
 * NDLSS EVENT TYPE
 * @description
 * All events must be defined here in order to be used in the system
 */
export const NdlssEventType = {
    /****************************************************************************************************
     * @type FROM PLAYER -> ORCHESTRATOR
     * @description this event is sent from a player to an orchestrator.
     * The orchestrator will then handle the event by formatting a fake HTTP request and send start the saga.
     * @implements No restriction
     ****************************************************************************************************

    /**
     * @origin -> PLANNING - When we remove a CC that was planned from a planning.
     * @destination -> PLANNING ORC - PLANNING_RECALCULATE
     **/
    CC_REMOVED_FROM_PLANNING: 'CC_REMOVED_FROM_PLANNING',

    /**
     * @origin -> PLANNING - When we archived a truck but planning already had collects.
     * @destination -> PLANNING ORC - PLANNING_FLATTEN
     **/
    PLANNING_TO_FLATTEN: 'PLANNING_TO_FLATTEN',

    /**
     * @origin -> CC - When all the sub CC of a splitted collect that were planned are done.(FINISHED OR HAZARD)
     * @destination -> COLLECT ORC - COLLECT_SPLITTED_ALL_DONE (we calculate the price)
     **/
    CC_SPLITTED_ALL_DONE: 'CC_SPLITTED_ALL_DONE',

    /****************************************************************************************************
     * @type FROM ORCHESTRATOR -> PLAYER
     * @description this event is sent from an orchestrator to a player.
     * In order to do so, you need to register an event from the orchestrator and the player listen to.
     * @constructs Try to avoid sending full RO, pass as little keys/values as possible.
     * @implements Most often as possible.
     ****************************************************************************************************/
    /**
     * @origin -> CC ORC - When some sensible are updated.
     * @destination -> PLANNING - Remove the CC from the planning
     * @flow Send a new event to the planning orchestrator - CC_REMOVED_FROM_PLANNING
     */
    CC_PLANNED_EDITED                  : 'CC_PLANNED_EDITED',
    /**
     * @origin -> CC ORC - When some sensible are updated.
     * @destination -> PLANNING - Remove the CC from the planning
     * @flow Send a new event to the planning orchestrator - CC_REMOVED_FROM_PLANNING
     */
    CC_ADMINISTRATIVE_PLANNED_EDITED   : 'CC_ADMINISTRATIVE_PLANNED_EDITED',
    /**
     * @origin -> CC ORC - When some CCS or CCA are postponed.
     * @destination -> PLANNING - Remove the CCs from the planning
     * @flow Send a new event to the planning orchestrator - CC_REMOVED_FROM_PLANNING
     */
    CC_PLANNED_POSTPONED               : 'CC_PLANNED_POSTPONED',
    /**
     * @origin -> CC ORC - When we cancel a CC that was planned.
     * @destination -> PLANNING - Remove the CC from the planning
     * @flow Send a new event to the planning orchestrator - CC_REMOVED_FROM_PLANNING
     */
    CC_PLANNED_CANCELED                : 'CC_PLANNED_CANCELED',
    /**
     * @origin -> CC ORC - When we cancel a CC that was planned.
     * @destination -> PLANNING - Remove the CC from the planning
     * @flow Send a new event to the planning orchestrator - CC_REMOVED_FROM_PLANNING
     */
    CC_ADMINISTRATIVE_PLANNED_CANCELED : 'CC_ADMINISTRATIVE_PLANNED_CANCELED',
    /**
     * @origin -> CC ORC - When a splitted CC that had children planned is merged back.
     * @destination -> PLANNING - Remove the CC from the planning
     * @flow Send a new event to the planning orchestrator - CC_REMOVED_FROM_PLANNING
     */
    CC_SPLITTED_PLANNED_MERGED         : 'CC_SPLITTED_PLANNED_MERGED',

    /**
     * @origin -> CC ORC - When a CC that needed to be paid (and is not yet paid) is cancelled.
     * @destination -> STRIPE - Cancel the checkout session
     * @destination -> EMAIL - Send an email to the customer with cancelation confirmation
     */
    CC_ORDER_TO_PAY_CANCELLED: 'CC_ORDER_TO_PAY_CANCELLED',

    /**
     * @origin -> CC ORC - When a paid CC is cancelled.
     * @destination -> STRIPE - Refund the user.
     * @destination -> EMAIL - Send an email to the customer with cancelation + refund confirmation
     * @destination -> ZOHO - Cancel zoho invoice (which will trigger internal invoice status update through webhook)
     */
    CC_ORDER_PAID_CANCELLED: 'CC_ORDER_PAID_CANCELLED',

    /**
     * @origin -> CC ORC | ORDER ORC | PRD ORC | CUSTOMER ORC - When we 'disconnect' a document.
     * @destination -> DOCUMENT - Remove the document from s3
     **/
    DOCUMENT_DELETED: 'DOCUMENT_DELETED',

    /**
     * @origin -> COLLECT ORC - When we create a Collect service SUCCES
     * @destination -> BSD - Generate a BLC
     **/
    COLLECT_DUMPSTER_CREATED: 'COLLECT_DUMPSTER_CREATED',

    /**
     * @origin -> COLLECT ORC - When we create a Collect service SUCCES
     * @destination -> BSD - Generate a BLC
     **/
    COLLECT_BB_CREATED: 'COLLECT_BB_CREATED',

    /**
     * @origin -> COLLECT ORC - When we go to a landfill.
     * @destination -> COLLECT - Update the Collect emptyied at.
     * @destination -> BSD - Generate all the BSD.
     **/
    COLLECT_EMPTYING_CREATED: 'COLLECT_EMPTYING_CREATED',

    /**
     * @origin -> COLLECT ORC - When a collect service is updated after being emptied.
     * @destination -> BSD - Delete current BSD and generate a new one up-to-date.
     **/
    COLLECT_SERVICE_ALREADY_EMPTIED_UPDATED: 'COLLECT_SERVICE_ALREADY_EMPTIED_UPDATED',

    /**
     * @origin -> COLLECT ORC - When we create a collect that was not planned
     * @destination -> BSD - Generate all the BSD.
     */
    COLLECT_NOT_PLANNED_CREATED: 'COLLECT_NOT_PLANNED_CREATED',

    /**
     * @origin -> PLANNING ORC - When we unassign a collector that had planned CC.
     * @destination -> CC - Update the CC status. We dont recalculate the plannig as we can't unassign a started Planning.
     **/
    PLANNING_UNASSIGNED_SERVICE        : 'PLANNING_UNASSIGNED_SERVICE',
    PLANNING_UNASSIGNED_ADMINISTRATIVE : 'PLANNING_UNASSIGNED_ADMINISTRATIVE',

    /**
     * @origin -> PLANNING ORC - When we create a DRIVER_HOUSE_END
     * @destination -> PLANNING - Flatten the current planning
     **/
    PLANNING_DONE: 'PLANNING_DONE',

    /**
     * @origin -> ORDER ORC - When an order is created
     * @destination -> EMAIL - Send an email to the customer with the payment link
     **/
    ORDER_CREATED: 'ORDER_CREATED',

    /**
     * @origin -> ORDER ORC - ORDER_RESEND_LINK saga.
     * @destination -> EMAIL - Send an email to the customer with the new payment link
     **/
    STRIPE_NEW_PAYMENT_LINK_CREATED: 'STRIPE_NEW_PAYMENT_LINK_CREATED',

    /**
     * @origin -> COLLECT ORC - When a collect is created.
     * @destination -> CC - Update the CC status
     **/
    // COLLECT_SERVICE_CREATED        : 'COLLECT_SERVICE_CREATED',
    // COLLECT_SERVICE_HAZARD_CREATED : 'COLLECT_SERVICE_HAZARD_CREATED',
    // COLLECT_ADMINISTRATIVE_CREATED : 'COLLECT_ADMINISTRATIVE_CREATED',

    /**
     * @origin -> TRUCK ORC - When we unarchive a truck.
     * @destination -> PLANNING - Remove the flatten_info from the future plannings
     **/
    TRUCK_UNARCHIVED: 'TRUCK_UNARCHIVED',

    /**
     * @origin -> TRUCK ORC - When we archive a truck.
     * @destination -> PLANNING - Check if planning can be deleted or after Flatten (if we have collect)
     * @flow Send a new event to the planning orchestrator so it can flatten the planning
     **/
    TRUCK_ARCHIVED: 'TRUCK_ARCHIVED',

    /**
     * @origin -> CUSTOMER ORC - When we edit a customer's contact
     * @origin -> CUSTOMER ORC - When create an order an we update the customer's contact (with a 'new user')
     * @destination -> ZOHO - Update the contact in Zoho
     */
    CUSTOMER_INFO_IN_ZOHO_TO_UPDATE: 'CUSTOMER_INFO_IN_ZOHO_TO_UPDATE',

    /**
     * @origin -> CUSTOMER ORC - When we edit a customer's billing mode (from end_of_month to another)
     * @destination -> CUSTOMER ORC - Gather needed data to generate invoices for finished collects
     */
    CUSTOMER_INFO_BILLING_MODE_UPDATE: 'CUSTOMER_INFO_BILLING_MODE_UPDATE',

    /**
     * @origin -> BSD ORC - When we generate a BSD
     * @destination -> CUSTOMER ORC - Upload the BSD on google drive and update customer google drive folder info
     */
    CUSTOMER_UPLOAD_BSD_ON_GOOGLE_DRIVE: 'CUSTOMER_UPLOAD_BSD_ON_GOOGLE_DRIVE',

    /**
     * @origin -> BSD ORC - When we generate a BLC
     * @destination -> CUSTOMER ORC - Upload the BLC on google drive and update customer google drive folder info
     **/
    CUSTOMER_UPLOAD_BLC_ON_GOOGLE_DRIVE: 'CUSTOMER_UPLOAD_BLC_ON_GOOGLE_DRIVE',

    /**
     * @origin -> CUSTOMER ORC - When a user ask for to be invited to a customer
     * @destination -> EMAIL PLAYER - Send email with invitation link to the customer admins
     **/
    CUSTOMER_SEND_INVITATION_LINK: 'CUSTOMER_SEND_INVITATION_LINK',

    /**
     * @origin -> CUSTOMER ORC - When a user invitation is accepted
     * @destination -> EMAIL PLAYER - Send email with invitation confirmation to the invited user
     **/
    CUSTOMER_SEND_INVITATION_CONFIRMATION: 'CUSTOMER_SEND_INVITATION_CONFIRMATION',

    /****************************************************************************************************
     * @type FROM PLAYER -> PLAYER
     * @description this event is sent from a player to another player.
     * In order to do so, you need to send a Ndless Event.
     * @implements No restrictions
     ****************************************************************************************************/
    /**
     * @origin -> HERE - When we make a request to Here.com
     * @destination -> VRP - Update the price table from vrpAlgo
     **/
    MATRIX_CALCULATED_BY_HERE: 'MATRIX_CALCULATED_BY_HERE',

    /**
     * @origin -> PLANNING - When we removed a CC from a planning that was flatten after a truck was archived.
     * @destination -> CC - Update the status of the CC
     **/
    PLANNING_UNAVAILABLE_REMOVE_CC_SERVICE        : 'PLANNING_UNAVAILABLE_REMOVE_CC_SERVICE',
    PLANNING_UNAVAILABLE_REMOVE_CC_ADMINISTRATIVE : 'PLANNING_UNAVAILABLE_REMOVE_CC_ADMINISTRATIVE',

    /**
     * @origin -> COLLECT - When we create a collect service
     * @destination -> COLLECT - Send an SMS to the customer
     */
    COLLECT_SERVICE_CREATED_SEND_SMS: 'COLLECT_SERVICE_CREATED_SEND_SMS',

    /**
     * FROM ORCHESTRATOR -> ORCHESTRATOR
     **/
    /**
     * @origin -> CC ORC | ORDER_PAY - When we finish a CC and customer is after collec and CC not prepaid and if we manually paid the order on stripe
     * @destination -> INVOICE ORC => INVOICE_AFTER_DELIVERY_GENERATE
     */
    INVOICE_AFTER_DELIVERY              : 'INVOICE_AFTER_DELIVERY',
    /**
     * @origin -> ORDER ORC - When we change the status of an order to PAID manually
     * @destination -> INVOICE ORC => INVOICE_AFTER_MANUAL_PAYMENT_GENERATE
     */
    INVOICE_AFTER_MANUAL_PAYMENT        : 'INVOICE_AFTER_MANUAL_PAYMENT',
    /**
     * @origin -> CC ORC - When we cancel a CC that was paid.
     * @destination -> INVOICE ORC => INVOICE_AFTER_PAID_LATE_CANCELATION
     */
    INVOICE_AFTER_PAID_LATE_CANCELATION : 'INVOICE_AFTER_PAID_LATE_CANCELATION',

    /**
     * @origin -> COLLECT ORC - When we finish a Collect and customer is INTSTANT and CC not prepaid.
     * @destination -> INVOICE ORC => INVOICE_AFTER_COLLECT_GENERATE
     */
    INVOICE_AFTER_COLLECT: 'INVOICE_AFTER_COLLECT',

    /**
     * @origin -> INVOICE ORC - When we format invoices to be generated.
     * @destination -> INVOICE ORC => INVOICE_GENERATE_EXECUTE
     */
    INVOICE_GENERATE_FORMATTED: 'INVOICE_GENERATE_FORMATTED',

    /**
     * @origin -> COLLECT ORC - When we finish a success service collect.
     * @origin -> COLLECT ORC - When we finish a hazard service collect.
     * @origin -> COLLECT ORC - When we finish an unplanned service collect.
     * @origin -> COLLECT ORC - When we do a late cancelation.
     * @origin -> COLLECT ORC - When we update a service collect.
     * @origin -> COLLECT ORC - When we calculate global price of splitted collects.
     * @origin -> CC ORC - When we finish a delivery and customer is end of month.
     * @origin -> ORDER ORC - When we do an instant delivery.
     * @destination -> INVOICE ORC => INVOICE_UPSERT
     */
    INVOICE_UPSERT: 'INVOICE_UPSERT',

    /**
     * @origin -> ZOHO PLAYER - When an invoice is updated in Zoho and a webhook is triggered.
     * @destination -> INVOICE ORC => Update invoice
     */
    ZOHO_INVOICE_WEBHOOK: 'ZOHO_INVOICE_WEBHOOK',

    /**
     * @origin -> ZOHO PLAYER - When a customer is updated in Zoho and a webhook is triggered.
     * @destination -> CUSTOMER ORC => Update customer
     */
    ZOHO_CUSTOMER_WEBHOOK: 'ZOHO_CUSTOMER_WEBHOOK',

    /**
     * @origin -> Can be used by any orchestrator to send an sms
     * @destination -> SMS Player - Send an SMS
     */
    SEND_SMS: 'SEND_SMS',

    /**
     * @origin -> COLLECT ORC - When we create a collect service
     * @destination -> COLLECT - Create a new contact
     */
    COLLECT_SERVICE_CREATED_HUBSPOT_HOOKDECK: 'COLLECT_SERVICE_CREATED_HUBSPOT_HOOKDECK',

    /**
     * @origin -> ORDER ORC - When an order is created
     * @destination -> COLLECT PLAYER - Create a new contact
     */
    ORDER_CREATED_HOOKDECK: 'ORDER_CREATED_HOOKDECK',

    /**
     * @origin -> ORDER ORC - When we create an order instant delivery
     * @destination -> ORDER - Create a new contact
     */
    ORDER_INSTANT_DELIVERY_HOOKDECK: 'ORDER_INSTANT_DELIVERY_HOOKDECK',

    /**
     * @origin -> CC_ORC - When we update a CC delivery status
     * @destination -> CC PLAYER - Create a new contact
     */
    CC_DELIVERY_FINISHED_HUBSPOT: 'CC_DELIVERY_FINISHED_HUBSPOT',

    NO_EVENT: 'NO_EVENT'
} as const;
export type NdlssEventType = typeof NdlssEventType[keyof typeof NdlssEventType];

/**
 * NDLSS EVENT
 * @description
 * Data structure for an event in the system
 * inspired by
 * {@link https://www.boyney.io/blog/2022-02-11-event-payload-patterns Amazon EventBridge: Event Payload Standards}
 *
 * @param type             - The type of the event, also the topic destination
 * @param source           - The saga type that emitted the event
 * @param emittedAt        - The date at which the event was emitted in ISO string format
 * @param details          - Contextual information about the event, set up by the saga
 * @param details.metadata - Metadata about the event, set up by the saga, contains misc contextual information
 * @param details.data     - The data that was emitted by the saga, contains the main payload of the event
 */
export type NdlssEvent<Data = any, Metadata = Record<string, any>> = {
    /**
     * @param type - The type of the event, also the topic destination
     */
    type: NdlssEventType;

    /**
     * @param source - The saga type that emitted the event
     */
    source: ESagaName;

    /**
     * @param emittedAt - The date at which the event was emitted in ISO string format
     */
    emittedAt: string;

    /**
     * @param details - Contextual information about the event, set up by the saga
     */
    details: {
        /**
         * @param metadata - Metadata about the event, set up by the saga, contains misc contextual information
         */
        metadata: Metadata;

        /**
         * @param data - The data that was emitted by the saga, contains the main payload of the event
         */
        data: Data;
    };
};
