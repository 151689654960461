import { AutoComplete, AutoCompleteProps } from 'primereact/autocomplete';
import React from 'react';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { PlaceResult, useGoogleMapsContext } from '../../context/GoogleMaps';

const googleAddressFields = ['address_components', 'geometry', 'name', 'formatted_address'];

export type GoogleAddressAutocomplete = {
    onAddressSelect: (address: PlaceResult) => void;
    disabled?: boolean;
    autocompleteProps?: AutoCompleteProps;
};

export const GoogleAddressAutocomplete: React.FC<GoogleAddressAutocomplete> = ({
    onAddressSelect,
    disabled = false,
    autocompleteProps = {},
    ...props
}: GoogleAddressAutocomplete) => {
    const googleMaps = useGoogleMapsContext();
    const googleAutocomplete = usePlacesAutocompleteService({
        debounce: 200
    });

    const [value, setValue] = React.useState<string>('');

    const isDisabled = disabled || !googleMaps.isReady;

    const handleSelectAddress = (placeId: string) => {
        if (!googleAutocomplete || !googleAutocomplete.placesService) return;

        googleAutocomplete.placesService.getDetails(
            { placeId, fields: googleAddressFields },
            (placeResult: PlaceResult | null, placeServicesStatus: string) => {
                if (placeServicesStatus === 'OK' && placeResult !== null) {
                    onAddressSelect(placeResult);
                }
            }
        );
    };

    return (
        <span className="p-float-label">
            <AutoComplete
                value={value}
                onChange={(e) => setValue(e.value)}
                field="description"
                disabled={isDisabled}
                {...autocompleteProps}
                {...props}
                completeMethod={(e) =>
                    googleAutocomplete.getPlacePredictions({ input: e.query, componentRestrictions: { country: 'fr' } })
                }
                suggestions={googleAutocomplete.placePredictions}
                onSelect={(e) => {
                    handleSelectAddress(e.value.place_id);
                }}
            />
            <label>Rechercher une adresse</label>
        </span>
    );
};
