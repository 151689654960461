import Joi from 'joi';

import { AddressCreateSchema } from '../address';
import { ContactDtoSchema } from '../contact';
import { EPlanningType, PlanningStepCommonDtoSchema } from '../planning';
import { CC_PATCH_ORIGIN, COLLECT_CONFIG_PATCH_REASON_MIN_LENGTH } from './collect-config';

export const CCPostponeDtoSchema = Joi.object({
    date          : Joi.date().iso().required(),
    cc_admin_id   : Joi.array().items(Joi.string()),
    cc_service_id : Joi.array().items(Joi.string())
});

export const CollectConfigCreateDtoSchema = Joi.object({
    title                  : Joi.string().required(),
    address                : AddressCreateSchema.required(),
    comment                : Joi.string().allow('', null),
    from_date              : Joi.date().iso().required(),
    to_date                : Joi.date().iso().required(),
    type                   : Joi.string().valid('BIG_BAG', 'DUMPSTER').required(),
    // truck_id               : Joi.array().items(Joi.string()),
    collector_id           : Joi.array().items(Joi.string()),
    presta_id              : Joi.array().items(Joi.string()),
    execution_time_minutes : Joi.number().positive()
});

export const LinesSchema = Joi.object({
    id       : Joi.string().required(),
    quantity : Joi.number().positive().required()
});
const CCCharacteristic = {
    NARROW_STREET : 'NARROW_STREET',
    BAG_INSIDE    : 'BAG_INSIDE',
    ON_SIDEWALK   : 'ON_SIDEWALK',
    ON_SCAFFOLD   : 'ON_SCAFFOLD'
};
export const CollectConfigEditDtoSchema = Joi.object({
    characteristics           : Joi.array().items(...Object.values(CCCharacteristic)),
    comment                   : Joi.string().allow(''),
    from_date                 : Joi.date().iso(),
    to_date                   : Joi.date().iso(),
    waiting_time_minutes      : Joi.number().min(15),
    log_contact               : Joi.array().items(ContactDtoSchema),
    construction_site_contact : Joi.array().items(ContactDtoSchema),
    lines                     : Joi.array().items(LinesSchema),
    presta_id                 : Joi.array().items(Joi.string()),
    execution_time_minutes    : Joi.number().positive(),
    patch_origin              : Joi.string()
        .valid(...Object.values(CC_PATCH_ORIGIN))
        .required(),
    patch_reason           : Joi.string().min(COLLECT_CONFIG_PATCH_REASON_MIN_LENGTH).required(),
    already_available_date : Joi.object({
        to_date   : Joi.date().iso().required(),
        from_date : Joi.date().iso().required()
    }),
    dumpster_on_site_id       : Joi.array().items(Joi.string()),
    deposit_collect_config_id : Joi.array().items(Joi.string()).optional()
});

export const CollectConfigSplitDtoSchema = Joi.object({
    lines_by_collect: Joi.array()
        .items(
            Joi.array().items(LinesSchema)
            // .custom((value: CollectLineCreateDto[]) => {
            //     let totalQuantity = 0;
            //     (value || []).forEach((line) => {
            //         totalQuantity += line?.quantity;
            //     });
            //     if (totalQuantity === 0) {
            //         throw new Error('Collect must not be empty');
            //     }
            //     return value;
            // })
        )
        .min(2)
        .required()
});

export const CollectConfigCancelDtoSchema = Joi.object({
    patch_origin: Joi.string()
        .valid(...Object.keys(CC_PATCH_ORIGIN))
        .required(),
    patch_reason   : Joi.string().min(COLLECT_CONFIG_PATCH_REASON_MIN_LENGTH).required(),
    do_not_invoice : Joi.boolean().optional()
});

/***********************************************************************/
/*                                                                     */
/*                            ADMINISTRATIVE                           */
/*                                                                     */
/***********************************************************************/
export const CCAdministrativeEditDtoSchema = Joi.object({
    title                  : Joi.string(),
    address                : AddressCreateSchema,
    comment                : Joi.string().allow('', null),
    from_date              : Joi.date().iso(),
    to_date                : Joi.date().iso(),
    collector_id           : Joi.array().items(Joi.string()).max(1),
    execution_time_minutes : Joi.number().positive()
});

export const CCAdministrativeCreateDtoSchema = Joi.object({
    title     : Joi.string().required(),
    address   : AddressCreateSchema.required(),
    comment   : Joi.string().allow('', null),
    from_date : Joi.date().iso().required(),
    to_date   : Joi.date().iso().required(),
    type      : Joi.string()
        .valid(...Object.keys(EPlanningType))
        .required(),
    collector_id           : Joi.array().items(Joi.string()).max(1).required(),
    execution_time_minutes : Joi.number().positive()
});

export const CCGetDensityQuerySchema = Joi.object({
    sync : Joi.boolean(),
    day  : Joi.date().iso().required(),
    type : Joi.string()
        .valid(...Object.keys(EPlanningType))
        .required()
});

/***********************************************************************/
/*                                                                     */
/*                            REPLAN ACTIVITY                          */
/*                                                                     */
/***********************************************************************/
export const CCReplanActivityCreateDtoSchema = Joi.object({
    step: PlanningStepCommonDtoSchema.append({
        collect_config_id: Joi.string().required()
    }),
    collector_id : Joi.array().items(Joi.string()).required(),
    truck_id     : Joi.array().items(Joi.string()).required(),
    comment      : Joi.string().allow('')
});
