import React from 'react';

export type WindowSize = {
    width: number;
    height: number;
};

export const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = React.useState(() => ({
        width  : window.innerWidth,
        height : window.innerHeight
    }));

    React.useEffect(() => {
        function handleResize() {
            setWindowSize({
                width  : window.innerWidth,
                height : window.innerHeight
            });
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};
