import { CheckboxButton, CheckboxButtonProps } from './Checkbox';
import { ClickableButton, ClickableButtonProps } from './Clickable';
import { GroupButton, GroupButtonProps } from './Group';
import { PulseButton, PulseButtonProps } from './Pulse';
import { SwitchButton, SwitchButtonProps } from './Switch';
import { ToggleBtn, ToggleButtonProps } from './Toggle';

const Button = {
    Pulse     : (props: PulseButtonProps) => <PulseButton {...props} />,
    Group     : (props: GroupButtonProps) => <GroupButton {...props} multiple={true} />,
    Select    : (props: GroupButtonProps) => <GroupButton {...props} multiple={false} />,
    Switch    : (props: SwitchButtonProps) => <SwitchButton {...props} />,
    Checkbox  : (props: CheckboxButtonProps) => <CheckboxButton {...props} />,
    Clickable : (props: ClickableButtonProps) => <ClickableButton {...props} />,
    Toggle    : (props: ToggleButtonProps) => <ToggleBtn {...props} />
};

export default Button;
