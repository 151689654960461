import {
    AllowedDays,
    ESlot,
    FORBIDDEN_DAY_FORMAT,
    ISODate,
    MAX_TIME_LIMIT_FOR_ORDER_POST,
    ZoneRo
} from '@bbng/util/types';
import moment from 'moment';

export const FORMAT_DISPLAY_DATE = 'dddd DD MMMM YYYY'; // lundi 01 janvier 2021

/**
 * Get next business day from given date (or today by default).
 * @param {ISODate} date
 *
 * @returns {string}
 */
export function getNextBusinessDay(date = moment.utc().toISOString()): string {
    const dateMoment = moment.utc(date);
    const day = dateMoment.day();
    switch (day) {
        case 5:
            return dateMoment.add(3, 'days').toISOString();
        case 6:
            return dateMoment.add(2, 'days').toISOString();
        default:
            return dateMoment.add(1, 'days').toISOString();
    }
}

/**
 * Get previous business day from given date (or today by default).
 * @param {ISODate} date
 *
 * @returns {string}
 */
export function getPreviousBusinessDay(date = moment.utc().toISOString()): string {
    const dateMoment = moment.utc(date);
    const day = dateMoment.day();
    switch (day) {
        case 1:
            return dateMoment.subtract(3, 'days').toISOString();
        case 0:
            return dateMoment.subtract(2, 'days').toISOString();
        default:
            return dateMoment.subtract(1, 'days').toISOString();
    }
}

/**
 * Check if input is a valid Date object.
 * @param {any} date
 *
 * @returns {boolean}
 */
export function isValidDate(date: any): boolean {
    return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}

/**
 * Get middle of the given day. If no day is given, it will return the middle of the current day.
 * @param {string} day in ISO format
 *
 * @returns {string} in ISO format
 */
export function getMiddleOfTheDay(day?: string): string {
    const inputDay = day || moment.utc().toISOString();
    return moment.utc(inputDay).hour(12).minute(0).second(0).millisecond(0).toISOString();
}

/**
 * Merge two dates ISO dates into a ISO format date.
 * @param {string | Date} dateForDays date used to consider days.
 * @param {string | Date} dateForTime date used to consider time.

* @returns {string}
 */
export const mergeDates = (dateForDays?: string | Date, dateForTime?: string | Date): string => {
    const mergeDate = moment(dateForDays);
    mergeDate.set({
        hour        : moment(dateForTime)?.hours() || 0,
        minute      : moment(dateForTime)?.minutes() || 0,
        seconds     : moment(dateForTime)?.seconds() || 0,
        millisecond : moment(dateForTime)?.milliseconds() || 0
    });
    return mergeDate.toISOString();
};

/**
 * Get minutes from a date.
 * @param {string | Date | undefined}
 *
 * @returns {number}
 */
export const getMinutesFromDate = (date: string | Date | undefined): number => {
    if (!date) return 0;
    const castedDate = moment(convertHHMMToDate(date));
    return castedDate.hours() * 60 + castedDate.minutes();
};

/**
 * Convert string formatted as HH:MM to date.
 * @param {string | Date} value
 *
 * @returns {Date}
 */
export const convertHHMMToDate = (value: string | Date): Date => {
    if (typeof value === 'string') {
        if (value.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)) {
            // "HH:mm" format
            const [hours, minutes] = value.split(':');
            return moment()
                .set('hours', Number(hours))
                .set('minutes', Number(minutes))
                .set('seconds', 0)
                .set('milliseconds', 0)
                .toDate();
        } else {
            return moment(value).toDate();
        }
    } else return value;
};

export const minTwoDigits = (n: number): string => (n < 10 ? '0' : '') + n;

export const minsToDuration = (number: number): string => {
    const durationMinutes = number % 60;
    const durationHours = Math.floor(number / 60);
    return `${durationHours < 10 ? '0' : ''}${durationHours}:${durationMinutes < 10 ? '0' : ''}${durationMinutes}`;
};

export const durationToMinutes = (duration: string): number => {
    const [hours = 0, minutes = 0] = duration.split(':');
    return Number(hours) * 60 + Number(minutes);
};

/**
 * Return hours, minutes and seconds in Paris timezone for a given ISO date.
 * @param {ISODate} date
 * @returns {string[]} [hours, minutes, seconds]
 */
export const tzHoursMinutesSecondsFromISOString = (date: ISODate): string[] /** [hours, minutes, seconds] */ => {
    const tzLocaleTimeString = new Date(date).toLocaleTimeString('fr', { timeZone: 'Europe/Paris' });
    const result = tzLocaleTimeString.split(':');
    return result;
};

export const slotToDateMapper = (
    slot: ESlot,
    from_date?: Date | string,
    to_date?: Date | string
): {
    from_date: Date;
    to_date: Date;
} => {
    switch (slot) {
        case ESlot.DAY: {
            return {
                from_date : moment(from_date).toDate(),
                to_date   : convertHHMMToDate('19:00')
            };
        }
        case ESlot.MORNING: {
            return {
                from_date : moment(from_date).toDate(),
                to_date   : convertHHMMToDate('12:00')
            };
        }
        case ESlot.AFTERNOON: {
            return {
                from_date : moment(from_date).toDate(),
                to_date   : convertHHMMToDate('19:00')
            };
        }
        case ESlot.FIVE_EIGHT_AM: {
            return {
                from_date : convertHHMMToDate('05:00'),
                to_date   : convertHHMMToDate('08:00')
            };
        }
        case ESlot.EIGHT_TEN_AM: {
            return {
                from_date : convertHHMMToDate('08:00'),
                to_date   : convertHHMMToDate('10:00')
            };
        }
        case ESlot.TEN_TWELVE_AM: {
            return {
                from_date : convertHHMMToDate('10:00'),
                to_date   : convertHHMMToDate('12:00')
            };
        }
        case ESlot.TWELVE_TWO_PM: {
            return {
                from_date : convertHHMMToDate('12:00'),
                to_date   : convertHHMMToDate('14:00')
            };
        }
        case ESlot.TWO_FOUR_PM: {
            return {
                from_date : convertHHMMToDate('14:00'),
                to_date   : convertHHMMToDate('16:00')
            };
        }
        case ESlot.FOUR_SEVEN_PM: {
            return {
                from_date : convertHHMMToDate('16:00'),
                to_date   : convertHHMMToDate('19:00')
            };
        }
        default: {
            return {
                from_date : moment(from_date).toDate(),
                to_date   : moment(to_date).toDate()
            };
        }
    }
};

export const dateToSlotMapper = (from_date: ISODate, to_date: ISODate): ESlot => {
    const from = moment(from_date).hours();
    const to = moment(to_date).hours();

    if (from >= 5 && to <= 8) {
        return ESlot.FIVE_EIGHT_AM;
    }
    if (from >= 8 && to <= 10) {
        return ESlot.EIGHT_TEN_AM;
    }
    if (from >= 10 && to <= 12) {
        return ESlot.TEN_TWELVE_AM;
    }
    if (from >= 12 && to <= 14) {
        return ESlot.TWELVE_TWO_PM;
    }
    if (from >= 14 && to <= 16) {
        return ESlot.TWO_FOUR_PM;
    }
    if (from >= 16 && to <= 19) {
        return ESlot.FOUR_SEVEN_PM;
    }
    if (from >= 5 && to <= 12) {
        return ESlot.MORNING;
    }
    if (from >= 12 && to <= 19) {
        return ESlot.AFTERNOON;
    }

    return ESlot.DAY;
};

/**
 * Display a duration hh:mm:ss or mm:ss from seconds.
 * @param {number} seconds
 * @param {boolean} displayHours
 *
 * @returns {string} "hh:mm:ss" or "mm:ss"
 */
export const secondsToDuration = ({
    seconds,
    displayHours = false
}: {
    seconds: number;
    displayHours?: boolean;
}): string => {
    const durationSeconds = seconds % 60;
    const durationMinutes = Math.floor((seconds / 60) % 60);
    const durationHours = Math.floor(seconds / 3600);
    if (displayHours) {
        return `${minTwoDigits(durationHours)}:${minTwoDigits(durationMinutes)}:${minTwoDigits(durationSeconds)}`;
    }
    return `${minTwoDigits(durationMinutes)}:${minTwoDigits(durationSeconds)}`;
};

/**
 * Check if a given day is a working day (i.e. not a weekend).
 * @param {'YYYY-MM-DD'} day
 * @returns {boolean}
 */
const isWorkingDay = (day: string /*YYYY-DD-MM*/): boolean => {
    const date = moment(day);
    return date.isoWeekday() < 6;
};

/**
 * Return next available day for an order in a given zone.
 * @param {ZoneRo} zone
 * @param {ISODate} start_date start date from which to consider the next available day.
 * @returns {string | false} "YYYY-MM-DD" or false if no available day.
 */
export const nextAvailableDay = (zone?: ZoneRo, start_date?: ISODate): string /* YYYY-MM-DD */ | false => {
    if (!zone || !zone.allowed_days || !Object.values(zone.allowed_days).includes(true)) {
        return false;
    }

    const start = start_date ? moment(start_date) : moment();
    const timeLimit = start.clone().set(MAX_TIME_LIMIT_FOR_ORDER_POST);

    /**
     * First next working day is the next day when the planning can be prepared.
     */
    let firstNextWorkingDay;
    /**
     * If the current day is a working day and the time limit is not reached,
     * planning can be made this day.
     */
    if (isWorkingDay(start.format(FORBIDDEN_DAY_FORMAT)) && start.isBefore(timeLimit)) {
        firstNextWorkingDay = start.clone();
    } else {
        /**
         * Otherwise, we need to find the first next working day.
         */
        const candidateNextWorkingDay = start.clone().add(1, 'day');
        while (!isWorkingDay(candidateNextWorkingDay.format(FORBIDDEN_DAY_FORMAT))) {
            candidateNextWorkingDay.add(1, 'day');
        }
        firstNextWorkingDay = candidateNextWorkingDay;
    }

    /**
     * Once we found the first next working day,
     * we need to find the first next allowed day,
     * which is at least one day after the first next working day.
     * (plannings are never made for the current day)
     */
    const firstNextAllowedDay = firstNextWorkingDay.clone().add(1, 'day');
    while (
        !zone.allowed_days[(firstNextAllowedDay.isoWeekday() - 1) as keyof AllowedDays] ||
        zone.forbidden_days?.[firstNextAllowedDay.format(FORBIDDEN_DAY_FORMAT)]
    ) {
        firstNextAllowedDay.add(1, 'day');
    }
    return firstNextAllowedDay.format(FORBIDDEN_DAY_FORMAT);
};
