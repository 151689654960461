import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';

import { DashboardContainer } from './style';
import { useRequest } from '../../hooks/StatelessRequest';
import { EKpiDashboardName } from '@bbng/util/types';
import { urlApiBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import { mapDashboardType } from '../../common/enumMapper';

type DashboardProps = {
    //
};

const DASHBOARD_TYPE = Object.keys(EKpiDashboardName).map((key) => {
    return {
        label : mapDashboardType(EKpiDashboardName[key as keyof typeof EKpiDashboardName]),
        value : EKpiDashboardName[key as keyof typeof EKpiDashboardName]
    };
});

const Dashboard: React.FC<DashboardProps> = (props: DashboardProps): JSX.Element => {
    const bbngRequest = useRequest({ toastifyError: false, toastifySuccess: false });
    const [iFrameUrl, setIFrameUrl] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [dashboardTypeIdx, setDashboardTypeIdx] = React.useState<number>(0);

    const getDashboard = async (type: EKpiDashboardName) => {
        setLoading(true);
        const response = await bbngRequest<{ iFrameUrl: string }>({
            url     : urlApiBuilder.kpiGet(),
            method  : 'GET',
            payload : {
                queryParams: { type: type }
            },
            sync: true
        });

        if (response && response.success) {
            setIFrameUrl(response.response?.data.ro?.iFrameUrl || '');
        }
        setLoading(false);
    };

    React.useEffect(() => {
        getDashboard(DASHBOARD_TYPE[dashboardTypeIdx].value as EKpiDashboardName);
    }, [dashboardTypeIdx]);

    if (loading) {
        return <PageLoader loading />;
    }

    return (
        <DashboardContainer>
            <TabView activeIndex={dashboardTypeIdx} onTabChange={(e) => setDashboardTypeIdx(e.index)}>
                {DASHBOARD_TYPE.map((item) => {
                    return (
                        <TabPanel header={item.label} key={item.value}>
                            <iframe
                                src={iFrameUrl}
                                frameBorder="0"
                                title="DASHBOARD"
                                width={window.innerWidth * 0.95 - 75}
                                height={window.innerHeight * 0.95}
                            />
                        </TabPanel>
                    );
                })}
            </TabView>
        </DashboardContainer>
    );
};

export default Dashboard;
