import { Card } from 'primereact/card';
import React from 'react';

import { PageContainer, PageContent } from './style';

const ComingSoon: React.FC = (): JSX.Element => {
    return (
        <PageContainer>
            <PageContent>
                <Card>
                    <h1>Bientôt disponible...</h1>
                </Card>
            </PageContent>
        </PageContainer>
    );
};

export default ComingSoon;
