import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import { CollectorRoFront } from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { ActionsFormContainer } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import SnackBar from '../../components/SnackBar';
import { useRequest } from '../../hooks/StatelessRequest';
import Collector from '../../modules/collector';
import { AddressState } from '../../modules/common/Address';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    CollectorFormErrorState,
    CollectorFormState,
    CollectorModulesErrorStates,
    CollectorModulesStates,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiCreateData,
    mapStateToApiEditData
} from './helpers';
import { PageForm, PageFormLine } from './style';
import equal from 'fast-deep-equal/react';
import produce from 'immer';

type CollectorFormProps = {
    edit?: boolean;
    readOnly?: boolean;
};

const CollectorForm: React.FC<CollectorFormProps> = ({
    edit = false,
    readOnly = false
}: CollectorFormProps): JSX.Element => {
    const { id: paramsId } = useParams();
    const navigate = useNavigate();
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [apiState, setApiState] = React.useState<{ form: CollectorFormState; db: CollectorRoFront }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>(
        edit || readOnly ? 'read' : undefined
    );
    const [loading, setLoading] = useState<boolean>(edit || readOnly);

    const [err, setErr] = useState<CollectorFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<CollectorFormState>['onSubmit']>(
        async (data: CollectorFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            const method = edit ? 'PATCH' : 'POST';
            const url = edit ? urlApiBuilder.collectorEdit(dataId) : urlApiBuilder.collectorCreate();
            const body = edit
                ? mapStateToApiEditData(data, apiState!.form, apiState!.db)
                : mapStateToApiCreateData(data);
            setLoading(true);
            setOnRequestType('create');
            const response = await bbngRequest<CollectorRoFront>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);
            setOnRequestType(undefined);
            if (response.success) {
                navigate(urlBuilder.collectorList());
            }
        },
        [err, apiState, bbngRequest]
    );

    const formik = useFormik<CollectorFormState>({
        initialValues : initialState,
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (
            value: CollectorModulesStates | AddressState,
            errors: CollectorModulesErrorStates | string[] | null,
            id: string
        ): void => {
            if (equal(formik.values[id as keyof (CollectorModulesStates | AddressState)], value)) return;
            const newValue = produce(formik.values, (draft) => {
                draft[id as keyof CollectorFormState] = value as any;
            });
            formik.setValues(newValue);

            if (equal(err[id as keyof CollectorFormErrorState], errors)) return;
            const newError = produce(err, (draft) => {
                draft[id as keyof CollectorFormErrorState] = errors as any;
            });
            setErr(newError);
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response = await bbngRequest<CollectorRoFront>({
            method  : 'GET',
            url     : urlApiBuilder.collectorGet(dataId),
            options : { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            const ro: CollectorRoFront = await fetchDataRelation(response.response?.data.ro, { landfill_id: true });
            const formikState: CollectorFormState = mapApiDataToState(ro);

            setApiState({ form: formikState, db: ro });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (edit || readOnly) {
            fetchData();
        }
    }, []);

    const handleArchive = React.useCallback(() => {
        setLoading(true);
        setOnRequestType(apiState?.db.archived ? 'unarchive' : 'delete');

        bbngRequest({
            method  : 'PATCH',
            url     : urlApiBuilder.collectorArchive(dataId),
            options : { toastifySuccess: false },
            payload : {
                body: {
                    archived: !apiState?.db.archived
                }
            }
        })
            .then((response) => {
                if (response.success) {
                    navigate(urlBuilder.collectorList());
                }
            })
            .finally(() => {
                setLoading(false);
                setOnRequestType(undefined);
            });
    }, [bbngRequest, setLoading, setOnRequestType, apiState]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (errorCode) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.collectorList()} />
        ) : (
            <Error500 />
        );
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <h1>Collecteur{edit || readOnly ? `: ${apiState?.form?.general.email}` : ''}</h1>
            <ActionsFormContainer
                archived={apiState?.db.archived || false}
                edit={edit}
                readOnly={readOnly}
                // disableSaveOnEdit={JSON.stringify(apiState?.form) === JSON.stringify(formik.values)}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                handleArchiveOnView={handleArchive}
                editPageUrl={urlBuilder.collectorEdit(dataId)}
                historyUrl={urlApiBuilder.collectorHistory(dataId)}
            />
            <PageFormLine>
                <Collector.GeneralInfo
                    id="general"
                    result={handleChange}
                    value={formik.values.general}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            {edit ||
                (readOnly && apiState?.db && (
                    <PageFormLine>
                        <Collector.Access collector={apiState?.db} />
                    </PageFormLine>
                ))}
            {readOnly === false && (
                <Button
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        // (JSON.stringify(apiState?.form) === JSON.stringify(formik.values) && edit)
                        (equal(apiState?.form, formik.values) && edit)
                    }
                />
            )}
            {edit === true && (
                <SnackBar
                    // show={JSON.stringify(apiState?.form) !== JSON.stringify(formik.values)}
                    show={!equal(apiState?.form, formik.values)}
                    content="Vous avez des modifications non enregistrées"
                />
            )}
        </PageForm>
    );
};

export default CollectorForm;
