import { Button as PRButton } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useEffect, useRef, useState } from 'react';
import { baseStepClassName } from '../helpers';
import { CenteredContainer, ColumnContainer, FilterContainer, FilterGlobalTitle, StyledOverlayPanel } from './style';
import { usePlanningV2Store } from '../../../hooks/PlanningStoreV2';
import { CustomerRo, EPlanningType } from '@bbng/util/types';
import styled from 'styled-components';
import {
    PlanningFilterClassNames,
    classNamesCombinations,
    initialBBVolumeFiltersChecked,
    initialBNVolumeFiltersChecked,
    initialFamilyFiltersChecked,
    initialGeneralFiltersChecked,
    mapGeneralFilterText
} from './helpers';
import { CustomerFilter } from './customer-filter';
import { FilterDivider } from './divider';
import { GenericFilter } from './generic-filter';
import { mapCollectConfigFamilyText } from '../../../common/enumMapper';
import Button from '../../../components/Button';

export type PlanningFiltersProps = {
    filterClassNames: PlanningFilterClassNames;
    setFilterClassNames: React.Dispatch<React.SetStateAction<PlanningFilterClassNames>>;
};

const overlayId = 'planning_overlay_pannel';

export const PlanningFilters: React.FC<PlanningFiltersProps> = ({
    filterClassNames,
    setFilterClassNames
}: PlanningFiltersProps): JSX.Element => {
    const panelRef = useRef<OverlayPanel>(null);
    const [reset, setReset] = useState<boolean>(true);

    const [selectedCustomer, setSelectedCustomer] = useState<CustomerRo>();
    const [generalChecked, setGeneralChecked] =
        useState<typeof initialGeneralFiltersChecked>(initialGeneralFiltersChecked);
    const [familyChecked, setFamilyChecked] = useState<typeof initialFamilyFiltersChecked>(initialFamilyFiltersChecked);
    const [bbVolumeChecked, setBbVolumeChecked] =
        useState<typeof initialBBVolumeFiltersChecked>(initialBBVolumeFiltersChecked);
    const [bnVolumeChecked, setBnVolumeChecked] =
        useState<typeof initialBNVolumeFiltersChecked>(initialBNVolumeFiltersChecked);

    const { planningPageState, isCompactMode, setCompactMode, additionalInfoIsOpen, setAdditionalInfoIsOpen } =
        usePlanningV2Store();

    useEffect(() => {
        if (reset) setReset(false);
    }, [reset]);

    return (
        <>
            <PRButton
                type="button"
                icon="pi pi-filter"
                className="p-button-outlined"
                label="Paramétrer l'affichage"
                aria-haspopup
                aria-controls={overlayId}
                onClick={(e) => panelRef.current?.toggle(e)}
            />
            <StyledOverlayPanel ref={panelRef} id={overlayId}>
                <FilterGlobalTitle>Paramètres d'affichage</FilterGlobalTitle>
                <CenteredContainer>
                    <Button.Switch
                        id="compact_mode"
                        label="Mode compact"
                        value={isCompactMode}
                        result={(value) => {
                            setCompactMode(value);
                        }}
                        labelPosition="right"
                    />
                    <Button.Switch
                        id="additional_info"
                        label="Ouvrir toutes les informations complémentaires"
                        value={additionalInfoIsOpen}
                        result={(value) => {
                            setAdditionalInfoIsOpen(value);
                        }}
                        labelPosition="right"
                    />
                </CenteredContainer>
                <FilterContainer>
                    <ColumnContainer>
                        <CustomerFilter
                            reset={reset}
                            setFilterClassNames={setFilterClassNames}
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer={setSelectedCustomer}
                        />
                        <FilterDivider direction="row" />
                        <GenericFilter
                            reset={reset}
                            setFilterClassNames={setFilterClassNames}
                            objectKey="general"
                            val={generalChecked}
                            setVal={setGeneralChecked}
                            initialValues={initialGeneralFiltersChecked}
                            title="Général"
                            labelMapper={mapGeneralFilterText}
                        />
                    </ColumnContainer>
                    <FilterDivider direction="column" />
                    <ColumnContainer>
                        {planningPageState.type === EPlanningType.DUMPSTER && (
                            <>
                                <GenericFilter
                                    reset={reset}
                                    setFilterClassNames={setFilterClassNames}
                                    objectKey="family"
                                    val={familyChecked}
                                    setVal={setFamilyChecked}
                                    initialValues={initialFamilyFiltersChecked}
                                    title="Prestation"
                                    labelMapper={mapCollectConfigFamilyText}
                                />
                                <FilterDivider direction="row" />
                            </>
                        )}
                        {planningPageState.type === EPlanningType.DUMPSTER ? (
                            <GenericFilter
                                reset={reset}
                                setFilterClassNames={setFilterClassNames}
                                objectKey="volume"
                                val={bnVolumeChecked}
                                setVal={setBnVolumeChecked}
                                initialValues={initialBNVolumeFiltersChecked}
                                title="Volume"
                                labelMapper={(val) => {
                                    if (val === 'other') return 'Autre';
                                    return `${val}m³`;
                                }}
                            />
                        ) : (
                            <GenericFilter
                                reset={reset}
                                setFilterClassNames={setFilterClassNames}
                                objectKey="volume"
                                val={bbVolumeChecked}
                                setVal={setBbVolumeChecked}
                                initialValues={initialBBVolumeFiltersChecked}
                                title="Volume"
                                labelMapper={(val) => {
                                    if (val === 'other') return 'Autre';
                                    return `${val}m³`;
                                }}
                            />
                        )}
                    </ColumnContainer>
                </FilterContainer>
                <PRButton
                    label="Réinitialiser"
                    className="p-button-text p-button-sm"
                    onClick={() => {
                        setReset(true);
                    }}
                />
            </StyledOverlayPanel>
        </>
    );
};

export const FiltersContainer = styled.div<{
    classnames: PlanningFilterClassNames;
}>`
    ${(props) => {
        /**
         * If no filter is selected, we display all steps
         */
        if (Object.values(props.classnames).every((classnameGroup) => classnameGroup.length === 0)) {
            return `
                    * ${baseStepClassName} {
                        opacity: 100%;
                    }
                `;
        } else {
            const combinations = classNamesCombinations(Object.values(props.classnames));
            return `
                    * .${baseStepClassName} {
                        opacity: 25%;
                    }
                    ${combinations.join(',')} {
                        opacity: 100%;
                    }
                `;
        }
    }}
`;
