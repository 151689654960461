import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import styled from 'styled-components';
import { Dropdown } from 'primereact/dropdown';

import { BillingBranch, CustomerMandateRo, GetAllCustomerMandateQuery } from '@bbng/util/types';

import { CUSTOMER_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import { Tag } from 'primereact/tag';
import useQuery from '../../hooks/Query';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import moment from 'moment';
import { mapStatusToIcon, mapStatusToSeverity } from './style';
import { mapBillingBranchSeverity, mapMandateStatus } from '../../common/enumMapper';
import { MandateStatus } from 'gocardless-nodejs/types/Types';

const BillingBranchOptions = [
    { name: 'Endless 1', value: BillingBranch.ENDLESS_1 },
    { name: 'Endless 2', value: BillingBranch.ENDLESS_2 },
    { name: 'Endless 3', value: BillingBranch.ENDLESS_3 }
];

const StyledDropdown = styled(Dropdown)`
    width: 300px;
    height: 44px;
`;

const FilterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

type QueryParams = {
    billing_branch?: BillingBranch;
    customer_id?: string;
    status?: MandateStatus;
};

export const MandateListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [billingBranch, setBillingBranch] = React.useState<BillingBranch | undefined>(query.billing_branch);
    const [customer_id, setCustomerId] = React.useState<string | undefined>(query.customer_id);
    const [status, setStatus] = React.useState<MandateStatus | undefined>(query.status);

    return (
        <>
            <Listing<any, CustomerMandateRo>
                url={CUSTOMER_ORCHESTRATION_BASE_URL}
                endpoint="customers/mandates"
                name="Mandat"
                addButtonUrl={urlBuilder.customerMandateCreate()}
                pluralName="Mandats"
                hideBulkMenu
                displayActionColumn={false}
                displaySelectColumn={false}
                disableArchiveAction={true}
                displayArchiveFilters={false}
                displayArchiveColumn={false}
                showSearch={false}
                queryParams={
                    {
                        billing_branch: billingBranch,
                        customer_id,
                        status
                    } as GetAllCustomerMandateQuery
                }
                leftHandedComponent={
                    <FilterContainer>
                        <StyledDropdown
                            value={billingBranch}
                            onChange={(e) => setBillingBranch(e.value)}
                            placeholder="Filtrer par entité de facturation"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={BillingBranchOptions}
                        />
                        <StyledDropdown
                            value={status}
                            options={Object.values(MandateStatus)}
                            itemTemplate={(option) => mapMandateStatus(option)}
                            valueTemplate={(option) => (option ? mapMandateStatus(option) : 'Filtrer par statut')}
                            onChange={(e) => setStatus(e.value)}
                            placeholder="Filtrer par statut"
                            showClear
                        />
                        <RelationAutocomplete.Customer
                            placeholder="Filtrer par client"
                            onSelect={(customer) => setCustomerId(customer?.id)}
                            onUnselect={() => setCustomerId(undefined)}
                        />
                    </FilterContainer>
                }
                displayBulkArchiveAction={false}
                headers={[
                    {
                        name      : 'Client',
                        field     : 'customer',
                        component : (data: CustomerMandateRo) => (
                            <a href={urlBuilder.customerView(data.customer?.id as string)}>{data.customer?.name}</a>
                        )
                    },
                    {
                        name      : 'Créé le',
                        field     : 'created_at',
                        component : (data: CustomerMandateRo) => (
                            <span>{moment(data.created_at).format('DD/MM/YYYY à HH:mm')}</span>
                        )
                    },
                    {
                        name      : 'Statut',
                        field     : 'status',
                        component : (data: CustomerMandateRo) => (
                            <div>
                                <Tag
                                    icon={mapStatusToIcon(data.status)}
                                    className="mr-2"
                                    severity={mapStatusToSeverity(data.status)}
                                    value={mapMandateStatus(data.status)}
                                />
                            </div>
                        )
                    },
                    {
                        name      : 'Entité de facturation',
                        field     : 'billing_branch',
                        width     : 85,
                        component : (data: CustomerMandateRo) => {
                            const billingBranch = data.billing_branch;
                            return (
                                <div>
                                    <Tag
                                        className="mr-2"
                                        severity={mapBillingBranchSeverity(billingBranch)}
                                        value={billingBranch.replace(/_/g, ' ')}
                                    />
                                </div>
                            );
                        }
                    }
                ]}
            />
            <ConfirmDialog />
        </>
    );
};
