import { Divider } from 'primereact/divider';

import { EPlanningType } from '@bbng/util/types';

import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { getPlanningMetrics } from './helpers';
import {
    CACardContainer,
    CAContainer,
    OverviewHeader,
    OverviewLabel,
    OverviewLineWrapper,
    OverviewValue
} from './style';
import { useMemo } from 'react';
import { isCollectService } from '../../modules/planning/shifts/helpers';

export const PlanningStats: React.FC = (): JSX.Element => {
    const {
        ccsService,
        ccsAdministrative,
        planningPageState,
        unnassignedCCService,
        unnassignedCCAdministrative,
        plannings,
        collects
    } = usePlanningV2Store();

    const ccs = [
        ...Object.entries(ccsService).map(([key, value]) => ({ ...value, key })),
        ...Object.entries(ccsAdministrative).map(([key, value]) => ({ ...value, key }))
    ];
    const unassigned_ccs = [...unnassignedCCService, ...unnassignedCCAdministrative];

    const metrics = getPlanningMetrics([...Object.values(plannings)], ccs, planningPageState, unassigned_ccs);

    const plannedCA = useMemo(() => {
        const rawCA = Object.values(ccsService).reduce((acc, cc) => {
            return acc + cc.price.discounted_net.amount / 100;
        }, 0);
        return rawCA.toFixed(2);
    }, [ccsService]);

    const actualCA = useMemo(() => {
        const rawCA = Object.values(collects).reduce((acc, collect) => {
            if (isCollectService(collect)) {
                return acc + collect.informations.price.discounted_net.amount / 100;
            }
            return acc;
        }, 0);
        return rawCA.toFixed(2);
    }, [collects]);

    return (
        <div style={{ minWidth: '1000px' }}>
            <CAContainer>
                <CACard value={plannedCA} label="CA prévisionnel" />
                <CACard value={actualCA} label="CA réalisé" />
            </CAContainer>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Divider align="center">
                        <span className="p-tag">Statistiques globales</span>
                    </Divider>
                    <OverviewLineWrapper>
                        <OverviewHeader>DESCRIPTION</OverviewHeader>
                        <OverviewHeader>NOMBRES</OverviewHeader>
                        <OverviewLine label="Collectes: " value={metrics.nb_cc_service} />
                        {planningPageState.type === EPlanningType.BIG_BAG && (
                            <OverviewLine label="Collectes scindées: " value={metrics.nb_cc_service_splitted} />
                        )}
                        <OverviewLine label="Collectes administratives: " value={metrics.nb_cc_administrative} />
                        <Divider style={{ marginTop: '-10px' }} />
                        <OverviewLine label="Collectes total: " value={metrics.nb_cc_total} />
                        <OverviewLabel />
                    </OverviewLineWrapper>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Divider align="center">
                        <span className="p-tag">Statistiques par statut</span>
                    </Divider>
                    <OverviewLineWrapper>
                        <OverviewHeader>DESCRIPTION</OverviewHeader>
                        <OverviewHeader>NOMBRES</OverviewHeader>
                        {planningPageState.type !== EPlanningType.DUMPSTER && <OverviewHeader>VOLUMES</OverviewHeader>}
                        <OverviewLine
                            label="A planifier: "
                            value={metrics.nb_cc_service_to_plan}
                            volume={
                                planningPageState.type === EPlanningType.BIG_BAG
                                    ? metrics.volume_cc_service_to_plan
                                    : undefined
                            }
                            unit={planningPageState.type === EPlanningType.BIG_BAG ? 'm³' : undefined}
                        />
                        <OverviewLine
                            label="Planifiées: "
                            value={metrics.nb_cc_service_planned}
                            volume={
                                planningPageState.type === EPlanningType.BIG_BAG
                                    ? metrics.volume_cc_service_planned
                                    : undefined
                            }
                            unit={planningPageState.type === EPlanningType.BIG_BAG ? 'm³' : undefined}
                        />
                        <OverviewLine
                            label="Terminées: "
                            value={metrics.nb_cc_service_finished}
                            volume={
                                planningPageState.type === EPlanningType.BIG_BAG
                                    ? metrics.volume_cc_service_finished
                                    : undefined
                            }
                            unit={planningPageState.type === EPlanningType.BIG_BAG ? 'm³' : undefined}
                        />
                        <OverviewLine
                            label="En aléas: "
                            value={metrics.nb_cc_service_hazard}
                            volume={
                                planningPageState.type === EPlanningType.BIG_BAG
                                    ? metrics.volume_cc_service_hazard
                                    : undefined
                            }
                            unit={planningPageState.type === EPlanningType.BIG_BAG ? 'm³' : undefined}
                        />
                        <OverviewLine
                            label="A payer: "
                            value={metrics.nb_cc_service_to_pay}
                            volume={
                                planningPageState.type === EPlanningType.BIG_BAG
                                    ? metrics.volume_cc_service_to_pay
                                    : undefined
                            }
                            unit={planningPageState.type === EPlanningType.BIG_BAG ? 'm³' : undefined}
                        />
                        <Divider style={{ marginTop: '-10px' }} />
                        <OverviewLine
                            label="Collectes total: "
                            value={metrics.nb_cc_total}
                            volume={
                                planningPageState.type === EPlanningType.BIG_BAG ? metrics.volume_cc_service : undefined
                            }
                            unit={planningPageState.type === EPlanningType.BIG_BAG ? 'm³' : undefined}
                        />
                    </OverviewLineWrapper>
                </div>
            </div>
            {planningPageState.type === EPlanningType.DUMPSTER && (
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Divider align="center">
                            <span className="p-tag">Nombre de bennes par type de prestations</span>
                        </Divider>
                        <OverviewLineWrapper>
                            <OverviewHeader>DESCRIPTION</OverviewHeader>
                            <OverviewHeader>NB AMPLIROLL</OverviewHeader>
                            <OverviewHeader>NB CHAINE</OverviewHeader>
                            <OverviewHeader>TOTAL</OverviewHeader>
                            <OverviewLineByDumpster
                                label="Dépot: "
                                valueAmpliroll={metrics.nb_dumpster_deposit.DUMPSTER_AMPLIROLL}
                                valueChain={metrics.nb_dumpster_deposit.DUMPSTER_CHAIN}
                            />
                            <OverviewLineByDumpster
                                label="Enlèvement: "
                                valueAmpliroll={metrics.nb_dumpster_retrieval.DUMPSTER_AMPLIROLL}
                                valueChain={metrics.nb_dumpster_retrieval.DUMPSTER_CHAIN}
                            />
                            <OverviewLineByDumpster
                                label="Rotation: "
                                valueAmpliroll={metrics.nb_dumpster_rotation.DUMPSTER_AMPLIROLL}
                                valueChain={metrics.nb_dumpster_rotation.DUMPSTER_CHAIN}
                            />
                            <OverviewLineByDumpster
                                label="Attente de chargement:
                                "
                                valueAmpliroll={metrics.nb_dumpster_loadwait.DUMPSTER_AMPLIROLL}
                                valueChain={metrics.nb_dumpster_loadwait.DUMPSTER_CHAIN}
                            />
                            <Divider style={{ marginTop: '-10px' }} />
                            <OverviewLineByDumpster
                                label="Total: "
                                valueAmpliroll={metrics.nb_dumpster_total.DUMPSTER_AMPLIROLL}
                                valueChain={metrics.nb_dumpster_total.DUMPSTER_CHAIN}
                            />
                        </OverviewLineWrapper>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Divider align="center">
                            <span className="p-tag">Répartition par bennes</span>
                        </Divider>
                        <OverviewLineWrapper>
                            <OverviewHeader>VOLUMES</OverviewHeader>
                            <OverviewHeader>NB AMPLIROLL</OverviewHeader>
                            <OverviewHeader>NB CHAINE</OverviewHeader>
                            <OverviewHeader>NB TOTAL</OverviewHeader>
                            {Object.entries(metrics.nb_dumpster_volume).map(([key, value]) => (
                                <OverviewLineByDumpster
                                    label={key + ' m³'}
                                    valueAmpliroll={value.DUMPSTER_AMPLIROLL}
                                    valueChain={value.DUMPSTER_CHAIN}
                                />
                            ))}
                        </OverviewLineWrapper>
                    </div>
                </div>
            )}
            <Divider align="center">
                <span className="p-tag">Nombre d'administratives par statut</span>
            </Divider>
            <OverviewLineWrapper>
                <OverviewHeader>DESCRIPTION</OverviewHeader>
                <OverviewHeader>NOMBRES</OverviewHeader>
                <OverviewLine label="A planifier: " value={metrics.nb_cc_administrative_to_plan} />
                <OverviewLine label="Planifiées: " value={metrics.nb_cc_administrative_planned} />
                <OverviewLine label="Terminées: " value={metrics.nb_cc_administrative_hazard} />
                <OverviewLine label="En aléas: " value={metrics.nb_cc_administrative_finished} />
            </OverviewLineWrapper>
        </div>
    );
};

const OverviewLine = ({
    label,
    value,
    volume,
    unit
}: {
    label: string;
    value: number;
    unit?: string;
    volume?: number;
}) => {
    return (
        <tr>
            <OverviewLabel>{label}</OverviewLabel>
            <OverviewValue> {value}</OverviewValue>
            <OverviewValue>
                {volume} {unit ? unit : ''}
            </OverviewValue>
        </tr>
    );
};

const OverviewLineByDumpster = ({
    label,
    valueAmpliroll,
    valueChain
}: {
    label: string;
    valueAmpliroll: number;
    valueChain: number;
}) => {
    return (
        <tr>
            <OverviewLabel>{label}</OverviewLabel>
            <OverviewValue> {valueAmpliroll}</OverviewValue>
            <OverviewValue> {valueChain}</OverviewValue>
            <OverviewValue>{valueAmpliroll + valueChain}</OverviewValue>
        </tr>
    );
};

const CACard = ({ value, label }: { value: string; label: string }) => {
    return (
        <CACardContainer>
            <span className="ca-card-value">{value}€</span>
            {label}
        </CACardContainer>
    );
};
