import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { useMemo } from 'react';
import { Tag } from 'primereact/tag';
import { Checkbox } from 'primereact/checkbox';
import { useLocation } from 'react-router-dom';

import { NdlssError } from '@bbng/util/error';
import {
    EPlanningType,
    IBbngResponse,
    PlanningRoFront,
    PlanningVersionDescriptionRo,
    PRODUCT_DUMPSTER_TYPE
} from '@bbng/util/types';

import { syncRequest } from '../../../common/syncRequest';
import { urlApiBuilder } from '../../../common/urlBuilder';
import { Dialog } from '../../../components/Dialog';
import { toast } from '../../../components/Toast';
import { decodeQuery, encodeQuery } from '../../../hooks/Query';
import { GifLoader } from '../../../components/GifLoader';

import { GlobalConfigPlanning, useGlobalConfigContext } from './provider';
import { TruckPlanningBucket } from './truck-planning-bucket';
import { UnassignedCcsBucket } from './unassigned-ccs-bucket';
import { CheckboxButton } from '../../../components/Button/Checkbox';
import { DumpsterSelector } from '../../../components/DumpsterSelector';
import { BoardContainer, HeaderContainer, SubmitButtonContainer } from './style';

type BoardProps = {
    //
};
export const Board: React.FC<BoardProps> = (props) => {
    const { search } = useLocation();

    const { plannings, editVisibilityPlanning, formatManualShiftDto, type, dumpsterTypes, setDumpsterTypes } =
        useGlobalConfigContext();
    const refMenu = React.useRef<Menu>(null);
    const [onCallApi, setOnCallApi] = React.useState<boolean>(false);
    const [selectedPlanning, setSelectedPlanning] = React.useState<GlobalConfigPlanning | undefined>(undefined);
    const [planningSelection, setPlanningSelection] = React.useState<string[]>([]);
    const [action, setAction] = React.useState<'SHOW_ALL' | 'SHOW_ONE' | 'CALCULATE' | null>(null);
    const [ignoreHere, setIgnoreHere] = React.useState<boolean>(false);

    const handleApiCall = React.useCallback(async () => {
        try {
            setOnCallApi(true);
            const dtos = formatManualShiftDto(planningSelection, ignoreHere);

            const responses: IBbngResponse<PlanningRoFront[]>[] = [];

            for (const dto of dtos) {
                const lastVersion: PlanningVersionDescriptionRo | undefined =
                    responses.length > 0
                        ? responses[responses.length - 1].data.ro?.[0]?.planning_version_description
                        : decodeQuery(window.location.search)['redis_version_description'];

                dto.redis_version_key = lastVersion?.key;

                const res = await syncRequest<PlanningRoFront[]>({
                    method  : 'PATCH',
                    url     : urlApiBuilder.planningManualShift(),
                    payload : dto
                });

                if (res.success) {
                    responses.push(res.response!);
                    toast({
                        severity : 'success',
                        summary  : 'Succès',
                        detail   : `Le planning du camion ${dto.parameters.truck_id} a été mis à jour avec succès.`
                    });
                } else {
                    const errMessage = res.response?.data?.message ?? res.error?.message;
                    toast({
                        severity : 'error',
                        summary  : 'Erreur',
                        detail   : `Une erreur est survenue lors de la mise à jour des plannings.\n${
                            errMessage ? NdlssError.humanize(errMessage).message : ''
                        }`
                    });
                }
            }

            if (responses.length > 0) {
                const lastVersion = responses[responses.length - 1]?.data.ro?.[0]?.planning_version_description;
                if (lastVersion) {
                    toast({
                        severity : 'success',
                        summary  : 'Succès',
                        detail   : `Les plannings ont été mis à jour avec succès.`
                    });
                    // THIS DOES NOT WORK
                    // nav({
                    //     pathname : urlBuilder.planning(),
                    //     search   : `?${encodeQuery({ ...decodeQuery(search), redis_version_description: lastVersion })}`,
                    // });
                    // DO THIS INSTEAD (but it's not the best way to do it)
                    window.location.search = `?${encodeQuery({
                        ...decodeQuery(window.location.search),
                        redis_version_description: lastVersion
                    })}`;
                }
            }
        } catch (e) {
            console.error(e);
        }
        setOnCallApi(false);
    }, [formatManualShiftDto, search, planningSelection, window.location.search, ignoreHere]);

    const invisibleTruck = React.useMemo(() => {
        return plannings
            .filter((f) => !f.visible)
            .map((e) => ({ value: e, label: `${e.truck.name}, ${e.timeline.length} collecte(s) assignée(s)` }));
    }, [plannings]);

    const menuOptions = React.useMemo<MenuItem[]>(
        () => [
            {
                label   : 'Ajouter tous les camions',
                icon    : 'pi pi-eye',
                command : () => {
                    setAction('SHOW_ALL');
                }
            },
            {
                label   : 'Ajouter un camion',
                icon    : 'pi pi-eye',
                command : () => {
                    setAction('SHOW_ONE');
                }
            }
        ],
        [plannings]
    );

    const resetModalStates = React.useCallback(() => {
        setSelectedPlanning(undefined);
        setPlanningSelection([]);
    }, []);

    const filteredPlannings = useMemo(() => {
        if (type === EPlanningType.BIG_BAG) return plannings;
        return plannings.filter((planning) => {
            const truck = planning.truck;
            return truck.type.some((tpe) => dumpsterTypes.includes(tpe as PRODUCT_DUMPSTER_TYPE));
        });
    }, [plannings, type, dumpsterTypes]);

    return (
        <BoardContainer>
            <CalculLoader show={onCallApi} />
            <HeaderContainer>
                {type === EPlanningType.DUMPSTER && (
                    <DumpsterSelector dumpsterTypes={dumpsterTypes} setDumpsterTypes={setDumpsterTypes} />
                )}
                <SubmitButtonContainer>
                    <CheckboxButton
                        label="Ignorer HERE.COM"
                        labelPosition="right"
                        id="ignore_here"
                        value={ignoreHere}
                        result={setIgnoreHere}
                    />
                    <Button
                        onClick={() => {
                            resetModalStates();
                            setAction('CALCULATE');
                        }}
                    >
                        Calculer avec la configuration actuelle
                    </Button>
                </SubmitButtonContainer>
            </HeaderContainer>
            <UnassignedCcsBucket />
            <section id="board-plannings" style={{ display: 'flex', flex: 1 }}>
                {filteredPlannings
                    .filter((f) => f.visible)
                    .map((planning, i) => (
                        <TruckPlanningBucket key={planning.id} id={planning.id} />
                    ))}
                {invisibleTruck.length > 0 && (
                    <div style={{ width: '200px', height: '70px' }}>
                        <Button
                            style={{ width: '200px', height: '70px' }}
                            icon="pi pi-plus"
                            className="button-more p-button-outlined"
                            onClick={(event) => {
                                resetModalStates();
                                refMenu.current?.toggle(event);
                            }}
                        />
                        <Menu model={menuOptions} popup ref={refMenu} />
                    </div>
                )}
            </section>
            <Dialog
                onHide={() => {
                    setAction(null);
                }}
                visible={action === 'CALCULATE'}
                header={<h3 style={{ margin: 0, marginRight: '16px', width: '400px' }}>Calculer les plannings</h3>}
            >
                <p>Selectionnez les tournées que vous souhaitez recalculer.</p>
                <p>Les tournées non sélectionnées seront conservées.</p>
                <Tag
                    value="Seules les tournées ayant un chauffeur sont affichées ici."
                    icon="pi pi-info-circle"
                    severity="warning"
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        className="p-button-text"
                        label="Tout sélectionner"
                        onClick={() => {
                            setPlanningSelection(filteredPlannings.filter((f) => f.collector).map((m) => m.id));
                        }}
                    />
                    <Button
                        className="p-button-text"
                        label="Tout désélectionner"
                        onClick={() => {
                            setPlanningSelection([]);
                        }}
                    />
                </div>
                <div
                    style={{
                        display       : 'flex',
                        flexDirection : 'column',
                        marginTop     : '16px',
                        gap           : '8px',
                        maxHeight     : '20vw',
                        overflowY     : 'auto'
                    }}
                >
                    {filteredPlannings
                        .filter((f) => f.collector)
                        .map((planning) => (
                            <div key={planning.id} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Checkbox
                                    checked={planningSelection.includes(planning.id)}
                                    onChange={(e) => {
                                        if (e.checked) {
                                            setPlanningSelection(
                                                planningSelection.filter((f) => f !== planning.id).concat(planning.id)
                                            );
                                        } else {
                                            setPlanningSelection(planningSelection.filter((f) => f !== planning.id));
                                        }
                                    }}
                                />
                                {planning.truck.name} - {planning.collector?.fullname} - {planning.timeline.length}{' '}
                                étape{planning.timeline.length > 1 ? 's' : ''}
                            </div>
                        ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Button
                        label="Annuler"
                        className="p-button-text"
                        onClick={() => {
                            setAction(null);
                        }}
                    />
                    <Button
                        label="Confirmer"
                        className="p-button-text"
                        onClick={() => {
                            setAction(null);
                            handleApiCall();
                        }}
                    />
                </div>
            </Dialog>
            <Dialog
                onHide={() => {
                    setAction(null);
                }}
                visible={action === 'SHOW_ALL'}
                header={<h3 style={{ margin: 0, marginRight: '16px' }}>Afficher tous les camions</h3>}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Button
                        label="Annuler"
                        className="p-button-text"
                        onClick={() => {
                            setAction(null);
                        }}
                    />
                    <Button
                        label="Valider"
                        className="p-button-text"
                        onClick={() => {
                            setAction(null);
                            editVisibilityPlanning('all', true);
                        }}
                    />
                </div>
            </Dialog>
            <Dialog
                onHide={() => {
                    setAction(null);
                }}
                visible={action === 'SHOW_ONE'}
                header={<h3 style={{ margin: 0, marginRight: '16px', width: '300px' }}>Afficher un camion</h3>}
            >
                <h3 style={{ marginTop: 0 }}>Camion à ajouter</h3>
                <Dropdown
                    style={{ width: '100%' }}
                    value={selectedPlanning}
                    options={invisibleTruck}
                    onChange={(e) => {
                        setSelectedPlanning(e.value);
                    }}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Button
                        label="Annuler"
                        className="p-button-text"
                        onClick={() => {
                            setAction(null);
                        }}
                    />
                    <Button
                        label="Valider"
                        className="p-button-text"
                        disabled={!selectedPlanning}
                        onClick={() => {
                            setAction(null);
                            if (!selectedPlanning) return;
                            editVisibilityPlanning(selectedPlanning.id, true);
                        }}
                    />
                </div>
            </Dialog>
        </BoardContainer>
    );
};

const CalculLoader: React.FC<{ show?: boolean }> = ({ show = false }) => {
    return (
        <div
            style={{
                display         : show ? 'grid' : 'none',
                position        : 'fixed',
                top             : 0,
                left            : 0,
                zIndex          : 100,
                width           : '100vw',
                height          : '100vh',
                backgroundColor : 'rgba(0,0,0,0.2)',
                placeItems      : 'center'
            }}
        >
            <GifLoader />
        </div>
    );
};
