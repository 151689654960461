import { InputNumber } from 'primereact/inputnumber';
import * as yup from 'yup';

import { genericNumberInputProps } from '.';
import { useComponentInput } from '../../hooks/ComponentInput';

export function NumberInput({
    label,
    readOnly,
    required,
    result,
    id,
    validationSchema,
    value = null,
    errors,
    max,
    min,
    displayError,
    showRequiredStar = true,
    step = 1,
    suffix,
    decimal = false,
    size = 15,
    maxNumberOfDigit = 2
}: genericNumberInputProps) {
    const baseValidationSchema = yup.number().nullable();
    const schema = validationSchema
        ? validationSchema
        : required
        ? baseValidationSchema.required(`${label} requis`)
        : baseValidationSchema;

    const { handleBlur, handleOnChange, val, err, displayErr } = useComponentInput({
        id,
        value,
        errors,
        displayError        : displayError ?? false,
        result,
        required,
        schema,
        didRevalidateSchema : (e) => !!e && !isNaN(e) && e !== null,
        mapValue            : (e) => e.value
    });

    return (
        <span className="p-float-label">
            <InputNumber
                disabled={readOnly}
                locale="fr-FR"
                showButtons
                type="text"
                name={id}
                className={`p-inputtext-sm ${displayErr && err && err.length > 0 ? 'p-invalid' : ''}`}
                id={id}
                onChange={handleOnChange}
                onBlur={handleBlur}
                value={val}
                tooltip={displayErr && err && err.length > 0 ? err.join('\n') : ''}
                size={size}
                max={max}
                min={min}
                step={step}
                suffix={suffix}
                maxFractionDigits={decimal ? (maxNumberOfDigit ? maxNumberOfDigit : 2) : 0}
            />
            <label htmlFor={id}>{`${label}${required && showRequiredStar ? ' *' : ''}`}</label>
        </span>
    );
}
