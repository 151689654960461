import { Button as PRButton } from 'primereact/button';
import React from 'react';
import styled from 'styled-components';

import { Dialog } from '../../../components/Dialog';
import { usePlanningV2Store } from '../../../hooks/PlanningStoreV2';
import { Board } from './board';
import { GlobalConfigProvider } from './provider';
import { CCRo } from '@bbng/util/types';

export const GlobalConfigButton: React.FC = () => {
    const {
        plannings,
        unnassignedCCAdministrative,
        unnassignedCCService,
        planningPageState,
        collectors,
        landfills,
        trucks,
        ccsService,
        ccsAdministrative,
        customers
    } = usePlanningV2Store();

    const [showDialog, setShowDialog] = React.useState(false);

    const orderedUnassignedCC: Array<CCRo> = [...unnassignedCCAdministrative, ...unnassignedCCService]
        .map((unassignedCC) => {
            return {
                ...unassignedCC,
                from_date: new Date(unassignedCC.from_date)
            };
        })
        .sort((a, b) => a.from_date.getTime() - b.from_date.getTime())
        .map((orderedCC) => {
            return {
                ...orderedCC,
                from_date: orderedCC.from_date.toISOString()
            };
        });

    return (
        <>
            <PRButton
                type="button"
                className="p-button-sm"
                label="Configuration globale"
                onClick={() => setShowDialog(true)}
            />
            <Dialog
                visible={showDialog}
                onHide={() => setShowDialog(false)}
                header="Configuration globale"
                draggable={false}
                blockScroll
                contentStyle={{ overflow: 'scroll', height: '90vh', width: '90vw' }}
            >
                <ModalBody>
                    {showDialog && (
                        <GlobalConfigProvider
                            plannings={Object.values(plannings)}
                            planningInfo={planningPageState}
                            unassignedCcs={orderedUnassignedCC}
                            ccServices={ccsService}
                            ccAdministratives={ccsAdministrative}
                            collectors={collectors}
                            landfills={landfills}
                            trucks={trucks}
                            customers={customers}
                        >
                            <Board />
                        </GlobalConfigProvider>
                    )}
                </ModalBody>
            </Dialog>
        </>
    );
};

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
