import { AutoCompleteChangeParams, AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import React from 'react';

import { match } from '@bbng/util/misc';

import { RelationAutocompleteInput } from '../../../components/RelationAutocompleteInput';
import { toast } from '../../../components/Toast';
import { useRequest } from '../../../hooks/StatelessRequest';

export type RelationAutocompleteBaseProps<DataRo> = {
    baseValue?: DataRo;
    onSelect?: (e?: DataRo) => void;
    onUnselect?: () => void;
    itemTemplate?: (e: DataRo) => string | JSX.Element | React.ReactNode;
    valueTemplate?: (e: DataRo) => string;
    createDataLabel?: string;
    createDataPageUrl?: string;
    suggestionFetchUrl: string;
    hideCreateButton?: boolean;
    field: string;
    queryParams?: Record<string, any>;
    httpDirectRequest?: boolean;
    readOnly?: boolean;
    id?: string;
    label?: string;
    errors?: string[] | null;
    displayError?: boolean;
    placeholder?: string;
};

const empty = () => void 0;
const emptyTemplate = (item: any) => item['id'] ?? 'undefined';

export function RelationAutocompleteBase<DataRo extends Record<string, any>>({
    createDataLabel = 'Nouveau',
    suggestionFetchUrl = '#',
    createDataPageUrl,
    onSelect = empty,
    onUnselect = empty,
    itemTemplate = emptyTemplate,
    valueTemplate = emptyTemplate,
    field,
    hideCreateButton = false,
    httpDirectRequest = false,
    queryParams = {},
    readOnly,
    id,

    errors = [],
    displayError = false,

    /**
     * You have to provide an id to the component if you want to display a label
     */
    label,

    baseValue,
    placeholder
}: RelationAutocompleteBaseProps<DataRo>) {
    /**
     * Requester BBNG
     */
    const statelessRequest = useRequest();

    /**
     * AutoComplete states
     */
    const [suggestion, setSuggestion] = React.useState<DataRo[]>([]);
    const [selected, setSelected] = React.useState<DataRo | undefined>();
    const [val, setVal] = React.useState<string>(baseValue ? valueTemplate(baseValue) : '');

    React.useEffect(() => {
        if (match(baseValue, selected) === false) {
            setSelected(baseValue);
            setSuggestion(baseValue ? [baseValue] : []);
            setVal(baseValue ? valueTemplate(baseValue) : '');
            onSelect(baseValue);
        }
    }, [baseValue]);

    /**
     * Trigger on value change on AutoComplete Input Text
     */
    const handleChange = React.useCallback(
        (param: AutoCompleteChangeParams): void => {
            if (param.originalEvent.type === 'blur') {
                if (!selected) setVal('');
                return;
            }
            setVal(param.value);
            if (selected) {
                setSelected(undefined);
                onUnselect();
            }
        },
        [setVal, setSelected, selected, onUnselect]
    );

    /**
     * Trigger on click of a suggestion value
     */
    const handleSelect = React.useCallback(
        (param: AutoCompleteChangeParams): void => {
            const value: DataRo = param.value;

            setSelected(value);
            setVal(valueTemplate(value));
            onSelect(value);
        },
        [setVal, setSelected, selected, onUnselect, onSelect]
    );

    /**
     * Handle saga query for suggestions
     */
    const fetchQuery = React.useCallback(
        async (query: string) => {
            if (query.length !== 0 && query.length < 3) {
                toast({
                    severity : 'warn',
                    summary  : 'Information',
                    detail   : 'La recherche doit contenir au moins 3 caractères.'
                });
                return;
            }

            const response = await statelessRequest<DataRo[]>(
                {
                    method  : 'GET',
                    url     : suggestionFetchUrl,
                    payload : {
                        queryParams: {
                            ...queryParams,
                            q     : query,
                            limit : 25
                        }
                    }
                },
                httpDirectRequest
            );

            if (response?.response) {
                setSuggestion(response.response.data.ro ?? []);
            }
        },
        [statelessRequest, setSuggestion, queryParams]
    );

    const completeMethod = React.useCallback(
        (e: AutoCompleteCompleteMethodParams) => {
            fetchQuery(e.query);
        },
        [fetchQuery]
    );

    const relationInput: JSX.Element = (
        <RelationAutocompleteInput
            id={id}
            hideCreateButton={hideCreateButton || !createDataPageUrl}
            createDataLabel={createDataLabel}
            createDataUrl={createDataPageUrl!}
            dropdown
            forceSelection
            onChange={handleChange}
            onSelect={handleSelect}
            delay={300}
            completeMethod={completeMethod}
            itemTemplate={itemTemplate}
            value={val}
            placeholder={placeholder}
            suggestions={suggestion}
            field={field}
            disabled={readOnly}
            className={displayError && errors && errors.length > 0 ? 'p-invalid' : ''}
            tooltip={displayError && errors && errors.length > 0 ? errors.join('\n') : ''}
        />
    );

    return (
        <div>
            {label && id ? (
                <span className="p-float-label">
                    {relationInput}
                    <label htmlFor={id}>{label}</label>
                </span>
            ) : (
                relationInput
            )}
        </div>
    );
}
