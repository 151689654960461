import moment from 'moment';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { convertHHMMToDate, getNextBusinessDay } from '@bbng/util/misc';
import {
    CCDensityRo,
    CardErrors,
    ECollectCharacteristic,
    EPlanningType,
    ESlot,
    PRODUCT_FAMILY,
    ISODate,
    PrestaRo
} from '@bbng/util/types';

import { mapCollectConfigCharacteristic } from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import Calendar from '../../components/Calendar';
import { Card } from '../../components/Card';
import { useCCDensityData } from '../../hooks/CCDensityData';
import { useFormModule } from '../../hooks/FormModule';
import {
    CollectInfoSlot,
    CollectInfoSlotErrorState,
    CollectInfoSlotState,
    ESlotPrecision,
    initialErrorState as slotInitialErrorState,
    initialState as slotInitialState
} from './CollectInfo.slot';
import { EOrderTypeForm } from './Type';
import { OrderProductsState } from './Products';
import RelationAutocomplete from '../common/RelationAutocomplete';

const MIN_DIFF_BETWEEN_START_AND_END = 30; // minimum difference between start and end time in minutes

export type OrderCollectInfoProps = {
    readOnly?: boolean;
    value?: OrderCollectInfoState;
    id: string;
    result: (value: OrderCollectInfoState, errors: null | string[] | OrderCollectInfoErrorState, id: string) => void;
    displayError?: boolean;
    showWaitingTimeMinutes?: boolean;
    showRetrievalDate?: boolean;
    disableDayEdition?: boolean;
    showCharacteristics?: boolean;
    showAlreadyAvailable?: boolean;
    create?: boolean;
    displaySlots?: boolean;
    type?: EOrderTypeForm;
    selectedProducts?: OrderProductsState;
    customerName?: string;
};

const selectableCollectCharacteristics = Object.values(ECollectCharacteristic).filter(
    (char) => !char.includes('DUMPSTER')
);

export type OrderCollectInfoState = {
    characteristics?: ECollectCharacteristic[];
    collect_day?: string;
    from_date?: string | Date;
    to_date?: string | Date;
    slot?: CollectInfoSlotState;
    already_available?: boolean;
    already_available_from_date?: string | Date;
    already_available_to_date?: string | Date;
    waiting_time_minutes?: string | Date;
    retrieval_date?: ISODate;
    execution_time_minutes?: string | Date;
    by_presta?: boolean;
    presta?: PrestaRo;
};
export type OrderCollectInfoErrorState = CardErrors<Omit<OrderCollectInfoState, 'slot'>> & {
    slot: CollectInfoSlotErrorState;
};

export const initialState: OrderCollectInfoState = {
    characteristics             : [],
    collect_day                 : moment.max(moment(getNextBusinessDay())).toISOString(),
    slot                        : slotInitialState,
    from_date                   : convertHHMMToDate('05:00'),
    to_date                     : convertHHMMToDate('19:00'),
    already_available           : false,
    already_available_from_date : moment().toDate(),
    already_available_to_date   : moment().add(1, 'hour').toDate(),
    waiting_time_minutes        : convertHHMMToDate('00:45'),
    execution_time_minutes      : convertHHMMToDate('00:10')
};
export const initialErrorState: OrderCollectInfoErrorState = {
    ...generateInitialErrorState(initialState),
    slot: slotInitialErrorState
};

export const OrderCollectInfo: React.FC<OrderCollectInfoProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError,
    showWaitingTimeMinutes,
    showRetrievalDate = false,
    disableDayEdition = false,
    showCharacteristics = true,
    showAlreadyAvailable = true,
    create = false,
    displaySlots = false,
    type,
    selectedProducts,
    customerName
}: OrderCollectInfoProps) => {
    const [slotPrecision, setSlotPrecision] = React.useState<ESlotPrecision>(ESlotPrecision.DAY);
    const { val, setVal, err, setErr } = useFormModule<OrderCollectInfoState, OrderCollectInfoErrorState>({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    const { densityData, isDensityDataLoading } = useCCDensityData({
        date : val.collect_day,
        type :
            type === EOrderTypeForm.COLLECT_BIG_BAG
                ? EPlanningType.BIG_BAG
                : EOrderTypeForm.COLLECT_DUMPSTER
                ? EPlanningType.DUMPSTER
                : undefined
    });

    const handleChange = (
        value: boolean | number | Date | Date[] | string | string[] | CollectInfoSlotState | PrestaRo | undefined,
        errors: string[] | CollectInfoSlotErrorState | null,
        childId: string
    ) => {
        setVal((prev) => {
            const data = { ...prev, [childId]: value };
            setErr((old) => {
                const dataErr = {
                    ...old,
                    [childId]: errors
                };
                const newErr = checkIntegrity(data, dataErr, childId);
                return newErr;
            });
            return data;
        });
    };

    useEffect(() => {
        if (!showWaitingTimeMinutes && err.waiting_time_minutes) {
            setErr((old) => ({
                ...old,
                waiting_time_minutes: null
            }));
        }
        if (Object.values(selectedProducts ?? []).length > 0) {
            const time = handleTime();
            if (time !== val.execution_time_minutes) {
                setVal((prev) => ({
                    ...prev,
                    execution_time_minutes: time
                }));
            }
        }
    }, [showWaitingTimeMinutes, selectedProducts]);

    const handleTime = () => {
        if (selectedProducts === undefined) return convertHHMMToDate('00:10');
        if (
            type === EOrderTypeForm.COLLECT_BIG_BAG &&
            Object.values(selectedProducts).some((prd) => prd.quantity > 0)
        ) {
            const totalMinutes = Object.values(selectedProducts).reduce((acc, product) => {
                const volume = product.quantity * 2; //2 minutes par sac.
                return acc + volume;
            }, 0);
            const time = moment().startOf('day').add(totalMinutes, 'minutes').format('HH:mm');
            return convertHHMMToDate(time);
        }

        if (
            type === EOrderTypeForm.COLLECT_DUMPSTER &&
            Object.values(selectedProducts).some((prd) => prd.quantity > 0)
        ) {
            //Rotation
            if (
                Object.values(selectedProducts).some(
                    (prd) => prd.quantity > 0 && prd.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT
                ) &&
                Object.values(selectedProducts).some(
                    (prd) => prd.quantity > 0 && prd.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL
                )
            ) {
                return convertHHMMToDate('00:25');
            }
            if (
                Object.values(selectedProducts).some(
                    (prd) => prd.quantity > 0 && prd.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT
                )
            ) {
                return convertHHMMToDate('00:10');
            } else if (
                Object.values(selectedProducts).some(
                    (prd) => prd.quantity > 0 && prd.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL
                )
            ) {
                return convertHHMMToDate('00:15');
            } else {
                return convertHHMMToDate('00:10');
            }
        }

        return convertHHMMToDate(initialState.execution_time_minutes ?? '00:10');
    };

    React.useEffect(() => {
        if (showRetrievalDate && val.retrieval_date === undefined) {
            const date = handleRetrievalDate(val.collect_day);
            handleChange(date, null, 'retrieval_date');
        }
    }, [val.collect_day, showRetrievalDate]);

    const handleRetrievalDate = (date: string | Date | undefined): string => {
        const retrieval = moment(date).add(10, 'day');

        if (retrieval.day() === 0) {
            retrieval.add(1, 'day');
        } else if (retrieval.day() === 6) {
            retrieval.add(2, 'day');
        }

        return retrieval.toISOString();
    };

    const renderExecutionTime = () => {
        if (selectedProducts === undefined || type === EOrderTypeForm.DELIVERY) return <></>;
        return (
            <TimeWithLabel>
                <span>Durée de la prestation (ne pas inclure la durée d'attente de chargement)</span>
                <Calendar.HoursMins
                    required={true}
                    id="execution_time_minutes"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.execution_time_minutes}
                    errors={err.execution_time_minutes}
                    displayError={displayError}
                    defaultTime={convertHHMMToDate(initialState.execution_time_minutes ?? '2023-01-01T00:10:00')}
                />
            </TimeWithLabel>
        );
    };

    return (
        <StyledCard title="Informations sur la commande">
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <Button.Switch
                    id="by_presta"
                    readOnly={readOnly}
                    result={(value, error, childId) => {
                        handleChange(value, error, childId);
                        handleChange(undefined, ['Le prestataire est requis'], 'presta');
                    }}
                    value={val.by_presta ?? false}
                    label="Prise en charge par un prestataire"
                    labelPosition="left"
                />
                <div style={{ width: '250px' }}>
                    <RelationAutocomplete.Presta
                        readOnly={readOnly || !val.by_presta}
                        placeholder="Selectionner un prestataire"
                        baseValue={val.presta}
                        errors={err.presta}
                        displayError={displayError}
                        onSelect={(value) => {
                            handleChange(value, null, 'presta');
                        }}
                        onUnselect={() => {
                            handleChange(undefined, ['Le prestataire est requis'], 'presta');
                        }}
                    />
                </div>
            </div>
            {showCharacteristics && (
                <FullLineInput>
                    <Button.Group
                        required={false}
                        id="characteristics"
                        options={selectableCollectCharacteristics}
                        readOnly={readOnly}
                        labelMapper={(value) => mapCollectConfigCharacteristic(value as ECollectCharacteristic)}
                        result={handleChange}
                        value={val.characteristics}
                        errors={err.characteristics}
                        displayError={displayError}
                    />
                </FullLineInput>
            )}
            <FullLineInput>
                {!isDensityDataLoading ? (
                    <DensityInfo
                        precision={slotPrecision}
                        densityData={densityData}
                        isDataLoading={isDensityDataLoading}
                    />
                ) : (
                    <div
                        style={{
                            height          : '225px',
                            width           : '450px',
                            display         : 'grid',
                            placeItems      : 'center',
                            backgroundColor : '#00000005'
                        }}
                    >
                        <ProgressSpinner />
                    </div>
                )}
                <div>
                    <FullLineInput>
                        <TimeWithLabel>
                            <span>Le</span>
                            <Calendar.DayMonthYear
                                required={true}
                                id="collect_day"
                                readOnly={readOnly || disableDayEdition}
                                result={handleChange}
                                value={val.collect_day}
                                errors={err.collect_day}
                                displayError={displayError}
                            />
                        </TimeWithLabel>
                        {displaySlots ? (
                            <CollectInfoSlot
                                id="slot"
                                readOnly={readOnly}
                                value={val.slot}
                                result={handleChange}
                                displayError={displayError}
                                onPrecisionChange={(precision) => setSlotPrecision(precision)}
                            />
                        ) : (
                            <>
                                <TimeWithLabel>
                                    <span>A partir de</span>
                                    <Calendar.HoursMins
                                        required={true}
                                        id="from_date"
                                        readOnly={readOnly}
                                        result={handleChange}
                                        value={val.from_date}
                                        errors={err.from_date}
                                        displayError={displayError}
                                    />
                                </TimeWithLabel>
                                <TimeWithLabel>
                                    <span>Jusqu'à</span>
                                    <Calendar.HoursMins
                                        required={true}
                                        id="to_date"
                                        readOnly={readOnly}
                                        result={handleChange}
                                        value={val.to_date}
                                        errors={err.to_date}
                                        displayError={displayError}
                                    />
                                </TimeWithLabel>
                            </>
                        )}
                    </FullLineInput>
                    {showRetrievalDate && (
                        <TimeWithLabel style={{ marginTop: '16px' }}>
                            <span>Retrait estimé le</span>
                            <Calendar.DayMonthYear
                                required={true}
                                id="retrieval_date"
                                readOnly={readOnly}
                                result={handleChange}
                                value={val.retrieval_date ?? handleRetrievalDate(val.collect_day)}
                                errors={err.retrieval_date}
                                displayError={displayError}
                            />
                        </TimeWithLabel>
                    )}
                </div>
            </FullLineInput>
            {showAlreadyAvailable && (
                <FullLineInput>
                    <Button.Switch
                        id="already_available"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.already_available || false}
                        label="Collecte déjà disponible aujourd'hui"
                        labelPosition="left"
                    />
                    <TimeWithLabel>
                        <span>A partir de</span>
                        <Calendar.HoursMins
                            required={val.already_available || false}
                            id="already_available_from_date"
                            readOnly={readOnly || !val.already_available}
                            result={handleChange}
                            value={val.already_available_from_date}
                            errors={err.already_available_from_date}
                            displayError={displayError}
                        />
                    </TimeWithLabel>
                    <TimeWithLabel>
                        <span>Jusqu'à</span>
                        <Calendar.HoursMins
                            required={val.already_available || false}
                            id="already_available_to_date"
                            readOnly={readOnly || !val.already_available}
                            result={handleChange}
                            value={val.already_available_to_date}
                            errors={err.already_available_to_date}
                            displayError={displayError}
                        />
                    </TimeWithLabel>
                </FullLineInput>
            )}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {renderExecutionTime()}
                {showWaitingTimeMinutes && (
                    <TimeWithLabel>
                        <span>Temps d'attente de chargement</span>
                        <Calendar.HoursMins
                            required={true}
                            id="waiting_time_minutes"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.waiting_time_minutes}
                            errors={err.waiting_time_minutes}
                            displayError={displayError}
                            stepMinute={15}
                            validationSchema={yup.date().test({
                                name    : 'waiting_time_minutes',
                                message : 'Le temps de chargement doit être de 45 minutes minimum et 6 heures maximum',
                                test    : (value) => {
                                    const hours = moment(value).hours();
                                    const minutes = moment(value).minutes();
                                    const totalMinutes = minutes + hours * 60;
                                    return totalMinutes >= 45 && totalMinutes <= 6 * 60;
                                }
                            })}
                            defaultTime={convertHHMMToDate('00:45')}
                        />
                    </TimeWithLabel>
                )}
            </div>
        </StyledCard>
    );
};

const FullLineInput = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
`;

const TimeWithLabel = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const StyledCard = styled(Card)``;

const checkIntegrity = (
    data: OrderCollectInfoState,
    errors: OrderCollectInfoErrorState,
    id: string
): OrderCollectInfoErrorState => {
    let errorText = '';
    let alreadyAvailableErrorText = '';
    let prestaErrorText = '';
    if (moment(data.from_date).add(MIN_DIFF_BETWEEN_START_AND_END, 'minute').isAfter(moment(data.to_date))) {
        errorText = `La date de fin doit être au moins ${MIN_DIFF_BETWEEN_START_AND_END} minutes après la date de début`;
    }
    if (data.already_available) {
        if (
            moment(data.already_available_from_date)
                .add(MIN_DIFF_BETWEEN_START_AND_END, 'minute')
                .isAfter(moment(data.already_available_to_date))
        ) {
            alreadyAvailableErrorText = `La date de fin doit être au moins ${MIN_DIFF_BETWEEN_START_AND_END} minutes après la date de début`;
        }
    }
    if (data.by_presta && data.presta === undefined) {
        prestaErrorText = 'Le prestataire est requis';
    }

    return {
        ...errors,
        presta                    : prestaErrorText ? [prestaErrorText] : null,
        to_date                   : errorText ? [errorText] : null,
        already_available_to_date : alreadyAvailableErrorText ? [alreadyAvailableErrorText] : null
    };
};

type ChartData = {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string[];
        borderColor: string[];
        borderWidth: number;
    }[];
};
type DensityInfoProps = {
    densityData?: CCDensityRo;
    isDataLoading?: boolean;
    precision: ESlotPrecision;
};
const DensityInfo: React.FC<DensityInfoProps> = (props) => {
    const [chartData, setChartData] = React.useState<ChartData>();
    const [chartOptions] = React.useState({
        scales: {
            y: {
                beginAtZero: true
            }
        }
    });

    useEffect(() => {
        if (!props.densityData) return;

        const [labels = [], entries = []] =
            props.precision === ESlotPrecision.DAY
                ? [['Journée'], [ESlot.DAY]]
                : props.precision === ESlotPrecision.HALF_DAY
                ? [
                      ['Matin', 'Après-midi'],
                      [ESlot.MORNING, ESlot.AFTERNOON]
                  ]
                : props.precision === ESlotPrecision.TWO_HOUR
                ? [
                      ['5h-8h', '8h-10h', '10h-12h', '12h-14h', '14h-16h', '16h-19h'],
                      [
                          ESlot.FIVE_EIGHT_AM,
                          ESlot.EIGHT_TEN_AM,
                          ESlot.TEN_TWELVE_AM,
                          ESlot.TWELVE_TWO_PM,
                          ESlot.TWO_FOUR_PM,
                          ESlot.FOUR_SEVEN_PM
                      ]
                  ]
                : [];

        const wantedKeys = Object.keys(props.densityData).filter((f) => entries.includes(f as ESlot));

        const data = {
            labels   : labels,
            datasets : [
                {
                    label           : 'Commande prévues',
                    data            : wantedKeys.map((k) => props.densityData![k as ESlot].collectsNumber),
                    backgroundColor : ['#6263c9'],
                    borderColor     : ['#6366F1'],
                    borderWidth     : 1
                }
            ]
        };
        setChartData(data);
    }, [props.densityData, props.precision]);

    return chartData && chartData.datasets[0].data.some((e) => e > 0) ? (
        <Chart type="bar" width="450" height="225" data={chartData} options={chartOptions} id="density-chart" />
    ) : (
        <div
            style={{
                display         : 'flex',
                fontWeight      : 'bold',
                fontSize        : '16px',
                height          : '225px',
                width           : '450px',
                backgroundColor : '#00000005'
            }}
        >
            <p style={{ margin: 'auto' }}>Aucune commande n'est prévue</p>
        </div>
    );
};
