import React from 'react';
import styled from 'styled-components';

import { CardErrors, EPlanningRegion, EPlanningType, LandfillRo } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import Input, { textInputProps } from '../../components/Inputs';
import { useFormModule } from '../../hooks/FormModule';
import RelationAutocomplete from '../common/RelationAutocomplete';

export type CollectLandfillProps = {
    readOnly?: boolean;
    value?: CollectLandfillState;
    id: string;
    result: (
        value: CollectLandfillState,
        errors: null | string[] | CardErrors<CollectLandfillState>,
        id: string
    ) => void;
    displayError?: boolean;
    region?: EPlanningRegion;
    type?: EPlanningType;
    showNumber?: boolean;
    required?: boolean;
};

export type CollectLandfillState = {
    landfill?: LandfillRo;
    number?: string;
    weight?: number;
};

export type CollectLandfillErrorState = CardErrors<CollectLandfillState>;

export const initialState: CollectLandfillState = {
    landfill : undefined,
    number   : '',
    weight   : 0
};

export const initialErrorState: CollectLandfillErrorState = generateInitialErrorState(initialState);

export const initialRef = Array.from(Array(Object.keys(initialState).length)).map(() =>
    React.createRef<textInputProps>()
);

export const CollectLandfill = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false,
    region,
    type,
    showNumber = true,
    required = true
}: CollectLandfillProps) => {
    const { val, setVal, err, setErr } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    const handleChange = (
        value: string[] | string | LandfillRo | undefined | null | number,
        errors: string[] | null,
        childId: string
    ) => {
        setVal((prev) => {
            if (prev[childId as keyof typeof prev] === value) return prev;
            const data = { ...prev, [childId]: value };
            setErr((old) => {
                return {
                    ...old,
                    [childId]: errors
                };
            });
            return data;
        });
    };

    React.useEffect(() => {
        if (value.landfill === undefined && required) {
            setErr((old) => ({ ...old, landfill: ['Ce champ est obligatoire'] }));
        }
    }, []);

    return (
        <StyledCard title="Lieu d'évacuation">
            <FullLineInput>
                <RelationAutocomplete.Landfill
                    region={region}
                    type={type}
                    id="landfill"
                    label="Exutoire"
                    readOnly={readOnly}
                    baseValue={val.landfill}
                    errors={err.landfill}
                    displayError={displayError}
                    onSelect={(value) => {
                        handleChange(value, null, 'landfill');
                    }}
                    onUnselect={() => {
                        required && handleChange(undefined, ["L'exutoire est requis"], 'landfill');
                    }}
                />
                {showNumber && (
                    <Input.Text
                        label="Numéro du bon de pesée"
                        required={true}
                        id="number"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.number || ''}
                        errors={err.number}
                        displayError={displayError}
                    />
                )}
                {value.landfill?.owner_is_endless === false && (
                    <Input.Number
                        label="Poids net de la benne donné par l'exutoire"
                        required={true}
                        id="weight"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.weight || 0}
                        errors={err.weight}
                        displayError={displayError}
                    />
                )}
            </FullLineInput>
        </StyledCard>
    );
};

const StyledCard = styled(Card)``;

const FullLineInput = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
`;
