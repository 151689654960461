import React from 'react';
import styled from 'styled-components';

import { CardErrors, PhoneValue } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import Input, { textInputProps } from '../../components/Inputs';

export type LandfillCenterGeneralProps = {
    readOnly?: boolean;
    value?: LandfillCenterGeneralState;
    id: string;
    result: (
        value: LandfillCenterGeneralState,
        errors: null | string[] | CardErrors<LandfillCenterGeneralState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type LandfillCenterGeneralState = {
    name: string;
    code_name: string;
    siren: string;
    siret: string;
    phone: PhoneValue | null;
    fax: PhoneValue | null;
    average_waiting_minutes: number | null;
    complement: string;
    maximum_daily_volume_m3: number;
    accept_bigbag: boolean;
    accept_dumpster: boolean;
    owner_is_endless: boolean;
};

export type LandfillCenterGeneralErrorState = CardErrors<LandfillCenterGeneralState>;

export const initialState: LandfillCenterGeneralState = {
    name                    : '',
    code_name               : '',
    siren                   : '',
    siret                   : '',
    phone                   : null,
    fax                     : null,
    complement              : '',
    maximum_daily_volume_m3 : 0,
    average_waiting_minutes : null,
    accept_bigbag           : false,
    accept_dumpster         : false,
    owner_is_endless        : false
};

export const initialErrorState: LandfillCenterGeneralErrorState = generateInitialErrorState(initialState);

export const initialRef = Array.from(Array(Object.keys(initialState).length)).map(() =>
    React.createRef<textInputProps>()
);

export const LandfillCenterGeneral = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: LandfillCenterGeneralProps) => {
    const [val, setVal] = React.useState<LandfillCenterGeneralState>(value);
    const [err, setErr] = React.useState<LandfillCenterGeneralErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <StyledCard title="Informations générales">
            <CardLine>
                <Input.Text
                    required
                    label="Nom"
                    id="name"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.name}
                    errors={err.name}
                    displayError={displayError}
                />
                <Input.Text
                    required
                    label="Code"
                    id="code_name"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.code_name}
                    errors={err.code_name}
                    displayError={displayError}
                />
                <Input.Siren
                    required
                    id="siren"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.siren}
                    errors={err.siren}
                    displayError={displayError}
                />
                <Input.Siret
                    required
                    id="siret"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.siret}
                    errors={err.siret}
                    displayError={displayError}
                />
            </CardLine>
            <CardLine>
                <Input.Number
                    required
                    label="Temps de traitement moyen"
                    id="average_waiting_minutes"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.average_waiting_minutes}
                    errors={err.average_waiting_minutes}
                    displayError={displayError}
                    suffix=" minutes"
                />
                <Input.Phone
                    required
                    id="phone"
                    specialLabel="Numéro de téléphone"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.phone}
                    errors={err.phone}
                />
                <Input.Phone
                    required={false}
                    id="fax"
                    specialLabel="Numéro de fax"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.fax}
                    errors={err.fax}
                />
            </CardLine>
            <CardLine>
                <Button.Toggle
                    label={{
                        on  : 'Déchetterie EndLess',
                        off : 'Déchetterie partenaire'
                    }}
                    id="owner_is_endless"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.owner_is_endless}
                />
                {val.owner_is_endless ? (
                    <Input.Number
                        required
                        label="Volume journalier maximum"
                        id="maximum_daily_volume_m3"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.maximum_daily_volume_m3}
                        errors={err.maximum_daily_volume_m3}
                        displayError={displayError}
                        suffix=" m3"
                    />
                ) : (
                    <div />
                )}
            </CardLine>
            <CardLine>
                <Input.Textarea
                    required={false}
                    label="Informations complémentaires"
                    id="complement"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.complement}
                    errors={err.complement}
                    displayError={displayError}
                />
            </CardLine>
            <CardLine>
                <Button.Toggle
                    label={{
                        on  : 'Accepte les bigbags',
                        off : 'Ne prend pas les bigbags'
                    }}
                    id="accept_bigbag"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.accept_bigbag}
                />
                <Button.Toggle
                    label={{
                        on  : 'Accepte les bennes',
                        off : 'Ne prend pas les bennes'
                    }}
                    id="accept_dumpster"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.accept_dumpster}
                />
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
