import Joi from 'joi';

import { CollectRo } from './collect';
import { CollectorRo } from './collector';
import { ConstructionSiteRo } from './construction-site';
import { CustomerRo } from './customer';
import { DiscountRo } from './discount';
import { DumpsterRo } from './dumpster';
import { ObjectKeys } from './misc';
import { OrderRo } from './order';
import { LaDouaneRo } from './response';
import { TruckRo } from './truck';
import { UserRo } from './user';

export type RelationKeys<T> = Extract<ObjectKeys<T>, `${string}_id`>;

export type MapKeyRo = {
    truck_id: TruckRo;
    collector_id: CollectorRo;
    collect_id: CollectRo;
    customer_id: CustomerRo;
    order_id: OrderRo;
    construction_site_id: ConstructionSiteRo;
    dumpster_id: DumpsterRo;
    discount_id: DiscountRo;
    user_id: UserRo;
};

export type MappingKeyRo<Key> = Key extends ObjectKeys<MapKeyRo> ? MapKeyRo[Key] : any;

export type FrontRo<Ro> = {
    [P in keyof Ro]: IsRelationType<Ro, P> extends true
        ? LaDouaneRo<MappingKeyRo<P>>[]
        : HasToRecursive<Ro, P> extends true
        ? FrontRo<Ro[P]>
        : Ro[P];
};

export type IsRelationType<Type, Key extends keyof Type> = Key extends `${string}_id` ? true : false;

export type HasToRecursive<Type, Key extends keyof Type> = Type[Key] extends Record<string, any>
    ? IsRelationType<Type, Key> extends false
        ? true
        : false
    : false;

/**
 * Extend a DTO from this interface to add `connect` and `disconnect`
 * fields inside, holding all relation keys (keys ending with _id)
 *
 * @example
 * interface AdminEditDto extends RelationsDto<AdminRo> { ... }
 */
export interface RelationsDto<Ro> {
    connect?: Partial<Record<RelationKeys<Ro>, string[]>>;
    disconnect?: Partial<Record<RelationKeys<Ro>, string[]>>;
}

/**
 * Create a schema validating `connect` and `disconnect` fields for an EditDtoSchema

 * @param relationKeys Relation keys (keys ending with _id) you want to enable in schema validation
 * @return Joi schema
 *
 * @example
 * const AdminEditDtoSchema = Joi
 *   .object({ ... }
 *   .concat(generateRelationsSchema<AdminRo>('customer_id', 'item_id'));
 */
export const generateRelationsSchema = <T = never>(...relationKeys: RelationKeys<T>[]) =>
    Joi.object({
        connect: relationKeys.reduce(
            (acc, val) => ({
                ...acc,
                [val]: Joi.array().items(Joi.string())
            }),
            {}
        ),
        disconnect: relationKeys.reduce(
            (acc, val) => ({
                ...acc,
                [val]: Joi.array().items(Joi.string())
            }),
            {}
        )
    });
