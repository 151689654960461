import Joi from 'joi';

import { EPlanningRegion } from '../planning';
import { ESlot } from '../order';

export const AllowedDaysDtoSchema = Joi.object({
    '0' : Joi.boolean(),
    '1' : Joi.boolean(),
    '2' : Joi.boolean(),
    '3' : Joi.boolean(),
    '4' : Joi.boolean(),
    '5' : Joi.boolean(),
    '6' : Joi.boolean()
});

export const ZoneCreateDtoSchema = Joi.object({
    name          : Joi.string().required(),
    display_name  : Joi.string().optional().allow(''),
    postal_code   : Joi.array().items(Joi.string()).required(),
    min_weight    : Joi.number(),
    required_slot : Joi.string()
        .valid(...Object.values(ESlot))
        .allow(null)
        .optional(),
    metropole: Joi.string()
        .valid(...Object.keys(EPlanningRegion))
        .required(),
    allowed_days   : AllowedDaysDtoSchema.required(),
    forbidden_days : Joi.object().pattern(Joi.string(), Joi.boolean())
});

export const ZoneUpdateDtoSchema = Joi.object({
    name          : Joi.string(),
    display_name  : Joi.string().optional().allow(''),
    min_weight    : Joi.number(),
    required_slot : Joi.string()
        .valid(...Object.values(ESlot))
        .allow(null)
        .optional(),
    postal_code    : Joi.array().items(Joi.string()),
    metropole      : Joi.string().valid(...Object.keys(EPlanningRegion)),
    allowed_days   : AllowedDaysDtoSchema,
    forbidden_days : Joi.object().pattern(Joi.string(), Joi.boolean())
});
