import { DiscountCustomer, DiscountCustomerProps } from './Customer';
import { DiscountDetails, DiscountDetailsProps } from './Details';
import { DiscountGeneral, DiscountGeneralProps } from './General';

const Modules = {
    GeneralInfo: (props: DiscountGeneralProps) => {
        return <DiscountGeneral {...props} />;
    },
    Customer: (props: DiscountCustomerProps) => {
        return <DiscountCustomer {...props} />;
    },
    Details: (props: DiscountDetailsProps) => {
        return <DiscountDetails {...props} />;
    }
};

export default Modules;
