import { FormikConfig, useFormik } from 'formik';
import { Button } from 'primereact/button';
import React, { useState } from 'react';

import { deepCheckEmptyObject } from '@bbng/util/misc';
import { CustomerMandateFlowCreateRo } from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import { useRequest } from '../../hooks/StatelessRequest';
import Customer from '../../modules/customer';
import {
    MandateFormErrorState,
    MandateFormState,
    initialErrorState,
    initialState,
    mapStateToApiCreateData
} from './helpers';
import { ButtonsContainer, PageForm, PageFormLine } from './style';
import { CustomerMandateErrorState, CustomerMandateState } from '../../modules/customer/Mandate';
import { toast } from '../../components/Toast';

const MandateForm: React.FC = (): JSX.Element => {
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [err, setErr] = useState<MandateFormErrorState>(initialErrorState);
    const [mandateUrls, setMandateUrls] = useState<CustomerMandateFlowCreateRo>();

    const handleSubmit = React.useCallback<FormikConfig<MandateFormState>['onSubmit']>(
        async (data: MandateFormState) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            if (!data.mandate.createOnE1 && !data.mandate.createOnE2) {
                toast({
                    severity : 'error',
                    summary  : 'Erreur',
                    detail   : 'Au moins une entité doit être sélectionnée'
                });
                return;
            }

            setLoading(true);

            const body = mapStateToApiCreateData(data);
            const response = await bbngRequest<CustomerMandateFlowCreateRo>({
                method  : 'POST',
                url     : urlApiBuilder.customerCreateMandateFlow(),
                payload : {
                    body
                }
            });
            setLoading(false);
            if (response.success && response.response?.data.ro) {
                setMandateUrls(response.response.data.ro);
                return;
            }
        },
        [err, bbngRequest]
    );

    const formik = useFormik<MandateFormState>({
        initialValues : initialState,
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (value: CustomerMandateState, errors: CustomerMandateErrorState | string[] | null, id: string): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    if (loading) {
        return <PageLoader loading actionType="create" />;
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <h1>Création de mandat de prélèvement</h1>
            <PageFormLine>
                <Customer.Mandate
                    id="mandate"
                    result={handleChange}
                    value={formik.values.mandate}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            {mandateUrls ? (
                <ButtonsContainer>
                    {mandateUrls.E1Url && (
                        <Button className="p-button-info" onClick={() => window.open(mandateUrls.E1Url, '_blank')}>
                            Remplir le mandat Endless 1
                        </Button>
                    )}
                    {mandateUrls.E2Url && (
                        <Button className="p-button-primary" onClick={() => window.open(mandateUrls.E2Url, '_blank')}>
                            Remplir le mandat Endless 2
                        </Button>
                    )}
                </ButtonsContainer>
            ) : (
                <Button
                    type="submit"
                    label="Valider"
                    disabled={deepCheckEmptyObject(err) === false && formik.submitCount > 0}
                />
            )}
        </PageForm>
    );
};

export default MandateForm;
