import { StyledButton } from './style';

export type AddStepButtonProps = {
    openMenuCallback: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const AddStepButton: React.FC<AddStepButtonProps> = ({ openMenuCallback }): JSX.Element => {
    return (
        <StyledButton
            type="button"
            className="p-button-text"
            label="Ajouter une étape"
            style={{ backgroundColor: 'white' }}
            icon="pi pi-plus"
            onClick={(event) => {
                /**
                 * This avoid accordion toggling when clicking on the button
                 */
                event.preventDefault();
                event.stopPropagation();

                openMenuCallback(event);
            }}
            aria-controls="popup_menu"
            aria-haspopup
        />
    );
};
