import moment from 'moment';

import { ConstructionSiteRo, ISODate, LostDumpsterRo, FindDumpsterQuery } from '@bbng/util/types';

import { mapTrashTypetext } from '../../common/enumMapper';
import { COLLECT_ORCHESTRATION_BASE_URL, urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import React, { useEffect } from 'react';
import useQuery from '../../hooks/Query';
import { Anchor, FiltersContainer, HeaderContainer, FlexContainer, VerticalFlexContainer } from './style';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import Calendar from '../../components/Calendar';
import { Button as PRButton } from 'primereact/button';
import { axiosClient } from '../../common/axios';
import { defaultErrorToast } from '../../common/syncRequest';
import { formatE164ToInternational } from '@bbng/util/misc';
import Input from '../../components/Inputs';
import Button from '../../components/Button';

type QueryParams = {
    customer_id?: string;
    construction_site_id?: string;
    maxDate?: ISODate;
    minDate?: ISODate;
    volume?: number;
    consider_planned_retrievals?: boolean;
};

const initialMinDate = moment()
    .set({
        year   : 2021,
        month  : 0,
        date   : 1,
        hour   : 0,
        minute : 0
    })
    .toISOString();

export const LostDumpstersListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [customer_id, setCustomerId] = React.useState<string | undefined>(query.customer_id);
    const [construction_site_id, setConstructionSiteId] = React.useState<string | undefined>(
        query.construction_site_id
    );
    const [constructionSite, setConstructionSite] = React.useState<ConstructionSiteRo | undefined>(undefined);
    const [maxDate, setMaxDate] = React.useState<ISODate | undefined>(query.maxDate);
    const [minDate, setMinDate] = React.useState<ISODate | undefined>(query.minDate);
    const [volume, setVolume] = React.useState<number | undefined>(query.volume);
    const [considerPlannedRetrievals, setPlannedRetrievals] = React.useState<boolean>(
        query.consider_planned_retrievals ?? true
    );
    const [totalRecords, setTotalRecords] = React.useState<number>(0);
    const [isExportLoading, setIsExportLoading] = React.useState<boolean>(false);

    useEffect(() => {
        /**
         * Each time customer changes, reset construction site
         */
        setConstructionSite(undefined);
        setConstructionSiteId(undefined);
    }, [customer_id]);

    useEffect(() => {
        if (!minDate) {
            setMinDate(initialMinDate);
        }
        if (!maxDate) {
            setMaxDate(moment().toISOString());
        }
    }, []);

    const exportData = async (customer_id?: string, construction_site_id?: string) => {
        setIsExportLoading(true);

        try {
            const response = await axiosClient.get(urlApiBuilder.exportLostDumpsters(), {
                params: {
                    construction_site_id,
                    customer_id,
                    consider_planned_retrievals: considerPlannedRetrievals
                } as FindDumpsterQuery,
                responseType: 'blob'
            });

            const formatedDay = moment().format('YYYY-MM-DD_HH:mm:ss');
            const name = `dumpster-lost-${formatedDay}`;

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.data);

            link.download = `${name}.xlsx`;
            link.click();
            link.remove();
        } catch (e) {
            defaultErrorToast("Une erreur est survenue lors de l'export des bennes perdues");
            console.error(e);
        }
        setIsExportLoading(false);
    };

    return (
        <Listing<any, LostDumpsterRo>
            url={COLLECT_ORCHESTRATION_BASE_URL}
            endpoint="collects/find-dumpsters"
            name="Benne déposée"
            showSearch={false}
            hideCreateButton={true}
            pluralName="Bennes déposées"
            displayActionColumn={false}
            displaySelectColumn={false}
            queryParams={
                {
                    customer_id,
                    construction_site_id,
                    consider_planned_retrievals: considerPlannedRetrievals
                } as FindDumpsterQuery
            }
            displayPaginator={false}
            displayArchiveFilters={false}
            leftHandedComponent={
                <VerticalFlexContainer>
                    <HeaderContainer>
                        <FiltersContainer>
                            <RelationAutocomplete.Customer
                                placeholder="Filtrer par client"
                                onSelect={(customer) => setCustomerId(customer?.id)}
                                onUnselect={() => setCustomerId(undefined)}
                            />
                            <RelationAutocomplete.ConstructionSite
                                readOnly={!customer_id}
                                baseValue={constructionSite}
                                placeholder="Filtrer par chantier"
                                onSelect={(consSite) => {
                                    setConstructionSiteId(consSite?.id);
                                    setConstructionSite(consSite);
                                }}
                                onUnselect={() => setConstructionSiteId(undefined)}
                                customerId={customer_id}
                            />
                            <Calendar.DayMonthYear
                                id=""
                                label="Date de collecte minimale"
                                value={minDate}
                                readOnly={false}
                                required={false}
                                result={(date) => setMinDate(date)}
                                maxDate={maxDate}
                            />
                            <Calendar.DayMonthYear
                                id=""
                                label="Date de collecte maximale"
                                value={maxDate}
                                readOnly={false}
                                required={false}
                                result={(date) => setMaxDate(date)}
                                minDate={minDate}
                            />
                            <Input.Number
                                errors={[]}
                                id=""
                                label="Volume de la benne (m³)"
                                required={false}
                                result={(value) => setVolume(value ?? undefined)}
                                value={volume ?? null}
                                suffix="m³"
                                size={30}
                            />
                            <Button.Switch
                                label="Inclure les enlèvements planifiés (non réalisés)"
                                value={considerPlannedRetrievals}
                                result={(value) => setPlannedRetrievals(value)}
                                id=""
                                labelPosition="right"
                            />
                        </FiltersContainer>
                        <PRButton
                            loading={isExportLoading}
                            loadingIcon="pi pi-spin pi-spinner"
                            label="Exporter les résultats"
                            icon="pi pi-file-excel"
                            aria-describedby="export-help"
                            tooltip="⚠️ L'export ne prend pas en compte les filtres de date de collecte min/max ni de volume."
                            tooltipOptions={{ position: 'left' }}
                            onClick={() => exportData(customer_id, construction_site_id)}
                        />
                    </HeaderContainer>
                    <FlexContainer>
                        Cette liste correspond à toutes les bennes ({totalRecords} bennes) d'Île-de-France ayant été
                        déposées et n'ayant pas de rotation/enlèvement associée.
                    </FlexContainer>
                </VerticalFlexContainer>
            }
            parseData={(data) => {
                const filteredData = data.filter((item) => {
                    const isInDateRange =
                        minDate && maxDate
                            ? moment(item.minimalCollect.completed_at).isBetween(minDate, maxDate)
                            : true;
                    const hasVolume = volume ? item.product.volume_m3 === volume : true;
                    return isInDateRange && hasVolume;
                });
                setTotalRecords(filteredData.length);
                return filteredData;
            }}
            headers={[
                {
                    name      : 'Client',
                    field     : 'customer',
                    component : (data: LostDumpsterRo) => (
                        <a target="_blank" href={urlBuilder.customerView(data.customer.id)} rel="noreferrer">
                            {data.customer.name}
                        </a>
                    )
                },
                {
                    name      : 'Email principal',
                    field     : 'customer',
                    component : (data: LostDumpsterRo) => {
                        if (data.customer.mainEmail) {
                            return (
                                <Anchor href={`mailto:${data.customer.mainEmail}`}>
                                    <i className="pi pi-envelope" />
                                    {data.customer.mainEmail}
                                </Anchor>
                            );
                        } else {
                            return <span>Aucun e-mail</span>;
                        }
                    }
                },
                {
                    name      : 'Téléphone principal',
                    field     : 'customer',
                    component : (data: LostDumpsterRo) => {
                        if (!data.customer.mainPhone) return <span>Aucun téléphone</span>;
                        return (
                            <Anchor
                                href={
                                    data.customer.mainPhone
                                        ? 'tel:' + formatE164ToInternational(data.customer.mainPhone)
                                        : undefined
                                }
                            >
                                <i className="pi pi-phone" />
                                {data.customer.mainPhone ? formatE164ToInternational(data.customer.mainPhone) : ''}
                            </Anchor>
                        );
                    }
                },
                {
                    name      : 'Adresse',
                    field     : 'constructionSite',
                    component : (data: LostDumpsterRo) => (
                        <a
                            target="_blank"
                            href={urlBuilder.constructionSiteView(data.constructionSite.id)}
                            rel="noreferrer"
                        >
                            {data.constructionSite.address.formatted_name}
                        </a>
                    )
                },
                {
                    name      : 'Numéro de collecte',
                    field     : 'minimalCollect',
                    component : (data: LostDumpsterRo) => (
                        <a
                            target="_blank"
                            href={urlBuilder.collectConfigView(data.minimalCollect.informations.collect_config_id)}
                            rel="noreferrer"
                        >
                            {data.minimalCollect.informations.cc_number} /{' '}
                            {data.minimalCollect.informations.order_number}
                        </a>
                    )
                },
                {
                    name      : 'Date de collecte',
                    field     : 'minimalCollect.completed_at' as keyof LostDumpsterRo,
                    component : (data: LostDumpsterRo) => (
                        <a target="_blank" href={urlBuilder.collectView(data.minimalCollect.id)} rel="noreferrer">
                            {moment(data.minimalCollect.completed_at).format('DD/MM/YYYY')}
                        </a>
                    ),
                    sortable: true
                },
                {
                    name      : 'Jours depuis le dépôt',
                    field     : 'minimalCollect.completed_at' as keyof LostDumpsterRo,
                    component : (data: LostDumpsterRo) => {
                        const days = moment().diff(moment(data.minimalCollect.completed_at), 'days');
                        return (
                            <span>
                                {days} jour{days > 1 ? 's' : ''}
                            </span>
                        );
                    },
                    sortable: true
                },
                {
                    name      : 'Benne concernée',
                    field     : 'product.volume_m3' as keyof LostDumpsterRo,
                    component : (data: LostDumpsterRo) => (
                        <span>
                            {data.product.volume_m3}m³ ({mapTrashTypetext(data.product.trash_type)})
                        </span>
                    ),
                    sortable: true
                }
            ]}
        />
    );
};
