import React from 'react';

import Fab from '../Fab';
import SideNavbar from '../Nav';
import ScrollTopButton from '../ScrollTopButton';
import { BaseLayout, Content, Page } from './global.style';

export const withLayout = (children: JSX.Element): JSX.Element => {
    return (
        <BaseLayout>
            <Fab />
            <ScrollTopButton />
            <Page>
                <SideNavbar />
                <Content>{children}</Content>
            </Page>
        </BaseLayout>
    );
};
