import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import { ZoneGetAllQuery, ZoneRo } from '@bbng/util/types';

import { ActionsFormContainer } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import SnackBar from '../../components/SnackBar';
import { useRequest } from '../../hooks/StatelessRequest';
import Zone from '../../modules/zone';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    ZoneFormErrorState,
    ZoneFormState,
    ZoneModulesErrorStates,
    ZoneModulesStates,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiCreateData,
    mapStateToApiEditData
} from './helpers';
import { PageForm, PageFormLine, SubmitButton } from './style';
import equal from 'fast-deep-equal/react';
import { toast } from '../../components/Toast';

type ZoneFormProps = {
    edit?: boolean;
    readOnly?: boolean;
};

const ZonesForm: React.FC<ZoneFormProps> = ({ edit = false, readOnly = false }: ZoneFormProps): JSX.Element => {
    const { id: paramsId } = useParams();
    const navigate = useNavigate();
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [apiState, setApiState] = React.useState<{ form: ZoneFormState; db: ZoneRo }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(edit || readOnly);
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>(
        edit || readOnly ? 'read' : undefined
    );
    const [zoneLoading, setZoneLoading] = useState<boolean>(false);

    const [err, setErr] = useState<ZoneFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<ZoneFormState>['onSubmit']>(
        async (data: ZoneFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            /**
             * Fetch zones with same postal codes to avoid overlap.
             */
            setZoneLoading(true);
            const zonesWithSamePostalCodes = await bbngRequest<ZoneRo[]>({
                method  : 'GET',
                url     : urlApiBuilder.zoneGetAll(),
                payload : {
                    queryParams: {
                        postal_codes: data.general.postal_code
                    } as ZoneGetAllQuery
                },
                options: { toastifySuccess: false }
            });

            if (zonesWithSamePostalCodes.success && zonesWithSamePostalCodes.response?.data.ro) {
                const zones = zonesWithSamePostalCodes.response?.data.ro.filter((zone) => zone.id !== dataId);

                if (zones.length > 0) {
                    const existingPostalCodes = [
                        ...zones.reduce((acc, zone) => {
                            zone.postal_code.forEach((code) => acc.add(code));
                            return acc;
                        }, new Set<string>())
                    ];
                    const overlapPostalCodes = existingPostalCodes.filter((code) =>
                        data.general.postal_code.includes(code)
                    );

                    toast({
                        severity : 'error',
                        summary  : 'Formulaire invalide',
                        detail   : `Les codes postaux suivants sont déjà dans d'autres zones: ${overlapPostalCodes.join(
                            ', '
                        )}.`
                    });
                    setZoneLoading(false);
                    return;
                }
            }
            setZoneLoading(false);

            const method = edit ? 'PATCH' : 'POST';
            const url = edit ? urlApiBuilder.zoneEdit(dataId) : urlApiBuilder.zoneCreate();
            const body = edit
                ? mapStateToApiEditData(data, apiState!.form, apiState!.db)
                : mapStateToApiCreateData(data);
            setLoading(true);
            setOnRequestType('create');
            const response = await bbngRequest<ZoneRo>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);
            setOnRequestType(undefined);
            if (response.success) {
                navigate(urlBuilder.zoneList());
            }
        },
        [err, apiState, bbngRequest]
    );

    const formik = useFormik<ZoneFormState>({
        initialValues : initialState,
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (value: ZoneModulesStates, errors: ZoneModulesErrorStates | string[] | null, id: string): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response = await bbngRequest<ZoneRo>({
            method  : 'GET',
            url     : urlApiBuilder.zoneGet(dataId),
            options : { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            const ro: ZoneRo = response.response?.data.ro;
            const formikState: ZoneFormState = mapApiDataToState(ro);

            setApiState({ form: formikState, db: ro });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (edit || readOnly) {
            fetchData();
        }
    }, []);

    const handleArchive = React.useCallback(() => {
        setLoading(true);
        setOnRequestType(apiState?.db.archived ? 'unarchive' : 'delete');

        bbngRequest({
            method  : 'PATCH',
            url     : urlApiBuilder.zoneArchive(dataId),
            options : { toastifySuccess: false },
            payload : {
                body: {
                    archived: !apiState?.db.archived
                }
            }
        })
            .then((response) => {
                if (response.success) {
                    navigate(urlBuilder.zoneList());
                }
            })
            .finally(() => {
                setLoading(false);
                setOnRequestType(undefined);
            });
    }, [bbngRequest, setLoading, setOnRequestType, apiState]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (errorCode) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.zoneList()} />
        ) : (
            <Error500 />
        );
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <h1>Zone{edit || readOnly ? `: ${apiState?.form?.general.name}` : ''}</h1>
            <ActionsFormContainer
                archived={apiState?.db.archived || false}
                edit={edit}
                readOnly={readOnly}
                // disableSaveOnEdit={JSON.stringify(apiState?.form) === JSON.stringify(formik.values)}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                handleArchiveOnView={handleArchive}
                editPageUrl={urlBuilder.zoneEdit(dataId)}
                historyUrl={urlApiBuilder.zoneHistory(dataId)}
            />
            <PageFormLine>
                <Zone.GeneralInfo
                    id="general"
                    result={handleChange}
                    value={formik.values.general}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            {readOnly === false && (
                <SubmitButton
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        // (JSON.stringify(apiState?.form) === JSON.stringify(formik.values) && edit)
                        (equal(apiState?.form, formik.values) && edit)
                    }
                    loading={zoneLoading}
                />
            )}
            {edit === true && (
                <SnackBar
                    // show={JSON.stringify(apiState?.form) !== JSON.stringify(formik.values)}
                    show={equal(apiState?.form, formik.values) === false}
                    content="Vous avez des modifications non enregistrées"
                />
            )}
        </PageForm>
    );
};

export default ZonesForm;
