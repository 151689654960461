import Dinero from 'dinero.js';
import { StatusCodes } from 'http-status-codes';
import Joi from 'joi';
import moment from 'moment';
import { filter, map, pipe, toArray } from '@fxts/core';

import { BusinessError } from '@bbng/util/error';
import {
    AddressRo,
    AdminRo,
    BillingBranch,
    CCServiceRo,
    CollectService,
    ConstructionSiteRo,
    CustomerRo,
    DiscountRo,
    EBillingBundle,
    EDataType,
    EDiscountType,
    EDiscountUnit,
    EPlanningType,
    ErrorResponsability,
    ICustomerContact,
    IdWithInvoiceId,
    InvoiceRo,
    NdlssPrice,
    OrderRo,
    PRODUCT_FAMILY,
    ProductInCCOrCO,
    ProductRo,
    ZohoLineItem,
    ZohoVatConfig,
    CustomerMandateRo,
    GCMandateActionRo,
    CollectLateCancelation,
    PlanningShiftStepCategory
} from '@bbng/util/types';

import { capitalize } from './util-misc';
import { deepCopy } from './json';

const isCc = (presta: CollectService | CCServiceRo): presta is CCServiceRo => 'address_shipping' in presta;
const isDelivery = (presta: CollectService | CCServiceRo): presta is CCServiceRo =>
    'address_shipping' in presta && !!presta?.address_shipping;

export type MapDataToZohoLineItemsReturnType = Record<
    number,
    { lineItems: ZohoLineItem[]; ccs: IdWithInvoiceId[]; collects: IdWithInvoiceId[]; billingBranch: BillingBranch }
>;

/**
 * Merge splitted collects together (keep non splitted collects as is) and send back the new array.
 * @param {CollectService[]} collects
 * @returns {CollectService[]}
 */
export const mergeSplittedCollects = (collects: CollectService[]): CollectService[] => {
    return pipe(
        collects,
        map((collect) => {
            if (!collect.informations.is_splitted) return collect;
            const siblings = collects.filter(
                (c) =>
                    c.informations.is_splitted === true &&
                    c.informations.collect_config_id === collect.informations.collect_config_id
            );

            const mergedProducts = siblings.reduce((acc, curr) => {
                curr.informations.collected_items.forEach((item) => {
                    if (acc[item.id]) {
                        acc[item.id] = {
                            ...acc[item.id],
                            quantity: acc[item.id].quantity + item.quantity
                        };
                    } else {
                        acc[item.id] = item;
                    }
                });
                return acc;
            }, {} as Record<string, ProductInCCOrCO>);

            return {
                ...collect,
                informations: {
                    ...collect.informations,
                    collected_items : Object.values(mergedProducts),
                    siblings        : siblings.map((c) => ({
                        id         : c.id,
                        invoice_id : c.invoice_id
                    })) // custom key to get id and invoice_id of all siblings
                }
            };
        }),
        filter((collect) => collect.informations.collected_items.length > 0),
        filter((collect) => !collect.informations.prepaid),
        filter((collect) => collect.informations.price.price_per_product.length > 0), // this will remove children splitted collects (only parent splitted collect have a filled price_per_product field)
        toArray
    );
};

export const mapDataToZohoLineItems = (
    customer: CustomerRo,
    collects: CollectService[],
    ccsService: CCServiceRo[],
    ccsDelivery: CCServiceRo[],
    constructionSites: ConstructionSiteRo[],
    orders: OrderRo[],
    config: ZohoVatConfig,
    products: ProductRo[],
    instantInvoice?: boolean, // For instant invoicing (stripe payments)
    allowAlreadyInvoiced?: boolean
): MapDataToZohoLineItemsReturnType => {
    // const updatedCollects = mergeSplittedCollects(collects); //Not necessary anymore, already filtered during a step

    /**
     * Split invoicable prestations in three parts: E1 related items | E2 related items | E3 related items.
     * An item of invoicablePrestation will contain either E1, E2 or E3 items (and only the ones not already invoiced).
     */
    const invoicablePrestations: Array<[CollectService | CCServiceRo, BillingBranch]> = collects.flatMap((e) => {
        const [itemsE1, itemsE2, itemsE3] = e.informations.collected_items.reduce(
            (acc, item) => {
                let idx = 0;
                if (Object.values(BillingBranch).includes(item.billing_branch)) {
                    idx = _findProductIdx(item);
                } else {
                    const fullProduct = products.find((p) => p.id === item.id);
                    if (!fullProduct || !Object.values(BillingBranch).includes(fullProduct?.billing_branch)) {
                        throw new BusinessError({
                            httpCode       : StatusCodes.BAD_REQUEST,
                            responsability : ErrorResponsability.Client,
                            dataType       : EDataType.PRODUCT,
                            code           : 'missing_item',
                            params         : {
                                item: 'product.billing_branch'
                            }
                        });
                    }
                    idx = _findProductIdx(fullProduct);
                }
                acc[idx].push(item);
                return acc;
            },
            [[], [], []] as [ProductInCCOrCO[], ProductInCCOrCO[], ProductInCCOrCO[]]
        );

        if (itemsE1.length > 0 && itemsE2.length > 0 && itemsE3.length > 0) {
            const copyE1 = deepCopy(e);
            const copyE2 = deepCopy(e);
            const copyE3 = deepCopy(e);

            copyE1.informations.collected_items = itemsE1;
            copyE2.informations.collected_items = itemsE2;
            copyE3.informations.collected_items = itemsE3;

            const returnTuples: Array<[CollectService | CCServiceRo, BillingBranch]> = [];

            if (!copyE1.invoicedOnE1 || allowAlreadyInvoiced) returnTuples.push([copyE1, BillingBranch.ENDLESS_1]);
            if (!copyE2.invoicedOnE2 || allowAlreadyInvoiced) returnTuples.push([copyE2, BillingBranch.ENDLESS_2]);
            if (!copyE3.invoicedOnE3 || allowAlreadyInvoiced) returnTuples.push([copyE3, BillingBranch.ENDLESS_3]);

            return returnTuples;
        }

        if (itemsE1.length > 0 && (!e.invoicedOnE1 || allowAlreadyInvoiced)) {
            return [[e, BillingBranch.ENDLESS_1]];
        }

        if (itemsE2.length > 0 && (!e.invoicedOnE2 || allowAlreadyInvoiced)) {
            return [[e, BillingBranch.ENDLESS_2]];
        }

        if (itemsE3.length > 0 && (!e.invoicedOnE3 || allowAlreadyInvoiced)) {
            return [[e, BillingBranch.ENDLESS_3]];
        }

        return [];
    });

    const invoicablePrestationsE1 = invoicablePrestations
        .filter(([, org]) => org === BillingBranch.ENDLESS_1)
        .map(([presta]) => presta)
        .concat(ccsDelivery.filter((cc) => !cc.invoicedOnE1 || allowAlreadyInvoiced))
        .sort((a, b) => {
            const presta1 = isCc(a) ? moment.utc(a.from_date) : moment.utc(a.completed_at);
            const presta2 = isCc(b) ? moment.utc(b.from_date) : moment.utc(b.completed_at);

            return presta1.isBefore(presta2) ? -1 : 1;
        });

    const invoicablePrestationsE2 = invoicablePrestations
        .filter(([, org]) => org === BillingBranch.ENDLESS_2)
        .map(([presta]) => presta)
        .sort((a, b) => {
            const presta1 = isCc(a) ? moment.utc(a.from_date) : moment.utc(a.completed_at);
            const presta2 = isCc(b) ? moment.utc(b.from_date) : moment.utc(b.completed_at);

            return presta1.isBefore(presta2) ? -1 : 1;
        });

    const invoicablePrestationsE3 = invoicablePrestations
        .filter(([, org]) => org === BillingBranch.ENDLESS_3)
        .map(([presta]) => presta)
        .sort((a, b) => {
            const presta1 = isCc(a) ? moment.utc(a.from_date) : moment.utc(a.completed_at);
            const presta2 = isCc(b) ? moment.utc(b.from_date) : moment.utc(b.completed_at);

            return presta1.isBefore(presta2) ? -1 : 1;
        });

    /**
     * Instant invoicing case (for service)
     */
    if (instantInvoice && ccsDelivery.length === 0 && collects.length === 0 && ccsService.length > 0) {
        ccsService.forEach((cc) => {
            if (cc.type === EPlanningType.BIG_BAG) {
                invoicablePrestationsE1.push(cc);
            } else {
                invoicablePrestationsE2.push(cc);
            }
        });
    }

    const zohoLineItemsE1 = _buildZohoLineItems({
        billingBranch         : BillingBranch.ENDLESS_1,
        invoicablePrestations : invoicablePrestationsE1,
        customer,
        ccsService,
        ccsDelivery,
        constructionSites,
        orders,
        config,
        allowAlreadyInvoiced
    });

    const zohoLineItemsE2 = _buildZohoLineItems({
        billingBranch         : BillingBranch.ENDLESS_2,
        invoicablePrestations : invoicablePrestationsE2,
        customer,
        ccsService,
        ccsDelivery,
        constructionSites,
        orders,
        config,
        allowAlreadyInvoiced
    });

    const zohoLineItemsE3 = _buildZohoLineItems({
        billingBranch         : BillingBranch.ENDLESS_3,
        invoicablePrestations : invoicablePrestationsE3,
        customer,
        ccsService,
        ccsDelivery,
        constructionSites,
        orders,
        config,
        allowAlreadyInvoiced
    });

    return Object.fromEntries(
        Object.values(zohoLineItemsE1)
            .filter((items) => items.lineItems.length > 0)
            .concat(Object.values(zohoLineItemsE2).filter((items) => items.lineItems.length > 0))
            .concat(Object.values(zohoLineItemsE3).filter((items) => items.lineItems.length > 0))
            .map((e, idx) => [idx, e])
    );
};

const _buildZohoLineItems = ({
    customer,
    invoicablePrestations,
    ccsService,
    ccsDelivery,
    constructionSites,
    orders,
    config,
    billingBranch,
    allowAlreadyInvoiced
}: {
    customer: CustomerRo;
    invoicablePrestations: (CCServiceRo | CollectService)[];
    ccsService: CCServiceRo[];
    ccsDelivery: CCServiceRo[];
    constructionSites: ConstructionSiteRo[];
    orders: OrderRo[];
    config: ZohoVatConfig;
    billingBranch: BillingBranch;
    allowAlreadyInvoiced?: boolean;
}): MapDataToZohoLineItemsReturnType => {
    switch (customer.billing_bundle) {
        case EBillingBundle.BY_COLLECT:
        case EBillingBundle.GLOBAL: {
            return invoicablePrestations.reduce((acc, presta, idx) => {
                /**
                 * For global billing, initialize only the first idx (to avoid having empty entries)
                 */
                if (customer.billing_bundle !== EBillingBundle.GLOBAL || idx === 0) {
                    acc[idx] = {
                        lineItems : [],
                        ccs       : [],
                        collects  : [],
                        billingBranch
                    };
                }

                const cc = !isCc(presta)
                    ? (ccsService.find((c) => c.id === presta.informations.collect_config_id) as CCServiceRo)
                    : presta;
                const collect = !isCc(presta) ? presta : undefined;
                const address = isCc(presta)
                    ? (presta.address as AddressRo)
                    : (constructionSites.find((cs) => cs.id === cc.construction_site_id[0])?.address as AddressRo);

                /**
                 * Check if has an ordersheet number or an ordersheet document.
                 */
                const order = orders.find((o) => o.id === cc.order_id[0]);
                if (!order) {
                    throw new BusinessError({
                        httpCode       : StatusCodes.BAD_REQUEST,
                        responsability : ErrorResponsability.Client,
                        dataType       : EDataType.COLLECT_CONFIG,
                        code           : 'missing_item',
                        params         : {
                            item: 'order'
                        }
                    });
                }

                [...(collect ? collect.informations.collected_items : cc.products)].forEach((product) => {
                    const headerName = _formatHeaderName({
                        billing_bundle: customer.billing_bundle,
                        cc,
                        collect,
                        order,
                        address
                    });
                    const line = _mapZohoLineItem(
                        customer,
                        presta,
                        product,
                        config,
                        headerName,
                        product.name,
                        cc,
                        false
                    );
                    if (customer.billing_bundle === EBillingBundle.GLOBAL) {
                        acc[0].lineItems.push(line);
                        acc[0].ccs.push({
                            id         : cc.id,
                            invoice_id : cc.invoice_id
                        });
                        if (
                            (collect?.informations as any)?.siblings &&
                            (collect?.informations as any)?.siblings.length > 0
                        ) {
                            acc[0].collects.push(...(collect?.informations as any).siblings);
                        } else {
                            collect?.id &&
                                acc[0].collects.push({
                                    id         : collect.id,
                                    invoice_id : collect.invoice_id
                                });
                        }
                    } else {
                        acc[idx].lineItems.push(line);
                        acc[idx].ccs.push({
                            id         : cc.id,
                            invoice_id : cc.invoice_id
                        });
                        if (
                            (collect?.informations as any)?.siblings &&
                            (collect?.informations as any)?.siblings.length > 0
                        ) {
                            acc[0].collects.push(...(collect?.informations as any).siblings);
                        } else {
                            collect?.id &&
                                acc[idx].collects.push({
                                    id         : collect.id,
                                    invoice_id : collect.invoice_id
                                });
                        }
                    }
                });

                return acc;
            }, {} as MapDataToZohoLineItemsReturnType);
        }
        case EBillingBundle.BY_CONSTRUCTION_SITE:
        case EBillingBundle.GLOBAL_BY_CONSTRUCTION_SITE: {
            const zohoLines = constructionSites.reduce((acc, constructionSite, idx) => {
                /**
                 * For global billing, initialize only the first idx (to avoid having empty entries)
                 */
                if (customer.billing_bundle !== EBillingBundle.GLOBAL_BY_CONSTRUCTION_SITE || idx === 0) {
                    acc[idx] = {
                        lineItems : [],
                        ccs       : [],
                        collects  : [],
                        billingBranch
                    };
                }
                const prestaByConstructionSite = invoicablePrestations.filter((presta) => {
                    if (!isCc(presta)) {
                        const cc = ccsService.find(
                            (c) => c.id === presta.informations.collect_config_id
                        ) as CCServiceRo;
                        return cc.construction_site_id[0] === constructionSite.id;
                    } else {
                        return (presta as CCServiceRo).construction_site_id[0] === constructionSite.id;
                    }
                });
                prestaByConstructionSite.forEach((presta) => {
                    const cc = !isCc(presta)
                        ? (ccsService.find((c) => c.id === presta.informations.collect_config_id) as CCServiceRo)
                        : presta;
                    const collect = !isCc(presta) ? presta : undefined;
                    const address = isDelivery(presta)
                        ? (presta.address_shipping as AddressRo)
                        : (constructionSites.find((cs) => cs.id === cc.construction_site_id[0])?.address as AddressRo);

                    /**
                     * Check if has an ordersheet number or an ordersheet document.
                     */
                    const order = orders.find((o) => o.id === cc.order_id[0]);
                    if (!order) {
                        throw new BusinessError({
                            httpCode       : StatusCodes.BAD_REQUEST,
                            responsability : ErrorResponsability.Client,
                            dataType       : EDataType.COLLECT_CONFIG,
                            code           : 'missing_item',
                            params         : {
                                item: 'order'
                            }
                        });
                    }

                    [...(collect ? collect.informations.collected_items : cc.products)].forEach((product) => {
                        const headerName = _formatHeaderName({
                            billing_bundle: customer.billing_bundle,
                            cc,
                            collect,
                            order,
                            address
                        });
                        const lineName = `${product.name} - ${cc.order_number} - ${
                            isCc(presta) ? _displayTime(cc.from_date) : _displayTime(collect?.completed_at)
                        } ${_addOrderSheetNumber({
                            order_sheet_number : order.order_sheet_number ?? undefined,
                            delivery_number    : collect?.delivery_number ?? undefined
                        })}`;
                        const line = _mapZohoLineItem(
                            customer,
                            presta,
                            product,
                            config,
                            headerName,
                            lineName,
                            cc,
                            false
                        );
                        if (customer.billing_bundle === EBillingBundle.GLOBAL_BY_CONSTRUCTION_SITE) {
                            acc[0].lineItems.push(line);
                            acc[0].ccs.push({
                                id         : cc.id,
                                invoice_id : cc.invoice_id
                            });
                            if (
                                (collect?.informations as any)?.siblings &&
                                (collect?.informations as any)?.siblings.length > 0
                            ) {
                                acc[0].collects.push(...(collect?.informations as any).siblings);
                            } else {
                                collect?.id &&
                                    acc[0].collects.push({
                                        id         : collect.id,
                                        invoice_id : collect.invoice_id
                                    });
                            }
                        } else {
                            acc[idx].lineItems.push(line);
                            acc[idx].ccs.push({
                                id         : cc.id,
                                invoice_id : cc.invoice_id
                            });
                            if (
                                (collect?.informations as any)?.siblings &&
                                (collect?.informations as any)?.siblings.length > 0
                            ) {
                                acc[idx].collects.push(...(collect?.informations as any).siblings);
                            } else {
                                collect?.id &&
                                    acc[idx].collects.push({
                                        id         : collect.id,
                                        invoice_id : collect.invoice_id
                                    });
                            }
                        }
                    });
                });
                return acc;
            }, {} as MapDataToZohoLineItemsReturnType);

            // if (billingBranch === BillingBranch.ENDLESS_1) {
            //     /**
            //      * We need to add the ccs Delivery as they are not linked to a construction site
            //      * so the `const data` will never contain them
            //      */
            //     ccsDelivery
            //         .filter((delivery) => (!delivery.invoicedOnE1 && !delivery.invoicedOnE2) || allowAlreadyInvoiced)
            //         .forEach((delivery) => {
            //             /**
            //              * Check if has an ordersheet number or an ordersheet document.
            //              */
            //             const order = orders.find((o) => o.id === delivery.order_id[0]);
            //             if (!order) {
            //                 throw new BusinessError({
            //                     httpCode       : StatusCodes.BAD_REQUEST,
            //                     responsability : ErrorResponsability.Client,
            //                     dataType       : EDataType.COLLECT_CONFIG,
            //                     code           : 'missing_item',
            //                     params         : {
            //                         item: 'order'
            //                     }
            //                 });
            //             }

            //             delivery.products.forEach((product) => {
            //                 const headerName = _formatHeaderName({
            //                     billing_bundle : customer.billing_bundle,
            //                     cc             : delivery,
            //                     collect        : undefined,
            //                     address        : delivery.address,
            //                     order
            //                 });
            //                 const line = _mapZohoLineItem(
            //                     customer,
            //                     delivery,
            //                     product,
            //                     config,
            //                     headerName,
            //                     product.name,
            //                     delivery,
            //                     false
            //                 );
            //                 if (customer.billing_bundle === EBillingBundle.GLOBAL_BY_CONSTRUCTION_SITE) {
            //                     if (!zohoLines[0]) {
            //                         zohoLines[0] = {
            //                             lineItems : [],
            //                             ccs       : [],
            //                             collects  : [],
            //                             billingBranch
            //                         };
            //                     }
            //                     zohoLines[0].lineItems.push(line);
            //                     zohoLines[0].ccs.push({
            //                         id         : delivery.id,
            //                         invoice_id : delivery.invoice_id
            //                     });
            //                 } else {
            //                     const index = constructionSites.length; // we want to add the delivery at the end of the array
            //                     if (!zohoLines[index]) {
            //                         zohoLines[index] = {
            //                             lineItems : [],
            //                             ccs       : [],
            //                             collects  : [],
            //                             billingBranch
            //                         };
            //                     }
            //                     zohoLines[index].lineItems.push(line);
            //                     zohoLines[index].ccs.push({
            //                         id         : delivery.id,
            //                         invoice_id : delivery.invoice_id
            //                     });
            //                 }
            //             });
            //         });
            // }
            return zohoLines;
        }
        default: {
            return {};
        }
    }
};

const _mapZohoLineItem = (
    customer: CustomerRo,
    prestation: CollectService | CCServiceRo,
    product: ProductInCCOrCO,
    config: ZohoVatConfig,
    header_name: string,
    name: string,
    cc: CCServiceRo,
    displayTime?: boolean
): ZohoLineItem => {
    const price = isCc(prestation) ? prestation.price : prestation.informations.price;
    const productPrices = price.price_per_product;

    const productPrice = productPrices.find((prd) => prd.product_id === product.id);

    if (productPrice === undefined) {
        throw new BusinessError({
            httpCode       : StatusCodes.BAD_REQUEST,
            responsability : ErrorResponsability.Client,
            dataType       : EDataType.INVOICE,
            code           : 'missing_item',
            params         : { item: product.id }
        });
    }

    const discountIsPenalty = productPrice.applied_discount ? productPrice.applied_discount.value < 0 : false;

    return {
        quantity    : product.quantity,
        rate        : _itemRate(discountIsPenalty, productPrice, product.quantity),
        discount    : _itemDiscount(discountIsPenalty, productPrice),
        tax_id      : zohoVatRateMapper(product.vat_rate_percentage, config),
        description : _getLineDescription(prestation, cc, product, displayTime, discountIsPenalty),
        header_name,
        name
    };
};

/**
 * If discount is a penalty, it should appear in the base price to avoid showing it in the invoice.
 * 🚨 Do not forget to divide by 100 as price or in cents !!!!
 */
const _itemRate = (isPenalty: boolean, price: NdlssPrice['price_per_product'][0], quantity: number): number => {
    const rate = Dinero({
        amount    : isPenalty ? price.discounted_net.amount : price.base_net.amount,
        currency  : price.base_net.currency,
        precision : 2
    }).divide(quantity);

    return _formatDineroToZoho(rate);
};

/**
 * If discount is a penalty, it should appear in the base price (not in the discount field).
 * 🚨 Do not forget to divide by 100 as price or in cents !!!!
 */
const _itemDiscount = (isPenalty: boolean, price: NdlssPrice['price_per_product'][0]): number => {
    if (isPenalty) return 0;
    const discount = Dinero({
        amount    : price.base_net.amount - price.discounted_net.amount,
        currency  : price.base_net.currency,
        precision : 2
    });
    const basePrice = Dinero({
        amount    : price.base_net.amount,
        currency  : price.base_net.currency,
        precision : 2
    });

    /**
     * discount is base price if discounted price is 0.
     * This to ensure rate - discount = 0)
     */
    if (price.discounted_net.amount <= 0) return _formatDineroToZoho(basePrice);

    return _formatDineroToZoho(discount);
};

/**
 * Transforms a dinero object (with cents unit) to a number (with euro unit) to be used in Zoho
 * @param {Dinero.Dinero} dinero
 */
const _formatDineroToZoho = (dinero: Dinero.Dinero): number => {
    return Number((dinero.getAmount() / 100).toFixed(2));
};

const _getLineDescription = (
    prestation: CollectService | CCServiceRo,
    cc: CCServiceRo,
    product?: ProductInCCOrCO,
    displayTime?: boolean,
    discountIsPenalty?: boolean
): string => {
    const descriptionLines = [];
    const time = isCc(prestation) ? prestation.updated_at : prestation.arrived_at;
    if (displayTime) descriptionLines.push(`Effectuée le ${_displayTime(time)}`);

    descriptionLines.push(`Zone ${cc.zone.name} (${capitalize(cc.zone.metropole)})`);

    const discounts = isCc(prestation)
        ? prestation.price.applied_discounts
        : prestation.informations.price.applied_discounts;

    /**
     * Display discounts for line only if it matches all following conditions:
     * - product is a bigbag_collect (if discount on bb_delivery applies, it applies on the whole prestation volume) OR product volume is equal to discount min volume
     * - product is a bigbag_delivery (no trash type associated to a delivery) OR trash type matches discount trash type
     *
     * This avoids displaying discounts that apply to the whole prestation but not to current the product
     */
    const discountsForProduct = discounts?.filter(
        (discount) =>
            (product?.family === PRODUCT_FAMILY.COLLECT_BIG_BAG || product?.volume_m3 === discount.min_volume) &&
            (product?.family === PRODUCT_FAMILY.DELIVERY_BIG_BAG || discount.trash_type.includes(product.trash_type))
    );

    /**
     * Do not display discounts if it is
     * - a penalty (already included in the base price)
     * - a free product (no discount to display)
     *
     */
    if (
        !discountIsPenalty &&
        discountsForProduct &&
        discountsForProduct.length > 0 &&
        (product?.price.net_amount_cents || 0) > 0
    ) {
        descriptionLines.push(`Remises appliquées:`);
        discountsForProduct.forEach((discount) => {
            if (
                (product?.family === PRODUCT_FAMILY.COLLECT_BIG_BAG || product?.volume_m3 === discount.min_volume) &&
                (product?.family === PRODUCT_FAMILY.DELIVERY_BIG_BAG ||
                    discount.trash_type.includes(product.trash_type))
            ) {
                descriptionLines.push(`- ${_displayDiscount(discount)}`);
            }
        });
    }
    return descriptionLines.join('\n');
};

const _displayDiscount = (discount: DiscountRo): string => {
    let condition = '';
    let unit = '';
    const m3value = discount.type === EDiscountType.BIG_BAG ? discount.value : discount.value * discount.min_volume;
    if (discount.type === EDiscountType.BIG_BAG) {
        unit = '/m³';
        if (discount.min_volume === discount.max_volume) condition = `pour ${discount.min_volume}m³`;
        if (discount.min_volume === 0) condition = `jusqu'à ${discount.max_volume}m³`;
        else condition = `de ${discount.min_volume} à ${discount.max_volume}m³`;
    } else if (discount.type === EDiscountType.DUMPSTER) {
        unit = '/benne';
        condition = `Pour ${discount.min_volume} m³`;
    } else {
        unit = '/sac';
        condition = `Pour tout sac de ${discount.min_volume}m³`;
    }
    return `${m3value}${discount.unit === EDiscountUnit.AMOUNT ? '€' : '%'}${
        discount.unit === EDiscountUnit.AMOUNT ? unit : ''
    } (${condition})`;
};

const _displayAddress = (address?: AddressRo): string => {
    return `${address?.components['street_number']} ${address?.components['route']}, ${address?.components['postal_code']} ${address?.components['locality']}`;
};

const _displayTime = (time?: string | null): string => {
    return moment(time).format('DD/MM/YYYY');
};

/**
 * Get customer invoice email from its contact (and ensure email are like 'something@domain.extension').
 * By default, it will return invoice contacts emails.
 * If no invoice contact is found, it will return primary contacts emails.
 * If no primary contact is found, it will return all emails given.
 * If no contact is found, it will return empty array.
 * @param {ICustomerContact[]} contact
 *
 * @returns {string[]}
 */
export const getCustomerContactInvoiceEmails = (contact: ICustomerContact[] = []): string[] => {
    if (contact.length === 0) return [];

    const emailSchema = Joi.string().email({ tlds: false });

    const invoiceEmails = contact
        .filter((contact) => contact.is_invoice && contact.email && !emailSchema.validate(contact.email).error)
        .map((contact) => contact.email) as string[];
    if (invoiceEmails.length > 0) return invoiceEmails;
    const primaryEmails = contact
        .filter((contact) => contact.is_primary && contact.email && !emailSchema.validate(contact.email).error)
        .map((contact) => contact.email) as string[];
    if (primaryEmails.length > 0) return primaryEmails;
    return contact
        .filter((contact) => contact.email && !emailSchema.validate(contact.email).error)
        .map((contact) => contact.email) as string[];
};

/**
 * Get customer invoice zoho id from its contact (and ensure email are like 'something@domain.extension').
 * By default, it will return invoice contacts zoho ids.
 * If no invoice contact is found, it will return primary contacts zoho ids.
 * If no primary contact is found, it will return all zoho ids found.
 * If no contact is found, it will return empty array.
 * @param {ICustomerContact[]} contact
 *
 * @returns {string[]}
 */
export const getCustomerContactInvoiceZohoIds = (contact: ICustomerContact[] = []): string[] => {
    const zohoContactEmails = getCustomerContactInvoiceEmails(contact);
    const filteredContacts = contact.filter((contact) => zohoContactEmails.includes(contact?.email as string));
    return filteredContacts
        .filter((contact) => !!contact.id_zoho_contact_person)
        .map((contact) => contact.id_zoho_contact_person as string);
};

export const zohoVatRateMapper = (vatRate: number, config: ZohoVatConfig): string => {
    switch (vatRate) {
        case 5.5:
            return config.zohoVat5_5;
        case 10:
            return config.zohoVat10;
        case 20:
            return config.zohoVat20;
        default:
            return '';
    }
};

/**
 * @description
 * Helper to add the order sheet number and collect delivery number to the zoho description
 * @param order_sheet_number
 * @param delivery_number
 * @returns string
 */
const _addOrderSheetNumber = ({
    delivery_number,
    order_sheet_number
}: {
    order_sheet_number?: string;
    delivery_number?: string;
}) => {
    const text: string[] = [];
    if (order_sheet_number && order_sheet_number !== '') {
        text.push(`- Bon de commande n°: ${order_sheet_number}`);
    }
    if (delivery_number && delivery_number !== '') {
        text.push(`- Bon client n°: ${delivery_number}`);
    }
    return text.join(' ');
};

export const emptyAdminOnInvoice: AdminRo = {
    firstname  : '',
    lastname   : '',
    fullname   : 'Commercial non renseigné',
    email      : '',
    archived   : false,
    id         : '',
    created_at : moment.utc().toISOString(),
    created_by : '',
    updated_at : moment.utc().toISOString(),
    roles      : []
};

/**
 * Calculate payment charge date based on invoices to pay and mandate next possible charge date.
 * Minimum date should be mandate next possible charge date to avoid gocardless error.
 */
export const paymentChargeDate = (
    invoicesToPay: InvoiceRo[],
    mandate: CustomerMandateRo | GCMandateActionRo
): string /*YYYY-MM-DD*/ => {
    /**
     * Get the earliest invoice date to set the charge date.
     */
    const earliestInvoiceChargeDate = invoicesToPay.reduce((acc, inv) => {
        const invoiceDate = moment.utc(inv.scheduled_payment_date);
        return invoiceDate.isBefore(acc) ? invoiceDate : acc;
    }, moment.utc(invoicesToPay[0].scheduled_payment_date));

    if (mandate.next_possible_charge_date) {
        const mandateMinimumChargeDateFormatted = moment.utc(mandate.next_possible_charge_date);

        /**
         * If earliest invoice date is before mandate next possible charge date, set the charge date to mandate next possible charge date (to avoid gocardless error).
         */
        if (earliestInvoiceChargeDate.isBefore(mandateMinimumChargeDateFormatted)) {
            return mandateMinimumChargeDateFormatted.format('YYYY-MM-DD');
        } else {
            /**
             * Otherwise, keep the earliest invoice date.
             */
            return earliestInvoiceChargeDate.format('YYYY-MM-DD');
        }
    } else {
        return earliestInvoiceChargeDate.format('YYYY-MM-DD');
    }
};

/**
 * Format invoice header name based on context
 */
const _formatHeaderName = ({
    billing_bundle,
    cc,
    collect,
    order,
    address
}: {
    billing_bundle: EBillingBundle;
    cc: CCServiceRo;
    collect?: CollectService | CollectLateCancelation;
    order: OrderRo;
    address: AddressRo;
}): string => {
    const formattedAddress = _displayAddress(address);
    const formattedTime = _displayTime(collect ? collect.completed_at : cc.from_date);
    const formattedOrderNumber = _addOrderSheetNumber({
        order_sheet_number : order.order_sheet_number ?? undefined,
        delivery_number    : collect?.delivery_number ?? undefined
    });
    const deliveryContext = !!cc.address_shipping;
    const lateCancelationContext = collect?.category === PlanningShiftStepCategory.LATE_CANCELATION;

    switch (billing_bundle) {
        case EBillingBundle.BY_COLLECT:
        case EBillingBundle.GLOBAL: {
            if (deliveryContext) {
                return `Livraison ${cc.order_number} du ${formattedTime}, au ${formattedAddress} ${formattedOrderNumber}`;
            } else if (lateCancelationContext) {
                return `Annulation tardive ${cc.order_number} du ${formattedTime}, au ${formattedAddress} ${formattedOrderNumber}`;
            } else {
                return `Collecte ${cc.order_number} du ${formattedTime}, au ${formattedAddress} ${formattedOrderNumber}`;
            }
        }
        case EBillingBundle.BY_CONSTRUCTION_SITE:
        case EBillingBundle.GLOBAL_BY_CONSTRUCTION_SITE: {
            // if (deliveryContext) {
            // return `Livraison ${cc.order_number} du ${formattedTime}, au ${formattedAddress} ${formattedOrderNumber}`;
            // } else {
            return `Chantier ${formattedAddress}`;
            // }
        }
    }
};

const _findProductIdx = (fullProduct: ProductRo | ProductInCCOrCO): number => {
    switch (fullProduct?.billing_branch) {
        case BillingBranch.ENDLESS_1:
            return 0;
        case BillingBranch.ENDLESS_2:
            return 1;
        case BillingBranch.ENDLESS_3:
            return 2;
    }
};
