import { AutoComplete, AutoCompleteProps, AutoCompleteSelectParams } from 'primereact/autocomplete';
import React from 'react';
import styled from 'styled-components';

export type RelationAutocompleteInputProps = AutoCompleteProps & {
    hideCreateButton?: boolean;
    createDataUrl: string;
    createDataLabel: string;
};

const AUTOCOMPLETE_ITEM = {
    id: 'relation-autocomplete'
};

export const RelationAutocompleteInput: React.FC<RelationAutocompleteInputProps> = ({
    createDataUrl,
    createDataLabel,
    suggestions = [],
    hideCreateButton = false,
    ...props
}) => {
    const itemTemplate = React.useCallback(
        (item: any, index: number) => {
            if (item?.id === AUTOCOMPLETE_ITEM.id) {
                return <CreateDataText>{createDataLabel}</CreateDataText>;
            }

            if (props.itemTemplate) {
                return typeof props.itemTemplate === 'function' ? props.itemTemplate(item, index) : props.itemTemplate;
            }

            return props.field ? item[props.field] : item;
        },
        [props.field, props.itemTemplate, createDataLabel]
    );

    const onSelect = React.useCallback(
        (event: AutoCompleteSelectParams) => {
            if (event.originalEvent.type === 'blur') {
                return;
            }
            if (event.value.id === AUTOCOMPLETE_ITEM.id) {
                const url = createDataUrl.includes('?')
                    ? `${createDataUrl}&on_success=close`
                    : `${createDataUrl}?on_success=close`;
                window.open(url, '_blank');
                return;
            }
            if (props.onSelect) {
                props.onSelect(event);
            }
        },
        [props.onSelect, createDataUrl]
    );

    return (
        <AutoComplete
            {...props}
            suggestions={hideCreateButton ? suggestions : [AUTOCOMPLETE_ITEM, ...suggestions]}
            itemTemplate={itemTemplate}
            onSelect={onSelect}
            style={{ width: '100%' }}
        />
    );
};

export const CreateDataText = styled.span`
    font-weight: 500;
    font-size: 1em;
    color: var(--primary-color);
`;
