import equal from 'fast-deep-equal/react';
import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import { DocumentCreateDto, DocumentRo, EDocumentType, ProductRo, ZoneRo } from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { TDocument, mapFrontDocumentToDocumentDto } from '../../common/form';
import { uploadFiles } from '../../common/form';
import { ActionsFormContainer } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import SnackBar from '../../components/SnackBar';
import { toast } from '../../components/Toast';
import { useRequest } from '../../hooks/StatelessRequest';
import { AddressState } from '../../modules/common/Address';
import Product from '../../modules/product';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    ProductFormErrorState,
    ProductFormState,
    ProductModulesErrorStates,
    ProductModulesStates,
    ProductWithPics,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiCreateData,
    mapStateToApiEditData
} from './helpers';
import { PageForm, PageFormLine, SubmitButton } from './style';

type ProductFormProps = {
    edit?: boolean;
    readOnly?: boolean;
};

const ProductForm: React.FC<ProductFormProps> = ({ edit = false, readOnly = false }: ProductFormProps): JSX.Element => {
    const { id: paramsId } = useParams();
    const navigate = useNavigate();
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [apiState, setApiState] = React.useState<{ form: ProductFormState; db: ProductRo }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(edit || readOnly);
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>(
        edit || readOnly ? 'read' : undefined
    );

    const [err, setErr] = useState<ProductFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<ProductFormState>['onSubmit']>(
        async (data: ProductFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            let documents;
            let main_documents;

            if (data?.uploads?.documents) {
                const files: TDocument[] = data.uploads.documents.map((doc: TDocument) => {
                    if (doc.local) {
                        return {
                            local: {
                                ...doc.local,
                                type: EDocumentType.PRODUCT_PICTURE
                            },
                            type: 'local'
                        };
                    }
                    return {
                        ...doc
                    };
                });
                documents = await uploadFiles(files, 'products');
                if (!documents) {
                    toast({
                        severity : 'error',
                        summary  : 'Téléversement des documents échoué',
                        detail   : 'Le téléversement des documents a échoué, veuillez réessayer.'
                    });
                    return;
                }
                documents = documents
                    .filter((doc) => !!doc.local)
                    .map((doc) => mapFrontDocumentToDocumentDto(doc)) as DocumentCreateDto[];
            }
            if (data?.main_upload?.documents) {
                const files: TDocument[] = data.main_upload.documents.map((doc: TDocument) => {
                    if (doc.local) {
                        return {
                            local: {
                                ...doc.local,
                                type: EDocumentType.PRODUCT_PICTURE
                            },
                            type: 'local'
                        };
                    }
                    return {
                        ...doc
                    };
                });
                main_documents = await uploadFiles(files, 'products');
                if (!main_documents) {
                    toast({
                        severity : 'error',
                        summary  : 'Téléversement des documents de la photo principale a échoué',
                        detail   : 'Le téléversement des documents de la photo principale a échoué, veuillez réessayer.'
                    });
                    return;
                }
                main_documents = main_documents
                    .filter((doc) => !!doc.local)
                    .map((doc) => mapFrontDocumentToDocumentDto(doc)) as DocumentCreateDto[];
            }

            const dataWithUploadedDocuments: ProductFormState = {
                ...data,
                document: {
                    documents: documents ? documents : []
                },
                main_document: {
                    documents: main_documents ? { ...main_documents } : []
                }
            };
            const method = edit ? 'PATCH' : 'POST';
            const url = edit ? urlApiBuilder.productEdit(dataId) : urlApiBuilder.productCreate();
            const body = edit
                ? mapStateToApiEditData(dataWithUploadedDocuments, apiState!.form, apiState!.db)
                : mapStateToApiCreateData(dataWithUploadedDocuments);
            setLoading(true);
            setOnRequestType('create');
            const response = await bbngRequest<ProductRo>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);
            setOnRequestType(undefined);
            if (response.success) {
                navigate(urlBuilder.productList());
            }
        },
        [err, apiState, bbngRequest]
    );

    const formik = useFormik<ProductFormState>({
        initialValues : initialState,
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (
            value: ProductModulesStates | AddressState,
            errors: ProductModulesErrorStates | string[] | null,
            id: string
        ): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response = await bbngRequest<ProductRo>({
            method  : 'GET',
            url     : urlApiBuilder.productGet(dataId),
            options : { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            const tmp = await fetchDataRelation<{ document_id: DocumentRo; photo_id: DocumentRo[]; zone_id: ZoneRo[] }>(
                {
                    document_id : response.response?.data.ro?.main_photo,
                    photo_id    : response.response?.data.ro?.photo_id,
                    zone_id     : response.response?.data.ro?.zone_id
                },
                { photo_id: true, document_id: true, zone_id: true }
            );

            const ro: ProductWithPics = {
                ...response.response?.data.ro,
                main_photo : tmp?.document_id,
                photo_id   : tmp?.photo_id,
                zone_id    : tmp?.zone_id
            };
            const formikState: ProductFormState = mapApiDataToState({
                ...ro
            }) as ProductFormState;
            setApiState({
                form : formikState,
                db   : {
                    ...ro,
                    main_photo : ro.main_photo?.id,
                    photo_id   : ro.photo_id.map((pic) => pic.id),
                    zone_id    : ro.zone_id.map((zone) => zone.id)
                }
            });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (edit || readOnly) {
            fetchData();
        }
    }, []);

    const handleArchive = React.useCallback(() => {
        setLoading(true);
        setOnRequestType(apiState?.db.archived ? 'unarchive' : 'delete');

        bbngRequest({
            method  : 'PATCH',
            url     : urlApiBuilder.productArchive(dataId),
            options : { toastifySuccess: false },
            payload : {
                body: {
                    archived: !apiState?.db.archived
                }
            }
        })
            .then((response) => {
                if (response.success) {
                    navigate(urlBuilder.productList());
                }
            })
            .finally(() => {
                setLoading(false);
                setOnRequestType(undefined);
            });
    }, [bbngRequest, setLoading, setOnRequestType, apiState]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (errorCode) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.productList()} />
        ) : (
            <Error500 />
        );
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <h1>Produit{edit || readOnly ? `: ${apiState?.form?.general.name}` : ''}</h1>
            <ActionsFormContainer
                archived={apiState?.db.archived || false}
                edit={edit}
                readOnly={readOnly}
                // disableSaveOnEdit={JSON.stringify(apiState?.form) === JSON.stringify(formik.values)}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                handleArchiveOnView={handleArchive}
                editPageUrl={urlBuilder.productEdit(dataId)}
                historyUrl={urlApiBuilder.productHistory(dataId)}
            />
            <PageFormLine>
                <Product.GeneralInfo
                    id="general"
                    result={handleChange}
                    value={formik.values.general}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                    edit={edit}
                />
            </PageFormLine>
            <PageFormLine>
                <Product.MainDocuments
                    id="main_upload"
                    DisplayLabel="Photo principale"
                    result={handleChange}
                    singleFile={true}
                    value={formik.values.main_upload}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            <PageFormLine>
                <Product.Document
                    id="uploads"
                    DisplayLabel="Autres photos"
                    result={handleChange}
                    value={formik.values.uploads}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            {readOnly === false && (
                <SubmitButton
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        // (JSON.stringify(apiState?.form) === JSON.stringify(formik.values) && edit)
                        (equal(apiState?.form, formik.values) && edit)
                    }
                />
            )}
            {edit === true && (
                <SnackBar
                    // show={JSON.stringify(apiState?.form) !== JSON.stringify(formik.values)}
                    show={!equal(apiState?.form, formik.values)}
                    content="Vous avez des modifications non enregistrées"
                />
            )}
        </PageForm>
    );
};

export default ProductForm;
