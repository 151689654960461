import styled from 'styled-components';

export const BaseLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #f7f9fc;
    min-height: 100vh;
`;

export const Page = styled.div`
    display: flex;
    flex: 1;
`;

export const Content = styled.main`
    flex: 1;
    display: flex;
    max-width: calc(100vw - 60px);
    flex-direction: column;
    align-items: stretch;
    margin-left: 60px;
    min-height: 100%;
`;
