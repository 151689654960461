import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';

import { getMiddleOfTheDay } from '@bbng/util/misc';
import {
    CCReplanActivityRo,
    CustomerRo,
    ECustomerCategory,
    EPlanningRegion,
    EPlanningType,
    FrontRo,
    ISODate,
    CCReplanActivityQuery,
    CollectorRo,
    TruckRo
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { mapPlanningConfigRegion, mapPlanningConfigType } from '../../common/enumMapper';
import { COLLECT_CONFIG_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { mapRegionToSeverity } from '../CollectConfigs';
import { FiltersContainer, StyledAccordion, StyleSpan, TitleContainer } from './style';
import Calendar from '../../components/Calendar';
import { AccordionTab } from 'primereact/accordion';
import { toast } from '../../components/Toast';

const regionOptions = Object.values(EPlanningRegion).map((region) => ({
    name  : mapPlanningConfigRegion(region),
    value : region
}));

const typeOptions = Object.values(EPlanningType).map((type) => ({
    name  : mapPlanningConfigType(type),
    value : type
}));

type QueryParams = {
    type?: EPlanningType;
    region?: EPlanningRegion;
    customer_id?: string;
    collector_id?: string;
    truck_id?: string;
    min_date?: ISODate;
    max_date?: ISODate;
};

export const CCReplanActivitiesListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [type, setType] = React.useState<EPlanningType | undefined>(query.type);
    const [region, setRegion] = React.useState<EPlanningRegion | undefined>(query.region);
    const [customer_id, setCustomerId] = React.useState<string | undefined>(query.customer_id);
    const [collector_id, setCollectorId] = React.useState<string | undefined>(query.collector_id);
    const [truck_id, setTruckId] = React.useState<string | undefined>(query.truck_id);
    const [minDate, setMinDate] = React.useState<ISODate | undefined>(query.min_date ?? undefined);
    const [maxDate, setMaxDate] = React.useState<ISODate | undefined>(query.max_date ?? undefined);

    const [triggerPageRefresh, setTriggerPageRefresh] = useState<boolean>(false);
    useEffect(() => {
        setTriggerPageRefresh(false);
    }, [triggerPageRefresh]);

    return (
        <>
            <Listing<CCReplanActivityQuery, CCReplanActivityRo>
                url={COLLECT_CONFIG_ORCHESTRATION_BASE_URL}
                endpoint="collect-configs/replan"
                hideCreateButton
                hideBulkMenu
                displayArchiveFilters={false}
                displayArchiveColumn={false}
                searchPlaceholder="Recherche par adresse ou numéro de commande/collecte"
                pageUrl={urlBuilder.collectHistory()}
                displaySelectColumn={false}
                displayActionColumn={false}
                triggerPageRefresh={triggerPageRefresh}
                enrichData={async (data) => {
                    data.data.ro = await fetchDataRelation(data.data.ro ?? [], {
                        collector_id         : true,
                        truck_id             : true,
                        customer_id          : true,
                        construction_site_id : true
                    });
                    return data;
                }}
                queryParams={{
                    type,
                    region,
                    customer_id,
                    collector_id,
                    truck_id,
                    max_date : maxDate ? moment(maxDate).add(1, 'day').startOf('day').toISOString() : undefined, // max date is exclusive so include the next day for better UX
                    min_date : minDate ? moment(minDate).startOf('day').toISOString() : undefined
                }}
                leftHandedComponent={
                    <StyledAccordion>
                        <AccordionTab
                            header={
                                <TitleContainer>
                                    <StyleSpan>Filtres et exports</StyleSpan>
                                </TitleContainer>
                            }
                        >
                            <FiltersContainer>
                                <Calendar.Range
                                    id=""
                                    value={minDate && maxDate ? [minDate, maxDate] : undefined}
                                    required={false}
                                    readOnly={false}
                                    result={(date) => {
                                        if (Array.isArray(date)) {
                                            /**
                                             * Needed to get the middle of the day to avoid timezone issues.
                                             */
                                            const date0 = moment(date[0]).toLocaleString();
                                            const middleStartDay = getMiddleOfTheDay(date0);
                                            const date1 = moment(date[1]).toLocaleString();
                                            const middleEndDay = getMiddleOfTheDay(date1);
                                            if (date?.length === 2) {
                                                /**
                                                 * Case when only the start date is set.
                                                 * Toast the user to select an end date.
                                                 * We cannot set the min and max date to the same value as we won't be able to select two dates then.
                                                 */
                                                if (date[1] == null) {
                                                    toast({
                                                        severity : 'info',
                                                        summary  : 'Sélectionnez une date de fin.',
                                                        detail   : 'Vous devez sélectionner une date de fin pour filtrer par période (pour sélectionner un seul jour, cliquez deux fois sur la même date).'
                                                    });
                                                    return;
                                                }
                                                setMinDate(middleStartDay);
                                                setMaxDate(middleEndDay);
                                            } else if (date?.length === 1) {
                                                setMinDate(middleStartDay);
                                                setMaxDate(middleStartDay);
                                            }
                                        }
                                    }}
                                    showTime={false}
                                    displayError={false}
                                />
                                <Dropdown
                                    value={type}
                                    onChange={(e) => setType(e.value)}
                                    placeholder="Filtrer par type"
                                    optionValue="value"
                                    optionLabel="name"
                                    showClear
                                    options={typeOptions}
                                />
                                <Dropdown
                                    value={region}
                                    onChange={(e) => setRegion(e.value)}
                                    placeholder="Filtrer par région"
                                    optionValue="value"
                                    optionLabel="name"
                                    showClear
                                    options={regionOptions}
                                />
                                <RelationAutocomplete.Customer
                                    placeholder="Filtrer par client"
                                    onSelect={(customer) => setCustomerId(customer?.id)}
                                    onUnselect={() => setCustomerId(undefined)}
                                />
                                <RelationAutocomplete.Collector
                                    placeholder="Filtrer par chauffeur"
                                    onSelect={(collector) => setCollectorId(collector?.id)}
                                    onUnselect={() => setCollectorId(undefined)}
                                />
                                <RelationAutocomplete.Truck
                                    placeholder="Filtrer par camion"
                                    onSelect={(truck) => setTruckId(truck?.id)}
                                    onUnselect={() => setTruckId(undefined)}
                                />
                            </FiltersContainer>
                        </AccordionTab>
                    </StyledAccordion>
                }
                title={'Historique des replanifications'}
                name="Replanification"
                pluralName="Replanifications"
                headers={[
                    {
                        name      : 'Numéro collecte',
                        field     : 'collect_config',
                        component : (data: CCReplanActivityRo) => {
                            return (
                                <a
                                    href={urlBuilder.collectConfigView(data.collect_config.id)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data.collect_config.number}
                                </a>
                            );
                        }
                    },
                    {
                        name      : 'Numéro commande',
                        field     : 'collect_config',
                        component : (data: CCReplanActivityRo) => {
                            return (
                                <a
                                    href={urlBuilder.orderView(data.collect_config.order_id[0])}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data.collect_config.order_number}
                                </a>
                            );
                        }
                    },
                    {
                        name      : 'Collecte initialement prévue le',
                        field     : 'collect_config',
                        component : (data: CCReplanActivityRo) => (
                            <span>
                                {moment(data.from_date).format('DD/MM/YYYY')} {moment(data.from_date).format('HH:mm')} -{' '}
                                {moment(data.to_date).format('HH:mm')}
                            </span>
                        )
                    },
                    {
                        name      : 'Adresse',
                        field     : 'collect_config',
                        component : (data: FrontRo<CCReplanActivityRo>) => (
                            <a
                                href={urlBuilder.constructionSiteView(
                                    data.collect_config.construction_site_id?.[0]?.id
                                )}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {data.collect_config.address.formatted_name}
                            </a>
                        )
                    },
                    {
                        name      : 'Commentaire',
                        field     : 'comment',
                        component : (data: CCReplanActivityRo) => <span>{data.comment ?? 'N/A'}</span>
                    },
                    {
                        name      : 'Client',
                        field     : 'collect_config',
                        component : (data: FrontRo<CCReplanActivityRo>) => {
                            const customer = data.collect_config.customer_id[0] as CustomerRo;
                            const isIndividual = customer?.category === ECustomerCategory.INDIVIDUAL;
                            const individualIcon = <i className="pi pi-user" />;
                            return (
                                <a href={urlBuilder.customerView(customer?.id)} target="_blank" rel="noreferrer">
                                    <Tooltip
                                        position="top"
                                        target={`.customer-${data.id.replace(':', '')}`}
                                        content={isIndividual ? 'Particulier' : undefined}
                                    />
                                    <span className={`customer-${data.id.replace(':', '')}`}>
                                        {customer?.name} {isIndividual && individualIcon}
                                    </span>
                                </a>
                            );
                        }
                    },
                    {
                        name      : 'Type',
                        field     : 'collect_config',
                        component : (data: CCReplanActivityRo) => (
                            <div>
                                <Tag
                                    className="mr-2"
                                    severity={data.collect_config.type === EPlanningType.BIG_BAG ? 'success' : 'info'}
                                    value={mapPlanningConfigType(data.collect_config.type)}
                                />
                            </div>
                        )
                    },
                    {
                        name      : 'Région',
                        field     : 'collect_config',
                        component : (data: CCReplanActivityRo) => (
                            <Tag
                                className="mr-2"
                                value={mapPlanningConfigRegion(data.collect_config.region)}
                                severity={mapRegionToSeverity(data.collect_config.region)}
                            />
                        )
                    },
                    {
                        name      : 'Chauffeur',
                        field     : 'collector_id',
                        component : (data: FrontRo<CCReplanActivityRo>) => {
                            const collector = data.collector_id[0] as CollectorRo;
                            return (
                                <a href={urlBuilder.collectorView(collector?.id)} target="_blank" rel="noreferrer">
                                    {collector?.fullname ?? 'N/A'}
                                </a>
                            );
                        }
                    },
                    {
                        name      : 'Camion',
                        field     : 'truck_id',
                        component : (data: FrontRo<CCReplanActivityRo>) => {
                            const truck = data.truck_id[0] as TruckRo;
                            return (
                                <a href={urlBuilder.truckView(truck?.id)} target="_blank" rel="noreferrer">
                                    {truck?.name ?? 'N/A'}
                                </a>
                            );
                        }
                    }
                ]}
            />
        </>
    );
};
