import React, { useState } from 'react';

import { IOpeningTime } from '@bbng/util/types';

import Button from '../Button';
import styled from 'styled-components';
import moment from 'moment';

export type AllowedDaysProps = {
    readOnly: boolean;
    required: boolean;
    id: string;
    value?: IOpeningTime<boolean>;
    result: (value: IOpeningTime<boolean>, errors: null | string[], id: string) => void;
    displayError?: boolean;
};

const initialValues: IOpeningTime<boolean> = {
    0 : true,
    1 : true,
    2 : true,
    3 : true,
    4 : true,
    5 : false, // samedi disabled by default
    6 : false // dimanche disabled by default
};

export const AllowedDays = ({ readOnly, required, id, value, result, displayError }: AllowedDaysProps) => {
    const [val, setVal] = useState<IOpeningTime<boolean>>(value ?? initialValues);

    React.useEffect(() => {
        if (readOnly === false) {
            result(val, null, id);
        }
    }, []);

    const handleChange = React.useCallback(
        (value: boolean, day: string) => {
            const data = {
                ...val,
                [day]: value
            };
            setVal(data);
            result(data, null, id);
        },
        [val, setVal, id]
    );

    return (
        <>
            {Object.entries(val).map(([day, isAllowed]) => (
                <Line key={day}>
                    <span style={{ width: '100px' }}>
                        {moment()
                            .set({ weekday: Number(day) })
                            .format('dddd')}
                    </span>
                    <div style={{ width: '50px' }}>
                        <Button.Switch
                            readOnly={readOnly}
                            value={isAllowed}
                            id={day}
                            result={(v, _, i) => handleChange(v, i)}
                        />
                    </div>
                </Line>
            ))}
        </>
    );
};

const Line = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
