import {
    BaseCollectCreateDtoHazard,
    BaseCollectCreateDtoSuccess,
    CCRoFront,
    CCServiceRoFront,
    CC_FAMILY,
    CC_STATUS,
    CollectCreateServiceDto,
    CollectEditServiceDto,
    CollectorRo,
    CollectRo,
    CollectServiceFront,
    ECollectHazardReason,
    EPlanningType,
    ETrashType,
    PlanningShiftStepCategory,
    ProductInCCOrCO,
    PRODUCT_FAMILY
} from '@bbng/util/types';

import {
    CollectInfoState,
    CollectInfoErrorState,
    initialErrorState as collectInfoInitialErrorState,
    initialState as collectInfoInitialState
} from '../../modules/collect/Info';

import {
    OrderProductsState,
    OrderProductsErrorState,
    initialErrorState as productInfoInitialErrorState,
    initialState as productInfoInitialState,
    ProductRoWithQuantity
} from '../../modules/order/Products';

import {
    CollectNotificationState,
    CollectNotificationErrorState,
    initialState as notificationInitialState,
    initialErrorState as notificationInitialErrorState
} from '../../modules/collect/Notification';

import {
    OrderDumpsterServiceErrorState,
    OrderDumpsterServiceState,
    initialErrorState as orderDumpsterServiceInitialErrorState,
    initialState as orderDumpsterServiceInitialState,
    EOrderDumpsterServiceForm
} from '../../modules/order/DumpsterService';

import {
    OrderTrashTypeErrorState,
    OrderTrashTypeState,
    initialErrorState as orderTrashTypeInitialErrorState,
    initialState as orderTrashTypeInitialState
} from '../../modules/order/TrashType';
import {
    CollectDocumentsErrorState,
    CollectDocumentsState,
    initialErrorState as collectDocumentsInitialErrorState,
    initialState as collectDocumentsInitialState
} from '../../modules/collect/Documents';
import moment from 'moment';
import { getMiddleOfTheDay } from '@bbng/util/misc';
import { mapFrontDocumentToDocumentDto } from '../../common/form';

export type CollectModulesStates =
    | CollectInfoState
    | OrderDumpsterServiceState
    | OrderTrashTypeState
    | OrderProductsState
    | CollectDocumentsState
    | CollectNotificationState;

export type CollectModulesErrorStates =
    | CollectInfoErrorState
    | OrderDumpsterServiceErrorState
    | OrderTrashTypeErrorState
    | OrderProductsErrorState
    | CollectDocumentsErrorState
    | CollectNotificationErrorState;

export type CompleteServiceCollectFormState = {
    info: CollectInfoState;
    products: OrderProductsState;
    productsOperational?: OrderProductsState | null;
    productsDelivery?: OrderProductsState | null;
    dumpsterService: OrderDumpsterServiceState;
    trashType: OrderTrashTypeState;
    documents: CollectDocumentsState;
    notification: CollectNotificationState;
};

export type CompleteServiceCollectFormErrorState = {
    info: CollectInfoErrorState;
    products: OrderProductsErrorState;
    productsOperational?: OrderProductsErrorState | null;
    productsDelivery?: OrderProductsErrorState | null;
    dumpsterService: OrderDumpsterServiceErrorState;
    trashType: OrderTrashTypeErrorState;
    documents: CollectDocumentsErrorState;
    notification: CollectNotificationErrorState;
};

export const initialState = (day?: string): CompleteServiceCollectFormState => ({
    info                : collectInfoInitialState(day),
    products            : productInfoInitialState,
    productsOperational : productInfoInitialState,
    productsDelivery    : productInfoInitialState,
    dumpsterService     : orderDumpsterServiceInitialState,
    trashType           : orderTrashTypeInitialState,
    documents           : collectDocumentsInitialState,
    notification        : notificationInitialState
});

export const initialErrorState = (day?: string): CompleteServiceCollectFormErrorState => ({
    info                : collectInfoInitialErrorState(day),
    products            : productInfoInitialErrorState,
    productsOperational : productInfoInitialErrorState,
    productsDelivery    : productInfoInitialErrorState,
    dumpsterService     : orderDumpsterServiceInitialErrorState,
    trashType           : orderTrashTypeInitialErrorState,
    documents           : collectDocumentsInitialErrorState,
    notification        : notificationInitialErrorState
});

export const mapApiDataToState = (collect: CollectServiceFront): CompleteServiceCollectFormState => {
    let initialDumpsterService = undefined;
    let initialTrashType = undefined;
    if (collect.type === EPlanningType.DUMPSTER) {
        const deposit = collect.informations.collected_items.find(
            (item) => item.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT
        );
        const retrieval = collect.informations.collected_items.find(
            (item) => item.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL
        );
        const loadWait = collect.informations.collected_items.find(
            (item) => item.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT
        );

        if (deposit && retrieval) {
            initialDumpsterService = EOrderDumpsterServiceForm.ROTATION;
        } else if (deposit) {
            initialDumpsterService = EOrderDumpsterServiceForm.DEPOSIT;
            initialTrashType = deposit.trash_type;
        } else if (retrieval) {
            initialDumpsterService = EOrderDumpsterServiceForm.RETRIEVAL;
            initialTrashType = retrieval.trash_type;
        } else if (loadWait) {
            initialDumpsterService = EOrderDumpsterServiceForm.LOAD_WAIT;
            initialTrashType = loadWait.trash_type;
        }
    }

    const operationalProducts = collect.informations.collected_items.filter((product) => product.operational === true);

    const nonOperationalProducts = collect.informations.collected_items.filter(
        (product) => product.operational === false
    );

    const collectProducts = nonOperationalProducts.filter(
        (product) => product.family !== PRODUCT_FAMILY.DELIVERY_BIG_BAG
    );
    const deliveryProducts = nonOperationalProducts.filter(
        (product) => product.family === PRODUCT_FAMILY.DELIVERY_BIG_BAG
    );

    return {
        info: {
            collect_day    : collect.day,
            arrived_at     : collect.arrived_at,
            completed_at   : collect.completed_at,
            collector      : collect.collector,
            truck          : collect.truck,
            hazard_comment : collect.hazard_comment,
            hazard_reason  : collect.hazard_reason,
            has_hazard     : collect.status === CC_STATUS.HAZARD
        },
        products            : mapProductInCCOrCOToOrderProductState(collectProducts),
        productsOperational : mapProductInCCOrCOToOrderProductState(operationalProducts),
        productsDelivery    : mapProductInCCOrCOToOrderProductState(deliveryProducts),
        dumpsterService     : {
            service: initialDumpsterService
        },
        trashType: {
            trashType: initialTrashType
        },
        documents: collectDocumentsInitialState
    } as CompleteServiceCollectFormState;
};

export const mapCCApiDataToState = (cc: CCServiceRoFront): CompleteServiceCollectFormState => {
    const [product, productsOperational, productsDelivery] = cc.products.reduce(
        (acc, product) => {
            if (product.operational) {
                acc[1].push(product);
            } else if (product.family === PRODUCT_FAMILY.DELIVERY_BIG_BAG) {
                acc[2].push(product);
            } else {
                acc[0].push(product);
            }

            return acc;
        },
        [[], [], []] as [ProductInCCOrCO[], ProductInCCOrCO[], ProductInCCOrCO[]]
    );

    return {
        info: {
            collect_day    : cc.from_date,
            arrived_at     : undefined,
            completed_at   : undefined,
            collector      : cc.collector_id[0] as CollectorRo,
            truck          : undefined,
            hazard_reason  : undefined,
            hazard_comment : '',
            has_hazard     : false
        },
        dumpsterService     : mapCcFamilyToDumpsterServiceState(cc.type, cc.family),
        trashType           : mapCcTrashTypeToOrderTrashTypeState(cc.type, cc.products),
        products            : mapProductInCCOrCOToOrderProductState(product),
        productsOperational : mapProductInCCOrCOToOrderProductState(productsOperational),
        productsDelivery    : mapProductInCCOrCOToOrderProductState(productsDelivery),
        documents           : collectDocumentsInitialState
    } as CompleteServiceCollectFormState;
};

export const mapStateToApiCompleteData = ({
    state,
    cc
}: {
    state: CompleteServiceCollectFormState;
    cc: CCServiceRoFront;
}): CollectCreateServiceDto => {
    const setDay = (input: string | Date | undefined, day: string | Date | undefined) => {
        const date = moment(input);
        const dayDate = moment(day);
        date.set('year', dayDate.year());
        date.set('month', dayDate.month());
        date.set('date', dayDate.date());
        return date.toDate();
    };
    const toStringDate = (date: string | Date | undefined) =>
        typeof date === 'string' ? date : moment(date).utc().toISOString();

    const successBaseDto: Omit<BaseCollectCreateDtoSuccess, 'collector_id' | 'truck_id' | 'planning_id'> = {
        arrived_at   : toStringDate(setDay(state.info.arrived_at, state.info.collect_day)),
        completed_at : toStringDate(setDay(state.info.completed_at, state.info.collect_day)),
        category     : PlanningShiftStepCategory.SERVICE,
        day          : getMiddleOfTheDay(toStringDate(state.info.collect_day)),
        region       : cc.region,
        status       : 'FINISHED',
        type         : cc.type,
        documents    : state.documents.documents
            .filter((doc) => !!doc.local)
            .map((doc) => mapFrontDocumentToDocumentDto(doc))
    };

    const hazardBaseDto: Omit<BaseCollectCreateDtoHazard, 'collector_id' | 'truck_id' | 'planning_id'> = {
        arrived_at   : toStringDate(setDay(state.info.arrived_at, state.info.collect_day)),
        completed_at : toStringDate(setDay(state.info.completed_at, state.info.collect_day)),
        category     : PlanningShiftStepCategory.SERVICE,
        day          : getMiddleOfTheDay(toStringDate(state.info.collect_day)),
        region       : cc.region,
        status       : 'HAZARD',
        type         : cc.type,
        documents    : state.documents.documents
            .filter((doc) => !!doc.local)
            .map((doc) => mapFrontDocumentToDocumentDto(doc)),
        hazard_reason  : state.info.hazard_reason as ECollectHazardReason,
        hazard_comment : state.info.hazard_comment ?? ''
    };

    const collected_products = [
        ...Object.values(state.products),
        ...Object.values(state.productsOperational ?? {}),
        ...Object.values(state.productsDelivery ?? {})
    ].filter((product) => product.quantity > 0);

    const dto: CollectCreateServiceDto = {
        ...(state.info.has_hazard ? hazardBaseDto : successBaseDto),
        presta_id       : typeof cc.presta_id[0] === 'string' ? cc.presta_id[0] : cc.presta_id[0]?.id,
        planning_id     : undefined,
        truck_id        : state.info.truck?.id,
        collector_id    : state.info.collector?.id,
        dumpster_weight :
            cc.type === EPlanningType.DUMPSTER &&
            state.dumpsterService.service !== EOrderDumpsterServiceForm.DEPOSIT &&
            state.info.has_hazard === false
                ? state.info.dumpster_weight ?? 0
                : undefined,
        collected_items: state.info.has_hazard
            ? []
            : collected_products.map((p) => ({ id: p.id, quantity: p.quantity })),
        was_planned  : true,
        step_service : {
            category               : PlanningShiftStepCategory.SERVICE,
            collect_config_id      : cc.id,
            customer_id            : typeof cc.customer_id[0] === 'string' ? cc.customer_id[0] : cc.customer_id[0].id,
            is_splitted            : false, // TODO
            scheduled_at           : toStringDate(setDay(state.info.arrived_at, state.info.collect_day)),
            scheduled_end_at       : toStringDate(setDay(state.info.completed_at, state.info.collect_day)),
            scheduled_service_time : '00:00'
        },
        collect_config_id : cc.id,
        is_splitted       : false, // TODO
        delivery_number   : state.info.delivery_number,
        notifyBySMS       : state.notification.notifyBySms,
        contactToNotify   : state.notification?.contactToNotify ? [state.notification?.contactToNotify] : undefined
    };

    return dto;
};

const mapCcFamilyToDumpsterServiceState = (type: EPlanningType, family: CC_FAMILY): OrderDumpsterServiceState => {
    if (type !== EPlanningType.DUMPSTER) return { service: undefined };

    switch (family) {
        case CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT:
            return { service: EOrderDumpsterServiceForm.DEPOSIT };
        case CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL:
            return { service: EOrderDumpsterServiceForm.RETRIEVAL };
        case CC_FAMILY.COLLECT_DUMPSTER_ROTATION:
            return { service: EOrderDumpsterServiceForm.ROTATION };
        case CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT:
            return { service: EOrderDumpsterServiceForm.LOAD_WAIT };
        default:
            return { service: undefined };
    }
};

const mapCcTrashTypeToOrderTrashTypeState = (type: EPlanningType, products: ProductInCCOrCO[]): OrderTrashTypeState => {
    if (type !== EPlanningType.DUMPSTER) return { trashType: undefined };

    const trashTypesMap = products.reduce((acc, product) => {
        acc[product.trash_type] = true;
        return acc;
    }, {} as Record<ETrashType, true>);

    const trashTypes = Object.keys(trashTypesMap) as ETrashType[];

    return { trashType: trashTypes[0] };
};

export const mapProductInCCOrCOToOrderProductState = (products: ProductInCCOrCO[]): OrderProductsState => {
    return products.reduce((acc, product) => {
        acc[product.id] = {
            ...product,
            quantity: product.quantity
        } as unknown as ProductRoWithQuantity;
        return acc;
    }, {} as OrderProductsState);
};
