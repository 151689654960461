import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import {
    CCServiceRo,
    CollectRo,
    CollectServiceFront,
    EDocumentType,
    EPlanningType,
    EZohoInvoiceStatus,
    InvoiceRo
} from '@bbng/util/types';

import { ActionsFormContainer, uploadFiles } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import SnackBar from '../../components/SnackBar';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import { AddressState } from '../../modules/common/Address';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    CollectFormErrorState,
    CollectFormState,
    CollectModulesErrorStates,
    CollectModulesStates,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiEditData
} from './helpers';
import equal from 'fast-deep-equal/react';
import { displayAccurateTitle } from '../Collect/helpers';
import { PageForm, PageFormLine, SubmitButton } from './style';
import Collect from '../../modules/collect';
import Order from '../../modules/order';
import { fetchDataRelation } from '../../common/dataRelation';
import { mapPlanningTypeToOrderTypeForm } from '../../common/enumMapper';
import { EOrderDumpsterServiceForm } from '../../modules/order/DumpsterService';
import { EOrderTypeForm } from '../../modules/order/Type';
import { toast } from '../../components/Toast';
import { Documents } from '../../modules/common/Documents';

type CollectFormProps = {
    edit?: boolean;
    readOnly?: boolean;
};

const CollectForm: React.FC<CollectFormProps> = ({ edit = false, readOnly = false }: CollectFormProps): JSX.Element => {
    const { id: paramsId } = useParams();
    const navigate = useNavigate();
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [apiState, setApiState] = React.useState<{ form: CollectFormState; db: CollectServiceFront }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(edit || readOnly);
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>(
        edit || readOnly ? 'read' : undefined
    );

    const [err, setErr] = useState<CollectFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<CollectFormState>['onSubmit']>(
        async (data: CollectFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            data.products = {
                ...data.products,
                ...deepCopy(data.productsDelivery),
                ...deepCopy(data.productsOperational)
            };

            const hasProducts = Object.values(data.products).some((pr) => pr.quantity > 0);
            if (!hasProducts && !data.info.has_hazard) {
                toast({
                    severity : 'error',
                    summary  : 'Aucun produit sélectionné',
                    detail   : 'Veuillez sélectionner au moins un produit.'
                });
                return;
            }

            const documents = await uploadFiles(data.documents.documents, 'collect');
            if (!documents) {
                toast({
                    severity : 'error',
                    summary  : 'Téléversement des documents échoué',
                    detail   : 'Le téléversement des documents a échoué, veuillez réessayer.'
                });
                return;
            }
            const dataWithUploadedDocuments: CollectFormState = {
                ...data,
                documents: {
                    documents
                }
            };

            const method = edit ? 'PATCH' : 'POST';
            const url = edit ? urlApiBuilder.collectServicePatch(dataId) : urlApiBuilder.collectServicePost();
            const body = mapStateToApiEditData({
                state   : dataWithUploadedDocuments,
                collect : apiState!.db as CollectRo
            });
            setLoading(true);
            setOnRequestType('create');
            const response = await bbngRequest<CollectRo>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);
            setOnRequestType(undefined);
            if (response.success) {
                navigate(urlBuilder.collectView(dataId));
            }
        },
        [err, apiState, bbngRequest]
    );

    const formik = useFormik<CollectFormState>({
        initialValues : initialState(apiState?.db.day),
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (
            value: CollectModulesStates | AddressState,
            errors: CollectModulesErrorStates | string[] | null,
            id: string
        ): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response: StatelessResponse<CollectServiceFront> = await fetchDataRelation(
            await bbngRequest<CollectServiceFront>({
                method  : 'GET',
                url     : urlApiBuilder.collectGetById(dataId),
                options : { toastifySuccess: false }
            }),
            {
                collector_id      : true,
                truck_id          : true,
                collect_config_id : true,
                document_id       : true,
                invoice_id        : true
            }
        );

        if (response.success && response.response?.data.ro) {
            const ro: CollectServiceFront = response.response?.data.ro;
            const formikState: CollectFormState = mapApiDataToState(ro);

            setApiState({ form: formikState, db: ro });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (edit || readOnly) {
            fetchData();
        }
    }, []);

    const alreadyInvoiced = useMemo(() => {
        const invoices = apiState?.db?.invoice_id as InvoiceRo[];
        if (invoices && invoices.length > 0 && invoices.some((invoice) => invoice?.status !== EZohoInvoiceStatus.DRAFT))
            return true;
        else return false;
    }, [apiState?.db]);

    const showDumpsterServiceSelect = useMemo(() => {
        return apiState?.db.type === EPlanningType.DUMPSTER;
    }, [apiState?.db?.type]);

    const showTrashTypeSelect = useMemo(() => {
        return (
            apiState?.db.type === EPlanningType.DUMPSTER &&
            formik.values.dumpsterService?.service !== EOrderDumpsterServiceForm.ROTATION
        );
    }, [formik.values.trashType, apiState?.db?.type]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (errorCode || !apiState) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.collectHistory()} />
        ) : (
            <Error500 />
        );
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <h1>{displayAccurateTitle(apiState.db)}</h1>
            <ActionsFormContainer
                archived={apiState?.db.archived || false}
                edit={edit}
                readOnly={readOnly}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                editPageUrl={urlBuilder.truckEdit(dataId)}
                historyUrl={urlApiBuilder.truckHistory(dataId)}
                disableDeleteOnView={true}
            />
            <PageFormLine>
                <Collect.Info
                    id="info"
                    result={handleChange}
                    value={formik.values.info}
                    readOnly={readOnly || alreadyInvoiced}
                    displayError={formik.submitCount > 0}
                    hideHazard={false}
                    hidePhoto={true}
                    category={apiState.db.category}
                    day={apiState.db.day}
                    type={apiState.db.type}
                    terminated={true}
                />
            </PageFormLine>
            <Documents
                id="documents"
                title="Documents"
                result={handleChange}
                value={formik.values.documents}
                readOnly={readOnly}
                displayError={formik.submitCount > 0}
                allowedTypes={[
                    EDocumentType.COLLECT_PHOTO,
                    EDocumentType.COLLECT_LANDFILL_SIGNATURE,
                    EDocumentType.COLLECT_SIGNATURE,
                    EDocumentType.BLC,
                    EDocumentType.BSD
                ]}
            />
            {showDumpsterServiceSelect && (
                <PageFormLine>
                    <Order.DumpsterService
                        displayError={formik.submitCount > 0}
                        readOnly={readOnly || alreadyInvoiced}
                        id="dumpsterService"
                        result={handleChange}
                        value={formik.values.dumpsterService}
                    />
                </PageFormLine>
            )}
            {showTrashTypeSelect && (
                <Order.TrashType
                    displayError={formik.submitCount > 0}
                    readOnly={readOnly || alreadyInvoiced}
                    id="trashType"
                    result={handleChange}
                    value={formik.values.trashType}
                />
            )}
            <PageFormLine>
                <Order.Products
                    id="products"
                    result={handleChange}
                    value={formik.values.products}
                    readOnly={readOnly || alreadyInvoiced}
                    zoneId={(apiState.db.informations.collect_config_id as CCServiceRo).zone.id}
                    type={mapPlanningTypeToOrderTypeForm(apiState.db.type)}
                    trashType={formik.values.trashType?.trashType}
                    service={formik.values.dumpsterService?.service}
                    fetchOperational={false}
                    allowNoProducts
                />
            </PageFormLine>
            <PageFormLine>
                <Order.Products
                    label="Produits opérationnels"
                    id="productsOperational"
                    result={handleChange}
                    value={formik.values.productsOperational ? formik.values.productsOperational : {}}
                    readOnly={readOnly || alreadyInvoiced}
                    zoneId={(apiState.db.informations.collect_config_id as CCServiceRo).zone.id}
                    trashType={formik.values.trashType?.trashType}
                    service={formik.values.dumpsterService?.service}
                    fetchOperational={true}
                    forceFetch={true}
                    type={mapPlanningTypeToOrderTypeForm(apiState.db.type)}
                    displayBigBagTrashTypeSelect={false}
                    forceSelectType="number"
                    allowNoProducts
                    forceOneProductOnly={false}
                />
            </PageFormLine>
            <PageFormLine>
                <Order.Products
                    readOnly={alreadyInvoiced}
                    label="Produits livrés sur place"
                    id="productsDelivery"
                    result={handleChange}
                    value={formik.values.productsDelivery ? formik.values.productsDelivery : {}}
                    type={EOrderTypeForm.DELIVERY}
                    retrieveDeliveries={true}
                    forceFetch
                    allowNoProducts
                />
            </PageFormLine>
            {readOnly === false && (
                <SubmitButton
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        (equal(apiState?.form, formik.values) && edit)
                    }
                />
            )}
            {edit === true && (
                <SnackBar
                    show={equal(apiState?.form, formik.values) === false}
                    content="Vous avez des modifications non enregistrées"
                />
            )}
        </PageForm>
    );
};

export default CollectForm;
