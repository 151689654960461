import { checkCCTruckCollectorCompatibility } from '@bbng/util/misc';
import { CCServiceRoFront, CC_FAMILY } from '@bbng/util/types';
import React, { useMemo } from 'react';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';

import styled from 'styled-components';
import { CardType, CardUnassignedId, MonitorItem, useGlobalConfigContext } from './provider';

type CardCcProps = {
    type: CardType;
    item?: MonitorItem;
    disabledOverEffect?: boolean;
    disableDropOver?: boolean;
};

export const Card: React.FC<CardCcProps> = ({ type, item, children, disabledOverEffect, disableDropOver }) => {
    const { switchCard, assignCard, plannings } = useGlobalConfigContext();
    const planning = useMemo(() => {
        return plannings.find((planning) => planning.id === item?.columnId);
    }, [plannings]);

    const [{ isDragging }, drag, dragPreview] = useDrag(
        () => ({
            type,
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            }),
            item
        }),
        [item]
    );

    const { itemDrag } = useDragLayer((monitor) => ({
        itemDrag: monitor.getItem<MonitorItem>()
    }));

    const [{ isOver }, dropRef] = useDrop(
        () => ({
            accept  : Object.values(CardType),
            collect : (monitor) => ({
                isOver: monitor.isOver()
            }),
            drop: (it, monitor) => {
                const dropItem = monitor.getItem<MonitorItem>();
                if (disableDropOver || !dropItem || !item || dropItem.id === item.id) return;

                /**
                 * Check if the drop item is compatible with the column
                 */
                if (dropItem?.extra?.category === 'CC') {
                    const cc = dropItem.extra.cc;
                    if (cc.family !== CC_FAMILY.ADMINISTRATIVE) {
                        if (
                            !checkCCTruckCollectorCompatibility({
                                cc           : dropItem.extra.cc as CCServiceRoFront,
                                truck        : planning?.truck,
                                collector    : planning?.collector,
                                throwOnError : false
                            })
                        )
                            return;
                    } else {
                        /**
                         * Block admin cc to be dropped on uncompatible columns
                         */
                        if (cc.collector_id[0] && cc.collector_id[0] !== planning?.collector?.id) return;
                    }
                }

                if ((dropItem as MonitorItem).columnId === CardUnassignedId) {
                    assignCard(dropItem as MonitorItem, item);
                } else {
                    switchCard(dropItem as MonitorItem, item);
                }
            }
        }),
        [item, disableDropOver, planning]
    );

    const displayOverEffect = useMemo(() => {
        let compatibleDrag = true;
        if (itemDrag?.extra?.category === 'CC') {
            const cc = itemDrag.extra.cc;
            if (cc.family !== CC_FAMILY.ADMINISTRATIVE) {
                compatibleDrag = checkCCTruckCollectorCompatibility({
                    cc,
                    truck        : planning?.truck,
                    collector    : planning?.collector,
                    throwOnError : false
                });
            } else {
                /**
                 * Block admin cc to be dropped on uncompatible columns
                 */
                if (cc.collector_id[0] && cc.collector_id[0] !== planning?.collector?.id) {
                    compatibleDrag = false;
                }
            }
        }
        return compatibleDrag && !disabledOverEffect && !isDragging && isOver;
    }, [itemDrag, planning, isDragging, disabledOverEffect, isOver]);

    return (
        <div ref={dropRef} style={{ paddingTop: displayOverEffect ? '90px' : '8px' }}>
            <div ref={type === CardType.SPACER ? undefined : dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
                <div id="Handle" ref={type === CardType.SPACER ? undefined : drag}>
                    {type === CardType.SPACER ? <Spacer isOver={isOver} /> : children}
                </div>
            </div>
        </div>
    );
};

const Spacer = styled.div<{ isOver?: boolean }>`
    height: 90px;
    width: 200px;
    border-radius: 8px;
    border: 3px dashed #4a5cfc33;
    background-color: ${({ isOver }) => (isOver ? '#4A5CFC33' : 'transparent')};
`;
