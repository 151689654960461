import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';

interface Props {
    images: string[];
}

export const ImageViewer = ({ images }: Props) => {
    const [index, setIndex] = useState<number>(0);
    const [imageRotation, setImageRotation] = useState<number>(0);

    return (
        <div>
            <div style={{ height: '600px' }}>
                <div style={{ display: 'grid', gridTemplate: 'row' }}>
                    <h1 style={{ textAlign: 'center', grid: '2fr', margin: '1em' }}>Visionneuse d'images</h1>
                </div>

                <div
                    style={{
                        transform      : `rotate(${imageRotation}deg)`,
                        display        : 'flex',
                        justifyContent : 'center',
                        alignContent   : 'center',
                        marginBottom   : '1em'
                    }}
                >
                    <img
                        src={images[index]}
                        alt="media"
                        style={{ height: '500px', borderRadius: '8px', justifyContent: 'center', maxWidth: '40vw' }}
                    />
                </div>

                <div
                    style={{
                        display        : 'flex',
                        justifyContent : 'center',
                        flexDirection  : 'row',
                        alignContent   : 'center',
                        marginTop      : '2em'
                    }}
                >
                    <Button
                        icon="pi pi-arrow-circle-left"
                        type="button"
                        onClick={() => {
                            setImageRotation(0);
                            setIndex(index - 1);
                        }}
                        disabled={index === 0}
                    />
                    <Button
                        type="button"
                        className="p-button-text"
                        icon="pi pi-refresh"
                        onClick={() => {
                            setImageRotation(imageRotation + 90);
                        }}
                        style={{ marginLeft: '1em' }}
                    />
                    <p style={{ marginLeft: '2em', marginRight: '2em' }}>
                        {index + 1} / {images.length}
                    </p>
                    <Button
                        type="button"
                        className="p-button-text"
                        icon="pi pi-replay"
                        onClick={() => setImageRotation(imageRotation - 90)}
                        style={{ marginRight: '1em' }}
                    />
                    <Button
                        icon="pi pi-arrow-circle-right"
                        type="button"
                        disabled={index + 1 === images.length}
                        onClick={() => {
                            setImageRotation(0);
                            setIndex(index + 1);
                        }}
                    />
                </div>
                <div
                    style={{
                        display        : 'flex',
                        justifyContent : 'center',
                        flexDirection  : 'row',
                        alignContent   : 'center',
                        marginTop      : '2em',
                        overflowY      : 'scroll'
                    }}
                >
                    {images.map((image, i) => (
                        <img
                            key={i}
                            src={image}
                            alt="media"
                            style={{ height: '100px', borderRadius: '8px', margin: '1em', cursor: 'pointer' }}
                            onClick={() => setIndex(i)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
