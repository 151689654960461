import React from 'react';
import { Link } from 'react-router-dom';

import { urlBuilder } from '../../common/urlBuilder';
import {
    BaseContainer,
    ButtonsContainer,
    DashboardCard,
    DashboardCardDescription,
    DashboardStateBar,
    SidebarContainer,
    SubExternalLink,
    SubLink,
    SubLinks
} from './style';

interface Props {
    links?: Array<{
        icon: React.ReactElement;
        label: string;
        href: string;
    }>;
}

interface Link {
    icon: React.ReactElement;
    label: string;
    href?: string;
    links?: Array<{
        label: string;
        href: string;
        target?: React.HTMLAttributeAnchorTarget | undefined;
        externalRedirect?: boolean;
        description?: string;
        disabled?: boolean;
        comingSoon?: boolean;
    }>;
}

const baseLinks: Link[] = [
    {
        icon  : <i className="pi pi-home"></i>,
        label : 'Dashboard',
        href  : urlBuilder.dashboard()
    },
    {
        icon  : <i className="pi pi-shopping-bag"></i>,
        label : 'Commandes',
        links : [
            {
                label       : 'Nouvelle commande',
                href        : urlBuilder.orderCreate(),
                description : 'Créer une nouvelle commande pour un client. Coucou les Sales 👋'
            },
            {
                label       : 'Commandes',
                href        : urlBuilder.orderList(),
                description : 'Consulter tous les bons de commande et leurs états.'
            },
            {
                label       : 'Commandes client',
                href        : urlBuilder.orderCustomersList(),
                description : 'Consulter tous les bons de commande clients et leurs états.'
            },
            {
                label       : 'Livraisons',
                href        : urlBuilder.collectConfigDeliveries(),
                description : "Pas toucher, c'est pour Athanas"
            }
        ]
    },
    {
        icon  : <i className="pi pi-calendar"></i>,
        label : 'Collectes',
        links : [
            {
                label       : 'Plannings',
                href        : urlBuilder.planning(),
                description : 'Consulter ou recalculer les plannings de collecte. Courage les Ops 💪'
            },
            {
                label       : 'Collectes bigbags à venir',
                href        : `${urlBuilder.collectConfigBigbag()}`,
                description : 'Consulter les collectes de bigbags à venir.'
            },
            {
                label       : 'Collectes bennes à venir',
                href        : urlBuilder.collectConfigDumpster(),
                description : 'Consulter les collectes de bennes à venir.'
            },
            {
                label       : 'Historique des collectes',
                href        : urlBuilder.collectHistory(),
                description : "Consulter l'historique des collectes passées."
            },
            {
                label       : 'Collectes prestataires en cours',
                href        : urlBuilder.collectConfigPresta(),
                description : 'Consulter les collectes de prestataires en cours.'
            },
            {
                label       : 'Prestations externes effectuées',
                href        : urlBuilder.collectPrestaHistory(),
                description : 'Consulter les prestations externes effectuées.'
            },
            {
                label       : 'BSDs',
                href        : urlBuilder.bsdList(),
                description : 'Consulter et générer des bordereaux de suivi des déchets.'
            },
            {
                label       : '(BB) Terminer en masse',
                href        : urlBuilder.collectBulkTerminateBB(),
                description : 'Terminer les collectes de BigBag en masse.'
            },
            {
                label       : 'Bennes sur chantier',
                href        : urlBuilder.dumpsterOnSiteList(),
                description : 'Pour pas les perdre !'
            },
            {
                label       : 'Historique des replanifications',
                href        : urlBuilder.ccReplanActivities(),
                description : "Consulter l'historique des collectes replanifiées."
            }
        ]
    },
    {
        icon  : <i className="pi pi-users"></i>,
        label : 'Clients',
        links : [
            {
                label       : 'Nouveau client',
                href        : urlBuilder.customerCreate(),
                description : 'Ajouter un nouveau client à la base de données. 🇵🇹'
            },
            {
                label       : 'Clients',
                href        : urlBuilder.customerList(),
                description : "Consulter tous les clients d'Endless."
            },
            {
                label       : 'Chantiers',
                href        : urlBuilder.constructionSiteList(),
                description : 'Lister les chantiers des clients. 🏗'
            },
            {
                label       : 'Remises',
                href        : urlBuilder.discountList(),
                description : 'Molo molo les Sales, on va pas se ruiner.'
            },
            {
                label       : 'Utilisateurs',
                href        : urlBuilder.userList(),
                description : 'Tous nos utilisateurs.'
            },
            {
                label       : "Demandes d'accès",
                href        : urlBuilder.customerInvitationList(),
                description : "Toutes les demandes d'accès utilisateurs."
            },
            {
                label       : 'Facturation',
                href        : urlBuilder.invoiceList(),
                description : 'Il est temps de se faire payer. 💶'
            },
            {
                label       : 'Mandats',
                href        : urlBuilder.customerMandateList(),
                description : 'Il est temps de se faire payer (sans rien faire !). 💶'
            },
            {
                label       : 'Revalorisation',
                href        : urlBuilder.revalorisationExport(),
                description : 'Exporter les données de revalorisation par client. 📈'
            },
            {
                label       : 'SMS',
                href        : urlBuilder.smsList(),
                description : 'Listing des SMS envoyés à nos clients. 📱'
            }
        ]
    },
    {
        icon  : <i className="pi pi-cog"></i>,
        label : 'Configuration',
        links : [
            {
                label       : 'Prestataires',
                href        : urlBuilder.prestaList(),
                description : 'Les entreprises qui travaillent pour nous. 🏗'
            },
            {
                label       : 'Camions',
                href        : urlBuilder.truckList(),
                description : 'Honk honk 🚚'
            },
            {
                label       : 'Conducteur-collecteurs',
                href        : urlBuilder.collectorList(),
                description : "Les transporteurs (C'est pas Statham)"
            },
            {
                label       : 'Exutoires',
                href        : urlBuilder.landfillList(),
                description : 'La où on jette nos déchets. 🗑'
            },
            {
                label       : 'Produits',
                href        : urlBuilder.productList(),
                description : 'On vend quoi ? 🤔'
            },
            {
                label       : 'Zones',
                href        : urlBuilder.zoneList(),
                description : 'On intervient où ? 🧭'
            },
            {
                label       : 'Admins',
                href        : urlBuilder.adminList(),
                description : "C'est nous !"
            }
        ]
    }
];

export const SideNavbar: React.FC<Props> = (props: Props): JSX.Element => {
    return (
        <BaseContainer>
            <SidebarContainer>
                {baseLinks.map(({ icon, label, href, links = [] }) => {
                    return [
                        <div key={label} className="category">
                            <ButtonsContainer
                                disabled={href ? undefined : true}
                                to={{ pathname: href }}
                                key={label + href}
                            >
                                <div className="logo">{icon}</div>
                                <div className="logo-text">{label}</div>
                            </ButtonsContainer>
                            <SubLinks className="sublinks">
                                {links.map(({ label, href, target, externalRedirect }) => {
                                    if (externalRedirect)
                                        return (
                                            <SubExternalLink href={href} target={target} key={label + href}>
                                                {label}
                                            </SubExternalLink>
                                        );
                                    return (
                                        <SubLink
                                            key={label + href}
                                            className="sublink"
                                            to={{ pathname: href }}
                                            target={target}
                                        >
                                            {label}
                                        </SubLink>
                                    );
                                })}
                            </SubLinks>
                        </div>
                    ];
                })}
            </SidebarContainer>
        </BaseContainer>
    );
};

export default SideNavbar;

export const DashboardNav: React.FC = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {baseLinks
                .filter((f) => f.label !== 'Dashboard')
                .map((category) => (
                    <div key={category.label}>
                        <div
                            style={{
                                margin          : '20px 0',
                                padding         : '8px',
                                borderRadius    : '8px',
                                display         : 'flex',
                                alignItems      : 'center',
                                gap             : '8px',
                                color           : 'white',
                                backgroundColor : '#00000011',
                                width           : 'min-content'
                            }}
                        >
                            {category.icon}
                            <h2 style={{ margin: 0 }}>{category.label}</h2>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'stretch', flexWrap: 'wrap', gap: '16px' }}>
                            {category.links?.map((link) => (
                                <DashboardLinkCard key={category.label + '-' + link.label} {...link} />
                            ))}
                        </div>
                    </div>
                ))}
        </div>
    );
};

type DashboardLinkCardProps = Exclude<Link['links'], undefined>[number];

const DashboardLinkCard: React.FC<DashboardLinkCardProps & { key: string }> = (props) => {
    const { label, disabled, comingSoon, href, description, externalRedirect, key, target } = props;

    const color = disabled ? '#FAA0A0' : comingSoon ? '#F3DD9E' : '#B4D3B2';

    const NavLink: React.FC<React.PropsWithChildren<{ href: string }>> = ({ href, children }) =>
        disabled ? (
            <>{children}</>
        ) : externalRedirect ? (
            <a href={href} key={key} target={target}>
                {children}
            </a>
        ) : (
            <Link to={{ pathname: href }} key={key}>
                {children}
            </Link>
        );

    return (
        <NavLink href={href}>
            <DashboardCard style={disabled ? { cursor: 'not-allowed' } : {}}>
                <DashboardStateBar color={color} />
                <h3>{label}</h3>
                {description && <DashboardCardDescription>{description}</DashboardCardDescription>}
            </DashboardCard>
        </NavLink>
    );
};
