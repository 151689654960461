import React from 'react';
import styled from 'styled-components';

import { CardErrors, CollectorRo, ECollectorSkill, EPlanningRegion, EPlanningType } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import { textInputProps } from '../../components/Inputs';
import RelationAutocomplete from '../common/RelationAutocomplete';

export type CollectConfigCollectorTruckProps = {
    readOnly?: boolean;
    value?: CollectConfigCollectorTruckState;
    id: string;
    result: (
        value: CollectConfigCollectorTruckState,
        errors: null | string[] | CardErrors<CollectConfigCollectorTruckState>,
        id: string
    ) => void;
    displayError?: boolean;
    region?: EPlanningRegion;
    type?: EPlanningType;
    required: boolean;
};

export type CollectConfigCollectorTruckState = {
    collector?: CollectorRo;
};

export type CollectConfigCollectorTruckErrorState = CardErrors<CollectConfigCollectorTruckState>;

export const initialState: CollectConfigCollectorTruckState = {
    collector: undefined
};

export const initialErrorState: CollectConfigCollectorTruckErrorState = generateInitialErrorState(initialState);

export const initialRef = Array.from(Array(Object.keys(initialState).length)).map(() =>
    React.createRef<textInputProps>()
);

export const CollectConfigCollectorTruck = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false,
    region,
    type,
    required
}: CollectConfigCollectorTruckProps) => {
    const [val, setVal] = React.useState<CollectConfigCollectorTruckState>(value);
    const [err, setErr] = React.useState<CollectConfigCollectorTruckErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    React.useEffect(() => {
        if (required && value.collector === undefined) {
            setErr({ ...err, collector: ['Ce champ est obligatoire'] });
        }
    }, [required]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <StyledCard title="Collecteur souhaité">
            <CardLine>
                <RelationAutocomplete.Collector
                    id="collector"
                    label="Collecteur souhaité"
                    readOnly={readOnly}
                    baseValue={val.collector}
                    errors={err.collector}
                    displayError={displayError}
                    onSelect={(value) => {
                        handleChange(value, null, 'collector');
                    }}
                    onUnselect={() => {
                        handleChange(undefined, required ? ['Aucun collecteur sélectionné'] : null, 'collector');
                    }}
                    has_landfill={type === EPlanningType.DUMPSTER || undefined} // retrieve only collectors with landfill if it's a dumpster planning
                    skill={type as unknown as ECollectorSkill}
                />
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
