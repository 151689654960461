import { Currency } from 'dinero.js';
import { MandateStatus } from 'gocardless-nodejs/types/Types';

import {
    CC_FAMILY,
    CC_PATCH_ORIGIN,
    CC_STATUS,
    DumpsterToTake,
    EBillingBundle,
    EBillingMode,
    EBillingShipment,
    ECollectCharacteristic,
    ECollectHazardReason,
    ECollectorSkill,
    ECustomerCategory,
    ECustomerOrigin,
    EDiscountType,
    EDiscountUnit,
    EDocumentType,
    EKpiDashboardName,
    EOrderPaymentStatus,
    CREATED_FROM,
    EPlanningCalculMethod,
    EPlanningRegion,
    EPlanningType,
    EPriceUnit,
    ESlot,
    ETrashType,
    ETruckFuelType,
    ETruckType,
    EUserRole,
    EZohoInvoiceStatus,
    PRODUCT_FAMILY,
    PlanningShiftStepCategory,
    EProductBillingMode,
    CollectRo,
    InvoiceRo,
    EZohoOrganization,
    EUserInvitationStatus,
    PRODUCT_DUMPSTER_TYPE,
    BillingBranch
} from '@bbng/util/types';
import { EOrderTypeForm } from '../modules/order/Type';
import { config } from './env';
import { urlBuilder } from './urlBuilder';
import { TagSeverityType } from 'primereact/tag';

export function mapCollectConfigFamilyText(family?: CC_FAMILY, withType = false): string {
    switch (family) {
        case CC_FAMILY.DELIVERY_BIG_BAG:
            return 'Livraison' + (withType ? ' big bag' : '');
        case CC_FAMILY.COLLECT_BIG_BAG:
            return 'Collecte' + (withType ? ' big bag' : '');
        case CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT:
            return 'Dépôt' + (withType ? ' benne' : '');
        case CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT:
            return 'Attente chargement' + (withType ? ' benne' : '');
        case CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL:
            return 'Enlèvement' + (withType ? ' benne' : '');
        case CC_FAMILY.COLLECT_DUMPSTER_ROTATION:
            return 'Rotation' + (withType ? ' benne' : '');
        case CC_FAMILY.ADMINISTRATIVE:
            if (withType) {
                return 'Collecte administrative';
            } else {
                return 'Administrative';
            }
        default:
            return 'Famille inconnue';
    }
}

export const mapProductFamilyToString = (item: PRODUCT_FAMILY): string => {
    switch (item) {
        case PRODUCT_FAMILY.COLLECT_BIG_BAG:
            return 'Collecte big bag';
        case PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT:
            return 'Dépôt benne';
        case PRODUCT_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT:
            return 'Attente chargement benne';
        case PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL:
            return 'Enlèvement benne';
        case PRODUCT_FAMILY.DELIVERY_BIG_BAG:
            return 'Livraison big bag';
        default:
            console.log('Unknown product family', item);
            return 'Famille inconnue';
    }
};

export function mapTrashTypetext(family?: ETrashType): string {
    switch (family) {
        case ETrashType.ASBESTOS:
            return 'Amiante';
        case ETrashType.GLASS:
            return 'Verre';
        case ETrashType.LEAD:
            return 'Plomb';
        case ETrashType.METAL:
            return 'Métal';
        case ETrashType.MINERAL_FRACTION:
            return 'Fraction minérale';
        case ETrashType.MIXED:
            return 'Mélangé';
        case ETrashType.PAPER_CARDBOARD:
            return 'Papier/carton';
        case ETrashType.PLASTER:
            return 'Plâtre';
        case ETrashType.PLASTIC:
            return 'Plastique';
        case ETrashType.RUBBLE:
            return 'Gravat';
        case ETrashType.WOOD:
            return 'Bois';
        case ETrashType.OIW:
            return 'DIB';
        case ETrashType.GREEN_TRASH:
            return 'Vert';
        case ETrashType.ULTIMATE_TRASH:
            return 'Ultime';
        default:
            return 'Type inconnu';
    }
}

export function mapColorFromVolume(volume: number): string {
    switch (volume) {
        case 8:
            return '#fffb00';
        case 15:
            return '#f745b1';
        case 30:
            return '#4ed4f2';
        default:
            return '#00ff38';
    }
}

/**
 * Same function than above with softer colors for backgrounds.
 */
export function mapBgColorFromVolume(volume: number): string {
    switch (volume) {
        case 8:
            return '#FFF9C5';
        case 15:
            return '#FBE6FB';
        case 30:
            return '#C5DCFF';
        default:
            return '#D6F7DA';
    }
}

export function mapOrderStatus(status?: EOrderPaymentStatus): string {
    switch (status) {
        case EOrderPaymentStatus.PAID:
            return 'Payée';
        case EOrderPaymentStatus.TO_PAY:
            return 'À payer';
        case EOrderPaymentStatus.CANCELED:
            return 'Annulée';
        case EOrderPaymentStatus.NA:
            return 'Non applicable';
        default:
            return 'Statut inconnu';
    }
}

export function mapDocumentType(type?: EDocumentType): string {
    switch (type) {
        case EDocumentType.ID_CARD_RECTO:
            return "Carte d'identité (recto)";
        case EDocumentType.ID_CARD_VERSO:
            return "Carte d'identité (verso)";
        case EDocumentType.DRIVING_LICENSE:
            return 'Permis de conduire';
        case EDocumentType.PASSPORT:
            return 'Passeport';
        case EDocumentType.KBIS:
            return 'KBIS';
        case EDocumentType.RIB:
            return 'RIB';
        case EDocumentType.OTHER:
            return 'Autre';
        case EDocumentType.ORDER_SHEET:
            return 'Bon de commande';
        case EDocumentType.COLLECT_PHOTO:
            return 'Photo de collecte';
        case EDocumentType.COLLECT_SIGNATURE:
            return 'Signature de collecte';
        case EDocumentType.COLLECT_LANDFILL_SIGNATURE:
            return "Signature d'évacuation";
        case EDocumentType.BSD:
            return 'BSD';
        case EDocumentType.BLC:
            return 'Bon de livraison client';
        case EDocumentType.PRODUCT_PICTURE:
            return 'Photo de produit';
        default:
            return 'Type inconnu';
    }
}

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function mapCollectConfigCharacteristic(characteristic?: ECollectCharacteristic): string {
    switch (characteristic) {
        case ECollectCharacteristic.BAG_INSIDE:
            return 'Sac en intérieur';
        case ECollectCharacteristic.NARROW_STREET:
            return 'Rue étroite';
        case ECollectCharacteristic.ON_SCAFFOLD:
            return 'Sur sapine';
        case ECollectCharacteristic.ON_SIDEWALK:
            return 'Sur un trottoir';
        case ECollectCharacteristic.DUMPSTER_AMPLIROLL:
            return 'Benne ampliroll';
        case ECollectCharacteristic.DUMPSTER_CHAIN:
            return 'Benne à chaîne';
        default:
            return 'Caractéristique inconnue';
    }
}

export function mapBillingMode(mode?: EBillingMode): string {
    switch (mode) {
        case EBillingMode.AFTER_COLLECT:
            return 'Après la collecte';
        case EBillingMode.INSTANT:
            return 'À la commande';
        case EBillingMode.END_OF_MONTH:
            return 'À la fin du mois';
        default:
            return 'Mode inconnu';
    }
}

export function mapTruckType(type?: ETruckType, short?: boolean): string {
    switch (type) {
        case ETruckType.BIG_BAG:
            return 'Big bag';
        case ETruckType.DUMPSTER_AMPLIROLL:
            if (short) {
                return 'Ampliroll';
            } else {
                return 'Benne ampliroll';
            }
        case ETruckType.DUMPSTER_CHAIN:
            if (short) {
                return 'Chaîne';
            } else {
                return 'Benne à chaîne';
            }
        case ETruckType.ADR:
            return 'ADR';
        case ETruckType.DELIVERY:
            return 'Livraison';
        case ETruckType.NARROW_STREET:
            return 'Rue étroite';
        case ETruckType.OTHER:
            return 'Autre';
        default:
            return 'Type inconnu';
    }
}

export function mapFuelType(type?: ETruckFuelType): string {
    switch (type) {
        case ETruckFuelType.DIESEL:
            return 'Diesel';
        case ETruckFuelType.ETHANOL:
            return 'Ethanol';
        case ETruckFuelType.HYDROGEN:
            return 'Hydrogène';
        case ETruckFuelType.LPG:
            return 'GPL';
        case ETruckFuelType.LNG:
            return 'Gaz naturel liquéfié';
        case ETruckFuelType.CNG:
            return 'Gaz naturel comprimé';
        case ETruckFuelType.UNLEADED_95:
            return 'Sans-plomb 95';
        case ETruckFuelType.UNLEADED_98:
            return 'Sans-plomb 98';
        default:
            return 'Carburant inconnu';
    }
}

export const mapPlanningConfigRegion = (region?: EPlanningRegion): string => {
    switch (region) {
        case EPlanningRegion.PARIS:
            return 'Paris';
        case EPlanningRegion.MARSEILLE:
            return 'Marseille';
        case EPlanningRegion.LYON:
            return 'Lyon';
        default:
            return 'Région inconnue';
    }
};

export const mapPlanningConfigType = (type?: EPlanningType): string => {
    switch (type) {
        case EPlanningType.BIG_BAG:
            return 'BB';
        case EPlanningType.DUMPSTER:
            return 'Bennes';
        default:
            return 'Type inconnu';
    }
};

export const mapCreatedFrom = (origin?: CREATED_FROM): string => {
    switch (origin) {
        case CREATED_FROM.BACK_OFFICE:
            return 'Back Office';
        case CREATED_FROM.ECOMMERCE:
            return 'E-Commerce 💻 ';
        case CREATED_FROM.APPLICATION:
            return 'Application 📱';
        case CREATED_FROM.EMAIL:
            return 'E-mail';
        case CREATED_FROM.INBOUND_CALL:
            return 'Appel entrant';
        case CREATED_FROM.OUTBOUND_CALL:
            return 'Appel sortant';
        default:
            return 'BO';
    }
};

export const createdFromOptions = Object.values(CREATED_FROM).map((origin) => ({
    name  : mapCreatedFrom(origin),
    value : origin
}));

export function mapCollectCategoryText(category?: PlanningShiftStepCategory, details = true): string {
    switch (category) {
        case PlanningShiftStepCategory.ADMINISTRATIVE:
            return 'Administrative';
        case PlanningShiftStepCategory.DRIVER_HOUSE_START:
            return 'Début de journée';
        case PlanningShiftStepCategory.DRIVER_HOUSE_END:
            return 'Fin de journée';
        case PlanningShiftStepCategory.EMPTYING:
            return `Évacuation ${details ? 'en exutoire' : ''}`;
        case PlanningShiftStepCategory.SERVICE:
            return 'Service';
        case PlanningShiftStepCategory.BREAK:
            return 'Pause';
        case PlanningShiftStepCategory.LATE_CANCELATION:
            return 'Annulation tardive';
        default:
            return 'Catégorie inconnue';
    }
}

export function mapCollectHazardReason(hazard?: ECollectHazardReason): string {
    switch (hazard) {
        case ECollectHazardReason.BAD_FILLING:
            return 'Mauvais remplissage';
        case ECollectHazardReason.DAMAGED:
            return 'Produit abimé';
        case ECollectHazardReason.NO_BAG:
            return 'Produit introuvable/inaccessible';
        case ECollectHazardReason.ORDER_ERROR:
            return 'Erreur à la commande';
        case ECollectHazardReason.OBSTRUCTIVE_PARKING:
            return 'Parking génant';
        case ECollectHazardReason.NO_ACCESS:
            return 'Accès impossible';
        case ECollectHazardReason.NO_SPACE:
            return "Pas d'espace suffisant";
        case ECollectHazardReason.NOBODY_ON_SITE:
            return 'Aucun interlocuteur sur place';
        case ECollectHazardReason.OVERLOADED_DUMPSTER:
            return 'Benne surchargée';
        case ECollectHazardReason.OTHER:
            return 'Autre';
        case ECollectHazardReason.TO_REPLAN:
            return 'Aléa interne à replanifier';
        default:
            return 'Aléa inconnu';
    }
}

export function mapBillingBundle(mode?: EBillingBundle): string {
    switch (mode) {
        case EBillingBundle.BY_COLLECT:
            return '/collecte';
        case EBillingBundle.BY_CONSTRUCTION_SITE:
            return '/chantier';
        case EBillingBundle.GLOBAL:
            return 'Global';
        case EBillingBundle.GLOBAL_BY_CONSTRUCTION_SITE:
            return 'Global/chantier';
        default:
            return 'Groupement de facture inconnu';
    }
}

export function mapCollectorSkill(skill?: ECollectorSkill): string {
    switch (skill) {
        case ECollectorSkill.BIG_BAG:
            return 'Big bag';
        case ECollectorSkill.DUMPSTER_AMPLIROLL:
            return 'Benne ampliroll';
        case ECollectorSkill.DUMPSTER_CHAIN:
            return 'Benne à chaîne';
        case ECollectorSkill.ADR:
            return 'ADR';
        case ECollectorSkill.DELIVERY:
            return 'Livraison';
        case ECollectorSkill.SMS:
            return 'Envoi de SMS';
        case ECollectorSkill.OTHER:
            return 'Autre';
        default:
            return 'Compétence inconnue';
    }
}

export function mapCollectConfigStatus(status?: CC_STATUS): string {
    switch (status) {
        case CC_STATUS.WAITING_FOR_APPROVAL:
            return 'A vérifier';
        case CC_STATUS.TO_PREPARE:
            return 'A livrer';
        case CC_STATUS.TO_PLAN:
            return 'A planifier';
        case CC_STATUS.PLANNED:
            return 'Planifiée';
        case CC_STATUS.CANCELED:
            return 'Annulée';
        case CC_STATUS.FINISHED:
            return 'Terminée';
        case CC_STATUS.HAZARD:
            return 'Aléa';
        case CC_STATUS.ORDER_TO_PAY:
            return 'Commande à payer';
        case CC_STATUS.SPLITTED:
            return 'Scindée';
        default:
            return 'Statut inconnu';
    }
}

export function mapCollectStatus(status?: CollectRo['status']): string {
    switch (status) {
        case CC_STATUS.CANCELED:
            return 'Annulation tardive';
        case CC_STATUS.FINISHED:
            return 'Terminée';
        case CC_STATUS.HAZARD:
            return 'Aléa';
        default:
            return 'Statut inconnu';
    }
}

export const mapCollectConfigPatchOrigin = (origin?: CC_PATCH_ORIGIN): string => {
    switch (origin) {
        case CC_PATCH_ORIGIN.CLIENT_REQUEST:
            return 'Demande client';
        case CC_PATCH_ORIGIN.INTERNAL:
            return 'Modification interne';
        default:
            return 'Origine inconnue';
    }
};

export const mapInvoiceStatus = (status?: EZohoInvoiceStatus): string => {
    switch (status) {
        case EZohoInvoiceStatus.DRAFT:
            return 'Brouillon';
        case EZohoInvoiceStatus.OVERDUE:
            return 'En retard';
        case EZohoInvoiceStatus.PAID:
            return 'Payée';
        case EZohoInvoiceStatus.PARTIALLY_PAID:
            return 'Partiellement payée';
        case EZohoInvoiceStatus.SENT:
            return 'Envoyée';
        case EZohoInvoiceStatus.UNPAID:
            return 'Non payée';
        case EZohoInvoiceStatus.VIEWED:
            return 'Vue';
        case EZohoInvoiceStatus.VOID:
            return 'Annulée';
        case EZohoInvoiceStatus.SENDING:
            return "En cours d'envoi";
        default:
            return 'Statut inconnu';
    }
};

export function mapBillingShipment(shipment?: EBillingShipment): string {
    switch (shipment) {
        case EBillingShipment.EMAIL:
            return 'E-mail';
        case EBillingShipment.LETTER:
            return 'Courrier';
        default:
            return "Mode d'envoi inconnu";
    }
}

export const mapCustomerCategory = (category?: ECustomerCategory): string => {
    switch (category) {
        case ECustomerCategory.PRO:
            return 'Professionnel';
        case ECustomerCategory.PRO_TO_VALIDATE:
            return 'Professionnel à valider';
        case ECustomerCategory.INDIVIDUAL:
            return 'Particulier';
        default:
            return 'Catégorie inconnue';
    }
};

export function mapPriceUnit(unit?: EPriceUnit): string {
    switch (unit) {
        case EPriceUnit.BAG:
            return 'Sac';
        case EPriceUnit.KG:
            return 'Kg';
        case EPriceUnit.M3:
            return 'M³';
        case EPriceUnit.T:
            return 'Tonne';
        default:
            return 'Unité inconnue';
    }
}

export const mapDumpsterToTake = (key: keyof DumpsterToTake) => {
    switch (key) {
        case 'nb_8m3':
            return 'Bennes 8m³';
        case 'nb_15m3':
            return 'Bennes 15m³';
        case 'nb_20m3':
            return 'Bennes 20m³';
        case 'nb_30m3':
            return 'Bennes 30m³';
        default:
            return 'Type de benne inconnu';
    }
};

export const mapCustomerOrigin = (origin?: ECustomerOrigin): string => {
    switch (origin) {
        case ECustomerOrigin.BIG_BAG_N_GO:
            return "Big Bag 'n Go";
        case ECustomerOrigin.SOS_BENNES:
            return 'SOS Bennes';
        case ECustomerOrigin.ENDLESS:
            return 'EndLess';
        default:
            return origin ?? 'Origine inconnue';
    }
};

export const mapDiscountUnit = (unit?: EDiscountUnit): string => {
    switch (unit) {
        case EDiscountUnit.PERCENT:
            return 'Pourcentage';
        case EDiscountUnit.AMOUNT:
            return 'Euro';
        default:
            return 'Unité inconnue';
    }
};

export const mapDiscountType = (type?: EDiscountType): string => {
    switch (type) {
        case EDiscountType.BIG_BAG:
            return 'Big bags';
        case EDiscountType.DUMPSTER:
            return 'Bennes';
        case EDiscountType.DELIVERY:
            return 'Livraison';
        default:
            return 'Type inconnu';
    }
};

export const mapVolumeToBag = (volume?: number): string => {
    switch (volume) {
        case 0.25:
            return 'B0 (250L)';
        case 1:
            return 'B1 (1m³)';
        case 2:
            return 'B2 (2m³)';
        default:
            return `${volume}m³`;
    }
};

export const mapUserRole = (role?: EUserRole): string => {
    switch (role) {
        case EUserRole.BBNG_USER_ACCOUNTANT:
            return 'Comptable';
        case EUserRole.BBNG_USER_ADMIN:
            return 'Administrateur';
        case EUserRole.BBNG_USER_CUSTOM:
            return 'Personnalisé';
        case EUserRole.BBNG_USER_EMPLOYEE:
            return 'Employé';
        case EUserRole.BBNG_USER_MANAGER:
            return 'Manager';
        default:
            return 'Rôle inconnu';
    }
};

export const mapCurrency = (currency?: Currency): string => {
    switch (currency) {
        case 'EUR':
            return '€';
        case 'USD':
            return '$';
        default:
            return '€';
    }
};

export const mapDashboardType = (type: EKpiDashboardName) => {
    switch (type) {
        case EKpiDashboardName.GLOBAL_BIG_BAG:
            return 'Global BB';
        case EKpiDashboardName.POSITION_BB_PARIS_TOMORROW:
            return 'Position BB Paris demain';
        case EKpiDashboardName.POSITION_BB_PARIS_TODAY:
            return "Position BB Paris aujourd'hui";
        case EKpiDashboardName.DETAILS_BIG_BAG_TOMORROW:
            return 'Détails BB Paris demain';
        case EKpiDashboardName.DETAILS_BIG_BAG_TODAY:
            return "Détails BB Paris aujourd'hui";
        case EKpiDashboardName.PLANNING_BIG_BAG_TOMORROW:
            return 'Planning BB demain';
        case EKpiDashboardName.PLANNING_BIG_BAG_TODAY:
            return "Planning BB aujourd'hui";
        default:
            return 'Tableau de bord inconnu';
    }
};

export const mapCalculMethod = (method: EPlanningCalculMethod) => {
    switch (method) {
        case EPlanningCalculMethod.Classic:
            return 'Classique';
        case EPlanningCalculMethod.Custom:
            return 'Alternative';
        case EPlanningCalculMethod.CustomWithoutTime:
            return 'Flexible';
        case EPlanningCalculMethod.Manual:
            return 'Manuelle';
        default:
            return '';
    }
};

export const mapSlot = (slot: ESlot) => {
    switch (slot) {
        case ESlot.AFTERNOON:
            return 'Après-midi';
        case ESlot.MORNING:
            return 'Matin';
        case ESlot.DAY:
            return 'Journée';
        default:
            return 'Aucun';
    }
};

export const mapMandateStatus = (status?: MandateStatus): string => {
    switch (status) {
        case MandateStatus.Active:
            return 'Actif';
        case MandateStatus.Blocked:
            return 'Bloqué';
        case MandateStatus.Cancelled:
            return 'Annulé';
        case MandateStatus.Expired:
            return 'Expiré';
        case MandateStatus.PendingCustomerApproval:
            return 'En attente de validation client';
        case MandateStatus.PendingSubmission:
            return 'En attente de soumission';
        case MandateStatus.Consumed:
            return 'Consommé';
        case MandateStatus.Failed:
            return 'Echoué';
        case MandateStatus.Submitted:
            return 'Soumis';
        case MandateStatus.SuspendedByPayer:
            return 'Supsendu par le payeur';
        default:
            return 'Statut inconnu';
    }
};

export const mapProductBillingMode = (bilingMode: EProductBillingMode) => {
    switch (bilingMode) {
        case EProductBillingMode.DELAYED:
            return 'Différée (fin de mois/après collecte)';
        case EProductBillingMode.INSTANT:
            return 'Instantanée (à la commande)';
        default:
            return '';
    }
};

export const mapPlanningTypeToOrderTypeForm = (type: EPlanningType): EOrderTypeForm => {
    switch (type) {
        case EPlanningType.BIG_BAG:
            return EOrderTypeForm.COLLECT_BIG_BAG;
        case EPlanningType.DUMPSTER:
            return EOrderTypeForm.COLLECT_DUMPSTER;
    }
};

export const mapInvoiceToInvoiceUrl = (invoice: InvoiceRo): string => {
    if (invoice.billing_organization === EZohoOrganization.ZOHO_2) {
        return urlBuilder.zohoInvoice(config.zohoServer.organization_id_2, invoice.id_zoho_invoice);
    }
    return urlBuilder.zohoInvoice(config.zohoServer.organization_id_1, invoice.id_zoho_invoice);
};

export const mapUserInvitationStatus = (status?: EUserInvitationStatus): string => {
    switch (status) {
        case EUserInvitationStatus.ACCEPTED:
            return 'Acceptée';
        case EUserInvitationStatus.REJECTED:
            return 'Refusée';
        case EUserInvitationStatus.PENDING_OR_EXPIRED:
            return 'En attente';
        default:
            return 'Statut inconnu';
    }
};

export const mapUserInvitationStatusToSeverity = (status?: EUserInvitationStatus): 'info' | 'success' | 'danger' => {
    switch (status) {
        case EUserInvitationStatus.ACCEPTED:
            return 'success';
        case EUserInvitationStatus.REJECTED:
            return 'danger';
        default:
            return 'info';
    }
};

export function mapDumpsterTypeText(type: PRODUCT_DUMPSTER_TYPE, short?: boolean): string {
    switch (type) {
        case PRODUCT_DUMPSTER_TYPE.DUMPSTER_AMPLIROLL:
            if (short) {
                return 'Ampliroll';
            } else {
                return 'Benne ampliroll';
            }
        case PRODUCT_DUMPSTER_TYPE.DUMPSTER_CHAIN:
            if (short) {
                return 'Chaîne';
            } else {
                return 'Benne à chaîne';
            }
    }
}

export function mapDumpsterTypeToHexa(type: PRODUCT_DUMPSTER_TYPE): string {
    switch (type) {
        case PRODUCT_DUMPSTER_TYPE.DUMPSTER_AMPLIROLL:
            return '#7e0315';
        case PRODUCT_DUMPSTER_TYPE.DUMPSTER_CHAIN:
            return '#3f4f4c';
    }
}

export const mapBillingBranchSeverity = (branch: BillingBranch): TagSeverityType => {
    switch (branch) {
        case BillingBranch.ENDLESS_1:
            return 'success';
        case BillingBranch.ENDLESS_2:
            return 'primary';
        case BillingBranch.ENDLESS_3:
            return 'warning';
    }
};
