import { StatusCodes } from 'http-status-codes';
import moment from 'moment';

import { CustomError, NdlssError } from '@bbng/util/error';
import {
    CollectBreak,
    CollectRo,
    CollectorRo,
    EDataType,
    EPlanningCalculMethod,
    EPlanningType,
    EVRPNodeType,
    EVrpOrderIdPrefix,
    ErrorContext,
    ErrorResponsability,
    ISODate,
    PlanningCalculateDto,
    PlanningRo,
    PlanningShift,
    PlanningShiftStepAdministrative,
    PlanningShiftStepBreak,
    PlanningShiftStepCategory,
    PlanningShiftStepDriver,
    PlanningShiftStepEmptying,
    PlanningShiftStepRoulage,
    PlanningShiftStepService,
    PlanningShiftStepWaiting,
    PlanningUpdateActionDto,
    TruckRo,
    VRPMetadataAdmin,
    VRPMetadataDecheterie,
    VRPMetadataDone,
    VRPMetadataDriver,
    VRPMetadataOrder,
    VRPNode,
    VrpAlgorithmRo
} from '@bbng/util/types';

import { HashTable } from './hashtable';

export const durationToDate = (day: ISODate, duration: string): ISODate => {
    const hours = Number(duration.split(':')[0]) ?? 0;
    const minutes = Number(duration.split(':')[1]) ?? 0;

    return moment.utc(day).hours(hours).minutes(minutes).toISOString();
};

export const map_diff_between_two_dates = (
    date: ISODate,
    refDate: ISODate = moment.utc().toISOString()
): string /* HH:mm */ => {
    const minutes = Math.abs(moment.utc(refDate).diff(moment.utc(date), 'minutes'));
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    return `${hours.toString().length === 1 ? `0${hours}` : hours}:${
        minutesLeft.toString().length === 1 ? `0${minutesLeft}` : minutesLeft
    }`;
};

export type ConstructorVRPOutput = {
    dto: PlanningCalculateDto;
    vrpAlgorithmRo: VrpAlgorithmRo<EPlanningType>;
    trucks: TruckRo[];
    collectors: CollectorRo[];
    collects: CollectRo[];
};
export class VRPOutputMapper {
    private readonly vrpAlgorithmRo: VrpAlgorithmRo<EPlanningType>;
    private readonly trucks: TruckRo[];
    private readonly collectors: CollectorRo[];
    private readonly collects: HashTable<CollectRo>;

    private readonly params: PlanningCalculateDto['parameters'];
    private readonly day: PlanningCalculateDto['day'];
    private readonly general_config: PlanningCalculateDto['general_config'];
    private readonly planningType: EPlanningType;

    private readonly calcul_method: EPlanningCalculMethod;

    private _overlapingNodes:
        | {
              break: VRPNode;
              node: VRPNode;
          }
        | undefined = undefined;
    private _isWaiting: PlanningShiftStepWaiting | false = false;

    private _sortedNodes: Record<EVRPNodeType, VRPNode[]> = {} as any;

    private steps_service: PlanningShiftStepService[] = [];
    private steps_break: PlanningShiftStepBreak[] = [];
    private steps_driver: PlanningShiftStepDriver[] = [];
    private steps_emptying: PlanningShiftStepEmptying[] = [];
    private steps_roulage: PlanningShiftStepRoulage[] = [];
    private steps_waiting: PlanningShiftStepWaiting[] = [];
    private steps_administrative: PlanningShiftStepAdministrative[] = [];

    constructor(data: ConstructorVRPOutput) {
        this.params = data.dto.parameters;
        this.day = data.dto.day;
        this.general_config = data.dto.general_config;
        this.planningType = data.dto.type;
        this.calcul_method = data.dto.calcul_method;

        this.vrpAlgorithmRo = data.vrpAlgorithmRo;
        this.trucks = data.trucks;
        this.collectors = data.collectors;
        this.collects = new HashTable<CollectRo>(data.collects, 'category');
    }

    private _resetStep = () => {
        this.steps_service = [];
        this.steps_break = [];
        this.steps_driver = [];
        this.steps_emptying = [];
        this.steps_roulage = [];
        this.steps_waiting = [];
        this.steps_administrative = [];
    };

    private _sortNodes = (nodes: VRPNode[]) => {
        this._sortedNodes = nodes.reduce(
            (acc, node) => {
                switch (node.node_type) {
                    case EVRPNodeType.ADMINISTRATIVE_LOCATION:
                        acc[EVRPNodeType.ADMINISTRATIVE_LOCATION].push(node);
                        break;
                    case EVRPNodeType.BREAK:
                        acc[EVRPNodeType.BREAK].push(node);
                        break;
                    case EVRPNodeType.DRIVER_HOUSE_LOCATION_END:
                        acc[EVRPNodeType.DRIVER_HOUSE_LOCATION_END].push(node);
                        break;
                    case EVRPNodeType.DRIVER_HOUSE_LOCATION_START:
                        acc[EVRPNodeType.DRIVER_HOUSE_LOCATION_START].push(node);
                        break;
                    case EVRPNodeType.LANDFILL_LOCATION:
                        acc[EVRPNodeType.LANDFILL_LOCATION].push(node);
                        break;
                    default:
                        acc[EVRPNodeType.ORDER_LOCATION].push(node);
                }
                return acc;
            },
            {
                [EVRPNodeType.ADMINISTRATIVE_LOCATION]     : [],
                [EVRPNodeType.BREAK]                       : [],
                [EVRPNodeType.DRIVER_HOUSE_LOCATION_END]   : [],
                [EVRPNodeType.DRIVER_HOUSE_LOCATION_START] : [],
                [EVRPNodeType.LANDFILL_LOCATION]           : [],
                [EVRPNodeType.ORDER_LOCATION]              : []
            } as Record<EVRPNodeType, VRPNode[]>
        );
    };

    private _breakNodeOverlapWith = (node: VRPNode, nodeStart: ISODate): void => {
        /**
         * Check if break overlaps with service or emptying.
         * Do not check against a break node or a driver start or end node because
         * they are not considered as service or emptying and it's useless as it's impossbile to overlap.
         */
        if (
            this._sortedNodes[EVRPNodeType.BREAK].length <= 0 ||
            [
                EVRPNodeType.BREAK,
                EVRPNodeType.DRIVER_HOUSE_LOCATION_START,
                EVRPNodeType.DRIVER_HOUSE_LOCATION_END
            ].includes(node.node_type)
        ) {
            return;
        }

        const segmentsOverlap = (segment1: string[], segment2: string[]): boolean => {
            const start1 = moment.utc(segment1[0]);
            const end1 = moment.utc(segment1[1]);
            const start2 = moment.utc(segment2[0]);
            const end2 = moment.utc(segment2[1]);
            return (start2.isAfter(start1) && start2.isBefore(end1)) || (end2.isAfter(start1) && end2.isBefore(end1));
        };

        this._sortedNodes[EVRPNodeType.BREAK].forEach((breakNode) => {
            const timeSegments = [
                [nodeStart, durationToDate(this.day, node.departure_time)],
                [durationToDate(this.day, breakNode.starting_time!), durationToDate(this.day, breakNode.ending_time!)]
            ];

            const isOverlap = segmentsOverlap(timeSegments[0], timeSegments[1]);

            if (isOverlap) {
                this._overlapingNodes = {
                    break: breakNode,
                    node
                };
            }
        });
    };

    private _formatWaitingStep = (node: VRPNode, start: string): PlanningShiftStepWaiting => {
        const waitingHours = node.waiting_duration.split(':')[0];
        const waitingMinutes = node.waiting_duration.split(':')[1];

        const formatedEnd = moment
            .utc(start)
            .add(Number(waitingHours), 'hours')
            .add(Number(waitingMinutes), 'minutes')
            .toISOString();

        return {
            scheduled_at           : start,
            scheduled_end_at       : formatedEnd,
            scheduled_service_time : node.waiting_duration
        };
    };

    private _handleWaitingAndBreak = (node: VRPNode) => {
        this._isWaiting = false;
        this._overlapingNodes = undefined;

        let start = durationToDate(this.day, node.arrival_time);
        let end = durationToDate(this.day, node.departure_time);

        /**
         * Check if we have some waiting time
         */
        const waitingTime = node.waiting_duration ?? '00:00';
        if (waitingTime !== '00:00') {
            this._isWaiting = this._formatWaitingStep(node, start);
            this.steps_waiting.push(this._isWaiting);
            start = this._isWaiting.scheduled_end_at;
        }
        /**
         * Check break overlapping
         */
        this._breakNodeOverlapWith(node, start);
        if (this._overlapingNodes !== undefined) {
            const overlapingNodes = this._overlapingNodes as { break: VRPNode; node: VRPNode };

            const startBreak = durationToDate(this.day, overlapingNodes.break.starting_time!);
            const endBreak = durationToDate(this.day, overlapingNodes.break.ending_time!);

            //Start of break can be before/start/inside the segment node.
            const isBreakStartingBeforeNodeStart = moment.utc(startBreak).isBefore(moment.utc(start));
            const isBreakSameStartTimeAsNodeStart = moment.utc(startBreak).isSame(moment.utc(start));
            const isBreakStartingInsideNodeStart = moment.utc(startBreak).isAfter(moment.utc(start));

            //End of break can be inside/end/after the segment node.
            const isBreakSameEndTimeAsNodeEnd = moment.utc(endBreak).isSame(moment.utc(end));
            const isBreakEndingInsideNodeEnd = moment.utc(endBreak).isBefore(moment.utc(end));
            const isBreakEndingAfterNodeEnd = moment.utc(endBreak).isAfter(moment.utc(end));

            if (isBreakStartingBeforeNodeStart) {
                if (isBreakEndingInsideNodeEnd) {
                    start = endBreak;
                }
                if (isBreakSameEndTimeAsNodeEnd) {
                    // do nothing, nonsense: break is during the whole step
                }
                if (isBreakEndingAfterNodeEnd) {
                    // do nothing, nonsense: break is during the whole step
                }
            }

            if (isBreakSameStartTimeAsNodeStart) {
                if (isBreakEndingInsideNodeEnd) {
                    start = endBreak;
                }
                if (isBreakSameEndTimeAsNodeEnd) {
                    // do nothing, nonsense: break is during the whole step
                }
                if (isBreakEndingAfterNodeEnd) {
                    // do nothing, nonsense: break is during the whole step
                }
            }

            if (isBreakStartingInsideNodeStart) {
                if (isBreakEndingInsideNodeEnd) {
                    // do nothing, break is during the step
                }
                if (isBreakSameEndTimeAsNodeEnd) {
                    end = startBreak;
                }
                if (isBreakEndingAfterNodeEnd) {
                    end = startBreak;
                }
            }

            // if (isBreakStartingBeforeNodeStart || isBreakSameStartTimeAsNodeStart) {
            //     start = endBreak;
            // }
            // if(isBreakStartingInsideNodeStart) {
            //     //Do nothing.
            // }

            // if(isBreakEndingInsideNodeEnd) {
            //     //Do nothing.
            // }
            // if(isBreakSameEndTimeAsNodeEnd || isBreakEndingAfterNodeEnd) {
            //     end = startBreak;
            // }
        }

        return { start, end };
    };

    private setDriverStartSteps = (node: VRPNode, collector: CollectorRo): void => {
        /**
         * 🚨 BENNE_F 🚨
         * Condition added
         */
        if (node && this.planningType === EPlanningType.BIG_BAG) {
            const metadata = node.metadata as VRPMetadataDone | VRPMetadataDriver;
            const departureTime = moment
                .utc(durationToDate(this.day, node.departure_time))
                .subtract(10, 'minutes')
                .format('HH:mm');

            const step_driver: PlanningShiftStepDriver = {
                collect_id             : metadata.is_collect ? metadata.collect_id : undefined,
                category               : PlanningShiftStepCategory.DRIVER_HOUSE_START,
                scheduled_at           : durationToDate(this.day, departureTime),
                scheduled_end_at       : durationToDate(this.day, node.departure_time),
                scheduled_service_time : '00:10'
            };

            /**
             * Handle the case where the driver house start was not passed to etape_deja réalisee.
             * So we don't have any metadata.
             **/
            const collectsDriverHouseStart = this.collects.retrieve(
                PlanningShiftStepCategory.DRIVER_HOUSE_START,
                true
            ) as CollectRo[];
            if (collectsDriverHouseStart.length > 0) {
                //Check collector has a driver house start
                const collectDriverHouseStart = collectsDriverHouseStart.find(
                    (collect) => collect.collector.id === collector.id
                );
                if (step_driver.collect_id === undefined && collectDriverHouseStart !== undefined) {
                    step_driver.collect_id = collectDriverHouseStart.id;
                }
            }

            this.steps_driver.push(step_driver);
        }

        /**
         * 🚨 BENNE_F 🚨
         * We need to handle BENNE_F
         * We had to pass fake nodes on etape déjà réalisée. and we need to handle them by not dealing with them
         * but we have to find the BENNE_F to make it DRIVER_HOUSE_START
         */
        if (
            node &&
            this.planningType === EPlanningType.DUMPSTER &&
            (node.metadata as VRPMetadataDone).is_fake === true
        ) {
            const benneF = this._sortedNodes[EVRPNodeType.ORDER_LOCATION].find((nodeOrder) => {
                if ((nodeOrder.metadata as VRPMetadataOrder).is_benne_F === true) {
                    return nodeOrder;
                }
                return undefined;
            });
            if (!benneF) {
                throw new NdlssError({
                    httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                    code           : 'custom',
                    dataType       : EDataType.PLANNING,
                    context        : ErrorContext.Other,
                    responsability : ErrorResponsability.Server,
                    params         : {
                        message:
                            "We couln't find trucks in the database to create the planning. At this stage, it's not possible."
                    }
                });
            }

            const departureTime = moment
                .utc(durationToDate(this.day, node.departure_time))
                .subtract(10, 'minutes')
                .format('HH:mm');

            this.steps_driver.push({
                collect_id             : benneF.metadata.is_collect ? (benneF.metadata as VRPMetadataDone).collect_id : undefined,
                category               : PlanningShiftStepCategory.DRIVER_HOUSE_START,
                scheduled_at           : durationToDate(this.day, departureTime),
                scheduled_end_at       : durationToDate(this.day, node.departure_time),
                scheduled_service_time : '00:10'
            });
        }
        return;
    };

    private setDriverEndSteps = (node: VRPNode): void => {
        if (node) {
            const metadata = node.metadata as VRPMetadataDone | VRPMetadataDriver;
            const arrivalTime = moment
                .utc(durationToDate(this.day, node.arrival_time))
                .add(10, 'minutes')
                .format('HH:mm');
            this.steps_driver.push({
                collect_id             : metadata.is_collect ? metadata.collect_id : undefined,
                category               : PlanningShiftStepCategory.DRIVER_HOUSE_END,
                scheduled_at           : durationToDate(this.day, node.arrival_time),
                scheduled_end_at       : durationToDate(this.day, arrivalTime),
                scheduled_service_time : '00:10'
            });
        }
    };

    private setBreaksSteps = () => {
        if (this._sortedNodes[EVRPNodeType.BREAK].length <= 0) {
            const breakCollects = this.collects.retrieve(PlanningShiftStepCategory.BREAK, true);
            if (breakCollects.length > 0) {
                (breakCollects as CollectBreak[]).forEach((collect) => {
                    this.steps_break.push({
                        collect_id             : collect.id,
                        category               : PlanningShiftStepCategory.BREAK,
                        scheduled_at           : collect.arrived_at,
                        scheduled_end_at       : collect.completed_at,
                        scheduled_service_time : `00:${collect.informations.duration}`
                    });
                });
            }
            return;
        }

        this._sortedNodes[EVRPNodeType.BREAK].forEach((breakNode) => {
            const start = durationToDate(this.day, breakNode.starting_time!);
            const end = durationToDate(this.day, breakNode.ending_time!);
            const duration = moment(end).diff(moment(start), 'minutes');

            const breakCollects = this.collects.retrieve(PlanningShiftStepCategory.BREAK, true) as CollectBreak[];

            const _getCorrespondingBreakCollect = (breakCollects: CollectBreak[]) => {
                if (breakCollects.length === 0) {
                    return undefined;
                }

                const found = breakCollects.find((breakCollect) => {
                    if (breakCollect.informations.duration === duration) {
                        return breakCollect;
                    }
                    return undefined;
                });
                return found ? found.id : undefined;
            };

            this.steps_break.push({
                collect_id             : _getCorrespondingBreakCollect(breakCollects),
                scheduled_at           : start,
                scheduled_end_at       : end,
                scheduled_service_time : `00:${duration}`,
                category               : PlanningShiftStepCategory.BREAK
            });
        });
    };

    private setEmptyingSteps = (nodes: VRPNode[]): void => {
        nodes.forEach((node) => {
            const metadata = node.metadata as VRPMetadataDone | VRPMetadataDecheterie;

            /**
             * 🚨 BENNE_F 🚨
             * Don't do anything if it's a fake node for BENNE_F
             */
            if ((metadata as VRPMetadataDone).is_fake !== undefined) return;

            /**
             * check if existing collect for emptying
             */
            const collect_id = metadata.is_collect ? metadata.collect_id : undefined;

            /**
             * Handle start and end date of emptying if breaks and waiting time
             */
            const { start, end } = this._handleWaitingAndBreak(node);

            const baseEmptyingStep = <PlanningShiftStepEmptying>{
                collect_id,
                scheduled_at           : start,
                scheduled_end_at       : end,
                scheduled_service_time : node.service_duration,
                dumpster_to_take       :
                    this.planningType === EPlanningType.DUMPSTER
                        ? {
                              nb_8m3  : Number(node.nb_8m3_containers_to_take ?? 0),
                              nb_15m3 : Number(node.nb_15m3_containers_to_take ?? 0),
                              nb_20m3 : Number(node.nb_20m3_containers_to_take ?? 0),
                              nb_30m3 : Number(node.nb_30m3_containers_to_take ?? 0)
                          }
                        : null,
                category    : PlanningShiftStepCategory.EMPTYING,
                landfill_id : metadata.landfill_id
            };
            this.steps_emptying.push(baseEmptyingStep);
        });
    };

    private setAdministrativeSteps = (nodes: VRPNode[]): void => {
        nodes.forEach((node) => {
            const metadata = node.metadata as VRPMetadataDone | VRPMetadataAdmin;
            /**
             * Check if existing collect for administrative
             */
            const collectId = metadata.is_collect ? metadata.collect_id : undefined;
            const ccId = metadata.cc_id;

            /**
             * Handle start and end date of emptying if breaks and waiting time
             */
            const { start, end } = this._handleWaitingAndBreak(node);

            this.steps_administrative.push({
                collect_id             : collectId,
                category               : PlanningShiftStepCategory.ADMINISTRATIVE,
                scheduled_at           : start,
                scheduled_end_at       : end,
                scheduled_service_time : node.service_duration,
                collect_config_id      : ccId
            });
        });
    };

    private setServiceSteps = (nodes: VRPNode[]): void => {
        nodes.forEach((node) => {
            const metadata = node.metadata as VRPMetadataDone | VRPMetadataOrder;

            /**
             * 🚨 BENNE_F 🚨
             * Check that it is not a BENNE_F.
             * If it is, then do nothing
             */
            if (metadata.is_benne_F !== undefined && metadata.is_benne_F === true) {
                return;
            }

            /**
             * Do not deal with ROTATION OR ATTENTE DE CHARGEMENT
             * it will be handled in an other method
             */
            if (
                node.node_name.includes(EVrpOrderIdPrefix.DEPOSIT) ||
                node.node_name.includes(EVrpOrderIdPrefix.RETRIEVAL)
            ) {
                return;
            }

            /**
             * Check if existing collect for service
             */
            const collectId = metadata.is_collect ? metadata.collect_id : undefined;
            const ccId = metadata.cc_id;
            const customerId = metadata.customer_id;

            /**
             * Handle start and end date of emptying if breaks and waiting time
             */
            const { start, end } = this._handleWaitingAndBreak(node);

            this.steps_service.push({
                collect_id             : collectId,
                category               : PlanningShiftStepCategory.SERVICE,
                scheduled_at           : start,
                scheduled_end_at       : end,
                scheduled_service_time : node.service_duration,
                collect_config_id      : ccId,
                customer_id            : customerId,
                is_splitted            : metadata.is_splitted ? metadata.is_splitted : false,
                splitted_idx           : (metadata.is_splitted === true && metadata.splitted_idx !== undefined
                    ? metadata.splitted_idx === 0
                        ? 0
                        : metadata.splitted_idx
                    : undefined) as number
            });
        });
    };

    private setServiceStepsForRotationAndLoadWait = (nodes: VRPNode[]): void => {
        const filteredNodes = nodes.filter((node) => {
            if (
                node.node_name.includes(EVrpOrderIdPrefix.DEPOSIT) ||
                node.node_name.includes(EVrpOrderIdPrefix.RETRIEVAL)
            ) {
                return true;
            }
            return false;
        });

        if (filteredNodes.length > 0) {
            filteredNodes.forEach((node) => {
                if (node.node_name.includes(EVrpOrderIdPrefix.RETRIEVAL)) return;
                const metadata = node.metadata as VRPMetadataDone | VRPMetadataOrder;

                const collectId = metadata.is_collect ? metadata.collect_id : undefined;
                const ccId = metadata.cc_id;

                const correspondingRetrievalNode = filteredNodes.find(
                    (el) =>
                        el.node_name.includes(collectId !== undefined ? collectId : ccId) &&
                        el.node_name.includes(EVrpOrderIdPrefix.RETRIEVAL)
                );

                if (correspondingRetrievalNode === undefined) {
                    throw new CustomError(
                        StatusCodes.INTERNAL_SERVER_ERROR,
                        'Could not find the corresponding retrieval node for the rotation or loadwait. This should not happen.'
                    );
                }

                /**
                 * Get the start and end date of the rotation or loadwait
                 */
                let start = durationToDate(this.day, node.arrival_time);
                let end = durationToDate(this.day, correspondingRetrievalNode.departure_time);

                const fakeNode = <VRPNode>{
                    node_type        : EVRPNodeType.ORDER_LOCATION,
                    node_name        : 'useless_id',
                    driving_duration : node.driving_duration,
                    waiting_duration : node.waiting_duration,
                    arrival_time     : node.arrival_time,
                    departure_time   : correspondingRetrievalNode.departure_time,
                    service_duration : map_diff_between_two_dates(end, start)
                };

                /**
                 * Check if we have some waiting time
                 */
                const waitingTime = fakeNode.waiting_duration ?? '00:00';
                if (waitingTime !== '00:00') {
                    this._isWaiting = this._formatWaitingStep(fakeNode, start);
                    this.steps_waiting.push(this._isWaiting);
                    start = this._isWaiting.scheduled_end_at;
                }

                (this._isWaiting = false), (this._overlapingNodes = undefined);

                this._breakNodeOverlapWith(fakeNode, start);
                if (this._overlapingNodes !== undefined) {
                    const overlapingNodes = this._overlapingNodes as { break: VRPNode; node: VRPNode };

                    const startBreak = durationToDate(this.day, overlapingNodes.break.starting_time!);
                    const endBreak = durationToDate(this.day, overlapingNodes.break.ending_time!);

                    //Start of break can be before/start/inside the segment node.
                    const isBreakStartingBeforeNodeStart = moment.utc(startBreak).isBefore(moment.utc(start));
                    const isBreakSameStartTimeAsNodeStart = moment.utc(startBreak).isSame(moment.utc(start));
                    const isBreakStartingInsideNodeStart = moment.utc(startBreak).isAfter(moment.utc(start));

                    //End of break can be inside/end/after the segment node.
                    const isBreakSameEndTimeAsNodeEnd = moment.utc(endBreak).isSame(moment.utc(end));
                    const isBreakEndingInsideNodeEnd = moment.utc(endBreak).isBefore(moment.utc(end));
                    const isBreakEndingAfterNodeEnd = moment.utc(endBreak).isAfter(moment.utc(end));

                    if (isBreakStartingBeforeNodeStart) {
                        if (isBreakEndingInsideNodeEnd) {
                            start = endBreak;
                        }
                        if (isBreakSameEndTimeAsNodeEnd) {
                            // do nothing, nonsense: break is during the whole step
                        }
                        if (isBreakEndingAfterNodeEnd) {
                            // do nothing, nonsense: break is during the whole step
                        }
                    }

                    if (isBreakSameStartTimeAsNodeStart) {
                        if (isBreakEndingInsideNodeEnd) {
                            start = endBreak;
                        }
                        if (isBreakSameEndTimeAsNodeEnd) {
                            // do nothing, nonsense: break is during the whole step
                        }
                        if (isBreakEndingAfterNodeEnd) {
                            // do nothing, nonsense: break is during the whole step
                        }
                    }

                    if (isBreakStartingInsideNodeStart) {
                        if (isBreakEndingInsideNodeEnd) {
                            // do nothing, break is during the step
                        }
                        if (isBreakSameEndTimeAsNodeEnd) {
                            end = startBreak;
                        }
                        if (isBreakEndingAfterNodeEnd) {
                            end = startBreak;
                        }
                    }

                    // const overlapingNodes = this._overlapingNodes as { break: VRPNode; node: VRPNode };
                    // const overlapingFromStart =
                    //     overlapingNodes.node.arrival_time === overlapingNodes.break.starting_time;
                    // const overlapingFromEnd = overlapingNodes.node.departure_time === overlapingNodes.break.ending_time;
                    // if (overlapingFromStart) {
                    //     start = durationToDate(this.day, overlapingNodes.break.ending_time!);
                    // }
                    // if (overlapingFromEnd) {
                    //     end = durationToDate(this.day, overlapingNodes.break.starting_time!);
                    // }
                }

                const customerId = metadata.customer_id;

                this.steps_service.push({
                    collect_id             : collectId,
                    category               : PlanningShiftStepCategory.SERVICE,
                    collect_config_id      : ccId,
                    customer_id            : customerId,
                    scheduled_at           : start,
                    scheduled_end_at       : end,
                    scheduled_service_time : map_diff_between_two_dates(end, start),
                    is_splitted            : false
                });
            });
        }
    };

    private setRoulageSteps = (nodes: VRPNode[]): void => {
        nodes.forEach((node) => {
            if (node.driving_duration && node.driving_duration !== '00:00') {
                const end = durationToDate(this.day, node.arrival_time ?? node.departure_time);

                const hours = node.driving_duration.split(':')[0];
                const minutes = node.driving_duration.split(':')[1];

                const start = moment.utc(end).subtract(hours, 'hours').subtract(minutes, 'minutes').toISOString();

                this.steps_roulage.push({
                    scheduled_at           : start,
                    scheduled_end_at       : end,
                    scheduled_service_time : node.driving_duration
                });
            }
        });
    };

    public generatePlanning = (): (PlanningUpdateActionDto & { id: string })[] => {
        return this.params.reduce((acc, param) => {
            this._resetStep();
            const mapShift = (): Pick<PlanningUpdateActionDto, 'shift' | 'truck_id' | 'collector_id'> => {
                const truck = this.trucks.find((t) => t.id === param.truck_id) as TruckRo;
                const collector = this.collectors.find((c) => c.id === param.collector_id) as CollectorRo;

                if (!this.vrpAlgorithmRo.output?.routes) {
                    throw new NdlssError({
                        httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                        code           : 'custom',
                        dataType       : EDataType.PLANNING,
                        context        : ErrorContext.Other,
                        responsability : ErrorResponsability.Server,
                        params         : {
                            message: "Algorithm response doesn't provide any route..."
                        }
                    });
                }

                const route =
                    this.vrpAlgorithmRo.output?.routes.find((route) => {
                        return route.vehicle_id === truck.id && route.driver_name === collector.id;
                    }) ?? undefined;
                if (!route || route.nodes.length === 1) {
                    return {
                        shift: {
                            distance             : 0,
                            duration             : '00:00',
                            start_date           : moment.utc(this.day, 'YYYY-MM-DD').startOf('day').toISOString(),
                            end_date             : moment.utc(this.day, 'YYYY-MM-DD').endOf('day').toISOString(),
                            steps_administrative : [],
                            steps_break          : [],
                            steps_driver         : [],
                            steps_emptying       : [],
                            steps_roulage        : [],
                            steps_service        : [],
                            steps_waiting        : []
                        },
                        truck_id     : [truck.id],
                        collector_id : [collector.id]
                    };
                }

                this._sortNodes(route.nodes);

                this.setDriverStartSteps(this._sortedNodes[EVRPNodeType.DRIVER_HOUSE_LOCATION_START][0], collector);
                this.setDriverEndSteps(this._sortedNodes[EVRPNodeType.DRIVER_HOUSE_LOCATION_END][0]);

                this.setBreaksSteps();

                this.setEmptyingSteps(this._sortedNodes[EVRPNodeType.LANDFILL_LOCATION]);

                this.setAdministrativeSteps(this._sortedNodes[EVRPNodeType.ADMINISTRATIVE_LOCATION]);

                this.setServiceSteps(this._sortedNodes[EVRPNodeType.ORDER_LOCATION]);

                if (this.planningType === EPlanningType.DUMPSTER)
                    this.setServiceStepsForRotationAndLoadWait(this._sortedNodes[EVRPNodeType.ORDER_LOCATION]);

                this.setRoulageSteps(route.nodes);

                return <Pick<PlanningRo, 'shift' | 'truck_id' | 'collector_id'>>{
                    shift: <PlanningShift>{
                        distance             : Number(route.travel_distance),
                        duration             : route.travel_duration,
                        start_date           : durationToDate(this.day, route.starting_work_time),
                        end_date             : durationToDate(this.day, route.ending_work_time),
                        steps_driver         : this.steps_driver,
                        steps_break          : this.steps_break,
                        steps_emptying       : this.steps_emptying,
                        steps_administrative : this.steps_administrative,
                        steps_service        : this.steps_service,
                        steps_waiting        : this.steps_waiting,
                        steps_roulage        : this.steps_roulage
                    },
                    truck_id     : [truck.id],
                    collector_id : [collector.id]
                };
            };

            acc.push({
                id: param.planning_id,

                general_config : this.general_config,
                shift_config   : param.shift_config,

                calculated_at : this.vrpAlgorithmRo.updated_at,
                automatic     : false,
                calcul_method : this.calcul_method,

                ...mapShift()
            });
            return acc;
        }, [] as (PlanningUpdateActionDto & { id: string })[]);
    };
}
