import equal from 'fast-deep-equal/react';
import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import React, { useState } from 'react';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import {
    CCAdministrativeRoFront,
    CCRo,
    CCServiceRo,
    CCServiceRoFront,
    CC_STATUS,
    PRODUCT_FAMILY,
    ALLOW_GOD_MODE_KEY
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { ActionsFormContainer } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import useQuery from '../../hooks/Query';
import { useRequest } from '../../hooks/StatelessRequest';
import CollectConfig from '../collect-config';
import { Address, AddressState } from '../common/Address';
import Error404Data from '../../pages/Error404Data';
import Error500 from '../../pages/Error500';
import { Contacts } from '../common/Contacts';
import Order from '../order';
import { EOrderDumpsterServiceForm, mapCollectConfigFamilyToService } from '../order/DumpsterService';
import { mapProductFamilyToOrderTypeForm } from '../order/Products/helpers';
import { EOrderTypeForm } from '../order/Type';
import {
    CollectConfigFormErrorState,
    CollectConfigFormState,
    CollectConfigModulesErrorStates,
    CollectConfigModulesStates,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiCreateData,
    mapStateToApiEditData
} from './helpers';
import { SubmitButton } from './style';
import useLocalStorage from '../../hooks/LocalStorage';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';

export type CollectConfigFormProps = {
    edit?: boolean;
    readOnly?: boolean;
    setModalIsOpen: (isOpen: boolean) => void;
    dataId?: string;
    showActions?: boolean;
    isInternal?: boolean;
    isAdministrative?: boolean;
    hideAlreadyAvailable?: boolean;
};

type QueryParams = { isInternal?: boolean };

export const CollectConfigForm: React.FC<CollectConfigFormProps> = ({
    edit = false,
    readOnly = false,
    setModalIsOpen,
    dataId = '',
    showActions = true,
    isInternal = false,
    isAdministrative = false,
    hideAlreadyAvailable = false
}: CollectConfigFormProps): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [apiState, setApiState] = React.useState<{ form: CollectConfigFormState; db: CCRo }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });
    const [fetchError, setFetchError] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(edit || readOnly);
    const [collectConfigType, setCollectConfigType] = useState<EOrderTypeForm>();
    const [collectConfigService, setCollectConfigService] = useState<EOrderDumpsterServiceForm>();

    const [isGodMode] = useLocalStorage(ALLOW_GOD_MODE_KEY, false);

    const { planningPageState, getPlannings, getCCAdministrative, getCCService } = usePlanningV2Store();

    const [err, setErr] = useState<CollectConfigFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<CollectConfigFormState>['onSubmit']>(
        async (data: CollectConfigFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }
            setLoading(true);

            const method = edit ? 'PATCH' : 'POST';
            const url = () => {
                if (edit) {
                    return isAdministrative
                        ? urlApiBuilder.ccAdministrativeEdit(dataId as string)
                        : urlApiBuilder.collectConfigEdit(dataId as string);
                } else {
                    return isAdministrative
                        ? urlApiBuilder.ccAdministrativeCreate()
                        : urlApiBuilder.collectConfigCreate();
                }
            };
            const body = edit
                ? mapStateToApiEditData(data, apiState!.db, apiState!.form, isAdministrative)
                : mapStateToApiCreateData(data, planningPageState.type);

            const response = await bbngRequest<CCRo>({
                method,
                url     : url(),
                payload : {
                    body,
                    queryParams: {
                        godMode: isGodMode ? true : undefined
                    }
                }
            });

            if (response.success && response.response?.data.ro) {
                await Promise.all([
                    getPlannings(bbngRequest),
                    getCCAdministrative(bbngRequest),
                    getCCService(bbngRequest)
                ]);
                setModalIsOpen(false);
            }
            setLoading(false);
        },
        [err, apiState, bbngRequest, planningPageState]
    );

    const formik = useFormik<CollectConfigFormState>({
        initialValues: {
            ...initialState,
            collectInfo: {
                ...initialState.collectInfo,
                collect_day: planningPageState.day || initialState.collectInfo?.collect_day
            }
        },
        onSubmit: handleSubmit
    });

    const handleChange = React.useCallback(
        (
            value: CollectConfigModulesStates | AddressState,
            errors: CollectConfigModulesErrorStates | string[] | null,
            id: string
        ): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response = await bbngRequest<CCRo>({
            method : 'GET',
            url    : isAdministrative
                ? urlApiBuilder.ccAdministrativeRead(dataId)
                : urlApiBuilder.collectConfigRead(dataId as string),
            options: { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            const ro = await fetchDataRelation<CCServiceRoFront | CCAdministrativeRoFront>(response.response.data.ro, {
                collector_id : true,
                truck_id     : true
            });
            const formikState: CollectConfigFormState = mapApiDataToState(ro);

            setCollectConfigType(mapProductFamilyToOrderTypeForm(ro.family as PRODUCT_FAMILY));
            setCollectConfigService(
                ro.family.includes('DUMPSTER') ? mapCollectConfigFamilyToService(ro.family) : undefined
            );
            setApiState({ form: formikState, db: response.response?.data.ro });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setFetchError(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, [dataId]);

    React.useEffect(() => {
        if (edit || readOnly) {
            fetchData();
        }
    }, []);

    if (loading) return <PageLoader loading />;

    if (fetchError) {
        return fetchError === StatusCodes.NOT_FOUND ? <Error404Data /> : <Error500 />;
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            {showActions && (
                <ActionsFormContainer
                    archived={apiState?.db?.archived || false}
                    edit={edit}
                    readOnly={readOnly}
                    disableSaveOnEdit={equal(apiState?.form, formik.values)}
                    editPageUrl={urlBuilder.collectConfigEdit(dataId)}
                    historyUrl={urlApiBuilder.collectConfigHistory(dataId)}
                />
            )}
            <CollectConfig.Misc
                displayError={formik.submitCount > 0}
                readOnly={readOnly}
                id="misc"
                result={handleChange}
                value={formik.values.misc}
                edit={edit}
                showExecutionTimeMinutes
                showTitle={isAdministrative}
            />
            <Order.CollectInfo
                displayError={formik.submitCount > 0}
                readOnly={readOnly}
                id="collectInfo"
                result={handleChange}
                value={formik.values.collectInfo}
                showWaitingTimeMinutes={collectConfigService === EOrderDumpsterServiceForm.LOAD_WAIT}
                disableDayEdition={apiState?.db?.status === CC_STATUS.SPLITTED}
                showCharacteristics={!isAdministrative}
                showAlreadyAvailable={!hideAlreadyAvailable}
                create={!edit && !readOnly}
            />
            {/* Show address field only for creation or when it's an administrative cc */}
            {(!edit || isAdministrative) && (
                <Address
                    displayError={formik.submitCount > 0}
                    readOnly={readOnly}
                    id="address"
                    result={handleChange}
                    value={formik.values.address}
                />
            )}
            {(isInternal || query.isInternal) && (
                <CollectConfig.CollectorTruck
                    displayError={formik.submitCount > 0}
                    readOnly={readOnly}
                    id="collectorTruck"
                    result={handleChange}
                    value={formik.values.collectorTruck}
                    region={planningPageState.region}
                    type={planningPageState.type}
                    required={false}
                />
            )}
            {(edit || readOnly) && (
                <>
                    {!isAdministrative && (
                        <Contacts
                            displayError={formik.submitCount > 0}
                            readOnly={readOnly}
                            id="contacts"
                            result={handleChange}
                            value={formik.values.contacts}
                        />
                    )}
                    {!(isInternal || query.isInternal) && !isAdministrative && (
                        <Order.Products
                            displayError={formik.submitCount > 0}
                            readOnly={readOnly}
                            id="products"
                            result={handleChange}
                            value={formik.values.products}
                            type={collectConfigType}
                            service={collectConfigService}
                            zoneId={(apiState?.db as CCServiceRo).zone?.id}
                            forceFetch
                            fetchOperational={false}
                        />
                    )}
                    {readOnly === false && (
                        <CollectConfig.PatchTracker
                            displayError={formik.submitCount > 0}
                            readOnly={readOnly}
                            id="patchTracker"
                            result={handleChange}
                            value={formik.values.patchTracker}
                        />
                    )}
                </>
            )}
            {readOnly === false && (
                <SubmitButton
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        (equal(apiState?.form, formik.values) && edit)
                    }
                />
            )}
        </form>
    );
};

export default CollectConfigForm;
