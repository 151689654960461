import { Tag } from 'primereact/tag';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
`;

export const SubmitButtonContainer = styled.div`
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    gap: 24px;
`;

export const BoardContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
`;

export const StyledTruckTypeTag = styled(Tag)<{ backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
`;
