import { StatusCodes } from 'http-status-codes';

import { BusinessError, DtoValidationError } from '@bbng/util/error';
import {
    CCServiceRo,
    CC_FAMILY,
    EDataType,
    ETrashType,
    ErrorResponsability,
    LineCreateDto,
    PRODUCT_FAMILY,
    ProductInCCOrCO,
    ProductRo,
    CollectConfigZone
} from '@bbng/util/types';

/**
 * Display total volume of given product on a given collect/collect_config family.
 * @param {MinimalProduct[]} products
 * @param {ECollectConfigFamily} family
 *
 * @returns {string}
 */
export const displayVolumeFromProducts = (products: MinimalProduct[], family: CC_FAMILY): string => {
    if (family === CC_FAMILY.COLLECT_DUMPSTER_ROTATION) {
        const volDeposit = products.find((p) => p.family === CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT)?.volume_m3 || 0;
        const volRetrieval = products.find((p) => p.family === CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL)?.volume_m3 || 0;
        return `DEP ${volDeposit}m³ / ENL ${volRetrieval}m³`;
    }
    const volume = products.reduce((acc, curr) => {
        return acc + (curr?.volume_m3 || 0) * curr.quantity;
    }, 0);
    return `${volume}m³`;
};

/**
 * Get total volume of given product on a given collect/collect_config family.
 * @param {MinimalProduct[]} products
 * @param {ECollectConfigFamily} family
 *
 * @returns {number}
 */
export const getVolumeFromProducts = (products: MinimalProduct[], family: CC_FAMILY): number => {
    const volume = products.reduce((acc, curr) => {
        if (family === CC_FAMILY.COLLECT_DUMPSTER_ROTATION && curr.family === CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT)
            return acc; // for rotation collects, do not consider deposit volume
        return acc + (curr?.volume_m3 || 0) * curr.quantity;
    }, 0);
    return volume;
};

/**
 * Get total volume of given product on a given collect/collect_config family but we are giving the volume of the last benne that has been deposited
 * @param {MinimalProduct[]} products
 * @param {ECollectConfigFamily} family
 *
 * @returns {number}
 */
export const getVolumeFromProductsForHistoric = (products: MinimalProduct[], family: CC_FAMILY): number => {
    let rotation = 0;

    const volume = products.reduce((acc, curr) => {
        if (family === CC_FAMILY.COLLECT_DUMPSTER_ROTATION && curr.family === CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT) {
            rotation = curr?.volume_m3 || 0 * curr.quantity;
            return curr?.volume_m3 || 0 * curr.quantity;
        }
        return acc + (curr?.volume_m3 || 0) * curr.quantity;
    }, 0);

    return rotation ? rotation : volume;
};

export type MinimalProduct = {
    quantity: number;
    volume_m3?: number;
    family: PRODUCT_FAMILY;
    trash_type?: ETrashType;
};

/**
 * @description
 * Return an array of ProductInCCOrCO from a CollectConfigLineCreateDto array.
 * So this is usefull when we create an Order and Collect
 * @param data CollectConfigLineCreateDto[]
 * @param products ProductRo[]
 * @param zone CollectConfigZone
 * @returns ProductInCCOrCO[]
 */
export const mapCCLineToProductInCCOrCO = (
    data: LineCreateDto[],
    products: ProductRo[],
    zone: CollectConfigZone,
    cc?: CCServiceRo
): ProductInCCOrCO[] => {
    return data.map((item) => {
        //We first need to check if the products was already present when we took the order
        if (cc !== undefined) {
            const productInCC = cc.products.find((p) => p.id === item.id);
            if (productInCC !== undefined) {
                return {
                    ...productInCC,
                    quantity: item.quantity
                };
            }
        }
        //If not, we need to check if the product new product
        const product = products.find((p) => p.id === item.id);
        if (product === undefined) {
            throw new BusinessError({
                httpCode       : StatusCodes.INTERNAL_SERVER_ERROR,
                responsability : ErrorResponsability.Server,
                code           : 'missing_item',
                dataType       : EDataType.PRODUCT,
                params         : {
                    item: 'product'
                }
            });
        }
        return {
            id                  : product.id,
            family              : product.family,
            internal            : product.internal,
            operational         : product.operational,
            name                : product.name,
            subname             : product.subname,
            price               : product.price,
            vat_rate_percentage : product.vat_rate_percentage,
            trash_type          : product.trash_type,
            volume_m3           : product.volume_m3,
            main_photo          : product.main_photo,
            description         : product.description,
            quantity            : item.quantity,
            billing_branch      : product.billing_branch,
            zone                : {
                id        : zone.id,
                name      : zone.name,
                metropole : zone.metropole
            },
            dumpster_type: product.dumpster_type
        };
    });
};

/**
 * Get accurate CC_FAMILY from input products
 * @param {MinimalProduct[]} products
 * @param {boolean} throwOnError
 *
 * @returns {CC_FAMILY}
 */
export const ccFamilyFromProducts = (products: MinimalProduct[], throwOnError = true): CC_FAMILY => {
    let family: CC_FAMILY;
    const families = [...new Set(products.map((prd) => prd.family))];
    if (families.length > 2 && throwOnError) {
        throw new BusinessError({
            httpCode       : StatusCodes.BAD_REQUEST,
            responsability : ErrorResponsability.Client,
            dataType       : EDataType.COLLECT_CONFIG,
            code           : 'collect_integrity'
        });
    }
    if (families.length === 2) {
        family = CC_FAMILY.COLLECT_DUMPSTER_ROTATION;
    } else {
        family = families[0];
    }

    return family;
};

export const mapVolumeToWeightDumpster = {
    7  : 1.5,
    8  : 1.5,
    10 : 1.8,
    12 : 2,
    15 : 2,
    20 : 2.5,
    30 : 3
};

/**
 * Ensure products are compatible with each other
 * @param {ProductInCCOrCO[]} products
 * @returns {boolean} throw error if products are not compatible
 */
export const checkProductsCompatibility = (products: ProductInCCOrCO[]): boolean => {
    const family = ccFamilyFromProducts(products);

    if (family === CC_FAMILY.COLLECT_DUMPSTER_ROTATION) {
        const deposit = products.find((product) => product.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT);
        const retrieval = products.find((product) => product.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL);
        /**
         * Deposit should at least have one dumpster type that is in the retrieval dumpster types.
         */
        if (deposit?.dumpster_type.every((type) => !retrieval?.dumpster_type.includes(type))) {
            throw new DtoValidationError({
                httpCode       : StatusCodes.BAD_REQUEST,
                responsability : ErrorResponsability.Client,
                dataType       : EDataType.COLLECT_CONFIG,
                code           : 'orderWithIncompatibleDumpsters'
            });
        }
    }

    return true;
};
