import React from 'react';
import styled from 'styled-components';

import { maskString } from '@bbng/util/misc';
import { CardErrors, CustomerRo, ECustomerCategory, UserRo } from '@bbng/util/types';

import { mapCustomerCategory } from '../../common/enumMapper';
import { Card } from '../../components/Card';
import RelationAutocomplete from '../common/RelationAutocomplete';

export type ConstructionSiteCustomerProps = {
    readOnly?: boolean;
    value?: ConstructionSiteCustomerState;
    id: string;
    result: (
        value: ConstructionSiteCustomerState,
        errors: null | string[] | CardErrors<ConstructionSiteCustomerState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type ConstructionSiteCustomerState = {
    customer?: CustomerRo;
};
export type ConstructionSiteCustomerErrorState = CardErrors<ConstructionSiteCustomerState>;

export const initialState: ConstructionSiteCustomerState = {
    customer: undefined
};
export const initialErrorState: ConstructionSiteCustomerErrorState = Object.fromEntries(
    Object.keys(initialState).map((k) => [k, null])
) as ConstructionSiteCustomerErrorState;

export const ConstructionSiteCustomer: React.FC<ConstructionSiteCustomerProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: ConstructionSiteCustomerProps) => {
    const [val, setVal] = React.useState<ConstructionSiteCustomerState>(value);
    const [err, setErr] = React.useState<ConstructionSiteCustomerErrorState>(initialErrorState);

    React.useEffect(() => {
        if (readOnly) return;

        const error = {
            customer: val.customer ? null : ['Le client est requis']
        };
        setErr(error);
    }, []);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: CustomerRo | UserRo | undefined, errors: string[] | null, childId: string) => {
        setVal((prev) => {
            const state = { ...prev, [childId]: value };
            return state;
        });
        setErr((prev) => {
            const state = { ...prev, [childId]: errors };
            return state;
        });
    };

    return (
        <StyledCard title="Client">
            {displayError && (err.customer ?? []).length > 0 && (
                <Errors>
                    {(err.customer ?? []).map((e, i) => (
                        <li key={e + i}>{e}</li>
                    ))}
                </Errors>
            )}
            <FullLineInput>
                <RelationAutocomplete.Customer
                    readOnly={readOnly}
                    baseValue={val.customer}
                    onSelect={(value) => {
                        handleChange(value, null, 'customer');
                    }}
                    onUnselect={() => {
                        handleChange(undefined, ['Le client est requis'], 'customer');
                    }}
                />
            </FullLineInput>
            {val.customer ? (
                <ClientInfo>
                    <div className="bold">{val.customer.membership}</div>
                    <div className="bold">{mapCustomerCategory(val.customer.category)}</div>
                    <div className="bold">{val.customer.name}</div>
                    {val.customer.category !== ECustomerCategory.INDIVIDUAL && (
                        <div>
                            <span>SIREN:</span>{' '}
                            <span className="bold">{maskString(val.customer.siren ?? '', '### ### ###')}</span>{' '}
                            <span>SIRET:</span>{' '}
                            <span className="bold">{maskString(val.customer.siret ?? '', '### ### ### #####')}</span>{' '}
                        </div>
                    )}
                </ClientInfo>
            ) : (
                <ClientInfo>
                    <h3 className="subtitle">Information Client:</h3>
                    <div className="bold">Aucun client sélectionné</div>
                </ClientInfo>
            )}
        </StyledCard>
    );
};

const ClientInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
    .subtitle {
        margin-top: 0;
        margin-bottom: 5px;
    }
    .bold {
        font-weight: bold;
    }
`;

const Errors = styled.div`
    color: #d22020;
`;

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)``;
