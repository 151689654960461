import Joi from 'joi';

import { EBillingBundle } from '../customer';
import { EZohoInvoiceStatus } from '../zoho';
import { BillingBranch } from '../misc';

export const InvoiceGenerateDtoSchema = Joi.object({
    month            : Joi.number().min(0).max(11).required(),
    year             : Joi.number().required(),
    numberToGenerate : Joi.number().min(1).required(),
    bundleMode       : Joi.string()
        .valid(...Object.values(EBillingBundle))
        .required(),
    billingBranch: Joi.string()
        .valid(...Object.values(BillingBranch))
        .required()
});

export const invoicePreGenerateDtoSchema = Joi.object({
    month : Joi.number().min(0).max(11).required(),
    year  : Joi.number().required()
});

export const InvoiceEmailDtoSchema = Joi.object({
    month         : Joi.number().min(0).max(11).required(),
    year          : Joi.number().required(),
    billingBranch : Joi.string()
        .valid(...Object.values(BillingBranch))
        .required()
});

export const TriggerDebitDtoSchema = InvoiceEmailDtoSchema;

export const InvoiceUpdateFromZohoInvoiceIdSchema = Joi.object({
    id_zoho_invoice     : Joi.string().required(),
    number_zoho_invoice : Joi.string().required(),
    status              : Joi.string()
        .valid(...Object.values(EZohoInvoiceStatus))
        .required(),
    scheduled_payment_date : Joi.string().isoDate(),
    paid_payment_date      : Joi.string().isoDate(),
    price                  : Joi.object({
        discounted_net_amount   : Joi.number(),
        discounted_vat_amount   : Joi.number(),
        discounted_total_amount : Joi.number()
    }).required()
});

export const InvoiceAfterCollectDtoSchema = Joi.object({
    ccId               : Joi.string().required(),
    customerId         : Joi.string().required(),
    constructionSiteId : Joi.string().required(),
    zoneId             : Joi.string().required()
});

export const InvoiceAfterDeliveryDtoSchema = Joi.object({
    ccId       : Joi.string().required(),
    customerId : Joi.string().required()
});
