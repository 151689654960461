import { ProductDocuments, ProductDocumentsProps } from './Documents';
import { ProductGeneral, ProductGeneralProps } from './General';

const Modules = {
    GeneralInfo: (props: ProductGeneralProps) => {
        return <ProductGeneral {...props} />;
    },
    Document: (props: ProductDocumentsProps) => {
        return <ProductDocuments {...props} />;
    },
    MainDocuments: (props: ProductDocumentsProps) => {
        return <ProductDocuments {...props} />;
    }
};

export default Modules;
