import { DEFAULT_CONTACT_EMAIL, DEFAULT_CONTACT_PHONE, INVOICE_CONTACT_EMAIL } from '@bbng/util/types';
import { formatE164ToInternational } from '../util-misc';

export type TEmailBaseTemplateParams = {
    title?: string;
    content: string;
    footerText: string;
    overview: string;
    cgvUrl?: string;
};

export const emailBaseTemplate = ({ content, footerText, overview, title, cgvUrl }: TEmailBaseTemplateParams) => `
<!DOCTYPE HTML
  PUBLIC '-//W3C//DTD XHTML 1.0 Transitional //EN' 'http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd'>
<html xmlns='http://www.w3.org/1999/xhtml' xmlns:v='urn:schemas-microsoft-com:vml'
  xmlns:o='urn:schemas-microsoft-com:office:office'>

<head>
  <!--[if gte mso 9]>
<xml>
  <o:OfficeDocumentSettings>
    <o:AllowPNG/>
    <o:PixelsPerInch>96</o:PixelsPerInch>
  </o:OfficeDocumentSettings>
</xml>
<![endif]-->
  <meta http-equiv='Content-Type' content='text/html; charset=UTF-8'>
  <meta name='viewport' content='width=device-width, initial-scale=1.0'>
  <meta name='x-apple-disable-message-reformatting'>
  <!--[if !mso]><!-->
  <meta http-equiv='X-UA-Compatible' content='IE=edge'>
  <!--<![endif]-->
  <title></title>

  <style type='text/css'>
    @media only screen and (min-width: 620px) {
      .u-row {
        width: 600px !important;
      }

      .u-row .u-col {
        vertical-align: top;
      }

      .u-row .u-col-50 {
        width: 300px !important;
      }

      .u-row .u-col-100 {
        width: 600px !important;
      }

    }

    @media (max-width: 620px) {
      .u-row-container {
        max-width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }

      .u-row .u-col {
        min-width: 320px !important;
        max-width: 100% !important;
        display: block !important;
      }

      .u-row {
        width: calc(100% - 40px) !important;
      }

      .u-col {
        width: 100% !important;
      }

      .u-col>div {
        margin: 0 auto;
      }
    }

    body {
      margin: 0;
      padding: 0;
    }

    table,
    tr,
    td {
      vertical-align: top;
      border-collapse: collapse;
    }

    p {
      margin: 0;
    }

    .ie-container table,
    .mso-container table {
      table-layout: fixed;
    }

    * {
      line-height: inherit;
    }

    .footer a, a[x-apple-data-detectors='true'] {
      color: inherit !important;
      text-decoration: none !important;
    }

    a, a[x-apple-data-detectors='true'] {
      color: #707efa;
      text-decoration: underline;
    }

    table,
    td {
      color: #000000;
    }

  </style>



  <!--[if !mso]><!-->
  <link href='https://fonts.googleapis.com/css?family=Urbanist:100,300,500,700&display=swap' rel='stylesheet' type='text/css'>
  <!--<![endif]-->

</head>

<body class='clean-body u_body'
  style='margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #f9f9f9;color: #000000'>
  <!--[if IE]><div class='ie-container'><![endif]-->
  <!--[if mso]><div class='mso-container'><![endif]-->
  <table
    style='border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #f9f9f9;width:100%'
    cellpadding='0' cellspacing='0'>

    <tbody id='u_body'>
      <tr style='vertical-align: top'>
        <td style='word-break: break-word;border-collapse: collapse !important;vertical-align: top'>
          <!--[if (mso)|(IE)]><table width='100%' cellpadding='0' cellspacing='0' border='0'><tr><td align='center' style='background-color: #f9f9f9;'><![endif]-->

          <!-- PRE-HEADER TEXT -->
          <p style="color: #f9f9f9; height: 0px; max-height: 0px; opacity: 0;">${overview} ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌</p>

          <div class='u-row-container' style='padding: 0px;background-color: #f9f9f9'>
            <div class='u-row'
              style='Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #f9f9f9;'>
              <div
                style='border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;'>
                <!--[if (mso)|(IE)]><table width='100%' cellpadding='0' cellspacing='0' border='0'><tr><td style='padding: 0px;background-color: #f9f9f9;' align='center'><table cellpadding='0' cellspacing='0' border='0' style='width:600px;'><tr style='background-color: #f9f9f9;'><![endif]-->

                <!--[if (mso)|(IE)]><td align='center' width='600' style='width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;' valign='top'><![endif]-->
                <div class='u-col u-col-100'
                  style='max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;'>
                  <div style='height: 100%;width: 100% !important;'>
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style='height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;'>
                      <!--<![endif]-->

                      <table style='font-family:arial,sans-serif;' role='presentation' cellpadding='0' cellspacing='0'
                        width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td style='overflow-wrap:break-word;word-break:break-word;padding:15px;font-family:arial,sans-serif;' align='left'>

                              <table height='0px' align='center' border='0' cellpadding='0' cellspacing='0' width='100%'
                                style='border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #f9f9f9;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%'>
                                <tbody>
                                  <tr style='vertical-align: top'>
                                    <td
                                      style='word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%'>
                                      <span>&#160;</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td><![endif]-->
                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>



          <div class='u-row-container' style='padding: 0px;background-color: transparent'>
            <div class='u-row'
              style='Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;'>
              <div
                style='border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;'>
                <!--[if (mso)|(IE)]><table width='100%' cellpadding='0' cellspacing='0' border='0'><tr><td style='padding: 0px;background-color: transparent;' align='center'><table cellpadding='0' cellspacing='0' border='0' style='width:600px;'><tr style='background-color: #ffffff;'><![endif]-->

                <!--[if (mso)|(IE)]><td align='center' width='600' style='width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;' valign='top'><![endif]-->
                <div class='u-col u-col-100'
                  style='max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;'>
                  <div style='height: 100%;width: 100% !important;'>
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style='height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;'>
                      <!--<![endif]-->

                      <table style='font-family:arial,sans-serif;' role='presentation' cellpadding='0' cellspacing='0'
                        width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td style='overflow-wrap:break-word;word-break:break-word;padding:25px 10px;font-family:arial,sans-serif;' align='left'>

                              <table width='100%' cellpadding='0' cellspacing='0' border='0'>
                                <tr>
                                  <td style='padding-right: 0px;padding-left: 0px;' align='center'>
                                    <a href='https://endless.fr' target='_blank'>
                                      <img align='center' border='0' src='https://i.postimg.cc/Ty6bpGqY/image-5.png' alt='Logo EndLess'
                                        title='Logo EndLess'
                                        style='outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 16%;max-width: 92.8px;'
                                        width='92.8' />
                                    </a>
                                  </td>
                                </tr>
                              </table>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td><![endif]-->
                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>



          ${
              title
                  ? `
            <div class='u-row-container' style='padding: 0px;background-color: transparent'>
              <div class='u-row'
                style='Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;'>
                <div
                  style='border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;'>
                  <!--[if (mso)|(IE)]><table width='100%' cellpadding='0' cellspacing='0' border='0'><tr><td style='padding: 0px;background-color: transparent;' align='center'><table cellpadding='0' cellspacing='0' border='0' style='width:600px;'><tr style='background-color: transparent;'><![endif]-->

                  <!--[if (mso)|(IE)]><td align='center' width='600' style='background-color: #5465fc;width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;' valign='top'><![endif]-->
                  <div class='u-col u-col-100'
                    style='max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;'>
                    <div style='background-color: #5465fc;height: 100%;width: 100% !important;'>
                      <!--[if (!mso)&(!IE)]><!-->
                      <div
                        style='height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;'>
                        <!--<![endif]-->

                        <table style='font-family:arial,sans-serif;' role='presentation' cellpadding='0' cellspacing='0'
                          width='100%' border='0'>
                          <tbody>
                            <tr>
                              <td
                                style='overflow-wrap:break-word;word-break:break-word;padding:0px 10px 30px;font-family:arial,sans-serif;' align='left'>

                                <div style='line-height: 100%; text-align: center; word-wrap: break-word;'>
                                  <p style='font-size: 14px; line-height: 100%; text-align: center;'><span
                                      style='font-size: 28px; line-height: 28px; color: #ffffff; font-family: obviously-wide, sans-serif;'><br />${title}</span></p>
                                </div>

                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                </div>
              </div>
            </div>
            `
                  : ''
          }



          <div class='u-row-container' style='padding: 0px;background-color: transparent'>
            <div class='u-row'
              style='Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;'>
              <div
                style='border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;'>
                <!--[if (mso)|(IE)]><table width='100%' cellpadding='0' cellspacing='0' border='0'><tr><td style='padding: 0px;background-color: transparent;' align='center'><table cellpadding='0' cellspacing='0' border='0' style='width:600px;'><tr style='background-color: #ffffff;'><![endif]-->

                <!--[if (mso)|(IE)]><td align='center' width='600' style='width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;' valign='top'><![endif]-->
                <div class='u-col u-col-100'
                  style='max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;'>
                  <div style='height: 100%;width: 100% !important;'>
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style='height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;'>
                      <!--<![endif]-->

                      ${content}

                      <table style='font-family:arial,sans-serif;margin-top:40px;margin-bottom:16px;' role='presentation' cellpadding='0' cellspacing='0'
                        width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td style='overflow-wrap:break-word;word-break:break-word;padding:0 25px 40px;font-family:arial,sans-serif;font-weight:300;' align='left'>

                              <div style='line-height: 100%; text-align: left; word-wrap: break-word;'>
                                <p style='line-height: 100%; font-size: 14px; text-align: left;'><span
                                    style='color: #aaaaaa; font-size: medium;'><span
                                      style='caret-color: #aaaaaa; font-size: 16px; line-height: 22px;'><em>${footerText}</em></span></span></p>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table style='font-family:arial,sans-serif;margin-bottom:32px;' role='presentation' cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td align="center" class="es-m-txt-c es-m-p10r es-m-p10l" style="Margin:0;padding: 5px 100px;">
                              <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:18px">
                                Pour vos prochaines évacuations,
                              </p>
                              <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:18px">
                                téléchargez notre application MY ENDLESS
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td style='overflow-wrap:break-word;word-break:break-word;padding:25px 10px 10px;font-family:arial,sans-serif;' align='center'>

                            <div align='center'>
                              <div style='display: table'>
                                <!--[if (mso)|(IE)]><table width='187' cellpadding='0' cellspacing='0' border='0'><tr><td style='border-collapse:collapse;' align='left'><table width='100%' cellpadding='0' cellspacing='0' border='0' style='border-collapse:collapse; mso-table-lspace: 0pt;mso-table-rspace: 0pt; width:187px;'><tr><![endif]-->


                                <!--[if (mso)|(IE)]><td width='200' style='width:px; padding-right: 15px;' valign='top'><![endif]-->
                                <table align='left' border='0' cellspacing='0' cellpadding='0' width='200' height='95'
                                  style='width: auto !important;height: 95px !important;display: inline-block;border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin: 0 8px'>
                                  <tbody>
                                    <tr style='vertical-align: top'>
                                      <td align='left' valign='middle'
                                        style='word-break: break-word;border-collapse: collapse !important;vertical-align: top'>
                                        <a href='https://apps.apple.com/fr/app/my-endless/id6449434687' title='My EndLess app store' target='_blank'>
                                          <img src='https://i.postimg.cc/3xn6fy3L/Google-App-Store-badge-FR.png' alt='My EndLess app store'
                                            title='My EndLess app store' width='200'
                                            style='outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 200px !important'>
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <!--[if (mso)|(IE)]></td><![endif]-->

                                <!--[if (mso)|(IE)]><td width='200' style='width:200px; padding-right: 15px;' valign='top'><![endif]-->
                                  <table align='left' border='0' cellspacing='0' cellpadding='0' width='200' height='95'
                                  style='width: auto !important;height: 32px !important;display: inline-block;border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin: 0 8px'>
                                  <tbody>
                                    <tr style='vertical-align: top'>
                                      <td align='left' valign='middle'
                                        style='word-break: break-word;border-collapse: collapse !important;vertical-align: top'>
                                        <a href='https://play.google.com/store/apps/details?id=fr.ndlss.customer&pcampaignid=web_share' title='My EndLess google play' target='_blank'>
                                          <img src='https://i.postimg.cc/wB1WW0fv/Google-Play-Store-badge-FR.png' alt='My EndLess google play'
                                            title='My EndLess google play' width='200'
                                            style='outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 200px !important'>
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <!--[if (mso)|(IE)]></td><![endif]-->

                                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                              </div>
                            </div>

                            </td>
                          </tr>

                        </tbody>
                      </table>

                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td><![endif]-->
                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>



          <div class='u-row-container footer' style='padding: 0px;background-color: transparent'>
            <div class='u-row'
              style='Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #18163a;'>
              <div
                style='border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;'>
                <!--[if (mso)|(IE)]><table width='100%' cellpadding='0' cellspacing='0' border='0'><tr><td style='padding: 0px;background-color: transparent;' align='center'><table cellpadding='0' cellspacing='0' border='0' style='width:600px;'><tr style='background-color: #18163a;'><![endif]-->

                <!--[if (mso)|(IE)]><td align='center' width='300' style='background-color: #5465fc;width: 300px;padding: 20px 20px 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;' valign='top'><![endif]-->
                <div class='u-col u-col-50'
                  style='max-width: 320px;min-width: 300px;display: table-cell;vertical-align: middle; '>
                  <div style='background-color: #5465fc;height: 100%;width: 100% !important; padding-bottom: 16px'>
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style='height: 100%; padding: 20px 20px 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;'>
                      <!--<![endif]-->

                      <table style='font-family:arial,sans-serif;' role='presentation' cellpadding='0' cellspacing='0'
                        width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,sans-serif;' align='left'>

                              <div style='line-height: 140%; text-align: left; word-wrap: break-word;'>
                                <img align='center' border='0' src='https://i.postimg.cc/MGn1MVgC/Logo-White.png' alt='Logo_endless'
                                    title='Logo_endless'
                                    style='outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 120px; margin-bottom: 8px;'
                                    width='120px' />
                                <div style='font-weight:100; margin-bottom: 4px;'>
                                  <p style='font-size: 14px; line-height: 140%;'><span
                                      style='font-size: 14px; line-height: 19.6px; color: #ecf0f1;'>21 rue Châtillon,</span></p>
                                  <p style='font-size: 14px; line-height: 140%;'><span
                                      style='font-size: 14px; line-height: 19.6px; color: #ecf0f1;'>75014 PARIS</span></p>
                                </div>
                                <p style='font-size: 14px; line-height: 140%;'><span
                                    style='font-size: 14px; line-height: 19.6px; color: #ecf0f1;'><a style="color: #ecf0f1;text-decoration:none;" href="tel:+33180181953">01 80 18 19 53</a> |
                                    <a style="color: #ecf0f1;text-decoration:none;" href="https://endless.fr" target='_blank'>endless.fr</a></span></p>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td><![endif]-->
                <!--[if (mso)|(IE)]><td align='center' width='300' style='background-color: #fbcfa0;width: 300px;padding: 0px 0px 0px 20px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;' valign='top'><![endif]-->
                <div class='u-col u-col-50'
                  style='max-width: 320px;min-width: 300px;display: table-cell;vertical-align: middle;'>
                  <div style='background-color: #fbcfa0;height: 100%;width: 100% !important;'>
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style='padding: 10px 20px 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;'>
                      <!--<![endif]-->

                      <table style='font-family:arial,sans-serif;' role='presentation' cellpadding='0' cellspacing='0'
                        width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td
                              style='overflow-wrap:break-word;word-break:break-word;padding:15px 10px 10px;font-family:arial,sans-serif;' align='left'>

                              <div align='left'>
                                <div style='display: table; max-width:240px;'>
                                  <!--[if (mso)|(IE)]><table width='187' cellpadding='0' cellspacing='0' border='0'><tr><td style='border-collapse:collapse;' align='left'><table width='100%' cellpadding='0' cellspacing='0' border='0' style='border-collapse:collapse; mso-table-lspace: 0pt;mso-table-rspace: 0pt; width:187px;'><tr><![endif]-->


                                  <!--[if (mso)|(IE)]><td width='32' style='width:32px; padding-right: 15px;' valign='top'><![endif]-->
                                  <table align='left' border='0' cellspacing='0' cellpadding='0' width='32' height='32'
                                    style='width: 32px !important;height: 32px !important;display: inline-block;border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin-right: 15px'>
                                    <tbody>
                                      <tr style='vertical-align: top'>
                                        <td align='left' valign='middle'
                                          style='word-break: break-word;border-collapse: collapse !important;vertical-align: top'>
                                          <a href='https://www.facebook.com/groupe.endless/' title='Facebook' target='_blank'>
                                            <img src='https://i.postimg.cc/d13vQJ16/Icone-Facebook-Blanc.png' alt='Facebook'
                                              title='Facebook' width='32'
                                              style='outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important'>
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <!--[if (mso)|(IE)]></td><![endif]-->

                                  <!--[if (mso)|(IE)]><td width='32' style='width:32px; padding-right: 15px;' valign='top'><![endif]-->
                                    <table align='left' border='0' cellspacing='0' cellpadding='0' width='32' height='32'
                                    style='width: 32px !important;height: 32px !important;display: inline-block;border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin-right: 15px'>
                                    <tbody>
                                      <tr style='vertical-align: top'>
                                        <td align='left' valign='middle'
                                          style='word-break: break-word;border-collapse: collapse !important;vertical-align: top'>
                                          <a href='https://www.instagram.com/groupe_endless/' title='Instagram' target='_blank'>
                                            <img src='https://i.postimg.cc/Bt9GCDC7/Icone-Instagram-Blanc.png' alt='Instagram'
                                              title='Instagram' width='32'
                                              style='outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important'>
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <!--[if (mso)|(IE)]></td><![endif]-->

                                  <!--[if (mso)|(IE)]><td width='32' style='width:32px; padding-right: 0px;' valign='top'><![endif]-->
                                  <table align='left' border='0' cellspacing='0' cellpadding='0' width='32' height='32'
                                    style='width: 32px !important;height: 32px !important;display: inline-block;border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin-right: 15px'>
                                    <tbody>
                                      <tr style='vertical-align: top'>
                                        <td align='left' valign='middle'
                                          style='word-break: break-word;border-collapse: collapse !important;vertical-align: top'>
                                          <a href='https://www.linkedin.com/company/groupe-endless/' title='LinkedIn' target='_blank'>
                                            <img src='https://i.postimg.cc/XNZGQb89/Icone-Linkedin-Blanc.png' alt='LinkedIn' title='LinkedIn' width='32'
                                              style='outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important'>
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <!--[if (mso)|(IE)]></td><![endif]-->

                                  <!--[if (mso)|(IE)]><td width='32' style='width:32px; padding-right: 0px;' valign='top'><![endif]-->
                                  <table align='left' border='0' cellspacing='0' cellpadding='0' width='32' height='32'
                                    style='width: 32px !important;height: 32px !important;display: inline-block;border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin-right: 15px'>
                                    <tbody>
                                      <tr style='vertical-align: top'>
                                        <td align='left' valign='middle'
                                          style='word-break: break-word;border-collapse: collapse !important;vertical-align: top'>
                                          <a href='https://www.youtube.com/user/bigbagngo' title='YouTube' target='_blank'>
                                            <img src='https://i.postimg.cc/J4vs9dpS/Icone-Youtube-Blanc.png' alt='YouTube' title='YouTube' width='32'
                                              style='outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important'>
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <!--[if (mso)|(IE)]></td><![endif]-->

                                  <!--[if (mso)|(IE)]><td width='32' style='width:32px; padding-right: 15px;' valign='top'><![endif]-->
                                  <table align='left' border='0' cellspacing='0' cellpadding='0' width='32' height='32'
                                    style='width: 32px !important;height: 32px !important;display: inline-block;border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin-right: 15px'>
                                    <tbody>
                                      <tr style='vertical-align: top'>
                                        <td align='left' valign='middle'
                                          style='word-break: break-word;border-collapse: collapse !important;vertical-align: top'>
                                          <a href='https://twitter.com/endless_groupe' title='Twitter' target='_blank'>
                                            <img src='https://i.postimg.cc/wMspJFDs/Icone-Twitter-Blanc.png' alt='Twitter'
                                              title='Twitter' width='32'
                                              style='outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important'>
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <!--[if (mso)|(IE)]></td><![endif]-->

                                  <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                                </div>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table style='margin-top: 10px; font-family:arial,sans-serif;' role='presentation' cellpadding='0' cellspacing='0'
                        width='100%' border='0'>
                        <tbody>
                          ${
                              cgvUrl
                                  ? `
                            <tr>
                              <td
                                style='overflow-wrap:break-word;word-break:break-word;padding:1px 10px 0px;font-family:arial,sans-serif;' align='left'>

                                <div style='line-height: 140%; text-align: left; word-wrap: break-word;'>
                                  <a href='${cgvUrl}' title='Conditions générales' target='_blank' style='color: #ffffff !important; font-size: 14px; line-height: 19.6px; font-weight:300; text-decoration:underline !important;'>
                                    Conditions générales
                                  </a>
                                </div>

                              </td>
                            </tr>
                            `
                                  : ''
                          }
                          <tr>
                            <td
                              style='overflow-wrap:break-word;word-break:break-word;padding:1px 10px 0px;font-family:arial,sans-serif;' align='left'>

                              <div style='line-height: 140%; text-align: left; word-wrap: break-word;'>
                                <p style='line-height: 140%; font-size: 14px;'><span
                                    style='font-size: 14px; line-height: 19.6px;'><span
                                      style='color: #ffffff; font-size: 14px; line-height: 19.6px; font-weight:300'><span
                                        style='line-height: 19.6px; font-size: 14px;'>EndLess &copy;&nbsp; All Rights
                                        Reserved</span></span></span></p>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td><![endif]-->
                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>


          <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
        </td>
      </tr>
    </tbody>
  </table>
  <!--[if mso]></div><![endif]-->
  <!--[if IE]></div><![endif]-->
</body>

</html>
`;

/**
 * Format phone number in an html link
 * @param {string} phone E164 formatted
 */
export const phoneNumberTemplate = (phone: string) =>
    `<a target="_blank"href="tel:${phone}" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#5465fc;font-size:16px;">${formatE164ToInternational(
        phone
    )}</a>`;

export const defaultFooterText = `Si vous avez des questions, n'hésitez pas à nous contacter sur <a target="_blank"href="mailto:${DEFAULT_CONTACT_EMAIL}" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#5465fc;font-size:16px;">${DEFAULT_CONTACT_EMAIL}</a> ou par téléphone au ${phoneNumberTemplate(
    DEFAULT_CONTACT_PHONE
)}`;

export const invoiceFooterText = `Si vous avez des questions, n'hésitez pas à nous contacter sur <a target="_blank"href="mailto:${INVOICE_CONTACT_EMAIL}" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#5465fc;font-size:16px;">${INVOICE_CONTACT_EMAIL}</a> ou par téléphone au ${phoneNumberTemplate(
    DEFAULT_CONTACT_PHONE
)}`;
