import moment from 'moment';
import { useEffect, useRef } from 'react';

import {
    AddressRo,
    CC_FAMILY,
    CC_STATUS,
    CollectRo,
    CollectorRo,
    PlanningRo,
    PlanningShiftStep,
    PlanningShiftStepCategory,
    ProductInCCOrCO,
    TruckRo,
    PlanningShiftStepService,
    PlanningShiftStepAdministrative,
    CCRo,
    PlanningShiftStepEmptying,
    CollectService,
    PlanningShift,
    LandfillRo
} from '@bbng/util/types';

import { PlanningShiftsState } from '.';
import { ShiftHeaderState } from './shiftHeader';

export const isStepServiceOrAdmin = (
    step?: PlanningShiftStep
): step is PlanningShiftStepService | PlanningShiftStepAdministrative => {
    if (!step) return false;
    return 'collect_config_id' in step;
};

export const isStepService = (step?: PlanningShiftStep): step is PlanningShiftStepService => {
    return step?.category === PlanningShiftStepCategory.SERVICE;
};

export const isStepEmptying = (step?: PlanningShiftStep): step is PlanningShiftStepEmptying => {
    if (!step) return false;
    return 'landfill_id' in step;
};

export const isCollectService = (collect?: CollectRo): collect is CollectService => {
    return collect?.category === PlanningShiftStepCategory.SERVICE;
};

export const getTill = (collect: PlanningShiftStep): moment.Moment => {
    if (
        moment
            .utc(collect.scheduled_at)
            .add(collect.scheduled_service_time, 'minutes')
            .isBefore(moment.utc(collect.scheduled_end_at))
    ) {
        return moment.utc(collect.scheduled_end_at);
    }
    return moment.utc(collect.scheduled_at).add(collect.scheduled_service_time, 'minutes');
};

export const getMinimumStartDate = (plannings: PlanningRo[], collects: CollectRo[]): Date => {
    if (!plannings || plannings?.length === 0) return moment().set('hours', 6).toDate();
    const planningStarts = plannings.map((el) => moment(el.shift_config.start_date).subtract(0.5, 'hour'));
    const collectStarts = collects.map((el) => moment(el.arrived_at).subtract(0.5, 'hour'));
    return moment.min([...planningStarts, ...collectStarts]).toDate();
};

export const getMaximumEndDate = (plannings: PlanningRo[]): Date => {
    if (!plannings || plannings?.length === 0) return moment().set('hours', 6).add(10, 'hours').toDate();
    const dates = plannings.map((el) => moment(el.shift_config.end_date).add(1, 'hour'));
    dates.push(
        ...plannings
            .filter((pl) => pl.shift?.steps_driver?.length === 2)
            .map((pl) => {
                const lastStepOfPlanning = pl.shift.steps_driver.find(
                    (step) => step.category === PlanningShiftStepCategory.DRIVER_HOUSE_END
                );
                return moment(lastStepOfPlanning?.scheduled_end_at).add(1, 'hour');
            })
    );
    return moment.max(dates).toDate();
};

export const associateColorToStep = (category: PlanningShiftStepCategory): string => {
    switch (category) {
        case PlanningShiftStepCategory.EMPTYING:
            return '#b2a4d5ca';
        case PlanningShiftStepCategory.ADMINISTRATIVE:
            return '#EEF2E3CA';
        case PlanningShiftStepCategory.DRIVER_HOUSE_END:
        case PlanningShiftStepCategory.DRIVER_HOUSE_START:
            return '#f2e3e3CA';
        case PlanningShiftStepCategory.BREAK:
            return '#ffb091b5';
        default:
            return '#BEDAF0CA';
    }
};

export const displayIconIfRotation = (product: ProductInCCOrCO, cc?: CCRo): JSX.Element => {
    if (cc) {
        if (cc.family === CC_FAMILY.COLLECT_DUMPSTER_ROTATION) {
            if (product.family.includes('DEPOSIT'))
                return <i className="pi pi-download" style={{ fontSize: '1rem', textAlign: 'center' }} />;
            if (product.family.includes('RETRIEVAL'))
                return <i className="pi pi-upload" style={{ fontSize: '1rem', textAlign: 'center' }} />;
        }
    }
    return <></>;
};

export const associateIconToCollect = (step: PlanningShiftStep, cc?: CCRo): string => {
    if (cc) {
        switch (cc.family) {
            case CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT:
                return 'pi-download';
            case CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT:
                return 'pi-clock';
            case CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL:
            case CC_FAMILY.COLLECT_BIG_BAG:
                return 'pi-upload';
            case CC_FAMILY.COLLECT_DUMPSTER_ROTATION:
                return 'pi-sync';
        }
    }
    switch (step.category) {
        case PlanningShiftStepCategory.EMPTYING:
            return 'pi-sign-out';
        case PlanningShiftStepCategory.ADMINISTRATIVE:
            return 'pi-cog';
        case PlanningShiftStepCategory.DRIVER_HOUSE_START:
        case PlanningShiftStepCategory.DRIVER_HOUSE_END:
            return 'pi-home';
        case PlanningShiftStepCategory.BREAK:
            return 'pi-pause';
        default:
            return '';
    }
};

export const getBorderFromStatus = (status?: CC_STATUS): string | undefined => {
    switch (status) {
        case CC_STATUS.FINISHED:
            return '#22C55E';
        case CC_STATUS.HAZARD:
            return '#F59E0B';
        case CC_STATUS.CANCELED:
            return '#EF4444';
        default:
            return undefined;
    }
};

export const mapPlanningToShiftHeaderState = (
    planning: PlanningRo,
    truck: TruckRo,
    collector: CollectorRo
): ShiftHeaderState => {
    return {
        id                   : planning.id,
        collector,
        truck,
        end_date             : planning.shift_config.end_date,
        start_date           : planning.shift_config.start_date,
        is_available         : truck?.is_available,
        distance             : planning.shift?.distance || 0,
        duration             : planning.shift?.duration || '',
        steps_break          : planning.shift?.steps_break || [],
        steps_driver         : planning.shift?.steps_driver || [],
        steps_service        : planning.shift?.steps_service || [],
        steps_administrative : planning.shift?.steps_administrative || [],
        steps_emptying       : planning.shift?.steps_emptying || [],
        selected             : true
    };
};

export const getCollectAddressFromStep = (
    category: PlanningShiftStepCategory,
    cc?: CCRo,
    truck?: TruckRo,
    landfill?: LandfillRo
): AddressRo | undefined => {
    switch (category) {
        case PlanningShiftStepCategory.SERVICE:
        case PlanningShiftStepCategory.ADMINISTRATIVE:
            return cc?.address;
        case PlanningShiftStepCategory.EMPTYING:
            return landfill?.address;
        case PlanningShiftStepCategory.DRIVER_HOUSE_END:
        case PlanningShiftStepCategory.DRIVER_HOUSE_START:
            return truck?.garage_address;
        default:
            return undefined;
    }
};

export const getCollectorDuplicates = (shifts: PlanningShiftsState): CollectorRo[] => {
    const duplicates = Object.values(shifts).reduce((acc, shift) => {
        Object.values(shifts).forEach((shift2) => {
            if (shift2.is_available && shift.is_available) {
                // look for duplicates among available shifts only
                if (shift2.id !== shift.id && shift2.collector?.id && shift2.collector.id === shift.collector?.id) {
                    if (acc.some((collector) => collector.id === shift2.collector?.id) === false) {
                        acc.push(shift2.collector);
                    }
                }
            }
        });
        return acc;
    }, [] as CollectorRo[]);
    return [...new Set(duplicates)];
};

export const usePrevious = <T,>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const planningFinished = (planning: PlanningRo | undefined): boolean => {
    if (!planning) return false;
    return planning.shift.steps_driver.some(
        (step) => step.category === PlanningShiftStepCategory.DRIVER_HOUSE_END && !!step.collect_id
    );
};

export const collectStatus = (cc?: CCRo, collect?: CollectRo): CC_STATUS => {
    if (collect !== undefined) {
        return collect.status;
    }
    if (cc && Object.keys(cc).length > 0) {
        return cc.status;
    }
    return CC_STATUS.PLANNED;
};

export const sortPlannings = (plannings: PlanningRo[], trucksByPlanning: Record<string, TruckRo>): PlanningRo[] => {
    const sortedPlannings =
        plannings?.sort((a, b) => {
            const truckA = trucksByPlanning[a.id];
            const truckB = trucksByPlanning[b.id];
            if (truckA?.is_available && truckB?.is_available) {
                /**
                 * if both plannings have no collector, put the longest one first
                 */
                if (!a.collector_id[0] && !b.collector_id[0]) {
                    return _totalShiftSteps(b.shift) - _totalShiftSteps(a.shift);
                } else return !a.collector_id[0] ? 1 : -1;
            }
            return !truckA?.is_available ? 1 : -1;
        }) ?? [];
    return sortedPlannings;
};

const _totalShiftSteps = (shift: PlanningShift): number => {
    return (
        shift.steps_service.length +
        shift.steps_administrative.length +
        shift.steps_break.length +
        shift.steps_driver.length +
        shift.steps_emptying.length
    );
};
