import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Button as PRButton } from 'primereact/button';

import { getMiddleOfTheDay } from '@bbng/util/misc';
import { ISODate, DumpsterOnSiteQuery, ConstructionSiteRo, DumpsterOnSiteRo } from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { COLLECT_ORCHESTRATION_BASE_URL, urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { FiltersContainer, StyledAccordion, StyleSpan, TitleContainer } from './style';
import Calendar from '../../components/Calendar';
import { AccordionTab } from 'primereact/accordion';
import { toast } from '../../components/Toast';
import Button from '../../components/Button';
import Input from '../../components/Inputs';
import { dumpsterOnSiteListHeaders } from './headers';
import { DataTableSortOrderType } from 'primereact/datatable';
import { axiosClient } from '../../common/axios';
import { defaultErrorToast } from '../../common/syncRequest';

type QueryParams = {
    customer_id?: string;
    min_deposit_date?: ISODate;
    max_deposit_date?: ISODate;
    min_retrieval_date?: ISODate;
    max_retrieval_date?: ISODate;
    zone_id?: string;
    volume_m3?: number;
    construction_site_id?: string;
    exclude_retrieved?: boolean;
    exclude_planned_for_retrieval?: boolean;
};

export const DumpsterOnSiteListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [customer_id, setCustomerId] = React.useState<string | undefined>(query.customer_id);
    const [construction_site_id, setConstructionSiteId] = React.useState<string | undefined>(
        query.construction_site_id
    );
    const [constructionSite, setConstructionSite] = React.useState<ConstructionSiteRo | undefined>(undefined);
    const [minDepositDate, setMinDepositDate] = React.useState<ISODate | undefined>(
        query.min_deposit_date ?? undefined
    );
    const [maxDepositDate, setMaxDepositDate] = React.useState<ISODate | undefined>(
        query.max_deposit_date ?? undefined
    );
    const [minRetrievalDate, setMinRetrievalDate] = React.useState<ISODate | undefined>(
        query.min_retrieval_date ?? undefined
    );
    const [maxRetrievalDate, setMaxRetrievalDate] = React.useState<ISODate | undefined>(
        query.max_retrieval_date ?? undefined
    );
    const [exclude_retrieved, setExcludeRetrieved] = React.useState<boolean>(query.exclude_retrieved ?? true);
    const [exclude_planned_for_retrieval, setExcludePlannedForRetrieval] = React.useState<boolean>(
        query.exclude_planned_for_retrieval ?? true
    );
    const [volume_m3, setVolumeM3] = React.useState<number | undefined>(query.volume_m3);
    const [sortOrder, setSortOrder] = React.useState<DataTableSortOrderType>(-1); // DESC by default (most recent ones first)

    const [triggerPageRefresh, setTriggerPageRefresh] = useState<boolean>(false);
    useEffect(() => {
        setTriggerPageRefresh(false);
    }, [triggerPageRefresh]);

    useEffect(() => {
        /**
         * Each time customer changes, reset construction site
         */
        setConstructionSiteId(undefined);
        setConstructionSite(undefined);
    }, [customer_id]);

    const [isExportLoading, setIsExportLoading] = React.useState(false);

    const exportData = useCallback(async () => {
        setIsExportLoading(true);

        try {
            const response = await axiosClient.get(urlApiBuilder.dumpsterOnSiteExport(), {
                params: {
                    ...query
                } as DumpsterOnSiteQuery,
                responseType: 'blob'
            });

            const formatedDay = moment().format('YYYY-MM-DD_HH:mm:ss');
            const name = `export-bennes-${formatedDay}`;

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.data);

            link.download = `${name}.xlsx`;
            link.click();
            link.remove();
        } catch (e) {
            defaultErrorToast("Une erreur est survenue lors de l'export des bennes");
            console.error(e);
        }
        setIsExportLoading(false);
    }, [JSON.stringify(query)]);

    return (
        <>
            <Listing<any, DumpsterOnSiteRo>
                url={COLLECT_ORCHESTRATION_BASE_URL}
                endpoint="collects/dumpster-on-site"
                addButtonUrl={urlBuilder.dumpsterOnSiteCreate()}
                hideBulkMenu
                pageUrl={urlBuilder.dumpsterOnSiteList()}
                displaySelectColumn={false}
                displayActionColumn={true}
                triggerPageRefresh={triggerPageRefresh}
                showSearch={false}
                sortField="deposited_at"
                sortOrder={sortOrder}
                onSort={(e) => setSortOrder(e.sortOrder)}
                enrichData={async (data) => {
                    data.data.ro = await fetchDataRelation(data.data.ro ?? [], {
                        customer_id                 : true,
                        construction_site_id        : true,
                        zone_id                     : true,
                        retrieval_collect_config_id : true
                    });
                    return data;
                }}
                queryParams={
                    {
                        customer_id,
                        construction_site_id,
                        exclude_retrieved,
                        exclude_planned_for_retrieval,
                        volume_m3,
                        min_deposited_at: minDepositDate
                            ? moment(minDepositDate).startOf('day').toISOString()
                            : undefined,
                        max_deposited_at: maxDepositDate
                            ? moment(maxDepositDate).add(1, 'day').startOf('day').toISOString()
                            : undefined, // max date is exclusive so exclude the next day for better UX
                        min_retrieved_at: minRetrievalDate
                            ? moment(minRetrievalDate).startOf('day').toISOString()
                            : undefined,
                        max_retrieved_at: maxRetrievalDate
                            ? moment(maxRetrievalDate).add(1, 'day').startOf('day').toISOString()
                            : undefined, // max date is exclusive so exclude the next day for better UX
                        order_by_deposited_at: sortOrder === 1 ? 'asc' : 'desc'
                    } as DumpsterOnSiteQuery
                }
                leftHandedComponent={
                    <StyledAccordion>
                        <AccordionTab
                            header={
                                <TitleContainer>
                                    <StyleSpan>Filtres et synchronisation</StyleSpan>
                                </TitleContainer>
                            }
                        >
                            <FiltersContainer>
                                <Calendar.Range
                                    id="depositRange"
                                    iconPos="right"
                                    placeholder=""
                                    label="Filtrer par date de dépôt"
                                    value={
                                        minDepositDate && maxDepositDate ? [minDepositDate, maxDepositDate] : undefined
                                    }
                                    required={false}
                                    readOnly={false}
                                    result={(date) => {
                                        if (Array.isArray(date)) {
                                            /**
                                             * Needed to get the middle of the day to avoid timezone issues.
                                             */
                                            const date0 = moment(date[0]).toLocaleString();
                                            const middleStartDay = getMiddleOfTheDay(date0);
                                            const date1 = moment(date[1]).toLocaleString();
                                            const middleEndDay = getMiddleOfTheDay(date1);
                                            if (date?.length === 2) {
                                                /**
                                                 * Case when only the start date is set.
                                                 * Toast the user to select an end date.
                                                 * We cannot set the min and max date to the same value as we won't be able to select two dates then.
                                                 */
                                                if (date[1] == null) {
                                                    toast({
                                                        severity : 'info',
                                                        summary  : 'Sélectionnez une date de fin.',
                                                        detail   : 'Vous devez sélectionner une date de fin pour filtrer par période (pour sélectionner un seul jour, cliquez deux fois sur la même date).'
                                                    });
                                                    return;
                                                }
                                                setMinDepositDate(middleStartDay);
                                                setMaxDepositDate(middleEndDay);
                                            } else if (date?.length === 1) {
                                                setMinDepositDate(middleStartDay);
                                                setMaxDepositDate(middleStartDay);
                                            }
                                        }
                                    }}
                                    showTime={false}
                                    displayError={false}
                                />
                                <Calendar.Range
                                    id="retrievalRange"
                                    iconPos="right"
                                    placeholder=""
                                    label="Filtrer par date d'enlèvement"
                                    value={
                                        minRetrievalDate && maxRetrievalDate
                                            ? [minRetrievalDate, maxRetrievalDate]
                                            : undefined
                                    }
                                    required={false}
                                    readOnly={false}
                                    result={(date) => {
                                        if (Array.isArray(date)) {
                                            /**
                                             * Needed to get the middle of the day to avoid timezone issues.
                                             */
                                            const date0 = moment(date[0]).toLocaleString();
                                            const middleStartDay = getMiddleOfTheDay(date0);
                                            const date1 = moment(date[1]).toLocaleString();
                                            const middleEndDay = getMiddleOfTheDay(date1);
                                            if (date?.length === 2) {
                                                /**
                                                 * Case when only the start date is set.
                                                 * Toast the user to select an end date.
                                                 * We cannot set the min and max date to the same value as we won't be able to select two dates then.
                                                 */
                                                if (date[1] == null) {
                                                    toast({
                                                        severity : 'info',
                                                        summary  : 'Sélectionnez une date de fin.',
                                                        detail   : 'Vous devez sélectionner une date de fin pour filtrer par période (pour sélectionner un seul jour, cliquez deux fois sur la même date).'
                                                    });
                                                    return;
                                                }
                                                setMinRetrievalDate(middleStartDay);
                                                setMaxRetrievalDate(middleEndDay);
                                            } else if (date?.length === 1) {
                                                setMinRetrievalDate(middleStartDay);
                                                setMaxRetrievalDate(middleStartDay);
                                            }
                                        }
                                    }}
                                    showTime={false}
                                    displayError={false}
                                />
                                <RelationAutocomplete.Customer
                                    placeholder="Filtrer par client"
                                    onSelect={(customer) => setCustomerId(customer?.id)}
                                    onUnselect={() => setCustomerId(undefined)}
                                />
                                <RelationAutocomplete.ConstructionSite
                                    readOnly={!customer_id}
                                    baseValue={constructionSite}
                                    placeholder="Filtrer par chantier"
                                    onSelect={(consSite) => {
                                        setConstructionSiteId(consSite?.id);
                                        setConstructionSite(consSite);
                                    }}
                                    onUnselect={() => {
                                        setConstructionSiteId(undefined);
                                        setConstructionSite(undefined);
                                    }}
                                    customerId={customer_id}
                                />
                                <Input.Number
                                    id="volume_m3"
                                    value={volume_m3 ?? null}
                                    result={(value) => setVolumeM3(value ?? undefined)}
                                    label="Volume de la benne (m³)"
                                    readOnly={false}
                                    required={false}
                                    errors={[]}
                                    size={30}
                                />
                                <Button.Checkbox
                                    id="exclude_retrieved"
                                    value={exclude_retrieved}
                                    result={(value) => setExcludeRetrieved(value)}
                                    label="Exclure les bennes enlevées"
                                    labelPosition="right"
                                    readOnly={false}
                                />
                                <Button.Checkbox
                                    id="exclude_planned_for_retrieval"
                                    value={exclude_planned_for_retrieval}
                                    result={(value) => setExcludePlannedForRetrieval(value)}
                                    label="Exclure les bennes ayant une commande d'enlèvement demandée"
                                    labelPosition="right"
                                    readOnly={false}
                                />
                                <PRButton
                                    type="button"
                                    loading={isExportLoading}
                                    loadingIcon="pi pi-spin pi-spinner"
                                    label="Exporter"
                                    icon="pi pi-file-excel"
                                    tooltipOptions={{ position: 'left' }}
                                    onClick={exportData}
                                />
                            </FiltersContainer>
                        </AccordionTab>
                    </StyledAccordion>
                }
                title={'Bennes sur chantier'}
                name="Benne sur chantier"
                pluralName="Bennes sur chantier"
                headers={dumpsterOnSiteListHeaders}
            />
        </>
    );
};
