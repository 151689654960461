import React from 'react';
import styled from 'styled-components';

import { CardErrors, EDiscountType, EDiscountUnit, ETrashType } from '@bbng/util/types';

import { mapDiscountType, mapDiscountUnit, mapTrashTypetext, mapVolumeToBag } from '../../common/enumMapper';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import Input from '../../components/Inputs';

export type DiscountDetailsProps = {
    readOnly?: boolean;
    value?: DiscountDetailsState;
    id: string;
    result: (
        value: DiscountDetailsState,
        errors: null | string[] | CardErrors<DiscountDetailsState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type DiscountDetailsState = {
    type: EDiscountType;
    min_volume: number;
    max_volume: number;
    value: number;
    unit: EDiscountUnit;
    trash_type: ETrashType[];
    allTrashType?: boolean;
};
export type DiscountDetailsErrorState = CardErrors<DiscountDetailsState>;

export const initialState: DiscountDetailsState = {
    type         : EDiscountType.BIG_BAG,
    min_volume   : 0,
    max_volume   : 0,
    value        : 0,
    unit         : EDiscountUnit.AMOUNT,
    trash_type   : [],
    allTrashType : undefined
};

export const initialErrorState: DiscountDetailsErrorState = Object.fromEntries(
    Object.keys(initialState).map((k) => [k, null])
) as DiscountDetailsErrorState;

export const DiscountDetails: React.FC<DiscountDetailsProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: DiscountDetailsProps) => {
    const [val, setVal] = React.useState<DiscountDetailsState>(value);
    const [err, setErr] = React.useState<DiscountDetailsErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (
        value: boolean | string | string[] | number | undefined | null,
        errors: string[] | null,
        childId: string
    ) => {
        setVal((prev) => {
            if (childId === 'allTrashType') {
                if (value === true) {
                    return {
                        ...prev,
                        trash_type: Object.values(ETrashType)
                    };
                } else {
                    return {
                        ...prev,
                        trash_type: []
                    };
                }
            }
            const state = { ...prev, [childId]: value };
            return state;
        });
        setErr((prev) => {
            const state = { ...prev, [childId]: errors };
            return state;
        });
    };

    return (
        <StyledCard title="Détails">
            <CardLine>
                <Button.Select
                    forceSelection
                    id="type"
                    required={true}
                    readOnly={readOnly}
                    options={Object.values(EDiscountType)}
                    errors={err.type}
                    displayError={displayError}
                    result={handleChange}
                    value={val.type}
                    labelMapper={(value) => mapDiscountType(value as EDiscountType)}
                />
            </CardLine>
            {(val.type === EDiscountType.DUMPSTER || val.type === EDiscountType.BIG_BAG) && (
                <CardLine>
                    <TrashTypeSwitchContainer>
                        <Button.Switch
                            id="allTrashType"
                            value={val.allTrashType || false}
                            result={handleChange}
                            label="Tous les types de déchets"
                            labelPosition="left"
                            readOnly={false}
                        />
                    </TrashTypeSwitchContainer>
                    <Button.Group
                        required={true}
                        id="trash_type"
                        options={Object.values(ETrashType)}
                        readOnly={readOnly}
                        labelMapper={(value) => mapTrashTypetext(value as ETrashType)}
                        result={handleChange}
                        value={val.trash_type}
                        errors={err.trash_type}
                        displayError={displayError}
                    />
                </CardLine>
            )}
            {val.type === EDiscountType.DELIVERY && (
                <CardLine>
                    <Button.Select
                        required={true}
                        id="min_volume"
                        options={['0.25', '1', '2']}
                        readOnly={readOnly}
                        labelMapper={(value) => mapVolumeToBag(parseFloat(value))}
                        result={(val, err, id) => handleChange(parseFloat(val as string), err, id)}
                        value={`${val.min_volume}`}
                        errors={err.min_volume}
                        displayError={displayError}
                    />
                </CardLine>
            )}
            <CardLine>
                {val.type !== EDiscountType.DELIVERY && (
                    <MarginDiv>
                        <Input.Number
                            required
                            label={`Volume ${val.type === EDiscountType.BIG_BAG ? 'minimum' : 'de la benne'}`}
                            id="min_volume"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.min_volume}
                            errors={err.min_volume}
                            displayError={displayError}
                            min={0}
                            step={1}
                        />
                    </MarginDiv>
                )}
                {val.type === EDiscountType.BIG_BAG && (
                    <MarginDiv>
                        <Input.Number
                            required
                            label="Volume maximum"
                            id="max_volume"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.max_volume}
                            errors={err.max_volume}
                            displayError={displayError}
                            step={1}
                        />
                    </MarginDiv>
                )}
                <InputWithHelper>
                    {val.unit === EDiscountUnit.AMOUNT ? (
                        <Input.Currency
                            required
                            label={`Montant de la remise ${
                                val.type === EDiscountType.BIG_BAG
                                    ? 'par m³'
                                    : val.type === EDiscountType.DUMPSTER
                                    ? 'par benne'
                                    : 'par sac'
                            } (HT)`}
                            id="value"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.value}
                            errors={err.value}
                            displayError={displayError}
                            min={-100_000}
                        />
                    ) : (
                        <Input.Percentage
                            required
                            label={`Montant de la remise ${
                                val.type === EDiscountType.BIG_BAG
                                    ? 'par m³'
                                    : val.type === EDiscountType.DUMPSTER
                                    ? 'par benne'
                                    : 'par sac'
                            }`}
                            id="value"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.value}
                            errors={err.value}
                            displayError={displayError}
                            min={-100}
                            max={100}
                        />
                    )}
                    <StyledSpan>
                        <small>
                            <i>Utiliser un montant négatif pour appliquer une pénalité.</i>
                        </small>
                    </StyledSpan>
                </InputWithHelper>
                <MarginDiv style={{ width: '150px' }}>
                    <Input.Dropdown
                        required
                        label="Unité de la remise"
                        id="unit"
                        readOnly={readOnly}
                        result={handleChange}
                        options={Object.values(EDiscountUnit)}
                        itemTemplate={(item) => mapDiscountUnit(item as EDiscountUnit)}
                        valueTemplate={(item) => mapDiscountUnit(item as EDiscountUnit)}
                        value={val.unit}
                        errors={err.unit}
                        displayError={displayError}
                    />
                </MarginDiv>
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 8px 0;
`;

const StyledCard = styled(Card)`
    position: relative;
    .p-picklist {
        width: 100%;
    }
    ${CardLine} + ${CardLine} {
        margin-top: 20px;
    }
`;

const TrashTypeSwitchContainer = styled.div`
    text-align: right;
`;

const InputWithHelper = styled.div`
    display: flex;
    flex-direction: column;
`;

const MarginDiv = styled.div`
    margin-bottom: 24px;
`;

const StyledSpan = styled.span`
    padding-top: 4px;
    height: 24px;
    font-size: 0.9em;
`;
