import React from 'react';

import { getMiddleOfTheDay } from '@bbng/util/misc';
import { CCDensityRo, EPlanningType } from '@bbng/util/types';

import { syncRequest } from '../../common/syncRequest';
import { urlApiBuilder } from '../../common/urlBuilder';

export type UseCCDensityDataProps = {
    date?: string;
    type?: EPlanningType;
};

export const useCCDensityData = (props: UseCCDensityDataProps) => {
    const [isDensityDataLoading, setIsDensityDataLoading] = React.useState(false);
    const [densityData, setDensityData] = React.useState<CCDensityRo>();
    const [memory, setMemory] = React.useState<string>();

    const fetchData = React.useCallback(
        async (date: string, type: EPlanningType) => {
            setIsDensityDataLoading(true);

            const result = await syncRequest<CCDensityRo>({
                method : 'GET',
                url    : urlApiBuilder.collectConfigStatsDensity(),
                params : {
                    type : type,
                    day  : getMiddleOfTheDay(date)
                }
            });

            if (result.success) {
                setDensityData(result.response?.data.ro);
            }

            setIsDensityDataLoading(false);
        },
        [setDensityData, setIsDensityDataLoading]
    );

    React.useEffect(() => {
        if (!props.date || !props.type) return;
        const newMemory = `${props.date}-${props.type}`;
        if (memory && newMemory === memory) return;

        setMemory(newMemory);

        fetchData(props.date, props.type);
    }, [props.date, props.type]);

    return {
        isDensityDataLoading,
        densityData
    };
};

export type UseCCDensityData = ReturnType<typeof useCCDensityData>;
