import React from 'react';
import styled from 'styled-components';

import { CardErrors, CustomerRo, EBillingMode, UserRo } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import { useFormModule } from '../../hooks/FormModule';
import RelationAutocomplete from '../common/RelationAutocomplete';

export type OrderCustomerProps = {
    readOnly?: boolean;
    value?: OrderCustomerState;
    id: string;
    result: (value: OrderCustomerState, errors: null | string[] | CardErrors<OrderCustomerState>, id: string) => void;
    displayError?: boolean;
    selectUser?: boolean;
};

export type OrderCustomerState = {
    customer?: CustomerRo;
    user?: UserRo;
    confirmationEmail?: string; // set in confirmationEmailModal component
};
export type OrderCustomerErrorState = CardErrors<OrderCustomerState>;

export const initialState: OrderCustomerState = {
    customer          : undefined,
    confirmationEmail : undefined
};
export const initialErrorState: OrderCustomerErrorState = generateInitialErrorState(initialState);

export const OrderCustomer: React.FC<OrderCustomerProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError,
    selectUser = false
}: OrderCustomerProps) => {
    const { val, setVal, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    const [delayedPayment, setDelayedPayment] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (readOnly) return;

        const error = {
            customer          : val.customer ? null : ['Le client est requis'],
            user              : val.user || !selectUser ? null : ["L'utilisateur est requis"],
            confirmationEmail : val.confirmationEmail ? null : ["L'email de confirmation est requis"]
        };
        setErr(error);
    }, []);

    React.useEffect(() => {
        if (!selectUser) {
            setVal((old) => ({ ...old, user: undefined }));
            setErr((old) => ({ ...old, user: null }));
        }
    }, [selectUser]);

    React.useEffect(() => {
        if (!delayedPayment) {
            setVal((old) => ({ ...old, confirmationEmail: undefined }));
            setErr((old) => ({ ...old, confirmationEmail: null }));
        }
    }, [delayedPayment]);

    React.useEffect(() => {
        if (!val.customer) return;
        if (val.customer.billing_mode !== EBillingMode.INSTANT) {
            setDelayedPayment(true);
        } else {
            setDelayedPayment(false);
        }
    }, [val.customer]);

    return (
        <StyledCard title="Client">
            <FullLineInput>
                <RelationAutocomplete.Customer
                    readOnly={readOnly}
                    placeholder="Rechercher par nom, SIREN ou SIRET"
                    baseValue={val.customer}
                    errors={err.customer}
                    displayError={displayError}
                    onSelect={(value) => {
                        handleChange(value, null, 'customer');
                        selectUser && handleChange(undefined, ["L'utilisateur est requis"], 'user');
                    }}
                    onUnselect={() => {
                        handleChange(undefined, ['Le client est requis'], 'customer');
                        selectUser && handleChange(undefined, ["L'utilisateur est requis"], 'user');
                    }}
                />
            </FullLineInput>
            <FullLineInput>{val.customer?.blocked && <BlockedText>Client bloqué</BlockedText>}</FullLineInput>
            {val.customer && selectUser && (
                <FullLineInput>
                    <RelationAutocomplete.User
                        customerId={val.customer.id}
                        readOnly={readOnly}
                        baseValue={val.user}
                        errors={err.user}
                        displayError={displayError}
                        onSelect={(value) => handleChange(value, null, 'user')}
                        onUnselect={() => handleChange(undefined, ["L'utilisateur est requis"], 'user')}
                    />
                    <StyledSpan>
                        Le client étant en paiement immédiat, veuillez préciser à qui envoyer le lien de paiement.
                    </StyledSpan>
                </FullLineInput>
            )}
        </StyledCard>
    );
};

const BlockedText = styled.span`
    padding-top: 16px;
    display: flex;
    justify-content: center;
    color: #ef4444;
    font-size: 2em;
    text-align: center;
    font-weight: 600;
`;

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)``;

const StyledSpan = styled.span`
    font-size: 0.8em;
    font-style: italic;
`;
