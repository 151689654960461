import { PrismaClientKnownRequestError } from '@prisma/client/runtime';
import { StatusCodes } from 'http-status-codes';
import { ValidationError } from 'joi';

import { i18n_bbng } from '@bbng/util/i18n';
// import { MulterError } from 'multer';
import { IMSResponse } from '@bbng/util/types';

import { NdlssError } from './ndlss-error';

export * from './ndlss-error';
export * from './business-error';
export * from './database-error';
export * from './dto-validation-error';
export * from './auth-error';

/**
 * @description
 * Throw an error with an http code and a custom name
 **/
export class CustomError extends Error {
    public httpCode: StatusCodes;

    constructor(code = StatusCodes.INTERNAL_SERVER_ERROR, message = i18n_bbng.t('error.server')) {
        super(stripAnsi(message));

        this.httpCode = code;
        this.name = 'CustomError';
    }

    static fromError = (error: Error): CustomError => {
        try {
            handleError(error);
        } catch (err) {
            return <CustomError>err;
        }
        return new CustomError(StatusCodes.INTERNAL_SERVER_ERROR);
    };

    toIMSResponse = (): IMSResponse => ({
        count       : 0,
        status_code : this.httpCode,
        message     : this.message
    });
}

/**
 *  Convert any Error into CustomError and throw it
 */
export const handleError = (err: Error): never => {
    if (NdlssError.isNdlssError(err)) {
        throw err;
    }

    if (err instanceof CustomError) {
        throw err;
    }
    if (err instanceof ValidationError) {
        throw new CustomError(StatusCodes.BAD_REQUEST, err.message);
    }
    // if (err instanceof MulterError) {
    //     throw new CustomError(StatusCodes.BAD_REQUEST, err.message);
    // }

    if (Object.prototype.toString.call(err) === '[object PrismaClientKnownRequestError]') {
        handlePrismaError(err as PrismaClientKnownRequestError);
    }

    if (Object.prototype.toString.call(err) === '[object PrismaClientValidationError]') {
        throw new CustomError(StatusCodes.BAD_REQUEST, err.message);
    }

    // Do not comment this console.error
    // It enables to log every non handled error, to provide information for developers before converting it to an HTTP 500
    console.error(err);

    throw new CustomError(StatusCodes.INTERNAL_SERVER_ERROR);
};

/**
 *  Convert Prisma Error into CustomError and throw it
 */
export const handlePrismaError = (err: PrismaClientKnownRequestError): never => {
    switch (err.code) {
        case 'P2002': {
            const meta = err['meta'] as undefined | Record<string, string>;
            const target = meta ? meta['target'] : '';
            const errMessage = `Un élément existant possède déjà le/la même ${target}.`;
            console.error(errMessage);
            throw new CustomError(StatusCodes.CONFLICT, errMessage);
        }
        case 'P2025': {
            const errMessage = i18n_bbng.t('error.missingId');
            console.error(err['message']);
            throw new CustomError(StatusCodes.NOT_FOUND, errMessage);
        }
        default:
            throw new CustomError(StatusCodes.INTERNAL_SERVER_ERROR);
    }
};

export class GoCardlessError extends CustomError {
    public override name = 'GoCardlessError';
    constructor(code: StatusCodes, message: string, errorDetails = null) {
        super(code, message);
        if (errorDetails) {
            console.error(this.constructor.name + ': ' + JSON.stringify(errorDetails, null, 2));
        }
    }
}
export class PrismaValidationError extends CustomError {
    constructor(code: StatusCodes, message: string) {
        super(code, message);
        console.error(this.constructor.name + ': ' + message);
    }
}

export class Auth0Error extends CustomError {
    public override name = 'Auth0Error';
}

export class ZohoError extends CustomError {
    public override name = 'ZohoError';
}

export class StripeError extends CustomError {
    public override name = 'StripeError';
}

export class GoogleDriveError extends CustomError {
    public override name = 'GoogleDriveError';
}

export class GocardlessError extends CustomError {
    public override name = 'GocardlessError';
    constructor(code: StatusCodes, message: string, errorDetails = null) {
        if (errorDetails) {
            console.error('GoCardlessError details', errorDetails);
        }
        const errorMessage = `${message} \n ${errorDetails ? JSON.stringify(errorDetails) : ''}`;
        super(code, errorMessage);
    }
}

/**
 * @description
 * Remove Ansi character from string (color)
 **/
const ansiRegex = ({ onlyFirst = false } = {}) => {
    const pattern = [
        '[\\u001B\\u009B][[\\]()#;?]*(?:(?:(?:[a-zA-Z\\d]*(?:;[-a-zA-Z\\d\\/#&.:=?%@~_]*)*)?\\u0007)',
        '(?:(?:\\d{1,4}(?:;\\d{0,4})*)?[\\dA-PR-TZcf-ntqry=><~]))'
    ].join('|');

    return new RegExp(pattern, onlyFirst ? undefined : 'g');
};

export const stripAnsi = (str: string) => {
    if (typeof str !== 'string') return '';
    return str ? str.replace(ansiRegex(), '') : '';
};
