import React from 'react';
import styled from 'styled-components';

import { CardErrors, ETrashType } from '@bbng/util/types';

import { mapTrashTypetext } from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import { useFormModule } from '../../hooks/FormModule';

export type OrderTrashTypeProps = {
    readOnly?: boolean;
    value?: OrderTrashTypeState;
    id: string;
    result: (value: OrderTrashTypeState, errors: null | string[] | CardErrors<OrderTrashTypeState>, id: string) => void;
    displayError?: boolean;
};

export type OrderTrashTypeState = {
    trashType?: ETrashType;
};
export type OrderTrashTypeErrorState = CardErrors<OrderTrashTypeState>;

export const initialState: OrderTrashTypeState = {
    trashType: ETrashType.MIXED
};
export const initialErrorState: OrderTrashTypeErrorState = generateInitialErrorState(initialState);

export const OrderTrashType: React.FC<OrderTrashTypeProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: OrderTrashTypeProps) => {
    const { val, setVal, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    return (
        <StyledCard title="Type de déchet">
            <FullLineInput>
                <Button.Select
                    required={true}
                    id="trashType"
                    options={Object.values(ETrashType).filter((val) => val !== ETrashType.ULTIMATE_TRASH)}
                    readOnly={readOnly}
                    labelMapper={(value) => mapTrashTypetext(value as ETrashType)}
                    result={handleChange}
                    value={val.trashType}
                    errors={err.trashType}
                    displayError={displayError}
                />
            </FullLineInput>
        </StyledCard>
    );
};

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)``;
