import { EOrderPaymentStatus } from '@bbng/util/types';
import { Button } from 'primereact/button';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { mapOrderStatus } from '../../common/enumMapper';
import { Dialog } from '../../components/Dialog';
import Input from '../../components/Inputs';
import { ItalicText } from './style';

export type ConfirmDialogProps = {
    visible: boolean;
    onHide: () => void;
    onConfirm: (triggerInvoice: boolean) => void;
};

const triggerInvoiceText = 'facturer';
const doNotTriggerInvoiceText = 'offrir';

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ visible, onHide, onConfirm }) => {
    const [text, setText] = useState<string>('');

    useEffect(() => {
        setText('');
    }, [visible]);

    const textIsValid = useMemo(() => {
        return text === triggerInvoiceText || text === doNotTriggerInvoiceText;
    }, [text]);

    const handleConfirmation = () => {
        onConfirm(text === triggerInvoiceText);
    };

    return (
        <Dialog visible={visible} onHide={onHide} header="Confirmer l'annulation">
            <Body>
                <div>L'annulation est tardive, souhaitez-vous facturer le client ?</div>
                <div>
                    Tapez <StyledText>{triggerInvoiceText}</StyledText> pour facturer l'annulation tardive,{' '}
                    <StyledText>{doNotTriggerInvoiceText}</StyledText> sinon.
                </div>
                <InputContainer>
                    <Input.Text
                        id="triggerInvoice"
                        value={text}
                        result={(newText) => {
                            if (newText !== text) {
                                setText(newText);
                            }
                        }}
                        readOnly={false}
                        label="Facturer ou offrir l'annulation ?"
                        required
                        errors={null}
                    />
                </InputContainer>
                <ItalicText>
                    <i className="pi pi-info-circle" />
                    Si la commande est en statut{' '}
                    <StyledStatus>{mapOrderStatus(EOrderPaymentStatus.TO_PAY)}</StyledStatus>, le client ne sera pas
                    facturé.
                </ItalicText>
            </Body>
            <Footer>
                <Button className="p-button-text" label="Retour" onClick={onHide} />
                <Button disabled={!textIsValid} label="Confirmer" onClick={handleConfirmation} />
            </Footer>
        </Dialog>
    );
};

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

/**
 * text with monospace font
 */
const StyledText = styled.span`
    padding: 5px;
    margin: 0 5px;
    background-color: #f0f0f0;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    font-family: monospace;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`;

const StyledStatus = styled.span`
    font-weight: bold;
`;

const InputContainer = styled.div`
    margin-top: 16px;
    & input {
        width: 100%;
    }
`;
