import { Admin, Admin_Authz, Admin_History } from '@prisma/client/admin';
import { IKafkaQuery } from '../kafka';
// import { EAdminType } from '../auth0';
// import { RelationsDto } from '../dto';
import { ISODate } from '../misc';

export type AdminEditDto = {
    firstname?: string;
    lastname?: string;
    profile_not_completed?: boolean;
};

export type AdminCreateActionDto = {
    firstname?: string;
    lastname?: string;
    email: string;
    roles: EAdminRole[];
    archived?: boolean;
    appears_on_list?: boolean;
};

export type AdminEditActionDto = AdminEditDto & {
    appears_on_list?: boolean;
    archived?: boolean;
};

export type AdminQueryRead = IKafkaQuery &
    Partial<{
        internal: boolean;
    }>;

export interface AdminRo {
    id: string;

    firstname?: string;
    lastname?: string;
    fullname?: string;

    email: string;
    roles: string[];

    archived: boolean;
    created_by: string;
    created_at: ISODate;
    updated_at: ISODate;
}

export enum EAdminRole {
    BBNG_SUPER_ADMIN = 'BBNG_SUPER_ADMIN',
    BBNG_ADMIN = 'BBNG_ADMIN'
}

export type AdminPrisma = Admin & { authorization: Admin_Authz; history: Admin_History };
