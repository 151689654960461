import { Discount, Discount_Authz, Discount_History } from '@prisma/client/discount';

import { IKafkaQuery } from '../kafka';
import { ETrashType, ISODate } from '../misc';
import { RelationKeys, RelationsDto } from '../dto';

export type DiscountCreateDto = {
    value: number;
    unit: EDiscountUnit;
    type: EDiscountType;
    trash_type?: ETrashType[];

    min_volume: number;
    max_volume: number; //SETUP AUTOMATICALLY IF DUMPSTER

    customer_id: string[]; //Mandatory to apply to at least one customer
    construction_site_id?: string[];
    zone_id?: string[];

    start_date: ISODate;
    end_date?: ISODate;
};
export type DiscountCreateActionDto = DiscountCreateDto & {
    id?: string;
};

export type DiscountEditDto = Partial<Omit<DiscountCreateDto, RelationKeys<DiscountRo>>> &
    Omit<RelationsDto<DiscountRo>, 'customer_id'>;

export type DiscountEditActionDto = Omit<DiscountEditDto, 'connect' | 'disconnect'> & {
    archived?: boolean;
    construction_site_id?: string[];
    zone_id?: string[];
};

export type DiscountRo = {
    id: string;

    value: number; // /m3
    unit: EDiscountUnit;
    type: EDiscountType;
    trash_type: ETrashType[];

    min_volume: number;
    max_volume: number;

    customer_id: string[]; //Mandatory to apply to at least one customer
    construction_site_id?: string[];
    zone_id?: string[]; //Optional to apply to at least one zone

    start_date: ISODate;
    end_date?: ISODate;

    archived: boolean;
    created_at: ISODate;
    updated_at: ISODate;
    created_by: string;
};
export enum EDiscountUnit {
    'PERCENT' = 'PERCENT',
    'AMOUNT' = 'AMOUNT'
}

export type DiscountPrisma = Discount & { authorization: Discount_Authz; history: Discount_History };

export type DiscountQuery = IKafkaQuery & {
    page?: number;
    limit?: number;

    include_expired?: boolean;
    max_start_date?: string; // filters discounts actives before max_start_date
    min_end_date?: string; // filters discounts actives after min_end_date

    construction_site_id?: string;
    zone_id?: string;
    customer_id?: string;
};

export enum EDiscountType {
    BIG_BAG = 'BIG_BAG',
    DUMPSTER = 'DUMPSTER',
    DELIVERY = 'DELIVERY'
}
