import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';

import Calendar from '../../components/Calendar';
import { InvoicePageState } from '../../pages/Invoices';

type MonthSelectorProps = {
    value: InvoicePageState;
    setValue: (value: InvoicePageState) => void;
    label?: string;
};

export const MonthSelector: React.FC<MonthSelectorProps> = ({
    value,
    setValue,
    label
}: MonthSelectorProps): JSX.Element => {
    const [val] = useState(moment.utc().set({ month: value.month, year: value.year }).toISOString());

    return (
        <InputsContainer>
            <Calendar.DayMonthYear
                value={val}
                showMonths
                id="month"
                readOnly={false}
                dateFormat="MM yy"
                locale="fr"
                required={false}
                result={(date) => {
                    setValue({
                        month : moment.utc(date).get('month'),
                        year  : moment.utc(date).get('year')
                    });
                }}
                displayError={false}
                label={label}
            />
        </InputsContainer>
    );
};

export const InputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
`;
