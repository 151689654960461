import React from 'react';
import styled from 'styled-components';

import { CardErrors } from '@bbng/util/types';

import { Card } from '../../components/Card';
import InputList from '../InputList';
import { FrontContact } from '../InputList/contact';

export type LandfillCenterContactsProps = {
    readOnly?: boolean;
    value?: LandfillCenterContactsState;
    id: string;
    result: (
        value: LandfillCenterContactsState,
        errors: null | string[] | CardErrors<LandfillCenterContactsState>,
        id: string
    ) => void;
    displayError?: boolean;
};

const FullLineInput = styled.div`
    width: 100%;
`;

export type LandfillCenterContactsState = {
    contactList: Array<FrontContact>;
};
export type LandfillCenterContactsErrorState = CardErrors<LandfillCenterContactsState>;

export const initialState: LandfillCenterContactsState = {
    contactList: []
};
export const initialErrorState: LandfillCenterContactsErrorState = Object.fromEntries(
    Object.keys(initialState).map((k) => [k, null])
) as LandfillCenterContactsErrorState;

export const LandfillCenterContacts: React.FC<LandfillCenterContactsProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: LandfillCenterContactsProps) => {
    const [val, setVal] = React.useState<LandfillCenterContactsState>(value);
    const [err, setErr] = React.useState<LandfillCenterContactsErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <StyledCard title="Liste de contacts">
            <FullLineInput>
                <InputList.Contacts
                    title="Contacts"
                    onChange={(value) => handleChange(value, null, 'contactList')}
                    value={val.contactList}
                    readOnly={readOnly}
                    requiredEmail={false}
                />
            </FullLineInput>
        </StyledCard>
    );
};

const StyledCard = styled(Card)`
    flex: 2 !important;
`;
