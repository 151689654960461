import React from 'react';

import { ETrashType, PRODUCT_FAMILY } from '@bbng/util/types';

import { OrderProductsErrorState, OrderProductsState } from '.';
import { mapTrashTypetext } from '../../../common/enumMapper';
import Button from '../../../components/Button';
import { EOrderDumpsterServiceForm } from '../DumpsterService';
import { EOrderTypeForm } from '../Type';
import { ProductCard, ProductCardProps } from './ProductCard';
import { DumpsterCardContainer } from './style';

interface ProductWrapperProps {
    readOnly?: boolean;
    value: OrderProductsState;
    handleChange: (value: number | boolean | null, errors: null | string[], id: string) => void;
    displayError?: boolean;
    errors: OrderProductsErrorState;
    type?: EOrderTypeForm;
    service?: EOrderDumpsterServiceForm;
    displayBigBagTrashTypeSelect?: boolean;
    selectType: ProductCardProps['selectType'];
}

export const ProductWrapper: React.FC<ProductWrapperProps> = ({
    readOnly,
    value,
    handleChange,
    displayError,
    errors,
    type,
    service,
    displayBigBagTrashTypeSelect = true,
    selectType
}: ProductWrapperProps): JSX.Element => {
    const [depositTrashType, setDepositTrashType] = React.useState<ETrashType | undefined>(
        value
            ? Object.values(value).find(
                  (pr) => pr.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT && pr.quantity > 0
              )?.trash_type
            : ETrashType.MIXED
    );
    const [retrievalTrashType, setRetrievalTrashType] = React.useState<ETrashType | undefined>(
        value
            ? Object.values(value).find(
                  (pr) => pr.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL && pr.quantity > 0
              )?.trash_type
            : ETrashType.MIXED
    );
    const [bigBagTrashType, setBigBagTrashType] = React.useState<ETrashType[] | undefined>(
        value
            ? Array.from(
                  new Set(
                      Object.values(value)
                          .filter((pr) => pr.family === PRODUCT_FAMILY.COLLECT_BIG_BAG && pr.quantity > 0)
                          .map((pr) => pr.trash_type)
                  )
              )
            : [ETrashType.MIXED]
    );

    const handleBigBagTrashTypeChange = (trashTypeValues: ETrashType[]) => {
        setBigBagTrashType(trashTypeValues);
        const trashTypeRemoved = bigBagTrashType?.filter((t) => !trashTypeValues.includes(t));
        if (trashTypeRemoved && trashTypeRemoved?.length > 0) {
            const productsToRemove = Object.entries(value).filter(
                ([, { family, trash_type, quantity }]) =>
                    family === PRODUCT_FAMILY.COLLECT_BIG_BAG && trashTypeRemoved.includes(trash_type) && quantity > 0
            );
            productsToRemove.forEach(([id]) => handleChange(0, null, id));
        }
    };

    if (type === EOrderTypeForm.COLLECT_BIG_BAG) {
        return (
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px' }}>
                <DumpsterCardContainer>
                    {!readOnly && displayBigBagTrashTypeSelect && (
                        <Button.Group
                            required={true}
                            id=""
                            options={Object.values(ETrashType)}
                            readOnly={readOnly}
                            labelMapper={(value) => mapTrashTypetext(value as ETrashType)}
                            result={(val) => handleBigBagTrashTypeChange(val as ETrashType[])}
                            value={bigBagTrashType}
                            displayError={displayError}
                        />
                    )}
                    <div
                        style={{
                            display        : 'flex',
                            flexWrap       : 'wrap',
                            justifyContent : 'center',
                            gap            : '8px'
                        }}
                    >
                        {Object.entries(value)
                            .filter(([, { family, trash_type }]) => {
                                if (displayBigBagTrashTypeSelect) {
                                    return (
                                        family === PRODUCT_FAMILY.COLLECT_BIG_BAG &&
                                        bigBagTrashType?.includes(trash_type)
                                    );
                                } else {
                                    return family === PRODUCT_FAMILY.COLLECT_BIG_BAG;
                                }
                            })
                            .map(([id, product]) => (
                                <ProductCard
                                    key={id}
                                    required={true}
                                    id={id}
                                    readOnly={readOnly}
                                    result={handleChange}
                                    value={product}
                                    errors={errors[id]}
                                    displayError={displayError}
                                    selectType={selectType}
                                />
                            ))}
                    </div>
                </DumpsterCardContainer>
            </div>
        );
    }
    if (type === EOrderTypeForm.COLLECT_DUMPSTER && service === EOrderDumpsterServiceForm.ROTATION) {
        return (
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px' }}>
                <DumpsterCardContainer>
                    <h3>Dépôt</h3>
                    {!readOnly && (
                        <Button.Select
                            required={true}
                            id=""
                            options={Object.values(ETrashType)}
                            readOnly={readOnly}
                            labelMapper={(value) => mapTrashTypetext(value as ETrashType)}
                            result={(val) => setDepositTrashType(val as ETrashType)}
                            value={depositTrashType}
                            displayError={displayError}
                        />
                    )}
                    {Object.entries(value)
                        .filter(
                            ([, { family, trash_type }]) =>
                                family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT && trash_type === depositTrashType
                        )
                        .sort(([, a], [, b]) => a?.volume_m3 - b?.volume_m3)
                        .map(([id, product]) => (
                            <ProductCard
                                key={id}
                                required={true}
                                id={id}
                                readOnly={readOnly}
                                result={handleChange}
                                value={product}
                                errors={errors[id]}
                                displayError={displayError}
                                selectType={selectType}
                            />
                        ))}
                </DumpsterCardContainer>
                <DumpsterCardContainer>
                    <h3>Enlèvement</h3>
                    {!readOnly && (
                        <Button.Select
                            required={true}
                            id=""
                            options={Object.values(ETrashType)}
                            readOnly={readOnly}
                            labelMapper={(value) => mapTrashTypetext(value as ETrashType)}
                            result={(val) => setRetrievalTrashType(val as ETrashType)}
                            value={retrievalTrashType}
                            displayError={displayError}
                        />
                    )}
                    {Object.entries(value)
                        .filter(
                            ([, { family, trash_type }]) =>
                                family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL &&
                                trash_type === retrievalTrashType
                        )
                        .sort(([, a], [, b]) => a?.volume_m3 - b?.volume_m3)
                        .map(([id, product]) => (
                            <ProductCard
                                key={id}
                                required={true}
                                id={id}
                                readOnly={readOnly}
                                result={handleChange}
                                value={product}
                                errors={errors[id]}
                                displayError={displayError}
                                selectType={selectType}
                            />
                        ))}
                </DumpsterCardContainer>
            </div>
        );
    }
    return (
        <>
            {Object.entries(value)
                .sort(([, a], [, b]) => a?.volume_m3 - b?.volume_m3)
                .sort(([, a], [, b]) => a?.family.localeCompare(b?.family))
                .map(([id, product]) => (
                    <ProductCard
                        key={id}
                        required={true}
                        id={id}
                        readOnly={readOnly}
                        result={handleChange}
                        value={product}
                        errors={errors[id]}
                        displayError={displayError}
                        selectType={selectType}
                    />
                ))}
        </>
    );
};
