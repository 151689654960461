import { filter, map, pipe, toArray, uniq } from '@fxts/core';
import equal from 'fast-deep-equal/react';
import moment from 'moment';

import { deepCopy, getMiddleOfTheDay, mergeDates } from '@bbng/util/misc';
import {
    BaseType,
    CCAdministrativeRo,
    CCAdministrativeRoFront,
    CCServiceRo,
    CCServiceRoFront,
    CC_FAMILY,
    CC_STATUS,
    CollectorRo,
    EPlanningCalculMethod,
    EPlanningType,
    PlanningCalculateDto,
    PlanningParameters,
    PlanningRo,
    PlanningSaveDto,
    PlanningUnassignDto,
    PRODUCT_DUMPSTER_TYPE,
    TruckRo
} from '@bbng/util/types';

import { PlanningPageState } from '../../pages/Planning/helpers';
import {
    PlanningSettingsErrorState,
    PlanningSettingsState,
    initialErrorState as planningSettingsInitialErrorState,
    initialState as planningSettingsInitialState
} from '../planning/Config';
import {
    PlanningShiftsErrorState,
    PlanningShiftsState,
    initialErrorState as planningShiftsInitialErrorState,
    initialState as planningShiftsInitialState
} from '../planning/shifts';

export enum EPlanningFormSubmitAction {
    CALCULATE = 'CALCULATE',
    SAVE = 'SAVE',
    UNASSIGN = 'UNASSIGN'
}

export const mapPlanningFormSubmitAction = (action: EPlanningFormSubmitAction): string => {
    switch (action) {
        case EPlanningFormSubmitAction.CALCULATE:
            return 'calculer';
        case EPlanningFormSubmitAction.SAVE:
            return 'sauvegarder';
        case EPlanningFormSubmitAction.UNASSIGN:
            return 'désassigner';
    }
};

export type PlanningModulesStates = PlanningShiftsState | PlanningSettingsState;

export type PlanningModulesErrorStates = PlanningShiftsErrorState | PlanningSettingsErrorState;

export type PlanningFormState = {
    settings: PlanningSettingsState;
    shifts: PlanningShiftsState;
};

export type PlanningFormErrorState = {
    settings: PlanningSettingsErrorState;
    shifts: PlanningShiftsErrorState;
};

export const initialState = (planning: PlanningRo[], trucksByPlanning: Record<string, TruckRo>): PlanningFormState => ({
    settings : planningSettingsInitialState,
    shifts   : planningShiftsInitialState(planning, trucksByPlanning)
});

export const initialErrorState = (
    plannings: PlanningRo[],
    trucksByPlanning: Record<string, TruckRo>
): PlanningFormErrorState => ({
    settings : planningSettingsInitialErrorState,
    shifts   : planningShiftsInitialErrorState(plannings, trucksByPlanning)
});

export const mapStateToCalculateDtoWithoutCluster = ({
    state,
    context,
    unassigned_cc,
    ccsService,
    ccsAdministrative,
    zoneIds,
    dumpsterTypes
}: {
    state: PlanningFormState;
    context: PlanningPageState;
    unassigned_cc: (CCServiceRoFront | CCAdministrativeRoFront)[];
    ccsService: Record<string, CCServiceRo>;
    ccsAdministrative: Record<string, CCAdministrativeRo>;
    zoneIds: string[];
    dumpsterTypes: PRODUCT_DUMPSTER_TYPE[];
}): PlanningCalculateDto => {
    const { redis_version_description, ...copiedContext } = deepCopy(context);
    return {
        ...(copiedContext as PlanningPageState),
        redis_version_key : redis_version_description.key,
        day               : getMiddleOfTheDay(moment(context.day).toISOString()),
        general_config    : {
            dumpster_deposit_priority : state.settings.dumpster_deposit_priority,
            dumpster_removal_priority : state.settings.dumpster_removal_priority,
            maximum_volume            : state.settings.maximum_volume
        },
        cc_service_to_plan_id: pipe(
            unassigned_cc,
            /**
             * Keep service cc that are in the selected zone with selected dumpster type or that are finished or hazard
             */
            filter((cc) => {
                if (cc.family === CC_FAMILY.ADMINISTRATIVE) return false;

                const isInZone = zoneIds.includes(cc.zone.id);
                const isFinishedOrHazard = [CC_STATUS.FINISHED, CC_STATUS.HAZARD].includes(cc.status as any);

                const isDumpster = context.type === EPlanningType.DUMPSTER;
                const isSelectedDumpster = cc.characteristics.some((type) =>
                    dumpsterTypes.includes(type as PRODUCT_DUMPSTER_TYPE)
                );

                return (isInZone || isFinishedOrHazard) && (isDumpster ? isSelectedDumpster : true);
            }),
            map((cc) => cc.id),
            uniq,
            toArray
        ),
        cc_administrative_to_plan_id: pipe(
            unassigned_cc,
            filter((cc) => cc.family === CC_FAMILY.ADMINISTRATIVE),
            map((cc) => cc.id),
            uniq,
            toArray
        ),
        parameters                    : getParametersFromShifts(state.shifts, context, ccsService, ccsAdministrative, zoneIds),
        calcul_method                 : state.settings.calcul_method,
        end_slot_margin_minutes       : state.settings.end_slot_margin_minutes,
        optimization_duration_seconds : state.settings.optimization_duration_seconds,
        ignore_here                   : state.settings.ignore_here,
        begin_slot_margin_minutes     : state.settings.begin_slot_margin_minutes
    };
};

export const mapStateToSaveDto = (state: PlanningFormState, context: PlanningPageState): PlanningSaveDto => {
    const { redis_version_description, ...copiedContext } = deepCopy(context);
    return {
        ...(copiedContext as PlanningPageState),
        redis_version_key: redis_version_description.key
    };
};

export const mapStateToUnassignDto = (state: PlanningFormState, context: PlanningPageState): PlanningUnassignDto => {
    const { redis_version_description, ...copiedContext } = deepCopy(context);
    return {
        ...(copiedContext as PlanningPageState),
        unassigned_planning_id: Object.values(state.shifts)
            .filter((planning) => planning.selected)
            .map((planning) => planning.id)
    };
};

export const mapApiDataToState = (
    plannings: PlanningRo[],
    trucksByPlanning: Record<string, TruckRo>,
    collectorsByPlanning: Record<string, CollectorRo>
): PlanningFormState => {
    return {
        settings: {
            dumpster_deposit_priority     : plannings[0]?.general_config.dumpster_deposit_priority || 50,
            dumpster_removal_priority     : plannings[0]?.general_config.dumpster_removal_priority || 50,
            maximum_volume                : plannings[0]?.general_config.maximum_volume || 300,
            calcul_method                 : EPlanningCalculMethod.Classic,
            end_slot_margin_minutes       : 0,
            optimization_duration_seconds : 300,
            ignore_here                   : false,
            begin_slot_margin_minutes     : 0
        },
        shifts: plannings.reduce((acc, cur) => {
            const truck = trucksByPlanning[cur.id];
            const collector = collectorsByPlanning[cur.id];

            acc[cur.truck_id[0]] = {
                id                   : cur.id,
                collector,
                truck,
                start_date           : cur.shift_config.start_date,
                is_available         : truck?.is_available,
                end_date             : cur.shift_config.end_date,
                distance             : cur.shift?.distance ?? 0,
                duration             : cur.shift?.duration ?? '',
                steps_break          : cur.shift?.steps_break || [],
                steps_driver         : cur.shift?.steps_driver || [],
                steps_emptying       : cur.shift?.steps_emptying || [],
                steps_service        : cur.shift?.steps_service || [],
                steps_administrative : cur.shift?.steps_administrative || [],
                selected             : true
            };
            return acc;
        }, {} as PlanningShiftsState)
    };
};

const getParametersFromShifts = (
    state: PlanningShiftsState,
    planningBaseData: BaseType,
    ccsService: Record<string, CCServiceRo>,
    ccsAdmin: Record<string, CCAdministrativeRo>,
    zoneIds: string[]
): PlanningParameters[] => {
    return Object.entries(state)
        .filter(([key, value]) => key !== 'settings' && value.selected)
        .map(([key, value]) => ({
            planning_id : value.id,
            ccsService  : value.steps_service
                .filter((step) => {
                    const cc = ccsService[step.collect_config_id];
                    /**
                     * Keep service cc that are in the selected zone or that are finished or hazard
                     */
                    return (
                        cc.status !== CC_STATUS.CANCELED &&
                        (zoneIds.includes(cc.zone.id) ||
                            [CC_STATUS.FINISHED, CC_STATUS.HAZARD].includes(cc.status as any))
                    );
                })
                .map((step) => step.collect_config_id),
            ccsAdministrative: value.steps_administrative
                .filter((step) => {
                    const cc = ccsAdmin[step.collect_config_id];
                    return cc.status !== CC_STATUS.CANCELED;
                })
                .map((step) => step.collect_config_id),
            collector_id : value.collector?.id as string,
            truck_id     : value.truck?.id as string,
            shift_config : {
                start_date: mergeDates(
                    moment.utc(planningBaseData.day).toISOString(),
                    moment.utc(value.start_date).toISOString()
                ),
                end_date: mergeDates(
                    moment.utc(planningBaseData.day).toISOString(),
                    moment.utc(value.end_date).toISOString()
                )
            }
        }));
};

export const removeShiftsWithoutCollectorsOrUnavailable = (shifts: PlanningShiftsState): PlanningShiftsState => {
    return Object.fromEntries(
        Object.entries(shifts).filter(([key, value]) => value.collector !== undefined && value.is_available)
    );
};

export const removeUndisplayedShifts = ({
    shifts,
    trucksByPlanning,
    planningType,
    dumpsterTypes
}: {
    shifts: PlanningShiftsState;
    trucksByPlanning: Record<string, TruckRo>;
    planningType: EPlanningType;
    dumpsterTypes: PRODUCT_DUMPSTER_TYPE[];
}): PlanningShiftsState => {
    if (planningType === EPlanningType.BIG_BAG) return shifts;
    return Object.fromEntries(
        Object.entries(shifts).filter(([key, value]) => {
            const truck = trucksByPlanning[value.id];
            return truck.type.some((tpe) => dumpsterTypes.includes(tpe as PRODUCT_DUMPSTER_TYPE));
        })
    );
};

export const calculatedAndNotSavedDiverge = (unsaved: PlanningFormState, calculated: PlanningFormState): boolean => {
    /**
     * Settings have changed.
     */
    // if (JSON.stringify(calculated.settings) !== JSON.stringify(unsaved.settings)) return true;
    if (equal(calculated.settings, unsaved.settings) === false) return true;

    const unsavedShiftsCleaned = removeShiftsWithoutCollectorsOrUnavailable(unsaved.shifts);

    /**
     * There are not the same number of shifts.
     */
    if (Object.keys(calculated.shifts).length !== Object.keys(unsavedShiftsCleaned).length) return true;

    const result = Object.keys(unsavedShiftsCleaned).some((key) => {
        const calculatedShift = calculated.shifts[key];

        if (calculatedShift === undefined) return true;

        /**
         * The collector of the shift has changed.
         */
        if (calculatedShift.collector?.id !== unsavedShiftsCleaned[key].collector?.id) return true;

        /**
         * The truck of the shift has changed.
         */
        if (calculatedShift.truck?.id !== unsavedShiftsCleaned[key].truck?.id) return true;

        if (
            moment(calculatedShift.start_date).toISOString() !==
            moment(unsavedShiftsCleaned[key].start_date).toISOString()
        )
            return true;
        if (moment(calculatedShift.end_date).toISOString() !== moment(unsavedShiftsCleaned[key].end_date).toISOString())
            return true;

        return false;
    });

    return result;
};

export const outOfScheduleStepClassName = 'out-of-schedule-step';
export const commentStepClassName = 'comment-step';
export const baseStepClassName = 'base-step';
export const narrowStepClassName = 'narrow-step';
export const customerStepClassName = (customerId?: string) => {
    if (!customerId) return '';
    return `customer-${customerId}-step`;
};
export const familyStepClassName = (family?: CC_FAMILY) => {
    if (!family) return '';
    return `${family.toLowerCase()}-step`;
};

export const oneM3Volume = 'one-m3-products-step';
export const twoM3Volume = 'two-m3-products-step';
export const twoFiftyM3Volume = 'twoFifty-L-products-step';
export const eightM3Volume = 'eight-m3-products-step';
export const fifteenM3Volume = 'fifteen-m3-products-step';
export const twentyM3Volume = 'twenty-m3-products-step';
export const thirtyM3Volume = 'thirty-m3-products-step';
export const otherVolume = 'other-products-step';

export const mapVolumeToStepClassName = (volume?: number): string => {
    if (volume === 1) return oneM3Volume;
    if (volume === 2) return twoM3Volume;
    if (volume === 0.25) return twoFiftyM3Volume;
    if (volume === 8) return eightM3Volume;
    if (volume === 15) return fifteenM3Volume;
    if (volume === 20) return twentyM3Volume;
    if (volume === 30) return thirtyM3Volume;
    return otherVolume;
};
