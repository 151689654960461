import styled from 'styled-components';

import { Card } from '../../components/Card';

export const CollectConfigsContainer = styled.div`
    margin-top: 4px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 8px;
`;

export const StyledCard = styled(Card)<{ cancel?: boolean; borderColor?: string }>`
    display: flex;
    justify-content: center;
    background-image: ${(props) =>
        props.cancel
            ? 'linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 50%, #dcdcdc 50%, #dcdcdc 75%, transparent 75%, #fff)'
            : 'none'};
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : 'none')};
    background-size: 50px 50px;
    font-size: 0.7em;
    .p-card-content {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
    }
    .p-card-body {
        padding: 8px;
        display: flex;
        align-items: center;
    }
`;

export const CardLine = styled.div<{ weight?: number }>`
    text-align: center;
    font-weight: ${({ weight }) => weight ?? 400};
`;

export const MainLine = styled.div`
    padding: 4px 0;
`;

export const ClientNameContainer = styled.div`
    font-weight: 300;
    max-width: 100px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
