import Joi from 'joi';

import { DumpsterRo } from '.';
import { generateRelationsSchema } from '../dto';

export const DumpsterCreateDtoSchema = Joi.object({
    identifier           : Joi.string().required(),
    volume_m3            : Joi.number().positive().required(),
    weight_ton           : Joi.number().positive(),
    height_cm            : Joi.number().positive(),
    length_cm            : Joi.number().positive(),
    width_cm             : Joi.number().positive(),
    collect_id           : Joi.array().items(Joi.string()),
    construction_site_id : Joi.array().items(Joi.string()),
    landfill_id          : Joi.array().items(Joi.string()),
    truck_id             : Joi.array().items(Joi.string())
});

export const DumpsterEditDtoSchema = Joi.object({
    identifier : Joi.string(),
    volume_m3  : Joi.number().positive(),
    weight_ton : Joi.number().positive(),
    height_cm  : Joi.number().positive(),
    length_cm  : Joi.number().positive(),
    width_cm   : Joi.number().positive()
}).concat(generateRelationsSchema<DumpsterRo>('collect_id', 'construction_site_id', 'landfill_id', 'truck_id'));
