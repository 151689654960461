import React from 'react';
import styled from 'styled-components';

import { deepCopy, formatPhoneNumber } from '@bbng/util/misc';
import { PhoneValue } from '@bbng/util/types';

import Button from '../../components/Button';
import Input from '../../components/Inputs';
import { InputListBase } from './base';

export interface FrontCustomerContact {
    firstname: string;
    lastname: string;
    email: string;
    phone_number: PhoneValue | null;
    is_invoice: boolean;
    is_primary: boolean;
}

export type InputListCustomerContactProps = {
    title?: string;
    value?: Array<FrontCustomerContact>;
    onChange?: (value: Array<FrontCustomerContact>) => void;
    readOnly?: boolean;
    displayError?: boolean;
    emailRequired?: boolean;
};

export function InputListCustomerContact({
    title = 'Contact client',
    displayError = true,
    emailRequired = false,
    ...props
}: InputListCustomerContactProps) {
    const [errors, setErrors] = React.useState<Record<string, string[]>>({});
    const isError =
        Object.keys(errors)
            .map((e) => errors[e] ?? [])
            .flat().length > 0;

    return (
        <InputListBase<FrontCustomerContact>
            title={title}
            columns={[
                { header: 'Prénom', field: 'firstname' },
                { header: 'Nom', field: 'lastname' },
                { header: 'Email', field: 'email' },
                {
                    header : 'Téléphone',
                    field  : 'phone_number',
                    body   : (e) => <div>{e.phone_number ? formatPhoneNumber(e.phone_number) : 'Non renseigné'}</div>
                },
                {
                    header : 'Principal',
                    field  : 'is_primary',
                    body   : (e) => e.is_primary && <StyledI className="pi pi-check" />
                },
                {
                    header : 'Facturation',
                    field  : 'is_invoice',
                    body   : (e) => e.is_invoice && <StyledI className="pi pi-check" />
                }
            ]}
            disableModalButton={(inputs) => {
                if (allFieldsKeysUndefined(inputs)) return true;
                return isError;
            }}
            setErrors={setErrors}
            modalTemplate={ContactModal(errors, setErrors, displayError, emailRequired)}
            {...props}
        />
    );
}

const ContactModal =
    (
        errors: Record<string, string[]>,
        setError: React.Dispatch<React.SetStateAction<Record<string, string[]>>>,
        displayError: boolean,
        emailRequired: boolean
    ) =>
    (
        state: FrontCustomerContact | undefined,
        setter: React.Dispatch<React.SetStateAction<FrontCustomerContact | undefined>>
    ) => {
        return (
            <ModalContainer>
                <Input.Text
                    id="firstname"
                    required={false}
                    result={(value, err, id) => {
                        setter(
                            (prev) =>
                                ({ ...prev, firstname: `${value.charAt(0).toUpperCase()}${value.slice(1)}` } as any)
                        );
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    label="Prénom"
                    errors={errors['firstname'] ?? []}
                    displayError={displayError}
                    value={state?.firstname ?? ''}
                />
                <Input.Text
                    id="lastname"
                    required={false}
                    result={(value, err, id) => {
                        setter((prev) => ({ ...prev, lastname: value.trim().toUpperCase() } as any));
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    label="Nom"
                    errors={errors['lastname'] ?? []}
                    displayError={displayError}
                    value={state?.lastname ?? ''}
                />
                <Input.Phone
                    id="phone_number"
                    required={false}
                    result={(value, err, id) => {
                        setter((prev) => ({ ...prev, phone_number: value } as any));
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    errors={errors['phone_number'] ?? []}
                    displayError={displayError}
                    value={state?.phone_number ?? null}
                />
                <Input.Email
                    id="email"
                    required={emailRequired && !!state?.is_invoice}
                    result={(value, err, id) => {
                        setter((prev) => ({ ...prev, email: value } as any));
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    label="Email"
                    errors={errors['email'] ?? []}
                    displayError={displayError}
                    value={state?.email ?? ''}
                />
                <Button.Checkbox
                    id="is_primary"
                    result={(value, err, id) => {
                        setter((prev) => ({ ...prev, is_primary: value } as any));
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    value={state?.is_primary ?? false}
                    label="Contact principal"
                />
                <Button.Checkbox
                    id="is_invoice"
                    result={(value, err, id) => {
                        setter((prev) => ({ ...prev, is_invoice: value } as any));
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    value={state?.is_invoice ?? false}
                    label="Contact de facturation"
                />
            </ModalContainer>
        );
    };

const ModalContainer = styled.div`
    .p-component,
    .react-tel-input {
        width: 100% !important;
    }
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr;
`;

const StyledI = styled.i`
    padding-left: 16px;
`;

const allFieldsKeysUndefined = (state?: FrontCustomerContact): boolean => {
    if (!state) return true;
    const copy = deepCopy(state) as Partial<FrontCustomerContact>;
    delete copy?.is_primary;
    delete copy?.is_invoice;
    return Object.keys(copy).every((key) => !copy[key as keyof FrontCustomerContact]);
};
