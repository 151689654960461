import moment from 'moment';

import { mergeDates } from '@bbng/util/misc';
import {
    BaseCollectCreateDto,
    BaseCollectCreateDtoHazard,
    BaseCollectCreateDtoSuccess,
    CCServiceRoFront,
    CC_STATUS,
    CollectCreateAdministativeDto,
    CollectCreateBreakDto,
    CollectCreateDriverDto,
    CollectCreateEmptyingDto,
    CollectCreateServiceDto,
    CollectCreateServiceNotPlannedDto,
    ECollectHazardReason,
    EPlanningType,
    LineCreateDto,
    PlanningRo,
    PlanningShiftStep,
    PlanningShiftStepAdministrative,
    PlanningShiftStepBreak,
    PlanningShiftStepCategory,
    PlanningShiftStepDriver,
    PlanningShiftStepEmptying,
    PlanningShiftStepService
} from '@bbng/util/types';

import { TDocument, mapFrontDocumentToDocumentDto } from '../../common/form';
import { removeFrontRoFromObject } from '../../common/misc';
import { urlApiBuilder } from '../../common/urlBuilder';
import {
    CollectInfoErrorState,
    CollectInfoState,
    initialErrorState as collectInfoInitialErrorState,
    initialState as collectInfoInitialState
} from '../collect/Info';
import {
    CollectLandfillErrorState,
    CollectLandfillState,
    initialErrorState as collectLandfillInitialErrorState,
    initialState as collectLandfillInitialState
} from '../collect/Landfill';
import {
    OrderProductsErrorState,
    OrderProductsState,
    initialErrorState as orderProductsInitialErrorState,
    initialState as orderProductsInitialState
} from '../order/Products';
import { isStepEmptying, isStepService, isStepServiceOrAdmin } from '../planning/shifts/helpers';
import { PlanningPageState } from '../../pages/Planning/helpers';
import {
    CollectNotificationErrorState,
    CollectNotificationState,
    initialErrorState as collectNotificationInitialErrorState,
    initialState as collectNotificationInitialState
} from '../collect/Notification';

export type CollectCreateModulesStates =
    | OrderProductsState
    | CollectInfoState
    | CollectLandfillState
    | CollectNotificationState;

export type CollectCreateModulesErrorStates =
    | OrderProductsErrorState
    | CollectInfoErrorState
    | CollectLandfillErrorState
    | CollectNotificationErrorState;

export type CollectCreateFormState = {
    info?: CollectInfoState;
    products: OrderProductsState;
    productsDelivery?: OrderProductsState | null;
    landfill?: CollectLandfillState;
    notification?: CollectNotificationState;
};

export type CollectCreateFormErrorState = {
    info?: CollectInfoErrorState;
    products: OrderProductsErrorState;
    productsDelivery?: OrderProductsErrorState | null;
    Landfill?: CollectLandfillErrorState;
    notification?: CollectNotificationErrorState;
};

export const initialState = (day?: string): CollectCreateFormState => ({
    info             : collectInfoInitialState(day),
    products         : orderProductsInitialState,
    productsDelivery : orderProductsInitialState || null,
    landfill         : collectLandfillInitialState,
    notification     : collectNotificationInitialState
});

export const initialErrorState = (day?: string): CollectCreateFormErrorState => ({
    info             : collectInfoInitialErrorState(day),
    products         : orderProductsInitialErrorState,
    productsDelivery : orderProductsInitialErrorState || null,
    Landfill         : collectLandfillInitialErrorState,
    notification     : collectNotificationInitialErrorState
});

export const mapStateToApiCreateData = (
    state: CollectCreateFormState,
    wasPlanned: boolean,
    category: PlanningShiftStepCategory,
    planningPageState: PlanningPageState,
    plannings: PlanningRo[],
    step?: PlanningShiftStep,
    documents?: TDocument[],
    recalculate?: boolean,
    cc?: CCServiceRoFront
):
    | CollectCreateServiceDto
    | CollectCreateAdministativeDto
    | CollectCreateEmptyingDto
    | CollectCreateBreakDto
    | CollectCreateDriverDto
    | CollectCreateServiceNotPlannedDto => {
    const currentPlanning = plannings.find((planning) => planning.truck_id[0] === state.info?.truck?.id);
    const isHazard = state.info?.has_hazard;

    const arrived_at = mergeDates(state.info?.collect_day, state.info?.arrived_at as string);

    const baseCollectDto: BaseCollectCreateDto = {
        category     : category,
        day          : moment.utc(planningPageState.day, 'YYYY-MM-DD').toISOString(),
        type         : planningPageState.type,
        region       : planningPageState.region,
        planning_id  : currentPlanning?.id as string,
        truck_id     : state.info?.truck?.id as string,
        collector_id : state.info?.collector?.id as string,
        arrived_at   : moment.utc(arrived_at).toISOString(),
        completed_at :
            category === PlanningShiftStepCategory.BREAK
                ? moment.utc(arrived_at).add(state.info?.duration, 'minutes').toISOString()
                : mergeDates(state.info?.collect_day, state.info?.completed_at as string),
        documents          : documents?.map((doc) => mapFrontDocumentToDocumentDto(doc)) || [],
        do_not_recalculate : !recalculate ? true : undefined
    };

    const baseSucessOrHazard = (): BaseCollectCreateDtoSuccess | BaseCollectCreateDtoHazard => {
        if (isHazard) {
            const hazard: BaseCollectCreateDtoHazard = {
                ...baseCollectDto,
                status         : CC_STATUS.HAZARD,
                hazard_reason  : state.info?.hazard_reason as ECollectHazardReason,
                hazard_comment : state.info?.hazard_comment as string
            };
            return hazard;
        } else {
            const success: BaseCollectCreateDtoSuccess = {
                ...baseCollectDto,
                status: CC_STATUS.FINISHED
            };
            return success;
        }
    };

    const buildDto = ():
        | CollectCreateServiceDto
        | CollectCreateAdministativeDto
        | CollectCreateEmptyingDto
        | CollectCreateBreakDto
        | CollectCreateDriverDto
        | CollectCreateServiceNotPlannedDto => {
        switch (category) {
            case PlanningShiftStepCategory.BREAK: {
                const duration = state.info?.duration as 15 | 30 | 45;
                if (!wasPlanned) {
                    const unplannedBreak: CollectCreateBreakDto = {
                        ...(baseSucessOrHazard() as BaseCollectCreateDtoSuccess),
                        was_planned: false,
                        duration
                    };
                    return unplannedBreak;
                } else {
                    const plannedBreak: CollectCreateBreakDto = {
                        ...(baseSucessOrHazard() as BaseCollectCreateDtoSuccess),
                        step_break  : step as PlanningShiftStepBreak,
                        was_planned : true,
                        duration
                    };
                    return plannedBreak;
                }
            }
            case PlanningShiftStepCategory.ADMINISTRATIVE: {
                const stepAdministrative = isStepServiceOrAdmin(step) ? step : undefined;

                const administrativeDto: CollectCreateAdministativeDto = {
                    ...baseSucessOrHazard(),
                    was_planned         : true,
                    collect_config_id   : stepAdministrative?.collect_config_id as string,
                    step_administrative : step as PlanningShiftStepAdministrative
                };
                return administrativeDto;
            }
            case PlanningShiftStepCategory.SERVICE: {
                const stepService = isStepService(step) ? step : undefined;
                const collected_items: LineCreateDto[] = state.products
                    ? Object.values(state.products || [])
                          .filter((product) => product.quantity > 0)
                          .map((product) => ({
                              id       : product.id,
                              quantity : product.quantity
                          }))
                    : [];
                if (wasPlanned) {
                    if (isHazard) {
                        const hazardStepService: CollectCreateServiceDto = {
                            was_planned       : true,
                            ...(baseSucessOrHazard() as BaseCollectCreateDtoHazard),
                            step_service      : step as PlanningShiftStepService,
                            collected_items,
                            collect_config_id : stepService?.collect_config_id as string,
                            is_splitted       : stepService?.is_splitted ?? false,
                            notifyBySMS       : state.notification?.notifyBySms,
                            contactToNotify   : state.notification?.contactToNotify
                                ? [state.notification?.contactToNotify]
                                : undefined
                        };
                        return hazardStepService;
                    }
                    if (planningPageState.type === EPlanningType.DUMPSTER) {
                        const dumpsterStepService: CollectCreateServiceDto = {
                            ...(baseSucessOrHazard() as BaseCollectCreateDtoSuccess),
                            step_service      : step as PlanningShiftStepService,
                            was_planned       : true,
                            dumpster_weight   : state.info?.dumpster_weight as number,
                            collected_items,
                            collect_config_id : stepService?.collect_config_id as string,
                            is_splitted       : stepService?.is_splitted ?? false,
                            delivery_number   : state.info?.delivery_number,
                            notifyBySMS       : state.notification?.notifyBySms,
                            contactToNotify   : state.notification?.contactToNotify
                                ? [state.notification?.contactToNotify]
                                : undefined
                        };
                        return dumpsterStepService;
                    } else {
                        const serviceStepService: CollectCreateServiceDto = {
                            ...(baseSucessOrHazard() as BaseCollectCreateDtoSuccess),
                            step_service      : step as PlanningShiftStepService,
                            was_planned       : true,
                            dumpster_weight   : undefined,
                            collected_items,
                            collect_config_id : stepService?.collect_config_id as string,
                            is_splitted       : stepService?.is_splitted ?? false,
                            notifyBySMS       : state.notification?.notifyBySms,
                            contactToNotify   : state.notification?.contactToNotify
                                ? [state.notification?.contactToNotify]
                                : undefined
                        };
                        return serviceStepService;
                    }
                } else {
                    const unplannedServiceDto: CollectCreateServiceNotPlannedDto = {
                        ...(baseSucessOrHazard() as BaseCollectCreateDtoSuccess),
                        collected_items,
                        was_planned       : false,
                        collect_config_id : cc?.id as string,
                        is_splitted       : cc?.is_splitted ?? false,
                        landfill_id       : state.landfill?.landfill?.id as string,
                        splitted_idx      : cc?.splitted_idx as number,
                        notifyBySMS       : state.notification?.notifyBySms,
                        contactToNotify   : state.notification?.contactToNotify
                            ? [state.notification?.contactToNotify]
                            : undefined
                    };
                    return unplannedServiceDto;
                }
            }
            case PlanningShiftStepCategory.EMPTYING: {
                const stepEmptying = isStepEmptying(step) ? step : undefined;
                if (wasPlanned) {
                    const landfillStepEmptying: CollectCreateEmptyingDto = {
                        ...baseSucessOrHazard(),
                        was_planned   : true,
                        step_emptying : {
                            dumpster_to_take       : stepEmptying?.dumpster_to_take,
                            category               : stepEmptying?.category,
                            scheduled_at           : stepEmptying?.scheduled_at,
                            scheduled_end_at       : stepEmptying?.scheduled_end_at,
                            scheduled_service_time : stepEmptying?.scheduled_service_time,
                            landfill_id            : stepEmptying?.landfill_id,
                            collect_id             : stepEmptying?.collect_id
                        } as PlanningShiftStepEmptying,
                        landfill_id     : state.landfill?.landfill?.id as string,
                        landfill_number : state.landfill?.number as string,
                        weight          : state.landfill?.weight
                    };
                    return landfillStepEmptying;
                } else {
                    const landfillStepEmptying: CollectCreateEmptyingDto = {
                        ...(baseSucessOrHazard() as BaseCollectCreateDtoSuccess),
                        was_planned     : false,
                        landfill_id     : state.landfill?.landfill?.id as string,
                        landfill_number : state.landfill?.number as string
                    };
                    return landfillStepEmptying;
                }
            }
            case PlanningShiftStepCategory.DRIVER_HOUSE_END:
            case PlanningShiftStepCategory.DRIVER_HOUSE_START: {
                const driverStep: CollectCreateDriverDto = {
                    ...(baseSucessOrHazard() as BaseCollectCreateDtoSuccess),
                    step_driver : step as PlanningShiftStepDriver,
                    was_planned : true,
                    origin      : category === PlanningShiftStepCategory.DRIVER_HOUSE_END ? 'end' : 'start'
                };
                return driverStep;
            }
            default: {
                throw new Error('Catégorie de collecte non prise en charge');
            }
        }
    };

    return removeFrontRoFromObject(buildDto()) as
        | CollectCreateServiceDto
        | CollectCreateAdministativeDto
        | CollectCreateEmptyingDto
        | CollectCreateBreakDto
        | CollectCreateDriverDto
        | CollectCreateServiceNotPlannedDto;
};

export const getUrlFromCategory = (
    category: PlanningShiftStepCategory,
    hazard?: boolean,
    wasPlanned?: boolean
): string => {
    switch (category) {
        case PlanningShiftStepCategory.BREAK:
            return urlApiBuilder.collectBreakPost();
        case PlanningShiftStepCategory.DRIVER_HOUSE_END:
        case PlanningShiftStepCategory.DRIVER_HOUSE_START:
            return urlApiBuilder.collectDriverPost();
        case PlanningShiftStepCategory.EMPTYING:
            return urlApiBuilder.collectEmptyingPost();
        case PlanningShiftStepCategory.ADMINISTRATIVE:
            return urlApiBuilder.collectAdministrativePost();
        case PlanningShiftStepCategory.SERVICE:
            if (!wasPlanned) {
                return urlApiBuilder.collectServiceUnplannedPost();
            }
            return hazard ? urlApiBuilder.collectServiceHazardPost() : urlApiBuilder.collectServicePost();
        default:
            throw new Error('Catégorie de collecte non prise en charge');
    }
};
