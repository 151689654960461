import { Contacts, ContactsProps } from '../common/Contacts';
import { OrderCollectInfo, OrderCollectInfoProps } from './CollectInfo';
import { OrderConstructionSite, OrderConstructionSiteProps } from './ConstructionSite';
import { OrderCustomer, OrderCustomerProps } from './Customer';
import { OrderDocuments, OrderDocumentsProps } from './Documents';
import { OrderDumpsterService, OrderDumpsterServiceProps } from './DumpsterService';
import { OrderMisc, OrderMiscProps } from './Misc';
import { OrderOverview, OrderOverviewProps } from './Overview';
import { OrderProducts, OrderProductsProps } from './Products';
import { OrderTrashType, OrderTrashTypeProps } from './TrashType';
import { OrderType, OrderTypeProps } from './Type';
import { OrderZoneProps, OrderZone } from './Zone';
import { OrderNotification, OrderNotificationProps } from './Notification';

const Modules = {
    Customer: (props: OrderCustomerProps) => {
        return <OrderCustomer {...props} />;
    },
    Type: (props: OrderTypeProps) => {
        return <OrderType {...props} />;
    },
    DumpsterService: (props: OrderDumpsterServiceProps) => {
        return <OrderDumpsterService {...props} />;
    },
    ConstructionSite: (props: OrderConstructionSiteProps) => {
        return <OrderConstructionSite {...props} />;
    },
    TrashType: (props: OrderTrashTypeProps) => {
        return <OrderTrashType {...props} />;
    },
    Contacts: (props: ContactsProps) => {
        return <Contacts {...props} />;
    },
    CollectInfo: (props: OrderCollectInfoProps) => {
        return <OrderCollectInfo {...props} />;
    },
    Misc: (props: OrderMiscProps) => {
        return <OrderMisc {...props} />;
    },
    Products: (props: OrderProductsProps) => {
        return <OrderProducts {...props} />;
    },
    Overview: (props: OrderOverviewProps) => {
        return <OrderOverview {...props} />;
    },
    Documents: (props: OrderDocumentsProps) => {
        return <OrderDocuments {...props} />;
    },
    Zone: (props: OrderZoneProps) => {
        return <OrderZone {...props} />;
    },
    Notification: (props: OrderNotificationProps) => {
        return <OrderNotification {...props} />;
    }
};

export default Modules;
