import equal from 'fast-deep-equal/react';
import moment from 'moment';
import { Tag } from 'primereact/tag';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import {
    CardErrors,
    CollectorRo,
    ECollectorSkill,
    EPlanningType,
    ETrashType,
    ETruckType,
    MAX_DIFF_BETWEEN_START_AND_END,
    PlanningShiftStepAdministrative,
    PlanningShiftStepBreak,
    PlanningShiftStepCategory,
    PlanningShiftStepDriver,
    PlanningShiftStepEmptying,
    PlanningShiftStepService,
    PRODUCT_DUMPSTER_TYPE,
    TruckRo
} from '@bbng/util/types';

import { generateInitialErrorState } from '../../../common/form';
import Calendar from '../../../components/Calendar';
import RelationAutocomplete from '../../common/RelationAutocomplete';
import { mapPlanningToShiftHeaderState, planningFinished } from './helpers';
import { GridItem } from './layout';
import { ManualShift } from './manual-shift';
import { usePlanningV2Store } from '../../../hooks/PlanningStoreV2';
import { mapDumpsterTypeToHexa, mapTruckType } from '../../../common/enumMapper';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { urlBuilder } from '../../../common/urlBuilder';

const MIN_DIFF_BETWEEN_START_AND_END = 30; // minimum difference between start and end time in minutes

export type ShiftHeaderProps = {
    id: string;
    planning_id: string;
    readOnly?: boolean;
    result: (value: ShiftHeaderState, errors: ShiftHeaderErrorState, id: string) => void;
    displayError?: boolean;
    type: EPlanningType;
};

export type ShiftHeaderState = {
    id: string;
    is_available: boolean;
    collector?: CollectorRo;
    truck?: TruckRo;
    start_date: string;
    end_date: string;
    distance: number;
    duration: string; // HH:mm
    steps_service: PlanningShiftStepService[];
    steps_administrative: PlanningShiftStepAdministrative[];
    steps_break: PlanningShiftStepBreak[];
    steps_driver: PlanningShiftStepDriver[];
    steps_emptying: PlanningShiftStepEmptying[];
    selected: boolean; // used for calculate presubmit
};
export type ShiftHeaderErrorState = CardErrors<ShiftHeaderState>;

const START_SHIFT: moment.Moment = moment.utc().hours(6);
const END_SHIFT: moment.Moment = START_SHIFT.add(9, 'hours');

export const initialState: ShiftHeaderState = {
    id                   : '',
    is_available         : true,
    collector            : undefined,
    truck                : undefined,
    start_date           : START_SHIFT.format(),
    end_date             : END_SHIFT.format(),
    distance             : 0,
    duration             : '00:00',
    steps_service        : [],
    steps_administrative : [],
    steps_break          : [],
    steps_driver         : [],
    steps_emptying       : [],
    selected             : true
};
export const initialErrorState: ShiftHeaderErrorState = generateInitialErrorState(initialState);

export const ShiftHeader: React.FC<ShiftHeaderProps> = ({
    id,
    planning_id,
    readOnly = false,
    result,
    displayError = false,
    type
}: ShiftHeaderProps): JSX.Element => {
    const {
        plannings,
        trucksByPlanning,
        collectorsByPlanning,
        ccsService,
        landfills,
        planningPageState,
        additionalInfoIsOpen
    } = usePlanningV2Store();
    const planning = plannings[planning_id];
    const truck = trucksByPlanning[planning_id];
    const collector = collectorsByPlanning[planning_id];
    const [val, setVal] = useState<ShiftHeaderState>(
        planning ? mapPlanningToShiftHeaderState(planning, truck, collector) : initialState
    );
    const [err, setErr] = useState<ShiftHeaderErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (
        value: boolean | Date | string | string[] | CollectorRo | undefined,
        errors: null | string[],
        childId: string
    ): void => {
        if (childId === 'start_date' || childId === 'end_date') {
            const isoValue = moment(value as Date).toISOString();
            if (equal(isoValue, val[childId as keyof ShiftHeaderState])) return;
            setVal((current) => ({ ...current, [childId as keyof ShiftHeaderState]: isoValue }));
        } else {
            if (equal(value, val[childId as keyof ShiftHeaderState])) return;
            setVal((current) => ({ ...current, [childId as keyof ShiftHeaderState]: value }));
        }

        if (childId === 'start_date' || childId === 'end_date') {
            const updatedValue = {
                ...val,
                // ...deepCopy(val),
                [childId as keyof ShiftHeaderState]: moment(value as Date).toISOString()
            };
            const isValid = checkIntegrity(updatedValue, err);
            if (equal(isValid, err) === false) {
                setErr(isValid);
            }
            return;
        } else {
            if (equal(errors, err[childId as keyof ShiftHeaderErrorState])) return;
            setErr((current) => ({ ...current, [childId]: errors }));
        }
    };

    const duration = () => {
        const start = moment.utc(planning?.shift.steps_driver[0]?.scheduled_at) ?? moment.utc();
        const end = moment.utc(planning?.shift.steps_driver[1]?.scheduled_at) ?? moment.utc();

        const duration = moment.duration(end.diff(start)).asMinutes();

        const durationMinutes = Math.round(duration % 60);
        const durationHours = Math.round(Math.floor(duration / 60));
        const formattedDuration = `${durationHours < 10 ? '0' : ''}${durationHours}:${
            durationMinutes < 10 ? '0' : ''
        }${durationMinutes}`;
        return formattedDuration;
    };

    const getVolume = () => {
        if (!planning) return 0;
        if (planning.type === EPlanningType.BIG_BAG) {
            const { volume } = planning.shift.steps_service.reduce(
                (acc, step) => {
                    const cc = ccsService[step.collect_config_id];
                    if (cc) {
                        if (step.is_splitted) {
                            if (acc.ccId.includes(step.collect_config_id)) return acc;
                            cc.splitted_informations[(step as any)?.splitted_idx]?.products.forEach((product) => {
                                acc.volume += product.volume_m3 * product.quantity;
                            });
                            acc.ccId.push(cc.id);
                        } else {
                            cc.products.forEach((product) => {
                                acc.volume += product.volume_m3 * product.quantity;
                            });
                        }
                    }
                    return acc;
                },
                { volume: 0, ccId: [] } as { volume: number; ccId: string[] }
            );

            return volume;
        }
        return 0;
    };

    const getPlanningCost = () => {
        const driverCostByHour = collector?.hourly_rate ?? 0;
        const driverCostByMinute = Number(
            (typeof driverCostByHour === 'number' ? 0 : driverCostByHour.net_amount_cents / 100 / 60).toFixed(2)
        );

        const durationInMinutes = moment
            .duration(
                moment(planning?.shift?.steps_driver[1]?.scheduled_at).diff(
                    moment(planning?.shift?.steps_driver[0]?.scheduled_at)
                )
            )
            .asMinutes();

        const distanceInKm = planning?.shift.distance ?? 0;

        const currentVolumeInTruck = truck?.characteristics.bigbag_current_load_m3 ?? 0;

        //Order step by date;
        const steps = [...(planning?.shift.steps_service ?? []), ...(planning?.shift.steps_emptying ?? [])].sort(
            (a, b) => moment(a.scheduled_at).diff(moment(b.scheduled_at))
        );

        const informationsForCalculus = steps.reduce(
            (acc, step) => {
                if (step.category === PlanningShiftStepCategory.EMPTYING) {
                    //On affecte le landfill à la current iteration
                    acc[acc.length - 1].landfillId = step.landfill_id;

                    //On en ajoute une nouvelle
                    const baseObject = Object.values(ETrashType).reduce(
                        (acc, type) => ({ ...acc, [type]: 0 }),
                        {} as { [x in ETrashType | 'landfillId']: x extends ETrashType ? number : string }
                    );
                    baseObject.landfillId = '';
                    acc.push(baseObject);
                } else {
                    const ccId = step.collect_config_id;
                    const cc = ccsService[ccId];

                    if (!cc) return acc;
                    if (planning?.type === EPlanningType.BIG_BAG) {
                        cc.products.forEach((product) => {
                            if (product.family === 'COLLECT_BIG_BAG') {
                                acc[acc.length - 1][product.trash_type] += product.quantity * product.volume_m3;
                            }
                        });
                    } else {
                        cc.products.forEach((product) => {
                            if (
                                product.family === 'COLLECT_DUMPSTER_LOAD_WAIT' ||
                                product.family === 'COLLECT_DUMPSTER_RETRIEVAL'
                            ) {
                                acc[acc.length - 1][product.trash_type] += product.quantity * product.volume_m3;
                            }
                        });
                    }
                }

                return acc;
            },
            [
                {
                    ...Object.values(ETrashType).reduce((acc, type) => ({ ...acc, [type]: 0 }), {}),
                    MIXED      : currentVolumeInTruck,
                    landfillId : ''
                }
            ] as {
                [x in ETrashType | 'landfillId']: x extends ETrashType ? number : string;
            }[]
        );

        const priceForEmptying = informationsForCalculus.reduce((acc, information) => {
            if (information.landfillId === '') return acc;

            const landfill = landfills[information.landfillId];
            if (!landfill) return acc;

            Object.keys(information).forEach((key) => {
                if (key === 'landfillId') return;

                const volume = information[key as ETrashType];
                const trashType = key as ETrashType;

                const trashTypePrice = landfill.trash_details.find((trashDetail) => trashDetail.type === trashType);
                if (!trashTypePrice) return;

                acc += volume * ((trashTypePrice?.price_value?.net_amount_cents ?? 0) / 100);
            });

            return acc;
        }, 0);

        const priceForDriver = durationInMinutes * driverCostByMinute;

        const priceForTruck = distanceInKm * 1 + (planning.type === EPlanningType.DUMPSTER ? 700 : 600);

        return (priceForEmptying + priceForDriver + priceForTruck).toFixed(2);
    };

    const getCA = () => {
        const ccIds = planning?.shift.steps_service.map((step) => step.collect_config_id);
        const ccs = ccIds?.filter((ccId) => !!ccsService[ccId])?.map((ccId) => ccsService[ccId]);

        const price = ccs.reduce((acc, cc) => {
            return acc + cc.price.discounted_net.amount / 100;
        }, 0);

        return price.toFixed(2);
    };

    const getGain = () => {
        const ca = Number(getCA());
        const cost = Number(getPlanningCost());

        return {
            inPercent : (((ca - cost) / ca) * 100).toFixed(2),
            inValue   : (ca - cost).toFixed(2)
        };
    };

    const typesToDisplay: ETruckType[] = React.useMemo(() => {
        if (type === EPlanningType.DUMPSTER) {
            return truck.type.filter((tp) =>
                [ETruckType.DUMPSTER_AMPLIROLL, ETruckType.DUMPSTER_CHAIN, ETruckType.NARROW_STREET].includes(tp)
            );
        } else {
            return truck.type.filter((tp) => tp === ETruckType.NARROW_STREET);
        }
    }, [type, truck]);

    const isFinished = useMemo(() => {
        return planning.shift.steps_driver.some(
            (step) => step.category === PlanningShiftStepCategory.DRIVER_HOUSE_END && step.collect_id
        );
    }, [planning.shift]);

    const [activeAccordionIndex, setActiveAccordionIndex] = useState<number | undefined>();

    useEffect(() => {
        if (additionalInfoIsOpen) {
            setActiveAccordionIndex(0);
        } else {
            setActiveAccordionIndex(undefined);
        }
    }, [additionalInfoIsOpen]);

    const collectorSkills: ECollectorSkill[] = useMemo(() => {
        if (planningPageState.type === EPlanningType.BIG_BAG) {
            return [ECollectorSkill.BIG_BAG];
        } else {
            return truck.type.filter((tpe) =>
                Object.values(PRODUCT_DUMPSTER_TYPE).includes(tpe as PRODUCT_DUMPSTER_TYPE)
            ) as unknown as ECollectorSkill[];
        }
    }, [truck, planningPageState.type]);

    return (
        <GridItem columnStart={1} columnEnd={-1} rowStart={1} rowEnd={2} zIndex={100}>
            <ShiftHeaderCell
                style={{
                    background: truck?.is_available
                        ? 'transparent'
                        : 'repeating-linear-gradient(45deg,#fff,#fff 10px,#9e9e9e 10px,#9e9e9e 20px)',
                    height       : '100%',
                    marginBottom : '16px',
                    minWidth     : '250px'
                }}
            >
                <ShiftHeaderContentCell>
                    <TruckCharacteristics>
                        {typesToDisplay.map((type) => (
                            <StyledTruckTypeTag
                                key={type}
                                value={mapTruckType(type)}
                                backgroundColor={
                                    type === ETruckType.NARROW_STREET
                                        ? '#FFA500'
                                        : mapDumpsterTypeToHexa(type as PRODUCT_DUMPSTER_TYPE)
                                }
                            />
                        ))}
                    </TruckCharacteristics>
                    <TruckInfo>
                        <TruckName href={urlBuilder.truckEdit(truck.id)} target="_blank" rel="noreferrer">
                            {val.truck?.name}
                        </TruckName>
                        <div>
                            <CurrentLoad>{val.truck?.characteristics.bigbag_current_load_m3 ?? 0}</CurrentLoad>
                            <TotalLoad>/{val.truck?.characteristics.bigbag_capacity ?? 0}m³</TotalLoad>
                        </div>
                    </TruckInfo>
                    <RelationAutocomplete.Collector
                        onSelect={(value: CollectorRo | undefined) => {
                            handleChange(value, null, 'collector');
                        }}
                        onUnselect={() => handleChange(undefined, null, 'collector')}
                        readOnly={readOnly || !val.is_available}
                        errors={err.collector}
                        displayError={displayError}
                        baseValue={val.collector as CollectorRo}
                        has_landfill={type === EPlanningType.DUMPSTER || undefined} // retrieve only collectors with landfill if it's a dumpster planning
                        skills={collectorSkills}
                    />
                    {isFinished ? (
                        <FinishedTag value="Terminé" severity="success" />
                    ) : (
                        <ManualShift
                            planning_id={planning.id}
                            planningState={val}
                            readOnly={planningFinished(planning)}
                        />
                    )}
                    <ShiftStats>
                        <Tag value={`${planning?.shift.steps_service.length} évacs`} />
                        <Tag value={`${planning?.shift.distance.toFixed(1)} km`} />
                        {planning?.type === EPlanningType.BIG_BAG && <Tag value={`${getVolume()}m3`} />}
                        <Tag value={`${duration()}`} />
                    </ShiftStats>
                    <StyledAccordion
                        activeIndex={activeAccordionIndex}
                        onTabChange={(e) => setActiveAccordionIndex(e.index as any)}
                    >
                        <AccordionTab headerTemplate={<AccordionHeader>Informations complémentaires</AccordionHeader>}>
                            <AccordionTabContent>
                                <ShiftHours>
                                    <Calendar.HoursMins
                                        id={`start_date`}
                                        readOnly={readOnly || !val.is_available}
                                        required={val.is_available ? true : false}
                                        value={moment(val.start_date).toDate()}
                                        result={handleChange}
                                        displayError={displayError}
                                        errors={err.start_date}
                                        stepMinute={15}
                                    />
                                    <p>-</p>
                                    <Calendar.HoursMins
                                        id={`end_date`}
                                        readOnly={readOnly || !val.is_available}
                                        required={val.is_available ? true : false}
                                        value={moment(val.end_date).toDate()}
                                        result={handleChange}
                                        displayError={displayError}
                                        errors={err.end_date}
                                        stepMinute={15}
                                    />
                                </ShiftHours>
                                {moment(planning.day).isSame(moment(), 'day') === false && (
                                    <ShiftCost>
                                        <Tag value={`CA: ${getCA()}€`} severity="success" />
                                        <Tag value={`Coût: ${getPlanningCost()}€`} severity="danger" />
                                        <Tag value={`${getGain().inPercent}%`} />
                                        <Tag value={`${getGain().inValue}€`} />
                                    </ShiftCost>
                                )}
                                <span>
                                    {planning?.calcul_method ? `Calculé: ${planning?.calcul_method}` : 'Non calculé'}
                                </span>
                            </AccordionTabContent>
                        </AccordionTab>
                    </StyledAccordion>
                </ShiftHeaderContentCell>
            </ShiftHeaderCell>
        </GridItem>
    );
};

const checkIntegrity = (value: ShiftHeaderState, error: ShiftHeaderErrorState): ShiftHeaderErrorState => {
    let errorText = '';
    if (
        moment(value.start_date).add(MIN_DIFF_BETWEEN_START_AND_END, 'minute').isAfter(moment(value.end_date)) ||
        moment(value.start_date).add(MAX_DIFF_BETWEEN_START_AND_END, 'hour').isBefore(moment(value.end_date))
    ) {
        errorText = `La fin de journée doit être au moins ${MIN_DIFF_BETWEEN_START_AND_END} minutes après le début, et maximum ${MAX_DIFF_BETWEEN_START_AND_END}h après.`;
    }
    return {
        ...error,
        start_date : errorText ? [errorText] : null,
        end_date   : errorText ? [errorText] : null
    };
};

const ShiftHeaderCell = styled.div`
    padding-left: 10px;
    color: black;
`;
const ShiftHeaderContentCell = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

const TruckCharacteristics = styled.div`
    height: 20px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    gap: 8px;
`;

const TruckInfo = styled.div`
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
`;
const ShiftStats = styled.div`
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;

const ShiftCost = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
`;

const ShiftHours = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;

const StyledTruckTypeTag = styled(Tag)<{ backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

const TruckName = styled.a`
    font-weight: 500;
    font-size: 1em;
`;

const CurrentLoad = styled.span`
    font-weight: 500;
    font-size: 1.2em;
`;

const TotalLoad = styled.span`
    font-weight: 200;
    font-size: 0.8em;
`;

const FinishedTag = styled(Tag)`
    width: 100%;
`;

const StyledAccordion = styled(Accordion)`
    z-index: 1000;
    & .p-accordion-header-link {
        padding: 4px 1.25em !important;
        gap: 4px;
    }
`;

const AccordionHeader = styled.span`
    font-size: 0.75em;
`;

const AccordionTabContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
