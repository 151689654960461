import Joi from 'joi';

export const CollectConfigPublicInformationsCreateDtoSchema = Joi.object({
    collect_config_id    : Joi.array().min(1).items(Joi.string().required()),
    customer_id          : Joi.array().min(1).items(Joi.string().required()),
    order_id             : Joi.array().min(1).items(Joi.string().required()),
    construction_site_id : Joi.array().min(1).items(Joi.string().required()),

    sms_sent    : Joi.boolean().optional(),
    sms_sent_to : Joi.array().items(Joi.string()).optional(),
    sms_sent_at : Joi.date().optional(),
    sms_content : Joi.string().optional(),

    driver_coordinate_latitude  : Joi.number().optional(),
    driver_coordinate_longitude : Joi.number().optional()
});

export const CollectConfigPublicInformationsEditDtoSchema = Joi.object({
    driver_coordinate_x : Joi.number(),
    driver_coordinate_y : Joi.number()
});
