import styled from 'styled-components';

import { CardErrors } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import { useFormModule } from '../../hooks/FormModule';
import InputList from '../InputList';
import { Address } from '../InputList/address';

export type CustomerShippingAndInvoiceAddressesProps = {
    readOnly?: boolean;
    value?: CustomerShippingAndInvoiceAddressesState;
    id: string;
    result: (
        value: CustomerShippingAndInvoiceAddressesState,
        errors: null | string[] | CardErrors<CustomerShippingAndInvoiceAddressesState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type CustomerShippingAndInvoiceAddressesState = {
    shipping_address: Array<Address>;
    invoice_address: Array<Address>;
    //
};

export type CustomerShippingAndInvoiceAddressesErrorState = CardErrors<CustomerShippingAndInvoiceAddressesState>;

export const initialState: CustomerShippingAndInvoiceAddressesState = {
    shipping_address : [],
    invoice_address  : []
};

export const initialErrorState: CustomerShippingAndInvoiceAddressesErrorState = generateInitialErrorState(initialState);

export const CustomerShippingAndInvoiceAddresses = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: CustomerShippingAndInvoiceAddressesProps) => {
    const { val, err, handleChange } = useFormModule({
        id,
        initialError : initialErrorState,
        initialValue : value,
        result
    });

    return (
        <StyledCard title="Liste des adresses">
            <CardLine>
                <div>
                    {readOnly && <h4 style={{ textDecoration: 'underline' }}>FACTURATION</h4>}
                    <InputList.Addresses
                        id="invoice_address"
                        readOnly={readOnly}
                        title="Facture"
                        value={val.invoice_address}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    {readOnly && <h4 style={{ textDecoration: 'underline' }}>LIVRAISON</h4>}
                    <InputList.Addresses
                        id="shipping_address"
                        readOnly={readOnly}
                        title="Livraison"
                        value={val.shipping_address}
                        onChange={handleChange}
                    />
                </div>
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
    }
`;

const StyledCard = styled(Card)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > * {
        flex: 1;
        width: 100%;
    }
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
