import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { CardErrors, CCServiceRo } from '@bbng/util/types';
import { CheckboxButton } from '../../components/Button/Checkbox';
import { generateInitialErrorState } from '../../common/form';
import { IContact } from '@bbng/util/types';
import { useFormModule } from '../../hooks/FormModule';
import { useRequest } from '../../hooks/StatelessRequest';
import { urlApiBuilder } from '../../common/urlBuilder';
import PhoneInput from 'react-phone-input-2';

export type CollectNotificationErrorState = CardErrors<CollectNotificationState>;

export const initialState: CollectNotificationState = {
    notifyBySms     : false,
    contactToNotify : ''
};

export type CollectNotificationState = {
    notifyBySms: boolean;
    contactToNotify: string;
};

export const initialErrorState: CollectNotificationErrorState = generateInitialErrorState(initialState);

export type CollectSMSInformationModalProps = {
    id: string;
    dataId: string;
    visible?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    value?: CollectNotificationState;
    result?: (
        value: CollectNotificationState,
        errors: null | string[] | CollectNotificationErrorState,
        id: string
    ) => void;
};

export const CollectSMSInformationModal: React.FC<CollectSMSInformationModalProps> = ({
    id,
    dataId: ccId,
    visible = false,
    onConfirm = () => void 0,
    onCancel = () => void 0,
    value = initialState,
    result = () => void 0
}) => {
    const [existingContact, setExistingContact] = useState<IContact[]>([]);
    const bbngRequest = useRequest({
        toastifyError   : false,
        toastifySuccess : false
    });
    const { val, setVal } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    const fetchCollectConfig = async () => {
        const response = await bbngRequest<CCServiceRo>({
            url    : urlApiBuilder.collectConfigRead(ccId),
            method : 'GET'
        });
        const collectConfig = response.response?.data.ro;
        setExistingContact(collectConfig?.construction_site_contact.filter((item) => item.phone_number) || []);
    };

    useEffect(() => {
        fetchCollectConfig();
    }, []);

    const handleChange = (
        value: boolean | string | string[] | undefined | null | IContact,
        errors: string[] | null,
        childId: string
    ) => {
        setVal((current) => ({
            ...current,
            [childId]: value
        }));
    };

    return (
        <StyledModal visible={visible} onHide={onCancel} header="Information importante !">
            <div>
                <h3 style={{ textAlign: 'center' }}>
                    Souhaitez vous envoyer un SMS au client ?
                    <span role="img" aria-label="sms" style={{ paddingLeft: '1em' }}>
                        💬
                    </span>
                </h3>
            </div>
            <br />
            <HorizontalContainer>
                <CheckboxButton id="notifyBySms" result={handleChange} value={value.notifyBySms} />
                <h4 style={{ textAlign: 'center' }}>
                    Envoyer un SMS au client pour le prévenir de la fin de la collecte ?
                </h4>
            </HorizontalContainer>
            {val.notifyBySms && (
                <div
                    style={{
                        justifyContent : 'center',
                        alignContent   : 'center',
                        display        : 'flex',
                        flexDirection  : 'column',
                        flex           : 1
                    }}
                >
                    <h4 style={{ textAlign: 'center' }}>Veuillez sélectionner le contact à notifier :</h4>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        {existingContact.map((contact, index) => (
                            <CheckboxButton
                                id={contact.phone_number}
                                key={index}
                                label={contact.firstname + ' ' + contact.lastname + ' | ' + contact.phone_number}
                                result={(val) => {
                                    if (val) {
                                        setVal((current) => ({
                                            ...current,
                                            contactToNotify: contact.phone_number
                                        }));
                                    } else {
                                        setVal((current) => ({
                                            ...current,
                                            contactToNotify: ''
                                        }));
                                    }
                                }}
                                value={val.contactToNotify === contact.phone_number}
                            />
                        ))}
                    </div>
                    <div style={{ alignSelf: 'center', marginTop: '1.25em' }}>
                        <PhoneInput
                            country={'fr'}
                            countryCodeEditable={false}
                            showDropdown={false}
                            placeholder="Numéro de téléphone"
                            disableDropdown={true}
                            enableAreaCodeStretch={false}
                            enableSearch={false}
                            value={val.contactToNotify ?? ''}
                            onChange={(val) => {
                                setVal((current) => ({
                                    ...current,
                                    contactToNotify: val
                                }));
                            }}
                        />
                    </div>
                </div>
            )}
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button label="Annuler" onClick={onCancel} style={{ backgroundColor: 'red' }} />
                <Button
                    label="Confirmer"
                    type="button"
                    onClick={onConfirm}
                    disabled={val.notifyBySms && val.contactToNotify.length === 0}
                />
            </div>
        </StyledModal>
    );
};

const StyledModal = styled(Dialog)`
    min-width: 500px;
`;

const HorizontalContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
`;
