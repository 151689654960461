import Joi from 'joi';

import { generateRelationsSchema } from '../dto';
import { PhoneE164Schema, ProductPriceSchema } from '../misc.schema';
import { CollectorRo, ECollectorContract, ECollectorSkill } from './collector';

export const CollectorCreateDtoSchema = Joi.object({
    firstname    : Joi.string().required(),
    lastname     : Joi.string().required(),
    email        : Joi.string().email({ tlds: false }).required(),
    phone_number : PhoneE164Schema.required(),
    avatar       : Joi.string(),
    contract     : Joi.string()
        .valid(...Object.values(ECollectorContract))
        .required(),
    is_available : Joi.boolean().required(),
    efficiency   : Joi.number().min(0).max(100).required(),
    skills       : Joi.array().items(Joi.string().valid(...Object.values(ECollectorSkill))),
    landfill_id  : Joi.array().items(Joi.string()).max(1),
    hourly_rate  : ProductPriceSchema.allow(null)
});

export const CollectorEditDtoSchema = Joi.object({
    firstname    : Joi.string(),
    lastname     : Joi.string(),
    email        : Joi.string().email({ tlds: false }),
    phone_number : PhoneE164Schema,
    avatar       : Joi.string(),
    contract     : Joi.string().valid(...Object.values(ECollectorContract)),
    is_available : Joi.boolean(),
    efficiency   : Joi.number().min(0).max(100),
    skills       : Joi.array().items(Joi.string().valid(...Object.values(ECollectorSkill))),
    hourly_rate  : ProductPriceSchema.allow(null)
}).concat(generateRelationsSchema<CollectorRo>('landfill_id'));
