import { CollectService, CollectServiceFront } from '../collect';
import { CollectorRo } from '../collector';
import { ConstructionSiteRo } from '../construction-site';
import { CustomerRo } from '../customer';
import { DumpsterOnSiteRo } from '../dumpster-on-site';
import { InvoiceRo } from '../invoice';
import { OrderRo } from '../order';
import { PrestaRo } from '../presta';
import { TruckRo } from '../truck';
import { CCAdministrativeRo, CCRo, CCServiceRo, CC_FAMILY } from './collect-config';

export type CCAdministrativeRoFront = Omit<CCAdministrativeRo, 'truck_id' | 'collector_id'> & {
    truck_id: string[] | TruckRo[];
    collector_id: string[] | CollectorRo[];
};

export type CCServiceRoFront = Omit<
    CCServiceRo,
    | 'order_id'
    | 'collector_id'
    | 'invoice_id'
    | 'construction_site_id'
    | 'customer_id'
    | 'presta_id'
    | 'dumpster_on_site_id'
> & {
    order_id: string[] | OrderRo[];
    collector_id: string[] | CollectorRo[];
    invoice_id: string[] | InvoiceRo[];
    construction_site_id: string[] | ConstructionSiteRo[];
    customer_id: string[] | CustomerRo[];
    presta_id: string[] | PrestaRo[];
    collect?: CollectServiceFront | CollectService; // this is not automatic, this should be fetched manually in frontend
    splitted_idx?: number; // this is not automatic, this should be added manually in frontend
    is_splitted?: true; // this is not automatic, this should be added manually in frontend
    dumpster_on_site_id?: string[] | DumpsterOnSiteRo[];
    deposit_collect_config_id?: string[] | CCServiceRo[];
};

export type CCRoFront = CCAdministrativeRoFront | CCServiceRoFront;

export const isCCServiceFront = (cc: CCAdministrativeRoFront | CCServiceRoFront): cc is CCServiceRoFront => {
    return cc.family !== CC_FAMILY.ADMINISTRATIVE;
};

export const isCCService = (cc?: CCRo): cc is CCServiceRo => {
    if (!cc) return false;
    return cc?.family !== CC_FAMILY.ADMINISTRATIVE;
};
