import moment from 'moment';
import { Tag } from 'primereact/tag';
import React, { useEffect, useState } from 'react';

import {
    UserRo,
    UserQuery,
    CustomerCreateInvitationAsAdminDto,
    FrontRo,
    CustomerRo,
    EUserRole,
    EUserInvitationStatus
} from '@bbng/util/types';

import { USER_ORCHESTRATION_BASE_URL, urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import Button from '../../components/Button';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { Anchor, Filters, FiltersContainer, PageDescription } from './style';
import { MenuItemCommandParams } from 'primereact/menuitem';
import { confirmDialog } from 'primereact/confirmdialog';
import { PageLoader } from '../../components/PageLoader';
import { useRequest } from '../../hooks/StatelessRequest';
import { toast } from '../../components/Toast';
import { fetchDataRelation } from '../../common/dataRelation';
import { mapCreatedFrom, mapUserInvitationStatus, mapUserInvitationStatusToSeverity } from '../../common/enumMapper';

type QueryParams = {
    customer_id?: string;
};

export const CustomerInvitationsListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [customer_id, setCustomerId] = React.useState<string | undefined>(query.customer_id);
    const [onlyActive, setOnlyActive] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(false);

    const request = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [triggerPageRefresh, setTriggerPageRefresh] = useState<boolean>(false);
    useEffect(() => {
        setTriggerPageRefresh(false);
    }, [triggerPageRefresh]);

    const resendInvitation = React.useCallback(async (siret: string, user_id: string) => {
        setLoading(true);

        const res = await request({
            method  : 'POST',
            url     : urlApiBuilder.customerResendInvitation(siret),
            payload : {
                body: {
                    user_id
                } as CustomerCreateInvitationAsAdminDto
            },
            options: { toastifySuccess: false }
        });
        if (res.success) {
            toast({
                life     : 10_000,
                severity : 'success',
                summary  : "Demande d'accès renvoyée",
                detail   : `La demande d'accès a bien été renvoyée aux administrateurs de l'entreprise.`
            });
        }

        setLoading(false);
        setTriggerPageRefresh(true);
    }, []);

    const deleteInvitation = React.useCallback(async (user_id: string) => {
        setLoading(true);
        await request({
            method : 'POST',
            url    : urlApiBuilder.customerDeleteInvitation(user_id)
        });

        toast({
            life     : 10_000,
            severity : 'success',
            summary  : "Demande d'accès supprimée",
            detail   : `La demande d'accès a bien été supprimée.`
        });

        setLoading(false);
        setTriggerPageRefresh(true);
    }, []);

    const handleActionRowAccept = (e: MenuItemCommandParams) => {
        const index = e.item['rowInfo']['rowIndex'];
        const user = e.item['rowInfo']['props']['value'][index] as UserRo;

        /**
         * Frontend guard to avoid useless API call if user invitation is expired.
         */
        if (
            !user.invitation ||
            (user.invitation?.expires_at && moment.utc(user.invitation?.expires_at).isBefore(moment.utc())) ||
            !user.invitation?.expires_at
        ) {
            toast({
                life     : 10000,
                severity : 'error',
                summary  : 'Erreur',
                detail   : 'Cette invitation est expirée.'
            });
            return;
        }

        confirmDialog({
            header      : "Relancer la demande d'accès",
            message     : `Etes-vous sur de vouloir renvoyer la demande de ${user.fullname} au client ${user.invitation?.customer_name} ?`,
            icon        : 'pi pi-info-circle',
            acceptLabel : 'Confirmer',
            rejectLabel : 'Annuler',
            accept      : () => {
                resendInvitation(user.invitation!.customer_siret, user.id);
            }
        });
    };

    const handleActionRowDelete = (e: MenuItemCommandParams) => {
        const index = e.item['rowInfo']['rowIndex'];
        const user = e.item['rowInfo']['props']['value'][index] as UserRo;

        confirmDialog({
            header          : "Supprimer la demande d'accès",
            message         : `Etes-vous sur de vouloir supprimer la demande de ${user.fullname} au client ${user.invitation?.customer_name} ?`,
            icon            : 'pi pi-info-circle',
            acceptClassName : 'p-button-danger',
            acceptLabel     : 'Confirmer',
            rejectLabel     : 'Annuler',
            accept          : () => {
                deleteInvitation(user.id);
            }
        });
    };

    if (loading) return <PageLoader loading actionType="create" />;

    return (
        <>
            <Listing<any, UserRo>
                url={USER_ORCHESTRATION_BASE_URL}
                endpoint="users"
                hideCreateButton
                hideBulkMenu
                displayBulkArchiveAction={false}
                displayArchiveFilters={false}
                pageUrl={urlBuilder.collectHistory()}
                displaySelectColumn={false}
                showSearch={false}
                triggerPageRefresh={triggerPageRefresh}
                displayArchiveColumn={false}
                disableArchiveAction
                disableEditAction
                actionRows={[
                    {
                        label   : 'Relancer',
                        icon    : 'pi pi-replay',
                        command : (e) => handleActionRowAccept(e)
                    },
                    {
                        label   : 'Supprimer',
                        icon    : 'pi pi-trash',
                        command : (e) => handleActionRowDelete(e)
                    }
                ]}
                queryParams={
                    {
                        invitation_customer_id : customer_id,
                        has_invitation         : true,
                        has_active_invitation  : onlyActive ? true : undefined
                    } as UserQuery
                }
                enrichData={async (data) => {
                    /**
                     * Fetch linked customers first.
                     * Then, fetch linked users (to get all customer users).
                     */
                    data.data.ro = await fetchDataRelation(
                        await fetchDataRelation(data.data.ro ?? [], { customer_id: true }),
                        { user_id: true }
                    );
                    return data;
                }}
                leftHandedComponent={
                    <FiltersContainer>
                        <Filters>
                            <Button.Switch
                                id="onlyActive"
                                value={onlyActive}
                                result={(value) => setOnlyActive(value)}
                                label="Demandes d'accès actives uniquement"
                                labelPosition="left"
                                readOnly={false}
                            />
                            <RelationAutocomplete.Customer
                                placeholder="Filtrer par client"
                                onSelect={(customer) => setCustomerId(customer?.id)}
                                onUnselect={() => setCustomerId(undefined)}
                            />
                        </Filters>
                        <PageDescription>
                            Cette liste correspond à toutes les demandes d'accès faites par des utilisateurs pour
                            rejoindre un client depuis l'app mobile. En relançant une invitation, les utilisateurs
                            administrateurs du client seront notifiés par e-mail et pourront accepter la demande.
                        </PageDescription>
                    </FiltersContainer>
                }
                title={"Liste des demandes d'accès clients"}
                name="Demande d'accès"
                pluralName="Demandes d'accès"
                headers={[
                    {
                        name      : 'Utilisateur',
                        field     : 'fullname',
                        component : (data: UserRo) => {
                            return (
                                <a href={urlBuilder.userView(data.id)} target="_blank" rel="noreferrer">
                                    {data.fullname}
                                </a>
                            );
                        }
                    },
                    {
                        name      : 'E-mail',
                        field     : 'email',
                        component : (data: UserRo) => {
                            return (
                                <Anchor href={`mailto:${data.email}`}>
                                    <i className="pi pi-envelope" />
                                    {data.email}
                                </Anchor>
                            );
                        }
                    },
                    {
                        name      : 'Client',
                        field     : 'invitation',
                        component : (data: UserRo) => {
                            return (
                                <a
                                    href={urlBuilder.customerView((data.invitation?.customer_id as any)?.id)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data.invitation?.customer_name}
                                </a>
                            );
                        }
                    },
                    {
                        name      : 'Utilisateurs administrateurs du client',
                        field     : 'customer_id',
                        component : (data: FrontRo<UserRo>) => {
                            const customer = data.invitation?.customer_id as unknown as CustomerRo;
                            const users = (customer?.user_id as unknown as UserRo[]) || [];
                            const admins = users.filter((user) => user.roles.includes(EUserRole.BBNG_USER_ADMIN));

                            if (admins.length === 0 && users.length === 0) {
                                return 'Aucun utilisateur';
                            } else if (admins.length === 0) {
                                const query = `customer_id=${customer.id}`;
                                return (
                                    <span>
                                        Aucun administrateur&nbsp;
                                        <a href={urlBuilder.userList(query)} target="_blank" rel="noreferrer">
                                            ({users.length} utilisateur(s))
                                        </a>
                                    </span>
                                );
                            } else {
                                const query = `customer_id=${customer.id}&role=${EUserRole.BBNG_USER_ADMIN}`;
                                return (
                                    <a href={urlBuilder.userList(query)} target="_blank" rel="noreferrer">
                                        {admins.length} administrateur(s)
                                    </a>
                                );
                            }
                        }
                    },
                    {
                        name      : 'Statut',
                        field     : 'invitation',
                        component : (data: UserRo) => {
                            /**
                             * Backward compatility: if no status, consider it as PENDING_OR_EXPIRED.
                             */
                            if (
                                !data.invitation?.status ||
                                data.invitation?.status === EUserInvitationStatus.PENDING_OR_EXPIRED
                            ) {
                                if (
                                    !data.invitation ||
                                    Object.keys(data.invitation).length === 0 ||
                                    moment.utc(data.invitation?.expires_at).isBefore(moment.utc())
                                ) {
                                    return (
                                        <Tag
                                            className="mr-2"
                                            value={`Expirée (depuis le ${moment(data.invitation?.expires_at).format(
                                                'DD/MM/YYYY à HH:mm'
                                            )})`}
                                            severity="danger"
                                        />
                                    );
                                } else {
                                    return (
                                        <div>
                                            <Tag
                                                className="mr-2"
                                                severity="info"
                                                value={`Active (expire le ${moment(data.invitation?.expires_at).format(
                                                    'DD/MM/YYYY à HH:mm'
                                                )})`}
                                            />
                                        </div>
                                    );
                                }
                            } else {
                                return (
                                    <Tag
                                        className="mr-2"
                                        value={mapUserInvitationStatus(data.invitation?.status)}
                                        severity={mapUserInvitationStatusToSeverity(data.invitation?.status)}
                                    />
                                );
                            }
                        }
                    },
                    {
                        name      : 'Origine',
                        field     : 'created_from',
                        component : (data: UserRo) => <span>{mapCreatedFrom(data.created_from)}</span>
                    }
                ]}
            />
        </>
    );
};
