import Joi from 'joi';
import { PhoneE164Schema } from '../misc.schema';

export const SmsCreateDtoSchema = Joi.object({
    recipients   : Joi.array().items(PhoneE164Schema),
    message_type : Joi.string().required(),
    ccId         : Joi.string().required()
});

export const SMSEndOfCollectSchema = Joi.object({
    recipients                : Joi.array().items(PhoneE164Schema),
    message_type              : Joi.string().required(),
    bdc                       : Joi.string().required(),
    construction_site_address : Joi.string().required(),
    products                  : Joi.array()
        .items(
            Joi.object({
                product  : Joi.object().required(),
                quantity : Joi.number().required()
            })
        )
        .required()
});

export const SMSEndOfCollectHazardSchema = Joi.object({
    recipients                : Joi.array().items(PhoneE164Schema),
    message_type              : Joi.string().required(),
    bdc                       : Joi.string().required(),
    construction_site_address : Joi.string().required()
});

export const SMSBeginOfCollectSchema = Joi.object({
    recipients       : Joi.array().items(PhoneE164Schema),
    ccId             : Joi.string().required(),
    driver_longitude : Joi.number().optional(),
    driver_latitude  : Joi.number().optional()
});

export const SMSBeginOfCollectActionSchema = Joi.object({
    construction_site_address     : Joi.string().required(),
    bdc                           : Joi.string().required(),
    estimated_time_arrival        : Joi.string().required(),
    recipients                    : Joi.array().items(PhoneE164Schema),
    message_type                  : Joi.string().required(),
    driver_longitude              : Joi.number().required(),
    driver_latitude               : Joi.number().required(),
    collect_public_information_id : Joi.string().required()
});
