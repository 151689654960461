import React from 'react';
import * as yup from 'yup';

import { match } from '@bbng/util/misc';

type ComponentInputProps<Value, ChangeEvent> = {
    id: string;
    value: Value;
    errors: null | string[];
    displayError: boolean;
    schema: yup.AnySchema;
    result: (value: Value, errors: null | string[], id: string) => void;
    required: boolean;
    mapValue: (e: ChangeEvent) => Value;
    didRevalidateSchema?: (e: Value) => boolean;
};

type ComponentInputReturnType<Value, ChangeEvent> = {
    val: Value;
    err: null | string[];
    displayErr: boolean;
    handleOnChange: (e: ChangeEvent) => void;
    handleBlur: () => void;
    setVal: React.Dispatch<React.SetStateAction<Value>>;
    setErr: React.Dispatch<React.SetStateAction<string[] | null>>;
    setDisplayErr: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useComponentInput<Value = any, ChangeEvent = any>({
    id,
    value,
    errors,
    displayError,
    result,
    required,
    schema,
    didRevalidateSchema = () => false,
    mapValue
}: ComponentInputProps<Value, ChangeEvent>): ComponentInputReturnType<Value, ChangeEvent> {
    const [val, setVal] = React.useState<Value>(value);
    const [err, setErr] = React.useState<null | string[]>(errors ?? null);
    const [displayErr, setDisplayErr] = React.useState<boolean>(displayError ?? false);

    const _checkValidationSchema = (val: Value) => {
        try {
            schema.validateSync(val, { abortEarly: false });
        } catch (err) {
            setErr(err.errors);
            result(val, err.errors, id);
        }
    };

    const _checkDescendingValue = React.useCallback(
        async (stateValue: Value, propsValue: Value): Promise<void> => {
            if (stateValue !== propsValue) {
                setVal(propsValue);
                setErr(null);
                result(propsValue, null, id);
                if (didRevalidateSchema(propsValue) || required) {
                    _checkValidationSchema(propsValue);
                    setDisplayErr(true);
                }
            }
        },
        [setVal, setErr, result]
    );

    const _checkDescendingErrors = React.useCallback(
        async (stateError: null | string[], propsError: null | string[]): Promise<void> => {
            if (match(stateError, propsError) === false) {
                setErr(propsError);
                result(val, propsError, id);
                setDisplayErr(true);
            }
        },
        [setVal, setErr, result, val]
    );

    React.useEffect(() => {
        _checkDescendingValue(val, value);
        if (errors !== undefined) {
            _checkDescendingErrors(err, errors);
        }
    }, [value, errors]);

    React.useEffect(() => {
        setDisplayErr(displayError ?? false);
    }, [displayError]);

    React.useEffect(() => {
        if (required) {
            _checkValidationSchema(val);
        }
    }, [required]);

    const handleOnChange = React.useCallback(
        (e: ChangeEvent): void => {
            const value = mapValue(e);
            setVal(value);
            setErr(null);
            setDisplayErr(false);
            result(value, null, id);
        },
        [setVal, setErr, result, id]
    );

    const handleBlur = React.useCallback(async (): Promise<void> => {
        if (didRevalidateSchema(val) || required) {
            _checkValidationSchema(val);
            setDisplayErr(true);
        }
    }, [schema, val, setErr, result, id]);

    return {
        val,
        err,
        handleOnChange,
        handleBlur,
        displayErr,
        setDisplayErr,
        setVal,
        setErr
    };
}
