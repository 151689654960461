import React from 'react';
import styled from 'styled-components';

import { AdminRo, CardErrors } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import RelationAutocomplete from '../common/RelationAutocomplete';

export type CustomerAdminProps = {
    readOnly?: boolean;
    value?: CustomerAdminState;
    id: string;
    result: (value: CustomerAdminState, errors: null | string[] | CardErrors<CustomerAdminState>, id: string) => void;
    displayError?: boolean;
};

export type CustomerAdminState = {
    admin?: AdminRo;
};

export type CustomerAdminErrorState = CardErrors<CustomerAdminState>;

export const initialState: CustomerAdminState = {
    admin: undefined
};

export const initialErrorState: CustomerAdminErrorState = generateInitialErrorState(initialState);

export const CustomerAdmin = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: CustomerAdminProps) => {
    const [val, setVal] = React.useState<CustomerAdminState>(value);
    const [err, setErr] = React.useState<CustomerAdminErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    React.useEffect(() => {
        if (readOnly) return;

        const error = {
            admin: val.admin ? null : ['Le commercial est requis']
        };
        setErr(error);
    }, []);

    const handleChange = (value: AdminRo | undefined, errors: string[] | null, childId: string) => {
        setVal((prev) => {
            const state = { ...prev, [childId]: value };
            return state;
        });
        setErr((prev) => {
            const state = { ...prev, [childId]: errors };
            return state;
        });
    };

    return (
        <StyledCard title="Responsable commercial">
            <RelationAutocomplete.Admin
                id="admin"
                label="Commercial associé *"
                baseValue={val.admin}
                readOnly={readOnly}
                onSelect={(value) => {
                    handleChange(value, null, 'admin');
                }}
                onUnselect={() => {
                    handleChange(undefined, ['Le commercial est requis'], 'admin');
                }}
            />
            {displayError && (err.admin ?? []).length > 0 && (
                <Errors>
                    {(err.admin ?? []).map((e, i) => (
                        <li key={e + i}>{e}</li>
                    ))}
                </Errors>
            )}
        </StyledCard>
    );
};

const StyledCard = styled(Card)`
    .p-card-content > div {
        width: 100%;
    }
`;

const Errors = styled.div`
    color: #d22020;
`;
