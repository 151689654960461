// import rfdc from 'rfdc';

/**
 * Stringify a data in a readable way, with indents and line break
 *
 * @param json Json to beautify
 * @return Printable string
 */
export function beautifyJson(json: any) {
    return JSON.stringify(json, null, 2);
}

/**
 * Sort keys of a JSON alphabetically
 *
 * @param json Json to sort
 * @return Sorted json
 */
export function sortJsonKeys(json: Record<string, any>) {
    return JSON.parse(JSON.stringify(json, Object.keys(json).sort()));
}

/**
 * Check if the str/date is ISO date
 */
const isIsoDate = (str: string) => {
    if (
        !/^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/.test(
            str
        )
    )
        return false;
    const date: Date = new Date(str);
    return date.toISOString() === str;
};

/**
 * JSON.parse doesn't convert dates to Date type (yeah, it's insane...)
 * Use parseJsonWithDates to correctly have Dates deserialized
 *
 * @param str JSON stringifies
 * @return JSON deserialized, including dates
 */
export function parseJsonWithDates(str: string) {
    const handleDates = (key: string, val: any) => {
        const valueIsIsoDate = isIsoDate(val);
        if (valueIsIsoDate) {
            return new Date(val).toISOString();
        }
        return val;
    };
    return JSON.parse(str, handleDates);
}

/**
 * Return a deep copy of the json passed as parameter
 *
 * @param json Json to copy
 * @return Deep copy of the json
 */
export const deepCopy = <T>(json: T): T => {
    return structuredClone(json);
};
