import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { useState } from 'react';
import styled from 'styled-components';

import { formatPhoneE164ToPhoneValue, formatPhoneNumber } from '@bbng/util/misc';
import { EPlanningRegion, EPlanningType, IOpeningDay, LandfillRo } from '@bbng/util/types';

import { mapPlanningConfigRegion, mapPlanningConfigType } from '../../common/enumMapper';
import { LANDFILL_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';

const StyledDropdown = styled(Dropdown)`
    width: 250px;
    height: 44px;
    & + & {
        margin-left: 20px;
    }
`;

const Anchor = styled.a`
    word-break: break-all;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #343699;
`;

type QueryParams = {
    region?: EPlanningRegion;
    collect_type?: EPlanningType;
};

export const LandfillsListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [region, setRegion] = useState<EPlanningRegion | undefined>(query.region);
    const [collectType, setCollectType] = useState<EPlanningType | undefined>(query.collect_type);

    return (
        <Listing<any, LandfillRo>
            url={LANDFILL_ORCHESTRATION_BASE_URL}
            addButtonUrl={urlBuilder.landfillCreate()}
            endpoint="landfills"
            name="Déchetterie partenaire"
            pluralName="Déchetteries partenaires"
            queryParams={{
                region,
                collect_type: collectType
            }}
            leftHandedComponent={
                <div>
                    <StyledDropdown
                        value={region}
                        options={Object.values(EPlanningRegion)}
                        itemTemplate={(option) => mapPlanningConfigRegion(option)}
                        valueTemplate={(option) => (option ? mapPlanningConfigRegion(option) : 'Filtrer par région')}
                        onChange={(e) => setRegion(e.value)}
                        placeholder="Filtrer par région"
                        showClear
                    />
                    <StyledDropdown
                        value={collectType}
                        options={Object.values(EPlanningType)}
                        itemTemplate={(option) => mapPlanningConfigType(option)}
                        valueTemplate={(option) => (option ? mapPlanningConfigType(option) : 'Accepte BB et bennes')}
                        onChange={(e) => setCollectType(e.value)}
                        placeholder="Accepte BB et bennes"
                        showClear
                    />
                </div>
            }
            headers={[
                {
                    name      : 'Nom',
                    field     : 'id',
                    component : (data: LandfillRo) => <a href={urlBuilder.landfillView(data.id)}>{data.name}</a>
                },
                {
                    name      : 'Adresse',
                    field     : 'address',
                    component : (data: LandfillRo) => <p>{data.address.formatted_name}</p>
                },
                {
                    name      : 'Horaires matin',
                    field     : 'opening_time',
                    width     : 180,
                    component : (data: LandfillRo) => (
                        <div>
                            {Object.entries(data.opening_time).map(([key, v]) => {
                                const value = v as IOpeningDay;
                                const weekDay = moment()
                                    .day((parseInt(key) + 1) % 7)
                                    .format('dd');
                                if (value.is_closed) {
                                    return <div>{weekDay} : Fermé</div>;
                                }
                                return (
                                    <div>
                                        {weekDay}: {moment(value.morning_open).format('HH:mm')} -{' '}
                                        {moment(value.morning_close).format('HH:mm')}
                                    </div>
                                );
                            })}
                        </div>
                    )
                },
                {
                    name      : 'Horaires après-midi',
                    field     : 'opening_time',
                    width     : 180,
                    component : (data: LandfillRo) => (
                        <div>
                            {Object.entries(data.opening_time).map(([key, v]) => {
                                const value = v as IOpeningDay;
                                const weekDay = moment()
                                    .day((parseInt(key) + 1) % 7)
                                    .format('dd');
                                if (value.is_closed) {
                                    return <div>{weekDay} : Fermé</div>;
                                }
                                return (
                                    <div>
                                        {weekDay}: {moment(value.afternoon_open).format('HH:mm')} -{' '}
                                        {moment(value.afternoon_close).format('HH:mm')}
                                    </div>
                                );
                            })}
                        </div>
                    )
                },
                {
                    name      : 'Téléphone',
                    field     : 'phone_number',
                    width     : 180,
                    component : (data: LandfillRo) => (
                        <Anchor
                            href={
                                data.phone_number
                                    ? 'tel:' + formatPhoneNumber(formatPhoneE164ToPhoneValue(data.phone_number))
                                    : undefined
                            }
                        >
                            <i className="pi pi-phone" />
                            {data.phone_number ? formatPhoneNumber(formatPhoneE164ToPhoneValue(data.phone_number)) : ''}
                        </Anchor>
                    )
                }
            ]}
        />
    );
};
