import { ConstructionSiteRo } from '@bbng/util/types';
import moment from 'moment';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { Card } from '../../components/Card';
import { useRequest } from '../../hooks/StatelessRequest';

export type UserConstructionSitesProps = {
    userId: string;
};

export const UserConstructionSites: React.FC<UserConstructionSitesProps> = ({ userId }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState<Array<ConstructionSiteRo>>();
    const [error, setError] = React.useState<string>();

    const bbngRequest = useRequest();

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const res = await bbngRequest<Array<ConstructionSiteRo>>({
                method : 'GET',
                url    : urlApiBuilder.constructionSiteGetAllByUser(userId)
            });
            if (res.success) {
                setData(res?.response?.data?.ro);
            } else {
                setError(res?.error?.message);
            }
        } catch (e) {
            setError('Une erreur est survenue');
        }
        setIsLoading(false);
    };

    React.useEffect(() => {
        fetchData();
    }, [userId]);

    return (
        <StyledCard title="Chantiers créés">
            {isLoading && (
                <div style={{ width: '100%' }}>
                    <ProgressBar mode="indeterminate" />
                </div>
            )}
            {error && <div>{error}</div>}
            {data && (
                <table style={{ width: '100%' }}>
                    <thead style={{ backgroundColor: '#00000033' }}>
                        <tr>
                            <th>Chantier</th>
                            <th>Adresse</th>
                            <th>Crée le</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((cs, index) => (
                            <tr key={cs.id} style={{ backgroundColor: index % 2 === 0 ? '#00000005' : '#00000010' }}>
                                <td className="cs-label">
                                    <Link to={urlBuilder.constructionSiteView(cs.id)}>{cs.label}</Link>
                                </td>
                                <td className="cs-formated-address">{cs.address.formatted_name}</td>
                                <td className="cs-created-at">{moment(cs.created_at).format('DD/MM/YYYY HH:mm')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </StyledCard>
    );
};

const StyledCard = styled(Card)`
    th {
        padding: 10px;
    }
    td {
        padding: 10px;
    }
    td.cs-created-at {
        text-align: center;
    }
`;
