import Joi from 'joi';

export const DumpsterOnSiteCreateDtoSchema = Joi.object({
    deposited_at : Joi.date().iso().required(),
    retrieved_at : Joi.date().iso().allow(null),
    comment      : Joi.string().optional().allow(''),

    product_id           : Joi.string().required(),
    construction_site_id : Joi.string().required(),
    deposit_collect_id   : Joi.string().optional(),
    retrieval_collect_id : Joi.string().optional()
});

export const DumpsterOnSiteEditDtoSchema = Joi.object({
    deposited_at : Joi.date().iso(),
    retrieved_at : Joi.date().iso().allow(null),
    comment      : Joi.string().optional().allow(''),

    product_id           : Joi.string(),
    construction_site_id : Joi.string(),
    deposit_collect_id   : Joi.string().optional(),
    retrieval_collect_id : Joi.string().optional()
});

export const DumpsterOnSiteSynchronizeDtoSchema = Joi.object({
    dumpsters: Joi.array()
        .items(
            Joi.object({
                deposited_at                : Joi.date().iso().required(),
                product                     : Joi.object().required(),
                construction_site_id        : Joi.string().required(),
                customer_id                 : Joi.string().required(),
                deposit_collect_id          : Joi.string().required(),
                retrieval_collect_config_id : Joi.array().items(Joi.string()).optional()
            })
        )
        .required()
});
