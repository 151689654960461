import Dinero, { Currency } from 'dinero.js';
import moment from 'moment';

import {
    DiscountRo,
    EDiscountType,
    EDiscountUnit,
    ETrashType,
    ISODate,
    NdlssPrice,
    PRODUCT_FAMILY,
    ProductInCCOrCO
} from '@bbng/util/types';

export type CalculatePriceParamaters = {
    customerId: string;
    date: ISODate;
    products: ProductInCCOrCO[];
    zoneId: string;
    constructionSiteId?: string;
    discounts: DiscountRo[];
};
export const calculatePrice = (data: CalculatePriceParamaters): NdlssPrice => {
    const { customerId, discounts, products } = data;

    const bbDiscounts = discounts.filter((d) => d.type === EDiscountType.BIG_BAG);
    const dumpsterDiscounts = discounts.filter((d) => d.type === EDiscountType.DUMPSTER);
    const deliveryDiscounts = discounts.filter((d) => d.type === EDiscountType.DELIVERY);

    const sortedProducts = products.reduce(
        (acc, prd) => {
            if (prd.family === PRODUCT_FAMILY.DELIVERY_BIG_BAG) {
                acc.delivery.push(prd);
            } else if (prd.family.includes('DUMPSTER')) {
                acc.dumpster.push(prd);
            } else {
                acc.bb.push(prd);
            }
            return acc;
        },
        {
            bb       : [] as ProductInCCOrCO[],
            dumpster : [] as ProductInCCOrCO[],
            delivery : [] as ProductInCCOrCO[]
        }
    );

    /**
     * Utility functions
     */
    const _checkDate = (evacDate: ISODate, start: ISODate, end?: ISODate) => {
        const date = {
            evac  : moment.utc(evacDate),
            start : moment.utc(start),
            end   : end ? moment.utc(end) : undefined
        };

        if (end === undefined) return date.evac.isAfter(date.start);
        return date.evac.isBetween(date.start, date.end, 'hours');
    };
    const _checkZone = (zoneId: string, discountZoneIds: string[]) => {
        if (discountZoneIds.length === 0) return true;
        return discountZoneIds.includes(zoneId);
    };
    const _checkConstructionSite = (csId: string, discountCsIds: string[]) => {
        if (discountCsIds.length === 0) return true;
        return discountCsIds.includes(csId);
    };
    const _checkCustomer = (customerId: string, discountCustomerIds: string[]) => {
        return discountCustomerIds.includes(customerId);
    };
    const _checkVolume = (volume: number, minVolume: number, maxVolume: number) => {
        return volume >= minVolume && volume <= maxVolume;
    };
    const _checkTrashType = (trashType: ETrashType, discountTrashTypes: ETrashType[]) => {
        if (discountTrashTypes.length === 0) return true;
        return discountTrashTypes.includes(trashType);
    };

    /**
     * DELIVERY APPLICABLE DISCOUNTS
     */
    const deliveryApplicableDiscounts = () => {
        return sortedProducts.delivery.reduce(
            (acc, prd, idx) => {
                acc[idx] = {
                    prdDelivery         : prd,
                    applicableDiscounts : []
                };
                deliveryDiscounts.forEach((discount) => {
                    _checkDate(data.date, discount.start_date, discount.end_date) &&
                        _checkZone(data.zoneId, discount.zone_id ?? []) &&
                        _checkConstructionSite(data.constructionSiteId ?? '', discount.construction_site_id ?? []) &&
                        _checkCustomer(customerId, discount.customer_id ?? []) &&
                        _checkVolume(prd.volume_m3, discount.min_volume, discount.max_volume) &&
                        // _checkTrashType(prd.trash_type, discount.trash_type ?? []) &&
                        acc[idx].applicableDiscounts.push(discount);
                });
                return acc;
            },
            [] as {
                prdDelivery: ProductInCCOrCO;
                applicableDiscounts: DiscountRo[];
            }[]
        );
    };

    /**
     * DUMPSTER APPLICABLE DISCOUNTS
     */
    const dumpsterApplicableDiscounts = () => {
        return sortedProducts.dumpster.reduce(
            (acc, prd, idx) => {
                acc[idx] = {
                    prdDumpster         : prd,
                    applicableDiscounts : []
                };
                dumpsterDiscounts.forEach((discount) => {
                    _checkDate(data.date, discount.start_date, discount.end_date) &&
                        _checkZone(data.zoneId, discount.zone_id ?? []) &&
                        _checkConstructionSite(data.constructionSiteId ?? '', discount.construction_site_id ?? []) &&
                        _checkCustomer(customerId, discount.customer_id ?? []) &&
                        _checkVolume(prd.volume_m3, discount.min_volume, discount.max_volume) &&
                        _checkTrashType(prd.trash_type, discount.trash_type ?? []) &&
                        acc[idx].applicableDiscounts.push(discount);
                });
                return acc;
            },
            [] as {
                prdDumpster: ProductInCCOrCO;
                applicableDiscounts: DiscountRo[];
            }[]
        );
    };

    /**
     * BIG BAG APPLICABLE DISCOUNTS
     */
    const bbApplicableDiscounts = () => {
        const sortedGlobalVolume = sortedProducts.bb.reduce(
            (acc, prd) => {
                acc[prd.trash_type] = acc[prd.trash_type] ?? {
                    volume_m3 : 0,
                    prdsBb    : []
                };

                acc[prd.trash_type] = {
                    volume_m3 : (acc[prd.trash_type].volume_m3 ?? 0) + prd.volume_m3 * prd.quantity,
                    prdsBb    : [...(acc[prd.trash_type].prdsBb ?? []), prd]
                };
                return acc;
            },
            {} as Record<
                ETrashType,
                {
                    volume_m3: number;
                    prdsBb: ProductInCCOrCO[];
                }
            >
        );

        return Object.entries(sortedGlobalVolume).reduce(
            (acc, [trashType, info], idx) => {
                acc[idx] = {
                    prdsBB              : info.prdsBb,
                    applicableDiscounts : []
                };

                acc[idx].prdsBB = info.prdsBb;
                bbDiscounts.forEach((discount) => {
                    //A discount can be applicable to multiple trash types
                    const volume = Math.max(
                        (discount.trash_type ?? []).reduce((acc, trashType) => {
                            return acc + (sortedGlobalVolume[trashType]?.volume_m3 ?? 0);
                        }, 0),
                        info.volume_m3
                    );
                    if (
                        _checkDate(data.date, discount.start_date, discount.end_date) &&
                        _checkZone(data.zoneId, discount.zone_id ?? []) &&
                        _checkConstructionSite(data.constructionSiteId ?? '', discount.construction_site_id ?? []) &&
                        _checkCustomer(data.customerId, discount.customer_id ?? []) &&
                        _checkVolume(volume, discount.min_volume, discount.max_volume) &&
                        _checkTrashType(trashType as ETrashType, discount.trash_type ?? [])
                    ) {
                        acc[idx].applicableDiscounts.push(discount);
                    }
                });
                return acc;
            },
            [] as {
                prdsBB: ProductInCCOrCO[];
                applicableDiscounts: DiscountRo[];
            }[]
        );
    };

    /**
     * @description
     * Format price per product in NdlssPrice
     * @param prd ProductInCCOrCO
     * @param discount DiscountRo
     * @returns NdlssPrice['price_per_product'][0]
     */
    const formatPricePerProduct = (
        prd: ProductInCCOrCO,
        discount: DiscountRo | null
    ): NdlssPrice['price_per_product'][0] => {
        const price = Dinero({
            amount    : prd.price.net_amount_cents,
            currency  : prd.price.currency,
            precision : 2
        }).multiply(prd.quantity);
        const vatRate = prd.vat_rate_percentage;

        const base_net_amount = price;
        const base_vat_amount = price.percentage(vatRate, 'HALF_UP');
        const base_total_amount = base_net_amount.add(base_vat_amount);

        let discounted_net_amount = base_net_amount;
        let discounted_vat_amount = base_vat_amount;
        let discounted_total_amount = base_total_amount;
        if (discount !== null) {
            if (discount.unit === EDiscountUnit.AMOUNT) {
                const discountValue = Dinero({
                    amount: Dinero({ amount: Math.round(discount.value * 100), currency: prd.price.currency })
                        .multiply(prd.volume_m3)
                        .multiply(prd.quantity)
                        .getAmount(),
                    currency  : prd.price.currency,
                    precision : 2
                });
                discounted_net_amount = base_net_amount.subtract(discountValue);
                discounted_vat_amount = discounted_net_amount.percentage(vatRate, 'HALF_UP');
                discounted_total_amount = discounted_net_amount.add(discounted_vat_amount);
            } else if (discount.unit === EDiscountUnit.PERCENT) {
                const discountValue = Dinero({
                    amount    : price.percentage(Math.abs(discount.value), 'DOWN').getAmount(),
                    currency  : prd.price.currency,
                    precision : 2
                });
                discounted_net_amount = base_net_amount.subtract(discountValue);
                discounted_vat_amount = discounted_net_amount.percentage(vatRate, 'HALF_UP');
                discounted_total_amount = discounted_net_amount.add(discounted_vat_amount);
            }
        }

        return {
            product_id       : prd.id,
            base_net         : positivePriceFormatter(base_net_amount.toObject()),
            base_vat         : positivePriceFormatter(base_vat_amount.toObject()),
            base_total       : positivePriceFormatter(base_total_amount.toObject()),
            discounted_net   : positivePriceFormatter(discounted_net_amount.toObject()),
            discounted_vat   : positivePriceFormatter(discounted_vat_amount.toObject()),
            discounted_total : positivePriceFormatter(discounted_total_amount.toObject()),
            applied_discount : discount
        };
    };

    const positivePriceFormatter = (price: Dinero.DineroObject): Dinero.DineroObject => {
        return {
            ...price,
            amount: price.amount > 0 ? price.amount : 0
        };
    };

    const getMostAppropriateDiscount = (
        productPrice: Dinero.Dinero,
        discounts: DiscountRo[],
        volume_m3: number,
        currency: Currency
    ): DiscountRo | null => {
        let discountedPrice = productPrice;
        const hasNegativeDiscounts = discounts.some((discount) => discount.value < 0);

        return (
            discounts
                .map((discount) => {
                    /**
                     * Do not forget that the value of a discount is /m3 !!!
                     */
                    const discountValue = Dinero({
                        amount    : Math.round(discount.value * 100),
                        currency  : currency,
                        precision : 2
                    }).multiply(volume_m3);
                    let calculatedPrice: Dinero.Dinero;
                    if (discount.unit === EDiscountUnit.AMOUNT) {
                        calculatedPrice = productPrice.subtract(discountValue);
                    } else {
                        /**
                         * As percentage method of Dinero only accepts values [0-100],
                         * we need to handle the case when the percentage is > 100
                         */
                        const percentage = 100 - discount.value;
                        if (percentage <= 100) {
                            calculatedPrice = productPrice.percentage(percentage, 'DOWN');
                        } else {
                            /**
                             * Add the difference between 100 and the percentage to the price
                             * Example: price * 110% = price + price * (110 - 100)% = price + price * 10%
                             */
                            const toAdd = productPrice.percentage(percentage - 100, 'DOWN');
                            calculatedPrice = productPrice.add(toAdd);
                        }
                    }
                    if (
                        hasNegativeDiscounts
                            ? calculatedPrice.greaterThan(discountedPrice)
                            : calculatedPrice.lessThan(discountedPrice)
                    ) {
                        discountedPrice = calculatedPrice;
                        return discount;
                    }
                    return undefined;
                })
                .filter((discount): discount is DiscountRo => discount !== undefined)[0] ?? null
        );
    };

    const priceDelivery = (): NdlssPrice['price_per_product'] => {
        const info = deliveryApplicableDiscounts();

        const bestDiscountByPrd = info.reduce(
            (acc, { prdDelivery, applicableDiscounts }, idx) => {
                acc[idx] = {
                    prd      : prdDelivery,
                    discount : null
                };

                const productPrice = Dinero({
                    amount    : prdDelivery.price.net_amount_cents,
                    currency  : prdDelivery.price.currency,
                    precision : 2
                });
                acc[idx].discount = getMostAppropriateDiscount(
                    productPrice,
                    applicableDiscounts,
                    prdDelivery.volume_m3,
                    prdDelivery.price.currency
                );
                return acc;
            },
            [] as {
                prd: ProductInCCOrCO;
                discount: DiscountRo | null;
            }[]
        );

        return bestDiscountByPrd.map((item) => {
            return formatPricePerProduct(item.prd, item.discount);
        });
    };

    const priceDumpster = (): NdlssPrice['price_per_product'] => {
        const info = dumpsterApplicableDiscounts();

        const bestDiscountByPrd = info.reduce(
            (acc, { prdDumpster, applicableDiscounts }, idx) => {
                acc[idx] = {
                    prd      : prdDumpster,
                    discount : null
                };

                const productPrice = Dinero({
                    amount    : prdDumpster.price.net_amount_cents,
                    currency  : prdDumpster.price.currency,
                    precision : 2
                });

                acc[idx].discount = getMostAppropriateDiscount(
                    productPrice,
                    applicableDiscounts,
                    prdDumpster.volume_m3,
                    prdDumpster.price.currency
                );
                return acc;
            },
            [] as {
                prd: ProductInCCOrCO;
                discount: DiscountRo | null;
            }[]
        );

        return bestDiscountByPrd.map((item) => {
            return formatPricePerProduct(item.prd, item.discount);
        });
    };

    const priceBB = (): NdlssPrice['price_per_product'] => {
        const info = bbApplicableDiscounts();

        const bestDiscountByPrds = info.reduce(
            (acc, { prdsBB, applicableDiscounts }, idx) => {
                acc[idx] = {
                    prds     : prdsBB,
                    discount : null
                };

                const productsPrice = prdsBB.reduce((acc2, prd) => {
                    return acc2.add(
                        Dinero({ amount: prd.price.net_amount_cents, currency: prd.price.currency, precision: 2 })
                    );
                }, Dinero({ amount: 0, currency: prdsBB[0].price.currency, precision: 2 }));

                const productsVolume = prdsBB.reduce((acc2, prd) => (acc2 += prd.volume_m3 * prd.quantity), 0);

                const tmp = getMostAppropriateDiscount(
                    productsPrice,
                    applicableDiscounts,
                    productsVolume,
                    prdsBB[0].price.currency
                );
                acc[idx].discount = tmp;
                return acc;
            },
            [] as {
                prds: ProductInCCOrCO[];
                discount: DiscountRo | null;
            }[]
        );

        return bestDiscountByPrds.reduce((acc, item) => {
            const info = item.prds.map((prd) => formatPricePerProduct(prd, item.discount));
            return acc.concat(info);
        }, [] as NdlssPrice['price_per_product']);
    };

    return [...priceDelivery(), ...priceDumpster(), ...priceBB()].reduce(
        (acc, pricePerProduct) => {
            const base_net = Dinero({
                amount   : pricePerProduct.base_net.amount > 0 ? pricePerProduct.base_net.amount : 0,
                currency : pricePerProduct.base_net.currency as Currency
            });
            const base_vat = Dinero({
                amount   : pricePerProduct.base_vat.amount > 0 ? pricePerProduct.base_vat.amount : 0,
                currency : pricePerProduct.base_vat.currency as Currency
            });
            const base_total = Dinero({
                amount   : pricePerProduct.base_total.amount > 0 ? pricePerProduct.base_total.amount : 0,
                currency : pricePerProduct.base_total.currency as Currency
            });
            const discounted_net = Dinero({
                amount   : pricePerProduct.discounted_net.amount > 0 ? pricePerProduct.discounted_net.amount : 0,
                currency : pricePerProduct.discounted_net.currency as Currency
            });
            const discounted_vat = Dinero({
                amount   : pricePerProduct.discounted_vat.amount > 0 ? pricePerProduct.discounted_vat.amount : 0,
                currency : pricePerProduct.discounted_vat.currency as Currency
            });
            const discounted_total = Dinero({
                amount   : pricePerProduct.discounted_total.amount > 0 ? pricePerProduct.discounted_total.amount : 0,
                currency : pricePerProduct.discounted_total.currency as Currency
            });

            acc.base_net = Dinero({ amount: acc.base_net.amount, currency: acc.base_net.currency as Currency })
                .add(base_net)
                .toObject();
            acc.base_vat = Dinero({ amount: acc.base_vat.amount, currency: acc.base_vat.currency as Currency })
                .add(base_vat)
                .toObject();
            acc.base_total = Dinero({ amount: acc.base_total.amount, currency: acc.base_total.currency as Currency })
                .add(base_total)
                .toObject();
            acc.discounted_net = Dinero({
                amount   : acc.discounted_net.amount,
                currency : acc.discounted_net.currency as Currency
            })
                .add(discounted_net)
                .toObject();
            acc.discounted_vat = Dinero({
                amount   : acc.discounted_vat.amount,
                currency : acc.discounted_vat.currency as Currency
            })
                .add(discounted_vat)
                .toObject();
            acc.discounted_total = Dinero({
                amount   : acc.discounted_total.amount,
                currency : acc.discounted_total.currency as Currency
            })
                .add(discounted_total)
                .toObject();
            if (pricePerProduct.applied_discount !== null) {
                if (acc.applied_discounts.some((dis) => dis.id === pricePerProduct.applied_discount?.id) === false) {
                    acc.applied_discounts.push(pricePerProduct.applied_discount);
                }
            }
            acc.price_per_product.push(pricePerProduct);

            return acc;
        },
        {
            base_net: {
                amount   : 0,
                currency : 'EUR'
            },
            base_vat: {
                amount   : 0,
                currency : 'EUR'
            },
            base_total: {
                amount   : 0,
                currency : 'EUR'
            },
            discounted_net: {
                amount   : 0,
                currency : 'EUR'
            },
            discounted_vat: {
                amount   : 0,
                currency : 'EUR'
            },
            discounted_total: {
                amount   : 0,
                currency : 'EUR'
            },
            applied_discounts : [],
            price_per_product : []
        } as NdlssPrice
    );
};

export const formatCouponName = (discounts: DiscountRo[]): string => {
    //Make discount type unique
    const uniqueDiscounts = discounts.reduce((acc, discount) => {
        if (acc.find((d) => d.type === discount.type)) {
            return acc;
        }
        return [...acc, discount];
    }, [] as DiscountRo[]);

    return uniqueDiscounts
        .map((discount) => {
            switch (discount.type) {
                case EDiscountType.BIG_BAG:
                    return 'collectes bigbags';
                case EDiscountType.DELIVERY:
                    return 'sacs';
                case EDiscountType.DUMPSTER:
                    return 'collectes bennes';
            }
        })
        .join('|');
};
