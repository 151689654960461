export type TEmailZohoInvoiceEndOfMonthParams = {
    invoiceNumber: string;
    extraInfo: string;
    paymentInfo: string;
};

export const emailZohoInvoiceEndOfMonthTemplate = ({
    invoiceNumber,
    extraInfo,
    paymentInfo
}: TEmailZohoInvoiceEndOfMonthParams) => `
<table class="es-content" cellspacing="0" cellpadding="0" align="center"
    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
    <tr>
        <td align="center" style="padding:0;Margin:0">
            <table class="es-content-body" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:600px">
                <tr>
                    <td align="left" style="padding:0;Margin:0;background-color:#fff">
                        <table width="100%" cellspacing="0" cellpadding="0"
                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                                <td valign="top" align="center"
                                    style="padding:32px 0 0;Margin:0;width:600px">
                                    <table width="100%" cellspacing="0" cellpadding="0"
                                        role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Bonjour,
                                                    </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Nous avons effectué récemment pour votre compte des évacuations de déchets et nous vous remercions pour votre confiance.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Veuillez trouver ci-joint la facture ${invoiceNumber} correspondante.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Merci de nous adresser le règlement par chèque ou virement aux coordonnées suivantes:
                                                </p>
                                                ${paymentInfo}
                                            </td>
                                        </tr>
                                        ${extraInfo}
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:10px;padding-bottom:10px;padding-left:25px;padding-right:25px">
                                                <p
                                                style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px;background-color:#D4FAE6;padding:10px;border-radius:4px;">
                                                    <span style="font-weight:bold;">NOUVEAU :</span> Retrouvez vos données de valorisation, vos BSD et vos factures, et planifiez vos prochaines évacuations sur
                                                    <a target="_blank"
                                                        style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#5465fc;font-size:16px;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#147341;"
                                                        href="https://my.endless.fr"
                                                    >votre espace client MY ENDLESS</a>.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;margin-top:16px;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Merci pour votre confiance et à bientôt sur vos chantiers !
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
`;

export enum EEmailZohoInvoiceBsdLinkTemplate {
    MONTH_AND_YEAR = '{{MONTH_AND_YEAR}}',
    BSD_LINK = '{{BSD_LINK}}'
}

export const emailZohoInvoiceBsdLinkTemplate = ``;
// <tr>
//     <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
//         style="Margin:0;padding-top:5px;padding-bottom:15px;padding-left:25px;padding-right:25px">
//         <p
//             style="Margin:16px 0 0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
//             Vous pouvez également accéder à vos bordereaux de suivi de déchets (BSD) du mois de {{MONTH_AND_YEAR}}
//             <a target="_blank"
//                 style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:none;color:#5465fc;font-size:16px;font-family:arial, 'helvetica neue', helvetica, sans-serif"
//                 href="{{BSD_LINK}}"
//             >en cliquant ici</a>.
//     </td>
// </tr>
// `;

export type TEmailZohoInvoicePaymentInfoParams = {
    companyName: string;
    ribs: {
        iban: string;
        bic: string;
        bank: string;
    }[];
};

export const emailZohoInvoicePaymentInfo = ({ companyName, ribs }: TEmailZohoInvoicePaymentInfoParams) => `
<p
    style="padding-top:15px;Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;font-size:16px">
    <strong style="font-size: 18px; margin-bottom: 24px;">${companyName}</strong>
    ${ribs
        .map(
            ({ iban, bic, bank }) => `
            <div style="margin-bottom: 16px">
                <span style="font-size:18px">${bank}</span>
                <br>
                <i>IBAN:</i> <strong style="font-size:18px">${iban}</strong>
                <br>
                <i>BIC:</i> <strong style="font-size:18px">${bic}</strong>
            </div>
        `
        )
        .join('')}
</p>
`;
