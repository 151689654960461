import axios, { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';

import { CredentialsManager } from './credentialsManager';

export const axiosClient = axios.create({});
export const credentialsManager = new CredentialsManager();

axiosClient.interceptors.request.use(async (config) => {
    const access_token: string = await credentialsManager.getAccessToken();

    if (access_token) {
        config.headers ??= {};
        config.headers['Authorization'] = `Bearer ${access_token}`;
    }
    return config;
});

axiosClient.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err: AxiosError) => {
        if (err.response?.status === StatusCodes.UNAUTHORIZED) {
            const refreshRes = await credentialsManager.refreshCredentials();

            if (!refreshRes) {
                if (window.location.pathname !== '/login') {
                    window.location.href = window.location.origin + '/login';
                }
                return;
            }
            err.config.headers ??= {};
            err.config.headers['Authorization'] = `Bearer ${refreshRes.accessToken}`;
            err.config.validateStatus = (status: number) => status !== 401;
            try {
                return axios(err.config);
            } catch (err) {
                return Promise.reject(err);
            }
        }
        return err;
    }
);
