import moment, { Moment } from 'moment';
import { Button } from 'primereact/button';
import React, { useState } from 'react';

import { ALLOW_GOD_MODE_KEY, CustomerCreateMissingGoogleDriverDto } from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import { useRequest } from '../../hooks/StatelessRequest';
import { MonthSelector } from '../../modules/month-selector';
import { Page, Header, ButtonsContainer } from './style';
import { confirmDialog } from 'primereact/confirmdialog';
import useLocalStorage from '../../hooks/LocalStorage';
import { toast } from '../../components/Toast';

export type GoogleDriveActionsPageState = {
    month: number;
    year: number;
};

enum EGDActions {
    CREATE_MISSING_DRIVES = 'CREATE_MISSING_DRIVES',
    CREATE_MISSING_MONTHS = 'CREATE_MISSING_MONTHS',
    CREATE_MISSING_CONS_SITE = 'CREATE_MISSING_CONS_SITE',
    UPLOAD_MISSING_BSD = 'UPLOAD_MISSING_BSD',
    SEND_BSD_EMAIL = 'SEND_BSD_EMAIL'
}

export const GoogleDriveActions: React.FC = (): JSX.Element => {
    const [lastMonth] = useState<Moment>(moment.utc().subtract(1, 'month'));
    const [val, setVal] = useState<GoogleDriveActionsPageState>({
        month : lastMonth.month(),
        year  : lastMonth.year()
    });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isGodMode] = useLocalStorage(ALLOW_GOD_MODE_KEY, false);
    const [mailNotSent, setMailNotSent] = useLocalStorage('MAIL_NOT_SENT', false);
    const [res, setRes] = useState<any>(null);

    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const createMissingDrives = React.useCallback(
        async (dto: GoogleDriveActionsPageState, action: EGDActions) => {
            setLoading(true);

            let url;
            switch (action) {
                case EGDActions.CREATE_MISSING_DRIVES:
                    url = urlApiBuilder.customerCreateMissingDrives();
                    break;
                case EGDActions.CREATE_MISSING_MONTHS:
                    url = urlApiBuilder.customerCreateMissingMonthFolder();
                    break;
                case EGDActions.CREATE_MISSING_CONS_SITE:
                    url = urlApiBuilder.customerCreateMissingConsSiteFolder();
                    break;
                case EGDActions.UPLOAD_MISSING_BSD:
                    url = urlApiBuilder.customerUploadMissingBsds();
                    break;
                case EGDActions.SEND_BSD_EMAIL:
                    url = urlApiBuilder.customerSendGoogleDriveEmails();
                    setMailNotSent(false);
                    break;
            }

            const response = await bbngRequest({
                method  : 'POST',
                url,
                options : { toastifySuccess: false },
                payload : {
                    body: {
                        month : dto.month,
                        year  : dto.year
                    } as CustomerCreateMissingGoogleDriverDto
                },
                sync         : false,
                timeout      : 1_000 * 60 * 60 * 6, // 6 hours
                retryPolling : 5_000
            });

            setRes(response.success ? response.response?.data.ro : response.error?.message);

            toast({
                severity : response.success ? 'success' : 'error',
                summary  : response.success ? 'Successful' : 'Error',
                detail   : response.success
                    ? `${response.response?.data.count} google drives created/updated`
                    : response.error?.message
            });

            setLoading(false);
        },
        [loading, setLoading, setMailNotSent, setRes]
    );

    const confirmAction = React.useCallback(
        async (action: EGDActions) => {
            confirmDialog({
                message : `Êtes-vous sûr de vouloir ${action} ?`,
                header  : 'Confirmation',
                icon    : 'pi pi-exclamation-triangle',
                accept  : async () => await createMissingDrives(val, action)
            });
        },
        [val, createMissingDrives]
    );

    if (!isGodMode) return <div>Whoopsie you are not allowed to be here...</div>;

    if (loading) return <PageLoader loading actionType={'read'} />;

    return (
        <Page>
            <Header>
                <MonthSelector value={val} setValue={setVal} label="Période désirée" />
            </Header>
            <ButtonsContainer>
                <Button
                    label="Générer les dossiers google drive manquant (DB + Google Drive)"
                    onClick={() => confirmAction(EGDActions.CREATE_MISSING_DRIVES)}
                />
                <Button
                    label="Générer les google drive de mois manquant (Google Drive)"
                    onClick={() => confirmAction(EGDActions.CREATE_MISSING_MONTHS)}
                    className="p-button-secondary"
                />
                <Button
                    label="Générer les google drive de chantiers manquant (Google Drive)"
                    onClick={() => confirmAction(EGDActions.CREATE_MISSING_CONS_SITE)}
                    className="p-button-warning"
                />
                <Button
                    label="Uploader les BSD manquant sur Google Drive"
                    onClick={() => confirmAction(EGDActions.UPLOAD_MISSING_BSD)}
                    className="p-button-help"
                />
            </ButtonsContainer>
            <Button
                label="Envoyer les BSD aux clients"
                onClick={() => confirmAction(EGDActions.SEND_BSD_EMAIL)}
                className="p-button-danger"
                disabled={!mailNotSent}
            />
            <div>{JSON.stringify(res)}</div>
        </Page>
    );
};
