import moment from 'moment';
import { CalendarChangeParams, Calendar as PRCalendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import React, { LegacyRef, useRef } from 'react';
import * as yup from 'yup';

import { match } from '@bbng/util/misc';
import { ISODate } from '@bbng/util/types';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import styled from 'styled-components';

export type CalendarMultipleDayMonthYearProps = {
    label?: string;
    readOnly: boolean;
    required: boolean;
    id: string;
    result: (value: ISODate[], errors: null | string[], id: string) => void;
    validationSchema?: yup.AnySchema;
    yearRange?: string;
    value?: ISODate[];
    errors?: string[] | null;
    displayError?: boolean;
    showMonths?: boolean;
    locale?: string;
    buttonLabel?: string;
};

const YEAR = moment.utc().format('YYYY');

export const CalendarMultipleDayMonthYear = ({
    label,
    readOnly,
    required,
    id,
    result,
    validationSchema,
    yearRange,
    value = [],
    errors,
    displayError,
    showMonths,
    locale = 'fr',
    buttonLabel = 'Sélectionner des dates'
}: CalendarMultipleDayMonthYearProps) => {
    const [val, setVal] = React.useState<ISODate[]>(value);
    const [err, setErr] = React.useState<null | string[]>(errors ?? null);
    const [displayErr, setDisplayErr] = React.useState<boolean>(displayError ?? false);
    const [visible, setVisible] = React.useState<boolean>(false);

    const baseValidationSchema = yup.array().of(yup.date());
    const schema = validationSchema
        ? validationSchema
        : required
        ? baseValidationSchema.required('Date requise')
        : baseValidationSchema;

    const _checkValidationSchema = (val: ISODate[], errVal: ISODate[]) => {
        try {
            schema.validateSync(val, { abortEarly: false });
        } catch (err) {
            setErr(err.errors);
            result(errVal, err.errors, id);
        }
    };

    const _checkDescendingValue = React.useCallback(
        async (stateValue: ISODate[], propsValue: ISODate[]): Promise<void> => {
            if (stateValue !== propsValue) {
                setVal(propsValue);
                setErr(null);
                result(propsValue, null, id);
                if (propsValue) {
                    _checkValidationSchema(propsValue, propsValue);
                    setDisplayErr(true);
                }
            }
        },
        [setVal, setErr, result]
    );

    const _checkDescendingErrors = React.useCallback(
        async (stateError: null | string[], propsError: null | string[]): Promise<void> => {
            if (match(stateError, propsError) === false) {
                setErr(propsError);
                result(val, propsError, id);
                setDisplayErr(true);
            }
        },
        [setVal, setErr, result]
    );

    React.useEffect(() => {
        _checkDescendingValue(val, value);
        if (errors !== undefined) {
            _checkDescendingErrors(err, errors);
        }
    }, [value, errors]);

    React.useEffect(() => {
        setDisplayErr(displayError ?? false);
    }, [displayError]);

    React.useEffect(() => {
        if (required) {
            _checkValidationSchema(val, val);
        }
    }, []);

    const handleOnChange = React.useCallback(
        (e: CalendarChangeParams): void => {
            const eventValue = e.value as Date[];
            const newValue =
                // this is needed to make sure the time is 12:00 of selected day in UTC
                eventValue
                    ?.sort((a, b) => a.getTime() - b.getTime())
                    ?.map((date) => moment.utc(moment(date).hours(12)).hours(12).toISOString()) || [];
            if (e.originalEvent.type === 'blur') return;
            setVal(newValue);
            setErr(null);
            result(newValue, null, id);
            setDisplayErr(false);
        },
        [setVal, setErr, result, id]
    );

    const handleBlur = React.useCallback(async (): Promise<void> => {
        if (val) {
            try {
                await schema.validate(val, { abortEarly: false });
            } catch (err) {
                setErr(err.errors);
                result([], err.errors, id);
            }
        }
        setDisplayErr(true);
    }, [schema, val, setErr, result, id]);

    return (
        <>
            <span className="p-float-label">
                <PRCalendar
                    inline
                    locale={locale}
                    id={id}
                    visible={visible}
                    onVisibleChange={() => setVisible(!visible)}
                    disabled={readOnly}
                    onChange={handleOnChange}
                    onBlur={handleBlur}
                    selectionMode="multiple"
                    monthNavigator
                    yearNavigator
                    yearRange={yearRange ? yearRange : `1850:${parseInt(YEAR) + 1}`}
                    monthNavigatorTemplate={monthNavigatorTemplate}
                    yearNavigatorTemplate={yearNavigatorTemplate}
                    value={val.map((valEntry) => moment(valEntry).toDate())}
                    view={showMonths ? 'month' : undefined}
                    tooltip={displayErr && err && err.length > 0 ? err.join('\n') : ''}
                    className={`${displayErr && err && err.length > 0 ? 'p-invalid' : ''}`}
                />
                {label && <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>}
            </span>
            <ChipContainer>
                {val.length > 0 ? (
                    val.map((date, index) => (
                        <StyledChip
                            key={date}
                            label={moment(date).format('DD/MM/YYYY')}
                            removable={!readOnly}
                            onRemove={() => {
                                const newValue = val.filter((_, i) => i !== index);
                                setVal(newValue);
                                setErr(null);
                                result(newValue, null, id);
                            }}
                        />
                    ))
                ) : (
                    <Chip label="Aucune date sélectionnée" />
                )}
            </ChipContainer>
        </>
    );
};

const monthNavigatorTemplate = (e: any) => {
    return (
        <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            style={{ lineHeight: 1 }}
        />
    );
};

const yearNavigatorTemplate = (e: any) => {
    return (
        <Dropdown
            value={e.value}
            options={e.options.reverse()}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            className="ml-2"
            style={{ lineHeight: 1 }}
        />
    );
};

const StyledChip = styled(Chip)`
    background-color: var(--blue-100);
`;

const ChipContainer = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
`;
