import { Method } from 'axios';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ColumnBodyType } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
    DataTableHeaderTemplateType,
    DataTableSelectionChangeParams,
    DataTableProps as PRDataTableProps
} from 'primereact/datatable';
import { DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { MenuItem, MenuItemCommandParams } from 'primereact/menuitem';
import { Skeleton } from 'primereact/skeleton';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { NdlssError } from '@bbng/util/error';
import { match, useDebounce } from '@bbng/util/misc';
import { IBbngResponse, IKafkaQuery, ObjectKeys } from '@bbng/util/types';

import usePaginateData from '../../hooks/PaginateData';
import useQuery from '../../hooks/Query';
import { RequestProps, useRequest } from '../../hooks/StatelessRequest';
import DataTable, { DataTableProps } from '../DataTable';
import { GifLoader } from '../GifLoader';
import { toast } from '../Toast';
import { ArchiveFilters } from './archiveFilters';
import { PageActions, PageContainer, PageHeader, RightHandedAction, Veil } from './listing.style';

interface Query {
    q: string;
}

interface ListingProps<R, QP = Record<string, any>> extends PRDataTableProps {
    method?: Method;
    url: string;
    addButtonUrl?: string;
    version?: string;
    endpoint: string;
    bulkActionsList?: (selectedRows: DataTableSelectionChangeParams['value']) => MenuItem[];
    name: string;
    pluralName: string;
    headers: DataTableProps<R>['headers'];
    actionRows?: MenuItem[];
    leftHandedComponent?: JSX.Element;
    queryParams?: IKafkaQuery & QP;
    showSearch?: boolean;
    displayActionColumn?: boolean;
    displayPaginator?: boolean;
    displaySelectColumn?: boolean;
    hideCreateButton?: boolean;
    hideBulkMenu?: boolean;
    disableArchiveAction?: boolean;
    disableEditAction?: boolean;
    title?: string;
    subtitle?: string;
    pageUrl?: string;
    setFetchedData?: (data: R[]) => void;
    triggerPageRefresh?: boolean;
    searchPlaceholder?: string;
    parseData?: (data: R[]) => R[];
    actionColumnField?: string;
    minSearchLength?: number;
    pageUrlSuffix?: (data: R) => string;
    displayBulkArchiveAction?: boolean;
    enrichData?: (data: IBbngResponse<R[]>) => Promise<IBbngResponse<R[]>>;
    displayArchiveFilters?: boolean;
    breakpoint?: `${number}px`;
    totalRecords?: number;
    displayArchiveColumn?: boolean;
}

export function Listing<QP = any, R = any>({
    method = 'GET',
    url,
    addButtonUrl,
    version = 'v1',
    endpoint,
    bulkActionsList = () => [],
    name,
    pluralName,
    headers,
    actionRows = [],
    leftHandedComponent = <React.Fragment />,
    queryParams,
    showSearch = true,
    displayActionColumn = true,
    displayPaginator = true,
    displaySelectColumn = true,
    hideCreateButton = false,
    hideBulkMenu = false,
    title = `Liste des ${pluralName}`,
    subtitle = `Tous les ${pluralName} de EndLess`,
    disableArchiveAction = false,
    disableEditAction = false,
    pageUrl,
    setFetchedData,
    triggerPageRefresh,
    searchPlaceholder = 'Rechercher',
    parseData,
    actionColumnField,
    minSearchLength = 3,
    pageUrlSuffix,
    displayBulkArchiveAction = true,
    enrichData,
    displayArchiveFilters = true,
    breakpoint,
    totalRecords,
    displayArchiveColumn = true,
    ...props
}: ListingProps<R, QP>): JSX.Element {
    /**
     * Check the URL for queryParams
     **/
    const { query } = useQuery<Query>();

    const [customQuery, setCustomQuery] = React.useState<Record<string, any>>({
        ...queryParams,
        q: query.q ?? ''
    });

    const { isMounting, isLoading, items, pagination, nextPage, previousPage, setLimit, pageRefresh } = usePaginateData(
        {
            endpoint          : `${url}/${version}/${endpoint}`,
            customQueryParams : customQuery,
            enrichData
        }
    );

    const [isArchiving, setIsArchiving] = React.useState(false);
    const [isUnarchiving, setIsUnarchiving] = React.useState(false);

    const request = useRequest();

    const navigate = useNavigate();
    const location = useLocation();
    const bulk = React.useRef<Menu>(null);
    /**
     * Check if there are queryParams related to request.
     **/
    const { q: urlQ } = query;

    /**
     * ⚠️ There is a difference of 1 between displayPage and the Query !
     **/
    const [search, setSearch] = React.useState(urlQ || '');
    const [displayErrorSearch, setDisplayErrorSearch] = React.useState(false);

    /**
     * Handle Search
     * Debounce search request so we only start the request after 1 second of inactivity.
     */
    const handleSearch = (e: React.ChangeEvent<any>): void => {
        const displayValue: string = e.target.value || '';
        setSearch(displayValue);
        setDisplayErrorSearch(false);
        const searchValue: string = displayValue.toLowerCase().trim();
        if (searchValue.length > 1 && searchValue.length < minSearchLength) {
            warnSearchRequest();
        }
        if (searchValue.length >= minSearchLength || searchValue.length < 1) {
            cancelWarnSearchRequest();

            //Start search request.
            launchSearchRequest([searchValue, setCustomQuery]);
        }
    };
    const resetSearch = (): void => {
        setCustomQuery((prev) => ({ ...prev, q: '' }));
        setSearch('');
        setDisplayErrorSearch(false);
    };

    const [launchSearchRequest] = useDebounce<[string, typeof setCustomQuery]>((data) => {
        if (!data) return;

        const [value, setCustomQuery] = data;

        setCustomQuery((prev) => ({ ...prev, q: value }));
    }, 1000);

    const [warnSearchRequest, cancelWarnSearchRequest] = useDebounce(() => {
        setDisplayErrorSearch(true);
    }, 1000);

    React.useEffect(() => {
        if (!queryParams) return;

        const { q, ...custom } = customQuery;

        if (match(queryParams, custom)) return;

        setCustomQuery((prev) => ({ ...prev, ...queryParams }));
    }, [queryParams]);

    React.useEffect(() => {
        setFetchedData && setFetchedData(items);
    }, [items]);

    React.useEffect(() => {
        if (triggerPageRefresh) pageRefresh();
    }, [triggerPageRefresh]);

    const [selectedRows, setSelectedRows] = React.useState<DataTableSelectionChangeParams['value']>([]);
    const handleSelectedRows = (e: DataTableSelectionChangeParams): void => {
        setSelectedRows(e.value);
    };

    const handleOnClickPerPageDropdown = (e: DropdownChangeParams): void => {
        setLimit(e.value);
    };

    const handleActionRowArchive = (e: MenuItemCommandParams) => {
        const item = items[e.item['rowInfo']['rowIndex']];
        confirmDialog({
            header          : `Confirmer ${item?.archived ? 'la réactivation' : "l'archivage"}.`,
            message         : `Etes-vous sur de vouloir ${item?.archived ? 'réactiver' : 'archiver'} cet élément ?`,
            icon            : 'pi pi-info-circle',
            acceptClassName : 'p-button-danger',
            acceptLabel     : 'Confirmer',
            rejectLabel     : 'Annuler',
            accept          : () => {
                actionRowArchive(e.item['rowInfo']['rowIndex']);
            }
        });
    };

    const handleBulkActionArchive = (e: MenuItemCommandParams) => {
        const someActive = selectedRows.some((el: { id?: string; archived?: boolean & R }) => el?.archived === false);
        confirmDialog({
            header  : `Confirmer ${someActive ? "l'archivage" : 'la réactivation'}.`,
            message : `Etes-vous sur de vouloir ${someActive ? 'archiver' : 'réactiver'} ces ${
                selectedRows.length
            } éléments ?`,
            icon            : 'pi pi-info-circle',
            acceptClassName : 'p-button-danger',
            acceptLabel     : 'Confirmer',
            rejectLabel     : 'Annuler',
            accept          : bulkActionArchive
        });
    };

    const actionRowArchive = async (index: number) => {
        const item: { id?: string; archived?: boolean } & R = items[index];

        item.archived ? setIsUnarchiving(true) : setIsArchiving(true);

        const response = await request({
            method  : 'PATCH',
            url     : `${url}/${version}/${endpoint}/${item.id}/archive`,
            payload : {
                body: {
                    archived: !item.archived
                }
            }
        });

        setIsArchiving(false);
        setIsUnarchiving(false);

        pageRefresh();

        let detail = '';
        if (response.success) detail = `${item?.archived ? 'Reactivation' : 'Archiving'} complete.`;
        else if (response?.response?.data?.message?.startsWith('NdlssError'))
            detail = NdlssError.humanize(response?.response?.data?.message).message;
        else if (response?.error?.message?.startsWith('NdlssError'))
            detail = NdlssError.humanize(response?.error?.message).message;
        else detail = response?.error?.message ?? 'An error occured.';
        toast({
            severity : response.success ? 'success' : 'error',
            summary  : response.success ? 'Successfull' : 'Error',
            detail
        });
    };

    const bulkActionArchive = async () => {
        const listIdsAndArchived: { id?: string; archived?: boolean }[] = selectedRows.map(
            (el: { id?: string; archived?: boolean & R }) => ({
                id       : el.id,
                archived : el.archived
            })
        );
        const someActive = selectedRows.some((el: { id?: string; archived?: boolean & R }) => el?.archived === false);
        someActive ? setIsArchiving(true) : setIsUnarchiving(true);

        const requestsArgs = listIdsAndArchived.map<RequestProps>((e) => ({
            method  : 'PATCH',
            url     : `${url}/${version}/${endpoint}/${e.id}/archive`,
            payload : {
                body: {
                    archived: !e.archived
                }
            }
        }));
        await Promise.all(requestsArgs.map((e) => request(e)));
        setIsArchiving(false);
        setIsUnarchiving(false);
        pageRefresh();
    };

    const handleActionRowEdit = (e: MenuItemCommandParams) => {
        const list: Array<R & { id?: string }> = items;

        const index: number | undefined = (e.item as any)?.rowInfo?.rowIndex;
        const item = list[index ?? 0];

        if (item === undefined || item.id === undefined) return;

        const url = (pageUrl ?? location.pathname) + '/edit/' + item.id + (pageUrlSuffix ? pageUrlSuffix(item) : '');

        navigate({ pathname: url });
    };

    const bulkActionsListDisplay = (): MenuItem[] => {
        const someActive = selectedRows.some((el: { id?: string; archived?: boolean & R }) => el?.archived === false);

        const baseBulkActionsList: MenuItem[] = displayBulkArchiveAction
            ? [
                  {
                      label   : someActive ? 'Archiver' : 'Réactiver',
                      icon    : 'pi pi-folder',
                      command : (e) => handleBulkActionArchive(e)
                  }
              ]
            : [];
        if (bulkActionsList.length > 0) {
            baseBulkActionsList.unshift(...bulkActionsList(selectedRows));
        }
        return baseBulkActionsList;
    };
    return (
        <PageContainer>
            <PageHeader>
                <div>
                    <h1>{title}</h1>
                    {subtitle && <h3>{subtitle}</h3>}
                    {displayArchiveFilters && (
                        <ArchiveFilters customQuery={customQuery} setCustomQuery={setCustomQuery} />
                    )}
                </div>
                {hideCreateButton ? (
                    <div />
                ) : (
                    <Link to={{ pathname: addButtonUrl ?? undefined }}>
                        <Button>{`Ajouter un(e) ${name}`}</Button>
                    </Link>
                )}
            </PageHeader>
            <PageActions>
                {leftHandedComponent}
                {showSearch && (
                    <RightHandedAction>
                        <div style={{ width: '400px' }}>
                            {isLoading && isMounting ? (
                                <Skeleton width="400px" height="45px" />
                            ) : (
                                <>
                                    <span className="p-float-label p-input-icon-right">
                                        {search && (
                                            <i
                                                className={'pi pi-times-circle'}
                                                onClick={() => resetSearch()}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        )}
                                        <InputText
                                            value={search}
                                            onChange={handleSearch}
                                            id="search_list"
                                            style={{ width: '400px' }}
                                            className={classNames({ 'p-invalid': displayErrorSearch })}
                                        />
                                        <label htmlFor="search_list">{searchPlaceholder}</label>
                                    </span>
                                    {displayErrorSearch && (
                                        <small id="" className="p-error">
                                            Il faut minimum {minSearchLength} caractères.
                                        </small>
                                    )}
                                </>
                            )}
                        </div>
                        {hideBulkMenu ? (
                            <div />
                        ) : (
                            <div>
                                {isLoading ? (
                                    <Skeleton width="45px" height="45px" />
                                ) : (
                                    <>
                                        <Button
                                            type="button"
                                            icon="pi pi-ellipsis-v"
                                            onClick={(e) => bulk.current?.toggle(e)}
                                            className={classNames({ 'mb-15': displayErrorSearch })}
                                        />
                                        <Menu
                                            model={bulkActionsListDisplay()}
                                            onShow={() => {
                                                if (selectedRows.length === 0) {
                                                    toast({
                                                        severity : 'info',
                                                        summary  : 'Requirements not met',
                                                        detail   : 'Please select at least one line.'
                                                    });
                                                }
                                            }}
                                            ref={bulk}
                                            popup
                                            className={selectedRows.length > 0 ? '' : 'p-disabled'}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </RightHandedAction>
                )}
            </PageActions>
            <DataTable<R>
                // TODO: Add loading on bulk action call
                // Previous param => isLoading={state.isLoading || state.signature !== refreshNamespace}
                isLoading={isLoading}
                data={parseData ? parseData(items) : items}
                headers={headers}
                actionRows={actionRows}
                selectedRows={(e) => handleSelectedRows(e)}
                rows={pagination?.totalPerPage ?? 10}
                onClickPrevPage={previousPage}
                onClickNextPage={nextPage}
                onClickPerPageDropdown={(e) => handleOnClickPerPageDropdown(e)}
                totalRecords={totalRecords ?? pagination?.itemsCount ?? 0}
                page={pagination?.currentPage ?? 1}
                actionRowArchive={(e) => handleActionRowArchive(e)}
                actionRowEdit={(e) => handleActionRowEdit(e)}
                displayActionColumn={displayActionColumn}
                displayPaginator={displayPaginator}
                displaySelectColumn={displaySelectColumn}
                disableArchiveAction={disableArchiveAction}
                disableEditAction={disableEditAction}
                actionColumnField={actionColumnField}
                breakpoint={breakpoint}
                displayArchiveColumn={displayArchiveColumn}
                {...props}
            />
            {(isArchiving || isUnarchiving) && (
                <Veil>
                    <StyledCard>
                        <CustomGifLoader
                            title="chargement"
                            subTitle={`${isArchiving ? 'Archivage' : 'Réactivation'} de la sélection...`}
                        />
                        {/* <ProgressBar
                            value={handleProgressBarValue(totalActionsForBulk, actionsRemaining?.length || 0)}
                        /> */}
                    </StyledCard>
                    <BlockUI blocked fullScreen />
                </Veil>
            )}
        </PageContainer>
    );
}

const StyledCard = styled(Card)`
    width: 350px;
    display: flex;
    flex-direction: column;
    align-content: center;

    & > .p-card-body {
        padding: 0 5px;
        padding-bottom: 10px;
    }
`;

const CustomGifLoader = styled(GifLoader)`
    max-width: 100%;
    position: relative;
    transform: none;
    top: auto;
    right: auto;
`;
