import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import React from 'react';
import styled from 'styled-components';

import { formatE164ToInternational } from '@bbng/util/misc';
import { PrestaRo } from '@bbng/util/types';

import { PRESTA_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';

const Anchor = styled.a`
    word-break: break-all;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #343699;
`;

export const PrestasListing: React.FC = (): JSX.Element => {
    return (
        <Listing<any, PrestaRo>
            url={PRESTA_ORCHESTRATION_BASE_URL}
            endpoint="prestas"
            addButtonUrl={urlBuilder.prestaCreate()}
            name="Prestataire"
            pluralName="Prestataires"
            headers={[
                {
                    name      : 'Nom',
                    field     : 'name',
                    component : (data: PrestaRo) => <Anchor href={urlBuilder.prestaView(data.id)}>{data.name}</Anchor>
                },
                {
                    name      : 'Email',
                    field     : 'email',
                    component : (data: PrestaRo) => (
                        <Anchor href={`mailto:${data.email}`}>
                            <i className="pi pi-envelope" />
                            {data.email}
                        </Anchor>
                    )
                },
                {
                    name      : 'Téléphone',
                    field     : 'phone_number',
                    width     : 190,
                    component : (data: PrestaRo) => (
                        <Anchor
                            href={data.phone_number ? 'tel:' + formatE164ToInternational(data.phone_number) : undefined}
                        >
                            <i className="pi pi-phone" />
                            {data.phone_number ? formatE164ToInternational(data.phone_number) : ''}
                        </Anchor>
                    )
                }
            ]}
        />
    );
};
