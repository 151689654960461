import { env } from '@bbng/util/environment';

/**
 * Configuration
 */
export const config = {
    frontVersion : env('NX_VERSION_FRONT', false).asString() ?? 'version.unknown',
    nodeEnv      : env('NX_ENV_VERSION', false).asString() ?? 'dev',
    googleApiKey : env('NX_GOOGLE_PLACE_API_KEY', false).asString() ?? '',
    baseUrl      : env('NX_API_BASE_URL', false).asString() ?? '',
    websocket    : {
        // host : env('NX_WEBSOCKET_ORCHESTRATION_HOST').asString(),
        port: env('NX_WEBSOCKET_ORCHESTRATION_PORT', false).asPortNumber()
    },
    adminOrchestration: {
        // host : env('NX_ADMIN_ORCHESTRATION_HOST').asString(),
        port: env('NX_ADMIN_ORCHESTRATION_PORT', false).asPortNumber()
    },
    adminPlayer: {
        // host : env('NX_ADMIN_PLAYER_HOST').asString(),
        port: env('NX_ADMIN_PLAYER_PORT', false).asPortNumber()
    },
    collectOrchestration: {
        // host : env('NX_COLLECT_ORCHESTRATION_HOST').asString(),
        port: env('NX_COLLECT_ORCHESTRATION_PORT', false).asPortNumber()
    },
    collectorOrchestration: {
        // host : env('NX_COLLECTOR_ORCHESTRATION_HOST').asString(),
        port: env('NX_COLLECTOR_ORCHESTRATION_PORT', false).asPortNumber()
    },
    prestaOrchestration: {
        // host : env('NX_PRESTA_ORCHESTRATION_HOST').asString(),
        port: env('NX_PRESTA_ORCHESTRATION_PORT', false).asPortNumber()
    },
    constructionSiteOrchestration: {
        // host : env('NX_CONSTRUCTION_SITE_ORCHESTRATION_HOST').asString(),
        port: env('NX_CONSTRUCTION_SITE_ORCHESTRATION_PORT', false).asPortNumber()
    },
    contactPlayer: {
        // host : env('NX_CONTACT_PLAYER_HOST').asString(),
        port: env('NX_CONTACT_PLAYER_PORT', false).asPortNumber()
    },
    customerOrchestration: {
        // host : env('NX_CUSTOMER_ORCHESTRATION_HOST').asString(),
        port: env('NX_CUSTOMER_ORCHESTRATION_PORT', false).asPortNumber()
    },
    discountOrchestration: {
        // host : env('NX_DISCOUNT_ORCHESTRATION_HOST').asString(),
        port: env('NX_DISCOUNT_ORCHESTRATION_PORT', false).asPortNumber()
    },
    documentPlayer: {
        // host : env('NX_DOCUMENT_PLAYER_HOST').asString(),
        port: env('NX_DOCUMENT_PLAYER_PORT', false).asPortNumber()
    },
    dumpsterOrchestration: {
        // host : env('NX_DUMPSTER_ORCHESTRATION_HOST').asString(),
        port: env('NX_DUMPSTER_ORCHESTRATION_PORT', false).asPortNumber()
    },
    elasticPlayer: {
        // host : env('NX_ELASTIC_PLAYER_HOST').asString(),
        port: env('NX_ELASTIC_PLAYER_PORT', false).asPortNumber()
    },
    itemOrchestration: {
        // host : env('NX_ITEM_ORCHESTRATION_HOST').asString(),
        port: env('NX_ITEM_ORCHESTRATION_PORT', false).asPortNumber()
    },
    landfillOrchestration: {
        // host : env('NX_LANDFILL_ORCHESTRATION_HOST').asString(),
        port: env('NX_LANDFILL_ORCHESTRATION_PORT', false).asPortNumber()
    },
    orderOrchestration: {
        // host : env('NX_ORDER_ORCHESTRATION_HOST').asString(),
        port: env('NX_ORDER_ORCHESTRATION_PORT', false).asPortNumber()
    },
    planningConfigOrchestration: {
        // host : env('NX_PLANNING_CONFIG_ORCHESTRATION_HOST').asString(),
        port: env('NX_PLANNING_CONFIG_ORCHESTRATION_PORT', false).asPortNumber()
    },
    productOchestration: {
        // host : env('NX_STRAPI_PRODUCT_PLAYER_HOST').asString(),
        port: env('NX_PRODUCT_ORCHESTRATION_PORT', false).asPortNumber()
    },
    zoneOrchestration: {
        // host : env('NX_STRAPI_ZONE_PLAYER_HOST').asString(),
        port: env('NX_ZONE_ORCHESTRATION_PORT', false).asPortNumber()
    },
    truckOrchestration: {
        // host : env('NX_TRUCK_ORCHESTRATION_HOST').asString(),
        port: env('NX_TRUCK_ORCHESTRATION_PORT', false).asPortNumber()
    },
    userOrchestration: {
        // host : env('NX_USER_ORCHESTRATION_HOST').asString(),
        port: env('NX_USER_ORCHESTRATION_PORT', false).asPortNumber()
    },
    planningOrchestration: {
        // host : env('NX_PLANNING_ORCHESTRATION_HOST').asString(),
        port: env('NX_PLANNING_ORCHESTRATION_PORT', false).asPortNumber()
    },
    collectConfigOrchestration: {
        // host : env('NX_COLLECT_CONFIG_ORCHESTRATION_HOST').asString(),
        port: env('NX_COLLECT_CONFIG_ORCHESTRATION_PORT', false).asPortNumber()
    },
    invoiceOrchestration: {
        // host : env('NX_INVOICE_ORCHESTRATION_HOST').asString(),
        port: env('NX_INVOICE_ORCHESTRATION_PORT', false).asPortNumber()
    },
    zohoServer: {
        host              : env('NX_ZOHO_SERVER_HOST').asString(),
        organization_id_1 : env('NX_ZOHO_1_ORGANIZATION_ID').asFloat(),
        organization_id_2 : env('NX_ZOHO_2_ORGANIZATION_ID').asFloat()
    },
    bsdOrchestration: {
        // host : env('NX_BSD_ORCHESTRATION_HOST').asString(),
        port: env('NX_BSD_ORCHESTRATION_PORT', false).asPortNumber()
    },
    sentryDsn        : env('NX_SENTRY_DSN').asString(),
    kpiOrchestration : {
        // host : env('NX_KPI_ORCHESTRATION_HOST').asString(),
        port: env('NX_KPI_ORCHESTRATION_PORT', false).asPortNumber()
    },
    smsOrchestration: {
        // host : env('NX_SMS_ORCHESTRATION_HOST').asString(),
        port: env('NX_SMS_ORCHESTRATION_PORT', false).asPortNumber()
    }
};
