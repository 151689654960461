import { Divider } from 'primereact/divider';
import styled from 'styled-components';

export const OverviewLineWrapper = styled.table`
    border-spacing: 8px;
    margin: 0 auto;
`;

export const OverviewHeader = styled.th`
    font-weight: 600;
    font-size: 1em;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
`;

export const OverviewLabel = styled.td`
    font-weight: 200;
    font-size: 0.9em;
`;

export const OverviewValue = styled.td`
    font-weight: 600;
    font-size: 1em;
    text-align: center;
`;

export const StyledDivider = styled(Divider)`
    height: 16px;
    &.p-divider.p-divider-vertical {
        padding: 4px;
        margin: 0;
    }
`;

export const GlobalPrice = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 8px;
`;

export const GlobalPriceLabel = styled.div`
    font-weight: 600;
    font-size: 0.9em;
`;

export const GlobalPriceValue = styled.div`
    font-weight: 100;
    font-size: 2.5em;
`;

export const PriceContainer = styled.div`
    display: flex;
    gap: 16px;
    flex-direction: column;
`;

export const StyledTable = styled.table`
    text-align: center;
    & td,
    th {
        padding: 4px 12px;
    }
`;

export const StyledItalic = styled.i`
    font-size: 0.8em;
`;

export const CACardContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    align-items: center;
    gap: 4px;
    .ca-card-value {
        font-weight: bold;
        font-size: 1.5em;
    }
`;

export const CAContainer = styled.div`
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
`;
