import { InputListAddress } from './address';
import { InputListBase } from './base';
import { InputListCalendarRange } from './calendar-range';
import { InputListContact, InputListContactProps } from './contact';
import { InputListCustomerContact } from './customer-contact';
import { InputListPrice } from './prices';

const InputList = {
    Base             : InputListBase,
    Contacts         : InputListContact,
    CustomerContacts : InputListCustomerContact,
    Users            : (props: InputListContactProps) => <InputListContact title={'Utilisateur'} {...props} />,
    Addresses        : InputListAddress,
    Prices           : InputListPrice,
    CalendarRange    : InputListCalendarRange
};

export default InputList;
