import { Order, Order_Authz, Order_History } from '@prisma/client/order';

import { AddressCreateDto } from '../address';
import { AdminRo } from '../admin';
import { ECollectCharacteristic } from '../collect';
import { AlreadyAvailableDate, CCServiceRo, CollectConfigZone } from '../collect-config';
import { ConstructionSiteRo } from '../construction-site';
import { CustomerRo } from '../customer';
import { DocumentCreateDto } from '../document';
import { IKafkaQuery } from '../kafka';
import { BillingBranch, CREATED_FROM, IContact, ISODate } from '../misc';
import { StripeCheckoutSessionRo } from '../stripe';

export type OrderCreateDto = {
    user_id: string[];
    admin_id: string[];
    customer_id: string[];
    collect_configs: CCOrderCreateDto[];
    conditions_accepted: boolean;
    documents?: Array<DocumentCreateDto>;
    existing_document_id?: string[]; // Only for existing document, i.e already in DB (e.g. documents created on construction-site and duplicated on order)
    order_sheet_number?: string;
    taken_from: string;
    confirmation_email_recipients?: string[];
    callback_success_url?: string;
    callback_cancel_url?: string;
};

export type OrderInstantDeliveryDto = {
    customer_id: string;
    products: Array<LineCreateDto>;
    documents?: Array<DocumentCreateDto>;
    construction_site_id: string;
    taken_from: string;
};

export type OrderCreateActionDto = Omit<OrderCreateDto, 'collect_configs'> &
    Pick<OrderRo, 'id' | 'payment_status' | 'collect_config_id' | 'document_id'> &
    Partial<Pick<OrderRo, 'confirmed_by_client'>>;

export type CCOrderCreateDto = {
    lines: LineCreateDto[];
    details: CollectConfigDetails;
};

export type LineCreateDto = {
    id: string;
    quantity: number;
};

export type CollectConfigDetails = {
    prepaid: boolean;
    to_date: ISODate;
    comment?: string;
    from_date: ISODate;
    zone: CollectConfigZone;
    log_contact: Array<IContact>;
    already_available_date?: AlreadyAvailableDate;
    characteristics: ECollectCharacteristic[];
    construction_site_contact: Array<IContact>;
    waiting_time_minutes?: number; //Only if loadwait
    retrieval_date?: ISODate; //Only if deposit
    address_shipping?: AddressCreateDto; //Only for delivery
    construction_site_id: string[]; //Only if different from DELIVERY
    execution_time_minutes?: number;
    presta_id?: string[]; // To create a presta linked collect-config
    dumpster_on_site_id?: string[]; // Only if retrieval/rotation
    deposit_collect_config_id?: string[]; // Only if retrieval/rotation
};

export type OrderEditDto = {
    order_sheet_number?: string;
    payment_status?: EOrderPaymentStatus;
    documents?: Array<DocumentCreateDto>;
    stripe_checkout_sessions?: OrderStripeCheckoutSession;
};
export type OrderEditActionDto = Omit<OrderEditDto, 'documents'> & {
    document_id?: string[];
    stripe_checkout_sessions?: OrderStripeCheckoutSession;
    archived?: boolean;
    confirmed_by_client?: boolean;
};

export interface OrderRo {
    id: string;

    number: string;
    conditions_accepted: boolean;
    confirmed_by_client: boolean;
    payment_status: EOrderPaymentStatus;
    order_sheet_number: string | null;
    stripe_checkout_sessions: OrderStripeCheckoutSession | null;
    taken_from: CREATED_FROM;

    customer_id: string[];
    admin_id: string[];
    collect_config_id: string[];
    document_id: string[];
    user_id: string[];

    archived: boolean;
    created_by: string;
    created_at: ISODate;
    updated_at: ISODate;
}

export type OrderPrisma = Order & { authorization: Order_Authz; history: Order_History };

export enum EOrderPaymentStatus {
    TO_PAY = 'TO_PAY',
    PAID = 'PAID',
    NA = 'NA',
    CANCELED = 'CANCELED'
}

export interface GetAllOrderQuery extends IKafkaQuery {
    min_date?: ISODate; // filters order greater than min_date
    max_date?: ISODate; // filters order less than max_date
    payment_status?: EOrderPaymentStatus;
    customer_id?: string;
    date_asc?: boolean;
    number?: string;
    taken_from?: string;
}

export const ORDER_NUMBER_PREFIX = 'BDC';

export type OrderStripeCheckoutSession = Pick<StripeCheckoutSessionRo, 'id' | 'url' | 'expires_at' | 'amount_total'> & {
    payment_intent?: string;
    billing_branch: BillingBranch;
};

export enum ESlot {
    CUSTOM = 'CUSTOM',
    DAY = 'DAY',
    MORNING = 'MORNING',
    AFTERNOON = 'AFTERNOON',
    FIVE_EIGHT_AM = 'FIVE_EIGHT_AM',
    EIGHT_TEN_AM = 'EIGHT_TEN_AM',
    TEN_TWELVE_AM = 'TEN_TWELVE_AM',
    TWELVE_TWO_PM = 'TWELVE_TWO_PM',
    TWO_FOUR_PM = 'TWO_FOUR_PM',
    FOUR_SEVEN_PM = 'FOUR_SEVEN_PM'
}

export const WEBHOOK_STRIPE_1_ENDPOINT = 'webhook1';
export const WEBHOOK_STRIPE_2_ENDPOINT = 'webhook2';
export const WEBHOOK_STRIPE_3_ENDPOINT = 'webhook3';

export type SlackMarseilleDumpsterWebhookDto = {
    comment: string;
    dumpster_details: string;
    slot: string;
    date: string;
    price: string;
    order_number: string;
    client_name: string;
    cons_site_address: string;
    contacts: string;
    order_to_pay: string;
    taken_from: string;
};

export type OrderConfirmationData = {
    order_id: string;
    order_number: string;
};

export type OrderCalculateCartPriceDto = {
    products: Array<LineCreateDto>;
    zone_id: string;
    construction_site_id: string;
    date: string;
    customer_id?: string; // If you don't pass it we will take the one in the jwt
};

export type createOrderHookdeckEvent = {
    event_name: string;
    customer: CustomerRo;
    constructionSite: ConstructionSiteRo;
    admin: AdminRo;
    order: OrderRo;
};

export type createOrderInstantDeliveryHookdeckEvent = {
    event_name: string;
    customer: CustomerRo;
    constructionSite: ConstructionSiteRo;
    admin: AdminRo;
    cc: CCServiceRo[];
    order: OrderRo;
};
