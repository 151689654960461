import { useEffect, useState } from 'react';
import moment from 'moment';

import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber, InputNumberChangeParams } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Message } from 'primereact/message';

import {
    CCAdministrativeRo,
    CCServiceRo,
    CC_FAMILY,
    CC_STATUS,
    CollectAdministrative,
    CollectCreateAdministativeDto,
    CollectCreateDriverDto,
    CollectCreateEmptyingDto,
    CollectCreateServiceDto,
    CollectRo,
    CollectService,
    CollectorRo,
    CustomerRo,
    ECollectHazardReason,
    EPlanningRegion,
    EPlanningType,
    ETrashType,
    ISODate,
    LandfillRo,
    LineCreateDto,
    PRODUCT_FAMILY,
    PlanningShiftStepAdministrative,
    PlanningShiftStepCategory,
    PlanningShiftStepDriver,
    PlanningShiftStepEmptying,
    PlanningShiftStepService,
    ProductInCCOrCO,
    ProductRo
} from '@bbng/util/types';
import { usePStore } from './store';
import { useRequest } from '../../hooks/StatelessRequest';
import { deepCopy, delay, getMiddleOfTheDay } from '@bbng/util/misc';
import { Tag } from 'primereact/tag';
import { urlApiBuilder } from '../../common/urlBuilder';
import { mapCollectCategoryText, mapCollectConfigFamilyText } from '../../common/enumMapper';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const region = [{ name: EPlanningRegion.PARIS }, { name: EPlanningRegion.LYON }, { name: EPlanningRegion.MARSEILLE }];

type Unassigned = {
    id: string;
    family: string; //CC_FAMILY;
    number?: string;
    customer?: CustomerRo;
    postal_code: string;
    collector?: CollectorRo;
    title?: string;
};

type InPlanning = {
    id: string;
    cateogy: PlanningShiftStepCategory;
    displayCategory: string;
    number?: string;
    customer?: CustomerRo;
    postal_code?: string;
    to_collect?: ProductInCCOrCO[];
    collected?: ProductInCCOrCO[];
    hazard: boolean;
    from_date: ISODate;
    to_date: ISODate;
    collect?: CollectRo;
    step:
        | PlanningShiftStepService
        | PlanningShiftStepDriver
        | PlanningShiftStepEmptying
        | PlanningShiftStepAdministrative;
    landfill?: LandfillRo;
    zone?: CCServiceRo['zone'];
    hazardReason?: { name: ECollectHazardReason };
    hazardComment?: string;
    planning_id: string;
    truck_id: string;
    collector_id: string;
    collect_config_id?: string;
};

export const FinishPage: React.FC = () => {
    const [date, setDate] = useState<string>(moment().format('MM/DD/YYYY'));
    const [planningRegion, setPlanningRegion] = useState<{ name: EPlanningRegion }>({ name: EPlanningRegion.PARIS });
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedDriver, setSelectedDriver] = useState<CollectorRo | null>(null);
    const [selectedPlanning, setSelectedPlanning] = useState<InPlanning[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [progressInfo, setProgressInfo] = useState<number>(0);

    const bbngRequest = useRequest();
    const {
        fetchAll,
        unnassignedCCService,
        unnassignedCCAdministrative,
        customers,
        collectors,
        plannings,
        trucks,
        collects,
        landfills,
        ccsService,
        ccsAdministrative,
        products
    } = usePStore((state) => ({
        fetchAll                    : state.fetchAll,
        unnassignedCCService        : state.unnassignedCCService,
        unnassignedCCAdministrative : state.unnassignedCCAdministrative,
        customers                   : state.customers,
        collectors                  : state.collectors,
        plannings                   : state.plannings,
        trucks                      : state.trucks,
        collects                    : state.collects,
        landfills                   : state.landfills,
        ccsService                  : state.ccsService,
        ccsAdministrative           : state.ccsAdministrative,
        products                    : state.products
    }));

    const handleDateChange = (e: CalendarChangeParams) => {
        setDate(moment(e.value as Date).format('MM/DD/YYYY'));
    };
    const handlePlanningRegionChange = (e: DropdownChangeParams) => {
        setPlanningRegion(e.value);
    };
    const handleDriverChange = (e: DropdownChangeParams) => {
        setSelectedDriver(e.value);
    };

    const loadPlannings = async () => {
        setLoading(true);
        setSelectedDriver(null);
        setSelectedPlanning([]);
        await fetchAll(
            {
                day    : getMiddleOfTheDay(moment.utc(date, 'MM/DD/YYYY').toISOString()),
                region : planningRegion.name,
                type   : EPlanningType.BIG_BAG
            },
            bbngRequest
        );
        setLoading(false);
    };

    useEffect(() => {
        loadPlannings();
    }, [date, planningRegion]);

    const getCorrespondingTruck = (collector: CollectorRo | null) => {
        if (!collector) return '';
        const planning = Object.values(plannings).find((planning) => {
            return planning.collector_id[0] === collector.id;
        });
        if (!planning || planning.truck_id.length === 0) return '';
        return trucks[planning.truck_id[0]].name;
    };

    const mapUnassignedCC = (ccs: (CCServiceRo | CCAdministrativeRo)[]): Unassigned[] => {
        return ccs.map((cc) => {
            return {
                id       : cc.id,
                family   : mapCollectConfigFamilyText(cc.family),
                number   : (cc as CCServiceRo).number !== undefined ? (cc as CCServiceRo).number : undefined,
                customer :
                    (cc as CCServiceRo).customer_id !== undefined
                        ? customers[(cc as CCServiceRo).customer_id[0]]
                        : undefined,
                postal_code : cc.address.components['postal_code'],
                hazard      : false
            };
        });
    };

    const mapRecap = () => {
        const data = selectedPlanning.reduce(
            (acc, curr) => {
                if (curr.cateogy === PlanningShiftStepCategory.SERVICE && curr.hazard === false) {
                    acc.service_success++;
                } else if (curr.cateogy === PlanningShiftStepCategory.ADMINISTRATIVE) {
                    acc.administrative_success++;
                } else if (curr.cateogy === PlanningShiftStepCategory.EMPTYING) {
                    acc.emptying++;
                } else if (
                    curr.cateogy === PlanningShiftStepCategory.DRIVER_HOUSE_END ||
                    curr.cateogy === PlanningShiftStepCategory.DRIVER_HOUSE_START
                ) {
                    acc.driver++;
                }
                if (curr.hazard) {
                    acc.service_hazard++;
                }
                return acc;
            },
            {
                driver                 : 0,
                service_success        : 0,
                administrative_success : 0,
                service_hazard         : 0,
                emptying               : 0
            }
        );
        return [data];
    };

    useEffect(() => {
        const planning = Object.values(plannings).find((planning) => {
            return planning.collector_id[0] === selectedDriver?.id;
        });
        if (!planning) return;

        const formatInPlanning = () => {
            const formatedStepDriver: InPlanning[] = planning.shift.steps_driver.map((step) => {
                return {
                    id              : '',
                    cateogy         : step.category,
                    displayCategory : mapCollectCategoryText(step.category),
                    hazard          : false,
                    from_date       : step.scheduled_at,
                    to_date         : step.scheduled_end_at,
                    collect         : step.collect_id ? collects[step.collect_id] : undefined,
                    step            : step,
                    planning_id     : planning.id,
                    truck_id        : planning.truck_id[0],
                    collector_id    : planning.collector_id[0]
                };
            });
            const formatedStepEmptying: InPlanning[] = planning.shift.steps_emptying.map((step) => {
                return {
                    id              : '',
                    cateogy         : step.category,
                    displayCategory : mapCollectCategoryText(step.category),
                    hazard          : false,
                    from_date       : step.scheduled_at,
                    to_date         : step.scheduled_end_at,
                    collect         : step.collect_id ? collects[step.collect_id] : undefined,
                    step            : step,
                    landfill        : landfills[step.landfill_id],
                    planning_id     : planning.id,
                    truck_id        : planning.truck_id[0],
                    collector_id    : planning.collector_id[0]
                };
            });
            const formatedStepService: InPlanning[] = planning.shift.steps_service.map((step) => {
                const currentZone = ccsService[step.collect_config_id].zone;
                const productForZone = Object.values(products)
                    .filter((product) => product.zone_id.some((zone) => zone === currentZone.id))
                    .filter(
                        (product) =>
                            product.family === CC_FAMILY.COLLECT_BIG_BAG ||
                            product.family === CC_FAMILY.DELIVERY_BIG_BAG
                    )
                    .filter((product) => product.trash_type === ETrashType.MIXED);

                const correspondingCollect = step.collect_id ? collects[step.collect_id] : undefined;

                const weHave = correspondingCollect
                    ? (correspondingCollect as CollectService).informations.collected_items
                    : ccsService[step.collect_config_id].products;

                //Check what product id is missing from rowData.collected and add it with quantity 0
                const missingProduct = productForZone.filter(
                    (product) => weHave.find((collected) => collected.id === product.id) === undefined
                );
                const collected = weHave.concat(
                    missingProduct.map((product) => {
                        return {
                            id                  : product.id,
                            family              : product.family,
                            internal            : product.internal,
                            operational         : product.operational,
                            name                : product.name,
                            subname             : product.subname,
                            price               : product.price,
                            vat_rate_percentage : product.vat_rate_percentage,
                            trash_type          : product.trash_type,
                            volume_m3           : product.volume_m3,
                            main_photo          : product.main_photo,
                            description         : product.description,
                            quantity            : 0,
                            zone                : currentZone
                        } as ProductInCCOrCO;
                    })
                );

                //Sort collected by family and volume
                const sortedCollected = collected.sort((a, b) => {
                    if (a.family === b.family) {
                        return a.volume_m3 - b.volume_m3;
                    }
                    if (a.family === CC_FAMILY.DELIVERY_BIG_BAG) return 1;
                    return -1;
                });

                const formatHazard = () => {
                    if (step.collect_id) {
                        const collect = collects[step.collect_id] as CollectService;
                        if (collect.status === CC_STATUS.HAZARD) return true;
                    }
                    return false;
                };

                return {
                    id              : step.collect_config_id,
                    cateogy         : step.category,
                    displayCategory : mapCollectCategoryText(step.category),
                    hazard          : formatHazard(),
                    from_date       : step.scheduled_at,
                    to_date         : step.scheduled_end_at,
                    collect         : step.collect_id ? collects[step.collect_id] : undefined,
                    step            : step,
                    customer        : customers[step.customer_id],
                    number          : ccsService[step.collect_config_id].number,
                    postal_code     : ccsService[step.collect_config_id].address.components['postal_code'],
                    to_collect      : ccsService[step.collect_config_id].products,
                    collected       : sortedCollected,
                    zone            : ccsService[step.collect_config_id].zone,
                    hazardReason    : step.collect_id
                        ? {
                              name:
                                  (collects[step.collect_id] as CollectService).hazard_reason ??
                                  ECollectHazardReason.OTHER
                          }
                        : undefined,
                    hazardComment: step.collect_id
                        ? (collects[step.collect_id] as CollectService).hazard_comment ?? ''
                        : undefined,
                    planning_id       : planning.id,
                    truck_id          : planning.truck_id[0],
                    collector_id      : planning.collector_id[0],
                    collect_config_id : step.collect_config_id
                };
            });
            const formattedStepAdministrative: InPlanning[] = planning.shift.steps_administrative.map((step) => {
                return {
                    id                : step.collect_config_id,
                    cateogy           : step.category,
                    displayCategory   : mapCollectCategoryText(step.category),
                    hazard            : false,
                    from_date         : step.scheduled_at,
                    to_date           : step.scheduled_end_at,
                    collect           : step.collect_id ? collects[step.collect_id] : undefined,
                    step              : step,
                    postal_code       : ccsAdministrative[step.collect_config_id].address.components['postal_code'],
                    planning_id       : planning.id,
                    truck_id          : planning.truck_id[0],
                    collector_id      : planning.collector_id[0],
                    collect_config_id : step.collect_config_id
                };
            });
            const sorted = [
                ...formatedStepDriver,
                ...formatedStepEmptying,
                ...formatedStepService,
                ...formattedStepAdministrative
            ].sort((a, b) => moment(a.from_date).diff(moment(b.from_date)));
            return sorted;
        };

        setSelectedPlanning(formatInPlanning());
    }, [selectedDriver]);

    const displayAlea = (rowData: InPlanning) => {
        if (
            selectedPlanning.length === 0 ||
            rowData.cateogy !== PlanningShiftStepCategory.SERVICE ||
            rowData.collect !== undefined
        )
            return <></>;
        const updateHazard = () => {
            const newPlanning = selectedPlanning.map((cc) => {
                if (cc.id === rowData.id) {
                    return {
                        ...cc,
                        hazard    : !cc.hazard,
                        collected : cc.collected!.map((product) => {
                            return {
                                ...product,
                                quantity: 0
                            };
                        }),
                        ...(!cc.hazard === false
                            ? {
                                  hazardReasons : undefined,
                                  hazardComment : undefined
                              }
                            : {})
                    };
                }
                return cc;
            });
            setSelectedPlanning(newPlanning);
        };
        return <Checkbox onChange={() => updateHazard()} checked={rowData.hazard} />;
    };
    const displayTimeFrom = (rowData: InPlanning) => {
        const from = moment(rowData.from_date).format('HH:mm');
        return <span>{from}</span>;
    };
    const displayTimeTo = (rowData: InPlanning) => {
        const to = moment(rowData.to_date).format('HH:mm');
        return <span>{to}</span>;
    };
    const displayToCollect = (rowData: InPlanning) => {
        if (rowData.to_collect === undefined) return <>-</>;
        const toCollect = rowData.to_collect.reduce((acc, product) => {
            const productInAcc = acc.find((p) => p.name === product.name);
            if (productInAcc) {
                productInAcc.quantity += product.quantity;
            } else {
                acc.push({
                    name     : product.name + '|' + product.zone.name,
                    quantity : product.quantity,
                    volume   : product.volume_m3
                });
            }

            return acc;
        }, [] as { name: string; quantity: number; volume: number }[]);

        return (
            <>
                {toCollect.map((product, index) => {
                    return (
                        <div key={index}>
                            ({product.volume}m3) x {product.quantity}
                        </div>
                    );
                })}
            </>
        );
    };
    const displayHasCollect = (rowData: InPlanning) => {
        if (rowData.collect)
            return (
                <>
                    <Tag severity="success">Oui</Tag>
                </>
            );
        return (
            <>
                <Tag severity="error">Non</Tag>
            </>
        );
    };
    const displayCollectedItem = (rowData: InPlanning, options: ColumnBodyOptions, idx: number) => {
        if (rowData.zone === undefined) return <></>;
        const productForZone = Object.values(products)
            .filter((product) => product.zone_id.some((zone) => zone === rowData.zone!.id))
            .filter(
                (product) =>
                    product.family === CC_FAMILY.COLLECT_BIG_BAG || product.family === CC_FAMILY.DELIVERY_BIG_BAG
            )
            .filter((product) => product.trash_type === ETrashType.MIXED);

        //Check what product id is missing from rowData.collected and add it with quantity 0
        const missingProduct = productForZone.filter(
            (product) => (rowData.collected ?? []).find((collected) => collected.id === product.id) === undefined
        );
        const collected = (rowData.collected ?? []).concat(
            missingProduct.map((product) => {
                return {
                    id                  : product.id,
                    family              : product.family,
                    internal            : product.internal,
                    operational         : product.operational,
                    name                : product.name,
                    subname             : product.subname,
                    price               : product.price,
                    vat_rate_percentage : product.vat_rate_percentage,
                    trash_type          : product.trash_type,
                    volume_m3           : product.volume_m3,
                    main_photo          : product.main_photo,
                    description         : product.description,
                    quantity            : 0,
                    zone                : rowData.zone
                } as ProductInCCOrCO;
            })
        );

        //Sort collected by family and volume
        const sortedCollected = collected.sort((a, b) => {
            if (a.family === b.family) {
                return a.volume_m3 - b.volume_m3;
            }
            if (a.family === CC_FAMILY.DELIVERY_BIG_BAG) return 1;
            return -1;
        });

        const thisCollected = sortedCollected[idx];

        const handleVolumeChange = (e: InputNumberChangeParams, productId: string) => {
            const newPlanning = selectedPlanning.map((cc) => {
                if (cc.id === rowData.id) {
                    const newCollected = cc.collected?.map((collected) => {
                        if (collected.id === productId) {
                            return {
                                ...collected,

                                quantity: e.value
                            };
                        }
                        return collected;
                    });
                    return {
                        ...cc,
                        collected: newCollected
                    };
                }
                return cc;
            }) as InPlanning[];
            setSelectedPlanning(newPlanning);
        };

        return (
            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {thisCollected && (
                    <span>
                        {thisCollected.family === CC_FAMILY.DELIVERY_BIG_BAG ? 'Livraison' : 'Service'} |{' '}
                        {thisCollected.volume_m3}m3 | {thisCollected.zone.name}
                    </span>
                )}
                <InputNumber
                    value={thisCollected?.quantity}
                    onChange={(e) => handleVolumeChange(e, thisCollected?.id ?? '')}
                    showButtons
                    buttonLayout="vertical"
                    style={{ width: '50px' }}
                    decrementButtonClassName={thisCollected?.quantity > 0 ? 'p-button-success' : ''}
                    incrementButtonClassName={thisCollected?.quantity > 0 ? 'p-button-success' : ''}
                    disabled={rowData.collect !== undefined}
                    min={0}
                />
            </span>
        );
    };
    const displayHazardReazon = (rowData: InPlanning) => {
        const handleHazardReasonChange = (e: DropdownChangeParams) => {
            const newPlanning = selectedPlanning.map((cc) => {
                if (cc.id === rowData.id) {
                    return {
                        ...cc,
                        hazardReason: e.value
                    };
                }
                return cc;
            }) as InPlanning[];
            setSelectedPlanning(newPlanning);
        };

        return (
            rowData.hazard && (
                <Dropdown
                    value={rowData.hazardReason}
                    options={Object.values(ECollectHazardReason).map((r) => ({ name: r }))}
                    onChange={(e) => handleHazardReasonChange(e)}
                    optionLabel="name"
                />
            )
        );
    };
    const displayHazardComment = (rowData: InPlanning) => {
        const handleHazardCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const newPlanning = selectedPlanning.map((cc) => {
                if (cc.id === rowData.id) {
                    return {
                        ...cc,
                        hazardComment: e.target.value
                    };
                }
                return cc;
            }) as InPlanning[];
            setSelectedPlanning(newPlanning);
        };

        return (
            rowData.hazard && (
                <InputTextarea
                    value={rowData.hazardComment}
                    onChange={(e) => handleHazardCommentChange(e)}
                    rows={4}
                    cols={40}
                />
            )
        );
    };

    const createDriverHouse = async (data: InPlanning, type: 'start' | 'end') => {
        const createDto: CollectCreateDriverDto = {
            planning_id  : data.planning_id,
            truck_id     : data.truck_id,
            collector_id : data.collector_id,
            arrived_at   : data.step.scheduled_at,
            completed_at : data.step.scheduled_end_at,
            category     :
                type === 'start'
                    ? PlanningShiftStepCategory.DRIVER_HOUSE_START
                    : PlanningShiftStepCategory.DRIVER_HOUSE_END,
            do_not_recalculate : true,
            status             : CC_STATUS.FINISHED,
            was_planned        : true,
            step_driver        : data.step as PlanningShiftStepDriver,
            origin             : type,
            day                : getMiddleOfTheDay(moment.utc(date, 'MM/DD/YYYY').toISOString()),
            type               : EPlanningType.BIG_BAG,
            region             : planningRegion.name
        };
        await bbngRequest<CollectRo>({
            url     : urlApiBuilder.collectDriverPost(),
            method  : 'POST',
            sync    : true,
            payload : {
                body: createDto
            }
        });
    };

    const createEmptying = async (data: InPlanning) => {
        if (data.landfill === undefined) return;
        const createDto: CollectCreateEmptyingDto = {
            planning_id        : data.planning_id,
            truck_id           : data.truck_id,
            collector_id       : data.collector_id,
            arrived_at         : data.step.scheduled_at,
            completed_at       : data.step.scheduled_end_at,
            category           : PlanningShiftStepCategory.EMPTYING,
            do_not_recalculate : true,
            was_planned        : true,
            step_emptying      : data.step as PlanningShiftStepEmptying,
            landfill_number    : 'Pas de bon',
            landfill_id        : data.landfill.id,
            day                : getMiddleOfTheDay(moment.utc(date, 'MM/DD/YYYY').toISOString()),
            type               : EPlanningType.BIG_BAG,
            region             : planningRegion.name,
            status             : CC_STATUS.FINISHED
        };
        await bbngRequest<CollectRo>({
            url     : urlApiBuilder.collectEmptyingPost(),
            method  : 'POST',
            sync    : true,
            payload : {
                body: createDto
            }
        });
    };

    const createServiceSuccess = async (data: InPlanning) => {
        if (data.collected === undefined || data.collect_config_id === undefined) return;
        const createDto: CollectCreateServiceDto = {
            planning_id        : data.planning_id,
            truck_id           : data.truck_id,
            collector_id       : data.collector_id,
            arrived_at         : data.step.scheduled_at,
            completed_at       : data.step.scheduled_end_at,
            category           : PlanningShiftStepCategory.SERVICE,
            do_not_recalculate : true,
            status             : CC_STATUS.FINISHED,
            collected_items    : data.collected.some((c) => c.quantity > 0)
                ? (data.collected
                      .map((c) => {
                          if (c.quantity === 0) return undefined;
                          return {
                              id       : c.id,
                              quantity : c.quantity
                          };
                      })
                      .filter((c) => c !== undefined) as LineCreateDto[])
                : [],
            was_planned       : true,
            step_service      : data.step as PlanningShiftStepService,
            collect_config_id : data.collect_config_id,
            is_splitted       : false,
            day               : getMiddleOfTheDay(moment.utc(date, 'MM/DD/YYYY').toISOString()),
            type              : EPlanningType.BIG_BAG,
            region            : planningRegion.name
        };
        await bbngRequest<CollectRo>({
            url     : urlApiBuilder.collectServicePost(),
            method  : 'POST',
            sync    : true,
            payload : {
                body: createDto
            }
        });
    };

    const createAdministrative = async (data: InPlanning) => {
        const createDto: CollectCreateAdministativeDto = {
            planning_id         : data.planning_id,
            truck_id            : data.truck_id,
            collector_id        : data.collector_id,
            arrived_at          : data.step.scheduled_at,
            completed_at        : data.step.scheduled_end_at,
            category            : PlanningShiftStepCategory.ADMINISTRATIVE,
            do_not_recalculate  : true,
            collect_config_id   : data.collect_config_id ?? '',
            was_planned         : true,
            day                 : getMiddleOfTheDay(moment.utc(date, 'MM/DD/YYYY').toISOString()),
            type                : EPlanningType.BIG_BAG,
            region              : planningRegion.name,
            status              : CC_STATUS.FINISHED,
            step_administrative : data.step as PlanningShiftStepAdministrative
        };
        await bbngRequest<CollectAdministrative>({
            url     : urlApiBuilder.collectAdministrativePost(),
            method  : 'POST',
            sync    : true,
            payload : {
                body: createDto
            }
        });
    };

    const createServiceHazards = async (data: InPlanning) => {
        if (data.collected === undefined || data.collect_config_id === undefined || data.hazardReason === undefined)
            return;
        const createDto: CollectCreateServiceDto = {
            planning_id        : data.planning_id,
            truck_id           : data.truck_id,
            collector_id       : data.collector_id,
            arrived_at         : data.step.scheduled_at,
            completed_at       : data.step.scheduled_end_at,
            category           : PlanningShiftStepCategory.SERVICE,
            do_not_recalculate : true,
            status             : CC_STATUS.HAZARD,
            collected_items    : data.collected.some((c) => c.quantity > 0)
                ? (data.collected
                      .map((c) => {
                          if (c.quantity === 0) return undefined;
                          return {
                              id       : c.id,
                              quantity : c.quantity
                          };
                      })
                      .filter((c) => c !== undefined) as LineCreateDto[])
                : [],
            was_planned       : true,
            hazard_reason     : data.hazardReason.name,
            hazard_comment    : data.hazardComment ?? '',
            step_service      : data.step as PlanningShiftStepService,
            collect_config_id : data.collect_config_id,
            is_splitted       : false,
            day               : getMiddleOfTheDay(moment.utc(date, 'MM/DD/YYYY').toISOString()),
            type              : EPlanningType.BIG_BAG,
            region            : planningRegion.name
        };
        await bbngRequest<CollectRo>({
            url     : urlApiBuilder.collectServiceHazardPost(),
            method  : 'POST',
            sync    : true,
            payload : {
                body: createDto
            }
        });
    };

    const finishPlanning = async () => {
        setProgressInfo(0);
        setShowModal(true);
        const filtered = selectedPlanning.filter((s) => s.collect === undefined);

        const total = filtered.length;

        for (const data of filtered) {
            if (data.cateogy === PlanningShiftStepCategory.DRIVER_HOUSE_START) {
                await createDriverHouse(data, 'start');
            } else if (data.cateogy === PlanningShiftStepCategory.DRIVER_HOUSE_END) {
                await createDriverHouse(data, 'end');
            }
            if (data.cateogy === PlanningShiftStepCategory.EMPTYING) {
                await createEmptying(data);
            }
            if (data.cateogy === PlanningShiftStepCategory.SERVICE) {
                if (data.hazard === false) {
                    await createServiceSuccess(data);
                } else if (data.hazard === true) {
                    await createServiceHazards(data);
                }
            }
            if (data.cateogy === PlanningShiftStepCategory.ADMINISTRATIVE) {
                await createAdministrative(data);
            }
            setProgressInfo((prev) => Number((prev + (1 * 100) / total).toPrecision(2)));
            await delay(data.cateogy === PlanningShiftStepCategory.EMPTYING ? 30_000 : 1_000);
        }
        const currentDriver = deepCopy(selectedDriver);
        await loadPlannings();
        setSelectedDriver(currentDriver);
        setShowModal(false);
    };

    const confirm = () => {
        confirmDialog({
            message         : 'Êtes-vous sûr de vouloir enregistrer ? Cette action est irréversible.',
            header          : 'Confirmation',
            icon            : 'pi pi-info-circle',
            acceptClassName : 'p-button-danger',
            acceptLabel     : 'Confirmer',
            rejectLabel     : 'Annuler',
            accept          : finishPlanning
        });
    };

    return (
        <div style={{ paddingLeft: '15px' }}>
            <h3>Paramètres:</h3>
            <div style={{ display: 'flex', gap: '10px' }}>
                <Calendar
                    value={new Date(date)}
                    onChange={(e) => handleDateChange(e)}
                    dateFormat="dd/mm/yy"
                    locale="fr"
                />
                <Dropdown
                    value={planningRegion}
                    options={region}
                    onChange={(e) => handlePlanningRegionChange(e)}
                    optionLabel="name"
                />
            </div>
            <h3>Collectes non assignées:</h3>
            <Message
                severity="warn"
                text="Assurez-vous de n'avoir aucune étape assignée avant de terminer les plannings. Une fois les plannings terminées, vous ne pourrez plus utiliser cette page pour terminer les collectes non assignées."
                style={{ maxWidth: '50rem' }}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DataTable
                    value={mapUnassignedCC([...unnassignedCCService, ...unnassignedCCAdministrative])}
                    tableStyle={{ minWidth: '50rem' }}
                    size="small"
                    loading={loading}
                    emptyMessage="Aucune collecte non assignée"
                >
                    <Column field="family" header="Famille"></Column>
                    <Column field="number" header="Numero"></Column>
                    <Column field="customer.name" header="Client"></Column>
                    <Column field="postal_code" header="Code postal"></Column>
                    <Column field="collector.name" header="Chauffeur"></Column>
                    <Column field="title" header="Titre"></Column>
                </DataTable>
            </div>
            <h3>Planning</h3>
            <div
                style={{
                    display       : 'flex',
                    flexDirection : 'row',
                    alignContent  : 'center',
                    alignItems    : 'center',
                    gap           : '10px'
                }}
            >
                <Dropdown
                    value={selectedDriver}
                    options={Object.values(collectors)}
                    onChange={(e) => handleDriverChange(e)}
                    optionLabel="fullname"
                />
                Camion: {getCorrespondingTruck(selectedDriver)}
            </div>
            <DataTable
                value={selectedPlanning}
                tableStyle={{ minWidth: '50rem' }}
                size="small"
                emptyMessage="Veuillez sélectionner un planning..."
                loading={loading}
            >
                <Column field="collect" header="Collecte" body={displayHasCollect}></Column>
                <Column field="displayCategory" header="Categorie"></Column>
                <Column field="customer.name" header="Client"></Column>
                <Column field="landfill.name" header="Déchèterie"></Column>
                <Column field="postal_code" header="CP"></Column>
                <Column field="from_date" header="Début" body={displayTimeFrom}></Column>
                <Column field="to_date" header="Fin" body={displayTimeTo}></Column>
                <Column field="number" header="N°"></Column>
                <Column field="to_collect" header="A collecter" body={displayToCollect}></Column>
                {Object.values(products)
                    .filter(
                        (p) =>
                            p.family === PRODUCT_FAMILY.DELIVERY_BIG_BAG || p.family === PRODUCT_FAMILY.COLLECT_BIG_BAG
                    )
                    .filter((p) => p.trash_type === ETrashType.MIXED)
                    .reduce((acc, prd) => {
                        if (acc.find((el) => el.name === prd.name)) return acc;
                        acc.push(prd);
                        return acc;
                    }, [] as ProductRo[])
                    .map((product, idx) => {
                        return (
                            <Column
                                field={product.id}
                                header={idx}
                                body={(r, o) => displayCollectedItem(r, o, idx)}
                            ></Column>
                        );
                    })}
                <Column field="hazard" header="Aléas" body={displayAlea}></Column>
                {selectedPlanning.some((c) => c.hazard) && (
                    <Column field="hazard_reazon" header="raison" body={displayHazardReazon}></Column>
                )}
                {selectedPlanning.some((c) => c.hazard) && (
                    <Column field="hazard_comment" header="commentaire" body={displayHazardComment}></Column>
                )}
            </DataTable>
            <br />
            <h3>Récapitulatif</h3>
            <div>
                <DataTable
                    value={mapRecap()}
                    tableStyle={{ minWidth: '50rem' }}
                    size="small"
                    emptyMessage="Veuillez sélectionner un planning."
                    loading={loading}
                >
                    <Column field="driver" header="Départ/Arrivée"></Column>
                    <Column field="service_success" header="Services"></Column>
                    <Column field="administrative_success" header="Administratifs"></Column>
                    <Column field="service_hazard" header="Aléas"></Column>
                    <Column field="emptying" header="Vidages"></Column>
                </DataTable>
                <Divider />
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Message
                        severity="info"
                        text="Entre chaque collecte, il y a 1 sec d'attente. Après un vidage, il y a 30 sec d'attente"
                    />
                    <Message
                        severity="warn"
                        text="Une fois la requete lancée, vous ne pourrez plus revenir en arrière."
                    />
                    <Button label="Enregistrer" icon="pi pi-check" loading={loading} onClick={confirm} />
                </div>
            </div>
            <Dialog
                header="Requêtes en cours"
                visible={showModal}
                modal={false}
                style={{ width: '50vw' }}
                onHide={() => setShowModal(false)}
            >
                <ProgressBar value={progressInfo}></ProgressBar>
            </Dialog>
            <ConfirmDialog />
        </div>
    );
};
