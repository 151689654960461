import { Button } from 'primereact/button';
import { Column, ColumnBodyOptions, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { deepCopy, match } from '@bbng/util/misc';

export type InputListBaseProps<Ro> = {
    id?: string;
    title?: string;
    value?: Array<Ro>;
    onChange?: (value: Array<Ro>, errors: string[] | null, id: string) => void;
    columns?: Array<ColumnProps>;
    readOnly?: boolean;
    disableModalButton?: boolean | ((modalState: Ro | undefined) => boolean);
    disableEditButton?: boolean;
    disableDeleteButton?: boolean;
    scrollHeight?: string;
    modalTemplate?: (
        state: Ro | undefined,
        setter: React.Dispatch<React.SetStateAction<Ro | undefined>>
    ) => JSX.Element;
    disableAddButton?: boolean;
    setErrors: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
    onEdit?: (data: Ro) => void;
    onClose?: () => void;
};

export function InputListBase<Ro>({
    id = '',
    title,
    value,
    onChange = () => void 0,
    columns = [],
    scrollHeight = '400px',
    disableModalButton = false,
    disableEditButton = false,
    disableDeleteButton = false,
    readOnly = false,
    modalTemplate,
    disableAddButton = false,
    setErrors,
    onEdit,
    onClose
}: InputListBaseProps<Ro>) {
    const [modalInputs, setModalInputs] = React.useState<Ro | undefined>();
    const [editIndex, setEditIndex] = React.useState<number | undefined>();
    const [showModal, setShowModal] = React.useState(false);
    const [data, setData] = React.useState<Array<Ro>>(value ?? []);

    React.useEffect(() => {
        onChange(data, null, id);
    }, [JSON.stringify(data)]);

    React.useEffect(() => {
        if (!match(value, data)) {
            setData(value ?? []);
        }
    }, [value]);

    const onShow = () => {
        setErrors({});
        setShowModal(true);
    };

    const onHide = () => {
        setEditIndex(undefined);
        setModalInputs(undefined);
        setShowModal(false);
        onClose?.();
    };

    const onConfirm = useCallback(() => {
        if (modalInputs) {
            if (editIndex !== undefined) {
                setData((old) => {
                    old.splice(editIndex, 1, modalInputs);
                    return old;
                });
            } else {
                setData((old) => [...old, modalInputs]);
            }
        }
        onHide();
    }, [setData, editIndex, modalInputs]);

    const deleteColumnBody = (_: Ro, options: ColumnBodyOptions) => {
        return (
            <Button
                disabled={readOnly}
                type="button"
                icon="pi pi-times"
                className="p-button-rounded p-button-danger p-button-text"
                onClick={() => {
                    const newData = data.filter((_, i) => options.rowIndex !== i);
                    setData(newData);
                }}
            />
        );
    };

    const updateColumnBody = (_: Ro, options: ColumnBodyOptions) => {
        return (
            <Button
                disabled={readOnly}
                type="button"
                icon="pi pi-pencil"
                className="p-button-rounded p-button-text"
                onClick={() => {
                    const dataInputs = data[options.rowIndex];
                    setModalInputs(dataInputs);
                    setEditIndex(options.rowIndex);
                    onEdit?.(dataInputs);
                    onShow();
                }}
            />
        );
    };

    const footer = () => (
        <div>
            <Button
                disabled={disableModalButton instanceof Function ? disableModalButton(modalInputs) : disableModalButton}
                label={editIndex !== undefined ? 'Sauvegarder' : 'Ajouter'}
                type="button"
                icon="pi pi-check"
                onClick={onConfirm}
                autoFocus
            />
        </div>
    );

    return (
        <div>
            {!readOnly && modalTemplate && !disableAddButton && (
                <>
                    <StyledButton type="button" icon="pi pi-plus" iconPos="right" label={title} onClick={onShow} />
                </>
            )}
            <DataTable
                size="small"
                value={data}
                scrollable
                scrollHeight={scrollHeight}
                scrollDirection="vertical"
                emptyMessage="Aucun élément"
            >
                {columns.map((columnProps) => (
                    <Column key={JSON.stringify(columnProps)} {...columnProps} />
                ))}
                {!readOnly && !disableEditButton && (
                    <Column body={updateColumnBody} headerStyle={{ maxWidth: '3em' }} bodyStyle={{ maxWidth: '3em' }} />
                )}
                {!readOnly && !disableDeleteButton && (
                    <Column body={deleteColumnBody} headerStyle={{ maxWidth: '3em' }} bodyStyle={{ maxWidth: '3em' }} />
                )}
            </DataTable>
            <StyledDialog
                draggable={false}
                resizable={false}
                blockScroll={false}
                header={title}
                visible={showModal}
                onHide={onHide}
                footer={footer}
                contentStyle={{ overflow: 'visible' }}
            >
                {modalTemplate ? modalTemplate(modalInputs, setModalInputs) : null}
            </StyledDialog>
        </div>
    );
}

const StyledDialog = styled(Dialog)`
    min-width: 500px;
`;

const StyledButton = styled(Button)`
    margin-bottom: 10px;
`;
