import styled from 'styled-components';

export const InputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
`;

export const PlanningRightHandInputsContainer = styled.div`
    display: flex;
    gap: 16px;
    .button-part {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .button-row {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: flex-end;
    }
`;

export const PlanningLeftHandInputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    .column-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
    }
`;

export const ZoomContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: end;
`;

export const Header = styled.div`
    margin: 0 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 15px;
    border-bottom: 1px solid #33333333;
`;

export const HorizontalContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
`;

export const Subtitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0;
    text-align: center;
`;
