import Stripe from 'stripe';
import { BillingBranch } from '../misc';
import { IKafkaQuery } from '../kafka';

export type StripeCustomerRo = Stripe.Customer & {
    metadata?: {
        customer_id?: string;
        user_id?: string;
    };
};
export type StripeCustomerRetrieveRo = Stripe.Customer | Stripe.DeletedCustomer;
export type StripeCreateCustomerDto = Stripe.CustomerCreateParams & {
    metadata: {
        customer_id?: string;
        user_id?: string;
    };
};
export type StripeEditCustomerDto = Stripe.CustomerUpdateParams & {
    metadata?: {
        customer_id?: string;
        user_id?: string;
    };
};

export type StripeInvoiceRo = Stripe.Invoice & {
    metadata: {
        order_id?: string;
    };
};
// export type StripeInvoiceRetrieveRo = Stripe.Invoice | Stripe.DeletedInvoice;
// export type StripeCreateInvoiceDto = Stripe.InvoiceCreateParams;

// export type StripeInvoiceItemRo = Stripe.InvoiceItem;
// export type StripeInvoiceItemRetrieveRo = Stripe.InvoiceItem | Stripe.DeletedInvoiceItem;
// export type StripeCreateInvoiceItemDto = Stripe.InvoiceItemCreateParams;

export type StripeCreateCheckoutSessionDto = Omit<Stripe.Checkout.SessionCreateParams, 'metadata'> & {
    metadata: {
        order_id?: string;
        order_number?: string;
        success_callback_url?: string;
        cancel_callback_url?: string;
    };
};

export type StripeCheckoutSessionRo = Stripe.Checkout.Session;

export type StripeCheckoutSessionStatus = Stripe.Checkout.Session.Status;

export type StripeCreateCouponDto = Stripe.CouponCreateParams;
export type StripeCouponRo = Stripe.Coupon;
export type StripeCouponRetrieveRo = Stripe.Coupon | Stripe.DeletedCustomer;

export type StripeCreateRefundDto = Stripe.RefundCreateParams;
export type StripeRefundRo = Stripe.Refund;

export enum EStripeEvents {
    // INVOICE_PAID = 'invoice.paid',
    CHECKOUT_SESSION_COMPLETED = 'checkout.session.completed'
}

export const enum EStripeCurrency {
    EUR = 'EUR'
}

export interface StripeQuery extends IKafkaQuery {
    billingBranch: BillingBranch;
}
