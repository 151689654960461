import styled from 'styled-components';

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const ScrollableContainer = styled.div`
    overflow: auto;
    max-height: 60px;
    padding: 0 16px;
`;

export const StyleSpan = styled.span`
    padding: 12px;
    width: 100%;
    font-size: 1em;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    & .p-accordion {
        width: 100%;
    }
    & .p-accordion-header-link {
        padding: 0 1.25em !important;
    }
    & .p-accordion-content {
        background: transparent;
        display: flex;
        flex-wrap: wrap;
        padding: 8px;
    }
    & .p-accordion-tab {
        width: 100%;
        background: transparent;
        margin: 0;
    }
`;
