import Joi from 'joi';

import { EItemStatus, ItemRo } from '.';
import { generateRelationsSchema } from '../dto';

export const ItemCreateDtoSchema = Joi.object({
    test_name    : Joi.string(),
    name         : Joi.string().required(),
    pack_barcode : Joi.string(),
    barcode      : Joi.string().required(),
    status       : Joi.string()
        .valid(...Object.values(EItemStatus))
        .required(),
    customer_id    : Joi.array().items(Joi.string()),
    user_id        : Joi.array().items(Joi.string()),
    pickable_date  : Joi.date().iso(),
    collected_date : Joi.date().iso()
});

export const ItemCsvHeaderSchema = Joi.array().ordered(
    Joi.string().valid('barcode').required(),
    Joi.string().valid('name').required(),
    Joi.string().valid('pack_barcode')
);

export const ItemCsvRowSchema = Joi.array().ordered(
    Joi.string().required(),
    Joi.string().required(),
    Joi.string().allow(null, '')
);

export const ItemEditDtoSchema = Joi.object({
    test_name      : Joi.string(),
    name           : Joi.string(),
    pack_barcode   : Joi.string(),
    barcode        : Joi.string(),
    status         : Joi.string().valid(...Object.values(EItemStatus)),
    pickable_date  : Joi.date().iso(),
    collected_date : Joi.date().iso()
}).concat(generateRelationsSchema<ItemRo>('user_id', 'customer_id'));
