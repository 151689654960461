import { SpeedDial } from 'primereact/speeddial';
import styled from 'styled-components';

export const StyledSpeedDial = styled(SpeedDial)`
    position: fixed;
    right: 25px;
    top: 25px;
    z-index: 1000;
`;

export const SpeedDialContainer = styled.div`
    /**
    * Display none to avoid having invisible div above layout.
    * TODO: Handle animation
    */
    & :not(.p-speeddial-opened) {
        & .p-speeddial-list {
            display: none;
        }
    }
`;
