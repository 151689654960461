import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BaseContainer = styled.div`
    height: 100vh;
    width: 60px;
    z-index: 10000;
    background-color: blue;
    position: fixed;
`;

export const SidebarContainer = styled.div`
    z-index: 100;
    width: 60px;
    height: 100%;
    transition: 0.2s;
    padding: 40px 20px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: white;
    overflow-x: hidden;
    &:hover {
        box-shadow: 2px 3px 4px rgb(0 0 0 / 10%);
        width: 300px;
        .logo-text {
            opacity: 1;
        }
        .sublinks {
            opacity: 1;
            display: flex;
        }
    }
    .category:hover {
        .sublinks {
            display: flex;
        }
    }
    & > * + * {
        padding-top: 18px;
    }
`;

export const Environment = styled.div`
    max-width: fit-content;
    background-color: orange;
    border-radius: 15px;
    padding: 10px 0;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    .env {
        display: flex;
        justify-content: space-around;
    }
`;

export const ButtonsContainer = styled(Link)<{ disabled?: boolean }>`
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    ${({ disabled }) => (disabled ? 'pointer-events: none;' : '')};
    transition: 0.2s;
    font-size: 1.1rem;
    z-index: 100;
    display: flex;
    flex-wrap: nowrap;
    color: black;
    .logo {
        width: 20px;
        display: grid;
        place-items: center;
        & > * {
            width: 20px;
            height: 20px;
        }
    }
    .logo-text {
        font-size: 20px;
        font-weight: semibold;
        margin-left: 10px;
        transition: opacity 0.2s;
        opacity: 0;
        white-space: nowrap;
    }
`;

export const SubLinks = styled.div`
    opacity: 0;
    z-index: 100;
    display: none;
    flex-direction: column;
    align-items: stretch;
`;

export const SubLink = styled(Link)`
    transition: 0.1s;
    height: 32px;
    z-index: 100;
    margin: 0 -20px;
    padding-top: 8px;
    font-size: 14px;
    padding-left: 50px;
    color: black;
    flex-wrap: nowrap;
    white-space: nowrap;
    &:hover {
        background-color: var(--gray-800);
        color: white;
    }
`;

export const SubExternalLink = styled.a`
    transition: 0.1s;
    height: 32px;
    z-index: 100;
    margin: 0 -20px;
    padding-top: 8px;
    font-size: 14px;
    padding-left: 50px;
    color: black;
    flex-wrap: nowrap;
    white-space: nowrap;
    &:hover {
        background-color: var(--gray-800);
        color: white;
    }
`;

export const DashboardCard = styled.div`
    height: 100%;
    width: 300px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 16px;
    color: black;
    &:hover {
        animation: shake 0.5s;
        animation-iteration-count: infinite;
    }

    @keyframes shake {
        0% {
            transform: translate(1px, 1px) rotate(0deg);
        }
        10% {
            transform: translate(-1px, -2px) rotate(-1deg);
        }
        20% {
            transform: translate(-3px, 0px) rotate(1deg);
        }
        30% {
            transform: translate(3px, 2px) rotate(0deg);
        }
        40% {
            transform: translate(1px, -1px) rotate(1deg);
        }
        50% {
            transform: translate(-1px, 2px) rotate(-1deg);
        }
        60% {
            transform: translate(-3px, 1px) rotate(0deg);
        }
        70% {
            transform: translate(3px, 1px) rotate(-1deg);
        }
        80% {
            transform: translate(-1px, -1px) rotate(1deg);
        }
        90% {
            transform: translate(1px, 2px) rotate(0deg);
        }
        100% {
            transform: translate(1px, -2px) rotate(-1deg);
        }
    }
`;

export const DashboardCardDescription = styled.p`
    word-break: keep-all;
    font-style: italic;
    color: #00000099;
    margin-top: 6px;
`;

export const DashboardStateBar = styled.div<{ color: string }>`
    width: 100%;
    height: 6px;
    border-radius: 8px;
    background-color: ${({ color }) => color};
`;
