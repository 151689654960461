import { Tag } from 'primereact/tag';
import styled from 'styled-components';

import { capitalize, cleanUndefinedKeys } from '@bbng/util/misc';
import {
    AdminRo,
    CollectorRo,
    CollectQuery,
    ConstructionSiteRo,
    CustomerRo,
    ECollectorSkill,
    EPlanningRegion,
    EPlanningType,
    LandfillQuery,
    LandfillRo,
    ProductRo,
    PrestaRo,
    TruckRo,
    UserRo,
    ZoneRo,
    CollectService
} from '@bbng/util/types';

import { urlApiBuilder, urlBuilder } from '../../../common/urlBuilder';
import { RelationAutocompleteBase, RelationAutocompleteBaseProps } from './base';
import moment from 'moment';
import { mapCollectorSkill } from '../../../common/enumMapper';

type RelationAutocompleteProps<DataRo extends Record<string, any>> = {
    baseValue?: DataRo;
    itemTemplate?: (item: DataRo) => string | JSX.Element | React.ReactNode;
    onSelect?: RelationAutocompleteBaseProps<DataRo>['onSelect'];
    onUnselect?: RelationAutocompleteBaseProps<DataRo>['onUnselect'];
    readOnly?: boolean;
    id?: string;
    /**
     * You have to provide an id to the component if you want to display a label
     */
    label?: string;

    errors?: string[] | null;
    displayError?: boolean;
    placeholder?: string;
};

type UserRelationAutocompleteProps = RelationAutocompleteProps<UserRo> & {
    customerId?: string;
};

type ConstructionSiteRelationAutocompleteProps = RelationAutocompleteProps<ConstructionSiteRo> & {
    customerId?: string;
};

type TruckRelationAutocompleteProps = RelationAutocompleteProps<TruckRo> & {
    region?: EPlanningRegion;
};

type LandfillRelationAutocompleteProps = RelationAutocompleteProps<LandfillRo> & {
    region?: EPlanningRegion;
    type?: EPlanningType;
    owner_is_endless?: boolean;
};

type CollectorRelationAutocompleteProps = RelationAutocompleteProps<CollectorRo> & {
    has_landfill?: boolean;
    skill?: ECollectorSkill;
    skills?: ECollectorSkill[];
};

type CollectRelationAutocompleteProps = RelationAutocompleteProps<CollectService> & {
    query?: CollectQuery;
};

const RelationAutocomplete = {
    Base: function <T extends Record<string, any>>(props: RelationAutocompleteBaseProps<T>) {
        return <RelationAutocompleteBase<T> {...props} />;
    },
    Admin: (props: RelationAutocompleteProps<AdminRo>) => (
        <RelationAutocompleteBase<AdminRo>
            hideCreateButton
            suggestionFetchUrl={urlApiBuilder.adminGetAll()}
            itemTemplate={(item) => (
                <StyledSpan>
                    {item.fullname}
                    {item.archived && <ArchivedTag />}
                </StyledSpan>
            )}
            valueTemplate={(item) => item.fullname ?? item.id}
            field="fullname"
            {...props}
        />
    ),
    Collector: (props: CollectorRelationAutocompleteProps) => (
        <RelationAutocompleteBase<CollectorRo>
            queryParams={cleanUndefinedKeys({
                has_landfill : props.has_landfill,
                skill        : props.skill,
                skills       : props.skills
            })}
            createDataLabel="Ajouter un conducteur"
            createDataPageUrl={urlBuilder.collectorCreate()}
            suggestionFetchUrl={urlApiBuilder.collectorGetAll()}
            itemTemplate={(item) => (
                <StyledSpan>
                    {item.fullname ?? item.id}{' '}
                    {!item.is_available && (
                        <Tag severity="warning" icon="pi pi-exclamation-triangle">
                            Indisponible
                        </Tag>
                    )}
                    {item.archived && <ArchivedTag />}
                    {item.skills.map((skill) => (
                        <Tag>{mapCollectorSkill(skill)}</Tag>
                    ))}
                </StyledSpan>
            )}
            valueTemplate={(item) => item.fullname ?? item.id}
            field="fullname"
            {...props}
        />
    ),
    Customer: (props: RelationAutocompleteProps<CustomerRo>) => (
        <RelationAutocompleteBase<CustomerRo>
            createDataLabel="Ajouter un client"
            createDataPageUrl={urlBuilder.customerCreate()}
            suggestionFetchUrl={urlApiBuilder.customerGetAll()}
            valueTemplate={(item) => item.name ?? item.id}
            itemTemplate={(item) => (
                <StyledSpan>
                    {item.name} {item.internal_name ? `(${item.internal_name})` : ''} {item.archived && <ArchivedTag />}
                </StyledSpan>
            )}
            field="name"
            {...props}
        />
    ),
    User: (props: UserRelationAutocompleteProps) => (
        <RelationAutocompleteBase<UserRo>
            hideCreateButton={props.customerId === undefined}
            queryParams={cleanUndefinedKeys({ customer_id: props.customerId })}
            createDataLabel="Ajouter un utilisateur"
            createDataPageUrl={urlBuilder.userCreate()}
            suggestionFetchUrl={urlApiBuilder.userGetAll()}
            itemTemplate={(item) => (
                <StyledSpan>
                    {item.fullname} ({item.email}){item.archived && <ArchivedTag />}
                </StyledSpan>
            )}
            valueTemplate={(item) => item.fullname ?? item.email ?? item.id}
            field="fullname"
            {...props}
        />
    ),
    Product: (props: RelationAutocompleteProps<ProductRo>) => (
        <RelationAutocompleteBase<ProductRo>
            createDataLabel="Ajouter un produit"
            createDataPageUrl={urlBuilder.productCreate()}
            suggestionFetchUrl={urlApiBuilder.productGetAll('')}
            valueTemplate={(item) => item.name ?? item.id}
            itemTemplate={(item) => (
                <StyledSpan>
                    {item.name} {item.archived && <ArchivedTag />}
                </StyledSpan>
            )}
            field="name"
            {...props}
        />
    ),
    ConstructionSite: (props: ConstructionSiteRelationAutocompleteProps) => (
        <RelationAutocompleteBase<ConstructionSiteRo>
            // httpDirectRequest
            queryParams={cleanUndefinedKeys({ customer_id: props.customerId })}
            createDataLabel="Ajouter un chantier"
            createDataPageUrl={urlBuilder.constructionSiteCreate()}
            suggestionFetchUrl={urlApiBuilder.constructionSiteGetAll()}
            itemTemplate={(item) => (
                <StyledSpan>
                    {item.label}
                    {item.archived && <ArchivedTag />}
                </StyledSpan>
            )}
            valueTemplate={(item) => item.label ?? item.id}
            field="label"
            {...props}
        />
    ),
    Landfill: (props: LandfillRelationAutocompleteProps) => (
        <RelationAutocompleteBase<LandfillRo>
            queryParams={cleanUndefinedKeys({
                region           : props.region,
                collect_type     : props.type,
                owner_is_endless : props.owner_is_endless
            } as LandfillQuery)}
            createDataLabel="Ajouter une déchetterie"
            createDataPageUrl={urlBuilder.landfillCreate()}
            suggestionFetchUrl={urlApiBuilder.landfillGetAll()}
            itemTemplate={(item) => (
                <StyledSpan>
                    {item.name}
                    {item.archived && <ArchivedTag />}
                </StyledSpan>
            )}
            valueTemplate={(item) => item.name ?? item.id}
            field="name"
            {...props}
        />
    ),
    Zone: (props: RelationAutocompleteProps<ZoneRo>) => (
        <RelationAutocompleteBase<ZoneRo>
            hideCreateButton
            createDataLabel=""
            createDataPageUrl=""
            suggestionFetchUrl={urlApiBuilder.zoneGetAll()}
            itemTemplate={(item) => (
                <StyledSpan>
                    {item.name} ({capitalize(item.metropole)})
                </StyledSpan>
            )}
            valueTemplate={(item) => item.name ?? item.id}
            field="name"
            httpDirectRequest
            {...props}
        />
    ),
    Truck: (props: TruckRelationAutocompleteProps) => (
        <RelationAutocompleteBase<TruckRo>
            queryParams={cleanUndefinedKeys({ region: props.region })}
            hideCreateButton
            createDataLabel="Ajouter un camion"
            createDataPageUrl={urlBuilder.truckCreate()}
            suggestionFetchUrl={urlApiBuilder.truckGetAll()}
            itemTemplate={(item) => (
                <StyledSpan>
                    {item.name}
                    {item.archived && <ArchivedTag />}
                </StyledSpan>
            )}
            valueTemplate={(item) => item.name ?? item.id}
            field="name"
            {...props}
        />
    ),
    Presta: (props: RelationAutocompleteProps<PrestaRo>) => (
        <RelationAutocompleteBase<PrestaRo>
            createDataLabel="Ajouter un prestataire"
            createDataPageUrl={urlBuilder.prestaCreate()}
            suggestionFetchUrl={urlApiBuilder.prestaGetAll()}
            itemTemplate={(item) => (
                <StyledSpan>
                    {item.name ?? item.id} {item.archived && <ArchivedTag />}
                </StyledSpan>
            )}
            valueTemplate={(item) => item.name ?? item.id}
            field="name"
            {...props}
        />
    ),
    Collect: (props: CollectRelationAutocompleteProps) => (
        <RelationAutocompleteBase<CollectService>
            queryParams={cleanUndefinedKeys(props.query)}
            createDataLabel="Voir toutes les collectes"
            createDataPageUrl={urlBuilder.collectHistory()}
            suggestionFetchUrl={urlApiBuilder.collectGetAll()}
            itemTemplate={(item) => (
                <StyledSpan>
                    {item.informations.cc_number} - Traitée le {moment(item.arrived_at).format('DD/MM/YYYY à HH:mm')}
                    {item.archived && <ArchivedTag />}
                </StyledSpan>
            )}
            valueTemplate={(item) => item.informations.cc_number ?? item.id}
            field="informations.cc_number"
            {...props}
        />
    )
};

export default RelationAutocomplete;

const ArchivedTag = () => (
    <Tag severity="danger" icon="pi pi-exclamation-triangle">
        Archivé
    </Tag>
);

const StyledSpan = styled.span`
    display: flex;
    align-items: center;
    gap: 8px;
`;
