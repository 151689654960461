import {
    ConstructionSiteCreateDto,
    ConstructionSiteEditDto,
    ConstructionSiteQuery,
    ConstructionSiteRo,
    ConstructionSiteWithDocuments,
    CREATED_FROM,
    DocumentRo
} from '@bbng/util/types';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';

import {
    TDocument,
    getRelationsDto,
    mapBackContactToFrontContact,
    mapFrontContactToBackContact,
    optimiseEditDto
} from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { useRequest } from '../../hooks/StatelessRequest';
import {
    AddressErrorState,
    AddressState,
    initialErrorState as addressInitialErrorState,
    initialState as addressInitialState
} from '../../modules/common/Address';
import {
    ContactsErrorState,
    ContactsState,
    initialErrorState as constructionSiteContactsInitialErrorState,
    initialState as constructionSiteContactsInitialState
} from '../../modules/common/Contacts';
import {
    ConstructionSiteCustomerErrorState,
    ConstructionSiteCustomerState,
    initialErrorState as constructionSiteCustomerInitialErrorState,
    initialState as constructionSiteCustomerInitialState
} from '../../modules/construction-site/Customer';
import {
    ConstructionSiteDocumentsErrorState,
    ConstructionSiteDocumentsState,
    ConstructionSiteUploadsErrorState,
    ConstructionSiteUploadsState,
    initialDocumentsErrorState as constructionSiteDocumentInitialErrorState,
    initialDocumentsState as constructionSiteDocumentInitialState,
    initialUploadsErrorState as constructionSiteUploadInitialErrorState,
    initialUploadsState as constructionSiteUploadInitialState
} from '../../modules/construction-site/Document';
import {
    ConstructionSiteCollectorListErrorState,
    ConstructionSiteCollectorListState,
    initialErrorState as constructionSiteCollectorListInitialErrorState,
    initialState as constructionSiteCollectorListInitialState
} from '../../modules/construction-site/CollectorList';
import {
    ConstructionSiteGeneralErrorState,
    ConstructionSiteGeneralState,
    initialErrorState as constructionSiteGeneralInitialErrorState,
    initialState as constructionSiteGeneralInitialState
} from '../../modules/construction-site/General';
import { ModalIcon, ModalList, ModalTitle } from './style';

export type ConstructionSiteModulesStates =
    | ConstructionSiteGeneralState
    | ConstructionSiteCollectorListState
    | ContactsState
    | ConstructionSiteCustomerState
    | AddressState
    | ConstructionSiteDocumentsState
    | ConstructionSiteUploadsState;

export type ConstructionSiteModulesErrorStates =
    | ConstructionSiteGeneralErrorState
    | ConstructionSiteCollectorListErrorState
    | ContactsErrorState
    | ConstructionSiteCustomerErrorState
    | AddressErrorState
    | ConstructionSiteDocumentsErrorState
    | ConstructionSiteUploadsErrorState;

export type ConstructionSiteFormState = {
    general: ConstructionSiteGeneralState;
    collectorList: ConstructionSiteCollectorListState;
    contacts: ContactsState;
    customer: ConstructionSiteCustomerState;
    address: AddressState;
    documents?: ConstructionSiteDocumentsState;
    uploads?: ConstructionSiteUploadsState;
    document_removed?: string[];
};

export type ConstructionSiteFormErrorState = {
    general: ConstructionSiteGeneralErrorState;
    collectorList: ConstructionSiteCollectorListErrorState;
    contacts: ContactsErrorState;
    customer: ConstructionSiteCustomerErrorState;
    address: AddressErrorState;
    documents: ConstructionSiteDocumentsErrorState;
    uploads: ConstructionSiteUploadsErrorState;
};

export const initialState: ConstructionSiteFormState = {
    general       : constructionSiteGeneralInitialState,
    collectorList : constructionSiteCollectorListInitialState,
    contacts      : constructionSiteContactsInitialState,
    customer      : constructionSiteCustomerInitialState,
    address       : addressInitialState,
    documents     : constructionSiteDocumentInitialState,
    uploads       : constructionSiteUploadInitialState
};

export const initialErrorState: ConstructionSiteFormErrorState = {
    general       : constructionSiteGeneralInitialErrorState,
    collectorList : constructionSiteCollectorListInitialErrorState,
    contacts      : constructionSiteContactsInitialErrorState,
    customer      : constructionSiteCustomerInitialErrorState,
    address       : addressInitialErrorState,
    documents     : constructionSiteDocumentInitialErrorState,
    uploads       : constructionSiteUploadInitialErrorState
};

export const mapApiDataToState = (constructionSite: ConstructionSiteWithDocuments): ConstructionSiteFormState => {
    let documents: TDocument[] = [];

    if (constructionSite.document_id && constructionSite.document_id.length > 0) {
        documents = (constructionSite.document_id as unknown as DocumentRo[]).map<TDocument>((doc: DocumentRo) => ({
            type   : 'online',
            online : doc
        }));
    }

    return {
        customer: {
            customer: constructionSite.customer_id[0]
        },
        contacts: {
            construction_site:
                constructionSite.construction_site_contact?.map((c) => mapBackContactToFrontContact(c)) || [],
            logistic: constructionSite.log_contact?.map((c) => mapBackContactToFrontContact(c)) || []
        },
        general: {
            label      : constructionSite.label,
            complement : constructionSite.additional_info,
            tag        : constructionSite.characteristics
        },
        address: {
            address_google        : undefined,
            address_street_number : constructionSite.address.components['street_number'] ?? '',
            address_street_name   : constructionSite.address.components['route'] ?? '',
            address_city          : constructionSite.address.components['locality'] ?? '',
            address_zip_code      : constructionSite.address.components['postal_code'] ?? '',
            address_country       : constructionSite.address.components['country'] ?? '',
            address_complement    : constructionSite.address.components['complement'] ?? '',
            address_lat           : constructionSite.address.coordinates.latitude,
            address_lng           : constructionSite.address.coordinates.longitude
        },
        uploads: {
            documents          : documents,
            order_sheet_number : constructionSite.order_sheet_number ?? ''
        },
        collectorList: {
            listType      : constructionSite.list_collector_type,
            collectorList : constructionSite.list_collector_id
        }
    };
};

export const mapStateToApiCreateData = (state: ConstructionSiteFormState): ConstructionSiteCreateDto => {
    return {
        characteristics : state.general.tag,
        additional_info : state.general.complement,
        label           :
            !state.general.label || state.general.label === ''
                ? (state.address.address_google?.formatted_address as string)
                : state.general.label,
        customer_id               : [state.customer.customer?.id ?? ''],
        construction_site_contact : state.contacts.construction_site.map((c) => mapFrontContactToBackContact(c)),
        log_contact               : state.contacts.logistic.map((c) => mapFrontContactToBackContact(c)),
        order_sheet_number        : state.uploads?.order_sheet_number ?? '',
        address                   : {
            coordinates: {
                latitude  : state.address.address_lat!,
                longitude : state.address.address_lng!
            },
            name           : (state.address.address_street_name || state.address.address_google?.name) ?? '',
            formatted_name :
                (state.address.address_google?.formatted_address ||
                    state.address.address_street_name ||
                    state.address.address_google?.name) ??
                '',
            components: {
                street_number : state.address.address_street_number,
                route         : state.address.address_street_name,
                locality      : state.address.address_city,
                postal_code   : state.address.address_zip_code,
                country       : state.address.address_country,
                complement    : state.address.address_complement
            }
        },
        list_collector_type : state.collectorList.listType,
        list_collector_id   : state.collectorList.collectorList.map((e) => e.id),
        document_id         :
            state.documents?.documents && state.documents?.documents.length > 0
                ? [state.documents.documents[0]]
                : undefined,
        created_from: CREATED_FROM.BACK_OFFICE
    };
};

export const mapStateToApiEditData = (
    state: ConstructionSiteFormState,
    apiState: ConstructionSiteFormState,
    discount: ConstructionSiteRo
): ConstructionSiteEditDto => {
    const diff: ConstructionSiteEditDto = optimiseEditDto(
        {
            ...state,
            uploads: {
                order_sheet_number : state.uploads?.order_sheet_number ?? '',
                documents          : []
            }
        },
        apiState,
        discount,
        mapStateToApiCreateData,
        getRelationsDto<ConstructionSiteRo>()
    );

    if (
        JSON.stringify(state.collectorList.collectorList.map((e) => e.id)) !==
        JSON.stringify(apiState.collectorList.collectorList.map((e) => e.id))
    ) {
        diff.list_collector_id = state.collectorList.collectorList.map((e) => e.id);
    }

    const document_id_add =
        state.documents?.documents && state.documents?.documents.length > 0 ? state.documents.documents : undefined;
    let document_id_remove: string[] = state.document_removed ?? [];

    if (state?.documents?.documents) {
        if (apiState.documents?.documents && apiState.documents.documents.length > 0) {
            document_id_remove = apiState?.documents?.documents.map((doc) => {
                if (state.documents?.documents && state.documents.documents.length > 0) {
                    const found = state.documents.documents.find((doc2) => doc.id === doc2.id);
                    if (!found) return doc.id;
                }
                return '';
            });
        }
    } else {
        apiState?.uploads?.documents.map((doc) => {
            if (doc?.online?.id) {
                document_id_remove.push(doc.online.id);
            }
        });
    }
    return {
        ...diff,
        document_id:
            (document_id_add?.length ?? 0) <= 0 && document_id_remove.length <= 0
                ? undefined
                : {
                      add    : document_id_add ? document_id_add : undefined,
                      remove : document_id_remove.length > 0 ? document_id_remove[0] : undefined
                  }
    };
};

export const useHandleExisting = (values: ConstructionSiteFormState) => {
    const bbngRequest = useRequest();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const checkExisting = async (address: string, customerId: string) => {
        setIsLoading(true);
        const response = await bbngRequest<ConstructionSiteRo[]>({
            method  : 'GET',
            url     : urlApiBuilder.constructionSiteCheckExiting(),
            payload : {
                queryParams: {
                    formatted_name : address,
                    customer_id    : customerId
                } as ConstructionSiteQuery
            },
            options: { toastifySuccess: false }
        });

        setIsLoading(false);

        if (response.success && response.response?.data.ro) {
            const existingCount = response.response.data.count;
            const existingCS = response.response.data.ro;
            const pluralVerb = existingCount > 1 ? 'nt' : '';
            const pluralNoun = existingCount > 1 ? 's' : '';

            if (existingCount > 0) {
                confirmDialog({
                    header: (
                        <ModalTitle>
                            <ModalIcon className="pi pi-exclamation-triangle" />
                            {existingCount} Doublon{pluralNoun} détecté{pluralNoun}
                        </ModalTitle>
                    ),
                    message: (
                        <>
                            Le{pluralNoun} chantier{pluralNoun} suivant{pluralNoun} semble{pluralVerb} être à la même
                            adresse, êtes-vous sûr de vouloir continuer ?
                            <ModalList>
                                <DataTable value={existingCS}>
                                    <Column
                                        field="label"
                                        header="Nom"
                                        body={(val: ConstructionSiteRo) => {
                                            return (
                                                <a
                                                    href={urlBuilder.constructionSiteView(val.id)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {val.label}
                                                </a>
                                            );
                                        }}
                                    />
                                    <Column field="address.formatted_name" header="Adresse" />
                                    <Column
                                        field="archived"
                                        header="Archivé"
                                        body={(val: ConstructionSiteRo) => {
                                            return val.archived ? 'Oui' : 'Non';
                                        }}
                                    />
                                </DataTable>
                            </ModalList>
                        </>
                    ),
                    acceptLabel     : "J'ai compris",
                    rejectClassName : 'hidden'
                });
            } else {
                return;
            }
        }
    };

    useEffect(() => {
        if (values.address.address_google?.formatted_address && values.customer.customer?.id) {
            checkExisting(values.address.address_google.formatted_address, values.customer.customer.id);
        }
    }, [values.address.address_google?.formatted_address, values.customer.customer?.id]);

    return { isLoading };
};
