import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { useDragLayer } from 'react-dnd';
import { Chips } from 'primereact/chips';

import { CardType, MonitorItem, useGlobalConfigContext } from './provider';
import { CardCC } from './cc-card';
import { Card } from './card';
import { CardEmptying } from './emptying-card';
import { MenuItem } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Dialog } from '../../../components/Dialog';
import RelationAutocomplete from '../../common/RelationAutocomplete';
import { InputNumber } from 'primereact/inputnumber';
import {
    CC_FAMILY,
    CollectorRo,
    ECollectorSkill,
    EPlanningType,
    ETruckType,
    LandfillRo,
    PRODUCT_DUMPSTER_TYPE
} from '@bbng/util/types';
import Calendar from '../../../components/Calendar';
import { mapDumpsterTypeToHexa, mapTruckType } from '../../../common/enumMapper';
import { checkCCTruckCollectorCompatibility } from '@bbng/util/misc';
import { StyledTruckTypeTag } from './style';

type TruckPlanningBucketProps = {
    id: string;
};
export const TruckPlanningBucket: React.FC<TruckPlanningBucketProps> = (props) => {
    const {
        plannings,
        planningRegistry,
        addLandfillCard,
        editPlanningConfig,
        editVisibilityPlanning,
        importList,
        ccNumberExists,
        type
    } = useGlobalConfigContext();
    const planning = React.useMemo(() => planningRegistry[props.id][0], [planningRegistry, props.id]);

    const refMenu = React.useRef<Menu>(null);

    // Modal Import states
    const [importIds, setImportIds] = React.useState<string[]>([]);

    // Modal Add Emptying states
    const [selectedLandill, setSelectedLandill] = React.useState<LandfillRo | undefined>(undefined);
    const [waitingTimeMinutesState, setWaitingTimeMinutesState] = React.useState<number>(0);

    // Modal Edit Config states
    const [selectedCollector, setSelectedCollector] = React.useState<CollectorRo | undefined>(planning.collector);
    const [shiftHours, setShiftHours] = React.useState<[string, string]>([
        moment(planning.startShiftAt).toISOString(),
        moment(planning.endShiftAt).toISOString()
    ]);
    const invalidCollector = React.useMemo(() => {
        const collectorsIds = plannings.filter((f) => f.collector && f.id !== props.id).map((e) => e.collector!.id);

        return selectedCollector && collectorsIds.includes(selectedCollector.id);
    }, [props, plannings, selectedCollector, planning.collector]);

    const [action, setAction] = React.useState<'ADD_EMPTYING' | 'EDIT_CONFIG' | 'VISIBILITY' | 'IMPORT' | null>(null);

    const resetModalStates = React.useCallback(() => {
        setShiftHours([moment(planning.startShiftAt).toISOString(), moment(planning.endShiftAt).toISOString()]);
        setSelectedCollector(planning.collector);
        setSelectedLandill(undefined);
        setWaitingTimeMinutesState(0);
        setImportIds([]);
    }, [planning]);

    const { isItemDragging, itemDrag } = useDragLayer((monitor) => ({
        isItemDragging : monitor.isDragging(),
        itemDrag       : monitor.getItem<MonitorItem>()
    }));

    const menuOptions = React.useMemo<MenuItem[]>(() => {
        const options: MenuItem[] = [];

        if (planning.collector) {
            options.push({
                label   : 'Passage en déchèterie',
                icon    : 'pi pi-plus',
                command : () => {
                    setAction('ADD_EMPTYING');
                }
            });

            options.push({
                label   : 'Importer des collectes',
                icon    : 'pi pi-upload',
                command : () => {
                    setAction('IMPORT');
                }
            });
        }

        options.push(
            {
                label   : 'Modifier la configuration',
                icon    : 'pi pi-pencil',
                command : () => {
                    setAction('EDIT_CONFIG');
                }
            },
            {
                icon    : 'pi pi-eye',
                label   : 'Cacher le planning',
                command : () => {
                    setAction('VISIBILITY');
                }
            }
        );

        return options;
    }, [planningRegistry]);

    const showSpacer = React.useMemo(() => {
        let dragItemCompatibleWithColumn = true;
        /**
         * Ensure that the dragged item is compatible with the column
         */
        if (itemDrag?.extra?.category === 'CC') {
            const cc = itemDrag.extra.cc;
            if (cc.family !== CC_FAMILY.ADMINISTRATIVE) {
                dragItemCompatibleWithColumn = checkCCTruckCollectorCompatibility({
                    cc,
                    truck        : planning.truck,
                    collector    : planning.collector,
                    throwOnError : false
                });
            } else {
                /**
                 * Block admin cc to be dropped on uncompatible columns
                 */
                if (cc.collector_id[0] && cc.collector_id[0] !== planning.collector?.id) {
                    dragItemCompatibleWithColumn = false;
                }
            }
        }
        return (
            isItemDragging &&
            (itemDrag.columnId !== planning.id || itemDrag.columnIndex !== planning.timeline.length - 1) &&
            planning.collector &&
            dragItemCompatibleWithColumn
        );
    }, [isItemDragging, itemDrag, planning.timeline.length]);

    const typesToDisplay: ETruckType[] = React.useMemo(() => {
        if (type === EPlanningType.DUMPSTER) {
            return planning.truck.type.filter((tp) =>
                [ETruckType.DUMPSTER_AMPLIROLL, ETruckType.DUMPSTER_CHAIN, ETruckType.NARROW_STREET].includes(tp)
            );
        } else {
            return planning.truck.type.filter((tp) => tp === ETruckType.NARROW_STREET);
        }
    }, [type, planning]);

    const collectorSkills: ECollectorSkill[] = React.useMemo(() => {
        if (type === EPlanningType.BIG_BAG) {
            return [ECollectorSkill.BIG_BAG];
        } else {
            return planning.truck.type.filter((tpe) =>
                Object.values(PRODUCT_DUMPSTER_TYPE).includes(tpe as PRODUCT_DUMPSTER_TYPE)
            ) as unknown as ECollectorSkill[];
        }
    }, [planning.truck, type]);

    return (
        <StyledCard key={props.id + '-card'}>
            <div className="sticky-header">
                <div className="truck-bucket-header">
                    <div>{planning.truck.name}</div>
                    <div>{planning.collector ? planning.collector?.fullname : 'Chauffeur non assigné'}</div>
                    <b>
                        {moment(planning.startShiftAt).format('HH:mm')} - {moment(planning.endShiftAt).format('HH:mm')}
                    </b>
                    <div>
                        {typesToDisplay.map((type) => (
                            <StyledTruckTypeTag
                                key={type}
                                value={mapTruckType(type, true)}
                                backgroundColor={
                                    type === ETruckType.NARROW_STREET
                                        ? '#FFA500'
                                        : mapDumpsterTypeToHexa(type as PRODUCT_DUMPSTER_TYPE)
                                }
                            />
                        ))}
                    </div>
                    <Button
                        icon="pi pi-ellipsis-v"
                        className="button-more p-button-text"
                        onClick={(event) => {
                            resetModalStates();
                            refMenu.current?.toggle(event);
                        }}
                    />
                </div>
            </div>
            <div className="truck-bucket-body">
                {planning.timeline.map((cc, i) =>
                    cc.category === 'CC' ? (
                        <CardCC key={cc.gc_id} item={cc} columnId={planning.id} columnIndex={i} />
                    ) : (
                        <CardEmptying key={cc.gc_id} item={cc} columnId={planning.id} columnIndex={i} />
                    )
                )}
                {showSpacer && (
                    <Card
                        type={CardType.SPACER}
                        disabledOverEffect
                        item={{
                            columnId    : planning.id,
                            columnIndex : planning.timeline.length,
                            type        : CardType.SPACER,
                            id          : 'SPACER'
                        }}
                    />
                )}
            </div>
            <Menu ref={refMenu} model={menuOptions} popup />

            <Dialog
                onHide={() => {
                    setAction(null);
                }}
                visible={action === 'ADD_EMPTYING'}
                header={<h3 style={{ margin: 0, marginRight: '16px' }}>Ajouter un passage en déchèterie</h3>}
            >
                <h3 style={{ marginTop: 0 }}>Déchèterie</h3>
                <RelationAutocomplete.Landfill
                    region={planning.region}
                    type={planning.type}
                    baseValue={selectedLandill}
                    onSelect={(e) => {
                        if (!e) return;
                        setSelectedLandill(e);
                        setWaitingTimeMinutesState(e.average_waiting_minutes);
                    }}
                    onUnselect={() => setSelectedLandill(undefined)}
                />
                <h3>Temps de vidage (minutes)</h3>
                <InputNumber
                    size={6}
                    value={waitingTimeMinutesState}
                    onChange={(e) => e.value !== null && setWaitingTimeMinutesState(e.value)}
                />

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Button
                        label="Annuler"
                        className="p-button-text"
                        onClick={() => {
                            setAction(null);
                        }}
                    />
                    <Button
                        label="Ajouter"
                        className="p-button-text"
                        disabled={!selectedLandill}
                        onClick={() => {
                            setAction(null);
                            if (!selectedLandill) return;
                            addLandfillCard(props.id, selectedLandill, waitingTimeMinutesState);
                        }}
                    />
                </div>
            </Dialog>

            <Dialog
                onHide={() => {
                    setAction(null);
                }}
                visible={action === 'EDIT_CONFIG'}
                header={<h3 style={{ width: '350px', margin: 0, marginRight: '32px' }}>Configuration de tournée</h3>}
            >
                <h3 style={{ marginTop: 0 }}>Chauffeur</h3>
                <RelationAutocomplete.Collector
                    baseValue={selectedCollector}
                    onSelect={(e) => {
                        if (!e) return;
                        setSelectedCollector(e);
                    }}
                    onUnselect={() => setSelectedCollector(undefined)}
                    errors={invalidCollector ? ['Ce collecteur est déjà assigné à un planning'] : []}
                    displayError={invalidCollector}
                    skills={collectorSkills}
                />

                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <h3>Début de journée</h3>
                        <Calendar.HoursMins
                            id={`start_date`}
                            readOnly={false}
                            required={false}
                            value={moment(shiftHours[0]).toDate()}
                            result={(res) => setShiftHours([res.toISOString(), shiftHours[1]])}
                            stepMinute={15}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <h3>Fin de journée</h3>
                        <Calendar.HoursMins
                            id={`end_date`}
                            readOnly={false}
                            required={false}
                            value={moment(shiftHours[1]).toDate()}
                            result={(res) => setShiftHours([shiftHours[0], res.toISOString()])}
                            stepMinute={15}
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Button
                        label="Annuler"
                        className="p-button-text"
                        onClick={() => {
                            setAction(null);
                        }}
                    />
                    <Button
                        label="Sauvegarder"
                        className="p-button-text"
                        disabled={!selectedCollector || invalidCollector}
                        onClick={() => {
                            setAction(null);
                            if (!selectedCollector) return;
                            editPlanningConfig(props.id, {
                                collector    : selectedCollector,
                                startShiftAt : shiftHours[0],
                                endShiftAt   : shiftHours[1]
                            });
                        }}
                    />
                </div>
            </Dialog>

            <Dialog
                onHide={() => {
                    setAction(null);
                }}
                visible={action === 'VISIBILITY'}
                header={<h3 style={{ margin: 0, marginRight: '16px' }}>Cacher le planning</h3>}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Button
                        label="Annuler"
                        className="p-button-text"
                        onClick={() => {
                            setAction(null);
                        }}
                    />
                    <Button
                        label="Valider"
                        className="p-button-text"
                        onClick={() => {
                            setAction(null);
                            editVisibilityPlanning(props.id, false);
                        }}
                    />
                </div>
            </Dialog>

            <Dialog
                onHide={() => {
                    setAction(null);
                }}
                visible={action === 'IMPORT'}
                contentStyle={{ width: '35vw', minWidth: '500px' }}
                header={<h3 style={{ margin: 0, marginRight: '16px' }}>Importer les collectes</h3>}
            >
                <p>
                    Veuillez mettre un <b>retour à la ligne</b> ou une <b>virgule</b> entre chaque numéro de collecte.
                </p>
                <p>
                    <small>
                        Dans le cas de collectes <i>splittées</i>, veuillez mettre un{' '}
                        <b style={{ color: 'purple' }}>|</b> entre le numéro de collecte et le numéro de split (ex:
                        1024|0), sans celui-ci, la collecte ne sera pas trouvée.
                    </small>
                </p>
                <Button
                    label="Tout supprimer"
                    className="p-button-text"
                    onClick={() => {
                        setImportIds([]);
                    }}
                />

                <StyledChips
                    value={importIds}
                    onChange={(e) => setImportIds(e.value)}
                    style={{ width: '100%' }}
                    allowDuplicate={false}
                    placeholder="Numéros de collectes"
                    separator={','}
                    itemTemplate={(item: string) => {
                        return (
                            <span style={{ color: ccNumberExists(item) ? 'green' : 'red' }}>
                                {item.split('|')[0]}
                                {item.split('|')[1] && <span> ({item.split('|')[1]})</span>}
                            </span>
                        );
                    }}
                />

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Button
                        label="Annuler"
                        className="p-button-text"
                        onClick={() => {
                            setAction(null);
                        }}
                    />
                    <Button
                        label="Valider"
                        className="p-button-text"
                        onClick={() => {
                            setAction(null);
                            importList(props.id, importIds);
                        }}
                    />
                </div>
            </Dialog>
        </StyledCard>
    );
};

const StyledChips = styled(Chips)`
    .p-chips-multiple-container {
        width: 100%;
    }
`;

const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* padding: 8px; */
    margin-right: 8px;
    text-align: center;
    width: 250px;
    min-width: 250px;
    .sticky-header {
        position: sticky;
        top: 0;
        z-index: 1;
    }
    .truck-bucket-header {
        .button-more {
            position: absolute;
            right: 8px;
            top: 8px;
            color: black;
            height: 20px;
            width: 20px;
        }

        position: relative;
        background-color: #f0f0f0;
        padding-top: 8px;
        height: 100px;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .truck-bucket-body {
        background-color: #f6f6f6;
        padding: 8px;
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;
