import { SelectButton } from 'primereact/selectbutton';
import React from 'react';
import styled from 'styled-components';

import { CardErrors, ConstructionSiteRo, CustomerRo, ZoneRo } from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import ProgressBar from '../../components/ProgressBar';
import { useRequest } from '../../hooks/StatelessRequest';
import RelationAutocomplete from '../common/RelationAutocomplete';

export type DiscountCustomerProps = {
    edit?: boolean;
    readOnly?: boolean;
    value?: DiscountCustomerState;
    id: string;
    result: (
        value: DiscountCustomerState,
        errors: null | string[] | CardErrors<DiscountCustomerState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export enum ESpecificOption {
    GLOBAL = 'GLOBAL',
    CONS_SITE = 'CONS_SITE',
    ZONE = 'ZONE'
}
export const mapSpecificOptions = (option: ESpecificOption): string => {
    switch (option) {
        case ESpecificOption.CONS_SITE:
            return 'Chantier spécifique';
        case ESpecificOption.ZONE:
            return 'Zone spécifique';
        case ESpecificOption.GLOBAL:
            return 'Global';
    }
};

export type DiscountCustomerState = {
    customer?: CustomerRo;
    specific: ESpecificOption;
    constructionSite?: ConstructionSiteRo;
    zones?: ZoneRo[];
};
export type DiscountCustomerErrorState = CardErrors<DiscountCustomerState>;

export const initialState: DiscountCustomerState = {
    customer         : undefined,
    specific         : ESpecificOption.GLOBAL,
    constructionSite : undefined,
    zones            : undefined
};
export const initialErrorState: DiscountCustomerErrorState = Object.fromEntries(
    Object.keys(initialState).map((k) => [k, null])
) as DiscountCustomerErrorState;

export const DiscountCustomer: React.FC<DiscountCustomerProps> = ({
    edit = false,
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: DiscountCustomerProps) => {
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : false
    });
    const [zonesAvailable, setZonesAvailable] = React.useState<ZoneRo[]>();

    const [val, setVal] = React.useState<DiscountCustomerState>(value);
    const [err, setErr] = React.useState<DiscountCustomerErrorState>(initialErrorState);

    React.useEffect(() => {
        if (readOnly) return;

        const error: DiscountCustomerErrorState = {
            customer         : val.customer ? null : ['Le client est requis'],
            constructionSite : null,
            specific         : null,
            zones            : null
        };
        setErr(error);
    }, []);

    React.useEffect(() => {
        if (val.specific === ESpecificOption.CONS_SITE) {
            setErr((prev) => ({ ...prev, constructionSite: ['Le chantier est requis'], zone: null }));
            setVal((prev) => ({ ...prev, zones: undefined }));
        }
        if (val.specific === ESpecificOption.ZONE) {
            setErr((prev) => ({ ...prev, constructionSite: null, zone: null }));
            setVal((prev) => ({ ...prev, constructionSite: undefined }));
        }
        if (val.specific === ESpecificOption.GLOBAL) {
            setErr((prev) => ({ ...prev, constructionSite: null, zones: null }));
            setVal((prev) => ({ ...prev, constructionSite: undefined, zones: undefined }));
        }
    }, [val.specific]);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    React.useEffect(() => {
        bbngRequest<ZoneRo[]>({
            method  : 'GET',
            sync    : true,
            url     : urlApiBuilder.zoneGetAll(),
            payload : {
                queryParams: {
                    no_limit: true
                }
            }
        }).then((res) => {
            if (res.success) {
                setZonesAvailable(res.response?.data?.ro ?? []);
            }
        });
    }, []);

    const handleChange = (
        value: CustomerRo | ConstructionSiteRo | boolean | string | string[] | ZoneRo | undefined,
        errors: string[] | null,
        childId: string
    ) => {
        setVal((prev) => {
            const state = { ...prev, [childId]: value };
            return state;
        });
        setErr((prev) => {
            const state = { ...prev, [childId]: errors };
            return state;
        });
    };

    return (
        <StyledCard title="Client">
            <FullLineInput>
                <RelationAutocomplete.Customer
                    id="customer-relation"
                    label="Selectionner un client"
                    readOnly={readOnly || edit}
                    baseValue={val.customer}
                    onSelect={(value) => {
                        handleChange(value, null, 'customer');
                    }}
                    onUnselect={() => {
                        handleChange(undefined, ['Le client est requis'], 'customer');
                    }}
                    displayError={displayError}
                    errors={err.customer}
                />
            </FullLineInput>
            <CenteredInput>
                <Button.Select
                    forceSelection
                    id="specific"
                    required={true}
                    readOnly={readOnly || val.customer === undefined}
                    options={Object.values(ESpecificOption)}
                    errors={err.specific}
                    displayError={displayError}
                    result={handleChange}
                    value={val.specific}
                    labelMapper={(value) => mapSpecificOptions(value as ESpecificOption)}
                />
            </CenteredInput>
            {val.specific !== ESpecificOption.GLOBAL && (
                <FullLineInput>
                    {val.specific === ESpecificOption.CONS_SITE ? (
                        <RelationAutocomplete.ConstructionSite
                            id="constructionSite"
                            label="Selectionner un chantier"
                            readOnly={readOnly || val.customer === undefined}
                            customerId={val.customer?.id}
                            baseValue={val.constructionSite}
                            onSelect={(value) => {
                                handleChange(value, null, 'constructionSite');
                            }}
                            onUnselect={() => {
                                handleChange(undefined, ['Le chantier est requis'], 'constructionSite');
                            }}
                            displayError={displayError}
                            errors={err.constructionSite}
                        />
                    ) : zonesAvailable ? (
                        <CenteredInput>
                            <div>
                                {err.zones && (
                                    <small className="p-invalid" style={{ color: '#e24c4c' }}>
                                        {err.zones.join(', ')}
                                    </small>
                                )}
                                <SelectButton
                                    multiple
                                    optionLabel="name"
                                    onChange={(e) => {
                                        handleChange(e.value, null, 'zones');
                                    }}
                                    options={zonesAvailable}
                                    value={val.zones}
                                />
                            </div>
                        </CenteredInput>
                    ) : (
                        <ProgressBar loadingText="Chargement des zones..." />
                    )}
                </FullLineInput>
            )}
        </StyledCard>
    );
};

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)`
    .p-card-content > * + * {
        margin-top: 10px;
    }
`;

const CenteredInput = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;
