import styled from 'styled-components';

import { CREATED_FROM, CustomerRo, EUserRole, UserQuery, UserRo } from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { USER_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import useQuery from '../../hooks/Query';
import { useState } from 'react';
import { formatE164ToInternational } from '@bbng/util/misc';
import { Tag } from 'primereact/tag';
import { createdFromOptions, mapCreatedFrom, mapUserRole } from '../../common/enumMapper';
import { Dropdown } from 'primereact/dropdown';

const RoleOptions = Object.values(EUserRole).map((role) => ({
    name  : mapUserRole(role),
    value : role
}));

const StyledDropdown = styled(Dropdown)`
    width: 300px;
`;

const TagList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const Anchor = styled.a`
    word-break: break-all;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #343699;
`;

const FiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

const StyledTag = styled(Tag)<{ color?: string }>`
    ${({ color }) => color && `background-color: ${color};`}
`;

type QueryParams = {
    customer_id?: string;
    role?: EUserRole;
    created_from?: CREATED_FROM;
};

export const UsersListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [customer_id, setCustomerId] = useState<string | undefined>(query.customer_id);
    const [role, setRole] = useState<EUserRole | undefined>(query.role);
    const [created_from, setCreatedFrom] = useState<CREATED_FROM | undefined>(query.created_from);

    return (
        <Listing<any, UserRo>
            url={USER_ORCHESTRATION_BASE_URL}
            endpoint="users"
            name="Utilisateur"
            pluralName="Utilisateurs"
            hideBulkMenu={true}
            addButtonUrl={urlBuilder.userCreate()}
            displaySelectColumn={false}
            enrichData={async (data) => {
                data.data.ro = await fetchDataRelation(data.data.ro ?? [], { customer_id: true });
                return data;
            }}
            searchPlaceholder={`Rechercher par nom, prénom, e-mail`}
            leftHandedComponent={
                <FiltersContainer>
                    <RelationAutocomplete.Customer
                        placeholder="Filtrer par client"
                        onSelect={(customer) => setCustomerId(customer?.id)}
                        onUnselect={() => setCustomerId(undefined)}
                    />
                    <StyledDropdown
                        value={role}
                        onChange={(e) => setRole(e.value)}
                        placeholder="Filtrer par rôle"
                        optionValue="value"
                        optionLabel="name"
                        showClear
                        options={RoleOptions}
                    />
                    <Dropdown
                        value={created_from}
                        onChange={(e) => setCreatedFrom(e.value)}
                        placeholder="Filtrer par origine"
                        optionValue="value"
                        optionLabel="name"
                        showClear
                        options={createdFromOptions}
                    />
                </FiltersContainer>
            }
            queryParams={
                {
                    customer_id,
                    role,
                    created_from
                } as UserQuery
            }
            headers={[
                {
                    name      : 'Nom complet',
                    field     : 'fullname',
                    component : (data: UserRo) => <a href={'/dashboard/users/view/' + data.id}>{data.fullname}</a>
                },
                {
                    name      : 'Email',
                    field     : 'email',
                    component : (data: UserRo) => (
                        <Anchor href={`mailto:${data.email}`}>
                            <i className="pi pi-envelope" />
                            {data.email}
                        </Anchor>
                    )
                },
                {
                    name      : 'Téléphone',
                    field     : 'phone_number',
                    width     : 190,
                    component : (data: UserRo) => {
                        if (!data.phone_number) return null;
                        return (
                            <Anchor
                                href={
                                    data.phone_number
                                        ? 'tel:' + formatE164ToInternational(data.phone_number)
                                        : undefined
                                }
                            >
                                <i className="pi pi-phone" />
                                {data.phone_number ? formatE164ToInternational(data.phone_number) : ''}
                            </Anchor>
                        );
                    }
                },
                {
                    name      : 'Client',
                    field     : 'customer_id',
                    component : (data: UserRo) => (data.customer_id[0] as unknown as CustomerRo)?.name ?? 'Non renseigné'
                },
                {
                    name      : 'Rôles',
                    field     : 'roles',
                    component : (data: UserRo) => {
                        const sortedRoles = data.roles.sort((a, b) => {
                            const aText = mapUserRole(a);
                            const bText = mapUserRole(b);
                            return aText.localeCompare(bText);
                        });
                        return (
                            <TagList>
                                {sortedRoles.map((role) => (
                                    <StyledTag
                                        key={data.id + role}
                                        value={mapUserRole(role)}
                                        color={
                                            role === EUserRole.BBNG_USER_ADMIN ? 'var(--orange-500)' : 'var(--blue-500)'
                                        }
                                    />
                                ))}
                            </TagList>
                        );
                    }
                },
                {
                    name      : 'Origine',
                    field     : 'created_from',
                    component : (data: UserRo) => <span>{mapCreatedFrom(data.created_from)}</span>
                }
            ]}
        />
    );
};
