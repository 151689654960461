import React from 'react';

import { ZoneRo } from '@bbng/util/types';

import { ZONE_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';

const ZonePostCodeManager = (post_code: string[]) => {
    return (
        <div
            style={{
                overflowY           : 'scroll',
                width               : '100%',
                height              : 150,
                display             : 'grid',
                gridTemplateColumns : 'repeat(auto-fill, minmax(70px, 1fr))'
            }}
        >
            {post_code.map((item: string) => {
                return (
                    <div
                        style={{
                            height          : 25,
                            width           : 70,
                            borderRadius    : 5,
                            backgroundColor : '#fde1c4',
                            opacity         : 0.8,
                            border          : 'none',
                            color           : 'black',
                            textAlign       : 'center',
                            paddingTop      : 5,
                            margin          : 5
                        }}
                    >
                        {item}
                    </div>
                );
            })}
        </div>
    );
};

export const ZoneListing: React.FC = (): JSX.Element => {
    return (
        <>
            <Listing<any, ZoneRo>
                url={ZONE_ORCHESTRATION_BASE_URL}
                endpoint="zones"
                addButtonUrl={urlBuilder.zoneCreate()}
                name="Zone"
                pluralName="Zones"
                headers={[
                    {
                        name      : 'Nom Interne',
                        field     : 'id',
                        component : (data: ZoneRo) => <a href={urlBuilder.zoneView(data.id)}>{data.name}</a>,
                        width     : 250
                    },
                    {
                        name      : 'Nom Externe',
                        field     : 'display_name',
                        component : (data: ZoneRo) => <span>{data.display_name}</span>,
                        width     : 250
                    },
                    {
                        name      : 'code postal',
                        field     : 'postal_code',
                        component : (data: ZoneRo) => ZonePostCodeManager(data.postal_code)
                    },
                    {
                        name      : 'Métropole',
                        field     : 'metropole',
                        component : (data: ZoneRo) => <span>{data.metropole}</span>,
                        width     : 150
                    }
                ]}
            />
        </>
    );
};
