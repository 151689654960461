import { ESlot, FORBIDDEN_DAY_FORMAT, ZoneCreateDto, ZoneRo, ZoneUpdateDto } from '@bbng/util/types';
import moment from 'moment';

import { getRelationsDto, optimiseEditDto } from '../../common/form';
import {
    EGeneralSlot,
    ZoneGeneralErrorState,
    ZoneGeneralState,
    initialErrorState as zoneGeneralInitialErrorState,
    initialState as zoneGeneralInitialState
} from '../../modules/zone/General';

export type ZoneModulesStates = ZoneGeneralState;

export type ZoneModulesErrorStates = ZoneGeneralErrorState;

export type ZoneFormState = {
    general: ZoneGeneralState;
};

export type ZoneFormErrorState = {
    general: ZoneGeneralErrorState;
};

export const initialState: ZoneFormState = {
    general: zoneGeneralInitialState
};

export const initialErrorState: ZoneFormErrorState = {
    general: zoneGeneralInitialErrorState
};

export const mapApiDataToState = (zone: ZoneRo): ZoneFormState => {
    return {
        general: {
            name           : zone.name,
            display_name   : zone.display_name,
            postal_code    : zone.postal_code,
            metropole      : zone.metropole,
            min_weight     : zone.min_weight,
            required_slot  : (zone.required_slot ?? EGeneralSlot.NONE) as EGeneralSlot,
            archived       : zone.archived,
            allowed_days   : zone.allowed_days,
            forbidden_days : zone.forbidden_days
                ? Object.keys(zone.forbidden_days).map((day) => moment.utc(day, FORBIDDEN_DAY_FORMAT).toISOString())
                : []
        }
    };
};

export const mapStateToApiCreateData = (state: ZoneFormState): ZoneCreateDto => {
    return {
        name           : state.general.name,
        display_name   : state.general.display_name,
        postal_code    : state.general.postal_code,
        min_weight     : state.general.min_weight,
        required_slot  : mapGeneralSlotToSlot(state.general.required_slot),
        metropole      : state.general.metropole,
        allowed_days   : state.general.allowed_days,
        forbidden_days : state.general.forbidden_days.reduce(
            (acc, day) => ({ ...acc, [moment.utc(day).format(FORBIDDEN_DAY_FORMAT)]: true }),
            {}
        ),
    };
};

export const mapStateToApiEditData = (state: ZoneFormState, apiState: ZoneFormState, discount: ZoneRo): ZoneUpdateDto =>
    optimiseEditDto(state, apiState, discount, mapStateToApiCreateData, getRelationsDto<ZoneRo>());

export const mapGeneralSlotToSlot = (slot: EGeneralSlot): ESlot | null => {
    switch (slot) {
        case EGeneralSlot.NONE:
            return null;
        case EGeneralSlot.MORNING:
            return ESlot.MORNING;
        case EGeneralSlot.AFTERNOON:
            return ESlot.AFTERNOON;
        case EGeneralSlot.DAY:
            return ESlot.DAY;
    }
};
