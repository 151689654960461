import { removeSpacesFromString } from './util-misc';

/**
 * Verify if the given input is a valid SIREN or SIRET.
 * @param {string} input
 * @param {number} size
 *
 * @returns {boolean}
 */
export function verify(input: string, size: number): boolean {
    const trimmedInput = removeSpacesFromString(input);
    if (!trimmedInput) return false;
    if (trimmedInput.length !== size) return false;
    let bal = 0;
    let total = 0;

    for (let i = size - 1; i >= 0; i--) {
        const step = (trimmedInput.charCodeAt(i) - 48) * (bal + 1);

        total += step > 9 ? step - 9 : step;
        bal = 1 - bal;
    }
    return total % 10 === 0 ? true : false;
}

/**
 * Verify if the given siret is valid.
 * @param {string} siret
 *
 * @returns {boolean}
 */
export function isSIRET(siret: string): boolean {
    return verify(siret, 14);
}

/**
 * Verify if the given siren is valid.
 * @param {string} siren
 *
 * @returns {boolean}
 */
export function isSIREN(siren: string): boolean {
    return verify(siren, 9);
}

/**
 * Verify if the given vat is valid.
 * @param {string} vat
 * @param {string} countryCode

* @returns {boolean}
 */
export function isVAT(vat: string, countryCode = 'FR'): boolean {
    const numero = removeSpacesFromString(vat);
    if (numero.substring(0, 2) !== countryCode) return false;
    const keyFound = numero.substring(2, 4);
    const siren = numero.substring(4, 13);
    if (!isSIREN(siren)) return false;
    if (getTvaKeyFromSiren(siren) !== keyFound) return false;
    else return true;
}

/**
 * Verify if the given naf is valid (00.00A).
 * @param {string} vat

* @returns {boolean}
 */
export function isNAF(naf: string): boolean {
    if (naf.length !== 5 && naf.length !== 6) return false;
    if (naf.substring(0, 2).match(/^[0-9]+$/) === null) return false;
    if (naf.substring(2, 3) !== '.') return false;
    if (naf.substring(3, 5).match(/^[0-9]+$/) === null) return false;
    return true;
}

/**
 * Convert a siren/siret to its associated vat number.
 * @param {string} input
 * @param {string} countryCode
 * @returns {string}
 */
export function convertSirenOrSiretToVat(input: string, countryCode = 'FR'): string {
    if (!input) return '';
    let isSiret = false;
    let numero = removeSpacesFromString(input);
    if (isSIREN(numero)) isSiret = false;
    else if (isSIRET(numero)) isSiret = true;
    else return '';
    if (isSiret) {
        numero = numero.substring(0, 9);
    }
    const key = getTvaKeyFromSiren(numero);
    return countryCode + key + numero;
}

function getTvaKeyFromSiren(siren: string) {
    const k = (12 + 3 * (parseInt(siren) % 97)) % 97;
    const key = k < 10 ? '0' + k : '' + k;
    return key;
}
