import axios from 'axios';
import {
    AutoComplete,
    AutoCompleteChangeParams,
    AutoCompleteCompleteMethodParams,
    AutoCompleteSelectParams
} from 'primereact/autocomplete';
import React from 'react';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import styled from 'styled-components';

import { convertSirenOrSiretToVat, deepCopy, maskString } from '@bbng/util/misc';
import { CustomerRo, GetAllCustomerQuery } from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import { toast } from '../../components/Toast';
import { PlaceResult } from '../../context/GoogleMaps';
import { useRequest } from '../../hooks/StatelessRequest';
import type { CustomerFormState } from '../../pages/CustomerForm/helpers';
import { Address } from '../InputList/address';
import type { PapersSearchResult, Resultats } from './papers.d';

export const googleAddressComponents = [
    { key: 'address_street_number', component: 'street_number' },
    { key: 'address_street_name', component: 'route' },
    { key: 'address_city', component: 'locality' },
    { key: 'address_zip_code', component: 'postal_code' },
    { key: 'address_country', component: 'country' }
] as const;

const googleAddressFields = ['address_components', 'geometry', 'name', 'formatted_address'];

export enum ESirenOrName {
    SIREN = 'SIREN',
    NAME = 'NAME'
}

export type CustomerPapersProps = {
    readOnly?: boolean;
    globalSetter: (setter: (previous: CustomerFormState) => CustomerFormState) => void;
};

export const CustomerPapers = ({ readOnly = false, globalSetter }: CustomerPapersProps) => {
    const [val, setVal] = React.useState<string>('');
    const [searchByName, setSearchByName] = React.useState<boolean>(true);
    const [suggestion, setSuggestion] = React.useState<
        {
            label: string;
            value: Resultats;
        }[]
    >([]);
    const { placePredictions, getPlacePredictions, placesService } = usePlacesAutocompleteService({
        debounce : 0,
        options  : {
            input                 : '',
            componentRestrictions : { country: 'fr' }
        }
    });
    const bbngRequest = useRequest();

    React.useEffect(() => {
        if (placePredictions?.length > 0) {
            const placePrediction = placePredictions[0];

            placesService?.getDetails(
                {
                    placeId : placePrediction.place_id,
                    fields  : googleAddressFields
                },
                (placeResult: PlaceResult | null) => {
                    const entries = googleAddressComponents.map((input) => {
                        const componentName = placeResult?.address_components?.find((f: any) =>
                            f.types?.includes(input.component)
                        )?.long_name;

                        return [input.key, componentName?.toLowerCase()];
                    });

                    const newState = Object.fromEntries(entries);

                    const place: Address = {
                        ...newState,
                        address_lat : placeResult?.geometry?.location?.lat(),
                        address_lng : placeResult?.geometry?.location?.lng()
                    };

                    globalSetter((prev) => ({
                        ...prev,
                        headquarters     : deepCopy(place),
                        invoice_address  : deepCopy(place),
                        shipping_address : deepCopy(place)
                    }));
                }
            );
        }
    }, [placePredictions]);

    const handleSelect = React.useCallback(
        async (e: AutoCompleteSelectParams) => {
            const selected: Resultats = e.value.value;

            setVal('');

            getPlacePredictions({ input: `${selected.siege.adresse_ligne_1} ${selected.siege.ville}` });

            globalSetter((prev) => ({
                ...prev,
                general: {
                    ...prev.general,
                    name          : selected.nom_entreprise,
                    type          : selected.forme_juridique,
                    registration  : new Date(selected.date_creation),
                    siren         : selected.siren,
                    siret         : selected.siege.siret,
                    naf           : selected.code_naf,
                    share_capital : selected.capital,
                    vat           : maskString(convertSirenOrSiretToVat(selected.siren), '__ __ ___ ___ ___', '_'),
                    revenue       : selected.chiffre_affaires,
                    revenue_year  : selected.annee_finances
                }
            }));

            const res = await bbngRequest<CustomerRo[]>({
                url     : urlApiBuilder.customerGetAll(),
                method  : 'GET',
                payload : {
                    queryParams: {
                        q: selected.siege.siret
                    } as GetAllCustomerQuery
                },
                sync: true
            });
            if (res.success && res.response?.data.ro && res.response?.data.ro.length > 0) {
                const customer = res.response?.data.ro[0];
                toast({
                    severity : 'warn',
                    summary  : 'Client déjà existant',
                    detail   : `Ce client existe déjà dans la base de données sous le nom ${customer.name}.`,
                    life     : 10000
                });
            }
        },
        [globalSetter]
    );

    const requestPappersApi = React.useCallback(
        async (query: string): Promise<Array<Resultats>> => {
            try {
                const response = await axios.request<PapersSearchResult>({
                    url    : `https://suggestions.pappers.fr/v2`,
                    params : {
                        q      : query,
                        cibles : 'nom_entreprise,siren,siret'
                    }
                });
                const result: PapersSearchResult = response.data;
                if (searchByName) return result.resultats_nom_entreprise;
                else return result.resultats_siret;
            } catch (err) {
                console.error(err);
                return [];
            }
        },
        [axios, searchByName]
    );

    const handleAutocomplete = React.useCallback(
        async (e: AutoCompleteCompleteMethodParams) => {
            const results = await requestPappersApi(e.query);
            setSuggestion(
                results.map((r) => ({
                    label: `${r.nom_entreprise}
                        - siren: ${r.siren}
                        - créé le: ${r.date_creation_formate}
                        - CP: ${r.siege.code_postal}
                        - ville: ${r.siege.ville}`,
                    value: r
                }))
            );
        },
        [setSuggestion, requestPappersApi]
    );

    const handleChange = React.useCallback(
        (e: AutoCompleteChangeParams) => {
            setVal(e.value);
        },
        [setVal]
    );

    return (
        <StyledCard title="Pappers API">
            <CardLine>
                <SelectWrapper>
                    <Button.Select
                        id="select"
                        forceSelection={true}
                        options={Object.values(ESirenOrName)}
                        result={(value) => setSearchByName(value === ESirenOrName.NAME)}
                        value={searchByName ? ESirenOrName.NAME : ESirenOrName.SIREN}
                        labelMapper={(value) => `Recherche par ${value === ESirenOrName.NAME ? 'nom' : 'siren/siret'}`}
                    />
                </SelectWrapper>
                <AutoComplete
                    value={val}
                    suggestions={suggestion}
                    field="label"
                    onChange={handleChange}
                    delay={300}
                    completeMethod={handleAutocomplete}
                    forceSelection
                    onSelect={handleSelect}
                    placeholder="Votre recherche"
                />
            </CardLine>
            <small>Pré-remplissage automatique via la base de données de Pappers.</small>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    .p-component {
        width: 100%;
    }
`;

const StyledCard = styled(Card)`
    .p-card-content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
`;

const SelectWrapper = styled.div`
    display: flex;
    .p-button {
        width: 50%;
    }
    margin-bottom: 8px;
`;
