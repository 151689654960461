import { PlanningSettings, PlanningSettingsProps } from './Config';
import { PlanningShifts, PlanningShiftsProps } from './shifts';

const Modules = {
    Settings: (props: PlanningSettingsProps) => {
        return <PlanningSettings {...props} />;
    },
    Shifts: (props: PlanningShiftsProps) => {
        return <PlanningShifts {...props} />;
    }
};

export default Modules;
