import React, { useEffect } from 'react';
import styled from 'styled-components';

import { formatPhoneNumber } from '@bbng/util/misc';
import { PhoneValue } from '@bbng/util/types';

import Input from '../../components/Inputs';
import { InputListBase } from './base';

export interface FrontContact {
    firstname: string;
    lastname: string;
    email: string;
    phone_number: PhoneValue | null;
}

export type InputListContactProps = {
    title?: string;
    value?: Array<FrontContact>;
    onChange?: (value: Array<FrontContact>) => void;
    readOnly?: boolean;
    requiredEmail?: boolean;
    requiredFirstName?: boolean;
    requiredLastName?: boolean;
    displayError?: boolean;
};

export function InputListContact({
    title = 'Contact',
    requiredFirstName = false,
    requiredLastName = false,
    requiredEmail = true,
    displayError = false,
    ...props
}: InputListContactProps) {
    const [errors, setErrors] = React.useState<Record<string, string[]>>({});
    const isError =
        Object.keys(errors)
            .map((e) => errors[e] ?? [])
            .flat().length > 0;

    return (
        <InputListBase<FrontContact>
            title={title}
            columns={[
                { header: 'Prénom', field: 'firstname' },
                { header: 'Nom', field: 'lastname' },
                { header: 'Email', field: 'email' },
                {
                    header : 'Téléphone',
                    field  : 'phone_number',
                    body   : (e) => <div>{e.phone_number ? formatPhoneNumber(e.phone_number) : 'Non renseigné'}</div>
                }
            ]}
            disableModalButton={isError}
            setErrors={setErrors}
            modalTemplate={ContactModal(
                errors,
                setErrors,
                requiredEmail,
                requiredFirstName,
                requiredLastName,
                displayError
            )}
            {...props}
        />
    );
}

const ContactModal =
    (
        errors: Record<string, string[]>,
        setError: React.Dispatch<React.SetStateAction<Record<string, string[]>>>,
        requiredEmail: boolean,
        requiredFirstName: boolean,
        requiredLastName: boolean,
        displayError: boolean
    ) =>
    (state: FrontContact | undefined, setter: React.Dispatch<React.SetStateAction<FrontContact | undefined>>) => {
        return (
            <ModalContainer>
                <Input.Text
                    id="firstname"
                    required={requiredFirstName}
                    result={(value, err, id) => {
                        setter((prev) => ({ ...prev, firstname: value } as any));
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    label="Prénom"
                    errors={errors['firstname'] ?? []}
                    displayError={displayError}
                    value={state?.firstname ?? ''}
                />
                <Input.Text
                    id="lastname"
                    required={requiredLastName}
                    result={(value, err, id) => {
                        setter((prev) => ({ ...prev, lastname: value } as any));
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    label="Nom"
                    errors={errors['lastname'] ?? []}
                    displayError={displayError}
                    value={state?.lastname ?? ''}
                />
                <Input.Phone
                    id="phone_number"
                    required={true}
                    result={(value, err, id) => {
                        setter((prev) => ({ ...prev, phone_number: value } as any));
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    errors={errors['phone_number'] ?? []}
                    displayError={displayError}
                    value={state?.phone_number ?? null}
                />
                <Input.Email
                    id="email"
                    required={requiredEmail}
                    result={(value, err, id) => {
                        setter((prev) => ({ ...prev, email: value } as any));
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    label="Email"
                    errors={errors['email'] ?? []}
                    displayError={displayError}
                    value={state?.email ?? ''}
                />
            </ModalContainer>
        );
    };

const ModalContainer = styled.div`
    .p-component,
    .react-tel-input {
        width: 100% !important;
    }
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr;
`;
