import equal from 'fast-deep-equal/react';
import { useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ccFamilyFromProducts, deepCheckEmptyObject, deepCopy, getVolumeFromProducts } from '@bbng/util/misc';
import {
    AdminRo,
    CCRo,
    CCServiceRo,
    CCServiceRoFront,
    CollectConfigQuery,
    CollectQuery,
    CollectService,
    ConstructionSiteRo,
    CustomerRo,
    EBillingMode,
    ELandfillDangerousTrashType,
    EOrderPaymentStatus,
    EPlanningType,
    ESlot,
    MinimalInfoForDumpsterFindingQuery,
    LostDumpsterRo,
    OrderRo,
    OrderRoFront,
    CREATED_FROM,
    AllowedDays as AllowedDaysType,
    FORBIDDEN_DAY_FORMAT
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { mapCreatedFrom, mapOrderStatus } from '../../common/enumMapper';
import { ActionsFormContainer, uploadFiles } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import { toast } from '../../components/Toast';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import Order from '../../modules/order';
import CollectConfig from '../../modules/collect-config';
import { EOrderDumpsterServiceForm } from '../../modules/order/DumpsterService';
import { EOrderTypeForm } from '../../modules/order/Type';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    OrderFormErrorState,
    OrderFormState,
    OrderModulesErrorStates,
    OrderModulesStates,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiCreateData
} from './helpers';

import { mapSlot } from '../../common/enumMapper';
import { LastCollectConfigs } from './last-ccs';
import { PageForm, PageFormLine, StyledH1 } from './style';
import moment from 'moment';
import { ConfirmQuantityModal } from '../../modules/order/ConfirmQuantityModal';
import { ConfirmSlotModal } from '../../modules/order/ConfirmSlotModal';
import { ConfirmSameOrderModal } from '../../modules/order/ConfirmSameOrderModal';
import { ConfirmDayModal } from '../../modules/order/ConfirmDayModal';
import { ConfirmationEmailModal } from './confirmationEmailModal';
import useQuery from '../../hooks/Query';
import { ConfirmHolidayModal } from '../../modules/order/ConfirmHolidayModal';

type OrderFormProps = {
    readOnly?: boolean;
    duplicate?: boolean;
};

const OrderForm: React.FC<OrderFormProps> = ({ readOnly = false, duplicate = false }: OrderFormProps): JSX.Element => {
    const { query } = useQuery<{ on_success?: 'close' }>();
    const { id: paramsId } = useParams();
    const navigate = useNavigate();
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [apiState, setApiState] = React.useState<{ form: OrderFormState; db: OrderRoFront }>();
    const [duplicateInitialized, setDuplicatedInitialized] = React.useState<boolean>(false);
    const [isDuplicate, setIsDuplicate] = React.useState<boolean>(duplicate);

    const [admin, setAdmin] = React.useState<AdminRo>();
    const [orderNumber, setOrderNumber] = React.useState<string>();
    const [orderOrigin, setOrderOrigin] = React.useState<CREATED_FROM>();
    const [blocked, setBlocked] = React.useState<boolean>(false);

    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(readOnly);
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>(
        readOnly ? 'read' : undefined
    );

    const [err, setErr] = useState<OrderFormErrorState>(initialErrorState);

    const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);
    const [quantityRequirementAlert, setQuantityRequirementAlert] = useState<boolean>(false);
    const [slotRequirementAlert, setSlotRequirementAlert] = useState<boolean>(false);
    const [dayRequirementAlert, setDayRequirementAlert] = useState<boolean>(false);
    const [holidayRequirementAlert, setHolidayRequirementAlert] = useState<boolean>(false);
    const [confirmationEmailModalOpen, setConfirmationEmailModalOpen] = useState<boolean>(false);
    const [duplicateCC, setDuplicateCC] = useState<CCServiceRoFront>();
    const [lastCollectConfigs, setLastCollectConfigs] = useState<CCServiceRoFront[]>([]);
    const [lostDumpstersState, setLostDumpstersState] = useState<{ isLoading: boolean; ro: LostDumpsterRo[] }>({
        isLoading : false,
        ro        : []
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handlePreSubmit = (data: OrderFormState) => {
        if (data.customer.customer?.billing_mode === EBillingMode.INSTANT) {
            handleCallApi(data);
        } else {
            setConfirmationEmailModalOpen(true);
        }
    };

    const handleSubmit = React.useCallback(
        async (data: OrderFormState) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            if (data.customer.customer?.mandatory_order_sheet && data.documents.documents.length === 0) {
                toast({
                    severity : 'error',
                    summary  : 'Formulaire invalide',
                    detail   : 'Aucun bon de commande renseigné (obligatoire pour ce client).'
                });
                return;
            }

            if (data?.zone?.zone?.archived) {
                toast({
                    severity : 'error',
                    summary  : 'Formulaire invalide',
                    detail   : 'La zone du chantier est archivée.'
                });
                return;
            }

            // Compare the total quantity in the order with the one setup in the DB to check if it fullfill the minimum requirement
            const family = ccFamilyFromProducts(Object.values(data?.products || {}), false);
            const totalQuantity = getVolumeFromProducts(Object.values(data?.products || {}), family);
            if (
                data.type.type === EOrderTypeForm.COLLECT_BIG_BAG &&
                data?.zone.zone &&
                totalQuantity < data?.zone?.zone?.min_weight
            ) {
                setQuantityRequirementAlert(true);
                return;
            }

            // If there is a required dayslot and the slot doesn't fit show an alert.
            if (data.zone.zone?.required_slot && data?.collectInfo?.slot?.slot !== data.zone.zone?.required_slot) {
                setSlotRequirementAlert(true);
                return;
            }

            // If there is allowed_days and the day is not allowed
            const orderWeekDay = moment(data.collectInfo?.collect_day).weekday();
            if (data.zone.zone?.allowed_days && !data.zone.zone?.allowed_days[orderWeekDay as keyof AllowedDaysType]) {
                setDayRequirementAlert(true);
                return;
            }

            // If there is forbidden_days and the day is forbidden
            const plannedDay = moment(data.collectInfo?.collect_day).format(FORBIDDEN_DAY_FORMAT);
            if (data.zone.zone?.forbidden_days && data.zone.zone.forbidden_days[plannedDay]) {
                setHolidayRequirementAlert(true);
                return;
            }

            if (data.contacts.construction_site.length === 0 && data.contacts.logistic.length === 0) {
                toast({
                    severity : 'error',
                    summary  : 'Formulaire invalide',
                    detail   : "Aucun contact n'a été renseigné."
                });
                return;
            }

            const duplicate = lastCollectConfigs.find((cc) => {
                const customer = cc.customer_id[0] as CustomerRo;
                const constructionSite = cc.construction_site_id[0] as ConstructionSiteRo;
                const ccDay = moment(cc.from_date).format('DD/MM/YYYY');
                const isSameCustomer = customer.id === data.customer.customer?.id;
                const isSameConstructionSite = constructionSite?.id === data.constructionSite?.constructionSite?.id;
                const isSameDate = ccDay === moment(data.collectInfo?.collect_day).format('DD/MM/YYYY');

                return isSameCustomer && isSameConstructionSite && isSameDate;
            });

            if (duplicate) {
                setDuplicateCC(duplicate);
                setShowDuplicateModal(true);
                return;
            } else {
                handlePreSubmit(data);
            }
        },
        [err, apiState, bbngRequest, lastCollectConfigs]
    );

    const handleCallApi = async (data: OrderFormState) => {
        const documents = await uploadFiles(data.documents.documents, 'order');
        if (!documents) {
            toast({
                severity : 'error',
                summary  : 'Téléversement des documents échoué',
                detail   : 'Le téléversement des documents a échoué, veuillez réessayer.'
            });
            return;
        }
        const dataWithUploadedDocuments: OrderFormState = {
            ...data,
            documents: {
                ...data.documents,
                documents
            }
        };
        const body = mapStateToApiCreateData(dataWithUploadedDocuments);

        const method = 'POST';
        const url = urlApiBuilder.orderCreate();
        setLoading(true);
        setOnRequestType('create');
        const response = await bbngRequest<OrderRo>({
            method,
            url,
            payload: {
                body
            }
        });
        setLoading(false);
        setOnRequestType(undefined);

        if (query?.on_success === 'close') {
            window.close();
        }
        if (response.success) {
            navigate(urlBuilder.orderList());
        }
    };

    const formik = useFormik<OrderFormState>({
        initialValues : initialState,
        onSubmit      : handleSubmit
    });

    /**
     * Reset dumpster type when type is no more COLLECT_DUMPSTER
     */
    React.useEffect(() => {
        if (formik.values.type.type !== EOrderTypeForm.COLLECT_DUMPSTER && formik.values.type.dumpsterType) {
            formik.setValues((prev) => ({
                ...prev,
                type: {
                    ...prev.type,
                    dumpsterType: undefined
                }
            }));
            setErr((prev) => ({
                ...prev,
                type: {
                    ...prev.type,
                    dumpsterType: null
                }
            }));
        }
    }, [formik.values.type.type]);

    React.useEffect(() => {
        if (formik.values.customer.customer && !readOnly && (!isDuplicate || duplicateInitialized)) {
            setBlocked(formik.values.customer.customer.blocked);

            /**
             * Reset construction site each time customer changes, to avoid order for a given customer with another customer's construction site.
             */
            formik.setValues((prev) => ({
                ...prev,
                constructionSite: initialState.constructionSite
            }));
            setErr((prev) => ({
                ...prev,
                constructionSite: initialErrorState.constructionSite
            }));
        }
    }, [formik.values.customer.customer]);

    const handleChange = React.useCallback(
        (value: OrderModulesStates, errors: OrderModulesErrorStates | string[] | null, id: string): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async (id: string, duplicate?: boolean) => {
        setLoading(true);
        const response = await bbngRequest<OrderRo>({
            method  : 'GET',
            url     : urlApiBuilder.orderGet(id),
            options : { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            const orderRoFront: OrderRoFront = await fetchDataRelation(response.response?.data.ro, {
                customer_id       : true,
                collect_config_id : true,
                admin_id          : true,
                document_id       : true,
                user_id           : true
            });
            const ccFront: CCRo[] = await fetchDataRelation(orderRoFront.collect_config_id, {
                construction_site_id : true,
                presta_id            : true
            });
            const ccWithCollect: CCServiceRoFront[] = await Promise.all(
                ccFront.map(async (cc) => {
                    const collectResponse = await bbngRequest<CollectService[]>({
                        method  : 'GET',
                        url     : urlApiBuilder.collectGetAll(),
                        payload : {
                            queryParams: {
                                collect_config_id: (cc as CCServiceRoFront).id
                            } as CollectQuery
                        }
                    });
                    return {
                        ...(cc as CCServiceRoFront),
                        collect:
                            collectResponse.response?.data.ro && (collectResponse.response?.data.ro || []).length > 0
                                ? collectResponse.response?.data.ro[0]
                                : undefined
                    };
                })
            );
            const ro = {
                ...orderRoFront,
                collect_config_id: ccWithCollect
            };
            const formikState: OrderFormState = mapApiDataToState(ro, duplicate);
            setApiState({ form: formikState, db: ro });
            setAdmin(ro.admin_id[0] as AdminRo);
            setOrderNumber(ro.number);
            setOrderOrigin(ro.taken_from);
            formik.setValues(deepCopy(formikState));
            setLoading(false);
            setDuplicatedInitialized(true);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    const fetchAdminData = React.useCallback(async (id: string) => {
        const response = await bbngRequest<AdminRo>({
            method  : 'GET',
            url     : urlApiBuilder.adminGet(id),
            options : { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            setAdmin(response.response?.data.ro);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
        }
    }, []);

    const fetchLastCollectConfigs = async () => {
        setIsLoading(true);
        const construction_site_id = formik.values.constructionSite?.constructionSite?.id;
        const type = formik.values.type.type;
        const res = await fetchDataRelation<StatelessResponse<CCServiceRoFront[]>>(
            await bbngRequest<CCServiceRo[]>({
                url     : urlApiBuilder.collectConfigReadAll(),
                method  : 'GET',
                payload : {
                    queryParams: {
                        customer_id          : paramsId,
                        construction_site_id : construction_site_id,
                        type                 : type === EOrderTypeForm.COLLECT_DUMPSTER ? EPlanningType.DUMPSTER : EPlanningType.BIG_BAG,
                        limit                : 10,
                        no_administrative    : true
                    } as CollectConfigQuery
                },
                options: {
                    toastifySuccess: false
                }
            }),
            {
                customer_id          : true,
                construction_site_id : true
            }
        );
        if (res && res.success && res.response?.data.ro) {
            setLastCollectConfigs(res.response?.data.ro.sort((a, b) => moment(b.from_date).diff(moment(a.from_date))));
        }
        setIsLoading(false);
    };

    const fetchLostDumpsters = async () => {
        setLostDumpstersState((old) => ({
            ...old,
            isLoading: true
        }));
        const construction_site_id = formik.values.constructionSite?.constructionSite?.id;
        const customer_id = formik.values.customer?.customer?.id;
        const res = await fetchDataRelation<StatelessResponse<LostDumpsterRo[]>>(
            await bbngRequest<LostDumpsterRo[]>({
                url     : urlApiBuilder.lostDumpsters(),
                method  : 'GET',
                payload : {
                    queryParams: {
                        customer_id,
                        construction_site_id
                    } as MinimalInfoForDumpsterFindingQuery
                },
                options: {
                    toastifySuccess: false
                }
            }),
            {
                customer_id          : true,
                construction_site_id : true
            }
        );
        if (res && res.success && res.response?.data.ro) {
            setLostDumpstersState({
                isLoading : false,
                ro        : res.response?.data.ro
            });
        }
    };

    React.useEffect(() => {
        if (formik.values.constructionSite?.constructionSite?.id && formik.values.type.type) {
            fetchLastCollectConfigs();
        }
    }, [formik.values.constructionSite?.constructionSite?.id, formik.values.type.type]);

    React.useEffect(() => {
        if (formik.values.customer.customer?.id) {
            fetchLostDumpsters();
        }
    }, [formik.values.constructionSite?.constructionSite?.id, formik.values.customer.customer?.id]);

    React.useEffect(() => {
        if (readOnly || isDuplicate) {
            fetchData(dataId, duplicate);
        }
    }, []);

    React.useEffect(() => {
        if (formik.values.customer?.customer?.admin_id) {
            fetchAdminData(formik.values.customer?.customer?.admin_id[0]);
        }
    }, [formik.values.customer]);

    React.useEffect(() => {
        if (formik.values.type.type === EOrderTypeForm.DELIVERY) {
            err.trashType = initialErrorState.trashType;
            formik.setFieldValue('trashType', initialState.trashType);
        } else {
            err.deliveryAddress = initialErrorState.deliveryAddress;
            formik.setFieldValue('deliveryAddress', initialState.deliveryAddress);
        }
        if (formik.values.type.type !== EOrderTypeForm.COLLECT_DUMPSTER) {
            err.dumpsterService = initialErrorState.dumpsterService;
            formik.setFieldValue('dumpsterService', initialState.dumpsterService);
        }
        if (formik.values.dumpsterService?.service === EOrderDumpsterServiceForm.ROTATION) {
            err.trashType = initialErrorState.trashType;
            formik.setFieldValue('trashType', initialState.trashType);
        }
    }, [formik.values, err]);

    const handleArchive = React.useCallback(() => {
        setLoading(true);
        setOnRequestType(apiState?.db.archived ? 'unarchive' : 'delete');

        bbngRequest({
            method  : 'PATCH',
            url     : urlApiBuilder.orderArchive(dataId),
            options : { toastifySuccess: false },
            payload : {
                body: {
                    archived: !apiState?.db.archived
                }
            }
        })
            .then((response) => {
                if (query?.on_success === 'close') {
                    window.close();
                }
                if (response.success) {
                    navigate(urlBuilder.orderList());
                }
            })
            .finally(() => {
                setLoading(false);
                setOnRequestType(undefined);
            });
    }, [bbngRequest, setLoading, setOnRequestType, apiState]);

    const isRetrievalOrRotation = useMemo(() => {
        if (!formik.values.dumpsterService?.service) return false;
        return [EOrderDumpsterServiceForm.RETRIEVAL, EOrderDumpsterServiceForm.ROTATION].includes(
            formik.values.dumpsterService?.service
        );
    }, [formik.values.dumpsterService]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (errorCode) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.orderList()} />
        ) : (
            <Error500 />
        );
    }

    const collectHasDangerousTrash =
        formik.values.type.type !== EOrderTypeForm.DELIVERY &&
        Object.values(ELandfillDangerousTrashType).includes(
            (formik.values.trashType?.trashType || '') as unknown as ELandfillDangerousTrashType
        );

    if (!readOnly)
        return (
            <PageForm onSubmit={formik.handleSubmit}>
                <h1>Prise de commande</h1>
                <ActionsFormContainer
                    archived={apiState?.db.archived || false}
                    readOnly={readOnly}
                    // disableSaveOnEdit={JSON.stringify(apiState?.form) === JSON.stringify(formik.values)}
                    disableSaveOnEdit={equal(apiState?.form, formik.values)}
                    handleArchiveOnView={handleArchive}
                    editPageUrl={urlBuilder.orderEdit(dataId)}
                    historyUrl={urlApiBuilder.orderHistory(dataId)}
                />
                <PageFormLine>
                    <Order.Customer
                        displayError={formik.submitCount > 0}
                        readOnly={readOnly}
                        id="customer"
                        result={handleChange}
                        value={formik.values.customer}
                        selectUser={formik.values.customer.customer?.billing_mode === EBillingMode.INSTANT}
                    />
                    {!blocked && (
                        <Order.Documents
                            displayError={formik.submitCount > 0}
                            readOnly={readOnly || blocked}
                            id="documents"
                            result={handleChange}
                            value={formik.values.documents}
                            customer={formik.values.customer?.customer}
                        />
                    )}
                </PageFormLine>
                {!blocked && (
                    <>
                        <PageFormLine>
                            <Order.Type
                                displayError={formik.submitCount > 0}
                                readOnly={readOnly || blocked}
                                id="type"
                                result={handleChange}
                                value={formik.values.type}
                            />
                            <Order.ConstructionSite
                                displayError={formik.submitCount > 0}
                                readOnly={readOnly || blocked}
                                id="constructionSite"
                                result={handleChange}
                                value={formik.values.constructionSite}
                                customerId={formik.values.customer?.customer?.id}
                                globalSetter={formik.setValues}
                                documents={formik.values.documents}
                            />
                            <Order.Zone
                                displayError={formik.submitCount > 0}
                                readOnly={readOnly || blocked}
                                id="zone"
                                result={handleChange}
                                value={formik.values.zone}
                                postalCode={
                                    formik.values.deliveryAddress?.address_zip_code ||
                                    formik.values.constructionSite?.constructionSite?.address.components['postal_code']
                                }
                            />
                        </PageFormLine>
                        <LastCollectConfigs
                            type={formik.values.type.type}
                            customer={formik.values.customer?.customer}
                            constructionSite={formik.values.constructionSite?.constructionSite}
                            lastCollectConfigs={lastCollectConfigs as CCServiceRo[]}
                            isLoading={isLoading}
                            fetchOrderData={fetchData}
                            setDuplicateInitialized={setDuplicatedInitialized}
                            setIsDuplicate={setIsDuplicate}
                        />
                        {formik.values.type.type === EOrderTypeForm.COLLECT_DUMPSTER && (
                            <Order.DumpsterService
                                displayError={formik.submitCount > 0}
                                readOnly={readOnly || blocked}
                                id="dumpsterService"
                                result={handleChange}
                                value={formik.values.dumpsterService}
                            />
                        )}
                        {/* {isRetrievalOrRotation && formik.values.customer.customer && (
                            <LostDumpsters
                                lostDumpsters={lostDumpstersState.ro}
                                isLoading={lostDumpstersState.isLoading}
                                customerName={formik.values.customer.customer?.name}
                                address={formik.values.constructionSite?.constructionSite?.address}
                            />
                        )} */}
                        {formik.values.type.type !== EOrderTypeForm.DELIVERY &&
                            formik.values.dumpsterService?.service !== EOrderDumpsterServiceForm.ROTATION &&
                            formik.values.type.type !== EOrderTypeForm.COLLECT_BIG_BAG && (
                                <Order.TrashType
                                    displayError={formik.submitCount > 0}
                                    readOnly={readOnly || blocked}
                                    id="trashType"
                                    result={handleChange}
                                    value={formik.values.trashType}
                                />
                            )}
                        <Order.Products
                            displayError={formik.submitCount > 0}
                            readOnly={readOnly || blocked}
                            id="products"
                            result={handleChange}
                            value={formik.values.products}
                            zoneId={formik.values.zone?.zone?.id}
                            trashType={formik.values.trashType?.trashType}
                            type={formik.values.type?.type}
                            service={formik.values.dumpsterService?.service}
                            customerBillingMode={formik.values.customer?.customer?.billing_mode}
                            fetchOperational={false}
                            dumpsterTypes={formik.values.type?.dumpsterType ? [formik.values.type.dumpsterType] : []}
                        />
                        <CollectConfig.DumpsterOnSiteSelect
                            displayError={formik.submitCount > 0}
                            readOnly={readOnly}
                            id="dumpsterOnSiteSelect"
                            result={handleChange}
                            construction_site_id={formik.values.constructionSite?.constructionSite?.id}
                            customer_id={formik.values.customer?.customer?.id}
                            products={formik.values.products}
                            value={formik.values.dumpsterOnSiteSelect}
                        />
                        {!collectHasDangerousTrash && (
                            <Order.CollectInfo
                                displayError={formik.submitCount > 0}
                                readOnly={readOnly || blocked}
                                id="collectInfo"
                                result={handleChange}
                                value={formik.values.collectInfo}
                                showWaitingTimeMinutes={
                                    formik.values.type?.type === EOrderTypeForm.COLLECT_DUMPSTER &&
                                    formik.values.dumpsterService?.service === EOrderDumpsterServiceForm.LOAD_WAIT
                                }
                                showRetrievalDate={
                                    formik.values.type?.type === EOrderTypeForm.COLLECT_DUMPSTER &&
                                    formik.values.dumpsterService?.service === EOrderDumpsterServiceForm.DEPOSIT
                                }
                                create={true}
                                displaySlots={true}
                                type={formik.values.type?.type}
                                selectedProducts={formik.values.products}
                            />
                        )}
                        <Order.Contacts
                            displayError={formik.submitCount > 0}
                            readOnly={readOnly || blocked}
                            id="contacts"
                            result={handleChange}
                            value={formik.values.contacts}
                        />
                        <Order.Misc
                            displayError={formik.submitCount > 0}
                            readOnly={readOnly || blocked}
                            id="misc"
                            result={handleChange}
                            value={formik.values.misc}
                        />
                        <Order.Overview
                            values={formik.values}
                            admin={admin}
                            refetch={fetchData}
                            isDelivery={formik.values.type.type === EOrderTypeForm.DELIVERY}
                        />
                        {readOnly === false && (
                            <Button
                                type="submit"
                                label="Valider"
                                disabled={(deepCheckEmptyObject(err) === false && formik.submitCount > 0) || blocked}
                            />
                        )}
                    </>
                )}
                <ConfirmSameOrderModal
                    visible={showDuplicateModal}
                    onCancel={() => setShowDuplicateModal(false)}
                    onConfirm={() => handlePreSubmit(formik.values)}
                    cc={duplicateCC}
                />
                <ConfirmQuantityModal
                    visible={quantityRequirementAlert}
                    onCancel={() => setQuantityRequirementAlert(false)}
                    onConfirm={() => handlePreSubmit(formik.values)}
                    minWeight={formik.values.zone.zone?.min_weight}
                    zoneName={formik.values.zone.zone?.name}
                />
                <ConfirmSlotModal
                    visible={slotRequirementAlert}
                    onCancel={() => setSlotRequirementAlert(false)}
                    onConfirm={() => handlePreSubmit(formik.values)}
                    requiredSlot={
                        formik.values.zone.zone?.required_slot
                            ? mapSlot(formik.values.zone.zone?.required_slot as ESlot)
                            : ''
                    }
                    zoneName={formik.values.zone.zone?.name}
                />
                <ConfirmDayModal
                    visible={dayRequirementAlert}
                    onCancel={() => setDayRequirementAlert(false)}
                    onConfirm={() => handlePreSubmit(formik.values)}
                    allowedDays={formik.values.zone.zone?.allowed_days}
                    zoneName={formik.values.zone.zone?.name}
                />
                <ConfirmHolidayModal
                    visible={holidayRequirementAlert}
                    onCancel={() => setHolidayRequirementAlert(false)}
                    onConfirm={() => handlePreSubmit(formik.values)}
                    zoneName={formik.values.zone.zone?.name}
                />
                <ConfirmationEmailModal
                    formValues={formik.values}
                    visible={confirmationEmailModalOpen}
                    onConfirm={(email) => {
                        formik.setFieldValue('customer.confirmationEmail', email);
                        setConfirmationEmailModalOpen(false);
                        handleCallApi({
                            ...formik.values,
                            customer: {
                                ...formik.values.customer,
                                confirmationEmail: email
                            }
                        });
                    }}
                    onCancel={() => setConfirmationEmailModalOpen(false)}
                />
            </PageForm>
        );

    return (
        <>
            <StyledH1>
                Commande {readOnly ? `${orderNumber}` : ''}
                {apiState?.db.payment_status === EOrderPaymentStatus.TO_PAY && (
                    <Tag value={mapOrderStatus(apiState?.db.payment_status)} severity="warning" />
                )}
                <Tag value={`Origine: ${mapCreatedFrom(orderOrigin)}`} severity="info" />
            </StyledH1>
            <ActionsFormContainer
                archived={false}
                readOnly={true}
                historyUrl={urlApiBuilder.orderHistory(dataId)}
                hideEditButton={true}
                hideArchiveButton={true}
            />
            <Order.Overview
                values={formik.values}
                dbValues={apiState?.db}
                admin={admin}
                refetch={fetchData}
                isDelivery={formik.values.type.type === EOrderTypeForm.DELIVERY}
            />
        </>
    );
};

export default OrderForm;
