import React from 'react';
import styled from 'styled-components';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import Dinero from 'dinero.js';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';

import {
    CardErrors,
    EProductBillingMode,
    ETrashType,
    ProductQuery,
    ProductRoFront,
    PRODUCT_FAMILY
} from '@bbng/util/types';
import { ProductRo } from '@bbng/util/types';

import { Card } from '../../components/Card';
import InputList from '../InputList';
import { EOrderTypeForm } from '../order/Type';
import { EOrderDumpsterServiceForm } from '../order/DumpsterService';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import { urlApiBuilder } from '../../common/urlBuilder';
import Button from '../../components/Button';
import { mapTrashTypetext } from '../../common/enumMapper';
import { mapOrderTypeFormToProductFamilies } from '../order/Products/helpers';
import ProgressBar from '../../components/ProgressBar';
import { fetchDataRelation } from '../../common/dataRelation';

export type PrestaPriceListProps = {
    readOnly?: boolean;
    value?: PrestaPriceListState;
    id: string;
    result: (
        value: PrestaPriceListState,
        errors: null | string[] | CardErrors<PrestaPriceListState>,
        id: string
    ) => void;
    displayError?: boolean;
};

const FullLineInput = styled.div`
    width: 100%;
`;

export type PrestaPriceListState = {
    priceList: Array<Line>;
};
export type PrestaPriceListErrorState = CardErrors<PrestaPriceListState>;

export const initialState: PrestaPriceListState = {
    priceList: []
};
export const initialErrorState: PrestaPriceListErrorState = Object.fromEntries(
    Object.keys(initialState).map((k) => [k, null])
) as PrestaPriceListErrorState;

export const PrestaPriceList: React.FC<PrestaPriceListProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: PrestaPriceListProps) => {
    const [val, setVal] = React.useState<PrestaPriceListState>(value);
    const [err, setErr] = React.useState<PrestaPriceListErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <Card title="Grille de tarifs des produits">
            <FullLineInput>
                <InputListPrice
                    onChange={(value) => handleChange(value, null, 'priceList')}
                    value={val.priceList}
                    readOnly={readOnly}
                />
            </FullLineInput>
        </Card>
    );
};

export interface Line {
    product_id?: ProductRo;
    value: number;
}

export type InputListPriceProps = {
    value?: Array<Line>;
    onChange?: (value: Array<Line>) => void;
    readOnly?: boolean;
};

const useProductSelect = () => {
    const [type, setType] = React.useState<EOrderTypeForm | undefined>(undefined);
    const [serviceType, setServiceType] = React.useState<EOrderDumpsterServiceForm | undefined>(undefined);
    const [trashType, setTrashType] = React.useState<ETrashType | undefined>(undefined);
    const [isPending, setIsPending] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const [products, setProducts] = React.useState<Array<ProductRoFront>>([]);
    const statelessRequest = useRequest({ toastifyError: true, toastifySuccess: false });

    const fetchProducts = React.useCallback(async () => {
        setIsPending(true);
        const response = await fetchDataRelation<StatelessResponse<ProductRoFront[]>>(
            await statelessRequest<Array<ProductRo>>({
                method  : 'GET',
                url     : urlApiBuilder.productGetAll(''),
                payload : {
                    queryParams: {
                        // zone_id       : zoneId,
                        families    : mapOrderTypeFormToProductFamilies(type, serviceType),
                        trash_type  : trashType,
                        limit       : 1000,
                        operational : undefined
                    } as ProductQuery
                }
            }),
            {
                zone_id: true
            }
        );

        if (response.success) {
            setProducts(response.response?.data?.ro ?? []);
        }
        setIsError(!response.success);
        setIsPending(false);
    }, [type, trashType, serviceType]);

    React.useEffect(() => {
        if (type === EOrderTypeForm.DELIVERY) {
            fetchProducts();
        } else if (type === EOrderTypeForm.COLLECT_DUMPSTER && serviceType && trashType) {
            fetchProducts();
        } else if (type === EOrderTypeForm.COLLECT_BIG_BAG && trashType) {
            fetchProducts();
        } else {
            setProducts([]);
        }
    }, [type, trashType, serviceType]);

    const refetch = () => {
        fetchProducts();
    };

    const preset = (product: ProductRo) => {
        setProducts([]);
        const typePreset: EOrderTypeForm =
            product.family === PRODUCT_FAMILY.COLLECT_BIG_BAG
                ? EOrderTypeForm.COLLECT_BIG_BAG
                : product.family === PRODUCT_FAMILY.DELIVERY_BIG_BAG
                ? EOrderTypeForm.DELIVERY
                : EOrderTypeForm.COLLECT_DUMPSTER;
        const servicePreset: EOrderDumpsterServiceForm | undefined =
            typePreset === EOrderTypeForm.COLLECT_DUMPSTER ? productToOrderServiceType(product) : undefined;
        const trashPreset: ETrashType | undefined = product.trash_type;

        console.log('DEBUG: => preset()', typePreset, servicePreset, trashPreset);

        setType(typePreset);
        setServiceType(servicePreset);
        setTrashType(trashPreset);
    };

    const reset = () => {
        console.log('DEBUG: => reset()');
        setType(undefined);
        setServiceType(undefined);
        setTrashType(undefined);
        setProducts([]);
    };

    return {
        type: {
            value : type,
            set   : setType
        },
        serviceType: {
            value : serviceType,
            set   : setServiceType
        },
        trashType: {
            value : trashType,
            set   : setTrashType
        },
        isPending,
        isError,
        products,
        refetch,
        preset,
        reset,
        visualConditions: {
            showServiceType : type === EOrderTypeForm.COLLECT_DUMPSTER,
            showTrashType   :
                (type === EOrderTypeForm.COLLECT_DUMPSTER && serviceType !== undefined && serviceType.length > 0) ||
                type === EOrderTypeForm.COLLECT_BIG_BAG,
            showEmptyProductTag:
                !isError &&
                !isPending &&
                (type === EOrderTypeForm.DELIVERY || trashType !== undefined) &&
                products.length === 0
        }
    };
};
type ProductSelect = ReturnType<typeof useProductSelect>;

export function InputListPrice(props: InputListPriceProps) {
    const [errors, setErrors] = React.useState<Record<string, string[]>>({});
    const isError =
        Object.keys(errors)
            .map((e) => errors[e] ?? [])
            .flat().length > 0;
    const productSelector = useProductSelect();

    return (
        <InputList.Base<Line>
            title={'Tarif'}
            columns={[
                {
                    header : 'Produit',
                    field  : 'product_id',
                    body   : (val: Line, test) =>
                        val.product_id?.name ? (
                            `${val.product_id?.name}`
                        ) : (
                            <>
                                <Tooltip target={`.no-product-${test.rowIndex}`} position="right" />
                                <div
                                    className={`no-product-${test.rowIndex}`}
                                    data-pr-tooltip="Si le produit n'est pas remplacé, ce tarif sera supprimé à la prochaine édition."
                                >
                                    <Tag icon="pi pi-exclamation-triangle" severity="error">
                                        Produit supprimé ou absent
                                    </Tag>
                                </div>
                            </>
                        )
                },
                {
                    header : 'Type de déchet',
                    field  : 'product_id',
                    body   : (val: Line) => mapTrashTypetext(val.product_id?.trash_type)
                },
                {
                    header : 'Prix Endless',
                    field  : 'price_value',
                    body   : (val: Line) =>
                        Dinero({
                            amount   : val.product_id?.price.net_amount_cents,
                            currency : val.product_id?.price.currency
                        }).toFormat('$0.00')
                },
                {
                    header : 'Prix Prestataire',
                    field  : 'price_value',
                    body   : (val: Line) => `${val.value}€`
                }
            ]}
            disableModalButton={isError}
            setErrors={setErrors}
            modalTemplate={PriceModal(productSelector, errors, setErrors)}
            onEdit={(data) => {
                if (data?.product_id) {
                    productSelector.preset(data.product_id);
                }
            }}
            onClose={() => {
                productSelector.reset();
            }}
            {...props}
        />
    );
}

const typeMapper: Record<EOrderTypeForm, string> = {
    [EOrderTypeForm.COLLECT_DUMPSTER] : 'Collecte benne',
    [EOrderTypeForm.COLLECT_BIG_BAG]  : 'Collecte big bag',
    [EOrderTypeForm.DELIVERY]         : 'Livraison'
};

const serviceMapper: Record<EOrderDumpsterServiceForm, string> = {
    [EOrderDumpsterServiceForm.DEPOSIT]   : 'Dépôt',
    [EOrderDumpsterServiceForm.RETRIEVAL] : 'Enlèvement',
    [EOrderDumpsterServiceForm.ROTATION]  : 'Rotation',
    [EOrderDumpsterServiceForm.LOAD_WAIT] : 'Attente chargement'
};

const productToOrderServiceType = (product: ProductRo): EOrderDumpsterServiceForm | undefined => {
    switch (product.family) {
        case PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT:
            return EOrderDumpsterServiceForm.DEPOSIT;
        case PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL:
            return EOrderDumpsterServiceForm.RETRIEVAL;
        case PRODUCT_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT:
            return EOrderDumpsterServiceForm.LOAD_WAIT;
        default:
            return undefined;
    }
};

const trashOptions = Object.values(ETrashType).map((type) => ({
    name  : mapTrashTypetext(type),
    value : type
}));

const PriceModal =
    (
        selector: ProductSelect,
        errors: Record<string, string[]>,
        setError: React.Dispatch<React.SetStateAction<Record<string, string[]>>>
    ) =>
    (state: Line | undefined, setter: React.Dispatch<React.SetStateAction<Line | undefined>>) => {
        if (!state?.product_id && errors['product']?.[0] === undefined) {
            setError((prev) => {
                const newState = { ...prev, product: ['Vous devez selectionner un produit'] };

                return newState;
            });
        } else if (state?.product_id && errors['product']?.[0] !== undefined) {
            setError((prev) => {
                const newState = { ...prev, product: [] };

                return newState;
            });
        }

        return (
            <ModalContainer>
                <FormContainer>
                    <div>
                        <RowSpaced>
                            <p>Selectionner un produit</p>
                        </RowSpaced>
                        <div>
                            <Button.Select
                                id="type"
                                options={Object.values(EOrderTypeForm)}
                                labelMapper={(value) => typeMapper[value as EOrderTypeForm]}
                                result={(value) => {
                                    setter(() => {
                                        const newState: Line = { product_id: undefined, value: 0 };

                                        return newState;
                                    });
                                    selector.trashType.set(undefined);
                                    selector.serviceType.set(undefined);
                                    selector.type.set(value as EOrderTypeForm);
                                }}
                                value={selector.type.value}
                            />
                        </div>
                    </div>
                    {selector.visualConditions.showServiceType && (
                        <div>
                            <Button.Select
                                id="serviceType"
                                options={Object.values(EOrderDumpsterServiceForm)}
                                labelMapper={(value) => serviceMapper[value as EOrderDumpsterServiceForm]}
                                result={(value) => {
                                    console.log(`DEBUG: => value:`, value);
                                    setter(() => {
                                        const newState: Line = { product_id: undefined, value: 0 };

                                        return newState;
                                    });
                                    selector.trashType.set(undefined);
                                    selector.serviceType.set(value as EOrderDumpsterServiceForm);
                                }}
                                value={selector.serviceType.value}
                            />
                        </div>
                    )}
                    {selector.visualConditions.showTrashType && (
                        <div>
                            <Dropdown
                                value={selector.trashType.value}
                                onChange={(e) => selector.trashType.set(e.value)}
                                placeholder="Sélectionner un type de déchet"
                                optionValue="value"
                                optionLabel="name"
                                options={trashOptions}
                            />
                        </div>
                    )}
                    {selector.isPending && <ProgressBar loadingText="Récuperation des produits..." />}
                    {!selector.isPending && selector.isError && (
                        <Tag severity="danger" value="Erreur lors de la récupération des produits" />
                    )}
                    {selector.visualConditions.showEmptyProductTag && (
                        <Tag severity="warning" value="Aucun produit disponible pour cette configuration" />
                    )}
                    {selector.products.length > 0 && (
                        <ProductContainer>
                            {selector.products.map((product) => {
                                const infoTag = [
                                    product.internal ? 'interne' : undefined,
                                    product.operational ? 'opérationnel' : undefined,
                                    ...product.billing_modes.map((mode) =>
                                        mode === EProductBillingMode.INSTANT ? 'paiement intantané' : 'paiment différé'
                                    )
                                ].filter((f) => f !== undefined);

                                return (
                                    <ProductCard
                                        key={product.id}
                                        selected={state?.product_id?.id === product.id}
                                        onClick={() => {
                                            setter(() => {
                                                const newState: Line = { product_id: product as ProductRo, value: 0 };

                                                return newState;
                                            });
                                        }}
                                    >
                                        <div className="head">
                                            <span>{product.name}</span>
                                            <span>
                                                {Dinero({
                                                    amount   : product.price.net_amount_cents,
                                                    currency : product.price.currency
                                                }).toFormat('$0.00')}
                                            </span>
                                        </div>
                                        {infoTag.length > 0 && (
                                            <div className="info-tag">
                                                {infoTag.map((tag) => (
                                                    <i>{tag}</i>
                                                ))}
                                            </div>
                                        )}
                                        {product.zone_id.length > 0 && (
                                            <div className="product-zones">
                                                {product.zone_id.map((zone) =>
                                                    typeof zone === 'string' ? (
                                                        <></>
                                                    ) : (
                                                        <div className="tag" key={zone.id}>
                                                            {zone.name}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                        {/* {product.zone_id} */}
                                    </ProductCard>
                                );
                            })}
                        </ProductContainer>
                    )}
                    {state?.product_id && (
                        <PriceContainer>
                            <div className="price">
                                <div className="title">Prix Endless</div>
                                <div className="endless-price">
                                    {Dinero({
                                        amount   : state?.product_id?.price.net_amount_cents,
                                        currency : state?.product_id?.price.currency
                                    }).toFormat('$0.00')}
                                </div>
                            </div>
                            <div className="price">
                                <div className="title">Prix Prestataire</div>
                                <InputNumber
                                    id="value"
                                    locale="fr-FR"
                                    showButtons
                                    mode="currency"
                                    currency="EUR"
                                    className="p-inputtext-sm"
                                    value={state?.value}
                                    onValueChange={(e) => {
                                        setter((prev) => {
                                            const newState = { ...prev, value: e.value } as Line;

                                            return newState;
                                        });
                                    }}
                                />
                            </div>
                        </PriceContainer>
                    )}
                </FormContainer>
            </ModalContainer>
        );
    };

const ModalContainer = styled.div`
    .p-dropdown {
        width: 100% !important;
    }
    span:last-child {
        grid-column: 1 / 3;
    }
`;

const FormContainer = styled.div`
    display: flex;
    min-width: 550px;
    flex-direction: column;
    align-items: stretch;
    gap: 25px;
`;

const RowSpaced = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    & > p {
        font-size: 1.2rem;
        font-weight: bold;
    }
`;

const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
    max-height: 250px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 8px;
    background-color: #fafafa;
    box-shadow: inset 0 0 4px rgb(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.2);
`;

const ProductCard = styled.div<{ selected: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #fff;
    border: 2px solid ${(props) => (props.selected ? 'var(--primary-color)' : 'rgba(0, 0, 0, 0.2)')};

    /* box-shadow: 0 0 5px #000; */
    cursor: pointer;
    transition: 0.1s;
    &:hover {
        background-color: #fafafa;
    }

    & > div {
        min-height: 21px;
    }

    .head {
        display: flex;
        justify-content: space-between;
    }
    .info-tag {
        color: var(--yellow-500);
        & > i + i {
            margin-left: 8px;
        }
    }
    .product-zones {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
    }
    .tag {
        background-color: #f0f0f0;
        padding: 4px 6px;
        border-radius: 5px;
        font-size: 0.8rem;
        font-weight: bold;
    }
`;

const PriceContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;

    & > .price {
        display: flex;
        flex-direction: column;
        gap: 5px;
        & > .title {
            font-size: 1.1rem;
            font-weight: bold;
        }
        & > .endless-price {
            margin: 9px;
        }
    }
`;
