import React from 'react';

const debounce = <Args = any[], R = void>(
    fn: (args?: Args) => R,
    ms: number
): [(args?: Args) => Promise<R>, () => void] => {
    let timeout: NodeJS.Timeout;

    const debouncedFunction = (args?: Args): Promise<R> => {
        return new Promise((resolve) => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                resolve(fn(args));
            }, ms);
        });
    };

    const cancelTimeout = () => timeout && clearTimeout(timeout);
    return [debouncedFunction, cancelTimeout];
};

export const useDebounce = <Args = any[], R = void>(
    fn: (args?: Args) => R,
    ms: number
): [(args?: Args) => Promise<R>, () => void] => {
    const [[debouncedFunction, cancelTimeout]] = React.useState(debounce<Args, R>(fn, ms));

    React.useEffect(() => () => cancelTimeout(), []);

    return [debouncedFunction, cancelTimeout];
};
