import { useEffect } from 'react';
import Button from '../../../components/Button';
import { FilterSectionTitle } from './style';
import { FilterChecked, PlanningFilterClassNames } from './helpers';
import React from 'react';

export type GenericFilterProps = {
    objectKey: keyof PlanningFilterClassNames;
    reset: boolean;
    setFilterClassNames: React.Dispatch<React.SetStateAction<PlanningFilterClassNames>>;
    val: Record<string, FilterChecked>;
    setVal: React.Dispatch<React.SetStateAction<Record<string, FilterChecked>>>;
    initialValues: Record<string, FilterChecked>;
    title: string;
    labelMapper: (key: any) => string;
};

export const GenericFilter: React.FC<GenericFilterProps> = ({
    objectKey,
    reset,
    setFilterClassNames,
    val,
    setVal,
    initialValues,
    title,
    labelMapper
}: GenericFilterProps): JSX.Element => {
    useEffect(() => {
        if (reset) {
            setVal(initialValues);
            setFilterClassNames((current) => ({ ...current, [objectKey]: [] }));
        }
    }, [reset]);

    return (
        <>
            <FilterSectionTitle>{title}</FilterSectionTitle>
            {Object.entries(val).map(([category, filter]) => {
                return (
                    <Button.Checkbox
                        key={category}
                        id={category}
                        label={labelMapper(category)}
                        value={filter.checked}
                        result={(value) => {
                            if (value) {
                                setFilterClassNames((current) => ({
                                    ...current,
                                    [objectKey]: current[objectKey].concat(`.${filter.className}`)
                                }));
                            } else {
                                setFilterClassNames((current) => ({
                                    ...current,
                                    [objectKey]: current[objectKey].filter((val) => !val.includes(filter.className))
                                }));
                            }

                            setVal((current) => ({ ...current, [category]: { ...filter, checked: value } }));
                        }}
                        labelPosition="right"
                    />
                );
            })}
        </>
    );
};
