import { ETrashType } from '@bbng/util/types';

export const mapTrashTypeToCode = {
    [ETrashType.PAPER_CARDBOARD]  : { code: '150101', denomination: 'Emballages papiers/carton' },
    [ETrashType.METAL]            : { code: '170407', denomination: 'Métaux en mélange' },
    [ETrashType.PLASTIC]          : { code: '170203', denomination: 'Matière plastiques' },
    [ETrashType.GLASS]            : { code: '170202', denomination: 'Verre' },
    [ETrashType.WOOD]             : { code: '170201', denomination: 'Bois' },
    [ETrashType.MINERAL_FRACTION] : { code: 'XXXXXXXXXXX', denomination: 'XXXXXXXXXXX' },
    [ETrashType.PLASTER]          : { code: '170802', denomination: 'Matériaux de construction à base de gypse' },
    [ETrashType.ASBESTOS]         : { code: '170605', denomination: 'Matériaux de construction contenant de l’amiante' },
    [ETrashType.LEAD]             : { code: '170904', denomination: 'Déchets de construction et démolition en mélange' },
    [ETrashType.MIXED]            : { code: '170904', denomination: 'Déchets de construction et démolition en mélange' },
    [ETrashType.RUBBLE]           : { code: '170504', denomination: 'Terre et cailloux' }, //Gravat
    [ETrashType.OIW]              : { code: '170904', denomination: 'Déchets de construction et démolition en mélange' }, // DIB
    [ETrashType.GREEN_TRASH]      : { code: '200201', denomination: 'Déchets biodégradables' },
    [ETrashType.ULTIMATE_TRASH]   : { code: 'XXXXXXXXXXX', denomination: 'XXXXXXXXXXX' }
};
