import React from 'react';
import styled from 'styled-components';

import { CardErrors, IOpeningTime } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Calendar from '../../components/Calendar';
import { Card } from '../../components/Card';
import { textInputProps } from '../../components/Inputs';

export type LandfillCenterOpeningTimeProps = {
    readOnly?: boolean;
    value?: LandfillCenterOpeningTimeState;
    id: string;
    result: (
        value: LandfillCenterOpeningTimeState,
        errors: null | string[] | CardErrors<LandfillCenterOpeningTimeState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type LandfillCenterOpeningTimeState = {
    calendar?: IOpeningTime;
};
export type LandfillCenterOpeningTimeErrorState = CardErrors<LandfillCenterOpeningTimeState>;

export const initialState: LandfillCenterOpeningTimeState = {
    calendar: undefined
};

export const initialErrorState: LandfillCenterOpeningTimeErrorState = generateInitialErrorState(initialState);

export const initialRef = Array.from(Array(Object.keys(initialState).length)).map(() =>
    React.createRef<textInputProps>()
);

const FullLineInput = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    & > * {
        flex: 1;
        .p-inputtext {
            width: 100%;
        }
    }
`;

export const LandfillCenterOpeningTime = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: LandfillCenterOpeningTimeProps) => {
    const [val, setVal] = React.useState<LandfillCenterOpeningTimeState>(value);
    const [err, setErr] = React.useState<LandfillCenterOpeningTimeErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null | any, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <Card title="Heures d'ouverture">
            <FullLineInput>
                <Calendar.OpenClose
                    id="calendar"
                    readOnly={readOnly}
                    value={val.calendar}
                    result={handleChange}
                    displayError={displayError}
                    required
                />
            </FullLineInput>
        </Card>
    );
};
