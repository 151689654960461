export enum EEmailOrderResentPaymentLinkKeys {
    PAYMENT_LINK = '{{PAYMENT_LINK}}',
    ORDER_NUMBER = '{{ORDER_NUMBER}}',
    BUTTON_WARNING = '{{BUTTON_WARNING}}',
    USERNAME = '{{USERNAME}}'
}

export const emailOrderResentPaymentLink = `
<table class="es-content" cellspacing="0" cellpadding="0" align="center"
    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
    <tr>
        <td align="center" style="padding:0;Margin:0">
            <table class="es-content-body" cellspacing="0" cellpadding="0" align="center"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:600px">
                <tr>
                    <td align="left" style="padding:0;Margin:0;background-color:#fff">
                        <table width="100%" cellspacing="0" cellpadding="0"
                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                                <td valign="top" align="center"
                                    style="padding:32px 0 0;Margin:0;width:600px">
                                    <table width="100%" cellspacing="0" cellpadding="0"
                                        role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Bonjour {{USERNAME}},
                                                    </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:30px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Suite à votre demande, voici un nouveau lien de paiement pour votre commande {{ORDER_NUMBER}}.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center" style="padding:0;margin:0">
                                                <!--[if mso]><a href="{{PAYMENT_LINK}}" target="_blank" hidden> <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" esdevVmlButton href="{{PAYMENT_LINK}}" style="height:48px; v-text-anchor:middle; width:271px" arcsize="10%" stroke="f" fillcolor="#5465fc"> <w:anchorlock></w:anchorlock> <center style='color:#ffffff; font-family:arial, "helvetica neue", helvetica, sans-serif; font-size:18px; font-weight:700; line-height:18px; mso-text-raise:1px'>Payer ma commande</center> </v:roundrect></a><![endif]--><!--[if !mso]><!-- --><span
                                                    class="msohide es-button-border"
                                                    style="border-style:solid;border-color:#2cb543;background:#5465fc;border-width:0px;display:inline-block;border-radius:6px;width:auto;mso-hide:all"><a
                                                        href="{{PAYMENT_LINK}}"
                                                        class="es-button es-button-1"
                                                        target="_blank"
                                                        style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:16px;border-style:solid;border-color:#5465fc;border-width:20px 45px;display:inline-block;background:#5465fc;border-radius:6px;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-weight:bold;line-height:22px;width:auto;text-align:center">Payer ma commande</a></span><!--<![endif]--></td>
                                        </tr>
                                        {{BUTTON_WARNING}}
                                        <tr>
                                            <td align="left" class="es-m-txt-c es-m-p10r es-m-p10l"
                                                style="Margin:0;padding-top:5px;padding-bottom:5px;padding-left:25px;padding-right:25px">
                                                <p
                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:27px;color:#555555;font-size:16px">
                                                    Merci pour votre confiance et à bientôt sur vos chantiers !
                                                    </p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
`;
