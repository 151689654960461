import moment from 'moment';
import styled from 'styled-components';

import { TIME_STEP } from '.';

export const Grid = ({
    start,
    end,
    precision,
    displayTime = false
}: {
    start: string;
    end: string;
    precision: number;
    displayTime?: boolean;
}) => {
    const duration = moment(end).diff(moment(start), 'minutes');
    return (
        <>
            {[...Array(Math.floor(duration / precision) + 1)].map((_, index) => {
                const time = moment(start).add(precision * index, 'minute');
                const rowStart = Math.floor(Math.abs(time.diff(moment(start), 'minutes')) / TIME_STEP) + 1;
                return (
                    <GridItem
                        style={{ borderBottom: 'solid 1px #d4d4d4' }}
                        key={time.toISOString()}
                        columnStart={displayTime ? 2 : 1}
                        columnEnd={-1}
                        rowStart={rowStart}
                        rowEnd={rowStart + 1}
                    />
                );
            })}
        </>
    );
};

export const Legend = ({ start, end, precision }: { start: string; end: string; precision: number }) => {
    const duration = moment(end).diff(moment(start), 'minutes');
    return (
        <>
            {[...Array(Math.floor(duration / precision) + 1)].map((_, index) => {
                const time = moment(start).add(precision * index, 'minute');
                const rowStart = Math.floor(Math.abs(time.diff(moment(start), 'minutes')) / TIME_STEP) + 1;
                return (
                    <GridItem
                        key={time.toISOString()}
                        columnStart={1}
                        columnEnd={-1}
                        rowStart={rowStart + 1}
                        rowEnd={rowStart + 2}
                    >
                        <div
                            style={{
                                display  : 'block',
                                position : 'relative',
                                top      : '-10px',
                                fontSize : '12px',
                                color    : '#d4d4d4'
                            }}
                        >
                            {time.format('HH:mm')}
                        </div>
                    </GridItem>
                );
            })}
        </>
    );
};

/**
 * Whole grid containing all shifts.
 */
export const PlanningGrid = styled.div<{ columnLength: number; rowLength: number; zoom: number }>`
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 2fr repeat(${(props) => props.columnLength}, min(300px, 100%));
    grid-template-rows: 200px repeat(${(props) => props.rowLength}, ${(props) => props.zoom}px);
    column-gap: 4px;
    overflow-x: scroll;
`;

/**
 * Individual grid containing one shift.
 */
export const ShiftGrid = styled.div<{ disableRows: boolean; columnLength: number; rowLength: number; zoom: number }>`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 220px repeat(
            ${(props) => (props.disableRows ? 0 : props.rowLength)},
            ${(props) => props.zoom}px
        );
    column-gap: 4px;
`;

export const GridItem = styled.div<{
    columnStart: number;
    columnEnd: number;
    rowStart: number;
    rowEnd: number;
    zIndex?: number;
}>`
    grid-column: ${(props) => props.columnStart} / ${(props) => props.columnEnd};
    grid-row: ${(props) => props.rowStart} / ${(props) => props.rowEnd};
    margin: 0.5px 0 0;
    z-index: ${(props) => props.zIndex ?? 0};
`;
