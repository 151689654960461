import { PRODUCT_DUMPSTER_TYPE } from '@bbng/util/types';
import { MultiSelect } from 'primereact/multiselect';
import styled from 'styled-components';
import { mapDumpsterTypeText, mapDumpsterTypeToHexa } from '../../common/enumMapper';

export type DumpsterSelectorProps = {
    setDumpsterTypes: (value: PRODUCT_DUMPSTER_TYPE[]) => void;
    dumpsterTypes: PRODUCT_DUMPSTER_TYPE[];
};

export const DumpsterSelector: React.FC<DumpsterSelectorProps> = ({ dumpsterTypes, setDumpsterTypes }): JSX.Element => {
    return (
        <MultiSelectContainer className="p-float-label">
            <MultiSelect
                id="dumpsterTypes"
                value={dumpsterTypes}
                itemTemplate={(type) => mapDumpsterTypeText(type)}
                selectedItemTemplate={(type) => (
                    <SelectedItemTemplateContainer backgroundColor={mapDumpsterTypeToHexa(type)}>
                        {mapDumpsterTypeText(type)}
                    </SelectedItemTemplateContainer>
                )}
                options={Object.values(PRODUCT_DUMPSTER_TYPE)}
                onChange={(e) => setDumpsterTypes(e.value)}
            />
            <label htmlFor="dumpsterTypes" className="p-float-label">
                Types de bennes
            </label>
        </MultiSelectContainer>
    );
};

const MultiSelectContainer = styled.div`
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
        top: -0.75em;
        font-weight: 12px;
    }
`;

const SelectedItemTemplateContainer = styled.span<{ backgroundColor: string }>`
    :not(:first-child) {
        margin-left: 4px;
    }
    padding: 2px 6px;
    border-radius: 4px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: white;
`;
