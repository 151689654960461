import { Truck, Truck_Authz, Truck_History } from '@prisma/client/truck';

import { AddressCreateDto, AddressRo } from '../address';
import { IKafkaQuery } from '../kafka';
import { ISODate } from '../misc';
import { EPlanningRegion, EPlanningType } from '../planning';

export interface TruckCreateDto {
    test_name?: string;
    name: string;
    garage_address: AddressCreateDto;
    weight_ton?: number;
    characteristics?: ITruckCharacteristics;
    type: ETruckType[];
    license_plate: string;
    is_available?: boolean;
}

export type TruckCreateActionDto = TruckCreateDto & { id?: string };

export type TruckEditDto = {
    test_name?: string;
    name?: string;
    weight_ton?: number;
    garage_address?: AddressCreateDto;
    characteristics?: ITruckCharacteristics;
    type?: ETruckType[];
    license_plate?: string;
    is_available?: boolean;
};

export type TruckEditActionDto = TruckEditDto & {
    archived: boolean;
};

export interface TruckRo {
    id: string;
    test_name?: string;
    name: string;
    garage_address: AddressRo;
    weight_ton: number;
    characteristics: ITruckCharacteristics;
    type: ETruckType[];
    license_plate: string;
    created_at: ISODate;
    updated_at: ISODate;
    created_by: string;
    is_available: boolean;
    region: EPlanningRegion;
    archived: boolean;
}

/**
 * Data needed for VRP optimization algorithm
 */
export interface ITruckCharacteristics {
    fuel_type: ETruckFuelType;
    max_autonomy: number;
    remaining_autonomy?: number;
    fuel_refill_duration: number;
    // usable_volume_m3: number;
    usable_load_kg: number;
    setup_duration: number;
    setdown_duration: number;
    profitability_bag_number_limit: number;
    profitability_dumpster_number_limit: number;
    dumpster_capacity?: number; // how many dumpsters a truck can carry
    bigbag_capacity?: number; // how many bigbags a truck can carry
    bigbag_stock?: number; // how many empty bigbags a truck has
    bigbag_current_load_m3?: number;
    bigbag_current_collects?: Record<string, string[]>;
}

export enum ETruckFuelType {
    UNLEADED_95 = 'UNLEADED_95',
    UNLEADED_98 = 'UNLEADED_98',
    ETHANOL = 'ETHANOL',
    DIESEL = 'DIESEL',
    HYDROGEN = 'HYDROGEN',
    CNG = 'CNG', // Compressed Natural Gas
    LNG = 'LNG', // Liquid Natural Gas
    LPG = 'LPG' // Liquid Petroleum Gas
}

export enum ETruckType {
    BIG_BAG = 'BIG_BAG',
    DUMPSTER_AMPLIROLL = 'DUMPSTER_AMPLIROLL',
    DUMPSTER_CHAIN = 'DUMPSTER_CHAIN',
    ADR = 'ADR',
    DELIVERY = 'DELIVERY',
    NARROW_STREET = 'NARROW_STREET',
    OTHER = 'OTHER'
}

export interface TruckQuery extends IKafkaQuery {
    region?: EPlanningRegion;
    type?: ETruckType | EPlanningType;
    types?: ETruckType[];
}

export type TruckPrisma = Truck & { authorization: Truck_Authz; history: Truck_History };
