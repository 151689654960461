import { EDataType, ErrorContext, ErrorResponsability } from '@bbng/util/types';

/**
 * ERROR CODES
 */
export const ErrorCodeFR = {
    [ErrorContext.Auth]: {
        'wrong-mail'                : () => "L'adresse mail est incorrecte",
        'wrong-code'                : () => 'Le code est incorrect',
        'retry-in'                  : (params: Record<string, any>) => `Réessayez dans ${params['time']} secondes`,
        'no-jwt'                    : () => "Aucun token JWT n'a été trouvé. Vous devez être authentifié pour accéder à cette ressource.",
        'no-expiration-token'       : () => "Aucun code de validation n'a été trouvé.",
        'expired-token'             : () => 'Le code de validation est expiré.',
        'not-found'                 : () => 'Utilisateur inconnu.',
        'expired-session'           : () => 'La session est expirée.',
        'wrong-jwt'                 : () => 'Token JWT invalide.',
        'missing-user-informations' : () => 'Informations utilisateur manquantes.',
        'archived-user'             : () => "L'utilisateur a été supprimé."
    },
    [ErrorContext.Business]: {
        product_doesnt_have_endless_billing_organization: () =>
            'Un ou plusieurs produits ne sont pas inscrits à une organisation.',
        cannot_charge_more_than_one_product_branch_at_times: () =>
            'Vous ne pouvez pas charger des produits de plusieurs organisations en une seule commande.',
        break_already_taken   : () => 'La durée légale de pause a déjà été atteinte.',
        first_break_not_30    : () => 'La première pause ne peut être que de 15 ou 45 minutes.',
        second_break_not_30   : () => 'La deuxième pause est forcément de 30 minutes.',
        order_for_current_day : () => 'Vous ne pouvez pas créer une commande pour le même jour.',
        too_late_to_create    : () =>
            'Vous ne pouvez pas créer de commande pour le prochain travaillé après 22h00. Vous devez la créer à J+2',
        move_cc_in_past         : () => 'Vous ne pouvez pas déplacer une commande dans le passé.',
        to_date_after_from_date : () => 'La date de fin doit être après la date de début.',
        too_late_to_edit        : () =>
            `Deadline pour modifier une évacutation: J-1 à 18h00.\nVous pouvez l'annuler et en créer une nouvelle à J+1, ou créer une collecte complémentaire à J+1 en indiquant sa disponibilité au jour J`,
        freeze_no_edit: () =>
            "Vous ne pouvez pas éditer d'évacuation non modifiable (prépayée, avec BC & paiement à la commande",
        split_no_edit                               : () => "Vous ne pouvez pas éditer d'évacuation découpée par les ops.",
        status_no_edit                              : () => "Vous ne pouvez pas éditer d'évacuation terminée, ou en aléas, ou annulée.",
        split_no_cancel                             : () => "Vous ne pouvez pas annuler d'évacuation découpée par les ops.",
        status_no_cancel                            : () => "Vous ne pouvez pas annuler d'évacuation terminée, ou en aléas, ou annulée.",
        order_paid_and_partially_finished_no_cancel : () =>
            "Vous ne pouvez pas annuler une commande déjà payée dont au moins une des demandes d'évacuation est terminée ou en aléa.",
        drifted_order_status: () =>
            "La commande est à payer alors qu'une des demandes d'évacuation associée n'est pas en attente de paiement. Ce cas ne devrait pas arriver, merci de contacter le support technique immédiatement",
        unassigned_in_past       : () => "Vous ne pouvez pas désassigner un chauffeur d'un planning dans le passé.",
        unassigned_with_collects : () =>
            "Vous ne pouvez pas désassigner un chauffeur d'un planning qui a réalisé des collectes.",
        collector_on_multiple_planning: () =>
            'Vous essayez de sauvvegarder un planning avec un chauffeur qui est déjà assigné à un autre planning.',
        no_planning_to_save                            : () => 'Aucun planning à sauvegarder.',
        no_planning_for_instance                       : () => 'Aucun planning ayant des instances.',
        planning_on_past                               : () => 'Vous ne pouvez pas sauvegarder un planning dans le passé.',
        cant_find_previous_version_of_planning_to_save : () =>
            'Impossible de trouver la version précédente du planning à sauvegarder.',
        missing_item: (params: Record<string, any>) =>
            `Un objet est manquant pour finaliser la saga : ${params['item']}`,
        discount_overlap: (params: Record<string, any>) =>
            'Vous essayez de créer une remise avec des conditions déjà existantes pour ce client: ' +
            params['discountIds'],
        discount_construction_site_not_from_customer: () =>
            "Vous essayez de créer une remise pour un chantier qui n'appartient pas à ce client.",
        construction_site_not_from_customer: () =>
            "Vous essayez de créer une évacuation pour un chantier qui n'appartient pas à ce client.",
        order_sheet_without_number: () => {
            'Lorsque vous ajoutez un bon de commande, vous devez obligatoirement renseigner son numéro.';
        },
        mandatory_order_sheet: () =>
            'Ce client est obligé de fournir un bon de commande pour chaque évacuation (ou pour son chantier).',
        customer_blocked            : () => 'Ce client est bloqué.',
        no_user_for_instant_payment : () => "Ce client doit payer à la commande mais n'a pas de user associé.",
        collect_integrity           : () => 'Vous essayez de créer une collecte avec des familles de collectes incohérentes.',
        finish_only_delivery        : () => "Vous ne pouvez pas terminer une collecte qui n'est pas une livraison.",
        delivery_not_paid           : () => "Vous ne pouvez pas terminer une livraison qui n'est pas payée.",
        customer_without_contact    : () =>
            "Ce client n'a pas de contact associé. Cette condition est nécessaire pour pouvoir le facturer.",
        customer_without_primary_contact_on_edit: () => {
            'Un client doit forcément avoir un contact principal. Vous ne pouvez pas supprimer le contact principal d\'un client.';
        },
        customer_without_invoice_contact_on_edit: () => {
            'Un client doit forcément avoir un contact facturation. Vous ne pouvez pas supprimer le contact facturation d\'un client.';
        },
        merge_only_bb             : () => 'Vous ne pouvez pas fusionner des évacuations qui ne sont pas des évacuations BB.',
        merge_status_incompatible : () =>
            'Vous ne pouvez pas fusionner des évacuations découpée dont au moins une est terminée, en aléas.',
        merge_only_splitted: () =>
            'Vous ne pouvez pas fusionner des évacuations qui ne sont pas des évacuations découpées.',
        split_order_to_pay        : () => 'Vous ne pouvez pas découpé une évacuation à payer.',
        split_only_bb             : () => "Vous ne pouvez pas découpé une évacuation qui n'est pas une évacuation BB.",
        split_status_incompatible : () =>
            'Vous ne pouvez pas découpé une évacuation qui terminée, en aléas, annulée, ou plannifiée.',
        split_already_splitted: () =>
            'Vous ne pouvez pas découpé une évacuation qui est déjà découpée. Merci de la fusionner avant.',
        cc_not_waiting_for_approval: () =>
            "Vous ne pouvez pas approuver une évacuation qui n'est pas en attente d'approbation.",
        invoice_already_generating: () =>
            "Les factures de fins de mois sont déjà en cours de génération, veuillez attendre qu'elles apparaissent ou veuillez essayer dans 1 heure.",
        paying_order_not_good_status: () =>
            "Vous ne pouvez pas payer une évacuation qui n'est pas en attente de paiement.",
        customer_category_change_only_admin      : () => "Seul un administrateur peut changer la catégorie d'un client.",
        customer_no_contact                      : () => "Vous ne pouvez pas supprimer tous les contacts d'un client.",
        customer_contact_needed_for_mail_invoice : () =>
            'Il faut minimum un contact si vous souhaitez envoyer les factures par mail.',
        no_ccs_services_to_plan: () =>
            "Aucune évactuation n'est plannifiée pour ce jour. Nous ne pouvons donc pas calculter de planning.",
        collect_already_exist: () =>
            "Vous essayez de créer une collecte qui existe déjà. Veuillez vérifier les dates et les familles de collectes. Si le problème persiste, veuillez contacter l'équipe technique.",
        postpone_status_incompatible: () =>
            'Vous ne pouvez rappatrier que des évacuations à plannifier ou plannifiées.',
        postpone_in_the_past                      : () => "Vous ne pouvez pas rapatrier une évacuation dans le passé ou à aujourd'hui.",
        zone_archived_for_order                   : () => 'Vous ne pouvez pas créer une évacuation dans une zone archivée.',
        customer_without_contact_on_user_archived : () =>
            "Vous essayez d'archiver un utilisateur mais son client associé se retrouvera sans contact. Ce n'est pas possible. Veuillez ajouter un autre contact au client associé si vous souhaitez archiver cet utilisateur.",
        collect_not_planned_on_not_ready_planning: () =>
            "Vous essayez de créer une collecte sur un planning qui n'est pas correctement paramétré (binome camion/chauffeur incomplet).",
        collect_not_planned_on_wrong_planning: () =>
            "Vous essayez de créer une collecte sur un planning dont le binome camion/chauffeur demandé n'exite pas.",
        planning_not_finished_to_create_not_planned_collect: () =>
            "Vous essayez de créer une collecte sur un planning qui n'est pas terminé. Veuillez terminer le planning avant de créer une collecte.",
        calculate_on_planning_finished    : () => "Le calcul n'est pas possible sur des plannings déjà terminés.",
        manual_shift_on_finished_planning : () =>
            "La configuration manuelle n'est pas possible sur un planning déjà commencé.",
        unassign_cc_not_from_same_config: () =>
            "Vous ne pouvez pas désassigner une évacuation d'un planning n'était pas du même type, de la même région ou du même jour.",
        unassign_cc_not_from_planning: () =>
            "Vous essayez de désassigner une évacuation d'un planning ne possédant pas cette evacuation.",
        planning_already_started_no_save: () =>
            "Vous ne pouvez pas sauvegarder un planning qui a déjà commencé sans l'avoir recalculé au préalable.",
        unassign_cc_administrative     : () => 'Vous ne pouvez pas désassigner une évacuation administrative.',
        unassign_cc_not_planned        : () => "Vous ne pouvez pas désassigner une évacuation qui n'est pas plannifiée.",
        order_already_paid             : () => 'Cette commande a déjà été payée.',
        order_without_checkout_session : () =>
            "Cette commande ne possède aucune session de paiement. Contactez l'équipe technique.",
        collect_invoiced_patch_forbidden    : () => 'Vous ne pouvez pas modifier une collecte déjà facturée.',
        invoice_generation_number_not_match : () =>
            "Le nombre de factures générées ne correspond pas au nombre de factures attendues. Veuillez contacter l'équipe technique.",
        user_already_have_a_customer: () =>
            "Vous essayez de créer un client pour un utilisateur qui en possède déjà un. Vous n'avez pas le droit.",
        user_without_userId: () =>
            "Vous n'avez pas fourni d'identifiant d'utilisateur. Cela ne devrait pas arriver. Contactez l'équipe technique.",
        cc_on_multiple_planning: (params: Record<string, any>) =>
            'Certaines collectes sont sur plusieurs plannings: ' + params['ids'].join(', '),
        customer_instant_delivery_not_possible              : () => "Le client n'est pas éligible à la livraison instantanée.",
        customer_billing_mode_invoice_generate_saga_useless : () =>
            'La saga de génération de factures suite à un changement de mode de facturation est inutile (client en facturation fin de mois).',
        google_drive_without_children: (params: Record<string, any>) =>
            `Le client ${params['customerName']} possède un dossier google drive sans dossier. Contactez l'équipe technique.`,
        generate_only_bsd_on_service   : () => 'Vous ne pouvez générer que des BSD pour des évacuations.',
        generate_only_bsd_for_finished : () => 'Vous ne pouvez générer que des BSD pour des évacuations terminées.',
        generate_only_bsd_if_emptied   : () =>
            'Vous ne pouvez générer que des BSD pour des évacuations vidées en exutoire.',
        bsd_generate_cc_id_not_matching: () =>
            "L'identifiant de l'évacuation ne correspond pas à celui de la collecte.",
        missing_bsd_documents                             : () => 'Les documents pour la génération du BSD sont manquants.',
        already_a_zoho                                    : () => 'Ce client est déjà un client Zoho.',
        mandate_already_exist_on_1                        : () => 'Ce client possède déjà un mandat sur Endless 1.',
        mandate_already_exist_on_2                        : () => 'Ce client possède déjà un mandat sur Endless 2.',
        mandate_already_exist                             : () => 'Ce client possède déjà un mandat sur Endless 1 et Endless 2.',
        user_already_linked_to_customer                   : () => 'Cet utilisateur est déjà associé à un client.',
        no_admin_for_customer                             : () => "Ce client ne possède aucun administrateur. Contactez l'équipe technique.",
        user_already_have_customer                        : () => 'Cet utilisateur est déjà associé à un client.',
        revalorisation_characteristics_range_date_overlap : () =>
            'Les dates de revalorisation se chevauchent avec une autre revalorisation.',
        revalorisation_characteristics_cannot_change_landfill_id: () =>
            "Vous ne pouvez pas changer l'identifiant de la décharge.",
        ccs_not_from_same_customer        : () => 'Vous ne pouvez pas exporter des évacuations de clients différents.',
        no_ccs_found                      : () => 'Aucune évacuation trouvée.',
        invitation_already_exists         : () => "Une demande d'invitation est déjà en cours pour cet utilisateur.",
        collect_day_not_allowed_for_zone  : () => 'Vous ne pouvez pas créer une commande pour ce jour dans cette zone.',
        collect_volume_too_low_for_zone   : () => 'Le volume de la commande est insuffisant pour cette zone.',
        collect_slot_not_allowed_for_zone : () =>
            'Vous ne pouvez pas créer une commande pour ce créneau dans cette zone.',
        collect_category_patch_forbidden           : () => 'Vous ne pouvez pas modifier ce type de collecte.',
        product_not_deposit                        : () => "Le produit n'est pas compatible pour créer une benne sur chantier.",
        cc_with_dumpster_on_site_without_retrieval : () =>
            "Seules les collectes d'enlèvement/rotation peuvent être associées à une benne sur chantier.",
        cc_with_retrieval_without_dumpster_on_site: () =>
            "Les collectes d'enlèvement/rotation doivent être associées à une benne sur chantier.",
        incompatible_deposit_retrieval        : () => "L'enlèvement n'est pas compatible avec la benne sur chantier indiquée.",
        split_can_not_have_presta             : () => 'Une évacuation découpée ne peut pas avoir de prestation.',
        no_collects_emptied_for_this_period   : () => 'Aucune collecte vidée dans un centre de tri pour cette période.',
        no_deposit_in_linked_cc               : () => "La collecte associée n'est pas un dépôt.",
        sms_already_sent                      : () => 'Ce SMS a déjà été envoyé.',
        here_could_not_calculate_time_arrival : () => "Impossible de calculer l'heure d'arrivée.",
        collector_not_allowed_to_send_sms     : () => "Vous n'êtes pas autorisé à envoyer des SMS.",
        user_need_to_be_admin_of_customer     : () =>
            'Vous devez être administrateur du compte client pour effectuer cette action.',
        cannot_remove_last_user_of_customer  : () => "Vous ne pouvez pas supprimer le dernier utilisateur d'un client.",
        user_not_linked_to_customer          : () => "Cet utilisateur n'est pas associé à ce client.",
        cannot_remove_yourself_from_customer : () => 'Vous ne pouvez pas vous supprimer de votre propre client.',
        cannot_remove_last_admin_of_customer : () =>
            "Vous ne pouvez pas supprimer le dernier administrateur d'un client.",
        wrong_invitation_status : () => "Le statut de la demande d'accès est incompatible avec l'action demandée.",
        cc_linked_to_itself     : () => 'La benne de dépôt ne peut pas être la collecte elle-même.'
    },
    [ErrorContext.Database]: {
        missingId          : (params: Record<string, any>) => `L'identifiant "${params['id']}" n'a pas été trouvé`,
        missingMultipleIds : (params: Record<string, any>) =>
            `Les identifiants suivants n'ont pas été trouvés : ${params['ids']?.join(', ')}`
    },
    [ErrorContext.DtoValidation]: {
        missingInfo       : () => `Les informations envoyées sont manquantes`,
        missingId         : () => "L'identifiant est manquant",
        missingEmail      : () => `L'email est manquant`,
        invalidEmail      : () => `L'email est invalide`,
        invalid_cc_id     : () => 'Les identifiants fournis sont manquants ou invalides',
        'incorect-format' : (params: Record<string, any>) =>
            `Les informations envoyées n'ont pas le bon format. ${params['message']}`,
        'planning-missing-truck-config': (params: Record<string, any>) =>
            `Le planning ne possède pas de configuration de camion pour ${params['name']}`,
        forbiddenPublicOperationalProduct      : () => 'Vous ne pouvez pas définir un produit public comme opérationnel.',
        webhookGoCardlessNoEvent               : () => "Le webhook GoCardless ne possède pas d'événement.",
        webhookGoCardlessNoMandateId           : () => "Le webhook GoCardless ne possède pas d'identifiant de mandat.",
        webhookGoCardlessEventTypeNotSupported : () => "Le type d'événement GoCardless n'est pas supporté.",
        webhookGoCardlessNoCustomerIdOnMandate : () =>
            "Le webhook GoCardless ne possède pas d'identifiant de client de notre base de donnée.",
        invalidInvitationToken               : () => "Cet identifiant d'invitation est erroné ou expiré",
        confirmation_email_recipient_missing : () => "Aucun adresse n'a été renseignée pour l'e-mail de confirmation.",
        invalidOrderToken                    : () => 'Cet identifiant de commande est erroné ou expiré',
        defaultEmptyRunCannotBePublished     : () =>
            'Un passage à vide par défaut ne peut pas être publié. Veuillez déclarer un autre passage à vide par défaut pour le même type et la même zone et réessayer.',
        datesRequiredForOrderExport : () => "Une plage de date est requise pour l'export des commandes.",
        datesTooLargeForOrderExport : () =>
            "La plage de date doit être inférieure à 45 jours pour l'export des commandes.",
        productWithDumpsterTypeNotAllowed: () =>
            "Un produit qui n'est pas une benne ne peut posséder un type de benne.",
        productWithoutDumpsterTypeNotAllowed : () => 'Un produit qui est une benne doit posséder un type de benne.',
        orderWithIncompatibleDumpsters       : () => 'La commande possède des types de bennes incompatibles.',
        ccFamilyChangeForbidden              : () => 'Vous ne pouvez pas changer la famille de collecte.',
        ccNotCompatibleWithTruckCollector    : (params: { order_number?: string; cc_number?: string }) =>
            `La collecte ${params?.order_number ?? ''} / ${
                params?.cc_number ?? ''
            } n'est pas compatible avec ce couple camion/chauffeur.`
    },
    [ErrorContext.Other]: {
        custom        : (params: Record<string, any>) => params['message'],
        'missing-key' : (params: Record<string, any>) =>
            `La clé "${params['key']}" est manquante et ça ne devrait pas arriver`,
        missing_checkout_session                : () => 'Aucune session de paiement stripe trouvée',
        missing_checkout_session_expiration_url : () =>
            "Aucune lien d'expiration trouvé pour la session de paiement stripe",
        redis_error                    : () => `Une erreur Redis est survenue`,
        hubspot_notify_error           : () => `Une erreur est survenue lors de la notification à Hubspot`,
        slack_webhook_error            : () => `Une erreur est survenue lors de la notification à Slack`,
        missing_empty_run              : () => `Aucun passage à vide trouvé, veuillez contacter un administrateur`,
        err_sms_client_not_initialized : () => "Le serveur SMS n'a pas été initialisé",
        err_sending_sms                : () => "Une erreur est survenue lors de l'envoi du SMS"
    }
} as const;
export type ErrorCodeFR = typeof ErrorCodeFR;

/**
 * ERROR RESPONSABILITY
 */
export const ErrorResponsabilityFR: Record<ErrorResponsability, string> = {
    [ErrorResponsability.Server] : 'Serveur',
    [ErrorResponsability.Client] : 'Client'
};

/**
 * ERROR CONTEXT
 */
export const ErrorContextFR: Record<ErrorContext, string> = {
    [ErrorContext.Auth]          : 'Authentification',
    [ErrorContext.Business]      : 'Métier',
    [ErrorContext.Database]      : 'Base de données',
    [ErrorContext.DtoValidation] : 'Validation des données',
    [ErrorContext.Other]         : 'Autre'
};

/**
 * ERROR DATA TYPE
 */
export const ErrorDataTypeFR: Record<EDataType, string> = {
    [EDataType.LANDFILL]                  : 'Décharge',
    [EDataType.CUSTOMER]                  : 'Client',
    [EDataType.ADMIN]                     : 'Administrateur',
    [EDataType.ITEM]                      : 'Article',
    [EDataType.AUTH0]                     : 'Auth0',
    [EDataType.DISCOUNT]                  : 'Remise',
    [EDataType.DOCUMENT]                  : 'Document',
    [EDataType.CONSTRUCTION_SITE]         : 'Chantier',
    [EDataType.TRUCK]                     : 'Camion',
    [EDataType.INTERNAL_PRODUCT]          : 'Produit interne',
    [EDataType.COLLECTOR]                 : 'Collecteur',
    [EDataType.ORDER]                     : 'Commande',
    [EDataType.COLLECT]                   : 'Collecte',
    [EDataType.DUMPSTER_ON_SITE]          : 'Benne sur chantier',
    [EDataType.DUMPSTER]                  : 'Benne',
    [EDataType.USER]                      : 'Utilisateur',
    [EDataType.EMAIL]                     : 'Email',
    [EDataType.COLLECT_CONFIG]            : 'Configuration de collecte',
    [EDataType.PLANNING]                  : 'Planning',
    [EDataType.INVOICE]                   : 'Facture',
    [EDataType.BSD]                       : 'BSD',
    [EDataType.CONTACT]                   : 'Contact',
    [EDataType.GOCARDLESS]                : 'GoCardless',
    [EDataType.PERMISSION]                : 'Permission',
    [EDataType.PRODUCT]                   : 'Produit',
    [EDataType.ZONE]                      : 'Zone',
    [EDataType.STRIPE]                    : 'Stripe',
    [EDataType.VRP_ALGORITHM]             : 'Algorithme de VRP',
    [EDataType.WAITING_LIST]              : "Liste d'attente",
    [EDataType.ZOHO]                      : 'Zoho',
    [EDataType.GOOGLE_DRIVE]              : 'Google drive',
    [EDataType.CUSTOMER_MANDATE]          : 'Mandat GoCardless',
    [EDataType.RECYCLING_CHARACTERISTICS] : 'Caractéristiques de revalorisation',
    [EDataType.CUSTOMER_APP_VERSION]      : "Version de l'application client",
    [EDataType.AUTHENTICATION]            : 'Authentification',
    [EDataType.PRESTA]                    : 'Prestataire',
    [EDataType.CC_REPLAN_ACTIVITY]        : 'Activité de replanification de collecte',
    [EDataType.CC_PUBLIC_INFORMATIONS]    : 'Informations publiques de collecte',
    [EDataType.SMS]                       : 'SMS',
    [EDataType.HERE]                      : 'HERE'
};
