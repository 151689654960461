import Joi from 'joi';
import moment from 'moment';

import { CustomerRo, EBillingMode, EMembershipType } from '.';
import { AddressCreateSchema, AddressEditSchema } from '../address';
import { ContactDtoSchema } from '../contact';
import { DocumentCreateDtoSchema } from '../document/document.schema';
import { generateRelationsSchema } from '../dto';
import { CREATED_FROM } from '../misc';
import { IbanSchema, NafSchema, SirenSchema, SiretSchema, VatSchema } from '../misc.schema';
import { UserCreateDtoSchema, UserEditDtoSchema } from '../user';
import { EBillingBundle, EBillingShipment, ECustomerCategory, ECustomerOrigin, ICustomerContact } from './customer';

export const CustomerSetupMandateSchema = Joi.object({
    iban: IbanSchema.required()
});

export const CustomerContactDto = ContactDtoSchema.concat(
    Joi.object({
        is_invoice : Joi.boolean(),
        is_primary : Joi.boolean()
    }).custom((value, helper) => {
        const contact = helper.state.ancestors[0] as ICustomerContact[];
        if (contact.every((c) => !c.is_invoice) || contact.every((c) => !c.is_primary)) {
            // invalid if all contact are neither invoice nor primary
            throw new Error('At least one contact must be invoice and one contact must be primary');
        }
        return value;
    })
);

export const CustomerCreateDtoSchema = Joi.object({
    name               : Joi.string().required(),
    internal_name      : Joi.string().allow(null, ''),
    shipping_addresses : Joi.array().items(AddressCreateSchema).max(1),
    invoice_addresses  : Joi.array().items(AddressCreateSchema).length(1).required(),
    membership         : Joi.string()
        .valid(...Object.keys(EMembershipType))
        .allow(null),
    origin: Joi.string()
        .valid(...Object.keys(ECustomerOrigin))
        .allow(null),
    registration: Joi.when('category', {
        is        : ECustomerCategory.INDIVIDUAL,
        then      : Joi.forbidden(),
        otherwise : Joi.date().iso().less('now').allow(null)
    }),
    share_capital: Joi.when('category', {
        is        : ECustomerCategory.INDIVIDUAL,
        then      : Joi.forbidden(),
        otherwise : Joi.number().min(0).allow(null)
    }),
    siren: Joi.when('category', {
        is        : ECustomerCategory.INDIVIDUAL,
        then      : Joi.forbidden(),
        otherwise : SirenSchema.required()
    }),
    siret: Joi.when('category', {
        is        : ECustomerCategory.INDIVIDUAL,
        then      : Joi.forbidden(),
        otherwise : SiretSchema.required()
    }),
    vat: Joi.when('category', {
        is        : ECustomerCategory.INDIVIDUAL,
        then      : Joi.forbidden(),
        otherwise : VatSchema.required()
    }),
    naf: Joi.when('category', {
        is        : ECustomerCategory.INDIVIDUAL,
        then      : Joi.forbidden(),
        otherwise : NafSchema.allow(null)
    }),
    revenue: Joi.when('category', {
        is        : ECustomerCategory.INDIVIDUAL,
        then      : Joi.forbidden(),
        otherwise : Joi.number().allow(null)
    }),
    revenue_year: Joi.when('category', {
        is        : ECustomerCategory.INDIVIDUAL,
        then      : Joi.forbidden(),
        otherwise : Joi.number().min(1900).max(moment.utc().year()).allow(null)
    }),
    headquarters: Joi.when('category', {
        is        : ECustomerCategory.INDIVIDUAL,
        then      : Joi.forbidden(),
        otherwise : AddressCreateSchema.required()
    }),
    billing_mode: Joi.string()
        .valid(...Object.keys(EBillingMode))
        .allow(null),
    billing_shipment: Joi.string()
        .valid(...Object.keys(EBillingShipment))
        .allow(null),
    billing_bundle: Joi.string().when('billing_mode', {
        is   : EBillingMode.END_OF_MONTH,
        then : Joi.string()
            .valid(...Object.keys(EBillingBundle))
            .required(),
        otherwise: Joi.forbidden()
    }),
    outstanding_amount     : Joi.number().min(0).allow(null),
    outstanding_max_amount : Joi.number().min(0).allow(null),
    payment_time           : Joi.number().min(0),
    type                   : Joi.when('category', {
        is        : ECustomerCategory.INDIVIDUAL,
        then      : Joi.forbidden(),
        otherwise : Joi.string().allow(null)
    }),
    is_retailer: Joi.when('category', {
        is        : ECustomerCategory.INDIVIDUAL,
        then      : Joi.forbidden(),
        otherwise : Joi.boolean()
    }).allow(null),
    blocked   : Joi.boolean(),
    contact   : Joi.array().items(CustomerContactDto).allow(null),
    admin_id  : Joi.array().items(Joi.string()).length(1).required(),
    documents : Joi.array().items(DocumentCreateDtoSchema),
    category  : Joi.string()
        .valid(...Object.keys(ECustomerCategory))
        .required(),
    mandatory_order_sheet     : Joi.boolean(),
    has_legacy_deliveries     : Joi.boolean(),
    previous_id_zoho_customer : Joi.string().allow(null, ''),
    comment                   : Joi.string().allow(null, ''),
    created_from              : Joi.string()
        .valid(...Object.values(CREATED_FROM))
        .optional()
});

export const CustomerEditDtoSchema = Joi.object({
    test_name          : Joi.string().allow(null),
    name               : Joi.string(),
    internal_name      : Joi.string(),
    shipping_addresses : Joi.array().items(AddressEditSchema).min(1).max(1),
    invoice_addresses  : Joi.array().items(AddressEditSchema).min(1).max(1),
    membership         : Joi.string()
        .valid(...Object.keys(EMembershipType))
        .allow(null),
    origin: Joi.string()
        .valid(...Object.keys(ECustomerOrigin))
        .allow(null),
    hubspot_id    : Joi.string().allow(null),
    registration  : Joi.date().iso().less('now').allow(null),
    share_capital : Joi.number().min(0).allow(null),
    siren         : SirenSchema.allow(null),
    siret         : SiretSchema.allow(null),
    vat           : VatSchema.allow(null),
    naf           : NafSchema.allow(null),
    revenue       : Joi.number().allow(null),
    revenue_year  : Joi.number().min(1900).max(moment.utc().year()).allow(null),
    headquarters  : AddressEditSchema,
    billing_mode  : Joi.string()
        .valid(...Object.keys(EBillingMode))
        .allow(null),
    billing_shipment: Joi.string()
        .valid(...Object.keys(EBillingShipment))
        .allow(null),
    billing_bundle: Joi.string()
        .valid(...Object.keys(EBillingBundle))
        .allow(null),
    outstanding_amount     : Joi.number().min(0).allow(null),
    outstanding_max_amount : Joi.number().min(0).allow(null),
    payment_time           : Joi.number().min(0),
    type                   : Joi.string().allow(null),
    is_retailer            : Joi.boolean(),
    usersToCreate          : Joi.array().items(UserCreateDtoSchema),
    usersToRemove          : Joi.array().items(Joi.string()),
    usersToEdit            : Joi.array().items(
        Joi.object({
            body : UserEditDtoSchema.required(),
            id   : Joi.string().required()
        })
    ),
    setupMandate         : CustomerSetupMandateSchema,
    defaultPaymentMethod : Joi.string(),
    category             : Joi.string()
        .valid(...Object.keys(ECustomerCategory))
        .allow(null),
    blocked : Joi.boolean(),
    contact : Joi.array().items(
        ContactDtoSchema.concat(
            Joi.object({
                is_invoice             : Joi.boolean(),
                is_primary             : Joi.boolean(),
                id_zoho_contact_person : Joi.string().allow(null, ''),
                id_zoho_contact        : Joi.string().allow(null, '') // it doesn't exist anymore in code, but some existing data still have this field in DB (manual scripting of 26/01/2023)
            })
        )
    ),
    mandatory_order_sheet     : Joi.boolean(),
    zoho_modifications        : Joi.object(),
    documents                 : Joi.array().items(DocumentCreateDtoSchema),
    previous_id_zoho_customer : Joi.string().allow(null, ''),
    comment                   : Joi.string().allow(null, '')
}).concat(generateRelationsSchema<CustomerRo>('admin_id', 'document_id'));

export const CustomerUpdateFromZohoCustomerIdSchema = CustomerEditDtoSchema.concat(
    Joi.object({
        id_zoho_customer: Joi.string().required()
    })
);

export const CustomerCreateRevalorisationExportDtoSchema = Joi.object({
    customer_id           : Joi.string().required(),
    construction_site_ids : Joi.array().items(Joi.string()).required(),
    from_date             : Joi.date().iso().optional(),
    to_date               : Joi.date().iso().optional()
});

export const CustomerCreateMissingGoogleDriveSchema = Joi.object({
    month : Joi.number().min(0).max(11).required(),
    year  : Joi.number().required()
});

export const CustomerMandateFlowCreateDtoSchema = Joi.object({
    customer_id : Joi.string().required(),
    email       : Joi.string().email({ tlds: false }).required(),
    firstname   : Joi.string().allow(null, ''),
    lastname    : Joi.string().allow(null, ''),
    createOnE1  : Joi.boolean().required(),
    createOnE2  : Joi.boolean().required()
});

export const CustomerRemoveUserSchema = Joi.object({
    customer_id : Joi.string().required(),
    user_id     : Joi.string().required()
});
