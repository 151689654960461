import { Currency } from 'dinero.js';
import { SelectButton } from 'primereact/selectbutton';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import {
    CardErrors,
    PRODUCT_FAMILY,
    PhoneValue,
    ZoneRo,
    BillingBranch,
    EProductBillingMode,
    PRODUCT_DUMPSTER_TYPE
} from '@bbng/util/types';
import { ETrashType } from '@bbng/util/types';
import { DocumentCreateDto } from '@bbng/util/types';

import {
    mapDumpsterTypeText,
    mapProductBillingMode,
    mapProductFamilyToString,
    mapTrashTypetext
} from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import { urlApiBuilder } from '../../common/urlBuilder';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import Input from '../../components/Inputs';
import { useRequest } from '../../hooks/StatelessRequest';
import { Tooltip } from 'primereact/tooltip';
import { Divider } from 'primereact/divider';

export type ProductGeneralProps = {
    readOnly?: boolean;
    value?: ProductGeneralState;
    id: string;
    result: (value: ProductGeneralState, errors: null | string[] | CardErrors<ProductGeneralState>, id: string) => void;
    displayError?: boolean;
    edit?: boolean;
};

export type ProductGeneralState = {
    name: string;
    subname: string | null;
    description: string | null;
    public: boolean; //Product that client can buy or see;
    operational: boolean; //Product used for operations (always internal)
    net_amount_cents: number;
    currency: Currency;
    vat_rate_percentage: number; //0 to 100
    family: string;
    billing_branch: BillingBranch;
    trash_type: ETrashType;
    volume_m3: number;
    main_photo: string | null;
    photo_id: string[];
    zone_id: ZoneRo[];
    billing_modes: EProductBillingMode[];
    is_default_empty_run: boolean;
    dumpster_type?: PRODUCT_DUMPSTER_TYPE[];
};

export type ProductDocumentState = {
    main_photo?: {
        add: DocumentCreateDto[];
        remove: string[];
    }; //id from s3
    photo_id?: {
        add: DocumentCreateDto[];
        remove: string[];
    }; //ids from s3
};

export type ProductDocumentErrorState = CardErrors<ProductDocumentState>;

export type ProductGeneralErrorState = CardErrors<ProductGeneralState>;

export const initialState: ProductGeneralState = {
    name                 : '',
    subname              : null,
    description          : null,
    public               : false,
    operational          : false,
    net_amount_cents     : 0,
    currency             : 'EUR',
    vat_rate_percentage  : 0, //0 to 100
    main_photo           : '',
    billing_branch       : BillingBranch.ENDLESS_1,
    photo_id             : [],
    family               : PRODUCT_FAMILY.COLLECT_BIG_BAG,
    trash_type           : ETrashType.METAL,
    volume_m3            : 0,
    zone_id              : [],
    billing_modes        : [],
    is_default_empty_run : false,
    dumpster_type        : []
};

export const initialDocumentState: ProductDocumentState = {
    main_photo: {
        add    : [],
        remove : []
    }, //id from s3
    photo_id: {
        add    : [],
        remove : []
    }
};

export const initialErrorState: ProductGeneralErrorState = generateInitialErrorState(initialState);

export const initialDocumentErrorState: ProductDocumentErrorState = generateInitialErrorState(initialDocumentState);

export const ProductGeneral = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false,
    edit
}: ProductGeneralProps) => {
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : false
    });
    const [zonesAvailable, setZonesAvailable] = React.useState<ZoneRo[]>();

    const [val, setVal] = React.useState<ProductGeneralState>(value);
    const [err, setErr] = React.useState<ProductGeneralErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    React.useEffect(() => {
        bbngRequest<ZoneRo[]>({
            method  : 'GET',
            sync    : true,
            url     : urlApiBuilder.zoneGetAll(),
            payload : {
                queryParams: {
                    no_limit: true
                }
            }
        }).then((res) => {
            if (res.success) {
                setZonesAvailable(res.response?.data?.ro ?? []);
            }
        });
    }, []);

    const handleChange = (
        value: boolean | string | string[] | null | number | undefined | PhoneValue,
        errors: string[] | null,
        childId: string
    ) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    const zoneNames = useMemo(() => {
        if (!val.zone_id) return '';
        return val.zone_id.map((z) => z.name).join(', ');
    }, [val.zone_id]);

    const isDumpster = useMemo(() => val.family.includes('DUMPSTER'), [val.family]);

    React.useEffect(() => {
        if (isDumpster) {
            if (val.dumpster_type?.length === 0) {
                setErr((prev) => ({ ...prev, dumpster_type: ['Veuillez renseigner un type de benne'] }));
            } else {
                setErr((prev) => ({ ...prev, dumpster_type: null }));
            }
        } else {
            setVal((prev) => ({ ...prev, dumpster_type: [] }));
            setErr((prev) => ({ ...prev, dumpster_type: null }));
        }
    }, [isDumpster]);

    React.useEffect(() => {
        if (isDumpster) {
            if ((val.dumpster_type || [])?.length > 0) {
                setErr((prev) => ({ ...prev, dumpster_type: null }));
            } else {
                setErr((prev) => ({ ...prev, dumpster_type: ['Veuillez renseigner un type de benne'] }));
            }
        }
    }, [val.dumpster_type]);

    return (
        <StyledCard title="Informations générales">
            <CardLine>
                <FlexWithGap>
                    <Flex>
                        <Tooltip target=".public-chip" position="bottom" />
                        <span
                            data-pr-tooltip={
                                "Un produit public est visible par les clients. \nIl apparaîtra sur la prise de commande du site e-commerce ou de l'application client. \n\nUn produit interne n'apparaîtra que sur la prise de commande du back-office."
                            }
                            className="public-chip"
                        >
                            <i className="pi pi-info-circle" style={{ marginRight: '5px' }}></i>
                        </span>
                        <Button.Toggle
                            id="public"
                            label={{
                                on  : 'Produit public',
                                off : 'Produit interne'
                            }}
                            result={handleChange}
                            value={val.public}
                            readOnly={readOnly || edit} // field can't be change on edit, only on create (to (un)publish, dedicated action is the product list actions)
                        />
                    </Flex>
                    {!val.public && (
                        <Flex>
                            <Tooltip target=".operational-chip" position="bottom" />
                            <span
                                data-pr-tooltip={
                                    "Un produit opérationnel est toujours caché des clients. \n\nIl est utilisé pour des éléments facturés dans un contexte opérationnel: passage à vide, immobilisation...\n\nPar exemple, lors d'un aléa, seuls les produits opérationnels peuvent être utilisés."
                                }
                                className="operational-chip"
                            >
                                <i className="pi pi-info-circle" style={{ marginRight: '5px' }}></i>
                            </span>
                            <Button.Switch
                                id="operational"
                                result={handleChange}
                                value={val.operational}
                                label="Pour l'opérationnel"
                                readOnly={readOnly}
                            />
                        </Flex>
                    )}
                </FlexWithGap>
                {val.operational && (
                    <>
                        <Flex>
                            <Tooltip target=".public-chip" position="bottom" />
                            <span
                                data-pr-tooltip={
                                    'En cochant cette case, ce produit est considéré comme le passage à vide à utiliser lors des annulations tardives, pour la zone et le type de produit sélectionnés.'
                                }
                                className="public-chip"
                            >
                                <i className="pi pi-info-circle" style={{ marginRight: '5px' }}></i>
                            </span>
                            <Button.Checkbox
                                id="is_default_empty_run"
                                label="Passage à vide par défaut"
                                result={handleChange}
                                value={val.is_default_empty_run}
                                readOnly={readOnly}
                            />
                        </Flex>
                        <Flex>
                            {val.is_default_empty_run && (
                                <span>
                                    Ce produit sera le passage à vide utilisé pour les annulations tardives de{' '}
                                    {mapProductFamilyToString(val.family as PRODUCT_FAMILY)?.toLowerCase()} en zone(s){' '}
                                    {zoneNames}.
                                    <br />
                                    Il remplacera le produit par défaut existant (s'il y en a un).
                                </span>
                            )}
                        </Flex>
                    </>
                )}
                <RowLine>
                    <Input.Text
                        required={true}
                        label="Nom"
                        id="name"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.name}
                        errors={err.name}
                        displayError={displayError}
                    />
                    <Input.Text
                        required={false}
                        label="Subname"
                        id="subname"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.subname ?? ''}
                        errors={err.subname}
                        displayError={displayError}
                    />
                </RowLine>
                <RowLine>
                    <Input.Textarea
                        required={false}
                        width="100%"
                        label="description"
                        id="description"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.description ?? ''}
                        errors={err.description}
                        displayError={displayError}
                    />
                </RowLine>
                <RowLine>
                    <Input.Currency
                        required={true}
                        label="prix"
                        id="net_amount_cents"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.net_amount_cents ?? null}
                        errors={err.net_amount_cents ?? null}
                        displayError={displayError}
                    />
                    <Input.Number
                        required={false}
                        label="Taux de TVA"
                        id="vat_rate_percentage"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.vat_rate_percentage ?? null}
                        errors={err.vat_rate_percentage ?? null}
                        displayError={displayError}
                    />
                </RowLine>
                <RowLine>
                    <Input.Dropdown
                        required={false}
                        id="trash_type"
                        label="Type de déchet"
                        options={Object.values(ETrashType).filter((val) => val !== ETrashType.ULTIMATE_TRASH)}
                        readOnly={readOnly}
                        itemTemplate={(value: ETrashType) => mapTrashTypetext(value as ETrashType)}
                        valueTemplate={(value: ETrashType) => mapTrashTypetext(value as ETrashType)}
                        result={handleChange}
                        value={val.trash_type}
                        errors={err.trash_type}
                        displayError={displayError}
                    />
                    <Input.Number
                        required={false}
                        label="Volume en m3"
                        id="volume_m3"
                        decimal={true}
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.volume_m3 ?? null}
                        errors={err.volume_m3 ?? null}
                        displayError={displayError}
                    />
                </RowLine>
                <RowLine>
                    <Card title="Facturation">
                        <FlexWithGap>
                            <SelectWithDescription>
                                <span>Entité de facturation</span>
                                <Button.Select
                                    required={true}
                                    id="billing_branch"
                                    options={Object.values(BillingBranch)}
                                    readOnly={readOnly}
                                    labelMapper={(value) => value.replaceAll('_', ' ')}
                                    result={handleChange}
                                    value={val.billing_branch}
                                    errors={err.billing_branch}
                                    displayError={displayError}
                                />
                            </SelectWithDescription>
                            <SelectWithDescription>
                                <span>
                                    <Tooltip target=".operational-chip" position="bottom" />
                                    <span
                                        data-pr-tooltip={
                                            'Seuls les clients ayant un mode de facturation éligible pourront commander ce produit. \n\nExemple: les bennes avec paiement au dépôt pour les clients en paiement immédiat.'
                                        }
                                        className="operational-chip"
                                    >
                                        <i className="pi pi-info-circle" style={{ marginRight: '5px' }}></i>
                                    </span>
                                    Modes de facturation éligibles
                                </span>
                                <Button.Group
                                    required={true}
                                    id="billing_modes"
                                    options={Object.values(EProductBillingMode)}
                                    readOnly={readOnly}
                                    labelMapper={(value) => mapProductBillingMode(value as EProductBillingMode)}
                                    result={handleChange}
                                    value={val.billing_modes}
                                    errors={err.billing_modes}
                                    displayError={displayError}
                                    forceSelection={true}
                                />
                            </SelectWithDescription>
                        </FlexWithGap>
                    </Card>
                </RowLine>
                <RowLine>
                    <CardLine>
                        <Card title="Type de produit">
                            <Button.Select
                                required={true}
                                id="family"
                                options={Object.values(PRODUCT_FAMILY)}
                                readOnly={readOnly}
                                labelMapper={(value) => mapProductFamilyToString(value as PRODUCT_FAMILY)}
                                result={handleChange}
                                value={val.family}
                                errors={err.family}
                                displayError={displayError}
                            />
                            {isDumpster && (
                                <>
                                    <Divider />
                                    <Button.Group
                                        id="dumpster_type"
                                        options={Object.values(PRODUCT_DUMPSTER_TYPE)}
                                        readOnly={readOnly}
                                        labelMapper={(value) => mapDumpsterTypeText(value as PRODUCT_DUMPSTER_TYPE)}
                                        result={handleChange}
                                        value={val.dumpster_type}
                                        errors={err.dumpster_type}
                                        displayError={displayError}
                                    />
                                </>
                            )}
                        </Card>
                        <Card title="Zones">
                            <SelectButton
                                multiple
                                optionLabel="name"
                                onChange={(e) => {
                                    handleChange(e.value, null, 'zone_id');
                                }}
                                options={zonesAvailable}
                                value={value.zone_id}
                            />
                        </Card>
                    </CardLine>
                </RowLine>
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;

const RowLine = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const FlexWithGap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 36px;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SelectWithDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;
