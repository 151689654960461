import equals from 'fast-deep-equal';
import { PhoneNumberFormat, PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import * as core from 'express-serve-static-core';
import { Request } from 'express';
import 'moment/locale/fr';
import { ulid } from 'ulid';

import { CustomError } from '@bbng/util/error';
import {
    DeepPartial,
    EActionName,
    EDocumentType,
    EFileMimeTypes,
    IContact,
    IRedisAction,
    MaybeArray,
    PhoneValue,
    RelationKeys
} from '@bbng/util/types';

moment.locale('fr');

/**
 * Utility function for REVERT saga.
 * Remove a given id from an array of ids.
 **/
export const removeIdInArray = (config: IRemoveIdInArrayConfig): string[] => {
    let array: string[] = config.array || [];
    const toRemove: string = config.toRemove || '';

    array = array.filter((id) => id !== toRemove);
    return array;
};

export interface IRemoveIdInArrayConfig {
    array: string[];
    toRemove: string;
}

/**
 * Utility type for easier typing of Express Request object.
 */
export type ExpressReq<Body extends Record<string, any> = any, Query extends Record<string, any> = any> = Request<
    core.ParamsDictionary,
    any,
    Body,
    Query
>;

/**
 * Create a line at the width of the prompt
 **/
export const line = () => '-'.repeat(process.stdout.columns);

export interface IRelationsToAddRemove {
    relationsToAdd: string[];
    relationsToRemove: string[];
}
export const cleanUndefinedKeys = (obj: unknown) => {
    if (typeof obj !== 'object') return {};
    return obj ? JSON.parse(JSON.stringify(obj)) : {};
};

/**
 * Remove all _id fields from a given ro.
 * @param ro
 */
export const removeRelationFieldsFromRo = <T extends Record<string, any>>(ro: T): Omit<T, RelationKeys<T>> => {
    (Object.keys(ro) as Array<keyof T>).forEach((key) => (key as string).endsWith('_id') && delete ro[key]);
    return ro;
};

/**
 * Keep only all _id fields from a given ro.
 * @param ro
 */
export const keepRelationFieldsFromRo = <T extends Record<string, any>>(ro: T): Pick<T, RelationKeys<T>> => {
    (Object.keys(ro) as Array<keyof T>).forEach((key) => !(key as string).endsWith('_id') && delete ro[key]);
    return ro;
};

/**
 * Check if two object are identical.
 */
export const match = (a: any, b: any) => equals(a, b);
// export const match = (a: any, b: any) => {
//     return JSON.stringify(a) === JSON.stringify(b)
// };

/**
 * It returns a string from a date in the format DD/MM/YYYY
 * @param {Date} date - Date
 * @returns A Date formated string
 */
export const getDateStringFromDate = (date: Date): string => moment(date).format('DD/MM/YYYY');

/**
 * It returns a string of the time of the day from a date.
 * @param {Date} date - Date
 * @returns The time of the day.
 */
export const getDayTimeFromDate = (date: Date): string => moment(date).format('HH:mm');

/**
 * It takes a PhoneValue and returns a formatted phone number string into international format.
 * @param {PhoneValue} phone - PhoneValue
 * @returns A formatted phone number string.
 */
export const formatPhoneNumber = (phone: PhoneValue): string => {
    try {
        const instance = PhoneNumberUtil.getInstance();
        const num = instance.parseAndKeepRawInput(phone.phone_number, phone.countryCode);
        return instance.format(num, PhoneNumberFormat.INTERNATIONAL);
    } catch (err) {
        return phone.phone_number;
    }
};

/**
 * It takes a PhoneValue and returns a formatted phone number string into E.164 format.
 * @param {PhoneValue} phone - PhoneValue
 * @returns {string} A formatted phone number string.
 */
export const formatPhoneNumberToE164 = (phone: PhoneValue): string => {
    const instance = PhoneNumberUtil.getInstance();
    const num = instance.parseAndKeepRawInput(phone.phone_number, phone.countryCode);
    return instance.format(num, PhoneNumberFormat.E164);
};

/**
 * It takes a string in E.164 format and returns a formatted phone number string into international format.
 * @param {string} phone - PhoneValue
 * @returns A formatted phone number string.
 */
export const formatE164ToInternational = (phone: string): string => {
    try {
        const instance = PhoneNumberUtil.getInstance();
        const num = instance.parse(phone);
        return instance.format(num, PhoneNumberFormat.INTERNATIONAL);
    } catch (err) {
        return phone;
    }
};

/**
 * It takes a string in E.164 format and returns a PhoneValue object.
 * @param {string} phone - E.164 format.
 * @returns {PhoneValue}
 */
export const formatPhoneE164ToPhoneValue = (phone: string): PhoneValue => {
    try {
        const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
        const instance = PhoneNumberUtil.getInstance();

        const num = instance.parse(phone);
        const countryCode = instance.getRegionCodeForNumber(num) as PhoneValue['countryCode'];

        return {
            name         : (countryCode ? regionNames.of(countryCode) : '') as PhoneValue['name'],
            dialCode     : num.getCountryCode()?.toString() as string,
            phone_number : num.getNationalNumber()?.toString() as string,
            countryCode
        };
    } catch (err) {
        return {
            name         : 'France',
            dialCode     : '+33',
            phone_number : phone,
            countryCode  : 'FR'
        };
    }
};

/**
 * Check if a given string is a valid mobile phone number.
 * @param {string} phone - E.164 format.
 * @returns {boolean}
 */
export const checkIsValidMobileNumber = (phone: string): boolean => {
    const instance = PhoneNumberUtil.getInstance();
    try {
        const num = instance.parse(phone);
        const phoneNumberType = instance.getNumberType(num);
        return phoneNumberType == PhoneNumberType.MOBILE;
    } catch (e) {
        return false;
    }
};

/**
 * This function verifies the validity of a society id.
 * @param {any} number - The number to be verified.
 * @param {any} size - the length of the number
 * @returns A boolean value.
 */
export const verifySocietyId = (number: any, size: any) => {
    if (isNaN(number) || number.length !== size) return false;
    let bal = 0;
    let total = 0;

    for (let i = size - 1; i >= 0; i--) {
        const step = (number.charCodeAt(i) - 48) * (bal + 1);

        total += step > 9 ? step - 9 : step;
        bal = 1 - bal;
    }
    return total % 10 === 0 ? true : false;
};

export function cleanEmptyKeys<T>(obj: T): Partial<T> {
    if (typeof obj !== 'object') return {};
    for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined || (obj[key] as unknown as string) === '') {
            delete obj[key];
        }
    }
    return obj;
}

export function deepCleanEmptyKeys<T>(obj: T): DeepPartial<T> {
    if (typeof obj !== 'object') return {};
    for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined || (obj[key] as unknown as string) === '') {
            delete obj[key];
        } else if (typeof obj[key] === 'object') {
            obj[key] = deepCleanEmptyKeys(obj[key]) as any;
        }
    }
    return obj;
}

/**
 * Wrapper around getDifference to return more readable result.
 */
export const getUnique = (a: string[], b: string[]) => {
    const diff = getDifference(a, b);

    return {
        uniqueToA : diff.toRemove,
        uniqueToB : diff.toAdd
    };
};

/**
 * Get difference between two arrays.
 * Returned object contains:
 * - toAdd : element in newArray only
 * - toRemove : element in oldArray only
 * @param {string[]} oldArray
 * @param {string[]} newArray
 * @returns {IDifferenceResult}
 */
export const getDifference = (oldArray: string[], newArray: string[]): IDifferenceResult => {
    const differenceResult: IDifferenceResult = {
        toAdd    : [],
        toRemove : []
    };
    differenceResult.toAdd = newArray.filter((entry) => !oldArray.includes(entry));
    differenceResult.toRemove = oldArray.filter((entry) => !newArray.includes(entry));
    return differenceResult;
};

export interface IDifferenceResult {
    toAdd: string[];
    toRemove: string[];
}

export const capitalize = (str: string) => str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();

export const processContactName = (contact: IContact): IContact => {
    return {
        ...contact,
        firstname : capitalize(contact.firstname ?? ''),
        lastname  : contact.lastname?.toUpperCase()
    };
};

const keyToRemoveSpace = ['siren', 'siret', 'vat'];

/**
 * Sanitize a request body by trimming all strings and removing spaces from specific keys.
 * @param {T} body
 *
 * @returns {T} sanitized body
 */
export const sanitizeBody = <T extends Record<string, any>>(body: T): T => {
    if (typeof body !== 'object') return body;
    let sanitizedBody;
    if (Array.isArray(body)) {
        sanitizedBody = body.map((value) => {
            let sanitizeValue = value;
            if (typeof value === 'string') {
                sanitizeValue = value.trim();
            }
            if (value && typeof value === 'object' && !(value instanceof Date) && !(value instanceof File)) {
                if (Array.isArray(value)) {
                    sanitizeValue = value.map((item) => sanitizeBody(item));
                } else {
                    sanitizeValue = sanitizeBody(value);
                }
            }
            return sanitizeValue;
        });
    } else {
        sanitizedBody = Object.fromEntries(
            Object.entries(body).map(([key, value]) => {
                let sanitizeValue = value;
                if (typeof value === 'string') {
                    sanitizeValue = value.trim();
                }
                if (keyToRemoveSpace.includes(key)) {
                    sanitizeValue = removeSpacesFromString(value);
                }
                if (value && typeof value === 'object' && !(value instanceof Date) && !(value instanceof File)) {
                    if (Array.isArray(value)) {
                        sanitizeValue = value.map((item) => sanitizeBody(item));
                    } else {
                        sanitizeValue = sanitizeBody(value);
                    }
                }
                return [key, sanitizeValue];
            })
        ) as T;
    }
    return sanitizedBody as T;
};

/**
 * Remove all spaces from a string.
 * @param {string} str
 *
 * @returns {string}
 */
export const removeSpacesFromString = (str: string): string => {
    return str.replace(/\s+/g, '');
};

/**
 * It create an url from a host and an optional port
 * @param host - The hostname of the server
 * @param port - The port of the server
 * @returns
 */
export const urlMaker = (host = 'undefined', port?: number) => {
    const portStr: string = port ? `:${port}` : '';
    const hasScheme = host.includes('://');
    return `${hasScheme ? '' : 'https://'}${host}${portStr}`;
};

/**
 * Clean a url to ensure that there is no slash at the end
 * @param str - The string to be formatted
 * @returns
 */
export const removeLastSlash = (str: string): string => (str.endsWith('/') ? str.slice(0, -1) : str);

/**
 * Convert a JS object to FormData recursively.
 * @param {any} data JS object to convert
 *
 * @returns {FormData}
 */
export const objectToFormData = (data: any, formData?: FormData, parentKey?: string): FormData => {
    if (!formData) formData = new FormData();
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            objectToFormData(data[key as keyof any], formData, parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        if (data !== null && data !== undefined) {
            formData.append(parentKey as string, data);
        }
    }
    return formData;
};

export const DB_ID_SEPARATOR = '-';
export const buildDbId = (prefix: string) => `${prefix}${DB_ID_SEPARATOR}${ulid()}`;

export const extractRo = <RoFromAction, Single extends boolean = true, Flat extends boolean = false>(
    actions: MaybeArray<IRedisAction<RoFromAction>>,
    name?: EActionName,
    { single = true as Single, optional = false, flatten = false } = {}
): Single extends true ? RoFromAction : Flat extends false ? RoFromAction[] : RoFromAction => {
    const listActions = Array.isArray(actions) ? actions : [actions];

    const allListActions = listActions.reduce((acc: IRedisAction[], action: IRedisAction) => {
        if (action.action === EActionName.BULK) {
            (action.response?.ro as []).forEach((el: IRedisAction) => acc.push(el));
        } else {
            acc.push(action);
        }
        return acc;
    }, [] as IRedisAction[]);

    if (allListActions.length === 1 && (!name || allListActions[0].action === name) && single) {
        if (
            optional === false &&
            (allListActions[0].response?.ro === null || allListActions[0].response?.ro === undefined)
        ) {
            throw new Error(`Redis object not found for action ${allListActions[0].action}`);
        }
        return allListActions[0].response?.ro as any;
    }

    const filteredAction: IRedisAction<RoFromAction>[] = allListActions.filter((action) => action.action === name);

    /**
     * Si je n'ai pas d'action
     */
    if (filteredAction.length === 0) {
        if (optional === false) {
            throw new CustomError(StatusCodes.INTERNAL_SERVER_ERROR, `No action found for ${name}`);
        } else {
            return single ? undefined : ([] as any);
        }
    }
    /**
     * Si je n'ai qu'une seule action
     */
    if (filteredAction.length === 1) {
        if (
            optional === false &&
            (filteredAction[0].response === undefined || filteredAction[0].response.ro === undefined)
        ) {
            throw new CustomError(StatusCodes.INTERNAL_SERVER_ERROR, `No response found for ${name}`);
        }
        if (flatten && Array.isArray(filteredAction[0].response?.ro)) {
            return filteredAction[0].response?.ro.flat() as any;
        }
        return single ? filteredAction[0].response?.ro ?? null : ([filteredAction[0].response?.ro] as any);
    }

    /**
     * Si j'en ai plusieurs
     */
    const ros = filteredAction
        .map((action) => {
            if (optional === false && (action.response === undefined || action.response.ro === undefined)) {
                throw new CustomError(StatusCodes.INTERNAL_SERVER_ERROR, `No response found for ${name}`);
            }
            return action.response && action.response.ro ? action.response.ro : undefined;
        })
        .filter(Boolean);
    if (Array.isArray(ros[0]) && flatten) {
        return ros.flat() as any;
    }
    return ros as any;
};

export const getActions = <T>(name: EActionName, actions: IRedisAction[]): IRedisAction<T>[] => {
    if (actions.some((action) => action.action === EActionName.BULK)) {
        return actions.reduce((acc, action) => {
            if (action.action === EActionName.BULK) {
                action.response?.ro.forEach((ro: IRedisAction) => {
                    if (ro.action === name) {
                        acc.push(ro);
                    }
                });
            } else {
                if (action.action === name) {
                    acc.push(action);
                }
            }
            return acc;
        }, [] as IRedisAction<T>[]);
    }
    return actions.filter((action) => action.action === name);
};

export const getRo = <Action extends MaybeArray<IRedisAction>>(
    actions: Action | undefined
):
    | (Action extends Array<IRedisAction<infer R>> ? R[] : Action extends IRedisAction<infer R> ? R : never)
    | undefined => {
    if (!actions) return undefined;
    if (!Array.isArray(actions)) {
        return actions.response?.ro;
    } else {
        const ros = actions.map((action) => action.response?.ro);
        return ros.filter((ro) => ro !== undefined) as any;
    }
};

/**
 * Check if a given object has only null/undefined values.
 * @param {any} obj
 *
 * @returns {boolean}
 */
export function deepCheckEmptyObject(obj: any): boolean {
    return Object.values(obj).every((value) => {
        if (value === undefined || value === null) return true;
        else if (value instanceof Object && value) return deepCheckEmptyObject(value);
        else return false;
    });
}

/**
 * Apply a mask to a string and return the masked string
 * @param str the string to be masked
 * @param pattern a string composed of '#' that represent the pattern to apply on string
 * @returns the masked string
 * @example
 * const masked = mask('123456789', '### ### ###');
 * console.log(masked); // 123 456 789
 *
 * // Pattern too long
 * const masked = mask('123456789', '####-####-####');
 * console.log(masked); // 1234-5678-9###
 *
 * // Pattern too short
 * const masked = mask('123456789', '##_##_#');
 * console.log(masked); // 12_34_5
 *
 * //Pattern with custom char
 * const masked = mask('123456789', 'ooo ooo ooo', 'o');
 * console.log(masked); // 123 456 789
 *
 */
export function maskString(str: string, pattern: string, patternChar = '#'): string {
    let i = 0;

    const reg = new RegExp(patternChar, 'g');
    return pattern.replace(reg, () => {
        const char = str[i] ?? '#';
        i += 1;
        return char;
    });
}

export const batchArray = <T>(array: T[], size: number): Array<T[]> => {
    const batchedArray = [];
    for (let i = 0; i < array.length; i += size) {
        batchedArray.push(array.slice(i, i + size));
    }
    return batchedArray;
};

export const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export const replaceStringifiedBooleans = (obj: any): any => {
    if (typeof obj === 'string') {
        if (obj === 'true') {
            return true;
        }
        if (obj === 'false') {
            return false;
        }
        return obj;
    }
    if (typeof obj !== 'object') return obj;
    if (Array.isArray(obj)) {
        return obj.map((item) => replaceStringifiedBooleans(item));
    }
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (typeof value === 'string') {
            if (value === 'true') {
                acc[key] = true;
            } else if (value === 'false') {
                acc[key] = false;
            } else {
                acc[key] = value;
            }
        } else if (typeof value === 'object') {
            acc[key] = replaceStringifiedBooleans(value);
        } else {
            acc[key] = value;
        }
        return acc;
    }, {} as any);
};

export const replaceNullWithPrismaDbNull = (obj: any, dbNull: any): any => {
    if (typeof obj !== 'object') return obj;
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (value === null) {
            acc[key] = dbNull;
        } else {
            acc[key] = value;
        }
        return acc;
    }, {} as any);
};

export const customFileId = (type: EDocumentType, mimetype: string, customKey: string): string => {
    //extract extension from mimetype
    const getExtension = (mimetype: string) => {
        switch (mimetype) {
            case EFileMimeTypes.PDF:
                return '.pdf';
            case EFileMimeTypes.JPG:
                return '.jpeg';
            case EFileMimeTypes.PNG:
                return '.png';
            default:
                return '.txt';
        }
    };

    return `${type}|${ulid()}|${customKey}${getExtension(mimetype)}`;
};

export const mapify = <T extends Record<string, any> = any, Key extends keyof T = any>(
    array: T[],
    key: Key
): Record<T[Key], T> => {
    const map = array.reduce((acc, item) => {
        acc[item[key]] = item;
        return acc;
    }, <Record<T[Key], T>>{});

    return map;
};
