import styled from 'styled-components';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useEffect, useMemo, useState } from 'react';
import { OrderFormState } from './helpers';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toast } from '../../components/Toast';
import Input from '../../components/Inputs';
import { useRequest } from '../../hooks/StatelessRequest';
import { urlApiBuilder } from '../../common/urlBuilder';
import { CustomerOrderConfirmationEmailRo } from '@bbng/util/types';

export type ConfirmationEmailModalProps = {
    formValues: OrderFormState;
    visible: boolean;
    onCancel?: () => void;
    onConfirm?: (email: string) => void;
};

type AvailableEmail = { email: string; role: string };

export const ConfirmationEmailModal: React.FC<ConfirmationEmailModalProps> = ({
    formValues,
    visible = false,
    onCancel = () => void 0,
    onConfirm = () => void 0
}) => {
    const [selectedEmail, setSelectedEmail] = useState<AvailableEmail | undefined>(undefined);
    const [emailErrors, setEmailErrors] = useState<string[] | null>(null);
    const [orderConfirmationEmails, setOrderConfirmationEmails] = useState<CustomerOrderConfirmationEmailRo[]>([]);

    const bbngRequest = useRequest();

    const fetchOrderConfirmationEmail = async () => {
        const res = await bbngRequest<CustomerOrderConfirmationEmailRo[]>({
            url    : urlApiBuilder.customerGetOrderConfirmationEmails(formValues?.customer?.customer?.id || ''),
            method : 'GET'
        });

        if (res.success && res.response?.data.ro) {
            setOrderConfirmationEmails(res.response.data.ro);
        }
    };

    /**
     * Fetch order confirmation emails when customer is updated
     */
    useEffect(() => {
        fetchOrderConfirmationEmail();
    }, [formValues.customer?.customer?.id]);

    /**
     * Reset selected email and email errors when modal is closed
     */
    useEffect(() => {
        if (!visible) {
            setSelectedEmail(undefined);
            setEmailErrors(null);
        }
    }, [visible]);

    const availableEmails: AvailableEmail[] = useMemo(() => {
        let selectedAlreadySet = false;
        const customerContacts: AvailableEmail[] =
            formValues.customer?.customer?.contact
                .filter((contact) => contact.email && contact.email?.trim() !== '')
                .map((contact) => {
                    const getRole = () => {
                        if (contact.is_primary) return 'Contact client principal';
                        if (contact.is_invoice) return 'Contact client facturation';
                        return 'Contact client';
                    };
                    if (contact.is_invoice && !selectedAlreadySet) {
                        selectedAlreadySet = true;
                        setSelectedEmail({ email: contact.email as string, role: getRole() });
                    }
                    return {
                        email : contact.email as string,
                        role  : getRole()
                    };
                }) || [];

        const logContacts: AvailableEmail[] =
            formValues.contacts?.logistic
                .filter((contact) => contact.email && contact.email?.trim() !== '')
                .map((contact) => {
                    return {
                        email : contact.email,
                        role  : 'Contact logistique'
                    };
                }) || [];

        const consSiteContacts: AvailableEmail[] =
            formValues.contacts?.construction_site
                .filter((contact) => contact.email && contact.email?.trim() !== '')
                .map((contact) => {
                    return {
                        email : contact.email,
                        role  : 'Contact chantier'
                    };
                }) || [];

        const contacts = [...customerContacts, ...logContacts, ...consSiteContacts];

        orderConfirmationEmails.forEach((confirmationEmail) => {
            if (contacts.find((contact) => contact.email === confirmationEmail.email)) return;
            contacts.push({
                email : confirmationEmail.email,
                role  : 'Contact déjà utilisé pour une commande'
            });
        });

        return contacts;
    }, [formValues, orderConfirmationEmails]);

    return (
        <StyledModal visible={visible} onHide={onCancel} header="Ajouter l'e-mail de confirmation">
            <Description>
                <i className="pi pi-info-circle" />
                <p>L'adresse e-mail renseignée sera utilisée pour envoyer l'e-mail de confirmation de commande.</p>
            </Description>
            <DataTable
                dataKey="email"
                value={availableEmails}
                selectionMode="single"
                selection={selectedEmail}
                onSelectionChange={(e) => setSelectedEmail(e.value)}
            >
                <Column field="email" header="E-mail" />
                <Column field="role" header="Rôle" />
            </DataTable>
            <EmailContainer>
                <Input.Email
                    id="email"
                    label="E-mail de confirmation"
                    required={true}
                    result={(value, errors) => {
                        setSelectedEmail({ email: value, role: 'E-mail renseigné' });
                        setEmailErrors(errors);
                    }}
                    value={selectedEmail?.email ?? ''}
                    errors={emailErrors}
                />
            </EmailContainer>
            <Footer>
                <Button className="p-button-outlined" label="Annuler" onClick={onCancel} />
                <Button
                    disabled={!selectedEmail || !!(emailErrors && emailErrors?.length > 0)}
                    label="Valider"
                    onClick={() => {
                        if (selectedEmail?.email) {
                            onConfirm(selectedEmail?.email);
                        } else {
                            toast({
                                life     : 10_000,
                                severity : 'error',
                                summary  : 'E-mail manquant',
                                detail   : "Aucun e-mail n'a été selectionné."
                            });
                        }
                    }}
                />
            </Footer>
        </StyledModal>
    );
};

const StyledModal = styled(Dialog)`
    min-width: 500px;
`;

const Description = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 16px;
`;

const EmailContainer = styled.div`
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Footer = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
`;
