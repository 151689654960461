import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Accordion, AccordionTab } from 'primereact/accordion';

import { PageLoader } from '../../components/PageLoader';
import { Card } from '../../components/Card';
import { ETrashType, RecyclingCharacteristicsRo } from '@bbng/util/types';
import { Button } from 'primereact/button';
import { useRequest } from '../../hooks/StatelessRequest';
import { urlApiBuilder } from '../../common/urlBuilder';
import { RevalorisationModal } from '../../components/LandfillRevalorisation';

export type LandfillCenterRevalorisationProps = {
    landfillId?: string;
};

const FullLineInput = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const LandfillRevalorisation: React.FC<LandfillCenterRevalorisationProps> = ({
    landfillId = ''
}: LandfillCenterRevalorisationProps) => {
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });
    const [isRevalorisationAccordionOpen, setIsRevalorisationAccordionOpen] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [revalorisationId, setRevalorisationId] = useState<string>('');
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [revalorisationList, setRevalorisationList] = useState<Array<RecyclingCharacteristicsRo>>([]);

    const fetchRevalorisationData = async () => {
        if (!landfillId) {
            return;
        }

        const response = await bbngRequest<RecyclingCharacteristicsRo[]>({
            url    : urlApiBuilder.landfillRevalorisationGetByLandfillId(landfillId),
            method : 'GET'
        });
        if (response.response?.data.ro && response.response?.data.ro !== undefined) {
            setRevalorisationList(response.response?.data.ro);
        }
    };

    if (landfillId === '') {
        return <></>;
    }

    return (
        <Accordion
            activeIndex={isRevalorisationAccordionOpen ? 0 : -1}
            onTabClose={() => setIsRevalorisationAccordionOpen(false)}
            onTabOpen={() => {
                setIsRevalorisationAccordionOpen(true);
                fetchRevalorisationData();
            }}
        >
            <AccordionTab header="Caracteristiques de Revalorisation">
                <Button
                    label="Ajouter"
                    type="button"
                    onClick={() => {
                        setRevalorisationId('');
                        setOpenModal(true);
                    }}
                />
                {openModal && (
                    <RevalorisationModal
                        id={revalorisationId}
                        landfill_id={landfillId}
                        readOnly={readOnly}
                        setOpenModal={(value: boolean) => {
                            setOpenModal(value);
                            fetchRevalorisationData();
                        }}
                    />
                )}
                {revalorisationList.length > 0 &&
                    revalorisationList.map((revalorisationItem: RecyclingCharacteristicsRo) => {
                        return (
                            <FullLineInput key={revalorisationItem.id}>
                                <h3>
                                    Donnée valide à partir du: {new Date(revalorisationItem.from_date).toDateString()}{' '}
                                    {revalorisationItem.to_date
                                        ? `jusqu'au : ${new Date(String(revalorisationItem.to_date)).toDateString()}`
                                        : ''}
                                </h3>
                                <div>
                                    <Button
                                        label="Voir les détails"
                                        type="button"
                                        onClick={() => {
                                            setRevalorisationId(revalorisationItem.id);
                                            setReadOnly(true);
                                            setOpenModal(true);
                                        }}
                                    />
                                    <Button
                                        label="Modifier"
                                        type="button"
                                        onClick={() => {
                                            setRevalorisationId(revalorisationItem.id);
                                            setReadOnly(false);
                                            setOpenModal(true);
                                        }}
                                    />
                                </div>
                            </FullLineInput>
                        );
                    })}
            </AccordionTab>
        </Accordion>
    );
};
