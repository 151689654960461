import { CCServiceSplitDto, ProductInCCOrCO } from '@bbng/util/types';

import {
    CollectConfigSplitErrorState,
    CollectConfigSplitState,
    initialErrorState as splitInitialErrorState,
    initialState as splitInitialState
} from '../collect-config/Split';

export type CollectConfigSplitModulesStates = CollectConfigSplitState;

export type CollectConfigSplitModulesErrorStates = CollectConfigSplitErrorState;

export type CollectConfigSplitFormState = {
    split: CollectConfigSplitState;
};

export type CollectConfigSplitFormErrorState = {
    split: CollectConfigSplitErrorState;
};

export const initialState = (products: ProductInCCOrCO[]): CollectConfigSplitFormState => ({
    split: splitInitialState(products)
});

export const initialErrorState = (products: ProductInCCOrCO[]): CollectConfigSplitFormErrorState => ({
    split: splitInitialErrorState(products)
});

export const mapStateToApiData = (state: CollectConfigSplitFormState): CCServiceSplitDto => {
    return {
        lines_by_collect: Object.values(state.split).map((line) =>
            Object.entries(line)
                .filter(([id, quantity]) => quantity > 0)
                .map(([id, quantity]) => ({
                    id,
                    quantity
                }))
        )
    };
};

export const checkSplitIsConsistent = (state: CollectConfigSplitFormState, products: ProductInCCOrCO[]): boolean => {
    let isConsistent = true;
    products.forEach((product) => {
        const subCollectsQuantity = Object.values(state.split).reduce((acc, line) => {
            return acc + line[product.id];
        }, 0);
        if (subCollectsQuantity !== product.quantity) {
            isConsistent = false;
        }
    });
    return isConsistent;
};
