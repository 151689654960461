import i18n from 'i18n-js';

import en from './languages/english.json';
import fr from './languages/french.json';

i18n.translations = {
    fr,
    en
};

export const i18n_bbng = i18n;
