import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { ISODate } from '@bbng/util/types';

import Calendar from '../../components/Calendar';
import { InputListBase } from './base';

export type CalendarRange = Array<ISODate>;

export type InputListCalendarRangeProps = {
    title?: string;
    value?: Array<CalendarRange>;
    onChange?: (value: Array<CalendarRange>) => void;
    readOnly?: boolean;
};

const formatDate = (date: ISODate) => moment(date).format('DD/MM/YYYY à HH:mm');

export function InputListCalendarRange({ title = 'CalendarRange', ...props }: InputListCalendarRangeProps) {
    const [errors, setErrors] = React.useState<Record<string, string[]>>({});
    const isError =
        Object.keys(errors)
            .map((e) => errors[e] ?? [])
            .flat().length > 0;

    return (
        <InputListBase<CalendarRange>
            title={title}
            columns={[
                {
                    header : 'A partir de',
                    field  : '0',
                    body   : (value: CalendarRange) => (value && value[0] ? formatDate(value[0]) : 'Date invalide')
                },
                {
                    header : "Jusqu'à",
                    field  : '1',
                    body   : (value: CalendarRange) => (value && value[1] ? formatDate(value[1]) : 'Date invalide')
                }
            ]}
            disableModalButton={isError}
            setErrors={setErrors}
            modalTemplate={CalendarRangeModal(errors, setErrors)}
            {...props}
        />
    );
}

const CalendarRangeModal =
    (errors: Record<string, string[]>, setError: React.Dispatch<React.SetStateAction<Record<string, string[]>>>) =>
    (state: CalendarRange | undefined, setter: React.Dispatch<React.SetStateAction<CalendarRange | undefined>>) => {
        return (
            <ModalContainer>
                <Calendar.Range
                    id="calendar"
                    readOnly={false}
                    required
                    result={(value, err, id) => {
                        setter((prev) => {
                            const safeValue: Array<ISODate> = Array.isArray(value)
                                ? value
                                : value === undefined
                                ? []
                                : [value];
                            return safeValue;
                        });
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    value={state}
                />
            </ModalContainer>
        );
    };

const ModalContainer = styled.div`
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;
