import { CCRo } from '../collect-config';
import { LandfillRo } from '../landfill';
import { ISODate } from '../misc';
import { EPlanningRegion, EPlanningType, PlanningCalculateDto, PlanningUpdateActionDto } from '../planning';
import { TruckRo } from '../truck';

export type CalculateDistanceMatrixActionDto = {
    day: ISODate;
    region: EPlanningRegion;
    type: EPlanningType;
    data: DataForDistanceMatrix[];
};

export type DataForDistanceMatrix = {
    lat: number;
    lng: number;
};

export type DataOnRedisForDistanceMatrix = {
    from: string;
    to: string;
    distance: number;
    time: number;
};

export type CalculateDistanceMatrixRo = Record<number, CalculateDistanceMatrixContent>;

export type CalculateDistanceMatrixContent = {
    depart: {
        position_grid_X: number; //longitude
        position_grid_Y: number; //latitude
    };
    arrivee: {
        position_grid_X: number; //longitude
        position_grid_Y: number; //latitude
    };
    distance: number; //meters
    duree: string; //HH:mm
};

export type HereCalculateTimeArrival = {
    origin_latitude: number;
    origin_longitude: number;
    destination_latitude: number;
    destination_longitude: number;
};

export type HereCalculatePlanningDto = {
    truck_id: TruckRo[];
    collect_config_id: CCRo[];
    landfill_id: LandfillRo[];
    distanceMatrix: CalculateDistanceMatrixRo;
    planningDto: PlanningCalculateDto;
    region: EPlanningRegion;
    day: string;
};

export type HereCalculatePlanningRo = PlanningUpdateActionDto & { id: string };

/**
 * HERE.COM TYPES
 */
export interface HereDto {
    origins: Destination[];
    destinations: Destination[];
    departureTime: Date;
    transportMode: 'car' | 'truck' | 'pedestrian' | 'bicycle' | 'taxi' | 'scooter' | 'bus' | 'privateBus';
    profile?: 'carFast' | 'carShort' | 'truckFast' | 'pedestrian' | 'bycicle'; //Only if region is world
    routingMode: 'fast' | 'short';
    regionDefinition: RegionDefinition;
    vehicle?: {
        shippedHazardousGoods?: (
            | 'explosive'
            | 'gas'
            | 'flammable'
            | 'combustible'
            | 'organic'
            | 'poison'
            | 'radioactive'
            | 'corrosive'
            | 'poisonousInhalation'
            | 'harmfulToWater'
            | 'other'
        )[];
        grossWeight?: number; // in kg
        weightPerAxle?: number; // in kg
        height?: number; // in cm
        width?: number; // in cm
        length?: number; // in cm
        tunnelCategory?: 'B' | 'C' | 'D' | 'E';
        axleCount?: number;
        trailerCount?: number;
        category?: 'lightTruck';
        weightPerAxleGroup?: {
            single?: number;
            tandem?: number;
            triple?: number;
        };
        speedCap?: number; // in m/s
        type?: 'straightTruck' | 'tractor';
    };
    matrixAttributes: ('travelTimes' | 'distances')[];
}

export interface HereRo {
    matrixId: string;
    matrix: Matrix;
    error?: {
        title: string;
        status: number;
        code: string;
        cause: string;
        action: string;
        correlationId: string;
    };
    regionDefinition: RegionDefinition;
}

export interface Matrix {
    numOrigins: number;
    numDestinations: number;
    travelTimes: number[]; // seconds
    distances: number[]; // meters
    errorCodes: number[];
}

export interface Destination {
    lat: number;
    lng: number;
}

export interface RegionDefinition {
    type: 'circle' | 'boundingBox' | 'polygon' | 'autoCircle' | 'world';
    center?: Destination; //circle
    radius?: number; //circle
    north?: number; //boundingBox
    south?: number; //boundingBox
    east?: number; //boundingBox
    west?: number; //boundingBox
    outer?: Destination[]; //polygon
    margin?: number; //autoCircle
    maxRadius?: number; //autoCircle
}

export interface HereTourResponse {
    statistic: Statistic;
    tours: Tour[];
    unassigned?: Unassigned[];
}

export interface Statistic {
    cost: number;
    distance: number;
    duration: number;
    times: Times;
}

export interface Times {
    driving: number;
    serving: number;
    waiting: number;
    break: number;
}

export interface Tour {
    vehicleId: string;
    typeId: string;
    stops: Stop[];
    statistic: Statistic;
    shiftIndex: number;
}

export interface Stop {
    location: Location;
    time: StopTime;
    load: number[];
    activities: Activity[];
}

export interface Activity {
    jobId: string;
    type: Type;
    location?: Location;
    time?: ActivityTime;
}

export interface Location {
    lat: number;
    lng: number;
}

export interface ActivityTime {
    start: string;
    end: string;
}

export enum Type {
    Arrival = 'arrival',
    Departure = 'departure',
    Pickup = 'pickup'
}

export interface StopTime {
    arrival: string;
    departure: string;
}

export interface Unassigned {
    jobId: string;
    reasons: Reason[];
}

export interface Reason {
    code: string;
    description: string;
}

export type HereTourDto = {
    fleet: HereTourFleet;
    plan: HereTourPlan;
    configuration?: {
        termination?: {
            maxTime: number;
            stagnationTime: number;
        };
    };
};

export type HereTourPlan = {
    jobs: Array<{
        id: string;
        priority: 1 | 2; // 1 is high priority, 2 is normal priority
        /**
         * MAybe where to put the collects
         */
        tasks: {
            pickups: Array<{
                places: Array<{
                    times: [[string, string]]; // [[from, to]] (not sure)
                    location: {
                        lat: number;
                        lng: number;
                    };
                    duration: number; // in seconds
                }>;
                demand: [number];
            }>;
        };
        skills: Array<string>;
    }>;
    relations?: Array<{
        type: 'sequence';
        jobs: Array<string>;
        vehicleId: string;
        shiftIndex?: number;
    }>;
};

export type HereTourFleet = {
    types: Array<{
        id: string;
        profile: string;
        costs: {
            fixed?: number;
            distance?: number;
            time?: number;
        };
        capacity: [number];
        skills: string[];
        shifts: Array<{
            start: {
                time: string;
                location: {
                    lat: number;
                    lng: number;
                };
            };
            end: {
                time: string;
                location: {
                    lat: number;
                    lng: number;
                };
            };
        }>;
        amount: number;
    }>;
    profiles: Array<{
        name: string;
        mode: 'fast' | 'short';
        type: 'car';
    }>;
};

export interface WaypointSequenceResponse {
    errors: string[];
    processingTimeDesc: string;
    requestId: string;
    responseCode: string;
    results: Result[];
    warnings: Warnings;
}

export interface Result {
    description: string;
    distance: string;
    interconnections: Interconnection[];
    time: string;
    timeBreakdown: TimeBreakdown;
    waypoints: Waypoint[];
}

export interface Interconnection {
    distance: number;
    fromWaypoint: string;
    rest: number;
    time: number;
    toWaypoint: string;
    waiting: number;
}

export interface TimeBreakdown {
    driving: number;
    rest: number;
    service: number;
    waiting: number;
}

export interface Waypoint {
    estimatedArrival: Date;
    estimatedDeparture: Date;
    fulfilledConstraints: string[];
    id: string;
    lat: number;
    lng: number;
    sequence: number;
}

export interface Warnings {
    outOfSequenceWaypoints: OutOfSequenceWaypoint[];
}

export interface OutOfSequenceWaypoint {
    failedConstraints: FailedConstraint[];
    id: string;
    lat: number;
    lng: number;
}

export interface FailedConstraint {
    constraint: string;
    reason: number;
}
