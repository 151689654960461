export interface PappersCompanyRo {
    siren: string;
    siren_formate: string;
    diffusable: boolean;
    nom_entreprise: string;
    personne_morale: boolean;
    denomination: string;
    nom?: string;
    prenom?: string;
    sexe?: EPappersSexe;
    siege: PappersSiege;
    villes: string[];
    code_naf: string;
    libelle_code_naf: string;
    domaine_activite: string;
    conventions_collectives: PappersConventionsCollective[];
    date_creation: string; // AAAA-MM-JJ
    date_creation_formate: string; // JJ-MM-AAAA
    entreprise_employeuse: number;
    entreprise_cessee: number;
    date_cessation?: string; // AAAA-MM-JJ
    categorie_juridique: string;
    forme_juridique: string;
    tranche_effectif: string;
    effectif: string;
    effectif_min: number;
    effectif_max: number;
    annee_effectif?: number;
    capital: number;
    statut_rcs: string;
    chiffre_affaires?: number;
    resultat?: number;
    effectifs_finances?: number;
    annee_finances?: number;
    mention: string;
}

export interface PappersResponseWrapper {
    resultats_nom_entreprise: PappersCompanyRo[];
    resultats_siren: PappersCompanyRo[];
    resultats_siret: PappersCompanyRo[];
}

export interface PappersSiege {
    siret: string;
    siret_formate: string;
    nic: string;
    numero_voie?: number;
    indice_repetition?: string;
    type_voie: string;
    libelle_voie: string;
    complement_adresse: string;
    adresse_ligne_1: string;
    adresse_ligne_2: string;
    code_postal: string;
    ville: string;
    latitude?: number;
    longitude?: number;
}

export interface PappersConventionsCollective {
    nom: string;
    idcc: string;
    confirmee: boolean;
}

export enum EPappersSexe {
    F = 'F',
    M = 'M'
}
