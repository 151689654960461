import React from 'react';
import styled from 'styled-components';

import { cleanEmptyKeys } from '@bbng/util/misc';
import { AddressRo, CardErrors } from '@bbng/util/types';

import { AddressMapButton } from '../../../components/AddressDisplayButton';
import { Card } from '../../../components/Card';
import { GoogleAddressAutocomplete } from '../../../components/GoogleAddressAutocomplete';
import Input from '../../../components/Inputs';
import { PlaceResult } from '../../../context/GoogleMaps';
import { useFormModule } from '../../../hooks/FormModule';

export type AddressProps = {
    readOnly?: boolean;
    value?: AddressState;
    id: string;
    result: (value: AddressState, errors: null | string[] | CardErrors<AddressState>, id: string) => void;
    displayError?: boolean;
    title?: string;
};

export type AddressState = {
    address_street_number: string;
    address_street_name: string;
    address_city: string;
    address_zip_code: string;
    address_country: string;
    address_complement: string;
    address_google?: PlaceResult;
    address_lng: number | null;
    address_lat: number | null;
};
export type AddressErrorState = CardErrors<AddressState>;

function generateInitialErrorState(state: AddressState): AddressErrorState {
    const stateEntries = Object.entries(state);
    const errorEntries = stateEntries.map(([key, value]) => [key, null]);
    const errorState: AddressErrorState = Object.fromEntries(errorEntries);

    return errorState;
}

export const initialState: AddressState = {
    address_street_number : '',
    address_street_name   : '',
    address_city          : '',
    address_zip_code      : '',
    address_country       : '',
    address_complement    : '',
    address_google        : undefined,
    address_lng           : null,
    address_lat           : null
};

export const initialErrorState: AddressErrorState = generateInitialErrorState(initialState);

export const Address = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false,
    title = 'Adresse'
}: AddressProps) => {
    const { val, setVal, err, handleChange } = useFormModule<AddressState>({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    return (
        <StyledCard title={title}>
            {readOnly === false && (
                <CardLine>
                    <GoogleAddressAutocomplete
                        disabled={readOnly}
                        autocompleteProps={{ inputStyle: { width: '100%' } }}
                        onAddressSelect={(place: PlaceResult) => {
                            const entries = googleAddressComponents.map((input) => {
                                const componentName = place?.address_components?.find((f: any) =>
                                    f.types?.includes(input.component)
                                )?.long_name;

                                const defaultValue = value ? value[input.key] : '';
                                return [input.key, (componentName ?? defaultValue).toLowerCase()];
                            });

                            const newState = Object.fromEntries(entries);

                            setVal((prev) => ({
                                ...initialState,
                                ...newState,
                                address_google: Object.fromEntries(
                                    Object.entries(place).map(([key, value]) => [
                                        key,
                                        typeof value === 'string' ? value.toLowerCase() : value
                                    ])
                                ),
                                address_lat : place.geometry?.location?.lat(),
                                address_lng : place.geometry?.location?.lng()
                            }));
                        }}
                    />
                </CardLine>
            )}
            <CardLine>
                <Input.Text
                    id="address_street_number"
                    label="Numéro"
                    displayError={displayError}
                    readOnly={readOnly}
                    required={false}
                    result={handleChange}
                    errors={err.address_street_number}
                    value={val.address_street_number}
                />
                <Input.Text
                    id="address_street_name"
                    label="Nom de rue"
                    displayError={displayError}
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    errors={err.address_street_name}
                    value={val.address_street_name}
                />
                <Input.Text
                    id="address_city"
                    label="Ville"
                    displayError={displayError}
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    errors={err.address_city}
                    value={val.address_city}
                />
                <Input.Text
                    id="address_zip_code"
                    label="Code Postal"
                    displayError={displayError}
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    errors={err.address_zip_code}
                    value={val.address_zip_code}
                />
                <Input.Text
                    id="address_country"
                    label="Pays"
                    displayError={displayError}
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    errors={err.address_country}
                    value={val.address_country}
                />
            </CardLine>
            <CardLine>
                <Input.Text
                    id="address_complement"
                    label="Complement"
                    displayError={displayError}
                    readOnly={readOnly}
                    required={false}
                    result={handleChange}
                    errors={err.address_complement}
                    value={val.address_complement}
                />
                <Input.Number
                    id="address_lng"
                    label="Longitude"
                    displayError={displayError}
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    errors={err.address_lng}
                    value={val.address_lng}
                />
                <StyledAddressMapButton
                    draggable={!readOnly}
                    lat={val.address_lat ?? 0}
                    lng={val.address_lng ?? 0}
                    disable={!val || !val.address_lat || !val.address_lng}
                    onDragChange={(lat: number, lng: number) => {
                        setVal((prev) => ({
                            ...prev,
                            address_lat : lat,
                            address_lng : lng
                        }));
                    }}
                    readOnly={readOnly}
                />
                <Input.Number
                    id="address_lat"
                    label="Latitude"
                    displayError={displayError}
                    readOnly={readOnly}
                    required={true}
                    result={handleChange}
                    errors={err.address_lat}
                    value={val.address_lat}
                />
            </CardLine>
        </StyledCard>
    );
};

const StyledAddressMapButton = styled(AddressMapButton)`
    max-width: 50px;
`;

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 2;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;

export const googleAddressComponents = [
    { key: 'address_street_number', component: 'street_number' },
    { key: 'address_street_name', component: 'route' },
    { key: 'address_city', component: 'locality' },
    { key: 'address_zip_code', component: 'postal_code' },
    { key: 'address_country', component: 'country' }
] as const;

export function mapApiAddressToStateAddress(apiAddress: AddressRo): AddressState {
    return {
        address_google        : undefined,
        address_street_number : apiAddress.components['street_number'],
        address_street_name   : apiAddress.components['route'],
        address_city          : apiAddress.components['locality'],
        address_zip_code      : apiAddress.components['postal_code'],
        address_country       : apiAddress.components['country'],
        address_complement    : apiAddress.components['complement'],
        address_lat           : Number(apiAddress.coordinates.latitude),
        address_lng           : Number(apiAddress.coordinates.longitude)
    };
}

export function mapStateAddressToApiAddress(stateAddress: AddressState): AddressRo {
    return {
        coordinates: {
            latitude  : stateAddress.address_lat!,
            longitude : stateAddress.address_lng!
        },
        name           : (stateAddress.address_street_name || stateAddress.address_google?.name) ?? '',
        formatted_name :
            (stateAddress.address_google?.formatted_address ||
                stateAddress.address_street_name ||
                stateAddress.address_google?.name) ??
            '',
        components: cleanEmptyKeys({
            street_number : stateAddress.address_street_number,
            route         : stateAddress.address_street_name,
            locality      : stateAddress.address_city,
            postal_code   : stateAddress.address_zip_code,
            country       : stateAddress.address_country,
            complement    : stateAddress.address_complement
        })
    };
}
