import styled from 'styled-components';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export type ConfirmQuantityModalProps = {
    visible?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    minWeight?: number;
    zoneName?: string;
};

export const ConfirmQuantityModal: React.FC<ConfirmQuantityModalProps> = ({
    visible = false,
    onConfirm = () => void 0,
    onCancel = () => void 0,
    minWeight,
    zoneName
}) => {
    return (
        <StyledModal visible={visible} onHide={onCancel} header="Attention !">
            <div>
                <h3>
                    <span role="img" aria-label="danger">
                        ⚠️
                    </span>
                    Votre commande contient moins de {minWeight}m3 alors que elle est en ZONE: {zoneName} !!!
                    <span role="img" aria-label="danger">
                        ⚠️
                    </span>
                </h3>
            </div>
            <br />
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Voulez-vous confirmer la commande ?</p>
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button label="Confirmer" style={{ backgroundColor: 'red' }} onClick={onConfirm} />
                <Button label="Annuler" onClick={onCancel} />
            </div>
        </StyledModal>
    );
};

const StyledModal = styled(Dialog)`
    min-width: 500px;
`;
