import moment from 'moment';
import { Button as PRButton } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { SelectButton } from 'primereact/selectbutton';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { AssignMethod, GeneticClustering, getVolumeFromProducts } from '@bbng/util/misc';
import {
    CCRoFront,
    PlanningCalculateDto,
    TruckRo,
    isCCServiceFront,
    CCRo,
    isCCService,
    CC_STATUS
} from '@bbng/util/types';

import { mapCollectConfigFamilyText } from '../../common/enumMapper';
import Input from '../../components/Inputs';
import { displayAddress } from '../../pages/Collect/helpers';
import { PlanningPageState } from '../../pages/Planning/helpers';
import { PlanningFormState, mapStateToCalculateDtoWithoutCluster } from './helpers';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { toast } from '../../components/Toast';

export type PlanningClusterConfigurationProps = {
    data: PlanningFormState | undefined;
    submitCallback: (dto: PlanningCalculateDto) => void;
    setModalIsOpen: (isOpen: boolean) => void;
    context: PlanningPageState;
    setCalculatedPlanningsState: (state: PlanningFormState) => void;
    selectedZoneIds: string[];
};

export const PlanningClusterConfiguration: React.FC<PlanningClusterConfigurationProps> = ({
    data,
    context,
    submitCallback,
    setModalIsOpen,
    setCalculatedPlanningsState,
    selectedZoneIds
}: PlanningClusterConfigurationProps): JSX.Element => {
    const [clusters, setClusters] = useState<Record<string, CCRoFront[]>>({});
    const [clusterNumber, setClusterNumber] = useState(
        data ? Object.values(data.shifts).filter((sh) => sh.selected).length : 0
    );
    const [assignMethod, setAssignMethod] = useState<AssignMethod>('equitablyByCollect');
    const {
        ccsService,
        ccsAdministrative,
        unnassignedCCService,
        unnassignedCCAdministrative,
        trucks: storeTrucks,
        dumpsterTypes
    } = usePlanningV2Store();

    const assignMethodOptions = [
        { label: 'Equitablement par collecte', value: 'equitablyByCollect' },
        { label: 'Equitablement par volume', value: 'equitablyByVolume' }
    ];

    const createCluster = (dto: PlanningCalculateDto, clusterNumber: number): Record<string, CCRoFront[]> => {
        let size = clusterNumber;
        if (clusterNumber > dto.parameters.length) size = dto.parameters.length;

        const trucks = dto.parameters
            .flatMap((param) => {
                const truck = storeTrucks[param.truck_id];
                if (truck) return truck;
                return undefined;
            })
            .filter((el): el is TruckRo => el !== undefined);
        const listCCs = dto.parameters
            .flatMap<CCRo>((param) => {
                const listCCService: CCRo[] = param.ccsService.map((ccId) => ccsService[ccId]);
                const listCCAdmin: CCRo[] = param.ccsAdministrative.map((ccId) => ccsAdministrative[ccId]);
                return listCCAdmin.concat(listCCService);
            })
            .concat(unnassignedCCService, unnassignedCCAdministrative)
            .filter((cc) => {
                /**
                 * Remove all unfinished CCs not in selected zones
                 */
                if (isCCService(cc) && ![CC_STATUS.FINISHED, CC_STATUS.HAZARD].includes(cc.status as any))
                    return selectedZoneIds.includes(cc.zone.id);
                return true;
            });

        const geneticClustering = new GeneticClustering(listCCs, trucks, size).optimize();
        return geneticClustering.dispatch(assignMethod);
    };

    useEffect(() => {
        if (data) {
            const selectedData: PlanningFormState = {
                ...data,
                shifts: Object.fromEntries(Object.entries(data.shifts).filter(([_, shift]) => shift.selected))
            };
            const clusterInputs = mapStateToCalculateDtoWithoutCluster({
                state         : selectedData,
                context,
                unassigned_cc : [...unnassignedCCAdministrative, ...unnassignedCCService],
                ccsService,
                ccsAdministrative,
                zoneIds       : selectedZoneIds,
                dumpsterTypes
            });
            const clusters = createCluster(clusterInputs, clusterNumber);
            if (Object.keys(clusters)?.length === 0 || Object.values(clusters).every((ccs) => ccs.length === 0)) {
                toast({
                    life     : 10_000,
                    severity : 'info',
                    summary  : 'Aucun cluster possible',
                    detail   : "Il n'y aucun cluster possible avec les chauffeurs/zones sélectionnés."
                });
                setModalIsOpen(false);
            } else {
                setClusters(clusters);
            }
        }
    }, [
        clusterNumber,
        ccsService,
        ccsAdministrative,
        data,
        context,
        unnassignedCCAdministrative,
        unnassignedCCService,
        assignMethod
    ]);

    if (!data) {
        return <div>Clusterisation impossible... Veuillez contacter l'équipe technique.</div>;
    }

    return (
        <div>
            <div>
                <InputContainer>
                    <Input.Number
                        label="Nombre de clusters"
                        value={clusterNumber}
                        required={true}
                        id="clusterNumber"
                        max={Object.keys(data.shifts).length}
                        min={1}
                        result={(value) => value && setClusterNumber(value)}
                        errors={[]}
                    />
                </InputContainer>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    Répartition des collectes:
                    <SelectButton
                        value={assignMethod}
                        options={assignMethodOptions}
                        onChange={(e) => setAssignMethod(e.value)}
                    />
                </div>
                {Object.entries(clusters).map(([truckId, ccs]) => {
                    const shift = Object.values(data.shifts).find((shift) => shift.truck?.id === truckId);
                    const volume = ccs.reduce((acc, cc) => {
                        const ccService = isCCServiceFront(cc) ? cc : undefined;
                        if (ccService) {
                            const ccVolume = getVolumeFromProducts(ccService.products, cc.family);
                            return acc + ccVolume;
                        }
                        return acc;
                    }, 0);
                    return (
                        <ClusterContainer key={truckId}>
                            <ClusterTitle>
                                {shift?.collector?.fullname} - {shift?.truck?.name} - {volume}m³ - {ccs.length} élements
                            </ClusterTitle>
                            {ccs.length > 0 ? (
                                <StyledTable>
                                    <thead>
                                        <tr>
                                            <th>Numéro</th>
                                            <th>Plage horaire</th>
                                            <th>Adresse</th>
                                            <th>Terminée</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ alignItems: 'center' }}>
                                        {ccs
                                            .sort((a, b) => {
                                                return moment(a.from_date).diff(moment(b.from_date));
                                            })
                                            .map((cc) => {
                                                const ccService = isCCServiceFront(cc) ? cc : undefined;
                                                const slot = `${moment(cc.from_date).format('HH:mm')} - ${moment(
                                                    cc.to_date
                                                ).format('HH:mm')}`;
                                                const isFinished = [CC_STATUS.FINISHED, CC_STATUS.HAZARD].includes(
                                                    cc.status as any
                                                );
                                                return (
                                                    <tr key={cc.id}>
                                                        <td>
                                                            {ccService
                                                                ? ccService.number
                                                                : mapCollectConfigFamilyText(cc.family)}
                                                        </td>
                                                        <td>{slot}</td>
                                                        <td>{displayAddress(cc.address)}</td>
                                                        <td>{isFinished ? 'Oui' : 'Non'}</td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </StyledTable>
                            ) : (
                                <span>Camion flexible (pour toutes les collectes)</span>
                            )}
                            <Divider />
                        </ClusterContainer>
                    );
                })}
            </div>
            <Footer>
                <Footer>
                    <PRButton
                        className="p-button-secondary"
                        label="Calculer sans cluster"
                        onClick={() => {
                            setModalIsOpen(false);
                            submitCallback(
                                mapStateToCalculateDtoWithoutCluster({
                                    state         : data,
                                    context,
                                    unassigned_cc : [...unnassignedCCAdministrative, ...unnassignedCCService],
                                    ccsService,
                                    ccsAdministrative,
                                    zoneIds       : selectedZoneIds,
                                    dumpsterTypes
                                })
                            );
                            setCalculatedPlanningsState(data);
                        }}
                    />
                </Footer>
                <PRButton
                    label="Calculer avec les clusters"
                    onClick={() => {
                        setModalIsOpen(false);
                        submitCallback({
                            ...mapStateToCalculateDtoWithoutCluster({
                                state         : data,
                                context,
                                unassigned_cc : [...unnassignedCCAdministrative, ...unnassignedCCService],
                                ccsService,
                                ccsAdministrative,
                                zoneIds       : selectedZoneIds,
                                dumpsterTypes
                            }),
                            clusters: Object.fromEntries(
                                Object.entries(clusters).map(([truckId, ccs]) => [truckId, ccs.map((cc) => cc.id)])
                            )
                        });
                        setCalculatedPlanningsState(data);
                    }}
                    disabled={Object.keys(clusters).length === 0}
                />
            </Footer>
        </div>
    );
};

const Footer = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-between;
`;

const StyledTable = styled.table`
    border-spacing: 4px;
`;

const ClusterContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const ClusterTitle = styled.div`
    font-weight: 500;
    font-size: 1.1em;
`;

const InputContainer = styled.div`
    padding: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;
