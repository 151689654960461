import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import equal from 'fast-deep-equal';
import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';

import {
    ALLOW_GOD_MODE_KEY,
    CCAdministrativeRo,
    CCRo,
    CCRoFront,
    CCServiceRo,
    CCServiceRoFront,
    CC_FAMILY,
    CC_STATUS,
    CustomerRo,
    isCCService,
    PRODUCT_FAMILY
} from '@bbng/util/types';
import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';

import { fetchDataRelation } from '../../common/dataRelation';
import { ActionsFormContainer } from '../../common/form';
import { PageLoader } from '../../components/PageLoader';
import useLocalStorage from '../../hooks/LocalStorage';
import { useRequest } from '../../hooks/StatelessRequest';
import { AddressState, Address } from '../../modules/common/Address';
import { Contacts } from '../../modules/common/Contacts';
import { EOrderDumpsterServiceForm, mapCollectConfigFamilyToService } from '../../modules/order/DumpsterService';
import { mapProductFamilyToOrderTypeForm } from '../../modules/order/Products/helpers';
import { EOrderTypeForm } from '../../modules/order/Type';
import CollectConfig from '../../modules/collect-config';
import Order from '../../modules/order';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    CollectConfigFormErrorState,
    initialErrorState,
    mapStateToApiEditData,
    initialState,
    CollectConfigModulesStates,
    CollectConfigModulesErrorStates,
    mapApiDataToState,
    CollectConfigFormState
} from './helpers';
import { SubmitButton } from './style';
import { Tag } from 'primereact/tag';
import { mapCollectConfigStatus } from '../../common/enumMapper';
import { mapStatusToSeverity } from '../CollectConfigs';

export type CollectConfigFormPageProps = {
    edit?: boolean;
    readOnly?: boolean;
};

export const CollectConfigFormPage: React.FC<CollectConfigFormPageProps> = ({
    edit = false,
    readOnly = false
}: CollectConfigFormPageProps): JSX.Element => {
    const { id: dataId = 'undefined' } = useParams();

    const [apiState, setApiState] = React.useState<{ form: CollectConfigFormState; db: CCRo }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });
    const navigate = useNavigate();
    const [fetchError, setFetchError] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(edit || readOnly);
    const [collectConfigType, setCollectConfigType] = useState<EOrderTypeForm>();
    const [collectConfigService, setCollectConfigService] = useState<EOrderDumpsterServiceForm>();
    const [isAdministrative, setIsAdministrative] = useState<boolean>();
    const [customer, setCustomer] = useState<CustomerRo>();

    const isGodMode = useLocalStorage(ALLOW_GOD_MODE_KEY, false);

    const ccNumber = useMemo(() => {
        if (isCCService(apiState?.db)) return apiState?.db?.number;
        return undefined;
    }, [apiState?.db]);
    const orderNumber = useMemo(() => {
        if (isCCService(apiState?.db)) return apiState?.db?.order_number;
        return undefined;
    }, [apiState?.db]);
    const orderId = useMemo(() => {
        if (isCCService(apiState?.db)) return apiState?.db?.order_id[0];
        return undefined;
    }, [apiState?.db]);
    const title = useMemo(() => {
        if (isAdministrative) return `administrative`;
        if (ccNumber) return `${ccNumber}`;
        return '';
    }, [ccNumber, isAdministrative]);

    const [err, setErr] = useState<CollectConfigFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<CollectConfigFormState>['onSubmit']>(
        async (data: CollectConfigFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }
            setLoading(true);

            const method = edit ? 'PATCH' : 'POST';
            const url = () => {
                if (edit) {
                    return isAdministrative
                        ? urlApiBuilder.ccAdministrativeEdit(dataId as string)
                        : urlApiBuilder.collectConfigEdit(dataId as string);
                } else {
                    return isAdministrative
                        ? urlApiBuilder.ccAdministrativeCreate()
                        : urlApiBuilder.collectConfigCreate();
                }
            };
            const body = mapStateToApiEditData(data, apiState!.db, apiState!.form, isAdministrative);

            const response = await bbngRequest<CCRo>({
                method,
                url     : url(),
                payload : {
                    body,
                    queryParams: {
                        godMode: isGodMode ? true : undefined
                    }
                }
            });

            if (response.success && response.response?.data.ro) {
                navigate(urlBuilder.collectConfigView(dataId));
            }
            setLoading(false);
        },
        [err, apiState, bbngRequest, dataId]
    );

    const formik = useFormik<CollectConfigFormState>({
        initialValues : initialState,
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (
            value: CollectConfigModulesStates | AddressState,
            errors: CollectConfigModulesErrorStates | string[] | null,
            id: string
        ): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        /**
         * As there is no way to guess cc nature (service or admin), we need to try both, and work with the successful one.
         */
        const serviceRes = await bbngRequest<CCServiceRo>({
            method  : 'GET',
            options : { toastifySuccess: false, toastifyError: false },
            url     : urlApiBuilder.collectConfigRead(dataId)
        });
        const administrativeRes = await bbngRequest<CCAdministrativeRo>({
            method  : 'GET',
            options : { toastifySuccess: false, toastifyError: false },
            url     : urlApiBuilder.ccAdministrativeRead(dataId)
        });

        let cc: CCRo;
        if (serviceRes.success && serviceRes.response?.data.ro) {
            cc = serviceRes.response.data.ro;
            setIsAdministrative(false);
        } else if (administrativeRes.success && administrativeRes.response?.data.ro) {
            cc = administrativeRes.response.data.ro;
            setIsAdministrative(true);
        } else {
            setFetchError(serviceRes?.response?.data.status_code ?? serviceRes.error?.status);
            setLoading(false);
            return;
        }

        if (cc) {
            const ro = await fetchDataRelation<CCRoFront>(cc, {
                collector_id              : true,
                truck_id                  : true,
                customer_id               : true,
                presta_id                 : true,
                dumpster_on_site_id       : true,
                deposit_collect_config_id : true
            });
            const formikState: CollectConfigFormState = mapApiDataToState(ro);

            if ((ro as CCServiceRoFront).customer_id && (ro as CCServiceRoFront).customer_id[0]) {
                setCustomer((ro as CCServiceRoFront).customer_id[0] as CustomerRo);
            }
            setCollectConfigType(mapProductFamilyToOrderTypeForm(ro.family as PRODUCT_FAMILY));
            setCollectConfigService(
                ro.family.includes('DUMPSTER') ? mapCollectConfigFamilyToService(ro.family) : undefined
            );
            setApiState({ form: formikState, db: cc });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        }
    }, [dataId]);

    React.useEffect(() => {
        if (edit || readOnly) {
            fetchData();
        }
    }, []);

    if (loading) return <PageLoader loading />;

    if (fetchError) {
        return fetchError === StatusCodes.NOT_FOUND ? <Error404Data /> : <Error500 />;
    }

    const canTerminateCollect =
        !apiState?.db ||
        (apiState.db.family !== CC_FAMILY.ADMINISTRATIVE &&
            (
                [CC_STATUS.CANCELED, CC_STATUS.FINISHED, CC_STATUS.HAZARD, CC_STATUS.ORDER_TO_PAY] as CC_STATUS[]
            ).includes(apiState.db.status) === false);

    return (
        <form onSubmit={formik.handleSubmit}>
            <h1>
                Configuration de collecte: {title}
                {orderNumber && orderId && (
                    <a href={urlBuilder.orderView(orderId)} target="_blank" rel="noreferrer">
                        &nbsp;(commande {orderNumber})
                    </a>
                )}
            </h1>
            <StatusTag
                value={mapCollectConfigStatus(apiState?.db?.status as CC_STATUS)}
                severity={mapStatusToSeverity(apiState?.db?.status as CC_STATUS)}
            />
            <ActionsFormContainer
                archived={apiState?.db?.archived || false}
                edit={edit}
                readOnly={readOnly}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                editPageUrl={urlBuilder.collectConfigEdit(dataId)}
                historyUrl={urlApiBuilder.collectConfigHistory(dataId)}
                customActions={[
                    {
                        tooltip : 'Terminer la collecte',
                        icon    : 'pi pi-check',
                        hide    : canTerminateCollect === false || readOnly === false,
                        onClick : () => {
                            navigate(urlBuilder.collectCreate(dataId));
                        }
                    }
                ]}
            />
            <CollectConfig.Misc
                displayError={formik.submitCount > 0}
                readOnly={readOnly}
                id="misc"
                result={handleChange}
                value={formik.values.misc}
                edit={edit}
                showExecutionTimeMinutes
                showTitle={isAdministrative}
            />
            <Order.CollectInfo
                displayError={formik.submitCount > 0}
                readOnly={readOnly}
                id="collectInfo"
                result={handleChange}
                value={formik.values.collectInfo}
                showWaitingTimeMinutes={collectConfigService === EOrderDumpsterServiceForm.LOAD_WAIT}
                disableDayEdition={apiState?.db?.status === CC_STATUS.SPLITTED}
                showCharacteristics={!isAdministrative}
                customerName={customer?.name}
            />
            {/* Show address field only for creation or when it's an administrative cc */}
            {(!edit || isAdministrative) && (
                <Address
                    displayError={formik.submitCount > 0}
                    readOnly={readOnly}
                    id="address"
                    result={handleChange}
                    value={formik.values.address}
                />
            )}
            {(edit || readOnly) && (
                <>
                    {!isAdministrative && (
                        <Contacts
                            displayError={formik.submitCount > 0}
                            readOnly={readOnly}
                            id="contacts"
                            result={handleChange}
                            value={formik.values.contacts}
                        />
                    )}
                    {!isAdministrative && (
                        <Order.Products
                            displayError={formik.submitCount > 0}
                            readOnly={readOnly}
                            id="products"
                            result={handleChange}
                            value={formik.values.products}
                            type={collectConfigType}
                            service={collectConfigService}
                            zoneId={(apiState?.db as CCServiceRo).zone?.id}
                            forceFetch
                            fetchOperational={false}
                        />
                    )}
                    <CollectConfig.DumpsterOnSiteSelect
                        displayError={formik.submitCount > 0}
                        readOnly={readOnly}
                        id="dumpsterOnSiteSelect"
                        result={handleChange}
                        construction_site_id={(apiState?.db as CCServiceRo)?.construction_site_id?.[0]}
                        products={formik.values.products}
                        value={formik.values.dumpsterOnSiteSelect}
                        currentCcId={apiState?.db.id}
                    />
                    {readOnly === false && (
                        <CollectConfig.PatchTracker
                            displayError={formik.submitCount > 0}
                            readOnly={readOnly}
                            id="patchTracker"
                            result={handleChange}
                            value={formik.values.patchTracker}
                        />
                    )}
                </>
            )}
            {readOnly === false && (
                <SubmitButton
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        (equal(apiState?.form, formik.values) && edit)
                    }
                />
            )}
        </form>
    );
};

export const PageForm = styled.div`
    margin: 16px;
    display: flex;
    flex-direction: column;
    & > * {
        flex: 1;
    }
    gap: 16px;
    & h1 {
        margin: 0;
    }
`;

export const StatusTag = styled(Tag)`
    display: flex;
    width: 100%;
    width: fit-content;
    font-size: 1.5em;
    margin: 16px auto;
`;
