import { BlockUI } from 'primereact/blockui';
import React from 'react';
import styled from 'styled-components';

import { GifLoader } from '../GifLoader';

const Backdrop = styled.div<{ blur?: boolean }>`
    ${({ blur }) =>
        blur &&
        `
        filter: blur(3px);
    `}
`;

export type PageLoaderProps = React.PropsWithChildren<{
    loading?: boolean;
    actionType?:
        | 'create'
        | 'delete'
        | 'read'
        | 'calculate_planning'
        | 'create_planning'
        | 'generate_invoice'
        | 'generate_bsd'
        | 'unarchive';
}>;

export const PageLoader: React.FC<PageLoaderProps> = ({
    loading = false,
    actionType = 'read',
    children
}: PageLoaderProps) => {
    const loaderText = {
        create             : 'Écriture des données en cours...',
        delete             : 'Archivage des données en cours...',
        read               : 'Récupération des données en cours...',
        calculate_planning : 'Calcul du planning en cours...',
        create_planning    : 'Création du planning en cours...',
        generate_invoice   : 'Génération des factures en cours...',
        generate_bsd       : 'Génération des BSDs en cours...',
        unarchive          : 'Réactivation des données en cours...'
    } as const;

    return (
        <div>
            {loading && <GifLoader subTitle={loaderText[actionType]} />}
            <Backdrop blur={loading}>
                <BlockUI fullScreen blocked={loading} />
            </Backdrop>
        </div>
    );
};
