import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { useState } from 'react';
import styled from 'styled-components';

import { ETruckType, TruckRo } from '@bbng/util/types';

import { mapTruckType } from '../../common/enumMapper';
import { TRUCK_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';

const StyledDropdown = styled(Dropdown)`
    width: 300px;
    height: 44px;
    & + & {
        margin-left: 20px;
    }
`;

const Anchor = styled.a`
    word-break: break-all;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #343699;
`;

const TagList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const StyledTag = styled(Tag)<{ color?: string }>`
    ${({ color }) => color && `background-color: ${color};`}
`;

type QueryParams = { type?: ETruckType };

export const TrucksListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [type, setType] = useState<ETruckType | undefined>(query.type);

    return (
        <Listing<any, TruckRo>
            url={TRUCK_ORCHESTRATION_BASE_URL}
            addButtonUrl={urlBuilder.truckCreate()}
            endpoint="trucks"
            name="Camion"
            pluralName="Camions"
            queryParams={{
                type
            }}
            leftHandedComponent={
                <div>
                    <StyledDropdown
                        value={type}
                        options={Object.values(ETruckType)}
                        itemTemplate={(option) => mapTruckType(option)}
                        valueTemplate={(option) => (option ? mapTruckType(option) : 'Filtrer par type')}
                        onChange={(e) => setType(e.value)}
                        placeholder="Filtrer par type"
                        showClear
                    />
                </div>
            }
            headers={[
                {
                    name      : 'Nom et plaque',
                    field     : 'name',
                    component : (data: TruckRo) => (
                        <Anchor href={urlBuilder.truckView(data.id)}>
                            {data.name} ({data.license_plate})
                        </Anchor>
                    )
                },
                {
                    name      : 'Poids',
                    field     : 'weight_ton',
                    width     : 70,
                    component : (e: TruckRo) => `${e.weight_ton}T`
                },
                {
                    name      : 'Temps de chargement',
                    field     : 'characteristics',
                    width     : 180,
                    component : (e: TruckRo) => `${e.characteristics.setup_duration} minutes`
                },
                {
                    name      : 'Garage',
                    field     : 'garage_address',
                    component : (e: TruckRo) =>
                        `${e.garage_address.formatted_name} (${e.garage_address.components['postal_code']})`
                },
                {
                    name      : 'Type',
                    field     : 'type',
                    component : (data: TruckRo) => {
                        return (
                            <TagList>
                                {data.type.map((type) => (
                                    <StyledTag key={data.id + type} value={mapTruckType(type)} />
                                ))}
                            </TagList>
                        );
                    }
                },
                {
                    name      : 'Disponibilité',
                    field     : 'is_available',
                    width     : 140,
                    component : (data: TruckRo) => {
                        const props = data.is_available
                            ? { severity: 'success', icon: 'pi pi-check', value: 'Disponible' }
                            : { severity: 'danger', icon: 'pi pi-times', value: 'Indisponible' };

                        return (
                            <div>
                                <Tag className="mr-2" {...props} />
                            </div>
                        );
                    }
                }
            ]}
        />
    );
};
