import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

export const CheckboxContainer = styled.div<{ labelOnLeft: boolean }>`
    display: flex;
    justify-content: left;
    flex-direction: ${({ labelOnLeft }) => (labelOnLeft ? 'row' : 'row-reverse')};
    align-items: center;
    gap: 16px;
`;

export type CheckboxButtonProps = {
    label?: string;
    labelPosition?: 'left' | 'right';
    value: boolean;
    id: string;
    result: (value: boolean, errors: null | string[], id: string) => void;
    readOnly?: boolean;
};

export const CheckboxButton: React.FC<CheckboxButtonProps> = ({
    label,
    labelPosition = 'left',
    value,
    id,
    result,
    readOnly
}: CheckboxButtonProps): JSX.Element => {
    const [val, setVal] = useState<boolean>(value);
    const labelOnLeft = labelPosition === 'left';

    const _checkDescendingValue = useCallback(
        async (stateValue: boolean, propsValue: boolean): Promise<void> => {
            if (stateValue !== propsValue) {
                setVal(propsValue);
                result(propsValue, null, id);
            }
        },
        [setVal, result]
    );

    const handleOnChange = useCallback(
        (e: CheckboxChangeParams): void => {
            setVal(e.checked);
            result(e.checked, null, id);
        },
        [setVal, result, id]
    );

    useEffect(() => {
        _checkDescendingValue(val, value);
    }, [value]);

    return (
        <CheckboxContainer labelOnLeft={labelOnLeft}>
            {label && <span>{label}</span>}
            <Checkbox onChange={handleOnChange} checked={val} disabled={readOnly} />
        </CheckboxContainer>
    );
};
