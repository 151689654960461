import { CollectorAccessList, CollectorAccessListProps } from './AccessList';
import { CollectorGeneral, CollectorGeneralProps } from './General';

const Modules = {
    GeneralInfo: (props: CollectorGeneralProps) => {
        return <CollectorGeneral {...props} />;
    },
    Access: (props: CollectorAccessListProps) => {
        return <CollectorAccessList {...props} />;
    }
};

export default Modules;
