import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { CCServiceRo, CC_FAMILY, CustomerRo, CREATED_FROM, FrontRo, OrderRoFront, UserRo } from '@bbng/util/types';
import { fetchDataRelation } from '../../common/dataRelation';
import {
    createdFromOptions,
    mapCollectConfigFamilyText,
    mapCollectConfigStatus,
    mapCreatedFrom
} from '../../common/enumMapper';
import { ORDER_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { FiltersContainer } from '../CollectConfigs/style';
import { ListingContainer, StyledSpan, TooltipEntry } from './style';
import moment from 'moment';
import Dinero from 'dinero.js';
import { displayVolumeFromProducts } from '@bbng/util/misc';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { mapStatusToIcon, mapStatusToSeverity } from '../CollectConfigs';

type QueryParams = {
    customer_id?: string;
    taken_from?: CREATED_FROM;
};

const GROUP_ROW_KEY = 'order_number';

export const OrderCustomerListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [customer_id, setCustomerId] = useState<string | undefined>(query.customer_id);
    const [taken_from, setTakenFrom] = useState<CREATED_FROM | undefined>(query.taken_from);

    const [triggerPageRefresh, setTriggerPageRefresh] = useState<boolean>(false);
    useEffect(() => {
        setTriggerPageRefresh(false);
    }, [triggerPageRefresh]);

    return (
        <ListingContainer>
            <Listing<any, OrderRoFront>
                url={ORDER_ORCHESTRATION_BASE_URL}
                endpoint="orders"
                name="Commande Utilisateurs"
                pageUrl={urlBuilder.orderList()}
                pluralName="Commandes Utilisateurs"
                enrichData={async (data) => {
                    data.data.ro = await fetchDataRelation(data.data.ro ?? [], {
                        customer_id       : true,
                        user_id           : true,
                        collect_config_id : true
                    });
                    return data;
                }}
                queryParams={{
                    customer_id,
                    taken_from,
                    no_administrative: true
                }}
                hideCreateButton
                searchPlaceholder={`Rechercher par numéro de commande`}
                leftHandedComponent={
                    <FiltersContainer>
                        <RelationAutocomplete.Customer
                            placeholder="Filtrer par client"
                            onSelect={(customer) => setCustomerId(customer?.id)}
                            onUnselect={() => setCustomerId(undefined)}
                        />
                        <Dropdown
                            value={taken_from}
                            onChange={(e) => setTakenFrom(e.value)}
                            placeholder="Filtrer par origine de la commande"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={createdFromOptions}
                        />
                    </FiltersContainer>
                }
                displayActionColumn={false}
                headers={[
                    {
                        name      : 'Client',
                        field     : 'customer_id' as keyof OrderRoFront,
                        component : (data: FrontRo<OrderRoFront>) => {
                            const customer = data.customer_id[0] as CustomerRo | undefined;

                            if (!customer) return <span>Non renseigné</span>;

                            return <a href={urlBuilder.customerView(customer.id)}>{customer.name}</a>;
                        }
                    },
                    {
                        name      : 'Adresse (zone)',
                        field     : 'collect_config_id' as keyof OrderRoFront,
                        component : (data: FrontRo<OrderRoFront>) => {
                            const cc = data.collect_config_id[0] as CCServiceRo | undefined;
                            return (
                                <a href={urlBuilder.constructionSiteView(cc?.construction_site_id[0] as string)}>
                                    {cc?.address.formatted_name} ({cc?.zone.name})
                                </a>
                            );
                        }
                    },
                    {
                        name      : 'Utilisateur',
                        field     : 'user_id',
                        component : (data: FrontRo<OrderRoFront>) => {
                            const user = data.user_id[0] as UserRo | undefined;

                            if (!user) return <span>Non renseigné</span>;
                            return <a href={urlBuilder.userView(user.id)}>{user.email}</a>;
                        }
                    },
                    {
                        name      : 'Numéro commande',
                        field     : 'number' as keyof OrderRoFront,
                        width     : 90,
                        component : (data: FrontRo<OrderRoFront>) => (
                            <a href={urlBuilder.orderView(data.id)}>{data.number}</a>
                        )
                    },
                    {
                        name      : 'Numéro collecte',
                        field     : 'number' as keyof OrderRoFront,
                        width     : 90,
                        component : (data: FrontRo<OrderRoFront>) => {
                            const cc = data.collect_config_id[0] as CCServiceRo | undefined;
                            return <a href={urlBuilder.collectConfigView(cc?.id as string)}>{cc?.number}</a>;
                        }
                    },
                    {
                        name      : 'Origine de la commande',
                        field     : 'taken_from' as keyof OrderRoFront,
                        component : (data: FrontRo<OrderRoFront>) => {
                            return <span>{mapCreatedFrom(data.taken_from)}</span>;
                        }
                    },
                    {
                        name      : 'Date de commande',
                        field     : 'created_at' as keyof OrderRoFront,
                        component : (data: FrontRo<OrderRoFront>) => {
                            return <span>{new Date(data.created_at).toLocaleDateString('fr')}</span>;
                        }
                    },
                    {
                        name      : 'Date de collecte demandée',
                        field     : 'collect_date' as keyof OrderRoFront,
                        component : (data: FrontRo<OrderRoFront>) => {
                            const cc = data.collect_config_id[0] as CCServiceRo | undefined;
                            return (
                                <span>
                                    {moment(cc?.from_date).format('DD/MM/YYYY')} {moment(cc?.from_date).format('HH:mm')}{' '}
                                    - {moment(cc?.to_date).format('HH:mm')}
                                </span>
                            );
                        }
                    },
                    {
                        name      : 'Type',
                        field     : 'collect_config_id' as keyof OrderRoFront,
                        component : (data: FrontRo<OrderRoFront>) => {
                            const cc = data.collect_config_id[0] as CCServiceRo | undefined;
                            return <span>{mapCollectConfigFamilyText(cc?.family, true)}</span>;
                        }
                    },
                    {
                        name      : `Volume`,
                        field     : 'collect_config_id' as keyof OrderRoFront,
                        component : (data: FrontRo<OrderRoFront>) => {
                            const cc = data.collect_config_id[0] as CCServiceRo | undefined;
                            if (cc?.family === CC_FAMILY.ADMINISTRATIVE) return <span>N/A</span>;
                            const volumeToCollect = displayVolumeFromProducts(
                                cc?.products || [],
                                cc?.family as CC_FAMILY
                            );
                            return (
                                <>
                                    <Tooltip position="top" target={`.volume-${cc?.id}`}>
                                        {cc?.products.map((p) => (
                                            <TooltipEntry margin="4px 0 0">
                                                {p.quantity} x {mapCollectConfigFamilyText(p.family, true)} -{' '}
                                                {p.volume_m3}m3
                                            </TooltipEntry>
                                        ))}
                                    </Tooltip>
                                    <StyledSpan className={`volume-${cc?.id}`}>
                                        {volumeToCollect ?? 'N/A'}
                                        <i className="pi pi-eye" />
                                    </StyledSpan>
                                </>
                            );
                        }
                    },
                    {
                        name      : 'Prix HT',
                        field     : 'collect_config_id' as keyof OrderRoFront,
                        component : (data: FrontRo<OrderRoFront>) => {
                            const cc = data.collect_config_id[0] as CCServiceRo | undefined;
                            return (
                                <span>
                                    {Dinero({
                                        amount   : cc?.price.discounted_net.amount ?? 0,
                                        currency : cc?.price.discounted_net.currency ?? 'EUR'
                                    }).toFormat('$0.00')}
                                </span>
                            );
                        }
                    },
                    {
                        name      : 'Statut',
                        field     : 'collect_config_id' as keyof OrderRoFront,
                        component : (data: FrontRo<OrderRoFront>) => {
                            const cc = data.collect_config_id[0] as CCServiceRo | undefined;
                            return (
                                <div>
                                    <Tag
                                        className="mr-2"
                                        value={mapCollectConfigStatus(cc?.status)}
                                        severity={mapStatusToSeverity(cc?.status)}
                                        icon={mapStatusToIcon(cc?.status)}
                                    />
                                </div>
                            );
                        }
                    }
                ]}
                disableEditAction
                disableArchiveAction
                displaySelectColumn={false}
                hideBulkMenu
                triggerPageRefresh={triggerPageRefresh}
                rowGroupMode="rowspan"
                groupRowsBy={GROUP_ROW_KEY}
                scrollable={false}
                rowHover={false}
                actionColumnField={GROUP_ROW_KEY}
                minSearchLength={1}
                displayArchiveColumn={false}
                displayArchiveFilters={false}
            />
            <ConfirmDialog />
        </ListingContainer>
    );
};
