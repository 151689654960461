import { ETrashType, RevalorisationCharacteritics, RecyclingCharacteristicsRo } from '@bbng/util/types';

export const initialRecyclingCharacteristics = (): RecyclingCharacteristicsRo => {
    const result: RecyclingCharacteristicsRo = {
        id             : '',
        landfill_id    : '',
        to_date        : '',
        from_date      : '',
        recycling_info : Object.keys(ETrashType).reduce((acc, trashType) => {
            return {
                ...acc,
                [trashType]: {
                    reusage                : 0,
                    recycling              : 0,
                    energetic_valorisation : 0,
                    elimination            : 0,
                    global_valorisation    : 0
                }
            };
        }, {} as Record<ETrashType, RevalorisationCharacteritics>)
    };
    return result;
};
