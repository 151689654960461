import Dinero from 'dinero.js';
import moment, { Moment } from 'moment';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';

import {
    CustomerRo,
    EBillingBundle,
    EBillingMode,
    EBillingShipment,
    ECustomerCategory,
    EZohoInvoiceStatus,
    BillingBranch,
    FrontRo,
    InvoiceEmailDto,
    InvoiceEmailRo,
    InvoiceGenerateDto,
    InvoicePreGenerateRo,
    InvoiceQuery,
    InvoiceRo,
    EZohoOrganization,
    InvoiceDebitDto
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import {
    mapBillingBranchSeverity,
    mapBillingBundle,
    mapBillingMode,
    mapBillingShipment,
    mapInvoiceStatus
} from '../../common/enumMapper';
import { config } from '../../common/env';
import { INVOICE_ORCHESTRATION_BASE_URL, urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import { PageLoader } from '../../components/PageLoader';
import useQuery from '../../hooks/Query';
import { useRequest } from '../../hooks/StatelessRequest';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { MonthSelector } from '../../modules/month-selector';
import { FiltersContainer } from '../CollectConfigs/style';
import {
    ButtonWithDetail,
    CenterSpan,
    CustomerWithoutMailModal,
    Header,
    InvoicePlaceholder,
    mapStatusToIcon,
    mapStatusToSeverity,
    Page,
    StyledTable
} from './style';
import styled from 'styled-components';
import { axiosClient } from '../../common/axios';
import { defaultErrorToast } from '../../common/syncRequest';

export enum EMethodType {
    GENERATE = 'GENERATE',
    EMAIL = 'EMAIL',
    DEBIT = 'DEBIT'
}

export type InvoicePageState = {
    month: number;
    year: number;
};

type QueryParams = {
    customer_id?: string;
    shipment?: EBillingShipment;
    status?: EZohoInvoiceStatus;
    mode?: EBillingMode;
};

const shipmentOptions = Object.values(EBillingShipment).map((shipment) => ({
    name  : mapBillingShipment(shipment),
    value : shipment
}));
const modeOptions = Object.values(EBillingMode).map((mode) => ({
    name  : mapBillingMode(mode),
    value : mode
}));
const statusOptions = Object.values(EZohoInvoiceStatus).map((status) => ({
    name  : mapInvoiceStatus(status),
    value : status
}));

const StyledButton = styled(Button)`
    padding: 0;
`;

export const InvoicesListing: React.FC = (): JSX.Element => {
    const [customersWithoutEmail, setCustomersWithoutEmail] = useState<CustomerRo[]>([]);
    const { query } = useQuery<QueryParams>();
    const [shipment, setShipment] = useState<EBillingShipment | undefined>(query.shipment);
    const [status, setStatus] = useState<EZohoInvoiceStatus | undefined>(query.status);
    const [mode, setMode] = useState<EBillingMode | undefined>(query.mode);
    const [customer_id, setCustomerId] = useState<string | undefined>(query.customer_id);
    const [lastMonth] = useState<Moment>(moment.utc().subtract(1, 'month'));
    const [generation, setGeneration] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [generateLoading, setGenerateLoading] = React.useState<boolean>(false);

    const [emailModalShow, setEmailModalShow] = useState<boolean>(false);
    const [debitModalShow, setDebitModalShow] = useState<boolean>(false);
    const [preGenerateModalShow, setPreGenerateModalShow] = useState<boolean>(false);
    const [preGenerateData, setPreGenerateData] = useState<InvoicePreGenerateRo & { count: number }>();
    const [billingBranch, setBillingBranch] = useState<BillingBranch>(BillingBranch.ENDLESS_1);
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
    const [downloadId, setDownloadId] = useState<string>();

    const [val, setVal] = useState<InvoicePageState>({
        month : lastMonth.month(),
        year  : lastMonth.year()
    });

    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [triggerPageRefresh, setTriggerPageRefresh] = React.useState<boolean>(false);
    useEffect(() => {
        setTriggerPageRefresh(false);
    }, [triggerPageRefresh]);

    const triggerGenerateRequest = React.useCallback(
        async (
            bundleMode: EBillingBundle.GLOBAL | EBillingBundle.BY_CONSTRUCTION_SITE | EBillingBundle.BY_COLLECT,
            billingBranch: BillingBranch
        ) => {
            setLoading(true);

            if (preGenerateData === undefined) {
                setLoading(false);
                return;
            }

            const numberToGenerate = preGenerateData[billingBranch][bundleMode];

            await bbngRequest<InvoiceEmailRo | InvoiceRo[]>({
                method  : 'POST',
                url     : urlApiBuilder.invoiceGenerate(),
                options : { toastifySuccess: false },
                payload : {
                    body: {
                        month            : val.month,
                        year             : val.year,
                        bundleMode,
                        numberToGenerate : numberToGenerate,
                        billingBranch
                    } as InvoiceGenerateDto
                },
                sync         : false,
                timeout      : 1_000 * 60 * 60 * 6, // 6 hours
                retryPolling : 5_000
            });
            setLoading(false);
            setTriggerPageRefresh(true);
        },
        [val, preGenerateData, billingBranch, setTriggerPageRefresh, setLoading, bbngRequest, setGeneration]
    );

    const triggerEmailRequest = React.useCallback(
        async (billingBranch: BillingBranch) => {
            setLoading(true);

            const res = await bbngRequest<InvoiceEmailRo>({
                method  : 'POST',
                url     : urlApiBuilder.invoiceEmail(),
                options : { toastifySuccess: false },
                payload : {
                    body: {
                        month         : val.month,
                        year          : val.year,
                        billingBranch : billingBranch
                    } as InvoiceEmailDto
                },
                sync         : false,
                timeout      : 1_000 * 60 * 60 * 6, // 6 hours
                retryPolling : 5_000
            });
            setLoading(false);
            setTriggerPageRefresh(true);

            const ro = res?.response?.data.ro;

            if (res.success && (ro?.customersWithoutEmail || [])?.length > 0) {
                setCustomersWithoutEmail(ro?.customersWithoutEmail || []);
            }
        },
        [val, preGenerateData, billingBranch, setTriggerPageRefresh, setLoading, bbngRequest]
    );

    const triggerDebitRequest = React.useCallback(
        async (billingBranch: BillingBranch) => {
            setLoading(true);

            await bbngRequest({
                method  : 'POST',
                url     : urlApiBuilder.invoiceDebit(),
                options : { toastifySuccess: true },
                payload : {
                    body: {
                        month         : val.month,
                        year          : val.year,
                        billingBranch : billingBranch
                    } as InvoiceDebitDto
                },
                sync         : false,
                timeout      : 1_000 * 60 * 60 * 6, // 6 hours
                retryPolling : 5_000
            });
            setLoading(false);
            setTriggerPageRefresh(true);
        },
        [val, preGenerateData, billingBranch, setTriggerPageRefresh, setLoading, bbngRequest]
    );

    const handleDownload = async (id: string, name: string): Promise<void> => {
        try {
            setDownloadLoading(true);
            setDownloadId(id);
            const response = await axiosClient.get(urlApiBuilder.invoiceDownload(id), {
                params: {
                    sync: true
                },
                responseType: 'blob'
            });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.data);
            link.download = name;
            link.click();
            link.remove();
            setDownloadLoading(false);
            setDownloadId(undefined);
        } catch (e) {
            defaultErrorToast('Une erreur est survenue lors du téléchargement de la facture');
            console.error(e);
        }
    };

    useEffect(() => {
        if (customersWithoutEmail.length > 0) {
            confirmDialog({
                header  : 'Attention, e-mails manquants pour certains clients',
                message : () => (
                    <CustomerWithoutMailModal>
                        Les factures n'ont pas été envoyées aux clients suivants car ils n'ont pas d'adresse e-mail (ou
                        elles sont toutes invalides).
                        {customersWithoutEmail.map((customer) => (
                            <StyledTable key={customer.id}>
                                <thead>
                                    <tr>
                                        <th colSpan={4}>Contacts de {customer.name}</th>
                                    </tr>
                                    <tr>
                                        <th>Prénom</th>
                                        <th>Nom</th>
                                        <th>Téléphone</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customer.contact?.map((c, idx) => (
                                        <tr key={idx}>
                                            <td>{c.firstname}</td>
                                            <td>{c.lastname}</td>
                                            <td>{c.phone_number}</td>
                                            <td>{c.email}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </StyledTable>
                        ))}
                    </CustomerWithoutMailModal>
                ),
                acceptLabel : 'Ok',
                rejectLabel : ' '
            });
        }
    }, [customersWithoutEmail]);

    const confirm = React.useCallback(
        async (type: EMethodType) => {
            switch (type) {
                case EMethodType.GENERATE: {
                    setGenerateLoading(true);
                    setGeneration(true);
                    const res = await bbngRequest<InvoicePreGenerateRo>({
                        method  : 'POST',
                        url     : urlApiBuilder.invoicePreGenerate(),
                        options : { toastifySuccess: false },
                        sync    : true,
                        payload : {
                            body: {
                                month : val.month,
                                year  : val.year
                            } as InvoiceGenerateDto
                        }
                    });

                    if (res.response?.data.ro === undefined || res.response?.data.count === 0) {
                        confirmDialog({
                            header  : 'Aucune facture à générer',
                            message : "Aucune facture n'a été trouvée pour le mois sélectionné."
                        });
                        setGenerateLoading(false);
                        return;
                    }

                    const nbInvoices = res.response?.data.count || 0;
                    setPreGenerateData({ count: nbInvoices, ...res.response?.data.ro });
                    setPreGenerateModalShow(true);
                    setGenerateLoading(false);
                    setGeneration(false);
                    break;
                }
                case EMethodType.EMAIL: {
                    setEmailModalShow(true);
                    break;
                }
                case EMethodType.DEBIT: {
                    setDebitModalShow(true);
                    break;
                }
            }
        },
        [val, billingBranch, setBillingBranch, setEmailModalShow, setDebitModalShow]
    );

    const PregenerateModale = () => {
        const footerContent = (
            <>
                <div
                    style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginBottom: '10px' }}
                >
                    <SelectButton
                        value={billingBranch}
                        onChange={(e) => setBillingBranch(e.value)}
                        options={Object.values(BillingBranch)}
                    />
                </div>
                <div
                    style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginBottom: '10px' }}
                >
                    <Button
                        label="Global"
                        onClick={() => {
                            setPreGenerateModalShow(false);
                            triggerGenerateRequest(EBillingBundle.GLOBAL, billingBranch);
                        }}
                    />
                    <Button
                        label="Par chantier"
                        onClick={() => {
                            setPreGenerateModalShow(false);
                            triggerGenerateRequest(EBillingBundle.BY_CONSTRUCTION_SITE, billingBranch);
                        }}
                    />
                    <Button
                        label="Par collecte"
                        onClick={() => {
                            setPreGenerateModalShow(false);
                            triggerGenerateRequest(EBillingBundle.BY_COLLECT, billingBranch);
                        }}
                    />
                </div>
            </>
        );

        return (
            <Dialog
                header={`Génération des factures de ${moment.months(val.month)} ${val.year}`}
                visible={preGenerateModalShow}
                onHide={() => setPreGenerateModalShow(false)}
                footer={footerContent}
            >
                <p className="m-0">
                    <div style={{ textAlign: 'center' }}>Vous allez générer {preGenerateData?.count} factures.</div>
                    <br />
                    <br />
                    Pour l'environnement ENDLESS 1 (prestations big bags (collectes et livraisons)):
                    <ul>
                        <li>Global/Global par chantier: {preGenerateData?.ENDLESS_1.GLOBAL}</li>
                        <li>Par chantier: {preGenerateData?.ENDLESS_1.BY_CONSTRUCTION_SITE}</li>
                        <li>Par collecte: {preGenerateData?.ENDLESS_1.BY_COLLECT}</li>
                    </ul>
                    Pour l'environnement ENDLESS 2 (prestations bennes):
                    <ul>
                        <li>Global/Global par chantier: {preGenerateData?.ENDLESS_2.GLOBAL}</li>
                        <li>Par chantier: {preGenerateData?.ENDLESS_2.BY_CONSTRUCTION_SITE}</li>
                        <li>Par collecte: {preGenerateData?.ENDLESS_2.BY_COLLECT}</li>
                    </ul>
                </p>
            </Dialog>
        );
    };

    const EmailModal = () => {
        return (
            <Dialog
                header={`Envoi par e-mail des factures de ${moment.months(val.month)} ${val.year}`}
                visible={emailModalShow}
                onHide={() => setEmailModalShow(false)}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginBottom: '10px' }}
                >
                    <SelectButton
                        value={billingBranch}
                        onChange={(e) => setBillingBranch(e.value)}
                        options={Object.values(BillingBranch)}
                    />
                </div>
                <Button
                    label="Valider"
                    onClick={() => {
                        setEmailModalShow(false);
                        triggerEmailRequest(billingBranch);
                    }}
                />
            </Dialog>
        );
    };

    const DebitModal = () => {
        return (
            <Dialog
                header={`Déclenchement des prélèvements des factures de ${moment.months(val.month)} ${val.year}`}
                visible={debitModalShow}
                onHide={() => setDebitModalShow(false)}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginBottom: '10px' }}
                >
                    <SelectButton
                        value={billingBranch}
                        onChange={(e) => setBillingBranch(e.value)}
                        options={Object.values(BillingBranch)}
                    />
                </div>
                <Button
                    label="Valider"
                    onClick={() => {
                        setDebitModalShow(false);
                        triggerDebitRequest(billingBranch);
                    }}
                />
            </Dialog>
        );
    };

    if (loading) return <PageLoader loading actionType={generation ? 'generate_invoice' : 'read'} />;

    const getInvoiceUrl = (data: FrontRo<InvoiceRo>) => {
        if (data.billing_organization === EZohoOrganization.ZOHO_2) {
            return urlBuilder.zohoInvoice(config.zohoServer.organization_id_2, data.id_zoho_invoice);
        }
        return urlBuilder.zohoInvoice(config.zohoServer.organization_id_1, data.id_zoho_invoice);
    };

    return (
        <Page>
            <Header>
                <MonthSelector value={val} setValue={setVal} label="Période de facturation" />
            </Header>
            <InvoicePlaceholder>
                <ButtonWithDetail>
                    <Button
                        label={`Générer les factures de ${moment.months(val.month)} ${val.year}`}
                        onClick={() => confirm(EMethodType.GENERATE)}
                        icon={`pi ${generateLoading ? 'pi-spinner pi-spin' : 'pi-euro '}`}
                    />
                    <CenterSpan>
                        <i className="pi pi-info-circle" /> Les factures déjà générées ne seront pas dupliquées.
                    </CenterSpan>
                </ButtonWithDetail>
                <ButtonWithDetail>
                    <Button
                        label={`Envoyer les factures de ${moment.months(val.month)} ${val.year}`}
                        onClick={() => confirm(EMethodType.EMAIL)}
                        icon="pi pi-at"
                    />
                    <CenterSpan>
                        <i className="pi pi-info-circle" /> Seules les factures notées en brouillon et associées à des
                        clients à envoi par e-mail seront envoyées.
                    </CenterSpan>
                </ButtonWithDetail>
                <ButtonWithDetail>
                    <Button
                        label={`Déclencher les prélèvements de ${moment.months(val.month)} ${val.year}`}
                        onClick={() => confirm(EMethodType.DEBIT)}
                        icon="pi pi-money-bill"
                        className="p-button-warning"
                    />
                    <CenterSpan>
                        <i className="pi pi-info-circle" />
                        Seules les factures envoyées et qui ne sont ni payées, ni prélevées seront considérées pour les
                        prélèvements.
                        <br />
                        Toutes les factures éligibles sont regroupées en un seul prélèvement par entité de facturation.
                    </CenterSpan>
                </ButtonWithDetail>
            </InvoicePlaceholder>
            {preGenerateModalShow && <PregenerateModale />}
            {emailModalShow && <EmailModal />}
            {debitModalShow && <DebitModal />}
            <Listing<any, InvoiceRo>
                url={INVOICE_ORCHESTRATION_BASE_URL}
                endpoint="invoices"
                hideCreateButton
                hideBulkMenu
                showSearch={false}
                displaySelectColumn={false}
                displayActionColumn={false}
                disableArchiveAction
                disableEditAction
                title={`Factures du mois de ${moment.months(val.month)} ${val.year}`}
                subtitle=""
                name="Facture"
                pluralName="Factures"
                enrichData={async (data) => {
                    data.data.ro = await fetchDataRelation(data.data.ro ?? [], { customer_id: true });
                    return data;
                }}
                queryParams={
                    {
                        month            : val.month,
                        year             : val.year,
                        billing_shipment : shipment,
                        customer_id      : customer_id,
                        billing_mode     : mode,
                        status           : status
                    } as InvoiceQuery
                }
                leftHandedComponent={
                    <FiltersContainer>
                        <RelationAutocomplete.Customer
                            placeholder="Filtrer par client"
                            onSelect={(customer) => setCustomerId(customer?.id)}
                            onUnselect={() => setCustomerId(undefined)}
                        />
                        <Dropdown
                            value={mode}
                            onChange={(e) => setMode(e.value)}
                            placeholder="Filtrer par mode d'envoi"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={modeOptions}
                        />
                        <Dropdown
                            value={shipment}
                            onChange={(e) => setShipment(e.value)}
                            placeholder="Filtrer par type d'envoi"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={shipmentOptions}
                        />
                        <Dropdown
                            value={status}
                            onChange={(e) => setStatus(e.value)}
                            placeholder="Filtrer par statut"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={statusOptions}
                        />
                    </FiltersContainer>
                }
                triggerPageRefresh={triggerPageRefresh}
                headers={[
                    {
                        name      : 'Numéro',
                        field     : 'number_zoho_invoice',
                        component : (data: FrontRo<InvoiceRo>) => (
                            <a href={getInvoiceUrl(data)} target="_blank" rel="noopener noreferrer">
                                {data.number_zoho_invoice}
                            </a>
                        )
                    },
                    {
                        name      : 'Client',
                        field     : 'customer_id',
                        component : (data: FrontRo<InvoiceRo>) => {
                            if (data.customer_id?.length > 0) {
                                const isIndividual = data.customer_id[0].category === ECustomerCategory.INDIVIDUAL;
                                const individualIcon = <i className="pi pi-user" />;
                                return (
                                    <>
                                        <Tooltip
                                            position="top"
                                            target={`.customer-${data.id.replace(':', '')}`}
                                            content={isIndividual ? 'Particulier' : undefined}
                                        />
                                        <span className={`customer-${data.id.replace(':', '')}`}>
                                            {data.customer_id[0].name} {isIndividual && individualIcon}
                                        </span>
                                    </>
                                );
                            }
                            return <span>N/A</span>;
                        }
                    },
                    {
                        name      : 'Paiement prévu le',
                        field     : 'scheduled_payment_date',
                        component : (data: FrontRo<InvoiceRo>) => (
                            <span>{moment(data.scheduled_payment_date).format('DD/MM/YYYY')}</span>
                        )
                    },
                    {
                        name      : 'Paiement fait le',
                        field     : 'paid_payment_date',
                        component : (data: FrontRo<InvoiceRo>) => (
                            <span>
                                {data.paid_payment_date ? moment(data.paid_payment_date).format('DD/MM/YYYY') : 'N/A'}
                            </span>
                        )
                    },
                    {
                        name      : 'Mode de facturation',
                        field     : 'billing_mode',
                        component : (data: FrontRo<InvoiceRo>) => {
                            if (data.billing_mode === EBillingMode.END_OF_MONTH) {
                                return (
                                    <span>
                                        {mapBillingMode(data.billing_mode)} ({mapBillingBundle(data.billing_bundle)})
                                    </span>
                                );
                            }
                            return <span>{mapBillingMode(data.billing_mode)}</span>;
                        }
                    },
                    {
                        name      : 'Délai de paiement',
                        field     : 'payment_time',
                        component : (data: FrontRo<InvoiceRo>) => (
                            <span>{data.payment_time === 0 ? 'Immédiat' : `${data.payment_time} jours`}</span>
                        )
                    },
                    {
                        name      : 'Montant (HT)',
                        field     : 'price',
                        component : (data: FrontRo<InvoiceRo>) => (
                            <span>
                                {Dinero({
                                    amount   : data.price.discounted_net.amount,
                                    currency : data.price.discounted_net.currency
                                }).toFormat('$0.00')}{' '}
                                (HT)
                            </span>
                        )
                    },
                    {
                        name      : 'Statut',
                        field     : 'status',
                        component : (data: FrontRo<InvoiceRo>) => (
                            <div>
                                <Tag
                                    icon={mapStatusToIcon(data.status)}
                                    className="mr-2"
                                    severity={mapStatusToSeverity(data.status)}
                                    value={mapInvoiceStatus(data.status)}
                                />
                            </div>
                        )
                    },
                    {
                        name      : 'Prélèvement déclenché ?',
                        field     : 'id_gocardless_payment',
                        component : (data: FrontRo<InvoiceRo>) => (
                            <span>{data.id_gocardless_payment ? `Oui (${data.id_gocardless_payment})` : 'Non'}</span>
                        )
                    },
                    {
                        name      : 'Mode envoi',
                        field     : 'billing_shipment',
                        width     : 55,
                        component : (data: FrontRo<InvoiceRo>) => (
                            <>
                                <Tooltip
                                    position="top"
                                    target={`.customer-${data.id.replace(':', '')}`}
                                    content={mapBillingShipment(data.billing_shipment)}
                                />
                                <i
                                    title={mapBillingShipment(data.billing_shipment)}
                                    className={`customer-${data.id.replace(':', '')} pi pi-${
                                        data.billing_shipment === EBillingShipment.EMAIL ? 'at' : 'envelope'
                                    }`}
                                />
                            </>
                        )
                    },
                    {
                        name      : 'Entité de facturation',
                        field     : 'billing_branch',
                        width     : 85,
                        component : (data: FrontRo<InvoiceRo>) => {
                            const billingBranch = data.billing_branch;
                            return (
                                <div>
                                    <Tag
                                        className="mr-2"
                                        severity={mapBillingBranchSeverity(billingBranch)}
                                        value={billingBranch.replace(/_/g, ' ')}
                                    />
                                </div>
                            );
                        }
                    },
                    {
                        name      : 'PDF',
                        field     : 'id',
                        width     : 150,
                        component : (data: InvoiceRo) => {
                            return (
                                <StyledButton
                                    label="Télécharger"
                                    className="p-button-text"
                                    icon="pi pi-download"
                                    onClick={() => handleDownload(data.id, `${data.number_zoho_invoice}.pdf`)}
                                    loading={data.id === downloadId && downloadLoading}
                                />
                            );
                        }
                    }
                ]}
            />
            <ConfirmDialog />
        </Page>
    );
};
