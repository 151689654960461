import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { CardErrors, CustomerRo, EMembershipType } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import Calendar from '../../components/Calendar';
import { Card } from '../../components/Card';
import Input from '../../components/Inputs';
import { useFormModule } from '../../hooks/FormModule';

import { Button as PRButton } from 'primereact/button';
import { useRequest } from '../../hooks/StatelessRequest';
import { urlApiBuilder } from '../../common/urlBuilder';

export type CustomerGeneralProps = {
    readOnly?: boolean;
    value?: CustomerGeneralState;
    id: string;
    result: (
        value: CustomerGeneralState,
        errors: null | string[] | CardErrors<CustomerGeneralState>,
        id: string
    ) => void;
    displayError?: boolean;
    isIndividual?: boolean;
    edit?: boolean;
};

export type CustomerGeneralState = {
    name: string;
    internal_name?: string;
    membership?: string;
    registration?: Date;
    share_capital?: number;
    siren: string;
    siret: string;
    vat: string;
    naf: string;
    revenue?: number;
    revenue_year?: string;
    type: string;
    is_retailer: boolean;
    blocked: boolean;
    mandatory_order_sheet: boolean;
    id_zoho_customer: string;
    previous_id_zoho_customer: string;
    comment?: string;
};

export type CustomerGeneralErrorState = CardErrors<CustomerGeneralState>;

export const initialState: CustomerGeneralState = {
    name                      : '',
    internal_name             : '',
    siret                     : '',
    siren                     : '',
    naf                       : '',
    vat                       : '',
    type                      : '',
    is_retailer               : false,
    registration              : undefined,
    membership                : EMembershipType.IVORY,
    blocked                   : false,
    mandatory_order_sheet     : false,
    id_zoho_customer          : '',
    previous_id_zoho_customer : '',
    comment                   : ''
};

export const initialErrorState: CustomerGeneralErrorState = generateInitialErrorState(initialState);

export const CustomerGeneral = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false,
    isIndividual = false,
    edit = false
}: CustomerGeneralProps) => {
    const { val, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    React.useEffect(() => {
        if (isIndividual) {
            const newErrorState = generateInitialErrorState(initialState);
            setErr((prev) => ({ ...newErrorState, name: prev.name, membership: prev.membership }));
        }
    }, [isIndividual]);

    const [creationLoading, setCreationLoading] = React.useState(false);
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });
    const handleCreateZohoCustomer = async () => {
        setCreationLoading(true);

        //Get browser URL
        const url = window.location.href;

        //Get customer ID
        const customerId = url.split('/').pop() ?? '';

        const response = await bbngRequest<CustomerRo>({
            method : 'POST',
            url    : urlApiBuilder.customerCreateZoho(customerId)
        });
        if (response.success === false) {
            console.log(response.error?.message);
        }
        setCreationLoading(false);

        //Refresh page
        window.location.reload();
    };

    return (
        <StyledCard title="Informations générales">
            <CardLine>
                <div>
                    <Button.Switch
                        label="Client bloqué"
                        id="blocked"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.blocked}
                    />
                </div>
                <Input.Text
                    required={false}
                    label="ID ZOHO"
                    id="id_zoho_customer"
                    readOnly={true}
                    result={handleChange}
                    value={val.id_zoho_customer}
                    errors={err.id_zoho_customer}
                    displayError={displayError}
                />
                <Input.Text
                    required={false}
                    label="Ancien ID ZOHO"
                    id="previous_id_zoho_customer"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.previous_id_zoho_customer}
                    errors={err.previous_id_zoho_customer}
                    displayError={displayError}
                />
            </CardLine>
            {isIndividual ? (
                <CardLine>
                    <Input.Text
                        required
                        label="Nom"
                        id="name"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.name}
                        errors={err.name}
                        displayError={displayError}
                    />
                    <Input.Text
                        required
                        label="Nom interne"
                        id="internal_name"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.internal_name || ''}
                        errors={err.internal_name}
                        displayError={displayError}
                    />
                    <Input.Dropdown
                        required={false}
                        label="Catégorie"
                        id="membership"
                        options={Object.values(EMembershipType)}
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.membership ?? ''}
                        errors={err.membership}
                        displayError={displayError}
                    />
                </CardLine>
            ) : (
                <>
                    <CardLine>
                        <Input.Text
                            required
                            label="Nom"
                            id="name"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.name}
                            errors={err.name}
                            displayError={displayError}
                        />
                        <Input.Text
                            required={false}
                            label="Nom interne"
                            id="internal_name"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.internal_name || ''}
                            errors={err.internal_name}
                            displayError={displayError}
                        />
                    </CardLine>
                    <CardLine>
                        <Input.Siren
                            required
                            id="siren"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.siren}
                            errors={err.siren}
                            displayError={displayError}
                        />
                        <Input.Siret
                            required
                            id="siret"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.siret}
                            errors={err.siret}
                            displayError={displayError}
                        />
                        <Calendar.DayMonthYear
                            required
                            label="Date de creation"
                            id="registration"
                            readOnly={readOnly}
                            result={handleChange}
                            value={moment(val.registration).toISOString()}
                            errors={err.registration}
                            displayError={displayError}
                        />
                        <Input.Number
                            required={false}
                            label="Capital Social"
                            id="share_capital"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.share_capital ?? null}
                            errors={err.share_capital ?? null}
                            displayError={displayError}
                        />
                    </CardLine>
                    <CardLine>
                        <Input.Number
                            required={false}
                            label="CA"
                            id="revenue"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.revenue ?? null}
                            errors={err.revenue}
                            displayError={displayError}
                        />
                        <Input.Mask
                            required={false}
                            label="Année CA"
                            id="revenue_year"
                            mask="9999"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.revenue_year ?? ''}
                            errors={err.revenue_year}
                            displayError={displayError}
                        />
                        <Input.Text
                            required={false}
                            label="Status"
                            id="type"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.type ?? ''}
                            errors={err.type}
                            displayError={displayError}
                        />
                        <Input.Mask
                            required
                            label="N° TVA"
                            id="vat"
                            mask="** 99 999 999 999"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.vat}
                            errors={err.vat}
                            displayError={displayError}
                        />
                        <Input.NAF
                            required
                            id="naf"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.naf}
                            errors={err.naf}
                            displayError={displayError}
                        />
                    </CardLine>
                    <CardLine>
                        <Input.Dropdown
                            required={false}
                            label="Catégorie"
                            id="membership"
                            options={Object.values(EMembershipType)}
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.membership ?? ''}
                            errors={err.membership}
                            displayError={displayError}
                        />
                        <div>
                            <Button.Checkbox
                                label="Distributeur"
                                id="is_retailer"
                                labelPosition="right"
                                readOnly={readOnly}
                                result={handleChange}
                                value={val.is_retailer}
                            />
                        </div>
                        <div>
                            <Button.Checkbox
                                label="BDC obligatoire"
                                id="mandatory_order_sheet"
                                labelPosition="right"
                                readOnly={readOnly}
                                result={handleChange}
                                value={val.mandatory_order_sheet}
                            />
                        </div>
                        {(edit || readOnly) && !val.id_zoho_customer && (
                            <div>
                                <PRButton
                                    type="button"
                                    label="Créer le client sur Zoho"
                                    onClick={handleCreateZohoCustomer}
                                    loading={creationLoading}
                                    disabled={creationLoading}
                                    tooltip="💡 Le client est automatiquement créé sur Zoho à lors de sa première commande. Toutefois, si vous devez créer un devis avant sa première commande, vous pouvez le créer manuellement en cliquant sur ce bouton"
                                />
                            </div>
                        )}
                        <div />
                        <div />
                    </CardLine>
                    <CardLine>
                        <Input.Textarea
                            required={false}
                            label="Commentaires"
                            id="comment"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.comment ?? ''}
                            errors={err.comment}
                            displayError={displayError}
                        />
                    </CardLine>
                </>
            )}
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
