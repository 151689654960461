import { StatusCodes } from 'http-status-codes';

import { IKafkaPayload, IMSResponse } from '..';
import { EActionName, ETopicName } from './kafka';

export enum ERedisSagaState {
    IN_PROGRESS = 'IN_PROGRESS',
    FINAL_STEP = 'FINAL_STEP',
    // FORCE_REVERT = 'FORCE_REVERT',
    REVERT = 'REVERT',
    REVEAL = 'REVEAL',
    LADOUANE = 'LADOUANE',
    /*ATHANAS = 'ATHANAS',*/
    EPILOGUE = 'EPILOGUE'
}

export enum ERedisActionState {
    RUNNING = 'RUNNING',
    COMPLETED = 'COMPLETED'
}

export enum ERedisActionType {
    INDEPENDENT = 'INDEPENDENT',
    BUNDLED = 'BUNDLED'
}

export interface IRedisSaga {
    state: ERedisSagaState;
    orchestrator: ETopicName;
    method: string;
    route: string;
    query: string;
    created_at: number;
    timeout: number;
    messageCount: number;
    waitingToRevert: boolean;
}

export interface IRedisAction<RoType = any, PayloadType = IKafkaPayload> {
    action: EActionName;
    revert?: EActionName;
    revert_payload?: IKafkaPayload;
    id: string;
    parent?: string;
    next_step_id: string;
    responded_at: number;
    type: ERedisActionType;
    state: ERedisActionState;
    destination: ETopicName;
    from: ETopicName;
    expected_http_code: StatusCodes[];
    payload: PayloadType;
    response?: IMSResponse<RoType>;
    saga_response: boolean;
}

export interface IRedisActionBundle {
    id: string;
    next_step_id: string;
    actionCount: number;
}
