import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CardErrors, IContact } from '@bbng/util/types';
import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import { useFormModule } from '../../hooks/FormModule';
import { CheckboxButton } from '../../components/Button/Checkbox';
import PhoneInput from 'react-phone-input-2';
import { phoneYupValidation } from '../../components/InputPhone';
import { formatPhoneE164ToPhoneValue } from '@bbng/util/misc';

export type OrderNotificationProps = {
    readOnly?: boolean;
    value?: OrderNotificationState;
    id: string;
    result: (
        value: OrderNotificationState,
        errors: null | string[] | CardErrors<OrderNotificationState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type OrderNotificationState = {
    notifyBySms: boolean;
    contactToNotify: string;
};

export type OrderNotificationErrorState = CardErrors<OrderNotificationState>;

export const initialState: OrderNotificationState = {
    notifyBySms     : false,
    contactToNotify : ''
};
export const initialErrorState: OrderNotificationErrorState = generateInitialErrorState(initialState);

export const OrderNotification: React.FC<OrderNotificationProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: OrderNotificationProps) => {
    const { val, setVal, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    useEffect(() => {
        if (readOnly) return;
        const error = {
            ...err,
            contactToNotify:
                val.notifyBySms && !phoneYupValidation.isValidSync(formatPhoneE164ToPhoneValue(val.contactToNotify))
                    ? ['Veuillez entrer un numéro de téléphone valide.']
                    : ['']
        };
        setErr(error);
    }, [val.notifyBySms]);

    return (
        <StyledCard title="Notification">
            <FullLineInput>
                <CheckboxButton
                    id="notifyBySms"
                    label="Notification par SMS"
                    value={val.notifyBySms}
                    result={handleChange}
                    readOnly={readOnly}
                />
                {displayError && err.contactToNotify && <Errors>{err.contactToNotify}</Errors>}
                {val.notifyBySms && (
                    <div style={{ marginTop: '1em' }}>
                        <PhoneInput
                            country={'fr'}
                            specialLabel="Numéro de téléphone du client"
                            value={val.contactToNotify}
                            onChange={(value) => setVal({ ...val, contactToNotify: `+${value}` })}
                            disableDropdown={true}
                            placeholder="Numéro de téléphone du client"
                            countryCodeEditable={false}
                            disableSearchIcon={true}
                            enableAreaCodeStretch={false}
                        />
                    </div>
                )}
            </FullLineInput>
        </StyledCard>
    );
};

const Errors = styled.div`
    color: #d22020;
`;

const FullLineInput = styled.div`
    display: flex;
    flex-direction: column;
    justiy-content: space-between;
    align-items: center;
    width: 100%;
`;

const StyledCard = styled(Card)``;
