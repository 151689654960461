import Joi from 'joi';

export const ESSearchByKeyByIndexDtoSchema = Joi.object({
    key   : Joi.string(),
    value : Joi.string().required()
});

export const ESSearchByKeyDtoSchema = Joi.object({
    value: Joi.string().required()
});
