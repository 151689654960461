import React from 'react';
import styled from 'styled-components';

import { match } from '@bbng/util/misc';

import { AddressMapButton } from '../../components/AddressDisplayButton';
import { GoogleAddressAutocomplete } from '../../components/GoogleAddressAutocomplete';
import Input from '../../components/Inputs';
import { PlaceResult } from '../../context/GoogleMaps';
import { AddressState } from '../common/Address';
import { InputListBase } from './base';

export type Address = AddressState;

export type InputListAddressProps = {
    id?: string;
    title?: string;
    value?: Array<Address>;
    onChange?: (value: Array<Address>, errors: string[] | null, id: string) => void;
    readOnly?: boolean;
};

export function InputListAddress({ title = 'Address', id = 'address', ...props }: InputListAddressProps) {
    const [errors, setErrors] = React.useState<Record<string, string[]>>({});
    const isError =
        Object.keys(errors)
            .map((e) => errors[e] ?? [])
            .flat().length > 0;

    return (
        <InputListBase<Address>
            title={title}
            columns={[
                { header: 'Numéro de rue', field: 'address_street_number' },
                { header: 'Rue', field: 'address_street_name' },
                { header: 'Ville', field: 'address_city' },
                { header: 'Code postal', field: 'address_zip_code' }
            ]}
            setErrors={setErrors}
            disableModalButton={isError}
            modalTemplate={AddressModal(errors, setErrors)}
            id={id}
            {...props}
        />
    );
}

const AddressModal =
    (errors: Record<string, string[]>, setError: React.Dispatch<React.SetStateAction<Record<string, string[]>>>) =>
    (state: Address | undefined, setter: React.Dispatch<React.SetStateAction<Address | undefined>>) => {
        const handleChange = (value: string | number | null | undefined, errors: string[] | null, id: string) => {
            setter((prev) => {
                const newState: any = { ...prev, [id]: value };
                return newState;
            });
            setError((prev) => {
                const newError: any = { ...prev, [id]: errors };
                return newError;
            });
        };

        return (
            <ModalContainer>
                <GridContainer templateColumn="1fr">
                    <GoogleAddressAutocomplete
                        autocompleteProps={{ inputStyle: { width: '100%' } }}
                        onAddressSelect={(place: PlaceResult) => {
                            const entries = googleAddressComponents.map((input) => {
                                const componentName = place?.address_components?.find((f: any) =>
                                    f.types?.includes(input.component)
                                )?.long_name;

                                const defaultValue = state ? state[input.key] : '';
                                return [input.key, (componentName ?? defaultValue).toLowerCase()];
                            });

                            const newState = Object.fromEntries(entries);

                            setter((prev) => ({
                                ...prev,
                                ...newState,
                                address_google : place,
                                address_lat    : place.geometry?.location?.lat(),
                                address_lng    : place.geometry?.location?.lng()
                            }));
                        }}
                    />
                </GridContainer>
                <GridContainer templateColumn="1fr 1fr">
                    <Input.Text
                        id="address_street_number"
                        label="Numéro de rue"
                        required={true}
                        result={handleChange}
                        errors={errors['address_street_number']}
                        value={state?.address_street_number ?? ''}
                    />
                    <Input.Text
                        id="address_street_name"
                        label="Nom de rue"
                        required={true}
                        result={handleChange}
                        errors={errors['address_street_name']}
                        value={state?.address_street_name ?? ''}
                    />
                    <Input.Text
                        id="address_city"
                        label="Ville"
                        required={true}
                        result={handleChange}
                        errors={errors['address_city']}
                        value={state?.address_city ?? ''}
                    />
                    <Input.Text
                        id="address_zip_code"
                        label="Code Postal"
                        required={true}
                        result={handleChange}
                        errors={errors['address_zip_code']}
                        value={state?.address_zip_code ?? ''}
                    />
                    <Input.Text
                        id="address_country"
                        label="Pays"
                        required={true}
                        result={handleChange}
                        errors={errors['address_country']}
                        value={state?.address_country ?? ''}
                    />
                    <Input.Text
                        id="address_complement"
                        label="Complement d'adresse"
                        required={false}
                        result={handleChange}
                        errors={errors['address_complement']}
                        value={state?.address_complement ?? ''}
                    />
                </GridContainer>
                <GridContainer templateColumn="1fr auto 1fr">
                    <Input.Number
                        id="address_lng"
                        label="Longitude"
                        required={true}
                        result={handleChange}
                        errors={errors['address_lng']}
                        value={state?.address_lng ?? null}
                    />
                    <AddressMapButton
                        draggable
                        lat={state?.address_lat ?? 0}
                        lng={state?.address_lng ?? 0}
                        disable={!state || !state.address_lat || !state.address_lng}
                        onDragChange={(lat: number, lng: number) => {
                            setter((prev) => {
                                const newState: any = { ...prev, address_lat: lat, address_lng: lng };
                                return newState;
                            });
                        }}
                    />
                    <Input.Number
                        id="address_lat"
                        label="Latitude"
                        required={true}
                        result={handleChange}
                        errors={errors['address_lat']}
                        value={state?.address_lat ?? null}
                    />
                </GridContainer>
            </ModalContainer>
        );
    };

export const googleAddressComponents = [
    { key: 'address_street_number', component: 'street_number' },
    { key: 'address_street_name', component: 'route' },
    { key: 'address_city', component: 'locality' },
    { key: 'address_zip_code', component: 'postal_code' },
    { key: 'address_country', component: 'country' }
] as const;

const GridContainer = styled.div<{ templateColumn: string }>`
    display: grid;
    grid-template-columns: ${(props) => props.templateColumn};
    gap: 25px;
`;

const ModalContainer = styled.div`
    & .p-component,
    & .react-tel-input {
        width: 100%;
    }
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
`;
