import React from 'react';

export type SetStorageAction<T> = (v: T | ((e: T) => T)) => void;

function useLocalStorage<T>(key: string, defaultValue: T | (() => T)): [T, SetStorageAction<T>] {
    const [storedValue, setStoredValue] = React.useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : defaultValue instanceof Function ? defaultValue() : defaultValue;
        } catch (error) {
            console.error(error);
            return defaultValue instanceof Function ? defaultValue() : defaultValue;
        }
    });

    const setValue = React.useCallback(
        (value: T | ((prev: T) => T)) => {
            try {
                const valueToStore = value instanceof Function ? value(storedValue) : value;
                setStoredValue(valueToStore);
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                console.error(error);
            }
        },
        [key, storedValue]
    );

    return [storedValue, setValue];
}

export default useLocalStorage;
