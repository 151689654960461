import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { convertHHMMToDate } from '@bbng/util/misc';
import { CardErrors } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Calendar from '../../components/Calendar';
import { Card } from '../../components/Card';
import Input, { textInputProps } from '../../components/Inputs';
import { useFormModule } from '../../hooks/FormModule';

export type CollectConfigMiscProps = {
    edit?: boolean;
    readOnly?: boolean;
    value?: CollectConfigMiscState;
    id: string;
    result: (
        value: CollectConfigMiscState,
        errors: null | string[] | CardErrors<CollectConfigMiscState>,
        id: string
    ) => void;
    displayError?: boolean;
    showExecutionTimeMinutes?: boolean;
    showTitle?: boolean;
};

export type CollectConfigMiscState = {
    title: string;
    comment: string;
    execution_time_minutes: string | Date;
};

export type CollectConfigMiscErrorState = CardErrors<CollectConfigMiscState>;

export const initialState: CollectConfigMiscState = {
    title                  : '',
    comment                : '',
    execution_time_minutes : convertHHMMToDate('00:10')
};

export const initialErrorState: CollectConfigMiscErrorState = generateInitialErrorState(initialState);

export const initialRef = Array.from(Array(Object.keys(initialState).length)).map(() =>
    React.createRef<textInputProps>()
);

export const CollectConfigMisc = ({
    edit,
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false,
    showExecutionTimeMinutes = false,
    showTitle = false
}: CollectConfigMiscProps) => {
    const { val, setVal, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    return (
        <StyledCard title="Informations diverses">
            <CardLine>
                {showTitle && (
                    <Input.Text
                        required={!edit}
                        label="Titre"
                        id="title"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.title}
                        errors={err.title}
                        displayError={displayError}
                    />
                )}
                {showExecutionTimeMinutes && (
                    <Calendar.HoursMins
                        required={true}
                        id="execution_time_minutes"
                        label="Durée d'exécution"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.execution_time_minutes}
                        errors={err.execution_time_minutes}
                        displayError={displayError}
                        stepMinute={15}
                        validationSchema={yup.date().test({
                            name    : 'execution_time_minutes',
                            message : "Le temps d'éxecution doit être de 1 minute minimum",
                            test    : (value) => {
                                const hours = moment(value).hours();
                                const minutes = moment(value).minutes();
                                const totalMinutes = minutes + hours * 60;
                                return totalMinutes >= 1;
                            }
                        })}
                        defaultTime={convertHHMMToDate('00:10')}
                    />
                )}
            </CardLine>
            <FullLineInput>
                <Input.Textarea
                    required={false}
                    label="Commentaires"
                    id="comment"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.comment}
                    errors={err.comment}
                    displayError={displayError}
                />
            </FullLineInput>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;

const FullLineInput = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
`;
