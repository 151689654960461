import moment from 'moment';
import { nanoid } from 'nanoid';
import { AccordionTab } from 'primereact/accordion';
import { Button as PRButton } from 'primereact/button';
import { ContextMenu } from 'primereact/contextmenu';
import { MenuItem } from 'primereact/menuitem';
import { useCallback, useEffect, useRef, useState } from 'react';

import { convertHHMMToDate, getVolumeFromProducts, mergeDates } from '@bbng/util/misc';
import {
    CC_FAMILY,
    EPlanningType,
    LandfillRo,
    PlanningShiftStepCategory,
    CCRo,
    PlanningShiftStepManualEmptying,
    CollectRo,
    isCCService
} from '@bbng/util/types';

import Button from '../../../../components/Button';
import Calendar from '../../../../components/Calendar';
import { CalendarHoursMin } from '../../../../components/Calendar/Hours&Min';
import { Dialog } from '../../../../components/Dialog';
import Input from '../../../../components/Inputs';
import { CardLine } from '../../../collect-configs-unassigned/style';
import RelationAutocomplete from '../../../common/RelationAutocomplete';
import { associateColorToStep } from '../helpers';
import { ShiftHeaderState } from '../shiftHeader';
import {
    DumpsterToTakeTitle,
    EmptyingCardContainer,
    EmptyingModalContainer,
    FilterHeader,
    FiltersContainer,
    FlexRow,
    SourceHeaderContainer,
    StyledAccordion,
    TargetHeaderContainer,
    TimeWithLabel
} from './style';
import { usePlanningV2Store } from '../../../../hooks/PlanningStoreV2';

export const isCC = (cc: CCRo | PlanningShiftStepManualEmptying): cc is CCRo => 'family' in cc;
export type CCOrManualEmptying = (CCRo | PlanningShiftStepManualEmptying) & {
    collect?: CollectRo;
    splitted_idx?: number;
    is_splitted?: boolean;
};

export type SourceHeaderTemplateProps = {
    localUnselectedCCs: CCOrManualEmptying[];
    setLocalFilteredUnselectedCCs: (value: React.SetStateAction<CCOrManualEmptying[]>) => void;
    dialogIsVisible: boolean;
    planningState: ShiftHeaderState;
};
export const SourceHeaderTemplate: React.FC<SourceHeaderTemplateProps> = ({
    localUnselectedCCs,
    setLocalFilteredUnselectedCCs,
    dialogIsVisible,
    planningState
}: SourceHeaderTemplateProps) => {
    const [customerId, setCustomerId] = useState<string | undefined>();
    const [startSlot, setStartSlot] = useState<Date | undefined>(moment(planningState.start_date).toDate());
    const [endSlot, setEndSlot] = useState<Date | undefined>(moment(planningState.end_date).toDate());
    const [showAdmin, setShowAdmin] = useState<boolean>(true);
    const [postalCode, setPostalCode] = useState<string>('');

    const { planningPageState } = usePlanningV2Store();

    useEffect(() => {
        const filteredCCs = localUnselectedCCs
            .filter((cc) => {
                if (!isCC(cc)) return true;
                if (!customerId) return true;
                if (isCCService(cc)) {
                    return (
                        cc.customer_id[0] === customerId ||
                        (typeof cc.customer_id[0] === 'object' && cc.customer_id[0] === customerId)
                    );
                }
                return isCCService(cc) ? cc.customer_id[0] === customerId : true;
            })
            .filter((cc) => {
                if (!isCC(cc)) return true;
                if (!startSlot || !endSlot) return true;
                const filterStart = mergeDates(planningPageState.day, startSlot);
                const filterEnd = mergeDates(planningPageState.day, endSlot);
                return moment(filterStart).isBefore(cc.to_date) && moment(filterEnd).isAfter(cc.from_date);
            })
            .filter((cc) => {
                if (!isCC(cc)) return true;
                if (showAdmin) return true;
                return cc.family !== CC_FAMILY.ADMINISTRATIVE;
            })
            .filter((cc) => {
                if (!isCC(cc)) return true;
                if (!postalCode) return true;
                return cc.address.components['postal_code'].includes(postalCode);
            });
        setLocalFilteredUnselectedCCs(filteredCCs);
    }, [customerId, startSlot, endSlot, showAdmin, postalCode, localUnselectedCCs, planningPageState]);

    /**
     * Reset filters each time dialog is opened
     */
    useEffect(() => {
        setCustomerId(undefined);
        setStartSlot(moment(planningState.start_date).toDate());
        setEndSlot(moment(planningState.end_date).toDate());
    }, [dialogIsVisible]);

    return (
        <SourceHeaderContainer>
            <span>Non assignées ({localUnselectedCCs.length})</span>
            <StyledAccordion>
                <AccordionTab header={<FilterHeader>Filtres</FilterHeader>}>
                    <FiltersContainer>
                        <TimeWithLabel>
                            <span>De</span>
                            <CalendarHoursMin
                                id="startSlot"
                                required={false}
                                value={startSlot}
                                result={(value) => setStartSlot(value)}
                                readOnly={false}
                            />
                        </TimeWithLabel>
                        <TimeWithLabel>
                            <span>à</span>
                            <CalendarHoursMin
                                id="endSlot"
                                required={false}
                                value={endSlot}
                                result={(value) => setEndSlot(value)}
                                readOnly={false}
                            />
                        </TimeWithLabel>
                        <RelationAutocomplete.Customer
                            placeholder="Filtrer par client"
                            onSelect={(customer) => setCustomerId(customer?.id)}
                            onUnselect={() => setCustomerId(undefined)}
                        />
                        <Input.Text
                            label="Code postal"
                            id="postalCode"
                            value={postalCode}
                            result={(value) => setPostalCode(value)}
                            required={false}
                            errors={[]}
                        />
                        <Button.Checkbox
                            label="Afficher les admin"
                            id="showAdmin"
                            value={showAdmin}
                            result={(value) => setShowAdmin(value)}
                        />
                    </FiltersContainer>
                </AccordionTab>
            </StyledAccordion>
        </SourceHeaderContainer>
    );
};

type TargetHeaderTemplateProps = {
    setLocalSelectedCCAndEmptyings: React.Dispatch<React.SetStateAction<CCOrManualEmptying[]>>;
    localSelectedCCAndEmptyings: CCOrManualEmptying[];
};
export const TargetHeaderTemplate = ({
    setLocalSelectedCCAndEmptyings,
    localSelectedCCAndEmptyings
}: TargetHeaderTemplateProps) => {
    const [emptyingModalIsVisible, setEmptyingModalIsVisible] = useState<boolean>(false);
    const [selectedLandfill, setSelectedLandfill] = useState<LandfillRo>();
    const [duration, setDuration] = useState<Date>(convertHHMMToDate('00:30'));
    const [nb8M3, setNb8M3] = useState<number>(0);
    const [nb15M3, setNb15M3] = useState<number>(0);
    const [nb20M3, setNb20M3] = useState<number>(0);
    const [nb30M3, setNb30M3] = useState<number>(0);
    const { planningPageState } = usePlanningV2Store();

    /**
     * Reset inputs each time modal is opened
     */
    useEffect(() => {
        if (emptyingModalIsVisible) {
            setSelectedLandfill(undefined);
            setDuration(convertHHMMToDate('00:30'));
            setNb8M3(0);
            setNb15M3(0);
            setNb20M3(0);
            setNb30M3(0);
        }
    }, [emptyingModalIsVisible]);

    const handleAddEmptying = useCallback(() => {
        if (!selectedLandfill) return;
        const step: PlanningShiftStepManualEmptying = {
            id          : nanoid(),
            landfill_id : selectedLandfill.id,
            category    : PlanningShiftStepCategory.EMPTYING,
            ...(planningPageState.type === EPlanningType.DUMPSTER && {
                dumpster_to_take: {
                    nb_8m3  : nb8M3,
                    nb_15m3 : nb15M3,
                    nb_20m3 : nb20M3,
                    nb_30m3 : nb30M3
                }
            }),
            scheduled_service_time: moment(duration).format('HH:mm')
        };
        setLocalSelectedCCAndEmptyings((current) => {
            return [...current.filter((c) => c.id !== step.id), step];
        });
        setEmptyingModalIsVisible(false);
    }, [selectedLandfill, setLocalSelectedCCAndEmptyings, nb8M3, nb15M3, nb20M3, nb30M3, duration, planningPageState]);

    return (
        <TargetHeaderContainer>
            <span>
                À assigner ({localSelectedCCAndEmptyings.filter((cc) => isCC(cc)).length} +{' '}
                {localSelectedCCAndEmptyings.filter((cc) => !isCC(cc)).length} vidage(s))
            </span>
            <PRButton
                type="button"
                label="Ajouter un vidage"
                icon="pi pi-plus"
                className="p-button-text"
                onClick={() => setEmptyingModalIsVisible(true)}
            />
            <Dialog
                header="Ajout d'un vidage"
                visible={emptyingModalIsVisible}
                onHide={() => setEmptyingModalIsVisible(false)}
            >
                <EmptyingModalContainer>
                    <FlexRow>
                        <RelationAutocomplete.Landfill
                            region={planningPageState.region}
                            type={planningPageState.type}
                            id="landfill"
                            label="Exutoire"
                            readOnly={false}
                            errors={[]}
                            baseValue={selectedLandfill}
                            onSelect={(value) => setSelectedLandfill(value)}
                            onUnselect={() => setSelectedLandfill(undefined)}
                        />
                        <TimeWithLabel>
                            <span>Durée</span>
                            <Calendar.HoursMins
                                required={true}
                                id="duration"
                                readOnly={false}
                                result={(val) => setDuration(val)}
                                value={duration}
                                errors={[]}
                                stepMinute={15}
                            />
                        </TimeWithLabel>
                    </FlexRow>
                    {planningPageState.type === EPlanningType.DUMPSTER && (
                        <>
                            <DumpsterToTakeTitle>Bennes à prendre</DumpsterToTakeTitle>
                            <FlexRow>
                                <Input.Number
                                    id="8m3"
                                    label="8m3"
                                    result={(val) => val && setNb8M3(val)}
                                    min={0}
                                    required={false}
                                    value={nb8M3}
                                    errors={[]}
                                />
                                <Input.Number
                                    id="15m3"
                                    label="15m3"
                                    result={(val) => val && setNb15M3(val)}
                                    min={0}
                                    required={false}
                                    value={nb15M3}
                                    errors={[]}
                                />
                                <Input.Number
                                    id="20m3"
                                    label="20m3"
                                    result={(val) => val && setNb20M3(val)}
                                    min={0}
                                    required={false}
                                    value={nb20M3}
                                    errors={[]}
                                />
                                <Input.Number
                                    id="30m3"
                                    label="30m3"
                                    result={(val) => val && setNb30M3(val)}
                                    min={0}
                                    required={false}
                                    value={nb30M3}
                                    errors={[]}
                                />
                            </FlexRow>
                        </>
                    )}
                </EmptyingModalContainer>
                <PRButton
                    disabled={!selectedLandfill}
                    type="button"
                    label="Ajouter"
                    icon="pi pi-check"
                    onClick={handleAddEmptying}
                />
            </Dialog>
        </TargetHeaderContainer>
    );
};

type EmptyingCardProps = {
    emptying: PlanningShiftStepManualEmptying;
    setLocalSelectedCCAndEmptyings: React.Dispatch<React.SetStateAction<CCOrManualEmptying[]>>;
    localSelectedCCAndEmptyings: CCOrManualEmptying[];
    borderColor?: string;
};
export const EmptyingCard: React.FC<EmptyingCardProps> = ({
    emptying,
    setLocalSelectedCCAndEmptyings,
    localSelectedCCAndEmptyings,
    borderColor
}: EmptyingCardProps) => {
    const { landfills } = usePlanningV2Store();
    const landfill = landfills[emptying.landfill_id];
    const contextMenuRef = useRef<ContextMenu>(null);
    const items: MenuItem[] = [
        {
            label   : 'Supprimer',
            icon    : 'pi pi-trash',
            command : () => {
                setLocalSelectedCCAndEmptyings((current) => current.filter((c) => c.id !== emptying.id));
            }
        }
    ];

    const calculateVolumeBeforeEmptying = useCallback(() => {
        const emptyingsIdx: { cc: CCOrManualEmptying; idx: number }[] = localSelectedCCAndEmptyings
            .map((cc, idx) => ({ cc, idx }))
            .filter((cc) => !isCC(cc.cc));
        const ccsVolumeBeforeEmptying = localSelectedCCAndEmptyings
            .filter((cc, idx) => {
                const currentEmptyingGlobalIdx = emptyingsIdx.find((idx) => idx.cc.id === emptying.id)?.idx || 0;
                const currentEmptyingLocalIdx = emptyingsIdx.findIndex((idx) => idx.cc.id === emptying.id) || 0;
                const previousEmptyingGlobalIdx =
                    emptyingsIdx.length <= 1 ? 0 : emptyingsIdx[currentEmptyingLocalIdx - 1]?.idx;
                /**
                 * We keep only the CCs that are between the previous emptying and the current emptying
                 * If there is no previous emptying, we keep all the CCs before the current emptying
                 */
                if (
                    isCC(cc) &&
                    (previousEmptyingGlobalIdx === undefined || idx >= previousEmptyingGlobalIdx) &&
                    idx < currentEmptyingGlobalIdx
                ) {
                    return true;
                }
                return false;
            })
            .map((cc) => {
                if (!isCC(cc) || !isCCService(cc)) return 0;
                const products =
                    cc.is_splitted && cc.splitted_idx !== undefined
                        ? cc.splitted_informations[cc.splitted_idx].products
                        : cc.products;
                return getVolumeFromProducts(products, cc.family);
            })
            .reduce((acc, val) => acc + val, 0);
        return ccsVolumeBeforeEmptying;
    }, [localSelectedCCAndEmptyings, emptying]);

    const { planningPageState } = usePlanningV2Store();

    return (
        <span onContextMenu={(e) => contextMenuRef?.current?.show(e)}>
            <EmptyingCardContainer color={associateColorToStep(emptying.category)} borderColor={borderColor}>
                <CardLine weight={200}>
                    <span>
                        Vidage à {landfill.name}{' '}
                        {planningPageState.type === EPlanningType.BIG_BAG && `de ${calculateVolumeBeforeEmptying()}m³`}{' '}
                        ({emptying.scheduled_service_time})
                    </span>
                </CardLine>
                {emptying.dumpster_to_take && (
                    <>
                        <FlexRow>
                            <CardLine weight={600}>Bennes à prendre</CardLine>
                            {emptying.dumpster_to_take.nb_8m3 > 0 && (
                                <span>{emptying.dumpster_to_take.nb_8m3} x 8m³</span>
                            )}
                            {emptying.dumpster_to_take.nb_15m3 > 0 && (
                                <span>{emptying.dumpster_to_take.nb_15m3} x 15m³</span>
                            )}
                            {emptying.dumpster_to_take.nb_20m3 > 0 && (
                                <span>{emptying.dumpster_to_take.nb_20m3} x 20m³</span>
                            )}
                            {emptying.dumpster_to_take.nb_30m3 > 0 && (
                                <span>{emptying.dumpster_to_take.nb_30m3} x 30m³</span>
                            )}
                            {emptying.dumpster_to_take.nb_8m3 +
                                emptying.dumpster_to_take.nb_15m3 +
                                emptying.dumpster_to_take.nb_20m3 +
                                emptying.dumpster_to_take.nb_30m3 ===
                                0 && <span>Aucune</span>}
                        </FlexRow>
                    </>
                )}
                <ContextMenu model={items} ref={contextMenuRef} />
            </EmptyingCardContainer>
        </span>
    );
};
