import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import { TruckRo } from '@bbng/util/types';

import { ActionsFormContainer } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import SnackBar from '../../components/SnackBar';
import { useRequest } from '../../hooks/StatelessRequest';
import { Address, AddressState } from '../../modules/common/Address';
import Truck from '../../modules/truck';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    TruckFormErrorState,
    TruckFormState,
    TruckModulesErrorStates,
    TruckModulesStates,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiCreateData,
    mapStateToApiEditData
} from './helpers';
import { PageForm, PageFormLine } from './style';
import equal from 'fast-deep-equal/react';

type TruckFormProps = {
    edit?: boolean;
    readOnly?: boolean;
};

const TruckForm: React.FC<TruckFormProps> = ({ edit = false, readOnly = false }: TruckFormProps): JSX.Element => {
    const { id: paramsId } = useParams();
    const navigate = useNavigate();
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [apiState, setApiState] = React.useState<{ form: TruckFormState; db: TruckRo }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(edit || readOnly);
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>(
        edit || readOnly ? 'read' : undefined
    );

    const [err, setErr] = useState<TruckFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<TruckFormState>['onSubmit']>(
        async (data: TruckFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            const method = edit ? 'PATCH' : 'POST';
            const url = edit ? urlApiBuilder.truckEdit(dataId) : urlApiBuilder.truckCreate();
            const body = edit
                ? mapStateToApiEditData(data, apiState!.form, apiState!.db)
                : mapStateToApiCreateData(data);
            setLoading(true);
            setOnRequestType('create');
            const response = await bbngRequest<TruckRo>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);
            setOnRequestType(undefined);
            if (response.success) {
                navigate(urlBuilder.truckList());
            }
        },
        [err, apiState, bbngRequest]
    );

    const formik = useFormik<TruckFormState>({
        initialValues : initialState,
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (
            value: TruckModulesStates | AddressState,
            errors: TruckModulesErrorStates | string[] | null,
            id: string
        ): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response = await bbngRequest<TruckRo>({
            method  : 'GET',
            url     : urlApiBuilder.truckGet(dataId),
            options : { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            const ro: TruckRo = response.response?.data.ro;
            const formikState: TruckFormState = mapApiDataToState(ro);

            setApiState({ form: formikState, db: ro });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (edit || readOnly) {
            fetchData();
        }
    }, []);

    const handleArchive = React.useCallback(() => {
        setLoading(true);
        setOnRequestType(apiState?.db.archived ? 'unarchive' : 'delete');

        bbngRequest({
            method  : 'PATCH',
            url     : urlApiBuilder.truckArchive(dataId),
            options : { toastifySuccess: false },
            payload : {
                body: {
                    archived: !apiState?.db.archived
                }
            }
        })
            .then((response) => {
                if (response.success) {
                    navigate(urlBuilder.truckList());
                }
            })
            .finally(() => {
                setLoading(false);
                setOnRequestType(undefined);
            });
    }, [bbngRequest, setLoading, setOnRequestType, apiState]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (errorCode) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.truckList()} />
        ) : (
            <Error500 />
        );
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <h1>Camion{edit || readOnly ? `: ${apiState?.form?.general.label}` : ''}</h1>
            <ActionsFormContainer
                archived={apiState?.db.archived || false}
                edit={edit}
                readOnly={readOnly}
                // disableSaveOnEdit={JSON.stringify(apiState?.form) === JSON.stringify(formik.values)}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                handleArchiveOnView={handleArchive}
                editPageUrl={urlBuilder.truckEdit(dataId)}
                historyUrl={urlApiBuilder.truckHistory(dataId)}
            />
            <PageFormLine>
                <Truck.GeneralInfo
                    id="general"
                    result={handleChange}
                    value={formik.values.general}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            <PageFormLine>
                <Truck.Characteristics
                    id="characteristics"
                    result={handleChange}
                    value={formik.values.characteristics}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                    tags={formik.values.general.tag}
                />
            </PageFormLine>
            <PageFormLine>
                <Address
                    displayError={formik.submitCount > 0}
                    readOnly={readOnly}
                    id="address"
                    result={handleChange}
                    value={formik.values.address}
                    title="Adresse du garage"
                />
            </PageFormLine>
            {readOnly === false && (
                <Button
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        // (JSON.stringify(apiState?.form) === JSON.stringify(formik.values) && edit)
                        (equal(apiState?.form, formik.values) && edit)
                    }
                />
            )}
            {edit === true && (
                <SnackBar
                    // show={JSON.stringify(apiState?.form) !== JSON.stringify(formik.values)}
                    show={equal(apiState?.form, formik.values) === false}
                    content="Vous avez des modifications non enregistrées"
                />
            )}
        </PageForm>
    );
};

export default TruckForm;
