import styled from 'styled-components';

export type FilterDividerProps = {
    direction: 'row' | 'column';
};

export const FilterDivider: React.FC<FilterDividerProps> = ({ direction = 'row' }: FilterDividerProps) => {
    return (
        <Container direction={direction}>
            <Line direction={direction} />
            <p>ET</p>
            <Line direction={direction} />
        </Container>
    );
};

const Container = styled.div<{ direction: 'row' | 'column' }>`
    display: flex;
    align-items: center;
    gap: 8px;
    color: #a8a8a8;
    flex-direction: ${({ direction }) => direction};
`;

const Line = styled.span<{ direction: 'row' | 'column' }>`
    width: ${({ direction }) => (direction === 'row' ? '100%' : '1px')};
    height: ${({ direction }) => (direction === 'column' ? '100%' : '1px')};
    background-color: #d3d3d3;
    color: #d3d3d3;
`;
