import { PhoneNumberUtil } from 'google-libphonenumber';
import React from 'react';
import Phone, { PhoneInputProps, CountryData as RPICountryData } from 'react-phone-input-2';
import styled from 'styled-components';
import * as yup from 'yup';

import { PhoneValue } from '@bbng/util/types';

export type InputPhoneProps = Omit<PhoneInputProps, 'onChange' | 'value'> & {
    value?: PhoneValue | null;
    onChange?: (value: PhoneValue) => void;
};

const StyledPhone = styled(Phone)`
    .form-control {
        padding: 0;
        padding-left: 58px;
        height: 39.5px;
        width: 100%;
        :hover,
        :focus {
            border: 1px solid var(--primary-color);
        }
        :focus {
            box-shadow: 0 0 0 0.2rem #c7d2fe;
        }
    }
`;

const InputPhone: React.FC<InputPhoneProps> = ({ value, onChange, ...props }: InputPhoneProps) => {
    const handleChange = React.useCallback(
        (phone: string, data: RPICountryData) => {
            if (!data.countryCode || !onChange) return;

            const phoneValue: PhoneValue = {
                countryCode  : data.countryCode.toUpperCase() as PhoneValue['countryCode'],
                dialCode     : data.dialCode,
                name         : data.name as PhoneValue['name'],
                phone_number : phone.replace(data.dialCode, '')
            };

            onChange(phoneValue);
        },
        [onChange]
    );

    return (
        <StyledPhone
            country="fr"
            inputClass="p-inputtext p-intputtext-sm p-component custom-input-text "
            enableSearch
            countryCodeEditable={false}
            {...props}
            {...(value ? { value: value.dialCode + value.phone_number } : { value: '33' })}
            onChange={handleChange}
        />
    );
};

export default InputPhone;

export const phoneYupValidation = yup
    .object({
        countryCode  : yup.string().required(),
        dialCode     : yup.string().required(),
        name         : yup.string().required(),
        phone_number : yup
            .string()
            .required()
            .test('Number', 'Vous devez rentrer un numéro valide', function (phone?: string) {
                const countryCode = this.parent.countryCode;
                const dialCode = this.parent.dialCode;
                if (!countryCode || !dialCode || !phone) return false;

                const instance: PhoneNumberUtil = PhoneNumberUtil.getInstance();
                const number = instance.parse(phone, countryCode);
                return instance.isValidNumberForRegion(number, countryCode);
            })
    })
    .nullable();
