import { formatPhoneE164ToPhoneValue, formatPhoneNumberToE164 } from '@bbng/util/misc';
import { PrestaCreateDto, PrestaEditDto, PrestaPrice, PrestaRoFront } from '@bbng/util/types';

import { getRelationsDto, optimiseEditDto } from '../../common/form';
import {
    PrestaGeneralErrorState,
    PrestaGeneralState,
    initialErrorState as prestaGeneralInitialErrorState,
    initialState as prestaGeneralInitialState
} from '../../modules/presta/General';
import {
    AddressErrorState,
    AddressState,
    initialErrorState as addressInitialErrorState,
    initialState as addressInitialState
} from '../../modules/common/Address';
import {
    PrestaPriceListErrorState,
    PrestaPriceListState,
    initialErrorState as prestaPriceListInitialErrorState,
    initialState as prestaPriceListInitialState
} from '../../modules/presta/PriceList';

export type PrestaModulesStates = PrestaGeneralState | AddressState;

export type PrestaModulesErrorStates = PrestaGeneralErrorState | AddressErrorState;

export type PrestaFormState = {
    general: PrestaGeneralState;
    headquarter: AddressState;
    priceList: PrestaPriceListState;
};

export type PrestaFormErrorState = {
    general: PrestaGeneralErrorState;
    headquarter: AddressErrorState;
    priceList: PrestaPriceListErrorState;
};

export const initialState: PrestaFormState = {
    general     : prestaGeneralInitialState,
    headquarter : addressInitialState,
    priceList   : prestaPriceListInitialState
};

export const initialErrorState: PrestaFormErrorState = {
    general     : prestaGeneralInitialErrorState,
    headquarter : addressInitialErrorState,
    priceList   : prestaPriceListInitialErrorState
};

const convertEuroToCentime = (price: number) => {
    const value = price * 100;

    return Number(value.toFixed(0));
};

const convertCentimeToEuro = (price: number) => {
    const value = price / 100;

    return Number(value.toFixed(2));
};

export const mapApiDataToState = (presta: PrestaRoFront): PrestaFormState => {
    return {
        general: {
            name         : presta.name,
            email        : presta.email,
            phone_number : formatPhoneE164ToPhoneValue(presta.phone_number),
            siret        : presta.siret
        },
        headquarter: {
            address_google        : undefined,
            address_street_number : presta.headquarter.components['street_number'],
            address_street_name   : presta.headquarter.components['route'],
            address_city          : presta.headquarter.components['locality'],
            address_zip_code      : presta.headquarter.components['postal_code'],
            address_country       : presta.headquarter.components['country'],
            address_complement    : presta.headquarter.components['complement'] ?? '',
            address_lat           : presta.headquarter.coordinates.latitude,
            address_lng           : presta.headquarter.coordinates.longitude
        },
        priceList: {
            priceList: presta.price_list.map((price) => ({
                value      : convertCentimeToEuro(price.price_in_centime), // Convert to euros
                product_id : typeof price.product_id === 'object' ? price.product_id : undefined
            }))
        }
    };
};

export const mapStateToApiCreateData = (state: PrestaFormState): PrestaCreateDto => {
    return {
        email        : state.general.email,
        name         : state.general.name,
        siret        : state.general.siret,
        phone_number : formatPhoneNumberToE164(state.general.phone_number!),
        headquarter  : {
            coordinates: {
                latitude  : state.headquarter.address_lat!,
                longitude : state.headquarter.address_lng!
            },
            name           : (state.headquarter.address_street_name || state.headquarter.address_google?.name) ?? '',
            formatted_name :
                (state.headquarter.address_google?.formatted_address ||
                    state.headquarter.address_street_name ||
                    state.headquarter.address_google?.name) ??
                '',
            components: {
                street_number : state.headquarter.address_street_number,
                route         : state.headquarter.address_street_name,
                locality      : state.headquarter.address_city,
                postal_code   : state.headquarter.address_zip_code,
                country       : state.headquarter.address_country,
                complement    : state.headquarter.address_complement
            }
        },
        price_list: state.priceList.priceList
            .map((price) =>
                price.product_id
                    ? {
                          product_id       : price.product_id.id,
                          price_in_centime : convertEuroToCentime(price.value) // Convert to cents
                      }
                    : null
            )
            .filter((f) => f !== null) as PrestaPrice[]
    };
};

export const mapStateToApiEditData = (
    state: PrestaFormState,
    apiState: PrestaFormState,
    presta: PrestaRoFront
): PrestaEditDto => {
    const body = optimiseEditDto(state, apiState, presta, mapStateToApiCreateData, getRelationsDto<PrestaRoFront>());

    return {
        ...body
    };
};
