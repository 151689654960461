import React from 'react';

import { match } from '@bbng/util/misc';
import { CardErrors } from '@bbng/util/types';

export type FormModuleProps<State, Error = CardErrors<State>> = {
    id: string;

    initialValue: State;
    initialError: Error;

    result: (value: State, errors: null | string[] | Error, id: string) => void;
};

export type FormModuleState<State, Error = CardErrors<State>> = {
    val: State;
    setVal: React.Dispatch<React.SetStateAction<State>>;
    err: Error;
    setErr: React.Dispatch<React.SetStateAction<Error>>;
    handleChange: (value: any, errors: string[] | null, childId: string) => void;
};

export function useFormModule<State, Error = CardErrors<State>>(
    props: FormModuleProps<State, Error>
): FormModuleState<State, Error> {
    const [val, setVal] = React.useState<State>(props.initialValue);
    const [err, setErr] = React.useState<Error>(props.initialError);

    React.useEffect(() => {
        if (match(props.initialValue, val) === false) {
            setVal(props.initialValue);
        }
    }, [props.initialValue]);

    React.useEffect(() => {
        props.result(val, err, props.id);
    }, [val, err]);

    const handleChange = React.useCallback(
        (value: any, errors: string[] | null, childId: string) => {
            setVal((prev) => ({ ...prev, [childId]: value }));
            setErr((prev) => ({ ...prev, [childId]: errors }));
        },
        [setVal, setErr]
    );

    return { val, setVal, err, setErr, handleChange };
}
