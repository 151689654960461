import Joi from 'joi';

import { AddressCreateSchema, AddressEditSchema } from '../address';
import { ContactDtoSchema } from '../contact';
import {
    CloseDayDtoSchema,
    OpeningTimeDtoSchema,
    PhoneE164Schema,
    SirenSchema,
    SiretSchema,
    TrashDetailsDtoSchema
} from '../misc.schema';

import { ETrashType } from '../misc';

export const LandfillInventorySchema = Joi.object({
    nb_8m3_dumpster  : Joi.number().integer().min(0).required(),
    nb_15m3_dumpster : Joi.number().integer().min(0).required(),
    nb_20m3_dumpster : Joi.number().integer().min(0).required(),
    nb_30m3_dumpster : Joi.number().integer().min(0).required()
});

const LandfillRecyclingCharacteristicsSchema = Joi.object({
    ETrashType: {
        reusage                : Joi.number().integer().min(0).required(),
        recycling              : Joi.number().integer().min(0).required(),
        energetic_valorisation : Joi.number().integer().min(0).required(),
        elimination            : Joi.number().integer().min(0).required(),
        global_valorisation    : Joi.number().integer().min(0).required()
    }
});

export const LandfillCreateDtoSchema = Joi.object({
    test_name               : Joi.string(),
    name                    : Joi.string().required(),
    code_name               : Joi.string().allow(''),
    siren                   : SirenSchema.allow(null),
    siret                   : SiretSchema.allow(null),
    accept_bigbag           : Joi.boolean().required(),
    accept_dumpster         : Joi.boolean().required(),
    phone_number            : PhoneE164Schema.required(),
    fax_number              : PhoneE164Schema.allow(null),
    additional_info         : Joi.string().allow(null, ''),
    contact                 : Joi.array().items(ContactDtoSchema).min(1),
    opening_time            : OpeningTimeDtoSchema.required(),
    exceptional_closure     : Joi.array().items(CloseDayDtoSchema),
    maximum_daily_volume_m3 : Joi.number(),
    address                 : AddressCreateSchema.required(),
    trash_details           : Joi.array().items(TrashDetailsDtoSchema).required(),
    average_waiting_minutes : Joi.number().integer().min(1).required(),
    owner_is_endless        : Joi.boolean().required(),
    inventory               : Joi.when('owner_is_endless', {
        is        : true,
        then      : LandfillInventorySchema.required(),
        otherwise : Joi.forbidden()
    }),
    recycling_characteristics: LandfillRecyclingCharacteristicsSchema.optional()
});

export const LandfillEditDtoSchema = Joi.object({
    test_name               : Joi.string(),
    name                    : Joi.string(),
    code_name               : Joi.string().allow(''),
    siren                   : SirenSchema.allow(null),
    siret                   : SiretSchema.allow(null),
    accept_bigbag           : Joi.boolean(),
    accept_dumpster         : Joi.boolean(),
    phone_number            : PhoneE164Schema.allow(null),
    fax_number              : PhoneE164Schema.allow(null),
    additional_info         : Joi.string().allow(null, ''),
    contact                 : Joi.array().items(ContactDtoSchema),
    opening_time            : OpeningTimeDtoSchema,
    exceptional_closure     : Joi.array().items(CloseDayDtoSchema),
    maximum_daily_volume_m3 : Joi.number(),
    address                 : AddressEditSchema,
    trash_details           : Joi.array().items(TrashDetailsDtoSchema),
    average_waiting_minutes : Joi.number().integer().min(1),
    owner_is_endless        : Joi.boolean(),
    inventory               : LandfillInventorySchema
});

export const RevalorisationCharacteriticsCreateDtoSchema = Joi.object({
    recycling_info: Joi.object().pattern(Object.keys(ETrashType), {
        reusage                : Joi.number().integer().min(0).required(),
        recycling              : Joi.number().integer().min(0).required(),
        energetic_valorisation : Joi.number().integer().min(0).required(),
        elimination            : Joi.number().integer().min(0).required(),
        global_valorisation    : Joi.number().integer().min(0).required()
    }),
    landfill_id : Joi.string().required(),
    from_date   : Joi.date().required(),
    to_date     : Joi.date().optional()
});

export const RevalorisationCharacteriticsEditDtoSchema = Joi.object({
    recycling_info: Joi.object()
        .pattern(Object.keys(ETrashType), {
            reusage                : Joi.number().integer().min(0).optional(),
            recycling              : Joi.number().integer().min(0).optional(),
            energetic_valorisation : Joi.number().integer().min(0).optional(),
            elimination            : Joi.number().integer().min(0).optional(),
            global_valorisation    : Joi.number().integer().min(0).optional()
        })
        .optional(),
    landfill_id : Joi.string().required(),
    from_date   : Joi.date().optional(),
    to_date     : Joi.date().optional()
});
