import Joi from 'joi';

import { generateRelationsSchema } from '../dto';
import { PhoneDtoSchema } from '../misc.schema';
import { AdminRo } from './admin';

export const AdminCreateDtoSchema = Joi.object({
    firstname             : Joi.string(),
    lastname              : Joi.string(),
    email                 : Joi.string().email({ tlds: false }).required(),
    profile_not_completed : Joi.boolean()
});

export const AdminEditDtoSchema = Joi.object({
    firstname             : Joi.string(),
    lastname              : Joi.string(),
    profile_not_completed : Joi.boolean()
});

// export const AdminEditDtoSchema = Joi.object({
//     firstname     : Joi.string(),
//     lastname      : Joi.string(),
//     fullname      : Joi.string(),
//     email         : Joi.string().email({ tlds: false }),
//     phone_number  : PhoneDtoSchema,
//     avatar        : Joi.string(),
//     admin_metadata : Joi.object({
//         service : Joi.string(),
//         city    : Joi.string()
//     })
// }).concat(generateRelationsSchema<AdminRo>('order_id', 'customer_id'));
