import { StyledButton } from './style';

const ScrollTopButton: React.FC = (): JSX.Element => {
    return (
        <StyledButton
            icon="pi pi-chevron-up"
            className="p-button-rounded p-button-secondary"
            onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
        />
    );
};

export default ScrollTopButton;
