import React, { useContext, useState } from 'react';

import { getMiddleOfTheDay } from '@bbng/util/misc';
import { PlanningRoFront, PlanningShiftStepService, PlanningUnassignCCDto } from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import { useRequest } from '../../hooks/StatelessRequest';
import { PageForm, SubmitButton } from './style';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { PlanningContext, PlanningContextType } from '../../context/Planning';

type CollectConfigCancelFormProps = {
    setModalIsOpen: (isOpen: boolean) => void;
    data: PlanningShiftStepService;
    submitCallback?: () => void;
};

export const CollectConfigUnassignForm: React.FC<CollectConfigCancelFormProps> = ({
    setModalIsOpen,
    data,
    submitCallback
}: CollectConfigCancelFormProps): JSX.Element => {
    const { planning_id } = useContext<PlanningContextType>(PlanningContext);
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [loading, setLoading] = useState<boolean>(false);

    const { plannings, getPlannings, getCCAdministrative, getCCService } = usePlanningV2Store();

    const handleSubmit = async () => {
        setLoading(true);

        const planning = plannings[planning_id];

        if (!planning) {
            setLoading(false);
            return;
        }

        const method = 'POST';
        const url = urlApiBuilder.planningUnassignedCC(planning.id);

        const response = await bbngRequest<PlanningRoFront, PlanningUnassignCCDto>({
            method,
            url,
            payload: {
                body: {
                    ccId         : data.collect_config_id,
                    day          : getMiddleOfTheDay(planning.dayISO),
                    region       : planning.region,
                    type         : planning.type,
                    is_splitted  : data.is_splitted ?? false,
                    splitted_idx : data.is_splitted ? data.splitted_idx : undefined
                }
            }
        });
        if (response.success && response.response?.data.ro) {
            /**
             * We need to fetch the plannings & ccs again to get the updated data
             */
            await Promise.all([getPlannings(bbngRequest), getCCAdministrative(bbngRequest), getCCService(bbngRequest)]);
        }
        setModalIsOpen(false);
        setLoading(false);
        submitCallback && submitCallback();
    };

    if (loading) return <PageLoader loading />;

    return (
        <PageForm>
            <SubmitButton type="submit" label="Valider" onClick={handleSubmit} />
        </PageForm>
    );
};
