import { CollectorListType } from '@bbng/util/types';
import { useMemo } from 'react';
import { usePlanningV2Store } from '../PlanningStoreV2';

export const useCollectorConstructionSiteCompatibility = (args: {
    collectorId?: string;
    constructionSiteId?: string;
}) => {
    const { collectorId = '', constructionSiteId = '' } = args;
    const { constructionSites } = usePlanningV2Store();
    const constructionSite = constructionSiteId ? constructionSites[constructionSiteId] : undefined;

    const collectorAllowedForConstructionSite = useMemo(() => {
        if (constructionSite && collectorId) {
            if (constructionSite.list_collector_type === CollectorListType.WHITE_LIST) {
                return constructionSite?.list_collector_id.includes(collectorId);
            } else {
                return !constructionSite?.list_collector_id.includes(collectorId);
            }
        }
        return true;
    }, [constructionSite, collectorId]);

    const constructionSiteRestrictionText = useMemo(() => {
        if (constructionSite) {
            return constructionSite.list_collector_type === CollectorListType.WHITE_LIST
                ? "Le chantier n'est accessible que pour certains chauffeurs."
                : 'Le chauffeur est interdit sur ce chantier';
        }
        return '';
    }, [constructionSite]);

    return {
        collectorAllowedForConstructionSite,
        constructionSiteRestrictionText
    };
};
