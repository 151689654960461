import { EDataType, ErrorContext, ErrorResponsability } from '@bbng/util/types';

import { ErrorCodeFR } from './error-translate';
import { NdlssError } from './ndlss-error';

type DtoValidationErrorConstructor = {
    httpCode?: number;
    code: keyof ErrorCodeFR[ErrorContext.DtoValidation];
    params?: Record<string, any>;
    responsability?: ErrorResponsability;
    dataType?: EDataType;
};

/**
 * @description
 * Error thrown when a DTO validation fails
 *
 * Wrapper around the {@link NdlssError} class to preset the context `ErrorContext.DtoValidation`
 *
 */
export class DtoValidationError extends NdlssError {
    override name = 'NdlssDtoValidationError';

    constructor({ httpCode, code, responsability, dataType, params = {} }: DtoValidationErrorConstructor) {
        super({
            httpCode,
            code,
            context: ErrorContext.DtoValidation,
            responsability,
            dataType,
            params
        });
    }
}
