import { PRODUCT_FAMILY } from '@bbng/util/types';

import { OrderProductsErrorState, OrderProductsState } from '.';
import { EOrderDumpsterServiceForm } from '../DumpsterService';
import { EOrderTypeForm } from '../Type';

export const getNewQuantity = (value: number | boolean | null): number => {
    if (typeof value === 'number') {
        return value;
    }
    if (typeof value === 'boolean') {
        return value ? 1 : 0;
    }
    return 0;
};

export const mapOrderTypeFormToProductFamilies = (
    type?: EOrderTypeForm,
    service?: EOrderDumpsterServiceForm
): PRODUCT_FAMILY[] => {
    switch (type) {
        case EOrderTypeForm.DELIVERY:
            return [PRODUCT_FAMILY.DELIVERY_BIG_BAG];
        case EOrderTypeForm.COLLECT_BIG_BAG:
            return [PRODUCT_FAMILY.COLLECT_BIG_BAG];
        case EOrderTypeForm.COLLECT_DUMPSTER:
            switch (service) {
                case EOrderDumpsterServiceForm.ROTATION:
                    return [PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT, PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL];
                case EOrderDumpsterServiceForm.DEPOSIT:
                    return [PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT];
                case EOrderDumpsterServiceForm.RETRIEVAL:
                    return [PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL];
                case EOrderDumpsterServiceForm.LOAD_WAIT:
                    return [PRODUCT_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT];
                // case EOrderDumpsterServiceForm.ROUND_TRIP:
                //     return [PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT, PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL];
                default:
                    return [];
            }
        default:
            return [];
    }
};

export const mapProductFamilyToOrderTypeForm = (family?: PRODUCT_FAMILY): EOrderTypeForm => {
    if (family?.startsWith(EOrderTypeForm.COLLECT_BIG_BAG)) return EOrderTypeForm.COLLECT_BIG_BAG;
    if (family?.startsWith(EOrderTypeForm.COLLECT_DUMPSTER)) return EOrderTypeForm.COLLECT_DUMPSTER;
    if (family?.startsWith(EOrderTypeForm.DELIVERY)) return EOrderTypeForm.DELIVERY;
    return 'Type inconnu' as EOrderTypeForm;
};

export const forceIntegrity = (
    data: OrderProductsState,
    id: string,
    type?: EOrderTypeForm,
    service?: EOrderDumpsterServiceForm
): OrderProductsState => {
    /**
     * Reset other collect dumpster products to 0 only if the current one is set to 1.
     * This is because once reset others to 0, this will trigger this function call again (as a child value changes).
     * And we don't want to reset the product initially set to 1 to 0 again.
     */
    if (type === EOrderTypeForm.COLLECT_DUMPSTER) {
        if (data[id].quantity === 1) {
            if (service === EOrderDumpsterServiceForm.ROTATION) {
                Object.entries(data)
                    .filter(([dataid, product]) => id !== dataid)
                    .forEach(([dataid, product]) => {
                        if (product.family === data[id].family) {
                            product.quantity = 0;
                        }
                    });
            } else {
                Object.entries(data)
                    .filter(([dataid, product]) => id !== dataid)
                    .forEach(([dataid, product]) => {
                        if (product.family === data[id].family) {
                            product.quantity = 0;
                        }
                    });
            }
        }
    }
    return data;
};

export const checkIntegrity = ({
    data,
    error,
    id,
    service,
    authorizeEmptyOrder = false
}: {
    data: OrderProductsState;
    error: OrderProductsErrorState;
    id: string;
    service?: EOrderDumpsterServiceForm;
    authorizeEmptyOrder?: boolean;
}): OrderProductsErrorState => {
    let uncompleteOrder = false;
    if (service === EOrderDumpsterServiceForm.ROTATION) {
        const uncompleteDeposit = Object.values(data)
            .filter((product) => product.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_DEPOSIT)
            .every((product) => product.quantity === 0);
        const uncompleteRetrieval = Object.values(data)
            .filter((product) => product.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL)
            .every((product) => product.quantity === 0);
        uncompleteOrder = uncompleteDeposit || uncompleteRetrieval;
    } else {
        uncompleteOrder = Object.values(data).every((product) => product.quantity === 0);
    }
    if (uncompleteOrder && !authorizeEmptyOrder) {
        error[id] = ['Au moins un produit doit être sélectionné.'];
    } else {
        Object.keys(error).forEach((key) => (error[key] = null));
    }
    return error;
};
