/* eslint-disable no-script-url */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import React from 'react';
import styled from 'styled-components';

import Draw404 from '../../../assets/images/error-404.png';
import { urlBuilder } from '../../common/urlBuilder';

export type Error404URLProps = {
    dataListUrl?: string;
};

const Error404URL: React.FC<Error404URLProps> = (props: Error404URLProps): JSX.Element => {
    return (
        <CenteredPage>
            <StyledCard>
                <div>
                    <img src={Draw404} width={404} alt="Error 404" />
                </div>
                <h2>{"Cette page n'existe pas ou a été supprimée."}</h2>
                {/* <a href={urlBuilder.dashboard()}>
                    <Button type="button">Retour à l'accueil</Button>
                </a> */}
            </StyledCard>
        </CenteredPage>
    );
};

const CenteredPage = styled.div`
    display: grid;
    place-items: center;
    width: 100%;
    height: 100vh;
`;

const StyledCard = styled(Card)`
    & .p-card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export default Error404URL;
