import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ulid } from 'ulid';
import moment from 'moment';

import {
    CCAdministrativeRo,
    CCRo,
    CCRoFront,
    CCServiceRo,
    CC_FAMILY,
    CC_STATUS,
    CollectorRo,
    CustomerRo,
    EPlanningRegion,
    EPlanningType,
    IdAndSplittedIdx,
    LandfillRo,
    PlanningManualShiftDto,
    PlanningRo,
    PlanningShiftStepAdministrative,
    PlanningShiftStepCategory,
    PlanningShiftStepEmptying,
    PlanningShiftStepManualEmptying,
    PlanningShiftStepService,
    PRODUCT_DUMPSTER_TYPE,
    TruckRo
} from '@bbng/util/types';
import { deepCopy } from '@bbng/util/misc';

import { PlanningPageState } from '../../../pages/Planning/helpers';
import { toast } from '../../../components/Toast';
import { useRequest } from '../../../hooks/StatelessRequest';
import { urlApiBuilder } from '../../../common/urlBuilder';

export const CardUnassignedId = 'unassigned';

export const CardType = {
    CC       : 'CC',
    EMPTYING : 'EMPTYING',
    SPACER   : 'SPACER'
};
export type CardType = typeof CardType[keyof typeof CardType];

export type MonitorItem<Extra = GlobalConfigCCEmptying | GlobalConfigCCRo> = {
    columnId: string;
    columnIndex: number;
    type: CardType;
    extra?: Extra;
    id: string;
};

export type GlobalConfigCCEmptying = {
    category: 'EMPTYING';
    landfill: LandfillRo;
    waitingTimeMinutes: number;
    gc_id: string;
};

export type GlobalConfigCCRo = {
    category: 'CC';
    cc: CCRoFront;
    gc_id: string;
};

export type GlobalConfigPlanning = {
    id: string;
    visible: boolean;
    collector?: CollectorRo;
    truck: TruckRo;
    startShiftAt: string;
    endShiftAt: string;
    timeline: Array<GlobalConfigCCRo | GlobalConfigCCEmptying>;
    day: string;
    region: EPlanningRegion;
    type: EPlanningType;
};

type GlobalConfigContextType = {
    /**
     * DATA
     */
    type: EPlanningType;
    plannings: GlobalConfigPlanning[];
    planningRegistry: Record<string, [GlobalConfigPlanning, number]>;
    unassignedCcs: GlobalConfigCCRo[];

    customers: Record<string, CustomerRo>;
    collectors: Record<string, CollectorRo>;

    dumpsterTypes: PRODUCT_DUMPSTER_TYPE[];
    setDumpsterTypes: (value: PRODUCT_DUMPSTER_TYPE[]) => void;

    /**
     * UTILS FUNCTIONS
     */
    formatManualShiftDto: (planningIds: string[], ignoreHere?: boolean) => PlanningManualShiftDto[];
    getAllAssignedCollectorIds: () => [string, string][];
    ccNumberExists: (ccNumber: string) => boolean;

    /**
     * DND ACTIONS
     */
    switchCard: (dragItem: MonitorItem, item: MonitorItem) => void;
    assignCard: (dragItem: MonitorItem, item: MonitorItem) => void;
    unassignedCard: (dragItem: MonitorItem) => void;

    /**
     * LANDFILL ACTIONS
     */
    addLandfillCard: (planningId: string, landfill: LandfillRo, waitingTimeMinutes: number) => void;
    updateLandfillCard: (
        landfillItem: MonitorItem<GlobalConfigCCEmptying>,
        newLandfill: LandfillRo,
        waitingTimeMinutes: number
    ) => void;
    deleteLandfillCard: (landfillItem: MonitorItem<GlobalConfigCCEmptying>) => void;

    /**
     * PLANNING ACTIONS
     */
    importList: (planningId: string, list: string[]) => void;
    editPlanningConfig: (
        planningId: string,
        newConfig: Partial<Pick<GlobalConfigPlanning, 'collector' | 'endShiftAt' | 'startShiftAt' | 'visible'>>
    ) => void;
    editVisibilityPlanning: (planningId: Omit<string, 'all'> | 'all', visible: boolean) => void;
};

// React context
const GlobalConfigContext = React.createContext<GlobalConfigContextType>({
    type             : EPlanningType.BIG_BAG,
    plannings        : [],
    planningRegistry : {},
    unassignedCcs    : [],
    customers        : {},
    collectors       : {},
    dumpsterTypes    : [],
    setDumpsterTypes : () => {
        throw new Error('setDumpsterTypes not implemented');
    },
    switchCard: () => {
        throw new Error('switchCard not implemented');
    },
    unassignedCard: () => {
        throw new Error('unnassignCard not implemented');
    },
    assignCard: () => {
        throw new Error('assignCard not implemented');
    },
    addLandfillCard: () => {
        throw new Error('addLandfillCard not implemented');
    },
    updateLandfillCard: () => {
        throw new Error('updateLandfillCard not implemented');
    },
    deleteLandfillCard: () => {
        throw new Error('deleteLandfillCard not implemented');
    },
    importList: () => {
        throw new Error('importList not implemented');
    },
    editPlanningConfig: () => {
        throw new Error('editPlanningConfig not implemented');
    },
    editVisibilityPlanning: () => {
        throw new Error('editVisibilityPlanning not implemented');
    },
    formatManualShiftDto: () => {
        throw new Error('formatManualShiftDto not implemented');
    },
    getAllAssignedCollectorIds: () => {
        throw new Error('getAllAssignedCollectorIds not implemented');
    },
    ccNumberExists: () => {
        throw new Error('ccNumberExists not implemented');
    }
});

export const useGlobalConfigContext = () => React.useContext(GlobalConfigContext);

type GlobalConfigProviderProps = {
    plannings: PlanningRo[];
    unassignedCcs: CCRo[];
    landfills: Record<string, LandfillRo>;
    trucks: Record<string, TruckRo>;
    collectors: Record<string, CollectorRo>;
    planningInfo: PlanningPageState;
    ccServices: Record<string, CCServiceRo>;
    ccAdministratives: Record<string, CCAdministrativeRo>;
    customers: Record<string, CustomerRo>;
};
export const GlobalConfigProvider: React.FC<GlobalConfigProviderProps> = ({
    children,
    plannings,
    unassignedCcs,
    customers,
    ...infos
}) => {
    const [planningsState, setPlanningsState] = React.useState<GlobalConfigContextType['plannings']>(() =>
        plannings.map((e) => formatPlanning(e, infos)).filter((f): f is GlobalConfigPlanning => f !== null)
    );
    const [unassignedCcsState, setUnassignedCcsState] = React.useState<GlobalConfigContextType['unassignedCcs']>(() =>
        unassignedCcs.map((e) => ({
            cc:
                e.family === CC_FAMILY.ADMINISTRATIVE
                    ? e
                    : {
                          ...e,
                          status: (e as any).is_splitted ? CC_STATUS.SPLITTED : e.status
                      },
            gc_id    : ulid(),
            category : 'CC'
        }))
    );

    const planningRegistry = React.useMemo(() => formatRegistry(planningsState, 'id'), [planningsState]);
    const listCCToImport = React.useCallback(() => {
        const store: Array<MonitorItem<GlobalConfigCCRo>> = [];

        unassignedCcsState.forEach((e, i) => {
            store.push({
                columnId    : CardUnassignedId,
                columnIndex : i,
                id          : e.gc_id,
                type        : CardType.CC,
                extra       : e
            });
        });

        planningsState.forEach((e) => {
            e.timeline.forEach((f, i) => {
                if (f.category === 'CC') {
                    store.push({
                        columnId    : e.id,
                        columnIndex : i,
                        id          : f.gc_id,
                        type        : CardType.CC,
                        extra       : f
                    });
                }
            });
        });

        return Object.fromEntries(
            store.map((e) => [
                e.extra &&
                e.extra.cc.status === 'SPLITTED' &&
                e.extra.cc.is_splitted &&
                e.extra.cc.splitted_idx !== undefined
                    ? `${e.extra.cc.id}|${e.extra.cc.splitted_idx}`
                    : e.extra?.cc.id || '',
                e
            ])
        );
    }, [planningsState, unassignedCcsState]);

    const addLandfillCard = React.useCallback(
        (planningId: string, landfill: LandfillRo, waitingTimeMinutes: number) => {
            setPlanningsState(
                planningsState.map((e, i) => {
                    if (e.id === planningId) {
                        e.timeline.push({
                            category : 'EMPTYING',
                            landfill,
                            waitingTimeMinutes,
                            gc_id    : ulid()
                        });
                    }

                    return e;
                })
            );
        },
        [planningsState]
    );

    const updateLandfillCard = React.useCallback(
        (landfillItem: MonitorItem<GlobalConfigCCEmptying>, newLandfill: LandfillRo, waitingTimeMinutes: number) => {
            setPlanningsState(
                planningsState.map((e, i) => {
                    if (i === planningRegistry[landfillItem.columnId]?.[1]) {
                        e.timeline = e.timeline.map((l, li) => {
                            if (li === landfillItem.columnIndex) {
                                return {
                                    category : 'EMPTYING',
                                    landfill : newLandfill,
                                    waitingTimeMinutes,
                                    gc_id    : l.gc_id
                                };
                            }
                            return l;
                        });
                    }

                    return e;
                })
            );
        },
        [planningsState, planningRegistry]
    );

    const deleteLandfillCard = React.useCallback(
        (landfillItem: MonitorItem<GlobalConfigCCEmptying>) => {
            setPlanningsState(
                planningsState.map((e, i) => {
                    if (i === planningRegistry[landfillItem.columnId]?.[1]) {
                        e.timeline.splice(landfillItem.columnIndex, 1);
                    }

                    return e;
                })
            );
        },
        [planningsState, planningRegistry]
    );

    const switchCard = React.useCallback(
        (dragItem: MonitorItem, item: MonitorItem) => {
            setPlanningsState((prevPlanningState) => {
                const toRemoveIndex = planningRegistry[dragItem.columnId][1];
                const toAddIndex = planningRegistry[item.columnId][1];

                return prevPlanningState.map((e, i) => {
                    if (i === toRemoveIndex || i === toAddIndex) {
                        e.timeline = e.timeline.reduce((acc, curr, index) => {
                            if (index === dragItem.columnIndex && i === toRemoveIndex) {
                                return acc;
                            } else if (index === item.columnIndex && i === toAddIndex && dragItem.extra) {
                                acc.push(dragItem.extra, curr);
                            } else {
                                acc.push(curr);
                            }
                            return acc;
                        }, [] as (GlobalConfigCCRo | GlobalConfigCCEmptying)[]);

                        if (item.type === CardType.SPACER && i === toAddIndex && dragItem.extra) {
                            e.timeline.push(dragItem.extra);
                        }
                    }
                    return e;
                });
            });
        },
        [planningsState, planningRegistry]
    );

    const assignCard = React.useCallback(
        (dragItem: MonitorItem, item: MonitorItem) => {
            setUnassignedCcsState((prevUnassignedCcsState) => {
                prevUnassignedCcsState.splice(dragItem.columnIndex, 1);
                return prevUnassignedCcsState;
            });
            setPlanningsState((prevPlanningState) => {
                const toAddIndex = planningRegistry[item.columnId][1];

                return prevPlanningState.map((e, i) => {
                    if (i === toAddIndex) {
                        e.timeline = e.timeline.reduce((acc, curr, index) => {
                            if (index === item.columnIndex && i === toAddIndex && dragItem.extra) {
                                acc.push(dragItem.extra, curr);
                            } else {
                                acc.push(curr);
                            }
                            return acc;
                        }, [] as (GlobalConfigCCRo | GlobalConfigCCEmptying)[]);

                        if (item.type === CardType.SPACER && i === toAddIndex && dragItem.extra) {
                            e.timeline.push(dragItem.extra);
                        }
                    }
                    return e;
                });
            });
        },
        [planningsState, planningRegistry]
    );

    const unassignedCard = React.useCallback(
        (item: MonitorItem) => {
            setUnassignedCcsState((prevUnassignedCcsState) => {
                prevUnassignedCcsState.push(item.extra as GlobalConfigCCRo);
                return prevUnassignedCcsState;
            });
            setPlanningsState((prevPlanningState) => {
                const toRemoveIndex = planningRegistry[item.columnId][1];
                return prevPlanningState.map((e, i) => {
                    if (i === toRemoveIndex) {
                        e.timeline = e.timeline.reduce((acc, curr, index) => {
                            if (index === item.columnIndex) {
                                return acc;
                            } else {
                                acc.push(curr);
                            }
                            return acc;
                        }, [] as (GlobalConfigCCRo | GlobalConfigCCEmptying)[]);
                    }
                    return e;
                });
            });
        },
        [planningsState, setUnassignedCcsState, planningRegistry]
    );

    const formatCcId = (cc: CCRoFront): string => {
        const id =
            cc.family !== CC_FAMILY.ADMINISTRATIVE
                ? cc.is_splitted && cc.splitted_idx !== undefined
                    ? `${cc.number.replace('CO', '')}|${cc.splitted_idx}`
                    : cc.number.replace('CO', '')
                : cc.id;

        return id;
    };

    const ccNumberExists = React.useCallback(
        (number: string): boolean => {
            const isUnassigned = unassignedCcsState.some((item) => {
                return formatCcId(item.cc) === number;
            });

            if (isUnassigned) return true;

            const isAssigned = planningsState.some((planning) => {
                return planning.timeline.some((item) => {
                    return item.category === 'CC' && formatCcId(item.cc) === number;
                });
            });

            return isAssigned;
        },
        [unassignedCcsState, planningsState]
    );

    const importList = React.useCallback(
        (planningId: string, ids: string[]) => {
            let foreignCardUnassignedCount = 0;
            let newUnassignedCcs = deepCopy(unassignedCcsState);
            const newPlannings = deepCopy(planningsState).map((planning) => {
                if (planning.id === planningId) {
                    newUnassignedCcs.push(
                        ...planning.timeline.filter((f): f is GlobalConfigCCRo => f.category === 'CC')
                    );

                    if (planning.timeline.length > 0) {
                        toast({
                            // life     : 10000,
                            severity : 'info',
                            summary  : 'Planning reinitialisé',
                            detail   : `Le planning a été réinitialisé pour l'import, ${planning.timeline.length} étape(s) ont été retirées`
                        });
                    }

                    planning.timeline = [];
                    return planning;
                } else {
                    planning.timeline = planning.timeline.filter((f) => {
                        if (f.category === 'CC' && ids.includes(formatCcId(f.cc))) {
                            foreignCardUnassignedCount += 0;
                            newUnassignedCcs.push(f);
                            return false;
                        } else {
                            return true;
                        }
                    });

                    return planning;
                }
            });

            if (foreignCardUnassignedCount > 0) {
                toast({
                    // life     : 10000,
                    severity : 'info',
                    summary  : 'Etapes retirées des autres plannings',
                    detail   : `${foreignCardUnassignedCount} étape(s) ont été retirées des autres plannings`
                });
            }

            const planningIndex = newPlannings.findIndex((e) => e.id === planningId);

            if (planningIndex === -1) {
                toast({
                    // life     : 10000,
                    severity : 'error',
                    summary  : 'Planning not found',
                    detail   : 'The planning you are trying to import to does not exist'
                });
                console.error('The planning you are trying to import to does not exist');
                // error message;
                return;
            }

            const toAssign = Object.fromEntries(
                newUnassignedCcs.filter((f) => ids.includes(formatCcId(f.cc))).map((e) => [formatCcId(e.cc), e])
            );

            ids.forEach((id) => {
                if (toAssign[id]) {
                    newPlannings[planningIndex].timeline.push(toAssign[id]);
                }
            });

            const nonExistingIds = ids.filter((f) => Object.keys(toAssign).includes(f) === false);
            if (nonExistingIds.length > 0) {
                toast({
                    // life     : 10000,
                    severity : 'error',
                    summary  : 'Etapes non trouvées',
                    detail   : `Les étapes suivantes n'ont pas été trouvées : ${nonExistingIds.join(', ')}`
                });
                console.error(`Les étapes suivantes n'ont pas été trouvées : ${nonExistingIds.join(', ')}`);
            }

            if (newPlannings[planningIndex].timeline.length > 0) {
                toast({
                    // life     : 10000,
                    severity : 'success',
                    summary  : 'Etapes importées',
                    detail   : `${newPlannings[planningIndex].timeline.length} étape(s) ont été importées (sur ${ids.length} demandées)`
                });
            }

            newUnassignedCcs = newUnassignedCcs.filter((f) => ids.includes(formatCcId(f.cc)) === false);

            setUnassignedCcsState(newUnassignedCcs);
            setPlanningsState(newPlannings);
        },
        [switchCard, assignCard, unassignedCard, listCCToImport]
    );

    const editPlanningConfig = React.useCallback(
        (
            planningId: string,
            newConfig: Partial<Pick<GlobalConfigPlanning, 'collector' | 'endShiftAt' | 'startShiftAt' | 'visible'>>
        ) => {
            setPlanningsState(
                planningsState.map((e) => {
                    if (e.id === planningId) {
                        return {
                            ...e,
                            ...newConfig
                        };
                    }
                    return e;
                })
            );
        },
        [planningsState, planningRegistry]
    );

    const editVisibilityPlanning = React.useCallback(
        (planningId: Omit<string, 'all'> | 'all', visible: boolean) => {
            setPlanningsState(
                planningsState.map((e) => {
                    if (planningId === 'all' || e.id === planningId) {
                        return {
                            ...e,
                            visible
                        };
                    }
                    return e;
                })
            );
        },
        [planningsState]
    );

    const formatManualShiftDto = React.useCallback(
        (planningIds: string[], ignoreHere?: boolean): PlanningManualShiftDto[] => {
            return planningsState
                .filter((f) => f.collector !== undefined && planningIds.includes(f.id))
                .map((e) => ({
                    day        : e.day,
                    region     : e.region,
                    type       : e.type,
                    parameters : {
                        ccsAdministrative: e.timeline
                            .filter(
                                (f): f is GlobalConfigCCRo =>
                                    f.category === 'CC' && f.cc.family === CC_FAMILY.ADMINISTRATIVE
                            )
                            .map((e) => e.cc.id),
                        ccsService: e.timeline
                            .filter(
                                (f): f is GlobalConfigCCRo =>
                                    f.category === 'CC' && f.cc.family !== CC_FAMILY.ADMINISTRATIVE
                            )
                            .map((e) => e.cc.id),
                        collector_id : e.collector!.id,
                        planning_id  : e.id,
                        truck_id     : e.truck!.id,
                        shift_config : {
                            end_date   : moment(e.endShiftAt).utc().toISOString(),
                            start_date : moment(e.startShiftAt).utc().toISOString()
                        }
                    },
                    ccAndEmptyingIdsSorted: e.timeline.map<IdAndSplittedIdx>((step) => ({
                        id           : step.category === 'CC' ? step.cc.id : step.gc_id,
                        splitted_idx :
                            step.category === 'CC' && step.cc.status === 'SPLITTED' ? step.cc.splitted_idx : undefined
                    })),
                    emptyingSteps: e.timeline
                        .filter((f): f is GlobalConfigCCEmptying => f.category === 'EMPTYING')
                        .map<PlanningShiftStepManualEmptying>((e) => ({
                            category               : PlanningShiftStepCategory.EMPTYING,
                            id                     : e.gc_id,
                            landfill_id            : e.landfill.id,
                            scheduled_service_time : moment()
                                .startOf('day')
                                .add(e.waitingTimeMinutes, 'minutes')
                                .format('HH:mm')
                        })),
                    ignore_here: ignoreHere
                }));
        },
        [planningsState]
    );

    const getAllAssignedCollectorIds = React.useCallback((): [string, string][] => {
        return planningsState.map((e) => [e.id, e.collector?.id]).filter((f) => f[1] !== undefined) as [
            string,
            string
        ][];
    }, [planningsState]);

    const [dumpsterTypes, setDumpsterTypes] = React.useState<PRODUCT_DUMPSTER_TYPE[]>([]);

    React.useEffect(() => {
        if (infos.planningInfo.type === EPlanningType.BIG_BAG) {
            if (dumpsterTypes.length > 0) {
                setDumpsterTypes([]);
            }
        } else {
            setDumpsterTypes(Object.values(PRODUCT_DUMPSTER_TYPE));
        }
    }, [infos.planningInfo.type]);

    const value = React.useMemo((): GlobalConfigContextType => {
        return {
            plannings     : planningsState,
            type          : infos.planningInfo.type,
            planningRegistry,
            unassignedCcs : unassignedCcsState,
            customers,
            importList,
            ccNumberExists,
            getAllAssignedCollectorIds,
            formatManualShiftDto,
            switchCard,
            unassignedCard,
            assignCard,
            addLandfillCard,
            updateLandfillCard,
            deleteLandfillCard,
            editPlanningConfig,
            editVisibilityPlanning,
            dumpsterTypes,
            setDumpsterTypes,
            collectors    : infos.collectors
        };
    }, [planningsState, unassignedCcsState, customers, dumpsterTypes, infos.collectors]);

    return (
        <GlobalConfigContext.Provider value={value}>
            <DndProvider backend={HTML5Backend}>{children}</DndProvider>
        </GlobalConfigContext.Provider>
    );
};

function formatRegistry<T extends Record<string, any>>(array: T[], key: keyof T) {
    return array.reduce((acc, curr, index) => {
        acc[curr[key]] = [curr, index];
        return acc;
    }, {} as Record<string, [T, number]>);
}

type FormatInfo = {
    collectors: Record<string, CollectorRo>;
    trucks: Record<string, TruckRo>;
    landfills: Record<string, LandfillRo>;
    ccServices: Record<string, CCServiceRo>;
    ccAdministratives: Record<string, CCAdministrativeRo>;
};
const formatPlanning = (planning: PlanningRo, info: FormatInfo): GlobalConfigPlanning | null => {
    const { ccAdministratives, ccServices, collectors, landfills, trucks } = info;

    if (planning.shift.steps_driver.length > 0 && planning.shift.steps_driver[0].collect_id) return null;

    const convertServiceTimeInMinutes = (time: string) => {
        const [hours, minutes] = time.split(':').map((e) => parseInt(e.trim(), 10));
        return hours * 60 + minutes;
    };

    const timeline: GlobalConfigPlanning['timeline'] = (
        [] as Array<PlanningShiftStepAdministrative | PlanningShiftStepService | PlanningShiftStepEmptying>
    )
        .concat(planning.shift.steps_administrative, planning.shift.steps_service, planning.shift.steps_emptying)
        .sort((a, b) => {
            const aDate = new Date(a.scheduled_at);
            const bDate = new Date(b.scheduled_at);
            return aDate.getTime() - bDate.getTime();
        })
        .map((e) => {
            return e.category === PlanningShiftStepCategory.EMPTYING
                ? {
                      landfill           : landfills[e.landfill_id],
                      waitingTimeMinutes : convertServiceTimeInMinutes(e.scheduled_service_time),
                      gc_id              : ulid(),
                      category           : 'EMPTYING'
                  }
                : e.category === PlanningShiftStepCategory.ADMINISTRATIVE
                ? {
                      cc       : ccAdministratives[e.collect_config_id],
                      gc_id    : ulid(),
                      category : 'CC'
                  }
                : {
                      cc       : ccServices[e.collect_config_id],
                      gc_id    : ulid(),
                      category : 'CC'
                  };
        });

    return {
        id     : planning.id,
        day    : planning.day,
        region : planning.region,
        type   : planning.type,

        visible      : timeline.length > 0,
        collector    : collectors[planning.collector_id[0]],
        endShiftAt   : planning.shift_config.end_date,
        startShiftAt : planning.shift_config.start_date,
        truck        : trucks[planning.truck_id[0]],
        timeline
    };
};
