import moment from 'moment';
import { Button as PRButton } from 'primereact/button';
import { Tag } from 'primereact/tag';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { FORMAT_DISPLAY_DATE, getVolumeFromProducts } from '@bbng/util/misc';
import {
    CCAdministrativeQuery,
    CCAdministrativeRo,
    CCPostponeDto,
    CCRo,
    CCRoFront,
    CCServiceRo,
    CCServiceRoFront,
    CC_FAMILY,
    CC_STATUS,
    CollectConfigQuery,
    CustomerRo,
    isCCServiceFront
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { mapCollectConfigFamilyText, mapPlanningConfigRegion, mapPlanningConfigType } from '../../common/enumMapper';
import { urlApiBuilder } from '../../common/urlBuilder';
import { SetStorageAction } from '../../hooks/LocalStorage';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import Button from '../Button';
import ProgressBar from '../ProgressBar';
import { toast } from '../Toast';
import { PlanningPageState } from '../../pages/Planning/helpers';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';

type CCSelected = CCRoFront & {
    is_selected: boolean;
};

export type PlanningPostponeProps = {
    setModalIsOpen: (isOpen: boolean) => void;
    setPostPoneDisabledLocalStorage: SetStorageAction<Record<string, boolean>>;
    postPoneDisabledLocalStorage: Record<string, boolean>;
};

export const POSTPONE_PLANNING_LOCAL_STORAGE_KEY = 'postpone_planning';

export const buildPlanningPostponeKey = (planningPageState: PlanningPageState): string => {
    return `${planningPageState.day}_${planningPageState.region}_${planningPageState.type}`;
};

export const PlanningPostpone: React.FC<PlanningPostponeProps> = ({
    setModalIsOpen,
    setPostPoneDisabledLocalStorage,
    postPoneDisabledLocalStorage
}: PlanningPostponeProps): JSX.Element => {
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });
    const { planningPageState, getCCAdministrative, getCCService } = usePlanningV2Store();
    const [unfinishedCcs, setUnfinishedCcs] = useState<CCSelected[]>();
    const [loading, setLoading] = useState(false);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    const handleSubmit = React.useCallback(async () => {
        setLoading(true);
        const response = await bbngRequest<CCRo[]>({
            sync    : true,
            method  : 'PATCH',
            url     : urlApiBuilder.collectConfigPostpone(),
            payload : {
                body: {
                    date        : moment.utc(planningPageState.day, 'YYYY-MM-DD').toISOString(),
                    cc_admin_id : unfinishedCcs
                        ?.filter((cc) => cc.is_selected && cc.family === CC_FAMILY.ADMINISTRATIVE)
                        .map((cc) => cc.id),
                    cc_service_id: unfinishedCcs
                        ?.filter((cc) => cc.is_selected && cc.family !== CC_FAMILY.ADMINISTRATIVE)
                        .map((cc) => cc.id)
                } as CCPostponeDto
            },
            options      : { toastifySuccess: false },
            retryPolling : 1000
        });
        if (response.success && response.response?.data.ro) {
            await Promise.all([getCCAdministrative(bbngRequest), getCCService(bbngRequest)]);
        }
        setLoading(false);
        setModalIsOpen(false);
    }, [planningPageState, unfinishedCcs, setModalIsOpen, setLoading, bbngRequest]);

    const fetchUnfinishedCcs = React.useCallback(async () => {
        const dayIso = moment.utc(planningPageState.day, 'YYYY-MM-DD').toISOString();
        setLoading(true);
        /**
         * Fetch unfinished CCs from last 7 days
         */
        const [unfinishedServiceWithoutCustomer, unfinishedAdmin] = await Promise.all([
            bbngRequest<CCServiceRo[]>({
                sync    : true,
                method  : 'GET',
                url     : urlApiBuilder.collectConfigReadAll(),
                payload : {
                    queryParams: {
                        region   : planningPageState.region,
                        type     : planningPageState.type,
                        max_date : moment.utc(dayIso).toISOString(),
                        min_date : moment.utc(dayIso).subtract(7, 'day').toISOString(),
                        statuses : Object.values(CC_STATUS).filter(
                            (status) =>
                                ![CC_STATUS.FINISHED, CC_STATUS.HAZARD, CC_STATUS.ORDER_TO_PAY].includes(status as any)
                        ),
                        no_delivery : true,
                        uncancelled : true,
                        no_archived : true,
                        no_limit    : true
                    } as CollectConfigQuery
                },
                options: { toastifySuccess: false }
            }),
            bbngRequest<CCAdministrativeRo[]>({
                sync    : true,
                method  : 'GET',
                url     : urlApiBuilder.ccAdministrativeReadAll(),
                payload : {
                    queryParams: {
                        region   : planningPageState.region,
                        type     : planningPageState.type,
                        max_date : moment.utc(dayIso).toISOString(),
                        min_date : moment.utc(dayIso).subtract(7, 'day').toISOString(),
                        statuses : Object.values(CC_STATUS).filter(
                            (status) =>
                                ![CC_STATUS.FINISHED, CC_STATUS.HAZARD, CC_STATUS.ORDER_TO_PAY].includes(status as any)
                        ),
                        no_archived : true,
                        no_limit    : true,
                        uncancelled : true
                    } as CCAdministrativeQuery
                },
                options: { toastifySuccess: false }
            })
        ]);

        const unfinishedService: StatelessResponse<CCServiceRoFront[]> = await fetchDataRelation(
            unfinishedServiceWithoutCustomer,
            { customer_id: true }
        );

        /**
         * Set all unfinished CCs as selected and destructure splitted CCs
         */
        if (
            unfinishedService.success &&
            unfinishedService.response?.data.ro &&
            unfinishedAdmin.success &&
            unfinishedAdmin.response?.data.ro
        ) {
            const unfinished = [...unfinishedService.response.data.ro, ...unfinishedAdmin.response.data.ro].reduce(
                (acc, collectConfig) => {
                    if (collectConfig.status === CC_STATUS.SPLITTED) {
                        collectConfig.splitted_informations?.forEach((splittedCollectConfig) => {
                            if (![CC_STATUS.FINISHED, CC_STATUS.HAZARD].includes(splittedCollectConfig.status as any)) {
                                acc.push({
                                    ...collectConfig,
                                    products     : splittedCollectConfig.products,
                                    status       : splittedCollectConfig.status,
                                    splitted_idx : splittedCollectConfig.idx,
                                    is_splitted  : true,
                                    is_selected  : false
                                });
                            }
                        });
                    } else if (![CC_STATUS.FINISHED, CC_STATUS.HAZARD].includes(collectConfig.status as any)) {
                        acc.push({
                            ...collectConfig,
                            is_selected: false
                        });
                    }
                    return acc;
                },
                [] as CCSelected[]
            );

            unfinished.sort((a, b) => (moment(a.from_date).isBefore(b.from_date) ? -1 : 1));
            setUnfinishedCcs(unfinished);
            if (unfinished.length === 0) {
                toast({
                    life     : 10_000,
                    severity : 'info',
                    summary  : 'Aucune étape non terminée dans les 7 derniers jours.',
                    detail   : 'Toutes les étapes des 7 derniers jours sont terminées. Aucun rapatriement nécessaire.'
                });
                setModalIsOpen(false);
            }
        }
        setLoading(false);
    }, [planningPageState, setUnfinishedCcs, setLoading, setModalIsOpen, bbngRequest]);

    useEffect(() => {
        if (planningPageState.day) {
            fetchUnfinishedCcs();
        }
    }, [planningPageState]);

    return (
        <Wrapper>
            {loading ? (
                <ProgressBar loadingText="Chargement des étapes non terminées" />
            ) : (
                <>
                    <Tag
                        value="Certaines étapes des 7 derniers jours ne sont pas encore terminées. Sélectionnez les étapes à rapatrier au planning du jour."
                        icon="pi pi-info-circle"
                        severity="warning"
                    />
                    <StyledTable>
                        <thead>
                            <tr>
                                <th>
                                    <Button.Checkbox
                                        value={allSelected}
                                        result={(value) => {
                                            setUnfinishedCcs(
                                                (old) =>
                                                    old?.map((val) => ({
                                                        ...val,
                                                        is_selected: value
                                                    })) ?? []
                                            );
                                            setAllSelected(value);
                                        }}
                                        id="all"
                                    />
                                </th>
                                <th>Date initiale</th>
                                <th>Numéro de commande</th>
                                <th>Numéro de collecte</th>
                                <th>Prestation</th>
                                <th>Volume</th>
                                <th>Code postal</th>
                                <th>Client</th>
                            </tr>
                        </thead>
                        <tbody>
                            {unfinishedCcs?.map((cc) => {
                                const ccService = isCCServiceFront(cc) ? cc : undefined;
                                const ccIndex = ccService?.splitted_idx ?? 0;
                                const id = `${cc.id}-${ccIndex}`;
                                return (
                                    <tr key={id}>
                                        <td>
                                            <Button.Checkbox
                                                key={id}
                                                value={cc.is_selected}
                                                result={(value) => {
                                                    const currentCc = unfinishedCcs?.findIndex(
                                                        (val) => val.id === cc.id
                                                    );
                                                    if (currentCc !== undefined) {
                                                        setUnfinishedCcs((prev) => {
                                                            if (prev) {
                                                                prev[currentCc].is_selected = value;
                                                                return [...prev];
                                                            }
                                                            return [];
                                                        });
                                                    }
                                                }}
                                                id={cc.id}
                                            />
                                        </td>
                                        <td>{moment(cc.from_date).format('DD/MM/YYYY')}</td>
                                        <td>{ccService?.order_number ?? 'NA'}</td>
                                        <td>
                                            {ccService?.number ?? 'NA'}{' '}
                                            {ccService?.is_splitted && <Tag severity="warning" value="Scindée" />}
                                        </td>
                                        <td>{mapCollectConfigFamilyText(ccService?.family)}</td>
                                        <td>
                                            {ccService?.products
                                                ? getVolumeFromProducts(ccService?.products, ccService.family)
                                                : '0'}
                                            m³
                                        </td>
                                        <td>{cc.address.components['postal_code']}</td>
                                        <td>{(ccService?.customer_id[0] as CustomerRo)?.name ?? 'NA'}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </StyledTable>
                    <Footer>
                        <Button.Checkbox
                            value={postPoneDisabledLocalStorage[buildPlanningPostponeKey(planningPageState)]}
                            result={(value) => {
                                setPostPoneDisabledLocalStorage((old) => ({
                                    ...old,
                                    [buildPlanningPostponeKey(planningPageState)]: value
                                }));
                                toast({
                                    life     : 10_000,
                                    severity : 'info',
                                    summary  : `Ouverture automatique ${!value ? 'activée' : 'désactivée'}.`,
                                    detail   : `Ouverture automatique ${
                                        !value ? 'activée' : 'désactivée'
                                    } de cette fenêtre pour le planning du ${moment(planningPageState.day).format(
                                        'DD/MM/YYYY'
                                    )}, région ${mapPlanningConfigRegion(
                                        planningPageState.region
                                    )} en ${mapPlanningConfigType(planningPageState.type)}.`
                                });
                            }}
                            id="autoModalOpeningDisabled"
                            label="Désactiver pendant 2h l'ouverture automatique de cette fenêtre pour ce planning."
                            labelPosition="right"
                        />
                        <PRButton
                            label={`Déplacer au ${moment(planningPageState.day).format(FORMAT_DISPLAY_DATE)}`}
                            onClick={() => {
                                handleSubmit();
                            }}
                            disabled={unfinishedCcs?.every((cc) => !cc.is_selected)}
                        />
                    </Footer>
                </>
            )}
        </Wrapper>
    );
};

export const StyledTable = styled.table`
    text-align: center;
    & td,
    th {
        padding: 4px 12px;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

export const Footer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;
