import { ETruckFuelType, ITruckCharacteristics, TruckCreateDto, TruckEditDto, TruckRo } from '@bbng/util/types';

import { getRelationsDto, optimiseEditDto } from '../../common/form';
import {
    AddressErrorState,
    AddressState,
    initialErrorState as addressInitialErrorState,
    initialState as addressInitialState
} from '../../modules/common/Address';
import {
    TruckCharacteristicsErrorState,
    TruckCharacteristicsState,
    initialErrorState as truckCharacteristicsInitialErrorState,
    initialState as truckCharacteristicsInitialState
} from '../../modules/truck/Characteristics';
import {
    TruckGeneralErrorState,
    TruckGeneralState,
    initialErrorState as truckGeneralInitialErrorState,
    initialState as truckGeneralInitialState
} from '../../modules/truck/General';

export type TruckModulesStates = TruckGeneralState | TruckCharacteristicsState | AddressState;

export type TruckModulesErrorStates = TruckGeneralErrorState | TruckCharacteristicsErrorState | AddressErrorState;

export type TruckFormState = {
    general: TruckGeneralState;
    characteristics: TruckCharacteristicsState;
    address: AddressState;
};

export type TruckFormErrorState = {
    general: TruckGeneralErrorState;
    characteristics: TruckCharacteristicsErrorState;
    address: AddressErrorState;
};

export const initialState: TruckFormState = {
    general         : truckGeneralInitialState,
    characteristics : truckCharacteristicsInitialState,
    address         : addressInitialState
};

export const initialErrorState: TruckFormErrorState = {
    general         : truckGeneralInitialErrorState,
    characteristics : truckCharacteristicsInitialErrorState,
    address         : addressInitialErrorState
};

export const mapApiDataToState = (truck: TruckRo): TruckFormState => {
    return {
        general: {
            label     : truck.name,
            available : truck.is_available,
            tag       : truck.type
        },
        characteristics: {
            license_plate                       : truck.license_plate,
            weight_ton                          : truck.weight_ton ?? null,
            fuel_type                           : truck.characteristics.fuel_type,
            max_autonomy                        : truck.characteristics.max_autonomy,
            remaining_autonomy                  : truck.characteristics.remaining_autonomy ?? null,
            fuel_refill_duration                : truck.characteristics.fuel_refill_duration,
            // usable_volume_m3                    : truck.characteristics.usable_volume_m3,
            usable_load_kg                      : truck.characteristics.usable_load_kg,
            setup_duration                      : truck.characteristics.setup_duration,
            setdown_duration                    : truck.characteristics.setdown_duration,
            profitability_bag_number_limit      : truck.characteristics.profitability_bag_number_limit,
            profitability_dumpster_number_limit : truck.characteristics.profitability_dumpster_number_limit,
            dumpster_capacity                   : truck.characteristics.dumpster_capacity ?? null,
            bigbag_capacity                     : truck.characteristics.bigbag_capacity ?? null,
            bigbag_stock                        : truck.characteristics.bigbag_stock ?? null,
            bigbag_current_load_m3              : truck.characteristics.bigbag_current_load_m3 ?? null
        },
        address: {
            address_street_number : truck.garage_address.components['street_number'],
            address_street_name   : truck.garage_address.components['route'],
            address_city          : truck.garage_address.components['locality'],
            address_zip_code      : truck.garage_address.components['postal_code'],
            address_country       : truck.garage_address.components['country'],
            address_complement    : truck.garage_address.components['complement'] ?? '',
            address_lat           : truck.garage_address.coordinates.latitude,
            address_lng           : truck.garage_address.coordinates.longitude
        }
    };
};

export const mapStateToApiCreateData = (state: TruckFormState): TruckCreateDto => {
    return {
        name            : state.general.label,
        type            : state.general.tag,
        is_available    : state.general.available,
        license_plate   : state.characteristics.license_plate,
        weight_ton      : state.characteristics.weight_ton ?? undefined,
        characteristics : {
            fuel_type                           : state.characteristics.fuel_type as ETruckFuelType,
            max_autonomy                        : state.characteristics.max_autonomy!,
            remaining_autonomy                  : state.characteristics.remaining_autonomy ?? undefined,
            fuel_refill_duration                : state.characteristics.fuel_refill_duration!,
            // usable_volume_m3                    : state.characteristics.usable_volume_m3!,
            usable_load_kg                      : state.characteristics.usable_load_kg!,
            setup_duration                      : state.characteristics.setup_duration!,
            setdown_duration                    : state.characteristics.setdown_duration!,
            profitability_bag_number_limit      : state.characteristics.profitability_bag_number_limit ?? 0,
            profitability_dumpster_number_limit : state.characteristics.profitability_dumpster_number_limit ?? 0,
            dumpster_capacity                   : state.characteristics.dumpster_capacity ?? undefined,
            bigbag_capacity                     : state.characteristics.bigbag_capacity ?? undefined,
            bigbag_stock                        : state.characteristics.bigbag_stock ?? undefined,
            bigbag_current_load_m3              : state.characteristics.bigbag_current_load_m3 ?? undefined
        },
        garage_address: {
            coordinates: {
                latitude  : state.address.address_lat!,
                longitude : state.address.address_lng!
            },
            name           : (state.address.address_street_name || state.address.address_google?.name) ?? '',
            formatted_name :
                (state.address.address_google?.formatted_address ||
                    state.address.address_street_name ||
                    state.address.address_google?.name) ??
                '',
            components: {
                street_number : state.address.address_street_number,
                route         : state.address.address_street_name,
                locality      : state.address.address_city,
                postal_code   : state.address.address_zip_code,
                country       : state.address.address_country,
                complement    : state.address.address_complement
            }
        }
    };
};

export const mapStateToApiEditData = (
    state: TruckFormState,
    apiState: TruckFormState,
    truck: TruckRo
): TruckEditDto => {
    const dto = optimiseEditDto(
        state,
        apiState,
        truck,
        mapStateToApiCreateData,
        getRelationsDto<TruckRo>()
    ) as TruckEditDto;

    if (dto.characteristics) {
        dto.characteristics = {
            /**
             * Inject the characteristics from the API state to the DTO as some of them are hidden in the form
             */
            ...truck.characteristics,
            ...dto.characteristics
        } as ITruckCharacteristics;
    }

    return dto;
};
