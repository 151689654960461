import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import React, { useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';

import { deepCheckEmptyObject, deepCopy, getMiddleOfTheDay } from '@bbng/util/misc';
import {
    CCServiceRo,
    CCServiceRoFront,
    CC_STATUS,
    CollectRo,
    EPlanningType,
    PlanningShiftStepCategory,
    PrestaRo
} from '@bbng/util/types';

import { ActionsFormContainer, uploadFiles } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import { AddressState } from '../../modules/common/Address';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    CompleteServiceCollectFormErrorState,
    CompleteServiceCollectFormState,
    CollectModulesErrorStates,
    CollectModulesStates,
    initialErrorState,
    initialState,
    mapCCApiDataToState,
    mapStateToApiCompleteData
} from './helpers';
import equal from 'fast-deep-equal/react';
import { PageForm, PageFormLine, SubmitButton } from './style';
import Collect from '../../modules/collect';
import Order from '../../modules/order';
import { fetchDataRelation } from '../../common/dataRelation';
import { mapPlanningTypeToOrderTypeForm } from '../../common/enumMapper';
import { EOrderDumpsterServiceForm } from '../../modules/order/DumpsterService';
import { EOrderTypeForm } from '../../modules/order/Type';
import { toast } from '../../components/Toast';
import { Card } from '../../components/Card';

type CompleteServiceCollectFormProps = {
    /**/
};

const CompleteServiceCollectForm: React.FC<CompleteServiceCollectFormProps> = (
    props: CompleteServiceCollectFormProps
): JSX.Element => {
    const { id: paramsId } = useParams();
    const navigate = useNavigate();
    const [smsModal, setSMSModal] = useState<boolean>(false);
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [apiState, setApiState] = React.useState<{ form: CompleteServiceCollectFormState; db: CCServiceRoFront }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(true);
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>('read');

    const [err, setErr] = useState<CompleteServiceCollectFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<CompleteServiceCollectFormState>['onSubmit']>(
        async (data: CompleteServiceCollectFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            const hasProducts = Object.values({
                ...data.products,
                ...deepCopy(data.productsDelivery),
                ...deepCopy(data.productsOperational)
            }).some((pr) => pr.quantity > 0);
            if (!hasProducts && !data.info.has_hazard) {
                toast({
                    severity : 'error',
                    summary  : 'Aucun produit sélectionné',
                    detail   : 'Veuillez sélectionner au moins un produit.'
                });
                return;
            }

            console.log('document', data.documents);
            const documents = await uploadFiles(data.documents.documents, 'collect');
            if (!documents) {
                toast({
                    severity : 'error',
                    summary  : 'Téléversement des documents échoué',
                    detail   : 'Le téléversement des documents a échoué, veuillez réessayer.'
                });
                return;
            }
            const dataWithUploadedDocuments: CompleteServiceCollectFormState = {
                ...data,
                documents: {
                    documents
                }
            };

            const method = 'POST';
            const url = data.info.has_hazard
                ? urlApiBuilder.collectServiceHazardPost()
                : urlApiBuilder.collectServicePost();
            const body = mapStateToApiCompleteData({ state: dataWithUploadedDocuments, cc: apiState!.db });

            setLoading(true);
            setOnRequestType('create');
            const response = await bbngRequest<CollectRo>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);
            setOnRequestType(undefined);
            if (response.success) {
                navigate(urlBuilder.collectView(response.response?.data.ro?.id ?? ''));
            }
        },
        [err, apiState, bbngRequest]
    );

    const formik = useFormik<CompleteServiceCollectFormState>({
        initialValues : initialState(getMiddleOfTheDay(apiState?.db.from_date)),
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (
            value: CollectModulesStates | AddressState,
            errors: CollectModulesErrorStates | string[] | null,
            id: string
        ): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response: StatelessResponse<CCServiceRoFront> = await fetchDataRelation(
            await bbngRequest<CCServiceRo>({
                method  : 'GET',
                url     : urlApiBuilder.collectConfigRead(dataId),
                options : { toastifySuccess: false }
            }),
            {
                collector_id : true,
                presta_id    : true
            }
        );

        if (response.success && response.response?.data.ro) {
            const ro: CCServiceRoFront = response.response?.data.ro;
            const formikState: CompleteServiceCollectFormState = mapCCApiDataToState(ro);

            setApiState({ form: formikState, db: ro });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        fetchData();
    }, []);

    const showDumpsterServiceSelect = useMemo(() => {
        return apiState?.db.type === EPlanningType.DUMPSTER;
    }, [apiState?.db?.type]);

    const showTrashTypeSelect = useMemo(() => {
        return (
            apiState?.db.type === EPlanningType.DUMPSTER &&
            formik.values.dumpsterService.service !== EOrderDumpsterServiceForm.ROTATION
        );
    }, [formik.values.trashType, apiState?.db?.type]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (errorCode || !apiState) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.collectHistory()} />
        ) : (
            <Error500 />
        );
    }

    const isCollectCreationForbidden = (
        [CC_STATUS.CANCELED, CC_STATUS.FINISHED, CC_STATUS.HAZARD, CC_STATUS.ORDER_TO_PAY] as CC_STATUS[]
    ).includes(apiState.db.status);

    if (isCollectCreationForbidden) {
        return (
            <PageForm>
                <h1>Terminaison de collecte</h1>
                <PageFormLine>
                    <Card>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                            <p style={{ fontWeight: 'bold' }}>
                                La configuration de collecte est dans un état qui ne permet pas de la terminer.
                            </p>
                            <Link to={urlBuilder.collectConfigView(dataId)}>
                                <Button>Voir collecte {apiState?.db?.number}</Button>
                            </Link>
                        </div>
                    </Card>
                </PageFormLine>
            </PageForm>
        );
    }

    return (
        <PageForm
            onSubmit={(e) => {
                setSMSModal(true);
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <h1>Terminaison de collecte</h1>
            <ActionsFormContainer
                archived={apiState?.db.archived || false}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                disableDeleteOnView={true}
            />
            <PageFormLine>
                <Collect.Info
                    id="info"
                    result={handleChange}
                    value={formik.values.info}
                    displayError={formik.submitCount > 0}
                    hideHazard={false}
                    hidePhoto={true}
                    category={PlanningShiftStepCategory.SERVICE}
                    day={getMiddleOfTheDay(apiState.db.from_date)}
                    disableMaxDay
                    editableDay
                    type={apiState.db.type}
                    terminated={false}
                    presta={apiState.db?.presta_id?.[0] as PrestaRo | undefined}
                />
            </PageFormLine>
            {showDumpsterServiceSelect && (
                <PageFormLine>
                    <Order.DumpsterService
                        displayError={formik.submitCount > 0}
                        id="dumpsterService"
                        result={handleChange}
                        value={formik.values.dumpsterService}
                    />
                </PageFormLine>
            )}
            {showTrashTypeSelect && (
                <Order.TrashType
                    displayError={formik.submitCount > 0}
                    id="trashType"
                    result={handleChange}
                    value={formik.values.trashType}
                />
            )}
            <PageFormLine>
                <Order.Products
                    id="products"
                    result={handleChange}
                    value={formik.values.products}
                    // readOnly={readOnly}
                    zoneId={apiState.db.zone.id}
                    type={mapPlanningTypeToOrderTypeForm(apiState.db.type)}
                    trashType={formik.values.trashType?.trashType}
                    service={formik.values.dumpsterService.service}
                    fetchOperational={false}
                    allowNoProducts={formik.values.info.has_hazard}
                />
            </PageFormLine>
            <PageFormLine>
                <Order.Products
                    label="Produits opérationnels"
                    id="productsOperational"
                    result={handleChange}
                    value={formik.values.productsOperational ? formik.values.productsOperational : {}}
                    zoneId={apiState.db.zone.id}
                    trashType={formik.values.trashType?.trashType}
                    service={formik.values.dumpsterService.service}
                    fetchOperational={true}
                    forceFetch={true}
                    type={mapPlanningTypeToOrderTypeForm(apiState.db.type)}
                    displayBigBagTrashTypeSelect={false}
                    forceSelectType="number"
                    allowNoProducts
                    forceOneProductOnly={false}
                />
            </PageFormLine>
            <PageFormLine>
                <Order.Products
                    label="Produits livrés sur place"
                    id="productsDelivery"
                    result={handleChange}
                    value={formik.values.productsDelivery ? formik.values.productsDelivery : {}}
                    type={EOrderTypeForm.DELIVERY}
                    retrieveDeliveries={true}
                    forceFetch
                    allowNoProducts
                />
            </PageFormLine>
            <PageFormLine>
                <Collect.Documents
                    id="documents"
                    result={handleChange}
                    value={formik.values.documents}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            <Collect.Notification
                id="notification"
                visible={smsModal}
                onCancel={() => setSMSModal(false)}
                onConfirm={() => {
                    formik.handleSubmit();
                    setSMSModal(false);
                }}
                result={handleChange}
                value={formik.values.notification}
                dataId={dataId}
            />
            <SubmitButton
                type="button"
                label="Valider"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSMSModal(true);
                }}
                disabled={(deepCheckEmptyObject(err) === false && formik.submitCount > 0) || isCollectCreationForbidden}
            />
        </PageForm>
    );
};

export default CompleteServiceCollectForm;
