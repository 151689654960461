import moment from 'moment';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import React, { useState } from 'react';

import { match } from '@bbng/util/misc';
import { CardErrors, IOpeningDay, IOpeningTime, ObjectKeys } from '@bbng/util/types';

import Button from '../Button';
import { CalendarHoursMin } from './Hours&Min';

export type OpenCloseProps = {
    readOnly: boolean;
    required: boolean;
    id: string;
    value?: IOpeningTime;
    result: (value: IOpeningTime, errors: null | string[] | IOpeningTime<CardErrors<IOpeningDay>>, id: string) => void;
    displayError?: boolean;
};

const days = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];
const _generateBaseValues = (): IOpeningTime => {
    return days.reduce((acc, day, idx) => {
        acc[idx as keyof IOpeningTime] = {
            day           : day,
            morning_open  : moment().startOf('day').set('hours', Number('08')).set('minutes', Number('00')).toISOString(),
            morning_close : moment()
                .startOf('day')
                .set('hours', Number('12'))
                .set('minutes', Number('00'))
                .toISOString(),
            afternoon_open: moment()
                .startOf('day')
                .set('hours', Number('12'))
                .set('minutes', Number('00'))
                .toISOString(),
            afternoon_close: moment()
                .startOf('day')
                .set('hours', Number('18'))
                .set('minutes', Number('00'))
                .toISOString(),
            is_closed : false,
            disabled  : false
        };
        return acc;
    }, {} as IOpeningTime);
};

const _generateBaseErrors = (): IOpeningTime<CardErrors<IOpeningDay>> => {
    return days.reduce((acc, day, idx) => {
        acc[idx as keyof IOpeningTime<CardErrors<IOpeningDay>>] = {
            day             : null,
            morning_open    : null,
            morning_close   : null,
            afternoon_open  : null,
            afternoon_close : null,
            is_closed       : null,
            disabled        : null
        };
        return acc;
    }, {} as IOpeningTime<CardErrors<IOpeningDay>>);
};

export const OpenClose = ({ readOnly, required, id, value, result, displayError }: OpenCloseProps) => {
    const [val, setVal] = useState<IOpeningTime>(value ? value : _generateBaseValues());
    const [err, setErr] = React.useState<IOpeningTime<CardErrors<IOpeningDay>>>(_generateBaseErrors());

    React.useEffect(() => {
        if (readOnly === false) {
            result(val, err, id);
        }
    }, []);

    const header = (
        <ColumnGroup>
            <Row>
                <Column header="Jour" rowSpan={2} align="center" />
                <Column header="Matin" colSpan={2} align="center" />
                <Column header="Après-midi" colSpan={2} align="center" />
                <Column header="Fermeture totale" rowSpan={2} />
            </Row>
            <Row>
                <Column header="Ouvre" align={'center'} />
                <Column header="Ferme" align={'center'} />
                <Column header="Ouvre" align={'center'} />
                <Column header="Ferme" align={'center'} />
            </Row>
        </ColumnGroup>
    );

    const handleChange = React.useCallback(
        (value: Date | boolean, errors: null | string[], childId: string, day: string) => {
            const data = {
                ...val,
                [days.indexOf(day) as ObjectKeys<IOpeningTime>]: {
                    ...val[days.indexOf(day) as keyof IOpeningTime],
                    [childId as keyof IOpeningDay as string] : value,
                    disabled                                 : childId === 'is_closed' ? value : false
                }
            };
            const dataError = checkIntegrity(data, err, childId, day);
            setVal(data);
            setErr(dataError);
            result(data, match(dataError, _generateBaseErrors) ? errors : dataError, id);
        },
        [val, err, setVal, setErr, id]
    );

    const bodyColumnTemplate = React.useCallback(
        (info: IOpeningDay, option: ColumnBodyOptions) => {
            if (option.field === 'is_closed') {
                return (
                    <Button.Switch
                        readOnly={readOnly}
                        value={info.is_closed as boolean}
                        id={option.field}
                        result={(v, e, i) => handleChange(v, e, i, info.day as string)}
                    />
                );
            } else {
                return (
                    <CalendarHoursMin
                        readOnly={readOnly ? readOnly : (info.disabled as boolean)}
                        required={required}
                        id={option.field}
                        result={(v, e, i) => handleChange(v, e, i, info.day as string)}
                        value={info[option.field as keyof Omit<IOpeningDay, 'is_closed' | 'disabled'>]}
                        errors={
                            err[days.indexOf(info.day as string) as keyof IOpeningTime<CardErrors<IOpeningDay>>][
                                option.field as keyof Omit<IOpeningDay, 'is_closed' | 'disabled'>
                            ]
                        }
                        displayError={displayError}
                    />
                );
            }
        },
        [val]
    );

    return (
        <DataTable size="small" value={Object.values(val)} headerColumnGroup={header}>
            <Column field="day" />
            <Column align={'center'} field="morning_open" body={bodyColumnTemplate} />
            <Column align={'center'} field="morning_close" body={bodyColumnTemplate} />
            <Column align={'center'} field="afternoon_open" body={bodyColumnTemplate} />
            <Column align={'center'} field="afternoon_close" body={bodyColumnTemplate} />
            <Column align={'center'} field="is_closed" body={bodyColumnTemplate} />
        </DataTable>
    );
};

const checkIntegrity = (
    data: IOpeningTime,
    dataError: IOpeningTime<CardErrors<IOpeningDay>>,
    id: string,
    day: string
): IOpeningTime<CardErrors<IOpeningDay>> => {
    const el = data[days.indexOf(day) as keyof IOpeningTime];
    let errorText = '';
    if (id === 'morning_open') {
        if (moment(moment(el[id]).toISOString()).isAfter(moment(el.morning_close).toISOString()))
            errorText = "L'heure d'ouverture du matin doit être avant l'heure de fermeture du matin";
        if (moment(moment(el[id]).toISOString()).isAfter(moment(el.afternoon_open).toISOString()))
            errorText = "L'heure d'ouverture du matin doit être avant l'heure d'ouverture de l'après-midi";
        if (moment(moment(el[id]).toISOString()).isAfter(moment(el.afternoon_close).toISOString()))
            errorText = "L'heure d'ouverture du matin doit être avant l'heure de fermeture de l'après-midi";
    }
    if (id === 'morning_close') {
        if (moment(moment(el[id]).toISOString()).isBefore(moment(el.morning_open).toISOString()))
            errorText = "L'heure fermeture du matin doit être après l'heure d'ouverture du matin";
        if (moment(moment(el[id]).toISOString()).isAfter(moment(el.afternoon_open).toISOString()))
            errorText = "L'heure de fermeture du matin doit être avant l'heure d'ouverture de l'après-midi";
        if (moment(moment(el[id]).toISOString()).isAfter(moment(el.afternoon_close).toISOString()))
            errorText = "L'heure de fermeture du matin doit être avant l'heure de fermeture de l'après-midi";
    }
    if (id === 'afternoon_open') {
        if (moment(moment(el[id]).toISOString()).isBefore(moment(el.morning_open).toISOString()))
            errorText = "L'heure d'ouverture de l'après-midi doit être après l'heure d'ouverture du matin";
        if (moment(moment(el[id]).toISOString()).isBefore(moment(el.morning_close).toISOString()))
            errorText = "L'heure d'ouverture de l'après-midi être après l'heure de fermeture du matin";
        if (moment(moment(el[id]).toISOString()).isAfter(moment(el.afternoon_close).toISOString()))
            errorText = "L'heure d'ouverture de l'après-midi être avant l'heure de fermeture de l'après-midi";
    }
    if (id === 'afternoon_close') {
        if (moment(moment(el[id]).toISOString()).isBefore(moment(el.morning_open).toISOString()))
            errorText = "L'heure de fermeture de l'après-midi doit être après l'heure d'ouverture du matin";
        if (moment(moment(el[id]).toISOString()).isBefore(moment(el.morning_close).toISOString()))
            errorText = "L'heure de fermeture de l'après-midi être après l'heure de fermeture du matin";
        if (moment(moment(el[id]).toISOString()).isBefore(moment(el.afternoon_open).toISOString()))
            errorText = "L'heure de fermeture de l'après-midi doit être après l'heure d'ouverture de l'après-midi";
    }

    return {
        ...dataError,
        [days.indexOf(day) as ObjectKeys<IOpeningTime>]: {
            ...dataError[days.indexOf(day) as keyof IOpeningTime],
            [id as keyof IOpeningDay as string]: errorText ? [errorText] : null
        }
    };
};
