import equal from 'fast-deep-equal/react';
import produce, { setAutoFreeze } from 'immer';
import moment from 'moment';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { match } from '@bbng/util/misc';
import { EPlanningType, PlanningRo, PlanningShiftStep, PRODUCT_DUMPSTER_TYPE, TruckRo } from '@bbng/util/types';

import { generateInitialErrorState } from '../../../common/form';
import { getMaximumEndDate, getMinimumStartDate, sortPlannings } from './helpers';
import { Grid, Legend, PlanningGrid, ShiftGrid } from './layout';
import { ShiftContent } from './shiftContent';
import {
    ShiftHeader,
    ShiftHeaderErrorState,
    ShiftHeaderState,
    initialState as initialShiftHeaderState
} from './shiftHeader';
import { PlanningContext } from '../../../context/Planning';
import { usePlanningV2Store } from '../../../hooks/PlanningStoreV2';
import { nanoid } from 'nanoid';

setAutoFreeze(false);

export type PlanningShiftsState = {
    [key: string]: ShiftHeaderState;
};
export type PlanningShiftsErrorState = {
    [key: string]: ShiftHeaderErrorState;
};

export const TIME_STEP = 1;

export const initialState = (
    planning: PlanningRo[],
    trucksByPlanning: Record<string, TruckRo>
): PlanningShiftsState => {
    return planning.reduce((acc, entry) => {
        const truck = trucksByPlanning[entry.id];
        if (!truck) {
            console.error("Can't find truck id", entry.id);
            return acc;
        }
        acc[truck.id] = initialShiftHeaderState;
        return acc;
    }, {} as PlanningShiftsState);
};

export const initialErrorState = (
    planning: PlanningRo[],
    trucksByPlanning: Record<string, TruckRo>
): PlanningShiftsErrorState => {
    return planning.reduce((acc, entry) => {
        const truck = trucksByPlanning[entry.id];
        if (!truck) {
            console.error("Can't find truck id", entry.id);
            return acc;
        }
        acc[truck.id] = generateInitialErrorState(initialShiftHeaderState);
        return acc;
    }, {} as PlanningShiftsErrorState);
};

export type PlanningShiftsProps = {
    id: string;
    value: {
        [key: string]: ShiftHeaderState;
    };
    readOnly: boolean;
    result: (value: PlanningShiftsState, errors: PlanningShiftsErrorState, id: string) => void;
    displayError?: boolean;
    zoom: number;
    type: EPlanningType;
};

export const PlanningShifts: React.FC<PlanningShiftsProps> = ({
    id,
    value,
    readOnly,
    result,
    displayError,
    zoom,
    type
}: PlanningShiftsProps): JSX.Element => {
    const [sortedAndFilteredPlanning, setSortedAndFilteredPlanning] = React.useState<PlanningRo[]>([]);
    const { plannings, trucksByPlanning, collects, isCompactMode, planningPageState, dumpsterTypes } =
        usePlanningV2Store();
    const [val, setVal] = React.useState<PlanningShiftsState>(
        value || initialState(Object.values(plannings), trucksByPlanning)
    );
    const [err, setErr] = React.useState<PlanningShiftsErrorState>(
        initialErrorState(Object.values(plannings), trucksByPlanning)
    );
    const [startDate, setStartDate] = React.useState<Date>(
        getMinimumStartDate(Object.values(plannings), Object.values(collects))
    );
    const [endDate, setEndDate] = React.useState<Date>(getMaximumEndDate(Object.values(plannings)));
    const [steps, setSteps] = React.useState<Record<string, (PlanningShiftStep & { id: string })[]>>({});

    React.useEffect(() => {
        if (plannings) {
            setStartDate(getMinimumStartDate(Object.values(plannings), Object.values(collects)));
            setEndDate(getMaximumEndDate(Object.values(plannings)));

            const newSteps = Object.values(plannings).reduce((acc, planning) => {
                const steps = ([] as PlanningShiftStep[]).concat(
                    planning.shift?.steps_break || [],
                    planning.shift?.steps_driver || [],
                    planning.shift?.steps_emptying || [],
                    planning.shift?.steps_administrative || [],
                    planning.shift?.steps_service || []
                );
                acc[planning.id] = steps.map((step) => ({ ...step, id: step.collect_id || nanoid() }));
                return acc;
            }, {} as Record<string, (PlanningShiftStep & { id: string })[]>);
            setSteps(newSteps);

            const filteredPlannings = Object.values(plannings).filter((planning) => {
                if (planningPageState.type === EPlanningType.BIG_BAG) {
                    return planning;
                } else {
                    const truck = trucksByPlanning[planning.id];
                    return truck.type.some((tpe) => dumpsterTypes.includes(tpe as PRODUCT_DUMPSTER_TYPE));
                }
            });

            setSortedAndFilteredPlanning(sortPlannings(filteredPlannings, trucksByPlanning));
        }
    }, [plannings, planningPageState, dumpsterTypes]);

    React.useEffect(() => {
        if (match(value, val) === false) {
            setVal(value);
        }
    }, [value]);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = React.useCallback(
        (value: any, errors: ShiftHeaderErrorState, childId: string) => {
            if (equal(value, val[childId as keyof PlanningShiftsState])) return;

            const newVal = produce(val, (draft) => {
                draft[childId as keyof PlanningShiftsState] = value;
            });
            setVal(newVal);

            if (equal(errors, err[childId as keyof PlanningShiftsErrorState])) return;
            setErr({ ...err, [childId]: errors } as PlanningShiftsErrorState);
        },
        [setVal, setErr, val]
    );

    const [legendPrecision, setLegendPrecision] = React.useState(5); // in minutes
    useEffect(() => {
        const newLegend = Math.floor(100 / zoom / 5) * 5;
        setLegendPrecision(newLegend < 5 ? 5 : newLegend > 60 ? 60 : newLegend);
    }, [zoom]);

    const rowLength = Math.floor(Math.abs(moment(startDate).diff(moment(endDate), 'minutes')) / TIME_STEP) + 1; // +1 for the shift config

    return (
        <Wrapper>
            <PlanningGrid columnLength={Object.keys(plannings).length} rowLength={rowLength} zoom={zoom}>
                <ShiftGrid
                    disableRows={isCompactMode}
                    columnLength={Object.keys(plannings).length}
                    rowLength={rowLength}
                    zoom={zoom}
                >
                    {!isCompactMode && (
                        <Legend
                            precision={legendPrecision}
                            start={moment(startDate).toISOString()}
                            end={moment(endDate).toISOString()}
                        />
                    )}
                </ShiftGrid>
                {Object.values(sortedAndFilteredPlanning).map((planning) => {
                    return (
                        <PlanningContext.Provider
                            key={planning.id}
                            value={{
                                collector_id : planning.collector_id[0],
                                truck_id     : planning.truck_id[0],
                                planning_id  : planning.id
                            }}
                        >
                            <ShiftGrid
                                disableRows={isCompactMode}
                                key={planning.id}
                                columnLength={Object.keys(sortedAndFilteredPlanning).length}
                                rowLength={rowLength}
                                zoom={zoom}
                            >
                                <ShiftHeader
                                    id={planning.truck_id[0]}
                                    planning_id={planning.id}
                                    readOnly={readOnly}
                                    result={handleChange}
                                    displayError={displayError}
                                    type={type}
                                    key={planning.id}
                                />
                                {!isCompactMode && (
                                    <Grid
                                        precision={legendPrecision}
                                        start={moment(startDate).toISOString()}
                                        end={moment(endDate).toISOString()}
                                    />
                                )}
                                {steps[planning.id]?.map(({ id, ...step }) => {
                                    return (
                                        <ShiftContent
                                            key={id}
                                            stepId={id}
                                            step={step}
                                            readOnly={readOnly}
                                            startDate={startDate}
                                        />
                                    );
                                })}
                            </ShiftGrid>
                        </PlanningContext.Provider>
                    );
                })}
            </PlanningGrid>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    box-sizing: border-box;
    height: 100%;
    max-width: 100vw;
    overflow-x: scroll;
`;
