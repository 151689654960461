import Joi from 'joi';
import moment from 'moment';

import { ISODate } from '../misc';
import { KafkaParamsQuerySchema } from '../misc.schema';
import {
    EPlanningCalculMethod,
    EPlanningRegion,
    EPlanningType,
    MAX_DIFF_BETWEEN_START_AND_END,
    PlanningGeneralConfig,
    PlanningShiftStepCategory
} from './planning';

const PlanningGeneralConfigSchema = Joi.object({
    dumpster_deposit_priority: Joi.when('dumpster_removal_priority', {
        is   : Joi.exist(),
        then : Joi.number()
            .required()
            .min(0)
            .max(100)
            .custom((value, helper) => {
                const { dumpster_removal_priority } = helper.state.ancestors[0] as PlanningGeneralConfig;

                if (dumpster_removal_priority + value !== 100) {
                    throw new Error('The sum of the priorities must be 100.');
                }

                return value;
            }),
        otherwise: Joi.forbidden()
    }),
    dumpster_removal_priority : Joi.number().min(0).max(100),
    maximum_volume            : Joi.number()
});

const PlanningShiftConfigSchema = Joi.object({
    start_date: Joi.date()
        .iso()
        .custom((value: ISODate, helpers) => {
            const endDate: moment.Moment = moment.utc(helpers.state.ancestors[0].end_date);
            const startDate: moment.Moment = moment.utc(value);
            const day = helpers.state.ancestors[3]?.day ?? helpers.state.ancestors[2]?.day;
            const configDate: moment.Moment = moment.utc(day);

            if (startDate.format('YYYY-MM-DD') !== configDate.format('YYYY-MM-DD')) {
                throw new Error('The start_date must be the same day as the config day.');
            }

            if (startDate.isAfter(endDate)) {
                throw new Error('The start_date must be before the end_date.');
            }

            if (startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
                throw new Error('The start_date must be the same day as the end_date.');
            }

            if (endDate.diff(startDate, 'hours') > MAX_DIFF_BETWEEN_START_AND_END) {
                throw new Error(
                    `Difference between start_date and end_date must be less than ${MAX_DIFF_BETWEEN_START_AND_END} hours.`
                );
            }

            return value;
        })
        .required(),
    end_date: Joi.date()
        .iso()
        .custom((value: ISODate, helpers) => {
            const endDate: moment.Moment = moment.utc(value);
            const startDate: moment.Moment = moment.utc(helpers.state.ancestors[0].start_date);
            const day = helpers.state.ancestors[3]?.day ?? helpers.state.ancestors[2]?.day;
            const configDate: moment.Moment = moment.utc(day);

            if (endDate.format('YYYY-MM-DD') !== configDate.format('YYYY-MM-DD')) {
                throw new Error('The end_date must be the same day as the config day.');
            }

            if (endDate.isBefore(startDate)) {
                throw new Error('The end_date must be after the start_date.');
            }

            if (endDate.format('YYYY-MM-DD') !== moment(startDate).format('YYYY-MM-DD')) {
                throw new Error('The end_date must be the same day as the start_date.');
            }

            return value;
        })
        .required()
});

const PlanningParametersSchema = Joi.object({
    planning_id       : Joi.string().required(),
    ccsService        : Joi.array().items(Joi.string()).required(),
    ccsAdministrative : Joi.array().items(Joi.string()).required(),
    shift_config      : PlanningShiftConfigSchema.required(),
    truck_id          : Joi.string().required(),
    collector_id      : Joi.string().required()
});
export const PlanningCalculateDtoSchema = Joi.object({
    day                           : Joi.date().iso().required(),
    type                          : Joi.string().required(),
    region                        : Joi.string().required(),
    general_config                : PlanningGeneralConfigSchema.required(),
    parameters                    : Joi.array().items(PlanningParametersSchema).required(),
    cc_service_to_plan_id         : Joi.array().items(Joi.string()).required(),
    cc_administrative_to_plan_id  : Joi.array().items(Joi.string()).required(),
    clusters                      : Joi.any(),
    redis_version_key             : Joi.string(),
    calcul_method                 : Joi.string().valid(...Object.values(EPlanningCalculMethod)),
    end_slot_margin_minutes       : Joi.number().min(0).optional(),
    optimization_duration_seconds : Joi.number().min(0).optional(),
    ignore_here                   : Joi.boolean().optional(),
    begin_slot_margin_minutes     : Joi.number().min(0).optional()
});

export const PlanningCreateDtoSchema = Joi.object({
    day    : Joi.date().iso().required(),
    type   : Joi.string().required(),
    region : Joi.string().required()
});

export const PlanningRemoveStepDtoSchema = PlanningCreateDtoSchema.concat(
    Joi.object({
        cc_id: Joi.string().required()
    })
);

export const PlanningGetPriceQuerySchema = PlanningCalculateDtoSchema;

export const PlanningGetAllQuerySchema = Joi.object({
    day  : Joi.date().iso().required(),
    type : Joi.string()
        .valid(...Object.keys(EPlanningType))
        .required(),
    region: Joi.string()
        .valid(...Object.keys(EPlanningRegion))
        .required(),
    sync              : Joi.boolean().optional(),
    redis_version_key : Joi.string()
}).concat(KafkaParamsQuerySchema);

export const PlanningExportQuerySchema = PlanningGetAllQuerySchema.concat(
    Joi.object({
        utcOffset: Joi.number().required()
    })
);

export const PlanningExportDtoSchema = Joi.object({
    ids: Joi.array().items(Joi.string()).required()
});

export const PlanningGetInstanceDtoSchema = Joi.object({
    ids     : Joi.array().items(Joi.string()).min(1).required(),
    isDraft : Joi.boolean().required()
});

export const PlanningStepCommonDtoSchema = Joi.object({
    collect_id             : Joi.string(),
    scheduled_service_time : Joi.string().required(),
    scheduled_at           : Joi.date()
        .iso()
        .custom((value: ISODate, helpers) => {
            const endDate: moment.Moment = moment.utc(helpers.state.ancestors[0].scheduled_end_at);
            const startDate: moment.Moment = moment.utc(value);

            if (startDate.isAfter(endDate)) {
                throw new Error('The start_date must be before the end_date.');
            }

            if (startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
                throw new Error('The start_date must be the same day as the end_date.');
            }

            return value;
        })
        .required(),
    scheduled_end_at: Joi.date()
        .iso()
        .custom((value: ISODate, helpers) => {
            const endDate: moment.Moment = moment.utc(value);
            const startDate: moment.Moment = moment.utc(helpers.state.ancestors[0].scheduled_at);

            if (endDate.isBefore(startDate)) {
                throw new Error('The end_date must be after the start_date.');
            }

            if (endDate.format('YYYY-MM-DD') !== moment(startDate).format('YYYY-MM-DD')) {
                throw new Error('The end_date must be the same day as the start_date.');
            }

            return value;
        })
        .required()
}).unknown(true);

export const PlanningUnassignDtoSchema = Joi.object({
    day                    : Joi.date().iso().required(),
    type                   : Joi.string().required(),
    region                 : Joi.string().required(),
    unassigned_planning_id : Joi.array().items(Joi.string()).min(1).required()
});

export const PlanningUnassignCCDtoSchema = Joi.object({
    day          : Joi.date().iso().required(),
    type         : Joi.string().required(),
    region       : Joi.string().required(),
    ccId         : Joi.string().required(),
    is_splitted  : Joi.boolean().required(),
    splitted_idx : Joi.number()
});

export const DumpsterToTakeSchema = Joi.object({
    nb_8m3  : Joi.number().required(),
    nb_15m3 : Joi.number().required(),
    nb_20m3 : Joi.number().required(),
    nb_30m3 : Joi.number().required()
});

export const PlanningShiftStepManualEmptyingSchema = Joi.object({
    id                     : Joi.string().required(),
    scheduled_service_time : Joi.string().required(),
    category               : Joi.string().valid(PlanningShiftStepCategory.EMPTYING).required(),
    landfill_id            : Joi.string().required(),
    dumpster_to_take       : DumpsterToTakeSchema.allow(null)
});

export const PlanningManualShiftDtoSchema = Joi.object({
    day                    : Joi.date().iso().required(),
    type                   : Joi.string().required(),
    region                 : Joi.string().required(),
    parameters             : PlanningParametersSchema.required(),
    ccAndEmptyingIdsSorted : Joi.array()
        .items(
            Joi.object({
                id           : Joi.string().required(),
                splitted_idx : Joi.number()
            })
        )
        .required(),
    emptyingSteps     : Joi.array().items(PlanningShiftStepManualEmptyingSchema).required(),
    redis_version_key : Joi.string(),
    calcul_method     : Joi.string().valid(...Object.values(EPlanningCalculMethod)),
    ignore_here       : Joi.boolean().optional()
});

export const PlanningSaveDtoSchema = Joi.object({
    day               : Joi.date().iso().required(),
    type              : Joi.string().required(),
    region            : Joi.string().required(),
    redis_version_key : Joi.string().required()
});
