import React, { useContext, useState } from 'react';

import { CCReplanActivityCreateDto, CCServiceRo, PlanningShiftStepService } from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import { useRequest } from '../../hooks/StatelessRequest';
import { PageForm, SubmitButton } from './style';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { PlanningContext, PlanningContextType } from '../../context/Planning';
import Input from '../../components/Inputs';
import { Message } from 'primereact/message';

type CollectConfigReplanFormProps = {
    setModalIsOpen: (isOpen: boolean) => void;
    data: PlanningShiftStepService;
    collector_id?: string;
    truck_id?: string;
    submitCallback?: () => void;
};

export const CollectConfigReplanForm: React.FC<CollectConfigReplanFormProps> = ({
    setModalIsOpen,
    data,
    collector_id,
    truck_id,
    submitCallback
}: CollectConfigReplanFormProps): JSX.Element => {
    const { planning_id } = useContext<PlanningContextType>(PlanningContext);
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');

    const { plannings, getPlannings, getCCAdministrative, getCCService } = usePlanningV2Store();

    const handleSubmit = async () => {
        setLoading(true);

        const planning = plannings[planning_id];

        if (!planning) {
            setLoading(false);
            return;
        }

        const response = await bbngRequest<CCServiceRo>({
            method  : 'POST',
            url     : urlApiBuilder.collectConfigReplan(),
            payload : {
                body: {
                    step         : data,
                    collector_id : collector_id ? [collector_id] : [],
                    truck_id     : truck_id ? [truck_id] : [],
                    comment
                } as CCReplanActivityCreateDto
            }
        });
        if (response.success && response.response?.data.ro) {
            /**
             * We need to fetch the plannings & ccs again to get the updated data
             */
            await Promise.all([
                getPlannings(bbngRequest, true),
                getCCAdministrative(bbngRequest),
                getCCService(bbngRequest)
            ]);
        }
        setModalIsOpen(false);
        setLoading(false);
        submitCallback && submitCallback();
    };

    if (loading) return <PageLoader loading />;

    return (
        <PageForm>
            <Input.Textarea
                id="comment"
                label="Commentaire"
                required={false}
                result={(val) => setComment(val)}
                value={comment}
                displayError={false}
                errors={[]}
                width="100%"
            />
            <Message severity="warn" text="En replanifiant une collecte, celle-ci sera retirée du planning actuel." />
            <SubmitButton type="submit" label="Replanifier" onClick={handleSubmit} />
        </PageForm>
    );
};
