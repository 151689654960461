import { CustomerAdmin, CustomerAdminProps } from './Admin';
import { CustomerBilling, CustomerBillingProps } from './Billing';
import { CustomerContacts, CustomerContactsProps } from './Contacts';
import { CustomerType, CustomerTypeProps } from './CustomerType';
import { CustomerDocuments, CustomerDocumentsProps } from './Documents';
import { CustomerGeneral, CustomerGeneralProps } from './General';
import { CustomerMandate, CustomerMandateProps } from './Mandate';
import { CustomerPapers, CustomerPapersProps } from './Papers';
import {
    CustomerShippingAndInvoiceAddresses,
    CustomerShippingAndInvoiceAddressesProps
} from './ShippingAndInvoiceAddresses';

const Modules = {
    GeneralInfo: (props: CustomerGeneralProps) => {
        return <CustomerGeneral {...props} />;
    },
    Billing: (props: CustomerBillingProps) => {
        return <CustomerBilling {...props} />;
    },
    ShippingAndInvoicesAddress: (props: CustomerShippingAndInvoiceAddressesProps) => {
        return <CustomerShippingAndInvoiceAddresses {...props} />;
    },
    Contacts: (props: CustomerContactsProps) => {
        return <CustomerContacts {...props} />;
    },
    Papers: (props: CustomerPapersProps) => {
        return <CustomerPapers {...props} />;
    },
    CustomerType: (props: CustomerTypeProps) => {
        return <CustomerType {...props} />;
    },
    Admin: (props: CustomerAdminProps) => {
        return <CustomerAdmin {...props} />;
    },
    Documents: (props: CustomerDocumentsProps) => {
        return <CustomerDocuments {...props} />;
    },
    Mandate: (props: CustomerMandateProps) => {
        return <CustomerMandate {...props} />;
    }
};

export default Modules;
