import Joi from 'joi';
import { CREATED_FROM } from '../misc';
import { PhoneE164Schema } from '../misc.schema';

const checkEmailDomain = (email: string) => {
    const isValidDomain = email.endsWith('@bigbagngo.com') || email.includes('@endless.fr');

    if (isValidDomain === false) {
        throw Error('email domain is invalid');
    }
};

export const AuthRefreshTokenDto = Joi.object({
    refresh_token: Joi.string().required()
});

export const SessionInfoSchema = Joi.object({
    ip        : Joi.string().required(),
    userAgent : Joi.string().required()
});

export const UserTypeSchema = Joi.string().valid('user', 'admin', 'collector', 'presta');

export const SessionCreateDtoSchema = Joi.object({
    user_id   : Joi.string().required(),
    user_type : UserTypeSchema.required(),
    info      : SessionInfoSchema.required()
});

export const InitiateLoginDtoSchema = Joi.object({
    email: Joi.string().email({ tlds: false }).required()
});

export const InitiateLoginAdminDtoSchema = Joi.object({
    email: Joi.string().email({ tlds: false }).custom(checkEmailDomain).required()
});

export const InitiateLoginQuerySchema = Joi.object({
    user_type          : UserTypeSchema.required(),
    throw_on_not_found : Joi.boolean().optional()
}).unknown(true);

export const LoginControllerDtoSchema = Joi.object({
    email        : Joi.string().email({ tlds: false }).required(),
    code         : Joi.string().required(),
    phone_number : PhoneE164Schema,
    firstname    : Joi.string(),
    lastname     : Joi.string(),
    info         : SessionInfoSchema.required(),
    created_from : Joi.string()
        .valid(...Object.values(CREATED_FROM))
        .optional()
});

export const LoginQuerySchema = Joi.object({
    user_type: UserTypeSchema.required()
}).unknown(true);

export const LoginControllerAdminDtoSchema = LoginControllerDtoSchema.concat(InitiateLoginAdminDtoSchema);
