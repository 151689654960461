import Joi from 'joi';

import { PhoneE164Schema } from '../misc.schema';

export const ContactDtoSchema = Joi.object({
    firstname    : Joi.string().allow(null, ''),
    lastname     : Joi.string().allow(null, ''),
    email        : Joi.string().email({ tlds: false }).allow(null, ''),
    phone_number : PhoneE164Schema.allow(null)
});

export const FeedContactDtoSchema = Joi.object({
    contacts: Joi.array().items(ContactDtoSchema.min(1)).min(1).required()
});
