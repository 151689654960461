import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { GMap } from 'primereact/gmap';
import React from 'react';

import { useGoogleMapsContext } from '../../context/GoogleMaps';

export type AddressMapButtonProps = {
    lat: number;
    lng: number;
    zoom?: number;
    draggable?: boolean;
    disable?: boolean;
    onDragChange?: (lat: number, lng: number) => void;
    readOnly?: boolean;
};

export const AddressMapButton: React.FC<AddressMapButtonProps> = ({
    lat,
    lng,
    zoom = 15,
    onDragChange,
    draggable = false,
    disable = false,
    readOnly = false,
    ...props
}: AddressMapButtonProps) => {
    const googleMaps = useGoogleMapsContext();
    const [showDialog, setShowDialog] = React.useState(false);
    const overlay = [
        ...(googleMaps.isReady
            ? [
                  new googleMaps.api.maps.Marker({
                      position: { lat, lng },
                      draggable
                  })
              ]
            : [])
    ];

    return (
        <>
            <Dialog header="Google Maps" resizable={false} visible={showDialog} onHide={() => setShowDialog(false)}>
                <GMap
                    style={{ width: '600px', maxWidth: 'calc(100vw - 200px)', height: '400px', maxHeight: '80vh' }}
                    onOverlayDragEnd={(props) => {
                        const event = props as {
                            originalEvent?: { latLng?: { lat?: () => number; lng?: () => number } };
                        };

                        if (event.originalEvent && event.originalEvent.latLng) {
                            if (onDragChange && event.originalEvent.latLng.lat && event.originalEvent.latLng.lng) {
                                const lat = event.originalEvent.latLng.lat();
                                const lng = event.originalEvent.latLng.lng();

                                onDragChange(lat, lng);
                            }
                        }
                    }}
                    overlays={overlay}
                    options={{
                        center: { lat, lng },
                        zoom
                    }}
                />
            </Dialog>
            <Button
                style={{
                    width   : '15px',
                    opacity : readOnly ? '90%' : '100%' //Don't put to much opacity so the user knows it's clickable
                }}
                className="p-button-sm"
                type="button"
                icon={`pi ${readOnly ? `pi-eye` : `pi-map-marker`}`}
                onClick={() => setShowDialog(true)}
                disabled={disable}
                {...props}
            />
        </>
    );
};
