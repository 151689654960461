import { MomentSetObject } from 'moment';

/**
 * order can be cancelled until 17h00 (Europe/Paris) the day before
 */
export const MAX_DAY_LIMIT_FOR_ORDER_CANCELLATION = 1;
export const MAX_TIME_LIMIT_FOR_ORDER_CANCELLATION: MomentSetObject = {
    hour        : 17,
    minute      : 0,
    second      : 0,
    millisecond : 0
};

/**
 * order can be made for the next day until 16h30
 */
export const MAX_TIME_LIMIT_FOR_ORDER_POST: MomentSetObject = {
    hour        : 16,
    minute      : 30,
    second      : 0,
    millisecond : 0
};
