import styled from 'styled-components';

import { PlanningShiftStepCategory } from '@bbng/util/types';

import { associateColorToStep } from '../../modules/planning/shifts/helpers';

export const PlanningHelpModal: React.FC = (): JSX.Element => {
    return (
        <div>
            <h3>Navigation</h3>
            <p>
                Pour naviguer sur le planning, un zoom (en haut à gauche) permet d'ajuster l'échelle de celui-ci. <br />
                Chaque ligne du planning correspond à un camion (auquel un chauffeur doit être associé). <br />
            </p>
            <h3>Collectes</h3>
            <h4>Code couleur</h4>
            <p>
                Chaque étape est representée par une carte dans un code couleur spécifique. <br />
                <List>
                    <ListItem>
                        <Square color={associateColorToStep(PlanningShiftStepCategory.SERVICE)} />
                        Les étapes de <b>service</b> (associées à une commande client)
                    </ListItem>
                    <ListItem>
                        <Square color={associateColorToStep(PlanningShiftStepCategory.ADMINISTRATIVE)} />
                        Les étapes <b>administratives</b>
                    </ListItem>
                    <ListItem>
                        <Square color={associateColorToStep(PlanningShiftStepCategory.DRIVER_HOUSE_END)} />
                        Les étapes de <b>domicile chauffeur</b> (son point de départ/arrivée)
                    </ListItem>
                    <ListItem>
                        <Square color={associateColorToStep(PlanningShiftStepCategory.EMPTYING)} />
                        Les étapes d'<b>évacuation</b> (en décharge)
                    </ListItem>
                    <ListItem>
                        <Square color={associateColorToStep(PlanningShiftStepCategory.BREAK)} />
                        Les <b>pauses</b>
                    </ListItem>
                </List>
            </p>
            <h4>Icônes</h4>
            <p>
                Chaque type de étapes est représentée par une icône pour faciliter la lecture du planning globale.{' '}
                <br />
                <List>
                    <ListItem>
                        <i className="pi pi-download" />
                        Les étapes de <b>dépôt</b>
                    </ListItem>
                    <ListItem>
                        <i className="pi pi-upload" />
                        Les étapes d'<b>enlèvement</b>
                    </ListItem>
                    <ListItem>
                        <i className="pi pi-clock" />
                        Les étapes d'<b>attente de chargement</b>
                    </ListItem>
                    <ListItem>
                        <i className="pi pi-sync" />
                        Les étapes de <b>rotation</b>
                    </ListItem>
                    <ListItem>
                        <i className="pi pi-sign-out" />
                        Les étapes d'<b>évacuation</b>
                    </ListItem>
                    <ListItem>
                        <i className="pi pi-cog" />
                        Les étapes <b>administratives</b>
                    </ListItem>
                    <ListItem>
                        <i className="pi pi-home" />
                        Les trajets <b>domicile-travail</b>
                    </ListItem>
                    <ListItem>
                        <i className="pi pi-pause" />
                        Les <b>pauses</b>
                    </ListItem>
                </List>
            </p>
            <h4>Volume à traiter</h4>
            <p>Pour les étapes de service, un chiffre est indiqué sous l'icône: il s'agit du volume à traiter.</p>
        </div>
    );
};

const Square = styled.span<{ color: string }>`
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: ${(props) => props.color};
`;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const ListItem = styled.li`
    display: flex;
    gap: 8px;
`;
