import { CollectConfigsAlreadyAvailable, CollectConfigsAlreadyAvailableProps } from './already-available';
import { CollectConfigsUnassignedOfTheDay } from './today';

const Modules = {
    CCUnassignedOfTheDay : CollectConfigsUnassignedOfTheDay,
    CCAlreadyAvailable   : (props: CollectConfigsAlreadyAvailableProps) => {
        return <CollectConfigsAlreadyAvailable {...props} />;
    }
};

export default Modules;
