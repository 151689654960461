import { Collect, Collect_Authz, Collect_History } from '@prisma/client/collect';

import { CCRo, CC_STATUS, ProductInCCOrCO } from '../collect-config';
import { CollectorRo } from '../collector';
import { DocumentCreateDto } from '../document';
import { IKafkaQuery } from '../kafka';
import { EmptyObject, ISODate, NdlssPrice } from '../misc';
import { LineCreateDto } from '../order';
import {
    BaseType,
    EPlanningRegion,
    EPlanningType,
    PlanningRo,
    PlanningShiftStepAdministrative,
    PlanningShiftStepBreak,
    PlanningShiftStepCategory,
    PlanningShiftStepDriver,
    PlanningShiftStepEmptying,
    PlanningShiftStepService
} from '../planning';
import { TruckRo } from '../truck';
import { AddressRo } from '../address';
import { LandfillRo } from '../landfill';
import { CustomerRo } from '../customer';
import { ConstructionSiteRo } from '../construction-site';
import { RelationsDto } from '../dto';
import { PrestaRo } from '../presta';

export type BaseCollectCreateDto = BaseType & {
    planning_id: string;
    truck_id: string;
    collector_id: string;
    arrived_at: ISODate;
    completed_at: ISODate;
    documents?: DocumentCreateDto[];
    category: PlanningShiftStepCategory;
    do_not_recalculate?: true;
};

export type BaseCollectCreateDtoSuccess = BaseCollectCreateDto & {
    status: typeof CC_STATUS.FINISHED;
};

export type BaseCollectCreateDtoHazard = BaseCollectCreateDto & {
    status: typeof CC_STATUS.HAZARD;
    hazard_reason: ECollectHazardReason;
    hazard_comment: string;
};

type CollectOperationalFields = 'planning_id' | 'truck_id' | 'collector_id';

export type CollectCreateServiceDto = RelationsDto<CollectService> & {
    presta_id?: string;
    planning_id?: string;
    truck_id?: string;
    collector_id?: string;
    signed_by?: string;
    dumpster_weight?: number | undefined;
    collected_items: LineCreateDto[];
    was_planned: true;
    step_service: PlanningShiftStepService;
    collect_config_id: string;
    is_splitted: boolean;
    notifyBySMS?: boolean;
    contactToNotify?: string[];
    delivery_number?: string;
} & (
        | Omit<BaseCollectCreateDtoSuccess, CollectOperationalFields>
        | Omit<BaseCollectCreateDtoHazard, CollectOperationalFields>
    );

export type CollectEditServiceDto = RelationsDto<CollectService> & {
    collected_items: LineCreateDto[];
    dumpster_weight?: number | undefined;
    delivery_number?: string;
    hazard_reason?: ECollectHazardReason;
    hazard_comment?: string;
    documents?: DocumentCreateDto[];
} & (
        | {
              status: typeof CC_STATUS.FINISHED;
          }
        | {
              status: typeof CC_STATUS.HAZARD;
              hazard_reason: ECollectHazardReason;
              hazard_comment: string;
          }
    );

export type CollectCreateServiceNotPlannedDto = Omit<
    CollectCreateServiceDto,
    'was_planned' | 'step_service' | CollectOperationalFields
> & {
    landfill_id?: string;
    was_planned: false;
    splitted_idx?: number;
    planning_id: string;
    truck_id: string;
    collector_id: string;
};

export type CollectCreateAdministativeDto = (BaseCollectCreateDtoSuccess | BaseCollectCreateDtoHazard) & {
    was_planned: true;
    collect_config_id: string;
    step_administrative: PlanningShiftStepAdministrative;
};

export type CollectCreateEmptyingDto =
    | ({
          was_planned: true;
          step_emptying: PlanningShiftStepEmptying;
          landfill_number: string;
          landfill_id: string;
          weight?: number;
      } & (BaseCollectCreateDtoSuccess | BaseCollectCreateDtoHazard))
    | ({
          was_planned: false;
          landfill_number: string;
          landfill_id: string;
          weight?: number;
      } & BaseCollectCreateDtoSuccess);

export type CollectCreateBreakDto = BaseCollectCreateDtoSuccess &
    BreakDuration &
    ({ was_planned: true; step_break: PlanningShiftStepBreak } | { was_planned: false });

export type CollectCreateDriverDto = BaseCollectCreateDtoSuccess & {
    was_planned: true;
    step_driver: PlanningShiftStepDriver;
    origin: 'start' | 'end';
};

export type CollectUpdateServiceActionDto = Partial<
    Pick<
        CollectRo,
        | 'informations'
        | 'document_id'
        | 'invoice_id'
        | 'invoicedOnE1'
        | 'invoicedOnE2'
        | 'invoicedOnE3'
        | 'delivery_number'
        | 'status'
        | 'hazard_comment'
        | 'hazard_reason'
    >
> & {
    informations?: {
        emptied_at_landfill_id?: string;
    };
};

export type CollectCreateBreakAction = BaseType & {
    status: typeof CC_STATUS.FINISHED;
    category: PlanningShiftStepCategory.BREAK;
    was_planned: boolean;
    informations: CollectRo['informations'];
    arrived_at: ISODate;
    completed_at: ISODate;
    truck: TruckRo;
    collector: CollectorRo;
    appears_on_list: true;
    planning: PlanningRo;
};

export type CollectCreateDriverAction = BaseType & {
    status: typeof CC_STATUS.FINISHED;
    category: PlanningShiftStepCategory.DRIVER_HOUSE_END | PlanningShiftStepCategory.DRIVER_HOUSE_START;
    was_planned: true;
    informations: CollectRo['informations'];
    arrived_at: ISODate;
    completed_at: ISODate;
    truck: TruckRo;
    collector: CollectorRo;
    appears_on_list: true;
    planning: PlanningRo;
};

export type CollectCreateEmptyingAction = BaseType & {
    status: typeof CC_STATUS.FINISHED | typeof CC_STATUS.HAZARD;
    category: PlanningShiftStepCategory.EMPTYING;
    was_planned: boolean;
    informations: CollectRo['informations'];
    arrived_at: ISODate;
    completed_at: ISODate;
    truck: TruckRo;
    collector: CollectorRo;
    appears_on_list: true;
    planning: PlanningRo;
};

export type CollectCreateServiceAction = BaseType & {
    category: PlanningShiftStepCategory.SERVICE;
    was_planned: true;
    informations: CollectRo['informations'];
    arrived_at: ISODate;
    completed_at: ISODate;
    truck?: TruckRo;
    collector?: CollectorRo;
    presta?: PrestaRo;
    appears_on_list: true;
    planning?: PlanningRo;
    document_id?: string[];
    invoice_id?: string[];
    status: typeof CC_STATUS.FINISHED | typeof CC_STATUS.HAZARD | typeof CC_STATUS.CANCELED;
    hazard_reason?: ECollectHazardReason;
    hazard_comment?: string;
    delivery_number?: string;
    invoicedOnE1?: boolean;
    invoicedOnE2?: boolean;
    invoicedOnE3?: boolean;
};

export type CollectCreateLateCancelationAction = BaseType & {
    id?: string;
    was_planned: false;
    category: PlanningShiftStepCategory.LATE_CANCELATION;
    informations: CollectRo['informations'];
    arrived_at: ISODate;
    completed_at: ISODate;
    truck: EmptyObject;
    collector: EmptyObject;
    appears_on_list: true;
    planning: EmptyObject;
    document_id?: string[];
    invoice_id?: string[];
    status: typeof CC_STATUS.CANCELED;
    invoicedOnE1?: boolean;
    invoicedOnE2?: boolean;
    invoicedOnE3?: boolean;
};

export type CollectCreateServiceNotPlannedAction = Omit<CollectCreateServiceAction, 'was_planned'> & {
    was_planned: false;
};

export type CollectCreateAdministrativeAction = BaseType & {
    status: typeof CC_STATUS.FINISHED | typeof CC_STATUS.HAZARD;
    category: PlanningShiftStepCategory.ADMINISTRATIVE;
    was_planned: true;
    informations: CollectRo['informations'];
    arrived_at: ISODate;
    completed_at: ISODate;
    truck: TruckRo;
    collector: CollectorRo;
    appears_on_list: true;
    planning: PlanningRo;
    document_id?: string[];
    hazard_reason?: ECollectHazardReason;
    hazard_comment?: string;
};

export type CollectRo = BaseType & {
    id: string;

    status: typeof CC_STATUS.FINISHED | typeof CC_STATUS.HAZARD | typeof CC_STATUS.CANCELED;
    category: PlanningShiftStepCategory;

    was_planned: boolean;
    planning: PlanningRo | EmptyObject;

    informations:
        | {
              step: PlanningShiftStepAdministrative;
              collect_config_id: string;
              step_category: PlanningShiftStepCategory.ADMINISTRATIVE; //Mostly for typing purposes (this is usless as we already have a category)
          }
        | {
              step: PlanningShiftStepService;
              collect_config_id: string;
              order_number: string; //we need to store this here so we can filter easily cc as we only have the id of cc
              cc_number: string; //we need to store this here so we can filter easily cc as we only have the id of cc
              prepaid: boolean; //we need to store this here so we can filter easily cc as we only have the id of cc
              collected_items: ProductInCCOrCO[];
              customer_id: string;
              emptied_at_landfill_id: string;
              signed_by: string;
              dumpster_weight: number;
              price: NdlssPrice;
              is_splitted: boolean;
              splitted_idx?: number;
              step_category: PlanningShiftStepCategory.SERVICE; //Mostly for typing purposes (this is usless as we already have a category)
          }
        | {
              step?: PlanningShiftStepEmptying;
              landfill_number: string;
              landfill_id: string;
              list_of_bc: string[];
              list_of_cc: string[];
              step_category: PlanningShiftStepCategory.EMPTYING; //Mostly for typing purposes (this is usless as we already have a category)
          }
        | {
              step: PlanningShiftStepDriver;
              origin: 'start';
              step_category: PlanningShiftStepCategory.DRIVER_HOUSE_START; //Mostly for typing purposes (this is usless as we already have a category)
          }
        | {
              step: PlanningShiftStepDriver;
              origin: 'end';
              step_category: PlanningShiftStepCategory.DRIVER_HOUSE_END; //Mostly for typing purposes (this is usless as we already have a category)
          }
        | ({
              step?: PlanningShiftStepBreak;
              step_category: PlanningShiftStepCategory.BREAK; //Mostly for typing purposes (this is usless as we already have a category)
          } & BreakDuration)
        | {
              collect_config_id: string;
              order_number: string;
              cc_number: string;
              collected_items: ProductInCCOrCO[];
              customer_id: string;
              price: NdlssPrice;
              step_category: PlanningShiftStepCategory.LATE_CANCELATION;
          };

    document_id: string[];

    invoice_id: string[];

    arrived_at: ISODate;
    completed_at: ISODate;

    truck: TruckRo | EmptyObject;
    collector: CollectorRo | EmptyObject;
    presta: PrestaRo | EmptyObject;

    hazard_reason: ECollectHazardReason | null;
    hazard_comment: string | null;

    delivery_number: string | null;

    invoicedOnE1: boolean;
    invoicedOnE2: boolean;
    invoicedOnE3: boolean;

    archived: boolean;
    created_by: string;
    created_at: ISODate;
    updated_at: ISODate;
};

export type CollectBreak = CollectRo & { informations: { step_category: PlanningShiftStepCategory.BREAK } };
export type CollectAdministrative = CollectRo & {
    informations: { step_category: PlanningShiftStepCategory.ADMINISTRATIVE };
};
export type CollectService = CollectRo & { informations: { step_category: PlanningShiftStepCategory.SERVICE } };
export type CollectEmptying = CollectRo & { informations: { step_category: PlanningShiftStepCategory.EMPTYING } };
export type CollectLateCancelation = CollectRo & {
    informations: { step_category: PlanningShiftStepCategory.LATE_CANCELATION };
};

export type CollectPrisma = Collect & { authorization: Collect_Authz; history: Collect_History };

export interface CollectQuery extends IKafkaQuery {
    min_date?: string; // filters collects after min_date
    max_date?: string; // filters collects before max_date
    day?: string; // filters collects on day
    planning_id?: string; // filters collects on planning
    type?: EPlanningType;
    region?: EPlanningRegion;
    status?: typeof CC_STATUS.FINISHED | typeof CC_STATUS.HAZARD | typeof CC_STATUS.CANCELED; // filters collects of a given status
    customer_id?: string; // filters collects of a given customer
    category?: PlanningShiftStepCategory;
    categories?: PlanningShiftStepCategory[];
    truck_id?: string; // filters collects of a given category
    order_number?: string; // filters collects of a given order
    cc_number?: string; // filters collects of a given cc
    prepaid?: boolean; // filter collect by prepaid key value
    collect_config_id?: string; // filters collects of a given collect config
    invoicedOnE1?: boolean; // Will get the collect invoiced on E1
    invoicedOnE2?: boolean; // Will get the collect invoiced on E2
    invoicedOnE3?: boolean; // Will get the collect invoiced on E2
    orderByAsc?: boolean; // Will order the collects by ascending order
    hazard_reason?: ECollectHazardReason; // Will get the collects with a given hazard reason
    presta?: 'exclude' | 'only' | 'include'; // Will get presta cc included or only presta cc or exclude presta cc
    presta_id?: string; // Will get the cc with the given presta_id (overwrites presta)
}

export enum ECollectHazardReason {
    /**
     * BB
     */
    NO_BAG = 'NO_BAG',
    BAD_FILLING = 'BAD_FILLING',
    DAMAGED = 'DAMAGED',
    ORDER_ERROR = 'ORDER_ERROR',

    /**
     * DUMPSTER
     */
    OBSTRUCTIVE_PARKING = 'OBSTRUCTIVE_PARKING',
    NO_ACCESS = 'NO_ACCESS',
    NO_SPACE = 'NO_SPACE',
    NOBODY_ON_SITE = 'NOBODY_ON_SITE',
    OVERLOADED_DUMPSTER = 'OVERLOADED_DUMPSTER',

    /**
     * COMMON
     */
    OTHER = 'OTHER',
    TO_REPLAN = 'TO_REPLAN'
}

export enum ECollectCharacteristic {
    NARROW_STREET = 'NARROW_STREET',
    BAG_INSIDE = 'BAG_INSIDE',
    ON_SIDEWALK = 'ON_SIDEWALK',
    ON_SCAFFOLD = 'ON_SCAFFOLD',
    DUMPSTER_AMPLIROLL = 'DUMPSTER_AMPLIROLL',
    DUMPSTER_CHAIN = 'DUMPSTER_CHAIN'
}

export type BreakDuration = {
    duration: 15 | 30 | 45;
};

export type LostDumpsterRo = {
    customer: {
        id: string;
        name: string;
        mainEmail?: string;
        mainPhone?: string;
    };
    constructionSite: {
        id: string;
        address: AddressRo;
    };
    minimalCollect: {
        id: string;
        informations: CollectService['informations'];
        completed_at: ISODate;
    };
    product: ProductInCCOrCO;
};

export interface MinimalInfoForDumpsterFindingQuery {
    collect_config_ids?: string[];
}

export interface GetCollectByCollectConfigIdsQuery {
    collect_config_ids: string[];
}

export interface GetEmptyingByPlanningIdsQuery {
    planning_ids: string[];
}

export interface FindDumpsterQuery extends IKafkaQuery {
    customer_id?: string;
    construction_site_id?: string;
    consider_planned_retrievals?: boolean;
}

export type MinimalInfoForDumpsterFindingRo = {
    id: string;
    informations: CollectService['informations'];
    completed_at: ISODate;
};

export type CollectWithRelationRo<CollectType extends CollectRo = CollectRo> = CollectType & {
    informations: CollectType['informations'] & {
        emptied_at_landfill: LandfillRo;
        customer: CustomerRo;
        collect_config: CCRo & {
            construction_site: ConstructionSiteRo;
        };
        presta?: PrestaRo;
    };
};
