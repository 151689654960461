import Joi from 'joi';

import { EDocumentType } from './document';

export const DocumentCreateDtoSchema = Joi.object({
    id   : Joi.string().required(),
    name : Joi.string().required(),
    type : Joi.string()
        .valid(...Object.keys(EDocumentType))
        .required(),
    mime_type   : Joi.string().required(),
    size        : Joi.number().required(),
    customer_id : Joi.array().items(Joi.string()),
    collect_id  : Joi.array().items(Joi.string()),
    order_id    : Joi.array().items(Joi.string())
});
