import React, { useEffect } from 'react';
import styled from 'styled-components';

import { CardErrors, EBillingBundle, EBillingMode, EBillingShipment, ECustomerCategory } from '@bbng/util/types';

import { mapBillingBundle, mapBillingMode, mapBillingShipment } from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import { useFormModule } from '../../hooks/FormModule';

export type CustomerBillingProps = {
    readOnly?: boolean;
    value?: CustomerBillingState;
    id: string;
    result: (
        value: CustomerBillingState,
        errors: null | string[] | CardErrors<CustomerBillingState>,
        id: string
    ) => void;
    displayError?: boolean;
    category?: ECustomerCategory;
    edit?: boolean;
};

export type CustomerBillingState = {
    billing_mode: EBillingMode;
    payment_delay: string;
    billing_bundle?: EBillingBundle;
    billing_shipment?: EBillingShipment;
};

export type CustomerBillingErrorState = CardErrors<CustomerBillingState>;

export const initialState: CustomerBillingState = {
    billing_mode     : EBillingMode.INSTANT,
    payment_delay    : '0',
    billing_bundle   : EBillingBundle.GLOBAL,
    billing_shipment : EBillingShipment.EMAIL
};

export const initialErrorState: CustomerBillingErrorState = generateInitialErrorState(initialState);

export const CustomerBilling = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false,
    category,
    edit
}: CustomerBillingProps) => {
    const [isEndOfMonthBilling, setIsEndOfMonthBilling] = React.useState(
        value.billing_mode === EBillingMode.END_OF_MONTH
    );
    const [isInstantBilling, setIsInstantBilling] = React.useState(value.billing_mode === EBillingMode.INSTANT);
    const { val, err, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    useEffect(() => {
        setIsEndOfMonthBilling(val.billing_mode === EBillingMode.END_OF_MONTH);
        setIsInstantBilling(val.billing_mode === EBillingMode.INSTANT);
    }, [val.billing_mode]);

    return (
        <StyledCard title="Information de paiement">
            <CardLine>
                <div>
                    <h3>Emission de facture</h3>
                    <Button.Select
                        forceSelection
                        id="billing_mode"
                        readOnly={readOnly || category !== ECustomerCategory.PRO}
                        required={true}
                        displayError={displayError}
                        result={handleChange}
                        options={Object.values(EBillingMode)}
                        value={val.billing_mode}
                        errors={err.billing_mode}
                        labelMapper={(value: string) => mapBillingMode(value as EBillingMode)}
                    />
                </div>
                {!isInstantBilling && (
                    <>
                        <div>
                            <h3>Délai de paiement</h3>
                            <Button.Select
                                forceSelection
                                id="payment_delay"
                                readOnly={readOnly || category !== ECustomerCategory.PRO}
                                required={true}
                                displayError={displayError}
                                result={handleChange}
                                options={['0', '7', '15', '30', '45']}
                                value={val.payment_delay}
                                errors={err.payment_delay}
                                labelMapper={(value: string) => (value === '0' ? 'Immédiat' : `${value}J`)}
                            />
                        </div>
                        <div>
                            <h3>Mode d'envoi</h3>
                            <Button.Select
                                forceSelection
                                id="billing_shipment"
                                readOnly={readOnly}
                                required={true}
                                displayError={displayError}
                                result={handleChange}
                                options={Object.values(EBillingShipment)}
                                value={val.billing_shipment}
                                errors={err.billing_shipment}
                                labelMapper={(value: string) => mapBillingShipment(value as EBillingShipment)}
                            />
                        </div>
                    </>
                )}
                {isEndOfMonthBilling && (
                    <div>
                        <h3>Groupement de facture</h3>
                        <Button.Select
                            forceSelection
                            id="billing_bundle"
                            readOnly={readOnly}
                            required={true}
                            displayError={displayError}
                            result={handleChange}
                            options={Object.values(EBillingBundle)}
                            value={val.billing_bundle}
                            errors={err.billing_bundle}
                            labelMapper={(value: string) => mapBillingBundle(value as EBillingBundle)}
                        />
                    </div>
                )}
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
    h3 {
        margin-top: 0;
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
