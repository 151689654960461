import React from 'react';
import styled from 'styled-components';

import { CardErrors, CustomerRo } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import { useFormModule } from '../../hooks/FormModule';
import RelationAutocomplete from '../common/RelationAutocomplete';

export type UserCustomerProps = {
    readOnly?: boolean;
    value?: UserCustomerState;
    id: string;
    result: (value: UserCustomerState, errors: null | string[] | CardErrors<UserCustomerState>, id: string) => void;
    displayError?: boolean;
};

export type UserCustomerState = {
    customer?: CustomerRo;
};
export type UserCustomerErrorState = CardErrors<UserCustomerState>;

export const initialState: UserCustomerState = {
    customer: undefined
};
export const initialErrorState: UserCustomerErrorState = generateInitialErrorState(initialState);

export const UserCustomer: React.FC<UserCustomerProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: UserCustomerProps) => {
    const { val, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    React.useEffect(() => {
        if (readOnly) return;

        const error = {
            customer: val.customer ? null : ['Le client est requis']
        };
        setErr(error);
    }, []);

    return (
        <StyledCard title="Client">
            <FullLineInput>
                <RelationAutocomplete.Customer
                    readOnly={readOnly}
                    placeholder="Rechercher par nom, SIREN ou SIRET"
                    baseValue={val.customer}
                    errors={err.customer}
                    displayError={displayError}
                    onSelect={(value) => {
                        handleChange(value, null, 'customer');
                    }}
                    onUnselect={() => {
                        handleChange(undefined, ['Le client est requis'], 'customer');
                    }}
                />
            </FullLineInput>
        </StyledCard>
    );
};

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)``;
