import { Accordion } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import styled from 'styled-components';

export const FiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    & .p-calendar {
        width: 300px;
    }
`;

export const TooltipEntry = styled.div<{ margin?: string }>`
    margin: ${(props) => props.margin || 0};
`;

export const StyledSpan = styled.span`
    display: flex;
    gap: 4px;
    align-items: center;
`;

export const StyledDropdown = styled(Dropdown)`
    width: 300px;
    height: 44px;
`;

export const StyledAccordion = styled(Accordion)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    & .p-accordion {
        width: 100%;
    }
    & .p-accordion-header-link {
        padding: 0 1.25em !important;
    }
    & .p-accordion-content {
        background: transparent;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 8px;
        & > div {
            gap: 20px;
            align-items: center;
        }
    }
    & .p-accordion-tab {
        width: 100%;
        background: transparent;
        margin: 0;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const StyleSpan = styled.span`
    padding: 12px;
    width: 100%;
    font-size: 1em;
    display: flex;
    align-items: center;
`;

export const RetrievalLine = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;
`;

export const RetrievalContainer = styled.div`
    display: flex;
    gap: 4px;
    flex-direction: column;
`;

export const DetailContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const Anchor = styled.a`
    word-break: break-all;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #343699;
`;

export const StyledFooter = styled.div`
    padding: 8px 16px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;

export const StyledDetails = styled.div`
    display: flex;
    gap: 8px;
`;

export const BoldDetail = styled.span`
    font-weight: 600;
`;
