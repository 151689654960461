import { Card, CardProps } from 'primereact/card';
import styled from 'styled-components';

export const ProductCardContent = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    .p-inputnumber-input {
        text-align: center;
        max-width: 56px;
    }
    .p-card-body {
        padding: 8px;
    }
`;

export const CardStyler = styled.span<{ color: string; selected: boolean; error: boolean }>`
    cursor: pointer;
    .p-card {
        border: ${({ error }) => (error ? 'solid 1px #e24c4c' : 'none')};
        border-top: solid 2px ${({ color }) => color};
        background: ${({ selected }) => selected && 'var(--green-100)'};
    }
`;

export const ProductCardData = styled.div`
    display: flex;
    flex-direction: column;
    margin: 4px 0;
`;

export const ProductHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: 16px;
`;

export const ProductPrimaryText = styled.div`
    font-size: 1em;
    font-weight: 400;
`;

export const ProductSecondaryText = styled.div`
    font-size: 0.8em;
    font-weight: 300;
    margin-bottom: 8px;
`;

export const ProductTertiaryText = styled.div`
    font-size: 0.75em;
    font-weight: 200;
    margin-bottom: 8px;
`;

export const ProductMain = styled.div`
    display: flex;
    justify-content: center;
    gap: 16px;
`;

export const ProductSecondary = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const DumpsterCardContainer = styled.div`
    text-align: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 16px;
`;

interface StyledCardProps extends CardProps {
    color: string;
    selected: boolean;
    error: boolean;
}
export const StyledCard: React.FC<StyledCardProps> = ({
    color,
    selected = false,
    error = false,
    ...props
}: StyledCardProps): JSX.Element => {
    return (
        <CardStyler color={color} selected={selected} error={error}>
            <ProductCardContent {...props} />
        </CardStyler>
    );
};
