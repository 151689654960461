import moment from 'moment';
import { nanoid } from 'nanoid';
import { Button as PRButton } from 'primereact/button';
import { PickListChangeParams } from 'primereact/picklist';
import React, { useEffect, useMemo } from 'react';

import {
    CC_FAMILY,
    PlanningCalculateDto,
    PlanningManualShiftDto,
    PlanningShiftStepCategory,
    PlanningRoFront,
    EPlanningCalculMethod,
    isCCService,
    PlanningShiftStepManualEmptying,
    PlanningRo,
    CC_STATUS,
    CCServiceRo,
    EPlanningType,
    ETruckType,
    PRODUCT_DUMPSTER_TYPE
} from '@bbng/util/types';

import { urlApiBuilder } from '../../../../common/urlBuilder';
import { PageLoader } from '../../../../components/PageLoader';
import { RequestProps, useRequest } from '../../../../hooks/StatelessRequest';
import { CollectConfigCard } from '../../../collect-configs-unassigned/CollectConfigCard';
import {
    EPlanningFormSubmitAction,
    mapApiDataToState,
    removeShiftsWithoutCollectorsOrUnavailable
} from '../../../planning-form/helpers';
import { initialState as planningSettingsInitialState } from '../../Config';
import { ShiftHeaderState } from '../shiftHeader';
import { CCOrManualEmptying, EmptyingCard, SourceHeaderTemplate, TargetHeaderTemplate, isCC } from './helpers';
import {
    ButtonContainer,
    DialogContainer,
    Header,
    ManualShiftButtonContainer,
    ManualShiftContainer,
    StyledDialog,
    StyledPickList,
    StyledTruckTypeTag
} from './style';
import { usePlanningV2Store } from '../../../../hooks/PlanningStoreV2';
import { getBorderFromStatus } from '../helpers';
import { CheckboxButton } from '../../../../components/Button/Checkbox';
import { mapDumpsterTypeToHexa, mapTruckType } from '../../../../common/enumMapper';
import { checkCCTruckCollectorCompatibility } from '@bbng/util/misc';

enum EManualPlanningAction {
    WITH_VRP = 'WITH_VRP',
    WITHOUT_VRP = 'WITHOUT_VRP'
}

export type ManuelShiftProps = {
    readOnly?: boolean;
    planningState: ShiftHeaderState;
    planning_id: string;
};
export const ManualShift: React.FC<ManuelShiftProps> = ({
    readOnly = false,
    planningState,
    planning_id
}: ManuelShiftProps): JSX.Element => {
    const {
        plannings,
        ccsAdministrative,
        ccsService,
        unnassignedCCService,
        unnassignedCCAdministrative,
        planningPageState,
        setCalculationTriggered,
        setLastAction,
        setPlanningPageState,
        setCalculatedPlanningsState,
        trucksByPlanning,
        collectorsByPlanning,
        fetchAll,
        collects,
        dumpsterTypes
    } = usePlanningV2Store();
    const planning = plannings[planning_id];
    const [ignoreHere, setIgnoreHere] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [dialogIsVisible, setDialogIsVisible] = React.useState(false);
    const truck = planningState.truck;
    const collector = planningState.collector;

    const [localSelectedCCAndEmptyings, setLocalSelectedCCAndEmptyings] = React.useState<CCOrManualEmptying[]>([]);
    const [localUnselectedCCAndEmptyings, setLocalUnselectedCCAndEmptyings] = React.useState<CCOrManualEmptying[]>(
        ([] as CCOrManualEmptying[]).concat(Object.values(ccsAdministrative), Object.values(ccsService))
    );
    const [localFilteredUnselectedCCAndEmptyings, setLocalFilteredUnselectedCCAndEmptyings] =
        React.useState<CCOrManualEmptying[]>(localUnselectedCCAndEmptyings);

    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    useEffect(() => {
        if (planning) {
            /**
             * This is done to initialize localSelectedCCAndEmptyings.
             * For emptying, we just take the existing step and add an id to identify it easily.
             * For service, we need to extract splitted_idx to know which splitted parts are used.
             */
            const planningSteps = [
                ...planning.shift.steps_service.map((step) => ({
                    ...step,
                    cc_id        : step.collect_config_id,
                    splitted_idx : (step as any).splitted_idx,
                    is_splitted  : (step as any).is_splitted
                })),
                ...planning.shift.steps_administrative.map((step) => ({
                    ...step,
                    cc_id        : step.collect_config_id,
                    splitted_idx : undefined
                })),
                ...planning.shift.steps_emptying.map((step) => ({
                    ...step,
                    id           : nanoid(),
                    splitted_idx : undefined
                }))
            ];
            planningSteps.sort((a, b) => (moment(a.scheduled_at).isSameOrBefore(moment(b.scheduled_at)) ? -1 : 1));

            const ccAndEmptyings = planningSteps
                .map<CCOrManualEmptying | boolean>((step) => {
                    const collect = step.collect_id ? collects[step.collect_id] : undefined;
                    if (step.category === PlanningShiftStepCategory.EMPTYING)
                        return {
                            ...step,
                            collect
                        };
                    const cc = ccsAdministrative[step.cc_id] || ccsService[step.cc_id];

                    if (!cc) return false;

                    return {
                        ...cc,
                        collect,
                        is_splitted  : (step as any).is_splitted,
                        splitted_idx : (step as any).splitted_idx
                    };
                })
                .filter((cc) => cc) as CCOrManualEmptying[];
            /**
             * Finished collects are always at the beginning of the target, ordered chronologicaly
             */
            const sortedCCAndEmptyings = ccAndEmptyings.sort((a, b) => {
                if (a.collect && b.collect) {
                    return moment(a.collect.arrived_at).isSameOrBefore(moment(b.collect.arrived_at)) ? -1 : 1;
                }
                if (a.collect) {
                    return -1;
                }
                if (b.collect) {
                    return 1;
                }
                return 0;
            });

            setLocalSelectedCCAndEmptyings(sortedCCAndEmptyings);
        }
    }, [planning, ccsAdministrative, ccsService, dialogIsVisible]);

    useEffect(() => {
        const unassignedAndCompatibleCcs = [...unnassignedCCAdministrative, ...unnassignedCCService].filter((cc) => {
            /**
             * If admin, we keep only ccs that have no collector or the same collector as the one selected
             */
            if (!isCCService(cc)) {
                return (
                    (cc.collector_id as any)[0]?.id === collector?.id ||
                    cc.collector_id[0] === collector?.id ||
                    cc.collector_id.length === 0
                );
            } else {
                return checkCCTruckCollectorCompatibility({
                    cc,
                    collector,
                    truck,
                    throwOnError: false
                });
            }
            return cc;
        });
        setLocalUnselectedCCAndEmptyings(unassignedAndCompatibleCcs);
    }, [unnassignedCCService, unnassignedCCAdministrative, dialogIsVisible, collector]);

    const handleChange = React.useCallback(
        (e: PickListChangeParams) => {
            let baseSource: CCOrManualEmptying[] = e.source;
            const baseTarget: CCOrManualEmptying[] = e.target;
            /**
             * Force elements with collect to be in the target
             */
            baseSource = baseSource.filter((cc: CCOrManualEmptying) => {
                if (cc.collect) {
                    baseTarget.push(cc);
                    return false;
                }
                return true;
            });
            /**
             * Finished collects are always at the beginning of the target, ordered chronologicaly
             */
            const orderedTarget = baseTarget.sort((a, b) => {
                if (a.collect && b.collect) {
                    return moment(a.collect.arrived_at).isSameOrBefore(moment(b.collect.arrived_at)) ? -1 : 1;
                }
                if (a.collect) {
                    return -1;
                }
                if (b.collect) {
                    return 1;
                }
                return 0;
            });
            /**
             * New source includes:
             * - all the elements of the new filtered source (baseSource) that are not in the previous source (localUnselectedCCAndEmptyings)
             * - all the elements of the previous source (localUnselectedCCAndEmptyings) that are not in the target
             */
            const newSource = baseSource.filter(
                (cc: CCOrManualEmptying) => !localUnselectedCCAndEmptyings.includes(cc)
            );
            newSource.push(...localUnselectedCCAndEmptyings.filter((cc) => !orderedTarget.includes(cc)));
            setLocalUnselectedCCAndEmptyings(newSource);
            setLocalSelectedCCAndEmptyings(orderedTarget);
        },
        [localFilteredUnselectedCCAndEmptyings, localUnselectedCCAndEmptyings, localSelectedCCAndEmptyings]
    );

    const handleCalculate = React.useCallback(
        async (type: EManualPlanningAction) => {
            setLoading(true);
            setCalculationTriggered(true);
            setLastAction(EPlanningFormSubmitAction.CALCULATE);

            let requestProps: RequestProps;
            if (type === EManualPlanningAction.WITHOUT_VRP) {
                const dto: PlanningManualShiftDto = {
                    ccAndEmptyingIdsSorted: localSelectedCCAndEmptyings
                        .filter((cc) => !cc.collect)
                        .map((cc) => ({
                            id           : cc.id,
                            splitted_idx : (cc as any).splitted_idx
                        })),
                    day        : planningPageState.day,
                    region     : planningPageState.region,
                    type       : planningPageState.type,
                    parameters : {
                        ccsAdministrative: localSelectedCCAndEmptyings
                            .filter((cc) => isCC(cc) && cc.family === CC_FAMILY.ADMINISTRATIVE)
                            .map((cc) => cc.id),
                        ccsService: localSelectedCCAndEmptyings
                            .filter((cc) => isCC(cc) && cc.family !== CC_FAMILY.ADMINISTRATIVE)
                            .map((cc) => cc.id),
                        collector_id : collector?.id as string,
                        truck_id     : truck?.id as string,
                        planning_id  : planning?.id as string,
                        shift_config : {
                            end_date   : planningState.end_date,
                            start_date : planningState.start_date
                        }
                    },
                    emptyingSteps: localSelectedCCAndEmptyings
                        .filter((cc) => !isCC(cc))
                        .map((emptying) => {
                            const castedEmptying = emptying as PlanningShiftStepManualEmptying;
                            return {
                                category               : PlanningShiftStepCategory.EMPTYING,
                                id                     : castedEmptying.id,
                                scheduled_service_time : castedEmptying.scheduled_service_time,
                                dumpster_to_take       : castedEmptying.dumpster_to_take,
                                landfill_id            : castedEmptying.landfill_id
                            };
                        }),
                    redis_version_key : planningPageState.redis_version_description.key,
                    ignore_here       : ignoreHere
                };
                requestProps = {
                    method  : 'PATCH',
                    url     : urlApiBuilder.planningManualShift(),
                    sync    : true,
                    payload : {
                        body: dto
                    }
                };
            } else {
                const dto: PlanningCalculateDto = {
                    day            : planningPageState.day,
                    region         : planningPageState.region,
                    type           : planningPageState.type,
                    general_config : {
                        dumpster_deposit_priority : planningSettingsInitialState.dumpster_deposit_priority,
                        dumpster_removal_priority : planningSettingsInitialState.dumpster_removal_priority,
                        maximum_volume            : planningSettingsInitialState.maximum_volume
                    },
                    cc_service_to_plan_id        : [],
                    cc_administrative_to_plan_id : [],
                    parameters                   : [
                        {
                            planning_id  : planning?.id as string,
                            truck_id     : truck?.id as string,
                            collector_id : collector?.id as string,
                            shift_config : {
                                start_date : planningState.start_date,
                                end_date   : planningState.end_date
                            },
                            ccsAdministrative: localSelectedCCAndEmptyings
                                .filter((cc) => isCC(cc) && cc.family === CC_FAMILY.ADMINISTRATIVE)
                                .map((cc) => cc.id),
                            ccsService: localSelectedCCAndEmptyings
                                .filter((cc) => isCC(cc) && cc.family !== CC_FAMILY.ADMINISTRATIVE)
                                .map((cc) => cc.id)
                        }
                    ],
                    redis_version_key : planningPageState.redis_version_description.key,
                    calcul_method     : EPlanningCalculMethod.Manual,
                    ignore_here       : ignoreHere
                };
                requestProps = {
                    method       : 'PATCH',
                    url          : urlApiBuilder.planningCalculate(),
                    sync         : false,
                    retryPolling : 1000,
                    payload      : {
                        body: dto
                    }
                };
            }

            const response = await bbngRequest<PlanningRoFront[]>(requestProps);

            if (response.success && response.response?.data.ro) {
                const calculatedPlannings = response.response.data.ro;

                const arrayCalculatedPlannings = Array.isArray(calculatedPlannings)
                    ? calculatedPlannings
                    : [calculatedPlannings];

                if (arrayCalculatedPlannings?.length > 0) {
                    const version = arrayCalculatedPlannings[0].planning_version_description;
                    version &&
                        setPlanningPageState((curr) => ({
                            ...curr,
                            redis_version_description: version
                        }));
                }

                await fetchAll(bbngRequest);

                /**
                 * All this is needed as calculatedPlanningState is used to block planning save button
                 */
                const upToDatePlannings = Object.values(plannings)
                    .filter((planning) => !arrayCalculatedPlannings.some((calcP) => calcP.id === planning.id))
                    .concat(arrayCalculatedPlannings as PlanningRo[]);
                const newState = mapApiDataToState(upToDatePlannings, trucksByPlanning, collectorsByPlanning);
                setCalculatedPlanningsState({
                    ...newState,
                    shifts: removeShiftsWithoutCollectorsOrUnavailable(newState.shifts)
                });
            }

            setLoading(false);
            setDialogIsVisible(false);
        },
        [bbngRequest, localSelectedCCAndEmptyings, planningPageState, setPlanningPageState, ignoreHere]
    );

    const typesToDisplay: ETruckType[] = useMemo(() => {
        if (!truck) return [];
        if (planningPageState.type === EPlanningType.DUMPSTER) {
            return truck.type.filter((tp) =>
                [ETruckType.DUMPSTER_AMPLIROLL, ETruckType.DUMPSTER_CHAIN, ETruckType.NARROW_STREET].includes(tp)
            );
        } else {
            return truck.type.filter((tp) => tp === ETruckType.NARROW_STREET);
        }
    }, [planningPageState.type, dumpsterTypes, truck]);

    return (
        <ManualShiftContainer>
            <PRButton
                disabled={readOnly || !collector}
                type="button"
                icon="pi pi-cog"
                className="p-button-warning p-button-outlined"
                label="Configuration manuelle"
                onClick={() => setDialogIsVisible(true)}
            />
            {loading ? (
                <PageLoader loading actionType="calculate_planning" />
            ) : (
                <StyledDialog
                    header={
                        <Header>
                            <span>
                                Configuration manuelle pour {truck?.name} | {collector?.fullname}
                            </span>
                            {typesToDisplay.map((type) => (
                                <StyledTruckTypeTag
                                    key={type}
                                    value={mapTruckType(type)}
                                    backgroundColor={
                                        type === ETruckType.NARROW_STREET
                                            ? '#FFA500'
                                            : mapDumpsterTypeToHexa(type as PRODUCT_DUMPSTER_TYPE)
                                    }
                                />
                            ))}
                        </Header>
                    }
                    visible={dialogIsVisible}
                    onHide={() => setDialogIsVisible(false)}
                >
                    <DialogContainer>
                        <StyledPickList
                            source={localFilteredUnselectedCCAndEmptyings}
                            target={localSelectedCCAndEmptyings}
                            sourceHeader={
                                <SourceHeaderTemplate
                                    dialogIsVisible={dialogIsVisible}
                                    localUnselectedCCs={localUnselectedCCAndEmptyings}
                                    setLocalFilteredUnselectedCCs={setLocalFilteredUnselectedCCAndEmptyings}
                                    planningState={planningState}
                                />
                            }
                            targetHeader={
                                <TargetHeaderTemplate
                                    setLocalSelectedCCAndEmptyings={setLocalSelectedCCAndEmptyings}
                                    localSelectedCCAndEmptyings={localSelectedCCAndEmptyings}
                                />
                            }
                            itemTemplate={(cc: CCOrManualEmptying) => {
                                if (isCC(cc)) {
                                    let status = cc.status;
                                    if (cc.is_splitted && cc.splitted_idx !== undefined) {
                                        status = (cc as CCServiceRo).splitted_informations[cc.splitted_idx].status;
                                    }
                                    return (
                                        <CollectConfigCard
                                            borderColor={getBorderFromStatus(status)}
                                            collect_config={cc}
                                            readOnly={true}
                                            showEdit={false}
                                            showMerge={false}
                                            showSplit={false}
                                            showCancel={false}
                                            showComplete={false}
                                            splittedIdx={cc.splitted_idx}
                                        />
                                    );
                                } else {
                                    return (
                                        <EmptyingCard
                                            borderColor={getBorderFromStatus(
                                                cc.collect ? CC_STATUS.FINISHED : undefined
                                            )}
                                            emptying={cc}
                                            setLocalSelectedCCAndEmptyings={setLocalSelectedCCAndEmptyings}
                                            localSelectedCCAndEmptyings={localSelectedCCAndEmptyings}
                                        />
                                    );
                                }
                            }}
                            onChange={handleChange}
                        />
                        <ButtonContainer>
                            <ManualShiftButtonContainer>
                                <PRButton
                                    label="Calculer avec la configuration actuelle"
                                    icon="pi pi-play"
                                    className="p-button-secondary"
                                    onClick={() => handleCalculate(EManualPlanningAction.WITH_VRP)}
                                />
                            </ManualShiftButtonContainer>
                            <CheckboxButton
                                label="Ignorer HERE.COM"
                                labelPosition="right"
                                id="ignore_here"
                                value={ignoreHere}
                                result={setIgnoreHere}
                            />
                            <ManualShiftButtonContainer>
                                <PRButton
                                    label="Forcer la tournée manuelle"
                                    icon="pi pi-bolt"
                                    className="p-button-secondary"
                                    onClick={() => handleCalculate(EManualPlanningAction.WITHOUT_VRP)}
                                />
                            </ManualShiftButtonContainer>
                        </ButtonContainer>
                    </DialogContainer>
                </StyledDialog>
            )}
        </ManualShiftContainer>
    );
};
