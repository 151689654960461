export type KpiQuery = {
    type: EKpiDashboardName;
};

export const EKpiDashboardName = {
    GLOBAL_BIG_BAG: 'global_big_bag',

    POSITION_BB_PARIS_TOMORROW : 'position_bb_paris_tomorrow',
    POSITION_BB_PARIS_TODAY    : 'position_bb_paris_today',

    DETAILS_BIG_BAG_TOMORROW : 'details_big_bag_tomorrow',
    DETAILS_BIG_BAG_TODAY    : 'details_big_bag_today',

    PLANNING_BIG_BAG_TOMORROW : 'planning_big_bag_tomorrow',
    PLANNING_BIG_BAG_TODAY    : 'planning_big_bag_today'
} as const;

export type EKpiDashboardName = typeof EKpiDashboardName[keyof typeof EKpiDashboardName];
