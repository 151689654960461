import { OverlayPanel } from 'primereact/overlaypanel';
import styled from 'styled-components';

export const FilterContainer = styled.div`
    display: flex;
    gap: 32px;
`;

export const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding-left: 8px;
    padding-bottom: 16px;
`;

export const StyledOverlayPanel = styled(OverlayPanel)`
    .p-overlaypanel-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 16px 40px;
    }
`;

export const FilterSectionTitle = styled.span`
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
`;

export const FilterGlobalTitle = styled.span`
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: light;
    text-align: center;
`;
