import styled from 'styled-components';

export const PageContainer = styled.div`
    position: relative;
    height: 100%;
    max-height: calc(100vh - 100px);
    display: grid;
    grid-template-rows: auto auto 1fr;
    align-items: stretch;
    padding: 20px 80px 10px 80px;
`;

export const Veil = styled.div`
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    z-index: 9999;
    position: fixed;
    width: 100vw;
    height: 100vh;
`;

export const PageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PageActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 16px;
    gap: 8px;
`;

export const RightHandedAction = styled.div`
    display: flex;
    align-items: center;
    & > * + * {
        margin-left: 6px;
    }
`;

export const PagePagination = styled.div`
    margin: 6px 0;
    display: flex;
    align-items: center;
    & > .pagination {
        margin-right: 48px;
    }
`;
