import React from 'react';
import styled from 'styled-components';

import { CardErrors, CustomerRo } from '@bbng/util/types';

import { Card } from '../../components/Card';
import { useFormModule } from '../../hooks/FormModule';
import RelationAutocomplete from '../common/RelationAutocomplete';
import Input from '../../components/Inputs';
import Button from '../../components/Button';

export type CustomerMandateProps = {
    readOnly?: boolean;
    value?: CustomerMandateState;
    id: string;
    result: (
        value: CustomerMandateState,
        errors: null | string[] | CardErrors<CustomerMandateState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type CustomerMandateState = {
    customer?: CustomerRo;
    email: string;
    firstname?: string;
    lastname?: string;
    createOnE1: boolean;
    createOnE2: boolean;
};
export type CustomerMandateErrorState = CardErrors<CustomerMandateState>;

export const initialState: CustomerMandateState = {
    customer   : undefined,
    createOnE1 : true,
    createOnE2 : true,
    email      : '',
    firstname  : '',
    lastname   : ''
};
export const initialErrorState: CustomerMandateErrorState = Object.fromEntries(
    Object.keys(initialState).map((k) => [k, null])
) as CustomerMandateErrorState;

export const CustomerMandate: React.FC<CustomerMandateProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: CustomerMandateProps) => {
    const { val, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    React.useEffect(() => {
        if (readOnly) return;

        const error = {
            customer: val.customer ? null : ['Le client est requis']
        };
        setErr((old) => ({
            ...old,
            ...error
        }));
    }, []);

    return (
        <StyledCard>
            <FullLineInput>
                <RelationAutocomplete.Customer
                    displayError={displayError}
                    errors={err.customer}
                    id="customer"
                    readOnly={readOnly}
                    baseValue={val.customer}
                    onSelect={(value) => {
                        handleChange(value, null, 'customer');
                    }}
                    onUnselect={() => {
                        handleChange(undefined, ['Le client est requis'], 'customer');
                    }}
                />
            </FullLineInput>
            <LineInput>
                <Input.Email
                    required={true}
                    label="Email sur lequel envoyer le mandat"
                    id="email"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.email}
                    errors={err.email}
                    displayError={displayError}
                />
                <Input.Text
                    required={false}
                    label="Prénom"
                    id="firstname"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.firstname ?? ''}
                    errors={err.firstname}
                    displayError={displayError}
                />
                <Input.Text
                    required={false}
                    label="Nom"
                    id="lastname"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.lastname ?? ''}
                    errors={err.lastname}
                    displayError={displayError}
                />
            </LineInput>
            <LineInput>
                <Button.Checkbox
                    label="Mandat sur Endless 1"
                    id="createOnE1"
                    labelPosition="right"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.createOnE1}
                />
                <Button.Checkbox
                    label="Mandat sur Endless 2"
                    id="createOnE2"
                    labelPosition="right"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.createOnE2}
                />
            </LineInput>
        </StyledCard>
    );
};

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)`
    flex: 2 !important;
    .subtitle {
        margin-top: 0;
        margin-bottom: 5px;
    }
`;

const LineInput = styled.div`
    padding: 24px 0 0;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
