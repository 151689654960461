import moment from 'moment';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import React, { useCallback, useEffect, useState } from 'react';
import { Button as PRButton } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import Dinero from 'dinero.js';

import { displayVolumeFromProducts, getMiddleOfTheDay, mapify } from '@bbng/util/misc';
import {
    CCServiceRo,
    CC_STATUS,
    CollectRoFront,
    CustomerRo,
    ECustomerCategory,
    EDocumentType,
    EPlanningRegion,
    EPlanningType,
    FrontRo,
    PRODUCT_FAMILY,
    PlanningShiftStepCategory,
    CollectQuery,
    ECollectHazardReason,
    ISODate,
    ProductInCCOrCO,
    PrestaPrice
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import {
    mapCollectCategoryText,
    mapCollectConfigFamilyText,
    mapCollectConfigStatus,
    mapCollectHazardReason,
    mapCollectStatus,
    mapPlanningConfigRegion,
    mapPlanningConfigType,
    mapTrashTypetext
} from '../../common/enumMapper';
import { COLLECT_ORCHESTRATION_BASE_URL, urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { isLateCancelation, isService } from '../Collect/helpers';
import { mapRegionToSeverity, mapStatusToIcon, mapStatusToSeverity } from '../CollectConfigs';
import { FiltersContainer, StyledAccordion, StyledSpan, StyleSpan, TitleContainer, TooltipEntry } from './style';
import { DataTableSortOrderType } from 'primereact/datatable';
import Calendar from '../../components/Calendar';
import { AccordionTab } from 'primereact/accordion';
import { toast } from '../../components/Toast';
import { defaultErrorToast } from '../../common/syncRequest';
import { axiosClient } from '../../common/axios';

const regionOptions = Object.values(EPlanningRegion).map((region) => ({
    name  : mapPlanningConfigRegion(region),
    value : region
}));

const typeOptions = Object.values(EPlanningType).map((type) => ({
    name  : mapPlanningConfigType(type),
    value : type
}));

const statusOptions = [CC_STATUS.FINISHED, CC_STATUS.HAZARD, CC_STATUS.CANCELED].map((status) => ({
    name  : mapCollectStatus(status),
    value : status
}));

const categoryOptions = Object.values([
    PlanningShiftStepCategory.ADMINISTRATIVE,
    PlanningShiftStepCategory.EMPTYING,
    PlanningShiftStepCategory.SERVICE
]).map((ctg) => ({
    name  : mapCollectCategoryText(ctg),
    value : ctg
}));

type QueryParams = {
    type?: EPlanningType;
    region?: EPlanningRegion;
    category?: PlanningShiftStepCategory;
    customer_id?: string;
    presta_id?: string;
    status?: CollectQuery['status'];
    hazard_reason?: ECollectHazardReason;
    min_date?: ISODate;
    max_date?: ISODate;
};

type ExportLoading = {
    byProduct: boolean;
    default: boolean;
};

export const PrestaCollectsListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [type, setType] = React.useState<EPlanningType | undefined>(query.type);
    const [presta_id, setPrestaId] = React.useState<string | undefined>(query.presta_id);
    const [region, setRegion] = React.useState<EPlanningRegion | undefined>(query.region);
    const [customer_id, setCustomerId] = React.useState<string | undefined>(query.customer_id);
    const [category, setCategory] = React.useState<PlanningShiftStepCategory | undefined>(query.category);
    const [status, setStatus] = React.useState<CollectQuery['status'] | undefined>(query.status);
    const [sortOrder, setSortOrder] = React.useState<DataTableSortOrderType>(-1); // DESC by default (most recent ones first)
    const [minDate, setMinDate] = React.useState<ISODate | undefined>(query.min_date ?? moment().toISOString());
    const [maxDate, setMaxDate] = React.useState<ISODate | undefined>(query.max_date ?? moment().toISOString());
    const [testHide, setTestHide] = React.useState<boolean>(false);

    const [isExportLoading, setIsExportLoading] = React.useState<ExportLoading>({
        byProduct : false,
        default   : false
    });

    const [triggerPageRefresh, setTriggerPageRefresh] = useState<boolean>(false);
    useEffect(() => {
        setTriggerPageRefresh(false);
    }, [triggerPageRefresh]);

    const exportData = useCallback(
        async (type: keyof ExportLoading) => {
            setIsExportLoading((old) => ({
                ...old,
                [type]: true
            }));

            try {
                const url =
                    type === 'byProduct'
                        ? urlApiBuilder.exportPrestaCollectByProduct()
                        : urlApiBuilder.exportPrestaCollectDefault();
                const response = await axiosClient.get(url, {
                    params: {
                        ...query
                    } as CollectQuery,
                    responseType: 'blob'
                });

                const formatedDay = moment().format('YYYY-MM-DD_HH:mm:ss');
                const name = `export-${type}-${formatedDay}`;

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(response.data);

                link.download = `${name}.xlsx`;
                link.click();
                link.remove();
            } catch (e) {
                defaultErrorToast("Une erreur est survenue lors de l'export des collectes");
                console.error(e);
            }
            setIsExportLoading((old) => ({
                ...old,
                [type]: false
            }));
        },
        [JSON.stringify(query)]
    );

    return (
        <>
            <Listing<CollectQuery, CollectRoFront>
                url={COLLECT_ORCHESTRATION_BASE_URL}
                endpoint="collects"
                hideCreateButton
                hideBulkMenu
                searchPlaceholder="Recherche par numéro de commande ou collecte"
                pageUrl={urlBuilder.collectHistory()}
                displaySelectColumn={false}
                displayActionColumn={false}
                triggerPageRefresh={triggerPageRefresh}
                enrichData={async (data) => {
                    data.data.ro = await fetchDataRelation(data.data.ro ?? [], {
                        customer_id       : true,
                        collect_config_id : true
                    });
                    return data;
                }}
                queryParams={{
                    type,
                    region,
                    customer_id,
                    category,
                    categories: [
                        PlanningShiftStepCategory.ADMINISTRATIVE,
                        PlanningShiftStepCategory.SERVICE,
                        PlanningShiftStepCategory.EMPTYING,
                        PlanningShiftStepCategory.LATE_CANCELATION
                    ],
                    max_date   : maxDate ? moment(maxDate).add(1, 'day').startOf('day').toISOString() : undefined, // max date is exclusive so include the next day for better UX
                    min_date   : minDate ? moment(minDate).startOf('day').toISOString() : undefined,
                    status,
                    orderByAsc : sortOrder === 1,
                    presta     : 'only',
                    presta_id
                }}
                sortOrder={sortOrder}
                sortField="arrived_at"
                onSort={(e) => {
                    setSortOrder(e.sortOrder);
                }}
                leftHandedComponent={
                    <StyledAccordion>
                        <AccordionTab
                            header={
                                <TitleContainer>
                                    <StyleSpan>Filtres et exports</StyleSpan>
                                </TitleContainer>
                            }
                        >
                            <FiltersContainer>
                                <Calendar.Range
                                    id=""
                                    value={minDate && maxDate ? [minDate, maxDate] : undefined}
                                    required={false}
                                    readOnly={false}
                                    result={(date) => {
                                        if (Array.isArray(date)) {
                                            /**
                                             * Needed to get the middle of the day to avoid timezone issues.
                                             */
                                            const date0 = moment(date[0]).toLocaleString();
                                            const middleStartDay = getMiddleOfTheDay(date0);
                                            const date1 = moment(date[1]).toLocaleString();
                                            const middleEndDay = getMiddleOfTheDay(date1);
                                            if (date?.length === 2) {
                                                /**
                                                 * Case when only the start date is set.
                                                 * Toast the user to select an end date.
                                                 * We cannot set the min and max date to the same value as we won't be able to select two dates then.
                                                 */
                                                if (date[1] == null) {
                                                    toast({
                                                        severity : 'info',
                                                        summary  : 'Sélectionnez une date de fin.',
                                                        detail   : 'Vous devez sélectionner une date de fin pour filtrer par période (pour sélectionner un seul jour, cliquez deux fois sur la même date).'
                                                    });
                                                    return;
                                                }
                                                setMinDate(middleStartDay);
                                                setMaxDate(middleEndDay);
                                            } else if (date?.length === 1) {
                                                setMinDate(middleStartDay);
                                                setMaxDate(middleStartDay);
                                            }
                                        }
                                    }}
                                    showTime={false}
                                    displayError={false}
                                />
                                <Dropdown
                                    value={type}
                                    onChange={(e) => setType(e.value)}
                                    placeholder="Filtrer par type"
                                    optionValue="value"
                                    optionLabel="name"
                                    showClear
                                    options={typeOptions}
                                />
                                <Dropdown
                                    value={region}
                                    onChange={(e) => setRegion(e.value)}
                                    placeholder="Filtrer par région"
                                    optionValue="value"
                                    optionLabel="name"
                                    showClear
                                    options={regionOptions}
                                />
                                <Dropdown
                                    value={category}
                                    onChange={(e) => setCategory(e.value)}
                                    placeholder="Filtrer par catégorie"
                                    optionValue="value"
                                    optionLabel="name"
                                    showClear
                                    options={categoryOptions}
                                />
                                <Dropdown
                                    value={status}
                                    onChange={(e) => setStatus(e.value)}
                                    placeholder="Filtrer par statut"
                                    optionValue="value"
                                    optionLabel="name"
                                    showClear
                                    options={statusOptions}
                                />
                                <RelationAutocomplete.Presta
                                    placeholder="Filtrer par prestataire"
                                    onSelect={(presta) => setPrestaId(presta?.id)}
                                    onUnselect={() => setPrestaId(undefined)}
                                />
                                <RelationAutocomplete.Customer
                                    placeholder="Filtrer par client"
                                    onSelect={(customer) => setCustomerId(customer?.id)}
                                    onUnselect={() => setCustomerId(undefined)}
                                />
                                <PRButton
                                    className="p-button-info"
                                    type="button"
                                    loading={isExportLoading.byProduct}
                                    loadingIcon="pi pi-spin pi-spinner"
                                    label="Export par produit"
                                    icon="pi pi-file-excel"
                                    tooltipOptions={{ position: 'left' }}
                                    onClick={() => exportData('byProduct')}
                                />
                                <PRButton
                                    type="button"
                                    loading={isExportLoading.default}
                                    loadingIcon="pi pi-spin pi-spinner"
                                    label="Export par défaut"
                                    icon="pi pi-file-excel"
                                    tooltipOptions={{ position: 'left' }}
                                    onClick={() => exportData('default')}
                                />
                            </FiltersContainer>
                        </AccordionTab>
                        <InputSwitch checked={testHide} onChange={(e) => setTestHide(e.value)} />
                    </StyledAccordion>
                }
                title={'Prestations externes effectuées'}
                name="Collecte"
                pluralName="Collectes"
                headers={[
                    {
                        name      : 'Numéro collecte',
                        field     : 'informations',
                        component : (data: CollectRoFront) => {
                            const serviceData = isService(data) || isLateCancelation(data) ? data : undefined;
                            return (
                                <a href={urlBuilder.collectView(data.id)}>
                                    {serviceData?.informations?.cc_number ?? 'N/A'}
                                </a>
                            );
                        }
                    },
                    {
                        name      : 'Client',
                        field     : 'informations',
                        component : (data: CollectRoFront) => {
                            const serviceData = isService(data) ? data : undefined;
                            if (!serviceData) return <span>N/A</span>;
                            const customer = serviceData.informations.customer_id as CustomerRo;
                            const isIndividual = customer?.category === ECustomerCategory.INDIVIDUAL;
                            const individualIcon = <i className="pi pi-user" />;
                            return (
                                <>
                                    <Tooltip
                                        position="top"
                                        target={`.customer-${data.id.replace(':', '')}`}
                                        content={isIndividual ? 'Particulier' : undefined}
                                    />
                                    <span className={`customer-${data.id.replace(':', '')}`}>
                                        {customer?.name} {isIndividual && individualIcon}
                                    </span>
                                </>
                            );
                        }
                    },
                    {
                        name      : 'Prestataire',
                        field     : 'presta',
                        component : (data: CollectRoFront) => {
                            if (data.presta) {
                                return (
                                    <a href={urlBuilder.prestaView(data.presta.id)} target="_blank" rel="noreferrer">
                                        {data.presta.name}
                                    </a>
                                );
                            } else {
                                return '';
                            }
                        }
                    },
                    {
                        name      : 'Prestation',
                        field     : 'informations',
                        component : (data: CollectRoFront) => <span>{mapCollectServiceType(data)}</span>
                    },
                    {
                        name      : 'Région',
                        field     : 'region',
                        component : (data: FrontRo<CollectRoFront>) => (
                            <Tag
                                className="mr-2"
                                value={mapPlanningConfigRegion(data.region)}
                                severity={mapRegionToSeverity(data.region)}
                            />
                        )
                    },
                    {
                        name      : 'Passage',
                        field     : 'completed_at',
                        component : (data: FrontRo<CollectRoFront>) => (
                            <span>
                                {moment(data.arrived_at).format('DD/MM/YYYY')} {moment(data.arrived_at).format('HH:mm')}{' '}
                                - {moment(data.completed_at).format('HH:mm')}
                            </span>
                        )
                    },
                    {
                        name      : `Volume collecté (prévu)`,
                        field     : 'informations',
                        component : (data: CollectRoFront) => {
                            const serviceData = isService(data) ? data : undefined;
                            const cc = serviceData?.informations?.collect_config_id as CCServiceRo | undefined;
                            if (
                                !serviceData ||
                                serviceData.status === CC_STATUS.HAZARD ||
                                serviceData.category === PlanningShiftStepCategory.ADMINISTRATIVE ||
                                !cc
                            )
                                return <span>N/A</span>;

                            const productsWithoutDelivery = serviceData?.informations?.collected_items?.filter(
                                (p) => p.family !== PRODUCT_FAMILY.DELIVERY_BIG_BAG
                            );
                            const collectedVolume = displayVolumeFromProducts(productsWithoutDelivery, cc?.family);
                            const volumeToCollect = displayVolumeFromProducts(cc?.products, cc?.family);
                            return (
                                <>
                                    <Tooltip position="top" target={`.volume-${serviceData?.id.replace(':', '')}`}>
                                        <TooltipEntry>
                                            Réalisé:
                                            {serviceData?.informations?.collected_items.map((p) => (
                                                <TooltipEntry key={p.id} margin="4px 0 0">
                                                    {p.quantity} x {mapCollectConfigFamilyText(p.family, true)} -{' '}
                                                    {p.volume_m3}m3
                                                </TooltipEntry>
                                            ))}
                                        </TooltipEntry>
                                        <Divider />
                                        <TooltipEntry>
                                            Prévu:
                                            {cc.products.map((p) => (
                                                <TooltipEntry key={p.id} margin="4px 0 0">
                                                    {p.quantity} x {mapCollectConfigFamilyText(p.family, true)} -{' '}
                                                    {p.volume_m3}m3
                                                </TooltipEntry>
                                            ))}
                                        </TooltipEntry>
                                    </Tooltip>
                                    <StyledSpan className={`volume-${serviceData.id.replace(':', '')}`}>
                                        {collectedVolume} ({volumeToCollect})
                                        <i className="pi pi-eye" />
                                    </StyledSpan>
                                </>
                            );
                        }
                    },
                    {
                        name      : 'Prix Endless (HT)',
                        field     : 'endless-price' as keyof CollectRoFront,
                        component : (data: CollectRoFront) => {
                            if (
                                isService(data) === false ||
                                data.informations.step_category !== PlanningShiftStepCategory.SERVICE
                            ) {
                                return <span>N/A</span>;
                            }

                            const productRegistry = mapify(data.informations.collected_items, 'id');

                            return (
                                <span>
                                    <Tooltip position="top" target={`.price-endless-${data.id}`}>
                                        {data.informations.price.price_per_product.map((p) => {
                                            const product = productRegistry[p.product_id];
                                            return (
                                                <TooltipEntry margin="4px 0 0" key={p.product_id}>
                                                    {product.quantity} x {product.name} -{' '}
                                                    {Dinero(p.discounted_net).toFormat('$0.00')}
                                                </TooltipEntry>
                                            );
                                        })}
                                    </Tooltip>
                                    <StyledSpan className={`price-endless-${data.id}`}>
                                        {Dinero({
                                            amount   : data.informations.price.discounted_net.amount,
                                            currency : data.informations.price.discounted_net.currency
                                        }).toFormat('$0.00')}
                                        <i className="pi pi-eye" />
                                    </StyledSpan>
                                </span>
                            );
                        }
                    },
                    {
                        name      : 'Prix prestataire (HT)',
                        field     : 'endless-price' as keyof CollectRoFront,
                        component : (data: CollectRoFront) => {
                            if (
                                isService(data) === false ||
                                data.informations.step_category !== PlanningShiftStepCategory.SERVICE
                            ) {
                                return <span>N/A</span>;
                            }

                            const info = data.informations;
                            const presta = data.presta;
                            const prestaProductRegistry = mapify(presta.price_list, 'product_id');
                            const productsTuples: Array<[ProductInCCOrCO, PrestaPrice]> = info.collected_items.map(
                                (p) => [p, prestaProductRegistry[p.id] ?? null]
                            );

                            if (productsTuples.some(([_, prd]) => prd === null)) {
                                return (
                                    <div>
                                        <Tag
                                            className="mr-2"
                                            value={'Prix non défini'}
                                            severity={'danger'}
                                            icon={'pi pi-exclamation-circle'}
                                        />
                                    </div>
                                );
                            }

                            const price = productsTuples.reduce((acc, [prd, prdPresta]) => {
                                return acc + prdPresta.price_in_centime * prd.quantity;
                            }, 0);

                            return (
                                <span>
                                    <Tooltip position="top" target={`.price-presta-${data.id}`}>
                                        {productsTuples.map(([product, prestaPrd]) => {
                                            const dineroOpt: Dinero.Options = {
                                                amount   : prestaPrd.price_in_centime * product.quantity,
                                                currency : info.price.discounted_net.currency
                                            };
                                            return (
                                                <TooltipEntry margin="4px 0 0" key={product.id}>
                                                    {product.quantity} x {product.name} -{' '}
                                                    {Dinero(dineroOpt).toFormat('$0.00')}
                                                </TooltipEntry>
                                            );
                                        })}
                                    </Tooltip>
                                    <StyledSpan className={`price-presta-${data.id}`}>
                                        {Dinero({
                                            amount   : price,
                                            currency : info.price.discounted_net.currency
                                        }).toFormat('$0.00')}
                                        <i className="pi pi-eye" />
                                    </StyledSpan>
                                </span>
                            );
                        }
                    },

                    {
                        name      : 'Typologie',
                        field     : 'informations',
                        component : (data: CollectRoFront) => {
                            if (data.informations.step_category !== PlanningShiftStepCategory.SERVICE) {
                                return <span>N/A</span>;
                            }
                            const trashType = Array.from(
                                new Set(data.informations.collected_items.map((e) => e.trash_type))
                            );

                            return <StyledSpan>{trashType.map((e) => mapTrashTypetext(e)).join(', ')}</StyledSpan>;
                        }
                    },
                    {
                        name      : 'Photos jointes',
                        field     : 'document_id',
                        component : (data: CollectRoFront) => {
                            const collectPhotos = (data.document_id as string[]).filter((id) =>
                                id.includes(EDocumentType.COLLECT_PHOTO)
                            );
                            return <span>{collectPhotos.length}</span>;
                        }
                    },
                    {
                        name      : 'Clôture de collecte',
                        field     : 'hazard_reason',
                        component : (data: CollectRoFront) => {
                            if (data.status !== CC_STATUS.HAZARD) return <span>N/A</span>;
                            return <span>{mapCollectHazardReason(data.hazard_reason ?? undefined)}</span>;
                        }
                    },
                    {
                        name      : 'Statut',
                        field     : 'status',
                        component : (data: FrontRo<CollectRoFront>) => {
                            return (
                                <span className={`status-${data.id.replace(':', '')}`}>
                                    <Tag
                                        className="mr-2"
                                        value={mapCollectConfigStatus(data.status)}
                                        severity={mapStatusToSeverity(data.status)}
                                        icon={mapStatusToIcon(data.status)}
                                    />
                                </span>
                            );
                        }
                    }
                ]}
            />
        </>
    );
};

const mapCollectServiceType = (collect: CollectRoFront) => {
    switch (collect.informations.step_category) {
        case PlanningShiftStepCategory.ADMINISTRATIVE:
            return 'Administratif';
        case PlanningShiftStepCategory.SERVICE: {
            const cc = collect.informations.collect_config_id as CCServiceRo;
            return mapCollectConfigFamilyText(cc.family);
        }
        case PlanningShiftStepCategory.EMPTYING:
            return 'Vidage';
        case PlanningShiftStepCategory.LATE_CANCELATION:
            return 'Annulation tardive';
        default:
            return 'N/A';
    }
};
