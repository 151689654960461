import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import { LandfillRo } from '@bbng/util/types';

import { ActionsFormContainer } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import SnackBar from '../../components/SnackBar';
import { toast } from '../../components/Toast';
import { useRequest } from '../../hooks/StatelessRequest';
import { Address, AddressState } from '../../modules/common/Address';
import Landfill from '../../modules/landfill-center';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    LandfillFormErrorState,
    LandfillFormState,
    LandfillModulesErrorStates,
    LandfillModulesStates,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiCreateData,
    mapStateToApiEditData
} from './helpers';
import { PageForm, PageFormLine, SubmitButton } from './style';
import equal from 'fast-deep-equal/react';

type LandfillFormProps = {
    edit?: boolean;
    readOnly?: boolean;
};

const LandfillForm: React.FC<LandfillFormProps> = ({
    edit = false,
    readOnly = false
}: LandfillFormProps): JSX.Element => {
    const { id: paramsId } = useParams();
    const navigate = useNavigate();
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [apiState, setApiState] = React.useState<{ form: LandfillFormState; db: LandfillRo }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [fetchError, setFetchError] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(edit || readOnly);
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>(
        edit || readOnly ? 'read' : undefined
    );

    const [err, setErr] = useState<LandfillFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<LandfillFormState>['onSubmit']>(
        async (data: LandfillFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            if (data.contacts.contactList.length === 0) {
                toast({
                    severity : 'error',
                    summary  : 'Formulaire invalide',
                    detail   : "Aucun contact n'a été renseigné."
                });
                return;
            }

            const method = edit ? 'PATCH' : 'POST';
            const url = edit ? urlApiBuilder.landfillEdit(dataId) : urlApiBuilder.landfillCreate();
            const body = edit
                ? mapStateToApiEditData(data, apiState!.form, apiState!.db)
                : mapStateToApiCreateData(data);
            setLoading(true);
            setOnRequestType('create');

            const response = await bbngRequest<LandfillRo>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);
            setOnRequestType(undefined);
            if (response.success) {
                navigate(urlBuilder.landfillList());
            }
        },
        [err, apiState, bbngRequest]
    );

    const formik = useFormik<LandfillFormState>({
        initialValues : initialState,
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (
            value: LandfillModulesStates | AddressState,
            errors: LandfillModulesErrorStates | string[] | null,
            id: string
        ): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response = await bbngRequest<LandfillRo>({
            method  : 'GET',
            url     : urlApiBuilder.landfillGet(dataId),
            options : { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            const ro: LandfillRo = response.response?.data.ro;
            const formikState: LandfillFormState = mapApiDataToState(ro);

            setApiState({ form: formikState, db: ro });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setFetchError(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (edit || readOnly) {
            fetchData();
        }
    }, []);

    const handleArchive = React.useCallback(() => {
        setLoading(true);
        setOnRequestType(apiState?.db.archived ? 'unarchive' : 'delete');

        bbngRequest({
            method  : 'PATCH',
            url     : urlApiBuilder.landfillArchive(dataId),
            options : { toastifySuccess: false },
            payload : {
                body: {
                    archived: !apiState?.db.archived
                }
            }
        })
            .then((response) => {
                if (response.success) {
                    navigate(urlBuilder.landfillList());
                }
            })
            .finally(() => {
                setLoading(false);
                setOnRequestType(undefined);
            });
    }, [bbngRequest, setLoading, setOnRequestType, apiState]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (fetchError) {
        return fetchError === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.landfillList()} />
        ) : (
            <Error500 />
        );
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <h1>Déchetterie{edit || readOnly ? `: ${apiState?.form?.general.name}` : ''}</h1>
            <ActionsFormContainer
                archived={apiState?.db.archived || false}
                edit={edit}
                readOnly={readOnly}
                // disableSaveOnEdit={JSON.stringify(apiState?.form) === JSON.stringify(formik.values)}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                handleArchiveOnView={handleArchive}
                editPageUrl={urlBuilder.landfillEdit(dataId)}
                historyUrl={urlApiBuilder.landfillHistory(dataId)}
            />
            <PageFormLine>
                <Landfill.GeneralInfo
                    displayError={formik.submitCount > 0}
                    readOnly={readOnly}
                    id="general"
                    result={handleChange}
                    value={formik.values.general}
                />
                <Address
                    displayError={formik.submitCount > 0}
                    readOnly={readOnly}
                    id="address"
                    result={handleChange}
                    value={formik.values.address}
                />
            </PageFormLine>
            <Landfill.ContactList
                displayError={formik.submitCount > 0}
                readOnly={readOnly}
                id="contacts"
                result={handleChange}
                value={formik.values.contacts}
            />
            <PageFormLine>
                <Landfill.PriceList
                    displayError={formik.submitCount > 0}
                    readOnly={readOnly}
                    id="prices"
                    result={handleChange}
                    value={formik.values.prices}
                />
                <Landfill.ExceptionalClosureList
                    id="exceptionalClosures"
                    result={handleChange}
                    value={formik.values.exceptionalClosures}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            {formik.values.general.owner_is_endless && (
                <Landfill.Inventory
                    id="inventory"
                    result={handleChange}
                    value={formik.values.inventory}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            )}
            <Landfill.OpeningTimes
                id="openingTimes"
                result={handleChange}
                value={formik.values.openingTimes}
                readOnly={readOnly}
                displayError={formik.submitCount > 0}
            />
            {edit === true && <Landfill.RevalorisationCharateristics landfillId={dataId} />}
            {readOnly === false && (
                <SubmitButton
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        // (JSON.stringify(apiState?.form) === JSON.stringify(formik.values) && edit)
                        (equal(apiState?.form, formik.values) && edit)
                    }
                />
            )}
            {edit === true && (
                <SnackBar
                    // show={JSON.stringify(apiState?.form) !== JSON.stringify(formik.values)}
                    show={equal(apiState?.form, formik.values) === false}
                    content="Vous avez des modifications non enregistrées"
                />
            )}
        </PageForm>
    );
};

export default LandfillForm;
