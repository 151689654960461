import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import { DumpsterOnSiteRo, FrontRo, ZoneGetAllQuery, ZoneRo } from '@bbng/util/types';

import { ActionsFormContainer } from '../../common/form';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import SnackBar from '../../components/SnackBar';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import DumpsterOnSite from '../../modules/dumpster-on-site';
import Order from '../../modules/order';
import Error404Data from '../Error404Data';
import Error500 from '../Error500';
import {
    DumpsterOnSiteFormErrorState,
    DumpsterOnSiteFormState,
    DumpsterOnSiteModulesErrorStates,
    DumpsterOnSiteModulesStates,
    initialErrorState,
    initialState,
    mapApiDataToState,
    mapStateToApiCreateData,
    mapStateToApiEditData
} from './helpers';
import { PageForm, PageFormLine, SubmitButton } from './style';
import equal from 'fast-deep-equal/react';
import { fetchDataRelation } from '../../common/dataRelation';
import { EOrderTypeForm } from '../../modules/order/Type';
import { EOrderDumpsterServiceForm } from '../../modules/order/DumpsterService';
import useQuery from '../../hooks/Query';

type DumpsterOnSiteFormProps = {
    edit?: boolean;
    readOnly?: boolean;
};

const DumpsterOnSiteForm: React.FC<DumpsterOnSiteFormProps> = ({
    edit = false,
    readOnly = false
}: DumpsterOnSiteFormProps): JSX.Element => {
    const { query } = useQuery<{ on_success?: 'close' }>();
    const { id: paramsId } = useParams();
    const navigate = useNavigate();
    const [dataId] = React.useState(paramsId ?? 'undefined');
    const [apiState, setApiState] = React.useState<{ form: DumpsterOnSiteFormState; db: FrontRo<DumpsterOnSiteRo> }>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [errorCode, setErrorCode] = useState<StatusCodes>();
    const [loading, setLoading] = useState<boolean>(edit || readOnly);
    const [onRequestType, setOnRequestType] = useState<'read' | 'delete' | 'create' | 'unarchive' | undefined>(
        edit || readOnly ? 'read' : undefined
    );
    const [zoneId, setZoneId] = useState<string>();

    const [err, setErr] = useState<DumpsterOnSiteFormErrorState>(initialErrorState);

    const handleSubmit = React.useCallback<FormikConfig<DumpsterOnSiteFormState>['onSubmit']>(
        async (data: DumpsterOnSiteFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }

            const method = edit ? 'PATCH' : 'POST';
            const url = edit ? urlApiBuilder.dumpsterOnSitePatch(dataId) : urlApiBuilder.dumpsterOnSitePost();
            const body = edit
                ? mapStateToApiEditData(data, apiState!.form, apiState!.db)
                : mapStateToApiCreateData(data);
            setLoading(true);
            setOnRequestType('create');
            const response = await bbngRequest<DumpsterOnSiteRo>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);
            setOnRequestType(undefined);

            if (query?.on_success === 'close') {
                window.close();
            }
            if (response.success) {
                navigate(urlBuilder.dumpsterOnSiteList());
            }
        },
        [err, apiState, bbngRequest]
    );

    const formik = useFormik<DumpsterOnSiteFormState>({
        initialValues : initialState,
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (
            value: DumpsterOnSiteModulesStates,
            errors: DumpsterOnSiteModulesErrorStates | string[] | null,
            id: string
        ): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response = await fetchDataRelation<StatelessResponse<FrontRo<DumpsterOnSiteRo>>>(
            await bbngRequest<DumpsterOnSiteRo>({
                method  : 'GET',
                url     : urlApiBuilder.dumpsterOnSiteGetById(dataId),
                options : { toastifySuccess: false }
            }),
            {
                construction_site_id : true,
                customer_id          : true,
                deposit_collect_id   : true,
                retrieval_collect_id : true
            }
        );

        if (response.success && response.response?.data.ro) {
            const ro = response.response?.data.ro;
            const formikState: DumpsterOnSiteFormState = mapApiDataToState(ro);

            setApiState({ form: formikState, db: ro });
            formik.setValues(deepCopy(formikState));
            setLoading(false);
        } else {
            setErrorCode(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (edit || readOnly) {
            fetchData();
        }
    }, []);

    const handleArchive = React.useCallback(() => {
        setLoading(true);
        setOnRequestType(apiState?.db.archived ? 'unarchive' : 'delete');

        bbngRequest({
            method  : 'PATCH',
            url     : urlApiBuilder.dumpsterOnSiteArchive(dataId),
            options : { toastifySuccess: false },
            payload : {
                body: {
                    archived: !apiState?.db.archived
                }
            }
        })
            .then((response) => {
                if (response.success) {
                    navigate(urlBuilder.dumpsterOnSiteList());
                }
            })
            .finally(() => {
                setLoading(false);
                setOnRequestType(undefined);
            });
    }, [bbngRequest, setLoading, setOnRequestType, apiState]);

    const fetchZone = React.useCallback(async () => {
        const response = await bbngRequest<ZoneRo[]>({
            url     : urlApiBuilder.zoneGetAll(),
            payload : {
                queryParams: {
                    postal_code: formik.values.general.construction_site?.address.components['postal_code']
                } as ZoneGetAllQuery
            },
            method: 'GET'
        });

        if (response.success && response.response?.data?.ro) {
            const zone = response.response?.data.ro[0];
            setZoneId(zone?.id);
        }
    }, [formik.values.general.construction_site]);

    useEffect(() => {
        if (formik.values.general.construction_site) {
            fetchZone();
        }
    }, [formik.values.general.construction_site]);

    if (loading) {
        return <PageLoader loading actionType={onRequestType} />;
    }

    if (errorCode) {
        return errorCode === StatusCodes.NOT_FOUND ? (
            <Error404Data dataListUrl={urlBuilder.dumpsterOnSiteList()} />
        ) : (
            <Error500 />
        );
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <h1>Benne sur chantier </h1>
            <ActionsFormContainer
                archived={apiState?.db.archived || false}
                edit={edit}
                readOnly={readOnly}
                disableSaveOnEdit={equal(apiState?.form, formik.values)}
                handleArchiveOnView={handleArchive}
                editPageUrl={urlBuilder.dumpsterOnSiteEdit(dataId)}
                historyUrl={urlApiBuilder.dumpsterOnSiteHistory(dataId)}
            />
            <PageFormLine>
                <DumpsterOnSite.GeneralInfo
                    id="general"
                    result={handleChange}
                    value={formik.values.general}
                    readOnly={readOnly}
                    displayError={formik.submitCount > 0}
                />
            </PageFormLine>
            <PageFormLine>
                <Order.TrashType
                    displayError={formik.submitCount > 0}
                    readOnly={readOnly}
                    id="trashType"
                    result={handleChange}
                    value={formik.values.trashType}
                />
            </PageFormLine>
            <PageFormLine>
                <Order.Products
                    id="product"
                    label="Benne"
                    result={handleChange}
                    value={formik.values.product}
                    trashType={formik.values.trashType.trashType}
                    readOnly={readOnly}
                    zoneId={zoneId}
                    type={EOrderTypeForm.COLLECT_DUMPSTER}
                    service={EOrderDumpsterServiceForm.DEPOSIT}
                    fetchOperational={false}
                    allowNoProducts={false}
                />
            </PageFormLine>
            {readOnly === false && (
                <SubmitButton
                    type="submit"
                    label="Valider"
                    disabled={
                        (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                        (equal(apiState?.form, formik.values) && edit)
                    }
                />
            )}
            {edit === true && (
                <SnackBar
                    show={equal(apiState?.form, formik.values) === false}
                    content="Vous avez des modifications non enregistrées"
                />
            )}
        </PageForm>
    );
};

export default DumpsterOnSiteForm;
