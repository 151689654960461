import { getDistance } from 'geolib';
import moment from 'moment';

import { NdlssError } from '@bbng/util/error';
import {
    BaseType,
    CCRo,
    CC_FAMILY,
    EDataType,
    EPlanningRegion,
    EPlanningType,
    PLANNING_CALCULATE_KEY_BASE,
    PLANNING_VRP_KEY_BASE,
    PlanningRo,
    PlanningUpdateActionDto,
    PlanningVersionDescriptionRo,
    TruckRo
} from '@bbng/util/types';

import { deepCopy } from './json';

/**
 * Check if steps were ejected during a planning update.
 * @param {PlanningRo} planningBeforeCalculus
 * @param {PlanningUpdateActionDto} planningAfterCalculus
 * @returns {boolean}
 */
export const nothingWasEjected = (
    planningBeforeCalculus: PlanningRo,
    planningAfterCalculus: PlanningUpdateActionDto
): boolean => {
    if (!planningBeforeCalculus.shift || !planningAfterCalculus.shift)
        throw new NdlssError({
            params: {
                message: 'No planning shift in planningBeforeCalculus'
            },
            dataType : EDataType.PLANNING,
            code     : ''
        });
    const { steps_service: beforeStepService, steps_administrative: beforeStepAdmin } = planningBeforeCalculus.shift;
    const { steps_service: afterStepService, steps_administrative: afterStepAdmin } = planningAfterCalculus.shift;

    return beforeStepService.length === afterStepService.length && beforeStepAdmin.length === afterStepAdmin.length;
};

export type PlanningVrpRedisKeyParams = {
    planningId: string;
    isDraft: boolean;
};
export const planningVrpRedisKey = (params: PlanningVrpRedisKeyParams) => {
    return `${PLANNING_VRP_KEY_BASE}${params.isDraft ? 'DRAFT' : 'SAVE'}:${params.planningId.replace('-', '')}`; // Redis keys can't contain '-' (contained in planningId)
};

export type PlanningCalculateRedisKeyParams = BaseType & {
    admin_id: string;
    timestamp: number;
};
export const planningCalculateRedisKey = (params: PlanningCalculateRedisKeyParams) => {
    // Redis keys can't contain '-' (contained in user_id)
    return `${PLANNING_CALCULATE_KEY_BASE}${params.type}:${params.region}:${moment
        .utc(params.day)
        .format('YYYY-MM-DD')}:${params.admin_id}:${params.timestamp}`;
};
export const planningCalculateRedisKeyPattern = (params: BaseType) => {
    return `*${PLANNING_CALCULATE_KEY_BASE}${params.type}:${params.region}:${moment
        .utc(params.day)
        .format('YYYY-MM-DD')}*`;
};

export const destructuringPlanningCalculateKey = (key: string): PlanningCalculateRedisKeyParams => {
    const [, , type, region, day, admin_id, timestamp] = key.split(':');
    return {
        type      : type as EPlanningType,
        region    : region as EPlanningRegion,
        day,
        admin_id,
        timestamp : Number(timestamp)
    };
};

export const formatPlanningCalculateKeyAsDescription = (
    key: string /*, admins: AdminRo[]*/
): PlanningVersionDescriptionRo => {
    const { admin_id, day, region, timestamp, type } = destructuringPlanningCalculateKey(key);
    return {
        day,
        region,
        timestamp,
        type,
        key: key,
        admin_id
    } as PlanningVersionDescriptionRo;
};

export type Coordinate = { lat: number; lng: number };
export type Cluster = { list: { cc: CCRo; coordinates: Coordinate }[]; center: Coordinate };
export type AssignMethod = 'equitablyByVolume' | 'equitablyByCollect';

class CommonClustering {
    private ccs: { cc: CCRo; coordinates: Coordinate; volume: number }[] = [];
    private trucks: { truck: TruckRo; coordinates: Coordinate }[] = [];

    constructor(ccs: CCRo[], trucks: TruckRo[]) {
        this.ccs = ccs.map((cc) => ({
            cc,
            coordinates : { lat: cc.address.coordinates.latitude, lng: cc.address.coordinates.longitude },
            volume      :
                cc.family === CC_FAMILY.ADMINISTRATIVE
                    ? 0
                    : cc.products.reduce((acc, product) => acc + product.volume_m3 * product.quantity, 0)
        }));
        this.trucks = trucks.map((truck) => ({
            truck,
            coordinates: {
                lat : truck.garage_address.coordinates.latitude,
                lng : truck.garage_address.coordinates.longitude
            }
        }));
    }

    /**
     * @description
     * @param clusterCount number of clusters to create
     * @returns Cluster[]
     */
    public initializeClusters = (clusterCount: number): Cluster[] => {
        if (this.ccs.length === 0) return [];
        //Initialize first cluster with random center
        const clusters: Cluster[] = [];
        const firstCenterIdx = Math.floor(Math.random() * this.ccs.length);
        clusters.push({
            list   : [],
            center : this.ccs[firstCenterIdx].coordinates
        });

        //Initialize other clusters using k-means++
        for (let i = 1; i < clusterCount; i++) {
            const distance = this.ccs.map((cc) => {
                let closestCluster = clusters[0];
                let closestDistance = getDistance(cc.coordinates, closestCluster.center);
                for (const cluster of clusters) {
                    const distance = getDistance(cc.coordinates, cluster.center);
                    if (distance < closestDistance) {
                        closestDistance = distance;
                        closestCluster = cluster;
                    }
                }
                return closestDistance;
            });

            const sum = distance.reduce((a, b) => a + b, 0);
            let rand = Math.random() * sum;
            let idx = rand === 0 ? this.ccs.length - 1 : -1;
            while (rand > 0) {
                idx++;
                rand -= distance[idx];
            }

            clusters.push({
                list   : [],
                center : this.ccs[idx].coordinates
            });
        }
        return clusters;
    };

    public assignCCsToClustersEquitably = (clusters: Cluster[]): Cluster[] => {
        for (const cluster of clusters) {
            cluster.list = [];
        }

        const clusterVolumes = new Array(clusters.length).fill(0);
        const ccs = deepCopy(this.ccs);

        while (ccs.length > 0) {
            let closestCluster = clusters[0];
            let closestIdx = 0;

            for (let i = 0; i < clusters.length; i++) {
                if (clusterVolumes[i] < clusterVolumes[closestIdx]) {
                    closestIdx = i;
                    closestCluster = clusters[i];
                }
            }

            let closestCC = ccs[0];
            let closestCCIdx = 0;
            for (let i = 0; i < ccs.length; i++) {
                const distance = getDistance(ccs[i].coordinates, closestCluster.center);
                if (distance < getDistance(closestCC.coordinates, closestCluster.center)) {
                    closestCC = ccs[i];
                    closestCCIdx = i;
                }
            }

            closestCluster.list.push(closestCC);
            clusterVolumes[closestIdx] += closestCC.volume;
            ccs.splice(closestCCIdx, 1);
        }
        return clusters;
    };

    public assignCCsToClustersWithMaxNumberOfCCs = (clusters: Cluster[]): Cluster[] => {
        for (const cluster of clusters) {
            cluster.list = [];
        }

        const ccs = deepCopy(this.ccs);

        while (ccs.length > 0) {
            let closestCluster = clusters[0];
            let closestIdx = 0;

            for (let i = 0; i < clusters.length; i++) {
                if (clusters[i].list.length < closestCluster.list.length) {
                    closestIdx = i;
                    closestCluster = clusters[i];
                }
            }

            let closestCC = ccs[0];
            let closestCCIdx = 0;
            for (let i = 0; i < ccs.length; i++) {
                const distance = getDistance(ccs[i].coordinates, closestCluster.center);
                if (distance < getDistance(closestCC.coordinates, closestCluster.center)) {
                    closestCC = ccs[i];
                    closestCCIdx = i;
                }
            }

            closestCluster.list.push(closestCC);
            ccs.splice(closestCCIdx, 1);
        }

        return clusters;
    };

    public dispatch = (clusters: Cluster[], assignMethod: AssignMethod) => {
        if (assignMethod === 'equitablyByVolume') {
            this.assignCCsToClustersEquitably(clusters);
        }
        if (assignMethod === 'equitablyByCollect') {
            this.assignCCsToClustersWithMaxNumberOfCCs(clusters);
        }
        const truckCollects = Array(this.trucks.length).fill(0);
        const assinedCCs: { cc: CCRo; coordinates: Coordinate }[][] = Array(this.trucks.length).fill([]);

        const ccClusters = clusters.slice();

        while (ccClusters.length > 0) {
            let closestTruck = 0;
            let closestDistance = Number.MAX_VALUE;
            let closestClusterIdx = 0;

            for (const cluster of ccClusters) {
                for (let i = 0; i < this.trucks.length; i++) {
                    const distance = getDistance(cluster.center, this.trucks[i].coordinates);
                    if (distance < closestDistance && truckCollects[i] <= Math.min(...truckCollects)) {
                        closestDistance = distance;
                        closestTruck = i;
                        closestClusterIdx = ccClusters.indexOf(cluster);
                    }
                }
            }

            assinedCCs[closestTruck] = assinedCCs[closestTruck].concat(ccClusters[closestClusterIdx].list);
            truckCollects[closestTruck] += ccClusters[closestClusterIdx].list.length;
            ccClusters.splice(ccClusters.indexOf(ccClusters[closestClusterIdx]), 1);
        }

        // return assinedCCs;
        return this.trucks.reduce((acc, truck) => {
            acc[truck.truck.id] = assinedCCs[this.trucks.indexOf(truck)].map((el) => el.cc);
            return acc;
        }, {} as { [key: string]: CCRo[] });
    };
}

export class GeneticClustering {
    public clusters: Cluster[] = [];
    private ccs: { cc: CCRo; coordinates: Coordinate; volume: number }[] = [];

    public CommonnClustering: CommonClustering = new CommonClustering([], []);

    constructor(ccs: CCRo[], trucks: TruckRo[], clusterCount: number) {
        this.ccs = ccs.map((cc) => ({
            cc,
            coordinates : { lat: cc.address.coordinates.latitude, lng: cc.address.coordinates.longitude },
            volume      :
                cc.family === CC_FAMILY.ADMINISTRATIVE
                    ? 0
                    : cc.products.reduce((acc, product) => acc + product.volume_m3 * product.quantity, 0)
        }));

        this.CommonnClustering = new CommonClustering(ccs, trucks);

        this.clusters = this.CommonnClustering.initializeClusters(clusterCount);
    }

    public optimize = (): GeneticClustering => {
        const populationSize = 100;
        const mutationRate = 0.01;
        const population: Cluster[][] = [];
        for (let i = 0; i < populationSize; i++) {
            const clusters: Cluster[] = deepCopy(this.clusters);
            for (const cluster of clusters) {
                cluster.center = this.ccs[Math.floor(Math.random() * this.ccs.length)].coordinates;
            }
            population.push(clusters);
        }

        for (let i = 0; i < 1_000; i++) {
            population.sort((a, b) => this.fitness(b) - this.fitness(a));

            const newPopulation: Cluster[][] = [population[0]];

            while (newPopulation.length < populationSize) {
                const parentA = this.selectParent(population);
                const parentB = this.selectParent(population);
                let child = this.crossover(parentA, parentB);
                child = this.mutate(child, mutationRate);
                newPopulation.push(child);
            }
        }
        population.sort((a, b) => this.fitness(b) - this.fitness(a));
        this.clusters = population[0];
        return this;
    };

    private selectParent = (population: Cluster[][]): Cluster[] => {
        let rand = Math.random();
        let idx = 0;
        while (rand > 0) {
            rand -= this.fitness(population[idx]) / this.fitness(population[population.length - 1]);
            idx++;
        }
        return population[idx - 1];
    };

    private crossover = (parentA: Cluster[], parentB: Cluster[]): Cluster[] => {
        const child: Cluster[] = deepCopy(parentA);
        const idx = Math.floor(Math.random() * this.clusters.length);
        for (let i = idx; i < child.length; i++) {
            child[i].center = parentB[i].center;
        }

        return child;
    };

    private mutate(clusters: Cluster[], mutationRate: number): Cluster[] {
        for (const cluster of clusters) {
            if (Math.random() < mutationRate) {
                cluster.center = this.ccs[Math.floor(Math.random() * this.ccs.length)].coordinates;
            }
        }

        return clusters;
    }

    private fitness = (clusters: Cluster[]): number => {
        let fitness = 0;
        for (const cluster of clusters) {
            for (const li of cluster.list) {
                fitness += getDistance(li.coordinates, cluster.center);
            }
        }

        return fitness;
    };

    public dispatch = (assignMethod: AssignMethod) => this.CommonnClustering.dispatch(this.clusters, assignMethod);
}

// export class AnnealingClustering {
//     public clusters: Cluster[] = [];
//     private ccs: { cc: CCRo; coordinates: Coordinate; volume: number }[] = [];

//     public CommonnClustering: CommonClustering = new CommonClustering([], [], 0);

//     constructor(ccs: CCRo[], trucks: TruckRo[], clusterCount: number) {
//         this.ccs = ccs.map((cc) => ({
//             cc,
//             coordinates : { lat: cc.address.coordinates.latitude, lng: cc.address.coordinates.longitude },
//             volume      :
//                 cc.family === CC_FAMILY.ADMINISTRATIVE
//                     ? 0
//                     : cc.products.reduce((acc, product) => acc + product.volume_m3 * product.quantity, 0)
//         }));

//         this.CommonnClustering = new CommonClustering(ccs, trucks, clusterCount);

//         this.clusters = this.CommonnClustering.initializeClusters(clusterCount);
//     }

// }
