import React from 'react';
import styled from 'styled-components';

import { EPriceUnit, ETrashType } from '@bbng/util/types';

import { mapPriceUnit, mapTrashTypetext } from '../../common/enumMapper';
import Input from '../../components/Inputs';
import { InputListBase } from './base';

export interface Price {
    price_value: number | null;
    price_unit: EPriceUnit;
    type: ETrashType;
}

export type InputListPriceProps = {
    title?: string;
    value?: Array<Price>;
    onChange?: (value: Array<Price>) => void;
    readOnly?: boolean;
};

export function InputListPrice({ title = 'Price', ...props }: InputListPriceProps) {
    const [errors, setErrors] = React.useState<Record<string, string[]>>({});
    const isError =
        Object.keys(errors)
            .map((e) => errors[e] ?? [])
            .flat().length > 0;

    return (
        <InputListBase<Price>
            title={title}
            columns={[
                { header: 'Prix', field: 'price_value', body: (val: Price) => `${val.price_value}€` },
                { header: 'Unité', field: 'price_unit', body: (val: Price) => mapPriceUnit(val.price_unit) },
                { header: 'Type', field: 'type', body: (val: Price) => mapTrashTypetext(val.type) }
            ]}
            disableModalButton={isError}
            setErrors={setErrors}
            modalTemplate={PriceModal(errors, setErrors)}
            {...props}
        />
    );
}

const PriceModal =
    (errors: Record<string, string[]>, setError: React.Dispatch<React.SetStateAction<Record<string, string[]>>>) =>
    (state: Price | undefined, setter: React.Dispatch<React.SetStateAction<Price | undefined>>) => {
        return (
            <ModalContainer>
                <Input.Currency
                    id="price_value"
                    required
                    result={(value, err, id) => {
                        setter((prev) => {
                            const newState = { ...prev, price_value: value } as Price;

                            return newState;
                        });
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    label="Prix"
                    errors={errors['price_value'] ?? []}
                    value={state?.price_value ?? null}
                />
                <Input.Dropdown
                    id="price_unit"
                    options={Object.values(EPriceUnit)}
                    required
                    result={(value, err, id) => {
                        setter((prev) => {
                            const newState = { ...prev, price_unit: value } as Price;

                            return newState;
                        });
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    label="Unité"
                    errors={errors['price_unit'] ?? []}
                    value={state?.price_unit ?? ''}
                    itemTemplate={(val) => mapPriceUnit(val)}
                    valueTemplate={(val) => mapPriceUnit(val)}
                />
                <Input.Dropdown
                    id="type"
                    options={Object.values(ETrashType)}
                    required
                    result={(value, err, id) => {
                        setter((prev) => {
                            const newState = { ...prev, type: value } as Price;

                            return newState;
                        });
                        setError((prev) => ({ ...prev, [id]: err ?? [] }));
                    }}
                    label="Type"
                    errors={errors['type'] ?? []}
                    value={state?.type ?? ''}
                    itemTemplate={(val) => mapTrashTypetext(val)}
                    valueTemplate={(val) => mapTrashTypetext(val)}
                />
            </ModalContainer>
        );
    };

const ModalContainer = styled.div`
    .p-dropdown {
        width: 100% !important;
    }
    span:last-child {
        grid-column: 1 / 3;
    }
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr;
`;
