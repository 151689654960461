import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { MenuItemCommandParams } from 'primereact/menuitem';
import { Tag, TagProps, TagSeverityType } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import { IconType } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { displayVolumeFromProducts, getMiddleOfTheDay } from '@bbng/util/misc';
import {
    CCRoFront,
    CCServiceRoFront,
    CC_FAMILY,
    CC_STATUS,
    CollectConfigQuery,
    CustomerRo,
    ECustomerCategory,
    EPlanningRegion,
    EPlanningType,
    ISODate,
    PrestaRo
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { mapCollectConfigFamilyText, mapCollectConfigStatus, mapPlanningConfigRegion } from '../../common/enumMapper';
import { COLLECT_CONFIG_ORCHESTRATION_BASE_URL, urlBuilder } from '../../common/urlBuilder';
import Calendar from '../../components/Calendar';
import { collectConfigDescription } from '../../components/StepOverview';
import { Dialog } from '../../components/Dialog';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';
import CollectConfigCancelForm from '../../modules/collect-config-cancel-form';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { FiltersContainer, PostCodeContainer } from './style';
import { toast } from '../../components/Toast';

const regionOptions = Object.values(EPlanningRegion).map((region) => ({
    name  : mapPlanningConfigRegion(region),
    value : region
}));

const TooltipEntry = styled.div<{ margin?: string }>`
    margin: ${(props) => props.margin || 0};
`;

const familyOptions = Object.values(CC_FAMILY)
    .filter((val) => val !== CC_FAMILY.DELIVERY_BIG_BAG)
    .map((family) => ({
        name  : mapCollectConfigFamilyText(family, true),
        value : family
    }));

type QueryParams = {
    type?: EPlanningType;
    region?: EPlanningRegion;
    customer_id?: string;
    family?: CC_FAMILY;
    min_date?: ISODate;
    max_date?: ISODate;
};

const GROUP_ROW_KEY = 'id';

export type CollectConfigsListingProps = {
    type: EPlanningType;
};

export const CollectConfigsListing: React.FC<CollectConfigsListingProps> = ({
    type
}: CollectConfigsListingProps): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [region, setRegion] = React.useState<EPlanningRegion | undefined>(query.region);
    const [customer_id, setCustomerId] = React.useState<string | undefined>(query.customer_id);
    const [family, setFamily] = React.useState<CC_FAMILY | undefined>(query.family);
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = React.useState(false);
    const [collectConfigToCancel, setCollectConfigToCancel] = React.useState<CCRoFront>();
    const [minDate, setMinDate] = React.useState<ISODate | undefined>(query.min_date ?? undefined);
    const [maxDate, setMaxDate] = React.useState<ISODate | undefined>(query.max_date ?? undefined);

    const [triggerPageRefresh, setTriggerPageRefresh] = useState<boolean>(false);
    useEffect(() => {
        setTriggerPageRefresh(false);
    }, [triggerPageRefresh]);

    const handleActionRowCancel = (e: MenuItemCommandParams) => {
        const index = e.item['rowInfo']['rowIndex'];
        const collectConfig = e.item['rowInfo']['props']['value'][index];
        setCollectConfigToCancel(collectConfig);
        setCancelDialogIsOpen(true);
    };

    return (
        <>
            <Listing<CollectConfigQuery, CCRoFront>
                title={`Collectes de ${type === EPlanningType.BIG_BAG ? 'big bags' : 'bennes'} à venir`}
                subtitle={`Toutes les collectes de ${type === EPlanningType.BIG_BAG ? 'big bags' : 'bennes'} à venir`}
                url={COLLECT_CONFIG_ORCHESTRATION_BASE_URL}
                endpoint="collect-configs"
                hideCreateButton
                hideBulkMenu
                pageUrl={urlBuilder.collectConfigBase()}
                pageUrlSuffix={(data) => (data.family === CC_FAMILY.ADMINISTRATIVE ? '?isInternal=true' : '')}
                displaySelectColumn={false}
                searchPlaceholder={`Rechercher par numéro de commande ou collecte`}
                disableArchiveAction
                displayArchiveFilters={false}
                triggerPageRefresh={triggerPageRefresh}
                actionColumnField={GROUP_ROW_KEY}
                groupRowsBy={GROUP_ROW_KEY}
                rowGroupMode="rowspan"
                scrollable={false}
                actionRows={[
                    {
                        label   : 'Annuler',
                        icon    : 'pi pi-exclamation-triangle',
                        command : (e) => handleActionRowCancel(e)
                    }
                ]}
                enrichData={async (data) => {
                    data.data.ro = await fetchDataRelation(data.data.ro ?? [], { customer_id: true, presta_id: true });
                    /**
                     * For splitted cc, create n fake cc (n = split number) to show each of them in the listing
                     */
                    data.data.ro =
                        data.data.ro?.reduce((acc, collectConfig) => {
                            if (collectConfig.status === CC_STATUS.SPLITTED) {
                                collectConfig.splitted_informations?.forEach((splittedCollectConfig) => {
                                    acc.push({
                                        ...collectConfig,
                                        products     : splittedCollectConfig.products,
                                        status       : splittedCollectConfig.status,
                                        splitted_idx : splittedCollectConfig.idx,
                                        is_splitted  : true
                                    });
                                });
                            } else {
                                acc.push(collectConfig);
                            }
                            return acc;
                        }, [] as CCRoFront[]) || [];
                    return data;
                }}
                queryParams={{
                    type,
                    region,
                    customer_id,
                    family,
                    no_delivery  : true,
                    not_finished : true,
                    max_date     : maxDate ? moment(maxDate).add(1, 'day').startOf('day').toISOString() : undefined, // max date is exclusive so include the next day for better UX
                    min_date     : minDate ? moment(minDate).startOf('day').toISOString() : undefined
                }}
                leftHandedComponent={
                    <FiltersContainer>
                        <Calendar.Range
                            id=""
                            value={minDate && maxDate ? [minDate, maxDate] : undefined}
                            required={false}
                            readOnly={false}
                            result={(date) => {
                                if (Array.isArray(date)) {
                                    /**
                                     * Needed to get the middle of the day to avoid timezone issues.
                                     */
                                    const date0 = moment(date[0]).toLocaleString();
                                    const middleStartDay = getMiddleOfTheDay(date0);
                                    const date1 = moment(date[1]).toLocaleString();
                                    const middleEndDay = getMiddleOfTheDay(date1);
                                    if (date?.length === 2) {
                                        /**
                                         * Case when only the start date is set.
                                         * Toast the user to select an end date.
                                         * We cannot set the min and max date to the same value as we won't be able to select two dates then.
                                         */
                                        if (date[1] == null) {
                                            toast({
                                                severity : 'info',
                                                summary  : 'Sélectionnez une date de fin.',
                                                detail   : 'Vous devez sélectionner une date de fin pour filtrer par période (pour sélectionner un seul jour, cliquez deux fois sur la même date).'
                                            });
                                            return;
                                        }
                                        setMinDate(middleStartDay);
                                        setMaxDate(middleEndDay);
                                    } else if (date?.length === 1) {
                                        setMinDate(middleStartDay);
                                        setMaxDate(middleStartDay);
                                    }
                                }
                            }}
                            showTime={false}
                            displayError={false}
                        />
                        <Dropdown
                            value={region}
                            onChange={(e) => setRegion(e.value)}
                            placeholder="Filtrer par région"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={regionOptions}
                        />
                        <Dropdown
                            value={family}
                            onChange={(e) => setFamily(e.value)}
                            placeholder="Filtrer par famille"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={familyOptions}
                        />
                        <RelationAutocomplete.Customer
                            placeholder="Filtrer par client"
                            onSelect={(customer) => setCustomerId(customer?.id)}
                            onUnselect={() => setCustomerId(undefined)}
                        />
                    </FiltersContainer>
                }
                name="Collecte"
                pluralName="Collectes"
                headers={[
                    {
                        name      : 'Numéro collecte',
                        field     : GROUP_ROW_KEY as keyof CCRoFront,
                        component : (data: CCRoFront) => {
                            if (data.family === CC_FAMILY.ADMINISTRATIVE) {
                                return <span>N/A</span>;
                            }
                            return (
                                <CollectNumber>
                                    <div>
                                        <a href={urlBuilder.collectConfigView(data.id, false)}>{data.number}</a>(
                                        {data.order_number})
                                    </div>
                                    {data.is_splitted && <Tag value="Scindée" severity="warning" />}
                                </CollectNumber>
                            );
                        }
                    },
                    {
                        name      : 'Client',
                        field     : GROUP_ROW_KEY as keyof CCRoFront,
                        component : (data: CCServiceRoFront) => {
                            if (data.family === CC_FAMILY.ADMINISTRATIVE) return <span>N/A</span>;
                            const customer = data.customer_id[0] as CustomerRo | undefined;
                            const isIndividual = customer?.category === ECustomerCategory.INDIVIDUAL;
                            const individualIcon = <i className="pi pi-user" />;
                            return (
                                <>
                                    <Tooltip
                                        position="top"
                                        target={`.customer-${data.id.replace(':', '')}`}
                                        content={isIndividual ? 'Particulier' : undefined}
                                    />
                                    <span className={`customer-${data.id.replace(':', '')}`}>
                                        {customer?.name} {isIndividual && individualIcon}
                                    </span>
                                </>
                            );
                        }
                    },
                    {
                        name      : 'Famille',
                        field     : GROUP_ROW_KEY as keyof CCRoFront,
                        component : (data: CCRoFront) => <span>{mapCollectConfigFamilyText(data.family)}</span>
                    },
                    {
                        name      : 'Code postal',
                        field     : GROUP_ROW_KEY as keyof CCRoFront,
                        component : (data: CCRoFront) => (
                            <PostCodeContainer>
                                <Tag
                                    className="mr-2"
                                    value={mapPlanningConfigRegion(data.region)}
                                    severity={mapRegionToSeverity(data.region)}
                                />
                                <div>{data.address.components['postal_code']}</div>
                            </PostCodeContainer>
                        )
                    },
                    {
                        name      : 'Date',
                        field     : GROUP_ROW_KEY as keyof CCRoFront,
                        component : (data: CCRoFront) => <span>{moment(data.from_date).format('DD/MM/YYYY')}</span>
                    },
                    {
                        name      : 'Créneau',
                        field     : GROUP_ROW_KEY as keyof CCRoFront,
                        component : (data: CCRoFront) => (
                            <span>
                                {moment(data.from_date).format('HH:mm')} - {moment(data.to_date).format('HH:mm')}
                            </span>
                        )
                    },
                    {
                        name      : `Volume`,
                        field     : 'products' as keyof CCRoFront,
                        component : (data: CCServiceRoFront) => {
                            if (data.family === CC_FAMILY.ADMINISTRATIVE) return <span>N/A</span>;
                            const volumeToCollect = displayVolumeFromProducts(data.products, data.family);
                            return (
                                <>
                                    <Tooltip position="top" target={`.volume-${data.id}`}>
                                        {data.products.map((p) => (
                                            <TooltipEntry margin="4px 0 0">
                                                {p.quantity} x {mapCollectConfigFamilyText(p.family, true)} -{' '}
                                                {p.volume_m3}m3
                                            </TooltipEntry>
                                        ))}
                                    </Tooltip>
                                    <StyledSpan className={`volume-${data.id}`}>
                                        {volumeToCollect ?? 'N/A'}
                                        <i className="pi pi-eye" />
                                    </StyledSpan>
                                </>
                            );
                        }
                    },
                    {
                        name      : 'Traitant',
                        field     : 'presta_id' as keyof CCRoFront,
                        component : (data: CCRoFront) => {
                            if (data.family === CC_FAMILY.ADMINISTRATIVE) return <span>N/A</span>;
                            if (data.presta_id?.length > 0) {
                                const presta = data.presta_id[0] as PrestaRo;

                                return (
                                    <a href={urlBuilder.prestaView(presta.id)} target="_blank" rel="noreferrer">
                                        {presta.name}
                                    </a>
                                );
                            } else {
                                return 'Endless';
                            }
                        }
                    },
                    {
                        name      : 'Statut',
                        field     : 'status',
                        component : (data: CCRoFront) => {
                            return (
                                <div>
                                    <Tag
                                        className="mr-2"
                                        value={mapCollectConfigStatus(data.status)}
                                        severity={mapStatusToSeverity(data.status)}
                                        icon={mapStatusToIcon(data.status)}
                                    />
                                </div>
                            );
                        }
                    }
                ]}
            />
            {collectConfigToCancel && (
                <Dialog
                    visible={cancelDialogIsOpen}
                    onHide={() => setCancelDialogIsOpen(false)}
                    header={`Annulation - ${collectConfigToCancel && collectConfigDescription(collectConfigToCancel)}`}
                    draggable={false}
                    resizable={false}
                    blockScroll
                    contentStyle={{ overflow: 'scroll' }}
                >
                    <CollectConfigCancelForm
                        submitCallback={() => setTriggerPageRefresh(true)}
                        setModalIsOpen={setCancelDialogIsOpen}
                        dataId={collectConfigToCancel.id}
                        isAdministrative={collectConfigToCancel.family === CC_FAMILY.ADMINISTRATIVE}
                    />
                </Dialog>
            )}
        </>
    );
};

export const mapStatusToIcon = (status?: CC_STATUS): IconType<TagProps> => {
    switch (status) {
        case CC_STATUS.WAITING_FOR_APPROVAL:
        case CC_STATUS.ORDER_TO_PAY:
            return 'pi pi-cog';
        case CC_STATUS.FINISHED:
            return 'pi pi-check';
        case CC_STATUS.CANCELED:
            return 'pi pi-times';
        case CC_STATUS.PLANNED:
            return 'pi pi-check';
        case CC_STATUS.TO_PLAN:
            return 'pi pi-cog';
        case CC_STATUS.HAZARD:
            return 'pi pi-exclamation-circle';
        case CC_STATUS.TO_PREPARE:
            return 'pi pi-box';
        case CC_STATUS.SPLITTED:
            return 'pi pi-th-large';
        default:
            return '';
    }
};

export const mapStatusToSeverity = (status?: CC_STATUS): TagSeverityType => {
    switch (status) {
        case CC_STATUS.WAITING_FOR_APPROVAL:
        case CC_STATUS.ORDER_TO_PAY:
        case CC_STATUS.HAZARD:
            return 'warning';
        case CC_STATUS.FINISHED:
            return 'success';
        case CC_STATUS.CANCELED:
            return 'danger';
        case CC_STATUS.TO_PLAN:
        case CC_STATUS.TO_PREPARE:
            return 'info';
        case CC_STATUS.PLANNED:
            return 'primary';
        default:
            return 'info';
    }
};

export const mapRegionToSeverity = (status: EPlanningRegion): TagSeverityType => {
    switch (status) {
        case EPlanningRegion.PARIS:
            return 'primary';
        case EPlanningRegion.LYON:
            return 'success';
        case EPlanningRegion.MARSEILLE:
            return 'warning';
        default:
            return 'info';
    }
};

const StyledSpan = styled.span`
    display: flex;
    gap: 4px;
    align-items: center;
`;

const CollectNumber = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
`;
