import React, { useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import * as yup from 'yup';

import { match } from '@bbng/util/misc';
import { EDocumentType } from '@bbng/util/types';

import { formatBytes } from '../../common/enumMapper';
import { ImagePreview } from './ImagePreview';
import { Placeholder } from './Placeholder';

const DrpZoneContainer = styled.div`
    img {
        max-width: 100%;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const DrpZone = styled.div<{ disable?: boolean }>`
    --opacity: ${(props) => (props.disable ? 0.5 : 1)};

    opacity: var(--opacity);
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer;
`;

const getColor = (props: any) => {
    if (props.disable) {
        return '#eeeeee';
    }
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
};

type DropZoneProps = {
    readOnly: boolean;
    required: boolean;
    id: string;
    result: (value: File | null, errors: string[] | null, id: string, type: EDocumentType) => void;
    value: File | null;
    validationSchema?: yup.AnySchema;
    errors?: string[] | null;
    maxSize?: number;
    type: EDocumentType;
    hidePreview?: boolean;
    triggerValidation?: boolean;
    inputKey?: string;
};

export const Dropzone = ({
    inputKey,
    hidePreview = false,
    readOnly,
    required,
    id,
    result,
    value,
    validationSchema,
    errors,
    maxSize = 5000000, // 5 Mo
    type
}: DropZoneProps) => {
    const [val, setVal] = useState<File | null>(value);
    const [err, setErr] = useState<null | string[]>(errors ?? null);

    const baseValidationSchema = yup.mixed();
    const schema = validationSchema
        ? validationSchema
        : required
        ? baseValidationSchema.required(`Champ requis`)
        : baseValidationSchema;

    const _checkDescendingValue = React.useCallback(
        async (stateValue: File | null, propsValue: File | null): Promise<void> => {
            if (stateValue !== propsValue) {
                setVal(propsValue);
                setErr(null);
                result(propsValue, null, id, type);
                if (propsValue || required) {
                    try {
                        await schema.validate(propsValue, { abortEarly: false });
                    } catch (err) {
                        setErr(err.errors);
                        result(propsValue, err.errors, id, type);
                    }
                }
            }
        },
        [setVal, setErr, result]
    );

    const _checkDescendingErrors = React.useCallback(
        async (stateError: null | string[], propsError: null | string[]): Promise<void> => {
            if (match(stateError, propsError) === false) {
                setErr(propsError);
                result(val, propsError, id, type);
            }
        },
        [setVal, setErr, result]
    );

    React.useEffect(() => {
        _checkDescendingValue(val, value);
        if (errors !== undefined) {
            _checkDescendingErrors(err, errors);
        }
    }, [value, errors]);

    const handleDrop = React.useCallback(
        (files: File[]) => {
            if (files.length > 0) {
                result(files[0], null, id, type);
                setVal(files[0]);
                setErr(null);
            }
        },
        [setVal, setErr, result, id]
    );

    const handleDropReject = React.useCallback(
        (reject: FileRejection[]) => {
            if (reject.length > 0) {
                const errors: string[] = [];
                reject.forEach((r) => {
                    r.errors.forEach((error) => {
                        errors.push(error.message);
                    });
                });
                result(val, errors, id, type);
                setErr(errors);
            }
        },
        [setErr, result, id]
    );

    const handleError = React.useCallback(
        (err: any) => {
            setErr(err?.errors);
            result(val, err?.errors, id, type);
        },
        [setErr, result, id]
    );

    const handleClear = React.useCallback(() => {
        result(null, null, id, type);
        setVal(null);
        setErr(null);
    }, [setVal, setErr, result, id]);

    const disabled = value !== null || readOnly;

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'application/pdf' : [],
            'image/png'       : [],
            'image/jpeg'      : []
        },
        maxFiles       : 1,
        onDrop         : handleDrop,
        onError        : handleError,
        onDropRejected : handleDropReject,
        disabled,
        maxSize
    });

    return (
        <DrpZoneContainer>
            {!hidePreview && (value ? <ImagePreview image={value} handleClear={handleClear} /> : <Placeholder />)}
            <DrpZone {...getRootProps({ isFocused, isDragAccept, isDragReject })} disable={disabled}>
                <input key={inputKey} disabled={readOnly} {...getInputProps()} />
                <p>Déposez votre fichier, ou cliquez dans la zone.</p>
                <p>⚠️ : 1 fichier de {formatBytes(maxSize, 0)} maximum.</p>
            </DrpZone>
        </DrpZoneContainer>
    );
};
