import { Divider } from 'primereact/divider';
import styled from 'styled-components';

import { Card } from '../Card';

export const CollectConfigsContainer = styled.div`
    margin-top: 4px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 8px;
`;

export const StyledCard = styled(Card)<{ cancel?: boolean }>`
    display: flex;
    background-image: ${(props) =>
        props.cancel
            ? 'linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 50%, #dcdcdc 50%, #dcdcdc 75%, transparent 75%, #fff)'
            : 'none'};
    background-size: 50px 50px;
    .p-card-content {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
    }
    .p-card-body {
        display: flex;
        align-items: center;
    }
`;

export const CardLine = styled.div<{ weight?: number }>`
    text-align: center;
    font-weight: ${({ weight }) => weight ?? 400};
`;

export const MainLine = styled.div`
    padding: 4px 0;
`;

export const OverviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const TimeContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const DateContainer = styled.span`
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const VolumeContainer = styled.div`
    font-weight: 400;
    font-size: 2em;
`;

export const Time = styled.div`
    font-weight: 200;
    font-size: 2em;
    margin-bottom: 8px;
`;

export const Status = styled.div`
    font-weight: 300;
    font-size: 1.6em;
    margin-bottom: 8px;
`;

export const HorizontalContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const CustomerContainer = styled.div`
    font-size: 1.3em;
    font-weight: 500;
    margin-bottom: 8px;
`;

export const VerticalContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: column;
`;

export const NarrowVerticalContainer = styled.div`
    display: flex;
    gap: 2px;
    align-items: center;
    flex-direction: column;
`;

export const StyledTitle = styled.p`
    font-weight: 600;
    font-size: 1.2em;
    margin: 4px 0;
`;

export const StyledDivider = styled(Divider)`
    &.p-divider.p-divider-horizontal {
        margin: 4px 0;
    }
`;

export const HorizontalCenteredContainer = styled.div`
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
`;

export const CollectDetailsTitle = styled.div`
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-style: italic;
`;

export const ForbiddenCollectorContainer = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    & div {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: bold;
        color: red;
    }
`;
