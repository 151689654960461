import React from 'react';
import styled from 'styled-components';

import { IKafkaQuery } from '@bbng/util/types';

import Button from '../Button';

export type ArchiveFiltersProps = {
    customQuery: IKafkaQuery;
    setCustomQuery: React.Dispatch<React.SetStateAction<Record<string, any>>>;
};

export const ArchiveFilters: React.FC<ArchiveFiltersProps> = ({ customQuery, setCustomQuery }: ArchiveFiltersProps) => {
    return (
        <FiltersContainer>
            <Button.Switch
                id="noArchived"
                value={customQuery?.no_archived || false}
                result={(value) => {
                    setCustomQuery((old) => ({
                        ...old,
                        no_archived: value
                    }));
                }}
                label="Exclure les archives"
                labelPosition="left"
                readOnly={false}
            />
            <Button.Switch
                id="archivedOnly"
                value={customQuery?.archived_only || false}
                result={(value) => {
                    setCustomQuery((old) => ({
                        ...old,
                        archived_only: value
                    }));
                }}
                label="Inclure uniquement les archives"
                labelPosition="left"
                readOnly={false}
            />
        </FiltersContainer>
    );
};

export const FiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;
