import Joi from 'joi';

import { DocumentCreateDtoSchema } from '../document';
import { BillingBranch, ETrashType } from '../misc';
import { KafkaParamsQuerySchema, ProductPriceSchema } from '../misc.schema';
import { EProductBillingMode, PRODUCT_DUMPSTER_TYPE, PRODUCT_FAMILY } from './product';

export const ProductCreateDtoSchema = Joi.object({
    name        : Joi.string().required(),
    subname     : Joi.string().allow('', null),
    description : Joi.string().allow('', null),
    internal    : Joi.boolean().required(),
    operational : Joi.when('internal', {
        // operational is always internal: if internal is false, operational is false
        is        : false,
        then      : Joi.boolean().valid(false),
        otherwise : Joi.boolean().required()
    }),
    price               : ProductPriceSchema.required(),
    vat_rate_percentage : Joi.number().min(0).max(100).required(),
    family              : Joi.string()
        .valid(...Object.keys(PRODUCT_FAMILY))
        .required(),
    billing_branch: Joi.string()
        .valid(...Object.keys(BillingBranch))
        .required(),
    trash_type: Joi.string()
        .valid(...Object.keys(ETrashType))
        .required(),
    volume_m3     : Joi.number().positive().required(),
    main_photo    : DocumentCreateDtoSchema,
    photo_id      : Joi.array().items(DocumentCreateDtoSchema),
    zone_id       : Joi.array().items(Joi.string()).required(),
    billing_modes : Joi.array()
        .items(Joi.string().valid(...Object.values(EProductBillingMode)))
        .min(1)
        .required(),
    is_default_empty_run : Joi.boolean(),
    dumpster_type        : Joi.when('family', {
        is   : Joi.custom((value) => value.includes('DUMPSTER')),
        then : Joi.array()
            .items(Joi.string().valid(...Object.keys(PRODUCT_DUMPSTER_TYPE)))
            .min(1)
            .required(),
        otherwise: Joi.forbidden()
    })
});

export const ProductEditDtoSchema = Joi.object({
    name                : Joi.string(),
    subname             : Joi.string().allow('', null),
    description         : Joi.string().allow('', null),
    operational         : Joi.boolean().allow(null),
    price               : ProductPriceSchema,
    vat_rate_percentage : Joi.number().min(0).max(100),
    family              : Joi.string().valid(...Object.keys(PRODUCT_FAMILY)),
    billing_branch      : Joi.string().valid(...Object.keys(BillingBranch)),
    trash_type          : Joi.string().valid(...Object.keys(ETrashType)),
    volume_m3           : Joi.number().positive(),
    main_photo          : Joi.object({
        add    : DocumentCreateDtoSchema,
        remove : Joi.string().allow('', null)
    }),
    photo_id: Joi.object({
        add    : Joi.array().items(DocumentCreateDtoSchema),
        remove : Joi.array().items(Joi.string())
    }),
    zone_id: Joi.object({
        add    : Joi.array().items(Joi.string()),
        remove : Joi.array().items(Joi.string())
    }),
    billing_modes: Joi.array()
        .items(Joi.string().valid(...Object.values(EProductBillingMode)))
        .min(1),
    is_default_empty_run : Joi.boolean().valid(true), // only true is allowed, to remove a default, you need to set a default on another one
    dumpster_type        : Joi.array().items(Joi.string().valid(...Object.keys(PRODUCT_DUMPSTER_TYPE)))
});

export const ProductSetVisibilityDtoSchema = Joi.object({
    visibility: Joi.boolean().required()
});

export const ProductQuerySchema = Joi.object({
    trash_type           : Joi.string().valid(...Object.keys(ETrashType)),
    family               : Joi.string().valid(...Object.keys(PRODUCT_FAMILY)),
    families             : Joi.array().items(Joi.string().valid(...Object.keys(PRODUCT_FAMILY))),
    billing_branch       : Joi.string().valid(...Object.keys(BillingBranch)),
    zone_id              : Joi.alternatives(Joi.string(), Joi.array().items(Joi.string())),
    internal             : Joi.boolean(),
    operational          : Joi.boolean(),
    q                    : Joi.string(),
    postal_code          : Joi.string(),
    billing_modes        : Joi.array().items(Joi.string().valid(...Object.values(EProductBillingMode))),
    is_default_empty_run : Joi.boolean(),
    dumpster_type        : Joi.string().valid(...Object.keys(PRODUCT_DUMPSTER_TYPE)),
    dumpster_types       : Joi.array().items(Joi.string().valid(...Object.keys(PRODUCT_DUMPSTER_TYPE)))
}).concat(KafkaParamsQuerySchema);
