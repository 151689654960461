import React from 'react';
import styled from 'styled-components';
import equal from 'fast-deep-equal/react';

import { CardErrors, PhoneValue } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import Input from '../../components/Inputs';

export type PrestaGeneralProps = {
    readOnly?: boolean;
    value?: PrestaGeneralState;
    id: string;
    result: (value: PrestaGeneralState, errors: null | string[] | CardErrors<PrestaGeneralState>, id: string) => void;
    displayError?: boolean;
};

export type PrestaGeneralState = {
    name: string;
    email: string;
    phone_number: PhoneValue | null;
    siret: string;
    // headquarter?: AddressRo;
};

export type PrestaGeneralErrorState = CardErrors<PrestaGeneralState>;

export const initialState: PrestaGeneralState = {
    name         : '',
    email        : '',
    phone_number : null,
    siret        : ''
};

export const initialErrorState: PrestaGeneralErrorState = generateInitialErrorState(initialState);

export const PrestaGeneral = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: PrestaGeneralProps) => {
    const [val, setVal] = React.useState<PrestaGeneralState>(value);
    const [err, setErr] = React.useState<PrestaGeneralErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value2: any, errors: string[] | null, childId: string) => {
        if (equal(value2, val[childId as keyof PrestaGeneralErrorState])) return;

        setVal((prev) => ({ ...prev, [childId]: value2 }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <StyledCard title="Informations générales">
            <CardLine>
                <Input.Text
                    required
                    label="Nom du prestataire"
                    id="name"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.name}
                    errors={err.name}
                    displayError={displayError}
                />
                <Input.Email
                    required
                    label="Email"
                    id="email"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.email}
                    errors={err.email}
                    displayError={displayError}
                />
            </CardLine>
            <CardLine>
                <Input.Phone
                    required
                    id="phone_number"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.phone_number}
                    errors={err.phone_number}
                    displayError={displayError}
                />
                <Input.Siret
                    required
                    id="siret"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.siret}
                    errors={err.siret}
                    displayError={displayError}
                />
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
