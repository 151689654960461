import moment from 'moment';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
    CCServiceRo,
    CC_STATUS,
    CollectQuery,
    CollectRo,
    CollectServiceFront,
    CustomerRo,
    DocumentRo,
    ECustomerCategory,
    EDocumentType,
    EPlanningType,
    PlanningShiftStepCategory,
    isFetched
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import {
    mapCollectCategoryText,
    mapCollectConfigStatus,
    mapPlanningConfigRegion,
    mapPlanningConfigType
} from '../../common/enumMapper';
import { COLLECT_ORCHESTRATION_BASE_URL, urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import useQuery from '../../hooks/Query';
import { useRequest } from '../../hooks/StatelessRequest';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { mapStatusToIcon, mapStatusToSeverity } from '../CollectConfigs';
import { FiltersContainer } from '../CollectConfigs/style';

const StyledButton = styled(Button)`
    padding: 0;
`;

type QueryParams = {
    customer_id?: string;
};

export const BsdsListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [customer_id, setCustomerId] = useState<string | undefined>(query.customer_id);

    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [triggerPageRefresh, setTriggerPageRefresh] = useState<boolean>(false);
    useEffect(() => {
        setTriggerPageRefresh(false);
    }, [triggerPageRefresh]);

    const handleDownload = async (doc: DocumentRo): Promise<void> => {
        const response = await bbngRequest<string>(
            {
                method : 'GET',
                url    : urlApiBuilder.documentGetSignedUrl(doc.id)
            },
            true
        );
        if (!response.response?.data.ro) return undefined;

        const url: string = response.response.data.ro;
        const name: string = doc.name;

        const anchor = document.createElement('a');

        anchor.id = 'dummy-download-anchor';
        anchor.download = name;
        anchor.href = url;
        anchor.target = '_blank';

        anchor.click();
        anchor.remove();
    };

    return (
        <Listing<any, CollectServiceFront>
            url={COLLECT_ORCHESTRATION_BASE_URL}
            endpoint="collects"
            name="BSD"
            pluralName="BSDs"
            searchPlaceholder={`Rechercher par numéro de commande ou collecte`}
            hideCreateButton={true}
            displayActionColumn={false}
            triggerPageRefresh={triggerPageRefresh}
            hideBulkMenu={true}
            disableArchiveAction={true}
            displayBulkArchiveAction={false}
            enrichData={async (data) => {
                data.data.ro = await fetchDataRelation(data.data.ro ?? [], {
                    customer_id       : true,
                    collect_config_id : true,
                    document_id       : true
                });
                return data;
            }}
            queryParams={
                {
                    status   : CC_STATUS.FINISHED,
                    category : PlanningShiftStepCategory.SERVICE, // Warning: if this is changed, data retrieved is no longer CollectServiceFront
                    customer_id
                } as CollectQuery
            }
            leftHandedComponent={
                <FiltersContainer>
                    <RelationAutocomplete.Customer
                        placeholder="Filtrer par client"
                        onSelect={(customer) => setCustomerId(customer?.id)}
                        onUnselect={() => setCustomerId(undefined)}
                    />
                </FiltersContainer>
            }
            headers={[
                {
                    name      : 'Numéro collecte',
                    field     : 'informations',
                    component : (data: CollectServiceFront) => (
                        <a href={urlBuilder.collectView(data.id)}>
                            {(data.informations?.collect_config_id as CCServiceRo)?.number ?? 'N/A'}
                        </a>
                    )
                },
                {
                    name      : 'Client',
                    field     : 'informations',
                    component : (data: CollectServiceFront) => {
                        if (!data.informations) return <span>N/A</span>;
                        if (data.informations.customer_id) {
                            const customer = data.informations.customer_id as CustomerRo;
                            const isIndividual = customer.category === ECustomerCategory.INDIVIDUAL;
                            const individualIcon = <i className="pi pi-user" />;
                            return (
                                <>
                                    <Tooltip
                                        position="top"
                                        target={`.customer-${data.id.replace(':', '')}`}
                                        content={isIndividual ? 'Particulier' : undefined}
                                    />
                                    <span className={`customer-${data.id.replace(':', '')}`}>
                                        {customer.name} {isIndividual && individualIcon}
                                    </span>
                                </>
                            );
                        }
                        return <span>N/A</span>;
                    }
                },
                {
                    name      : 'Type',
                    field     : 'type',
                    width     : 80,
                    component : (data: CollectServiceFront) => (
                        <div>
                            <Tag
                                className="mr-2"
                                severity={data.type === EPlanningType.BIG_BAG ? 'success' : 'info'}
                                value={mapPlanningConfigType(data.type)}
                            />
                        </div>
                    )
                },
                {
                    name      : 'Catégorie',
                    field     : 'category',
                    width     : 120,
                    component : (data: CollectServiceFront) => <span>{mapCollectCategoryText(data.category)}</span>
                },
                {
                    name      : 'Région',
                    field     : 'region',
                    width     : 120,
                    component : (data: CollectServiceFront) => <span>{mapPlanningConfigRegion(data.region)}</span>
                },
                {
                    name      : 'Date',
                    field     : 'arrived_at',
                    width     : 100,
                    component : (data: CollectServiceFront) => (
                        <span>{moment(data.arrived_at).format('DD/MM/YYYY')}</span>
                    )
                },
                {
                    name      : 'Passage',
                    field     : 'completed_at',
                    width     : 150,
                    component : (data: CollectServiceFront) => (
                        <span>
                            {moment(data.arrived_at).format('HH:mm')} - {moment(data.completed_at).format('HH:mm')}
                        </span>
                    )
                },
                {
                    name      : 'Statut',
                    field     : 'status',
                    component : (data: CollectRo) => (
                        <Tag icon={mapStatusToIcon(data.status)} severity={mapStatusToSeverity(data.status)}>
                            {mapCollectConfigStatus(data.status)}
                        </Tag>
                    )
                },
                {
                    name      : 'BSD',
                    field     : 'document_id',
                    width     : 150,
                    component : (data: CollectServiceFront) => {
                        const bsdDocument = isFetched<CollectServiceFront, { document_id: DocumentRo[] }>(
                            data,
                            'document_id'
                        )
                            ? (data.document_id as DocumentRo[]).find((doc) =>
                                  doc?.id?.startsWith(`${EDocumentType.BSD}|`)
                              )
                            : undefined;
                        if (!bsdDocument) {
                            return <span>Non généré pour le moment</span>;
                        }
                        return (
                            <StyledButton
                                label="Télécharger"
                                className="p-button-text"
                                icon="pi pi-download"
                                onClick={() => handleDownload(bsdDocument)}
                            />
                        );
                    }
                }
            ]}
        />
    );
};
