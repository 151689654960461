import { Chips } from 'primereact/chips';
import * as yup from 'yup';

import { genericChipsInputProps } from '.';
import { useComponentInput } from '../../hooks/ComponentInput';

export function ChipsInput({
    label,
    readOnly,
    required,
    result,
    id,
    validationSchema,
    value = [''],
    errors,
    displayError
}: genericChipsInputProps) {
    const baseValidationSchema = yup.array();
    const schema = validationSchema
        ? validationSchema
        : required
        ? baseValidationSchema.required(`${label} requis`)
        : baseValidationSchema;

    const { handleBlur, handleOnChange, val, err, displayErr } = useComponentInput({
        id,
        value,
        errors,
        displayError        : displayError ?? false,
        result,
        required,
        schema,
        didRevalidateSchema : (e) => e?.length > 0,
        mapValue            : (e) => e.target.value
    });

    const manageChip = (e: any) => {
        const multiple = e.value.split(' ');
        value = [...value, ...multiple];
    };

    return (
        <span className="p-float-label">
            <Chips
                disabled={readOnly}
                name={id}
                className={`p-inputtext-sm ${err && err.length > 0 && displayErr ? 'p-invalid' : ''}`}
                id={id}
                onChange={handleOnChange}
                onAdd={manageChip}
                onBlur={handleBlur}
                value={val}
                tooltip={displayErr && err && err.length > 0 ? err.join('\n') : ''}
            />
            {label && <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>}
        </span>
    );
}
