import moment from 'moment';
import { Button as PRButton } from 'primereact/button';
import { Tag } from 'primereact/tag';
import React from 'react';
import styled from 'styled-components';

import { axiosClient } from '../../common/axios';
import { defaultErrorToast } from '../../common/syncRequest';
import { urlApiBuilder } from '../../common/urlBuilder';
import Button from '../../components/Button';
import { Dialog } from '../../components/Dialog';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { EPlanningFormSubmitAction } from './helpers';

export const GetPlanningInstanceButton: React.FC = () => {
    const { plannings, planningPageState, lastAction, collectorsByPlanning, trucksByPlanning } = usePlanningV2Store();
    const [showDialog, setShowDialog] = React.useState(false);

    const availableExport = React.useMemo(() => {
        return Object.values(plannings)
            .filter((p) => p.shift && p.collector_id.length > 0 && p.truck_id.length > 0)
            .map((e) => {
                const truck = trucksByPlanning[e.id];
                const collector = collectorsByPlanning[e.id];
                return {
                    id    : e.id,
                    label : `${collector?.fullname} - ${truck?.name} - ${moment(e.shift?.start_date).format(
                        'HH:mm'
                    )} - ${moment(e.shift?.end_date).format('HH:mm')}`
                };
            });
    }, [plannings]);

    const [selectedPlannings, setSelectedPlannings] = React.useState<Record<string, boolean>>(
        Object.fromEntries(availableExport.map((e) => [e.id, true]))
    );

    const [isLoading, setIsLoading] = React.useState(false);

    const exportPlanning = async (plannings: string[]) => {
        setIsLoading(true);

        try {
            const response = await axiosClient.post(
                urlApiBuilder.planningGetInstance(),
                {
                    ids     : plannings,
                    isDraft : lastAction === EPlanningFormSubmitAction.CALCULATE
                },
                {
                    responseType: 'blob'
                }
            );

            const formatedDay = moment.utc(planningPageState.day as string).format('YYYY-MM-DD');
            const name = `${planningPageState.region}-${formatedDay}-${planningPageState.type}-${
                lastAction === EPlanningFormSubmitAction.CALCULATE ? 'draft' : 'saved'
            }`;

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.data);
            link.download = `data-${name}.zip`;
            link.click();
            link.remove();
        } catch (e) {
            defaultErrorToast('Une erreur est survenue lors de la récupération des intances des plannings');
            console.error(e);
        }
        setIsLoading(false);
    };

    return (
        <>
            <PRButton
                type="button"
                disabled={availableExport.length === 0 || isLoading}
                className="p-button-sm p-button-secondary"
                icon={`pi ${isLoading ? 'pi-spinner pi-spin' : 'pi-download '}`}
                label="Récupérer les instances"
                onClick={() => setShowDialog(true)}
            />
            <Dialog
                visible={showDialog}
                onHide={() => setShowDialog(false)}
                header="Instances associées aux tournées"
                draggable={false}
                blockScroll
                contentStyle={{ overflow: 'scroll' }}
            >
                <ModalBody>
                    <Tag
                        value="Seules les tournées ayant un chauffeur sont affichées ici."
                        icon="pi pi-info-circle"
                        severity="warning"
                    />
                    <HeaderContainer>
                        <PRButton
                            className="p-button-text"
                            label="Tout sélectionner"
                            onClick={() => {
                                setSelectedPlannings((prev) =>
                                    Object.fromEntries(Object.keys(prev).map((e) => [e, true]))
                                );
                            }}
                        />
                        <PRButton
                            className="p-button-text"
                            label="Tout désélectionner"
                            onClick={() => {
                                setSelectedPlannings((prev) =>
                                    Object.fromEntries(Object.keys(prev).map((e) => [e, false]))
                                );
                            }}
                        />
                    </HeaderContainer>

                    {availableExport.map((e) => (
                        <Button.Checkbox
                            key={e.id}
                            label={e.label}
                            id={e.id}
                            value={selectedPlannings[e.id]}
                            result={(value) => {
                                setSelectedPlannings((prev) => ({
                                    ...prev,
                                    [e.id]: value
                                }));
                            }}
                            labelPosition="right"
                        />
                    ))}

                    <PRButton
                        label="Valider"
                        onClick={() => {
                            exportPlanning(
                                Object.entries(selectedPlannings)
                                    .filter((e) => e[1])
                                    .map((e) => e[0])
                            );
                            setShowDialog(false);
                        }}
                    />
                </ModalBody>
            </Dialog>
        </>
    );
};

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
