import React, { useState, useEffect } from 'react';
import { useRequest } from '../../hooks/StatelessRequest';
import { urlApiBuilder } from '../../common/urlBuilder';
import { ETrashType, RecyclingCharacteristicsRo, RecyclingCharacteristicCreateActionDto } from '@bbng/util/types';
import { mapTrashTypetext } from '../../common/enumMapper';
import { NumberInput } from '../Inputs/Number';
import { CalendarDayMonthYear } from '../Calendar/Day&Month&Year';
import { initialRecyclingCharacteristics } from './helper';
import { Button } from 'primereact/button';
import styled from 'styled-components';
import { Card } from '../Card';

export type RevalorisationModalProps = {
    id: string;
    landfill_id: string;
    setOpenModal: (value: boolean) => void;
    readOnly: boolean;
};

export const RevalorisationModal: React.FC<RevalorisationModalProps> = ({
    id,
    setOpenModal,
    landfill_id,
    readOnly
}: RevalorisationModalProps) => {
    const [revalorisation, setRevalorisation] = useState<RecyclingCharacteristicsRo>(initialRecyclingCharacteristics());
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    useEffect(() => {
        const fetchRevalorisation = async (id: string) => {
            if (!id) {
                return;
            }
            const url = urlApiBuilder.landfillRevalorisationGet(id);
            const response = await bbngRequest<RecyclingCharacteristicsRo>({
                url,
                method: 'GET'
            });

            if (response.response?.data.ro && response.response?.data.ro !== undefined) {
                setRevalorisation(response.response?.data.ro);
            }
        };
        fetchRevalorisation(id);
    }, []);

    const submitRevalorisation = async () => {
        const dto: RecyclingCharacteristicCreateActionDto = {
            landfill_id    : landfill_id,
            recycling_info : revalorisation.recycling_info,
            from_date      : revalorisation.from_date,
            to_date        : revalorisation.to_date === null ? undefined : String(revalorisation.to_date)
        };

        const response = await bbngRequest<RecyclingCharacteristicsRo>({
            url     : id ? urlApiBuilder.landfillRevalorisationUpdate(id) : urlApiBuilder.landfillRevalorisationCreate(),
            method  : id ? 'PATCH' : 'POST',
            payload : {
                body: {
                    ...dto
                }
            }
        });
        if (response.response?.data.ro && response.response?.data.ro !== undefined) {
            setRevalorisation(initialRecyclingCharacteristics());
            setOpenModal(false);
        }
    };

    return (
        <>
            <StyledCard>
                <div
                    style={{
                        display        : 'flex',
                        flexDirection  : 'row',
                        flexWrap       : 'wrap',
                        justifyContent : 'space-around'
                    }}
                >
                    <CalendarDayMonthYear
                        label="Date de commencement"
                        id="from_date"
                        value={revalorisation.from_date}
                        result={(val) => setRevalorisation({ ...revalorisation, from_date: val })}
                        errors={[]}
                        readOnly={false}
                        required={true}
                    />
                    <CalendarDayMonthYear
                        label="Date de fin"
                        id="to_date"
                        value={String(revalorisation.to_date)}
                        result={(val) => setRevalorisation({ ...revalorisation, to_date: val })}
                        errors={[]}
                        readOnly={false}
                        required={false}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {Object.values(ETrashType).map((trashType) => {
                        const trash = mapTrashTypetext(trashType);

                        return (
                            <div style={{ margin: 16, width: '20vw' }}>
                                <h3>{trash}</h3>
                                <div style={{ margin: 24 }}>
                                    <NumberInput
                                        readOnly={readOnly}
                                        id="reusage"
                                        required={false}
                                        max={100}
                                        min={0}
                                        errors={[]}
                                        label="Taux de revalorisation matière"
                                        value={Number(revalorisation?.recycling_info?.[trashType]?.reusage)}
                                        result={(val) =>
                                            setRevalorisation({
                                                ...revalorisation,
                                                recycling_info: {
                                                    ...revalorisation?.recycling_info,
                                                    [trashType]: {
                                                        ...revalorisation?.recycling_info?.[trashType],
                                                        reusage: val
                                                    }
                                                }
                                            })
                                        }
                                    />
                                </div>
                                <div style={{ margin: 24 }}>
                                    <NumberInput
                                        readOnly={readOnly}
                                        id="recycling"
                                        required={false}
                                        errors={[]}
                                        max={100}
                                        min={0}
                                        label="Taux de recyclage"
                                        value={Number(revalorisation?.recycling_info?.[trashType]?.recycling)}
                                        result={(val) =>
                                            setRevalorisation({
                                                ...revalorisation,
                                                recycling_info: {
                                                    ...revalorisation?.recycling_info,
                                                    [trashType]: {
                                                        ...revalorisation?.recycling_info?.[trashType],
                                                        recycling: val
                                                    }
                                                }
                                            })
                                        }
                                    />
                                </div>
                                <div style={{ margin: 24 }}>
                                    <NumberInput
                                        readOnly={readOnly}
                                        id="elimination"
                                        required={false}
                                        errors={[]}
                                        max={100}
                                        min={0}
                                        label="Taux d'elimination"
                                        value={Number(revalorisation?.recycling_info?.[trashType]?.elimination)}
                                        result={(val) =>
                                            setRevalorisation({
                                                ...revalorisation,
                                                recycling_info: {
                                                    ...revalorisation?.recycling_info,
                                                    [trashType]: {
                                                        ...revalorisation?.recycling_info?.[trashType],
                                                        elimination: val
                                                    }
                                                }
                                            })
                                        }
                                    />
                                </div>
                                <div style={{ margin: 24 }}>
                                    <NumberInput
                                        readOnly={readOnly}
                                        id="energetic_valorisation"
                                        required={false}
                                        errors={[]}
                                        max={100}
                                        min={0}
                                        label="Taux de valorisation energetique"
                                        value={Number(
                                            revalorisation?.recycling_info?.[trashType]?.energetic_valorisation
                                        )}
                                        result={(val) =>
                                            setRevalorisation({
                                                ...revalorisation,
                                                recycling_info: {
                                                    ...revalorisation?.recycling_info,
                                                    [trashType]: {
                                                        ...revalorisation?.recycling_info?.[trashType],
                                                        energetic_valorisation: val
                                                    }
                                                }
                                            })
                                        }
                                    />
                                </div>
                                <div style={{ margin: 24 }}>
                                    <NumberInput
                                        readOnly={readOnly}
                                        id="global_valorisation"
                                        required={false}
                                        errors={[]}
                                        max={100}
                                        min={0}
                                        label="Taux de valorisation global"
                                        value={Number(revalorisation?.recycling_info?.[trashType]?.global_valorisation)}
                                        result={(val) =>
                                            setRevalorisation({
                                                ...revalorisation,
                                                recycling_info: {
                                                    ...revalorisation?.recycling_info,
                                                    [trashType]: {
                                                        ...revalorisation?.recycling_info?.[trashType],
                                                        global_valorisation: val
                                                    }
                                                }
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </StyledCard>

            <div
                style={{
                    display        : 'flex',
                    flexDirection  : 'row',
                    flexWrap       : 'wrap',
                    justifyContent : 'space-around',
                    margin         : '16px 0'
                }}
            >
                {readOnly === false && (
                    <>
                        <Button
                            label="Annuler"
                            type="button"
                            className="p-button-danger"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        />
                        <Button
                            label="Confirmer"
                            type="button"
                            onClick={() => {
                                submitRevalorisation();
                            }}
                        />
                    </>
                )}
                {readOnly === true && (
                    <>
                        <Button
                            label="Fermer"
                            type="button"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        />
                    </>
                )}
            </div>
        </>
    );
};

const StyledCard = styled(Card)``;
