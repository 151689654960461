import moment from 'moment';
import { Button as PRButton } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Divider } from 'primereact/divider';

import { deepCopy, secondsToDuration } from '@bbng/util/misc';

import Button from '../../components/Button';
import { PlanningFormState, removeShiftsWithoutCollectorsOrUnavailable, removeUndisplayedShifts } from './helpers';
import { Subtitle } from './style';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import Input from '../../components/Inputs';
import { Message } from 'primereact/message';
import { CheckboxButton } from '../../components/Button/Checkbox';

export type PlanningPreSubmitModalProps = {
    data: PlanningFormState;
    submitCallback: (values: PlanningFormState) => void;
    setModalIsOpen: (isOpen: boolean) => void;
    setSelectedPlannings: React.Dispatch<React.SetStateAction<PlanningFormState | undefined>>;
    setSelectedZoneIds: React.Dispatch<React.SetStateAction<string[]>>;
    selectedZoneIds: string[];
};

export const PlanningPreSubmitModal: React.FC<PlanningPreSubmitModalProps> = ({
    data,
    submitCallback,
    setModalIsOpen,
    setSelectedPlannings,
    setSelectedZoneIds,
    selectedZoneIds
}: PlanningPreSubmitModalProps): JSX.Element => {
    const { zones, planningPageState, dumpsterTypes, trucksByPlanning } = usePlanningV2Store();

    const [internalData, setInternalData] = useState<PlanningFormState>(
        deepCopy({
            ...data,
            shifts: removeUndisplayedShifts({
                shifts       : removeShiftsWithoutCollectorsOrUnavailable(data.shifts),
                planningType : planningPageState.type,
                dumpsterTypes,
                trucksByPlanning
            })
        })
    );

    /**
     * On mount, select all zones
     */
    useEffect(() => {
        setSelectedZoneIds(Object.keys(zones));
    }, []);

    return (
        <div>
            <ListContainer>
                <Tag
                    value="Seules les tournées ayant un chauffeur sont affichées ici."
                    icon="pi pi-info-circle"
                    severity="warning"
                />
                <Subtitle>Chauffeurs à inclure</Subtitle>
                <HeaderContainer>
                    <PRButton
                        className="p-button-text"
                        label="Tout sélectionner"
                        onClick={() => {
                            setInternalData((old) => ({
                                ...old,
                                shifts: Object.fromEntries(
                                    Object.entries(old.shifts).map(([key, value]) => [
                                        key,
                                        { ...value, selected: true }
                                    ])
                                )
                            }));
                        }}
                    />
                    <PRButton
                        className="p-button-text"
                        label="Tout désélectionner"
                        onClick={() => {
                            setInternalData((old) => ({
                                ...old,
                                shifts: Object.fromEntries(
                                    Object.entries(old.shifts).map(([key, value]) => [
                                        key,
                                        { ...value, selected: false }
                                    ])
                                )
                            }));
                        }}
                    />
                </HeaderContainer>
                {Object.values(internalData.shifts).map((shift) => (
                    <RowContainer key={shift.id}>
                        <Button.Checkbox
                            label={`${shift.truck?.name} - ${shift.collector?.firstname} ${
                                shift.collector?.lastname
                            } - ${moment(shift.start_date).format('HH:mm')} - ${moment(shift.end_date).format(
                                'HH:mm'
                            )}`}
                            id={shift.truck?.id as string}
                            value={shift.selected}
                            result={(value) => {
                                setInternalData((old) => {
                                    return {
                                        ...old,
                                        shifts: {
                                            ...old.shifts,
                                            [shift.truck?.id as string]: {
                                                ...old.shifts[shift.truck?.id as string],
                                                selected: value
                                            }
                                        }
                                    };
                                });
                            }}
                            labelPosition="right"
                        />
                    </RowContainer>
                ))}
                <Divider />
                {
                    // If there is more than one zone, display the zone selection (no need to select a zone if there is only one)
                    Object.keys(zones)?.length > 1 && (
                        <>
                            <Subtitle>Zones à inclure</Subtitle>
                            {Object.entries(zones).map((zone) => {
                                const [zoneId, zoneBody] = zone;
                                return (
                                    <RowContainer key={zoneId}>
                                        <Button.Checkbox
                                            label={`${zoneBody.name}`}
                                            id={zoneId}
                                            value={selectedZoneIds.includes(zoneId)}
                                            result={(value) => {
                                                setSelectedZoneIds((old) => {
                                                    if (value) {
                                                        return [...old, zoneId];
                                                    } else {
                                                        return old.filter((id) => id !== zoneId);
                                                    }
                                                });
                                            }}
                                            labelPosition="right"
                                        />
                                    </RowContainer>
                                );
                            })}
                        </>
                    )
                }
                <Subtitle>Marge de dépassement des créneaux</Subtitle>
                <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '1em' }}
                >
                    <div>
                        <Input.Number
                            id="begin_slot_margin_minutes"
                            errors={[]}
                            required={false}
                            label="Marge avant collecte"
                            value={internalData.settings.begin_slot_margin_minutes}
                            result={(value) => {
                                setInternalData((old) => ({
                                    ...old,
                                    settings: {
                                        ...old.settings,
                                        begin_slot_margin_minutes: value ?? 0
                                    }
                                }));
                            }}
                            min={0}
                            step={5}
                            suffix=" minutes"
                            width="200"
                        />
                    </div>
                    <div>
                        <Input.Number
                            id="end_slot_margin_minutes"
                            errors={[]}
                            required={false}
                            label="Marge après collecte"
                            value={internalData.settings.end_slot_margin_minutes}
                            result={(value) => {
                                setInternalData((old) => ({
                                    ...old,
                                    settings: {
                                        ...old.settings,
                                        end_slot_margin_minutes: value ?? 0
                                    }
                                }));
                            }}
                            min={0}
                            step={5}
                            suffix=" minutes"
                            width="200"
                        />
                    </div>
                </div>
                <Divider />
                <Subtitle>Temps de calcul</Subtitle>
                <CenteredContainer>
                    <Input.Number
                        id="optimization_duration_seconds"
                        errors={[]}
                        required={false}
                        label=""
                        value={internalData.settings.optimization_duration_seconds}
                        result={(value) => {
                            setInternalData((old) => ({
                                ...old,
                                settings: {
                                    ...old.settings,
                                    optimization_duration_seconds: value ?? 0
                                }
                            }));
                        }}
                        min={0}
                        step={15}
                        suffix={` secondes (${secondsToDuration({
                            seconds: internalData.settings.optimization_duration_seconds
                        })})`}
                        size={40}
                    />
                    <Message
                        severity="info"
                        text="Le temps total de la requête sera légèrement plus long (total = préparation + calcul + formatage des données)."
                    />
                </CenteredContainer>
                <Divider />
                <CenteredContainer>
                    <CheckboxButton
                        label="Ignorer HERE.COM"
                        labelPosition="right"
                        id="ignore_here"
                        value={internalData.settings.ignore_here}
                        result={(val) => {
                            setInternalData((old) => ({ ...old, settings: { ...old.settings, ignore_here: val } }));
                        }}
                    />
                </CenteredContainer>
                <Divider />
            </ListContainer>
            <PRButton
                style={{ width: '100%' }}
                label="Valider"
                onClick={() => {
                    setModalIsOpen(false);
                    setSelectedPlannings(internalData);
                    submitCallback(internalData);
                }}
                disabled={
                    Object.values(internalData.shifts).every((shift) => !shift.selected) || selectedZoneIds.length === 0
                }
            />
        </div>
    );
};

const ListContainer = styled.div`
    margin-bottom: 8px;
`;
const RowContainer = styled.div`
    padding: 10px;
`;

const HeaderContainer = styled.div`
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
`;

const CenteredContainer = styled.div`
    padding-top: 8px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
