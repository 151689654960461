import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { CardErrors } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import Calendar from '../../components/Calendar';
import { Card } from '../../components/Card';

export type DiscountGeneralProps = {
    readOnly?: boolean;
    value?: DiscountGeneralState;
    id: string;
    result: (
        value: DiscountGeneralState,
        errors: null | string[] | CardErrors<DiscountGeneralState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type DiscountGeneralState = {
    start_date?: Date;
    has_end_date: Array<string>;
    end_date?: Date;
};

export type DiscountGeneralErrorState = CardErrors<DiscountGeneralState>;

export const initialState: DiscountGeneralState = {
    start_date   : new Date(),
    has_end_date : [],
    end_date     : undefined
};

export const initialErrorState: DiscountGeneralErrorState = generateInitialErrorState(initialState);

export const DiscountGeneral = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: DiscountGeneralProps) => {
    const [val, setVal] = React.useState<DiscountGeneralState>(value);
    const [err, setErr] = React.useState<DiscountGeneralErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = React.useCallback(
        (value: any, errors: string[] | null, childId: string) => {
            setVal((prev) => ({ ...prev, [childId]: value }));
            setErr((prev) => ({ ...prev, [childId]: errors }));
        },
        [setVal, setErr]
    );

    React.useEffect(() => {
        if (moment(val.end_date).isBefore(moment(val.start_date))) {
            setVal((prev) => ({ ...prev, end_date: val.start_date }));
        }
    }, [val.has_end_date, val.start_date]);

    return (
        <StyledCard title="Informations générales">
            <CardLine>
                <Calendar.DayMonthYear
                    required
                    label="Début de la remise"
                    id="start_date"
                    readOnly={readOnly}
                    result={handleChange}
                    value={moment(val.start_date).toISOString()}
                    errors={err.start_date}
                    displayError={displayError}
                    minDate={moment.utc().toISOString()}
                />
                <Button.Group
                    id="has_end_date"
                    options={['has_end_date']}
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.has_end_date}
                    labelMapper={() => 'Remise temporaire'}
                />
                <Calendar.DayMonthYear
                    required
                    label="Fin de la remise"
                    id="end_date"
                    readOnly={readOnly || val.has_end_date.includes('has_end_date') === false}
                    result={handleChange}
                    value={moment(val.end_date).toISOString()}
                    errors={err.end_date}
                    displayError={displayError}
                    minDate={moment(val.start_date).toISOString()}
                    validationSchema={yup.date().test({
                        name    : 'end_date',
                        message : 'La fin de remise doit être ultérieure à son début',
                        test    : (value) => {
                            if (readOnly || val.has_end_date.includes('has_end_date') === false) return true;
                            return moment(value).isSameOrAfter(moment(val.start_date));
                        }
                    })}
                />
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
