import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react';

import { capitalize } from '@bbng/util/misc';
import { VrpCalculPriceRo } from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import { useRequest } from '../../hooks/StatelessRequest';
import {
    GlobalPrice,
    GlobalPriceLabel,
    GlobalPriceValue,
    OverviewLineWrapper,
    PriceContainer,
    StyledItalic,
    StyledTable
} from './style';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';

export const PlanningCosts: React.FC = (): JSX.Element => {
    const bbngRequest = useRequest();
    const { planningPageState } = usePlanningV2Store();
    const [priceDetails, setPriceDetails] = useState<VrpCalculPriceRo>();

    const fetchData = React.useCallback(async () => {
        const res = await bbngRequest<VrpCalculPriceRo>({
            sync    : true,
            method  : 'GET',
            url     : urlApiBuilder.planningPrice(),
            payload : {
                queryParams: {
                    region : planningPageState.region,
                    day    : planningPageState.day,
                    type   : planningPageState.type
                }
            },
            options      : { toastifySuccess: false },
            retryPolling : 1000
        });
        const { success, response } = res;

        if (success && response?.data.ro) {
            setPriceDetails(response?.data?.ro);
        }
    }, [planningPageState]);

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(planningPageState)]);

    return (
        <OverviewLineWrapper>
            {priceDetails ? (
                <PriceContainer>
                    <GlobalPrice>
                        <GlobalPriceLabel>Coût global</GlobalPriceLabel>
                        <GlobalPriceValue>{priceDetails?.price}€</GlobalPriceValue>
                    </GlobalPrice>
                    <StyledTable>
                        <thead>
                            <tr>
                                <th>Utilisateur</th>
                                <th>Nombre de calculs *</th>
                                <th>Coût total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(priceDetails.details).map((detail, index) => {
                                const price = detail.reduce((acc, val) => acc + val.price, 0);
                                const { email, firstname, lastname } = detail[0];
                                return (
                                    <tr>
                                        <td>
                                            {capitalize(firstname)} {capitalize(lastname)} ({email})
                                        </td>
                                        <td>{detail.length}</td>
                                        <td>{price.toFixed(2)}€</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </StyledTable>
                    <StyledItalic>
                        * Nombre de calculs ayant engendré une facturation sur Here.com uniquement.
                    </StyledItalic>
                </PriceContainer>
            ) : (
                <Skeleton height="16px" width="300px" />
            )}
        </OverviewLineWrapper>
    );
};
