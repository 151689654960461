import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { CC_PATCH_ORIGIN, COLLECT_CONFIG_PATCH_REASON_MIN_LENGTH, CardErrors } from '@bbng/util/types';

import { mapCollectConfigPatchOrigin } from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import Input, { textInputProps } from '../../components/Inputs';
import { useFormModule } from '../../hooks/FormModule';

export type PatchTrackerProps = {
    readOnly?: boolean;
    value?: PatchTrackerState;
    id: string;
    result: (value: PatchTrackerState, errors: null | string[] | CardErrors<PatchTrackerState>, id: string) => void;
    displayError?: boolean;
    showExecutionTimeMinutes?: boolean;
};

export type PatchTrackerState = {
    patch_origin: CC_PATCH_ORIGIN;
    patch_reason: string;
};

export type PatchTrackerErrorState = CardErrors<PatchTrackerState>;

export const initialState: PatchTrackerState = {
    patch_origin : CC_PATCH_ORIGIN.CLIENT_REQUEST,
    patch_reason : ''
};

export const initialErrorState: PatchTrackerErrorState = generateInitialErrorState(initialState);

export const initialRef = Array.from(Array(Object.keys(initialState).length)).map(() =>
    React.createRef<textInputProps>()
);

export const PatchTracker = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: PatchTrackerProps) => {
    const { val, setVal, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    return (
        <StyledCard>
            <CardLine>
                <Button.Select
                    forceSelection
                    id="patch_origin"
                    readOnly={readOnly}
                    required={true}
                    displayError={displayError}
                    result={handleChange}
                    options={Object.values(CC_PATCH_ORIGIN)}
                    value={val.patch_origin}
                    errors={err.patch_origin}
                    labelMapper={(value: string) => mapCollectConfigPatchOrigin(value as CC_PATCH_ORIGIN)}
                />
                <Input.Textarea
                    required={true}
                    label="Raison de la modification"
                    id="patch_reason"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.patch_reason || ''}
                    errors={err.patch_reason}
                    displayError={displayError}
                    validationSchema={yup.string().min(COLLECT_CONFIG_PATCH_REASON_MIN_LENGTH).required()}
                />
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)`
    box-shadow: none;
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;
