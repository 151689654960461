import * as yup from 'yup';

import { isVAT } from '@bbng/util/misc';

import { textInputProps } from '.';
import { MaskInput } from './Mask';

export function VATInput({
    readOnly,
    required,
    result,
    id,
    validationSchema,
    value = '',
    errors,
    displayError
}: textInputProps) {
    const baseValidationSchema = yup.string().test({
        name    : 'vat',
        message : "Ce numéro de TVA n'est pas valide.",
        test    : (value) => (value ? isVAT(value) : false)
    });
    const schema = validationSchema ? validationSchema : baseValidationSchema;

    return (
        <MaskInput
            label="TVA intracommunautaire"
            readOnly={readOnly}
            mask="aa 99 999 999 999"
            id={id}
            value={value}
            size={18}
            required={required}
            errors={errors}
            result={result}
            displayError={displayError}
            validationSchema={schema}
        />
    );
}
