import { nanoid } from 'nanoid';
import { Button } from 'primereact/button';
import React from 'react';
import styled from 'styled-components';

import { match } from '@bbng/util/misc';
import { ProductInCCOrCO } from '@bbng/util/types';

import { mapCollectConfigFamilyText } from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import {
    CollectConfigSplitLine,
    CollectConfigSplitLineErrorState,
    CollectConfigSplitLineState,
    initialErrorState as lineInitialErrorState,
    initialState as lineInitialState
} from './SplitLine';

export type CollectConfigSplitProps = {
    products: ProductInCCOrCO[];
    value?: CollectConfigSplitState;
    id: string;
    result: (
        value: CollectConfigSplitState,
        errors: null | string[] | CollectConfigSplitErrorState,
        id: string
    ) => void;
    displayError?: boolean;
};

export type CollectConfigSplitState = {
    [key: string]: CollectConfigSplitLineState;
};

export type CollectConfigSplitErrorState = {
    [key: string]: CollectConfigSplitLineErrorState;
};

/**
 * Initial value is made of two sub-collects.
 */
const id1 = nanoid();
const id2 = nanoid();
export const initialState = (products: ProductInCCOrCO[]): CollectConfigSplitState => {
    return {
        [id1] : lineInitialState(products),
        [id2] : lineInitialState(products)
    };
};

export const initialErrorState = (products: ProductInCCOrCO[]): CollectConfigSplitErrorState => {
    return {
        [id1] : generateInitialErrorState(lineInitialState(products)),
        [id2] : generateInitialErrorState(lineInitialState(products))
    };
};

export const CollectConfigSplit = ({ products, value, id, result, displayError = false }: CollectConfigSplitProps) => {
    const [val, setVal] = React.useState<CollectConfigSplitState>(value || initialState(products));
    const [err, setErr] = React.useState<CollectConfigSplitErrorState>(initialErrorState(products));

    React.useEffect(() => {
        if (value && match(value, val) === false) {
            setVal(value);
        }
    }, [value]);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = React.useCallback(
        (childValue: any, childErrors: CollectConfigSplitLineErrorState, childId: string) => {
            setVal((prev) => {
                const data = { ...prev, [childId]: childValue };
                setErr((old) => ({
                    ...old,
                    [childId]: childErrors
                }));
                return data;
            });
        },
        [setVal, setErr]
    );

    return (
        <SplitContainer>
            <StyledTable cellPadding={8}>
                <thead>
                    <tr>
                        <th>Sous-collectes</th>
                        {products.map((product) => (
                            <th>
                                {mapCollectConfigFamilyText(product.family, true)} - {product.volume_m3}m³
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(val).map(([key, line], index) => (
                        <tr key={key}>
                            <td>Sous-collecte n°{index + 1}</td>
                            <CollectConfigSplitLine
                                key={key}
                                products={products}
                                value={line}
                                id={key}
                                result={handleChange as any}
                                errors={err[key]}
                                displayError={displayError}
                            />
                            <td>
                                {
                                    <Button
                                        disabled={Object.keys(val).length <= 2}
                                        icon="pi pi-times"
                                        type="button"
                                        className="p-button-rounded p-button-danger p-button-text"
                                        aria-label="Supprimer"
                                        onClick={() => {
                                            setVal((old) => {
                                                delete old[key];
                                                setErr((oldErr) => {
                                                    delete oldErr[key];
                                                    result(old, oldErr, id);
                                                    return oldErr;
                                                });
                                                return old;
                                            });
                                        }}
                                    />
                                }
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>Collecte initiale</td>
                        {products.map((product) => (
                            <td>
                                {product.quantity} ({product.volume_m3 * product.quantity}m³)
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td>Sous-collectes</td>
                        {products.map((product) => {
                            const subCollectsQuantity = Object.values(val).reduce((acc, line) => {
                                return acc + line[product.id];
                            }, 0);
                            const error = subCollectsQuantity !== product.quantity;
                            return (
                                <StyledTd error={error}>
                                    {subCollectsQuantity} ({product.volume_m3 * subCollectsQuantity}m³)
                                </StyledTd>
                            );
                        })}
                    </tr>
                </tbody>
            </StyledTable>
            <Button
                icon="pi pi-plus"
                className="p-button-outlined"
                type="button"
                label="Ajouter une sous-collecte"
                onClick={() => {
                    setVal((old) => {
                        const newId = nanoid();
                        const newVal = {
                            ...old,
                            [newId]: lineInitialState(products)
                        };
                        setErr((oldErr) => {
                            const newErr = {
                                ...oldErr,
                                [newId]: lineInitialErrorState(products)
                            };
                            result(newVal, newErr, id);
                            return newErr;
                        });
                        return newVal;
                    });
                }}
            />
        </SplitContainer>
    );
};

const StyledTable = styled.table`
    & td {
        vertical-align: middle;
        align-items: center;
        text-align: center;
    }
`;

const SplitContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 8px;
`;

const StyledTd = styled.td<{ error?: boolean }>`
    color: ${({ error }) => (error ? '#EF4444' : '#495057')};
`;
