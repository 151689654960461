import { Accordion } from 'primereact/accordion';
import { Button } from 'primereact/button';
import styled from 'styled-components';

export const Page = styled.div`
    margin: 16px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    & > * {
        flex: 1;
    }
    gap: 16px;
    & h1 {
        margin: 0;
    }
`;

export const PlanningContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PlanningPlaceholder = styled.div`
    margin: auto;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

export const Header = styled.div`
    margin: 0 10%;
    display: flex;
    justify-content: space-evenly;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const StyledAccordion = styled(Accordion)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    & .p-accordion-header-link {
        padding: 0 1.25em !important;
    }
    & .p-accordion-content {
        background: transparent;
    }
    & .p-accordion-tab {
        width: 100%;
        background: transparent;
    }
`;

export const StyledH2 = styled.h2`
    width: 100%;
    font-size: 1.4em;
    display: flex;
    align-items: center;
`;
