import { InputTextarea } from 'primereact/inputtextarea';
import * as yup from 'yup';

import { genericTextareaInpuProps } from '.';
import { useComponentInput } from '../../hooks/ComponentInput';

export function TextareaInput({
    label,
    readOnly,
    required,
    result,
    id,
    validationSchema,
    value = '',
    errors,
    displayError,
    minHeight = '100px',
    width = '500px'
}: genericTextareaInpuProps) {
    const baseValidationSchema = yup.string();
    const schema = validationSchema
        ? validationSchema
        : required
        ? baseValidationSchema.required(`${label} requis`)
        : baseValidationSchema;

    const { handleBlur, handleOnChange, val, err, displayErr } = useComponentInput({
        id,
        value,
        errors,
        displayError        : displayError ?? false,
        result,
        required,
        schema,
        didRevalidateSchema : (e) => e.length > 0,
        mapValue            : (e) => e.target.value
    });

    return (
        <span className="p-float-label">
            <InputTextarea
                disabled={readOnly}
                name={id}
                className={`p-inputtext-sm ${displayErr && err && err.length > 0 ? 'p-invalid' : ''}`}
                id={id}
                onChange={handleOnChange}
                onBlur={handleBlur}
                value={val}
                tooltip={displayErr && err && err.length > 0 ? err.join('\n') : ''}
                autoResize
                style={{ width, minHeight }}
            />
            <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>
        </span>
    );
}
