import React from 'react';
import styled from 'styled-components';

import { CardErrors } from '@bbng/util/types';

import { Card } from '../../components/Card';
import InputList from '../InputList';
import { FrontCustomerContact } from '../InputList/customer-contact';

export type CustomerContactsProps = {
    readOnly?: boolean;
    value?: CustomerContactsState;
    id: string;
    result: (
        value: CustomerContactsState,
        errors: null | string[] | CardErrors<CustomerContactsState>,
        id: string
    ) => void;
    displayError?: boolean;
    emailRequired?: boolean;
};

export type CustomerContactsState = {
    user: Array<FrontCustomerContact>;
};
export type CustomerContactsErrorState = CardErrors<CustomerContactsState>;

export const initialState: CustomerContactsState = {
    user: []
};
export const initialErrorState: CustomerContactsErrorState = Object.fromEntries(
    Object.keys(initialState).map((k) => [k, null])
) as CustomerContactsErrorState;

export const CustomerContacts: React.FC<CustomerContactsProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError,
    emailRequired = false
}: CustomerContactsProps) => {
    const [val, setVal] = React.useState<CustomerContactsState>(value);
    const [err, setErr] = React.useState<CustomerContactsErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <StyledCard title="Liste de contacts">
            <FullLineInput>
                <InputList.CustomerContacts
                    title="Contact"
                    onChange={(value) => handleChange(value, null, 'user')}
                    value={val.user}
                    readOnly={readOnly}
                    displayError={true}
                    emailRequired={emailRequired}
                />
            </FullLineInput>
        </StyledCard>
    );
};

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)`
    flex: 2 !important;
    .subtitle {
        margin-top: 0;
        margin-bottom: 5px;
    }
`;
