import styled from 'styled-components';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { AllowedDays } from '@bbng/util/types';
import moment from 'moment';

export type ConfirmDayModalProps = {
    visible?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    allowedDays?: AllowedDays;
    zoneName?: string;
};

export const ConfirmDayModal: React.FC<ConfirmDayModalProps> = ({
    visible = false,
    onConfirm = () => void 0,
    onCancel = () => void 0,
    allowedDays,
    zoneName
}) => {
    return (
        <StyledModal visible={visible} onHide={onCancel} header="Attention !">
            <div>
                <h3>
                    <span role="img" aria-label="danger">
                        ⚠️
                    </span>
                    Pour la zone {zoneName} le jour demandé doit être le {displayAllowedDays(allowedDays)} !!!
                    <span role="img" aria-label="danger">
                        ⚠️
                    </span>
                </h3>
            </div>
            <br />
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Voulez-vous confirmer la commande ?</p>
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button label="Confirmer" style={{ backgroundColor: 'red' }} onClick={onConfirm} />
                <Button label="Annuler" onClick={onCancel} />
            </div>
        </StyledModal>
    );
};

const StyledModal = styled(Dialog)`
    min-width: 500px;
`;

const displayAllowedDays = (allowedDays?: AllowedDays): string => {
    if (!allowedDays) return '';
    const days = Object.entries(allowedDays)
        .filter(([, value]) => value)
        .map(([key]) =>
            moment()
                .set({ weekday: Number(key) })
                .format('dddd')
        );

    return days.join(', ');
};
