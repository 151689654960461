import { Button, ButtonProps } from 'primereact/button';
import React from 'react';
import styled from 'styled-components';

export type PulseButtonProps = ButtonProps & {
    pulse?: boolean;
};

export const PulseButton: React.FC<PulseButtonProps> = ({ pulse = false, ...props }: PulseButtonProps) => {
    return <StyledButton pulse={pulse ? true : undefined} {...props} />;
};

const StyledButton = styled(Button)<{ pulse?: boolean }>`
    ${({ pulse }) =>
        pulse &&
        `
        animation: pulse 1s infinite;
    `}

    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
        }
        70% {
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
`;

export default PulseButton;
