import { Button } from 'primereact/button';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Confetti from 'react-confetti';

import { CardErrors, ConstructionSiteRo, CustomerRo, DocumentRo, EDocumentType } from '@bbng/util/types';

import { mapDocumentType } from '../../common/enumMapper';
import { TDocument } from '../../common/form';
import { urlApiBuilder } from '../../common/urlBuilder';
import { Card } from '../../components/Card';
import { Dropzone } from '../../components/Dropzone';
import Input from '../../components/Inputs';
import { useRequest } from '../../hooks/StatelessRequest';
import InputList from '../InputList';
import { useFormModule } from '../../hooks/FormModule';

export type OrderDocumentsProps = {
    readOnly?: boolean;
    value?: OrderDocumentsState;
    id: string;
    result: (value: OrderDocumentsState, errors: null | string[] | CardErrors<OrderDocumentsState>, id: string) => void;
    displayError?: boolean;
    customer?: CustomerRo;
    withContainer?: boolean;
    constructionSite?: ConstructionSiteRo;
};

export type OrderDocumentsState = {
    documents: TDocument[];
    order_sheet_number: string;
};
export type OrderDocumentsErrorState = CardErrors<OrderDocumentsState>;

export const initialState: OrderDocumentsState = {
    documents          : [],
    order_sheet_number : ''
};
export const initialErrorState: OrderDocumentsErrorState = Object.fromEntries(
    Object.keys(initialState).map((k) => [k, null])
) as OrderDocumentsErrorState;

export const OrderDocuments: React.FC<OrderDocumentsProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError,
    customer,
    withContainer = true,
    constructionSite
}: OrderDocumentsProps) => {
    const bbngRequest = useRequest();

    const handleChange = (value: TDocument[] | string, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    const { val, setVal, err, setErr } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    const getFileData = async (e: TDocument): Promise<{ url: string; name: string } | undefined> => {
        if (e.type === 'local' && e.local && e.local.document) {
            const doc: File = e.local.document;
            const url = URL.createObjectURL(doc);
            const name: string = doc.name;

            return { url, name };
        } else if (e.type === 'online' && e.online) {
            const doc: DocumentRo = e.online;

            const response = await bbngRequest<string>(
                {
                    method : 'GET',
                    url    : urlApiBuilder.documentGetSignedUrl(doc.id)
                },
                true
            );
            if (!response.response?.data.ro) return undefined;

            const url: string = response.response.data.ro;
            const name: string = doc.name;

            return { url, name };
        }
        return undefined;
    };

    const downloadColumnBody = (mode: 'preview' | 'download') => (e: TDocument) => {
        const icon = mode === 'preview' ? 'pi pi-eye' : 'pi pi-download';
        return (
            <Button
                type="button"
                icon={icon}
                className="p-button-rounded p-button-text"
                onClick={() => {
                    getFileData(e).then((info) => {
                        if (info === undefined) return;

                        const { url, name } = info;

                        const anchor = document.createElement('a');

                        anchor.id = 'dummy-download-anchor';
                        if (mode === 'download') anchor.download = name;
                        anchor.href = url;
                        anchor.target = '_blank';

                        anchor.click();
                        anchor.remove();
                    });
                }}
            />
        );
    };

    const formatSize = (size?: number): string => (size ? ((size ?? 0) / 1_000_000).toFixed(2) + ' Mo' : '');

    const Body = useCallback(() => {
        return (
            <>
                {!readOnly ? (
                    <FullLineInput>
                        <Input.Text
                            label="Numéro bon de commande"
                            result={handleChange}
                            id="order_sheet_number"
                            value={val.order_sheet_number}
                            displayError={displayError}
                            errors={err.order_sheet_number}
                            required={val.documents.length > 0 ? true : false}
                            readOnly={readOnly}
                            key={
                                val.documents.length > 0 ? 'order_sheet_number_required' : 'order_sheet_number_optional'
                            }
                            // The key changement is mandatory because we are changing the required state of the input and the value at the same time (which update two useEffect)
                        />
                    </FullLineInput>
                ) : (
                    <div>
                        Numéro bon de commande:{' '}
                        {val.order_sheet_number !== '' ? val.order_sheet_number : 'Non renseigné'}
                    </div>
                )}
                <FullLineInput>
                    <InputList.Base<TDocument>
                        id="documents"
                        readOnly={readOnly}
                        title="Bon de commande"
                        onChange={handleChange}
                        value={val.documents}
                        setErrors={() => void 0}
                        columns={[
                            {
                                header : 'Type',
                                body   : (e: TDocument) =>
                                    mapDocumentType(e.type === 'local' ? e.local?.type : e.online?.type)
                            },
                            {
                                header : 'Nom',
                                body   : (e: TDocument) => (e.type === 'local' ? e.local?.document?.name : e.online?.name)
                            },
                            {
                                header : 'Taille',
                                body   : (e: TDocument) =>
                                    formatSize(e.type === 'local' ? e.local?.document?.size : e.online?.size)
                            },
                            {
                                body        : downloadColumnBody('preview'),
                                headerStyle : { maxWidth: '3em' },
                                bodyStyle   : { maxWidth: '3em' }
                            },
                            {
                                body        : downloadColumnBody('download'),
                                headerStyle : { maxWidth: '3em' },
                                bodyStyle   : { maxWidth: '3em' }
                            }
                        ]}
                        disableEditButton
                        disableModalButton={(modalInputs) => {
                            return Boolean(modalInputs?.local?.type && modalInputs?.local?.document) === false;
                        }}
                        modalTemplate={DocumentModal}
                        disableAddButton={val.documents.length > 0}
                        disableDeleteButton={readOnly}
                    />
                </FullLineInput>
            </>
        );
    }, [val, readOnly, err, displayError, handleChange]);

    const [runConfetti, setRunConfetti] = React.useState(false);

    useEffect(() => {
        if (!readOnly && customer?.mandatory_order_sheet && val.order_sheet_number?.trim() !== '') {
            setRunConfetti(true);
        } else {
            setRunConfetti(false);
        }
    }, [customer?.mandatory_order_sheet, val.order_sheet_number]);

    return (
        <>
            {withContainer ? (
                <StyledCard
                    title={`Documents administratifs ${customer?.mandatory_order_sheet ? '(obligatoires)' : ''}`}
                    hideBoxShadow={readOnly}
                    redAlert={!!(customer?.mandatory_order_sheet && val.order_sheet_number?.trim() === '')}
                >
                    <Confetti
                        recycle={false}
                        numberOfPieces={readOnly ? 0 : 1000}
                        initialVelocityX={20}
                        initialVelocityY={20}
                        gravity={5}
                        run={runConfetti}
                    />
                    {Body()}
                </StyledCard>
            ) : (
                Body()
            )}
        </>
    );
};

const DocumentModal = (
    state: TDocument | undefined,
    setter: React.Dispatch<React.SetStateAction<TDocument | undefined>>
) => {
    return (
        <ModalContainer>
            {state?.local?.document ? (
                <UploadedItem>
                    <div>{state.local.document.name}</div>
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-text"
                        onClick={() => {
                            setter((prev) => ({
                                ...prev,
                                local : { ...prev?.local, document: undefined },
                                type  : 'local'
                            }));
                        }}
                    />
                </UploadedItem>
            ) : (
                <Dropzone
                    id="test"
                    hidePreview
                    readOnly={false}
                    required={false}
                    result={(value, errors, id) => {
                        setter((prev) => ({
                            ...prev,
                            local : { ...prev?.local, type: EDocumentType.ORDER_SHEET, document: value as File },
                            type  : 'local'
                        }));
                    }}
                    type={EDocumentType.ORDER_SHEET}
                    value={state?.local?.document ?? null}
                />
            )}
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
    & > span,
    .p-dropdown {
        width: 100%;
    }
`;

const FullLineInput = styled.div`
    width: 100%;
    display: flex;
    & > * {
        flex: 1;
        > .p-inputtext {
            width: 100% !important;
        }
    }
`;

const StyledCard = styled(Card)<{ hideBoxShadow: boolean; redAlert: boolean }>`
    flex: 2 !important;
    .subtitle {
        margin-top: 0;
        margin-bottom: 5px;
    }
    box-shadow: ${(props) => props.hideBoxShadow && 'none'};
    border: ${(props) => props.redAlert && '1px solid red'};
    animation: ${(props) => props.redAlert && 'red-alert 1s infinite'};
    @keyframes red-alert {
        0% {
            background-color: rgba(255, 47, 0, 0.2);
        }
        50% {
            background-color: rgba(255, 47, 0, 0.4);
        }
        100% {
            background-color: rgba(255, 47, 0, 0.2);
        }
    }
`;

const UploadedItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;
