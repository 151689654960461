import styled from 'styled-components';

export const GifContainer = styled.div`
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 4000;

    width: 40%;
    height: 40%;

    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    padding: 5px;

    gap: 10px;

    & > .title,
    & > .sub-title {
        font-size: 18px;
        font-weight: 300;
        text-align: center;
    }

    & > .title {
        font-weight: bold;
        margin-top: 10px;
        text-transform: uppercase;
    }

    & > .sub-title {
        white-space: wrap;
        margin-bottom: 10px;
    }

    background-color: white;
    /* max-width: 200px; */
    & > img {
        max-width: 100%;
        border-radius: 5px;
    }
`;

export const Dotted = styled.p`
    font-size: 60px;

    svg {
        margin-bottom: -2px;
    }

    span {
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
    }

    span:nth-child(2) {
        animation-delay: 0.2s;
    }

    span:nth-child(3) {
        animation-delay: 0.4s;
    }

    @keyframes blink {
        /**
         * At the start of the animation the dot
         * has an opacity of .2
         */
        0% {
            opacity: 0.2;
        }
        /**
         * At 20% the dot is fully visible and
         * then fades out slowly
         */
        20% {
            opacity: 1;
        }
        /**
         * Until it reaches an opacity of .2 and
         * the animation can start again
         */
        100% {
            opacity: 0.2;
        }
    }
`;
