import { TruckCharacteristics, TruckCharacteristicsProps } from './Characteristics';
import { TruckGeneral, TruckGeneralProps } from './General';

const Modules = {
    GeneralInfo: (props: TruckGeneralProps) => {
        return <TruckGeneral {...props} />;
    },
    Characteristics: (props: TruckCharacteristicsProps) => {
        return <TruckCharacteristics {...props} />;
    }
};

export default Modules;
