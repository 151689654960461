import moment from 'moment';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MenuItemCommandParams } from 'primereact/menuitem';
import { Tag, TagProps, TagSeverityType } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import { IconType } from 'primereact/utils';
import React, { useEffect, useState } from 'react';

import {
    AdminRo,
    CCServiceRo,
    CC_FAMILY,
    CC_STATUS,
    CollectorRo,
    ECustomerCategory,
    EPlanningRegion,
    FrontRo,
    UserRo
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { mapCollectConfigStatus, mapPlanningConfigRegion } from '../../common/enumMapper';
import { COLLECT_CONFIG_ORCHESTRATION_BASE_URL, urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { collectConfigDescription } from '../../components/StepOverview';
import { Listing } from '../../components/Layout';
import { PageLoader } from '../../components/PageLoader';
import useQuery from '../../hooks/Query';
import { useRequest } from '../../hooks/StatelessRequest';
import CollectConfigCancelForm from '../../modules/collect-config-cancel-form';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { FiltersContainer } from './style';

import { ListingMobile } from './listingMobile';

const regionOptions = Object.values(EPlanningRegion).map((region) => ({
    name  : mapPlanningConfigRegion(region),
    value : region
}));

const statusOptions = [CC_STATUS.TO_PREPARE, CC_STATUS.CANCELED, CC_STATUS.FINISHED].map((status) => ({
    name  : mapCollectConfigStatus(status),
    value : status
}));

type QueryParams = { status?: CC_STATUS; region?: EPlanningRegion; customer_id?: string };

export const DeliveryListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [region, setRegion] = React.useState<EPlanningRegion | undefined>(query.region);
    const [status, setStatus] = React.useState<CC_STATUS | undefined>(query.status);
    const [customer_id, setCustomerId] = React.useState<string | undefined>(query.customer_id);
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = React.useState(false);
    const [collectConfigToCancel, setCollectConfigToCancel] = React.useState<CCServiceRo>();
    const [loading, setLoading] = React.useState<boolean>(false);

    const request = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [triggerPageRefresh, setTriggerPageRefresh] = useState<boolean>(false);
    useEffect(() => {
        setTriggerPageRefresh(false);
    }, [triggerPageRefresh]);

    const handleActionRowCancel = (e: MenuItemCommandParams) => {
        const index = e.item['rowInfo']['rowIndex'];
        const collectConfig = e.item['rowInfo']['props']['value'][index];
        setCollectConfigToCancel(collectConfig);
        setCancelDialogIsOpen(true);
    };

    const finishCollectConfig = React.useCallback(async (id: string) => {
        setLoading(true);
        await request<FrontRo<CCServiceRo>>({
            method  : 'PATCH',
            url     : urlApiBuilder.collectConfigFinish(id),
            options : { toastifySuccess: false }
        });
        setLoading(false);
        setTriggerPageRefresh(true);
    }, []);

    const handleActionRowFinish = (e: MenuItemCommandParams) => {
        confirmDialog({
            header      : 'Confirmer la validation.',
            message     : 'Etes-vous sur de vouloir valider cette livraison ?',
            icon        : 'pi pi-info-circle',
            acceptLabel : 'Confirmer',
            rejectLabel : 'Annuler',
            accept      : () => {
                const index = e.item['rowInfo']['rowIndex'];
                finishCollectConfig(e.item['rowInfo']['props']['value'][index]?.id);
            }
        });
    };

    if (loading) return <PageLoader loading actionType="create" />;

    if (window.innerWidth < 1280) {
        return <ListingMobile />;
    }
    return (
        <>
            <Listing<any, CCServiceRo>
                url={COLLECT_CONFIG_ORCHESTRATION_BASE_URL}
                endpoint="collect-configs"
                breakpoint="1200px"
                hideCreateButton
                hideBulkMenu
                showSearch={false}
                triggerPageRefresh={triggerPageRefresh}
                pageUrl={urlBuilder.collectConfigBase()}
                displaySelectColumn={false}
                disableArchiveAction
                actionRows={[
                    {
                        label   : 'Terminer',
                        icon    : 'pi pi-check',
                        command : (e) => handleActionRowFinish(e)
                    },
                    {
                        label   : 'Annuler',
                        icon    : 'pi pi-exclamation-triangle',
                        command : (e) => handleActionRowCancel(e)
                    }
                ]}
                queryParams={{
                    region,
                    customer_id,
                    status,
                    family            : CC_FAMILY.DELIVERY_BIG_BAG,
                    no_administrative : true
                }}
                enrichData={async (data) => {
                    const ro: (CCServiceRo & {
                        tmp: { admin_id: string[]; user_id: string[]; collector_id: string[] };
                    })[] = (data.data.ro ?? []).map((cc) => ({
                        ...cc,
                        // Place created_by id in tmp key to be fetch on fetchDataRelation
                        tmp: {
                            admin_id     : [cc.created_by],
                            user_id      : [cc.created_by],
                            collector_id : [cc.created_by]
                        }
                    }));

                    const roFetch: (CCServiceRo & {
                        tmp: { admin_id: AdminRo[]; user_id: UserRo[]; collector_id: CollectorRo[] };
                    })[] = await fetchDataRelation(ro, {
                        customer_id  : true,
                        admin_id     : true,
                        user_id      : true,
                        collector_id : true
                    });

                    const newRo: CCServiceRo[] = roFetch.map((cc): CCServiceRo => {
                        /**
                         * Take created_by email from tmp key once fetched and remove the tmp key
                         */
                        if (cc.tmp.admin_id.length > 0 && cc.tmp.admin_id[0]?.id === cc.created_by)
                            cc.created_by = cc.tmp.admin_id[0]?.email;
                        else if (cc.tmp.user_id.length > 0 && cc.tmp.user_id[0]?.id === cc.created_by)
                            cc.created_by = cc.tmp.user_id[0]?.email;
                        else if (cc.tmp.collector_id.length > 0 && cc.tmp.collector_id[0]?.id === cc.created_by)
                            cc.created_by = cc.tmp.collector_id[0]?.email;
                        const item: CCServiceRo & {
                            tmp?: { admin_id?: AdminRo[]; user_id?: UserRo[]; collector_id?: CollectorRo[] };
                        } = cc;
                        delete item.tmp;

                        return item;
                    });

                    data.data.ro = newRo;

                    return data;
                }}
                leftHandedComponent={
                    <FiltersContainer>
                        <Dropdown
                            value={status}
                            onChange={(e) => setStatus(e.value)}
                            placeholder="Filtrer par statut"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={statusOptions}
                        />
                        <Dropdown
                            value={region}
                            onChange={(e) => setRegion(e.value)}
                            placeholder="Filtrer par région"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={regionOptions}
                        />
                        <RelationAutocomplete.Customer
                            placeholder="Filtrer par client"
                            onSelect={(customer) => setCustomerId(customer?.id)}
                            onUnselect={() => setCustomerId(undefined)}
                        />
                    </FiltersContainer>
                }
                name="Livraison"
                pluralName="Livraisons"
                headers={[
                    {
                        name      : 'Numéro collecte',
                        field     : 'number',
                        component : (data: FrontRo<CCServiceRo>) => (
                            <a href={urlBuilder.collectConfigView(data.id)}>{data.number}</a>
                        )
                    },
                    {
                        name      : 'Client',
                        field     : 'customer_id',
                        component : (data: FrontRo<CCServiceRo>) => {
                            if (data.customer_id?.length > 0) {
                                const isIndividual = data.customer_id[0].category === ECustomerCategory.INDIVIDUAL;
                                const individualIcon = <i className="pi pi-user" />;
                                return (
                                    <>
                                        <Tooltip
                                            position="top"
                                            target={`.customer-${data.id.replace(':', '')}`}
                                            content={isIndividual ? 'Particulier' : undefined}
                                        />
                                        <span className={`customer-${data.id.replace(':', '')}`}>
                                            {data.customer_id[0].name} {isIndividual && individualIcon}
                                        </span>
                                    </>
                                );
                            }
                            return <span>N/A</span>;
                        }
                    },
                    {
                        name      : 'Contact',
                        field     : 'construction_site_contact',
                        component : (data: FrontRo<CCServiceRo>) => {
                            if (data.construction_site_contact?.length > 0) {
                                return (
                                    <span>
                                        <a href={`tel:${data.construction_site_contact[0].phone_number}`}>
                                            {data.construction_site_contact[0].phone_number}
                                        </a>
                                    </span>
                                );
                            }
                            return <span>N/A</span>;
                        }
                    },
                    {
                        name      : 'Région',
                        field     : 'region',
                        component : (data: FrontRo<CCServiceRo>) => <span>{mapPlanningConfigRegion(data.region)}</span>
                    },
                    {
                        name      : 'Date',
                        field     : 'from_date',
                        component : (data: FrontRo<CCServiceRo>) => (
                            <span>{moment(data.from_date).format('DD/MM/YYYY')}</span>
                        )
                    },
                    {
                        name      : 'Créneau',
                        field     : 'from_date',
                        component : (data: FrontRo<CCServiceRo>) => (
                            <span>
                                {moment(data.from_date).format('HH:mm')} - {moment(data.to_date).format('HH:mm')}
                            </span>
                        )
                    },
                    {
                        name      : 'Adresse',
                        field     : 'address',
                        component : (data: FrontRo<CCServiceRo>) => <span>{data.address_shipping?.formatted_name}</span>
                    },
                    {
                        name      : 'Quantité',
                        field     : 'products',
                        component : (data: FrontRo<CCServiceRo>) => (
                            <div>
                                {data.products?.map((product, index) => {
                                    return (
                                        <div key={product.id + index}>
                                            {product.quantity} x {product.name}
                                        </div>
                                    );
                                })}
                            </div>
                        )
                    },
                    {
                        name      : 'Créé par',
                        field     : 'created_by',
                        component : (data: FrontRo<CCServiceRo>) => <span>{data.created_by}</span>
                    },
                    {
                        name      : 'Statut',
                        field     : 'status',
                        width     : 100,
                        component : (data: FrontRo<CCServiceRo>) => {
                            return (
                                <div>
                                    <Tag
                                        className="mr-2"
                                        value={mapCollectConfigStatus(data.status)}
                                        severity={mapStatusToSeverity(data.status)}
                                        icon={mapStatusToIcon(data.status)}
                                    />
                                </div>
                            );
                        }
                    }
                ]}
            />
            {collectConfigToCancel && (
                <Dialog
                    visible={cancelDialogIsOpen}
                    onHide={() => setCancelDialogIsOpen(false)}
                    header={`Annulation - ${collectConfigToCancel && collectConfigDescription(collectConfigToCancel)}`}
                    draggable={false}
                    resizable={false}
                    blockScroll
                    contentStyle={{ overflow: 'scroll' }}
                >
                    <CollectConfigCancelForm
                        submitCallback={() => setTriggerPageRefresh(true)}
                        setModalIsOpen={setCancelDialogIsOpen}
                        dataId={collectConfigToCancel.id}
                        isAdministrative={collectConfigToCancel?.family === CC_FAMILY.ADMINISTRATIVE}
                    />
                </Dialog>
            )}
        </>
    );
};

const mapStatusToIcon = (status: CC_STATUS): IconType<TagProps> => {
    switch (status) {
        case CC_STATUS.WAITING_FOR_APPROVAL:
            return 'pi pi-cog';
        case CC_STATUS.FINISHED:
            return 'pi pi-check';
        case CC_STATUS.CANCELED:
            return 'pi pi-times';
        case CC_STATUS.PLANNED:
            return 'pi pi-check';
        case CC_STATUS.TO_PLAN:
            return 'pi pi-cog';
        case CC_STATUS.HAZARD:
            return 'pi pi-exclamation-circle';
        case CC_STATUS.TO_PREPARE:
            return 'pi pi-box';
        default:
            return '';
    }
};

const mapStatusToSeverity = (status: CC_STATUS): TagSeverityType => {
    switch (status) {
        case CC_STATUS.WAITING_FOR_APPROVAL:
            return 'warn';
        case CC_STATUS.FINISHED:
            return 'success';
        case CC_STATUS.CANCELED:
        case CC_STATUS.HAZARD:
            return 'danger';
        case CC_STATUS.PLANNED:
        case CC_STATUS.TO_PLAN:
        case CC_STATUS.TO_PREPARE:
            return 'info';
        default:
            return 'info';
    }
};
