import React from 'react';
import styled from 'styled-components';

import { CardErrors, EPlanningRegion, PhoneValue, ESlot, IOpeningTime } from '@bbng/util/types';

import { mapPlanningConfigRegion, mapSlot } from '../../common/enumMapper';
import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import Input from '../../components/Inputs';
import Calendar from '../../components/Calendar';
import moment from 'moment';

export type ZoneGeneralProps = {
    readOnly?: boolean;
    value?: ZoneGeneralState;
    id: string;
    result: (value: ZoneGeneralState, errors: null | string[] | CardErrors<ZoneGeneralState>, id: string) => void;
    displayError?: boolean;
};

export enum EGeneralSlot {
    NONE = 'NONE',
    DAY = 'DAY',
    MORNING = 'MORNING',
    AFTERNOON = 'AFTERNOON'
}

export type ZoneGeneralState = {
    name: string;
    display_name: string | undefined;
    postal_code: string[];
    min_weight: number;
    required_slot: EGeneralSlot;
    metropole: EPlanningRegion;
    archived: boolean;
    allowed_days: IOpeningTime<boolean>;
    forbidden_days: string[];
};

export type ZoneGeneralErrorState = CardErrors<ZoneGeneralState>;

export const initialState: ZoneGeneralState = {
    name          : '',
    display_name  : undefined,
    postal_code   : [],
    min_weight    : 0,
    required_slot : EGeneralSlot.NONE,
    metropole     : EPlanningRegion.PARIS,
    archived      : false,
    allowed_days  : {
        0 : true,
        1 : true,
        2 : true,
        3 : true,
        4 : true,
        5 : false, // saturday disabled by default
        6 : false // sunday disabled by default
    },
    forbidden_days: []
};

export const initialErrorState: ZoneGeneralErrorState = generateInitialErrorState(initialState);

export const ZoneGeneral = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: ZoneGeneralProps) => {
    const [val, setVal] = React.useState<ZoneGeneralState>(value);
    const [err, setErr] = React.useState<ZoneGeneralErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (
        value: IOpeningTime<boolean> | boolean | string | string[] | null | number | undefined | PhoneValue,
        errors: string[] | null,
        childId: string
    ) => {
        if (value && Array.isArray(value) && childId === 'postal_code') {
            const size = value.length;
            if (size > 0) {
                const split = value[size - 1].split(' ');
                if (split.length > 1) {
                    setVal((prev) => ({ ...prev, [childId]: split }));
                    setErr((prev) => ({ ...prev, [childId]: errors }));
                    return;
                }
            }
        }
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <StyledCard title="Informations générales">
            <CardLine>
                <CenteredDiv>
                    <div style={{ marginRight: '1em' }}>
                        <Input.Text
                            required={true}
                            label="Nom Interne"
                            id="name"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.name}
                            errors={err.name}
                            displayError={displayError}
                        />
                    </div>
                    <div style={{ marginLeft: '1em' }}>
                        <Input.Text
                            required={false}
                            label="Nom Externe"
                            id="display_name"
                            readOnly={readOnly}
                            result={handleChange}
                            value={val.display_name ?? ''}
                            errors={err.display_name}
                            displayError={displayError}
                        />
                    </div>
                </CenteredDiv>
                <CenterSpan>
                    <i className="pi pi-info-circle" />
                    Le nom externe sera affiché sur les differentes plateformes clients (E-Commerce, Application Client)
                    ainsi que les factures
                </CenterSpan>
                <InputWithSpan>
                    <Input.Chips
                        required={true}
                        label="Codes postaux"
                        id="postal_code"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.postal_code}
                        errors={err.postal_code}
                        displayError={displayError}
                    />
                    <CenterSpan>
                        <i className="pi pi-info-circle" />
                        Appuyez sur entrée après avoir entré un code postal.
                    </CenterSpan>
                </InputWithSpan>
                <Input.Number
                    required={false}
                    label="Volume minimum de bigbags (hors livraison)"
                    id="min_weight"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.min_weight}
                    errors={err.min_weight}
                    displayError={displayError}
                    decimal={true}
                    suffix="m³"
                    size={45}
                />
                <InputWithSpan>
                    <div>
                        <Button.Select
                            required={false}
                            id="required_slot"
                            readOnly={readOnly}
                            result={handleChange}
                            options={Object.values(EGeneralSlot) as string[]}
                            labelMapper={(value) => mapSlot(value as ESlot)}
                            value={val.required_slot as string}
                            errors={err.required_slot}
                            displayError={displayError}
                        />
                    </div>
                    <CenterSpan>
                        <i className="pi pi-info-circle" />
                        Contrainte sur le créneau horaire (sélectionnez aucun si pas de contrainte).
                    </CenterSpan>
                </InputWithSpan>
                <InputWithSpan>
                    <div>
                        <Button.Select
                            required
                            id="metropole"
                            readOnly={readOnly}
                            result={handleChange}
                            options={Object.values(EPlanningRegion)}
                            labelMapper={(value) => mapPlanningConfigRegion(value as EPlanningRegion)}
                            value={val.metropole}
                            errors={err.metropole}
                            displayError={displayError}
                        />
                    </div>
                    <CenterSpan>
                        <i className="pi pi-info-circle" />
                        Région associée à la zone.
                    </CenterSpan>
                </InputWithSpan>
                <Container>
                    <h3>Jours de la semaine autorisés</h3>
                    <Calendar.AllowedDays
                        id="allowed_days"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.allowed_days}
                        required={true}
                    />
                </Container>
                <Container>
                    <h3>Jours interdits (fériés, vacances...)</h3>
                    <Calendar.MultipleDayMonthYear
                        id="forbidden_days"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.forbidden_days}
                        required={false}
                        displayError={displayError}
                    />
                </Container>
            </CardLine>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 36px;
    & > * {
        flex: 1;
        > .p-component {
            width: 100% !important;
        }
    }
`;

const InputWithSpan = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
`;

export const CenterSpan = styled.span`
    display: flex;
    gap: 4px;
    align-items: center;
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
`;

const CenteredDiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-directions: row;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
`;
