import { AxiosRequestHeaders } from 'axios';
import { AdminRo } from '../admin';
import { ICustomerContact } from '../customer';

import { IKafkaQuery } from '../kafka';
import { BillingBranch, ISODate } from '../misc';
import { ZohoInvoiceRo, ZohoLineItem } from './invoice';

export interface AccessTokenRo {
    access_token: string;
    api_domain: string;
    token_type: string;
    expires_in: number;
}

export interface IAuthorizationHeader extends AxiosRequestHeaders {
    Authorization: string;
}

export enum ZohoRoutes {
    CONTACTS = 'contacts',
    CONTACT = 'contact',
    INVOICES = 'invoices',
    INVOICE = 'invoice',
    CUSTOMER_PAYMENT = 'customerpayments',
    PAYMENT = 'payment',
    PAYMENTS = 'payments'
}

export type ZohoResponseWrapper<T> = {
    code: number;
    message: string;
    page_context?: IPageContext;
} & {
    [key in ZohoRoutes]: T;
};

export interface IPageContext {
    page: number;
    per_page: number;
    has_more_page: boolean;
    applied_filter: string;
    sort_column: string;
    sort_order: string;
}

export interface ZohoTag {
    is_tag_mandatory?: boolean;
    tag_id?: number;
    tag_name?: string;
    tag_option_id?: number;
    tag_option_name?: string;
}

export interface ZohoCustomField {
    index: number;
    value: string;
    label?: string;
}

export interface ZohoAddressRo {
    attention?: string;
    address: string;
    street2?: string;
    state_code?: string;
    city: string;
    state?: string;
    zip: string;
    country: string;
    fax?: string;
    phone?: string;
}

export enum EZohoOrganization {
    ZOHO_1 = 'ZOHO_1',
    ZOHO_2 = 'ZOHO_2'
}

export const ZOHO_ORGANIZATION_HEADER_KEY = 'ZOHO_ORGANIZATION';

export interface ZohoQuery extends IKafkaQuery {
    billingBranch?: BillingBranch;
}

export type ZohoVatConfig = {
    zohoVat5_5: string;
    zohoVat10: string;
    zohoVat20: string;
};

export type ZohoGenerateActionDto = {
    zohoLineItems: ZohoLineItem[];
    customer_zoho_id: string;
    invoice_date: ISODate;
    admin: AdminRo;
    billingBranch: BillingBranch;
    surchargeMessage?: Record<string, any>;
    contacts: ICustomerContact[];
};

export type ZohoUpdateInvoiceActionDto = Pick<
    ZohoGenerateActionDto,
    'zohoLineItems' | 'billingBranch' | 'contacts' | 'surchargeMessage'
> & {
    id_zoho_invoice: string;
    previousRo: ZohoInvoiceRo;
};
