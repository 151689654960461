import { Dropdown, DropdownItemTemplateType, DropdownValueTemplateType } from 'primereact/dropdown';
import { useState } from 'react';
import * as yup from 'yup';

import { genericTextInputProps } from '.';
import { useComponentInput } from '../../hooks/ComponentInput';

export interface DropdownInputProps extends genericTextInputProps {
    options: string[];
    valueTemplate?: DropdownValueTemplateType;
    itemTemplate?: DropdownItemTemplateType;
    emptyMessage?: string;
    placeholder?: string;
    onShow?: () => void;
}

export function DropdownInput({
    readOnly,
    required,
    result,
    id,
    validationSchema,
    value,
    label,
    options,
    valueTemplate,
    itemTemplate,
    errors,
    displayError,
    emptyMessage,
    placeholder,
    onShow
}: DropdownInputProps) {
    const [opt] = useState(options);

    const baseValidationSchema = yup.string().oneOf(Object.values(opt));
    const schema = validationSchema
        ? validationSchema
        : required
        ? baseValidationSchema.required(`${label} requis`)
        : baseValidationSchema;

    const { handleBlur, handleOnChange, val, err, displayErr } = useComponentInput({
        id,
        value,
        errors,
        displayError        : displayError ?? false,
        result,
        required,
        schema,
        didRevalidateSchema : (e) => e.length > 0,
        mapValue            : (e) => e.target.value
    });

    return (
        <span className="p-float-label">
            <Dropdown
                disabled={readOnly}
                name={id}
                className={`p-inputtext-sm ${displayErr && err && err.length > 0 ? 'p-invalid' : ''}`}
                id={id}
                onChange={handleOnChange}
                onBlur={handleBlur}
                value={val}
                options={options}
                valueTemplate={valueTemplate}
                itemTemplate={itemTemplate}
                tooltip={displayErr && err && err.length > 0 ? err.join('\n') : ''}
                emptyMessage={emptyMessage}
                placeholder={placeholder}
                onShow={onShow}
            />
            <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>
        </span>
    );
}
