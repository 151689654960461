import { AllowedDays, AllowedDaysProps } from './AllowedDays';
import { CalendarDayMonthYear, CalendarDayMonthYearProps } from './Day&Month&Year';
import { CalendarHoursMin, CalendarHoursMinProps } from './Hours&Min';
import { CalendarMultipleDayMonthYear, CalendarMultipleDayMonthYearProps } from './MultipleDay&Month&Year';
import { OpenClose, OpenCloseProps } from './Open&close';
import { CalendarRange, CalendarRangeProps } from './Range';

const Calendar = {
    DayMonthYear         : (props: CalendarDayMonthYearProps) => <CalendarDayMonthYear {...props} />,
    HoursMins            : (props: CalendarHoursMinProps) => <CalendarHoursMin {...props} />,
    Range                : (props: CalendarRangeProps) => <CalendarRange {...props} />,
    OpenClose            : (props: OpenCloseProps) => <OpenClose {...props} />,
    AllowedDays          : (props: AllowedDaysProps) => <AllowedDays {...props} />,
    MultipleDayMonthYear : (props: CalendarMultipleDayMonthYearProps) => <CalendarMultipleDayMonthYear {...props} />
};

export default Calendar;
