import { CollectorRo } from '@bbng/util/types';
import { useCallback, useState } from 'react';
import { urlApiBuilder } from '../../common/urlBuilder';
import { useRequest } from '../StatelessRequest';

export const useFetchCollector = () => {
    const bbngRequest = useRequest({
        toastifyError   : false,
        toastifySuccess : false
    });

    const [isLoading, setIsLoading] = useState(false);

    const fetchCollector = useCallback(async (collectorId: string) => {
        setIsLoading(true);
        const res = await bbngRequest<CollectorRo>({
            sync   : true,
            method : 'GET',
            url    : urlApiBuilder.collectorGet(collectorId)
        });
        if (res?.success && res?.response?.data?.ro) {
            return res.response.data.ro;
        } else {
            return undefined;
        }
        setIsLoading(false);
    }, []);

    return {
        fetchCollector,
        isLoading
    };
};
