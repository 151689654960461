import { IBbngResponse } from '@bbng/util/types';

import { axiosClient } from './axios';
import { urlApiBuilder } from './urlBuilder';

export type DataRelationKeys<Data, Keys = Pick<Data, Extract<keyof Data, `${string}_id`>>> = {
    [Key in keyof Keys]?: true;
};

export const urlRegistry = {
    customer_id                 : urlApiBuilder.customerGetMany,
    emptied_at_landfill_id      : urlApiBuilder.landfillGetMany,
    landfill_id                 : urlApiBuilder.landfillGetMany,
    construction_site_id        : urlApiBuilder.constructionSiteGetMany,
    admin_id                    : urlApiBuilder.adminGetMany,
    user_id                     : urlApiBuilder.userGetMany,
    truck_id                    : urlApiBuilder.truckGetMany,
    collector_id                : urlApiBuilder.collectorGetMany,
    order_id                    : urlApiBuilder.orderGetMany,
    discount_id                 : urlApiBuilder.discountGetMany,
    collect_config_id           : urlApiBuilder.collectConfigGetMany,
    ccAdministrativeId          : urlApiBuilder.ccAdministrativeGetMany,
    collect_id                  : urlApiBuilder.collectGetMany,
    invoice_id                  : urlApiBuilder.invoiceGetMany,
    document_id                 : urlApiBuilder.documentGetMany,
    zone_id                     : urlApiBuilder.zoneGetMany,
    photo_id                    : urlApiBuilder.documentGetMany,
    product_id                  : urlApiBuilder.productGetMany,
    presta_id                   : urlApiBuilder.prestaGetMany,
    dumpster_on_site_id         : urlApiBuilder.dumpsterOnSiteGetMany,
    deposit_collect_id          : urlApiBuilder.collectGetMany,
    retrieval_collect_id        : urlApiBuilder.collectGetMany,
    retrieval_collect_config_id : urlApiBuilder.collectConfigGetMany,
    deposit_collect_config_id   : urlApiBuilder.collectConfigGetMany
};

export const fetchDataRelation = async <Data>(
    data: any,
    relationKeys: DataRelationKeys<typeof urlRegistry>
): Promise<Data> => {
    const registryIds = retrieveAllData(data as any);
    const wantedRelationKeys = Object.entries(relationKeys)
        .filter(([key, value]) => value)
        .map(([key]) => key);

    const registryRo: Array<{ id?: string }> = (
        await Promise.all(
            Object.entries(registryIds)
                .filter(([key]) => wantedRelationKeys.includes(key))
                .map(async ([key, ids]) => {
                    if (registryIds[key].length > 0) {
                        try {
                            //If we have a collect_config_id, we need to make a specific url
                            //Because we have to go to CC && CC Admin.
                            if (key === 'collect_config_id') {
                                return await handleCCs(key, registryIds);
                            }
                            const makeUrl = urlRegistry[key as keyof typeof urlRegistry] as () => string;
                            const keys = registryIds[key];
                            const uniqueKeys = [...new Set(keys)];

                            const result = await axiosClient.post<IBbngResponse<Record<string, any>[]>>(
                                makeUrl(),
                                {
                                    ids: uniqueKeys
                                },
                                {
                                    params: {
                                        sync: true
                                    }
                                }
                            );
                            return result.data.data.ro ?? [];
                        } catch (err) {
                            console.error(err);
                        }
                    }
                    return [];
                })
        )
    )
        .flat()
        .filter((f): f is Record<string, any> => f !== undefined);

    const registryBucket = registryRo.reduce((acc, ro) => {
        if (ro.id) {
            acc[ro.id] = ro;
        }

        return acc;
    }, {} as Record<string, { id?: string }>);

    const newData = alterateData(data as any, registryBucket, wantedRelationKeys) as Data;

    return newData;
};

const isObject = (value: unknown): boolean => {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

const loadIdInBucket = (bucket: Record<string, Array<string>>, key: string, ...ids: string[]) => {
    // If key and array already exist
    if (bucket[key] && Array.isArray(bucket[key])) {
        // Add Ids at the end of array
        bucket[key] = bucket[key].concat(ids);
        return bucket;
    } else {
        // Set key on object as array
        bucket[key] = ids;
    }

    return bucket;
};

export const retrieveAllData = <T>(
    obj: Partial<Record<keyof T, unknown>>,
    bucket: Record<string, Array<string>> = {}
) => {
    Object.entries(obj).forEach(([key, value]) => {
        // If key is an array
        if (Array.isArray(value)) {
            // If array containing ids
            if (key.endsWith('_id')) {
                // Add all ids in a bucket of ids
                bucket = loadIdInBucket(bucket, key, ...value.filter((f) => typeof f === 'string'));
            } else {
                value.forEach((elem) => {
                    if (isObject(elem)) {
                        bucket = retrieveAllData(elem as Record<string, unknown>, bucket);
                    }
                });
            }
        }
        if (key.endsWith('_id') && typeof value === 'string') {
            bucket = loadIdInBucket(bucket, key, value);
        }
        // If key is an object
        if (isObject(value)) {
            // apply recursive on object and retrieve bucket state post computing
            bucket = retrieveAllData(value as Record<string, unknown>, bucket);
        }
    });

    return Object.fromEntries(
        Object.entries(bucket).map(([key, value]) => [key, Array.from(new Set(value))])
    ) as Record<string, string[]>;
};

const alterateData = <T>(
    obj: Partial<Record<keyof T, any>>,
    bucket: Record<string, { id?: string }>,
    wantedRelationKeys: string[]
): Record<string, any> => {
    (Object.keys(obj) as Array<keyof T>).forEach((key) => {
        // If key is an array
        if (Array.isArray(obj[key])) {
            // If array containing ids
            if (typeof key === 'string' && wantedRelationKeys.includes(key)) {
                obj[key] = (obj[key] as string[])
                    .map((elem) => {
                        if (typeof elem !== 'string') return elem;
                        const item: Record<string, any> | undefined = bucket[elem];

                        // Remove all id field on 1st depth
                        // if (item) {
                        //     Object.keys(item).forEach((itemKey) => {
                        //         if (itemKey.endsWith('_id')) delete item[itemKey];
                        //     });
                        // }
                        // Replace ib by fetched item
                        return item;
                    })
                    .filter(Boolean);
            } else {
                obj[key] = (obj[key] as Array<unknown>).map((elem) => {
                    return isObject(elem)
                        ? alterateData(elem as Record<string, unknown>, bucket, wantedRelationKeys)
                        : elem;
                });
            }
        }
        // If key is an id replace it by fetched item
        if (typeof key === 'string' && wantedRelationKeys.includes(key) && typeof obj[key] === 'string') {
            const item: Record<string, any> | undefined = bucket[obj[key]];

            obj[key] = item;
        }
        // If key is an object
        if (isObject(obj[key])) {
            // apply recursive on object and retrieve bucket state post computing
            obj[key] = alterateData(obj[key] as Record<string, unknown>, bucket, wantedRelationKeys);
        }
    });

    return obj;
};

const handleCCs = async (key: string, registryIds: Record<string, string[]>) => {
    const makeUrl1 = urlRegistry[key as keyof typeof urlRegistry] as () => string;
    const makeUrl2 = urlRegistry.ccAdministrativeId as () => string;

    const keys = registryIds[key];
    const uniqueKeys = Array.from(new Set(keys));

    const [result1, result2] = await Promise.all([
        axiosClient.post<IBbngResponse<Record<string, any>[]>>(
            makeUrl1(),
            {
                ids: uniqueKeys
            },
            {
                params: {
                    sync: true
                }
            }
        ),
        axiosClient.post<IBbngResponse<Record<string, any>[]>>(
            makeUrl2(),
            {
                ids: uniqueKeys
            },
            {
                params: {
                    sync: true
                }
            }
        )
    ]);
    return ([] as Record<string, any>[]).concat(result1.data.data.ro ?? [], result2.data.data.ro ?? []);
};
