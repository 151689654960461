import equal from 'fast-deep-equal';
import { StatusCodes } from 'http-status-codes';
import produce, { setAutoFreeze } from 'immer';
import moment from 'moment';
import { AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import React, { useEffect } from 'react';

import { FORMAT_DISPLAY_DATE } from '@bbng/util/misc';
import { EPlanningRegion, EPlanningType, PlanningRo } from '@bbng/util/types';

import { mapPlanningConfigRegion, mapPlanningConfigType } from '../../common/enumMapper';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { Dialog } from '../../components/Dialog';
import { PageLoader } from '../../components/PageLoader';
import { PlanningCosts } from '../../components/PlanningModals/costs';
import { PlanningDuplicate } from '../../components/PlanningModals/duplicates';
import {
    POSTPONE_PLANNING_LOCAL_STORAGE_KEY,
    PlanningPostpone,
    buildPlanningPostponeKey
} from '../../components/PlanningModals/postpone';
import { PlanningStats } from '../../components/PlanningModals/stats';
import useLocalStorage from '../../hooks/LocalStorage';
import useQuery from '../../hooks/Query';
import { useRequest } from '../../hooks/StatelessRequest';
import CCUnassigned from '../../modules/collect-configs-unassigned';
import { PlanningForm } from '../../modules/planning-form';
import { PlanningSelector } from '../../modules/planning-selector';
import Error404 from '../Error404Data';
import Error500 from '../Error500';
import { initialState } from './helpers';
import {
    Header,
    Page,
    PlanningContainer,
    PlanningPlaceholder,
    StyledAccordion,
    StyledH2,
    TitleContainer
} from './style';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { Menu } from 'primereact/menu';
import { AddStepMenu } from './add-step/menu';
import { AddStepButton } from './add-step/button';

setAutoFreeze(false);

const PlanningPageV2: React.FC = (): JSX.Element => {
    const { query, setQuery } = useQuery();
    const [valManuallySet, setValManuallySet] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [creation, setCreation] = React.useState<boolean>(false);
    const [metricsDialogIsOpen, setMetricsDialogIsOpen] = React.useState<boolean>(false);
    const [costDialogIsOpen, setCostDialogIsOpen] = React.useState<boolean>(false);
    const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = React.useState<boolean>(false);
    const [postponeDialogIsOpen, setPostponeDialogIsOpen] = React.useState<boolean>(false);

    const menuRef = React.useRef<Menu>(null);

    const {
        fetchAll,
        planningPageState,
        setPlanningPageState,
        plannings,
        unnassignedCCService,
        unnassignedCCAdministrative,
        dumpsterTypes,
        setDumpsterTypes
    } = usePlanningV2Store();

    const [errorCode, setErrorCode] = React.useState<StatusCodes>();

    useEffect(() => {
        const planningPageInitialState = produce(initialState, (draft) => {
            if (query) {
                if (query['day'] && moment(query['day']).isValid()) draft.day = query['day'];
                if (query['region'] && Object.values(EPlanningRegion).includes(query['region']))
                    draft.region = query['region'];
                if (query['type'] && Object.values(EPlanningType).includes(query['type'])) draft.type = query['type'];
                if (query['redis_version_description'])
                    draft.redis_version_description = query['redis_version_description'];
            }
        });
        setValManuallySet(true);
        setPlanningPageState(planningPageInitialState);
        if (equal(planningPageInitialState, query) === false) setQuery(planningPageInitialState);
    }, []);

    const [autoModalDisabledInLocalStorage, setAutoModalDisabledInLocalStorage] = useLocalStorage<
        Record<string, boolean>
    >(POSTPONE_PLANNING_LOCAL_STORAGE_KEY, {});

    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const fetchData = React.useCallback(async () => {
        setLoading(true);
        await fetchAll(bbngRequest);
        setLoading(false);
    }, [planningPageState, setPlanningPageState]);

    useEffect(() => {
        if (!autoModalDisabledInLocalStorage[buildPlanningPostponeKey(planningPageState)]) {
            setPostponeDialogIsOpen(true);
        } else {
            setPostponeDialogIsOpen(false);
        }
    }, [planningPageState.day, planningPageState.region, planningPageState.type]);

    useEffect(() => {
        if (valManuallySet) {
            fetchData();
            setValManuallySet(false);
        }

        //Handle query params
        if (equal(planningPageState, query) === false) setQuery(planningPageState);
    }, [planningPageState, valManuallySet]);

    const handlePost = React.useCallback(async () => {
        setLoading(true);
        setCreation(true);
        const planningResponse = await bbngRequest<PlanningRo[]>({
            method  : 'POST',
            url     : urlApiBuilder.planningPost(),
            payload : {
                body: {
                    region : planningPageState.region,
                    type   : planningPageState.type,
                    day    : planningPageState.day
                }
            },
            options      : { toastifySuccess: false },
            retryPolling : 1000
        });
        if (planningResponse.success && planningResponse.response?.data.ro) {
            await fetchAll(bbngRequest);
        } else {
            setErrorCode(planningResponse?.response?.data.status_code ?? planningResponse.error?.status);
        }
        setLoading(false);
        setCreation(false);
    }, [planningPageState]);

    const confirm = React.useCallback(() => {
        const typeText = mapPlanningConfigType(planningPageState.type);
        const regionText = mapPlanningConfigRegion(planningPageState.region);
        const dayText = moment(planningPageState.day).format('DD/MM/YYYY');
        confirmDialog({
            header      : `Création de planning - ${typeText} - ${regionText} - ${dayText}`,
            message     : `Êtes-vous sûr de vouloir créer un planning de ${typeText} à ${regionText} pour le ${dayText} ?`,
            accept      : handlePost,
            reject      : () => void 0,
            rejectLabel : 'Annuler',
            acceptLabel : 'Créer'
        });
    }, [planningPageState]);

    if (loading) return <PageLoader loading actionType={creation ? 'create_planning' : 'read'} />;

    if (errorCode) {
        return errorCode === StatusCodes.NOT_FOUND ? <Error404 dataListUrl={urlBuilder.planning()} /> : <Error500 />;
    }

    return (
        <Page>
            <Header>
                <PlanningSelector
                    value={planningPageState}
                    setValue={setPlanningPageState}
                    setValueManuallySet={setValManuallySet}
                    dumpsterTypes={dumpsterTypes}
                    setDumpsterTypes={setDumpsterTypes}
                />
            </Header>
            {plannings && Object.keys(plannings)?.length > 0 ? (
                <>
                    <PlanningContainer>
                        <StyledAccordion multiple activeIndex={[0]}>
                            <AccordionTab
                                header={
                                    <TitleContainer>
                                        <StyledH2>
                                            Étapes non assignées (
                                            {unnassignedCCAdministrative.length + unnassignedCCService.length})
                                            <Button
                                                icon="pi pi-clock"
                                                className="p-button-text"
                                                label="Rapatrier les étapes des jours précédents"
                                                onClick={(event) => {
                                                    /**
                                                     * This avoid accordion toggling when clicking on the button
                                                     */
                                                    event.preventDefault();
                                                    event.stopPropagation();

                                                    setPostponeDialogIsOpen(true);
                                                }}
                                            />
                                            <AddStepButton
                                                openMenuCallback={(event) => menuRef.current?.toggle(event)}
                                            />
                                        </StyledH2>
                                    </TitleContainer>
                                }
                            >
                                <CCUnassigned.CCUnassignedOfTheDay />
                                <AddStepMenu ref={menuRef} />
                            </AccordionTab>
                            <AccordionTab
                                header={
                                    <TitleContainer>
                                        <StyledH2>Étapes futures déjà disponibles</StyledH2>
                                    </TitleContainer>
                                }
                            >
                                <CCUnassigned.CCAlreadyAvailable readOnly={false} />
                            </AccordionTab>
                        </StyledAccordion>
                    </PlanningContainer>
                    <PlanningContainer>
                        <StyledH2>
                            {`Planning: ${moment(Object.values(plannings)[0].day).format(FORMAT_DISPLAY_DATE)}`}
                            <Button
                                icon="pi pi-chart-bar"
                                className="p-button-text"
                                label="Voir les chiffres clés"
                                onClick={() => setMetricsDialogIsOpen(true)}
                            />
                            <Button
                                icon="pi pi-euro"
                                className="p-button-text"
                                label="Voir les coûts de calculs"
                                onClick={() => setCostDialogIsOpen(true)}
                            />
                            <Button
                                icon="pi pi-exclamation-triangle"
                                className="p-button-text"
                                label="Verifier les doublons"
                                onClick={() => setDuplicateDialogIsOpen(true)}
                            />
                        </StyledH2>
                        <PlanningForm context={planningPageState} readOnly={false} />
                    </PlanningContainer>
                </>
            ) : (
                <PlanningPlaceholder>
                    <p>Aucun planning pour les paramètres sélectionnés.</p>
                    <>
                        <h3>{`Date souhaitée: ${moment(planningPageState.day).format(FORMAT_DISPLAY_DATE)}`}</h3>
                        <Button label="Créer un planning" onClick={confirm} icon="pi pi-plus" />
                        <ConfirmDialog />
                    </>
                </PlanningPlaceholder>
            )}
            {plannings && Object.keys(plannings)?.length > 0 && (
                <>
                    <Dialog
                        header={`Données clés - ${moment(planningPageState.day).format(
                            FORMAT_DISPLAY_DATE
                        )} - ${mapPlanningConfigType(planningPageState.type)} - ${mapPlanningConfigRegion(
                            planningPageState.region
                        )}`}
                        visible={metricsDialogIsOpen}
                        onHide={() => setMetricsDialogIsOpen(false)}
                    >
                        <PlanningStats />
                    </Dialog>
                    <Dialog
                        header={`Coûts des calculs - ${moment(planningPageState.day).format(
                            FORMAT_DISPLAY_DATE
                        )} - ${mapPlanningConfigType(planningPageState.type)} - ${mapPlanningConfigRegion(
                            planningPageState.region
                        )}`}
                        visible={costDialogIsOpen}
                        onHide={() => setCostDialogIsOpen(false)}
                    >
                        <PlanningCosts />
                    </Dialog>
                    <Dialog
                        header={'Verification des doublons'}
                        visible={duplicateDialogIsOpen}
                        onHide={() => setDuplicateDialogIsOpen(false)}
                    >
                        <PlanningDuplicate />
                    </Dialog>
                    <Dialog
                        header={`Rapatriement des étapes non terminées des 7 jours précédent le ${moment(
                            planningPageState.day
                        ).format(FORMAT_DISPLAY_DATE)} - ${mapPlanningConfigType(
                            planningPageState.type
                        )} - ${mapPlanningConfigRegion(planningPageState.region)}`}
                        visible={postponeDialogIsOpen}
                        onHide={() => setPostponeDialogIsOpen(false)}
                    >
                        <PlanningPostpone
                            setModalIsOpen={setPostponeDialogIsOpen}
                            setPostPoneDisabledLocalStorage={setAutoModalDisabledInLocalStorage}
                            postPoneDisabledLocalStorage={autoModalDisabledInLocalStorage}
                        />
                    </Dialog>
                </>
            )}
        </Page>
    );
};

export default PlanningPageV2;
