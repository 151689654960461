import moment from 'moment';

import { formatPhoneE164ToPhoneValue, formatPhoneNumberToE164 } from '@bbng/util/misc';
import {
    AdminRo,
    CREATED_FROM,
    CustomerCreateDto,
    CustomerEditDto,
    CustomerRo,
    DocumentRo,
    EBillingBundle,
    EBillingMode,
    EBillingShipment,
    ECustomerCategory,
    EMembershipType,
    ICustomerContact
} from '@bbng/util/types';

import { TDocument, getRelationsDto, mapFrontDocumentToDocumentDto, optimiseEditDto } from '../../common/form';
import { FrontCustomerContact } from '../../modules/InputList/customer-contact';
import {
    AddressErrorState,
    AddressState,
    initialErrorState as addressErrorInitialState,
    initialState as addressInitialState,
    mapApiAddressToStateAddress,
    mapStateAddressToApiAddress
} from '../../modules/common/Address';
import {
    CustomerAdminErrorState,
    CustomerAdminState,
    initialErrorState as customerAdminErrorInitialState,
    initialState as customerAdminInitialState
} from '../../modules/customer/Admin';
import {
    CustomerBillingErrorState,
    CustomerBillingState,
    initialErrorState as customerBillingErrorInitialState,
    initialState as customerBillingInitialState
} from '../../modules/customer/Billing';
import {
    CustomerContactsErrorState,
    CustomerContactsState,
    initialErrorState as customerContactsErrorInitialState,
    initialState as customerContactsInitialState
} from '../../modules/customer/Contacts';
import {
    CustomerTypeErrorState,
    CustomerTypeState,
    initialErrorState as customerTypeErrorInitialState,
    initialState as customerTypeInitialState
} from '../../modules/customer/CustomerType';
import {
    CustomerDocumentsErrorState,
    CustomerDocumentsState,
    initialErrorState as customerDocumentsErrorInitialState,
    initialState as customerDocumentsInitialState
} from '../../modules/customer/Documents';
import {
    CustomerGeneralErrorState,
    CustomerGeneralState,
    initialErrorState as customerGeneralErrorInitialState,
    initialState as customerGeneralInitialState
} from '../../modules/customer/General';

export type CustomerModulesStates =
    | CustomerGeneralState
    | CustomerBillingState
    | AddressState
    | CustomerContactsState
    | CustomerAdminState
    | CustomerDocumentsState
    | CustomerTypeState;

export type CustomerModulesErrorStates =
    | CustomerGeneralErrorState
    | CustomerBillingErrorState
    | AddressErrorState
    | CustomerContactsErrorState
    | CustomerAdminErrorState
    | CustomerDocumentsErrorState
    | CustomerTypeErrorState;

export type CustomerFormState = {
    customerType: CustomerTypeState;
    general: CustomerGeneralState;
    billing: CustomerBillingState;
    headquarters: AddressState;
    invoice_address: AddressState;
    shipping_address: AddressState;
    contacts: CustomerContactsState;
    admin: CustomerAdminState;
    documents: CustomerDocumentsState;
};

export type CustomerFormErrorState = {
    customerType: CustomerTypeErrorState;
    general: CustomerGeneralErrorState;
    billing: CustomerBillingErrorState;
    headquarters: AddressErrorState;
    invoice_address: AddressErrorState;
    shipping_address: AddressErrorState;
    contacts: CustomerContactsErrorState;
    admin: CustomerAdminErrorState;
    documents: CustomerDocumentsErrorState;
};

export const initialState: CustomerFormState = {
    customerType     : customerTypeInitialState,
    general          : customerGeneralInitialState,
    billing          : customerBillingInitialState,
    headquarters     : addressInitialState,
    invoice_address  : addressInitialState,
    shipping_address : addressInitialState,
    contacts         : customerContactsInitialState,
    admin            : customerAdminInitialState,
    documents        : customerDocumentsInitialState
};

export const initialErrorState: CustomerFormErrorState = {
    customerType     : customerTypeErrorInitialState,
    general          : customerGeneralErrorInitialState,
    billing          : customerBillingErrorInitialState,
    headquarters     : addressErrorInitialState,
    invoice_address  : addressErrorInitialState,
    shipping_address : addressErrorInitialState,
    contacts         : customerContactsErrorInitialState,
    admin            : customerAdminErrorInitialState,
    documents        : customerDocumentsErrorInitialState
};

export const mapApiDataToState = (customer: CustomerRo): Partial<CustomerFormState> => {
    return {
        customerType: {
            category: customer.category
        },
        general: {
            is_retailer               : customer.is_retailer,
            naf                       : customer.naf ?? '',
            name                      : customer.name,
            internal_name             : customer.internal_name ?? '',
            siren                     : customer.siren ?? '',
            siret                     : customer.siret ?? '',
            type                      : customer.type ?? '',
            vat                       : customer.vat ?? '',
            membership                : customer.membership ?? '',
            registration              : customer.registration ? moment(customer.registration).toDate() : undefined,
            revenue                   : customer.revenue ?? undefined,
            revenue_year              : customer.revenue_year?.toString() ?? undefined,
            share_capital             : customer.share_capital ?? undefined,
            blocked                   : customer.blocked,
            mandatory_order_sheet     : customer.mandatory_order_sheet,
            id_zoho_customer          : customer.id_zoho_customer ?? '',
            previous_id_zoho_customer : customer.previous_id_zoho_customer ?? '',
            comment                   : customer.comment ?? ''
        },
        admin: {
            admin: customer.admin_id[0] as unknown as AdminRo | undefined
        },
        billing: {
            billing_mode     : customer.billing_mode,
            payment_delay    : customer.payment_time.toString(),
            billing_bundle   : customer.billing_bundle ?? EBillingBundle.GLOBAL,
            billing_shipment : customer.billing_shipment ?? EBillingShipment.EMAIL
        },
        headquarters    : customer.headquarters ? mapApiAddressToStateAddress(customer.headquarters) : undefined,
        invoice_address :
            customer.invoice_addresses.length > 0
                ? mapApiAddressToStateAddress(customer.invoice_addresses[0])
                : undefined,
        shipping_address:
            customer.shipping_addresses.length > 0
                ? mapApiAddressToStateAddress(customer.shipping_addresses[0])
                : undefined,
        contacts: {
            user: customer.contact?.map((c) => mapBackCustomerContactToFrontContact(c))
        },
        documents: {
            documents: (customer.document_id as unknown as DocumentRo[]).map<TDocument>((doc) => ({
                type   : 'online',
                online : doc
            }))
        }
    };
};

export const mapStateToApiCreateData = (state: CustomerFormState): CustomerCreateDto => {
    const isIndividual = state.customerType.category === ECustomerCategory.INDIVIDUAL;

    const data: CustomerCreateDto = {
        name          : state.general.name,
        internal_name : state.general.internal_name ?? undefined,
        membership    : state.general.membership as EMembershipType | undefined,
        ...(!isIndividual && { registration: moment(state.general.registration).utc().toISOString() }),
        ...(!isIndividual && { share_capital: Number(state.general.share_capital) }),
        ...(!isIndividual && { siren: state.general.siren }),
        ...(!isIndividual && { siret: state.general.siret }),
        ...(!isIndividual && { vat: state.general.vat }),
        ...(!isIndividual && { naf: state.general.naf }),
        ...(!isIndividual && { revenue: Number(state.general.revenue) }),
        ...(!isIndividual && state.general.revenue_year && { revenue_year: Number(state.general.revenue_year) }),
        ...(!isIndividual && { type: state.general.type }),
        ...(!isIndividual && { is_retailer: state.general.is_retailer }),

        billing_mode     : state.billing.billing_mode,
        billing_shipment : state.billing.billing_shipment,
        payment_time     : +state.billing.payment_delay,
        ...(state.billing.billing_mode === EBillingMode.END_OF_MONTH && {
            billing_bundle: state.billing.billing_bundle
        }),
        ...(!isIndividual && { headquarters: mapStateAddressToApiAddress(state.headquarters) }),
        admin_id              : state.admin.admin ? [state.admin.admin.id] : [],
        invoice_addresses     : [mapStateAddressToApiAddress(state.invoice_address)],
        shipping_addresses    : [mapStateAddressToApiAddress(state.shipping_address)],
        contact               : state.contacts.user?.map<ICustomerContact>((contact) => mapFrontCustomerContactToBackContact(contact)),
        category              : state.customerType.category,
        blocked               : state.general.blocked,
        mandatory_order_sheet : state.general.mandatory_order_sheet,
        documents             : state.documents.documents
            .filter((doc) => !!doc.local)
            .map((doc) => mapFrontDocumentToDocumentDto(doc)),
        previous_id_zoho_customer : state.general.previous_id_zoho_customer,
        comment                   : state.general.comment,
        created_from              : CREATED_FROM.BACK_OFFICE
    };

    return data;
};

export const mapStateToApiEditData = (
    state: CustomerFormState,
    apiState: CustomerFormState,
    customer: CustomerRo
): CustomerEditDto => {
    const body = optimiseEditDto(state, apiState, customer, mapStateToApiCreateData, getRelationsDto<CustomerRo>());

    const relationState = {
        admin_id    : state.admin.admin ? [state.admin.admin.id] : [],
        document_id : state.documents.documents.filter((f) => f.type === 'online').map((e) => e.online?.id) // only online documents are kept in relations (other are to be created, through documents key)
    };

    const relations = getRelationsDto<CustomerRo>('admin_id', 'document_id')(relationState, customer);

    return {
        ...body,
        ...relations
    };
};

const mapFrontCustomerContactToBackContact = (contact: FrontCustomerContact): ICustomerContact => ({
    ...contact,
    phone_number: contact.phone_number ? formatPhoneNumberToE164(contact.phone_number) : undefined
});

const mapBackCustomerContactToFrontContact = (contact: ICustomerContact): FrontCustomerContact => {
    return {
        ...contact,
        phone_number: contact.phone_number ? formatPhoneE164ToPhoneValue(contact.phone_number) : undefined
    } as FrontCustomerContact;
};
