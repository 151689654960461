import { Currency } from 'dinero.js';

import { DocumentRo, ProductCreateDto, ProductRo, ProductUpdateDto, ZoneRo } from '@bbng/util/types';
import { PRODUCT_FAMILY } from '@bbng/util/types';

import { TDocument, getRelationsDto, optimiseEditDto } from '../../common/form';
import {
    ProductDocumentsErrorState,
    ProductDocumentsState,
    ProductUploadsErrorState,
    ProductUploadsState,
    initialDocumentsErrorState as productDocumentInitialErrorState,
    initialDocumentsState as productDocumentInitialState,
    initialUploadsErrorState as productUploadsInitialErrorState,
    initialUploadsState as productUploadsInitialState
} from '../../modules/product/Documents';
import {
    ProductGeneralErrorState,
    ProductGeneralState,
    initialErrorState as productGeneralInitialErrorState,
    initialState as productGeneralInitialState
} from '../../modules/product/General';

export type ProductModulesStates = ProductGeneralState | ProductDocumentsState | ProductUploadsState;

export type ProductModulesErrorStates =
    | ProductGeneralErrorState
    | ProductUploadsErrorState
    | ProductDocumentsErrorState;

export type ProductFormState = {
    general: ProductGeneralState;
    document?: ProductDocumentsState;
    main_document?: ProductDocumentsState;
    uploads?: ProductUploadsState;
    main_upload?: ProductUploadsState;
};

export type ProductFormErrorState = {
    general: ProductGeneralErrorState;
    document?: ProductDocumentsErrorState;
    main_document?: ProductDocumentsErrorState;
    uploads?: ProductUploadsErrorState;
    main_upload?: ProductUploadsErrorState;
};

export type ProductWithPics = Omit<ProductRo, 'main_photo' | 'photo_id' | 'zone_id'> & {
    main_photo: DocumentRo;
    photo_id: DocumentRo[];
    zone_id: ZoneRo[];
};

export const initialState: ProductFormState = {
    general       : productGeneralInitialState,
    document      : productDocumentInitialState,
    main_document : productDocumentInitialState,
    uploads       : productUploadsInitialState,
    main_upload   : productUploadsInitialState
};

export const initialErrorState: ProductFormErrorState = {
    general       : productGeneralInitialErrorState,
    document      : productDocumentInitialErrorState,
    main_document : productDocumentInitialErrorState,
    uploads       : productUploadsInitialErrorState,
    main_upload   : productUploadsInitialErrorState
};

export const mapApiDataToState = (product: ProductWithPics): ProductFormState => {
    const doc: DocumentRo = product.main_photo;
    const main_pic: TDocument = {
        type   : 'online',
        online : doc
    };
    return {
        general: {
            name                 : product.name,
            subname              : product.subname,
            description          : product.description,
            public               : !product.internal,
            operational          : product.operational,
            net_amount_cents     : product.price.net_amount_cents / 100,
            currency             : product.price.currency,
            vat_rate_percentage  : product.vat_rate_percentage,
            billing_branch       : product.billing_branch,
            family               : product.family,
            main_photo           : product?.main_photo?.id ? product.main_photo.id : null,
            photo_id             : product?.photo_id ? product?.photo_id?.map((doc) => (doc?.id ? doc.id : '')) : [],
            trash_type           : product.trash_type,
            volume_m3            : product.volume_m3,
            zone_id              : product.zone_id,
            billing_modes        : product.billing_modes,
            is_default_empty_run : product.is_default_empty_run,
            dumpster_type        : product.dumpster_type
        },
        uploads: {
            documents: (product.photo_id as unknown as DocumentRo[]).map<TDocument>((doc) => ({
                type   : 'online',
                online : doc
            }))
        },
        main_upload: {
            documents: product.main_photo ? [main_pic] : []
        }
    };
};

export const mapStateToApiCreateData = (state: ProductFormState): ProductCreateDto => {
    return {
        name           : state.general.name,
        subname        : state.general.subname || undefined,
        description    : state.general.description || undefined,
        internal       : !state.general.public,
        operational    : state.general.public ? false : state.general.operational, // if product is public, it cannot be operational
        billing_branch : state.general.billing_branch,
        price          : {
            net_amount_cents : Math.round(state.general.net_amount_cents * 100),
            currency         : 'EUR'
        },
        vat_rate_percentage : state.general.vat_rate_percentage,
        family              : state.general.family
            ? PRODUCT_FAMILY[state.general.family as PRODUCT_FAMILY]
            : PRODUCT_FAMILY.COLLECT_BIG_BAG,
        trash_type           : state.general.trash_type,
        volume_m3            : parseFloat(state.general.volume_m3.toString()),
        zone_id              : state.general.zone_id.map((zone) => zone.id),
        main_photo           : state.main_document?.documents ? state.main_document?.documents[0] : undefined,
        photo_id             : state.document?.documents && state.document?.documents.length > 0 ? state.document.documents : [],
        billing_modes        : state.general.billing_modes,
        is_default_empty_run : state.general.is_default_empty_run,
        dumpster_type        : state.general.dumpster_type
    };
};

export const mapStateToApiEditDataFormatter = (state: ProductFormState): ProductUpdateDto => {
    return {
        name                 : state.general.name,
        subname              : state.general.subname ?? undefined,
        description          : state.general.description ?? undefined,
        billing_branch       : state.general.billing_branch,
        billing_modes        : state.general.billing_modes,
        operational          : state.general.public ? false : state.general.operational, // if product is public, it cannot be operational
        is_default_empty_run : state.general.is_default_empty_run,
        price                : {
            net_amount_cents : Math.round(state.general.net_amount_cents * 100),
            currency         : 'EUR' as Currency
        },
        vat_rate_percentage : state.general.vat_rate_percentage,
        family              : state.general.family
            ? PRODUCT_FAMILY[state.general.family as PRODUCT_FAMILY]
            : PRODUCT_FAMILY.COLLECT_BIG_BAG,
        trash_type : state.general.trash_type,
        volume_m3  : state.general.volume_m3,
        zone_id    : {
            add    : state.general.zone_id.map((zone) => zone.id),
            remove : []
        },
        main_photo: {
            add    : state?.main_document && state.main_document?.documents ? state.main_document?.documents[0] : undefined,
            remove : ''
        },
        photo_id: {
            add    : state.document?.documents && state.document?.documents.length > 0 ? state.document.documents : [],
            remove : []
        },
        dumpster_type: state.general.dumpster_type
    };
};

export const mapStateToApiEditData = (
    state: ProductFormState,
    apiState: ProductFormState,
    discount: ProductRo
): ProductUpdateDto => {
    const diff = optimiseEditDto(
        state,
        apiState,
        discount,
        mapStateToApiEditDataFormatter,
        getRelationsDto<ProductRo>()
    );
    const photo_id_add =
        state.document?.documents && state.document?.documents.length > 0 ? state.document.documents : [];
    let photo_id_remove: string[] = [];
    let main_photo_remove = '';
    const zone_add: string[] = [];
    const zone_remove: string[] = [];
    if (state?.document?.documents && state.document.documents.length > 0) {
        if (apiState.document?.documents && apiState.document.documents.length > 0) {
            photo_id_remove = apiState?.document?.documents.map((doc) => {
                if (state.document?.documents && state.document.documents.length > 0) {
                    const found = state.document.documents.find((doc2) => doc.id === doc2.id);
                    if (!found) return doc.id;
                }
                return '';
            });
        }
    } else {
        apiState?.general?.photo_id.map((id) => {
            photo_id_remove.push(id);
        });
    }
    if (state?.main_document?.documents) {
        if (apiState?.main_document?.documents) {
            if (state.main_document?.documents[0].id !== apiState.general?.main_photo) {
                main_photo_remove = apiState?.general?.main_photo ? apiState?.general?.main_photo : '';
            }
        }
    }

    state.general.zone_id.map((zone) => {
        if (apiState.general.zone_id) {
            const found = apiState.general.zone_id.find((zone2) => zone.id === zone2.id);
            if (!found) zone_add.push(zone.id);
        } else {
            zone_add.push(zone.id);
        }
    });
    apiState.general.zone_id.map((zone) => {
        if (state.general.zone_id) {
            const found = state.general.zone_id.find((zone2) => zone.id === zone2.id);
            if (!found) zone_remove.push(zone.id);
        }
    });

    return {
        ...diff,
        photo_id: {
            add    : photo_id_add,
            remove : photo_id_remove
        },
        main_photo: {
            remove : main_photo_remove,
            add    : state?.main_document?.documents ? state.main_document?.documents[0] : undefined
        },
        zone_id: {
            add    : zone_add,
            remove : zone_remove
        }
    };
};
