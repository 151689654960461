import { Button } from 'primereact/button';
import styled from 'styled-components';

export const RowGroupHeader = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    gap: 16px;
    width: 100%;
`;

export const RowHeaderItem = styled.span`
    flex: 1 1 0px;
`;

export const ListingContainer = styled.span`
    .p-rowgroup-header-name {
        width: 100%;
    }
`;

export const TooltipEntry = styled.div<{ margin?: string }>`
    margin: ${(props) => props.margin || 0};
`;

export const StyledSpan = styled.span`
    display: flex;
    gap: 4px;
    align-items: center;
`;

export const ExportModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
    margin-bottom: 16px;
`;

export const ExportModalButton = styled(Button)`
    width: 100%;
`;
