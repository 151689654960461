import styled from 'styled-components';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

import { CCServiceRoFront, ConstructionSiteRo, CustomerRo } from '@bbng/util/types';

import { urlBuilder } from '../../common/urlBuilder';

export type ConfirmSameOrderModalProps = {
    visible?: boolean;
    cc?: CCServiceRoFront;
    onConfirm?: () => void;
    onCancel?: () => void;
};

export const ConfirmSameOrderModal: React.FC<ConfirmSameOrderModalProps> = ({
    visible = false,
    cc,
    onConfirm = () => void 0,
    onCancel = () => void 0
}) => {
    const client = cc?.customer_id[0] as CustomerRo;
    const constructionSite = cc?.construction_site_id[0] as ConstructionSiteRo;
    return (
        <StyledModal visible={visible} onHide={onCancel} header="Commande identique">
            <div>
                <Link to={urlBuilder.collectConfigView(cc?.id ?? '')} target="_blank">
                    <p>Commande {cc?.order_number}</p>
                </Link>
                <p>
                    Client: <b>{client?.name}</b>
                </p>
                <p>
                    Chantier: <b>{constructionSite?.label}</b>
                </p>
                <p style={{ marginLeft: '16px' }}>{constructionSite?.address.formatted_name}</p>
                <p>
                    Date: <b>{moment(cc?.from_date).format('DD/MM/YYYY')}</b>
                </p>
            </div>
            <br />
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Voulez-vous confirmer la commande ?</p>
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button label="Confirmer" onClick={onConfirm} />
                <Button label="Annuler" onClick={onCancel} />
            </div>
        </StyledModal>
    );
};

const StyledModal = styled(Dialog)`
    min-width: 500px;
`;
