import { MandateStatus } from 'gocardless-nodejs/types/Types';
import { TagSeverityType } from 'primereact/tag';

export const mapStatusToIcon = (status?: MandateStatus): string => {
    switch (status) {
        case MandateStatus.Active:
            return 'pi pi-check';
        case MandateStatus.Blocked:
            return 'pi pi-ban';
        case MandateStatus.Cancelled:
        case MandateStatus.Expired:
            return 'pi pi-exclamation-triangle';
        case MandateStatus.PendingCustomerApproval:
        case MandateStatus.PendingSubmission:
        case MandateStatus.Submitted:
            return 'pi pi-hourglass';
        case MandateStatus.SuspendedByPayer:
            return 'pi pi-pause';
        case MandateStatus.Consumed:
        case MandateStatus.Failed:
            return 'pi pi-times';
        default:
            return '';
    }
};

export const mapStatusToSeverity = (status: MandateStatus): TagSeverityType => {
    switch (status) {
        case MandateStatus.Active:
        case MandateStatus.PendingSubmission:
            return 'success';
        case MandateStatus.PendingCustomerApproval:
        case MandateStatus.Submitted:
            return 'info';
        case MandateStatus.Blocked:
        case MandateStatus.Cancelled:
        case MandateStatus.Expired:
        case MandateStatus.Consumed:
        case MandateStatus.Failed:
            return 'danger';
        case MandateStatus.SuspendedByPayer:
            return 'warning';
        default:
            return 'info';
    }
};
