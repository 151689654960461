import React, { useEffect } from 'react';

import { match } from '@bbng/util/misc';
import { CardErrors, ProductInCCOrCO } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Input from '../../components/Inputs';
import { useFormModule } from '../../hooks/FormModule';

export type CollectConfigSplitLineProps = {
    products: ProductInCCOrCO[];
    value?: CollectConfigSplitLineState;
    id: string;
    result: (
        value: CollectConfigSplitLineState,
        errors: null | string[] | CollectConfigSplitLineErrorState,
        id: string
    ) => void;
    displayError?: boolean;
    errors?: CollectConfigSplitLineErrorState;
};

export type CollectConfigSplitLineState = {
    [key: string]: number;
};

export type CollectConfigSplitLineErrorState = CardErrors<CollectConfigSplitLineState>;

export const initialState = (products: ProductInCCOrCO[]): CollectConfigSplitLineState => {
    return products.reduce((acc, product) => {
        acc[product.id] = Math.ceil(product.quantity / 2);
        return acc;
    }, {} as CollectConfigSplitLineState);
};

export const initialErrorState = (products: ProductInCCOrCO[]): CollectConfigSplitLineErrorState =>
    generateInitialErrorState(initialState(products));

export const CollectConfigSplitLine = ({
    products,
    value,
    id,
    result,
    displayError = false,
    errors
}: CollectConfigSplitLineProps) => {
    const { val, setVal, err, setErr } = useFormModule({
        id,
        initialValue : value || initialState(products),
        initialError : errors || initialErrorState(products),
        result
    });

    const handleChange = React.useCallback(
        (childValue: any, childErrors: string[] | null, childId: string) => {
            setVal((prev) => {
                const data = { ...prev, [childId]: childValue };
                setErr((old) => {
                    const dataErr = {
                        ...old,
                        [childId]: childErrors
                    };
                    const moduleErr = checkIntegrity(data, dataErr, childId);
                    const newErr = match(moduleErr, initialErrorState(products)) ? dataErr : moduleErr;
                    return newErr;
                });
                return data;
            });
        },
        [setVal, setErr, checkIntegrity, products, initialErrorState]
    );

    useEffect(() => {
        setErr((old) => {
            const initialErr = checkIntegrity(val, old);
            const newErr = match(initialErr, initialErrorState(products)) ? old : initialErr;
            return newErr;
        });
    }, []);

    const _checkDescendingValue = React.useCallback(
        async (stateValue: CollectConfigSplitLineState, propsValue: CollectConfigSplitLineState): Promise<void> => {
            if (match(stateValue, propsValue) === false) {
                setVal(propsValue);
                let newErr = initialErrorState(products);
                if (Object.keys(propsValue).length > 1) {
                    const moduleErr = checkIntegrity(propsValue, err);
                    if (match(moduleErr, initialErrorState(products)) === false) {
                        newErr = moduleErr;
                    }
                    setErr(newErr);
                }
            }
        },
        [setVal, setErr, err]
    );

    const _checkDescendingErrors = React.useCallback(
        async (
            stateError: null | string[] | CollectConfigSplitLineErrorState,
            propsError: CollectConfigSplitLineErrorState
        ): Promise<void> => {
            if (match(stateError, propsError) === false) {
                setErr(propsError);
            }
        },
        [setVal, setErr, val]
    );

    React.useEffect(() => {
        _checkDescendingValue(val, value || {});
        if (errors && Object.keys(errors).length > 0) {
            _checkDescendingErrors(err, errors);
        }
    }, [value, errors]);

    return (
        <>
            {Object.entries(val).map(([id, quantity]) => (
                <td>
                    <Input.Number
                        key={id}
                        required={true}
                        showRequiredStar={false}
                        label=""
                        id={id}
                        readOnly={false}
                        result={handleChange}
                        value={quantity}
                        errors={err[id]}
                        displayError={displayError}
                        min={0}
                    />
                </td>
            ))}
        </>
    );
};

const checkIntegrity = (
    data: CollectConfigSplitLineState,
    errors: CollectConfigSplitLineErrorState,
    id?: string
): CollectConfigSplitLineErrorState => {
    let errorText = '';
    if (id ? data[id] < 1 : true) {
        const allValuesAreZero = Object.values(data).every((value) => value < 1);
        if (allValuesAreZero) {
            errorText = 'La quantité doit être supérieure à 0';
        }
    }
    Object.keys(errors).forEach((key) => {
        errors[key] = errorText ? [errorText] : null;
    });
    return errors;
};
