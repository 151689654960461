import React from 'react';
import styled from 'styled-components';
import { RadioButton } from 'primereact/radiobutton';
import { Tag } from 'primereact/tag';
import { Message } from 'primereact/message';

import { CardErrors, CollectorListType, CollectorRo } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import RelationAutocomplete from '../common/RelationAutocomplete';
import { InputListBase } from '../InputList/base';
import { mapCollectorSkill } from '../../common/enumMapper';
import { urlBuilder } from '../../common/urlBuilder';

export type ConstructionSiteCollectorListProps = {
    readOnly?: boolean;
    value?: ConstructionSiteCollectorListState;
    id: string;
    result: (
        value: ConstructionSiteCollectorListState,
        errors: null | string[] | CardErrors<ConstructionSiteCollectorListState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type ConstructionSiteCollectorListState = {
    listType: CollectorListType;
    collectorList: Array<CollectorRo>;
};

export type ConstructionSiteCollectorListErrorState = CardErrors<ConstructionSiteCollectorListState>;

export const initialState: ConstructionSiteCollectorListState = {
    listType      : CollectorListType.BLACK_LIST,
    collectorList : []
};

export const initialErrorState: ConstructionSiteCollectorListErrorState = generateInitialErrorState(initialState);

export const ConstructionSiteCollectorList = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: ConstructionSiteCollectorListProps) => {
    console.log(`DEBUG: => value:`, value);
    const [val, setVal] = React.useState<ConstructionSiteCollectorListState>(value);

    console.log(`DEBUG: => val:`, val);
    const [err, setErr] = React.useState<ConstructionSiteCollectorListErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <StyledCard title="Liste des chauffeurs">
            {!readOnly && <label>Ajouter un chauffeur</label>}
            <CardLine>
                {!readOnly && (
                    <div className="relation-autocomplete">
                        <RelationAutocomplete.Collector
                            key={val.collectorList[val.collectorList.length - 1]?.id ?? 'none'}
                            onSelect={(value) => {
                                console.log(`DEBUG: => val.collectorList:`, val.collectorList);
                                handleChange([...val.collectorList, value], null, 'collectorList');
                            }}
                        />
                    </div>
                )}
                <div className="radiobutton">
                    <RadioButton
                        disabled={readOnly}
                        inputId="blacklist"
                        value={CollectorListType.BLACK_LIST}
                        onChange={(e) => handleChange(e.value, null, 'listType')}
                        checked={val.listType === CollectorListType.BLACK_LIST}
                    />
                    <label htmlFor="blacklist" className="ml-2">
                        Non autorisé
                    </label>
                </div>
                <div className="radiobutton">
                    <RadioButton
                        disabled={readOnly}
                        inputId="whitelist"
                        value={CollectorListType.WHITE_LIST}
                        onChange={(e) => handleChange(e.value, null, 'listType')}
                        checked={val.listType === CollectorListType.WHITE_LIST}
                    />
                    <label htmlFor="whitelist" className="ml-2">
                        Autorisé
                    </label>
                </div>
            </CardLine>
            <div className="fullline">
                <div>
                    {val.listType === CollectorListType.BLACK_LIST ? (
                        <p>Les chauffeurs dans cette liste ne pourront pas être assignés à ce chantier.</p>
                    ) : (
                        <p>Seulement les chauffeurs de cette liste pourront être assignés à ce chantier.</p>
                    )}
                    {val.collectorList.length === 0 && (
                        <Message
                            severity={'warn'}
                            text={
                                val.listType === CollectorListType.BLACK_LIST
                                    ? 'Liste vide: tous les chauffeurs pourront intervenir sur ce chantier.'
                                    : 'Liste vide: aucun chauffeur ne pourra intervenir sur ce chantier.'
                            }
                            style={{ marginBottom: '10px' }}
                        />
                    )}
                </div>
                <InputListBase<CollectorRo>
                    setErrors={() => void 0}
                    columns={[
                        {
                            header : 'Nom',
                            body   : (e: CollectorRo) => (
                                <a target="_blank" rel="noopener noreferrer" href={urlBuilder.collectorView(e.id)}>
                                    {e.fullname}
                                </a>
                            )
                        },
                        { header: 'Téléphone', body: (e: CollectorRo) => e.phone_number },
                        {
                            header : 'Compétences',
                            body   : (e: CollectorRo) => e.skills.map((skill) => mapCollectorSkill(skill)).join(', ')
                        },
                        {
                            header : 'Disponibilité',
                            body   : (e: CollectorRo) =>
                                e.is_available ? (
                                    <Tag severity="success">Disponible</Tag>
                                ) : (
                                    <Tag severity="danger">Indisponible</Tag>
                                )
                        }
                    ]}
                    title="Chauffeurs"
                    disableAddButton
                    disableEditButton
                    disableModalButton
                    disableDeleteButton={readOnly}
                    value={val.collectorList}
                    onChange={(value, errors) => handleChange(value, errors, 'collectorList')}
                />
            </div>
        </StyledCard>
    );
};

const CardLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    .relation-autocomplete {
        min-width: 400px;
    }
`;

const StyledCard = styled(Card)`
    .p-selectbutton {
        margin-bottom: 10px;
    }
    label {
        font-weight: 600;
        font-size: 1.2rem;
    }
    .radiobutton {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .fullline {
        width: 100%;x
    }
`;
