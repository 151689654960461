import styled from 'styled-components';

export const ProgramContentItem = styled.div<{ backgroundColor: string; borderColor?: string }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 8px;
    padding: 0;
    height: 100%;
    border-radius: 5px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border: ${({ borderColor }) => (borderColor ? `2px solid ${borderColor}` : 'none')};
`;

export const NarrowVerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
`;

export const HorizontalContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
`;

export const PostalCode = styled.p`
    text-align: center;
    font-size: 10px;
    margin: 0;
`;

const BadgeHeight = 20;
export const BadgeContainer = styled.div`
    z-index: 0;
    padding-right: 4px;
    top: -${BadgeHeight}px;
    right: 0;
    display: flex;
    gap: 2px;
    align-items: center;
`;

export const Badge = styled.div<{ backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 50% 50% 0 0;
    height: ${BadgeHeight}px;
    width: ${BadgeHeight}px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BadgeText = styled.span`
    font-size: 12px;
`;
