import { Tag } from 'primereact/tag';
import React from 'react';
import styled from 'styled-components';

import { CardErrors, ZoneGetAllQuery, ZoneRo } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { urlApiBuilder } from '../../common/urlBuilder';
import { Card } from '../../components/Card';
import ProgressBar from '../../components/ProgressBar';
import { useFormModule } from '../../hooks/FormModule';
import { useRequest } from '../../hooks/StatelessRequest';
import RelationAutocomplete from '../common/RelationAutocomplete';

export type OrderZoneProps = {
    readOnly?: boolean;
    value?: OrderZoneState;
    id: string;
    result: (value: OrderZoneState, errors: null | string[] | CardErrors<OrderZoneState>, id: string) => void;
    displayError?: boolean;
    postalCode?: string;
};

export type OrderZoneState = {
    zone?: ZoneRo;
};
export type OrderZoneErrorState = CardErrors<OrderZoneState>;

export const initialState: OrderZoneState = {
    zone: undefined
};
export const initialErrorState: OrderZoneErrorState = generateInitialErrorState(initialState);

export const OrderZone: React.FC<OrderZoneProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError,
    postalCode
}: OrderZoneProps) => {
    const [zoneWarning, setZoneWarning] = React.useState<boolean>(false);
    const [zoneLoading, setZoneLoading] = React.useState<boolean>(true);
    const [zoneError, setZoneError] = React.useState<boolean>(false);

    const { val, setVal, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    React.useEffect(() => {
        if (readOnly) return;

        const error = {
            zone: val.zone ? null : ['La zone est requise']
        };
        setErr(error);
    }, []);

    const statelessRequest = useRequest();
    const fetchZoneFromConstructionSite = React.useCallback(async () => {
        setZoneLoading(true);
        const response = await statelessRequest<ZoneRo[]>(
            {
                method  : 'GET',
                url     : urlApiBuilder.zoneGetAll(),
                payload : {
                    queryParams: {
                        postal_code: postalCode
                    } as ZoneGetAllQuery
                }
            },
            true
        );
        if (response.success && response.response?.data.ro) {
            if (response.response.data.ro.length > 0) {
                const zone = response.response.data.ro[0];
                handleChange(zone, null, 'zone');
                setZoneWarning(false);
            } else {
                setZoneWarning(true);
            }
        }
        setZoneLoading(false);
    }, [postalCode]);

    React.useEffect(() => {
        if (postalCode) {
            fetchZoneFromConstructionSite();
        }
    }, [postalCode]);

    return (
        <StyledCard title="Zone">
            {postalCode ? (
                <>
                    <FullLineInput>
                        {zoneLoading ? (
                            <ProgressBar loadingText="Chargement des zones" />
                        ) : (
                            <>
                                <div>Zone:</div>
                                <RelationAutocomplete.Zone
                                    readOnly={readOnly}
                                    baseValue={val.zone}
                                    errors={err.zone}
                                    displayError={displayError}
                                    onSelect={(value) => {
                                        handleChange(value, null, 'zone');
                                        setZoneWarning(false);
                                        setZoneError(Boolean(value?.archived));
                                    }}
                                    onUnselect={() => handleChange(undefined, ['La zone est requise'], 'zone')}
                                />
                            </>
                        )}
                        {zoneWarning && (
                            <ZoneWarning>
                                <Tag
                                    value="Ce chantier ne se situe dans aucune zone éligible."
                                    icon="pi pi-exclamation-triangle"
                                    severity="warning"
                                />
                            </ZoneWarning>
                        )}
                        {zoneError && (
                            <ZoneWarning>
                                <Tag
                                    value="Cette zone est archivée et ne peut être sélectionnée."
                                    icon="pi pi-exclamation-triangle"
                                    severity="error"
                                />
                            </ZoneWarning>
                        )}
                    </FullLineInput>
                </>
            ) : (
                <>
                    {displayError && (err.zone ?? []).length > 0 && (
                        <Errors>{err.zone ?? [].map((e, i) => <li key={e + i}>{e}</li>)}</Errors>
                    )}
                    <Tag
                        value="Veuillez renseigner une adresse de livraison ou un chantier pour pouvoir sélectionner une zone."
                        icon="pi pi-info-circle"
                    />
                </>
            )}
        </StyledCard>
    );
};

const Errors = styled.div`
    color: #d22020;
`;

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)``;

const ZoneWarning = styled.div`
    padding-top: 16px;
    display: flex;
    justify-content: center;
`;

const ZoneError = styled.div`
    padding-top: 16px;
    display: flex;
    justify-content: center;
`;
