import equal from 'fast-deep-equal/react';
import { FormikConfig, useFormik } from 'formik';
import produce, { setAutoFreeze } from 'immer';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import React, { useState } from 'react';

import { deepCheckEmptyObject, mapVolumeToWeightDumpster } from '@bbng/util/misc';
import { deepCopy } from '@bbng/util/misc';
import {
    EDocumentType,
    EPlanningType,
    ETrashType,
    PRODUCT_FAMILY,
    PlanningShiftStepCategory,
    ProductInCCOrCO,
    CollectService,
    PlanningShiftStep,
    CCServiceRo,
    CollectRo
} from '@bbng/util/types';

import { uploadFiles } from '../../common/form';
import { PageLoader } from '../../components/PageLoader';
import { toast } from '../../components/Toast';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import Collect from '../collect';
import { CollectInfoState } from '../collect/Info';
import { CollectLandfillState } from '../collect/Landfill';
import { AddressState } from '../common/Address';
import Order from '../order';
import { mapCollectConfigFamilyToService } from '../order/DumpsterService';
import { OrderProductsState, ProductRoWithQuantity } from '../order/Products';
import { mapProductFamilyToOrderTypeForm } from '../order/Products/helpers';
import { isCollectService, isStepEmptying, isStepService } from '../planning/shifts/helpers';
import { CollectNotificationState } from '../collect/Notification';
import {
    CollectCreateFormErrorState,
    CollectCreateFormState,
    CollectCreateModulesErrorStates,
    CollectCreateModulesStates,
    getUrlFromCategory,
    initialErrorState,
    initialState,
    mapStateToApiCreateData
} from './helpers';
import { PageForm, SubmitButton } from './style';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { isService } from '../../pages/Collect/helpers';

setAutoFreeze(false);

type CollectCreateFormProps = {
    setModalIsOpen: (isOpen: boolean) => void;
    isInternal?: boolean;
    category: PlanningShiftStepCategory;
    step?: PlanningShiftStep;
    wasPlanned?: boolean;
    hideHazard?: boolean;
    hidePhoto?: boolean;
    trashType?: ETrashType;
    showLandfill?: boolean;
    hideCalculateModal?: boolean;
    cc_id?: string;
    showLandfillNumber?: boolean;
    truck_id?: string;
    collector_id?: string;
};

const CollectCreateForm: React.FC<CollectCreateFormProps> = ({
    setModalIsOpen,
    isInternal = false,
    category,
    step,
    wasPlanned = true,
    hideHazard = false,
    hidePhoto = false,
    showLandfill = false,
    hideCalculateModal = false,
    showLandfillNumber = true,
    cc_id,
    truck_id,
    collector_id
}: CollectCreateFormProps): JSX.Element => {
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [err, setErr] = useState<CollectCreateFormErrorState>(initialErrorState);
    const [loading, setLoading] = useState<boolean>(false);

    const {
        planningPageState,
        plannings,
        trucks,
        collectors,
        getPlannings,
        getCollects,
        ccsAdministrative,
        ccsService,
        customers,
        collects,
        landfills,
        getCCAdministrative,
        getCCService
    } = usePlanningV2Store();

    const truck = truck_id ? trucks[truck_id] : undefined;
    const collector = collector_id ? collectors[collector_id] : undefined;
    const collectConfig = cc_id ? ccsService[cc_id] ?? ccsAdministrative[cc_id] : undefined;
    const [trashType, setTrashType] = useState<ETrashType | undefined>(
        step?.collect_id && isService(collects[step.collect_id])
            ? (collects[step.collect_id] as CollectService)?.informations?.collected_items?.[0]?.trash_type
            : (collectConfig?.products || [])?.length > 0
            ? collectConfig?.products[0]?.trash_type
            : undefined
    );
    const customerId = collectConfig?.customer_id || [];
    const customer = customerId?.length > 0 ? customers[customerId[0]] : undefined;
    const collect = step?.collect_id ? collects[step.collect_id] : undefined;
    const stepEmptying = step && isStepEmptying(step) ? step : undefined;
    const landfill = stepEmptying?.landfill_id ? landfills[stepEmptying.landfill_id] : undefined;

    const handleSubmit = React.useCallback(
        async (data: CollectCreateFormState, recalculate: boolean, helper) => {
            if (deepCheckEmptyObject({ ...err.Landfill, ...err.info, ...err.products }) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }
            setLoading(true);

            const documents = data.info?.document
                ? await uploadFiles(
                      [
                          {
                              type  : 'local',
                              local : {
                                  document : data.info?.document ?? undefined,
                                  type     : EDocumentType.COLLECT_PHOTO
                              }
                          }
                      ],
                      'collect'
                  )
                : [];
            if (!documents) {
                toast({
                    severity : 'error',
                    summary  : 'Téléversement des documents échoué',
                    detail   : 'Le téléversement des documents a échoué, veuillez réessayer.'
                });
                return;
            }

            data.products = { ...data.products, ...deepCopy(data.productsDelivery) };

            if (
                collect?.informations.step_category === PlanningShiftStepCategory.SERVICE &&
                collect.informations.emptied_at_landfill_id !== undefined &&
                data.info
            ) {
                const landfill = landfills[collect.informations.emptied_at_landfill_id];

                if (landfill?.owner_is_endless === false) {
                    const retrievalProduct = Object.values(data.products).find(
                        (p) =>
                            p.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL ||
                            p.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT
                    );

                    if (retrievalProduct) {
                        const emptyDumpsterWeight: number =
                            mapVolumeToWeightDumpster[
                                retrievalProduct.volume_m3 as keyof typeof mapVolumeToWeightDumpster
                            ];
                        data.info.dumpster_weight = emptyDumpsterWeight + (data?.info?.dumpster_weight ?? 0);
                    }
                }
            }

            if (
                step?.category === PlanningShiftStepCategory.EMPTYING &&
                data.landfill?.landfill?.owner_is_endless === false
            ) {
                const retrievalProduct = Object.values(data.products).find(
                    (p) =>
                        p.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_RETRIEVAL ||
                        p.family === PRODUCT_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT
                );

                if (retrievalProduct) {
                    const emptyDumpsterWeight: number =
                        mapVolumeToWeightDumpster[retrievalProduct.volume_m3 as keyof typeof mapVolumeToWeightDumpster];
                    data.landfill.weight = emptyDumpsterWeight + (data?.landfill?.weight ?? 0);
                }
            }

            const body = mapStateToApiCreateData(
                data,
                wasPlanned,
                category,
                planningPageState,
                Object.values(plannings),
                step,
                documents,
                recalculate,
                collectConfig
            );
            const url = getUrlFromCategory(category, data.info?.has_hazard, wasPlanned);
            const method = 'POST';

            const response: StatelessResponse<CollectService> = await bbngRequest<CollectService>({
                method,
                url,
                payload: {
                    body
                }
            });
            setLoading(false);

            if (response.success && response.response?.data.ro) {
                setModalIsOpen(false);
                /**
                 * After creating a collect, we need to refresh plannings, collects, and ccs (for updates statuses)
                 */
                await Promise.all([
                    getPlannings(bbngRequest),
                    getCollects(bbngRequest),
                    getCCAdministrative(bbngRequest),
                    getCCService(bbngRequest)
                ]);
            }
        },
        [err, bbngRequest]
    );

    const handlePreSubmit = React.useCallback<FormikConfig<CollectCreateFormState>['onSubmit']>(
        async (data: CollectCreateFormState, helper) => {
            if (deepCheckEmptyObject({ ...err.Landfill, ...err.info, ...err.products }) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            } else if (hideCalculateModal) {
                handleSubmit(data, false, helper);
            } else {
                const message = (
                    <div>
                        <p>Voulez-vous également recalculer le planning ?</p>
                        <Tag
                            value="Certaines collectes pourront être déplanifiées en cas de recalcul"
                            icon="pi pi-exclamation-triangle"
                            severity="warning"
                        />
                    </div>
                );
                confirmDialog({
                    message,
                    header      : 'Confirmer et recalculer',
                    icon        : 'pi pi-caret-right',
                    accept      : () => handleSubmit(data, false, helper), // do not recalculate
                    reject      : () => handleSubmit(data, true, helper), // recalculate
                    rejectLabel : 'Oui, recalculer et valider la collecte',
                    acceptLabel : 'Non, valider la collecte uniquement'
                });
            }
        },
        [err, bbngRequest]
    );

    const handleProductsFormik = (
        cc?: CCServiceRo,
        step?: PlanningShiftStep,
        collect?: CollectRo
    ): OrderProductsState => {
        if (!cc) {
            return {} as OrderProductsState;
        }

        let products: ProductInCCOrCO[] = [];

        if (isStepService(step) && step?.is_splitted) {
            if (isCollectService(collect)) {
                products = collect?.informations?.collected_items || [];
            } else {
                products = cc.splitted_informations[step.splitted_idx!].products || [];
            }
        } else {
            if (isCollectService(collect)) {
                products = collect?.informations?.collected_items || [];
            } else {
                products = cc.products || [];
            }
        }

        return products.reduce((acc, product) => {
            acc[product.id] = {
                ...product,
                quantity: product.quantity
            } as unknown as ProductRoWithQuantity;
            return acc;
        }, {} as OrderProductsState);
    };

    const handleWeightFormik = (collect?: CollectRo): number => {
        if (!collect) {
            return 0;
        }
        if (isCollectService(collect)) {
            return collect?.informations?.dumpster_weight || 0;
        } else {
            return 0;
        }
    };

    const handleDeliveryNumberFormik = (collect?: CollectRo): string => {
        if (!collect) {
            return '';
        }
        if (isCollectService(collect)) {
            return collect?.delivery_number || '';
        } else {
            return '';
        }
    };

    const formik = useFormik<CollectCreateFormState>({
        initialValues: {
            ...initialState(planningPageState.day),
            info: {
                ...initialState(planningPageState.day).info,
                ...(step && {
                    arrived_at   : step?.scheduled_at,
                    completed_at : step?.scheduled_end_at,
                    collector,
                    truck
                }),
                dumpster_weight : handleWeightFormik(collect),
                delivery_number : handleDeliveryNumberFormik(collect)
            } as CollectInfoState,
            products         : handleProductsFormik(collectConfig, step, collect),
            productsDelivery : handleProductsFormik(collectConfig, step, collect),
            landfill         : {
                ...initialState(planningPageState.day).landfill,
                ...(step && {
                    landfill
                })
            } as CollectLandfillState,
            notification: {
                notifyBySms     : true,
                contactToNotify : ''
            } as CollectNotificationState
        },
        onSubmit: handlePreSubmit
    });

    const handleChange = React.useCallback(
        (
            value: CollectCreateModulesStates | AddressState,
            errors: CollectCreateModulesErrorStates | string[] | null,
            id: string
        ): void => {
            if (equal(formik.values[id as keyof CollectCreateFormState], value)) return;
            if (id === 'info') {
                if (
                    (value as CollectInfoState).collector !== undefined &&
                    equal(formik.values.info?.collector, (value as CollectInfoState).collector) === false
                ) {
                    const relevantPlanning = Object.values(plannings).find(
                        (p) => p.collector_id[0] === (value as CollectInfoState).collector?.id
                    );
                    const truck = Object.values(trucks).find((t) => t.id === relevantPlanning?.truck_id[0]);

                    formik.setFieldValue(id, {
                        ...value,
                        ...(truck && { truck })
                    });
                    const newErr = produce(err, (draft) => {
                        if (draft.info) {
                            draft.info.truck = truck !== undefined ? [] : draft.info.truck;
                            draft.info.collector = [];
                        }
                    });
                    setErr(newErr);
                    return;
                }
                if (
                    (value as CollectInfoState).truck !== undefined &&
                    equal(formik.values.info?.truck, (value as CollectInfoState).truck) === false
                ) {
                    const relevantPlanning = Object.values(plannings).find(
                        (p) => p.truck_id[0] === (value as CollectInfoState).truck?.id
                    );
                    const collector = Object.values(collectors).find((t) => t.id === relevantPlanning?.collector_id[0]);

                    formik.setFieldValue('info', {
                        ...value,
                        ...(collector && { collector })
                    });
                    const newErr = produce(err, (draft) => {
                        if (draft.info) {
                            draft.info.collector = collector !== undefined ? [] : draft.info.collector;
                            draft.info.truck = [];
                        }
                    });
                    setErr(newErr);
                    return;
                } else {
                    formik.setFieldValue(id, value);
                }
            } else {
                formik.setFieldValue(id, value);
            }

            if (equal(err, errors)) return;
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    if (loading) return <PageLoader loading />;

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <Collect.Info
                displayError={formik.submitCount > 0}
                readOnly={false}
                id="info"
                result={handleChange}
                value={formik.values.info}
                hideHazard={hideHazard}
                hidePhoto={hidePhoto}
                category={category}
                day={planningPageState.day}
                region={planningPageState.region}
                type={planningPageState.type}
                terminated={!!collect}
                isInternalLandfill={landfill?.owner_is_endless}
            />
            {showLandfill && (
                <Collect.Landfill
                    displayError={formik.submitCount > 0}
                    readOnly={false}
                    id="landfill"
                    result={handleChange}
                    value={formik.values.landfill}
                    region={planningPageState.region}
                    type={planningPageState.type}
                    showNumber={showLandfillNumber}
                    required={formik.values.info?.has_hazard}
                />
            )}
            {!isInternal && (
                <>
                    {' '}
                    {
                        // Only show trash type if it's not a dumpster rotation and not big bag
                        collectConfig?.family !== 'COLLECT_DUMPSTER_ROTATION' &&
                            collectConfig?.family !== 'COLLECT_BIG_BAG' && (
                                <Order.TrashType
                                    displayError={formik.submitCount > 0}
                                    readOnly={false}
                                    id="trashType"
                                    result={(value, errors) => {
                                        if (value) {
                                            setTrashType(value.trashType);
                                        }
                                        if (errors) {
                                            setErr((prev) => ({ ...prev, trashType: errors }));
                                        }
                                    }}
                                    value={{ trashType }}
                                />
                            )
                    }
                    <Order.Products
                        displayError={formik.submitCount > 0}
                        readOnly={false}
                        id="products"
                        result={handleChange}
                        value={formik.values.products}
                        type={mapProductFamilyToOrderTypeForm(collectConfig?.family as PRODUCT_FAMILY)}
                        service={
                            collectConfig?.family.includes('DUMPSTER')
                                ? mapCollectConfigFamilyToService(collectConfig?.family as PRODUCT_FAMILY)
                                : undefined
                        }
                        hasHazard={Boolean(formik.values.info?.has_hazard || collect?.status === 'HAZARD')}
                        trashType={trashType}
                        zoneId={collectConfig?.zone?.id}
                        retrieveDeliveries={false}
                        forceFetch
                    />
                    <Order.Products
                        displayError={formik.submitCount > 0}
                        readOnly={false}
                        label="Autres produits"
                        id="productsDelivery"
                        result={handleChange}
                        value={formik.values.productsDelivery ? formik.values.productsDelivery : {}}
                        type={mapProductFamilyToOrderTypeForm(PRODUCT_FAMILY.DELIVERY_BIG_BAG)}
                        service={
                            step && collectConfig?.family.includes('DUMPSTER')
                                ? mapCollectConfigFamilyToService(
                                      step ? (collectConfig?.family as PRODUCT_FAMILY) : undefined
                                  )
                                : undefined
                        }
                        retrieveDeliveries={
                            step && planningPageState.type === EPlanningType.BIG_BAG && !formik.values.info?.has_hazard
                        }
                        allowNoProducts
                        forceFetch
                    />
                    <Order.Notification
                        displayError={formik.submitCount > 0}
                        readOnly={false}
                        id="notification"
                        result={handleChange}
                        value={formik.values.notification}
                    />
                </>
            )}
            <SubmitButton
                type="submit"
                label="Valider"
                disabled={deepCheckEmptyObject(err) === false && formik.submitCount > 0}
            />
            <ConfirmDialog />
        </PageForm>
    );
};

export default CollectCreateForm;
