import Dinero from 'dinero.js';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';

import { ProductRoWithQuantity } from '.';
import { mapColorFromVolume, mapTrashTypetext } from '../../../common/enumMapper';
import Button from '../../../components/Button';
import Input from '../../../components/Inputs';
import {
    ProductCardData,
    ProductMain,
    ProductPrimaryText,
    ProductSecondary,
    ProductSecondaryText,
    ProductTertiaryText,
    StyledCard
} from './style';

export interface ProductCardProps {
    readOnly?: boolean;
    required: boolean;
    value: ProductRoWithQuantity;
    id: string;
    result: (value: number | boolean | null, errors: null | string[], id: string) => void;
    displayError?: boolean;
    errors: null | string[];
    /**
     * Type of selection for the product card
     * - binary = selected (quantity = 1) or not selected (quantity = 0)
     * - number = input number (quantity = 0, 1, 2, ...)
     */
    selectType: 'binary' | 'number';
}

export const ProductCard: React.FC<ProductCardProps> = ({
    readOnly,
    required,
    value,
    id,
    result,
    displayError,
    errors,
    selectType
}: ProductCardProps): JSX.Element => {
    /**
     * Initial validation to trigger parent result validation (with integrity validation)
     */
    React.useEffect(() => {
        if (required) {
            if (selectType === 'binary') {
                result(value.quantity === 1, null, id);
            } else {
                result(value.quantity, null, id);
            }
        }
    }, []);

    if (selectType === 'binary') {
        return (
            <Button.Clickable id={id} result={result} value={value.quantity === 1} readOnly={readOnly}>
                <CardStyle errors={errors} displayError={displayError} value={value} />
            </Button.Clickable>
        );
    }

    return (
        <CardStyle errors={errors} displayError={false} value={value}>
            <Input.Number
                label=""
                required={required}
                showRequiredStar={false}
                id={id}
                readOnly={readOnly}
                result={result}
                value={value.quantity}
                errors={errors}
                displayError={displayError}
                min={0}
            />
        </CardStyle>
    );
};

type CardStyleProps = {
    value: ProductRoWithQuantity;
    errors: null | string[];
    displayError?: boolean;
    children?: React.ReactNode;
};
const CardStyle: React.FC<CardStyleProps> = (props): JSX.Element => {
    const priceDisplay = Dinero({
        amount   : props.value.price.net_amount_cents,
        currency : props.value.price.currency
    }).toFormat('$0.00');

    return (
        <>
            <Tooltip target=".product-style-card" />
            <div
                className="product-style-card"
                data-pr-tooltip={
                    props.displayError && props.errors && props.errors.length > 0 ? props.errors.join('\n') : ''
                }
            >
                <StyledCard
                    color={mapColorFromVolume(props.value.volume_m3)}
                    selected={props.value.quantity > 0}
                    error={props.displayError && props.errors && props.errors.length > 0 ? true : false}
                >
                    <ProductCardData>
                        <ProductTertiaryText>{props.value.name}</ProductTertiaryText>
                        <ProductSecondaryText>{priceDisplay} / unité</ProductSecondaryText>
                        <ProductMain>
                            <ProductSecondary>
                                <ProductPrimaryText>{props.value.volume_m3}m³</ProductPrimaryText>
                                <ProductPrimaryText>{mapTrashTypetext(props.value.trash_type)}</ProductPrimaryText>
                            </ProductSecondary>
                            {props.children}
                        </ProductMain>
                    </ProductCardData>
                </StyledCard>
            </div>
        </>
    );
};
