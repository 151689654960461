import styled from 'styled-components';

export const BaseContainer = styled.header<{ isScrolled?: boolean }>`
    --hover-shadow: ${({ isScrolled }) => (isScrolled ? '1px 1px 5px rgb(0 0 0 / 20%)' : '0')};

    z-index: 10000;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 0 60px;
    height: 100px;
    position: sticky;
    top: 0;
    transition: box-shadow 0.2s;
    box-shadow: var(--hover-shadow);
`;

export const LogoLink = styled.a`
    display: grid;
    place-items: center;
`;

export const Logo = styled.img`
    width: 200px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    & > * + * {
        margin-left: 26px;
    }
`;

export const Link = styled.a`
    svg {
        height: 1.25em;
        width: 1.25em;
        margin-right: 6px;
    }
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
`;
