/* eslint-disable jsx-a11y/anchor-is-valid */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import AuthCode from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { AccessRo, IBbngResponse, InitiateLoginDto } from '@bbng/util/types';

import { axiosClient, credentialsManager } from '../../common/axios';
import { urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import EndlessLogo from '../../components/EndlessLogo';
import useLocalStorage from '../../hooks/LocalStorage';
import { BlankContainer, BlockContainer, LoginCard, LoginContainer, PageContainer } from './style';

const loginStateMail = 'bbng_login_state-mail';
const loginStateOtp = 'bbng_login_state-otp-state';
const loginStateResendIn = 'bbng_login_state-resend-in';

const emailValidation = yup.string().email();

const LoginPage: React.FC = (): JSX.Element => {
    const nav = useNavigate();
    const [preChecking, setPreChecking] = React.useState(true);
    const [otpState, setOtpState] = useLocalStorage<'start' | 'finish'>(loginStateOtp, 'start');
    const [email, setEmail] = useLocalStorage(loginStateMail, '');

    React.useEffect(() => {
        axiosClient
            .get<any, AxiosResponse<IBbngResponse<boolean>>>(urlApiBuilder.adminAuthVerifyToken())
            .then((response) => {
                if (response.data.success) {
                    nav(urlBuilder.dashboard());
                } else {
                    setPreChecking(false);
                }
            })
            .catch((err) => {
                window.localStorage.clear();
                setPreChecking(false);
            });
    }, []);

    if (preChecking) {
        return (
            <PageContainer>
                <BlankContainer />
                <LoginContainer>
                    <LoginCard>
                        <EndlessLogo size={40} style={{ marginBottom: '32px' }} />
                        <ProgressSpinner />
                    </LoginCard>
                </LoginContainer>
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <BlankContainer />
            <LoginContainer>
                <LoginCard>
                    <EndlessLogo size={40} style={{ marginBottom: '32px' }} />

                    {otpState === 'start' ? (
                        <OtpStart
                            onStartSuccess={(emailSession: string) => {
                                setEmail(emailSession);
                                setOtpState('finish');
                            }}
                            skipStep={email !== '' ? () => setOtpState('finish') : undefined}
                        />
                    ) : (
                        <OtpFinish returnPrev={() => setOtpState('start')} email={email} />
                    )}
                </LoginCard>
            </LoginContainer>
        </PageContainer>
    );
};

const OtpStart: React.FC<{ onStartSuccess: (email: string) => void; skipStep?: () => void }> = ({
    onStartSuccess,
    skipStep
}) => {
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [error, setError] = React.useState<string | undefined>();

    const submitOtpStart = React.useCallback(async () => {
        setLoading(true);
        try {
            const response = await axiosClient.post<IBbngResponse>(urlApiBuilder.adminAuthStartOtp(), {
                email
            } as InitiateLoginDto);

            if (response.data.success) {
                onStartSuccess(email);
            } else {
                setError(response?.data?.data?.message);
            }
        } catch (e) {
            const err: AxiosError<IBbngResponse | string> = e;
            console.error(err);
            if (typeof err?.response?.data === 'string') {
                setError(err?.response?.data);
            } else {
                setError(err?.response?.data?.data?.message);
            }
        }
        setLoading(false);
    }, [email, setError]);

    const validEmail = email === '' ? false : emailValidation.isValidSync(email) && !email.includes('@endless.fr');

    return (
        <BlockContainer>
            <div>
                <span className="p-float-label">
                    <InputText
                        disabled={loading}
                        style={{ backgroundColor: '#ffffff55', width: '100%' }}
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => {
                            if (error) setError(undefined);
                            setEmail(e.target.value);
                        }}
                    />
                    <label htmlFor="email">Email (@bigbagngo.com)</label>
                </span>
                {error && <small id="email-error">{error}</small>}
            </div>

            {loading ? (
                <div style={{ display: 'grid', placeItems: 'center', height: '44px' }}>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '20px' }} />
                </div>
            ) : (
                <>
                    <Button disabled={!validEmail} label="Envoyer" onClick={submitOtpStart} />

                    <a
                        onClick={skipStep ? skipStep : () => void 0}
                        style={{
                            opacity    : skipStep ? '1' : '0.5',
                            cursor     : skipStep ? 'pointer' : 'default',
                            color      : 'var(--primary-color)',
                            fontWeight : 'bold'
                        }}
                    >
                        Passer au code
                    </a>
                </>
            )}
        </BlockContainer>
    );
};

const OtpFinish: React.FC<{ email: string; returnPrev: () => void }> = ({ email, returnPrev }) => {
    const nav = useNavigate();
    const [resendIn, setResendIn] = useLocalStorage(loginStateResendIn, 60);

    const [code, setCode] = React.useState('');

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | undefined>();

    React.useEffect(() => {
        const timer = setInterval(() => {
            setResendIn((e) => (e === 0 ? e : e - 1));
        }, 1000);

        return () => clearInterval(timer);
    }, [setResendIn]);

    const resendEmail = React.useCallback(async () => {
        try {
            await axiosClient.post<InitiateLoginDto, AxiosResponse<IBbngResponse>>(urlApiBuilder.adminAuthStartOtp(), {
                email
            });
            setResendIn(60);
        } catch (err) {
            console.error(err);
        }
    }, [email]);

    const submitOtpStart = React.useCallback(async () => {
        setLoading(true);
        try {
            const { data: bbngResponse } = await axios.post<InitiateLoginDto, AxiosResponse<IBbngResponse<AccessRo>>>(
                urlApiBuilder.adminAuthFinishOtp(),
                {
                    email,
                    code
                }
            );

            if (bbngResponse.success && bbngResponse.data.ro) {
                credentialsManager.updateCredentials(bbngResponse.data.ro);

                window.localStorage.removeItem(loginStateMail);
                window.localStorage.removeItem(loginStateOtp);
                window.localStorage.removeItem(loginStateResendIn);

                nav(urlBuilder.dashboard());
            } else {
                setError(bbngResponse.data.message);
            }
        } catch (err) {
            console.error(err);
            setError(err?.response?.data?.data?.message);
        }
        setLoading(false);
    }, [email, code, setError]);

    return (
        <BlockContainer>
            <div>
                Email envoyé à {email}.
                <br />
                <br />
                Entrer le code reçu pour se connecter
            </div>
            <div>
                <AuthCode
                    containerStyle={{
                        display        : 'flex',
                        justifyContent : 'space-between'
                    }}
                    inputStyle={{
                        height          : '45px',
                        width           : '45px',
                        color           : 'black',
                        backgroundColor : '#ffffff55',
                        border          : '1px solid #00000033',
                        borderRadius    : '5px'
                    }}
                    numInputs={6}
                    isInputNum
                    value={code}
                    onChange={(code: string) => {
                        if (error) setError(undefined);
                        setCode(code);
                    }}
                />
                {error && <small id="email-error">{error}</small>}
            </div>

            {loading ? (
                <div style={{ display: 'grid', placeItems: 'center', height: '44px' }}>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '20px' }} />
                </div>
            ) : (
                <>
                    <a
                        onClick={resendIn > 0 ? undefined : resendEmail}
                        style={{
                            cursor     : resendIn > 0 ? 'default' : 'pointer',
                            color      : 'var(--primary-color)',
                            opacity    : resendIn > 0 ? '0.7' : '1',
                            fontWeight : 'bold'
                        }}
                    >
                        {resendIn > 0 ? `Renvoyer un email dans ${resendIn} s` : 'Renvoyer un email'}
                    </a>

                    <Button disabled={code.length < 6} label="Se connecter" onClick={submitOtpStart} />

                    <a
                        onClick={returnPrev}
                        style={{
                            cursor     : 'pointer',
                            color      : 'var(--primary-color)',
                            fontWeight : 'bold'
                        }}
                    >
                        Email incorrect
                    </a>
                </>
            )}
        </BlockContainer>
    );
};

export default LoginPage;
