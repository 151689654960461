import moment from 'moment';
import { Button as PRButton } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import React from 'react';
import styled from 'styled-components';

import { convertHHMMToDate, mergeDates } from '@bbng/util/misc';
import { CardErrors, ESlot } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import Calendar from '../../components/Calendar';
import { useFormModule } from '../../hooks/FormModule';

export type CollectInfoSlotProps = {
    id: string;
    readOnly?: boolean;
    value?: CollectInfoSlotState;
    result: (value: CollectInfoSlotState, errors: null | string[] | CollectInfoSlotErrorState, id: string) => void;
    onPrecisionChange?: (value: ESlotPrecision) => void;
    displayError?: boolean;
};

export type SlotState = {
    from_date?: string | Date;
    to_date?: string | Date;
};

const slotDayOptions = [ESlot.DAY];
const slotHalfDayOptions = [ESlot.MORNING, ESlot.AFTERNOON];
const slotTwoHourOptions = [
    ESlot.FIVE_EIGHT_AM,
    ESlot.EIGHT_TEN_AM,
    ESlot.TEN_TWELVE_AM,
    ESlot.TWELVE_TWO_PM,
    ESlot.TWO_FOUR_PM,
    ESlot.FOUR_SEVEN_PM
];

export enum ESlotPrecision {
    DAY = 'DAY',
    HALF_DAY = 'HALF_DAY',
    TWO_HOUR = 'TWO_HOUR',
    CUSTOM = 'CUSTOM'
}

const mapSlotStateToPrecision = (value: ESlot): ESlotPrecision => {
    switch (value) {
        case ESlot.DAY:
            return ESlotPrecision.DAY;
        case ESlot.MORNING:
        case ESlot.AFTERNOON:
            return ESlotPrecision.HALF_DAY;
        case ESlot.FIVE_EIGHT_AM:
        case ESlot.EIGHT_TEN_AM:
        case ESlot.TEN_TWELVE_AM:
        case ESlot.TWELVE_TWO_PM:
        case ESlot.TWO_FOUR_PM:
        case ESlot.FOUR_SEVEN_PM:
            return ESlotPrecision.TWO_HOUR;
        case ESlot.CUSTOM:
            return ESlotPrecision.CUSTOM;
    }
};

const mapSlotStateToLabel = (value: ESlot): string => {
    switch (value) {
        case ESlot.DAY:
            return 'Journée';
        case ESlot.MORNING:
            return 'Matin';
        case ESlot.AFTERNOON:
            return 'Après-midi';
        case ESlot.FIVE_EIGHT_AM:
            return '5h-8h';
        case ESlot.EIGHT_TEN_AM:
            return '8h-10h';
        case ESlot.TEN_TWELVE_AM:
            return '10h-12h';
        case ESlot.TWELVE_TWO_PM:
            return '12h-14h';
        case ESlot.TWO_FOUR_PM:
            return '14h-16h';
        case ESlot.FOUR_SEVEN_PM:
            return '16h-19h';
        case ESlot.CUSTOM:
            return 'Personnalisé';
    }
};

export type CollectInfoSlotState = {
    slot?: ESlot;
    slot_from_date?: string | Date;
    slot_to_date?: string | Date;
};
export type CollectInfoSlotErrorState = CardErrors<CollectInfoSlotState>;

export const initialState: CollectInfoSlotState = {
    slot           : ESlot.DAY,
    slot_from_date : convertHHMMToDate('08:00'),
    slot_to_date   : convertHHMMToDate('17:00')
};
export const initialErrorState: CollectInfoSlotErrorState = generateInitialErrorState(initialState);

export const CollectInfoSlot: React.FC<CollectInfoSlotProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    onPrecisionChange,
    displayError
}: CollectInfoSlotProps) => {
    const { val, setVal, err, setErr } = useFormModule<CollectInfoSlotState, CollectInfoSlotErrorState>({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });
    const [precision, setPrecision] = React.useState<ESlotPrecision>(mapSlotStateToPrecision(value.slot as ESlot));

    React.useEffect(() => {
        onPrecisionChange?.(precision);
    }, [precision]);

    const handleChange = (
        value: boolean | number | Date | Date[] | string | string[] | CollectInfoSlotState | undefined,
        errors: string[] | CollectInfoSlotErrorState | null,
        childId: string
    ) => {
        setVal((prev) => {
            let data = { ...prev, [childId]: value };
            if (childId === 'slot') {
                switch (value) {
                    case ESlot.DAY:
                    case ESlot.MORNING: {
                        data = {
                            ...data,
                            slot_from_date: convertHHMMToDate('08:00')
                        };
                        break;
                    }
                    case ESlot.AFTERNOON: {
                        data = {
                            ...data,
                            slot_from_date: convertHHMMToDate('12:00')
                        };
                        break;
                    }
                }
            }
            setErr((old) => {
                const dataErr = {
                    ...old,
                    [childId]: errors
                };
                const newErr = checkIntegrity(data, dataErr);
                return newErr;
            });
            return data;
        });
    };

    const handleCustomSlotSelect = () => {
        confirmDialog({
            header  : `Êtes-vous sûr de vouloir choisir un créneau personnalisé ?`,
            message : () => (
                <div>
                    <strong>Les créneaux classiques doivent être privilégiés.</strong>
                    <br />
                    Un créneau personnalisé demande une <strong>confirmation préalable</strong> auprès de l'équipe
                    opérations.
                    <br />
                    Sans celle-ci, le créneau risque de ne pas être honoré et pourrait être{' '}
                    <strong>source d'un litige</strong>.
                </div>
            ),
            icon            : 'pi pi-exclamation-triangle',
            acceptClassName : 'p-button-warning',
            acceptLabel     : 'Confirmer',
            rejectLabel     : 'Annuler',
            accept          : () => {
                setPrecision(ESlotPrecision.CUSTOM);
                handleChange(ESlot.CUSTOM, null, 'slot');
            },
            reject: () => void 0
        });
    };

    return (
        <>
            {[ESlotPrecision.HALF_DAY, ESlotPrecision.TWO_HOUR, ESlot.CUSTOM].includes(precision) && (
                <PRButton
                    label="Élargir la plage horaire"
                    icon="pi pi-angle-left"
                    type="button"
                    className="p-button-outlined"
                    onClick={() => {
                        switch (precision) {
                            case ESlotPrecision.DAY: {
                                return;
                            }
                            case ESlotPrecision.HALF_DAY: {
                                setPrecision(ESlotPrecision.DAY);
                                handleChange(ESlot.DAY, null, 'slot');
                                return;
                            }
                            case ESlotPrecision.TWO_HOUR: {
                                setPrecision(ESlotPrecision.HALF_DAY);
                                handleChange(ESlot.MORNING, null, 'slot');
                                return;
                            }
                            case ESlotPrecision.CUSTOM: {
                                setPrecision(ESlotPrecision.TWO_HOUR);
                                handleChange(ESlot.FIVE_EIGHT_AM, null, 'slot');
                                return;
                            }
                        }
                    }}
                />
            )}
            {precision === ESlotPrecision.DAY && (
                <Button.Select
                    readOnly={readOnly}
                    required={true}
                    id="slot"
                    result={(selectedValue) => handleChange(selectedValue as ESlot, null, id)}
                    options={slotDayOptions}
                    labelMapper={(value) => mapSlotStateToLabel(value as ESlot)}
                    value={val.slot}
                />
            )}
            {precision === ESlotPrecision.HALF_DAY && (
                <Button.Select
                    readOnly={readOnly}
                    required={true}
                    id={id}
                    result={(value) => {
                        handleChange(value as ESlot, null, id);
                    }}
                    options={slotHalfDayOptions}
                    labelMapper={(value) => mapSlotStateToLabel(value as ESlot)}
                    value={val.slot}
                />
            )}
            {precision === ESlotPrecision.TWO_HOUR && (
                <Button.Select
                    readOnly={readOnly}
                    required={true}
                    id={id}
                    result={(value) => handleChange(value as ESlot, null, id)}
                    options={slotTwoHourOptions}
                    labelMapper={(value) => mapSlotStateToLabel(value as ESlot)}
                    value={val.slot}
                />
            )}
            {[ESlotPrecision.DAY, ESlotPrecision.HALF_DAY, ESlotPrecision.CUSTOM].includes(precision) && (
                <TimeWithLabel>
                    <span>A partir de</span>
                    <Calendar.HoursMins
                        required={true}
                        id="slot_from_date"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.slot_from_date}
                        errors={err.slot_from_date}
                        displayError={displayError}
                        stepMinute={15}
                    />
                </TimeWithLabel>
            )}
            {precision === ESlotPrecision.CUSTOM && (
                <TimeWithLabel>
                    <span>Jusqu'à</span>
                    <Calendar.HoursMins
                        required={true}
                        id="slot_to_date"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.slot_to_date}
                        errors={err.slot_to_date}
                        displayError={displayError}
                        stepMinute={15}
                    />
                </TimeWithLabel>
            )}
            {[ESlotPrecision.DAY, ESlotPrecision.HALF_DAY, ESlotPrecision.TWO_HOUR].includes(precision) && (
                <PRButton
                    label="Préciser la plage horaire"
                    icon="pi pi-angle-right"
                    type="button"
                    className="p-button-outlined"
                    onClick={() => {
                        switch (precision) {
                            case ESlotPrecision.DAY: {
                                setPrecision(ESlotPrecision.HALF_DAY);
                                handleChange(ESlot.MORNING, null, id);
                                return;
                            }
                            case ESlotPrecision.HALF_DAY: {
                                setPrecision(ESlotPrecision.TWO_HOUR);
                                handleChange(ESlot.FIVE_EIGHT_AM, null, id);
                                return;
                            }
                            case ESlotPrecision.TWO_HOUR: {
                                handleCustomSlotSelect();
                                return;
                            }
                        }
                    }}
                />
            )}
        </>
    );
};

const checkIntegrity = (data: CollectInfoSlotState, errors: CollectInfoSlotErrorState): CollectInfoSlotErrorState => {
    let errorText = '';
    const hoursFrom = data.slot_from_date && moment(data.slot_from_date).hours();
    if (typeof hoursFrom === 'number') {
        if (data.slot === ESlot.DAY) {
            if (hoursFrom < 5 || hoursFrom >= 19) {
                errorText = "L'heure de début de la plage horaire doit être comprise entre 5h et 19h";
            }
        }
        if (data.slot === ESlot.MORNING) {
            if (hoursFrom < 5 || hoursFrom >= 12) {
                errorText = "L'heure de début de la plage horaire doit être comprise entre 5h et 12h";
            }
        }
        if (data.slot === ESlot.AFTERNOON) {
            if (hoursFrom < 12 || hoursFrom >= 19) {
                errorText = "L'heure de début de la plage horaire doit être comprise entre 12h et 19h";
            }
        }
        if (data.slot === ESlot.CUSTOM) {
            const to = data.slot_to_date && moment(data.slot_to_date);
            const hoursTo = to && to.hours();
            const minutesTo = to && to.minutes();
            if (typeof hoursTo === 'number' && typeof minutesTo === 'number') {
                /**
                 * End date must be after start date
                 */
                if (hoursTo && hoursTo < hoursFrom) {
                    errorText = "L'heure de fin de la plage horaire doit être supérieure à l'heure de début";
                }

                /**
                 * Ensure day is the same for both dates by taking slot_from_date day as reference.
                 */
                const fromTime = mergeDates(data.slot_from_date, data.slot_from_date);
                const toTime = mergeDates(data.slot_from_date, data.slot_to_date);
                const diff = moment(toTime).diff(fromTime, 'minutes');
                /**
                 * Difference between two dates must be more than 1h
                 */
                if (typeof diff === 'number' && diff < 60) {
                    errorText = "La plage horaire doit être d'au moins 1h";
                }
                /**
                 * Hours must be between 5h and 19h
                 */
                if (hoursFrom < 5 || hoursTo > 19 || (hoursTo === 19 && minutesTo > 0)) {
                    errorText =
                        'Les heures de début et de fin de la plage horaire doivent être comprises entre 5h et 19h';
                }
            }
        }
    }
    return {
        ...errors,
        slot_from_date: errorText ? [errorText] : null
    };
};

const TimeWithLabel = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;
