import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import { CCServiceRo, CCServiceRoFront, CC_STATUS, CollectConfigQuery } from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import ProgressBar from '../../components/ProgressBar';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import { CollectConfigCard } from './CollectConfigCard';
import { CollectConfigsContainer } from './style';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';

export type CollectConfigsAlreadyAvailableProps = {
    readOnly: boolean;
};

export const CollectConfigsAlreadyAvailable: React.FC<CollectConfigsAlreadyAvailableProps> = ({
    readOnly
}: CollectConfigsAlreadyAvailableProps): JSX.Element => {
    const { planningPageState } = usePlanningV2Store();
    const [alreadyAvailableCcs, setAlreadyAvailableCcs] = useState<CCServiceRo[]>([]);
    const [alreadyAvailableFetched, setAlreadyAvailableFetched] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const bbngRequest = useRequest();
    const fetchAlreadyAvailableCcs = useCallback(async () => {
        setLoading(true);
        const res: StatelessResponse<CCServiceRo[]> = await bbngRequest({
            url     : urlApiBuilder.collectConfigReadAll(),
            method  : 'GET',
            sync    : true,
            payload : {
                queryParams: {
                    already_available_date : planningPageState.day,
                    type                   : planningPageState.type,
                    region                 : planningPageState.region,
                    statuses               : Object.values(CC_STATUS).filter(
                        (status) =>
                            ![CC_STATUS.FINISHED, CC_STATUS.HAZARD, CC_STATUS.ORDER_TO_PAY].includes(status as any)
                    )
                } as CollectConfigQuery
            }
        });
        if (res.success && res.response?.data.ro) {
            const ccs = res.response.data.ro;
            const sortedCcs = ccs.sort((a, b) => (moment(a.from_date).isBefore(moment(b.from_date)) ? -1 : 1));
            setAlreadyAvailableCcs(sortedCcs);
        }
        setLoading(false);
    }, [planningPageState]);

    useEffect(() => {
        if (!alreadyAvailableFetched) {
            fetchAlreadyAvailableCcs();
            setAlreadyAvailableFetched(true);
        }
    }, [planningPageState]);

    return (
        <CollectConfigsContainer>
            {loading ? (
                <ProgressBar loadingText="Chargement des étapes déjà disponibles" />
            ) : alreadyAvailableCcs.length === 0 ? (
                <div className="no-ccs">Aucune étape future déjà disponible.</div>
            ) : (
                alreadyAvailableCcs.map((collect_config) => (
                    <CollectConfigCard
                        key={collect_config.id + (collect_config as CCServiceRoFront)['splitted_idx'] ?? '0'}
                        collect_config={collect_config}
                        readOnly={readOnly}
                        showDay={true}
                        showEdit={false}
                        showMerge={false}
                        showSplit={false}
                        alreadyAvailable={true}
                        splittedIdx={(collect_config as CCServiceRoFront)['splitted_idx']}
                    />
                ))
            )}
        </CollectConfigsContainer>
    );
};
