import { EDataType, ErrorContext, ErrorResponsability } from '@bbng/util/types';

import { ErrorCodeFR } from './error-translate';
import { NdlssError } from './ndlss-error';

type AuthErrorConstructor = {
    httpCode?: number;
    code: keyof ErrorCodeFR[ErrorContext.Auth];
    params?: Record<string, any>;
    responsability?: ErrorResponsability;
    dataType?: EDataType;
};

/**
 * @description
 * Error thrown when a error occurs during the authentication process
 *
 * Wrapper around the {@link NdlssError} class to preset the context `ErrorContext.Auth`
 *
 */
export class AuthError extends NdlssError {
    override name = 'NdlssAuthError';

    constructor({ httpCode, code, responsability, dataType, params = {} }: AuthErrorConstructor) {
        super({
            httpCode,
            code,
            params,
            context: ErrorContext.Auth,
            responsability,
            dataType
        });
    }
}
