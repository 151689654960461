import { PlanningShiftStepCategory } from '@bbng/util/types';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React from 'react';
import { useState } from 'react';
import { mapCollectCategoryText } from '../../../common/enumMapper';
import { Dialog } from '../../../components/Dialog';
import CollectCreateForm from '../../../modules/collect-create-form';
import CollectConfigForm from '../../../modules/planning-collect-config-form';

export const AddStepMenu = React.forwardRef<Menu>((_, ref) => {
    const [createCcDialogIsOpen, setCreateCcDialogIsOpen] = useState(false);
    const [createCollectDialogIsOpen, setCreateCollectDialogIsOpen] = useState(false);
    const [collectCategory, setCollectCategory] = useState<PlanningShiftStepCategory>(
        PlanningShiftStepCategory.EMPTYING
    );

    const items: MenuItem[] = [
        {
            label : 'Étape à faire',
            items : [
                {
                    label   : 'Administrative',
                    command : () => {
                        setCreateCcDialogIsOpen(true);
                    }
                }
            ]
        },
        {
            label : 'Étape terminée',
            items : [
                {
                    label   : 'Évacuation',
                    command : () => {
                        setCollectCategory(PlanningShiftStepCategory.EMPTYING);
                        setCreateCollectDialogIsOpen(true);
                    }
                },
                {
                    label   : 'Pause',
                    command : () => {
                        setCollectCategory(PlanningShiftStepCategory.BREAK);
                        setCreateCollectDialogIsOpen(true);
                    }
                }
            ]
        }
    ];

    return (
        <>
            <Menu model={items} popup ref={ref} id="popup_menu" />
            <Dialog
                visible={createCcDialogIsOpen}
                onHide={() => setCreateCcDialogIsOpen(false)}
                header="Planifier une collecte"
                draggable={false}
                resizable={false}
                blockScroll
                contentStyle={{ overflow: 'scroll' }}
            >
                <CollectConfigForm
                    setModalIsOpen={setCreateCcDialogIsOpen}
                    isInternal
                    isAdministrative={true}
                    hideAlreadyAvailable={true}
                />
            </Dialog>
            <Dialog
                visible={createCollectDialogIsOpen}
                onHide={() => setCreateCollectDialogIsOpen(false)}
                header={`Déclarer manuellement une ${mapCollectCategoryText(
                    collectCategory
                ).toLocaleLowerCase()} terminée`}
                draggable={false}
                resizable={false}
                blockScroll
                contentStyle={{ overflow: 'scroll' }}
            >
                <CollectCreateForm
                    setModalIsOpen={setCreateCollectDialogIsOpen}
                    category={collectCategory}
                    isInternal
                    wasPlanned={false}
                    hideHazard={collectCategory === PlanningShiftStepCategory.BREAK}
                    hidePhoto={collectCategory === PlanningShiftStepCategory.BREAK}
                    showLandfill={collectCategory === PlanningShiftStepCategory.EMPTYING}
                />
            </Dialog>
        </>
    );
});
