import styled from 'styled-components';

export const FiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
`;

export const Filters = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

export const Anchor = styled.a`
    word-break: break-all;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #343699;
`;

export const PageDescription = styled.div`
    display: flex;
`;
