import { Tag } from 'primereact/tag';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import {
    CardErrors,
    ETrashType,
    PRODUCT_FAMILY,
    ProductQuery,
    ProductRo,
    EBillingMode,
    EProductBillingMode,
    PRODUCT_DUMPSTER_TYPE
} from '@bbng/util/types';

import { generateInitialErrorState } from '../../../common/form';
import { urlApiBuilder } from '../../../common/urlBuilder';
import { Card } from '../../../components/Card';
import ProgressBar from '../../../components/ProgressBar';
import { useFormModule } from '../../../hooks/FormModule';
import { useRequest } from '../../../hooks/StatelessRequest';
import { EOrderDumpsterServiceForm } from '../DumpsterService';
import { EOrderTypeForm } from '../Type';
import { ProductWrapper } from './ProductWrapper';
import { checkIntegrity, forceIntegrity, getNewQuantity, mapOrderTypeFormToProductFamilies } from './helpers';
import { ProductCardProps } from './ProductCard';

export type ProductRoWithQuantity = ProductRo & {
    quantity: number;
};

export type OrderProductsProps = {
    readOnly?: boolean;
    value?: OrderProductsState;
    id: string;
    result: (value: OrderProductsState, errors: null | string[] | CardErrors<OrderProductsState>, id: string) => void;
    displayError?: boolean;
    zoneId?: string;
    type?: EOrderTypeForm;
    trashType?: ETrashType;
    service?: EOrderDumpsterServiceForm;
    forceFetch?: boolean;
    hasHazard?: boolean;
    allowNoProducts?: boolean;
    retrieveDeliveries?: boolean;
    label?: string;
    customerBillingMode?: EBillingMode;
    /**
     * Fetch operational products:
     * - if true, fetch only operational products
     * - if false, exclude operational products
     * - if undefined, fetch all products
     */
    fetchOperational?: boolean;
    displayBigBagTrashTypeSelect?: boolean;
    forceSelectType?: ProductCardProps['selectType'];
    forceOneProductOnly?: boolean;
    dumpsterTypes?: PRODUCT_DUMPSTER_TYPE[];
};

export type OrderProductsState = {
    [key: string]: ProductRoWithQuantity;
};
export type OrderProductsErrorState = CardErrors<OrderProductsState>;

export const initialState: OrderProductsState = {};
export const initialErrorState: OrderProductsErrorState = generateInitialErrorState(initialState);

export const OrderProducts: React.FC<OrderProductsProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError,
    zoneId,
    type,
    trashType,
    service,
    forceFetch,
    hasHazard,
    retrieveDeliveries = false,
    label = 'Produits',
    customerBillingMode,
    fetchOperational,
    displayBigBagTrashTypeSelect = true,
    forceSelectType,
    allowNoProducts,
    forceOneProductOnly = true,
    dumpsterTypes
}: OrderProductsProps) => {
    const [incomplete, setIncomplete] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);
    const { val, setVal, err, setErr } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    const trashTypeFilter = React.useCallback(() => {
        /**
         * Do not filter by trashType for
         * - hazard context
         * - delivery context
         * - dumpster rotation context (filter is done in frontend in ProductWrapper)
         */
        if (
            hasHazard ||
            type === EOrderTypeForm.DELIVERY ||
            service === EOrderDumpsterServiceForm.ROTATION ||
            type === EOrderTypeForm.COLLECT_BIG_BAG
        ) {
            return undefined;
        }
        return trashType;
    }, [hasHazard, type, service, trashType]);

    const statelessRequest = useRequest();
    const fetchEligibleProducts = React.useCallback(async () => {
        setLoading(true);
        const families = retrieveDeliveries
            ? [...mapOrderTypeFormToProductFamilies(type, service), PRODUCT_FAMILY.DELIVERY_BIG_BAG]
            : mapOrderTypeFormToProductFamilies(type, service);

        // keep only operational product for hazard context
        let operationalQuery = undefined;
        if (hasHazard) operationalQuery = true;
        if (fetchOperational) operationalQuery = true;
        if (fetchOperational === false) operationalQuery = false;

        const response = await statelessRequest<ProductRo[]>(
            {
                method  : 'GET',
                url     : urlApiBuilder.productGetAll(''),
                payload : {
                    queryParams: {
                        zone_id       : zoneId,
                        families      : families,
                        trash_type    : trashTypeFilter(),
                        limit         : 1000,
                        operational   : operationalQuery,
                        no_archived   : true,
                        billing_modes : customerBillingMode
                            ? customerBillingMode === EBillingMode.INSTANT
                                ? [EProductBillingMode.INSTANT]
                                : [EProductBillingMode.DELAYED]
                            : undefined,
                        dumpster_types:
                            type === EOrderTypeForm.COLLECT_DUMPSTER && dumpsterTypes && dumpsterTypes?.length > 0
                                ? dumpsterTypes
                                : undefined
                    } as ProductQuery
                }
            },
            true
        );
        if (response.success && response.response?.data.ro) {
            setVal((old) => {
                return Object.fromEntries(
                    response.response?.data?.ro?.map((product) => {
                        const existingProduct = Object.values(value).find((p) => p.id === product.id);
                        return [
                            product.id,
                            {
                                ...product,
                                quantity : existingProduct ? existingProduct.quantity : 0,
                                price    : existingProduct ? existingProduct.price : product.price,
                                zone     : product.zone_id
                            }
                        ];
                    }) || []
                );
            });
            setErr((old) => initialErrorState);
            setIncomplete(false);
            if (response.response?.data.ro.length === 0) {
                setIncomplete(true);
                !hasHazard &&
                    !allowNoProducts &&
                    setErr((old) => {
                        return {
                            [id]: ["Aucun produit n'est disponible pour cette zone"]
                        };
                    });
            } else {
                setIncomplete(false);
            }
            setLoading(false);
        }
    }, [zoneId, type, trashType, service, hasHazard, customerBillingMode, fetchOperational, dumpsterTypes]);

    const handleChange = React.useCallback(
        (value: number | boolean | null, errors: string[] | null, childId: string) => {
            setVal((prev) => {
                let data = {
                    ...prev,
                    [childId]: {
                        ...prev[childId],
                        quantity: getNewQuantity(value)
                    }
                };
                if (forceOneProductOnly) {
                    data = forceIntegrity(data, childId, type, service);
                }
                setErr((prev) => {
                    const newErr = {
                        ...prev,
                        [childId]: errors
                    };
                    return checkIntegrity({
                        data                : data,
                        error               : newErr,
                        id                  : childId,
                        service             : service,
                        authorizeEmptyOrder : hasHazard || allowNoProducts
                    });
                });
                return data;
            });
        },
        [setVal, setErr, type, service, hasHazard, forceOneProductOnly]
    );

    useEffect(() => {
        /**
         * Reset error state when allowNoProducts changes
         */
        setErr((old) => {
            return checkIntegrity({
                data                : val,
                error               : old,
                id                  : id,
                service             : service,
                authorizeEmptyOrder : hasHazard || allowNoProducts
            });
        });
    }, [allowNoProducts]);

    useEffect(() => {
        const deliveryCondition = type === EOrderTypeForm.DELIVERY;
        const bigbagCollectCondition = !!zoneId && type === EOrderTypeForm.COLLECT_BIG_BAG;
        const dumpsterCollectCondition =
            !!zoneId &&
            type === EOrderTypeForm.COLLECT_DUMPSTER &&
            (!!trashType || service === EOrderDumpsterServiceForm.ROTATION) &&
            !!service;
        if (deliveryCondition || bigbagCollectCondition || dumpsterCollectCondition || forceFetch) {
            if (!readOnly) fetchEligibleProducts();
            setIncomplete(false);
        } else {
            setIncomplete(true);
        }
    }, [zoneId, type, trashType, service, hasHazard, customerBillingMode, JSON.stringify(dumpsterTypes)]);

    const selectType = useMemo(() => {
        if (forceSelectType) return forceSelectType;
        if ([EOrderTypeForm.COLLECT_BIG_BAG, EOrderTypeForm.DELIVERY].includes(type as any)) return 'number';
        return 'binary';
    }, [type, forceSelectType]);

    return (
        <StyledCard title={label}>
            {loading ? (
                <ProgressBar loadingText="Chargement des produits..." />
            ) : !incomplete && Object.values(val)?.length > 0 ? (
                <ProductWrapper
                    readOnly={readOnly}
                    value={val}
                    errors={err}
                    handleChange={handleChange}
                    displayError={displayError}
                    type={type}
                    service={service}
                    displayBigBagTrashTypeSelect={displayBigBagTrashTypeSelect}
                    selectType={selectType}
                />
            ) : (
                <Tag
                    severity={displayError && Object.keys(err).length > 0 ? 'danger' : 'warning'}
                    value="Aucun produit pour la combinaison sélectionnée (chantier, déchet, prestation et type de commande)."
                    icon={`pi ${displayError && Object.keys(err).length > 0 ? 'pi-times' : 'pi-info-circle'}`}
                />
            )}
        </StyledCard>
    );
};

const StyledCard = styled(Card)`
    & .p-card-content {
        justify-content: center;
    }
`;
