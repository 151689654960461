import { Contacts, ContactsProps } from '../common/Contacts';
import { ConstructionSiteCollectorList, ConstructionSiteCollectorListProps } from './CollectorList';
import { ConstructionSiteCustomer, ConstructionSiteCustomerProps } from './Customer';
import { ConstructionSiteDocuments, ConstructionSiteDocumentsProps } from './Document';
import { ConstructionSiteGeneral, ConstructionSiteGeneralProps } from './General';

const Modules = {
    GeneralInfo: (props: ConstructionSiteGeneralProps) => {
        return <ConstructionSiteGeneral {...props} />;
    },
    Contacts: (props: ContactsProps) => {
        return <Contacts {...props} />;
    },
    Customer: (props: ConstructionSiteCustomerProps) => {
        return <ConstructionSiteCustomer {...props} />;
    },
    Document: (props: ConstructionSiteDocumentsProps) => {
        return <ConstructionSiteDocuments {...props} />;
    },
    CollectorList: (props: ConstructionSiteCollectorListProps) => {
        return <ConstructionSiteCollectorList {...props} />;
    }
};

export default Modules;
