import { CustomerMandateFlowCreateDto } from '@bbng/util/types';

import {
    CustomerMandateState,
    CustomerMandateErrorState,
    initialErrorState as mandateCustomerInitialErrorState,
    initialState as mandateCustomerInitialState
} from '../../modules/customer/Mandate';

export type MandateFormState = {
    mandate: CustomerMandateState;
};

export type MandateFormErrorState = {
    mandate: CustomerMandateErrorState;
};

export const initialState: MandateFormState = {
    mandate: mandateCustomerInitialState
};

export const initialErrorState: MandateFormErrorState = {
    mandate: mandateCustomerInitialErrorState
};

export const mapStateToApiCreateData = (state: MandateFormState): CustomerMandateFlowCreateDto => {
    return {
        createOnE1  : state.mandate.createOnE1 ?? false,
        createOnE2  : state.mandate.createOnE2 ?? false,
        customer_id : state.mandate.customer?.id as string,
        email       : state.mandate.email as string,
        firstname   : state.mandate.firstname,
        lastname    : state.mandate.lastname
    };
};
