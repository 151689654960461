import { FormikConfig, useFormik } from 'formik';
import { StatusCodes } from 'http-status-codes';
import React, { useState } from 'react';

import { deepCheckEmptyObject, deepCopy } from '@bbng/util/misc';
import { CCServiceRo, ProductInCCOrCO } from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import { PageLoader } from '../../components/PageLoader';
import { toast } from '../../components/Toast';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import CollectConfig from '../../modules/collect-config';
import { AddressState } from '../../modules/common/Address';
import Error404Data from '../../pages/Error404Data';
import Error500 from '../../pages/Error500';
import {
    CollectConfigSplitFormErrorState,
    CollectConfigSplitFormState,
    CollectConfigSplitModulesErrorStates,
    CollectConfigSplitModulesStates,
    checkSplitIsConsistent,
    initialErrorState,
    initialState,
    mapStateToApiData
} from './helpers';
import { PageForm, SubmitButton } from './style';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';

type CollectConfigSplitFormProps = {
    setModalIsOpen: (isOpen: boolean) => void;
    dataId?: string;
};

const CollectConfigSplitForm: React.FC<CollectConfigSplitFormProps> = ({
    setModalIsOpen,
    dataId
}: CollectConfigSplitFormProps): JSX.Element => {
    const [apiState, setApiState] = React.useState<CollectConfigSplitFormState>();
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const { getPlannings, getCCService } = usePlanningV2Store();

    const [fetchError, setFetchError] = useState<StatusCodes>();
    const [fetchedData, setFetchedData] = useState<CCServiceRo>();
    const [loading, setLoading] = useState<boolean>(true);

    const [err, setErr] = useState<CollectConfigSplitFormErrorState>(initialErrorState([]));

    const handleSubmit = React.useCallback<FormikConfig<CollectConfigSplitFormState>['onSubmit']>(
        async (data: CollectConfigSplitFormState, helper) => {
            if (deepCheckEmptyObject(err) === false) {
                console.log('🧐 ~ SUBMIT FAILED', err);
                return;
            }
            setLoading(true);

            if (checkSplitIsConsistent(data, (fetchedData?.products as unknown as ProductInCCOrCO[]) || []) === false) {
                toast({
                    severity : 'error',
                    summary  : 'Formulaire invalide',
                    detail   : 'La quantité totale de produits dans les sous-collectes est différente de la quantité initiale.'
                });
                return;
            }

            const response: StatelessResponse<CCServiceRo> = await bbngRequest<CCServiceRo>({
                method  : 'PATCH',
                url     : urlApiBuilder.collectConfigSplit(dataId as string),
                payload : {
                    body: mapStateToApiData(data)
                }
            });
            if (response.success && response.response?.data.ro) {
                /**
                 * Fetch all plannings and all cc service to make sure store is up to date after split
                 */
                await Promise.all([getPlannings(bbngRequest), getCCService(bbngRequest)]);
            }
            setLoading(false);
            setModalIsOpen(false);
        },
        [err, apiState, bbngRequest]
    );

    const formik = useFormik<CollectConfigSplitFormState>({
        initialValues : initialState([]),
        onSubmit      : handleSubmit
    });

    const handleChange = React.useCallback(
        (
            value: CollectConfigSplitModulesStates | AddressState,
            errors: CollectConfigSplitModulesErrorStates | string[] | null,
            id: string
        ): void => {
            formik.setValues((prev) => ({ ...prev, [id]: value }));
            setErr((prev) => ({ ...prev, [id]: errors }));
        },
        [formik.setValues, setErr]
    );

    const fetchData = React.useCallback(async () => {
        const response = await bbngRequest<CCServiceRo>({
            method  : 'GET',
            url     : urlApiBuilder.collectConfigRead(dataId as string),
            options : { toastifySuccess: false }
        });

        if (response.success && response.response?.data.ro) {
            const ro: CCServiceRo = response.response?.data.ro;
            const roProducts = (ro.products as unknown as ProductInCCOrCO[]) || [];
            const formikState: CollectConfigSplitFormState = initialState(roProducts);

            setFetchedData(ro);
            setApiState(formikState);
            formik.setValues(deepCopy(formikState));
            setErr(initialErrorState(roProducts));
            setLoading(false);
        } else {
            setFetchError(response?.response?.data.status_code ?? response.error?.status);
            setLoading(false);
        }
    }, [dataId]);

    React.useEffect(() => {
        fetchData();
    }, []);

    if (loading) return <PageLoader loading />;

    if (fetchError) {
        return fetchError === StatusCodes.NOT_FOUND ? <Error404Data /> : <Error500 />;
    }

    return (
        <PageForm onSubmit={formik.handleSubmit}>
            <CollectConfig.Split
                products={(fetchedData?.products as unknown as ProductInCCOrCO[]) || []}
                displayError={formik.submitCount > 0}
                id="split"
                result={handleChange}
                value={formik.values.split}
            />
            <SubmitButton
                type="submit"
                label="Valider"
                disabled={
                    (deepCheckEmptyObject(err) === false && formik.submitCount > 0) ||
                    !checkSplitIsConsistent(
                        formik.values,
                        (fetchedData?.products as unknown as ProductInCCOrCO[]) || []
                    )
                }
            />
        </PageForm>
    );
};

export default CollectConfigSplitForm;
