import { Address, AddressProps } from '../common/Address';
import { Contacts, ContactsProps } from '../common/Contacts';
import { CollectConfigCollectorTruck, CollectConfigCollectorTruckProps } from './Collector&Truck';
import { DumpsterOnSiteSelect, DumpsterOnSiteSelectProps } from './DumpsterOnSiteSelect';
import { CollectConfigMisc, CollectConfigMiscProps } from './Misc';
import { PatchTracker, PatchTrackerProps } from './PatchTracker';
import { CollectConfigSplit, CollectConfigSplitProps } from './Split';

const Modules = {
    Misc: (props: CollectConfigMiscProps) => {
        return <CollectConfigMisc {...props} />;
    },
    Address: (props: AddressProps) => {
        return <Address {...props} />;
    },
    Contacts: (props: ContactsProps) => {
        return <Contacts {...props} />;
    },
    CollectorTruck: (props: CollectConfigCollectorTruckProps) => {
        return <CollectConfigCollectorTruck {...props} />;
    },
    Split: (props: CollectConfigSplitProps) => {
        return <CollectConfigSplit {...props} />;
    },
    PatchTracker: (props: PatchTrackerProps) => {
        return <PatchTracker {...props} />;
    },
    DumpsterOnSiteSelect: (props: DumpsterOnSiteSelectProps) => {
        return <DumpsterOnSiteSelect {...props} />;
    }
};

export default Modules;
