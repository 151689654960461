import { StatusCodes } from 'http-status-codes';
import React from 'react';

import { match } from '@bbng/util/misc';
import { IBbngResponse } from '@bbng/util/types';

import { defaultErrorToast, syncRequest } from '../../common/syncRequest';
import { toast } from '../../components/Toast';
import useQuery from '../Query';
import { useRequest } from '../StatelessRequest';

export type PaginateDataProps = {
    endpoint: string;
    customQueryParams?: Record<string, any>;
    enrichData?: (data: IBbngResponse<any[]>) => Promise<IBbngResponse<any[]>>;
};

export type PaginateDataQueryParams = {
    page: number;
    limit: number;
};

type PaginateDataError = {
    msg?: string;
    code?: StatusCodes;
};

const DEFAULT_LIMIT = 25;

const usePaginateData = <Data = any>({
    endpoint,
    customQueryParams = {},
    enrichData = async (data) => data
}: PaginateDataProps): PaginateDataState<Data> => {
    const {
        query: { limit: queryLimit = DEFAULT_LIMIT, page: queryPage = 1, ...customQueries },
        updateQuery: setValueQuery
    } = useQuery<PaginateDataQueryParams>();
    const request = useRequest();

    const [response, setResponse] = React.useState<IBbngResponse<Data[]>>();
    const [error, setError] = React.useState<PaginateDataError>();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isMounting, setIsMounting] = React.useState(true);

    const [lastCustomQuery, setLastCustomerQuery] = React.useState<Record<string, any> | null>(null);

    React.useEffect(() => {
        if (match(customQueryParams, lastCustomQuery)) return;

        setLastCustomerQuery(customQueryParams);

        if (isMounting) {
            const page = queryPage > 1 ? queryPage : 1;
            const limit = queryLimit > DEFAULT_LIMIT ? queryLimit : DEFAULT_LIMIT;
            setValueQuery({ ...customQueries, page, limit });
            fetchData(page, limit);
            setIsMounting(false);
        } else {
            const limit = queryLimit > DEFAULT_LIMIT ? queryLimit : DEFAULT_LIMIT;
            setValueQuery({ ...customQueryParams, page: 1, limit });
            fetchData(1, limit);
        }
    }, [customQueryParams]);

    const getPaginationInfo = (): PaginationData | undefined => {
        if (response?.metadata) {
            return {
                currentPage  : response.metadata.page,
                totalPerPage : response.metadata.total_per_page,
                totalPages   : response.metadata.pages_count,
                itemsCount   : response.data.count
            };
        } else {
            return undefined;
        }
    };

    const fetchData = React.useCallback(
        (page: number, limit: number) => {
            setIsLoading(true);

            syncRequest<Data[]>({
                method  : 'GET',
                url     : endpoint,
                onError : defaultErrorToast,
                params  : {
                    ...customQueryParams,
                    page  : page,
                    limit : limit
                }
            })
                .then(async (response) => {
                    if (response?.response?.data?.ro && response.response.metadata) {
                        setResponse(await enrichData(response.response));
                    } else {
                        setError({ code: response.error?.status, msg: response.error?.message });
                    }
                })
                .finally(() => {
                    setIsMounting(false);
                    setIsLoading(false);
                });
        },
        [request, queryLimit, queryPage, endpoint, customQueryParams, setError, setResponse, setIsLoading]
    );

    const nextPage = React.useCallback(() => {
        if (response?.metadata) {
            const currentPage = response.metadata.page;
            const page = currentPage + 1;
            const limit = response.metadata.total_per_page;

            setValueQuery({ page, limit });
            fetchData(page, limit);
        } else {
            toast({
                severity : 'error',
                summary  : 'Technical Error',
                detail   : 'No metadata available'
            });
        }
    }, [fetchData, response]);

    const previousPage = React.useCallback(() => {
        if (response?.metadata) {
            const currentPage = response.metadata.page;
            const page = currentPage + -1;
            const limit = response.metadata.total_per_page;

            setValueQuery({ page, limit });
            fetchData(page < 0 ? 0 : page, limit);
        } else {
            toast({
                severity : 'error',
                summary  : 'Technical Error',
                detail   : 'No metadata available'
            });
        }
    }, [fetchData, response]);

    const jumpToPage = React.useCallback(
        (page: number) => {
            const limit = response?.metadata.total_per_page ?? DEFAULT_LIMIT;

            setValueQuery({ page, limit });
            fetchData(page, limit);
        },
        [fetchData, response]
    );

    const setLimit = React.useCallback(
        (limit: number) => {
            if (limit < DEFAULT_LIMIT) limit = DEFAULT_LIMIT;

            const page = 1;

            setValueQuery({ page, limit });
            fetchData(page, limit);
        },
        [fetchData, response]
    );

    const pageRefresh = React.useCallback(() => {
        const { currentPage = 1, totalPerPage = DEFAULT_LIMIT } = getPaginationInfo() ?? {};

        fetchData(currentPage, totalPerPage);
    }, [fetchData, response, getPaginationInfo]);

    const paginationInfo = getPaginationInfo();

    return {
        isLoading,
        isMounting,
        items        : response?.data?.ro ?? [],
        error,
        pagination   : paginationInfo,
        nextPage     : response?.metadata?.next ? nextPage : undefined,
        previousPage : response?.metadata?.previous ? previousPage : undefined,
        jumpToPage   : jumpToPage,
        setLimit     : setLimit,
        pageRefresh  : pageRefresh
    };
};

type PaginationData = {
    currentPage: number;
    totalPages: number;
    totalPerPage: number;
    itemsCount: number;
};

export type PaginateDataState<Data> = {
    isMounting: boolean;
    isLoading: boolean;
    items: Data[];
    error?: PaginateDataError;
    pagination?: PaginationData;
    jumpToPage: (page: number) => void;
    setLimit: (limit: number) => void;
    nextPage?: () => void;
    previousPage?: () => void;
    pageRefresh: () => void;
};

export default usePaginateData;
