import moment from 'moment';
import { CalendarChangeParams, Calendar as PRCalendar } from 'primereact/calendar';
import React from 'react';

import { isValidDate } from '@bbng/util/misc';
import { ISODate } from '@bbng/util/types';

export type CalendarRangeProps = {
    label?: string;
    readOnly: boolean;
    required: boolean;
    id: string;
    result: (value: undefined | ISODate | ISODate[], errors: null | string[], id: string) => void;
    value?: ISODate[];
    triggerValidation?: boolean;
    showTime?: boolean;
    displayError?: boolean;
    iconPos?: 'left' | 'right';
    placeholder?: string;
};

export const CalendarRange = ({
    label,
    readOnly,
    required,
    id,
    result,
    value,
    triggerValidation,
    showTime = true,
    displayError = true,
    iconPos,
    placeholder = 'Date'
}: CalendarRangeProps) => {
    const [val, setVal] = React.useState<undefined | ISODate | ISODate[]>(value ? value : undefined);
    const [err, setErr] = React.useState<null | string[]>(null);

    React.useEffect(() => {
        if (triggerValidation && required) {
            const errors = isValidCalendar(val) ? null : ['Date invalide'];
            setErr(errors);
            result(val, errors, id);
        }
    }, [triggerValidation]);

    const isValidCalendar = React.useCallback(
        (dates: undefined | ISODate | ISODate[]) =>
            Array.isArray(dates) &&
            dates.length === 2 &&
            dates.every(Boolean) &&
            moment(dates[0]).isBefore(moment(dates[1])),
        []
    );

    const _checkDescendingValue = React.useCallback(
        async (
            stateValue: undefined | ISODate | ISODate[],
            propsValue: undefined | ISODate | ISODate[]
        ): Promise<void> => {
            if (stateValue !== propsValue) {
                setVal(propsValue);
                setErr(null);
                result(propsValue, null, id);
                if (propsValue) {
                    const errors = isValidCalendar(propsValue) ? null : ['Date invalide'];
                    setErr(errors);
                    result(val, errors, id);
                    result(propsValue ? propsValue : undefined, errors, id);
                }
            }
        },
        [setVal, setErr, result]
    );

    React.useEffect(() => {
        _checkDescendingValue(val, value);
    }, [value]);

    const handleOnChange = React.useCallback(
        (e: CalendarChangeParams): void => {
            const newVal = e.value
                ? ((e.value as Date[])?.map((d) => (isValidDate(d) ? moment.utc(d).toISOString() : null)) as ISODate[])
                : undefined;
            setVal(newVal ? newVal : undefined);
            const errors = isValidCalendar(newVal) ? null : ['Date invalide'];
            setErr(errors);
            result(newVal ? newVal : undefined, errors, id);
        },
        [setVal, setErr, result, id]
    );

    return (
        <span className="p-float-label">
            <PRCalendar
                locale="fr"
                disabled={readOnly}
                id={id}
                name={id}
                onChange={handleOnChange}
                className={`${displayError && err && err.length > 0 ? 'p-invalid' : ''}`}
                placeholder={placeholder}
                dateFormat="dd/mm/yy"
                mask="99/99/9999"
                showIcon
                iconPos={iconPos}
                showTime={showTime}
                selectionMode="range"
                value={
                    val
                        ? ((val as ISODate[]).map((d) =>
                              isValidDate(moment(d).toDate()) ? moment.utc(d).toDate() : null
                          ) as Date[])
                        : undefined
                }
                tooltip={displayError && err && err.length > 0 ? err.join('\n') : ''}
            />
            {label && <label htmlFor={id}>{`${label}${required ? ' *' : ''}`}</label>}
        </span>
    );
};
