import React from 'react';
import * as yup from 'yup';

import { ChipsInput } from './Chips';
import { CurrencyInput } from './Currency';
import { DropdownInput, DropdownInputProps } from './Dropdown';
import { EmailInput } from './Email';
import { LicensePlateInput } from './LicensePlate';
import { MaskInput } from './Mask';
import { NAFInput } from './Naf';
import { NumberInput } from './Number';
import { PercentageInput } from './Percentage';
import PhoneInput, { InputPhoneProps } from './Phone';
import { SirenInput } from './Siren';
import { SiretInput } from './Siret';
import { TextInput } from './Text';
import { TextareaInput } from './Textarea';
import { VATInput } from './Vat';

export type baseInputProps<Value = any> = {
    readOnly?: boolean;
    required: boolean;
    id: string;
    result: (value: Value, errors: null | string[], id: string) => void;
    value: Value;
    validationSchema?: yup.AnySchema;
    errors: null | string[];
    displayError?: boolean;
    width?: string;
};

export type textInputProps = baseInputProps<string>;
export type genericChipsInputProps = baseInputProps<string[]> & { label?: string };
export type genericTextInputProps = textInputProps & { label: string };
export type genericTextareaInpuProps = textInputProps & { label: string; width?: string; minHeight?: string };
export type genericNumberInputProps = baseInputProps<number | null> & {
    label: string;
    max?: number;
    min?: number;
    showRequiredStar?: boolean;
    step?: number;
    suffix?: string;
    decimal?: boolean;
    size?: number;
    maxNumberOfDigit?: number;
};
export type genericMaskInputProps = genericTextInputProps & { mask: string; maxLength?: number; size?: number };

const Input = {
    Siret        : (props: textInputProps) => <SiretInput {...props} />,
    Siren        : (props: textInputProps) => <SirenInput {...props} />,
    VAT          : (props: textInputProps) => <VATInput {...props} />,
    NAF          : (props: textInputProps) => <NAFInput {...props} />,
    Text         : (props: genericTextInputProps) => <TextInput {...props} />,
    Currency     : (props: genericNumberInputProps) => <CurrencyInput {...props} />,
    Phone        : (props: InputPhoneProps) => <PhoneInput {...props} />,
    Email        : (props: genericTextInputProps) => <EmailInput {...props} />,
    Number       : (props: genericNumberInputProps) => <NumberInput {...props} />,
    Percentage   : (props: genericNumberInputProps) => <PercentageInput {...props} />,
    Dropdown     : (props: DropdownInputProps) => <DropdownInput {...props} />,
    Textarea     : (props: genericTextareaInpuProps) => <TextareaInput {...props} />,
    LicensePlate : (props: textInputProps) => <LicensePlateInput {...props} />,
    Mask         : (props: genericMaskInputProps) => <MaskInput {...props} />,
    Chips        : (props: genericChipsInputProps) => <ChipsInput {...props} />
};

export default Input;
