import { Tag } from 'primereact/tag';
import styled from 'styled-components';

export const Page = styled.form`
    margin: 16px;
    display: flex;
    flex-direction: column;
    & > * {
        flex: 1;
    }
    gap: 16px;
    & h1 {
        margin: 0;
    }
`;

/**
 * --------- Description ----------
 */
export const DescriptionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
`;
export const DescriptionTimeContainer = styled.div`
    display: flex;
    gap: 16px;
`;
export const DescriptionTime = styled.div`
    font-size: 3em;
    font-weight: 100;
`;
export const DescriptionDate = styled.div`
    font-size: 1.5em;
    font-weight: 400;
`;
export const StatusTag = styled(Tag)`
    font-size: 1.5em;
`;
export const DescriptionContentContainer = styled.div`
    display: flex;
    gap: 64px;
`;
export const DescriptionContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
`;
export const DescriptionDriverContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    & label {
        font-weight: 100;
    }
`;
export const DriverData = styled.div`
    font-size: 1.2em;
    font-weight: 300;
`;

/**
 * --------- Service ----------
 */
export const ServiceContainer = styled.div`
    display: flex;
    gap: 32px;
    justify-content: center;
    flex-direction: column;
`;
export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const FlexContainer = styled.div`
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;
`;
export const AddressContainer = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;
export const AddressDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
export const CollectedItemsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;
export const HazardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const HazardReason = styled.div`
    font-size: 1.5em;
    font-weight: 200;
`;

export const OrderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const OrderItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
`;

export const OrderItemLabel = styled.label`
    font-weight: 100;
`;

export const OrderItemData = styled.div`
    flex-wrap: wrap;
    flex-shrink: 1;
    max-width: 300px;
    word-break: break-word;
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
`;
