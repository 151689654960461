import equal from 'fast-deep-equal/react';
import { SelectButton } from 'primereact/selectbutton';
import React from 'react';
import styled from 'styled-components';

import { CardErrors, EPlanningCalculMethod, EPlanningType } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Input, { textInputProps } from '../../components/Inputs';

export type PlanningSettingsProps = {
    readOnly?: boolean;
    value?: PlanningSettingsState;
    id: string;
    result: (value: PlanningSettingsState, errors: CardErrors<PlanningSettingsState>, id: string) => void;
    displayError?: boolean;
    type: EPlanningType;
};

export type PlanningSettingsState = {
    dumpster_deposit_priority: number;
    dumpster_removal_priority: number;
    maximum_volume: number;
    calcul_method: EPlanningCalculMethod;
    end_slot_margin_minutes: number;
    begin_slot_margin_minutes: number;
    optimization_duration_seconds: number;
    ignore_here: boolean;
};

export type PlanningSettingsErrorState = CardErrors<PlanningSettingsState>;

export const initialState: PlanningSettingsState = {
    dumpster_deposit_priority     : 50,
    dumpster_removal_priority     : 50,
    maximum_volume                : 300,
    calcul_method                 : EPlanningCalculMethod.Classic,
    end_slot_margin_minutes       : 0,
    optimization_duration_seconds : 300, // 5 minutes
    ignore_here                   : false,
    begin_slot_margin_minutes     : 0,
};

export const initialErrorState: PlanningSettingsErrorState = generateInitialErrorState(initialState);

export const initialRef = Array.from(Array(Object.keys(initialState).length)).map(() =>
    React.createRef<textInputProps>()
);

export const PlanningSettings = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false,
    type
}: PlanningSettingsProps) => {
    const [val, setVal] = React.useState<PlanningSettingsState>(value);
    const [err, setErr] = React.useState<PlanningSettingsErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        if (equal(val[id as keyof PlanningSettingsState], value)) return;

        const data = { ...val, [childId]: value };
        const newData = forceIntegrity(data, childId);

        setVal(newData);

        if (equal(err[id as keyof PlanningSettingsErrorState], errors)) return;
        const newErr = { ...err, [childId]: errors };
        setErr(checkIntegrity(newData, newErr, childId));
    };

    return (
        <ConfigContainer>
            {type === EPlanningType.DUMPSTER ? (
                <>
                    <Input.Number
                        required
                        label="Priorité dépôt"
                        id="dumpster_deposit_priority"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.dumpster_deposit_priority}
                        errors={err.dumpster_deposit_priority}
                        displayError={displayError}
                        max={100}
                        min={0}
                    />
                    <Input.Number
                        required
                        label="Priorité enlèvement"
                        id="dumpster_removal_priority"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.dumpster_removal_priority}
                        errors={err.dumpster_removal_priority}
                        displayError={displayError}
                        max={100}
                        min={0}
                    />
                </>
            ) : (
                <Input.Number
                    required
                    label="Volume maximum"
                    id="maximum_volume"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.maximum_volume}
                    errors={err.maximum_volume}
                    displayError={displayError}
                    min={0}
                />
            )}
            <div style={{ position: 'relative' }}>
                <small style={{ position: 'absolute', top: -17, opacity: 0.6 }}>Méthode de calcul</small>
                <SelectButton
                    className="p-button-sm"
                    value={val.calcul_method}
                    onChange={(e) => (e.value === null ? void 0 : handleChange(e.value, null, 'calcul_method'))}
                    options={[
                        { label: 'Classique', value: EPlanningCalculMethod.Classic },
                        { label: 'Alternative', value: EPlanningCalculMethod.Custom },
                        { label: 'Flexible (avec retard)', value: EPlanningCalculMethod.CustomWithoutTime }
                    ]}
                    disabled={readOnly}
                    unselectable={true}
                />
            </div>
        </ConfigContainer>
    );
};

const ConfigContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const checkIntegrity = (
    data: PlanningSettingsState,
    errors: PlanningSettingsErrorState,
    id: string
): PlanningSettingsErrorState => {
    if (data.dumpster_deposit_priority + data.dumpster_removal_priority !== 100) {
        errors[id as keyof PlanningSettingsErrorState] = ['La somme des priorités doit être égale à 100.'];
    } else {
        Object.keys(errors).forEach((key) => (errors[key as keyof PlanningSettingsErrorState] = null));
    }
    return errors;
};

export const forceIntegrity = (data: PlanningSettingsState, id: string): PlanningSettingsState => {
    if (id === 'dumpster_deposit_priority') {
        data.dumpster_removal_priority = 100 - data.dumpster_deposit_priority;
    }
    if (id === 'dumpster_removal_priority') {
        data.dumpster_deposit_priority = 100 - data.dumpster_removal_priority;
    }
    return data;
};
