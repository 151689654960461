import { ZohoAddressRo, ZohoCustomField, ZohoTag } from '.';
import { BillingBranch, ISODate } from '../misc';

export interface ZohoInvoiceCreateDto {
    customer_id: string;
    contact_persons?: string[];
    invoice_number?: string;
    place_of_supply?: string;
    vat_treatment?: string;
    tax_treatment?: string;
    gst_treatment?: string;
    gst_no?: string;
    reference_number?: string;
    template_id?: string;
    date?: string;
    payment_terms?: number;
    payment_terms_label?: string;
    due_date?: string;
    discount?: number;
    is_discount_before_tax?: boolean;
    discount_type?: string;
    is_inclusive_tax?: boolean;
    exchange_rate?: number;
    recurring_invoice_id?: string;
    invoiced_estimate_id?: string;
    salesperson_name?: string;
    custom_fields?: ZohoCustomField[];
    line_items: ZohoLineItem[];
    payment_options?: ZohoPaymentOptions;
    allow_partial_payments?: boolean;
    custom_body?: string;
    custom_subject?: string;
    notes?: string;
    terms?: string;
    shipping_charge?: number;
    adjustment?: number;
    adjustment_description?: string;
    reason?: string;
    tax_authority_id?: number;
    tax_exemption_id?: number;
    avatax_use_code?: string;
    avatax_exempt_no?: string;
    tax_id?: number;
    expense_id?: string;
    salesorder_item_id?: string;
    avatax_tax_code?: string;
    time_entry_ids?: string[];
    branch_id?: string;
}

export interface ZohoInvoiceEditDto {
    invoice_id?: number;
    ach_payment_initiated?: boolean;
    invoice_number?: string;
    is_pre_gst?: boolean;
    place_of_supply?: string;
    gst_no?: string;
    gst_treatment?: string;
    vat_treatment?: string;
    tax_treatment?: string;
    vat_reg_no?: string;
    date?: string;
    status?: EZohoInvoiceStatus;
    payment_terms?: number;
    payment_terms_label?: string;
    due_date?: string;
    payment_expected_date?: string;
    last_payment_date?: string;
    reference_number?: string;
    customer_id?: string;
    customer_name?: string;
    contact_persons?: string[];
    currency_id?: number;
    currency_code?: string;
    exchange_rate?: number;
    discount?: number;
    is_discount_before_tax?: boolean;
    discount_type?: string;
    is_inclusive_tax?: boolean;
    recurring_invoice_id?: string;
    is_viewed_by_client?: boolean;
    has_attachment?: boolean;
    client_viewed_time?: string;
    line_items?: ZohoLineItem[];
    shipping_charge?: number;
    adjustment?: number;
    adjustment_description?: string;
    sub_total?: number;
    tax_total?: number;
    total?: number;
    taxes?: ZohoTax[];
    payment_reminder_enabled?: boolean;
    payment_made?: number;
    credits_applied?: number;
    tax_amount_withheld?: number;
    balance?: number;
    write_off_amount?: number;
    allow_partial_payments?: boolean;
    price_precision?: number;
    payment_options?: ZohoPaymentOptions;
    is_emailed?: boolean;
    reminders_sent?: number;
    last_reminder_sent_date?: string;
    billing_address?: ZohoAddressRo;
    shipping_address?: ZohoAddressRo;
    notes?: string;
    terms?: string;
    custom_fields?: ZohoCustomField[];
    template_id?: number;
    template_name?: string;
    created_time?: ISODate;
    last_modified_time?: ISODate;
    attachment_name?: string;
    can_send_in_mail?: boolean;
    salesperson_id?: string;
    salesperson_name?: string;
    invoice_url?: string;
}

export type ZohoInvoiceEmailDto = {
    to_mail_ids: string[];
    cc_mail_ids?: string[];
    invoice_number: string; // zoho invoice number
    url?: string; // url to add in the email body
    month: number;
    year: number;
    day: number;
    billing_branch: BillingBranch; // needed to get the appropriate email template
    end_of_month_template: boolean;
    action?: 'payment' | 'service'; // required if end_of_month_template is false: whether the invoice comes after a payment or a service (collect or delivery)
};

/**
 * Contact Response Object from multiple invoices requests (get all...).
 */
export interface ZohoInvoicesRo {
    invoice_id: number;
    ach_payment_initiated: boolean;
    customer_name: string;
    customer_id: number;
    status: EZohoInvoiceStatus;
    invoice_number: string;
    reference_number: string;
    date: string;
    due_date: string;
    due_days: string;
    currency_id: number;
    schedule_time: string;
    currency_code: string;
    is_viewed_by_client: boolean;
    has_attachment: boolean;
    client_viewed_time: string;
    total: number;
    balance: number;
    created_time: ISODate;
    last_modified_time: ISODate;
    is_emailed: boolean;
    reminders_sent: number;
    last_reminder_sent_date: string;
    payment_expected_date: string;
    last_payment_date: string;
    custom_fields: ZohoCustomField[];
    documents: string;
    salesperson_id: string;
    salesperson_name: string;
    shipping_charge: number;
    adjustment: number;
    write_off_amount: number;
    exchange_rate: number;
}

/**
 * Contact Response Object from single invoice requests (get, patch, post...).
 */
export interface ZohoInvoiceRo {
    invoice_id: string;
    ach_payment_initiated: boolean;
    invoice_number: string;
    is_pre_gst: boolean;
    place_of_supply: string;
    gst_no: string;
    gst_treatment: string;
    vat_treatment: string;
    tax_treatment: string;
    vat_reg_no: string;
    date: string; // YYYY-MM-DD
    status: EZohoInvoiceStatus;
    payment_terms: number;
    payment_terms_label: string;
    due_date: string; // YYYY-MM-DD
    payment_expected_date: string;
    last_payment_date: string;
    reference_number: string;
    customer_id: string;
    customer_name: string;
    contact_persons: string[];
    currency_id: number;
    currency_code: string;
    exchange_rate: number;
    discount: number;
    is_discount_before_tax: boolean;
    discount_type: string;
    is_inclusive_tax: boolean;
    recurring_invoice_id: string;
    is_viewed_by_client: boolean;
    has_attachment: boolean;
    client_viewed_time: string;
    line_items: ZohoLineItem[];
    shipping_charge: number;
    adjustment: number;
    adjustment_description: string;
    sub_total: number;
    tax_total: number;
    total: number;
    taxes: ZohoTax[];
    payment_reminder_enabled: boolean;
    payment_made: number;
    credits_applied: number;
    tax_amount_withheld: number;
    balance: number;
    write_off_amount: number;
    allow_partial_payments: boolean;
    price_precision: number;
    payment_options: ZohoPaymentOptions;
    is_emailed: boolean;
    reminders_sent: number;
    last_reminder_sent_date: string;
    billing_address: ZohoInvoiceBillingAddress;
    shipping_address: ZohoInvoiceShippingAddress;
    notes: string;
    terms: string;
    custom_fields: ZohoCustomField[];
    template_id: number;
    template_name: string;
    created_time: ISODate;
    last_modified_time: ISODate;
    attachment_name: string;
    can_send_in_mail: boolean;
    salesperson_id: string;
    salesperson_name: string;
    invoice_url: string;
}

export interface Warehouse {
    warehouse_id: string;
    warehouse_name: string;
    warehouse_stock_on_hand: string;
}

export interface ZohoLineItem {
    line_item_id?: number;
    item_id?: number;
    project_id?: number;
    project_name?: string;
    time_entry_ids?: string[];
    warehouses?: Warehouse[];
    item_type?: string;
    product_type?: string;
    expense_id?: string;
    expense_receipt_name?: string;
    name?: string;
    description?: string;
    item_order?: number;
    bcy_rate?: number;
    rate?: number;
    quantity?: number;
    unit?: string;
    discount_amount?: number;
    discount?: number;
    tags?: ZohoTag[];
    tax_id?: string;
    tax_name?: string;
    tax_type?: string;
    tax_percentage?: number;
    tax_treatment_code?: string;
    item_total?: number;
    header_name?: string;
    header_id?: number;
}

export interface ZohoTax {
    tax_name: string;
    tax_amount: number;
}

export interface ZohoPaymentGateway {
    configured: boolean;
    additional_field1: string;
    gateway_name: string;
}

export interface ZohoPaymentOptions {
    payment_gateways: ZohoPaymentGateway[];
}

export interface ZohoInvoiceBillingAddress {
    address: string;
    street2: string;
    city: string;
    state: string;
    zip: number;
    country: string;
    fax: string;
}

export interface ZohoInvoiceShippingAddress {
    address: string;
    street2: string;
    city: string;
    state: string;
    zip: number;
    country: string;
    fax: string;
}

export enum EZohoInvoiceStatus {
    SENT = 'sent',
    SENDING = 'sending', // this is not an official status, but used by us once email is triggered
    PAID = 'paid',
    DRAFT = 'draft',
    OVERDUE = 'overdue',
    VOID = 'void',
    UNPAID = 'unpaid',
    PARTIALLY_PAID = 'partially_paid',
    VIEWED = 'viewed'
}

export type ZohoInvoiceDownloadRo = {
    id: string;
    document: Buffer;
};
