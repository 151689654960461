import { TTreeObject, TreeNode } from '@bbng/feature/authz-hierarchy';
import { ICustomerContact } from '@bbng/util/types';

/**
 * Get root user id of a given organization chart.
 * @param {TTreeObject<string>} organizationChartObject
 *
 * @returns {string} root user
 */
export function getRootUserId(organizationChartObject: TTreeObject<string>): string {
    const organizationChart = TreeNode.parse(organizationChartObject);
    const user_ids = getUserIdsFromOrganizationChart(organizationChartObject);
    let root = '';
    user_ids.forEach((id) => {
        const node = organizationChart.findFirst((node) => node.data === id);
        if (node?.isRoot()) {
            root = id;
        }
    });
    return root;
}

export function getUserIdsFromOrganizationChart(organizationChartObject: TTreeObject<string>): string[] {
    const organizationChart = TreeNode.parse(organizationChartObject);

    const nodes = organizationChart.findAll(() => true);

    return nodes.map((node) => node.data);
}

/**
 * On récupère l'email et le téléphone du contact le plus fiable (contact principal, sinon contact de facturation, sinon un autre contact).
 */
export const getMainEmailPhoneFromCustomerContacts = (
    contacts: ICustomerContact[]
): {
    email: string | undefined;
    phone: string | undefined;
} => {
    const primaryContacts = contacts.filter((e) => e.is_primary === true) || [];
    const invoiceContacts = contacts.filter((e) => e.is_invoice === true) || [];
    const otherContacts = contacts.filter((e) => e.is_primary === false && e.is_invoice === false) || [];

    /**
     * On récupère l'email et le téléphone du contact le plus fiable (contact principal, sinon contact de facturation, sinon un autre contact).
     */
    let mainEmail = undefined;
    let mainPhone = undefined;
    if (primaryContacts?.length > 0) {
        const primaryContactsWithEmail = primaryContacts.filter((e) => (e.email || '')?.trim()?.length > 0);
        if (!mainEmail && primaryContactsWithEmail.length > 0) {
            mainEmail = primaryContactsWithEmail[0].email;
        }
        const primaryContactsWithPhone = primaryContacts.filter((e) => (e.phone_number || '')?.trim()?.length > 0);
        if (!mainPhone && primaryContactsWithPhone.length > 0) {
            mainPhone = primaryContactsWithPhone[0].phone_number;
        }
    }
    if ((!mainEmail || !mainPhone) && invoiceContacts?.length > 0) {
        const invoiceContactsWithEmail = invoiceContacts.filter((e) => (e.email || '')?.trim()?.length > 0);
        if (!mainEmail && invoiceContactsWithEmail.length > 0) {
            mainEmail = invoiceContactsWithEmail[0].email;
        }
        const invoiceContactsWithPhone = invoiceContacts.filter((e) => (e.phone_number || '')?.trim()?.length > 0);
        if (!mainPhone && invoiceContactsWithPhone.length > 0) {
            mainPhone = invoiceContactsWithPhone[0].phone_number;
        }
    }
    if ((!mainEmail || !mainPhone) && otherContacts?.length > 0) {
        const otherContactsWithEmail = otherContacts.filter((e) => (e.email || '')?.trim()?.length > 0);
        if (!mainEmail && otherContactsWithEmail.length > 0) {
            mainEmail = otherContactsWithEmail[0].email;
        }
        const otherContactsWithPhone = otherContacts.filter((e) => (e.phone_number || '')?.trim()?.length > 0);
        if (!mainPhone && otherContactsWithPhone.length > 0) {
            mainPhone = otherContactsWithPhone[0].phone_number;
        }
    }

    return {
        email : mainEmail,
        phone : mainPhone
    };
};
