import React from 'react';
import styled from 'styled-components';

import { CardErrors } from '@bbng/util/types';

import { Card } from '../../components/Card';
import InputList from '../InputList';
import { Price } from '../InputList/prices';

export type LandfillCenterPricesProps = {
    readOnly?: boolean;
    value?: LandfillCenterPricesState;
    id: string;
    result: (
        value: LandfillCenterPricesState,
        errors: null | string[] | CardErrors<LandfillCenterPricesState>,
        id: string
    ) => void;
    displayError?: boolean;
};

const FullLineInput = styled.div`
    width: 100%;
`;

export type LandfillCenterPricesState = {
    priceList: Array<Price>;
};
export type LandfillCenterPricesErrorState = CardErrors<LandfillCenterPricesState>;

export const initialState: LandfillCenterPricesState = {
    priceList: []
};
export const initialErrorState: LandfillCenterPricesErrorState = Object.fromEntries(
    Object.keys(initialState).map((k) => [k, null])
) as LandfillCenterPricesErrorState;

export const LandfillCenterPrices: React.FC<LandfillCenterPricesProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: LandfillCenterPricesProps) => {
    const [val, setVal] = React.useState<LandfillCenterPricesState>(value);
    const [err, setErr] = React.useState<LandfillCenterPricesErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (value: any, errors: string[] | null, childId: string) => {
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    return (
        <Card title="Grille de tarifs des déchets">
            <FullLineInput>
                <InputList.Prices
                    title="Tarif"
                    onChange={(value) => handleChange(value, null, 'priceList')}
                    value={val.priceList}
                    readOnly={readOnly}
                />
            </FullLineInput>
        </Card>
    );
};
