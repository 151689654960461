import React from 'react';
import styled from 'styled-components';

import {
    CardErrors,
    ISODate,
    ConstructionSiteRo,
    CustomerRo,
    CollectService,
    EPlanningType,
    PlanningShiftStepCategory
} from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import { Card } from '../../components/Card';
import Calendar from '../../components/Calendar';
import moment from 'moment';
import RelationAutocomplete from '../common/RelationAutocomplete';
import Button from '../../components/Button';
import Input from '../../components/Inputs';

export type DumpsterOnSiteGeneralProps = {
    readOnly?: boolean;
    value?: DumpsterOnSiteGeneralState;
    id: string;
    result: (
        value: DumpsterOnSiteGeneralState,
        errors: null | string[] | CardErrors<DumpsterOnSiteGeneralState>,
        id: string
    ) => void;
    displayError?: boolean;
};

export type DumpsterOnSiteGeneralState = {
    deposited_at?: ISODate;
    already_retrieved: boolean;
    retrieved_at?: ISODate;
    customer?: CustomerRo;
    construction_site?: ConstructionSiteRo;
    comment?: string;
    deposit_collect?: CollectService;
    retrieval_collect?: CollectService;
};

export type DumpsterOnSiteGeneralErrorState = CardErrors<DumpsterOnSiteGeneralState>;

export const initialState: DumpsterOnSiteGeneralState = {
    deposited_at      : moment().toISOString(),
    already_retrieved : false,
    retrieved_at      : moment().toISOString(),
    customer          : undefined,
    construction_site : undefined,
    comment           : '',
    deposit_collect   : undefined,
    retrieval_collect : undefined
};

export const initialErrorState: DumpsterOnSiteGeneralErrorState = generateInitialErrorState(initialState);

export const DumpsterOnSiteGeneral = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError = false
}: DumpsterOnSiteGeneralProps) => {
    const [val, setVal] = React.useState<DumpsterOnSiteGeneralState>(value);
    const [err, setErr] = React.useState<DumpsterOnSiteGeneralErrorState>(initialErrorState);

    React.useEffect(() => {
        result(val, err, id);
    }, [val, err]);

    const handleChange = (
        value: CollectService | CustomerRo | ConstructionSiteRo | string | boolean | null | undefined,
        errors: string[] | null,
        childId: string
    ) => {
        if (value && Array.isArray(value) && childId === 'postal_code') {
            const size = value.length;
            if (size > 0) {
                const split = value[size - 1].split(' ');
                if (split.length > 1) {
                    setVal((prev) => ({ ...prev, [childId]: split }));
                    setErr((prev) => ({ ...prev, [childId]: errors }));
                    return;
                }
            }
        }
        setVal((prev) => ({ ...prev, [childId]: value }));
        setErr((prev) => ({ ...prev, [childId]: errors }));
    };

    React.useEffect(() => {
        if (value.customer === undefined) {
            setErr((old) => ({ ...old, customer: ['Client obligatoire'] }));
        }
        if (value.construction_site === undefined) {
            setErr((old) => ({ ...old, construction_site: ['Chantier obligatoire'] }));
        }
    }, []);

    /**
     * Each time customer is updated, ensure that related data is removed if it's not related to the new customer
     */
    React.useEffect(() => {
        const removeConsSite = val.construction_site?.customer_id?.[0] !== val.customer?.id;
        const removeDeposit = val.deposit_collect?.informations?.customer_id !== val.customer?.id;
        const removeRetrieval = val.retrieval_collect?.informations?.customer_id !== val.customer?.id;
        setVal((prev) => ({
            ...prev,
            construction_site : removeConsSite ? undefined : prev.construction_site,
            deposit_collect   : removeDeposit ? undefined : prev.deposit_collect,
            retrieval_collect : removeRetrieval ? undefined : prev.retrieval_collect
        }));
    }, [val.customer]);

    React.useEffect(() => {
        if (val.deposit_collect) {
            setVal((prev) => ({ ...prev, deposited_at: val.deposit_collect?.arrived_at }));
        }
    }, [val.deposit_collect]);

    React.useEffect(() => {
        if (val.retrieval_collect) {
            setVal((prev) => ({ ...prev, retrieved_at: val.retrieval_collect?.arrived_at }));
        }
    }, [val.retrieval_collect]);

    return (
        <StyledCard title="Informations générales">
            <CardLine>
                <RelationAutocomplete.Customer
                    baseValue={val.customer}
                    displayError={displayError}
                    errors={err.customer}
                    id="customer"
                    label="Client"
                    readOnly={readOnly}
                    onSelect={(value) => handleChange(value, null, 'customer')}
                    onUnselect={() => handleChange(undefined, ['Aucun client sélectionné'], 'customer')}
                />
                <RelationAutocomplete.ConstructionSite
                    baseValue={val.construction_site}
                    displayError={displayError}
                    errors={err.construction_site}
                    customerId={val.customer?.id}
                    id="construction_site"
                    label="Chantier"
                    readOnly={readOnly || !val.customer}
                    onSelect={(value) => handleChange(value, null, 'construction_site')}
                    onUnselect={() => handleChange(undefined, ['Aucun chantier sélectionné'], 'construction_site')}
                />
            </CardLine>

            <StyledDivider />

            <StyledH2>Informations sur le dépôt</StyledH2>
            <CardLine>
                <TimeWithLabel>
                    <span>Déposée le</span>
                    <Calendar.DayMonthYear
                        required={true}
                        id="deposited_at"
                        readOnly={readOnly}
                        result={handleChange}
                        value={val.deposited_at}
                        errors={err.deposited_at}
                        displayError={displayError}
                        maxDate={moment.utc().toISOString()}
                        showTime={true}
                    />
                </TimeWithLabel>
                <RelationAutocomplete.Collect
                    baseValue={val.deposit_collect}
                    displayError={displayError}
                    errors={err.deposit_collect}
                    query={{
                        type        : EPlanningType.DUMPSTER,
                        category    : PlanningShiftStepCategory.SERVICE,
                        customer_id : val.customer?.id,
                        status      : 'FINISHED'
                    }}
                    id="deposit_collect"
                    label="Collecte de dépôt"
                    readOnly={readOnly || !val.customer}
                    onSelect={(value) => handleChange(value, null, 'deposit_collect')}
                    onUnselect={() => handleChange(undefined, ['Aucun chantier sélectionné'], 'deposit_collect')}
                />
            </CardLine>
            <StyledInfo>
                Si la collecte de dépôt n'est pas connue, il est possible de renseigner la date uniquement.
            </StyledInfo>

            <StyledDivider />

            <StyledH2>Informations sur l'enlèvement</StyledH2>
            <CardLine>
                <Button.Switch
                    id="already_retrieved"
                    value={val.already_retrieved}
                    result={handleChange}
                    label="Benne déjà enlevée"
                    labelPosition="left"
                    readOnly={readOnly}
                />
                {val.already_retrieved && (
                    <>
                        <TimeWithLabel>
                            <span>Enlevée le</span>
                            <Calendar.DayMonthYear
                                required={false}
                                id="retrieved_at"
                                readOnly={readOnly}
                                result={handleChange}
                                value={val.retrieved_at}
                                errors={err.deposited_at}
                                displayError={displayError}
                                maxDate={moment.utc().toISOString()}
                                showTime={true}
                            />
                        </TimeWithLabel>
                        <RelationAutocomplete.Collect
                            baseValue={val.retrieval_collect}
                            displayError={displayError}
                            errors={err.retrieval_collect}
                            query={{
                                type        : EPlanningType.DUMPSTER,
                                category    : PlanningShiftStepCategory.SERVICE,
                                customer_id : val.customer?.id,
                                status      : 'FINISHED'
                            }}
                            id="retrieval_collect"
                            label="Collecte d'enlèvement"
                            readOnly={readOnly || !val.customer}
                            onSelect={(value) => handleChange(value, null, 'retrieval_collect')}
                            onUnselect={() =>
                                handleChange(undefined, ['Aucun chantier sélectionné'], 'retrieval_collect')
                            }
                        />
                    </>
                )}
            </CardLine>
            {val.already_retrieved && (
                <StyledInfo>
                    Si la collecte d'enlèvement n'est pas connue, il est possible de renseigner la date uniquement.
                </StyledInfo>
            )}

            <StyledDivider />

            <Input.Textarea
                required={false}
                label="Commentaires"
                id="comment"
                readOnly={readOnly}
                result={handleChange}
                value={val.comment ?? ''}
                errors={err.comment}
                displayError={displayError}
            />
        </StyledCard>
    );
};

const CardLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 36px;
`;

export const CenterSpan = styled.span`
    display: flex;
    gap: 4px;
    align-items: center;
`;

const StyledCard = styled(Card)`
    ${CardLine} + ${CardLine} {
        margin-top: 10px;
    }
    .p-card-content {
        justify-content: center;
        flex-direction: column;
        gap: 12px;
    }
`;

const TimeWithLabel = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const StyledDivider = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    width: 50%;
    height: 1px;
    background: #e0e0e0;
`;

const StyledH2 = styled.h2`
    font-weight: 600;
    margin: 0;
    padding: 0 0 8px 0;
`;

const StyledInfo = styled.span`
    font-weight: 100;
    font-style: italic;
`;
